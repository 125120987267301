import countries from "i18n-iso-countries";
import i18n from "i18next";
import {
  CountryCode,
  default as parsePhoneNumber,
  findPhoneNumbersInText,
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from "libphonenumber-js";

const bannedList = ["AQ", "BV", "TF", "HM", "PN", "GS", "UM", "ZZ"];

function extractPhoneNumbers(string: string, geoLocation: string) {
  // Replace what we consider separators with pipes (better handled by the parsing library)
  // Adding the same string appended by "+" to support the same behavior as parseNumber for numbers like "1-212-222 8888"
  const newStringWithPipes = string.replace(/[.,;]/g, "|") + "|+" + string.replace(/[.,;]/g, "|+");
  let remainingCharacters = string.replace(/[\s]/g, "");

  const numbersFromText = findPhoneNumbersInText(newStringWithPipes, geoLocation as CountryCode);

  const numbers = numbersFromText.reduce<string[]>((accumulator, currentValue) => {
    if (currentValue?.number?.number) {
      const endsAt = currentValue.endsAt;
      const currentNumber = currentValue.number.number.toString();
      const input = newStringWithPipes
        .substring(currentValue.startsAt, currentValue.endsAt)
        .replace(/[\s+]/g, "");

      let extension = "";

      if (string[endsAt] === "*") {
        // Go to right until you find a number.
        // Stop if you find anything but a number.
        const extensionParsing = Array.from(string.slice(endsAt)).reduce(
          (accumulator, currentValue) => {
            if (accumulator.canConcat && (currentValue === "*" || /^\d+$/.test(currentValue))) {
              accumulator.str += currentValue;
            } else {
              accumulator.canConcat = false;
            }
            return accumulator;
          },
          {
            str: "",
            canConcat: true,
          },
        );
        extension = extensionParsing.str;
      }

      if (!accumulator.includes(currentNumber + extension)) {
        remainingCharacters = remainingCharacters.replaceAll(input, "");
        accumulator.push(currentNumber + extension);
      }
    }

    return accumulator;
  }, []);

  const unauthorizedRemainingCharacters = remainingCharacters.replace(/[.,;|\s+]/g, "");

  const isOneNumberWithExtension =
    numbers.length === 1 ? parseExtension(numbers[0]).isValidExtension : false;

  return {
    numbers,
    isExtraText: isOneNumberWithExtension ? false : unauthorizedRemainingCharacters.length > 0,
  };
}

function parseExtension(number: string) {
  const indexOfExtension = number.indexOf("*");
  if (indexOfExtension === -1) {
    return {
      extension: "",
      number: number,
      isValidExtension: true,
    };
  }

  let isValidExtension = true;
  let numOfAsterisks = 0; // max 10
  let numAfterAsterisks = 0; // max 7
  const extension = number.substring(indexOfExtension);
  const extractedNumber = number.substring(0, indexOfExtension);

  for (let i = 0; i < extension.length; i++) {
    if (numOfAsterisks > 10 || numAfterAsterisks > 7) {
      isValidExtension = false;
      break;
    }
    if (extension[i] === "*") {
      if (numAfterAsterisks) {
        isValidExtension = false;
        break;
      }
      numOfAsterisks++;
    } else if (!Number.isNaN(+extension[i])) {
      // extension[i] is a number primitive
      numAfterAsterisks++;
    } else {
      // extension[i] is not a number or asterisk
      isValidExtension = false;
      break;
    }
  }

  return {
    extension,
    number: extractedNumber,
    isValidExtension: Boolean(isValidExtension && numAfterAsterisks),
  };
}

function parseNumber(input: string, geoLocation?: CountryCode) {
  const { number, isValidExtension, extension } = parseExtension(input);

  const parsedWithCountry = parsePhoneNumberFromString(number, geoLocation);
  if (parsedWithCountry && parsedWithCountry.isValid()) {
    return {
      number: parsedWithCountry.number,
      extension: isValidExtension ? extension : "",
    };
  }

  const parsedWithPlus = parsePhoneNumberFromString(`+${number}`);
  if (parsedWithPlus && parsedWithPlus.isValid()) {
    return {
      number: parsedWithPlus.number,
      extension: isValidExtension ? extension : "",
    };
  }

  return {
    number: "",
    extension: "",
  };
}

function getI18nCountryList() {
  return Object.entries(countries.getNames(i18n.language, { select: "official" }))
    .map((country) => ({
      value: country[0],
      label: country[1],
    }))
    .filter((country) => !bannedList.includes(country.value));
}

function getI18nCountry(value: string) {
  return getI18nCountryList().find((country) => country.value === value);
}

function getPrefixForCountry(country: string) {
  return "+" + getCountryCallingCode(country as CountryCode);
}

function formatPhoneNumberIntl(phone: string) {
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.formatInternational();
  }
  return "";
}

function sanitizePhoneNumber(phone: string) {
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return phoneNumber.number;
  }
  return phone;
}

function sanitizeFaxNumber(faxNumber: string) {
  const { number, extension } = parseExtension(faxNumber);
  const parsedNumber = parsePhoneNumber(number);
  if (parsedNumber) {
    return parsedNumber.number + extension;
  }
  return faxNumber;
}

function hasNonZeroDecimal(number: number): boolean {
  return number % 1 !== 0;
}

// Generate a random float between 0 and 1
function generateRandomFloat() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  const randomValue = array[0] / 0xffffffff;
  return randomValue;
}

export {
  parseNumber,
  parseExtension,
  getI18nCountry,
  getI18nCountryList,
  sanitizeFaxNumber,
  sanitizePhoneNumber,
  getPrefixForCountry,
  extractPhoneNumbers,
  formatPhoneNumberIntl,
  hasNonZeroDecimal,
  generateRandomFloat,
};
