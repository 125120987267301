import { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Typography } from "ui";

import { selectCdrPages } from "../../selectors/faxes.selector";

function FaxesColumnPage({ boxName, dataIndex }) {
  const pageCount = useSelector(selectCdrPages(boxName, dataIndex));

  return <Typography variant="body">{pageCount ?? 0}</Typography>;
}

FaxesColumnPage.propTypes = {
  boxName: PropTypes.string,
  dataIndex: PropTypes.number,
};

export default memo(FaxesColumnPage);
