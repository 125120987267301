import { useState } from "react";

import CommonLayout from "components/Authentication/CommonLayout";
import CheckToken from "components/Authentication/ResetPassword/CheckToken";
import RequestLink from "components/Authentication/ResetPassword/RequestLink";
import RequestSent from "components/Authentication/ResetPassword/RequestSent";
import NewPassword from "components/Authentication/ResetPassword/NewPassword";
import LinkExpired from "components/Authentication/ResetPassword/LinkExpired";

export type ResetPasswordSteps =
  | "CHECK_TOKEN"
  | "REQUEST_LINK"
  | "REQUEST_SENT"
  | "NEW_PASSWORD"
  | "LINK_EXPIRED";

function ResetPassword() {
  const [revisedLink, setRevisedLink] = useState("");
  const [step, setStep] = useState<ResetPasswordSteps>("CHECK_TOKEN");

  return (
    <CommonLayout>
      {
        {
          CHECK_TOKEN: (
            <CheckToken
              setRevisedLink={setRevisedLink}
              onCompleted={(step: ResetPasswordSteps) => setStep(step)}
            />
          ),
          REQUEST_LINK: <RequestLink onCompleted={() => setStep("REQUEST_SENT")} />,
          REQUEST_SENT: <RequestSent />,
          LINK_EXPIRED: <LinkExpired onCompleted={() => setStep("REQUEST_LINK")} />,
          NEW_PASSWORD: <NewPassword token={revisedLink} />,
        }[step]
      }
    </CommonLayout>
  );
}

export default ResetPassword;
