import { Panel, Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

import dataCy from "enums/dataCy";
import { websiteUrls } from "routes/urls";
import { Typography, makeStyles, Helper } from "ui";
import { selectIsBaaSigned } from "selectors/account.selector";
import AdvancedSecurityControlRow from "./AdvancedSecurityControlRow";
import SignBaa from "./SignBaaRow";

function HipaaPanel() {
  const { t } = useTranslation();
  const isBaaSigned = useSelector(selectIsBaaSigned);
  const classes = useStyles({ isBaaSigned });

  return (
    <Panel
      id="hipaa"
      sx={{ mb: 7 }}
      data-cy={dataCy.hipaaPanel}
      headerTitle={
        <Box>
          <Typography variant="panelFrontHeader">{t("PROFILE.HIPAA_TITLE")}</Typography>
          <Box ml={1} component="a" target="_blank" rel="noreferrer" href={websiteUrls.hipaa}>
            <Helper size="lg" variant="light" />
          </Box>
        </Box>
      }
    >
      <Panel.Front
        headerAction={
          <Box>
            <FontAwesomeIcon
              size="1x"
              className={classes.complianceIcon}
              icon={isBaaSigned ? faCheckCircle : faExclamationTriangle}
            />
            <Typography variant="panelFrontHeader">
              {isBaaSigned ? t("PROFILE.HIPAA_COMPLIANT") : t("PROFILE.HIPAA_NOT_COMPLIANT")}
            </Typography>
          </Box>
        }
      >
        <AdvancedSecurityControlRow withIcon withStatus={false} />
        <SignBaa />
      </Panel.Front>
    </Panel>
  );
}

const useStyles = makeStyles(({ spacing, alohi, typography }) => ({
  complianceIcon: ({ isBaaSigned }: { isBaaSigned: boolean }) => ({
    marginRight: spacing(1),
    marginLeft: spacing(3),
    fontSize: typography.pxToRem(17),
    color: isBaaSigned ? alohi.lightGreen : alohi.white,
  }),
}));

export default HipaaPanel;
