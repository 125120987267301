import { API_AFFOGATO } from "config";
import { fetchWithRefresh } from "../helpers/fetch";

const checkToken = ({ token }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/referrer_user/${token}`;
  return fetchWithRefresh(path, requestOptions);
};

const getReferralLink = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/referral_invite_link`;
  return fetchWithRefresh(path, requestOptions);
};

export const referrerUserApi = {
  checkToken,
  getReferralLink,
};
