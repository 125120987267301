import { Typography } from "@alohi/kit";
import { BorderAnimation, theme, useMediaQuery } from "@alohi/kit";
import { alohi, Box, Icon, makeStyles, SvgBox } from "@alohi/kit";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { CustomerMarketingDataFaxSignCrossSellBannerStatus } from "api/gql/generated/graphql";
import SignPlusLogo from "assets/svg/sign_plus_logo_text.svg";
import clsx from "clsx";
import { borderAnimationSettings } from "components/Incentive/helpers/animations";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  className?: string;
  onClose: () => void;
}

function Header({ className, onClose }: HeaderProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const { promoStatus } = useTrialIncentive();

  const animation = useMemo(() => borderAnimationSettings, []);

  return (
    <Box className={clsx(classes.base, className)}>
      <SvgBox className={classes.logo} src={SignPlusLogo} />
      {isMobileLayout &&
      promoStatus === CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable ? (
        <Box className={classes.freeTrial}>
          <Typography className={classes.freeTrialText}>
            {t("INCENTIVE_SIGN.FREE_TRIAL")}
          </Typography>
          <BorderAnimation
            isHovering={false}
            isActive={false}
            borderRadius={14}
            idleAnimation={animation}
            hoverAnimation={animation}
          />
        </Box>
      ) : null}
      <Box className={classes.filler} />
      <Icon className={classes.icon} icon={faClose} onClick={onClose} />
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    position: "absolute",
    height: "70px",
    left: 0,
    right: 0,
    top: 0,
    display: "flex",
    alignItems: "flex-start",
    padding: spacing(3, 3, 0, 4),
    [breakpoints.down("sm")]: {
      background: alohi.neutral0,
      zIndex: 1,
    },
  },
  logo: {
    width: "137px",
  },
  filler: {
    flexGrow: 1,
  },
  icon: {
    fontSize: 26,
    color: alohi.neutral900,
    cursor: "pointer",
    "&:hover": {
      color: alohi.neutral700,
    },
    "&:active": {
      color: alohi.neutral500,
    },
  },
  freeTrial: {
    position: "relative",
    marginLeft: spacing(2),
    padding: spacing(0.5, 1.5, 0.5, 1.5),
    whiteSpace: "nowrap",
  },
  freeTrialText: {
    color: "black",
  },
}));

export default Header;
