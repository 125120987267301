import { Box } from "@alohi/kit";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";

import { filterQueryParams } from "routes/urls";
import { Typography, makeStyles } from "ui";
import gift from "../../assets/images/gift.svg";
import CommonLayout from "../../components/Authentication/CommonLayout";
import SignUpForm from "../../components/Authentication/SignUpForm";
import Currency from "../../components/Core/Currency/Currency";
import useTitle from "../../hooks/useTitle";
import { selectIsReferrerError, selectReferrerInfo } from "../../selectors/verifications.selector";
import {
  checkReferrerByToken,
  clearCheckReferrer,
} from "../../stores/reducers/verifications.reducer";

function SignUpFromReferral() {
  const classes = useStyles();
  const { token } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reroutedQueryParams = filterQueryParams({
    filter: ["_gl"],
    currentParams: queryParams,
  }).toString();

  useTitle(t("REFERRAL.YOU_GOT_FREE_CREDITS"));

  const referrer = useSelector(selectReferrerInfo);
  const isError = useSelector(selectIsReferrerError);

  useEffect(() => {
    if (token) {
      dispatch(checkReferrerByToken({ token }));
    }
    return () => {
      dispatch(clearCheckReferrer());
    };
  }, [token, dispatch]);

  if (isError) {
    return <Redirect to={{ pathname: `/signup`, search: reroutedQueryParams }} />;
  }

  if (!referrer) {
    return null;
  }

  return (
    <CommonLayout>
      <Box display="flex" justifyContent="center" flex={"0 1 300px"}>
        <Box width="100%">
          <Box>
            <Box mb={4} className={classes.giftWrapper}>
              <Box className={classes.gift}>
                <img src={gift} alt="gift" width="80" />
              </Box>
              <Box className={classes.textContainer}>
                <Typography className={classes.introText}>
                  <Trans i18nKey="REFERRAL.SIGNUP_GET_YOUR_FREE_GIFT_EMAIL">
                    {{ name: referrer.name }}
                  </Trans>
                  <Trans
                    i18nKey="REFERRAL.SIGNUP_GET_YOUR_FREE_GIFT_CREDIT"
                    components={{
                      credit: (
                        <Currency value={referrer.referee_amount} currency={referrer.currency} />
                      ),
                    }}
                  />
                </Typography>
              </Box>
            </Box>
            <SignUpForm disableMemberSection referrerUid={referrer.referrer_uid} />
          </Box>
        </Box>
      </Box>
    </CommonLayout>
  );
}

const useStyles = makeStyles(({ breakpoints }) => ({
  giftWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  introText: { lineHeight: 2, fontSize: "16px", textAlign: "center" },
  gift: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  textContainer: {
    [breakpoints.up("sm")]: {
      marginLeft: "-30%",
      marginRight: "-30%",
    },
  },
}));

export default SignUpFromReferral;
