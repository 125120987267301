import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import LinearInfiniteProgress from "components/Core/LinearInfiniteProgress/LinearInfiniteProgress";

const InboxStatusFilter = ({ value }) => {
  const { t } = useTranslation();
  switch (value) {
    case "success":
      return t("FAXES.SUCCESSFUL");

    case "partially_received":
      return t("FAXES.PARTIALLY_RECEIVED");
    case "insufficient_credit":
      return t("COMMON.FAILED") + " (" + t("FAXES.INSUFFICIENT_CREDIT") + ")";
    default:
      return "";
  }
};
const OutboxStatusFilter = ({ value }) => {
  const { t } = useTranslation();
  switch (value) {
    case "sending":
      return (
        <Box display="flex" alignItems="center" justifyContent="start">
          <Trans i18nKey="FAXES.SENDING" />
          <LinearInfiniteProgress dotCount={3} diameter={4} wrapperStyle={{ margin: 0 }} />
        </Box>
      );
    case "submitted":
      return t("FAXES.SCHEDULED_FOR_SENDING");
    case "queued":
      return t("FAXES.SENDING");
    case "queued_enhancement":
      return t("FAXES.AWAITING_OPTIMIZATION");
    case "converting":
      return t("FAXES.CONVERTING");
    case "converted":
      return t("FAXES.CONVERTED");
    case "scheduled_for_sending":
      return t("FAXES.SCHEDULED_FOR_SENDING");
    case "insufficient_credit":
      return t("COMMON.FAILED") + ": " + t("FAXES.INSUFFICIENT_CREDIT");
    default:
      return value || "";
  }
};

OutboxStatusFilter.propTypes = {
  value: PropTypes.string,
};

export { InboxStatusFilter, OutboxStatusFilter };
