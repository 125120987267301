import { useState } from "react";
import { Panel, Button } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "stores/store";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import ActivityLogsModal from "views/ActivityLogs/ActivityLogsModal";
import { selectIsActivityLogsRunning } from "selectors/authentication.selector";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";

function ActivityLogsRow() {
  const { t } = useTranslation();

  const planIsEnterprise = useAppSelector(selectIsPlanEnterprise);
  const isActivityLogsLoading = useAppSelector(selectIsActivityLogsRunning);
  const [isActivityLogsModalOpen, setIsActivityLogsModalOpen] = useState(false);
  const [isRequireUpgradeModalOpen, setIsRequireUpgradeModalOpen] = useState(false);

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">{t("PROFILE.SECURITY_ACTIVITY_LOGS")}</Panel.Cell>
        <Panel.Cell variant="actions">
          <Button
            variant="white"
            isLoading={isActivityLogsLoading}
            onClick={() => {
              if (!planIsEnterprise) {
                setIsRequireUpgradeModalOpen(true);
              } else {
                setIsActivityLogsModalOpen(true);
              }
            }}
          >
            {t("PROFILE.SECURITY_ACTIVITY_VIEW_LOGS")}
          </Button>
        </Panel.Cell>
      </Panel.Row>

      {isActivityLogsModalOpen && (
        <ActivityLogsModal onConfirm={() => setIsActivityLogsModalOpen(false)} />
      )}

      {isRequireUpgradeModalOpen && (
        <RequireUpgradeToEnterprise
          isPlural
          title={t("PROFILE.SECURITY_ACTIVITY_LOGS")}
          featureName={t("PROFILE.SECURITY_ACTIVITY_LOGS")}
          handleClosure={() => setIsRequireUpgradeModalOpen(false)}
        />
      )}
    </>
  );
}

export default ActivityLogsRow;
