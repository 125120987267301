import { memo } from "react";
import { Box, makeStyles, Typography } from "@alohi/kit";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { selectPlanExpirationDate } from "selectors/plan.selector";
import { useTranslation } from "react-i18next";
import { formatDefaultDate } from "helpers/date";

interface PlanRenewalDateProps {
  className?: string;
}

function PlanRenewalDate({ className }: PlanRenewalDateProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const planExpirationDate = useSelector(selectPlanExpirationDate);

  return (
    <Box className={clsx(classes.base, className)}>
      <Typography isBold>{t("PROFILE.PLAN_RENEWAL_DATE")}</Typography>
      <Typography className={classes.label}>
        {planExpirationDate ? formatDefaultDate(planExpirationDate) : "-"}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    [breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  label: {
    marginTop: spacing(0.5),
    [breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: spacing(0.5),
    },
  },
}));

export default memo(PlanRenewalDate);
