import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersMedical } from "@fortawesome/pro-solid-svg-icons";
import {
  Dispatch,
  Fragment,
  useCallback,
  HTMLAttributes,
  SyntheticEvent,
  SetStateAction,
} from "react";
import {
  TextField,
  Typography,
  makeStyles,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@alohi/kit";

import { AutocompleteRenderOptionState } from "ui";
import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import { useAppDispatch, useAppSelector } from "stores/store";
import { getGroups } from "stores/reducers/contacts.groups.reducer";
import { getSharedGroups } from "stores/reducers/contacts.sharedGroups.reducer";
import { selectGroupNames, selectSharedGroupNames } from "selectors/contacts.selector";
import AddOrEditGroupModal from "../AddOrEditGroupModal";

interface GroupAutoCompleteProps {
  isShared: boolean;
  hasActions: boolean;
  groupsState: string[];
  setGroupsState: Dispatch<SetStateAction<string[]>>;
}

function GroupAutoComplete({
  isShared,
  hasActions,
  groupsState,
  setGroupsState,
}: GroupAutoCompleteProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const groups = useAppSelector<string[]>(selectGroupNames);
  const sharedGroups = useAppSelector<string[]>(selectSharedGroupNames);

  const groupNames = isShared ? sharedGroups : groups;

  const [isAddModalOpen, isAddModalOpenBool] = useBool(false);
  const [currentValue, currentValueInput] = useInput<string>("");

  const options = [t("CONTACTS.ADD_NEW_GROUP"), ...groupNames];

  function handleCloseAddModal(option?: string | SyntheticEvent) {
    if (option && typeof option === "string") {
      setGroupsState((previousGroups) => [...previousGroups, option]);
    }

    isShared
      ? dispatch(getSharedGroups({ offset: 0, limit: 100 }))
      : dispatch(getGroups({ offset: 0, limit: 100 }));

    isAddModalOpenBool.setFalse();
  }

  const handleInputChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: string, reason: string) => {
      if (event && event.type === "blur") {
        currentValueInput.setValue("");
      } else if (reason === "reset") {
        currentValueInput.setValue("");
      } else if (reason === "clear") {
        currentValueInput.setValue("");
        setGroupsState([]);
      } else {
        currentValueInput.setValue(value);
      }
    },
    [currentValueInput, setGroupsState],
  );

  const handleOnChange = useCallback(
    (
      _,
      __,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<string> | undefined,
    ) => {
      const option = details?.option;

      if (!option) {
        return;
      }

      switch (reason) {
        case "removeOption":
          setGroupsState((previousGroups) => {
            const set = new Set(previousGroups);
            set.delete(option);
            return Array.from(set);
          });
          break;
        case "createOption":
          break;
        case "blur":
          break;
        case "clear":
          break;
        case "selectOption":
          if (option === t("CONTACTS.ADD_NEW_GROUP")) {
            isAddModalOpenBool.setTrue();
            break;
          }
          setGroupsState((previousGroups) => [...previousGroups, option]);
          break;
      }
    },
    [isAddModalOpenBool, setGroupsState, t],
  );

  const RenderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    option: string,
    state: AutocompleteRenderOptionState,
  ) => {
    if (state.index === 0) {
      return (
        <li key="-1" {...props} className={clsx(classes.addGroups, props.className)}>
          <Typography sx={{ "&&": { py: 1 } }}>
            <FontAwesomeIcon icon={faUsersMedical} className={classes.addIcon} />
            {option}
          </Typography>
        </li>
      );
    }

    return (
      <li {...props}>
        <Typography sx={{ "&&": { py: 1 } }}>{option}</Typography>
      </li>
    );
  };

  return (
    <Fragment>
      <Autocomplete
        freeSolo
        multiple
        disableListWrap
        options={options}
        value={groupsState}
        filterSelectedOptions
        disabled={!hasActions}
        inputValue={currentValue}
        onChange={handleOnChange}
        className={classes.select}
        renderOption={RenderOptions}
        onInputChange={handleInputChange}
        renderInput={(props) => (
          <TextField
            {...props}
            error={false}
            onBlur={currentValueInput.onBlur}
            onFocus={currentValueInput.onFocus}
            placeholder={t("CONTACTS.TYPE_A_GROUP")}
          />
        )}
      />

      {isAddModalOpen ? (
        <AddOrEditGroupModal isSharedGroup={isShared} handleClosure={handleCloseAddModal} />
      ) : null}
    </Fragment>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  select: {
    "&& .MuiOutlinedInput-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  addIcon: {
    marginRight: spacing(1),
    color: alohi.almostGray,
  },
  addGroups: {
    "&&": {
      border: "unset",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

export default GroupAutoComplete;
