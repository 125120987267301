import { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Typography } from "ui";

import { selectCdrSendTime } from "../../selectors/faxes.selector";

function FaxesColumnSendTime({ boxName, dataIndex }) {
  const date = useSelector(selectCdrSendTime(boxName, dataIndex));

  return date ? <Typography variant="body">{date}</Typography> : null;
}

FaxesColumnSendTime.propTypes = {
  boxName: PropTypes.string,
  dataIndex: PropTypes.number,
};

export default memo(FaxesColumnSendTime);
