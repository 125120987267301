import { Box } from "@alohi/kit";
import { CSSProperties } from "react";
import { components as Components, OptionProps } from "react-select";

import { Typography, makeStyles } from "ui";
import { PhoneNumberFilter } from "components/Core/Filters";
import { formatPhoneNumberIntl, parseNumber } from "helpers/numbers";
import { Destination, NewEntryLabel } from "views/SendFax/contexts/store";
import Icon from "./Icon";

type OptionsProps = OptionProps<Destination, true>;

function Options({ data, label, ...restOfProps }: OptionsProps) {
  const classes = useStyles();

  let content = null;

  switch (data.icon) {
    case "group":
    case "sharedGroup":
      content = (
        <Box className={classes.wrapper}>
          <Typography className={classes.mainLabel}>{data.label}</Typography>
          {data.caption?.length ? (
            <Typography className={classes.secondLabel}>{data.caption}</Typography>
          ) : null}
        </Box>
      );
      break;
    case "recent":
      const isNumberExtension = data.label.indexOf("*") !== -1;

      content = (
        <Box className={classes.wrapper}>
          <Typography className={classes.mainLabel}>
            {isNumberExtension ? data.label : <PhoneNumberFilter number={data.label} />}
          </Typography>
        </Box>
      );
      break;
    case "contact":
    case "sharedContact":
      content = (
        <Box className={classes.wrapper}>
          {/* If label and value are different it means that label is a contact name and not a number */}
          {data.label !== data.value ? (
            <Typography className={classes.mainLabel}>{data.label}</Typography>
          ) : (
            <Typography className={classes.mainLabel}>
              <PhoneNumberFilter number={data.value} />
            </Typography>
          )}
          {data.caption?.length ? (
            <Typography className={classes.secondLabel}>
              <PhoneNumberFilter number={data.caption} />
            </Typography>
          ) : null}
        </Box>
      );
      break;
    case "newEntry":
      const { number, extension } = parseNumber(data.label.number, data.label.geoLocation);
      const castedLabel = label as unknown as NewEntryLabel;

      if (number) {
        content = (
          <Typography className={classes.mainLabel}>{`${formatPhoneNumberIntl(
            number,
          )}${extension}`}</Typography>
        );
      } else if ("number" in castedLabel) {
        content = castedLabel.number;
      }
      break;
    default:
  }

  return (
    <div className={classes.option}>
      <Components.Option label={label} data={data} {...restOfProps}>
        <div style={inDestinationOptionStyles}>
          <div style={labelDestinationOptionStyles} data-cy={"sendFaxToDestinationOption"}>
            {content}
          </div>
          <Box mr={1} style={{ textAlign: "right" }}>
            <Icon icon={data.icon} />
          </Box>
        </div>
      </Components.Option>
    </div>
  );
}

const inDestinationOptionStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const labelDestinationOptionStyles: CSSProperties = {
  height: "34px",
  display: "flex",
  marginLeft: "10px",
  alignItems: "start",
  flexDirection: "column",
  justifyContent: "center",
};

const useStyles = makeStyles(({ alohi }) => ({
  wrapper: {
    width: 480,
  },
  mainLabel: {
    fontSize: 16,
    color: alohi.gray,
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  secondLabel: {
    fontSize: 13,
    overflow: "hidden",
    color: alohi.gray,
  },
  option: {
    color: alohi.gray,
    display: "flex",
    borderBottom: `1px solid ${alohi.lighterGray}`,
  },
}));

export default Options;
