import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";

import { selectCurrentCart } from "selectors/payment.selector";
import { getCurrentCredit } from "stores/reducers/credit.reducer";
import { updateCart } from "stores/reducers/payment.cart.reducer";
import { chargeAmount } from "stores/reducers/payment.charge.reducer";
import { selectMinAmountForCustomAmount, selectCurrentCredit } from "selectors/credit.selector";
import usePrevious from "./usePrevious";

function useCart(withCreditUpdate = false) {
  const dispatch = useDispatch();
  const currentCart = useSelector(selectCurrentCart);
  const currentCredit = useSelector(selectCurrentCredit);
  const minAmount = useSelector(selectMinAmountForCustomAmount);

  const cartId = useRef<string | null>(null);
  const localCartIds = useRef<string[]>([]);
  const [state, setState] = useState<{
    cartItems: unknown[] | null;
    creditNeeded: number | null;
    canChargeCart: boolean | null;
    cartTotalAmount: number | null;
  }>({
    cartItems: null,
    creditNeeded: null,
    canChargeCart: null,
    cartTotalAmount: null,
  });

  const prevTotalAmount = usePrevious(state.cartTotalAmount);

  const localCartHandlers = useMemo(
    () => ({
      addIdToCart: (item: string) => {
        // Push only unique ids to the cart
        if (!localCartIds.current.some((id) => id === item)) {
          localCartIds.current = [...localCartIds.current, item];
        }
      },
      updateCart: () => {
        // This can have two outcomes
        // 1. Can be charged whenever the user has sufficient credit (updateCart, will return a cartId)
        // 2. Used to display the total amount for the user
        dispatch(updateCart(localCartIds.current));
        // The last cartId can be used to charge
        // If we need to change/remove an id, we have to redo the whole array of ids
        localCartIds.current = [];
        cartId.current = null;
      },
      // isAsync true means that the cart will take some time to charge
      // Other endpoint is available to check the status of the last async charge cart
      chargeCart: (isAsync = false) => {
        if (cartId.current) {
          dispatch(chargeAmount({ cartId: cartId.current, isAsync }));
        }
      },
      clearCart: () => {
        cartId.current = null;
        localCartIds.current = [];
        setState({
          cartItems: null,
          creditNeeded: null,
          canChargeCart: null,
          cartTotalAmount: null,
        });
      },
    }),
    // It's important that this memoized object never updates
    [dispatch],
  );

  useEffect(() => {
    // If the cart (backend cart) is updated at line 9 update the id and total amount
    if (currentCart) {
      setState((prevState) => ({
        ...prevState,
        cartItems: currentCart.items,
        cartTotalAmount: currentCart.total_amount,
      }));
      cartId.current = currentCart.id;
    }
  }, [currentCart]);

  useEffect(() => {
    if (currentCart === null) {
      setState({
        cartItems: null,
        creditNeeded: null,
        canChargeCart: null,
        cartTotalAmount: null,
      });
    }
  }, [currentCart]);

  useEffect(() => {
    // If cartTotalAmount (backend total amount) is updated after a re-render
    // re-evaluate the need for adding credit
    if (prevTotalAmount !== state.cartTotalAmount) {
      const cartTotalAmount = state.cartTotalAmount ?? 0;
      const creditNeeded = +(cartTotalAmount - currentCredit).toFixed(2);

      setState((prevState) => ({
        ...prevState,
        canChargeCart: !(cartTotalAmount > currentCredit),
        creditNeeded: creditNeeded <= minAmount ? minAmount : creditNeeded,
      }));
      // Get current credit whenever totalAmount (backend cart) is changed
      // Ensure that we only subscribe once per context (this hook can be mounted multiple times in a single flow)
      // This is just a simple UI update to be sure that we have the latest credit
      // Not necessarily needed but good to have
      if (withCreditUpdate) {
        dispatch(getCurrentCredit());
      }
    }
  }, [
    dispatch,
    minAmount,
    currentCredit,
    prevTotalAmount,
    withCreditUpdate,
    state.cartTotalAmount,
  ]);

  return [state, localCartHandlers] as const;
}

export default useCart;
