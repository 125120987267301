import WebHooksPanel from "components/Profile/Integrations/WebHooksPanel";
import ApiPanel from "components/Profile/Integrations/ApiPanel";
import AppsPanel from "components/Profile/Integrations/AppsPanel";
import SlackPanel from "components/Profile/Integrations/SlackPanel";

function Integrations() {
  return (
    <>
      <ApiPanel />
      <WebHooksPanel />
      <SlackPanel />
      <AppsPanel />
    </>
  );
}

export default Integrations;
