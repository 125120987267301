import { createAction, createSlice } from "@reduxjs/toolkit";

import { boxNames } from "../../enums/faxes";
import syncHistoryEvents from "../../enums/syncHistory";
import { isCdrDateSameOrBefore, orderCdrByDateAsc } from "../../helpers/date";
import { logout } from "./authentication.reducer";
import { faxesAsyncActions, setAllCdrCheckbox, toggleCdrCheckbox } from "./faxes.reducer";

const defaultFetchOptions = {
  direction: "incoming",
  status: "",
  limit: 20,
  before: null,
};

const initialState = {
  records: null,
  hasMore: true,
  thumbnails: null,
  cdrIdOfLoading: null,
  currentFetchOptions: defaultFetchOptions,
};

export const clearInbox = createAction("INBOX/CLEAR");
export const syncInboxToAdd = createAction("INBOX/SYNC_INBOX_TO_ADD");
export const syncInboxToRestore = createAction("INBOX/SYNC_INBOX_TO_RESTORE");
export const syncInboxToDelete = createAction("INBOX/SYNC_INBOX_TO_DELETE");
export const syncInboxToTrash = createAction("INBOX/SYNC_INBOX_TO_TRASH");
export const syncInboxToRead = createAction("INBOX/SYNC_INBOX_TO_READ");
export const syncInboxToUnread = createAction("INBOX/SYNC_INBOX_TO_UNREAD");
export const syncInboxToModify = createAction("INBOX/SYNC_INBOX_TO_MODIFY");

const { reducer } = createSlice({
  name: "INBOX",
  initialState,
  extraReducers: {
    [clearInbox]: () => initialState,
    [toggleCdrCheckbox]: (state, { payload: { cdrId, boxName } }) => {
      if (boxName !== boxNames.inbox) return;
      for (let i = 0; i < state.records.length; i++) {
        if (state.records[i].id === cdrId) {
          state.records[i].isChecked = !state.records[i].isChecked;
          break;
        }
      }
    },
    [setAllCdrCheckbox]: (state, { payload: { value, boxName } }) => {
      if (boxName !== boxNames.inbox) return;
      state.records = state.records?.map((record) => ({ ...record, isChecked: value }));
    },
    [syncInboxToModify]: (state, { payload }) => {
      const {
        cdrIds,
        response: { result },
      } = payload;
      const cdrIdsSet = new Set(cdrIds);
      const recordsToModify = new Map();
      for (let i = 0; i < result.length; i++) {
        if (cdrIdsSet.has(result[i].id)) {
          recordsToModify.set(result[i].id, result[i]);
        }
      }
      state.records = state.records?.reduce((acc, curr) => {
        if (recordsToModify.has(curr.id)) {
          acc.push(recordsToModify.get(curr.id));
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
    },
    [syncInboxToAdd]: ({ records }, { payload }) => {
      if (!records) {
        return;
      }
      const {
        response: { result },
        boxData,
      } = payload;
      let cdrIdsSet = new Set();
      boxData[boxNames.inbox]["events"][syncHistoryEvents.ADD].forEach(cdrIdsSet.add, cdrIdsSet);
      const recordsToInsert = new Map();
      // Loop and construct map with results cdrs as key and the actual cdr as value
      // Only insert if the target number is the same as the store selectedNumber
      for (let i = 0; i < result.length; i++) {
        if (
          cdrIdsSet.has(result[i].id) &&
          (boxData[boxNames.inbox]["selectedNumber"] === result[i].to ||
            boxData[boxNames.inbox]["selectedNumber"] === "all")
        ) {
          recordsToInsert.set(result[i].id, result[i]);
        }
      }
      // Ensure that it doesn't exist in the records before adding
      for (let i = 0; i < records.length; i++) {
        if (recordsToInsert.has(records[i].id)) {
          recordsToInsert.delete(records[i].id);
        }
      }
      let recordsToInsertSorted = Array.from(recordsToInsert.values()).sort(orderCdrByDateAsc);
      let j = 0;
      let i = recordsToInsertSorted.length - 1;
      while (i >= 0) {
        if (
          records[j]?.date &&
          recordsToInsertSorted[i]?.date &&
          isCdrDateSameOrBefore(records[j].date, recordsToInsertSorted[i].date)
        ) {
          // Found spot to insert the new record, pop it from recordsToInsertSorted
          records.splice(j, 0, recordsToInsertSorted.pop());
          // One record was consumed, decrease the iterator (prev length - 1)
          i--;
        } else {
          if (j + 1 < records.length) {
            // No spot was found, move downwards in the initial records list to compare the date
            j++;
          } else {
            // Reach end of the list, only push the recordsToInsertSorted
            // if we know that the records doesn't have more results
            if (!boxData[boxNames.inbox].hasMore) {
              recordsToInsertSorted.reverse();
              records.push(...recordsToInsertSorted);
            }
            break;
          }
        }
      }
    },
    [syncInboxToRestore]: ({ records }, { payload }) => {
      if (!records) {
        return;
      }
      const {
        response: { result },
        boxData,
      } = payload;
      let cdrIdsSet = new Set();
      boxData[boxNames.inbox]["events"][syncHistoryEvents.RESTORE].forEach(
        cdrIdsSet.add,
        cdrIdsSet,
      );
      const recordsToInsert = new Map();
      // Loop and construct map with results cdrs as key and the actual cdr as value
      // Only insert if the target number is the same as the store selectedNumber
      for (let i = 0; i < result.length; i++) {
        if (
          cdrIdsSet.has(result[i].id) &&
          (boxData[boxNames.inbox]["selectedNumber"] === result[i].to ||
            boxData[boxNames.inbox]["selectedNumber"] === "all")
        ) {
          recordsToInsert.set(result[i].id, result[i]);
        }
      }
      // Ensure that it doesn't exist in the records before adding
      for (let i = 0; i < records.length; i++) {
        if (recordsToInsert.has(records[i].id)) {
          recordsToInsert.delete(records[i].id);
        }
      }
      let recordsToInsertSorted = Array.from(recordsToInsert.values()).sort(orderCdrByDateAsc);
      let j = 0;
      let i = recordsToInsertSorted.length - 1;
      while (i >= 0) {
        if (
          records[j]?.date &&
          recordsToInsertSorted[i]?.date &&
          isCdrDateSameOrBefore(records[j].date, recordsToInsertSorted[i].date)
        ) {
          // Found spot to insert the new record, pop it from recordsToInsertSorted
          records.splice(j, 0, recordsToInsertSorted.pop());
          // One record was consumed, decrease the iterator (prev length - 1)
          i--;
        } else {
          if (j + 1 < records.length) {
            // No spot was found, move downwards in the initial records list to compare the date
            j++;
          } else {
            // Reach end of the list, only push the recordsToInsertSorted
            // if we know that the records doesn't have more results
            if (!boxData[boxNames.inbox].hasMore) {
              recordsToInsertSorted.reverse();
              records.push(...recordsToInsertSorted);
            }
            break;
          }
        }
      }
    },
    [syncInboxToDelete]: (state, { payload }) => {
      const set = new Set(payload);
      state.records = state.records?.reduce((acc, curr) => {
        if (!set.has(curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);
    },
    [syncInboxToTrash]: (state, { payload }) => {
      const set = new Set(payload);
      state.records = state.records?.reduce((acc, curr) => {
        if (!set.has(curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);
    },
    [syncInboxToRead]: (state, { payload }) => {
      const set = new Set(payload);
      state.records = state.records?.reduce((acc, curr) => {
        if (set.has(curr.id)) {
          curr.is_read = true;
        }
        acc.push(curr);
        return acc;
      }, []);
    },
    [faxesAsyncActions.markAsRead.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      const set = new Set(payload.cdrIds);
      state.records = state.records?.reduce((acc, curr) => {
        if (set.has(curr.id)) {
          curr.is_read = true;
        }
        acc.push(curr);
        return acc;
      }, []);
    },
    [syncInboxToUnread]: (state, { payload }) => {
      const set = new Set(payload);
      state.records = state.records?.reduce((acc, curr) => {
        if (set.has(curr.id)) {
          curr.is_read = false;
        }
        acc.push(curr);
        return acc;
      }, []);
    },
    [faxesAsyncActions.markAsUnread.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      const set = new Set(payload.cdrIds);
      state.records = state.records?.reduce((acc, curr) => {
        if (set.has(curr.id)) {
          curr.is_read = false;
        }
        acc.push(curr);
        return acc;
      }, []);
    },
    [faxesAsyncActions.fetchBox.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      state.hasMore = payload.response.has_more;
      state.currentFetchOptions = payload.fetchOptions;
      state.records =
        payload.response.records?.map((record) => ({ ...record, isChecked: false })) || [];
    },
    [faxesAsyncActions.fetchBox.rejected]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.records = [];
      state.hasMore = false;
      state.currentFetchOptions = action.meta.arg.fetchOptions;
    },
    [faxesAsyncActions.fetchBoxMore.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      state.hasMore = payload.response.has_more;
      state.currentFetchOptions = payload.fetchOptions;
      state.records?.push(
        ...(payload.response?.records?.map((record) => ({ ...record, isChecked: false })) || []),
      );
    },
    [faxesAsyncActions.fetchThumbnails.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      if (payload.response) {
        state.thumbnails = { ...state.thumbnails, ...payload.response };
      }
    },
    [faxesAsyncActions.moveToTrash.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      const cdrIdsSet = new Set(payload.cdrIds);
      state.records = state.records.filter((cdr) => {
        return !cdrIdsSet.has(cdr.id);
      });
    },
    [faxesAsyncActions.addNoteOnFax.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.inbox) {
        return;
      }
      for (let i = 0; i < state.records.length; i++) {
        if (state.records[i].id === payload.cdrId) {
          state.records[i].comment.text = payload.note;
          break;
        }
      }
    },
    [faxesAsyncActions.previewFile.pending]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.cdrIdOfLoading = action.meta.arg.cdrId;
    },
    [faxesAsyncActions.previewFile.fulfilled]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.cdrIdOfLoading = null;
    },
    [faxesAsyncActions.previewFile.rejected]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.cdrIdOfLoading = null;
    },
    [faxesAsyncActions.download.pending]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.cdrIdOfLoading = action.meta.arg.cdrId;
    },
    [faxesAsyncActions.download.fulfilled]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.cdrIdOfLoading = null;
    },
    [faxesAsyncActions.download.rejected]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.inbox) return;
      state.cdrIdOfLoading = null;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
