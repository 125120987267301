import { useEffect } from "react";
import { Modal } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import ViewPdf from "../components/ViewPdf/ViewPdf";
import useDownloadCoverSheet from "../hooks/useDownloadCoverSheet";

interface ViewCoverSheetModalProps {
  id: string;
  name: string;
  handleClosure: () => void;
  disableAnimation?: boolean;
}

function ViewCoverSheetModal({
  id,
  name,
  handleClosure,
  disableAnimation,
}: ViewCoverSheetModalProps) {
  const { t } = useTranslation();

  const { file, downloadById } = useDownloadCoverSheet();

  useEffect(() => {
    downloadById(id, false);
  }, [downloadById, id]);

  return (
    <Modal
      scroll="paper"
      disableRestoreFocus
      onConfirm={handleClosure}
      onPressEscape={handleClosure}
      title={t("COVER_SHEET.TITLE_AND_NAME", {
        value_1: name,
      })}
      confirmTitle={t("COMMON.CLOSE")}
      disableAnimation={disableAnimation}
    >
      <ViewPdf file={file} />
    </Modal>
  );
}

export default ViewCoverSheetModal;
