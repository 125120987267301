import { useCallback } from "react";
import { useBool } from "@alohi/kit";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { alohiAdminUrls } from "routes/urls";
import { profileRoutes } from "enums/profile";
import RedeemModal from "views/Redeem/RedeemModal";
import ChangePlan from "views/ChangePlan/ChangePlan";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import useAdminRedirections, { AdminRedirectionParams } from "hooks/useAdminRedirections";
import { selectIsPlanInactive } from "selectors/plan.selector";
import FreeCreditModal from "views/FreeCredit/FreeCreditModal";
import InactivePlanModal from "views/InactivePlan/InactivePlanModal";
import { selectIsEmailVerificationRequired } from "selectors/ability.selector";
import UpgradeEnterpriseModal from "views/UpgradeEnterprise/UpgradeEnterpriseModal";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import ManagePaymentMethodModal from "views/ManagePaymentMethod/ManagePaymentMethodModal";
import {
  selectAccountIsCorporateMember,
  selectAccountIsInactive,
  selectEmailIsUnverified,
} from "selectors/account.selector";

function PlanBillingHandleQueryString() {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();
  const { direct_open_modal, ["plan-type"]: planType, period } = queryString.parse(search);

  const isPlanInactive = useSelector(selectIsPlanInactive);
  const accountIsInactive = useSelector(selectAccountIsInactive);
  const emailIsUnverified = useSelector(selectEmailIsUnverified);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const isEmailVerificationRequired = useSelector(selectIsEmailVerificationRequired);

  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);
  const [isUpgradePlanOpen, isUpgradePlanOpenBool] = useBool(false);

  const closeCurrentModal = useCallback(() => {
    history.replace(`/profile/${profileRoutes.planBilling}`);
  }, [history]);

  const handleOpenChangePlan = useCallback(
    (isHighVolume: boolean) => {
      const url = new URL(window.location.href);
      url.search = ""; // This removes all query parameters
      window.history.replaceState({}, "", url);

      const redirectToAdminObject: AdminRedirectionParams = {
        adminUrl: alohiAdminUrls.fax.upgrade,
        fallbackAction: isChangePlanOpenBool.setTrue,
      };

      if (isHighVolume) {
        redirectToAdminObject["adminUrl"] = alohiAdminUrls.fax.highVolume;
        redirectToAdminObject["fallbackAction"] = isUpgradePlanOpenBool.setTrue;
      }

      redirectToAdminObject["adminParams"] = {};
      if (typeof planType === "string") {
        redirectToAdminObject["adminParams"]["plan-type"] = planType;
        if (typeof period === "string") {
          redirectToAdminObject["adminParams"].period = period;
        }
      }

      redirectToAdmin(redirectToAdminObject);
    },
    [
      isChangePlanOpenBool.setTrue,
      planType,
      redirectToAdmin,
      isUpgradePlanOpenBool.setTrue,
      period,
    ],
  );

  if (isCorporateMember && direct_open_modal) {
    return (
      <ContactAdminToUseFeature
        title={t("PROFILE.PLAN_BILLING")}
        description={(() => {
          switch (direct_open_modal) {
            case "change_plan":
              return t("PROFILE.TO_CHANGE_PLAN_PLEASE_CONTACT_YOUR_ADMIN");
            case "credit":
              return t("PROFILE.TO_ADD_CREDIT_PLEASE_CONTACT_YOUR_ADMIN");
            case "redeem":
              return t("PROFILE.TO_REDEEM_CODE_PLEASE_CONTACT_YOUR_ADMIN");
            case "payment_method":
              return t("PROFILE.TO_CHANGE_PLAN_PLEASE_CONTACT_YOUR_ADMIN");
            case "referral":
              return t("PROFILE.TO_REFER_PLEASE_CONTACT_YOUR_ADMIN");
            default:
              return t("PROFILE.PLEASE_CONTACT_YOUR_ADMIN");
          }
        })()}
        handleClosure={closeCurrentModal}
      />
    );
  }

  if ((emailIsUnverified && isEmailVerificationRequired) || accountIsInactive) {
    return null;
  }

  switch (direct_open_modal) {
    case "change_plan":
      if (isChangePlanOpen) {
        return <ChangePlan handleClosure={closeCurrentModal} />;
      }

      handleOpenChangePlan(false);
      return null;
    case "credit":
      return <AddCreditModal handleClosure={closeCurrentModal} />;
    case "redeem":
      return <RedeemModal handleClosure={closeCurrentModal} />;
    case "payment_method":
      return isPlanInactive ? (
        <InactivePlanModal handleClosure={closeCurrentModal} />
      ) : (
        <ManagePaymentMethodModal handleClosure={closeCurrentModal} />
      );
    case "referral":
      return <FreeCreditModal handleClosure={closeCurrentModal} />;
    case "high_volume_faxing":
      if (isUpgradePlanOpen) {
        return <UpgradeEnterpriseModal handleClosure={closeCurrentModal} />;
      }
      handleOpenChangePlan(true);
      return null;

    default:
      return null;
  }
}

export default PlanBillingHandleQueryString;
