import PropTypes from "prop-types";
import { Box, Modal } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { boxNames } from "enums/faxes";
import { FaxStatus } from "components/Faxes/FaxStatus";
import { selectCdrById } from "selectors/faxes.selector";

function FaxStatusModal({ handleClosure, cdrId, boxName }) {
  const { t } = useTranslation();

  const cdr = useSelector(selectCdrById(boxName, cdrId));

  return (
    <Modal
      maxWidth={"xxs"}
      onConfirm={handleClosure}
      onEnterPress={handleClosure}
      title={t("FAXES.FAX_STATUS")}
      confirmTitle={t("COMMON.CLOSE")}
    >
      <Box p={3}>
        <FaxStatus cdr={cdr} />
      </Box>
    </Modal>
  );
}

FaxStatusModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  cdrId: PropTypes.string.isRequired,
  boxName: PropTypes.oneOf(Object.values(boxNames)),
};

export default FaxStatusModal;
