import { Box, Card, Container, FaButton, Typography, useSnackbar } from "@alohi/kit";
import {
  faCheckCircle,
  faSitemap,
  faTimesCircle,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";

import UsersHierarchyTree from "components/Users/UsersHierarchyTree/UsersHierarchyTree";
import { useDelayedRender } from "hooks/useDelayedRender";
import useTitle from "hooks/useTitle";
import { filterQueryParams } from "routes/urls";
import { selectCanISeeUsersTab } from "selectors/ability.selector";
import {
  selectIsRemoveAsAdminSuccess,
  selectIsSetAsAdminSuccess,
  selectRemoveAsAdminError,
  selectSetAsAdminError,
} from "selectors/account.permissions.selector";
import {
  selectIsEnableUserSuccess,
  selectUpdateHierarchyRequestError,
  selectUpdateHierarchyRequestSuccess,
  selectUsersHierarchyTree,
} from "selectors/corporate.selector";
import { clearRemoveAsAdmin, clearSetAsAdmin } from "stores/reducers/account.permissions.reducer";
import {
  CLEAR_UPDATE_HIERARCHY_REQUEST,
  clearEnableUser,
  corporateAsyncActions,
} from "stores/reducers/corporate.reducer";
import InviteUsersModal from "./InviteUsersModal";

function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reroutedQueryParams = filterQueryParams({
    filter: ["_gl"],
    currentParams: queryParams,
  }).toString();

  useTitle(t("USERS.TITLE"));

  const canISeeUsersTab = useSelector(selectCanISeeUsersTab);
  const corporateUsersTreeData = useSelector(selectUsersHierarchyTree);
  const updateHierarchyRequestSuccess = useSelector(selectUpdateHierarchyRequestSuccess);
  const updateHierarchyRequestError = useSelector(selectUpdateHierarchyRequestError);

  const setAsAdminError = useSelector(selectSetAsAdminError);
  const removeAsAdminError = useSelector(selectRemoveAsAdminError);
  const isEnableUserSuccess = useSelector(selectIsEnableUserSuccess);
  const isSetAsAdminSuccess = useSelector(selectIsSetAsAdminSuccess);
  const isRemoveAsAdminSuccess = useSelector(selectIsRemoveAsAdminSuccess);

  const [isEditMode, setIsEditMode] = useState(false);
  const [treeData, setTreeData] = useState(corporateUsersTreeData);
  const [inviteUsersModal, setInviteUsersModal] = useState({ isOpen: false });

  const handleSave = () => {
    setIsEditMode(false);
    dispatch(corporateAsyncActions.updateHierarchy({ hierarchyTreeData: treeData }));
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setTreeData(corporateUsersTreeData);
  };

  const handleMouseClickEdit = () => {
    setIsEditMode(true);
  };

  const handleTouchEdit = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    if (!corporateUsersTreeData) {
      dispatch(corporateAsyncActions.getHierarchy());
    }
  }, [dispatch, corporateUsersTreeData]);

  useEffect(() => {
    if (isEnableUserSuccess) {
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(clearEnableUser());
    }
  }, [dispatch, isEnableUserSuccess]);

  useEffect(() => {
    if (isSetAsAdminSuccess) {
      enqueueSnackbar(t("USERS.SET_ADMIN_SUCCESS"), { variant: "success" });
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(clearSetAsAdmin());
    }
  }, [dispatch, enqueueSnackbar, isSetAsAdminSuccess, t]);

  useEffect(() => {
    if (!!setAsAdminError) {
      enqueueSnackbar(setAsAdminError, { variant: "error" });
      dispatch(clearSetAsAdmin());
    }
  }, [dispatch, enqueueSnackbar, setAsAdminError]);

  useEffect(() => {
    if (isRemoveAsAdminSuccess) {
      enqueueSnackbar(t("USERS.REMOVE_ADMIN_SUCCESS"), { variant: "success" });
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(clearRemoveAsAdmin());
    }
  }, [t, dispatch, enqueueSnackbar, isRemoveAsAdminSuccess]);

  useEffect(() => {
    if (!!removeAsAdminError) {
      enqueueSnackbar(removeAsAdminError, { variant: "error" });
      dispatch(clearRemoveAsAdmin());
    }
  }, [dispatch, enqueueSnackbar, removeAsAdminError]);

  useEffect(() => {
    if (corporateUsersTreeData) {
      setTreeData(corporateUsersTreeData);
    }
  }, [corporateUsersTreeData]);

  useEffect(() => {
    if (updateHierarchyRequestSuccess) {
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      enqueueSnackbar(t("USERS.UPDATE_HIERARCHY_SUCCESS"), {
        variant: "success",
      });
      dispatch(CLEAR_UPDATE_HIERARCHY_REQUEST());
    }
  }, [updateHierarchyRequestSuccess, enqueueSnackbar, t, dispatch]);

  useEffect(() => {
    if (updateHierarchyRequestError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(CLEAR_UPDATE_HIERARCHY_REQUEST());
    }
  }, [updateHierarchyRequestError, enqueueSnackbar, t, dispatch]);

  const delayedLoading = useDelayedRender(
    <Typography type="subtitle">{t("COMMON.LOADING")}</Typography>,
  );

  if (!canISeeUsersTab) {
    return <Redirect to={{ pathname: `/`, search: reroutedQueryParams }} />;
  }

  return (
    <>
      <Container maxWidth="sm">
        <Card>
          <Card.Header>
            <Typography type="cardFrontHeader">{`${t("USERS.TITLE")} - ${t(
              "USERS.HIERARCHY",
            )}`}</Typography>
            {isEditMode ? (
              <Box display={"flex"}>
                <FaButton
                  variant={"light"}
                  icon={faTimesCircle}
                  onMouseDown={handleCancel}
                  onTouchStart={handleCancel}
                  onMouseUp={(e) => e.preventDefault()}
                  onTouchEnd={(e) => e.preventDefault()}
                  tooltip={t("COMMON.CANCEL")}
                />
                <Box mr={1} />
                <FaButton
                  variant={"light"}
                  tooltip={t("COMMON.SAVE")}
                  icon={faCheckCircle}
                  onClick={handleSave}
                />
              </Box>
            ) : (
              <Box display={"flex"}>
                <FaButton
                  icon={faSitemap}
                  variant={"light"}
                  onTouchStart={handleTouchEdit}
                  onMouseDown={handleMouseClickEdit}
                  tooltip={t("USERS.EDIT_HIERARCHY")}
                  onMouseUp={(e) => e.preventDefault()}
                  onTouchEnd={(e) => e.preventDefault()}
                />
                <Box mr={1} />
                <FaButton
                  variant={"light"}
                  icon={faUserPlus}
                  tooltip={t("USERS.INVITE_NEW_USER")}
                  onClick={() => setInviteUsersModal({ isOpen: true })}
                />
              </Box>
            )}
          </Card.Header>

          <Card.Content
            variant="border"
            sx={{ p: 3, pl: 1 }}
            {...(isEditMode && {
              onMouseUp: (e) => e.preventDefault(),
              onTouchEnd: (e) => e.preventDefault(),
              onTouchStart: (e) => e.preventDefault(),
            })}
          >
            {!treeData ? (
              delayedLoading
            ) : (
              <UsersHierarchyTree
                treeData={treeData}
                isEditMode={isEditMode}
                onChange={(data) => setTreeData(data)}
              />
            )}
          </Card.Content>
        </Card>
      </Container>

      {inviteUsersModal.isOpen && (
        <InviteUsersModal handleClosure={() => setInviteUsersModal({ isOpen: false })} />
      )}
    </>
  );
}

export default Users;
