import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Modal, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { useAppSelector } from "stores/store";
import { isValidName } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { actions, selectors, thunks } from "stores/reducers/coverSheet.reducer";

interface UpdateCoverSheetModalProps {
  id: string;
  currentName: string;
  handleClosure: (updatedName: string | null) => void;
  disableAnimation?: boolean;
}

function UpdateCoverSheetModal({
  id,
  currentName,
  handleClosure,
  disableAnimation,
}: UpdateCoverSheetModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const updateType = useAppSelector(selectors.updateType);
  const [name, nameInput] = useInput(currentName, isValidName);
  const isUpdateError = useAppSelector(selectors.isUpdateError);
  const isUpdateRunning = useAppSelector(selectors.isUpdateRunning);

  const handleConfirm = () => {
    nameInput.touch();

    if (nameInput.isValid) {
      dispatch(
        thunks.update({
          id,
          type: "name",
          data: { name: nameInput.ref.current },
        }),
      );
    }
  };

  const handleCancel = () => {
    handleClosure(null);
  };

  useEffect(() => {
    if (updateType === "name") {
      enqueueSnackbar(t("COVER_SHEET.RENAMED_SUCCESS"), {
        variant: "success",
      });

      dispatch(actions.clearUpdate());

      handleClosure(null);
    }
  }, [t, dispatch, enqueueSnackbar, handleClosure, updateType]);

  useEffect(() => {
    if (isUpdateError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });

      dispatch(actions.clearUpdate());
    }
  }, [dispatch, enqueueSnackbar, isUpdateError, t]);

  return (
    <Modal
      maxWidth="sm"
      hasCloseIcon={false}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title="Rename Cover Sheet"
      onPressEnter={handleConfirm}
      onPressEscape={handleCancel}
      isModalDisabled={isUpdateRunning}
      isConfirmLoading={isUpdateRunning}
      disableAnimation={disableAnimation}
      isConfirmDisabled={nameInput.showsError}
    >
      <Box py={2} px={3}>
        <FormRowTextInput
          id="add-name"
          value={name}
          labelGridWidth={12}
          onBlur={nameInput.onBlur}
          onFocus={nameInput.onFocus}
          error={nameInput.showsError}
          onChange={nameInput.onChange}
          inputProps={{ maxLength: 50 }}
        />
      </Box>
    </Modal>
  );
}

export default UpdateCoverSheetModal;
