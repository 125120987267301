import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { selectAccountEmail } from "selectors/account.selector";
import { getCustomerInfo } from "stores/reducers/account.reducer";
import { activateCorporateTwoFa, clearActivateCorporateTwoFa } from "stores/reducers/twoFa.reducer";
import { isValidPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "helpers/inputValidation";
import {
  selectIsEnforceTwoFaLoading,
  selectIsActivateCorporateTwoFaError,
  selectIsActivateCorporateTwoFaSuccess,
  selectActivateCorporateTwoFaErrorInvalidPassword,
  selectActivateCorporateTwoFaErrorShouldBeEnabled,
} from "selectors/authentication.selector";

function ActivateCorporateTwoFaModal({ handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const accountEmail = useSelector(selectAccountEmail);
  const isLoading = useSelector(selectIsEnforceTwoFaLoading);
  const error = useSelector(selectIsActivateCorporateTwoFaError);
  const success = useSelector(selectIsActivateCorporateTwoFaSuccess);
  const errorInvalidPassword = useSelector(selectActivateCorporateTwoFaErrorInvalidPassword);
  const errorShouldBeEnabled = useSelector(selectActivateCorporateTwoFaErrorShouldBeEnabled);

  const [password, passwordInput] = useInput("", isValidPassword);

  const handleOnConfirm = useCallback(() => {
    passwordInput.touch();

    if (passwordInput.isValid) {
      dispatch(
        activateCorporateTwoFa({
          password: password,
          username: accountEmail,
        }),
      );
    }
  }, [accountEmail, dispatch, password, passwordInput]);

  useEffect(() => {
    if (error) {
      if (errorInvalidPassword) {
        enqueueSnackbar(t("FORMS.INVALID_PASSWORD"), { variant: "error" });
        passwordInput.setShowsError(true);
      } else if (errorShouldBeEnabled) {
        enqueueSnackbar(t("PROFILE.2FA_SHOULD_BE_ENABLE"), {
          variant: "error",
        });
        handleClosure();
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
      dispatch(clearActivateCorporateTwoFa());
    }
  }, [
    t,
    error,
    dispatch,
    passwordInput,
    handleClosure,
    enqueueSnackbar,
    errorInvalidPassword,
    errorShouldBeEnabled,
  ]);

  useEffect(() => {
    if (success) {
      dispatch(getCustomerInfo());
      dispatch(clearActivateCorporateTwoFa());
      handleClosure();
    }
  }, [handleClosure, dispatch, success]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleOnConfirm}
      isConfirmLoading={isLoading}
      title={t("PROFILE.ENFORCE2FA_TITLE")}
      isConfirmDisabled={passwordInput.showsError || isLoading}
    >
      <Box p={3}>
        <Typography type="body">{t("PROFILE.2FA_ACTIVATE_ENFORCE_2FA_DESCRIPTION")}</Typography>
        <Box my={2}>
          <TextField
            fullWidth
            id="password"
            type="password"
            value={password}
            onBlur={passwordInput.onBlur}
            onFocus={passwordInput.onFocus}
            error={passwordInput.showsError}
            placeholder={t("FORMS.PASSWORD")}
            onChange={passwordInput.onChange}
            helperText={
              passwordInput.showsError && !passwordInput.isValid
                ? t("FORMS.PASSWORD_LENGTH_RANGE", {
                    min: MIN_PASSWORD_LENGTH,
                    max: MAX_PASSWORD_LENGTH,
                  })
                : " "
            }
          />
        </Box>
      </Box>
    </Modal>
  );
}

ActivateCorporateTwoFaModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ActivateCorporateTwoFaModal;
