import { createSlice } from "@reduxjs/toolkit";

import { logout } from "./authentication.reducer";
import { initialWebHooksState, webHooksSlice } from "./integrations.webHooks.reducer";

const initialState = {
  ...initialWebHooksState,
};

const { reducer } = createSlice({
  name: "INTEGRATIONS",
  initialState,
  extraReducers: {
    ...webHooksSlice,
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
