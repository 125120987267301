import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { DatePicker, STATUS_BOX_ENUM, Box, StatusBox } from "@alohi/kit";

import dayjs from "helpers/date";
import FormRow from "components/Forms/FormRow";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { selectUserAddressRequirements } from "selectors/address.selector";
import AddressesSelect from "views/CreateAddressBundle/components/BundlePersonalInformation/components/AddressesSelect";
import { CreateAddressBundleContext } from "views/CreateAddressBundle/context/createAddressBundle.context";
import AcceptedValuesSelect from "./components/AcceptedValuesSelect";

const COUNTRY_ISO = "GB";

function BundlePersonalInformation() {
  const { t } = useTranslation();
  const [{ country, userType, userData }, localDispatch] = useContext(CreateAddressBundleContext);

  const userFields = useSelector(selectUserAddressRequirements(userType));

  const handleChangeField = useCallback(
    (name) =>
      ({ target }) => {
        localDispatch({
          type: "UPDATE_USER_DATA",
          payload: {
            [name]: target.value,
          },
        });
      },
    [localDispatch],
  );

  const handleUpdateAddressField = useCallback(
    (name) => (address) => {
      if (address) {
        localDispatch({
          type: "UPDATE_USER_DATA",
          payload: {
            [name]: address,
          },
        });
      }
    },
    [localDispatch],
  );

  useEffect(() => {
    // Init if there is a response from server with the fields
    // Don't init if local store is not null
    if (Boolean(userFields?.length) && !Boolean(userData?.[userType])) {
      // Init every field with the local address store
      const fields = userFields.reduce((accumulator, { inputId }) => {
        accumulator[inputId] = "";
        return accumulator;
      }, {});

      // Update the store
      localDispatch({
        type: "UPDATE_USER_DATA",
        payload: fields,
      });
    }
  }, [userFields, localDispatch, userData, userType]);

  const fields = useMemo(() => {
    // Both vars have the same length
    // This node will be re-rendered on each text change on any of its inputs
    if (userFields && userData?.[userType]) {
      return userFields.map(({ inputId, friendlyName, acceptedValues }, index) => {
        if (acceptedValues) {
          return (
            <AcceptedValuesSelect
              key={inputId}
              labelGridWidth={4}
              label={friendlyName}
              onSelectValue={handleUpdateAddressField(inputId)}
              value={userData[userType][userFields[index].inputId]}
              acceptedValues={acceptedValues}
            />
          );
        }
        if (inputId === "address_sid") {
          return (
            <AddressesSelect
              key={inputId}
              country={country}
              labelGridWidth={4}
              label={friendlyName}
              handleClosure={handleUpdateAddressField(inputId)}
              value={userData[userType][userFields[index].inputId]}
            />
          );
        }
        if (inputId === "birth_date") {
          return (
            <FormRow htmlFor="birth-date" label={friendlyName} key={inputId}>
              <DatePicker
                isFullWidth
                openTo="year"
                maxDate={dayjs()}
                format="YYYY-MM-DD"
                value={dayjs(userData[userType][userFields[index].inputId]) || null}
                onChange={(date) =>
                  handleChangeField(inputId)({
                    target: { value: dayjs(date).format("YYYY-MM-DD") },
                  })
                }
              />
            </FormRow>
          );
        }
        return (
          <FormRowTextInput
            key={inputId}
            labelGridWidth={4}
            label={friendlyName}
            id={`${inputId}-input`}
            onChange={handleChangeField(inputId)}
            value={userData[userType][userFields[index].inputId]}
            // Workaround to disable autoComplete on Chrome
            // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
            type="email"
          />
        );
      });
    }
    return null;
  }, [userFields, userData, userType, handleChangeField, country, handleUpdateAddressField]);

  return (
    <>
      <Box p={2} pr="10%">
        {fields}
      </Box>
      {country === COUNTRY_ISO ? (
        <StatusBox animation={{ skip: true }} mx={4} mb={4} type={STATUS_BOX_ENUM.WARNING}>
          {t("INFOBANNER.PHONE_WARNING")}
        </StatusBox>
      ) : null}
    </>
  );
}

export default BundlePersonalInformation;
