import { Box, Typography, makeStyles } from "@alohi/kit";

import { useAppSelector } from "stores/store";

function PageTitle() {
  const classes = useStyles();

  const title = useAppSelector((state) => state.layout.title);

  return (
    <Box className={classes.title}>
      <Typography type="appBarTitle">{title}</Typography>
    </Box>
  );
}

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

export default PageTitle;
