import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles, Modal, Box, Tooltip as KitTooltip } from "@alohi/kit";

import { Typography } from "ui";
import { entries } from "helpers/types";
import { formatPhoneNumberIntl } from "helpers/numbers";
import { useAppDispatch, useAppSelector } from "stores/store";
import { selectPurchasedNumbers } from "selectors/payment.selector";
import AnimatedSuccessIcon from "components/Animations/AnimatedSuccessIcon";
import { clearPurchasedNumbers } from "stores/reducers/payment.charge.reducer";

interface PurchasedNumbersSuccessProps {
  title: string;
  description: string;
  handleClosure: () => void;
}

function PurchasedNumbersSuccess({
  title,
  description,
  handleClosure,
}: PurchasedNumbersSuccessProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const purchasedNumbers = useAppSelector<null | Record<string, string>>(selectPurchasedNumbers);

  const showActualPurchasedNumbers = useMemo(() => {
    if (!purchasedNumbers) {
      return null;
    }

    let length = 0;

    const numbersMap = entries(purchasedNumbers)
      .map((currentNumber) => {
        length++;

        return {
          purchased: currentNumber[0],
          alreadyTaken: currentNumber[1],
        };
      })
      .slice(length - 2);

    const differentNumbers = numbersMap.filter(
      (number) => number.purchased !== number.alreadyTaken,
    );

    if (differentNumbers.length === 0) {
      return null;
    }

    const tooltip = (
      <Box>
        <Box>
          {t("NUMBERS.NUMBER_WAS_TAKEN", {
            count: differentNumbers.length,
            number_1: formatPhoneNumberIntl(differentNumbers[0].alreadyTaken),
            number_2: formatPhoneNumberIntl(differentNumbers[1]?.alreadyTaken ?? ""),
          })}
        </Box>
        <Box>{t("NUMBERS.WE_ASSIGNED_YOU", { count: differentNumbers.length })}</Box>
      </Box>
    );

    const numbers = (
      <Trans
        i18nKey="NUMBERS.YOUR_NEW_FAX_NUMBER"
        values={{
          count: length,
          number_1: formatPhoneNumberIntl(numbersMap[0].purchased),
          number_2: formatPhoneNumberIntl(numbersMap[1]?.purchased ?? ""),
        }}
        components={{
          strong: <strong />,
        }}
      />
    );

    return (
      <Box className={classes.warning}>
        <KitTooltip title={tooltip} placement="left">
          <FontAwesomeIcon className={classes.warningIcon} icon={faTriangleExclamation} />
        </KitTooltip>
        <Typography variant="subtitle3">{numbers}</Typography>
      </Box>
    );
  }, [t, purchasedNumbers, classes.warning, classes.warningIcon]);

  useEffect(() => {
    return () => {
      dispatch(clearPurchasedNumbers());
    };
  }, [dispatch]);

  return (
    <Modal title={title} confirmTitle={t("COMMON.DONE")} onConfirm={() => handleClosure()}>
      <Box className={classes.wrapper} p={4}>
        <Box my={2}>
          <AnimatedSuccessIcon />
        </Box>
        <Box textAlign="center">
          <Typography variant="h4">{t("COMMON.THANKS")}</Typography>
          <Box mt={2}>
            <Typography variant="subtitle3">{description}</Typography>
          </Box>
        </Box>

        {showActualPurchasedNumbers ? (
          <Box textAlign="center">{showActualPurchasedNumbers}</Box>
        ) : null}
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "start",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    fontSize: "6rem",
    color: alohi.green,
    marginBottom: spacing(3),
  },
  warning: {
    display: "flex",
    alignItems: "center",
    marginTop: spacing(1),
  },
  warningIcon: {
    color: alohi.lightGray,
    marginRight: spacing(1),
  },
}));

export default PurchasedNumbersSuccess;
