import { BaseButton, Box, Menu, MenuItem } from "@alohi/kit";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PhoneNumberFilter } from "components/Core/Filters";
import {
  selectAccountIsCorporateAdmin,
  selectAccountIsCorporateMember,
  selectAccountIsCorporateOwner,
} from "selectors/account.selector";
import { selectAssignedNumbers } from "selectors/numbers.selector";
import { selectIsPlanFree } from "selectors/plan.selector";
import { SET_SELECTED_NUMBER } from "stores/reducers/faxes.reducer";
import { Typography, makeStyles } from "ui";
import { useSendFaxContext } from "views/SendFax/contexts/context";

function From() {
  const classes = useStyles();
  const { t } = useTranslation();

  const { sendFaxDispatch } = useSendFaxContext();

  const dispatch = useDispatch();
  const isPlanFree = useSelector(selectIsPlanFree);
  const assignedNumbers = useSelector(selectAssignedNumbers);
  const isCorporateAdmin = useSelector(selectAccountIsCorporateAdmin);
  const isCorporateOwner = useSelector(selectAccountIsCorporateOwner);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(
    localStorage.getItem("archive_selected_number"),
  );
  const hasMultipleNumbers = assignedNumbers?.length > 1;

  const setStoreSelectedNumber = useCallback(
    (number) => dispatch(SET_SELECTED_NUMBER(number)),
    [dispatch],
  );

  const handleClickListItem = useCallback(
    (event) => {
      if (hasMultipleNumbers) {
        setAnchorEl(event.currentTarget);
      }
    },
    [hasMultipleNumbers],
  );

  const handleMenuItemClick = useCallback(
    (_, number) => {
      setSelectedNumber(number);
      setStoreSelectedNumber(number);
      localStorage.setItem("archive_selected_number", number);
      setAnchorEl(null);
    },
    [setStoreSelectedNumber],
  );

  useEffect(() => {
    // selectedNumber, can be all or a phone number (string)
    if (assignedNumbers?.length) {
      if (isPlanFree) {
        /* @TODO isPlanFree is added here temporary */
        const freeNumberObject = assignedNumbers.find(({ number }) => number?.includes("number-"));
        if (freeNumberObject) {
          sendFaxDispatch({
            type: "SET_FROM",
            payload: freeNumberObject.number,
          });
        }
        return;
      }

      const isOwnerOfNumber = assignedNumbers.some(({ number }) => number === selectedNumber);

      if (selectedNumber && selectedNumber !== "all" && isOwnerOfNumber) {
        sendFaxDispatch({
          type: "SET_FROM",
          payload: selectedNumber,
        });
      } else {
        // Most cases will end up here, (free users, inactive users)
        setSelectedNumber(assignedNumbers[0]?.number);
      }
    } else if (isCorporateMember) {
      // Some corporate members without a number assigned to them will have an empty array of ownedNumbers.
      // use corporate-member as a 'number'
      sendFaxDispatch({
        type: "SET_FROM",
        payload: "corporate-member",
      });
    } else if (isCorporateAdmin || isCorporateOwner) {
      // Some corporate admins without a number assigned to them will have an empty array of ownedNumbers.
      // use corporate-admin as a 'number'
      sendFaxDispatch({
        type: "SET_FROM",
        payload: "corporate-admin",
      });
    }
  }, [
    isPlanFree,
    selectedNumber,
    assignedNumbers,
    sendFaxDispatch,
    isCorporateAdmin,
    isCorporateOwner,
    isCorporateMember,
  ]);

  return (
    <Box className={classes.wrapper}>
      <Box mr={1} py={1} className={classNames(classes.notSelectable, classes.bold)}>
        {t("COMMON.SEND_FAX")}
      </Box>

      {selectedNumber &&
      selectedNumber !== "all" &&
      !selectedNumber.includes("number-") &&
      !isPlanFree ? (
        <>
          <Box className={classes.notSelectable}>|</Box>
          <BaseButton color="inherit" onClick={handleClickListItem} className={classes.selectable}>
            <PhoneNumberFilter number={selectedNumber} />
            {hasMultipleNumbers ? (
              <FontAwesomeIcon
                size="lg"
                icon={faAngleDown}
                className={classes.icon}
                onClick={handleClickListItem}
              />
            ) : null}
          </BaseButton>
        </>
      ) : null}

      {hasMultipleNumbers ? (
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              maxHeight: 400,
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {assignedNumbers.map(({ id, number, status, friendly_name }) => (
            <MenuItem
              key={id}
              disabled={status !== "active"}
              selected={number === selectedNumber}
              onClick={(event) => handleMenuItemClick(event, number)}
            >
              <Typography variant="body">
                <PhoneNumberFilter number={number} />
                {friendly_name ? ` (${friendly_name})` : ""}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  wrapper: {
    display: "flex",
    color: alohi.white,
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
    marginLeft: spacing(1),
  },
  selectable: {
    color: alohi.white,
    userSelect: "text",
    marginLeft: spacing(1),
    paddingRight: spacing(1),
  },
  notSelectable: {
    userSelect: "none",
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default From;
