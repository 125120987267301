import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageApi } from "api";
import { RootState } from "stores/store";

import RS, { FetchError, RequestStatus } from "enums/requestStatus";
import { UploadContactsResponse, UploadContactsVariables } from "api/types/storage";

const initialRequestState: {
  status: RequestStatus;
  response: unknown | null;
  error: FetchError | null | undefined;
} = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  contactsDocuments: { ...initialRequestState, files: [] },
  profileImage: initialRequestState,
  uploadApiLogo: initialRequestState,
};

export const clearUploadContactsDocuments = createAction("STORAGE/CLEAR_UPLOAD_CONTACTS_DOCUMENTS");
export const clearUploadProfileImage = createAction("STORAGE/CLEAR_UPLOAD_PROFILE_IMAGE");
export const clearUploadApiLogo = createAction("STORAGE/CLEAR_UPLOAD_API_LOGO");

export const uploadContactsDocuments = createAsyncThunk<
  UploadContactsResponse,
  UploadContactsVariables,
  { state: RootState; rejectValue: FetchError }
>("STORAGE/UPLOAD_CONTACTS_DOCUMENTS", async (file, { rejectWithValue }) => {
  try {
    const response = await storageApi.uploadContactsDocument(file);
    return response;
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const uploadProfileImage = createAsyncThunk(
  "STORAGE/UPLOAD_PROFILE_IMAGE",
  async (file, { rejectWithValue }) => {
    try {
      const response = await storageApi.uploadProfileImage(file);
      return response;
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
);

export const uploadApiLogo = createAsyncThunk(
  "STORAGE/UPLOAD_API_LOGO",
  async (file, { rejectWithValue }) => {
    try {
      const response = await storageApi.uploadApiLogo(file);
      return { response };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
);

const { reducer } = createSlice({
  name: "STORAGE",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearUploadContactsDocuments, (state) => {
      state.contactsDocuments.files = [];
      state.contactsDocuments.response = null;
      state.contactsDocuments.status = RS.IDLE;
      state.contactsDocuments.error = null;
    });
    builder.addCase(uploadContactsDocuments.pending, (state) => {
      state.contactsDocuments.response = null;
      state.contactsDocuments.status = RS.RUNNING;
      state.contactsDocuments.error = null;
    });
    builder.addCase(uploadContactsDocuments.fulfilled, (state, { payload }) => {
      state.contactsDocuments.response = payload;
      state.contactsDocuments.error = null;
      state.contactsDocuments.files.push(payload as never);
    });
    builder.addCase(uploadContactsDocuments.rejected, (state, action) => {
      state.contactsDocuments.response = null;
      state.contactsDocuments.status = RS.ERROR;
      state.contactsDocuments.error = action.payload as FetchError;
    });
    builder.addCase(clearUploadProfileImage, (state) => {
      state.profileImage.response = null;
      state.profileImage.status = RS.IDLE;
      state.profileImage.error = null;
    });
    builder.addCase(uploadProfileImage.pending, (state) => {
      state.profileImage.response = null;
      state.profileImage.status = RS.RUNNING;
      state.profileImage.error = null;
    });
    builder.addCase(uploadProfileImage.fulfilled, (state, { payload }) => {
      state.profileImage.response = payload;
      state.profileImage.error = null;
      state.profileImage.status = RS.IDLE;
    });
    builder.addCase(uploadProfileImage.rejected, (state, { payload }) => {
      state.profileImage.response = null;
      state.profileImage.status = RS.ERROR;
      state.profileImage.error = payload as FetchError;
    });
    builder.addCase(uploadApiLogo.pending, (state) => {
      state.uploadApiLogo.status = RS.RUNNING;
      state.uploadApiLogo.error = null;
    });
    builder.addCase(uploadApiLogo.fulfilled, (state, { payload }) => {
      state.uploadApiLogo.status = RS.IDLE;
      state.uploadApiLogo.response = payload.response;
    });
    builder.addCase(uploadApiLogo.rejected, (state, { payload }) => {
      state.uploadApiLogo.status = RS.ERROR;
      state.uploadApiLogo.error = payload as FetchError;
    });
    builder.addCase(clearUploadApiLogo, (state) => {
      state.uploadApiLogo = initialRequestState;
    });
  },
});

export default reducer;
