import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  faCogs,
  faPlug,
  faDollarSign,
  faShieldCheck,
  faClipboardListCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCogs as faCogsSolid,
  faPlug as faPlugSolid,
  faShieldCheck as faShieldCheckSolid,
  faDollarSign as faDollarSignSolid,
  faClipboardListCheck as faClipboardListCheckSolid,
} from "@fortawesome/pro-solid-svg-icons";

import { profileRoutes } from "enums/profile";
import { selectCanISeeIntegrationsTab } from "selectors/ability.selector";
import { useMemo } from "react";
import AppSubMenu, {
  AppSubMenuItem,
} from "components/Layout/PrivateLayout/components/AppSubMenu/AppSubMenu";
import { useLocation } from "react-router";

function ProfileTabs() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const canISeeIntegrationsTab = useSelector(selectCanISeeIntegrationsTab);

  const items: AppSubMenuItem[] = useMemo(
    () =>
      [
        {
          label: t("PROFILE.GENERAL"),
          to: `/profile/${profileRoutes.general}`,
          icon: pathname === `/profile/${profileRoutes.general}` ? faCogsSolid : faCogs,
          "data-cy": "ProfileTabToGeneral",
        },
        {
          label: t("PROFILE.PLAN_BILLING"),
          to: `/profile/${profileRoutes.planBilling}`,
          icon:
            pathname === `/profile/${profileRoutes.planBilling}` ? faDollarSignSolid : faDollarSign,
          "data-cy": "ProfileTabToProfileAndBilling",
        },
        {
          label: t("PROFILE.SECURITY"),
          to: `/profile/${profileRoutes.security}`,
          icon:
            pathname === `/profile/${profileRoutes.security}` ? faShieldCheckSolid : faShieldCheck,
          "data-cy": "ProfileTabToSecurity",
        },
        {
          label: t("PROFILE.COMPLIANCE_TITLE"),
          to: `/profile/${profileRoutes.compliance}`,
          icon:
            pathname === `/profile/${profileRoutes.compliance}`
              ? faClipboardListCheckSolid
              : faClipboardListCheck,
          "data-cy": "ProfileTabToCompliance",
        },
        {
          label: t("PROFILE.INTEGRATIONS"),
          to: `/profile/${profileRoutes.integrations}`,
          icon: pathname === `/profile/${profileRoutes.integrations}` ? faPlugSolid : faPlug,
          "data-cy": "ProfileTabToIntegrations",
          isDisabled: !canISeeIntegrationsTab,
        },
      ] satisfies AppSubMenuItem[],
    [canISeeIntegrationsTab, pathname, t],
  );

  return <AppSubMenu items={items} />;
}

export default ProfileTabs;
