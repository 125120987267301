import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";

export type TwoFaGetActivationCodesResponse = {
  manual_code: string;
  qr_code: string;
};

export type TwoFaActivateResponse = {
  recovery_codes: string[];
};

export type TwoFaActivateFromEnforcedResponse = {
  recovery_codes: string[];
  access_token: string;
};

const getTwoFaSettings = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/2fa`;
  return fetchWithRefresh(path, requestOptions);
};

const deactivateTwoFa = (twoFaCode: string) => {
  const requestOptions = {
    method: "DELETE",
    body: { code: twoFaCode },
  };
  const path = `${API_AFFOGATO}/2fa`;
  return fetchWithRefresh(path, requestOptions);
};

const getTwoFaActivationCodes = () => {
  const requestOptions = {
    method: "POST",
  };
  const path = `${API_AFFOGATO}/2fa`;
  return fetchWithRefresh(path, requestOptions);
};

const getTwoFaActivationCodesFromSso = (userUid: string) => {
  const requestOptions = {
    method: "POST",
    body: { uid: userUid },
  };
  const path = `${API_AFFOGATO}/2fa/corporate_member`;
  return fetchWithRefresh(path, requestOptions);
};

const activateTwoFa = (twoFaCode: string) => {
  const requestOptions = {
    method: "PATCH",
    body: { code: twoFaCode },
  };
  const path = `${API_AFFOGATO}/2fa`;
  return fetchWithRefresh(path, requestOptions);
};

const activateTwoFaFromEnforced = ({
  twoFaCode,
  twoFaToken,
}: {
  twoFaCode: string;
  twoFaToken: string;
}) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/2fa/enable_enforced_member/${twoFaToken}/${twoFaCode}?use_cookie_flow=true`;
  return fetchWithRefresh(path, requestOptions, {
    excludeBearer: true,
    retry: false,
    credentials: "include",
  });
};

const activateCorporateTwoFa = (data: unknown) => {
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_AFFOGATO}/2fa/enforce_members`;
  return fetchWithRefresh(path, requestOptions);
};

const deactivateCorporateTwoFa = (data: unknown) => {
  const requestOptions = {
    method: "DELETE",
    body: data,
  };
  const path = `${API_AFFOGATO}/2fa/enforce_members`;
  return fetchWithRefresh(path, requestOptions);
};

const loginWith2fa = (code: string, token: string) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/2fa/${token}/${code}?use_cookie_flow=true`;
  return fetchWithRefresh(path, requestOptions, { retry: false, credentials: "include" });
};

export const twoFaApi = {
  getTwoFaSettings,
  deactivateTwoFa,
  getTwoFaActivationCodes,
  getTwoFaActivationCodesFromSso,
  activateTwoFa,
  activateTwoFaFromEnforced,
  loginWith2fa,
  activateCorporateTwoFa,
  deactivateCorporateTwoFa,
};
