import { Button } from "@alohi/kit";
import { useTranslation } from "react-i18next";

interface UploadButtonProps {
  onOpen: () => void;
  isDisabled: boolean;
}

function UploadButton({ isDisabled, onOpen }: UploadButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onOpen}
      isDisabled={isDisabled}
      tooltip={isDisabled ? t("COVER_SHEET.LIMIT_REACHED") : undefined}
    >
      {t("COMMON.UPLOAD")}
    </Button>
  );
}

export default UploadButton;
