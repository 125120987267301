import PropTypes from "prop-types";
import { useTheme, Icon } from "ui";

function IconWrapper({ children, color }) {
  const { alohi } = useTheme();
  return (
    <Icon
      style={{
        color: color || alohi.gray,
        display: "flex",
        overflow: "visible",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Icon>
  );
}

IconWrapper.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

export default IconWrapper;
