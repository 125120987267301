import { useCallback, useEffect } from "react";

export const useOnPressEscape = (callback) => {
  const onKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        callback?.();
      }
    },
    [callback],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onKeyDown]);
};
