import Cookies from "js-cookie";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import database from "helpers/database";
import { clearFaxesDatabase } from "views/SendFax/components/ResumeFaxes";
import { gaEvents, getUserLocale, readMarketingCookies } from "helpers/googleAnalytics";
import { IFRAME_UPLOAD_FILES } from "views/InlineFrames/UploaderView/Uploader";
import RS, { FetchError, initialRequestState, RequestStatus } from "enums/requestStatus";
import {
  twoFaApi,
  authenticationApi,
  AuthenticationLoginResponse,
  AuthenticationGetLoginLogsParams,
} from "api";
import { RootState } from "../store";
import TagManager from "react-gtm-module";
import { GoogleAnalyticsCustomEvents } from "hooks/useGoogleTagManager";

const initialState = {
  login: initialRequestState,
  loginWithGoogle: initialRequestState,
  loginWithTwoFa: initialRequestState,
  loginWithSso: initialRequestState,
  refreshToken: initialRequestState,
  register: initialRequestState,
  forgotPassword: initialRequestState,
  getActivityLogs: initialRequestState,
  getRedirectionToSso: initialRequestState,
  logout: initialRequestState,
  resetPassword: initialRequestState,

  loggedIn: null as boolean | null,
  accessToken: null as string | null,
  activityLogs: [] as unknown[],
  isGracefulLogout: false,
};

const login = createAsyncThunk<
  { response: AuthenticationLoginResponse },
  { username: string; password: string }
>("AUTHENTICATION/LOGIN", async ({ username, password }, { rejectWithValue }) => {
  try {
    const response = await authenticationApi.login(username, password);
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const loginWithGoogle = createAsyncThunk<
  { response: AuthenticationLoginResponse },
  { token: string }
>("AUTHENTICATION/LOGIN_WITH_GOOGLE", async ({ token }, { rejectWithValue }) => {
  try {
    const response = await authenticationApi.loginBy3rdParty({
      provider: "google",
      token,
    });
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const loginWithTwoFa = createAsyncThunk<
  { response: { access_token: string } },
  string,
  { state: RootState }
>("AUTHENTICATION/LOGIN_WITH_TWO_FA", async (code, { rejectWithValue, getState }) => {
  const twoFaToken = getState().twoFa.token as string;
  try {
    const response = await twoFaApi.loginWith2fa(code, twoFaToken);
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const loginWithSso = createAsyncThunk<{ response: unknown }, { email: string }>(
  "AUTHENTICATION/LOGIN_WITH_SSO",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await authenticationApi.initiateSsoLogin(email);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const refreshToken = createAsyncThunk(
  "AUTHENTICATION/REFRESH_TOKEN",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await authenticationApi.refreshToken();
      return { response };
    } catch (error) {
      dispatch(logout());
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const register = createAsyncThunk<
  { response: { id: string } },
  {
    email: string;
    password: string;
    name: string;
    lastname: string;
    referrer_uid?: string;
    referrerUid?: string;
  }
>("AUTHENTICATION/REGISTER", async (user, { rejectWithValue, dispatch }) => {
  try {
    const marketingInfo = {
      ...readMarketingCookies(),
      ...getUserLocale(),
    };

    const userData = {
      ...user,
      affiliate: {
        visitor_id: Cookies.get("PAPVisitorId") ?? "",
        affiliate_id: Cookies.get("PAPAffiliateId") ?? "",
        cjevent: Cookies.get("cje") ?? "",
      },
      marketing: marketingInfo,
    };
    const response = await authenticationApi.register(userData);

    if (
      ("provider" in user && user.provider) ||
      ("account_type" in user && user?.account_type === "corporate_member")
    ) {
      // User signed up with a social provider or was invited to join as a corporate corporate_member
      // and he was automatically email verified
      TagManager.dataLayer({ dataLayer: { event: GoogleAnalyticsCustomEvents.emailVerification } });
    }

    dispatch(login({ username: user.email, password: user.password }));
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const forgotPassword = createAsyncThunk<{ response: unknown }, string>(
  "AUTHENTICATION/FORGOT_PASSWORD",
  async (email, { rejectWithValue }) => {
    try {
      const response = await authenticationApi.forgotPassword(email);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const resetPassword = createAsyncThunk<
  { response: unknown },
  { newPassword: string; revisedToken: string }
>("AUTHENTICATION/RESET_PASSWORD", async ({ newPassword, revisedToken }, { rejectWithValue }) => {
  try {
    const response = await authenticationApi.resetPassword(newPassword, revisedToken);
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const getActivityLogs = createAsyncThunk<
  { response: unknown[]; reset?: boolean },
  AuthenticationGetLoginLogsParams,
  { rejectValue: FetchError }
>("AUTHENTICATION/GET_ACTIVITY_LOGS", async (opts, { rejectWithValue }) => {
  try {
    const response = await authenticationApi.getLoginLogs(opts);
    return { response, reset: opts.reset };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const logout = createAsyncThunk<{ response: unknown }, undefined>(
  "AUTHENTICATION/LOGOUT",
  async (_, { rejectWithValue }) => {
    try {
      const response = await authenticationApi.logout();

      await clearFaxesDatabase();
      await database.removeFile(IFRAME_UPLOAD_FILES);

      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
  {
    condition: (_, { getState }) => {
      const { logout, loggedIn } = (
        getState() as {
          authentication: {
            logout: { status: RequestStatus };
            loggedIn: boolean;
          };
        }
      ).authentication;
      return logout.status === RS.IDLE && loggedIn;
    },
  },
);

export const loginAfterTwoFaActivation = createAction(
  "AUTHENTICATION/LOGIN_AFTER_2FA_ACTIVATION",
  (accessToken) => ({
    payload: accessToken,
  }),
);

export const clearLogin = createAction("AUTHENTICATION/CLEAR_LOGIN");
export const clearRegister = createAction("AUTHENTICATION/CLEAR_REGISTER");
export const clearLoginWithTwoFa = createAction("AUTHENTICATION/CLEAR_LOGIN_WITH_TWO_FA");
export const clearLoginWithSso = createAction("AUTHENTICATION/CLEAR_LOGIN_WITH_SSO");
export const clearIsGracefulLogout = createAction("AUTHENTICATION/CLEAR_IS_GRACEFUL_LOGOUT");
export const clearForgotPassword = createAction("AUTHENTICATION/CLEAR_FORGOT_PASSWORD");
export const clearResetPassword = createAction("AUTHENTICATION/CLEAR_RESET_PASSWORD");

const { reducer } = createSlice({
  name: "authentication",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.login.status = RS.RUNNING;
      state.login.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.login.status = RS.IDLE;
      state.login.response = payload.response;
      if (!!payload.response.access_token) {
        state.accessToken = payload.response.access_token;
        state.loggedIn = true;
      }
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.login.status = RS.ERROR;
      state.login.error = payload;
    });
    builder.addCase(clearLogin, (state) => {
      state.login = initialRequestState;
    });
    builder.addCase(loginWithGoogle.pending, (state) => {
      state.loginWithGoogle.status = RS.RUNNING;
      state.loginWithGoogle.error = null;
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, { payload }) => {
      state.loginWithGoogle.status = RS.IDLE;
      state.loginWithGoogle.response = payload.response;
      if (!!payload.response.access_token) {
        state.accessToken = payload.response.access_token;
        state.loggedIn = true;
        window.__loadingScreen?.hide?.();
      }
    });
    builder.addCase(loginWithGoogle.rejected, (state, { payload }) => {
      state.loginWithGoogle.status = RS.ERROR;
      state.loginWithGoogle.error = payload;
      window.__loadingScreen?.hide?.();
    });
    builder.addCase(loginWithTwoFa.pending, (state) => {
      state.loginWithTwoFa.status = RS.RUNNING;
      state.loginWithTwoFa.error = null;
    });
    builder.addCase(loginWithTwoFa.fulfilled, (state, { payload }) => {
      state.loginWithTwoFa.status = RS.IDLE;
      state.loginWithTwoFa.response = payload.response;
      if (!!payload.response.access_token) {
        state.accessToken = payload.response.access_token;
        state.loggedIn = true;
      }
    });
    builder.addCase(loginWithTwoFa.rejected, (state, { payload }) => {
      state.loginWithTwoFa.status = RS.ERROR;
      state.loginWithTwoFa.error = payload;
    });
    builder.addCase(clearLoginWithTwoFa, (state) => {
      state.loginWithTwoFa = initialRequestState;
    });
    builder.addCase(loginWithSso.pending, (state) => {
      state.loginWithSso.status = RS.RUNNING;
      state.loginWithSso.error = null;
    });
    builder.addCase(loginWithSso.fulfilled, (state, { payload }) => {
      state.loginWithSso.status = RS.IDLE;
      state.loginWithSso.response = payload.response;
    });
    builder.addCase(loginWithSso.rejected, (state, { payload }) => {
      state.loginWithSso.status = RS.ERROR;
      state.loginWithSso.error = payload;
    });
    builder.addCase(clearLoginWithSso, (state) => {
      state.loginWithSso = initialRequestState;
    });
    builder.addCase(loginAfterTwoFaActivation, (state, { payload }) => {
      state.accessToken = payload;
      state.loggedIn = true;
    });
    builder.addCase(refreshToken.pending, (state) => {
      state.refreshToken.status = RS.RUNNING;
      state.refreshToken.error = null;
    });
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      state.refreshToken.status = RS.IDLE;
      state.refreshToken.response = payload.response;
      state.accessToken = payload.response.access_token;
      state.loggedIn = !!payload.response.access_token;
    });
    builder.addCase(refreshToken.rejected, (state, { payload }) => {
      state.refreshToken.status = RS.ERROR;
      state.refreshToken.error = payload;
    });
    builder.addCase(register.pending, (state) => {
      state.register.status = RS.RUNNING;
      state.register.error = null;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.register.status = RS.IDLE;
      state.register.response = payload.response;
      if (payload?.response?.id) {
        gaEvents.setUserId(payload.response.id);
      }
    });
    builder.addCase(register.rejected, (state, { payload }) => {
      state.register.status = RS.ERROR;
      state.register.error = payload;
    });
    builder.addCase(clearRegister, (state) => {
      state.register = initialRequestState;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPassword.status = RS.RUNNING;
      state.forgotPassword.error = null;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.forgotPassword.status = RS.IDLE;
      state.forgotPassword.response = payload.response;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.forgotPassword.status = RS.ERROR;
      state.forgotPassword.error = payload;
    });
    builder.addCase(clearForgotPassword, (state) => {
      state.forgotPassword = initialRequestState;
    });
    builder.addCase(getActivityLogs.pending, (state) => {
      state.getActivityLogs.status = RS.RUNNING;
      state.getActivityLogs.error = null;
    });
    builder.addCase(getActivityLogs.fulfilled, (state, { payload }) => {
      state.getActivityLogs.status = RS.IDLE;
      state.getActivityLogs.response = payload.response;
      state.activityLogs = payload.reset
        ? payload.response
        : [...state.activityLogs, ...payload.response];
    });
    builder.addCase(getActivityLogs.rejected, (state, { payload }) => {
      state.getActivityLogs.status = RS.ERROR;
      state.getActivityLogs.error = payload;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPassword.status = RS.RUNNING;
      state.resetPassword.error = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.resetPassword.status = RS.IDLE;
      state.resetPassword.response = payload.response;
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.resetPassword.status = RS.ERROR;
      state.resetPassword.error = payload;
    });
    builder.addCase(clearResetPassword, (state) => {
      state.resetPassword = initialRequestState;
    });
    builder.addCase(logout.pending, (state) => {
      state.logout.status = RS.RUNNING;
      window.localStorage.setItem("logout", Date.now().toString());
    });
    builder.addCase(logout.fulfilled, () => {
      Cookies.remove("refresh_token");
      return { ...initialState, isGracefulLogout: true };
    });
    builder.addCase(logout.rejected, () => {
      Cookies.remove("refresh_token");
      return initialState;
    });
  },
});

export {
  login,
  logout,
  register,
  refreshToken,
  loginWithTwoFa,
  loginWithGoogle,
  loginWithSso,
  forgotPassword,
  resetPassword,
  getActivityLogs,
};

export default reducer;
