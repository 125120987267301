import { useSelector } from "react-redux";

import HipaaPanel from "components/Profile/Compliance/HipaaPanel";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import DataResidencyPanel from "components/Profile/Compliance/DataResidencyPanel";
import DocumentRetentionPanel from "components/Profile/Compliance/DocumentRetentionPanel";
import RegulatoryCompliancePanel from "components/Profile/General/RegulatoryCompliancePanel/RegulatoryCompliancePanel";
import ComplianceDocumentsPanel from "components/Profile/Compliance/ComplianceDocumentsPanel";
import ComplianceHandleQueryString from "components/Profile/Compliance/ComplianceHandleQueryString";

function Compliance() {
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  return (
    <>
      <DataResidencyPanel />
      <DocumentRetentionPanel />
      <HipaaPanel />
      <ComplianceDocumentsPanel />
      {!isCorporateMember ? <RegulatoryCompliancePanel /> : null}
      {!isCorporateMember ? <ComplianceHandleQueryString /> : null}
    </>
  );
}

export default Compliance;
