import { Box, useBool } from "@alohi/kit";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PhoneNumberFilter } from "components/Core/Filters/index";
import useAdminRedirections from "hooks/useAdminRedirections";
import { alohiAdminUrls } from "routes/urls";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import { selectCuratedCorporateUsers } from "selectors/corporate.selector";
import { selectMemberObjectOfSelectedMember } from "selectors/faxes.selector";
import { selectAssignedNumbers, selectHasFreeNumber } from "selectors/numbers.selector";
import { selectIsPlanCorporate, selectIsPlanFree } from "selectors/plan.selector";
import { SET_SELECTED_MEMBER_UID, SET_SELECTED_NUMBER } from "stores/reducers/faxes.reducer";
import { ButtonBase, makeStyles, Typography } from "ui";
import ChangePlan from "views/ChangePlan/ChangePlan";
import FaxesNumberModal from "views/Faxes/FaxesNumberModal";
import JustDot from "./components/JustDot";

function FaxesNumberChooser() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const isPlanFree = useSelector(selectIsPlanFree);
  const numberList = useSelector(selectAssignedNumbers);
  const hasFreeNumber = useSelector(selectHasFreeNumber);
  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const curatedMembers = useSelector(selectCuratedCorporateUsers);
  const memberObject = useSelector(selectMemberObjectOfSelectedMember);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const selectedNumber = useSelector((state) => state.faxes.selectedNumber);
  const selectedMemberUid = useSelector((state) => state.faxes.selectedMemberUid);

  const hasOnlyOneNumber = numberList.length === 1;
  const [openChooser, setOpenChooser] = useState(false);
  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);
  const [statusDotVariant, setStatusDotVariant] = useState("success");
  const [isFakeChoiceForCorporateMember, setIsFakeChoiceForCorporateMember] = useState(
    numberList.length === 0,
  );

  const setStoreSelectedNumber = useCallback(
    (number) => dispatch(SET_SELECTED_NUMBER(number)),
    [dispatch],
  );
  const setStoreSelectedMemberUid = useCallback(
    (uid) => dispatch(SET_SELECTED_MEMBER_UID(uid)),
    [dispatch],
  );

  const handleOpenChangePlan = useCallback(() => {
    if (isPlanFree) {
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.upgrade,
        fallbackAction: isChangePlanOpenBool.setTrue,
      });
    }
  }, [redirectToAdmin, isPlanFree, isChangePlanOpenBool]);

  const TextContent = useMemo(() => {
    if (selectedNumber === "all") {
      if (selectedMemberUid) {
        setStatusDotVariant(null);
        return (
          <span>
            {t("FAXES.SELECTED_MEMBER_ID", {
              name: memberObject?.name ?? "User",
            })}
          </span>
        );
      } else if (hasFreeNumber) {
        setStatusDotVariant("yellow");
        return <span>{t("COMMON.NO_AVAILABLE")}</span>;
      } else if (hasOnlyOneNumber && !curatedMembers) {
        setStatusDotVariant(numberList[0]?.reception_enabled ? "green" : "yellow");
        return <PhoneNumberFilter number={numberList[0]?.number} />;
      } else if (isCorporateMember && isFakeChoiceForCorporateMember) {
        setStatusDotVariant("yellow");
        return <span>{t("COMMON.NO_AVAILABLE")}</span>;
      } else {
        if (numberList?.some(({ reception_enabled }) => reception_enabled)) {
          setStatusDotVariant("green");
        } else {
          setStatusDotVariant("yellow");
        }
        return <span>{t("FAXES.ALL_FAXES")}</span>;
      }
    } else if (selectedNumber) {
      let found = false;

      numberList.forEach(({ reception_enabled, number }) => {
        if (number === selectedNumber) {
          setStatusDotVariant(reception_enabled ? "green" : "yellow");
          found = true;
        }
      });

      if (!found) {
        setStatusDotVariant(null);
      }

      return <PhoneNumberFilter number={selectedNumber} />;
    } else if (hasFreeNumber) {
      setStatusDotVariant("yellow");
      return <span>{t("COMMON.NO_AVAILABLE")}</span>;
    }

    return <span></span>;
  }, [
    curatedMembers,
    hasFreeNumber,
    hasOnlyOneNumber,
    isCorporateMember,
    isFakeChoiceForCorporateMember,
    memberObject?.name,
    numberList,
    selectedMemberUid,
    selectedNumber,
    t,
  ]);

  // Load saved number from localStorage or default number
  useEffect(() => {
    if (
      numberList &&
      selectedNumber === null &&
      selectedMemberUid === null &&
      (isPlanCorporate === false || curatedMembers !== null)
    ) {
      const savedSelectedNumber = localStorage.getItem("archive_selected_number");
      const savedSelectedMemberUid = localStorage.getItem("archive_selected_member");

      if (
        savedSelectedNumber &&
        savedSelectedMemberUid &&
        curatedMembers?.some(
          (member) =>
            member.uid === savedSelectedMemberUid &&
            member.numbers.some((number) => number === savedSelectedNumber),
        )
      ) {
        // Case 1: There is a member matching the id stored in localState
        setStoreSelectedMemberUid(savedSelectedMemberUid);
        setStoreSelectedNumber(savedSelectedNumber);
      } else if (hasOnlyOneNumber) {
        setStoreSelectedNumber("all");
      } else if (numberList.some((number) => number?.number === savedSelectedNumber)) {
        // Case 2: There is a number matching the value store in localState
        setStoreSelectedNumber(savedSelectedNumber);
        localStorage.removeItem("archive_selected_member");
      } else {
        // Case 3: No saved value in localState, or no match
        setStoreSelectedNumber("all");
        localStorage.removeItem("archive_selected_member");
      }
    }
  }, [
    curatedMembers,
    hasFreeNumber,
    hasOnlyOneNumber,
    isPlanCorporate,
    numberList,
    selectedMemberUid,
    selectedNumber,
    setStoreSelectedMemberUid,
    setStoreSelectedNumber,
  ]);

  return (
    <>
      <Box className={classes.root}>
        <Box
          onClick={handleOpenChangePlan}
          className={clsx({
            [classes.numberContainer]: true,
            [classes.cursorPointer]: isPlanFree,
          })}
        >
          <Box sx={{ pr: 2 }}>
            {statusDotVariant ? <JustDot variant={statusDotVariant} /> : null}
          </Box>
          <Typography component="div" className={classes.faxNumberTitle}>
            {t("FAXES.FAX_NUMBER")}
            {t("COMMON.COLON")}
          </Typography>
          <Typography component="div" className={classes.faxNumberContent}>
            {TextContent}
          </Typography>
        </Box>

        {(!hasOnlyOneNumber || !!curatedMembers) && !isPlanFree && (
          <ButtonBase
            className={classes.changeNumberContainer}
            onClick={() => setOpenChooser(true)}
          >
            <FontAwesomeIcon icon={faChevronDown} fontSize={21} />
          </ButtonBase>
        )}
      </Box>

      {openChooser ? (
        <FaxesNumberModal
          open
          onCancel={() => setOpenChooser(false)}
          handleClose={() => setOpenChooser(false)}
          setIsFakeChoiceForCorporateMember={setIsFakeChoiceForCorporateMember}
        />
      ) : null}

      {isChangePlanOpen ? <ChangePlan handleClosure={isChangePlanOpenBool.setFalse} /> : null}
    </>
  );
}

const useStyles = makeStyles(({ alohi, shape, spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: alohi.white,
    boxShadow: alohi.bannerShadow,
    borderRadius: shape.borderRadius,
    border: `1px solid ${alohi.lighterGray}`,
  },
  numberContainer: {
    width: "100%",
    display: "flex",
    overflow: "hidden",
    padding: spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
  faxNumberTitle: {
    "&&": {
      userSelect: "none",
      fontSize: "1.25rem",
      whiteSpace: "nowrap",
    },
  },
  faxNumberContent: {
    "&&": {
      fontWeight: "bold",
      margin: spacing(0, 1),
      fontSize: "1.25rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  changeNumberContainer: {
    width: "70px",
    minWidth: "70px",
    color: alohi.almostGray,
    borderTopRightRadius: shape.borderRadius,
    borderBottomRightRadius: shape.borderRadius,
    borderLeft: `1px solid ${alohi.lighterGray}`,
    "&:hover": {
      backgroundColor: alohi.lightestGray,
    },
  },
}));

export default memo(FaxesNumberChooser);
