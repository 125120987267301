import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { accountsApi } from "api";
import { RootState } from "stores/store";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";
import {
  Group,
  transformGroups,
  GetGroupsResponse,
  UpdateGroupResponse,
  CreateGroupResponse,
  DefaultRequestOptions,
  revertTransformGroups,
} from "./contacts.helpers";

const defaultRequestOptions = {
  offset: 0,
  limit: 20,
  search: "",
  sortBy: "",
  direction: "",
  type: "json",
  shared: false,
};

const initialState = {
  shareGroup: InitialRequestState(),
  getGroups: InitialRequestState(),
  createGroup: InitialRequestState(),
  updateGroup: InitialRequestState(),
  deleteGroup: InitialRequestState(),
  getMoreGroups: InitialRequestState(),

  hasMore: false,
  selection: [] as string[],
  groups: null as null | Group[],
  lastRequestOptions: defaultRequestOptions,
};

const getGroups = createAsyncThunk<
  GetGroupsResponse,
  Partial<DefaultRequestOptions>,
  { state: RootState; rejectValue: FetchError }
>("GROUPS/GET", async (requestOptionsOverrides = {}, { getState, rejectWithValue }) => {
  try {
    const lastRequestOptions = getState().groups.lastRequestOptions;
    const requestOptions = { ...lastRequestOptions, ...requestOptionsOverrides };

    const response = await accountsApi.getGroups(requestOptions);

    return { response, requestOptions };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const getMoreGroups = createAsyncThunk<
  GetGroupsResponse,
  undefined,
  { state: RootState; rejectValue: FetchError }
>(
  "GROUPS/GET_MORE",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { groups, lastRequestOptions } = getState().groups;

      const requestOptions = {
        ...lastRequestOptions,
        offset: groups?.length ?? 0,
      };

      const response = await accountsApi.getGroups(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().groups.getMoreGroups.status !== RS.RUNNING;
    },
  },
);

const createGroup = createAsyncThunk<
  CreateGroupResponse,
  { newGroup: Partial<Group> },
  { state: RootState; rejectValue: FetchError }
>("GROUPS/CREATE", async ({ newGroup }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.createGroup(revertTransformGroups(newGroup));

    return { response };
  } catch (error) {
    const errorValue = error as FetchError;
    Object.assign(errorValue, { newGroup });
    return rejectWithValue(errorValue);
  }
});

const updateGroup = createAsyncThunk<
  UpdateGroupResponse,
  { groupDiffs: Partial<Group> },
  { state: RootState; rejectValue: FetchError }
>("GROUPS/UPDATE", async ({ groupDiffs }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.updateGroup(revertTransformGroups(groupDiffs));

    return { response, groupDiffs };
  } catch (error) {
    const errorValue = error as FetchError;
    Object.assign(errorValue, { groupDiffs });
    return rejectWithValue(errorValue);
  }
});

const deleteGroup = createAsyncThunk<
  { idsToDelete: string[]; response: Record<string, string> },
  { idsToDelete: string[] },
  { state: RootState; rejectValue: FetchError }
>("GROUPS/DELETE", async ({ idsToDelete }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.deleteGroupById(idsToDelete);

    return { response, idsToDelete };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const shareGroup = createAsyncThunk<
  { idsToShare: string[]; response: Record<string, string> },
  { idsToShare: string[] },
  { state: RootState; rejectValue: FetchError }
>("GROUPS/SHARE", async ({ idsToShare }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.shareGroups(idsToShare);

    return { response, idsToShare };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const clearShareGroup = createAction("GROUPS/CLEAR_SHARE");
export const clearUpdateGroup = createAction("GROUPS/CLEAR_UPDATE");
export const clearCreateGroup = createAction("GROUPS/CLEAR_CREATE");
export const clearContactsGroups = createAction("GROUPS/CLEAR_GROUPS");
export const clearFetchedGroups = createAction("GROUPS/CLEAR_FETCHED_GROUPS");
export const updateGroupSelection = createAction<string[]>("GROUPS/UPDATE_SELECTION");

const { reducer } = createSlice({
  name: "GROUPS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateGroupSelection, (state, { payload }) => {
      state.selection = payload;
    });

    builder.addCase(getGroups.pending, (state) => {
      state.getGroups.status = RS.RUNNING;
      state.getGroups.error = null;
    });
    builder.addCase(getGroups.fulfilled, (state, { payload }) => {
      state.getGroups.status = RS.IDLE;
      state.getGroups.response = payload.response;

      state.groups = transformGroups(payload.response.result ?? []);
      state.hasMore = payload.response.has_more;
      state.lastRequestOptions = { ...state.lastRequestOptions, ...payload.requestOptions };
    });
    builder.addCase(getGroups.rejected, (state, { payload }) => {
      state.getGroups.status = RS.ERROR;
      state.getGroups.error = payload;
    });

    builder.addCase(getMoreGroups.pending, (state) => {
      state.getMoreGroups.status = RS.RUNNING;
      state.getMoreGroups.error = null;
    });
    builder.addCase(getMoreGroups.fulfilled, (state, { payload }) => {
      state.getMoreGroups.status = RS.IDLE;
      state.getMoreGroups.response = payload.response;

      if (state.groups) {
        state.groups.push(...transformGroups(payload.response.result));
        // Reset the indexes
        state.groups = state.groups.map((rest, index) => ({ ...rest, index }));
      } else {
        state.groups = transformGroups(payload.response.result ?? []);
      }
      state.hasMore = payload.response.has_more;
      state.lastRequestOptions = { ...state.lastRequestOptions, ...payload.requestOptions };
    });
    builder.addCase(getMoreGroups.rejected, (state, { payload }) => {
      state.getMoreGroups.status = RS.ERROR;
      state.getMoreGroups.error = payload;
    });

    builder.addCase(createGroup.pending, (state) => {
      state.createGroup.status = RS.RUNNING;
      state.createGroup.error = null;
    });
    builder.addCase(createGroup.fulfilled, (state, { payload }) => {
      state.createGroup.status = RS.IDLE;
      state.createGroup.response = payload.response;
      state.groups = null; // Refresh the list
      state.hasMore = false;
    });
    builder.addCase(createGroup.rejected, (state, { payload }) => {
      state.createGroup.status = RS.ERROR;
      state.createGroup.error = payload;
    });

    builder.addCase(updateGroup.pending, (state) => {
      state.updateGroup.status = RS.RUNNING;
      state.updateGroup.error = null;
    });
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      state.updateGroup.status = RS.IDLE;
      state.updateGroup.response = payload.response;

      const indexToEdit = state.groups?.findIndex((group) => group.id === payload.groupDiffs.id);

      if (indexToEdit !== undefined && indexToEdit !== -1 && state.groups) {
        const newContact = {
          ...state.groups[indexToEdit],
          ...payload.groupDiffs,
          index: indexToEdit,
        } as Group;

        state.groups[indexToEdit] = newContact;
      }
    });
    builder.addCase(updateGroup.rejected, (state, { payload }) => {
      state.updateGroup.status = RS.ERROR;
      state.updateGroup.error = payload;
    });

    builder.addCase(deleteGroup.pending, (state) => {
      state.deleteGroup.status = RS.RUNNING;
      state.deleteGroup.error = null;
    });
    builder.addCase(deleteGroup.fulfilled, (state, { payload }) => {
      state.deleteGroup.status = RS.IDLE;

      if (state.groups) {
        state.groups = state.groups.filter((group) => !payload.idsToDelete.includes(group.id));
        // Reset the indexes
        state.groups = state.groups.map((rest, index) => ({ ...rest, index }));
      }
    });

    builder.addCase(clearShareGroup, (state) => {
      state.shareGroup.status = RS.IDLE;
      state.shareGroup.error = null;
      state.shareGroup.response = null;
    });
    builder.addCase(shareGroup.pending, (state) => {
      state.shareGroup.status = RS.RUNNING;
      state.shareGroup.error = null;
    });
    builder.addCase(shareGroup.fulfilled, (state, { payload }) => {
      state.shareGroup.status = RS.IDLE;
      state.shareGroup.response = payload.response;
    });
    builder.addCase(shareGroup.rejected, (state, { payload }) => {
      state.shareGroup.status = RS.ERROR;
      state.shareGroup.error = payload;
    });

    builder.addCase(clearCreateGroup, (state) => {
      state.createGroup.status = RS.IDLE;
      state.createGroup.response = null;
      state.createGroup.error = null;
    });
    builder.addCase(clearUpdateGroup, (state) => {
      state.updateGroup.status = RS.IDLE;
      state.updateGroup.response = null;
      state.updateGroup.error = null;
    });

    builder.addCase(clearFetchedGroups, (state) => {
      state.hasMore = false;
      state.selection = [];
      state.groups = null;
      state.lastRequestOptions = defaultRequestOptions;
    });
    builder.addCase(clearContactsGroups, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export { getGroups, shareGroup, createGroup, updateGroup, deleteGroup, getMoreGroups };

export default reducer;
