import createSelector from "selectorator";

import RS from "enums/requestStatus";
import { slackTargetChannelString } from "helpers/common";
import { confirmationPage, faxedDocument, notify, notifyAttach, off } from "enums/notifications";

import i18n from "../i18n/index";
import { selectIsEnabledAdvancedSecurity } from "./account.selector";
import {
  selectSlackToken,
  selectSlackChannels,
  selectIsGetSlackChannelsRunning,
} from "./integrations.selector";

export const selectIsGetNotificationsRunning = createSelector(
  ["account.getNotifications.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsGetNotificationsSuccessful = createSelector(
  ["account.getNotifications.response"],
  (response) => {
    if (!response) {
      return false;
    }
    return !!response;
  },
);

export const selectIsGetNotificationsError = createSelector(
  ["account.getNotifications.status"],
  (status) => status === RS.ERROR,
);

export const selectIsUpdateNotificationsRunning = createSelector(
  ["account.updateNotifications.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUpdateNotificationsSuccessful = createSelector(
  ["account.updateNotifications.response"],
  (response) => {
    if (!response) {
      return false;
    }
    return !!response;
  },
);

export const selectIsUpdateNotificationsError = createSelector(
  ["account.updateNotifications.status"],
  (status) => status === RS.ERROR,
);

export const selectEmailNotificationsSettings = (state) =>
  state.account.getNotifications.response?.settings?.email ?? null;

export const selectEmailNotificationAddresses = createSelector(
  [selectEmailNotificationsSettings],
  (emailSettings) => {
    return emailSettings?.addresses ?? [];
  },
);

export const selectEmailNotificationOptions = createSelector(
  [selectEmailNotificationsSettings, selectIsEnabledAdvancedSecurity],
  (emailSettings, isEnabledAdvancedSecurity) => {
    const options = [];
    if (isEnabledAdvancedSecurity) {
      options.push({
        receiveFax: emailSettings.receive_fax ? notify : off,
      });
      options.push({
        sendFax: emailSettings.send_fax ? notify : off,
      });
    } else {
      options.push({
        receiveFax: emailSettings.receive_fax
          ? emailSettings.attachments && emailSettings.attachments.receive_fax
            ? notifyAttach
            : notify
          : off,
      });
      options.push({
        sendFax: emailSettings.send_fax
          ? emailSettings.attachments && emailSettings.attachments.send_fax
            ? notifyAttach
            : notify
          : off,
      });
    }
    return options;
  },
);

export const selectEmailNotificationAvailableOptions = createSelector(
  [selectIsEnabledAdvancedSecurity],
  (isEnabledAdvancedSecurity) => {
    if (!isEnabledAdvancedSecurity) return [notifyAttach, notify, off];
    return [notify, off];
  },
);

export const selectEmailNotificationsDisplayStatus = createSelector(
  [selectEmailNotificationsSettings, selectEmailNotificationAddresses],
  (emailSettings, emails) => {
    if (!!emails && !!emailSettings) {
      let displayStatus = emails.length < 2 ? emails[0] ?? "" : i18n.t("PROFILE.MULTIPLE_EMAILS");
      if (displayStatus) {
        displayStatus += `\n`;
      }
      const options = [];
      emailSettings.send_fax &&
        emailSettings.send_fax !== "off" &&
        options.push(i18n.t("PROFILE.SENT_FAX"));
      emailSettings.receive_fax &&
        emailSettings.receive_fax !== "off" &&
        options.push(i18n.t("PROFILE.RECEIVED_FAX"));

      switch (options.length) {
        case 0:
          return i18n.t("PROFILE.INACTIVE");
        case 1:
          displayStatus += `(${options[0]})`;
          break;
        case 2:
          displayStatus += `(${options.join(", ")})`;
          break;
        default:
          break;
      }

      return displayStatus;
    } else {
      return i18n.t("PROFILE.INACTIVE");
    }
  },
);

export const selectNotificationAttachmentsOption = createSelector(
  [selectEmailNotificationsSettings],
  (emailSettings) => {
    if (!emailSettings?.attachments) return null;

    return emailSettings?.attachments.confirmation_page ? confirmationPage : faxedDocument;
  },
);

export const selectNotificationAttachmentsAvailableOptions = () => [
  confirmationPage,
  faxedDocument,
];

export const selectSlackNotificationSettings = (state) =>
  state.account.getNotifications.response?.settings?.slack ?? null;

export const selectSlackNotificationsTargetChannelId = createSelector(
  [selectSlackNotificationSettings],
  (slackSettings) => slackSettings?.target_channel ?? null,
);

export const selectSlackNotificationsTargetChannel = createSelector(
  [selectSlackChannels, selectSlackNotificationsTargetChannelId],
  (channels, targetChannelId) => {
    if (!channels) return null;
    const targetObject = channels.find((channel) => channel.id === targetChannelId);

    if (targetObject) {
      return targetObject;
    }

    if (targetChannelId) {
      return {
        type: "id",
        id: targetChannelId,
        name: targetChannelId,
      };
    }

    return null;
  },
);

export const selectSlackNotificationsDisplayStatus = createSelector(
  [
    selectIsGetSlackChannelsRunning,
    selectSlackNotificationSettings,
    selectSlackToken,
    selectSlackNotificationsTargetChannel,
  ],
  (loading, slackSettings, token, targetChannel) => {
    if (loading) return null;

    if (!!slackSettings && !!token && !!targetChannel) {
      const options = [];
      let displayStatus = slackTargetChannelString(targetChannel.type, targetChannel.name) + `\n`;

      slackSettings.send_fax !== "off" && options.push(i18n.t("PROFILE.SENT_FAX"));
      slackSettings.receive_fax !== "off" && options.push(i18n.t("PROFILE.RECEIVED_FAX"));

      switch (options.length) {
        case 0:
          return i18n.t("PROFILE.INACTIVE");
        case 1:
          displayStatus += ` (${options[0]})`;
          break;
        case 2:
          displayStatus += ` (${options.join(", ")})`;
          break;
        default:
          break;
      }

      return displayStatus;
    } else {
      return i18n.t("PROFILE.INACTIVE");
    }
  },
);

export const selectSlackNotificationsAvailableOptions = createSelector(
  [selectIsEnabledAdvancedSecurity],
  (isEnabledAdvancedSecurity) => {
    if (!isEnabledAdvancedSecurity) return [notifyAttach, notify, off];
    return [notify, off];
  },
);

export const selectSlackNotificationsOptions = createSelector(
  [selectSlackNotificationSettings, selectIsEnabledAdvancedSecurity],
  (slackSettings) => {
    const options = {};
    options.receiveFax =
      slackSettings.receive_fax === "with_attachment"
        ? notifyAttach
        : slackSettings.receive_fax === "no_attachment"
        ? notify
        : off;
    options.sendFax =
      slackSettings.send_fax === "with_attachment"
        ? notifyAttach
        : slackSettings.send_fax === "no_attachment"
        ? notify
        : off;
    return options;
  },
);
