import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@alohi/kit";

import { boxNames } from "enums/faxes";
import { useAppDispatch } from "stores/store";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";

function ConfirmDeleteFaxModal({ handleClosure, boxName, cdrIds }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await dispatch(faxesAsyncActions.deleteFax({ boxName, cdrIds: cdrIds }));
    } catch {
      // Nothing to do with the error
    } finally {
      setLoading(true);
      handleClosure();
    }
  };

  return (
    <Modal
      title={t("DELETE_ITEM.ARE_YOU_SURE")}
      onConfirm={handleConfirm}
      onCancel={handleClosure}
      isConfirmLoading={loading}
      maxWidth="xs"
    >
      <Box p={3}>
        <Typography variant="body">
          {cdrIds.length > 1
            ? t("DELETE_ITEM.ARE_YOU_DELETE_SELECTED_LOGS")
            : t("DELETE_ITEM.ARE_YOU_DELETE_ONE_LOG")}
        </Typography>
      </Box>
    </Modal>
  );
}

ConfirmDeleteFaxModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  boxName: PropTypes.oneOf(Object.values(boxNames)).isRequired,
  cdrIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ConfirmDeleteFaxModal;
