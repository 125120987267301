import { Box } from "@alohi/kit";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect } from "react";

import { urls } from "routes/urls";
import { Contact } from "stores/reducers/contacts.helpers";
import { PhoneNumberFilter } from "components/Core/Filters";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import EmptyContacts from "components/InfiniTable/EmptyContacts";
import DeleteContactModal from "views/Contacts/DeleteContactModal";
import AddOrEditContactModal from "views/Contacts/AddOrEditContactModal";
import { SendFaxRouteState } from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import ShareContactsOrGroupsModal from "views/Contacts/ShareContactsOrGroupsModal";
import {
  Table,
  Typography,
  makeStyles,
  TableColumnsProp,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "ui";
import {
  selectWhitelistContacts,
  selectWhitelistHasMoreContacts,
  selectWhitelistContactsSelection,
  selectIsWhitelistContactsLoading,
} from "selectors/contacts.selector";
import {
  getWhitelistContacts,
  getMoreWhitelistContacts,
  updateWhitelistContactsSelection,
} from "stores/reducers/contacts.whitelist.reducer";
import GroupsRow from "./GroupsRow";

interface ModalState {
  isOpen: boolean;
  contactId: null | string;
}

interface WhitelistProps {
  noBorder: boolean;
  canShare: boolean;
  hasActions: boolean;
  areTabsClickable?: boolean;
}

function Whitelist({ canShare, hasActions, noBorder, areTabsClickable }: WhitelistProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleContactDestination } = useSendFaxDestinationHelpers();

  const hasMore = useAppSelector(selectWhitelistHasMoreContacts);
  const isLoading = useAppSelector<boolean>(selectIsWhitelistContactsLoading);
  const selection = useAppSelector<string[]>(selectWhitelistContactsSelection);
  const contactList = useAppSelector<null | Contact[]>(selectWhitelistContacts);

  const [editModal, setEditModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });
  const [deleteModal, setDeleteModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });
  const [shareModal, setShareModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });

  const rows = useMemo(() => contactList ?? [], [contactList]);

  const actions = useMemo(() => {
    return (index: number) =>
      rows.map((row) => {
        const actions = [
          {
            label: t("COMMON.SEND_FAX"),
            callback: () =>
              history.push(urls.sendFax, {
                destination: [handleContactDestination(row, "contact")],
              } satisfies SendFaxRouteState),
          },
          {
            label: "Divider",
          },
          {
            label: t("COMMON.EDIT"),
            callback: () => {
              setEditModal({
                isOpen: true,
                contactId: row.id,
              });
            },
          },
          {
            label: t("COMMON.DELETE"),
            callback: () => {
              setDeleteModal({
                isOpen: true,
                contactId: row.id,
              });
            },
          },
        ];

        if (canShare) {
          actions.push({
            label: "Divider",
          });

          actions.push({
            label: t("CONTACTS.ADD_WHITELIST_TO_SHARED"),
            callback: () => {
              setShareModal({
                isOpen: true,
                contactId: row.id,
              });
            },
          });
        }

        return actions;
      })[index];
  }, [canShare, handleContactDestination, history, rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    columns.push({
      width: 16,
      minWidth: 16,
      headerName: " ",
      sortable: false,
      field: "id",
      renderCell: function render() {
        return <Box> </Box>;
      },
    });

    columns.push({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.05,
    });

    columns.push({
      width: 15,
      minWidth: 15,
      headerName: " ",
      sortable: false,
      field: "index",
      renderCell: function render() {
        return <Box> </Box>;
      },
    });

    columns.push({
      flex: 0.25,
      sortable: false,
      field: "fullName",
      headerName: t("CONTACTS.NAME"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Box sx={{ overflow: "hidden" }}>
            <Typography className={classes.row} sx={{ fontWeight: "bold" }} stopPropagation>
              {row.fullName}
            </Typography>
            <Typography className={classes.row} sx={{ mt: 2 }} stopPropagation>
              <PhoneNumberFilter number={row.faxNumber} />
            </Typography>
          </Box>
        );
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "groups",
      headerName: t("CONTACTS.GROUPS"),
      renderCell: function render({ row }: { row: Contact }) {
        return <GroupsRow row={row} isSharedContact={false} hasActions={hasActions} />;
      },
    });

    columns.push({
      flex: 0.255,
      field: "note",
      sortable: false,
      headerName: t("CONTACTS.NOTE"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.note}
          </Typography>
        );
      },
    });

    if (hasActions) {
      columns.push({
        flex: 0.0925,
        sortable: false,
        field: "actions",
        headerName: " ",
        renderCell: function render({ row }: { row: Contact }) {
          return <ColumnActions dataIndex={row.index} createActions={actions} />;
        },
      });
    }

    return columns;
  }, [actions, classes.row, hasActions, t]);

  const onWaypoint = useMemo(() => {
    if (!hasMore) {
      return undefined;
    }

    return () => {
      dispatch(getMoreWhitelistContacts());
    };
  }, [dispatch, hasMore]);

  useEffect(() => {
    // Initial Fetch
    if (!contactList) {
      dispatch(getWhitelistContacts({ offset: 0 }));
    }
  }, [dispatch, contactList]);

  return (
    <>
      <Table
        rows={rows}
        rowHeight={76}
        columns={columns}
        checkboxSelection
        noBorder={noBorder}
        isLoading={isLoading}
        onWaypoint={onWaypoint}
        className={classes.root}
        selectionModel={selection}
        disableSelectionOnClick={!areTabsClickable}
        onSelectionModelChange={(selection) =>
          dispatch(updateWhitelistContactsSelection(selection as string[]))
        }
        components={{
          NoRowsOverlay: function render() {
            return <EmptyContacts text={t("CONTACTS.NO_CONTACTS_HERE_YET")} />;
          },
        }}
      />

      {editModal.isOpen && editModal.contactId ? (
        <AddOrEditContactModal
          contactToEditId={editModal.contactId}
          handleClosure={() => setEditModal({ isOpen: false, contactId: null })}
        />
      ) : null}

      {deleteModal.isOpen && deleteModal.contactId ? (
        <DeleteContactModal
          isSharedContact={false}
          contactIds={[deleteModal.contactId]}
          handleClosure={() => setDeleteModal({ isOpen: false, contactId: null })}
        />
      ) : null}

      {shareModal.isOpen && shareModal.contactId ? (
        <ShareContactsOrGroupsModal
          isContact
          idsToShare={[shareModal.contactId]}
          handleClosure={() => setShareModal({ isOpen: false, contactId: null })}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "&& .MuiDataGrid-columnHeader": {
      padding: spacing(0, 1),
    },
    "&& .MuiDataGrid-columnHeader:nth-child(2)": {
      paddingLeft: spacing(0.5),
    },
    "&&& .MuiDataGrid-cell": {
      padding: spacing(2, 1),
    },
    "&&&& .MuiDataGrid-row > div:first-child": {
      padding: 0,
    },
  },
  row: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "text",
  },
}));

export default Whitelist;
