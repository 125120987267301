import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal, Box, useSnackbar } from "@alohi/kit";

import { websiteUrls } from "routes/urls";
import { FormControlLabel, Typography } from "ui";
import { logout } from "stores/reducers/authentication.reducer";
import { selectAcceptTosError, selectIsAcceptTosSuccess } from "selectors/account.selector";
import { acceptTos, clearAcceptTos, getAccountDetails } from "stores/reducers/account.reducer";

/** Required for users who did not sign up "normally"
 *  And thus did not accept the Fax.Plus ToS yet
 *  (SSO, SCIM, Sign.Plus Users)
 *  Or when we release a new TOS that users have to accept again
 */

function TosModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [agree, setAgree] = useState(false);
  const acceptTosError = useSelector(selectAcceptTosError);
  const isAcceptTosSuccess = useSelector(selectIsAcceptTosSuccess);

  useEffect(() => {
    if (isAcceptTosSuccess) dispatch(getAccountDetails());
  }, [isAcceptTosSuccess, dispatch]);

  useEffect(() => {
    if (acceptTosError) {
      enqueueSnackbar(acceptTosError, { variant: "error" });
      dispatch(clearAcceptTos());
    }
  }, [acceptTosError, dispatch, enqueueSnackbar, t]);

  return (
    <Modal
      maxWidth="xs"
      isConfirmDisabled={!agree}
      confirmTitle={t("COMMON.CONFIRM")}
      cancelTitle={t("COMMON.LOGOUT")}
      onCancel={() => dispatch(logout())}
      onConfirm={() => {
        dispatch(acceptTos());
      }}
      title={t("TERMS.TITLE")}
    >
      <Box p={3}>
        <Typography variant="body">
          <Trans
            i18nKey={"SIGNUP.BEFORE_CONTINUING_PLEASE_READ_TERMS"}
            components={{
              termsLink: <a target="_blank" rel="noopener noreferrer" href={websiteUrls.terms} />,
            }}
          />
        </Typography>
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                value={agree}
                onChange={() => {
                  setAgree(!agree);
                }}
              />
            }
            label={
              <Trans
                i18nKey={"SIGNUP.AGREE"}
                components={{
                  termsLink: (
                    <a target="_blank" rel="noopener noreferrer" href={websiteUrls.terms} />
                  ),
                }}
              />
            }
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default TosModal;
