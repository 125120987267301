import { ActionReducerMapBuilder, createAction, createAsyncThunk } from "@reduxjs/toolkit";

import RS, { FetchError, initialRequestState } from "enums/requestStatus";
import { notificationsApi } from "../../api/notifications.api";
import { selectEmailNotificationsSettings } from "../../selectors/account.notifications.selector";
import { RootState } from "../store";

export const initialNotificationsState = {
  getNotifications: initialRequestState,
  updateNotifications: initialRequestState,
};

const getNotifications = createAsyncThunk<
  { response: unknown },
  undefined,
  { rejectValue: FetchError["origin"] }
>("ACCOUNT/GET_NOTIFICATIONS", async (_, { rejectWithValue }) => {
  try {
    const response = await notificationsApi.getNotifications();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const updateNotifications = createAsyncThunk<
  { response: unknown },
  { updateType: string; accountData: unknown },
  { rejectValue: FetchError["origin"] }
>(
  "ACCOUNT/UPDATE_NOTIFICATIONS",
  async ({ updateType, accountData }, { getState, rejectWithValue }) => {
    try {
      const currentEmailSettings = selectEmailNotificationsSettings(getState());
      if (!currentEmailSettings) return rejectWithValue("Invalid settings");
      const response = await notificationsApi.updateNotifications(
        accountData,
        updateType,
        currentEmailSettings,
      );
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const clearGetNotifications = createAction("ACCOUNT/CLEAR_GET_NOTIFICATIONS");
export const clearUpdateNotifications = createAction("ACCOUNT/CLEAR_UPDATE_NOTIFICATIONS");

export const notificationsSlice = (builder: ActionReducerMapBuilder<RootState["account"]>) => {
  builder.addCase(clearGetNotifications, (state) => {
    state.getNotifications = initialRequestState;
  });
  builder.addCase(getNotifications.pending, (state) => {
    state.getNotifications.status = RS.RUNNING;
    state.getNotifications.error = null;
  });
  builder.addCase(getNotifications.fulfilled, (state, action) => {
    state.getNotifications.response = action.payload.response;
    state.getNotifications.status = RS.IDLE;
  });
  builder.addCase(getNotifications.rejected, (state, { payload }) => {
    state.getNotifications.status = RS.ERROR;
    state.getNotifications.error = payload;
  });
  builder.addCase(clearUpdateNotifications, (state) => {
    state.updateNotifications = initialRequestState;
  });
  builder.addCase(updateNotifications.pending, (state) => {
    state.updateNotifications.status = RS.RUNNING;
    state.updateNotifications.error = null;
  });
  builder.addCase(updateNotifications.fulfilled, (state) => {
    state.updateNotifications.response = true; // Success returns 204
    state.updateNotifications.status = RS.IDLE;
  });
  builder.addCase(updateNotifications.rejected, (state, action) => {
    state.updateNotifications.status = RS.ERROR;
    state.updateNotifications.error = action.payload;
  });
};

export { updateNotifications, getNotifications };
