import { useTranslation } from "react-i18next";
import { Button, makeStyles, Box } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

import { useInput } from "hooks/useInput";
import { numberTypes } from "enums/numbers";
import FormRow from "components/Forms/FormRow";
import { selectPlanId } from "selectors/plan.selector";
import AddNumberModal from "views/AddNumber/AddNumberModal";
import { PhoneNumberFilter } from "components/Core/Filters";
import { isValidCompanyName } from "helpers/inputValidation";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import { selectCompanyName } from "selectors/account.selector";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import { selectCorporateUpgradeState } from "selectors/payment.selector";
import {
  verifyNumber,
  clearVerifyNumber,
  clearAssignBundleToNumber,
} from "stores/reducers/verifications.reducer";
import { selectIsVerifyNumberLoading, selectVerifyNumber } from "selectors/verifications.selector";
import {
  updateCorporateCompany,
  updateCorporateFirstNumber,
  updateCorporateSecondNumber,
} from "stores/reducers/payment.corporate.reducer";

function ChangeToCorporatePlan() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planId = useSelector(selectPlanId);
  const selectedNumber = useSelector(selectVerifyNumber);
  const initialCompanyName = useSelector(selectCompanyName);
  const isVerifyNumberLoading = useSelector(selectIsVerifyNumberLoading);
  const { firstNumber, secondNumber } = useSelector(selectCorporateUpgradeState);

  const [numberType, setNumberType] = useState(null);
  const [isGenerated, setIsGenerated] = useState(false);
  const [companyName, companyNameInput] = useInput(initialCompanyName || "", isValidCompanyName);

  const handleAddNumberModal = useCallback(
    (numberObject, numType) => {
      if (numberObject) {
        if (numType === "firstNumber") {
          dispatch(updateCorporateFirstNumber(numberObject));
        } else if (numType === "secondNumber") {
          dispatch(updateCorporateSecondNumber(numberObject));
        }
      }
      setNumberType(null);
    },
    [dispatch],
  );

  useEffect(() => {
    // Heavy temporary solution for this input to send the value to the button as soon as possible
    if (companyNameInput.isValid) {
      dispatch(updateCorporateCompany(companyName));
    }
  }, [companyName, companyNameInput.isValid, dispatch]);

  useEffect(() => {
    // Scope of this effect is auto request the first or second number using the country from the first selected number
    // Then wait for second selectedNumber to get updated
    if (
      ((firstNumber && !secondNumber) || (!firstNumber && secondNumber)) && // Ensure that at least one number was selected by the user
      !numberType && // Ensure that the AddNumberModal is not open
      !isGenerated // Ensure that we request a number to be generated just once
    ) {
      dispatch(
        verifyNumber({
          planId,
          areaCode: "",
          action: "number",
          numberType: "local",
          verificationType: numberTypes.random,
          countryCode: firstNumber?.item?.iso || secondNumber?.item?.iso,
        }),
      );
    }
  }, [dispatch, firstNumber, secondNumber, planId, numberType, isGenerated]);

  useEffect(() => {
    if (selectedNumber && !isGenerated) {
      // selectedNumber was updated with the new random number requested at line 73
      if (
        firstNumber &&
        firstNumber?.id !== selectedNumber?.id &&
        firstNumber.item.number !== selectedNumber.item.number // @TODO Do this check on the backend
      ) {
        handleAddNumberModal(selectedNumber, "secondNumber");
        dispatch(clearVerifyNumber());
        dispatch(clearAssignBundleToNumber());
        setIsGenerated(true); // Don't generate again
      } else if (
        secondNumber &&
        secondNumber?.id !== selectedNumber?.id &&
        secondNumber.item.number !== selectedNumber.item.number // @TODO Do this check on the backend
      ) {
        handleAddNumberModal(selectedNumber, "firstNumber");
        dispatch(clearVerifyNumber());
        dispatch(clearAssignBundleToNumber());
        setIsGenerated(true); // Don't generate again
      }
    }
  }, [dispatch, firstNumber, isGenerated, secondNumber, selectedNumber, handleAddNumberModal]);

  useEffect(() => {
    if (isGenerated && !firstNumber && !secondNumber) {
      // Restore the logic to generate again if a number is selected by the user after both were removed
      // To remove a selected number, the user can open AddNumberModal and close it without selecting a number
      setIsGenerated(false);
    }
  }, [firstNumber, secondNumber, isGenerated]);

  if (numberType) {
    return (
      <AddNumberModal
        numberType={numberType}
        handleClosure={(numberObject) => handleAddNumberModal(numberObject, numberType)}
      />
    );
  }

  return (
    <Box pr="22%" mb={2}>
      <FormRowTextInput
        helperText={" "}
        labelGridWidth={5}
        value={companyName}
        id="select-company-name"
        onBlur={companyNameInput.onBlur}
        label={t("PROFILE.COMPANY_NAME")}
        onFocus={companyNameInput.onFocus}
        error={companyNameInput.showsError}
        onChange={companyNameInput.onChange}
        inputProps={{ "data-cy": "CompanyNameInput" }}
      />

      <FormRow label={t("CHANGE_PLAN.FIRST_FAX_NUMBER")} labelGridWidth={5}>
        <Button
          variant="white"
          className={classes.chooseNumberButton}
          onClick={() => {
            !isVerifyNumberLoading && setNumberType("firstNumber");
          }}
        >
          <Box className={classes.numberWrapper}>
            {firstNumber?.item?.number ? (
              <>
                <Box className={classes.phone}>
                  <CountryFlag code={firstNumber.item.iso} />
                  <Box marginLeft="6px">
                    <PhoneNumberFilter number={firstNumber.item.number} />
                  </Box>
                </Box>
                <Box className={classes.change}>{t("COMMON.CHANGE")}</Box>
              </>
            ) : (
              t("CHANGE_PLAN.CLICK_CHOOSE_NUMBER")
            )}
            {firstNumber ? null : <FontAwesomeIcon icon={faChevronRight} />}
          </Box>
        </Button>
      </FormRow>

      <Box display="flex" alignItems="center">
        <FormRow label={t("CHANGE_PLAN.SECOND_FAX_NUMBER")} labelGridWidth={5}>
          <Button
            variant="white"
            className={classes.chooseNumberButton}
            isDisabled={isVerifyNumberLoading}
            onClick={() => {
              !isVerifyNumberLoading && setNumberType("secondNumber");
            }}
          >
            <Box className={classes.numberWrapper}>
              {secondNumber?.item?.number ? (
                <>
                  <Box className={classes.phone}>
                    <CountryFlag code={secondNumber.item.iso} />
                    <Box marginLeft="6px">
                      <PhoneNumberFilter number={secondNumber.item.number} />
                    </Box>
                  </Box>
                  <Box className={classes.change}>{t("COMMON.CHANGE")}</Box>
                </>
              ) : (
                t("CHANGE_PLAN.CLICK_CHOOSE_NUMBER")
              )}
              {secondNumber ? null : <FontAwesomeIcon icon={faChevronRight} />}
            </Box>
          </Button>
        </FormRow>
        <Box width={0} style={{ transform: "translate(16px, 2px)" }}>
          <HelpTooltip
            placement="bottom"
            title={<Box m={0.5}>{t("CHANGE_PLAN.YOU_CAN_ADD_MORE_NUMBERS")}</Box>}
          />
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  chooseNumberButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  numberWrapper: {
    display: "flex",
    flexBasis: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  change: {
    color: alohi.red,
    paddingRight: spacing(1),
  },
  phone: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default ChangeToCorporatePlan;
