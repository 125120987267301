import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const post = ({ cartId, isAsync }) => {
  const requestOptions = {
    method: "POST",
    body: {
      cart_id: cartId,
      background: isAsync,
    },
  };
  const path = `${API_ESHOP}/charge`;
  return fetchWithRefresh(path, requestOptions);
};

export const chargeApi = {
  post,
};
