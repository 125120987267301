import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography, makeStyles, Button, Box, Modal } from "@alohi/kit";

import { List, Radio, ListItem, ListItemAvatar } from "ui";
import { setPaymentMethod } from "stores/reducers/payment.methods.reducer";
import PaymentMethodImg from "components/Core/PaymentMethod/PaymentMethodImg";
import AddPaymentMethodModal from "views/AddPaymentMethod/AddPaymentMethodModal";
import {
  selectPaymentMethods,
  selectSelectedPaymentMethod,
  selectIsPaymentMethodsLoading,
} from "selectors/payment.selector";

/*
 * SelectPaymentMethod implies the fact that at least a payment method exists for the account
 * otherwise this component can't be rendered
 */

function SelectPaymentMethod({ handleClosure }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const selectedPayment = useSelector(selectSelectedPaymentMethod);
  const paymentMethods = useSelector(selectPaymentMethods);
  const isPaymentMethodsLoading = useSelector(selectIsPaymentMethodsLoading);

  const [selectMethod, setSelectedMethod] = useState(selectedPayment);
  const [openAddPaymentMethod, setOpenAddPaymentMethod] = useState(false);

  const changePaymentMethod = useCallback(() => {
    if (selectMethod) {
      dispatch(setPaymentMethod(selectMethod));
    }
    handleClosure(selectMethod);
  }, [dispatch, handleClosure, selectMethod]);

  if (openAddPaymentMethod) {
    return <AddPaymentMethodModal handleClosure={() => setOpenAddPaymentMethod(false)} />;
  }

  return (
    <Modal
      cancelTitle={t("COMMON.CANCEL")}
      onCancel={() => handleClosure(false)}
      confirmTitle={t("ADD_CREDIT.CONFIRM")}
      isModalDisabled={isPaymentMethodsLoading}
      onConfirm={changePaymentMethod}
      title={t("ADD_CREDIT.SELECT_PAYMENT_METHOD")}
    >
      <List className={classes.list}>
        {paymentMethods.map((method, index) => (
          <ListItem
            button
            divider
            key={method.id + index}
            onClick={() => setSelectedMethod(method)}
            data-cy={"PaymentMethodItem"}
          >
            <ListItemAvatar className={classes.listItemAvatar}>
              <Box display="flex" alignItems="center">
                <Radio
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  checked={method.id === selectMethod.id}
                  inputProps={{
                    "aria-labelledby": `checkbox-list-label-${method.id}`,
                  }}
                />
                <PaymentMethodImg brand={method.brand || method.type} />
              </Box>
            </ListItemAvatar>
            <Box style={{ paddingRight: "5px", minWidth: 113, overflow: "hidden" }}>
              <Typography className={classes.cardText}>
                {method.type !== "paypal" ? `**** **** **** ${method.last4}` : method.email}
              </Typography>
              <Typography className={classes.cardText}>
                {method.type !== "paypal" && `Exp. ${method.exp_month}/${method.exp_year}`}
              </Typography>
            </Box>
            {method.type !== "paypal" ? (
              <Box
                style={{
                  paddingRight: "5px",
                  marginLeft: "16px",
                  overflow: "hidden",
                }}
              >
                <Typography className={classes.cardText}>{method.name}</Typography>
                <Typography className={classes.cardText}>{method.address_line1}</Typography>
              </Box>
            ) : null}
          </ListItem>
        ))}
      </List>
      <Box className={classes.addPaymentMethod}>
        <Button color="primary" variant="contained" onClick={() => setOpenAddPaymentMethod(true)}>
          {t("ADD_CREDIT.ADD_PAYMENT_METHOD")}
        </Button>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  list: {
    flex: 1,
    padding: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    margin: "0 0 16px 0",
    flexDirection: "column",
  },
  img: {
    boxShadow: "none",
    padding: spacing(2),
  },
  listItemAvatar: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  addPaymentMethod: {
    display: "flex",
    margin: spacing(2),
    marginLeft: "45px",
    marginBottom: spacing(3),
    justifyContent: "flex-start",
  },
  cardText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

SelectPaymentMethod.propTypes = {
  payment: PropTypes.object.isRequired,
  handleClosure: PropTypes.func.isRequired,
};

export default SelectPaymentMethod;
