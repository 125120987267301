import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaButton, makeStyles, Box } from "@alohi/kit";
import { faBars } from "@fortawesome/pro-light-svg-icons";

import { urls } from "routes/urls";
import useBool from "hooks/useBool";
import { useMediaQuery, Drawer, useTheme } from "ui";
import {
  selectCanISeeDashboardTab,
  selectCanISeeNumbersTab,
  selectCanISeeUsersTab,
} from "selectors/ability.selector";
import NavigationLink from "./NavigationLink";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

interface NavigationProps {
  scrollToTop: () => void;
}

function Navigation({ scrollToTop }: NavigationProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const { sendCustomEvent } = useGoogleTagManager();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const canISeeUsersTab = useSelector(selectCanISeeUsersTab);
  const canISeeNumbersTab = useSelector(selectCanISeeNumbersTab);
  const canISeeDashboardTab = useSelector(selectCanISeeDashboardTab);

  const [isDrawerOpen, isDrawerOpenBool] = useBool(false);

  const handleNavigationClick = (label: string, to: string) => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarNavigation,
      page: location.pathname,
      extraData: {
        label,
        to,
      },
    });
    scrollToTop();
  };

  const nav = (
    <nav className={classes.navigation}>
      <NavigationLink
        to={urls.faxes.inbox}
        matchPath={urls.faxes.root}
        label={t("COMMON.FAXES")}
        dataCy={"NavigationToFaxes"}
        onClick={() => handleNavigationClick("faxes", urls.faxes.inbox)}
      />
      <NavigationLink
        to={urls.sendFax}
        matchPath={[
          urls.sendFax,
          urls.resendFax,
          urls.forwardFax,
          urls.replyFax,
          urls.sendFaxGoogleDrive,
          urls.inlineFrameToFax,
        ]}
        label={t("COMMON.SEND_FAX")}
        dataCy={"NavigationToSendFax"}
        onClick={() => handleNavigationClick("send_fax", urls.sendFax)}
      />
      <NavigationLink
        to={urls.contacts.root}
        matchPath={urls.contacts.root}
        label={t("CONTACTS.TITLE")}
        dataCy={"NavigationToContacts"}
        onClick={() => handleNavigationClick("contacts", urls.contacts.root)}
      />
      {canISeeNumbersTab && (
        <NavigationLink
          to={urls.numbers}
          matchPath={urls.numbers}
          label={t("USERS.NUMBERS")}
          dataCy={"NavigationToNumbers"}
          onClick={() => handleNavigationClick("numbers", urls.numbers)}
        />
      )}
      {canISeeUsersTab && (
        <NavigationLink
          to={urls.users}
          matchPath={urls.users}
          label={t("USERS.TITLE")}
          dataCy={"NavigationToUsers"}
          onClick={() => handleNavigationClick("users", urls.users)}
        />
      )}
      {canISeeDashboardTab && (
        <NavigationLink
          to={urls.dashboard.company}
          matchPath={urls.dashboard.root}
          label={t("DASHBOARD.TITLE")}
          dataCy={"NavigationToDashboard"}
          onClick={() => handleNavigationClick("analytics", urls.dashboard.company)}
        />
      )}
      <NavigationLink
        to={urls.profile.general}
        matchPath={urls.profile.root}
        label={t("COMMON.SETTINGS")}
        dataCy={"NavigationToProfile"}
        onClick={() => handleNavigationClick("settings", urls.profile.general)}
      />
    </nav>
  );

  if (isMobileLayout) {
    return (
      <Box className={classes.mobileDrawerIcon}>
        <FaButton variant="dark" icon={faBars} onClick={isDrawerOpenBool.toggle} />
        <Drawer
          open={isDrawerOpen}
          onClose={isDrawerOpenBool.setFalse}
          onClick={isDrawerOpenBool.setFalse}
        >
          {nav}
        </Drawer>
      </Box>
    );
  }

  return nav;
}

const useStyles = makeStyles(({ spacing, breakpoints, alohi }) => ({
  navigation: {
    gap: "6px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.down("md")]: {
      padding: spacing(2, 1),
      flexDirection: "column",
      background: alohi.white,
      justifyContent: "flex-start",
    },
  },
  mobileDrawerIcon: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

export default Navigation;
