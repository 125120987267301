import { ReactNode, useEffect } from "react";
import { useRouteMatch } from "react-router";
import { urls } from "routes/urls";
import { Grid, Hidden, makeStyles } from "ui";
import DevFab from "components/DevFab/DevFab";

import AppBar from "./PrivateLayout/components/AppBar";
import AppLogo from "./AppLogo";
import PageTitle from "./PageTitle";

interface StaticLayoutProps {
  children: ReactNode;
  hideHeader?: boolean;
}

function StaticLayout({ children, hideHeader = false }: StaticLayoutProps) {
  const classes = useStyles();
  const isUploadIframe = useRouteMatch(urls.iframe.upload);

  const isHeaderDisabled = isUploadIframe;

  useEffect(() => {
    window.__loadingScreen?.hide?.();
  }, []);

  return (
    <div className={classes.layoutContainer}>
      {!hideHeader && !isHeaderDisabled ? (
        <AppBar>
          <Grid container alignItems="center">
            <Grid item xs={6} sm={3}>
              <AppLogo />
            </Grid>
            <Hidden smDown>
              <Grid item container sm={6} justifyContent={"center"}>
                <PageTitle />
              </Grid>
            </Hidden>
          </Grid>
        </AppBar>
      ) : null}
      <div className={classes.mainContent}>{children}</div>
      <DevFab />
    </div>
  );
}

const useStyles = makeStyles(({ breakpoints }) => ({
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  mainContent: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    // Margin on XS to avoid hiding content with Re/**/Captcha
    [breakpoints.down("xs")]: { marginBottom: "60px" },
  },
}));

export default StaticLayout;
