import { API_ESHOP } from "config";
import { fetchWithRefresh, handleRequest } from "helpers";

/**
 *
 * @param {String} payment_reason // available values : add_credit | change_plan
 * @param {*} data
 */
const newDeposit = (paymentReason, data) => {
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_ESHOP}/deposit?payment_reason=${paymentReason}`;
  return fetchWithRefresh(path, requestOptions);
};

const confirmPaymentDeposit = (id) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      id,
      op: "confirm_payment",
    },
  };
  const path = `${API_ESHOP}/deposit`;
  return fetchWithRefresh(path, requestOptions);
};

const confirmPurchaseDeposit = (cid) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      cid,
      op: "confirm_purchase",
    },
  };
  const path = `${API_ESHOP}/deposit`;
  return fetchWithRefresh(path, requestOptions);
};

const getAll = (options) => {
  const { limit, before } = options;

  const requestOptions = {
    method: "GET",
  };
  const q = [];
  const queryString = { str: "" };
  if (limit) {
    q.push(`limit=${limit}`);
  }
  if (before) {
    q.push(`to=${before}`);
  }
  if (q.length) {
    queryString.str = q.join("&");
  }
  const path = queryString.str ? `${API_ESHOP}/deposit?${queryString.str}` : `${API_ESHOP}/deposit`;

  return fetchWithRefresh(path, requestOptions);
};

const getById = (id) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/deposit/${id}`;
  return fetchWithRefresh(path, requestOptions);
};

const getReceiptById = async ({ id }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/receipt/${id}`;

  try {
    const [newUrl, newConfig] = handleRequest(path, requestOptions);
    const response = await fetch(newUrl, newConfig);

    if (!response.ok) {
      throw new Error("Not found");
    }

    const blob = await response.blob();
    const headers = response.headers;
    const contentDispositionHeader = headers.get("content-disposition");

    return { blob, contentDispositionHeader };
  } catch (error) {
    throw error;
  }
};

export const depositApi = {
  getAll,
  getById,
  newDeposit,
  getReceiptById,
  confirmPaymentDeposit,
  confirmPurchaseDeposit,
};
