import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Select from "components/Forms/Select/Select";
import { useTheme } from "ui";

import dayjs from "helpers/date";
import { selectTimeZoneSelectOptions } from "selectors/verifications.selector";

interface TimeZoneSelectProps {
  timeZone: { label: string; value: string; zone: string } | null;
  setTimeZone: Dispatch<SetStateAction<{ label: string; value: string; zone: string } | null>>;
}

function TimeZonesSelect({ timeZone, setTimeZone }: TimeZoneSelectProps) {
  const { alohi } = useTheme();

  const timeZones = useSelector(selectTimeZoneSelectOptions) as {
    label: string;
    options: { label: string; value: string; zone: string }[];
  }[];

  useEffect(() => {
    if (!timeZone && timeZones?.length) {
      for (const zone of timeZones) {
        const labelFound = zone?.options?.find((element) =>
          element.label.includes(dayjs.tz.guess()),
        );
        if (labelFound) {
          setTimeZone(labelFound);
          break;
        }
      }
    }
  }, [timeZone, setTimeZone, timeZones]);

  const selectStyles = useMemo(
    () => ({
      menu: (provided: object) => ({
        ...provided,
        zIndex: 2,
      }),
      control: (provided: object) => ({
        ...provided,
        minHeight: "40px",
      }),
      dropdownIndicator: (provided: object) => ({
        ...provided,
        padding: "6px",
      }),
      menuPortal: (provided: object) => ({
        ...provided,
        zIndex: 9999,
      }),
      option: (provided: object, state: { isSelected: boolean }) => ({
        ...provided,
        color: alohi.gray,
        background: state.isSelected ? alohi.lightestBlue : alohi.white,
        ":hover": {
          background: alohi.lighterGray,
        },
      }),
      singleValue: (provided: object) => ({
        ...provided,
        color: alohi.gray,
      }),
    }),
    [alohi],
  );

  return (
    <Select
      value={timeZone}
      options={timeZones}
      styles={selectStyles}
      onChange={setTimeZone}
      menuPortalTarget={document.body}
    />
  );
}

export default TimeZonesSelect;
