import { useHistory, useParams } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  Paper,
  Button,
  Divider,
  TextButton,
  Typography,
  makeStyles,
  useSnackbar,
} from "@alohi/kit";

import useTitle from "hooks/useTitle";
import { useInput } from "hooks/useInput";
import { profileRoutes } from "enums/profile";
import Currency from "components/Core/Currency/Currency";
import { isValidRedeemCode } from "helpers/inputValidation";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { getCurrentCredit } from "stores/reducers/credit.reducer";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import { clearRedeemCode, redeemCode } from "stores/reducers/verifications.reducer";
import {
  selectRedeemCodeError,
  selectRedeemCodeResponse,
  selectIsRedeemCodeRunning,
} from "selectors/verifications.selector";

function Redeem() {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(t("COMMON.REDEEM"));

  const isRunning = useSelector(selectIsRedeemCodeRunning);
  const redeemCodeError = useSelector(selectRedeemCodeError);
  const redeemCodeResponse = useSelector(selectRedeemCodeResponse);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const [showAddCredit, setShowAddCredit] = useState(false);
  const [redeemValue, redeemInput] = useInput(id ?? "", isValidRedeemCode);

  const applyCode = useCallback(() => {
    redeemInput.touch();

    if (redeemInput.isValid) {
      dispatch(redeemCode({ token: redeemValue }));
    }
  }, [dispatch, redeemInput, redeemValue]);

  useEffect(() => {
    if (redeemCodeResponse) {
      dispatch(getCurrentCredit());
      enqueueSnackbar(
        <Typography variant="body">
          <Trans
            open
            i18nKey="REDEEM.SUCCESS"
            components={{
              amount: (
                <Currency
                  value={redeemCodeResponse.amount}
                  currency={redeemCodeResponse.currency}
                />
              ),
            }}
          />
        </Typography>,
        { variant: "success" },
      );
      history.push(`/profile/${profileRoutes.planBilling}`);
    }
  }, [enqueueSnackbar, dispatch, history, redeemCodeResponse, classes.notification]);

  useEffect(() => {
    if (redeemCodeError) {
      enqueueSnackbar(redeemCodeError, { variant: "error" });
      redeemInput.setShowsError(true);
      dispatch(clearRedeemCode());
    }
  }, [dispatch, redeemInput, redeemCodeError, enqueueSnackbar]);

  useEffect(() => {
    return () => {
      dispatch(clearRedeemCode());
    };
  }, [dispatch]);

  return (
    <Paper component="form" className={classes.wrapper}>
      <Typography variant="body" align="center">
        {t("REDEEM.ENTER_PIN")}
      </Typography>
      <FormRowTextInput
        required
        label={""}
        type="text"
        labelGridWidth={0}
        id={"redeem-input"}
        value={redeemValue}
        onBlur={redeemInput.onBlur}
        onFocus={redeemInput.onFocus}
        error={redeemInput.showsError}
        onChange={redeemInput.onChange}
        placeholder={t("REDEEM.ENTER_YOUR_REDEEM_CODE")}
        helperText={
          redeemInput.showsError && !redeemInput.isValid
            ? t("FORMS.REDEEM_CODE_LENGTH", { min: 16, max: 64 })
            : null
        }
      />
      <Button
        fullWidth
        onClick={applyCode}
        isLoading={isRunning}
        isDisabled={redeemInput.showsError}
        sx={{ mt: 2 }}
      >
        {t("COMMON.CONFIRM")}
      </Button>
      <Divider sx={{ my: 2 }} />

      {isCorporateMember ? null : (
        <TextButton onClick={() => setShowAddCredit(true)}>
          {t("REDEEM.WANT_TO_USE_ANOTHER_PAYMENT")}
        </TextButton>
      )}

      {showAddCredit ? (
        <AddCreditModal
          withSuccessModal
          reason="add_credit"
          handleClosure={() => setShowAddCredit(false)}
        />
      ) : null}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",
    margin: `${theme.spacing(4)} auto`,
    maxWidth: "480px",
    padding: theme.spacing(5),
  },
}));

export default Redeem;
