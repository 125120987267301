import { useTranslation } from "react-i18next";
import { Button, makeStyles, Menu, Typography } from "@alohi/kit";

import { UseBoolType } from "hooks/useBool";
import { helpCenterUrls } from "routes/urls";
import { useAppSelector } from "stores/store";
import { selectAccountIsBusinessCorporateMember } from "selectors/account.selector";

interface UpgradeDropdownProps {
  clearAnchorEl: () => void;
  anchorEl: HTMLElement | null;
  isUpgradeModalOpenBool: UseBoolType[1];
}

function UpgradeDropdown({
  anchorEl,
  clearAnchorEl,
  isUpgradeModalOpenBool,
}: UpgradeDropdownProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isCorporateBusinessMember = useAppSelector(selectAccountIsBusinessCorporateMember);

  return (
    <Menu
      open
      anchorEl={anchorEl}
      onClose={clearAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      MenuListProps={{ "aria-labelledby": "folders-menu-upgrade" }}
      classes={{
        list: classes.list,
        paper: classes.paperRoot,
      }}
    >
      <Typography className={classes.text}>
        {t("CHANGE_PLAN.REQUIRE_UPGRADE_TO_ENTERPRISE", {
          count: 2,
          featureName: t("CONTACTS.SHARED_CONTACTS"),
        })}
      </Typography>
      <Typography
        type="anchor"
        className={classes.text}
        onClick={() => window.open(helpCenterUrls.shareContacts, "_blank")}
      >
        {t("COMMON.LEARN_MORE")}
      </Typography>

      {isCorporateBusinessMember ? (
        <Typography sx={{ "&&": { mt: 2 }, textAlign: "center" }}>
          {t("CONTACTS.CONTACT_YOUR_ADMIN")}
        </Typography>
      ) : (
        <Button variant="blue" className={classes.button} onClick={isUpgradeModalOpenBool.setTrue}>
          {t("COMMON.UPGRADE")}
        </Button>
      )}
    </Menu>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  paperRoot: {
    width: 250,
    padding: spacing(3, 2),
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",
  },
  button: {
    "&&": {
      marginTop: spacing(4),
    },
  },
  text: {
    textAlign: "center",
  },
}));

export default UpgradeDropdown;
