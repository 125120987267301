import React from "react";
import PropTypes from "prop-types";

import ReactPlayer from "react-player";

function Player(props) {
  return (
    <ReactPlayer
      width="100%"
      height="35px"
      config={{
        file: {
          forceAudio: true,
          ...props.config,
        },
        ...props.config,
      }}
      {...props}
    />
  );
}

Player.propTypes = {
  config: PropTypes.object,
};

export default Player;
