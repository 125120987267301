import { ReactChildren, ReactNode } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";

import { selectIsLoggedIn } from "../selectors/authentication.selector";
import { filterQueryParams } from "./urls";

interface PrivateRouteProps {
  exact?: boolean;
  path?: RouteProps["path"];
  render?: RouteProps["render"];
  children?: ReactChildren | ReactNode;
}

function PrivateRoute({ children, render, ...otherProps }: PrivateRouteProps) {
  const location = useLocation();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const to = queryParams.get("to");
  const reroutedQueryParams = filterQueryParams({
    filter: ["_gl", "hideMobileBanner", "direct_open_modal"],
    currentParams: queryParams,
  }).toString();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (isLoggedIn) {
    return <Route render={render || (() => children)} {...otherProps} />;
  }

  if (to === "change_plan") {
    return (
      <Redirect
        to={{
          pathname: "/signup",
          state: { redirectTo: location.pathname + location.search + location.hash },
          search: reroutedQueryParams,
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { redirectTo: location.pathname + location.search + location.hash },
        search: reroutedQueryParams,
      }}
    />
  );
}

export default PrivateRoute;
