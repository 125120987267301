import { Box, makeStyles } from "@alohi/kit";
import { Document, Page, pdfjs } from "react-pdf";
import { useCallback, useRef, useState } from "react";

import { Loader } from "ui";

interface ViewPdfProps {
  file: File | null;
  getFirstPageBlob?: (blob: Blob | null, numPages: number) => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

function ViewPdf({ file, getFirstPageBlob }: ViewPdfProps) {
  const classes = useStyles();

  const canvasRef = useRef<null | HTMLCanvasElement>(null);
  const [documentData, setDocumentData] = useState({ numPages: 0 });
  const isDocumentReady = documentData.numPages > 0;

  const onPageRenderSuccess = useCallback(() => {
    if (canvasRef.current === null || !getFirstPageBlob) {
      return;
    }

    const blobCallback = (blob: Blob | null) => {
      getFirstPageBlob?.(blob, documentData.numPages);
    };

    canvasRef.current.toBlob(blobCallback);
  }, [documentData.numPages, getFirstPageBlob]);

  return (
    <Box width={"100%"}>
      {isDocumentReady ? null : (
        <Box className={classes.loader}>
          <Loader scale={1.2} />
        </Box>
      )}

      <Document
        error=""
        noData=""
        loading=""
        file={file}
        onPassword={() => undefined}
        onLoadSuccess={setDocumentData}
      >
        {isDocumentReady ? (
          <Page
            loading=""
            width={600}
            pageNumber={1}
            canvasRef={canvasRef}
            renderTextLayer={false}
            className={classes.canvas}
            renderAnnotationLayer={false}
            onRenderSuccess={onPageRenderSuccess}
          />
        ) : null}
      </Document>
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  canvas: {
    width: "100%",
    height: "auto",
  },
  loader: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: spacing(2),
    justifyContent: "center",
  },
}));

export default ViewPdf;
