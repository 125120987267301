import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigationModalApi, NavigationModal, Box } from "@alohi/kit";

import { Typography } from "ui";
import useOnMountCondition from "hooks/useOnMountCondition";
import { VIEWS } from "../PhoneVerificationModal";

function PhoneVerificationDuplicatePhone() {
  const { t } = useTranslation();

  const { updateStep, goToStep } = useNavigationModalApi();

  useOnMountCondition(() => {
    updateStep({
      title: t("VERIFY.MOBILE_VERIFICATION"),
      onCancel: handleCancel,
      onConfirm: handleConfirm,
      onPressEscape: handleCancel,
      onPressEnter: handleConfirm,
      cancelTitle: t("COMMON.CHANGE_NUMBER"),
      confirmTitle: t("FORMS.FORGOT_USER"),
    });
  }, true);

  const handleCancel = () => {
    goToStep(VIEWS.ENTER_PHONE);
  };

  const handleConfirm = () => {
    goToStep(VIEWS.FORGOT_USERNAME);
  };

  return (
    <NavigationModal.Content>
      <Box p={3}>
        <Typography variant="body">{t("VERIFY.DUPLICATE_PHONE")}</Typography>
      </Box>
    </NavigationModal.Content>
  );
}

PhoneVerificationDuplicatePhone.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PhoneVerificationDuplicatePhone;
