import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faArrowsAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Chip, makeStyles, Typography, useTheme } from "ui";
import { accountStatuses, accountTypes } from "enums/account";
import { selectIsCurrentPlanBusiness } from "selectors/plan.selector";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import { selectCanIManageCorporateAdmins } from "selectors/ability.selector";
import { removeAsAdmin, setAsAdmin } from "stores/reducers/account.permissions.reducer";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import { selectAccountId, selectAccountIsCorporateOwner } from "selectors/account.selector";
import MemberAvatar from "../../AccountAvatar/MemberAvatar";
import ColumnActions from "../../Columns/ColumnActions";
import ConfirmDeleteUserModal from "../ConfirmDeleteUserModal";
import ConfirmDisableUserModal from "../ConfirmDisableUserModal";
import ConfirmResendInvitationModal from "../ConfirmResendInvitationModal";
import ConfirmTransferOwnershipModal from "../ConfirmTransferOwnershipModal";
import EditUserJobTitleModal from "../EditUserJobTitleModal";
import EditUserQuotaModal from "../EditUserQuotaModal";
import ManageUserNumbersModal from "../ManageUserNumbersModal";

function UsersHierarchyTreeUserNode({ node: user, canDrag }) {
  const draggable = user.accountType !== accountTypes.corporateOwner;

  const classes = useStyles({ draggable });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const myId = useSelector(selectAccountId);
  const isPlanBusiness = useSelector(selectIsCurrentPlanBusiness);
  const isCorporateOwner = useSelector(selectAccountIsCorporateOwner);
  const canIManageCorporateAdmins = useSelector(selectCanIManageCorporateAdmins);

  const [editUserRoleModal, setEditUserRoleModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [editUserQuotaModal, setEditUserQuotaModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [assignNumbersModal, setAssignNumbersModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [confirmDeleteUserModal, setConfirmDeleteUserModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [confirmDisableUserModal, setConfirmDisableUserModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [confirmResendInvitationModal, setConfirmResendInvitationModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [confirmTransferOwnershipModal, setConfirmTransferOwnershipModal] = useState({
    isOpen: false,
    userUid: null,
  });

  const [requireUpgradeModal, setRequireUpgradeModal] = useState({
    isOpen: false,
  });

  let displayStatus;
  switch (user.accountStatus) {
    case accountStatuses.waitingForSignup:
      displayStatus = t("USERS.PENDING_USER_CONFIRMATION");
      break;
    case accountStatuses.disabled:
      displayStatus = <span className={classes.disabledStatus}>{t("COMMON.DISABLED")}</span>;
      break;
    default:
      break;
  }

  const createActions = () => {
    const actions = [];
    if (user.accountType === accountTypes.corporateOwner) return null;
    if (canDrag) return null;
    if (user.accountStatus === accountStatuses.active) {
      if (canIManageCorporateAdmins) {
        if (user.accountType === accountTypes.corporateAdmin) {
          if (isCorporateOwner) {
            actions.push({
              label: t("USERS.SET_OWNER"),
              callback: () =>
                setConfirmTransferOwnershipModal({
                  userUid: user.uid,
                  isOpen: true,
                }),
            });
          }
          actions.push({
            label: t("USERS.REMOVE_ADMIN"),
            callback: () => dispatch(removeAsAdmin({ userUid: user.uid })),
          });
        } else {
          actions.push({
            label: t("USERS.ADD_ADMIN"),
            callback: () => dispatch(setAsAdmin({ userUid: user.uid })),
          });
        }
        actions.push({ label: "Divider" });
        // For business users, ask to upgrade instead of setting as admin.
      } else if (isPlanBusiness) {
        actions.push({
          label: t("USERS.ADD_ADMIN"),
          callback: () => setRequireUpgradeModal({ isOpen: true }),
        });
        actions.push({ label: "Divider" });
      }
      actions.push({
        label: t("USERS.MODIFY_JOB_TITLE"),
        callback: () => setEditUserRoleModal({ userUid: user.uid, isOpen: true }),
      });
      if (user.accountType === accountTypes.corporateMember) {
        actions.push({
          label: t("USERS.MODIFY_USER_QUOTA"),
          callback: () => setEditUserQuotaModal({ userUid: user.uid, isOpen: true }),
        });
      }
      actions.push({
        label: t("USERS.MANAGE_NUMBERS"),
        callback: () => setAssignNumbersModal({ userUid: user.uid, isOpen: true }),
      });
      if (user.uid !== myId) {
        actions.push({ label: "Divider" });
        actions.push({
          label: t("USERS.DISABLE_USER"),
          callback: () => setConfirmDisableUserModal({ userUid: user.uid, isOpen: true }),
        });
      }
    }
    if (user.accountStatus === accountStatuses.waitingForSignup) {
      actions.push({
        label: t("USERS.RESEND_INVITATION"),
        callback: () => setConfirmResendInvitationModal({ userUid: user.uid, isOpen: true }),
      });
    }
    if (user.accountStatus === accountStatuses.disabled) {
      actions.push({
        label: t("USERS.ENABLE_USER"),
        callback: () => dispatch(corporateAsyncActions.enableUser({ userUid: user.uid })),
      });
    }
    if (user.uid !== myId) {
      actions.push({
        label: t("USERS.REMOVE_USER"),
        callback: () => {
          setConfirmDeleteUserModal({ userUid: user.uid, isOpen: true });
        },
      });
    }
    return actions;
  };

  return (
    <div className={classes.root}>
      {canDrag && draggable && (
        <FontAwesomeIcon icon={faArrowsAlt} size="2x" className={classes.icon} />
      )}
      <div className={classes.avatar}>
        <MemberAvatar member={user} />
      </div>
      <div className={classes.userInfo}>
        {user.name ? (
          <div>
            <Typography variant="body2" component="span">
              {t("FORMS.NAME_COLON")}{" "}
            </Typography>
            <Typography variant="body" component="span">
              {user.name}
            </Typography>
          </div>
        ) : null}
        {user.role ? (
          <div className={classes.jobTitle}>
            <Typography variant="body2" component="span">
              {t("USERS.JOB_TITLE_COLON")}{" "}
            </Typography>
            <Typography variant="body" component="span">
              {user.role}
            </Typography>
          </div>
        ) : null}
        {user.email ? (
          <div>
            <Typography
              variant="body"
              component="div"
              sx={{
                display: "block",
                maxWidth: "364px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {t("FORMS.EMAIL_COLON")} {user.email}
            </Typography>
          </div>
        ) : null}
        {user.numbers.length > 0 ? (
          <div>
            <Typography variant="body2" component="span">
              {t("USERS.NUMBERS_COLON")}{" "}
            </Typography>
            <Typography variant="body" component="span">
              {user.numbers.length > 1
                ? `${user.numbers.length} ${t("PROFILE.NUMBERS").toLocaleLowerCase()}`
                : user.numbers.length === 1 && user.numbers[0]}
            </Typography>
          </div>
        ) : null}
        {displayStatus ? <Typography variant="subtitle">{displayStatus}</Typography> : null}
      </div>
      <div className={classes.grow} />
      {user.accountType === accountTypes.corporateAdmin && (
        <Chip
          style={{ margin: 2, backgroundColor: theme.alohi.lightestBlue }}
          label={t("COMMON.ADMIN")}
        />
      )}
      {user.accountType === accountTypes.corporateOwner && (
        <Chip
          style={{ margin: 2, backgroundColor: theme.alohi.lightestBlue }}
          label={t("COMMON.OWNER")}
        />
      )}
      <div className={classes.actionsIcon}>
        <ColumnActions createActions={createActions} />
      </div>
      {confirmDeleteUserModal.isOpen && (
        <ConfirmDeleteUserModal
          userUid={confirmDeleteUserModal.userUid}
          handleClosure={() =>
            setConfirmDeleteUserModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {confirmDisableUserModal.isOpen && (
        <ConfirmDisableUserModal
          userUid={confirmDisableUserModal.userUid}
          handleClosure={() =>
            setConfirmDisableUserModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {editUserRoleModal.isOpen && (
        <EditUserJobTitleModal
          userUid={editUserRoleModal.userUid}
          handleClosure={() =>
            setEditUserRoleModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {editUserQuotaModal.isOpen && (
        <EditUserQuotaModal
          userUid={editUserQuotaModal.userUid}
          handleClosure={() =>
            setEditUserQuotaModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {assignNumbersModal.isOpen && (
        <ManageUserNumbersModal
          userUid={assignNumbersModal.userUid}
          handleClosure={() =>
            setAssignNumbersModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {confirmResendInvitationModal.isOpen && (
        <ConfirmResendInvitationModal
          userUid={confirmResendInvitationModal.userUid}
          handleClosure={() =>
            setConfirmResendInvitationModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {confirmTransferOwnershipModal.isOpen && (
        <ConfirmTransferOwnershipModal
          userUid={confirmTransferOwnershipModal.userUid}
          handleClosure={() =>
            setConfirmTransferOwnershipModal((current) => ({
              ...current,
              isOpen: false,
              userUid: null,
            }))
          }
        />
      )}
      {requireUpgradeModal.isOpen ? (
        <RequireUpgradeToEnterprise
          isPlural={false}
          title={t("USERS.MULTI_ADMINS")}
          featureName={t("USERS.MULTI_ADMINS_FEATURE")}
          handleClosure={() => setRequireUpgradeModal({ isOpen: false })}
        />
      ) : null}
    </div>
  );
}

const useStyles = makeStyles(({ alohi, spacing, palette }) => ({
  root: (props) => ({
    display: "flex",
    position: "relative",
    height: "100%",
    border: `solid 1px ${alohi.lighterGray}`,
    padding: spacing(0, 1, 0, 3),
    flex: "1 0 auto",
    alignItems: "center",
    backgroundColor: props.draggable ? alohi.white : alohi.lighterGray,
  }),
  icon: {
    color: alohi.gray,
    marginRight: spacing(2),
  },
  avatar: {
    marginRight: spacing(2),
  },
  userInfo: {
    display: "flex",
    flex: "0 1 auto",
    overflow: "hidden",
    paddingRight: "20px",
    flexDirection: "column",
  },
  grow: {
    flexGrow: 1,
  },
  disabledStatus: {
    color: palette.secondary.main,
  },
  actionsIcon: {
    width: 40,
    margin: spacing(1),
  },
  jobTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 400,
  },
}));

UsersHierarchyTreeUserNode.propTypes = {
  node: PropTypes.shape({
    role: PropTypes.string,
    accountStatus: PropTypes.string,
    accountType: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    numbers: PropTypes.arrayOf(PropTypes.string),
    profileImage: PropTypes.string,
    uid: PropTypes.string,
    expanded: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.object),
  }),
  canDrag: PropTypes.bool,
};

export default UsersHierarchyTreeUserNode;
