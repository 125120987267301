import PropTypes from "prop-types";
import { useSnackbar } from "@alohi/kit";
import { memo, useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import { selectIsNewBundleSuccessful } from "selectors/address.selector";
import {
  getBundles,
  clearPostNewBundle,
  clearAddressPowerset,
} from "stores/reducers/address.reducer";

function NewBundleRedirect({ handleClosure }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cleanReducers = useCleanPlansReducers();
  const isBundleSuccessful = useSelector(selectIsNewBundleSuccessful);

  useEffect(() => {
    if (isBundleSuccessful) {
      enqueueSnackbar(t("ADDRESS.BUNDLE_SUCCESS"), { variant: "success" });
      dispatch(clearPostNewBundle());
      dispatch(clearAddressPowerset());
      dispatch(getBundles());
      cleanReducers();
      // Unmount here by redirecting to Inbox view where the banner with the pending bundle will be shown.
      history.replace("/faxes/inbox");
      handleClosure(null);
    }
  }, [t, enqueueSnackbar, handleClosure, isBundleSuccessful, dispatch, history, cleanReducers]);

  return null;
}

NewBundleRedirect.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default memo(NewBundleRedirect);
