import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { formatCdrDuration } from "helpers/date";
import { InboxStatusFilter } from "components/Core/Filters/index";
import SentStatusFilter from "./components/SentStatusFilter";

export const FaxStatus = ({ cdr }) => {
  const formatted = formatCdrDuration(cdr.duration) ?? "";
  const { t } = useTranslation();

  if (!cdr) return;
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" mb={3}>
        {cdr.track[0].direction === "incoming" ? (
          <Box fontWeight="fontWeightBold">
            {t("FAXES.STATUS_RECEIVE")}
            {t("COMMON.COLON")}
          </Box>
        ) : (
          <Box fontWeight="fontWeightBold">
            {t("FAXES.STATUS_SEND")}
            {t("COMMON.COLON")}
          </Box>
        )}

        {/* TODO:  impl Telco Area filter*/}
        <Box fontWeight="fontWeightBold">
          {cdr.track[0].direction === "incoming" ? (
            <InboxStatusFilter value={cdr.track[0].data.status} />
          ) : (
            <SentStatusFilter value={cdr.track[0].data.status} />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {t("FAXES.STATUS_DURATION")}
          {t("COMMON.COLON")}
        </Box>
        <Box>{formatted}</Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {t("FAXES.STATUS_PAGES_NB")}
          {t("COMMON.COLON")}
        </Box>
        <Box>{cdr.track[0].data.pages}</Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {t("FAXES.STATUS_TELCO_AREA")}
          {t("COMMON.COLON")}
        </Box>
        {/* TODO:  impl Telco Area filter*/}
        <Box>
          {!cdr.track[0].data.cost_details.multiplier && !cdr.duration
            ? 0
            : cdr.track[0].data.cost_details.multiplier}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between">
        ----------------------------------------------
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {t("FAXES.STATUS_COST")}
          {t("COMMON.COLON")}
        </Box>
        <Box>{cdr.track[0].cost}</Box>
      </Box>
    </Box>
  );
};

FaxStatus.propTypes = {
  cdr: PropTypes.object,
};
