import { Box, useOnMount } from "@alohi/kit";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FormRowReactSelectInput from "components/Forms/FormRowReactSelectInput";
import { OptionCountry, SingleValueCountry } from "components/Payment/CustomizedSelect";
import { selectCurrentCreditCurrency } from "selectors/credit.selector";
import { selectAvailableCountries } from "selectors/plan.selector";
import { getAvailableCountries } from "stores/reducers/plans.reducer";

import { CreateAddressBundleContext } from "../../../context/createAddressBundle.context";

interface SelectCountryProps {
  preselectedCountry?: string;
  preselectedNumberType?: string;
}

function SelectCountry({ preselectedCountry, preselectedNumberType }: SelectCountryProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ country, numberType }, localDispatch] = useContext(CreateAddressBundleContext);

  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);
  const availableCountries: {
    iso: string;
    label: string;
    isAddressVerification: boolean;
    available_types: string[];
  }[] = useSelector(selectAvailableCountries);
  const availableNumberTypes = [
    { value: "local", label: t("ADD_NUMBER.LOCAL") },
    { value: "tollfree", label: t("ADD_NUMBER.TOLL_FREE") },
  ];

  const countriesRequiringPhoneType: string[] | undefined = availableCountries
    ?.filter(
      (availableCountry) =>
        availableCountry.isAddressVerification &&
        availableCountry.available_types.includes("local") &&
        availableCountry.available_types.includes("tollfree"),
    )
    .map((availableCountry) => availableCountry.iso);

  const handleChange = useCallback(
    (country: {
      iso: string;
      label: string;
      isAddressVerification: boolean;
      available_types: string[];
    }) => {
      localDispatch({
        type: "UPDATE_COUNTRY",
        payload: country.iso,
      });
      localDispatch({
        type: "UPDATE_NUMBER_TYPE",
        payload: preselectedNumberType ?? "local",
      });
    },
    [localDispatch, preselectedNumberType],
  );

  const handleNumberTypeChange = (numberType: { value: string; label: string }) => {
    localDispatch({
      type: "UPDATE_NUMBER_TYPE",
      payload: numberType.value,
    });
  };

  useOnMount(() => {
    if (!availableCountries) {
      dispatch(getAvailableCountries(currentCreditCurrency));
    }
  });

  useEffect(() => {
    if (availableCountries?.length && preselectedCountry) {
      const country = availableCountries.find(
        (availableCountry) => availableCountry.iso === preselectedCountry,
      );
      if (country) {
        handleChange(country);
      }
    }
  }, [availableCountries, handleChange, preselectedCountry]);

  return (
    <Box px={12} pt={1} pb={2}>
      <FormRowReactSelectInput
        id="select-country"
        label={t("COMMON.COUNTRY")}
        labelGridWidth={3}
        ReactSelectProps={{
          isSearchable: true,
          maxMenuHeight: 245,
          value: availableCountries?.find((availableCountry) => availableCountry.iso === country),
          onChange: handleChange,
          options: availableCountries?.filter(
            (availableCountry) => availableCountry.isAddressVerification,
          ),
          placeholder: t("COMMON.SELECT_COUNTRY"),
          components: { Option: OptionCountry, SingleValue: SingleValueCountry },
          hideRequireProofId: true,
        }}
      />
      {countriesRequiringPhoneType?.includes(country) ? (
        <FormRowReactSelectInput
          id="select-number-type"
          label={t("ADD_NUMBER.NUMBER_TYPE")}
          labelGridWidth={3}
          ReactSelectProps={{
            isSearchable: true,
            maxMenuHeight: 245,
            value: availableNumberTypes.find(
              (numberTypeAvailable) =>
                numberTypeAvailable.value === (preselectedNumberType ?? numberType),
            ),
            onChange: handleNumberTypeChange,
            options: availableNumberTypes.filter(
              (numberType) => !preselectedNumberType || numberType.value === preselectedNumberType,
            ),
            placeholder: t("COMMON.SELECT_COUNTRY"),
            hideRequireProofId: true,
          }}
        />
      ) : null}
    </Box>
  );
}

export default SelectCountry;
