import { Modal, Box } from "@alohi/kit";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { selectIsPlanEnterprise } from "selectors/plan.selector";
import { getPersonalAccessTokens } from "stores/reducers/integrations.api.reducer";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import { PersonalAccessTokens, selectPersonalAccessTokens } from "selectors/integrations.selector";
import Token from "./components/Token/Token";
import CreateTokenModal from "./modals/CreateTokenModal";
import EmptyPlaceholder from "./components/EmptyPlaceholder";

interface ManageTokensModalProps {
  onCancel: () => void;
}

function ManageTokensModal({ onCancel }: ManageTokensModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lastElementRef = useRef<HTMLDivElement>(null);
  const [isCreateTokenModalOpen, setIsCreateTokenModalOpen] = useState(false);

  const personalAccessTokens: PersonalAccessTokens = useSelector(selectPersonalAccessTokens);
  const planIsEnterprise = useSelector(selectIsPlanEnterprise);

  const { tokens } = personalAccessTokens;

  const handleConfirm = async () => {
    setIsCreateTokenModalOpen(true);
  };

  const onCreateTokenCancel = () => {
    setIsCreateTokenModalOpen(false);
  };

  const onCreateTokenConfirm = async () => {
    setIsCreateTokenModalOpen(false);
    await dispatch(getPersonalAccessTokens());
    scrollToLastElement();
  };

  const scrollToLastElement = () => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!planIsEnterprise) {
    return (
      <RequireUpgradeToEnterprise
        handleClosure={onCancel}
        isPlural
        title={t("API_TOKEN.TITLE")}
        featureName={t("API_TOKEN.TITLE")}
      />
    );
  }
  return (
    <>
      <Modal
        maxWidth="xs"
        onCancel={onCancel}
        onConfirm={handleConfirm}
        isConfirmDisabled={false}
        title={t("API_TOKEN.TITLE")}
        cancelTitle={t("COMMON.CLOSE")}
        confirmTitle={t("API_TOKEN.CREATE")}
      >
        <Box p={3} display={"flex"} maxHeight={"50vh"}>
          {tokens.length === 0 ? (
            <EmptyPlaceholder />
          ) : (
            <Box height={"100%"} display={"flex"} flexDirection={"column"} flex={1}>
              <Box pb={2}>
                {tokens.map((token, index) => (
                  <Box key={token.id}>
                    <Token
                      ref={index === tokens.length - 1 ? lastElementRef : null}
                      token={token}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      {isCreateTokenModalOpen ? (
        <CreateTokenModal onCancel={onCreateTokenCancel} onConfirm={onCreateTokenConfirm} />
      ) : null}
    </>
  );
}

export default ManageTokensModal;
