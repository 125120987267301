export default {
  appSwitcher: "appSwitcher",
  tabToInbox: "FaxesTabToInbox",
  tabToSent: "FaxesTabToSent",
  tabToOutbox: "FaxesTabToOutbox",
  tabToTrash: "FaxesTabToTrash",
  myFaxPanel: "myFaxPanel",
  requestSocModal: "requestSocModal",
  documentRetentionPanel: "documentRetentionPanel",
  sentFaxesSelect: "sentFaxesSelect",
  receivedFaxesSelect: "receivedFaxesSelect",
  sentFaxesInput: "sentFaxesInput",
  receivedFaxesInput: "receivedFaxesInput",
  sendFaxNoteButton: "sendFaxNoteButton",
  faxReceptionSwitchToggle: "faxReceptionSwitchToggle",
  phoneSlashIcon: "phoneSlashIcon",
  dataResidencyPanel: "dataResidencyPanel",
  hipaaPanel: "hipaaPanel",
  complianceDocumentsPanel: "complianceDocumentsPanel",
  regulatoryCompliancePanel: "regulatoryCompliancePanel",
  requestHighVolumePlanButton: "requestHighVolumePlanButton",
  highVolumePlanModal: "highVolumePlanModal",
  sendFaxSettingButton: "sendFaxSettingButton",
  scheduledTransmissionTimeInputContainer: "scheduledTransmissionTimeInputContainer",
  scheduledTransmissionTimeZoneInputContainer: "scheduledTransmissionTimeZoneInputContainer",
  sendFaxSettingsModal: "sendFaxSettingsModal",
  addToBlacklistButton: "addToBlacklistButton",
  inviteUsersModal: "inviteUsersModal",
  companyInfoPanel: `companyInfoPanel`,
  planDetailsPanel: `planDetailsPanel`,
  planDetailsPanelPlanType: `planDetailsPanelPlanType`,
  planDetailsPanelRemainingPages: `planDetailsPanelRemainingPages`,
  alohiSuitePanel: `alohiSuitePanel`,
  apiLogoUploadInput: `apiLogoUploadInput`,
  sendFaxDropzoneHiddenInput: `SendFaxInput`,
  sendFaxFilePickerHiddenInput: `SendFaxFilePickerInput`,
  filePicker: `filePicker`,
  anonymousButton: "anonymousButton",
};
