import { Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "ui";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import { setNextPlan, clearNextPlan, getAvailablePlans } from "stores/reducers/plans.reducer";
import {
  selectNextPlan,
  selectCurrentPlan,
  selectAvailablePlans,
  selectPlanPurchaseId,
} from "selectors/plan.selector";
import FormRowReactSelectInput from "../Forms/FormRowReactSelectInput";
import AvailablePlansTooltip from "./AvailablePlansTooltip";
import { GroupHeadingPlan, OptionPlan, GroupPlan, SingleValuePlan } from "./CustomizedSelect";

function AvailablePlans() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nextPlan = useSelector(selectNextPlan);
  const cleanReducers = useCleanPlansReducers();
  const currentPlan = useSelector(selectCurrentPlan);
  const availablePlans = useSelector(selectAvailablePlans);
  const planPurchaseId = useSelector(selectPlanPurchaseId);

  useEffect(() => {
    if (planPurchaseId && !nextPlan) {
      dispatch(getAvailablePlans(planPurchaseId));
    }
  }, [dispatch, planPurchaseId, nextPlan]);

  useEffect(() => {
    if (availablePlans && !nextPlan) {
      dispatch(setNextPlan(availablePlans, false, ""));
    }
  }, [availablePlans, nextPlan, currentPlan, dispatch]);

  useEffect(() => () => dispatch(clearNextPlan()), [dispatch]);

  const handleOnChange = useCallback(
    (chosenPlan) => {
      const prevPlan = nextPlan; // in this context nextPlan is previous plan because it has the last value from the store
      dispatch(setNextPlan(chosenPlan, true, ""));
      // Don't clean number selection if the switch is between enterprise and business
      if (
        (prevPlan.plan_type === "enterprise" || prevPlan.plan_type === "business") &&
        (chosenPlan.plan_type === "enterprise" || chosenPlan.plan_type === "business")
      ) {
        return;
      }
      // Clean if user changes the plan from basic to enterprise or premium to business
      // Don't clean if the user changes the plan from basic to basic
      if (chosenPlan.id !== prevPlan.id) {
        cleanReducers();
      }
    },
    [dispatch, nextPlan, cleanReducers],
  );

  const selectStyles = {
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    group: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    groupHeading: (provided) => ({
      ...provided,
      flex: 1,
      padding: "5px",
      color: theme.alohi.gray,
      fontSize: "1em",
      cursor: "pointer",
      fontWeight: "bold",
      textTransform: "capitalize",
    }),
  };

  return (
    <Box display="flex" alignItems="center" pr="22%">
      <FormRowReactSelectInput
        id="select-plan"
        label={t("COMMON.PLAN")}
        labelGridWidth={5}
        ReactSelectProps={{
          isSearchable: false,
          maxMenuHeight: 245,
          value: nextPlan,
          onChange: handleOnChange,
          options: availablePlans,
          placeholder: t("CHANGE_PLAN.SELECT_PLAN"),
          components: {
            Group: GroupPlan,
            Option: OptionPlan,
            SingleValue: SingleValuePlan,
            GroupHeading: GroupHeadingPlan,
          },
          styles: selectStyles,
        }}
      />
      <Box width={0} style={{ transform: "translate(16px, 0)" }}>
        <AvailablePlansTooltip availablePlans={availablePlans} />
      </Box>
    </Box>
  );
}

export default AvailablePlans;
