import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, makeStyles, Box, Typography } from "@alohi/kit";

import useCart from "hooks/useCart";
import Currency from "components/Core/Currency/Currency";
import { selectIsBundleRequiredToBeCreated } from "selectors/plan.selector";
import { selectCurrentCredit, selectCurrentCreditCurrency } from "selectors/credit.selector";
import {
  selectBulkNumbers,
  selectVerifyNumber,
  selectVerifyCustomNumber,
  selectSelectedNumberWithBundle,
} from "selectors/verifications.selector";
import CartTooltip from "../CartTooltip";

function ChangeOrAddNumberFooterInfo() {
  const classes = useStyles();
  const { t } = useTranslation();

  const credit = useSelector(selectCurrentCredit);
  const bulkNumbers = useSelector(selectBulkNumbers);
  const selectedNumber = useSelector(selectVerifyNumber);
  const creditCurrency = useSelector(selectCurrentCreditCurrency);
  const selectedCustomNumber = useSelector(selectVerifyCustomNumber);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);
  const selectedNumberWithBundle = useSelector(selectSelectedNumberWithBundle);

  const [{ cartTotalAmount }, { addIdToCart, updateCart }] = useCart(false);

  const [lastNumberWithBundleId, setLastNumberWithBundleId] = useState();
  const [lastSelectedNumberId, setLastSelectedNumberId] = useState();
  const [lastSelectedCustomNumberId, setLastSelectedCustomNumberId] = useState();

  useEffect(() => {
    if (selectedNumber?.id && selectedNumber?.id !== lastSelectedNumberId) {
      addIdToCart(selectedNumber.id);
      setLastSelectedNumberId(selectedNumber.id);
      updateCart();
    }
    if (selectedCustomNumber?.id && selectedCustomNumber?.id !== lastSelectedCustomNumberId) {
      addIdToCart(selectedCustomNumber.id);
      setLastSelectedCustomNumberId(selectedCustomNumber.id);
      updateCart();
    }
    if (selectedNumberWithBundle?.id && selectedNumberWithBundle?.id !== lastNumberWithBundleId) {
      addIdToCart(selectedNumberWithBundle.id);
      setLastNumberWithBundleId(selectedNumberWithBundle.id);
      updateCart();
    }
    if (bulkNumbers) {
      bulkNumbers.forEach((id) => addIdToCart(id));
      updateCart();
    }
  }, [
    addIdToCart,
    bulkNumbers,
    lastNumberWithBundleId,
    lastSelectedCustomNumberId,
    lastSelectedNumberId,
    selectedCustomNumber?.id,
    selectedNumber?.id,
    selectedNumberWithBundle?.id,
    updateCart,
  ]);

  if (isBundleRequiredToBeCreated) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="row">
          <Typography type="body">{t("CHANGE_PLAN.CREDIT_NEEDED_FOR_CHANGE")}</Typography>
          <CartTooltip />
        </Box>
        <Typography component="div" type="bold" style={{ textAlign: "end", minWidth: "100px" }}>
          {cartTotalAmount !== null && creditCurrency ? (
            <Currency value={cartTotalAmount} currency={creditCurrency} />
          ) : null}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Typography type="body">{t("ADD_NUMBER.YOUR_CURRENT_CREDIT")}</Typography>
        <div style={{ textAlign: "end", minWidth: "100px" }}>
          {credit !== null && creditCurrency ? (
            <Typography variant="bold" className={classes.credit}>
              <Currency value={credit} currency={creditCurrency} />
            </Typography>
          ) : null}
        </div>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  root: {
    flex: 1,
    display: "flex",
    borderRadius: "8px",
    padding: spacing(3),
    flexDirection: "column",
    backgroundColor: alohi.neutral100,
    margin: spacing(3, 3, 0, 3),
    border: `1px solid ${alohi.neutral200}`,
  },
  divider: {
    margin: spacing(1.5, 0),
  },
  credit: {
    color: alohi.green,
  },
}));

export default ChangeOrAddNumberFooterInfo;
