import { render } from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { DndProvider } from "react-dnd";
import TagManager from "react-gtm-module";
import { MainProvider } from "@alohi/kit";
import { I18nextProvider } from "react-i18next";
import { init as initApm } from "@elastic/apm-rum";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { MultiBackend } from "react-dnd-multi-backend";

import {
  VERSION,
  NODE_ENV,
  SENTRY_SDK,
  KIBANA_RUM_URL,
  KIBANA_RUM_NAME,
  GOOGLE_TAG_MANAGER_ID,
} from "config";
import i18n from "i18n"; // initialized i18next instance
import { ThemeProvider } from "ui";
import { store } from "stores/store";

import App from "./App";
import { ApolloProvider } from "api/gql/provider/ApolloProvider";

// Extending window globally
// https://github.com/microsoft/TypeScript/issues/33128
declare global {
  interface Window {
    __loadingScreen?: { show: () => void; hide: () => void };
    lottie: {
      loadAnimation: (options: unknown) => {
        play: () => void;
      };
    };
  }
}

// Sentry
if (SENTRY_SDK) {
  Sentry.init({
    dsn: SENTRY_SDK,
    release: VERSION,
  });
}

// Kibana APM RUM
if (NODE_ENV === "production" && KIBANA_RUM_URL) {
  initApm({
    serviceName: KIBANA_RUM_NAME,
    serverUrl: KIBANA_RUM_URL,
    serviceVersion: VERSION,
  });
}

// Google Tag Manager
if (GOOGLE_TAG_MANAGER_ID) {
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID,
  });
}

render(
  <MainProvider>
    <I18nextProvider i18n={i18n}>
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <ThemeProvider>
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
              <ApolloProvider>
                <App />
              </ApolloProvider>
            </DndProvider>
          </ThemeProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </I18nextProvider>
  </MainProvider>,
  document.getElementById("root"),
);
