import { useState, useRef, useEffect, useCallback } from "react";

function useCountdown(seconds = null) {
  const countdown = useRef();

  const [currentValue, setCurrentValue] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const stop = useCallback(() => {
    setIsRunning(false);
    clearInterval(countdown.current);
  }, []);

  const tick = useCallback(() => {
    setCurrentValue((prevTime) => {
      if (prevTime === 0) {
        stop();
      } else {
        return prevTime - 1;
      }
    });
  }, [stop]);

  const start = useCallback(
    (seconds) => {
      if (!Number.isSafeInteger(seconds)) throw Error("Value provided isn't valid.");
      setCurrentValue(seconds);
      setIsRunning(true);
      countdown.current = setInterval(tick, 1000);
    },
    [tick],
  );

  useEffect(() => {
    seconds && start(seconds);
    return () => stop();
  }, [seconds, start, stop]);

  return {
    seconds: currentValue,
    isRunning,
    start,
    stop,
  };
}

export default useCountdown;
