import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Modal, Typography } from "@alohi/kit";

import { useAppDispatch } from "stores/store";
import { getNotifications } from "stores/reducers/account.notifications.reducer";
import { getSlackToken, deactivateSlack } from "stores/reducers/integrations.slack.reducer";

function ConfirmDeactivateSlackModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleConfirm = async () => {
    setIsButtonLoading(true);
    await dispatch(deactivateSlack());
    await dispatch(getSlackToken());
    await dispatch(getNotifications());
    handleClosure();
  };

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isConfirmLoading={isButtonLoading}
      isConfirmDisabled={isButtonLoading}
      title={t("DELETE_ITEM.ARE_YOU_SURE")}
    >
      <Box p={3}>
        <Typography>{t("SLACK.ARE_YOU_SURE_TO_REVOKE")}</Typography>
      </Box>
    </Modal>
  );
}

ConfirmDeactivateSlackModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ConfirmDeactivateSlackModal;
