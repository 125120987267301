import PropTypes from "prop-types";
import _capitalize from "lodash/capitalize";
const UserStatusFilter = ({ value }) => {
  // return(currency_map[children.toUpperCase()]?currency_map[children.toUpperCase()]:"");
  let filtered = value;
  switch (value.toLowerCase()) {
    case "active":
      filtered = "Active";
      break;
    case "waiting_for_signup":
      filtered = "Pending User Confirmation";
      break;
    case "unverified_phone":
      filtered = "Unverified Phone";
      break;

    default:
      break;
  }
  return _capitalize(filtered);
};

UserStatusFilter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default UserStatusFilter;
