import { useCallback, useEffect } from "react";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import { Box, makeStyles, Modal, Typography, useSnackbar } from "@alohi/kit";

import { CodeInput } from "ui";
import { useInput } from "hooks/useInput";
import { useAppDispatch, useAppSelector } from "stores/store";
import { isValidEmailVerificationCode } from "helpers/inputValidation";
import { getNotifications } from "stores/reducers/account.notifications.reducer";
import { clearVerifyEmail, verifyEmail } from "stores/reducers/verifications.reducer";
import {
  selectVerifyEmail,
  selectVerifyEmailError,
  selectIsVerifyEmailLoading,
} from "selectors/verifications.selector";

interface ChangeEmailModalProps {
  email: string;
  handleClosure: (isSuccess: boolean) => void;
}

function ChangeEmailModal({ email, handleClosure }: ChangeEmailModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isVerifyEmail = useAppSelector(selectVerifyEmail);
  const verifyEmailError = useAppSelector(selectVerifyEmailError);
  const isVerifyEmailLoading = useAppSelector(selectIsVerifyEmailLoading);

  const [code, codeInput] = useInput<string>("", isValidEmailVerificationCode);

  const handleCheckCode = useCallback(async () => {
    codeInput.touch();

    if (codeInput.isValid) {
      type DispatchInput = Record<string, unknown>;
      type DispatchReturn = AsyncThunkAction<DispatchInput, void, DispatchInput>;

      const verifyEmailTyped = verifyEmail as (input: DispatchInput) => DispatchReturn;

      dispatch(
        verifyEmailTyped({
          code,
          email,
        }),
      );
    }
  }, [code, codeInput, dispatch, email]);

  useEffect(() => {
    if (isVerifyEmail) {
      dispatch(clearVerifyEmail());
      dispatch(getNotifications());
      handleClosure(true);
    }
  }, [dispatch, isVerifyEmail, handleClosure]);

  useEffect(() => {
    if (verifyEmailError) {
      enqueueSnackbar(verifyEmailError, { variant: "error" });
      dispatch(clearVerifyEmail());
    }
  }, [dispatch, enqueueSnackbar, verifyEmailError]);

  return (
    <Modal
      maxWidth="xs"
      onConfirm={handleCheckCode}
      onPressEnter={handleCheckCode}
      cancelTitle={t("COMMON.CANCEL")}
      confirmTitle={t("COMMON.CONFIRM")}
      onCancel={() => handleClosure(false)}
      isModalDisabled={isVerifyEmailLoading}
      title={t("PROFILE.CHANGE_YOUR_EMAIL")}
      isConfirmLoading={isVerifyEmailLoading}
      isConfirmDisabled={codeInput.showsError}
      onPressEscape={() => handleClosure(false)}
    >
      <Box className={classes.root}>
        <Typography type="body">
          <Trans
            values={{ email }}
            i18nKey="VERIFY.INSERT_CODE"
            components={{ strong: <strong /> }}
          />
        </Typography>
        <Box className={classes.code}>
          <CodeInput
            autoFocus
            fields={6}
            value={code}
            id="VerificationCodeInput"
            onChange={codeInput.setValue}
            isError={codeInput.showsError}
          />
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(3),
  },
  code: {
    display: "flex",
    marginTop: spacing(3),
    justifyContent: "center",
  },
}));

export default ChangeEmailModal;
