import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography, Modal, Box, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { selectAccountEmail } from "selectors/account.selector";
import { getCustomerInfo } from "stores/reducers/account.reducer";
import { isValidPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "helpers/inputValidation";
import {
  deactivateCorporateTwoFa,
  clearDeactivateCorporateTwoFa,
} from "stores/reducers/twoFa.reducer";
import {
  selectIsEnforceTwoFaLoading,
  selectIsDeactivateCorporateTwoFaError,
  selectIsDeactivateCorporateTwoFaSuccess,
  selectDeactivateCorporateTwoFaErrorInvalidPassword,
} from "selectors/authentication.selector";

function DeactivateCorporateTwoFaModal({ handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const accountEmail = useSelector(selectAccountEmail);
  const isLoading = useSelector(selectIsEnforceTwoFaLoading);
  const error = useSelector(selectIsDeactivateCorporateTwoFaError);
  const success = useSelector(selectIsDeactivateCorporateTwoFaSuccess);
  const errorInvalidPassword = useSelector(selectDeactivateCorporateTwoFaErrorInvalidPassword);

  const [password, passwordInput] = useInput("", isValidPassword);

  const handleOnConfirm = useCallback(() => {
    passwordInput.touch();

    if (passwordInput.isValid) {
      dispatch(
        deactivateCorporateTwoFa({
          password: password,
          username: accountEmail,
        }),
      );
    }
  }, [accountEmail, dispatch, password, passwordInput]);

  useEffect(() => {
    if (error) {
      if (errorInvalidPassword) {
        enqueueSnackbar(t("FORMS.INVALID_PASSWORD"), { variant: "error" });
        passwordInput.setShowsError(true);
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
      dispatch(clearDeactivateCorporateTwoFa());
    }
  }, [t, dispatch, enqueueSnackbar, error, errorInvalidPassword, passwordInput]);

  useEffect(() => {
    if (success) {
      dispatch(getCustomerInfo());
      dispatch(clearDeactivateCorporateTwoFa());
      handleClosure();
    }
  }, [handleClosure, dispatch, success]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleOnConfirm}
      isConfirmLoading={isLoading}
      title={t("PROFILE.ENFORCE2FA_TITLE")}
      isConfirmDisabled={passwordInput.showsError}
    >
      <Box p={3}>
        <Typography type="body">{t("PROFILE.2FA_DEACTIVATE_ENFORCE_2FA_DESCRIPTION")}</Typography>
        <Box my={2}>
          <TextField
            fullWidth
            id="password"
            type="password"
            value={password}
            onBlur={passwordInput.onBlur}
            onFocus={passwordInput.onFocus}
            error={passwordInput.showsError}
            placeholder={t("FORMS.PASSWORD")}
            onChange={passwordInput.onChange}
            helperText={
              passwordInput.showsError && !passwordInput.isValid
                ? t("FORMS.PASSWORD_LENGTH_RANGE", {
                    min: MIN_PASSWORD_LENGTH,
                    max: MAX_PASSWORD_LENGTH,
                  })
                : " "
            }
          />
        </Box>
      </Box>
    </Modal>
  );
}

DeactivateCorporateTwoFaModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default DeactivateCorporateTwoFaModal;
