import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select, SelectChangeEvent } from "@alohi/kit";

import dayjs from "helpers/date";
import { makeStyles, InputBase } from "ui";
import { capitalizeString, numberWithCommas } from "helpers/string";
import { updateDashboardSelectedPeriod } from "stores/reducers/corporate.reducer";
import { selectUsagePeriods, selectDashbordSelectedPeriod } from "selectors/corporate.selector";

interface UsagePeriods {
  to_date: string;
  from_date: string;
  plan_type: string;
  pages_allowed: number;
}

function DateRangeSelect() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedPeriod = useSelector(selectDashbordSelectedPeriod);
  const usagePeriods = useSelector(selectUsagePeriods) as UsagePeriods[] | null;

  const usagePeriodsWithDates = useMemo(() => {
    if (!usagePeriods) {
      return [];
    }
    let isPlanIncluded = false;
    const [firstPeriod] = usagePeriods;

    usagePeriods.forEach((period) => {
      if (
        period.pages_allowed !== firstPeriod.pages_allowed ||
        period.plan_type !== firstPeriod.plan_type
      ) {
        isPlanIncluded = true;
      }
    });

    return usagePeriods.map((period, index) => ({
      ...period,
      id: period.from_date + index,
      planInfo: isPlanIncluded
        ? `(${capitalizeString(period.plan_type)}${
            period.plan_type === "enterprise" ? ` - ${numberWithCommas(period.pages_allowed)}` : ""
          })`
        : "",
    }));
  }, [usagePeriods]);

  const handlePeriodChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as number;

      dispatch(updateDashboardSelectedPeriod(value));
    },
    [dispatch],
  );

  if (!usagePeriods) {
    return null;
  }

  return (
    <Select
      input={<InputBase />}
      value={selectedPeriod}
      onChange={handlePeriodChange}
      className={classes.filterBase}
    >
      {usagePeriodsWithDates.map(({ id, planInfo, to_date, from_date }, index) => (
        <MenuItem key={id} value={index}>
          {`${dayjs(from_date).format("MMM DD")} - ${dayjs(to_date).format("MMM DD")}`}
          {selectedPeriod === index ? "" : ` ${planInfo}`}
        </MenuItem>
      ))}
    </Select>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape, breakpoints }) => ({
  filterBase: {
    margin: spacing(1, 0),
    "& .MuiInputBase-input": {
      padding: spacing(1, 5, 1, 2),
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: 0,
      },
      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: 0,
      },
    },
    "&.MuiInputBase-root, & .MuiInputBase-root": {
      height: 32,
      borderRadius: shape.borderRadius,
      boxShadow: alohi.bannerShadow,
      border: `1px solid ${alohi.lighterGray}`,
      background: alohi.white,
      display: "flex",
      alignItems: "center",
      marginRight: spacing(2),
      [breakpoints.down("sm")]: {
        flexBasis: "100%",
        margin: spacing(0),
      },
    },
  },
}));

export default DateRangeSelect;
