import PropTypes from "prop-types";
import { Box, Modal } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import UserProgress from "../UserProgress/UserProgress";

function StepsModal({
  title,
  onForward,
  onBack,
  isNextHidden,
  isNextDisabled,
  backTitle,
  nextTitle,
  currentStep,
  stepNames,
  isModalDisabled,
  stepComponents,
  isNextLoading,
  height,
  confirmTooltip,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      height={height}
      scroll={"paper"}
      onConfirm={onForward}
      confirmTooltip={confirmTooltip}
      isConfirmLoading={isNextLoading}
      onCancel={!isNextHidden && onBack}
      confirmTitle={nextTitle ?? "Next"}
      isConfirmDisabled={isNextDisabled}
      isModalDisabled={isModalDisabled || isNextLoading}
      cancelTitle={backTitle ?? (currentStep === 1 ? t("COMMON.CANCEL") : t("COMMON.BACK"))}
    >
      <Box mt={2}>
        <UserProgress currentStep={currentStep} stepNames={stepNames} />
      </Box>

      {stepComponents[currentStep - 1]}
    </Modal>
  );
}

StepsModal.propTypes = {
  nextTitle: PropTypes.string,
  isModalDisabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onForward: PropTypes.func.isRequired,
  isNextLoading: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  isNextHidden: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  stepNames: PropTypes.arrayOf(PropTypes.node),
  stepComponents: PropTypes.arrayOf(PropTypes.node),
  backTitle: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmTooltip: PropTypes.string,
};

export default StepsModal;
