import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import {
  MenuItem,
  Select,
  Typography,
  TextButton,
  Panel,
  Button,
  Box,
  useSnackbar,
} from "@alohi/kit";

import { FormControl } from "ui";
import { selectIsPlanCorporate } from "selectors/plan.selector";
import EmailNotificationsModal from "views/Notifications/EmailNotificationsModal";
import SlackNotificationsModal from "views/Notifications/SlackNotificationsModal";
import {
  clearUpdateNotifications,
  getNotifications,
  updateNotifications,
} from "stores/reducers/account.notifications.reducer";
import {
  selectAccountIsCorporateMember,
  selectIsEnabledAdvancedSecurity,
} from "selectors/account.selector";
import {
  selectEmailNotificationsDisplayStatus,
  selectIsUpdateNotificationsError,
  selectIsUpdateNotificationsRunning,
  selectIsUpdateNotificationsSuccessful,
  selectNotificationAttachmentsAvailableOptions,
  selectNotificationAttachmentsOption,
  selectSlackNotificationsDisplayStatus,
  selectSlackNotificationsTargetChannel,
} from "selectors/account.notifications.selector";
import {
  selectIsGetSlackTestError,
  selectIsGetSlackTestRunning,
  selectIsGetSlackTestSuccess,
  selectIsSlackActive,
  selectSlackChannels,
} from "selectors/integrations.selector";
import {
  clearGetSlackTest,
  getSlackChannels,
  getSlackTest,
  getSlackToken,
} from "stores/reducers/integrations.slack.reducer";

function Notifications() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const isEnabledAdvancedSecurity = useSelector(selectIsEnabledAdvancedSecurity);
  const slackChannels = useSelector(selectSlackChannels);
  const notificationAttachmentsOption = useSelector(selectNotificationAttachmentsOption);
  const notificationAttachmentsAvailableOptions = useSelector(
    selectNotificationAttachmentsAvailableOptions,
  );
  const isSlackActive = useSelector(selectIsSlackActive);
  const slackDisplayStatus = useSelector(selectSlackNotificationsDisplayStatus);
  const targetSlackChannel = useSelector(selectSlackNotificationsTargetChannel);
  const emailDisplayStatus = useSelector(selectEmailNotificationsDisplayStatus);

  const isNotificationsError = useSelector(selectIsUpdateNotificationsError);
  const isNotificationsRunning = useSelector(selectIsUpdateNotificationsRunning);
  const isNotificationsSuccessful = useSelector(selectIsUpdateNotificationsSuccessful);
  const isSlackTestError = useSelector(selectIsGetSlackTestError);
  const isSlackTestRunning = useSelector(selectIsGetSlackTestRunning);
  const isSlackTestSuccess = useSelector(selectIsGetSlackTestSuccess);

  const [editMode, setEditMode] = useState(false);
  const [isEmailNotificationsModalOpen, setIsEmailNotificationsModalOpen] = useState(false);
  const [isSlackNotificationsModalOpen, setIsSlackNotificationsModalOpen] = useState(false);

  const [notificationAttachmentsInputValue, setNotificationAttachmentsInputValue] = useState(
    notificationAttachmentsOption?.value ?? null,
  );

  const onCancel = () => {
    setNotificationAttachmentsInputValue(notificationAttachmentsOption?.value ?? null);
    setEditMode(false);
  };

  useEffect(() => {
    if (isPlanCorporate || isCorporateMember) {
      !slackChannels && dispatch(getSlackChannels());
      dispatch(getSlackToken());
    }
  }, [isPlanCorporate, dispatch, isCorporateMember, slackChannels]);

  const handleOnConfirm = useCallback(() => {
    dispatch(
      updateNotifications({
        updateType: "confirmation",
        accountData: {
          notificationAttachments: notificationAttachmentsInputValue,
        },
      }),
    );
  }, [dispatch, notificationAttachmentsInputValue]);

  useEffect(() => {
    if (isNotificationsError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearUpdateNotifications());
      setEditMode(false);
    } else if (isNotificationsSuccessful) {
      dispatch(getNotifications());
      dispatch(clearUpdateNotifications());
      setEditMode(false);
    }
  }, [dispatch, t, enqueueSnackbar, isNotificationsError, isNotificationsSuccessful]);

  useEffect(() => {
    if (isSlackTestError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearGetSlackTest());
    }
  }, [dispatch, enqueueSnackbar, isSlackTestError, t]);

  useEffect(() => {
    return () => {
      dispatch(clearUpdateNotifications());
    };
  }, [dispatch]);

  return (
    <Panel
      id="notifications"
      headerTitle={t("PROFILE.NOTIFICATIONS")}
      isFlipped={editMode}
      sx={{ mb: 7 }}
    >
      <Panel.Front
        headerAction={
          <TextButton onClick={() => setEditMode(true)}>
            <Typography type="panelFrontHeader">{t("COMMON.EDIT")}</Typography>
            <Box sx={{ ml: 1, color: "white" }}>
              <FontAwesomeIcon icon={faPenToSquare} color="white" />
            </Box>
          </TextButton>
        }
      >
        <Panel.Row>
          <Panel.Cell variant="title">{t("COMMON.EMAIL")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Typography style={{ whiteSpace: "break-spaces" }}>{emailDisplayStatus}</Typography>
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("COMMON.SLACK")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Typography style={{ whiteSpace: "break-spaces" }}>{slackDisplayStatus}</Typography>
          </Panel.Cell>
          {isSlackActive && !!targetSlackChannel ? (
            <Panel.Cell variant="actions">
              {isSlackTestSuccess ? (
                <Button variant="white" isDisabled={isSlackTestSuccess}>
                  {t("SLACK.TEST_PASSED")}
                </Button>
              ) : (
                <Button
                  variant="white"
                  isDisabled={isSlackTestRunning}
                  onClick={() => {
                    dispatch(getSlackTest());
                  }}
                >
                  {t("SLACK.SENDING_A_TEST")}
                </Button>
              )}
            </Panel.Cell>
          ) : null}
        </Panel.Row>
        {!isEnabledAdvancedSecurity ? (
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.NOTIFICATION_ATTACHMENTS")}</Panel.Cell>
            <Panel.Cell variant="data">
              <Typography>{notificationAttachmentsOption?.name ?? ""}</Typography>
            </Panel.Cell>
          </Panel.Row>
        ) : null}
      </Panel.Front>

      <Panel.Back
        onCancel={onCancel}
        onConfirm={handleOnConfirm}
        isConfirmLoading={isNotificationsRunning}
        isPanelDisabled={isNotificationsRunning}
      >
        <Panel.Row>
          <Panel.Cell variant="title">{t("COMMON.EMAIL")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Typography style={{ whiteSpace: "break-spaces" }}>{emailDisplayStatus}</Typography>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button variant="white" onClick={() => setIsEmailNotificationsModalOpen(true)}>
              {t("PROFILE.MODIFY")}
            </Button>
            {isEmailNotificationsModalOpen ? (
              <EmailNotificationsModal
                handleClosure={() => setIsEmailNotificationsModalOpen(false)}
              />
            ) : null}
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("COMMON.SLACK")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Typography style={{ whiteSpace: "break-spaces" }}>{slackDisplayStatus}</Typography>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button
              variant="white"
              onClick={() => {
                setIsSlackNotificationsModalOpen(true);
              }}
            >
              {t("PROFILE.MODIFY")}
            </Button>

            {isSlackNotificationsModalOpen ? (
              <SlackNotificationsModal
                handleClosure={() => setIsSlackNotificationsModalOpen(false)}
              />
            ) : null}
          </Panel.Cell>
        </Panel.Row>
        {!isEnabledAdvancedSecurity ? (
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.NOTIFICATION_ATTACHMENTS")}</Panel.Cell>
            <Panel.Cell variant="data">
              <FormControl>
                <Select
                  id="confirmationPage"
                  value={notificationAttachmentsInputValue}
                  onChange={({ target }) =>
                    setNotificationAttachmentsInputValue(target.value as string)
                  }
                >
                  {notificationAttachmentsAvailableOptions.map(({ name, value }) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Panel.Cell>
          </Panel.Row>
        ) : null}
      </Panel.Back>
    </Panel>
  );
}

export default Notifications;
