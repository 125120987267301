import { Panel } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AdvancedSecurityControlRow from "components/Profile/Compliance/AdvancedSecurityControlRow";
import CorporateTwoFaRow from "components/Profile/Security/CorporateTwoFaRow";
import ActivityLogsRow from "components/Profile/Security/ActivityLogsRow";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import SsoRow from "./SsoRow";

function CompanyWideSettingsPanel() {
  const { t } = useTranslation();

  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  if (isCorporateMember) {
    return null;
  }

  return (
    <Panel id="company-wide-settings" headerTitle={t("PROFILE.ADVANCED_SECURITY_TITLE")}>
      <Panel.Front>
        <SsoRow />
        <CorporateTwoFaRow />
        <AdvancedSecurityControlRow withStatus withIcon={false} />
        <ActivityLogsRow />
      </Panel.Front>
    </Panel>
  );
}

export default CompanyWideSettingsPanel;
