import { memo } from "react";
import { Box, makeStyles, Typography } from "@alohi/kit";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { selectAllNumbers } from "selectors/numbers.selector";

interface FaxNumbersProps {
  className?: string;
}

function FaxNumbers({ className }: FaxNumbersProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const accountNumbers = useSelector(selectAllNumbers);

  return (
    <Box className={clsx(classes.base, className)}>
      <Typography isBold>{t("PROFILE.NUMBER_OF_FAX_NUMBERS")}</Typography>
      <Typography className={classes.label}>{`${accountNumbers?.length || 0}`}</Typography>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    [breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  label: {
    marginTop: spacing(0.5),
    [breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: spacing(0.5),
    },
  },
}));

export default memo(FaxNumbers);
