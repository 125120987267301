import { useTranslation } from "react-i18next";
import { Typography } from "ui";

function MoreOptionInfo() {
  const { t } = useTranslation();

  return <Typography>{t("PAGE_OPTIONS.CUSTOM_QUOTE")}</Typography>;
}

export default MoreOptionInfo;
