import PropTypes from "prop-types";
import { Checkbox, makeStyles } from "@alohi/kit";
import { useState, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAllCdrCheckbox, toggleCdrCheckbox } from "stores/reducers/faxes.reducer";
import {
  selectCdrId,
  selectBoxHeader,
  selectCdrThumbnail,
  selectIsCdrChecked,
  selectIsCdrLoading,
} from "selectors/faxes.selector";
import Loading from "../Loading/Loading";
import ThumbnailSvg from "./ThumbnailSvg";

function FaxCheckbox({ boxName, dataIndex, dataDescription, ...dataTableCheckboxProps }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCdrRow = dataDescription === "row-select";
  const index = isCdrRow ? dataIndex ?? 0 : -1;

  const headerCheckboxState = useSelector(selectBoxHeader(boxName));
  const thumbnail = useSelector(selectCdrThumbnail(boxName, index));
  const isDownloading = useSelector(selectIsCdrLoading(boxName, index));
  const cdrId = useSelector(selectCdrId(boxName, index));
  const isChecked = useSelector(selectIsCdrChecked(boxName, index));

  const [hoverState, setHoverState] = useState();

  const handleToggleCheckbox = useCallback(() => {
    dispatch(toggleCdrCheckbox({ cdrId, boxName }));
  }, [dispatch, cdrId, boxName]);

  const handleToggleHeaderCheckbox = useCallback(() => {
    dispatch(
      setAllCdrCheckbox({
        boxName,
        value: !headerCheckboxState.isChecked && !headerCheckboxState.isIndeterminate,
      }),
    );
  }, [boxName, dispatch, headerCheckboxState.isChecked, headerCheckboxState.isIndeterminate]);

  const isThumbnailHidden = isChecked || hoverState;

  if (!isCdrRow) {
    return (
      <div className={classes.center}>
        <Checkbox
          variant="dark"
          className={classes.headCheckbox}
          {...dataTableCheckboxProps}
          checked={headerCheckboxState.isChecked}
          indeterminate={headerCheckboxState.isIndeterminate}
          onChange={handleToggleHeaderCheckbox}
        />
      </div>
    );
  }

  if (isDownloading) {
    return (
      <div className={classes.root}>
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      {isThumbnailHidden ? (
        <div className={classes.center}>
          <Checkbox
            variant="dark"
            {...dataTableCheckboxProps}
            checked={isChecked}
            onChange={handleToggleCheckbox}
          />
        </div>
      ) : thumbnail ? (
        <img
          className={classes.thumbnail}
          src={`data:image/png;base64, ${thumbnail}`}
          alt="thumbnail"
        />
      ) : (
        <ThumbnailSvg id={cdrId} />
      )}
    </div>
  );
}

const useStyles = makeStyles(({ spacing, alohi, shape }) => ({
  root: {
    width: 47,
    height: 60,
    display: "grid",
    placeItems: "center",
    marginLeft: spacing(3), // 2 + accounting for the 8px dot
  },
  thumbnail: {
    width: 47,
    height: 60,
    borderRadius: shape.borderRadius,
    border: `1px solid ${alohi.lighterGray}`,
  },
  thumbnailPlaceHolder: {
    width: 50,
    height: 60,
  },
  center: {
    margin: "auto",
  },
  headCheckbox: {
    width: 47,
    marginLeft: spacing(3),
  },
}));

FaxCheckbox.propTypes = {
  isCdr: PropTypes.bool,
  cdrId: PropTypes.string,
  checked: PropTypes.bool,
  boxName: PropTypes.string,
  dataIndex: PropTypes.number,
  dataDescription: PropTypes.string,
};

export default memo(FaxCheckbox, (prevProps, nextProps) => prevProps.checked !== nextProps.checked);
