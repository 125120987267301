import { useTranslation } from "react-i18next";
import { CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Container, Box } from "@alohi/kit";
import { useCallback, useEffect, useState } from "react";

import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import FormRow from "components/Forms/FormRow";
import Loader from "components/Loading/Loading";
import { getI18nCountry } from "helpers/numbers";
import WithStripeHOC from "components/Payment/WithStripeHOC";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import CountrySelect from "components/Core/CountrySelect/CountrySelect";
import { selectDefaultCountry } from "selectors/verifications.selector";
import useStripeToCreatePaymentMethod from "hooks/useStripeToCreatePaymentMethod";
import {
  isValidPostalCode,
  isValidStreetName,
  isValidCardholderName,
} from "helpers/inputValidation";
import {
  updateCreditCardInfo,
  isCreditCardCompleted,
} from "stores/reducers/payment.creditCard.reducer";

function CreditCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { defaultCountry } = useSelector(selectDefaultCountry);
  const { isStripeLoading, CreditCardError } = useStripeToCreatePaymentMethod();

  const [isComplete, setIsComplete] = useState(false);
  const [isStripeError, isStripeErrorBool] = useBool(false);
  const [isStripeCompleted, setIsStripeCompleted] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState(false);

  const classes = useStyles({ stripeError: isStripeError });

  const [street, streetInput] = useInput("", isValidStreetName);
  const [name, nameInput] = useInput("", isValidCardholderName);
  const [zipcode, zipcodeInput] = useInput("", isValidPostalCode);
  const [country, countryInput] = useInput(defaultCountry ? getI18nCountry(defaultCountry) : "");

  const handleStripeOnChange = useCallback(
    ({ complete, error }) => {
      complete ? isStripeErrorBool.setFalse() : isStripeErrorBool.setTrue();
      setStripeErrorMessage(error?.message ?? " ");
      setIsStripeCompleted(complete);
    },
    [isStripeErrorBool],
  );

  useEffect(() => {
    const isEveryValueCompleted =
      nameInput.isValid && streetInput.isValid && zipcodeInput.isValid && countryInput.isValid;
    if (!isComplete && isEveryValueCompleted && isStripeCompleted) {
      nameInput.touch();
      setIsComplete(true);
      dispatch(isCreditCardCompleted(true));
    } else if ((isComplete && !isEveryValueCompleted) || !isStripeCompleted) {
      setIsComplete(false);
      dispatch(isCreditCardCompleted(false));
    }
  }, [
    dispatch,
    isComplete,
    nameInput,
    isStripeCompleted,
    streetInput.isValid,
    zipcodeInput.isValid,
    countryInput.isValid,
  ]);

  useEffect(() => {
    if (isComplete && country?.value) {
      dispatch(
        updateCreditCardInfo({
          name: name,
          street: street,
          zipcode: zipcode,
          country: country.value,
        }),
      );
    }
  }, [dispatch, isComplete, name, street, zipcode, country?.value]);

  const handleStripeBlur = useCallback(() => {
    isStripeCompleted ? isStripeErrorBool.setFalse() : isStripeErrorBool.setTrue();
  }, [isStripeErrorBool, isStripeCompleted]);

  return (
    <>
      <div className={classes.root}>
        <Container classes={{ root: classes.containerRoot }}>
          {!isStripeLoading ? (
            <Loader />
          ) : (
            <Box pr="15%" pl="5%" component="form">
              <FormRow
                labelGridWidth={4}
                label={t("ADD_CREDIT.CARD")}
                id="card-elements"
                error={isStripeError}
                helperText={stripeErrorMessage}
              >
                <CardElement
                  id="card-elements"
                  onBlur={handleStripeBlur}
                  onChange={handleStripeOnChange}
                  options={{ hidePostalCode: true }}
                  className={classes.baseCardElement}
                />
              </FormRow>
              <FormRowTextInput
                required
                name="name"
                type="text"
                id="card-name"
                labelGridWidth={4}
                value={name}
                onBlur={nameInput.onBlur}
                onFocus={nameInput.onFocus}
                error={nameInput.showsError}
                onChange={nameInput.onChange}
                label={t("ADD_CREDIT.CARDHOLDER_NAME")}
              />
              <FormRowTextInput
                required
                type="text"
                name="street"
                id="card-street"
                labelGridWidth={4}
                value={street}
                className={classes.street}
                onBlur={streetInput.onBlur}
                onFocus={streetInput.onFocus}
                error={streetInput.showsError}
                onChange={streetInput.onChange}
                label={t("ADD_CREDIT.STREET_ADDRESS")}
              />
              <FormRowTextInput
                required
                type="tell"
                name="zipcode"
                id="card-zipcode"
                labelGridWidth={4}
                value={zipcode}
                className={classes.zipCode}
                onBlur={zipcodeInput.onBlur}
                onFocus={zipcodeInput.onFocus}
                error={zipcodeInput.showsError}
                onChange={zipcodeInput.onChange}
                label={t("ADD_CREDIT.ZIP_CODE")}
              />
              <FormRow labelGridWidth={4} label={t("ADD_CREDIT.COUNTRY")} id="card-country">
                <CountrySelect
                  name="country"
                  value={country}
                  styles={countryStyles}
                  onBlur={countryInput.onBlur}
                  onFocus={countryInput.onFocus}
                  onChange={countryInput.setValue}
                />
              </FormRow>
            </Box>
          )}
        </Container>
      </div>
      {CreditCardError}
    </>
  );
}

const useStyles = makeStyles(({ spacing, breakpoints, alohi }) => ({
  root: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  containerRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.down("sm")]: {
      marginBottom: spacing(2),
    },
  },
  baseCardElement: ({ stripeError }) => ({
    color: "#555",
    fontWeight: 400,
    borderRadius: "4px",
    padding: "18.5px 14px",
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    border: `solid 1px ${stripeError ? "#f44336" : "rgba(0, 0, 0, 0.23)"}`,
  }),
  inputError: {
    color: alohi.red,
  },
}));

const countryStyles = {
  control: (styles) => ({ ...styles, margin: "8px auto" }),
};

export default WithStripeHOC(CreditCard);
