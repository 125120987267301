import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Modal, useSnackbar } from "@alohi/kit";

import { useAppDispatch, useAppSelector } from "stores/store";
import { actions, selectors, thunks } from "stores/reducers/coverSheet.reducer";
import { useSendFaxContext } from "../contexts/context";

interface ShareConfirmationModalProps {
  id: string;
  name: string;
  handleClosure: (isSuccess: boolean) => void;
}

function ShareConfirmationModal({ id, name, handleClosure }: ShareConfirmationModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    sendFaxDispatch,
    sendFaxStore: { galleryCoverSheet },
  } = useSendFaxContext();

  const updateType = useAppSelector(selectors.updateType);
  const isUpdateError = useAppSelector(selectors.isUpdateError);
  const isUpdateRunning = useAppSelector(selectors.isUpdateRunning);

  const handleConfirm = () => {
    if (id === galleryCoverSheet?.id) {
      sendFaxDispatch({
        type: "CLEAR_GALLERY_COVER_SHEET",
      });
    }

    dispatch(
      thunks.update({
        id,
        type: "isShared",
        data: { isShared: true },
      }),
    );
  };

  const handleCancel = () => {
    handleClosure(false);
  };

  useEffect(() => {
    if (updateType === "isShared") {
      enqueueSnackbar(t("COVER_SHEET.SHARED_SUCCESS"), { variant: "success" });

      dispatch(actions.clearUpdate());

      handleClosure(true);
    }
  }, [dispatch, enqueueSnackbar, handleClosure, t, updateType]);

  useEffect(() => {
    if (isUpdateError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });

      dispatch(actions.clearUpdate());
    }
  }, [dispatch, enqueueSnackbar, isUpdateError, t]);

  return (
    <Modal
      maxWidth="xs"
      disableAnimation
      disableRestoreFocus
      hasCloseIcon={false}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onPressEnter={handleConfirm}
      onPressEscape={handleCancel}
      isModalDisabled={isUpdateRunning}
      isConfirmLoading={isUpdateRunning}
      title={t("COVER_SHEET.MOVE_TO_SHARED")}
    >
      <Box p={3}>
        <Typography>
          {t(`COVER_SHEET.SHARE_WITH_TEAM_QUESTION`, {
            value_1: name,
          })}
        </Typography>
      </Box>
    </Modal>
  );
}

export default ShareConfirmationModal;
