import queryString from "query-string";

import { API_AFFOGATO, API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const getUsers = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/corporate/users`;
  return fetchWithRefresh(path, requestOptions);
};

const getHierarchy = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/corporate/v2/hierarchy`;
  return fetchWithRefresh(path, requestOptions);
};

const getAdminInfo = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/corporate/admin_info`;
  return fetchWithRefresh(path, requestOptions);
};

const listCorporateMembers = ({ type }) => {
  const requestOptions = {
    method: "GET",
  };

  const query = queryString.stringify(
    type === "SIGN_PLUS"
      ? {
          statuses: ["ACTIVE", "UNVERIFIED_PHONE", "WAITING_FOR_SIGNUP", "NOT_SIGNED_UP"],
          withoutLicenses: ["FAX_PLUS"],
        }
      : {
          statuses: ["ACTIVE", "UNVERIFIED_PHONE", "WAITING_FOR_SIGNUP", "NOT_SIGNED_UP"],
          withoutLicenses: ["SIGN_PLUS"],
        },
    { arrayFormat: "bracket" },
  );

  const path = `${API_AFFOGATO}/corporate/v2/members?${query}`;
  return fetchWithRefresh(path, requestOptions);
};

const inviteByEmails = (emails) => {
  const requestOptions = {
    method: "POST",
    body: emails.reduce((accumulator, currentValue) => {
      accumulator.push({ email: currentValue, role: "none" });
      return accumulator;
    }, []),
  };

  const path = `${API_AFFOGATO}/corporate/v2/members`;
  return fetchWithRefresh(path, requestOptions);
};

const resendInviteByEmail = (email) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      email,
      op: "resend_invitation",
    },
  };
  const path = `${API_AFFOGATO}/corporate/invitation`;
  return fetchWithRefresh(path, requestOptions);
};

const updateCompanyInfo = (companyInfo, getState) => {
  const newCompanyInfo = {
    ...companyInfo,
  };
  if (!("companyLogo" in companyInfo)) {
    newCompanyInfo.companyLogo = getState().account?.accountDetails?.company_logo ?? "";
  }
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "update_company_info",
      company_logo: newCompanyInfo.companyLogo,
      company_name: newCompanyInfo.companyName,
    },
  };
  const path = `${API_AFFOGATO}/corporate/users`;
  return fetchWithRefresh(path, requestOptions);
};

const updateDefaultCompanyNumber = (number) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "set_default_number",
      number: number,
    },
  };
  const path = `${API_AFFOGATO}/corporate/users`;
  return fetchWithRefresh(path, requestOptions);
};

const updateUsers = (users) => {
  const requestOptions = {
    method: "PUT",
    body: users,
  };
  const path = `${API_AFFOGATO}/corporate/users`;
  return fetchWithRefresh(path, requestOptions);
};

const getEmailByToken = (token) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/corporate/invitation?token=${token}`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true });
};

const deleteUser = (userUid) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      {
        op: "modify_account_status",
        value: "delete",
      },
    ],
  };
  const path = `${API_AFFOGATO}/corporate/users/${userUid}`;
  return fetchWithRefresh(path, requestOptions);
};

const enableUser = (userUid) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      {
        op: "modify_account_status",
        value: "enable",
      },
    ],
  };
  const path = `${API_AFFOGATO}/corporate/users/${userUid}`;
  return fetchWithRefresh(path, requestOptions);
};

const disableUser = (userUid) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      {
        op: "modify_account_status",
        value: "disable",
      },
    ],
  };
  const path = `${API_AFFOGATO}/corporate/users/${userUid}`;
  return fetchWithRefresh(path, requestOptions);
};

const updateUserQuota = ({ userUid, quota }) => {
  const requestOptions = {
    method: "PATCH",
    body: [{ op: "set_quota", amount: quota }],
  };
  const path = `${API_AFFOGATO}/corporate/users/${userUid}`;
  return fetchWithRefresh(path, requestOptions);
};

const updateUserRole = ({ userUid, role }) => {
  const requestOptions = {
    method: "PATCH",
    body: [{ op: "set_role", role }],
  };
  const path = `${API_AFFOGATO}/corporate/users/${userUid}`;
  return fetchWithRefresh(path, requestOptions);
};

const assignAndRevokeUserNumbers = ({ userUid, numbersToRevoke = [], numbersToAssign = [] }) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      ...numbersToRevoke.map((number) => ({
        op: "revoke_number",
        number,
      })),
      ...numbersToAssign.map((number) => ({
        op: "assign_number",
        number,
      })),
    ],
  };
  const path = `${API_AFFOGATO}/corporate/users/${userUid}`;
  return fetchWithRefresh(path, requestOptions);
};

const getQuotaByUid = (uid) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/corporate/users/${uid}/quota`;
  return fetchWithRefresh(path, requestOptions);
};

const getLimits = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/corporate/limits/plans`;
  return fetchWithRefresh(path, requestOptions);
};

const getUsagePeriods = ({ period, cid }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/last_usage_periods/${cid}?${queryString.stringify({
    number: period,
  })}`;
  return fetchWithRefresh(path, requestOptions);
};

const getPageConsumption = ({ cid, fromDate, toDate }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/page_consumption/${cid}?${queryString.stringify({
    from_date: fromDate,
    to_date: toDate,
  })}`;
  return fetchWithRefresh(path, requestOptions);
};

export const corporateApi = {
  getUsers,
  inviteByEmails,
  updateCompanyInfo,
  updateDefaultCompanyNumber,
  getHierarchy,
  updateUsers,
  getEmailByToken,
  resendInviteByEmail,
  deleteUser,
  enableUser,
  disableUser,
  updateUserQuota,
  updateUserRole,
  assignAndRevokeUserNumbers,
  getQuotaByUid,
  getAdminInfo,
  getLimits,
  getUsagePeriods,
  getPageConsumption,
  listCorporateMembers,
};
