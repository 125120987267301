import clsx from "clsx";
import { makeStyles } from "@alohi/kit";
import { Box, BoxProps } from "@mui/material";

interface LoaderProps extends BoxProps {
  color?: string;
  scale?: number;
}

function Loader({ scale = 1, color, className, ...rest }: LoaderProps) {
  const classes = useStyles({ scale, color });

  return (
    <Box {...rest} className={clsx(className, classes.superRoot)}>
      <Box className={classes.root}>
        <Box className={classes.parent}>
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
          <Box className={clsx(classes.loader, classes.fadeLoad)} />
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  superRoot: {
    width: 30,
    height: 30,
    position: "relative",
  },
  root: {
    width: 0,
    height: 0,
    left: "50%",
    position: "absolute",
    top: "50% !important",
  },
  parent: ({ scale }: LoaderProps) => ({
    position: "relative",
    transform: scale ? `scale(${0.3 * scale}, ${0.3 * scale}) translate(0, 10px)` : "unset",
  }),
  "@keyframes fadeLoad": {
    "50%": {
      opacity: 0.3,
    },
  },
  fadeLoad: {
    "&:after": {
      animation: `$fadeLoad .8s ease infinite`,
    },
  },
  loader: ({ color }: LoaderProps) => ({
    width: "5px",
    height: "16px",
    position: "absolute",
    transformOrigin: "50% 30px",
    left: "calc(50% - 5px / 2)",
    top: "calc(50% - 15px / 2 - 30px)",
    "&:after": {
      content: '""',
      display: "block",
      width: "inherit",
      height: "inherit",
      borderRadius: "3px",
      background: color || alohi.gray,
    },
    "&:nth-child(2)": {
      transform: "rotate(45deg)",
    },
    "&:nth-child(3)": {
      transform: "rotate(90deg)",
    },
    "&:nth-child(4)": {
      transform: "rotate(135deg)",
    },
    "&:nth-child(5)": {
      transform: "rotate(180deg)",
    },
    "&:nth-child(6)": {
      transform: "rotate(-135deg)",
    },
    "&:nth-child(7)": {
      transform: "rotate(-90deg)",
    },
    "&:nth-child(8)": {
      transform: "rotate(-45deg)",
    },
    "&:nth-child(2):after": {
      animationDelay: ".1s",
    },
    "&:nth-child(3):after": {
      animationDelay: ".2s",
    },
    "&:nth-child(4):after": {
      animationDelay: ".3s",
    },
    "&:nth-child(5):after": {
      animationDelay: ".4s",
    },
    "&:nth-child(6):after": {
      animationDelay: ".5s",
    },
    "&:nth-child(7):after": {
      animationDelay: ".6s",
    },
    "&:nth-child(8):after": {
      animationDelay: ".7s",
    },
  }),
}));

export default Loader;
