import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";

import { useInput } from "hooks/useInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { isValidUserQuota } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import {
  selectUserQuotaByUid,
  selectUpdateUserQuotaRequestError,
  selectUpdateUserQuotaRequestSuccess,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_GET_USER_QUOTA_REQUEST,
  CLEAR_UPDATE_USER_QUOTA_REQUEST,
} from "stores/reducers/corporate.reducer";

interface EditUserQuotaModalProps {
  userUid: string;
  handleClosure: () => void;
}

function EditUserQuotaModal({ userUid, handleClosure }: EditUserQuotaModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userQuota = useSelector(selectUserQuotaByUid(userUid));
  const error = useSelector(selectUpdateUserQuotaRequestError);
  const success = useSelector(selectUpdateUserQuotaRequestSuccess);

  const [quota, quotaInput] = useInput<string | null>(null, isValidUserQuota);

  const handleConfirm = useCallback(() => {
    quotaInput.touch();

    if (quotaInput.isValid) {
      dispatch(corporateAsyncActions.updateUserQuota({ userUid, quota }));
    } else {
      enqueueSnackbar(t("USERS.MIN_QUOTA"), { variant: "error" });
    }
  }, [dispatch, enqueueSnackbar, quota, quotaInput, t, userUid]);

  useEffect(() => {
    if (userQuota === null) {
      dispatch(corporateAsyncActions.getUserQuota({ userUid }));
    }
  }, [dispatch, userQuota, userUid]);

  useEffect(() => {
    if (userQuota !== null && quota === null) {
      quotaInput.setValue(userQuota.toString());
    }
  }, [quota, quotaInput, userQuota]);

  useEffect(() => {
    if (success) {
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(CLEAR_UPDATE_USER_QUOTA_REQUEST());
      handleClosure();
    }
  }, [success, enqueueSnackbar, t, dispatch, handleClosure]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(CLEAR_UPDATE_USER_QUOTA_REQUEST());
    }
  }, [error, enqueueSnackbar, t, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(CLEAR_GET_USER_QUOTA_REQUEST());
    };
  }, [dispatch]);

  useOnPressEnter(() => {
    if (quotaInput.isFocused) {
      handleConfirm();
    }
  });

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      title={t("USERS.MODIFY_USER_QUOTA")}
      isConfirmDisabled={quotaInput.showsError}
    >
      <Box p={3} pr="5%">
        <FormRowTextInput
          id="edit-quota"
          type="number"
          value={quota ?? ""}
          inputProps={{ min: 0 }}
          onBlur={quotaInput.onBlur}
          onFocus={quotaInput.onFocus}
          error={quotaInput.showsError}
          onChange={quotaInput.onChange}
          label={t("USERS.MONTHLY_QUOTA")}
          helperText={quotaInput.showsError ? t("USERS.MIN_QUOTA") : " "}
        />
      </Box>
    </Modal>
  );
}

export default EditUserQuotaModal;
