import { GOOGLE_ADDON_CLIENT_ID, GOOGLE_CLIENT_ID } from "config";
import { generateRandomFloat } from "helpers/numbers";
import { fileToFaxFile } from "./file";

// Google's OAuth 2.0 endpoint for requesting an access token
const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

export function signInWithGoogle({
  redirectTo,
  invitationToken,
  referrerUid,
  invitationEmail,
}: {
  redirectTo: string;
  invitationToken: string;
  referrerUid: string;
  invitationEmail: string;
}) {
  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  const params: Record<string, string> = {
    client_id: GOOGLE_CLIENT_ID || "",
    redirect_uri: window.location.origin + "/login",
    response_type: "token id_token",
    scope: "profile email",
    include_granted_scopes: "true",
    state: JSON.stringify({
      redirectTo,
      provider: "google",
      invitationToken,
      referrerUid,
      invitationEmail,
    }),
    // We use a random string for the nonce, because it is required by Google for the id_token
    nonce:
      generateRandomFloat().toString(36).substring(2, 15) +
      generateRandomFloat().toString(36).substring(2, 15),
  };

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

export function requestGoogleDriveAuthorization({
  userId = "",
  fileIds,
  workspaceFileIds,
  resourceKeys,
}: {
  userId: string | null;
  fileIds: string[] | null;
  workspaceFileIds: string[] | null;
  resourceKeys: string[] | null;
}) {
  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  const params: Record<string, string> = {
    client_id: GOOGLE_ADDON_CLIENT_ID || "",
    login_hint: userId || "",
    redirect_uri: window.location.origin + "/send-fax-google-drive",
    response_type: "token",
    scope: "https://www.googleapis.com/auth/drive.file",
    include_granted_scopes: "true",
    state: JSON.stringify({ userId, fileIds, workspaceFileIds, resourceKeys }), // Pass through values
  };
  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

export const getFileFromGoogleDrive = async ({
  googleAccessToken,
  fileId,
  resourceKeys,
}: {
  googleAccessToken: string;
  fileId: string;
  resourceKeys: string[];
}) => {
  try {
    // Format the object of resource keys into the proper header
    // X-Goog-Drive-Resource-Keys: fileId1/resourceKey1,fileId2/resourceKey2,fileId3/resourceKey3
    const resourceKeysHeader = Object.entries(resourceKeys)
      .map(([value, key]) => `${key}/${value}`)
      .join(",");

    const metadataRequestPromise = fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}?supportsAllDrives=true`,
      {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          "X-Goog-Drive-Resource-Keys": resourceKeysHeader,
        },
      },
    );
    const fileRequestPromise = fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&supportsAllDrives=true`,
      {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          "X-Goog-Drive-Resource-Keys": resourceKeysHeader,
        },
      },
    );

    const fileRequest = await fileRequestPromise;
    const fileBlob = await fileRequest.blob();
    const fileMetadata = await (await metadataRequestPromise).json();

    const name = fileMetadata.name ?? "Document from Google Drive";
    const type = fileMetadata.mimeType;
    const file = new File([fileBlob], name, { type });

    return fileToFaxFile(file);
  } catch {
    throw new Error("Error when accessing Google Drive");
  }
};

export const exportPdfFromGoogleDrive = async ({
  googleAccessToken,
  workspaceFileId,
  resourceKeys,
}: {
  googleAccessToken: string;
  workspaceFileId: string;
  resourceKeys: string[];
}) => {
  try {
    // Format the object of resource keys into the proper header
    // X-Goog-Drive-Resource-Keys: fileId1/resourceKey1,fileId2/resourceKey2,fileId3/resourceKey3
    const resourceKeysHeader = Object.entries(resourceKeys)
      .map(([value, key]) => `${key}/${value}`)
      .join(",");

    const metadataRequestPromise = fetch(
      `https://www.googleapis.com/drive/v3/files/${workspaceFileId}?supportsAllDrives=true`,
      {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          "X-Goog-Drive-Resource-Keys": resourceKeysHeader,
        },
      },
    );
    const fileRequestPromise = fetch(
      `https://www.googleapis.com/drive/v3/files/${workspaceFileId}/export?mimeType=application%2Fpdf`,
      {
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
          "X-Goog-Drive-Resource-Keys": resourceKeysHeader,
        },
      },
    );

    const fileRequest = await fileRequestPromise;
    const fileBlob = await fileRequest.blob();
    const fileMetadata = await (await metadataRequestPromise).json();

    const name = fileMetadata.name ?? "Document from Google Drive";
    // File was converted to PDF, so pass the correct mime type to the file constructor
    const file = new File([fileBlob], name, { type: "application/pdf" });

    return fileToFaxFile(file);
  } catch {
    throw new Error("Error when accessing Google Drive");
  }
};
