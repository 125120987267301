import {
  alohi,
  BorderAnimation,
  Box,
  makeStyles,
  theme,
  Typography,
  useMediaQuery,
} from "@alohi/kit";
import { CustomerMarketingDataFaxSignCrossSellBannerStatus } from "api/gql/generated/graphql";
import clsx from "clsx";
import { borderAnimationSettings } from "components/Incentive/helpers/animations";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface StreamlineSignatureContainerProps {
  className?: string;
}

function StreamlineSignatureContainer({ className }: StreamlineSignatureContainerProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const { promoStatus } = useTrialIncentive();

  const animation = useMemo(() => borderAnimationSettings, []);

  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>
          {t("INCENTIVE_SIGN.STREAMLINE_ESIGNATURES")}
        </Typography>
        {isMobileLayout ||
        promoStatus !== CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable ? null : (
          <Box className={classes.freeTrial}>
            <Typography className={classes.freeTrialText}>
              {t("INCENTIVE_SIGN.FREE_TRIAL")}
            </Typography>
            <BorderAnimation
              isHovering={false}
              isActive={false}
              borderRadius={14}
              idleAnimation={animation}
              hoverAnimation={animation}
            />
          </Box>
        )}
      </Box>

      <Typography className={classes.descriptionText}>
        {t("INCENTIVE_SIGN.UNLOCK_MORE_SIGN")}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    //
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    color: alohi.neutral900,
  },
  freeTrial: {
    position: "relative",
    marginLeft: spacing(2),
    padding: spacing(0.5, 1.5, 0.5, 1.5),
    width: "fit-content",
    [breakpoints.down("sm")]: {
      marginLeft: spacing(0),
      marginBottom: spacing(2),
    },
    whiteSpace: "nowrap",
  },
  descriptionText: {
    marginTop: spacing(2),
  },
  freeTrialText: {
    color: "black",
  },
}));

export default StreamlineSignatureContainer;
