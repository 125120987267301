import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography, useSnackbar } from "@alohi/kit";

import { useAppDispatch, useAppSelector } from "stores/store";
import { actions, selectors, thunks } from "stores/reducers/coverSheet.reducer";

interface RemoveCoverSheetModalProps {
  id: string;
  handleClosure: (isSuccess: boolean) => void;
  disableAnimation?: boolean;
}

function RemoveCoverSheetModal({
  id,
  handleClosure,
  disableAnimation,
}: RemoveCoverSheetModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isRemoveSuccess = useAppSelector(selectors.remove);
  const isRemoveError = useAppSelector(selectors.isRemoveError);
  const isRemoveRunning = useAppSelector(selectors.isRemoveRunning);
  const isEnforcedAndCantBeRemoved = useAppSelector(selectors.isEnforcedAndCantBeRemoved);

  const handleConfirm = () => {
    dispatch(thunks.remove({ id }));
  };

  const handleCancel = () => {
    handleClosure(false);
  };

  useEffect(() => {
    if (isRemoveSuccess) {
      enqueueSnackbar(t("COVER_SHEET.DELETE_SUCCESS"), { variant: "success" });

      dispatch(actions.clearRemove());

      handleClosure(true);
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isRemoveSuccess, t]);

  useEffect(() => {
    if (isEnforcedAndCantBeRemoved) {
      enqueueSnackbar(t("COVER_SHEET.DELETE_FAILURE"), { variant: "error" });

      dispatch(actions.clearRemove());
      handleClosure(false);
    } else if (isRemoveError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });

      dispatch(actions.clearRemove());
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isEnforcedAndCantBeRemoved, isRemoveError, t]);

  return (
    <Modal
      maxWidth="xs"
      disableRestoreFocus
      hasCloseIcon={false}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onPressEnter={handleConfirm}
      onPressEscape={handleCancel}
      isModalDisabled={isRemoveRunning}
      isConfirmLoading={isRemoveRunning}
      title={t("DELETE_ITEM.ARE_YOU_SURE")}
      disableAnimation={disableAnimation}
    >
      <Box p={3}>
        <Typography>{t("COVER_SHEET.DELETE_QUESTION")}</Typography>
      </Box>
    </Modal>
  );
}

export default RemoveCoverSheetModal;
