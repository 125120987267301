import PropTypes from "prop-types";
import React from "react";
import Avatar from "components/Core/Avatar/Avatar";

import { API_STORAGE } from "config";

const MemberAvatar = ({ member: { profileImage, name, lastname, uid }, ...restProps }) => {
  const src = profileImage && uid ? `${API_STORAGE}${profileImage}?uid=${uid}` : null;
  const alt = name || lastname ? `${name} ${lastname}`.trim() : null;
  return <Avatar src={src} alt={alt} {...restProps} />;
};

MemberAvatar.propTypes = {
  member: PropTypes.shape({
    profileImage: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    uid: PropTypes.string,
  }),
};

export default MemberAvatar;
