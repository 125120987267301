import { useEffect } from "react";
import { UAParser } from "ua-parser-js";
import { matchPath, useHistory } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import { Box, Button, TextButton, Typography, makeStyles } from "@alohi/kit";

import useBool from "hooks/useBool";
import { websiteUrls } from "routes/urls";
import sendFaxImg from "assets/images/send_fax.png";
import FaxPlusLogo from "assets/svg/fax_plus_logo.svg?react";
import backgroundImg from "assets/images/background_mobile.png";

const HIDE_MOBILE_BANNER = "hideMobileBanner";

interface SuggestMobileProps {
  uri: string;
  pathnames: `/${string}`[];
}

function SuggestMobile({ uri, pathnames }: SuggestMobileProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { pathname, search } = window.location;

  const isDrawerHidden = new URLSearchParams(search).get(HIDE_MOBILE_BANNER) === "true";
  const isAllowedToRender = Boolean(matchPath(pathname, { path: pathnames, exact: true }));

  const userAgent = UAParser(navigator?.userAgent || window?.navigator?.userAgent);

  const isIos = userAgent.os.name === "iOS";
  const isAndroid = userAgent.os.name === "Android";
  const isMobileDevice = userAgent.device.type === "mobile";
  const isIphoneSafari =
    isIos && userAgent.device.model === "iPhone" && userAgent.browser.name === "Mobile Safari";

  const [isOpen, isOpenBool] = useBool(isMobileDevice);

  function handleOpenApp() {
    if (isIphoneSafari) {
      window.location.assign(websiteUrls.iOSAppStore);
      return;
    }

    const iOSUri = `SendFax:/${uri}`;
    const androidUri = `faxplus://login`;

    if (isIos) {
      window.location.assign(iOSUri);
    }

    if (isAndroid) {
      window.location.assign(androidUri);
    }

    setTimeout(function appNotInstalled() {
      if (document.hasFocus()) {
        const storeLink = isIos ? websiteUrls.iOSAppStore : websiteUrls.androidAppStore;

        window.location.assign(storeLink);
      }
    }, 1000);
  }

  useEffect(() => {
    // Detect if we have hideMobileBanner inside the query params
    // Remove it from the query params and never open the drawer
    if (isDrawerHidden) {
      // Keep the query param for the time being 
      // to avoid showing the banner when switching between 
      // public layout -> private layout
      //const currentURL = new URL(window.location.href);
      //currentURL.searchParams.delete(HIDE_MOBILE_BANNER);
      //history.replace(currentURL.pathname + currentURL.search);

      isOpenBool.setFalse();
    }
  }, [history, isDrawerHidden, isOpenBool]);

  if (!isMobileDevice || !isAllowedToRender || !isOpen) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <img alt="sign_plus_logo_background" src={backgroundImg} className={classes.background} />

      <Box className={classes.content}>
        <Box className={classes.logo}>
          <FaxPlusLogo width={32} />
        </Box>

        <Typography className={classes.title}>{t("SUGGEST_MOBILE.ONLINE_FAXING")}</Typography>

        <Typography className={classes.description}>
          <Trans
            i18nKey="SUGGEST_MOBILE.OFFER"
            components={{
              strong: <strong />,
            }}
          />
        </Typography>

        <Button className={classes.cta} onClick={handleOpenApp}>
          <strong>{t("SUGGEST_MOBILE.CONTINUE_WITH")}</strong>
        </Button>

        <TextButton className={classes.browser} onClick={isOpenBool.setFalse}>
          {t("SUGGEST_MOBILE.MOBILE_BROWSER")}
        </TextButton>
      </Box>

      <img alt="send_fax_image" src={sendFaxImg} className={classes.sendFaxImg} />
    </Box>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "2000",
    display: "grid",
    position: "absolute",
    paddingTop: spacing(6),
    background: alohi.white,
    alignContent: "flex-start",
  },
  title: {
    zIndex: 2,
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "center",
    textIndent: spacing(1),
    color: alohi.neutral900,
  },
  description: {
    zIndex: 2,
    textAlign: "center",
    padding: spacing(0, 4),
    color: alohi.neutral700,
  },
  browser: {
    zIndex: 2,
    cursor: "pointer",
    textAlign: "center",
    color: alohi.neutral500,
  },
  cta: {
    zIndex: 2,
  },
  background: {
    left: 0,
    right: 0,
    zIndex: 1,
    width: "100%",
    height: "auto",
    opacity: "0.6",
    top: spacing(26),
    margin: "0 auto",
    position: "absolute",
  },
  content: {
    display: "flex",
    gap: spacing(3),
    alignItems: "center",
    flexDirection: "column",
  },
  sendFaxImg: {
    zIndex: 2,
    height: "auto",
    margin: "0 auto",
    maxWidth: "338px",
    marginTop: spacing(14),
  },
  logo: {
    zIndex: 1,
  },
}));

export default SuggestMobile;
