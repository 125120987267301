import { alohi, Box, makeStyles, Typography } from "@alohi/kit";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import KeyFeatures from "./KeyFeatures";
import StreamlineSignatureContainer from "./StreamlineSignatureContainer";
import useAdminRedirections, { SuccessRedirectToApp } from "hooks/useAdminRedirections";
import { alohiAdminUrls } from "routes/urls";
import {
  GoogleAnalyticsCustomEvents,
  IncentiveSignPlusUpgradeAction,
  useGoogleTagManager,
} from "hooks/useGoogleTagManager";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

interface ContentFreeEligibleProps {
  className?: string;
}

function ContentFreeEligible({ className }: ContentFreeEligibleProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { handleRedirection: redirectToAdmin } = useAdminRedirections();
  const { sendCustomEvent } = useGoogleTagManager();

  const handlePrimaryButtonClick = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.incentiveSignPlusUpgrade,
      page: location.pathname,
      extraData: {
        action: IncentiveSignPlusUpgradeAction.activateTrial,
      },
    });
    redirectToAdmin({
      adminUrl: alohiAdminUrls.sign.upgrade,
      adminParams: {
        trial: true,
        ["success-redirect-to-app"]: SuccessRedirectToApp.Sign,
      },
    });
  };

  const handleSecondaryButtonClick = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.incentiveSignPlusUpgrade,
      page: location.pathname,
      extraData: {
        action: IncentiveSignPlusUpgradeAction.showTrialPlans,
      },
    });
    redirectToAdmin({
      adminUrl: alohiAdminUrls.sign.upgrade,
      adminParams: {
        ["show-all-plans"]: true,
        trial: true,
        ["success-redirect-to-app"]: SuccessRedirectToApp.Sign,
      },
    });
  };

  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.content}>
        <StreamlineSignatureContainer />
        <KeyFeatures className={classes.keyFeatures} />
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.buttonsContainer}>
          <PrimaryButton icon={faBolt} onClick={handlePrimaryButtonClick}>
            <Typography>
              <Trans
                i18nKey="INCENTIVE_SIGN.ACTIVATE_TRIAL"
                components={{
                  strong: <strong />,
                }}
                values={{
                  value_1: 14,
                }}
              />
            </Typography>
          </PrimaryButton>
          <SecondaryButton onClick={handleSecondaryButtonClick}>
            <Typography>{t("INCENTIVE_SIGN.VIEW_ALL_PLANS")}</Typography>
          </SecondaryButton>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "scroll",
    [breakpoints.down("sm")]: {
      padding: spacing("90px", 2, 2, 2),
    },
  },
  keyFeatures: {
    marginTop: spacing(3),
    [breakpoints.down("sm")]: {
      marginTop: spacing(2),
    },
  },
  footer: {
    height: "fit-content",
    [breakpoints.down("sm")]: {
      padding: spacing(2),
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: "solid",
      borderColor: alohi.neutral200,
    },
  },
  buttonsContainer: {
    marginTop: spacing(4),
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
    [breakpoints.down("sm")]: {
      marginTop: spacing(0),
    },
  },
}));

export default ContentFreeEligible;
