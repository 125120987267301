import { memo } from "react";
import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Badge, makeStyles } from "ui";
import { selectCdrIsFailed, selectCdrIsRead } from "selectors/faxes.selector";

function FaxesColumnIsFailedOrUnread({ boxName, dataIndex }) {
  const classes = useStyles();

  const isFailed = useSelector(selectCdrIsFailed(boxName, dataIndex));
  const isRead = useSelector(selectCdrIsRead(boxName, dataIndex));

  if (isFailed) {
    return (
      <Box className={classes.wrapper}>
        <Badge variant="dot" classes={{ badge: classes.redBadge }} />
      </Box>
    );
  } else if (isRead === false) {
    return (
      <Box className={classes.wrapper}>
        <Badge variant="dot" classes={{ badge: classes.blueBadge }} />
      </Box>
    );
  }
  return null;
}

const useStyles = makeStyles(({ alohi }) => ({
  wrapper: {
    top: 0,
    left: "4px",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
  },
  blueBadge: {
    backgroundColor: alohi.lighterBlue,
  },
  redBadge: {
    backgroundColor: alohi.red,
  },
}));

FaxesColumnIsFailedOrUnread.propTypes = {
  boxName: PropTypes.string,
  dataIndex: PropTypes.number,
};

export default memo(FaxesColumnIsFailedOrUnread);
