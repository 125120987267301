import { Box } from "@alohi/kit";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { faSort } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSendFaxContext } from "views/SendFax/contexts/context";
import { List, ListItem, ListItemAvatar, ListItemText, makeStyles } from "ui";
import ViewCoverSheetModal from "../modals/ViewCoverSheetModal";

function EnforcedCoverSheetListItem() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    sendFaxStore: { enforcedCoverSheet },
  } = useSendFaxContext();

  const [idToView, setIdToView] = useState<null | { name: string; id: string }>(null);

  const viewCoverSheet = () => {
    if (enforcedCoverSheet?.id && enforcedCoverSheet?.name) {
      setIdToView({ id: enforcedCoverSheet.id, name: enforcedCoverSheet.name });
    }
  };

  return (
    <List className={classes.listStyle}>
      <ListItem dense className={classes.listItem} onClick={viewCoverSheet}>
        <ListItemAvatar>
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <FontAwesomeIcon icon={faSort} size="1x" color="transparent" />
            <Box className={classes.iconContainer}>
              <img
                alt="thumbnail"
                draggable={false}
                className={classes.image}
                src={`data:image/png;base64, ${enforcedCoverSheet?.thumbnail}`}
              />
            </Box>
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={t("COVER_SHEET.COMPANY_COVERSHEET")}
          secondary={enforcedCoverSheet?.name}
          classes={{
            primary: classes.listItemPrimaryText,
            secondary: classes.listItemSecondaryText,
          }}
        />
      </ListItem>

      {idToView ? (
        <ViewCoverSheetModal
          id={idToView.id}
          name={idToView.name}
          handleClosure={() => setIdToView(null)}
        />
      ) : null}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  listStyle: {
    width: "100%",
    paddingBottom: 0,
    cursor: "pointer",
    background: "transparent",
    paddingTop: theme.spacing(1),
  },
  listItem: {
    background: theme.alohi.lightestBlue,
    borderRadius: theme.shape.borderRadius,
  },
  listItemPrimaryText: {
    display: "block",
    overflow: "hidden",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    color: theme.alohi.gray,
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  listItemSecondaryText: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
    color: theme.alohi.lightGray,
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default EnforcedCoverSheetListItem;
