import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Button, Box, Modal, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import FormRow from "components/Forms/FormRow";
import CoreAvatar from "components/Core/Avatar/Avatar";
import { isValidRedirectUrl } from "helpers/inputValidation";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import FormRowMultiSelectTextInput from "components/Forms/FormRowMultiSelectTextInput";
import {
  selectIsActivateApiError,
  selectIsActivateApiRunning,
  selectIsActivateApiSuccess,
} from "selectors/integrations.selector";
import {
  activateApi,
  clearActivateApi,
  getApiVerification,
  getClientPrivateInfo,
} from "stores/reducers/integrations.api.reducer";
import UploadApiLogoModal from "../Image/UploadApiLogoModal";
import RequireUpgradeToEnterprise from "../RequireUpgrade/RequireUpgradeToEnterprise";

const ApiActivationModal = ({ handleClosure }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isError = useSelector(selectIsActivateApiError);
  const isRunning = useSelector(selectIsActivateApiRunning);
  const isSuccess = useSelector(selectIsActivateApiSuccess);
  const planIsEnterprise = useSelector(selectIsPlanEnterprise);

  const [fullName, fullNameInput] = useInput("", (value) => value.length > 0);

  const [image, setImage] = useState(null);
  const [urisInput, setUrisInput] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [imageShowsError, setImageShowsError] = useState(false);
  const [urisInputShowsError, setUrisInputShowsError] = useState(false);
  const [isUploadApiLogoModalOpen, setIsUploadApiLogoModalOpen] = useState(false);

  const handleImageUpload = useCallback(({ url, base64 }) => {
    setImage(base64);
    setImageURL(url);
    setIsUploadApiLogoModalOpen(false);
    setImageShowsError(false);
  }, []);

  const onConfirm = useCallback(() => {
    fullNameInput.touch();

    if (!urisInput.length) {
      setUrisInputShowsError(true);
    }

    if (!imageURL) {
      setImageShowsError(true);
    }

    if (!!urisInput.length && !!image && fullNameInput.hasValue) {
      dispatch(
        activateApi({
          fullName: fullName,
          redirectionUris: urisInput.map(({ value }) => value),
          imageUrl: imageURL,
        }),
      );
    }
  }, [dispatch, fullName, fullNameInput, image, imageURL, urisInput]);

  const handleUrisInputOnChange = useCallback((value) => {
    setUrisInput(value);
    setUrisInputShowsError(false);
  }, []);

  const handleUrisInputOnBlur = useCallback(() => {
    setUrisInputShowsError(!urisInput.length);
  }, [urisInput.length]);

  useEffect(() => {
    (async () => {
      if (isSuccess) {
        await Promise.all([dispatch(getApiVerification()), dispatch(getClientPrivateInfo())]);
        handleClosure();
        dispatch(clearActivateApi());
      }
    })();
  }, [dispatch, handleClosure, isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearActivateApi());
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isError, isSuccess, t]);

  const isButtonDisabled =
    fullNameInput.showsError || urisInputShowsError || imageShowsError || isRunning;

  if (!planIsEnterprise) {
    return (
      <RequireUpgradeToEnterprise
        handleClosure={handleClosure}
        title={t("WEB_SERVICE.OAUTH2_TOKEN")}
        featureName={t("WEB_SERVICE.THE_OAUTH2_TOKEN")}
      />
    );
  }

  return (
    <>
      <Modal
        onConfirm={onConfirm}
        onCancel={handleClosure}
        isConfirmLoading={isRunning}
        isConfirmDisabled={isButtonDisabled}
        title={t("WEB_SERVICE.OAUTH2_TOKEN")}
      >
        <Box py={3} pr={"10%"} component="form">
          <FormRowTextInput
            required
            type="text"
            value={fullName}
            id="fullname-input"
            onBlur={fullNameInput.onBlur}
            onFocus={fullNameInput.onFocus}
            error={fullNameInput.showsError}
            onChange={fullNameInput.onChange}
            label={t("COMMON.FULL_NAME") + "*"}
            helperText={fullNameInput.showsError ? t("FORMS.PLEASE_ENTER_A_VALID_NAME") : null}
          />

          <FormRowMultiSelectTextInput
            unique
            maxCount={5}
            placeholder=""
            id={"redirect-uris-input"}
            error={urisInputShowsError}
            onBlur={handleUrisInputOnBlur}
            validator={isValidRedirectUrl}
            onChange={handleUrisInputOnChange}
            helperText={t("WEB_SERVICE.TOOLTIP_URI")}
            label={t("WEB_SERVICE.REDIRECT_URI") + "*"}
            uniqueErrorMessage={t("WEB_SERVICE.DUPLICATE_DOMAIN")}
            validationErrorMessage={t("WEB_SERVICE.PLEASE_ENTER_VALID_URI")}
            maxCountErrorMessage={t("WEB_SERVICE.LIMIT_REACHED_URIS", {
              count: 5,
            })}
          />

          <FormRow
            error={imageShowsError}
            label={t("WEB_SERVICE.DEVELOPER_LOGO") + "*"}
            helperText={imageShowsError ? t("WEB_SERVICE.PLEASE_UPLOAD_DEVELOPER_LOGO") : null}
          >
            <Box display="flex" alignItems="center">
              <CoreAvatar src={image} alt={t("WEB_SERVICE.DEVELOPER_LOGO")} />
              <Button
                variant="white"
                onClick={() => {
                  setIsUploadApiLogoModalOpen(true);
                }}
                sx={{ ml: 2 }}
              >
                {t("COMMON.UPLOAD")}
              </Button>
            </Box>
          </FormRow>
        </Box>
      </Modal>

      {isUploadApiLogoModalOpen && <UploadApiLogoModal handleClosure={handleImageUpload} />}
    </>
  );
};

ApiActivationModal.propTypes = {
  handleClosure: PropTypes.func,
};

export default ApiActivationModal;
