import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import useBool from "hooks/useBool";
import useCart from "hooks/useCart";
import usePrevious from "hooks/usePrevious";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import { clearCart } from "stores/reducers/payment.cart.reducer";
import { selectIsUpgradePlanDisabled } from "selectors/common.selector";
import { selectIsBundleRequiredToBeCreated } from "selectors/plan.selector";
import { setCustomPaymentAmount } from "stores/reducers/payment.amounts.reducer";
import CreateAddressBundleModal from "views/CreateAddressBundle/CreateAddressBundleModal";
import { selectCartError, selectIsChargeProcessLoading } from "selectors/payment.selector";
import ChangePlanFromPaidComponent from "components/Payment/ChangePlanFromPaid/ChangePlanFromPaidComponent";
import ChangePlanFromPaidFooterInfo from "components/Payment/ChangePlanFromPaid/ChangePlanFromPaidFooterInfo";

function ChangePlanFromPaidModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cartError = useSelector(selectCartError);
  const isProceedLoading = useSelector(selectIsChargeProcessLoading);
  const isUpgradePlanDisabled = useSelector(selectIsUpgradePlanDisabled);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);

  const prevCartError = usePrevious(cartError);
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [openNewBundle, openNewBundleHandler] = useBool(false);
  const [{ creditNeeded, canChargeCart }, { chargeCart }] = useCart(true);

  const handleOnConfirm = useCallback(() => {
    if (isBundleRequiredToBeCreated) {
      openNewBundleHandler.setTrue();
      return;
    }
    if (canChargeCart) {
      chargeCart();
    } else {
      dispatch(setCustomPaymentAmount(creditNeeded));
      setShowAddCredit(true);
    }
  }, [
    dispatch,
    chargeCart,
    creditNeeded,
    canChargeCart,
    openNewBundleHandler,
    isBundleRequiredToBeCreated,
  ]);

  const handleAddCredit = useCallback(
    (isSuccess) => {
      if (isSuccess) {
        chargeCart();
      }
      setShowAddCredit(false);
    },
    [chargeCart],
  );

  useEffect(() => {
    if (cartError) {
      enqueueSnackbar(cartError, { variant: "error" });
      dispatch(clearCart());
    }
  }, [cartError, dispatch, t, enqueueSnackbar]);

  const isConfirmDisabled = isUpgradePlanDisabled || !!cartError || !!prevCartError;

  return (
    <>
      <Modal
        data-cy="ChangePlanFromPaidModal"
        onConfirm={handleOnConfirm}
        title={t("COMMON.CHANGE_PLAN")}
        isModalDisabled={isProceedLoading}
        isConfirmLoading={isProceedLoading}
        onCancel={() => handleClosure(null)}
        isConfirmDisabled={isConfirmDisabled}
        confirmTitle={
          isBundleRequiredToBeCreated ? t("CHANGE_PLAN.PROVIDE_ID") : t("ADD_NUMBER.PROCEED")
        }
      >
        <ChangePlanFromPaidComponent />
        <ChangePlanFromPaidFooterInfo />
      </Modal>

      {showAddCredit ? (
        <AddCreditModal
          reason="change_plan"
          withSuccessModal={false}
          handleClosure={handleAddCredit}
        />
      ) : null}

      {openNewBundle ? (
        <CreateAddressBundleModal handleClosure={openNewBundleHandler.setFalse} />
      ) : null}
    </>
  );
}

ChangePlanFromPaidModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ChangePlanFromPaidModal;
