export const accountTypes = {
  corporateOwner: "corporate_owner",
  corporateAdmin: "corporate_admin",
  corporateMember: "corporate_member",
  individual: "individual",
};

export const accountStatuses = {
  waitingForSignup: "waiting_for_signup",
  disabled: "disabled",
  active: "active",
};
