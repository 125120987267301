import { Box, Modal, useSnackbar } from "@alohi/kit";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import dataCy from "enums/dataCy";
import { Link, Typography } from "ui";
import { useInput } from "hooks/useInput";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { isValidEmail, isValidName } from "helpers/inputValidation";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import { clearRequestSocReport, requestSocReport } from "stores/reducers/support.reducer";
import {
  selectRequestSocReportError,
  selectIsRequestSocReportRunning,
  selectIsRequestSocReportSuccess,
} from "selectors/support.selector";

enum VIEWS {
  CONFIRM_REQUEST = "confirmActivate",
  CORPORATE_MEMBER = "corporateMemberView",
}

interface RequestSocModalProps {
  handleClosure: () => void;
}

function RequestSocModal({ handleClosure }: RequestSocModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const isRequestSocReportSuccess = useSelector(selectIsRequestSocReportSuccess);
  const isRequestSocReportRunning = useSelector(selectIsRequestSocReportRunning);
  const requestSocReportError = useSelector(selectRequestSocReportError);

  const [currentView, setCurrentView] = useState<VIEWS>();

  const [signersEmail, signersEmailInput] = useInput("", isValidEmail);
  const [signersFullName, signersFullNameInput] = useInput("", isValidName);

  useEffect(() => {
    if (isCorporateMember) {
      setCurrentView(VIEWS.CORPORATE_MEMBER);
    } else {
      setCurrentView(VIEWS.CONFIRM_REQUEST);
    }
  }, [isCorporateMember]);

  const handleRequestSoc = useCallback(() => {
    signersFullNameInput.touch();
    signersEmailInput.touch();
    if (signersFullNameInput.isValid && signersEmailInput.isValid) {
      dispatch(
        requestSocReport({
          signersEmail,
          signersName: signersFullName,
        }),
      );
    }
  }, [dispatch, signersEmail, signersEmailInput, signersFullName, signersFullNameInput]);

  useEffect(() => {
    if (isRequestSocReportSuccess) {
      enqueueSnackbar(t("PROFILE.COMPLIANCE_DOCUMENTS_REQUEST_SUBMITTED"), {
        variant: "success",
      });
      handleClosure();
      dispatch(clearRequestSocReport());
    }
  }, [t, dispatch, enqueueSnackbar, isRequestSocReportSuccess, handleClosure]);

  useEffect(() => {
    if (requestSocReportError) {
      enqueueSnackbar(requestSocReportError, {
        variant: "error",
      });
      dispatch(clearRequestSocReport());
    }
  }, [isRequestSocReportRunning, requestSocReportError, enqueueSnackbar, dispatch]);

  if (!currentView) {
    return null;
  } else
    return {
      [VIEWS.CORPORATE_MEMBER]: (
        <ContactAdminToUseFeature
          handleClosure={handleClosure}
          title={t("PROFILE.COMPLIANCE_DOCUMENTS_REQUEST_SOC_2_REPORT")}
          description={t("PROFILE.COMPLIANCE_DOCUMENTS_ASK_ADMIN_FOR_SOC_2")}
        />
      ),
      [VIEWS.CONFIRM_REQUEST]: (
        <Modal
          maxWidth="xs"
          onConfirm={handleRequestSoc}
          onCancel={handleClosure}
          title={t("PROFILE.COMPLIANCE_DOCUMENTS_REQUEST_SOC_2_REPORT")}
          confirmTitle={t("PROFILE.HIPAA_REQUEST")}
          isModalDisabled={isRequestSocReportRunning}
          isConfirmLoading={isRequestSocReportRunning}
          isConfirmDisabled={signersFullNameInput.showsError || signersEmailInput.showsError}
          data-cy={dataCy.requestSocModal}
        >
          <Box p={3}>
            <Typography variant="body">
              <Trans
                i18nKey="PROFILE.COMPLIANCE_DOCUMENTS_YOU_WILL_RECEIVE_NDA_VIA_SIGN_PLUS"
                components={{
                  signLink: <Link href="https://sign.plus" target="_blank" rel="noreferrer" />,
                }}
              />
            </Typography>
            <Box sx={{ mt: 1 }} />
            <Typography variant="body">
              {t("PROFILE.COMPLIANCE_DOCUMENTS_YOU_NEED_TO_PROVIDE_INFORMATION")}
            </Typography>
            <Box sx={{ mt: 2, pr: 3 }}>
              <FormRowTextInput
                required
                label={t("PROFILE.COMPLIANCE_DOCUMENTS_SIGNERS_FULL_NAME")}
                name="name"
                type="text"
                value={signersFullName}
                onBlur={signersFullNameInput.onBlur}
                onFocus={signersFullNameInput.onFocus}
                error={signersFullNameInput.showsError}
                onChange={signersFullNameInput.onChange}
              />
              <FormRowTextInput
                required
                label={t("PROFILE.COMPLIANCE_DOCUMENTS_SIGNERS_EMAIL")}
                name="email"
                type="email"
                value={signersEmail}
                onBlur={signersEmailInput.onBlur}
                onFocus={signersEmailInput.onFocus}
                error={signersEmailInput.showsError}
                onChange={signersEmailInput.onChange}
              />
            </Box>
          </Box>
        </Modal>
      ),
    }[currentView];
}

export default RequestSocModal;
