import queryString from "query-string";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Typography } from "ui";
import Loading from "components/Loading/Loading";
import {
  getFileFromGoogleDrive,
  exportPdfFromGoogleDrive,
  requestGoogleDriveAuthorization,
} from "helpers/google";
import InitSendFaxProviders from "./InitSendFaxProviders";
import RequireGoogleDriveAuthorizationModal from "./RequireGoogleDriveAuthorizationModal";

function SendFaxGoogleDrive() {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const alreadyAuthorizedGoogleDriveUserId = localStorage.getItem(
    "alreadyAuthorizedGoogleDriveUserId",
  );
  const { state: dataFromGoogleDrive } = queryString.parse(location.search);
  const { state: dataFromGoogleAuthentication, access_token: googleAccessToken } =
    queryString.parse(location.hash);

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requireAuthorizationModalState, setRequireAuthorizationModalState] = useState({
    isOpen: false,
    fileIds: null, // Native documents (PDF, PNG...)
    workspaceFileIds: null, // Google Workspace documents (ie. Docs, Sheets) that can be converted to PDF
    userId: null,
  });

  // Handle initial request from Google Drive
  useEffect(() => {
    (async () => {
      if (!!dataFromGoogleDrive) {
        try {
          const {
            ids: fileIds,
            exportIds: workspaceFileIds,
            userId,
            resourceKeys,
          } = await JSON.parse(dataFromGoogleDrive);
          if (userId === alreadyAuthorizedGoogleDriveUserId) {
            // Silently proceed with next steps
            requestGoogleDriveAuthorization({
              userId,
              fileIds,
              workspaceFileIds,
              resourceKeys,
            });
          } else {
            // Inform user they will be prompted to authorize Google Drive
            setRequireAuthorizationModalState({
              isOpen: true,
              userId,
              fileIds,
              workspaceFileIds,
            });
          }
        } catch (e) {
          enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        }
      }
    })();
  }, [alreadyAuthorizedGoogleDriveUserId, dataFromGoogleDrive, enqueueSnackbar, t]);

  // Download files after the Google authentication
  useEffect(() => {
    (async () => {
      if (!!dataFromGoogleAuthentication) {
        setIsLoading(true);
        history.replace({
          pathname: window.location.pathname,
        });
        try {
          const {
            userId,
            fileIds = [],
            workspaceFileIds = [],
            resourceKeys = {},
          } = await JSON.parse(dataFromGoogleAuthentication);
          let downloadPromises = [];
          for (let i = 0; i < Math.min(fileIds.length, 11); i++) {
            downloadPromises.push(
              getFileFromGoogleDrive({
                googleAccessToken,
                fileId: fileIds[i],
                resourceKeys,
              }),
            );
          }
          for (
            let i = 0;
            i < Math.min(workspaceFileIds.length, 11 - downloadPromises.length);
            i++
          ) {
            downloadPromises.push(
              exportPdfFromGoogleDrive({
                googleAccessToken,
                workspaceFileId: workspaceFileIds[i],
                resourceKeys,
              }),
            );
          }
          localStorage.setItem("alreadyAuthorizedGoogleDriveUserId", userId); // Don't show authorization modal next time
          const downloadedFiles = await Promise.all(downloadPromises);
          setFiles(downloadedFiles);
        } catch (e) {
          enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        }
        setIsLoading(false);
      }
    })();
  }, [dataFromGoogleAuthentication, enqueueSnackbar, googleAccessToken, history, t]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body">{t("FAXES.DOWNLOADING_FILES")}</Typography>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <InitSendFaxProviders files={files} initiatedFrom={{ from: "google-drive" }} />

      {requireAuthorizationModalState.isOpen && (
        <RequireGoogleDriveAuthorizationModal
          fileIds={requireAuthorizationModalState.fileIds}
          workspaceFileIds={requireAuthorizationModalState.workspaceFileIds}
          userId={requireAuthorizationModalState.userId}
        />
      )}
    </>
  );
}

export default SendFaxGoogleDrive;
