import { Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { helpCenterUrls } from "routes/urls";
import { Helper, Link, Typography } from "ui";
import { selectIsPlanEnterprise } from "selectors/plan.selector";

interface RemainingNumberOfUsersProps {
  remainingNumberOfUsers: number;
}

function RemainingNumberOfUsers({ remainingNumberOfUsers }: RemainingNumberOfUsersProps) {
  const { t } = useTranslation();

  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);

  if (remainingNumberOfUsers !== null && !isPlanEnterprise) {
    return (
      <Box mt={2}>
        <Typography
          variant="body"
          sx={{
            "&&": { ...(remainingNumberOfUsers < 0 && { color: "alohi.red" }) },
          }}
        >
          {t("USERS.MEMBER_REMAINING", { count: remainingNumberOfUsers })}{" "}
          <Link target="_blank" href={helpCenterUrls.teamSize} sx={{ ml: 0.5 }}>
            <Helper component="span" sx={{ "&&": { verticalAlign: "initial" } }} />
          </Link>
        </Typography>
      </Box>
    );
  }

  return null;
}

export default RemainingNumberOfUsers;
