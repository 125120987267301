import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { TextField, Checkbox, FaButton, Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import useBool from "hooks/useBool";
import { FormControlLabel } from "ui";
import { developerUrls } from "routes/urls";
import { useAppDispatch } from "stores/store";
import useClipboard from "hooks/useClipboard";
import FormRow from "components/Forms/FormRow";
import UserProgress from "components/UserProgress/UserProgress";
import { updateSsoSettings, clearUpdateSsoSettings } from "stores/reducers/account.sso.reducer";
import {
  selectSsoSettings,
  selectIsUpdateSsoSettingsSuccess,
  selectIsUpdateSsoSettingsRunning,
} from "selectors/account.sso.selector";

interface ScimConfigurationStepProps {
  handleClosure: (isNext: boolean) => void;
}

function ScimConfigurationStep({ handleClosure }: ScimConfigurationStepProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { copy, isCopied } = useClipboard();

  const ssoSettings = useSelector(selectSsoSettings);
  const isSuccess = useSelector(selectIsUpdateSsoSettingsSuccess);
  const isLoading = useSelector(selectIsUpdateSsoSettingsRunning);

  const isLegacy = ssoSettings?.isLegacy;
  const isAddFaxLicense = ssoSettings?.isAddFaxLicense ?? false;
  const isAddSignLicense = ssoSettings?.isAddSignLicense ?? false;

  const [isAddingNewFaxUsers, isAddingNewFaxUsersBool] = useBool(isAddFaxLicense);
  const [isAddingNewSignUsers, isAddingNewSignUsersBool] = useBool(isAddSignLicense);

  const handleConfirm = () => {
    if (isLegacy) {
      handleClosure(true);
      return;
    }

    const updaterThunk = updateSsoSettings as (
      updatedObject: Record<string, unknown>,
    ) => ReturnType<typeof updateSsoSettings>;

    dispatch(
      updaterThunk({
        isLegacy,
        ssoUrl: ssoSettings?.ssoUrl,
        x509Cert: ssoSettings?.x509Cert,
        entityId: ssoSettings?.entityId,
        isAddFaxLicense: isAddingNewFaxUsers,
        isAddSignLicense: isAddingNewSignUsers,
        isOnlyAllowSso: ssoSettings?.isOnlyAllowSso,
        refreshTokenLifetime: ssoSettings?.refreshTokenLifetime,
      }),
    );
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearUpdateSsoSettings());

      handleClosure(true);
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isSuccess, t]);

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar(t("REFERRAL.COPIED_TO_CLIPBOARD"), {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, isCopied, t]);

  return (
    <Modal
      disableAnimation
      title={t("SSO.TITLE")}
      onConfirm={handleConfirm}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      confirmTitle={t("COMMON.DONE")}
    >
      <Box mt={3} px={3}>
        <UserProgress
          currentStep={2}
          stepNames={[t("SSO.IDENTITY_PROVIDER"), t("SSO.USER_PROVISIONING")]}
        />
      </Box>
      <Box component="form" p={4} pr="17%">
        <FormRow labelGridWidth={4} label={t("SSO.TENANT_URL")} htmlFor="tenant-url-input">
          <TextField
            isDisabled
            fullWidth
            id="tenant-url-input"
            InputProps={{
              endAdornment: (
                <FaButton
                  tooltip={t("COMMON.COPY")}
                  icon={faCopy}
                  onClick={() => copy(developerUrls.scimTenantUrl)}
                />
              ),
            }}
            aria-describedby="scim-token-helper-text"
            value={isLegacy ? developerUrls.scimTenantUrl : developerUrls.scimTenantUrlAlohi}
          />
        </FormRow>

        <FormRow labelGridWidth={4} label={t("SSO.SCIM_TOKEN")} htmlFor="scim-token-input">
          <TextField
            multiline
            fullWidth
            isDisabled
            id="scim-token-input"
            value={ssoSettings?.scimToken ?? ""}
            InputProps={{
              endAdornment: (
                <FaButton
                  icon={faCopy}
                  tooltip={t("COMMON.COPY")}
                  onClick={() => copy(ssoSettings?.scimToken ?? "")}
                />
              ),
            }}
            aria-describedby="scim-token-helper-text"
          />
        </FormRow>

        {!isLegacy ? (
          <>
            <FormRow labelGridWidth={4} label={t("SSO.PRODUCT_ASSIGNMENT")}>
              <FormControlLabel
                label={<Typography>{t("SSO.ADD_NEW_FAX_PLUS")}</Typography>}
                control={
                  <Checkbox
                    id="add-new-sso-users-on-fax"
                    checked={isAddingNewFaxUsers}
                    onChange={isAddingNewFaxUsersBool.toggle}
                    inputProps={{ "aria-label": "new-sso-users-on-fax" }}
                  />
                }
              />
              <FormControlLabel
                label={
                  <>
                    <Typography>{t("SSO.ADD_NEW_SIGN_PLUS")}</Typography>
                    <Typography type="subtitle">{t("SSO.SEATS_MUST_BE_AVAILABLE")}</Typography>
                  </>
                }
                control={
                  <Checkbox
                    id="add-new-sso-users-on-sign"
                    checked={isAddingNewSignUsers}
                    onChange={isAddingNewSignUsersBool.toggle}
                    inputProps={{ "aria-label": "new-sso-users-on-sign" }}
                  />
                }
              />
            </FormRow>
          </>
        ) : null}
      </Box>
    </Modal>
  );
}

export default ScimConfigurationStep;
