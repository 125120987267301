import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
import { useBool, useSnackbar, Box, Modal, TextField, Typography } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { useAppDispatch, useAppSelector } from "stores/store";
import { selectAccountEmail } from "selectors/account.selector";
import { accountDelete } from "stores/reducers/account.reducer";
import { clearLogin, login } from "stores/reducers/authentication.reducer";
import { selectRequestDataDeletionError } from "selectors/support.selector";
import { selectLoginErrorNotification } from "selectors/authentication.selector";
import { clearRequestDataDeletion, requestDataDeletion } from "stores/reducers/support.reducer";
import { isValidPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "helpers/inputValidation";

interface ConfirmPasswordModalProps {
  handleClosure: (successMessage: string | null) => void;
}

function ConfirmPasswordModal({ handleClosure }: ConfirmPasswordModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const accountEmail = useAppSelector(selectAccountEmail);
  const loginError = useAppSelector(selectLoginErrorNotification);
  const dataDeletionError = useAppSelector(selectRequestDataDeletionError);

  const [isLoading, isLoadingBool] = useBool(false);
  const [password, passwordInput] = useInput("", isValidPassword);

  const handleRequestDataDeletion = useCallback(async () => {
    try {
      await dispatch(requestDataDeletion()).unwrap();

      handleClosure(t("DATA_DELETION.YOUR_REQUEST_HAS_BEEN_SENT"));
    } catch {
      // Nothing to do with the error
    } finally {
      isLoadingBool.setFalse();
    }
  }, [dispatch, handleClosure, isLoadingBool, t]);

  const handleOnConfirm = useCallback(async () => {
    passwordInput.touch();
    isLoadingBool.setTrue();

    if (passwordInput.isValid) {
      try {
        await dispatch(
          login({
            username: accountEmail,
            password: passwordInput.ref.current,
          }),
        ).unwrap();

        await dispatch(accountDelete()).unwrap();

        isLoadingBool.setFalse();

        handleClosure(t("DATA_DELETION.ACCOUNT_DELETED"));
      } catch (error) {
        if (error && typeof error === "object" && "code" in error && error?.code === 401) {
          isLoadingBool.setFalse();
        } else {
          handleRequestDataDeletion();
        }
      }
    }
  }, [
    t,
    dispatch,
    accountEmail,
    handleClosure,
    isLoadingBool,
    passwordInput,
    handleRequestDataDeletion,
  ]);

  const helperText = useMemo(
    () =>
      passwordInput.showsError && !passwordInput.isValid
        ? t("FORMS.PASSWORD_LENGTH_RANGE", {
            min: MIN_PASSWORD_LENGTH,
            max: MAX_PASSWORD_LENGTH,
          })
        : " ",
    [passwordInput.isValid, passwordInput.showsError, t],
  );

  useEffect(() => {
    if (loginError) {
      enqueueSnackbar(t("FORMS.INVALID_PASSWORD"), { variant: "error" });
      passwordInput.setShowsError(true);
      dispatch(clearLogin());
    }
  }, [dispatch, enqueueSnackbar, loginError, passwordInput, t]);

  useEffect(() => {
    if (dataDeletionError) {
      enqueueSnackbar(dataDeletionError, {
        variant: "error",
      });

      dispatch(clearRequestDataDeletion());
    }
  }, [dataDeletionError, dispatch, enqueueSnackbar]);

  return (
    <Modal
      maxWidth="xs"
      onConfirm={handleOnConfirm}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      onCancel={() => handleClosure(null)}
      title={t("DATA_DELETION.PASSWORD_TITLE")}
      isConfirmDisabled={passwordInput.showsError}
    >
      <Box p={3}>
        <Typography type="body">{t("DATA_DELETION.PASSWORD_DESCRIPTION")}</Typography>
        <Box my={2}>
          <TextField
            fullWidth
            id="password"
            type="password"
            value={password}
            helperText={helperText}
            onBlur={passwordInput.onBlur}
            onFocus={passwordInput.onFocus}
            error={passwordInput.showsError}
            placeholder={t("FORMS.PASSWORD")}
            onChange={passwordInput.onChange}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfirmPasswordModal;
