import { useState, useMemo } from "react";

export type UseBoolType = [
  boolean,
  {
    reset: () => void;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
    set: (value: boolean) => void;
  },
];

function useBool(initialState: boolean): UseBoolType {
  const [value, setValue] = useState(initialState);

  const handlers = useMemo(
    () => ({
      set: (value: boolean) => setValue(value),
      setTrue: () => setValue(true),
      setFalse: () => setValue(false),
      reset: () => setValue(initialState),
      toggle: () => setValue((prev) => !prev),
    }),
    [initialState],
  );

  return [value, handlers];
}

export default useBool;
