import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Waypoint } from "react-waypoint";
import { useTranslation } from "react-i18next";

import i18n from "i18n";
import { Typography, makeStyles } from "ui";
import { useDelayedRender } from "hooks/useDelayedRender";
import Loading from "../Loading/Loading";

function InfiniTable({
  options: optionsOverrides,
  columns,
  data: dataFromProps,
  components: componentsFromProps,
  onWaypoint,
  hasWaypoint,
  isLoading = false,
  isShowTableNoData = false,
  rowHeight = 56,
}) {
  const classes = useStyles({ rowHeight });
  const { t } = useTranslation();

  const data = dataFromProps ?? []; // Overwrites falsy values (like null)

  const options = {
    filter: false,
    print: false,
    search: false,
    serverSide: true,
    pagination: false,
    viewColumns: false,
    download: false,
    responsive: "standard",
    filterType: "dropdown",
    elevation: 0,
    rowHover: false,
    selectToolbarPlacement: "none",
    customFooter: function footerRender() {
      return hasWaypoint && !!data.length ? (
        <Waypoint key={data.length} onEnter={onWaypoint} bottomOffset="-401px">
          <tbody>
            <tr>
              <td>
                <Box display="flex" alignItems="center" justifyContent="center" p={1}>
                  <Typography variant="subtitle3" align="center" component="span">
                    {t("COMMON.LOADING")}
                  </Typography>
                  <Loading />
                </Box>
              </td>
            </tr>
          </tbody>
        </Waypoint>
      ) : (
        <></>
      );
    },
    ...optionsOverrides,
  };

  const delayedLoading = useDelayedRender(
    <Box display="flex" alignItems="center" justifyContent="center" p={1}>
      <Typography variant="subtitle" align="center" component="span">
        {t("COMMON.LOADING")}
      </Typography>
      <Loading scale={0.8} />
    </Box>,
  );

  isLoading &&
    (options.textLabels = {
      body: { noMatch: delayedLoading },
    });

  if (!data.length && !isShowTableNoData) {
    // Manually render the noMatch component because we want it "outside" the table
    const noMatchComponent = options?.textLabels?.body?.noMatch ?? null;
    return (
      <Box p={2} justifyContent={"center"} alignItems={"center"}>
        {noMatchComponent}
      </Box>
    );
  }

  return (
    <Box className={classes.tableOverwrite}>
      <MUIDataTable
        className={classes.dataTable}
        data={data}
        columns={columns}
        options={options}
        key={i18n.language}
        components={componentsFromProps}
      />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  tableOverwrite: ({ rowHeight }) => ({
    '& tr[class*="MUIDataTableBodyRow"]': {
      height: rowHeight,
    },
  }),
  dataTable: {
    borderRadius: 0,
  },
}));

InfiniTable.propTypes = {
  ...MUIDataTable.propTypes,
  isLoading: PropTypes.bool,
  onWaypoint: PropTypes.func,
  hasWaypoint: PropTypes.bool,
  isShowTableNoData: PropTypes.bool,
};

export default InfiniTable;
