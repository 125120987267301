import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { Box, useOnMount, Modal, Typography, makeStyles } from "@alohi/kit";

import useBool from "hooks/useBool";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import ManagePaymentMethodModal from "views/ManagePaymentMethod/ManagePaymentMethodModal";
import {
  selectDepositIsNotHonored,
  selectPaymentDepositError,
  selectSelectedPaymentType,
} from "selectors/payment.selector";
import SwitchPaymentFooter from "./SwitchPaymentFooter";

function AddCreditError({ handleClosure }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isNotHonoredPayment = useSelector(selectDepositIsNotHonored);
  const paymentDepositError = useSelector(selectPaymentDepositError);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentType);

  const [firstErrorText, setFirstErrorText] = useState("");
  const [secondErrorText, setSecondErrorText] = useState("");
  const [hasSwitchPaymentFooter, hasSwitchPaymentFooterBool] = useBool(false);
  const [isManagePaymentMethodModalOpened, isManagePaymentMethodModalOpenedBool] = useBool(false);
  const [confirmTitle, setConfirmTitle] = useState(t("FORMS.DONE"));
  const [title, setTitle] = useState(t("ADD_CREDIT.PAYMENT_FAILED"));
  const [handleConfirm, setHandleConfirm] = useState(() => {
    return () => undefined;
  });
  const [newPaymentMethodTab, setNewPaymentMethodTab] = useState("");

  const handleSwitchPaymentMethod = (type) => {
    isManagePaymentMethodModalOpenedBool.setTrue();
    if (type !== "list") {
      setNewPaymentMethodTab(type);
    }
  };

  useOnMount(() => {
    if (isNotHonoredPayment) {
      if (selectedPaymentMethod === "paypal") {
        setFirstErrorText(t("ERRORS.PAYPAL_PAYMENT_DECLINED"));
      } else {
        setFirstErrorText(t("ERRORS.DO_NOT_HONOR_1"));
        setSecondErrorText(t("ERRORS.DO_NOT_HONOR_2"));
      }
      setConfirmTitle(t("TITLES.RETRY"));
      setTitle(t("ADD_CREDIT.PAYMENT_DECLINED"));
      setHandleConfirm(() => {
        return () => isManagePaymentMethodModalOpenedBool.setTrue();
      });
      hasSwitchPaymentFooterBool.setTrue();
    } else {
      setConfirmTitle(t("FORMS.DONE"));
      setTitle(t("ADD_CREDIT.PAYMENT_FAILED"));
      setFirstErrorText(paymentDepositError);
      setHandleConfirm(() => {
        return () => handleClosure(false);
      });
    }
  });

  useEffect(() => {
    return () => {
      dispatch(getCreditDetails());
      dispatch(getCurrentCredit());
    };
  }, [dispatch]);

  return (
    <Modal
      confirmTitle={confirmTitle}
      onConfirm={handleConfirm}
      title={title}
      customFooterInfo={
        hasSwitchPaymentFooter ? (
          <SwitchPaymentFooter onOpenPaymentMethod={handleSwitchPaymentMethod} />
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <FontAwesomeIcon className={classes.icon} size="6x" icon={faExclamationCircle} />
        <Typography variant="body" align="center">
          {firstErrorText || t("ADD_CREDIT.CARD_DECLINED")}
        </Typography>
        {secondErrorText ? (
          <Typography className={classes.secondText} variant="body" align="center">
            {secondErrorText || t("ADD_CREDIT.CARD_DECLINED")}
          </Typography>
        ) : null}
      </Box>
      {isManagePaymentMethodModalOpened ? (
        <ManagePaymentMethodModal
          handleClosure={() => handleClosure(true)}
          initialTab={newPaymentMethodTab}
        />
      ) : null}
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  wrapper: {
    display: "flex",
    margin: spacing(2),
    alignItems: "center",
    alignContent: "start",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    fontSize: "6rem",
    color: alohi.gold,
    marginBottom: spacing(2),
  },
  secondText: {
    margin: spacing(2, 0, 1, 0),
  },
}));

AddCreditError.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default AddCreditError;
