import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem, Typography, FaButton, Modal, Box, useSnackbar } from "@alohi/kit";

import PaymentMethodImg from "components/Core/PaymentMethod/PaymentMethodImg";
import {
  List,
  ListItem,
  IconButton,
  makeStyles,
  ListItemAvatar,
  ListItemSecondaryAction,
} from "ui";
import {
  getPaymentMethods,
  getDefaultPaymentMethod,
} from "stores/reducers/payment.methods.reducer";
import {
  updateCreditCard,
  deleteCreditCardById,
  clearDeleteCreditCard,
  clearUpdateCreditCard,
} from "stores/reducers/payment.creditCard.reducer";
import {
  selectPaymentMethods,
  selectSelectedPaymentMethod,
  selectIsErrorDeleteCreditCard,
  selectIsErrorUpdateCreditCard,
  selectIsDeleteCreditCardSuccessful,
  selectIsUpdateCreditCardSuccessful,
} from "selectors/payment.selector";

function ListPaymentMethod() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const paymentMethods = useSelector(selectPaymentMethods);
  const isDeleteError = useSelector(selectIsErrorDeleteCreditCard);
  const isUpdateError = useSelector(selectIsErrorUpdateCreditCard);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentMethod);

  const isDeleteSuccessful = useSelector(selectIsDeleteCreditCardSuccessful);
  const isUpdateSuccessful = useSelector(selectIsUpdateCreditCardSuccessful);

  const [actionId, setActionId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const resetAction = useCallback(() => {
    setAnchorEl(null);
    setActionId(null);
    setOpenConfirmModal(false);
  }, []);

  const deleteMethod = useCallback(
    (isConfirm) => {
      if (isConfirm) {
        dispatch(deleteCreditCardById(actionId));
        resetAction();
      } else {
        setAnchorEl(null);
        setOpenConfirmModal(true);
      }
    },
    [actionId, resetAction, dispatch],
  );

  const setAsDefault = useCallback(() => {
    dispatch(updateCreditCard(actionId));
    resetAction();
  }, [actionId, resetAction, dispatch]);

  useEffect(() => {
    if (isDeleteError || isUpdateError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      isDeleteError && dispatch(clearDeleteCreditCard());
      isUpdateError && dispatch(clearUpdateCreditCard());
    }
  }, [isDeleteError, isUpdateError, t, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (isDeleteSuccessful || isUpdateSuccessful) {
      dispatch(getPaymentMethods());
      isDeleteSuccessful && dispatch(clearDeleteCreditCard());
      if (isUpdateSuccessful) {
        dispatch(getDefaultPaymentMethod());
        dispatch(clearUpdateCreditCard());
      }
    }
  }, [isDeleteSuccessful, isUpdateSuccessful, dispatch]);

  if (!paymentMethods) {
    return null;
  }

  return (
    <>
      <List className={classes.list}>
        {paymentMethods.map((method, index) => {
          const isDefault = method.id === selectedPaymentMethod.id;
          return (
            <ListItem divider key={method.id + index} style={{ paddingLeft: 12 }}>
              <ListItemAvatar className={classes.listItemAvatar}>
                <Box display="flex" alignItems="center">
                  <Box className={classes.iconWrapper}>
                    {isDefault ? (
                      <IconButton
                        aria-haspopup="true"
                        disabled={isDefault}
                        aria-owns={"long-menu"}
                        aria-label="SetAsDefault"
                        title={t("ADD_CREDIT.SET_AS_DEFAULT")}
                      >
                        <FontAwesomeIcon icon={faStarSharp} className={classes.starIcon} />
                      </IconButton>
                    ) : null}
                  </Box>
                  <PaymentMethodImg brand={method.brand || method.type} />
                </Box>
              </ListItemAvatar>
              <Box
                style={{
                  paddingRight: "5px",
                  minWidth: 113,
                  overflow: "hidden",
                }}
              >
                <Typography className={classes.cardText}>
                  {method.type !== "paypal" ? `**** **** **** ${method.last4}` : method.email}
                </Typography>
                <Typography className={classes.cardText}>
                  {method.type !== "paypal" && `Exp. ${method.exp_month}/${method.exp_year}`}
                </Typography>
              </Box>
              {method.type !== "paypal" ? (
                <Box
                  style={{
                    paddingRight: "5px",
                    marginLeft: "16px",
                    overflow: "hidden",
                  }}
                >
                  <Typography className={classes.cardText}>{method.name}</Typography>
                  <Typography className={classes.cardText}>{method.address_line1}</Typography>
                </Box>
              ) : null}
              {isDefault ? null : (
                <ListItemSecondaryAction>
                  <FaButton
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={(event) => {
                      setActionId(method.id);
                      setAnchorEl(event.currentTarget);
                    }}
                    aria-controls={`actions-menu-payment-methods`}
                    icon={faEllipsisV}
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
      <Menu
        elevation={2}
        anchorEl={anchorEl}
        onClose={resetAction}
        open={Boolean(anchorEl)}
        id={`actions-menu-payment-methods`}
      >
        <MenuItem onClick={setAsDefault}>
          <Typography className={classes.label}>{t("ADD_CREDIT.SET_AS_DEFAULT")}</Typography>
        </MenuItem>
        <MenuItem onClick={() => deleteMethod(false)}>
          <Typography className={classes.label}>{t("ADD_CREDIT.DELETE")}</Typography>
        </MenuItem>
      </Menu>
      {openConfirmModal ? (
        <Modal
          maxWidth="xs"
          isAllowingOverlap
          onCancel={resetAction}
          onConfirm={() => deleteMethod(true)}
          title={t("DELETE_ITEM.ARE_YOU_SURE")}
        >
          <Box p={3}>{t("DELETE_ITEM.ARE_YOU_SURE_DELETE_PAYMENT_METHOD")}</Box>
        </Modal>
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  list: {
    padding: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    marginBottom: spacing(1),
    flexDirection: "column",
  },
  img: {
    boxShadow: "none",
    padding: spacing(2),
  },
  starIcon: {
    fontSize: 16,
    color: alohi.almostGray,
  },
  iconWrapper: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listItemAvatar: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  label: {
    fontSize: "small",
  },
  cardText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default ListPaymentMethod;
