import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip, makeStyles } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle as faQuestionCircleLight } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle as faQuestionCircleDuotone } from "@fortawesome/pro-duotone-svg-icons";

function HelpTooltip({
  title,
  children,
  size = "1x",
  iconClassName = "",
  placement = "top-start",
  variant = "dark",
  ...rest
}) {
  const classes = useStyles();

  const tooltipContent = children ?? (
    <div style={{ display: "inline-flex", verticalAlign: "sub" }}>
      <FontAwesomeIcon
        size={size}
        icon={variant === "dark" ? faQuestionCircleDuotone : faQuestionCircleLight}
        className={classNames(iconClassName, classes[variant])}
      />
    </div>
  );

  if (!title) return tooltipContent;

  return (
    <Tooltip placement={placement} title={title} {...rest} classes={{ tooltip: classes.tooltip }}>
      {tooltipContent}
    </Tooltip>
  );
}

const useStyles = makeStyles(({ spacing, alohi, typography, palette }) => ({
  tooltip: {
    borderColor: palette.divider,
    background: "rgba(0, 0, 0, 0.8)",
    fontSize: typography.pxToRem(12),
    '& span[class*="MuiTypography"]': {
      color: `${alohi.white}`,
    },
    '& p[class*="MuiTypography"]': {
      color: `${alohi.white}`,
    },
    padding: spacing(1),
  },
  dark: {
    display: "inline-block",
    "--fa-primary-color": alohi.white,
    "--fa-secondary-color": alohi.lightGray,
    "--fa-secondary-opacity": 1,
  },
  light: { color: "white" },
  blue: { color: alohi.blue },
}));

HelpTooltip.propTypes = {
  title: PropTypes.any,
  size: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.string,
  iconClassName: PropTypes.string,
  variant: PropTypes.oneOf(["dark", "light", "blue"]),
};

export default HelpTooltip;
