import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BigButton, Box, Typography, makeStyles } from "@alohi/kit";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";
import { faEarthEurope, faFlagUsa } from "@fortawesome/pro-regular-svg-icons";

import { UseBoolType } from "hooks/useBool";
import FormRow from "components/Forms/FormRow";

interface DestinationsProps {
  isUsCa: UseBoolType[0];
  isUsCaBool: UseBoolType[1];
}

function Destinations({ isUsCa, isUsCaBool }: DestinationsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormRow labelGridWidth={4} label={t("PAGE_OPTIONS.FAXING_DESTINATIONS")}>
      <Box display="flex" justifyContent="flex-start">
        <BigButton width="112px" height="112px" isSelected={isUsCa} onClick={isUsCaBool.setTrue}>
          <Box>
            <FontAwesomeIcon
              size="2x"
              icon={faFlagUsa}
              className={clsx({
                [classes.icon]: true,
                [classes.iconLeft]: true,
              })}
            />
            <FontAwesomeIcon
              size="2x"
              icon={faCanadianMapleLeaf}
              className={clsx({
                [classes.icon]: true,
                [classes.iconRight]: true,
              })}
            />
          </Box>
          <Typography sx={{ "&&": { mt: 2 } }}>US & Canada</Typography>
        </BigButton>

        <BigButton
          width="112px"
          height="112px"
          sx={{ ml: 2 }}
          isSelected={!isUsCa}
          onClick={isUsCaBool.setFalse}
        >
          <FontAwesomeIcon icon={faEarthEurope} size="3x" className={classes.icon} />
          <Typography sx={{ "&&": { mt: 2 } }}>International</Typography>
        </BigButton>
      </Box>
    </FormRow>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  icon: {
    color: alohi.almostGray,
  },
  iconLeft: {
    marginRight: spacing(1),
  },
  iconRight: {
    marginLeft: spacing(1),
  },
}));

export default Destinations;
