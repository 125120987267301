import { useTranslation } from "react-i18next";
import { Box, Typography, Modal } from "@alohi/kit";

interface DocumentRetentionPeriodModalProps {
  handleClosure: (isSuccess: boolean) => void;
}

function DocumentRetentionPeriodModal({ handleClosure }: DocumentRetentionPeriodModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      maxWidth="xs"
      isConfirmDisabled={false}
      cancelTitle={t("COMMON.CANCEL")}
      confirmTitle={t("COMMON.CONFIRM")}
      onCancel={() => handleClosure(false)}
      onConfirm={() => handleClosure(true)}
      onPressEnter={() => handleClosure(true)}
      onPressEscape={() => handleClosure(false)}
      title={t("PROFILE.DOCUMENT_RETENTION_TITLE")}
    >
      <Box p={3}>
        <Typography type="body">{t("PROFILE.DOCUMENT_RETENTION_ARE_YOU_SURE_INFO")}</Typography>
        <Typography type="body">{t("PROFILE.DOCUMENT_RETENTION_ARE_YOU_SURE_INFO_TWO")}</Typography>
      </Box>
    </Modal>
  );
}

export default DocumentRetentionPeriodModal;
