import { Panel, Button } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import RequestSocModal from "views/Profile/RequestSocModal";

function ComplianceDocumentsPanel() {
  const { t } = useTranslation();

  const isSocRequestAlreadySent = localStorage.getItem("socRequestSent") === "true";

  const [isRequestSocModalOpen, isRequestSocModalOpenBool] = useBool(false);

  return (
    <>
      <Panel
        sx={{ mb: 7 }}
        id="compliance-documents"
        data-cy={dataCy.complianceDocumentsPanel}
        headerTitle={t("PROFILE.COMPLIANCE_DOCUMENTS_TITLE")}
      >
        <Panel.Front>
          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.COMPLIANCE_DOCUMENTS_SOC_2_REPORT")}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <Button
                variant="white"
                onClick={isRequestSocModalOpenBool.setTrue}
                isDisabled={isSocRequestAlreadySent}
                sx={{ maxWidth: 110 }}
              >
                {isSocRequestAlreadySent
                  ? t("COMMON.REQUESTED")
                  : t("PROFILE.COMPLIANCE_DOCUMENTS_REQUEST_REPORT")}
              </Button>
            </Panel.Cell>
          </Panel.Row>

          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.COMPLIANCE_DOCUMENTS_SOC_3_REPORT")}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <a
                href="/static/compliance/Alohi_SOC3.pdf"
                download="Alohi_SOC3.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="white">{t("COMMON.DOWNLOAD")}</Button>
              </a>
            </Panel.Cell>
          </Panel.Row>

          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.COMPLIANCE_DOCUMENTS_ISO_CERTIFICATE")}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <a
                href="/static/compliance/EYCP_Certificate_Alohi_27001.pdf"
                download="EYCP_Certificate_Alohi_27001.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="white">{t("COMMON.DOWNLOAD")}</Button>
              </a>
            </Panel.Cell>
          </Panel.Row>

          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.COMPLIANCE_DOCUMENTS_ISO_SOA")}</Panel.Cell>
            <Panel.Cell variant="actions">
              <a
                href="/static/compliance/AlohiSOA.pdf"
                download="AlohiSOA.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="white">{t("COMMON.DOWNLOAD")}</Button>
              </a>
            </Panel.Cell>
          </Panel.Row>

          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.COMPLIANCE_DOCUMENTS_PCI_DSS_CERTIFICATE")}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <a
                href="/static/compliance/Alohi_PCI_DSS.pdf"
                download="Alohi_PCI_DSS.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="white">{t("COMMON.DOWNLOAD")}</Button>
              </a>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>
      </Panel>

      {isRequestSocModalOpen && (
        <RequestSocModal handleClosure={isRequestSocModalOpenBool.setFalse} />
      )}
    </>
  );
}

export default ComplianceDocumentsPanel;
