import { Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "stores/store";
import { gaEvents } from "helpers/googleAnalytics";
import useForceRemount from "hooks/useForceRemount";
import { isLessTheOneDayFromNow } from "helpers/date";
import ChargeError from "views/Transactions/ChargeError";
import useOnMountCondition from "hooks/useOnMountCondition";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import { selectAccountInfo } from "selectors/account.selector";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import { selectFirstPaymentDate } from "selectors/credit.selector";
import { selectIsCorporateUpgrade } from "selectors/common.selector";
import NewBundleRedirect from "components/AddressBundle/NewBundleRedirect";
import { clearChargeAmount } from "stores/reducers/payment.charge.reducer";
import ChangePlanFromFreeModal from "views/ChangePlan/ChangePlanFromFreeModal";
import ChangePlanFromPaidModal from "views/ChangePlan/ChangePlanFromPaidModal";
import PurchasedNumbersSuccess from "views/Transactions/PurchasedNumbersSuccess";
import { clearCorporateUpgrade } from "stores/reducers/payment.corporate.reducer";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import { getAllNumbers, getAssignedNumbers } from "stores/reducers/numbers.reducer";
import { getAccountDetails, getCustomerInfo } from "stores/reducers/account.reducer";
import { clearUpdateCompany, updateCompanyInfo } from "stores/reducers/corporate.reducer";
import { selectNextPlan, selectIsPlanFree, selectAvailablePlans } from "selectors/plan.selector";
import {
  selectChargeAmount,
  selectIsChargeAmountError,
  selectCorporateUpgradeState,
  selectCurrentCart,
  selectPaymentDepositError,
} from "selectors/payment.selector";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

interface ChangePlanProps {
  handleClosure: (isSuccess: boolean) => void;
}

function ChangePlan({ handleClosure }: ChangePlanProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cleanReducers = useCleanPlansReducers();
  const [key, forceRemount] = useForceRemount();
  const { sendCustomEvent } = useGoogleTagManager();

  const nextPlan = useSelector(selectNextPlan);
  const { name } = useSelector(selectAccountInfo);
  const isPlanFree = useSelector(selectIsPlanFree);
  const availablePlans = useSelector(selectAvailablePlans);
  const firstPaymentDate = useSelector(selectFirstPaymentDate);
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);
  const corporateUpgradeState = useSelector(selectCorporateUpgradeState);

  const isChargeSuccess = useSelector(selectChargeAmount);
  const isChargeError = useSelector(selectIsChargeAmountError);
  const currentCart = useSelector(selectCurrentCart);
  const isPaymentDepositError = useSelector(selectPaymentDepositError);

  const [openChargeError, setOpenChargeError] = useState(false);
  const [openChargeSuccess, setOpenChargeSuccess] = useState(false);
  const [openChangePlanFromFree, setOpenChangePlanFromFree] = useState(false);
  const [openChangePlanFromPaid, setOpenChangePlanFromPaid] = useState(false);

  const handleOpenModal = useCallback(() => {
    cleanReducers();

    if (openChargeError || openChargeSuccess || openChangePlanFromFree || openChangePlanFromPaid) {
      // Don't open any modal if any modal is already open
      return;
    }

    if (isPlanFree) {
      setOpenChangePlanFromFree(true);
    } else {
      setOpenChangePlanFromPaid(true);
    }
  }, [
    isPlanFree,
    cleanReducers,
    openChargeError,
    openChargeSuccess,
    openChangePlanFromFree,
    openChangePlanFromPaid,
  ]);

  const handleSuccess = useCallback(() => {
    dispatch(getCurrentCredit());
    dispatch(getCreditDetails());
    dispatch(getAllNumbers());
    dispatch(getAssignedNumbers());
    dispatch(getAccountDetails());
    dispatch(getCustomerInfo());

    cleanReducers();

    handleClosure(true);
  }, [dispatch, cleanReducers, handleClosure]);

  useEffect(() => {
    if (isChargeSuccess) {
      if (isPlanFree) {
        if (isLessTheOneDayFromNow(firstPaymentDate) || firstPaymentDate === null) {
          gaEvents.newSubscription({
            planType: nextPlan?.plan_type?.toUpperCase(),
            value: nextPlan?.price,
            currency: nextPlan?.currency,
          });
        } else {
          sendCustomEvent({
            event: GoogleAnalyticsCustomEvents.reactivateSubscription,
            extraData: {
              planType: nextPlan?.plan_type?.toUpperCase() as string,
              value: nextPlan?.price,
              currency: nextPlan?.currency,
            },
          });
        }
      }

      setOpenChangePlanFromFree(false);
      setOpenChangePlanFromPaid(false);
      setOpenChargeSuccess(true);
      // Update with firstName if there was no input from the user while upgrading to corporate
      if (isCorporateUpgrade) {
        dispatch(
          updateCompanyInfo({
            companyName: corporateUpgradeState.companyName || name,
          }),
        );
        dispatch(clearCorporateUpgrade());
        dispatch(clearUpdateCompany());
      }
      dispatch(getCurrentPlan());
      dispatch(clearChargeAmount());
    } else if (isChargeError) {
      setOpenChargeError(true);
    }
  }, [
    name,
    dispatch,
    nextPlan,
    isPlanFree,
    isChargeError,
    isChargeSuccess,
    firstPaymentDate,
    isCorporateUpgrade,
    corporateUpgradeState.companyName,
    sendCustomEvent,
  ]);

  useEffect(() => {
    if (availablePlans && availablePlans.length === 0) {
      handleClosure(false);
    }
  }, [availablePlans, handleClosure]);

  useEffect(() => {
    handleOpenModal();
  }, [handleOpenModal]);

  useOnMountCondition(gaEvents.changePlanModalOpen, true);

  useOnMountCondition(() => {
    if (!nextPlan) {
      return;
    }
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartInitalization,
      page: "plan_upgrade",
      cart: {
        faxPlan: {
          planType: (nextPlan.plan_type as string).toLocaleUpperCase(),
          period: nextPlan.duration_unit === "year" ? "ANNUAL" : "MONTHLY",
          highVolumePages: nextPlan.pages,
        },
        totalPrice: undefined,
      },
    });
  }, Boolean(nextPlan));

  useOnMountCondition(() => {
    if (!nextPlan) {
      return;
    }

    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartPurchaseSuccess,
      page: "plan_upgrade",
      cart: {
        faxPlan: {
          planType: (nextPlan.plan_type as string).toLocaleUpperCase(),
          period: nextPlan.duration_unit === "year" ? "ANNUAL" : "MONTHLY",
          highVolumePages: nextPlan.pages,
        },
        faxNumbers: {
          totalCount: currentCart.items.random.length + currentCart.items.custom.length,
          customCount: currentCart.items.custom.length,
        },
        totalPrice: currentCart.total_amount,
      },
    });
  }, Boolean(isChargeSuccess));

  useOnMountCondition(
    () => {
      if (!nextPlan) {
        return;
      }

      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.cartPurchaseFailed,
        page: "plan_upgrade",
        cart: {
          faxPlan: {
            planType: (nextPlan.plan_type as string).toLocaleUpperCase(),
            period: nextPlan.duration_unit === "year" ? "ANNUAL" : "MONTHLY",
            highVolumePages: nextPlan.pages,
          },
          faxNumbers: {
            totalCount: currentCart.items.random.length + currentCart.items.custom.length,
            customCount: currentCart.items.custom.length,
          },
          totalPrice: currentCart.total_amount,
        },
      });
    },
    Boolean(isChargeError) || Boolean(isPaymentDepositError),
  );

  return (
    <Box key={key}>
      <NewBundleRedirect handleClosure={handleClosure} />

      {openChangePlanFromFree ? <ChangePlanFromFreeModal handleClosure={handleClosure} /> : null}

      {openChangePlanFromPaid ? <ChangePlanFromPaidModal handleClosure={handleClosure} /> : null}

      {openChargeSuccess ? (
        <PurchasedNumbersSuccess
          handleClosure={handleSuccess}
          title={t("COMMON.CHANGE_PLAN")}
          description={t("CHANGE_PLAN.YOUR_PLAN_SETUP")}
        />
      ) : null}

      {openChargeError ? (
        <ChargeError
          handleClosure={(shouldCloseModal) => {
            if (shouldCloseModal) {
              forceRemount();
              setOpenChargeError(false);
            } else {
              handleClosure(false);
            }
          }}
        />
      ) : null}
    </Box>
  );
}

export default ChangePlan;
