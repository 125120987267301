import clsx from "clsx";
import { ElementType, MouseEvent } from "react";
import { Box, BoxProps, makeStyles } from "@alohi/kit";
import { TypographyProps as MuiTypographyProps } from "@mui/material";

// Add variant here (example auto generated)
export const variants = [
  "body",
  "authenticationTitle",
  "subtitle",
  "subtitle3",
  "cardFrontHeader",
  "panelFrontHeader",
  "panelBackHeader",
  "panelCellTitle",
  "panelCellData",
  "modalTitle",
  "header",
  "navigationLabel",
  "appBarTitle",
  "loginButton",
  "tableContent",
  "tableContentBold",
  "tabName",
  "sideBarLabel",
  "envelopeNameCell",
  "envelopeSubNameCell",
  "userMenuName",
  "footer",
  "alohiBranding",
  "doneSigning",
  "promotion",
  "bigText",
  "signatureConsent",
  "dropZone",
  "voided",
  "anchor",
  "caption",
  "currentCredit",
  "subLabel",
  "h5",
  "h4",
  "bold",
  "warning",
  "info",
  "link",
] as const;

interface TypographyProps extends BoxProps {
  variant?: (typeof variants)[number];
  align?: MuiTypographyProps["align"];
  stopPropagation?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

function Typography({
  variant = "body",
  children,
  className,
  align,
  stopPropagation,
  onClick,
  ...rest
}: TypographyProps) {
  const classes = useStyles({ align });

  const Component: ElementType = variantMapping[variant] ?? "span";

  const handleStopPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick?.(event);
  };

  return (
    <Box
      component={Component}
      {...rest}
      className={clsx(className, classes.base, classes[variant])}
      onClick={stopPropagation ? handleStopPropagation : onClick}
    >
      {children}
    </Box>
  );
}

// Unless specified, the variants will use the <span> components
const variantMapping: { [variant in (typeof variants)[number]]?: ElementType } = {
  body: "p",
  header: "h3",
  tableContent: "p",
  tableContentBold: "p",
  footer: "p",
  alohiBranding: "span",
  caption: "p",
  subtitle3: "p",
  h4: "h4",
  h5: "h5",
};

const useStyles = makeStyles((theme) => ({
  base: (props: { align: TypographyProps["align"] }) => ({
    margin: 0,
    textAlign: props.align,
  }),
  body: { color: theme.alohi.gray, fontSize: "0.875rem" },
  authenticationTitle: {
    color: theme.alohi.gray,
    fontSize: "1.125rem",
    fontWeight: "bold",
    lineHeight: 2,
  },
  subtitle: { color: theme.alohi.lightGray, fontSize: "0.875rem" },
  subtitle3: { color: theme.alohi.gray, fontSize: "1.1rem" },
  subLabel: {
    color: theme.alohi.lightGray,
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
  dropZone: {
    color: theme.alohi.lightGray,
    fontSize: "1rem",
    textAlign: "center",
  },
  cardFrontHeader: {
    color: theme.alohi.white,
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
  panelFrontHeader: {
    color: theme.alohi.white,
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
  panelBackHeader: {
    color: theme.alohi.blue,
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
  panelCellTitle: {
    color: theme.alohi.gray,
    fontWeight: "bold",
    fontSize: "0.875rem",
    textAlign: "left",
  },
  panelCellData: {
    fontSize: "0.875rem",
    color: theme.alohi.gray,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  modalTitle: { color: theme.alohi.white, fontSize: "1.125rem" },
  header: {
    color: theme.alohi.white,
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  navigationLabel: {
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
    color: theme.alohi.almostGray,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  sideBarLabel: {
    color: theme.alohi.gray,
    fontSize: "0.8rem",
    fontWeight: "bolder",
  },
  appBarTitle: { color: theme.alohi.gray, fontSize: "1.25rem" },
  loginButton: { color: theme.alohi.white },
  tableContent: { lineHeight: 1.5 },
  tableContentBold: { lineHeight: 1.5, fontWeight: "bold" },
  tabName: { color: theme.alohi.white, fontSize: "0.875rem" },
  envelopeNameCell: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    color: theme.alohi.gray,
    textDecoration: "underline",
    "&:hover": { textDecoration: "none" },
  },
  promotion: {
    fontSize: "0.875rem",
    color: theme.alohi.gray,
    textAlign: "center",
  },
  bigText: {
    fontSize: "2rem",
    fontWeight: "bolder",
    color: theme.alohi.gray,
  },
  envelopeSubNameCell: {
    fontSize: "0.875rem",
    color: theme.alohi.gray,
    opacity: 0.65,
    cursor: "default",
  },
  doneSigning: {
    fontSize: "2rem",
    textAlign: "center",
  },
  userMenuName: {
    color: theme.alohi.gray,
    fontSize: "0.875rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  footer: {
    fontSize: "0.75rem",
    color: theme.alohi.gray,
  },
  alohiBranding: {
    lineHeight: 1,
    fontWeight: "bold",
    fontSize: "inherit",
    color: theme.alohi.gray,
  },
  signatureConsent: {
    userSelect: "none",
    fontSize: "0.75rem",
    color: theme.alohi.lightGray,
  },
  voided: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: theme.alohi.red,
  },
  anchor: {
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "0.75rem",
    color: theme.alohi.blue,
    textDecoration: "underline",
  },
  caption: {
    fontSize: "0.75rem",
    color: theme.alohi.gray,
  },
  currentCredit: {
    cursor: "pointer",
    color: theme.alohi.blue,
    fontSize: "0.875rem",
  },
  h5: {
    fontSize: "1.5rem",
    color: theme.alohi.gray,
  },
  h4: {
    fontSize: "2.125rem",
    color: theme.alohi.gray,
    fontWeight: "normal",
  },
  bold: {
    color: theme.alohi.gray,
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  warning: {
    color: theme.alohi.gray,
    fontSize: 13,
  },
  info: {
    color: theme.alohi.gray,
    fontSize: 13,
  },
  link: {
    cursor: "pointer",
    color: theme.alohi.blue,
    fontSize: "0.875rem",
  },
}));

export default Typography;
