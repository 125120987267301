import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useMemo, useState } from "react";
import { useSnackbar, Modal, Box, Chip, Typography, useTheme } from "@alohi/kit";

import { ContactsData } from "selectors/storage.selector";
import { PhoneNumberFilter } from "components/Core/Filters";
import InfiniTable from "components/InfiniTable/InfiniTable";
import ContactsImportFooter from "components/Contacts/ContactsImportFooter";
import { clearImportContacts, importContacts } from "stores/reducers/account.reducer";
import {
  selectIsImportContactsError,
  selectIsImportContactsRunning,
  selectIsImportContactsSuccessful,
} from "selectors/account.selector";

function CandidateContactsModal({
  contactsData,
  handleClosure,
}: {
  contactsData: ContactsData;
  handleClosure: () => void;
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedRows, setSelectedRows] = useState([]);
  const isError = useSelector(selectIsImportContactsError);
  const isLoading = useSelector(selectIsImportContactsRunning);
  const isSuccessful = useSelector(selectIsImportContactsSuccessful);

  const handleConfirm = useCallback(() => {
    const selectedContacts = selectedRows.map((row) => contactsData.list[row]);
    dispatch(importContacts(selectedContacts));
  }, [contactsData.list, dispatch, selectedRows]);

  const clearReducer = useCallback(() => {
    dispatch(clearImportContacts());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccessful) {
      handleClosure();
    }
  }, [handleClosure, isSuccessful]);

  useEffect(() => {
    if (isError) {
      clearReducer();
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [clearReducer, enqueueSnackbar, isError, t]);

  useEffect(() => {
    return () => clearReducer();
  }, [clearReducer]);

  const getColumns = useMemo(
    () => [
      {
        name: "nameAndDuplicate",
        label: t("CONTACTS.NAME"),
        options: {
          customBodyRender: function customBody(value: { name: string; isDuplicate: boolean }) {
            function CustomBodyRender({
              name,
              isDuplicate,
            }: {
              name: string;
              isDuplicate: boolean;
            }) {
              return (
                <Box>
                  <Typography type="body">
                    {name.substr(0, 20)}
                    {name.length > 20 ? "..." : ""}
                  </Typography>
                  {isDuplicate ? (
                    <Typography type="body">{`(${t("CONTACTS.DUPLICATE")})`}</Typography>
                  ) : null}
                </Box>
              );
            }
            CustomBodyRender.propTypes = {
              name: PropTypes.string,
              isDuplicate: PropTypes.bool,
            };
            return <CustomBodyRender {...value} />;
          },
        },
      },
      {
        name: "faxNumber",
        label: t("CONTACTS.FAX_NUMBER"),
        options: {
          customBodyRender: function customBody(value: string) {
            return <PhoneNumberFilter number={value} />;
          },
        },
      },
      {
        name: "tags",
        label: t("CONTACTS.GROUPS"),
        options: {
          customBodyRender: (arr = []) =>
            arr &&
            arr.map((label: string, i) => (
              <Chip
                size="small"
                key={label + i}
                style={{ margin: 2, backgroundColor: theme.alohi.lightestBlue }}
                label={`${label.substr(0, 20)}` + (label.length > 20 ? "..." : "")}
              />
            )),
        },
      },
      {
        name: "comment",
        label: t("CONTACTS.NOTE"),
        options: {
          filter: true,
          filterType: "textField",
          customBodyRender: (value: string) => value?.substr(0, 20),
        },
      },
    ],
    [t, theme.alohi.lightestBlue],
  );

  const totalNumOfRows = contactsData.list[0].numOfContacts;
  const totalNumOfImported = contactsData.list.length;

  return (
    <Modal
      scroll="paper"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
      confirmTitle={t("COMMON.SAVE")}
      data-cy="CandidateContactsModal"
      title={t("CONTACTS.IMPORT_EXCEL")}
      isConfirmDisabled={selectedRows.length === 0}
    >
      <Box>
        <InfiniTable
          data={contactsData.list}
          columns={getColumns}
          options={{
            responsive: "vertical",
            rowsSelected: selectedRows,
            selectableRowsOnClick: true,
            customToolbarSelect: () => null,
            onRowSelectionChange: (_: unknown, allRows: { dataIndex: never }[]) => {
              setSelectedRows(allRows.map((row) => row.dataIndex));
            },
          }}
        />
        <ContactsImportFooter
          totalNumOfRows={totalNumOfRows}
          totalNumOfImported={totalNumOfImported}
          numOfSelectedRows={selectedRows.length}
        />
      </Box>
    </Modal>
  );
}

CandidateContactsModal.propTypes = {
  contacts: PropTypes.array,
  handleClosure: PropTypes.func.isRequired,
};

export default CandidateContactsModal;
