import PropTypes from "prop-types";
import { FormLabel, FormHelperText } from "@alohi/kit";
import { FormControl, Grid, makeStyles } from "ui";

function FormRow({
  labelGridWidth = 4,
  children,
  htmlFor,
  label,
  error,
  helperText,
  isFocused,
  alignLabel,
}) {
  const classes = useStyles({ labelGridWidth, alignLabel });

  return (
    <FormControl fullWidth>
      <Grid container className={classes.container}>
        <Grid item xs={labelGridWidth} className={classes.labelContainer}>
          <FormLabel htmlFor={htmlFor} error={error} className={classes.label} focused={isFocused}>
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12 - labelGridWidth || 12}>
          <div className={classes.inputContainer}>
            {children}
            <FormHelperText error={error}>{helperText || " "}</FormHelperText>
          </div>
        </Grid>
      </Grid>
    </FormControl>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  container: ({ alignLabel }) => ({
    alignItems: alignLabel ? alignLabel : "center",
    minHeight: 75,
    "& .Mui-disabled": {
      color: alohi.gray,
      background: alohi.lighterGray,
    },
  }),
  labelContainer: ({ labelGridWidth, alignLabel }) => ({
    display: "flex",
    marginTop: alignLabel ? "18px" : 0,
    alignItems: alignLabel ? alignLabel : "center",
    textAlign: labelGridWidth === 12 ? "left" : "right",
    justifyContent: labelGridWidth === 12 ? "flex-start" : "flex-end",
  }),
  label: {
    color: alohi.gray,
    fontWeight: "bold",
    marginRight: spacing(2),
    fontSize: 14,
  },
  inputContainer: ({ labelGridWidth }) => ({
    marginTop: labelGridWidth === 12 ? 0 : 19,
    "&& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  }),
}));

FormRow.propTypes = {
  error: PropTypes.bool,
  children: PropTypes.node,
  htmlFor: PropTypes.string,
  isFocused: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelGridWidth: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  alignLabel: PropTypes.string,
};

export default FormRow;
