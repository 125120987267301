import {
  Box,
  Icon,
  Divider,
  MenuList,
  MenuItem,
  Typography,
  makeStyles,
  NestedMenuItem,
} from "@alohi/kit";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";

import { useAppSelector } from "stores/store";
import { selectIsPlanCorporate } from "selectors/plan.selector";
import { selectAccountCid, selectAccountId } from "selectors/account.selector";
import { API_PREFIX, BACKEND_URL, CHAILATTE_URL, COFFEE_URL, VERSION, ZORRO_URL } from "config";

interface MenuProps {
  isMenuOpen: boolean;
  onOpen: (isOpen: boolean) => void;
}

function Menu({ isMenuOpen, onOpen }: MenuProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const accountUid = useAppSelector(selectAccountId);
  const accountCid = useAppSelector(selectAccountCid);
  const isPlanCorporate = useAppSelector(selectIsPlanCorporate);

  return (
    <MenuList disablePadding className={classes.menuList}>
      <Box className={classes.titleContainer}>
        <Typography type="subtitle">{`Version ${VERSION}`}</Typography>
      </Box>
      <Divider className={classes.divider} />

      <Box className={classes.userInfosContainer}>
        <Typography
          className={classes.userInfos}
          type="subtitle"
        >{`UID: ${accountUid}`}</Typography>
        {isPlanCorporate ? (
          <Typography
            className={classes.userInfos}
            type="subtitle"
          >{`CID: ${accountCid}`}</Typography>
        ) : null}
      </Box>
      <Divider className={classes.divider} />

      {BACKEND_URL ? (
        <a href={BACKEND_URL} target="_blank" rel="noreferrer">
          <MenuItem onClick={() => onOpen(false)}>
            <Box flexGrow={1} />
            <Typography pr={1}>{`BE (${API_PREFIX})`}</Typography>
            <Typography>💥</Typography>
          </MenuItem>
        </a>
      ) : null}

      <a href={"https://ui.alohi.io/"} target="_blank" rel="noreferrer">
        <MenuItem onClick={() => onOpen(false)}>
          <Box flexGrow={1} />
          <Typography pr={1}>Storybook</Typography>
          <Typography>🎨</Typography>
        </MenuItem>
      </a>

      {CHAILATTE_URL ? (
        <a href={CHAILATTE_URL} target="_blank" rel="noreferrer">
          <MenuItem onClick={() => onOpen(false)}>
            <Box flexGrow={1} />
            <Typography pr={1}>{"Chailatte"}</Typography>
            <Typography>🍵</Typography>
          </MenuItem>
        </a>
      ) : null}

      {COFFEE_URL ? (
        <a href={COFFEE_URL} target="_blank" rel="noreferrer">
          <MenuItem onClick={() => onOpen(false)}>
            <Box flexGrow={1} />
            <Typography pr={1}>{"Coffee"}</Typography>
            <Typography>☕</Typography>
          </MenuItem>
        </a>
      ) : null}

      {ZORRO_URL ? (
        <a href={ZORRO_URL} target="_blank" rel="noreferrer">
          <MenuItem onClick={() => onOpen(false)}>
            <Box flexGrow={1} />
            <Typography pr={1}>{"Zorro"}</Typography>
            <Typography>⚔️</Typography>
          </MenuItem>
        </a>
      ) : null}

      <Divider className={classes.divider} />

      <NestedMenuItem
        className={classes.menuItem}
        parentMenuOpen={isMenuOpen}
        label={t("COMMON.CHANGE_LANGUAGE")}
        menuPosition="left"
        leftIcon={<Icon icon={faChevronLeft} />}
        rightIcon={null}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            changeLanguage("en")?.catch(() => undefined);
            onOpen(false);
          }}
        >
          {t("COMMON.LANGUAGE_NAME", { lng: "en" })}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            changeLanguage("fr")?.catch(() => undefined);
            onOpen(false);
          }}
        >
          {t("COMMON.LANGUAGE_NAME", { lng: "fr" })}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            changeLanguage("pt")?.catch(() => undefined);
            onOpen(false);
          }}
        >
          {t("COMMON.LANGUAGE_NAME", { lng: "pt" })}
        </MenuItem>
      </NestedMenuItem>
    </MenuList>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  menuList: {
    minWidth: 150,
  },
  menuItem: {
    "& div p": {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "bold",
    padding: spacing(0.5, 2, 0.5, 2),
  },
  userInfosContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(0.5, 2, 0.5, 2),
  },
  userInfos: {
    fontSize: "12px",
  },
  divider: {
    padding: spacing(1, 0, 1, 0),
    "&& .MuiBox-root": {
      fontSize: 10,
      fontWeight: "bold",
    },
  },
}));

export default Menu;
