import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import CreditCardError from "views/Transactions/CreditCardError";
import { paymentTypes } from "../enums/payment";
import {
  selectCreditCardInfo,
  selectStripePaymentId,
  selectTriggerStripePayment,
} from "../selectors/payment.selector";
import {
  newCreditCard,
  clearStripePayment,
  updateStripePaymentId,
} from "../stores/reducers/payment.creditCard.reducer";
import { sentryCaptureMessage } from "helpers/sentry";

function useStripeToCreatePaymentMethod() {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openNewCreditCardError, setOpenNewCreditCardError] = useState(null);

  const creditCardInfo = useSelector(selectCreditCardInfo);
  const stripePaymentId = useSelector(selectStripePaymentId);
  const triggerStripe = useSelector(selectTriggerStripePayment);

  const triggerPayment = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: creditCardInfo.name,
          address: {
            line1: creditCardInfo.street,
            postal_code: creditCardInfo.zipcode,
            country: creditCardInfo.country.value,
          },
        },
      });
      if (error?.message) {
        setOpenNewCreditCardError(error.message);
        sentryCaptureMessage({
          message: "Something went wrong during create payment method",
          level: "error",
          breadcumbs: {
            category: "fetch",
            level: "warning",
            message: JSON.stringify(error),
          },
        });
      } else {
        if (paymentMethod) {
          dispatch(updateStripePaymentId(paymentMethod.id));
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      enqueueSnackbar(t("ADD_CREDIT.GENERAL_ERROR"), { variant: "error" });
      sentryCaptureMessage({
        message: "Something went wrong during create payment method",
        level: "error",
        breadcumbs: {
          category: "fetch",
          level: "warning",
          message: JSON.stringify(error),
        },
      });
      dispatch(clearStripePayment());
    }
  }, [t, enqueueSnackbar, dispatch, creditCardInfo, elements, stripe]);

  useEffect(() => {
    if (triggerStripe) {
      triggerPayment();
    }
  }, [triggerPayment, triggerStripe, dispatch]);

  useEffect(() => {
    if (stripePaymentId) {
      dispatch(
        newCreditCard({
          type: paymentTypes.STRIPE,
          token: stripePaymentId,
        }),
      );
      dispatch(clearStripePayment());
    }
  }, [stripePaymentId, dispatch]);

  return {
    isStripeLoading: stripe,
    CreditCardError: openNewCreditCardError ? (
      <CreditCardError
        type="credit_card"
        errorText={openNewCreditCardError}
        handleClosure={() => {
          dispatch(clearStripePayment());
          setOpenNewCreditCardError(null);
        }}
        onOpenPaymentMethod={() => undefined}
      />
    ) : null,
  };
}

export default useStripeToCreatePaymentMethod;
