import { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, List, ListItem } from "ui";

import { PhoneNumberFilter } from "components/Core/Filters";
import { selectAllNumbers } from "selectors/numbers.selector";
import HelpTooltip from "../../Core/Tooltip/HelpTooltip";

function FaxNumberTooltip() {
  const { t } = useTranslation();
  const numbersList = useSelector(selectAllNumbers);

  return (
    <HelpTooltip
      title={
        <List>
          {numbersList.map(({ number, id }, index) =>
            index !== 0 ? (
              <ListItem key={id}>
                <PhoneNumberFilter number={number} />
              </ListItem>
            ) : null,
          )}
        </List>
      }
    >
      <Typography component="span">
        {t("PROFILE.FAX_NUMBER_MORE_TOOLTIP", { count: numbersList.length - 1 })}
      </Typography>
    </HelpTooltip>
  );
}

export default memo(FaxNumberTooltip);
