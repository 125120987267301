import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@alohi/kit";

import { clearFetchedGroups } from "stores/reducers/contacts.groups.reducer";
import SingleWhitelistMessage from "components/Contacts/SingleWhitelistMessage";
import { deleteWhitelistContacts } from "stores/reducers/contacts.whitelist.reducer";
import { deleteSharedContacts } from "stores/reducers/contacts.sharedContacts.reducer";
import { clearFetchedSharedGroups } from "stores/reducers/contacts.sharedGroups.reducer";

interface DeleteContactModalProps {
  contactIds: string[];
  isSharedContact: boolean;
  handleClosure: () => void;
}

function DeleteContactModal({
  contactIds,
  handleClosure,
  isSharedContact,
}: DeleteContactModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const renderMessage = () => {
    if (contactIds.length === 1) {
      return <SingleWhitelistMessage isSharedContact={isSharedContact} contactId={contactIds[0]} />;
    }

    return t("CONTACTS.QUESTION_REMOVE_SELECTED_CONTACTS", {
      listName: t("CONTACTS.CONTACTS").toLowerCase(),
    });
  };

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      title={t("CONTACTS.DELETE")}
      onConfirm={() => {
        if (isSharedContact) {
          dispatch(deleteSharedContacts({ idsToDelete: contactIds }));
          dispatch(clearFetchedSharedGroups());
        } else {
          dispatch(deleteWhitelistContacts({ idsToDelete: contactIds }));
          dispatch(clearFetchedGroups());
        }

        handleClosure();
      }}
    >
      <Box p={3}>
        <Typography type="body">{renderMessage()}</Typography>
      </Box>
    </Modal>
  );
}

export default DeleteContactModal;
