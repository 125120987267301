import { useState } from "react";
import { FaButton, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  faAlien,
  faUserLock,
  faUserPlus,
  faUsersMedical,
  faCloudUploadAlt,
  faMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";

import dataCy from "enums/dataCy";
import { urls } from "routes/urls";
import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import AddOrEditGroupModal from "views/Contacts/AddOrEditGroupModal";
import ImportContactsModal from "views/Contacts/ImportContactsModal";
import AddOrEditContactModal from "views/Contacts/AddOrEditContactModal";
import AddOrEditBlacklistContactModal from "views/Contacts/AddOrEditBlacklistContactModal";
import HandleAnonymousSendersModal from "views/Contacts/modals/HandleAnonymousSendersModal";
import {
  selectCanIEditSharedContacts,
  selectCanISearchSharedContacts,
} from "selectors/ability.selector";

interface ContactsActionsProps {
  showFilters: () => void;
}

function ContactsActions({ showFilters }: ContactsActionsProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const canIEditSharedContacts = useAppSelector(selectCanIEditSharedContacts);
  const canISearchSharedContacts = useAppSelector(selectCanISearchSharedContacts);

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [isAddOrEditGroupModalOpen, setIsAddOrEditGroupModalOpen] = useState(false);
  const [isAddSharedContactModalOpen, setIsAddSharedContactModalOpen] = useState(false);
  const [isAddBlacklistContactModalOpen, setIsAddBlacklistContactModalOpen] = useState(false);
  const [isAddOrEditSharedGroupModalOpen, setIsAddOrEditSharedGroupModalOpen] = useState(false);
  const [isHandleAnonymousSendersModalOpen, isHandleAnonymousSendersModalOpenBool] = useBool(false);

  return (
    <>
      {
        {
          [urls.contacts.root]: (
            <Box display={"flex"}>
              <FaButton
                sx={{ mr: 1 }}
                variant="light"
                onClick={showFilters}
                icon={faMagnifyingGlass}
                tooltip={t("COMMON.SEARCH")}
              />
              <FaButton
                sx={{ mr: 1 }}
                variant="light"
                tooltip={t("CONTACTS.IMPORT_EXCEL")}
                icon={faCloudUploadAlt}
                onClick={() => setIsImportModalOpen(true)}
              />
              <FaButton
                variant="light"
                tooltip={t("CONTACTS.ADD")}
                icon={faUserPlus}
                onClick={() => setIsAddContactModalOpen(true)}
              />
            </Box>
          ),
          [urls.contacts.blacklist]: (
            <Box display={"flex"}>
              <FaButton
                variant="light"
                sx={{ mr: 1 }}
                icon={faAlien}
                tooltip={t("CONTACTS.ANONYMOUS_SENDERS")}
                data-cy={dataCy.anonymousButton}
                onClick={isHandleAnonymousSendersModalOpenBool.setTrue}
              />
              <FaButton
                variant="light"
                icon={faUserLock}
                tooltip={t("CONTACTS.ADD_BLACKLIST")}
                data-cy={dataCy.addToBlacklistButton}
                onClick={() => setIsAddBlacklistContactModalOpen(true)}
              />
            </Box>
          ),
          [urls.contacts.groups]: (
            <Box display={"flex"}>
              <FaButton
                sx={{ mr: 1 }}
                variant="light"
                onClick={showFilters}
                icon={faMagnifyingGlass}
                tooltip={t("COMMON.SEARCH")}
              />
              <FaButton
                variant="light"
                tooltip={t("CONTACTS.ADD_NEW_GROUP")}
                icon={faUsersMedical}
                onClick={() => setIsAddOrEditGroupModalOpen(true)}
              />
            </Box>
          ),
          [urls.contacts.recent]: <></>,
          [urls.contacts.sharedContacts]: canISearchSharedContacts ? (
            <Box display={"flex"}>
              <FaButton
                sx={{ mr: 1 }}
                variant="light"
                onClick={showFilters}
                icon={faMagnifyingGlass}
                tooltip={t("COMMON.SEARCH")}
              />
              {canIEditSharedContacts ? (
                <FaButton
                  variant="light"
                  icon={faUserPlus}
                  tooltip={t("CONTACTS.ADD_SHARED")}
                  onClick={() => setIsAddSharedContactModalOpen(true)}
                />
              ) : null}
            </Box>
          ) : null,
          [urls.contacts.sharedGroups]: canISearchSharedContacts ? (
            <Box display={"flex"}>
              <FaButton
                sx={{ mr: 1 }}
                variant="light"
                onClick={showFilters}
                icon={faMagnifyingGlass}
                tooltip={t("COMMON.SEARCH")}
              />
              {canIEditSharedContacts ? (
                <FaButton
                  variant="light"
                  icon={faUsersMedical}
                  tooltip={t("CONTACTS.ADD_NEW_SHARED_GROUP")}
                  onClick={() => setIsAddOrEditSharedGroupModalOpen(true)}
                />
              ) : null}
            </Box>
          ) : null,
        }[pathname]
      }

      {(isAddContactModalOpen || isAddSharedContactModalOpen) && (
        <AddOrEditContactModal
          isSharedContact={isAddSharedContactModalOpen}
          handleClosure={() => {
            setIsAddContactModalOpen(false);
            setIsAddSharedContactModalOpen(false);
          }}
        />
      )}

      {isAddBlacklistContactModalOpen && (
        <AddOrEditBlacklistContactModal
          handleClosure={() => setIsAddBlacklistContactModalOpen(false)}
        />
      )}

      {isImportModalOpen && (
        <ImportContactsModal handleClosure={() => setIsImportModalOpen(false)} />
      )}

      {isAddOrEditGroupModalOpen || isAddOrEditSharedGroupModalOpen ? (
        <AddOrEditGroupModal
          isSharedGroup={isAddOrEditSharedGroupModalOpen}
          handleClosure={() => {
            setIsAddOrEditGroupModalOpen(false);
            setIsAddOrEditSharedGroupModalOpen(false);
          }}
        />
      ) : null}

      {isHandleAnonymousSendersModalOpen ? (
        <HandleAnonymousSendersModal
          handleClosure={isHandleAnonymousSendersModalOpenBool.setFalse}
        />
      ) : null}
    </>
  );
}

export default ContactsActions;
