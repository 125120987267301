import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  faxConfirmationApi,
  phoneVerificationApi,
  redeemApi,
  referrerUserApi,
  verifications,
} from "api";

import RS from "enums/requestStatus";
import { logout } from "./authentication.reducer";
import { currenciesSlice, initialCurrenciesState } from "./verifications.currencies.reducer";
import { subscribeSlice, initialSubscribeSlice } from "./verifications.subscribe.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  checkReferrer: initialRequestState,
  referralLink: initialRequestState,
  calculatePage: initialRequestState,
  geoLocation: initialRequestState,
  requestCallPhoneVerification: initialRequestState,
  requestSmsPhoneVerification: initialRequestState,
  submitPhoneVerificationCode: initialRequestState,
  faxConfirmationByToken: initialRequestState,
  redeemCode: initialRequestState,
  timezones: initialRequestState,
  verifyNumber: initialRequestState,
  verifyEmail: initialRequestState,
  resendEmailCode: initialRequestState,
  verifyCustomNumber: { ...initialRequestState, selected: null },
  verifyBulkNumbers: { ...initialRequestState, amount: 1 },
  ...initialCurrenciesState,
  ...initialSubscribeSlice,
  phoneVerificationBackoffTime: 60,
  assignBundleToNumber: initialRequestState,
};

export const verifyEmail = createAsyncThunk(
  "VERIFICATION/VERIFY_EMAIL",
  async ({ email, code, token }, { rejectWithValue }) => {
    try {
      const response = await verifications.email(email, code, token);

      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const resendEmailCode = createAsyncThunk(
  "VERIFICATION/RESEND_EMAIL_CODE",
  async (_, { rejectWithValue }) => {
    try {
      const response = await verifications.resendEmailCode();

      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const verifyBulkNumbers = createAsyncThunk(
  "VERIFICATION/VERIFY_BULK_NUMBERS",
  async ({ amount, planId, areaCode, numberType, countryCode }, { rejectWithValue }) => {
    try {
      const response = await verifications.verifyBulkNumbers({
        amount,
        planId,
        areaCode,
        numberType,
        countryCode,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const verifyNumber = createAsyncThunk(
  "VERIFICATION/VERIFY_NUMBER",
  async (randomNumberParams, { rejectWithValue }) => {
    try {
      const response = await verifications.verifyNumber(randomNumberParams);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const verifyCustomNumber = createAsyncThunk(
  "VERIFICATION/VERIFY_CUSTOM_NUMBER",
  async (randomNumberParams, { rejectWithValue }) => {
    try {
      const response = await verifications.verifyCustomNumber(randomNumberParams);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const getGeoLocation = createAsyncThunk(
  "VERIFICATION/GET_GEO_LOCATION",
  async (_, { rejectWithValue }) => {
    try {
      const response = await verifications.geoLocation();
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().verifications.geoLocation.response === null;
    },
  },
);

export const requestSmsPhoneVerification = createAsyncThunk(
  "VERIFICATION/REQUEST_SMS_PHONE_VERIFICATION",
  async ({ phoneNumber }, { rejectWithValue }) => {
    try {
      const response = await phoneVerificationApi.requestSmsVerification(phoneNumber);
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const requestCallPhoneVerification = createAsyncThunk(
  "VERIFICATION/REQUEST_CALL_PHONE_VERIFICATION",
  async ({ phoneNumber }, { rejectWithValue }) => {
    try {
      const response = await phoneVerificationApi.requestCallVerification(phoneNumber);
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const checkReferrerByToken = createAsyncThunk(
  "VERIFICATION/CHECK_REFERRER_BY_TOKEN",
  async ({ token }, { rejectWithValue }) => {
    try {
      return await referrerUserApi.checkToken({ token });
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
);

export const getReferralLink = createAsyncThunk(
  "VERIFICATION/GET_REFERRAL_LINK",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await referrerUserApi.getReferralLink();
      return { response, currency };
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
  {
    condition: (currency, { getState }) => {
      return getState().verifications.referralLink.response?.[currency] === undefined;
    },
  },
);

export const submitPhoneVerificationCode = createAsyncThunk(
  "VERIFICATION/SUBMIT_PHONE_VERIFICATION_CODE",
  async ({ code }, { rejectWithValue }) => {
    try {
      const response = await phoneVerificationApi.submitVerificationCode(code);
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const getFaxConfirmation = createAsyncThunk(
  "VERIFICATION/GET_FAX_CONFIRMATION",
  async ({ token }, { rejectWithValue }) => {
    try {
      return await faxConfirmationApi.getDataByToken({ token });
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
);

export const redeemCode = createAsyncThunk(
  "VERIFICATION/REDEEM_CODE",
  async ({ token }, { rejectWithValue }) => {
    try {
      return await redeemApi.applyCode({ token });
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
);

export const calculatePage = createAsyncThunk(
  "VERIFICATION/CALCULATE_PAGE",
  async ({ number }, { rejectWithValue }) => {
    try {
      const response = await verifications.calculatePage({ number });
      return { response, number };
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
  {
    condition: ({ number }, { getState }) => {
      return getState().verifications.calculatePage.response?.[number] === undefined;
    },
  },
);

export const getTimezones = createAsyncThunk(
  "VERIFICATION/GET_TIMEZONES",
  async (_, { rejectWithValue }) => {
    try {
      const response = await verifications.getTimezones();
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().verifications.timezones.response === null;
    },
  },
);

export const assignBundleToNumber = createAsyncThunk(
  "VERIFICATION/ASSIGN_BUNDLE_TO_NUMBER",
  async ({ numberId, bundleId }, { rejectWithValue }) => {
    try {
      const response = await verifications.assignBundleToNumber({ numberId, bundleId });
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const updateBulkNumbersAmount = createAction(
  "VERIFICATION/UPDATE_BULK_NUMBERS_AMOUNT",
  (amount) => {
    return { payload: amount };
  },
);

export const clearVerifyEmail = createAction("VERIFICATION/CLEAR_VERIFY_EMAIL");
export const clearResendEmailCode = createAction("VERIFICATION/CLEAR_RESEND_EMAIL_CODE");
export const clearRedeemCode = createAction("VERIFICATION/CLEAR_REDEEM_CODE");
export const clearVerifyNumber = createAction("VERIFICATION/CLEAR_VERIFY_NUMBER");
export const selectCustomNumber = createAction("VERIFICATION/SELECT_CUSTOM_NUMBER");
export const clearCalculatePage = createAction("VERIFICATION/CLEAR_CALCULATE_PAGE");
export const clearCheckReferrer = createAction("VERIFICATION/CLEAR_CHECK_REFERRER");
export const clearFaxConfirmation = createAction("VERIFICATION/CLEAR_FAX_CONFIRMATION");
export const clearVerifyBulkNumbers = createAction("VERIFICATION/CLEAR_VERIFY_BULK_NUMBERS");
export const clearVerifyCustomNumber = createAction("VERIFICATION/CLEAR_VERIFY_CUSTOM_NUMBER");
export const clearVerifyBulkNumbersResponse = createAction(
  "VERIFICATION/CLEAR_VERIFY_BULK_NUMBERS_RESPONSE",
);
export const clearRequestSmsPhoneVerification = createAction(
  "VERIFICATION/CLEAR_REQUEST_SMS_PHONE_VERIFICATION",
);
export const clearRequestCallPhoneVerification = createAction(
  "VERIFICATION/CLEAR_REQUEST_CALL_PHONE_VERIFICATION",
);
export const clearSubmitPhoneVerificationCode = createAction(
  "VERIFICATION/CLEAR_SUBMIT_PHONE_VERIFICATION_CODE",
);
export const clearAssignBundleToNumber = createAction("VERIFICATION/CLEAR_ASSIGN_BUNDLE_TO_NUMBER");

const { reducer } = createSlice({
  name: "VERIFICATION",
  initialState,
  reducers: {},
  extraReducers: {
    [clearVerifyNumber]: (state) => {
      state.verifyNumber.response = null;
      state.verifyNumber.status = RS.IDLE;
      state.verifyNumber.error = null;
    },
    [clearVerifyCustomNumber]: (state) => {
      state.verifyCustomNumber.response = null;
      state.verifyCustomNumber.status = RS.IDLE;
      state.verifyCustomNumber.error = null;
      state.verifyCustomNumber.selected = null;
    },
    [clearVerifyBulkNumbers]: (state) => {
      state.verifyBulkNumbers.response = null;
      state.verifyBulkNumbers.status = RS.IDLE;
      state.verifyBulkNumbers.error = null;
      state.verifyBulkNumbers.amount = null;
    },
    [clearVerifyBulkNumbersResponse]: (state) => {
      state.verifyBulkNumbers.response = null;
      state.verifyBulkNumbers.status = RS.IDLE;
      state.verifyBulkNumbers.error = null;
    },
    [updateBulkNumbersAmount]: (state, { payload }) => {
      state.verifyBulkNumbers.amount = payload;
    },
    [verifyBulkNumbers.pending]: (state) => {
      state.verifyBulkNumbers.response = null;
      state.verifyBulkNumbers.status = RS.RUNNING;
      state.verifyBulkNumbers.error = null;
    },
    [verifyBulkNumbers.fulfilled]: (state, action) => {
      state.verifyBulkNumbers.response = action.payload;
      state.verifyBulkNumbers.status = action.payload ? RS.IDLE : RS.NO_CONTENT;
      state.verifyBulkNumbers.error = null;
    },
    [verifyBulkNumbers.rejected]: (state, action) => {
      state.verifyBulkNumbers.response = null;
      state.verifyBulkNumbers.status = RS.ERROR;
      state.verifyBulkNumbers.error = action.payload;
    },
    [verifyNumber.pending]: (state) => {
      state.verifyNumber.response = null;
      state.verifyNumber.status = RS.RUNNING;
      state.verifyNumber.error = null;
    },
    [verifyNumber.fulfilled]: (state, action) => {
      state.verifyNumber.response = action.payload;
      state.verifyNumber.status = action.payload ? RS.IDLE : RS.NO_CONTENT;
      state.verifyNumber.error = null;
    },
    [verifyNumber.rejected]: (state, action) => {
      state.verifyNumber.response = null;
      state.verifyNumber.status = RS.ERROR;
      state.verifyNumber.error = action.payload;
    },
    [verifyEmail.pending]: (state) => {
      state.verifyEmail.response = null;
      state.verifyEmail.status = RS.RUNNING;
      state.verifyEmail.error = null;
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.verifyEmail.response = action.payload;
      state.verifyEmail.status = RS.IDLE;
      state.verifyEmail.error = null;
    },
    [verifyEmail.rejected]: (state, action) => {
      state.verifyEmail.response = null;
      state.verifyEmail.status = RS.ERROR;
      state.verifyEmail.error = action.payload;
    },
    [clearVerifyEmail]: (state) => {
      state.verifyEmail.response = null;
      state.verifyEmail.status = RS.IDLE;
      state.verifyEmail.error = null;
    },
    [resendEmailCode.pending]: (state) => {
      state.resendEmailCode.response = null;
      state.resendEmailCode.status = RS.RUNNING;
      state.resendEmailCode.error = null;
    },
    [resendEmailCode.fulfilled]: (state, action) => {
      state.resendEmailCode.response = action.payload;
      state.resendEmailCode.status = RS.IDLE;
      state.resendEmailCode.error = null;
    },
    [resendEmailCode.rejected]: (state, action) => {
      state.resendEmailCode.response = null;
      state.resendEmailCode.status = RS.ERROR;
      state.resendEmailCode.error = action.payload;
    },
    [clearResendEmailCode]: (state) => {
      state.resendEmailCode.response = null;
      state.resendEmailCode.status = RS.IDLE;
      state.resendEmailCode.error = null;
    },
    [selectCustomNumber]: (state, action) => {
      state.verifyCustomNumber.selected = action.payload;
    },
    [verifyCustomNumber.pending]: (state) => {
      state.verifyCustomNumber.response = null;
      state.verifyCustomNumber.status = RS.RUNNING;
      state.verifyCustomNumber.error = null;
    },
    [verifyCustomNumber.fulfilled]: (state, action) => {
      state.verifyCustomNumber.response = action.payload;
      state.verifyCustomNumber.status = action.payload ? RS.IDLE : RS.NO_CONTENT;
      state.verifyCustomNumber.error = null;
    },
    [verifyCustomNumber.rejected]: (state, action) => {
      state.verifyCustomNumber.response = null;
      state.verifyCustomNumber.status = RS.ERROR;
      state.verifyCustomNumber.error = action.payload;
    },
    [clearCalculatePage]: (state) => {
      state.calculatePage.status = RS.IDLE;
      state.calculatePage.error = null;
    },
    [calculatePage.pending]: (state) => {
      state.calculatePage.status = RS.RUNNING;
      state.calculatePage.error = null;
    },
    [calculatePage.fulfilled]: (state, { payload }) => {
      if (state.calculatePage.response === null) {
        state.calculatePage.response = {};
      }
      state.calculatePage.response[payload.number] = payload.response;
      state.calculatePage.status = RS.IDLE;
      state.calculatePage.error = null;
    },
    [calculatePage.rejected]: (state, action) => {
      state.calculatePage.status = RS.ERROR;
      state.calculatePage.error = action.payload;
    },
    [getGeoLocation.pending]: (state) => {
      state.geoLocation.response = null;
      state.geoLocation.status = RS.RUNNING;
      state.geoLocation.error = null;
    },
    [getGeoLocation.fulfilled]: (state, action) => {
      state.geoLocation.response = action.payload;
      state.geoLocation.status = RS.IDLE;
      state.geoLocation.error = null;
    },
    [getGeoLocation.rejected]: (state, action) => {
      state.geoLocation.response = null;
      state.geoLocation.status = RS.ERROR;
      state.geoLocation.error = action.payload;
    },
    [clearCheckReferrer]: (state) => {
      state.checkReferrer.response = null;
      state.checkReferrer.status = RS.IDLE;
      state.checkReferrer.error = null;
    },
    [checkReferrerByToken.pending]: (state) => {
      state.checkReferrer.response = null;
      state.checkReferrer.status = RS.RUNNING;
      state.checkReferrer.error = null;
    },
    [checkReferrerByToken.fulfilled]: (state, action) => {
      state.checkReferrer.response = action.payload;
      state.checkReferrer.status = RS.IDLE;
      state.checkReferrer.error = null;
    },
    [checkReferrerByToken.rejected]: (state, action) => {
      state.checkReferrer.response = null;
      state.checkReferrer.status = RS.ERROR;
      state.checkReferrer.error = action.payload;
    },
    [getReferralLink.pending]: (state) => {
      state.referralLink.status = RS.RUNNING;
      state.referralLink.error = null;
    },
    [getReferralLink.fulfilled]: (state, { payload }) => {
      if (!state.referralLink.response) {
        state.referralLink.response = {};
      }
      state.referralLink.response[payload.currency] = payload.response;
      state.referralLink.status = RS.IDLE;
      state.referralLink.error = null;
    },
    [getReferralLink.rejected]: (state, action) => {
      state.referralLink.status = RS.ERROR;
      state.referralLink.error = action.payload;
    },
    [requestSmsPhoneVerification.pending]: (state) => {
      state.requestSmsPhoneVerification.status = RS.RUNNING;
      state.requestSmsPhoneVerification.error = null;
    },
    [requestSmsPhoneVerification.fulfilled]: (state, { payload }) => {
      state.requestSmsPhoneVerification.status = RS.IDLE;
      state.requestSmsPhoneVerification.response = payload.response;
      if (payload.response?.reason === "quota_time_limit_reached") {
        let duration = 0;
        duration += payload.response.next_expected_try.second;
        duration += payload.response.next_expected_try.minute * 60;
        duration += payload.response.next_expected_try.hour * 3600;
        duration += payload.response.next_expected_try.day * 86400;
        state.phoneVerificationBackoffTime = Math.ceil(duration);
      } else {
        state.phoneVerificationBackoffTime = 60; // Default delay before call verification
      }
    },
    [requestSmsPhoneVerification.rejected]: (state, { payload }) => {
      state.requestSmsPhoneVerification.status = RS.ERROR;
      state.requestSmsPhoneVerification.error = payload;
    },
    [clearRequestSmsPhoneVerification]: (state) => {
      state.requestSmsPhoneVerification = initialRequestState;
    },
    [requestCallPhoneVerification.pending]: (state) => {
      state.requestCallPhoneVerification.status = RS.RUNNING;
      state.requestCallPhoneVerification.error = null;
    },
    [requestCallPhoneVerification.fulfilled]: (state, { payload }) => {
      state.requestCallPhoneVerification.status = RS.IDLE;
      state.requestCallPhoneVerification.response = payload.response;
    },
    [requestCallPhoneVerification.rejected]: (state, { payload }) => {
      state.requestCallPhoneVerification.status = RS.ERROR;
      state.requestCallPhoneVerification.error = payload;
    },
    [clearRequestCallPhoneVerification]: (state) => {
      state.requestCallPhoneVerification = initialRequestState;
    },
    [submitPhoneVerificationCode.pending]: (state) => {
      state.submitPhoneVerificationCode.status = RS.RUNNING;
      state.submitPhoneVerificationCode.error = null;
    },
    [submitPhoneVerificationCode.fulfilled]: (state, { payload }) => {
      state.submitPhoneVerificationCode.status = RS.IDLE;
      state.submitPhoneVerificationCode.response = payload.response;
    },
    [submitPhoneVerificationCode.rejected]: (state, { payload }) => {
      state.submitPhoneVerificationCode.status = RS.ERROR;
      state.submitPhoneVerificationCode.error = payload;
    },
    [clearSubmitPhoneVerificationCode]: (state) => {
      state.submitPhoneVerificationCode = initialRequestState;
    },
    [clearFaxConfirmation]: (state) => {
      state.faxConfirmationByToken.response = null;
      state.faxConfirmationByToken.status = RS.IDLE;
      state.faxConfirmationByToken.error = null;
    },
    [getFaxConfirmation.pending]: (state) => {
      state.faxConfirmationByToken.response = null;
      state.faxConfirmationByToken.status = RS.RUNNING;
      state.faxConfirmationByToken.error = null;
    },
    [getFaxConfirmation.fulfilled]: (state, action) => {
      state.faxConfirmationByToken.response = action.payload;
      state.faxConfirmationByToken.status = RS.IDLE;
      state.faxConfirmationByToken.error = null;
    },
    [getFaxConfirmation.rejected]: (state, action) => {
      state.faxConfirmationByToken.response = null;
      state.faxConfirmationByToken.status = RS.ERROR;
      state.faxConfirmationByToken.error = action.payload;
    },
    [clearRedeemCode]: (state) => {
      state.redeemCode.response = null;
      state.redeemCode.status = RS.IDLE;
      state.redeemCode.error = null;
    },
    [redeemCode.pending]: (state) => {
      state.redeemCode.response = null;
      state.redeemCode.status = RS.RUNNING;
      state.redeemCode.error = null;
    },
    [redeemCode.fulfilled]: (state, action) => {
      state.redeemCode.response = action.payload;
      state.redeemCode.status = RS.IDLE;
      state.redeemCode.error = null;
    },
    [redeemCode.rejected]: (state, action) => {
      state.redeemCode.response = null;
      state.redeemCode.status = RS.ERROR;
      state.redeemCode.error = action.payload;
    },
    [getTimezones.pending]: (state) => {
      state.timezones.response = null;
      state.timezones.status = RS.RUNNING;
      state.timezones.error = null;
    },
    [getTimezones.fulfilled]: (state, action) => {
      state.timezones.response = action.payload;
      state.timezones.status = RS.IDLE;
      state.timezones.error = null;
    },
    [getTimezones.rejected]: (state, action) => {
      state.timezones.response = null;
      state.timezones.status = RS.ERROR;
      state.timezones.error = action.payload;
    },
    [assignBundleToNumber.pending]: (state) => {
      state.assignBundleToNumber.response = null;
      state.assignBundleToNumber.status = RS.RUNNING;
      state.assignBundleToNumber.error = null;
    },
    [assignBundleToNumber.fulfilled]: (state, action) => {
      state.assignBundleToNumber.response = action.payload;
      state.assignBundleToNumber.status = RS.IDLE;
      state.assignBundleToNumber.error = null;
    },
    [assignBundleToNumber.rejected]: (state, action) => {
      state.assignBundleToNumber.response = null;
      state.assignBundleToNumber.status = RS.ERROR;
      state.assignBundleToNumber.error = action.payload;
    },
    [clearAssignBundleToNumber]: (state) => {
      state.assignBundleToNumber = initialRequestState;
    },
    ...currenciesSlice,
    ...subscribeSlice,
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
