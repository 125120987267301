import { Primitive } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import { stripQueryParams } from "routes/urls";

export const sentryCaptureMessage = (params: {
  message: string;
  level?: Sentry.SeverityLevel;
  tags?: Record<string, Primitive>;
  breadcumbs?: Record<string, unknown>;
}) => {
  Sentry.withScope((scope) => {
    scope.setLevel(params.level ?? "log");
    if (params.breadcumbs) {
      scope.addBreadcrumb(params.breadcumbs);
    }
    if (params.tags) {
      scope.setTags(params.tags);
    }
    Sentry.captureMessage(params.message);
  });
};

export const sentryHandleFetchError = (
  url: string,
  configs: Record<string, unknown> & { method: string },
  customOptions: Record<string, unknown>,
  error: Error & {
    reason: string;
    cfRay?: string | null;
    xRequestId?: string | null;
    status: string;
    headers: Record<string, unknown>;
  },
) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(url);

    scope.setFingerprint([stripQueryParams(url), error.message, error.status]);
    scope.setLevel("error");

    // Tags for filtering in Sentry
    scope.setTags({
      "request.debug.cf_ray_id": error.cfRay ?? "-",
      "request.debug.x_request_id": error.xRequestId ?? "-",
      "request.info.method": configs.method,
      "request.info.status": error.status,
      "request.info.endpoint": url,
    });

    // Extra data for debugging
    scope.setExtras({
      configs: configs,
      customOptions: customOptions,
      headers: JSON.stringify(error.headers, null, 2),
    });

    // Ensure the error has a message and stack trace
    const message = error.message || error.reason || "Unknown error";

    Sentry.captureException(new Error(`${error.status} - ${message}`));
  });
};
