import { Box, Button, makeStyles } from "@alohi/kit";
import clsx from "clsx";
import { MouseEvent, ReactNode } from "react";

interface SecondaryButtonProps {
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

function SecondaryButton({ className, children, onClick }: SecondaryButtonProps) {
  const classes = useStyles();

  return (
    <Button variant="white" className={clsx(classes.base, className)} onClick={onClick}>
      <Box className={classes.text}>{children}</Box>
    </Button>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    [breakpoints.down("sm")]: {
      order: 1,
      minWidth: "100px !important",
      minHeight: "48px !important",
      padding: spacing(1, 1, 1, 1) + "!important",
    },
  },
  text: {
    whiteSpace: "normal",
  },
}));

export default SecondaryButton;
