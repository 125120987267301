import { Button, Panel } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import CoverSheetEnforcementModal from "views/SendFax/modals/CoverSheetEnforcementModal";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import { selectCanIUseCoverSheetEnforcement } from "selectors/ability.selector";

interface BackPanelRowProps {
  enforcedCoverSheet: null | { id: string; name: string };
  setNewEnforcedCoverSheet: (enforcedCoverSheet: null | { id: string; name: string }) => void;
}

function BackPanelRow({ enforcedCoverSheet, setNewEnforcedCoverSheet }: BackPanelRowProps) {
  const { t } = useTranslation();

  const [isManageOpen, isManageOpenBool] = useBool(false);
  const [isUpgradeRequiredModalOpen, isUpgradeRequiredModalOpenBool] = useBool(false);

  const canIUseCoverSheetEnforcement = useAppSelector(selectCanIUseCoverSheetEnforcement);

  const handleOpenManageModal = () => {
    if (canIUseCoverSheetEnforcement) {
      isManageOpenBool.setTrue();
    } else {
      isUpgradeRequiredModalOpenBool.setTrue();
    }
  };

  const handleSelection = (enforcedCoverSheet: null | { id: string; name: string }) => {
    isManageOpenBool.setFalse();

    setNewEnforcedCoverSheet(enforcedCoverSheet);
  };

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">{t("COVER_SHEET.ENFORCEMENT")}</Panel.Cell>
        <Panel.Cell variant="data">{enforcedCoverSheet?.name || t("COMMON.INACTIVE")}</Panel.Cell>
        <Panel.Cell variant="actions">
          {enforcedCoverSheet?.name ? (
            <Button sx={{ mr: 2 }} variant="white" onClick={() => setNewEnforcedCoverSheet(null)}>
              {t("COMMON.DISABLE")}
            </Button>
          ) : null}

          <Button variant="white" onClick={handleOpenManageModal}>
            {enforcedCoverSheet?.name ? t("COMMON.CHANGE") : t("COMMON.ACTIVATE")}
          </Button>
        </Panel.Cell>
      </Panel.Row>

      {isManageOpen ? (
        <CoverSheetEnforcementModal
          enforcedCoverSheet={enforcedCoverSheet}
          handleClosure={isManageOpenBool.setFalse}
          setNewEnforcedCoverSheet={handleSelection}
        />
      ) : null}

      {isUpgradeRequiredModalOpen ? (
        <RequireUpgradeToEnterprise
          title={t("COVER_SHEET.ENFORCEMENT")}
          featureName={t("COVER_SHEET.ENFORCEMENT")}
          handleClosure={isUpgradeRequiredModalOpenBool.setFalse}
        />
      ) : null}
    </>
  );
}

export default BackPanelRow;
