import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Box, Modal, useSnackbar } from "@alohi/kit";

import useBool from "hooks/useBool";
import { Helper, Typography } from "ui";
import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import { slackTargetChannelString } from "helpers/common";
import { selectIsPlanCorporate } from "selectors/plan.selector";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import FormRowSelectInput from "components/Forms/FormRowSelectInput";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import FormRowReactSelectInput from "components/Forms/FormRowReactSelectInput";
import { getNotifications } from "stores/reducers/account.notifications.reducer";
import SlackNotificationsRequiresActivation from "components/Profile/SlackNotificationsRequiresActivationModal";
import {
  selectSlackChannels,
  selectIsSlackActive,
  selectIsUpdateSlackSettingsError,
  selectIsUpdateSlackSettingsSuccess,
} from "selectors/integrations.selector";
import {
  selectSlackNotificationsOptions,
  selectSlackNotificationsAvailableOptions,
  selectSlackNotificationsTargetChannel,
} from "selectors/account.notifications.selector";
import {
  updateSlackSettings,
  clearUpdateSlackSettings,
} from "stores/reducers/integrations.slack.reducer";
import RequireUpgradeToCorporate from "../RequireUpgrade/RequireUpgradeToCorporate";

function SlackNotificationsModal({ handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isSlackActive = useSelector(selectIsSlackActive);
  const slackChannels = useSelector(selectSlackChannels);
  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const isError = useSelector(selectIsUpdateSlackSettingsError);
  const isSuccess = useSelector(selectIsUpdateSlackSettingsSuccess);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const targetChannel = useSelector(selectSlackNotificationsTargetChannel);
  const slackNotificationsOptions = useSelector(selectSlackNotificationsOptions);
  const slackNotificationsAvailableOptions = useSelector(selectSlackNotificationsAvailableOptions);

  const [channelId, channelIdInput] = useInput("");
  const [isUsingChannelId, isUsingChannelIdBool] = useBool(false);
  const [targetChannelInput, setTargetChannelInput] = useState(null);
  const [receivedFaxNotificationsInputValue, setReceivedFaxNotificationsInputValue] = useState(
    slackNotificationsOptions.receiveFax.id,
  );
  const [sentFaxNotificationsInputValue, setSentFaxNotificationsInputValue] = useState(
    slackNotificationsOptions.sendFax.id,
  );

  useEffect(() => {
    if (targetChannel?.type === "channel" || targetChannel?.type === "direct") {
      setTargetChannelInput({
        value: targetChannel.id,
        label: slackTargetChannelString(targetChannel.type, targetChannel.name),
      });
    }
  }, [targetChannel]);

  const onConfirm = () => {
    dispatch(
      updateSlackSettings({
        sentFaxNotifications: sentFaxNotificationsInputValue,
        receivedFaxNotifications: receivedFaxNotificationsInputValue,
        targetChannel: channelIdInput.ref.current || targetChannelInput?.value || null,
      }),
    );
  };

  useEffect(() => {
    if (isSuccess) {
      handleClosure();
    } else if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearUpdateSlackSettings());
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isError, isSuccess, t]);

  useEffect(() => {
    return () => {
      dispatch(getNotifications());
      dispatch(clearUpdateSlackSettings());
    };
  }, [dispatch]);

  // If selectIsSlackActive is true, the integration can still be broken,
  // In that case we prompt to perform activation
  const requiresActivation = !isSlackActive || !slackChannels;
  const requiresUpgrade = !isPlanCorporate && !isCorporateMember;

  if (requiresUpgrade) {
    return (
      <RequireUpgradeToCorporate
        title={t("SLACK.ACTIVATION")}
        handleClosure={handleClosure}
        featureName={t("SLACK.INTEGRATION")}
      />
    );
  } else if (requiresActivation) {
    return <SlackNotificationsRequiresActivation handleClosure={handleClosure} />;
  }

  return (
    <Modal
      onConfirm={onConfirm}
      onCancel={handleClosure}
      confirmTitle={t("FORMS.CONFIRM")}
      title={t("SLACK.ACTIVATION")}
    >
      <Box component="form" p={3} pr={"15%"}>
        {isUsingChannelId ? (
          <FormRowTextInput
            type="text"
            required={false}
            name="channel-id"
            value={channelId}
            labelGridWidth={5}
            onBlur={channelIdInput.onBlur}
            onFocus={channelIdInput.onFocus}
            error={channelIdInput.showsError}
            onChange={channelIdInput.onChange}
            label={t("SLACK.CHANNEL_ID")}
            endAdornment={
              <Box
                ml={1}
                component="a"
                target="_blank"
                rel="noreferrer"
                href={helpCenterUrls.slackChannelId}
              >
                <Helper variant="dark" size="lg" />
              </Box>
            }
            helperText={
              <>
                <Typography variant="caption" component="span">
                  {t("COMMON.OR")}
                </Typography>
                <Typography
                  variant="anchor"
                  component="span"
                  onClick={isUsingChannelIdBool.setFalse}
                  sx={{ "&&": { ml: 0.5, userSelect: "none" } }}
                >
                  {t("SLACK.SELECT_FROM_LIST")}
                </Typography>
              </>
            }
          />
        ) : (
          <FormRowReactSelectInput
            labelGridWidth={5}
            id="target-channel-input"
            label={t("SLACK.CHANNEL")}
            ReactSelectProps={{
              maxMenuHeight: 150,
              isSearchable: true,
              value: targetChannelInput,
              onChange: (target) => setTargetChannelInput(target),
              placeholder: t("SLACK.SELECT_CHANNEL"),
              options: slackChannels?.map(({ name, id, type }) => ({
                value: id,
                label: (type === "channel" ? "#" : "@") + name,
              })),
            }}
            helperText={
              <>
                <Typography variant="caption" component="span">
                  {t("SLACK.CANT_FIND_IT")}
                </Typography>
                <Typography
                  variant="anchor"
                  component="span"
                  onClick={isUsingChannelIdBool.setTrue}
                  sx={{ "&&": { ml: 0.5, userSelect: "none" } }}
                >
                  {t("SLACK.ENTER_MANUALLY")}
                </Typography>
              </>
            }
          />
        )}

        <FormRowSelectInput
          labelGridWidth={5}
          id="received-fax-notifications-input"
          label={t("SLACK.RECEIVE")}
          value={receivedFaxNotificationsInputValue}
          placeholder={t("SLACK.SELECT_RECEIVE_OPTS")}
          onChange={({ target }) => setReceivedFaxNotificationsInputValue(target.value)}
        >
          {slackNotificationsAvailableOptions?.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </FormRowSelectInput>

        <FormRowSelectInput
          labelGridWidth={5}
          id="sent-fax-notifications-input"
          label={t("SLACK.SEND")}
          value={sentFaxNotificationsInputValue}
          placeholder={t("SLACK.SELECT_SEND_OPTS")}
          onChange={({ target }) => setSentFaxNotificationsInputValue(target.value)}
        >
          {slackNotificationsAvailableOptions?.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </FormRowSelectInput>
      </Box>
    </Modal>
  );
}

SlackNotificationsModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default SlackNotificationsModal;
