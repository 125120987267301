import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { depositApi } from "api";
import { getFilenameFromHeader, triggerDownload } from "helpers/file";
import RS, { FetchError } from "enums/requestStatus";
import { RootState } from "../store";

const initialRequestState = {
  status: RS.IDLE,
  response: null as null | Record<string, string>,
  error: null,
};

const initialPaymentReceiptState = {
  paymentReceipt: {
    ...initialRequestState,
  },
};

type PaymentReceiptInput = {
  response: {
    filename: string;
    blobUrl: string;
  };
};

export const clearPaymentReceipt = createAction("PAYMENT/CLEAR_PAYMENT_RECEIPT");

const getPaymentReceipt = createAsyncThunk<
  PaymentReceiptInput,
  string,
  { rejectValue: FetchError }
>("PAYMENT/GET_PAYMENT_RECEIPT", async (receiptId, { rejectWithValue }) => {
  try {
    const response = await depositApi.getById(receiptId);
    if (response?.receipt) {
      const { blob, contentDispositionHeader } = await depositApi.getReceiptById({
        id: response.receipt,
      });

      return {
        response: {
          blobUrl: URL.createObjectURL(blob),
          filename: contentDispositionHeader
            ? getFilenameFromHeader(contentDispositionHeader)
            : "receipt",
        },
      };
    }
    throw new Error("Bad response");
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const paymentReceiptSlice: Record<string, unknown> = {
  [clearPaymentReceipt as unknown as string]: (state: RootState["payment"]) => {
    state.paymentReceipt.response = null;
    state.paymentReceipt.status = RS.IDLE;
    state.paymentReceipt.error = null;
  },
  [getPaymentReceipt.pending as unknown as string]: (state: RootState["payment"]) => {
    state.paymentReceipt.response = null;
    state.paymentReceipt.status = RS.RUNNING;
    state.paymentReceipt.error = null;
  },
  [getPaymentReceipt.fulfilled as unknown as string]: (
    state: RootState["payment"],
    action: {
      payload: PaymentReceiptInput;
    },
  ) => {
    if (!action.payload) {
      return;
    }
    const { blobUrl, filename } = action.payload.response;
    triggerDownload(blobUrl, filename);

    state.paymentReceipt.status = RS.IDLE;
    state.paymentReceipt.error = null;
  },
  [getPaymentReceipt.rejected as unknown as string]: (
    state: RootState["payment"],
    action: Record<string, null>,
  ) => {
    state.paymentReceipt.response = null;
    state.paymentReceipt.status = RS.ERROR;
    state.paymentReceipt.error = action.payload;
  },
};

export { initialPaymentReceiptState, paymentReceiptSlice, getPaymentReceipt };
