import { API_AFFOGATO } from "config";
import dayjs from "../helpers/date";
import { fetchWithRefresh } from "../helpers";

const get = async ({
  number = "all",
  memberUid = "",
  type = "fax",
  direction = "outgoing",
  spamOnly = false,
  trashOnly = false,
  ascending = false,
  limit = 20,
  offset = 0,
  searchPhrase = "",
  sortBy = "",
  after = "",
  before = "",
  status = "",
  url = "",
  beforeFilter = "",
  afterFilter = "",
  excludeStatus = "",
} = {}) => {
  const requestOptions = {
    method: "GET",
  };
  let path;
  if (url) {
    path = `${API_AFFOGATO}/${url}`;
  } else {
    path = `${API_AFFOGATO}/history/${number || "all"}`;
  }
  const qs = [];
  if (type) {
    qs.push(`type=${type}`);
  }
  if (direction) {
    qs.push(`direction=${direction}`);
  }
  if (ascending) {
    qs.push(`ascending=${ascending}`);
  }
  if (memberUid && memberUid !== "null") {
    qs.push(`member_id=${memberUid}`);
  }
  if (after || afterFilter) {
    qs.push(`after=${after || afterFilter}`);
  }
  if (before || beforeFilter) {
    qs.push(`before=${before || beforeFilter}`);
  }
  if (searchPhrase) {
    qs.push(`search_phrase=${searchPhrase}`);
  }
  if (sortBy) {
    qs.push(`sort_by=${sortBy}`);
  }
  if (offset) {
    qs.push(`offset=${offset}`);
  }
  if (limit) {
    qs.push(`limit=${limit}`);
  }
  if (spamOnly) {
    qs.push(`spam_only=${spamOnly}`);
  }
  if (trashOnly) {
    qs.push(`trash_only=${trashOnly}`);
  }
  if (status) {
    qs.push(`status=${status}`);
  }
  if (excludeStatus) {
    qs.push(`exclude_status=${excludeStatus}`);
  }
  if (qs.length) {
    path += "?" + qs.join("&");
  }

  const response = await fetchWithRefresh(path, requestOptions);
  return {
    records: response._embedded?.records ?? response.records ?? [],
    has_more: !!response.has_more,
  };
};

const getByIds = ({ number = "all", cdrIds, memberUid = null }) => {
  const body = {
    ids: cdrIds,
  };
  if (memberUid) {
    body.member_id = memberUid;
  }
  const requestOptions = {
    method: "POST",
    body,
  };
  const path = `${API_AFFOGATO}/history/${number}/bulk`;

  return fetchWithRefresh(path, requestOptions);
};

const exportToCsv = ({
  number = "all",
  memberUid = "",
  type = "fax",
  direction = "outgoing",
  spamOnly = false,
  trashOnly = false,
  ascending = false,
  searchPhrase = "",
  sortBy = "",
  after = "",
  before = "",
  status = "",
  excludeStatus = "",
}) => {
  let path = `${API_AFFOGATO}/history/${
    number || "all"
  }?type=${type}&direction=${direction}&ascending=${ascending}`;

  const requestOptions = {
    method: "GET",
  };

  const qs = [];

  if (memberUid) {
    qs.push(`member_id=${memberUid}`);
  }
  if (after) {
    qs.push(`after=${after}`);
  }
  if (before) {
    qs.push(`before=${before}`);
  }
  if (searchPhrase) {
    qs.push(`search_phrase=${searchPhrase}`);
  }
  if (sortBy) {
    qs.push(`sort_by=${sortBy}`);
  }
  if (spamOnly) {
    qs.push(`spam_only=${spamOnly}`);
  }
  if (trashOnly) {
    qs.push(`trash_only=${trashOnly}`);
  }
  if (status) {
    qs.push(`status=${status}`);
  }
  if (excludeStatus) {
    qs.push(`exclude_status=${excludeStatus}`);
  }
  qs.push(`download_as=csv`);
  qs.push(`tzoffset=${dayjs().format("ZZ")}`);
  if (qs.length) {
    path += "&" + qs.join("&");
  }
  return fetchWithRefresh(path, requestOptions);
};

export const historyApi = {
  get,
  getByIds,
  exportToCsv,
};
