import { API_AFFOGATO, API_STORAGE } from "config";
import { fetchWithRefresh } from "helpers";

const uploadProfileImage = (file) => {
  const data = new FormData();
  data.append("file", file);
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_STORAGE}/storage?reason=profile_image`;
  return fetchWithRefresh(path, requestOptions);
};

const uploadFaxDocument = (file, fileName) => {
  const data = new FormData();
  data.append("file", file, fileName);

  const requestOptions = {
    method: "POST",
    body: data,
  };

  const path = `${API_STORAGE}/storage?reason=send_fax`;
  return fetchWithRefresh(path, requestOptions);
};

const uploadApiLogo = (file) => {
  const data = new FormData();
  data.append("file", file);
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_STORAGE}/storage/support?reason=apilogo`;
  return fetchWithRefresh(path, requestOptions);
};

const getCdrToken = ({ cdrId }) => {
  const requestOptions = {
    method: "GET",
  };

  const path = `${API_STORAGE}/storage/cdr_token?cdr_id=${cdrId}`;

  return fetchWithRefresh(path, requestOptions);
};

const getEnvelopeMetadata = ({ type, url, token }) => {
  const requestOptions = {
    method: "GET",
  };

  let path =
    type === "verify"
      ? `${API_STORAGE}/storage/envelope/get_envelope_metadata?token=${token}`
      : url;

  return fetchWithRefresh(path, requestOptions);
};

const download = ({
  idType,
  file_type = "",
  cdrId,
  uid = "",
  download_name = "",
  dryrun = false,
}) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: file_type === "pdf" ? `application/${file_type}` : "image/tiff",
    },
  };
  let path = `${API_STORAGE}/storage/${idType}/${cdrId}`;
  const qs = [];
  if (uid && uid !== "null") {
    qs.push(`uid=${uid}`);
  }
  if (download_name) {
    qs.push(`download_name=${download_name}`);
  }
  if (dryrun) {
    qs.push(`dryrun=${dryrun}`);
  }
  if (qs.length) {
    path += "?" + qs.join("&");
  }
  return fetchWithRefresh(path, requestOptions);
};

const getThumbnailsByNameList = ({ ids, operation }, { uid }) => {
  let path = `${API_STORAGE}/storage`;

  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({
      op: operation,
      category: "cdr",
      ids,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=utf-8",
    },
  };
  const qs = [];
  if (uid) {
    qs.push(`uid=${uid}`);
  }
  if (qs.length) {
    path += "?" + qs.join("&");
  }
  return fetchWithRefresh(path, requestOptions);
};

const uploadContactsDocument = (file) => {
  const data = new FormData();
  data.append("file", file);

  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_AFFOGATO}/accounts/contacts/upload_excel`;
  return fetchWithRefresh(path, requestOptions);
};

export const storageApi = {
  download,
  getCdrToken,
  uploadApiLogo,
  uploadFaxDocument,
  uploadProfileImage,
  getEnvelopeMetadata,
  uploadContactsDocument,
  getThumbnailsByNameList,
};
