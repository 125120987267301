import { Box, makeStyles, Modal, SvgBox, theme, useMediaQuery, useOnMount } from "@alohi/kit";
import SignTrial from "assets/svg/sign_trial.svg";
import clsx from "clsx";
import { useMemo } from "react";
import ContentFreeEligible from "./components/ContentFreeEligible";
import ContentFreeTrialInProgress from "./components/ContentFreeTrialInProgress";
import ContentPaidTrialExpired from "./components/ContentPaidTrialExpired";
import ContentPaidTrialInProgress from "./components/ContentPaidTrialInProgress";
import Header from "./components/Header";
import { useAppSelector } from "stores/store";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import ContentPaidEligible from "./components/ContentPaidEligible";
import useTrialIncentive from "../hooks/useTrialIncentive";
import { CustomerMarketingDataFaxSignCrossSellBannerStatus } from "api/gql/generated/graphql";
import {
  GoogleAnalyticsCustomEvents,
  IncentiveSignPlusUpgradeAction,
  useGoogleTagManager,
} from "hooks/useGoogleTagManager";
import ContentPaidEligibleCorpMember from "./components/ContentPaidEligibleCorpMember";
import ContentPaidTrialInProgressCorpMember from "./components/ContentPaidTrialInProgressCorpMember";
import ContentPaidTrialExpiredCorpMember from "./components/ContentPaidTrialExpiredCorpMember";

interface IncentiveModalProps {
  className?: string;
  onClose: () => void;
}

function IncentiveModal({ className, onClose }: IncentiveModalProps) {
  const classes = useStyles();

  const isCorporateMember = useAppSelector(selectAccountIsCorporateMember);
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const { hasPromo, hasFaxPlan, promoStatus } = useTrialIncentive();
  const { sendCustomEvent } = useGoogleTagManager();

  const statusContent = useMemo(() => {
    if (!hasPromo) {
      return null;
    }

    if (isCorporateMember) {
      if (hasFaxPlan) {
        switch (promoStatus) {
          case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable:
            return <ContentPaidEligibleCorpMember />;
          case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialInProgress:
            return <ContentPaidTrialInProgressCorpMember />;
          case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialExpired:
            return <ContentPaidTrialExpiredCorpMember />;
        }
      }
    }

    if (hasFaxPlan) {
      switch (promoStatus) {
        case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable:
          return <ContentPaidEligible />;
        case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialInProgress:
          return <ContentPaidTrialInProgress onClose={onClose} />;
        case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialExpired:
          return <ContentPaidTrialExpired />;
      }
    } else {
      switch (promoStatus) {
        case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable:
          return <ContentFreeEligible />;
        case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialInProgress:
          return <ContentFreeTrialInProgress onClose={onClose} />;
        case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialExpired:
          return null;
      }
    }
  }, [hasFaxPlan, hasPromo, isCorporateMember, onClose, promoStatus]);

  useOnMount(() => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.incentiveSignPlusUpgrade,
      page: location.pathname,
      extraData: {
        action: IncentiveSignPlusUpgradeAction.openModal,
      },
    });
  });

  return (
    <Modal maxWidth={"md"} className={clsx(classes.base, className)} isFooterHidden>
      <Header onClose={onClose} />
      <Box className={classes.content}>
        <Box className={classes.textSection}>{statusContent}</Box>
        {isMobileLayout ? null : (
          <Box className={classes.imageSection}>
            <SvgBox src={SignTrial} className={classes.image} />
          </Box>
        )}
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    "& .MuiDialog-container": {
      overflow: "hidden",
    },
    "& .MuiPaper-root": {
      borderRadius: spacing(2),
    },
    [breakpoints.down("sm")]: {
      padding: spacing(2),
    },
  },
  content: {
    overflow: "hidden",
    display: "flex",
    height: "100%",
  },
  textSection: {
    minWidth: "460px",
    padding: spacing("90px", 4, 4, 4),
    flex: 1,
    overflow: "hidden",
    [breakpoints.down("sm")]: {
      minWidth: 0,
      maxWidth: "100%",
      padding: 0,
    },
  },
  imageSection: {
    flexGrow: 1,
  },
  image: {
    height: "100%",
    "& div": {
      height: "100%",
      display: "block",
      "& svg": {
        height: "100%",
      },
    },
  },
}));
export default IncentiveModal;
