import { useSelector } from "react-redux";
import { AsYouType } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { TextFieldProps, TextField } from "@alohi/kit";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { InputAdornment } from "ui";
import { selectDefaultCountry } from "selectors/verifications.selector";
import CountryFlagDropdown from "components/Core/CountryFlag/CountryFlagDropdown";
import {
  getI18nCountry,
  getI18nCountryList,
  getPrefixForCountry,
  sanitizePhoneNumber,
} from "helpers/numbers";

interface PhoneInputProps extends TextFieldProps {
  shouldShowError?: boolean;
}

function PhoneInput({ value, onChange, shouldShowError, ...rest }: PhoneInputProps) {
  const { t } = useTranslation();
  const { defaultCountry } = useSelector(selectDefaultCountry);

  const [formattedValue, setFormattedValue] = useState(value);
  const [country, setCountry] = useState(defaultCountry);

  const initialValue = useRef(value);

  const handleCountryChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (getI18nCountry(event.target.value)) {
        onChange?.({
          ...event,
          target: {
            ...event.target,
            value: getPrefixForCountry(event.target.value),
          },
        });
        setCountry(event.target.value);
      }
    },
    [onChange],
  );

  const handleValueChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // Value is formatted here
      // Before sending it back to the parent, unformat it (remove whitespace)
      onChange?.({
        ...event,
        target: {
          ...event.target,
          value: sanitizePhoneNumber(event.target.value),
        },
      });
    },
    [onChange],
  );

  useEffect(() => {
    // Value will be unformatted when it gets updated from props
    // Format the value to be displayed on DOM
    if (typeof value === "string") {
      let defaultValue = value;
      if (!defaultValue.includes("+") && !(defaultValue === "")) {
        defaultValue = "+" + defaultValue;
      }
      const asYouType = new AsYouType();
      setFormattedValue(asYouType.input(defaultValue));
      if (asYouType.country) {
        setCountry(asYouType.country);
      } else {
        setCountry(undefined);
      }
    }
  }, [value]);

  useEffect(() => {
    if (initialValue.current === "") {
      setFormattedValue(getPrefixForCountry(defaultCountry));
      setCountry(defaultCountry);
    }
  }, [defaultCountry]);

  const helperText = shouldShowError
    ? rest.error
      ? t("RATES.INVALID_PHONE_INPUT")
      : " "
    : undefined;

  return (
    <TextField
      value={formattedValue}
      helperText={helperText}
      onChange={handleValueChange}
      {...rest}
      InputProps={{
        inputProps: { "data-cy": "PhoneInput" },
        startAdornment: (
          <InputAdornment position="start">
            <CountryFlagDropdown
              value={country}
              options={getI18nCountryList()}
              onChange={handleCountryChange}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PhoneInput;
