import { useEffect } from "react";
import { Panel, Button } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useBool from "hooks/useBool";
import { PersonalAccessTokens, selectPersonalAccessTokens } from "selectors/integrations.selector";
import { getPersonalAccessTokens } from "stores/reducers/integrations.api.reducer";
import ManageTokensModal from "./modals/ManageTokensModal/ManageTokensModal";

function PersonalTokensRow() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const personalAccessTokens: PersonalAccessTokens = useSelector(selectPersonalAccessTokens);

  const [isApiTokenModalOpen, isApiTokenModalOpenBool] = useBool(false);

  useEffect(() => {
    dispatch(getPersonalAccessTokens());
  }, [dispatch]);

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">{t("API_TOKEN.TITLE")}</Panel.Cell>
        <Panel.Cell variant="data">
          {personalAccessTokens.total
            ? t("API_TOKEN.NB_OF_TOKEN", {
                number: personalAccessTokens.total,
                count: personalAccessTokens.total,
              })
            : null}
        </Panel.Cell>
        <Panel.Cell variant="actions">
          {
            <Button variant="white" onClick={isApiTokenModalOpenBool.setTrue}>
              {t("COMMON.MANAGE")}
            </Button>
          }
        </Panel.Cell>
      </Panel.Row>

      {isApiTokenModalOpen ? (
        <ManageTokensModal onCancel={isApiTokenModalOpenBool.setFalse} />
      ) : null}
    </>
  );
}

export default PersonalTokensRow;
