import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Paper, Button, Typography, Box } from "@alohi/kit";

import useTitle from "hooks/useTitle";
import { history } from "helpers/index";
import { websiteUrls } from "routes/urls";
import Loading from "components/Loading/Loading";
import {
  selectIsResubscribed,
  selectIsUnsubscribed,
  selectUserInfoByToken,
  selectIsSubscribeError,
  selectIsSubscribeProcessRunning,
} from "selectors/verifications.selector";
import {
  getUserInfo,
  resubscribe,
  unsubscribe,
} from "stores/reducers/verifications.subscribe.reducer";

const VIEWS = {
  GO_HOME: "goHome",
  LOADING: "loading",
  UNSUBSCRIBE: "unsubscribe",
  RESUBSCRIBE: "resubscribe",
};

function Newsletter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { token } = queryString.parse(search);

  const [currentView, setCurrentView] = useState(VIEWS.LOADING);

  const email = useSelector(selectUserInfoByToken);
  const isError = useSelector(selectIsSubscribeError);
  const isUnsubscribed = useSelector(selectIsUnsubscribed);
  const isResubscribed = useSelector(selectIsResubscribed);
  const isRunning = useSelector(selectIsSubscribeProcessRunning);

  const { updateTitle } = useTitle("");

  useEffect(() => {
    if (pathname.search("resubscribe") !== -1) {
      dispatch(resubscribe(token));
      updateTitle(t("UNSUBSCRIBE.RESUBSCRIBED_TITLE"));
    } else {
      dispatch(getUserInfo(token));
      updateTitle(t("UNSUBSCRIBE.TITLE"));
    }
  }, [dispatch, pathname, t, token, updateTitle]);

  const onUnsubscribe = useCallback(() => {
    dispatch(unsubscribe(token));
  }, [dispatch, token]);

  const onResubscribe = useCallback(() => {
    dispatch(resubscribe(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (isRunning) {
      setCurrentView(VIEWS.LOADING);
    } else if (isError) {
      history.push("/");
    }
  }, [isRunning, isError]);

  useEffect(() => {
    if (email) {
      setCurrentView(VIEWS.UNSUBSCRIBE);
    }
  }, [email]);

  useEffect(() => {
    if (isResubscribed) {
      updateTitle(t("UNSUBSCRIBE.RESUBSCRIBE_SUCCESS_TITLE"));
      setCurrentView(VIEWS.GO_HOME);
    } else if (isUnsubscribed) {
      updateTitle(t("UNSUBSCRIBE.RESUBSCRIBED_TITLE"));
      setCurrentView(VIEWS.RESUBSCRIBE);
    }
  }, [isResubscribed, t, updateTitle, isUnsubscribed]);

  return (
    <Paper
      sx={{
        p: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 480,
        height: "fit-content",
        mt: 4,
      }}
    >
      {
        {
          [VIEWS.LOADING]: <Loading />,
          [VIEWS.UNSUBSCRIBE]: (
            <Box sx={{ p: 2 }}>
              <Typography sx={{ textAlign: "center" }}>
                {t("UNSUBSCRIBE.QUESTION_UNSUBSCRIBE", { email })}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Button onClick={onUnsubscribe}>{t("UNSUBSCRIBE.YES_UNSUBSCRIBE")}</Button>
                <Button variant="gray" sx={{ ml: 2 }} href={websiteUrls.homepage}>
                  {t("UNSUBSCRIBE.NO_CANCEL")}
                </Button>
              </Box>
            </Box>
          ),
          [VIEWS.RESUBSCRIBE]: (
            <Box sx={{ p: 2 }}>
              <Typography>{t("UNSUBSCRIBE.QUESTION_RESUBSCRIBE", { email })}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Button onClick={onResubscribe}>{t("UNSUBSCRIBE.RE_ENABLE")}</Button>
                <Button variant="gray" sx={{ ml: 2 }} href={websiteUrls.homepage}>
                  {t("UNSUBSCRIBE.CANCEL")}
                </Button>
              </Box>
            </Box>
          ),
          [VIEWS.GO_HOME]: (
            <Box sx={{ p: 2 }}>
              <Typography>{t("UNSUBSCRIBE.RESUBSCRIBE_SUCCESS", { email })}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Button variant="gray" href={websiteUrls.homepage}>
                  {t("UNSUBSCRIBE.CANCEL")}
                </Button>
              </Box>
            </Box>
          ),
        }[currentView]
      }
    </Paper>
  );
}

export default Newsletter;
