import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Divider, TextButton, Box, Button, Typography, useSnackbar } from "@alohi/kit";

import Link from "routes/Link";
import { urls } from "routes/urls";
import { useInput } from "hooks/useInput";
import { isValidEmail } from "helpers/inputValidation";
import { clearForgotPassword, forgotPassword } from "stores/reducers/authentication.reducer";
import {
  selectIsForgotPasswordSuccess,
  selectIsForgotPasswordRunning,
  selectForgotPasswordErrorMessage,
} from "selectors/authentication.selector";

interface RequestLinkProps {
  onCompleted: () => void;
}

function RequestLink({ onCompleted }: RequestLinkProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [email, emailInput] = useInput("", isValidEmail);

  const isSuccess = useSelector(selectIsForgotPasswordSuccess);
  const isRunning = useSelector(selectIsForgotPasswordRunning);
  const errorMessage = useSelector(selectForgotPasswordErrorMessage);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      emailInput.touch();

      if (!emailInput.isValid) {
        enqueueSnackbar(t("FORMS.INVALID_EMAIL"), { variant: "error" });
      } else {
        dispatch(forgotPassword(email));
      }
    },
    [dispatch, email, emailInput, enqueueSnackbar, t],
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearForgotPassword());
      onCompleted();
    }
  }, [dispatch, isSuccess, onCompleted]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
    dispatch(clearForgotPassword());
  }, [dispatch, enqueueSnackbar, errorMessage]);

  return (
    <Box component="form" sx={{ width: "100%" }} onSubmit={handleSubmit}>
      <Typography type="authenticationTitle">{t("FORGOT.TITLE")}</Typography>
      <Typography>{t("FORGOT.INTRO")}</Typography>
      <TextField
        fullWidth
        name="email"
        type="email"
        value={email}
        id="emailInput"
        sx={{ mb: 1, mt: 3 }}
        autoComplete="username"
        onBlur={emailInput.onBlur}
        onFocus={emailInput.onFocus}
        error={emailInput.showsError}
        onChange={emailInput.onChange}
        placeholder={t("COMMON.EMAIL")}
        helperText={emailInput.showsError ? t("FORMS.INVALID_EMAIL") : " "}
      />

      <Button size="large" fullWidth type="submit" isLoading={isRunning}>
        <Typography type="loginButton">{t("COMMON.SEND")}</Typography>
      </Button>

      <Divider sx={{ my: 2 }} />

      <Typography type="body" sx={{ display: "flex", alignItems: "center" }}>
        {t("SIGNUP.ALREADY_MEMBER")}
        <Link to={urls.login}>
          <TextButton sx={{ ml: 1 }}>{t("COMMON.LOG_IN")}</TextButton>
        </Link>
      </Typography>
    </Box>
  );
}

export default RequestLink;
