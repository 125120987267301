import { FaButton, Box } from "@alohi/kit";
import { ForwardedRef, MouseEvent, forwardRef } from "react";
import { animated, config, useTransition } from "react-spring";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { Grid, useTheme } from "ui";
import { PersonalAccessToken } from "selectors/integrations.selector";
import ViewTokenModal from "../../modals/ViewTokenModal";
import DeleteTokenModal from "../../modals/DeleteTokenModal";
import Name from "./components/Name";
import Infos from "./components/Infos";

interface TokenProps {
  token: PersonalAccessToken;
}

const Token = forwardRef(({ token }: TokenProps, ref: ForwardedRef<HTMLDivElement> | null) => {
  const theme = useTheme();

  const [isDeleteTokenModalOpen, isDeleteTokenModalOpenBool] = useBool(false);
  const [isTokenModalOpen, isTokenModalOpenBool] = useBool(false);

  const onDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    isDeleteTokenModalOpenBool.setTrue();
  };

  const transitions = useTransition(true, {
    config: { ...config.stiff, duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions((innerStyles) => (
    <animated.div ref={ref} style={innerStyles}>
      <Box
        display={"flex"}
        width={"100%"}
        p={1}
        mb={1}
        borderRadius={1}
        bgcolor={theme.alohi.lighterGray}
        onClick={isTokenModalOpenBool.setTrue}
        sx={{
          transition: "background 0.3s ease",

          ":hover": {
            background: theme.alohi.lightestBlue,
            cursor: "pointer",
          },
        }}
      >
        <Grid container display={"flex"} spacing={1}>
          <Grid item sm={5} xs={12} display={"flex"} flexDirection={"column"}>
            <Name name={token.name} last_used={token.last_used} />
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Infos token_str={token.token_str} expires_at={token.expires_at} />
          </Grid>
        </Grid>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          justifyContent={"center"}
        >
          <FaButton variant="dark" icon={faTrashCan} onClick={onDelete} />
        </Box>
      </Box>

      {isDeleteTokenModalOpen ? (
        <DeleteTokenModal
          id={token.id}
          onCancel={isDeleteTokenModalOpenBool.setFalse}
          onConfirm={isDeleteTokenModalOpenBool.setFalse}
        />
      ) : null}

      {isTokenModalOpen ? (
        <ViewTokenModal
          token={token}
          onCancel={isTokenModalOpenBool.setFalse}
          onDelete={isTokenModalOpenBool.setFalse}
        />
      ) : null}
    </animated.div>
  ));
});

Token.displayName = "Token";

export default Token;
