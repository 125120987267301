import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { clearCart } from "stores/reducers/payment.cart.reducer";
import { clearChargeAmount } from "stores/reducers/payment.charge.reducer";
import { clearAddressRequirements } from "stores/reducers/address.reducer";
import { clearCorporateUpgrade } from "stores/reducers/payment.corporate.reducer";
import { clearAreaForCountry, clearCountryForPlan } from "stores/reducers/plans.reducer";
import {
  clearVerifyNumber,
  clearVerifyCustomNumber,
  clearVerifyBulkNumbers,
  clearAssignBundleToNumber,
} from "stores/reducers/verifications.reducer";

function useCleanPlansReducers() {
  const dispatch = useDispatch();

  const handleCleaning = useCallback(() => {
    dispatch(clearCart());
    dispatch(clearChargeAmount());
    dispatch(clearVerifyNumber());
    dispatch(clearCountryForPlan());
    dispatch(clearAreaForCountry());
    dispatch(clearCorporateUpgrade());
    dispatch(clearVerifyCustomNumber());
    dispatch(clearAssignBundleToNumber());
    dispatch(clearAddressRequirements());
    dispatch(clearVerifyBulkNumbers());
  }, [dispatch]);

  return handleCleaning;
}

export default useCleanPlansReducers;
