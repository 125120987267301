import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  Button,
  Helper,
  Container,
  makeStyles,
  useOnMount,
  Typography,
} from "@alohi/kit";

import { alohiAdminUrls } from "routes/urls";
import useBool, { UseBoolType } from "hooks/useBool";
import ChangePlan from "views/ChangePlan/ChangePlan";
import notificationJson from "assets/json/notification.json";
import useAdminRedirections from "hooks/useAdminRedirections";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import PhoneVerificationModal from "views/PhoneVerification/PhoneVerificationModal";
import Tooltip from "./components/Tooltip";

interface PromotionProps {
  isPromotionShownBool: UseBoolType[1];
}

function Promotion({ isPromotionShownBool: isPromotionShowBool }: PromotionProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const cleanReducers = useCleanPlansReducers();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const [isChangePlanModalOpen, isChangePlanModalOpenBool] = useBool(false);
  const [isPhoneVerificationModalOpen, isPhoneVerificationModalOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: isChangePlanModalOpenBool.setTrue,
    });
  }, [redirectToAdmin, isChangePlanModalOpenBool.setTrue]);

  const handleClosure = useCallback(
    (isSuccess: boolean) => {
      isChangePlanModalOpenBool.setFalse();
      isPhoneVerificationModalOpenBool.setFalse();

      if (isSuccess) {
        isPromotionShowBool.setFalse();
        cleanReducers();
      }
    },
    [
      cleanReducers,
      isPromotionShowBool,
      isChangePlanModalOpenBool,
      isPhoneVerificationModalOpenBool,
    ],
  );

  const animationRefCallback = useCallback((domElement: HTMLDivElement) => {
    const isLottieLoaded =
      "lottie" in window &&
      "loadAnimation" in window.lottie &&
      typeof window.lottie.loadAnimation === "function";

    if (domElement !== null && isLottieLoaded) {
      window.lottie.loadAnimation({
        loop: true,
        renderer: "svg",
        autoplay: true,
        container: domElement,
        animationData: notificationJson,
        rendererSettings: {
          progressiveLoad: true,
        },
      });
    }
  }, []);

  useOnMount(isPromotionShowBool.setTrue);

  return (
    <Container maxWidth="sm">
      <Card>
        <Card.Header className={classes.header}>
          <Typography type="cardFrontHeader">{t("COMMON.SEND_FAX")}</Typography>
          <Helper variant="light" size="lg" tooltip={<Tooltip />} placement="right" />
        </Card.Header>

        <Card.Content variant="border" position="relative">
          <Box className={classes.icon} ref={animationRefCallback} width={87.5} height={70} />
          <Typography type="appBarTitle">{t("VERIFY.TO_SEND")}</Typography>
          <Typography type="body" className={classes.textInfo}>
            {t("SENT_FAX.TO_SEND_FAXES")}
          </Typography>
        </Card.Content>

        <Card.Footer className={classes.footer}>
          <Button onClick={isPhoneVerificationModalOpenBool.setTrue}>{t("VERIFY.PHONE")}</Button>
          <Button className={classes.upgradeButton} onClick={handleOpenChangePlan}>
            {t("COMMON.UPGRADE")}
          </Button>
        </Card.Footer>
      </Card>

      {isPhoneVerificationModalOpen ? (
        <PhoneVerificationModal handleClosure={handleClosure} />
      ) : null}

      {isChangePlanModalOpen ? <ChangePlan handleClosure={handleClosure} /> : null}
    </Container>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  header: {
    display: "flex",
    color: alohi.white,
  },
  icon: {
    height: 70,
    width: 87.5,
    margin: spacing(4, 0),
  },
  upgradeButton: {
    marginLeft: spacing(2),
  },
  footer: {
    "&&": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  textInfo: {
    margin: spacing(2, 4, 4, 4),
  },
}));

export default Promotion;
