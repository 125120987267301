import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { Menu, Divider, MenuItem, FaButton, Typography, Box } from "@alohi/kit";

import { boxNames } from "enums/faxes";
import FaxStatusModal from "views/Faxes/FaxStatusModal";
import { isPossiblePhoneNumber } from "helpers/inputValidation";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";
import AddNoteOnFaxModal from "views/Faxes/modals/AddNoteOnFaxModal";
import AddOrEditContactModal from "views/Contacts/AddOrEditContactModal";
import { selectAccountDefaultFaxFileType } from "selectors/account.selector";
import AddOrEditBlacklistContactModal from "views/Contacts/AddOrEditBlacklistContactModal";
import {
  selectCdrId,
  selectCdrById,
  selectCdrIsFailed,
  selectCdrContactName,
  selectCdrFromToNumber,
  selectCdrFullNoteById,
} from "selectors/faxes.selector";

function SentboxActions({ dataIndex }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Keep the ID of the original CDR, even when indexes change
  // Make sure that the menu is still tied to the CDR it was opened from
  const initialCdrId = useSelector(selectCdrId(boxNames.sentbox, dataIndex));
  const initialToNumber = useSelector(selectCdrFromToNumber(boxNames.sentbox, dataIndex));
  const initialIsFailed = useSelector(selectCdrIsFailed(boxNames.sentbox, dataIndex));
  const initialContactName = useSelector(selectCdrContactName(boxNames.sentbox, dataIndex));

  const [cdrId, setCdrId] = useState(initialCdrId);
  const [toNumber, setToNumber] = useState(initialToNumber);
  const [contactName, setContactName] = useState(initialContactName);
  const [isFailed, setIsFailed] = useState(initialIsFailed);
  const cdr = useSelector(selectCdrById(boxNames.sentbox, cdrId));
  const note = useSelector(selectCdrFullNoteById(boxNames.sentbox, cdrId));
  const downloadFileType = useSelector(selectAccountDefaultFaxFileType);

  const [actions, setActions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addNoteOnFaxModal, setAddNoteOnFaxModal] = useState({
    isOpen: false,
    cdrId: null,
    currentNote: null,
  });
  const [faxStatusDialog, setFaxStatusDialog] = useState({
    isOpen: false,
    cdrId: null,
  });
  const [addToWhitelistModal, setAddToWhitelistModal] = useState({
    isOpen: false,
    prefilledFaxNumber: null,
  });
  const [addToBlacklistModal, setAddToBlacklistModal] = useState({
    isOpen: false,
    prefilledFaxNumber: null,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCdrId(initialCdrId);
    setToNumber(initialToNumber);
    setContactName(initialContactName);
    setIsFailed(initialIsFailed);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Close menu if CDR disappears from the box
  useEffect(() => {
    if (!cdr && !!anchorEl) {
      handleClose();
    }
  }, [anchorEl, cdr]);

  useEffect(() => {
    if (!!anchorEl) {
      const isToValidPhoneNumber = isPossiblePhoneNumber(toNumber);
      let newActions = [];

      newActions.push({
        label: t("FAXES.RESEND"),
        callback: () => {
          dispatch(faxesAsyncActions.resend({ boxName: boxNames.sentbox, cdrId }));
        },
      });
      newActions.push({
        label: t("FAXES.FORWARD"),
        callback: () => {
          dispatch(faxesAsyncActions.forward({ boxName: boxNames.sentbox, cdrId }));
        },
      });
      newActions.push({
        label: t("FAXES.MOVE_TO_TRASH"),
        callback: async () => {
          await dispatch(
            faxesAsyncActions.moveToTrash({
              boxName: boxNames.sentbox,
              cdrIds: [cdrId],
            }),
          );
        },
      });
      newActions.push({ label: "Divider" });
      newActions.push({
        label: t("COMMON.DOWNLOAD"),
        callback: () => {
          dispatch(
            faxesAsyncActions.download({
              cdrId,
              downloadFileType,
              boxName: boxNames.sentbox,
            }),
          );
        },
      });
      newActions.push({
        label: t("FAXES.FORWARD_SIGN"),
        callback: () => {
          dispatch(
            faxesAsyncActions.getCdrToken({
              cdrId,
              boxName: boxNames.inbox,
            }),
          );
        },
      });
      newActions.push({
        label: !!note ? t("FAXES.MODIFY_NOTE") : t("FAXES.ADD_NOTE"),
        callback: () => {
          setAddNoteOnFaxModal({
            isOpen: true,
            cdrId,
            currentNote: note,
          });
        },
      });
      newActions.push({
        label: t("FAXES.FAX_STATUS"),
        callback: () => {
          setFaxStatusDialog({
            isOpen: true,
            cdrId,
          });
        },
      });
      newActions.push({
        label: isFailed ? t("FAXES.FAX_FAILURE_REPORT") : t("FAXES.FAX_CONFIRMATION_REPORT"),
        callback: () => {
          dispatch(
            faxesAsyncActions.previewReport({
              boxName: boxNames.sentbox,
              cdrId,
            }),
          );
        },
      });
      if (isToValidPhoneNumber) {
        newActions.push({ label: "Divider" });
        if (!contactName) {
          newActions.push({
            label: t("CONTACTS.ADD_TO_CONTACTS"),
            callback: () => {
              setAddToWhitelistModal({
                isOpen: true,
                prefilledFaxNumber: toNumber,
              });
            },
          });
        }
        newActions.push({
          label: t("CONTACTS.ADD_BLACKLIST"),
          callback: () => {
            setAddToBlacklistModal({
              isOpen: true,
              prefilledFaxNumber: toNumber,
            });
          },
        });
      }
      setActions(newActions);
    }
  }, [
    anchorEl,
    dispatch,
    toNumber,
    cdrId,
    isFailed,
    note,
    t,
    contactName,
    downloadFileType,
    actions.length,
  ]);

  return (
    <>
      <Box textAlign="end">
        <FaButton
          aria-label="More"
          aria-haspopup="true"
          onClick={handleClick}
          aria-controls={`actions-menu-${dataIndex}`}
          icon={faEllipsisV}
        />
        <Menu
          id={`actions-menu-${dataIndex}`}
          anchorEl={anchorEl}
          open={!!anchorEl && !!actions}
          onClose={handleClose}
          elevation={2}
        >
          {actions.map((action, key) => {
            if (action.label === "Divider") {
              return (
                <Box py={1} key={key}>
                  <Divider />
                </Box>
              );
            } else {
              return (
                <MenuItem
                  key={action.label}
                  disabled={action.disabled}
                  onClick={() => {
                    action.callback();
                    handleClose();
                  }}
                >
                  <Typography variant="body" style={{ fontSize: "0.875rem" }}>
                    {action.label}
                  </Typography>
                </MenuItem>
              );
            }
          })}
        </Menu>
      </Box>

      {addNoteOnFaxModal.isOpen ? (
        <AddNoteOnFaxModal
          dataIndex={dataIndex}
          boxName={boxNames.sentbox}
          cdrId={addNoteOnFaxModal.cdrId}
          currentNote={addNoteOnFaxModal.currentNote}
          handleClosure={() =>
            setAddNoteOnFaxModal({
              isOpen: false,
              cdrId: null,
              currentNote: null,
            })
          }
        />
      ) : null}

      {faxStatusDialog.isOpen ? (
        <FaxStatusModal
          handleClosure={() => setFaxStatusDialog({ isOpen: false, cdrId: null })}
          cdrId={faxStatusDialog.cdrId}
          boxName={boxNames.sentbox}
        />
      ) : null}

      {addToWhitelistModal.isOpen && (
        <AddOrEditContactModal
          prefilledFaxNumber={addToWhitelistModal.prefilledFaxNumber}
          handleClosure={() => setAddToWhitelistModal({ isOpen: false })}
        />
      )}

      {addToBlacklistModal.isOpen && (
        <AddOrEditBlacklistContactModal
          prefilledFaxNumber={addToBlacklistModal.prefilledFaxNumber}
          handleClosure={() => setAddToBlacklistModal({ isOpen: false })}
        />
      )}
    </>
  );
}

SentboxActions.propTypes = {
  dataIndex: PropTypes.number.isRequired,
};

export default memo(SentboxActions);
