import PropTypes from "prop-types";
import { FormLabel, Select } from "@alohi/kit";

import { Grid, FormControl, makeStyles } from "ui";

function FormRowDisabledSelectInput({ id, label, value, children, labelGridWidth = 4 }) {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item xs={labelGridWidth} className={classes.labelContainer}>
          <FormLabel id={`${id}-label`} htmlFor={id} className={classes.label}>
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12 - labelGridWidth}>
          <Select
            disabled
            id={id}
            fullWidth
            displayEmpty
            value={value}
            variant="outlined"
            labelId={`${id}-label`}
            IconComponent={() => null}
          >
            {children}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  labelContainer: {
    textAlign: "right",
  },
  label: {
    color: alohi.gray,
    fontWeight: "bold",
    marginRight: spacing(2),
    fontSize: 14,
  },
  gridContainer: {
    marginTop: 19,
  },
}));

FormRowDisabledSelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  labelGridWidth: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormRowDisabledSelectInput;
