import i18n from "i18n";

export const notificationAttachmentsValues = {
  confirmationPage: "confirmationPage",
  faxedDocuments: "faxedDocuments",
};

export const notificationsValues = {
  noAttachment: "noAttachment",
  withAttachment: "withAttachment",
  off: "off",
};

export const off = { name: i18n.t("COMMON.OFF"), id: notificationsValues.off };

export const notify = {
  name: i18n.t("PROFILE.NOTIFICATIONS_NOTIFY"),
  id: notificationsValues.noAttachment,
};

export const notifyAttach = {
  name: i18n.t("PROFILE.NOTIFICATIONS_NOTIFY_ATTACH"),
  id: notificationsValues.withAttachment,
};

export const confirmationPage = {
  name: i18n.t("PROFILE.NOTIFICATIONS_CONFIRMATION_PAGE"),
  value: notificationAttachmentsValues.confirmationPage,
};

export const faxedDocument = {
  name: i18n.t("PROFILE.NOTIFICATIONS_FAXED_DOCUMENT"),
  value: notificationAttachmentsValues.faxedDocuments,
};
