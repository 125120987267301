import countries from "i18n-iso-countries";
import { initReactI18next } from "react-i18next";
import { StripeElementLocale } from "@stripe/stripe-js";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n, { use as i18nUse, Resource, InitOptions, changeLanguage } from "i18next";

import isoEn from "i18n-iso-countries/langs/en.json";
import isoFr from "i18n-iso-countries/langs/fr.json";

import { NODE_ENV } from "config";
import dayjs, { Dayjs } from "helpers/date";

import en from "./local/en.json";
import fr from "./local/fr.json";

import kitEn from "./kit/en.json";
import kitFr from "./kit/fr.json";

import "dayjs/locale/fr";
import "dayjs/locale/en";

export const allowedStripeLocales: StripeElementLocale[] = ["auto", "en", "fr"];

export const resources: Resource = {
  en: { translation: { ...en, ...kitEn } },
  fr: { translation: { ...fr, ...kitFr } },
} as const;

countries.registerLocale(isoEn);
countries.registerLocale(isoFr);

const options: InitOptions = {
  resources,
  fallbackLng: "en",
  compatibilityJSON: "v2",
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      brand: "Fax.Plus",
    },
  },
  detection: {
    order: ["querystring", "cookie", "localStorage"],
  },
  keySeparator: ".",
  debug: NODE_ENV !== "production",
};

i18nUse(initReactI18next)
  .use(LanguageDetector)
  .init(options)
  .then(() => {
    if (!Object.keys(resources).includes(i18n.language)) {
      changeLanguage("en");
    }
    dayjs.locale(i18n.language);
  })
  .catch(() => undefined);

i18n.on("languageChanged", function (lng: string) {
  dayjs.locale(lng);
});

dayjs.updateLocale("en", {
  calendar: {
    sameDay: "[Today -] H:mm", // Today - 06:23
    lastDay: "[Yesterday -] H:mm", // Yesterday - 06:32
    lastWeek: "dddd [-] H:mm", // Last Tuesday - 06:21
    sameElse: function (now: Dayjs) {
      const date = this as unknown as Dayjs;
      if (date.isSame(now, "year")) {
        return date.format("ddd[,] MMM DD [-] H:mm"); // Tue, Jan 11 - 8:57
      } else {
        return date.format("MMM DD[,] YYYY [-] H:mm"); // Dec 26, 2021 - 16:17
      }
    },
  },
});

dayjs.updateLocale("fr", {
  calendar: {
    sameDay: "[Aujourd'hui -] H:mm",
    lastDay: "[Hier -] H:mm",
    lastWeek: "dddd [-] H:mm",
    sameElse: function (now: Dayjs) {
      const date = this as unknown as Dayjs;
      if (date.isSame(now, "year")) {
        return date.format("ddd DD MMM [-] H:mm");
      } else {
        return date.format("DD MMM YYYY [-] H:mm");
      }
    },
  },
});

dayjs.updateLocale("pt", {
  calendar: {
    sameDay: "[Hoje -] H:mm",
    lastDay: "[Ontem -] H:mm",
    lastWeek: "dddd [-] H:mm",
    sameElse: function (now: Dayjs) {
      const date = this as unknown as Dayjs;
      if (date.isSame(now, "year")) {
        return date.format("ddd DD MMM [-] H:mm");
      } else {
        return date.format("DD MMM YYYY [-] H:mm");
      }
    },
  },
});

export default i18n;
