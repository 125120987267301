import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useCallback, useEffect } from "react";
import { Box, Typography, makeStyles, Button } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import PhoneInput from "components/Forms/PhoneInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { useOnPressEscape } from "hooks/useOnPressEscape";
import { PhoneNumberFilter } from "components/Core/Filters";
import { isValidPhoneNumber } from "helpers/inputValidation";
import {
  selectIsCalculatePageError,
  selectIsGeoLocationRunning,
  selectCalculatePageResponse,
} from "selectors/verifications.selector";
import {
  calculatePage,
  getGeoLocation,
  clearCalculatePage,
} from "stores/reducers/verifications.reducer";

function Rates() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [phone, phoneInput] = useInput("", isValidPhoneNumber);

  const isError = useSelector(selectIsCalculatePageError);
  const result = useSelector(selectCalculatePageResponse(phone));
  const isGetGeoLocationRunning = useSelector(selectIsGeoLocationRunning);

  const [lastError, setLastError] = useState(null);
  const [lastResult, setLastResult] = useState(null);

  const handleCalculate = useCallback(() => {
    if (phoneInput.isValid) {
      dispatch(calculatePage({ number: phone }));
    }
  }, [dispatch, phone, phoneInput.isValid]);

  const onPressEnter = useCallback(() => {
    if (phoneInput.isFocused) {
      handleCalculate();
    }
  }, [handleCalculate, phoneInput.isFocused]);

  const onPressEscape = useCallback(() => {
    if (phoneInput.isFocused) {
      phoneInput.setValue("");
    }
  }, [phoneInput]);

  useOnPressEnter(onPressEnter);
  useOnPressEscape(onPressEscape);

  useEffect(() => {
    if (result) {
      setLastError(null);
      setLastResult({
        count: +result.page,
        description: result.description,
        phone: <PhoneNumberFilter number={phone} />,
      });
    }
  }, [phone, result]);

  useEffect(() => {
    if (isError) {
      setLastError(t("RATES.INVALID_PHONE"));
      setLastResult(null);
      phoneInput.setShowsError(true);
      dispatch(clearCalculatePage());
    }
  }, [isError, t, dispatch, phoneInput]);

  useEffect(() => {
    dispatch(getGeoLocation());

    return () => {
      dispatch(clearCalculatePage());
    };
  }, [dispatch]);

  if (isGetGeoLocationRunning) return null;

  return (
    <Box className={classes.wrapper}>
      <PhoneInput
        value={phone}
        shouldShowError
        className={classes.input}
        onBlur={phoneInput.onBlur}
        onFocus={phoneInput.onFocus}
        onChange={phoneInput.onChange}
        error={phoneInput.showsError}
        placeholder={t("PROFILE.ENTER_PHONE_NUMBER")}
      />
      <Button
        color="primary"
        onClick={handleCalculate}
        className={classes.button}
        disabled={phoneInput.showsError}
      >
        {t("COMMON.CALCULATE")}
      </Button>
      <Typography className={classes.text}>
        {lastError ? (
          lastError
        ) : lastResult ? (
          <Trans
            i18nKey="RATES.RESULT_RATE_PAGE"
            count={lastResult.count}
            components={{ u: <u />, ...lastResult }}
          />
        ) : null}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",
    "& u": {
      textDecoration: "underline",
    },
  },
  input: {
    maxWidth: "224px",
    margin: "20px auto 10px auto",
  },
  button: {
    width: "144px",
    marginBottom: "20px",
  },
  text: {
    color: alohi.blue,
    fontWeight: "bolder",
  },
}));

export default Rates;
