import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import FormRow from "components/Forms/FormRow";
import UserProgress from "components/UserProgress/UserProgress";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { Radio, RadioGroup, FormControlLabel, Helper } from "ui";
import { updateSsoSettings, clearUpdateSsoSettings } from "stores/reducers/account.sso.reducer";
import {
  selectIsSsoUnset,
  selectSsoSettings,
  selectUpdateSsoSettingsError,
  selectIsUpdateSsoSettingsSuccess,
  selectIsUpdateSsoSettingsRunning,
} from "selectors/account.sso.selector";

function SsoConfigurationStep({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isSsoUnset = useSelector(selectIsSsoUnset);
  const ssoSettings = useSelector(selectSsoSettings);
  const error = useSelector(selectUpdateSsoSettingsError);
  const isSuccess = useSelector(selectIsUpdateSsoSettingsSuccess);
  const isLoading = useSelector(selectIsUpdateSsoSettingsRunning);

  const [ssoType, setSsoType] = useState(
    isSsoUnset ? undefined : ssoSettings?.isLegacy ? "legacy" : "new",
  );

  const [ssoUrlValue, ssoUrlInput] = useInput(ssoSettings?.ssoUrl ?? "", (value) => !!value);
  const [entityIdValue, entityIdInput] = useInput(ssoSettings?.entityId ?? "", (value) => !!value);
  const [x509CertValue, x509CertInput] = useInput(ssoSettings?.x509Cert ?? "", (value) => !!value);
  const [refreshTokenLifetimeValue, refreshTokenLifetimeInput] = useInput(
    ssoSettings?.refreshTokenLifetime || 3600,
    (value) => value > 0,
  ); // Backend returns 0 when disabled
  const [isOnlyAllowSso, setIsOnlyAllowSso] = useState(ssoSettings?.isOnlyAllowSso ?? false);

  const isAddFaxLicense = ssoSettings?.isAddFaxLicense ?? false;
  const isAddSignLicense = ssoSettings?.isAddSignLicense ?? false;

  const handleConfirm = () => {
    entityIdInput.touch();
    ssoUrlInput.touch();
    x509CertInput.touch();
    refreshTokenLifetimeInput.touch();

    if (
      entityIdInput.isValid &&
      ssoUrlInput.isValid &&
      x509CertInput.isValid &&
      refreshTokenLifetimeInput.isValid
    ) {
      dispatch(
        updateSsoSettings({
          isOnlyAllowSso,
          isAddFaxLicense,
          isAddSignLicense,
          ssoUrl: ssoUrlValue,
          entityId: entityIdValue,
          x509Cert: x509CertValue,
          isLegacy: ssoType === "legacy",
          refreshTokenLifetime: refreshTokenLifetimeValue,
        }),
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearUpdateSsoSettings());

      handleClosure(true);
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isSuccess, t]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [enqueueSnackbar, error, t]);

  return (
    <Modal
      title={
        <Typography type="modalTitle">
          <Box display="flex" alignItems="center">
            <Box>{t("SSO.TITLE")}</Box>
            <Box ml={1} href={helpCenterUrls.sso} component="a" target="_blank" rel="noreferrer">
              <Helper size="lg" variant="dark" />
            </Box>
          </Box>
        </Typography>
      }
      onConfirm={handleConfirm}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      confirmTitle={t("COMMON.NEXT")}
      onCancel={() => handleClosure(false)}
      isConfirmDisabled={
        entityIdInput.showsError ||
        ssoUrlInput.showsError ||
        x509CertInput.showsError ||
        refreshTokenLifetimeInput.showsError
      }
    >
      <Box mt={3} px={3}>
        <UserProgress
          currentStep={1}
          stepNames={[t("SSO.IDENTITY_PROVIDER"), t("SSO.USER_PROVISIONING")]}
        />
      </Box>
      <Box component="form" py={2} pr="15%">
        <FormRowTextInput
          id={"sso-url"}
          value={ssoUrlValue}
          label={t("SSO.URL")}
          labelGridWidth={5}
          onChange={ssoUrlInput.onChange}
          onFocus={ssoUrlInput.onFocus}
          onBlur={ssoUrlInput.onBlur}
          error={ssoUrlInput.showsError}
          helperText={ssoUrlInput.showsError ? t("SSO.INVALID_URL") : " "}
        />
        <FormRowTextInput
          id={"sso-entity-id"}
          value={entityIdValue}
          labelGridWidth={5}
          label={t("SSO.ENTITY_ID")}
          onChange={entityIdInput.onChange}
          onFocus={entityIdInput.onFocus}
          onBlur={entityIdInput.onBlur}
          error={entityIdInput.showsError}
          helperText={entityIdInput.showsError ? t("SSO.INVALID_ENTITY_ID") : " "}
        />
        <FormRowTextInput
          id={"sso-x509-certificate"}
          multiline
          rows={4}
          labelGridWidth={5}
          value={x509CertValue}
          label={t("SSO.X509_CERTIFICATE")}
          onChange={x509CertInput.onChange}
          onFocus={x509CertInput.onFocus}
          onBlur={x509CertInput.onBlur}
          error={x509CertInput.showsError}
          helperText={x509CertInput.showsError ? t("SSO.INVALID_X509_CERTIFICATE") : " "}
        />
        <FormRowTextInput
          type="number"
          value={refreshTokenLifetimeValue}
          id={"sso-refresh-token-lifetime"}
          label={
            <Box display="flex" flexDirection="column" mt={2}>
              <Typography style={{ fontWeight: 700 }}>{t("SSO.REFRESH_TOKEN_LIFETIME")}</Typography>
              <Typography type="subLabel">({t("COMMON.SECONDS")})</Typography>
            </Box>
          }
          labelGridWidth={5}
          onChange={refreshTokenLifetimeInput.onChange}
          onFocus={refreshTokenLifetimeInput.onFocus}
          onBlur={refreshTokenLifetimeInput.onBlur}
          error={refreshTokenLifetimeInput.showsError}
          helperText={
            refreshTokenLifetimeInput.showsError ? t("SSO.INVALID_REFRESH_TOKEN_LIFETIME") : " "
          }
        />

        <FormRow labelGridWidth={5} label={t("SSO.BYPASS")}>
          <FormControlLabel
            label={t("SSO.ALLOW_ADMINS_TO_USE")}
            control={
              <Checkbox
                id="sso-only-for-admin"
                checked={isOnlyAllowSso}
                inputProps={{ "aria-label": "sso-only" }}
                onChange={(event) => {
                  setIsOnlyAllowSso(event.target.checked);
                }}
              />
            }
          />
        </FormRow>

        <FormRow labelGridWidth={5} label={t("SSO.TYPE")}>
          <RadioGroup
            name={ssoType}
            value={ssoType}
            aria-label="sso type"
            onChange={({ target }) => setSsoType(target.value)}
          >
            <FormControlLabel
              value="legacy"
              label={t("SSO.LEGACY")}
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="new"
              control={<Radio color="primary" />}
              label={
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography>Alohi SSO</Typography>
                  <Box
                    ml={1}
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    href={helpCenterUrls.ssoAlohi}
                  >
                    <Helper size="1x" />
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        </FormRow>
      </Box>
    </Modal>
  );
}

SsoConfigurationStep.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default SsoConfigurationStep;
