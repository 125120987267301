import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { accountsApi } from "api";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { RootState } from "stores/store";
import { logout } from "./authentication.reducer";
import {
  RecentContact,
  DefaultRequestOptions,
  GetRecentContactsResponse,
  transformRecentContacts,
} from "./contacts.helpers";

const defaultRequestOptions = {
  search: "",
  type: "json",
};

const initialState = {
  getRecentContacts: InitialRequestState(),

  selection: [] as string[],
  contacts: null as null | RecentContact[],
};

const getRecentContacts = createAsyncThunk<
  GetRecentContactsResponse,
  Partial<DefaultRequestOptions>,
  { state: RootState; rejectValue: FetchError }
>("RECENT/GET", async (requestOptionsOverrides = {}, { rejectWithValue }) => {
  try {
    const requestOptions = { ...defaultRequestOptions, ...requestOptionsOverrides };

    const response = await accountsApi.getRecent(requestOptions);

    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const clearRecentContacts = createAction("RECENT/CLEAR_RECENT");
export const updateRecentContactsSelection = createAction<string[]>("RECENT/UPDATE_SELECTION");

const { reducer } = createSlice({
  name: "RECENT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateRecentContactsSelection, (state, { payload }) => {
      state.selection = payload;
    });

    builder.addCase(getRecentContacts.pending, (state) => {
      state.getRecentContacts.status = RS.RUNNING;
      state.getRecentContacts.error = null;
    });
    builder.addCase(getRecentContacts.fulfilled, (state, { payload }) => {
      state.getRecentContacts.status = RS.IDLE;
      state.getRecentContacts.response = payload.response;

      state.contacts = transformRecentContacts(payload.response.recent_destinations ?? []);
    });
    builder.addCase(getRecentContacts.rejected, (state, { payload }) => {
      state.getRecentContacts.status = RS.ERROR;
      state.getRecentContacts.error = payload;
    });

    builder.addCase(clearRecentContacts, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export { getRecentContacts };

export default reducer;
