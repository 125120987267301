import { get } from "js-cookie";
import TagManager from "react-gtm-module";

import { GOOGLE_TAG_MANAGER_ID } from "config";

const setUserId = (userId?: string) => {
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID,
    dataLayer: {
      userId,
    },
  });
};

const changePlanModalOpen = () => {
  TagManager.dataLayer({ dataLayer: { event: "change_plan_modal_open" } });
};

// Free user buying a plan (last purchase was > 24h ago -- probably the credit to buy the plan)
const newSubscription = ({
  planType,
  value,
  currency,
}: {
  planType: string;
  value: number;
  currency: string;
}) => {
  // Send the generic event
  TagManager.dataLayer({
    dataLayer: {
      event: "new_subscription",
      plan_type: planType.toUpperCase(),
      value,
      currency,
    },
  });

  // Also send the sub-event for the plan type
  TagManager.dataLayer({
    dataLayer: {
      event: `new_subscription_${planType.toLowerCase()}`,
      plan_type: planType.toUpperCase(),
      value,
      currency,
    },
  });
};

const getUserLocale = () => {
  return {
    locale: navigator.language || "",
    referrer: get("Referrer") || document.referrer || "",
  };
};

/**
 * Reads a cookie called __gtm_campaign_url, and extracts utm params if any
 */
const readMarketingCookies = () => {
  const whitelistedTerms = [
    "utm_term",
    "utm_source",
    "utm_medium",
    "utm_content",
    "utm_campaign",
  ] as const;
  const urlSourceCookie = get("__gtm_campaign_url");

  if (!urlSourceCookie) {
    return null;
  }

  const paramsObject = Object.fromEntries(
    new URL(decodeURIComponent(urlSourceCookie)).searchParams,
  );

  const { params, isEmpty } = whitelistedTerms.reduce(
    (accumulator, currentValue) => {
      const currentParam = paramsObject[currentValue];

      if (currentParam) {
        accumulator.isEmpty = false;
        accumulator.params[currentValue] = currentParam;
      } else {
        accumulator.params[currentValue] = "";
      }

      return accumulator;
    },
    { params: {}, isEmpty: true } as {
      params: Record<(typeof whitelistedTerms)[number], string>;
      isEmpty: boolean;
    },
  );

  return isEmpty ? null : params;
};

export const gaEvents = {
  setUserId,
  newSubscription,
  changePlanModalOpen,
};

export { readMarketingCookies, getUserLocale };
