import { API_AFFOGATO } from "config";
import { fetchWithRefresh } from "helpers";

const getTokensList = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/personal_access_tokens`;
  return fetchWithRefresh(path, { ...requestOptions });
};

export interface CreateTokenProps {
  name: string;
  scopes: (
    | "fax:all:read"
    | "fax:all:edit"
    | "fax:webhook:read"
    | "fax:webhook:read"
    | "fax:webhook:edit"
    | "fax:fax:read"
    | "fax:fax:edit"
    | "fax:shop:edit"
    | "fax:shop:read"
    | "fax:user:read"
    | "fax:user:edit"
    | "fax:member:read"
    | "fax:member:edit"
    | "fax:numbers:read"
    | "fax:numbers:edit"
  )[];
  expires_at: number;
}

const createToken = (params: CreateTokenProps) => {
  const requestOptions = {
    method: "POST",
    body: { ...params },
  };
  const path = `${API_AFFOGATO}/personal_access_tokens`;
  return fetchWithRefresh(path, { ...requestOptions });
};

export interface DeleteTokenProps {
  id: string;
}

const deleteToken = (params: DeleteTokenProps) => {
  const requestOptions = {
    method: "DELETE",
  };
  const path = `${API_AFFOGATO}/personal_access_tokens/${params.id}`;
  return fetchWithRefresh(path, requestOptions);
};

export const apiPersonalAccessToken = {
  getTokensList,
  createToken,
  deleteToken,
};
