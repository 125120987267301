import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { CodeInput, Grid } from "ui";
import { useInput } from "hooks/useInput";
import { isValidTwoFaCode } from "helpers/inputValidation";
import { clearDeactivateTwoFa, deactivateTwoFa } from "stores/reducers/twoFa.reducer";
import {
  selectIsDeactivateTwoFaError,
  selectIsDeactivateTwoFaSuccess,
  selectDeactivateTwoFaErrorEnforced,
  selectDeactivateTwoFaErrorInvalidCode,
} from "selectors/authentication.selector";

interface DeactivateTwoFaModalProps {
  handleClosure: () => void;
}

function DeactivateTwoFaModal({ handleClosure }: DeactivateTwoFaModalProps) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const isSuccess = useSelector(selectIsDeactivateTwoFaSuccess);
  const hasError = useSelector(selectIsDeactivateTwoFaError);
  const errorInvalidCode = useSelector(selectDeactivateTwoFaErrorInvalidCode);
  const errorEnforced = useSelector(selectDeactivateTwoFaErrorEnforced);

  const [twoFaCode, twoFaCodeInput] = useInput<string>("", isValidTwoFaCode);

  const handleSubmit = () => {
    twoFaCodeInput.touch();

    if (twoFaCodeInput.isValid) {
      dispatch(deactivateTwoFa(twoFaCode));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClosure();
      dispatch(clearDeactivateTwoFa());
    } else if (hasError) {
      if (errorInvalidCode) {
        enqueueSnackbar(t("PROFILE.2FA_INVALID_CODE"), { variant: "error" });
        twoFaCodeInput.setShowsError(true);
      } else if (errorEnforced) {
        enqueueSnackbar(t("PROFILE.2FA_YOU_ARE_NOT_ALLOWED_TO_DEACTIVATE_2FA"), {
          variant: "error",
        });
        twoFaCodeInput.setShowsError(true);
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
      dispatch(clearDeactivateTwoFa());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    errorEnforced,
    errorInvalidCode,
    handleClosure,
    hasError,
    isSuccess,
    t,
    twoFaCodeInput,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearDeactivateTwoFa());
    };
  }, [dispatch]);

  return (
    <Modal
      onCancel={handleClosure}
      onConfirm={handleSubmit}
      title={t("PROFILE.2FA_DISABLE_2FA_AUTH")}
      isConfirmDisabled={twoFaCodeInput.showsError}
    >
      <Box p={3}>
        <Typography type="body" textAlign="center">
          {t("PROFILE.2FA_DISABLE_DESCRIPTION")}
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          <CodeInput
            autoFocus
            fields={6}
            sx={{ my: 2 }}
            value={twoFaCode}
            id="deactivate-2fa-input"
            onChange={twoFaCodeInput.setValue}
            isError={twoFaCodeInput.showsError}
          />
        </Grid>
      </Box>
    </Modal>
  );
}

export default DeactivateTwoFaModal;
