import { createAsyncThunk } from "@reduxjs/toolkit";

import { unsubscribeApi } from "api";
import RS from "../../enums/requestStatus";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialSubscribeSlice = {
  subscribe: {
    userInfo: initialRequestState,
    resubscribe: initialRequestState,
    unsubscribe: initialRequestState,
  },
};

const getUserInfo = createAsyncThunk(
  `VERIFICATIONS/GET_USER_INFO_BY_TOKEN`,
  async (email, { rejectWithValue }) => {
    try {
      const response = await unsubscribeApi.getUserInfo(email);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const unsubscribe = createAsyncThunk(
  `VERIFICATIONS/UNSUBSCRIBE_BY_TOKEN`,
  async (token, { rejectWithValue }) => {
    try {
      const response = await unsubscribeApi.postUnsubscribe(token);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const resubscribe = createAsyncThunk(
  `VERIFICATIONS/RESUBSCRIBE_BY_TOKEN`,
  async (token, { rejectWithValue }) => {
    try {
      const response = await unsubscribeApi.postResubscribe(token);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const subscribeSlice = {
  [getUserInfo.pending]: (state) => {
    state.subscribe.userInfo.response = null;
    state.subscribe.userInfo.status = RS.RUNNING;
    state.subscribe.userInfo.error = null;
  },
  [getUserInfo.fulfilled]: (state, action) => {
    state.subscribe.userInfo.response = action.payload;
    state.subscribe.userInfo.status = RS.IDLE;
    state.subscribe.userInfo.error = null;
  },
  [getUserInfo.rejected]: (state, action) => {
    state.subscribe.userInfo.response = null;
    state.subscribe.userInfo.status = RS.ERROR;
    state.subscribe.userInfo.error = action.payload;
  },
  [unsubscribe.pending]: (state) => {
    state.subscribe.unsubscribe.response = null;
    state.subscribe.unsubscribe.status = RS.RUNNING;
    state.subscribe.unsubscribe.error = null;
  },
  [unsubscribe.fulfilled]: (state, action) => {
    state.subscribe.unsubscribe.response = action.payload;
    state.subscribe.unsubscribe.status = RS.IDLE;
    state.subscribe.unsubscribe.error = null;
  },
  [unsubscribe.rejected]: (state, action) => {
    state.subscribe.unsubscribe.response = null;
    state.subscribe.unsubscribe.status = RS.ERROR;
    state.subscribe.unsubscribe.error = action.payload;
  },
  [resubscribe.pending]: (state) => {
    state.subscribe.resubscribe.response = null;
    state.subscribe.resubscribe.status = RS.RUNNING;
    state.subscribe.resubscribe.error = null;
  },
  [resubscribe.fulfilled]: (state, action) => {
    state.subscribe.resubscribe.response = action.payload;
    state.subscribe.resubscribe.status = RS.IDLE;
    state.subscribe.resubscribe.error = null;
  },
  [resubscribe.rejected]: (state, action) => {
    state.subscribe.resubscribe.response = null;
    state.subscribe.resubscribe.status = RS.ERROR;
    state.subscribe.resubscribe.error = action.payload;
  },
};

export { unsubscribe, resubscribe, getUserInfo, subscribeSlice, initialSubscribeSlice };
