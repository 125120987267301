import { useSelector } from "react-redux";
import { MenuItem, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Avatar, makeStyles } from "ui";
import { formatDefaultDate } from "../../../helpers/date";
import planTypeImages from "../../../enums/planTypeImages";
import { capitalizeString } from "../../../helpers/string";
import { selectPlanExpirationDate, selectPlanType } from "../../../selectors/plan.selector";
import FormRowDisabledSelectInput from "../../../components/Forms/FormRowDisabledSelectInput";

function CurrentPlan() {
  const classes = useStyles();
  const { t } = useTranslation();

  const planType = useSelector(selectPlanType);
  const expirationDate = useSelector(selectPlanExpirationDate);

  return (
    <Box mb="19px">
      <FormRowDisabledSelectInput
        value=""
        labelGridWidth={5}
        id="current-plan-input"
        label={t("COMMON.PLAN")}
      >
        <MenuItem value="">
          <Box display="flex" alignItems="center" style={{ fontSize: 14 }}>
            <Avatar alt={planType} className={classes.avatar} src={planTypeImages[planType]} />
            <Box mr={1}>{capitalizeString(planType)}</Box>
            <Box component="span">
              <Box component="span">{t("CHANGE_PLAN.VALID_UNTIL")}</Box>
              <Box ml={1} component="span">
                {formatDefaultDate(expirationDate)}
              </Box>
            </Box>
          </Box>
        </MenuItem>
      </FormRowDisabledSelectInput>
    </Box>
  );
}

const useStyles = makeStyles({
  avatar: {
    width: 22,
    height: 22,
    marginLeft: 0,
    marginRight: 5,
  },
});

export default CurrentPlan;
