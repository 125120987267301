import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import useBool from "hooks/useBool";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
  useTheme,
} from "ui";
import InviteFromSignPlusTable, { Member } from "./InviteFromSignPlusTable";

interface InviteUsersInputProps {
  corporateMembers: Member[];
  emailsToInvite: string[];
  setEmailsToInvite: Dispatch<SetStateAction<string[]>>;
}

function InviteFromSignPlus({
  corporateMembers,
  emailsToInvite,
  setEmailsToInvite,
}: InviteUsersInputProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { alohi } = useTheme();

  const [isExpanded, isExpandedBool] = useBool(false);

  return (
    <Accordion expanded={isExpanded} onChange={isExpandedBool.toggle} className={classes.root}>
      <AccordionSummary
        className={classes.title}
        aria-controls="invite-from-fax-plus-content"
        expandIcon={<FontAwesomeIcon icon={faChevronDown} color={alohi.almostGray} />}
      >
        <Typography variant="anchor">{t("USERS.INVITE_FROM_ALOHI")}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <InviteFromSignPlusTable
          corporateMembers={corporateMembers}
          emailsToInvite={emailsToInvite}
          setEmailsToInvite={setEmailsToInvite}
        />
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "&&": {
      marginTop: spacing(2),
      marginBottom: spacing(2),
      boxShadow: "none",
      "&:before": {
        backgroundColor: "unset",
      },
    },
  },
  title: {
    "&&": {
      padding: spacing(0, 3),
    },
  },
  details: {
    "&&": {
      padding: 0,
      marginTop: spacing(2),
    },
  },
}));

export default memo(InviteFromSignPlus);
