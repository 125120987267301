import { useTranslation } from "react-i18next";
import { Box, Typography, makeStyles } from "@alohi/kit";

function EmptyPlaceholder() {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={styles.container}>
      <Typography>{t("API_TOKEN.NO_TOKEN_PLACEHOLDER")}</Typography>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
}));

export default EmptyPlaceholder;
