import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";

import { useInput } from "hooks/useInput";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { isValidPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "helpers/inputValidation";
import {
  selectIsChangePasswordRunning,
  selectIsChangePasswordSuccessful,
  selectChangePasswordErrorMessage,
} from "selectors/account.selector";
import {
  changePassword,
  getAccountDetails,
  clearChangePassword,
} from "stores/reducers/account.reducer";

function ChangePasswordModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isRunning = useSelector(selectIsChangePasswordRunning);
  const errorMessage = useSelector(selectChangePasswordErrorMessage);
  const isSuccessful = useSelector(selectIsChangePasswordSuccessful);

  const [newPassword, newPasswordInput] = useInput("", isValidPassword);
  const [oldPassword, oldPasswordInput] = useInput("", (value) => value.length > 0);
  const [retypeNewPassword, retypeNewPasswordInput] = useInput(
    "",
    (value) => value === newPassword,
  );

  const handleConfirm = useCallback(() => {
    oldPasswordInput.touch();
    newPasswordInput.touch();
    retypeNewPasswordInput.touch();

    if (oldPasswordInput.isValid && newPasswordInput.isValid && retypeNewPasswordInput.isValid) {
      if (oldPassword === newPassword) {
        enqueueSnackbar(t("FORMS.NOT_SAME_OLD_NEW_PASSWORD"), {
          variant: "error",
        });
      } else {
        dispatch(
          changePassword({
            oldPassword,
            newPassword,
          }),
        );
      }
    }
  }, [
    t,
    dispatch,
    newPassword,
    oldPassword,
    enqueueSnackbar,
    newPasswordInput,
    oldPasswordInput,
    retypeNewPasswordInput,
  ]);

  useEffect(() => {
    if (isSuccessful) {
      (async () => await dispatch(getAccountDetails()))();

      enqueueSnackbar(t("PROFILE.CHANGE_PASSWORD_SUCCESS"), {
        variant: "success",
      });
      dispatch(clearChangePassword());
      handleClosure();
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isSuccessful, t]);

  useEffect(() => {
    if (!!errorMessage) {
      enqueueSnackbar(errorMessage, { variant: "error" });
      dispatch(clearChangePassword());
    }
  }, [dispatch, enqueueSnackbar, errorMessage]);

  return (
    <Modal
      title={t("PROFILE.CHANGE_PASSWORD")}
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isConfirmDisabled={
        oldPasswordInput.showsError ||
        newPasswordInput.showsError ||
        retypeNewPasswordInput.showsError
      }
      isConfirmLoading={isRunning}
      isModalDisabled={isRunning}
    >
      <Box p={2} pr={"10%"} component="form">
        <FormRowTextInput
          required
          label={t("PROFILE.OLD_PASSWORD")}
          type="password"
          id="old-password-input"
          variant="outlined"
          value={oldPassword}
          onBlur={oldPasswordInput.onBlur}
          onFocus={oldPasswordInput.onFocus}
          error={oldPasswordInput.showsError}
          onChange={oldPasswordInput.onChange}
          helperText={oldPasswordInput.showsError ? t("FORMS.ENTER_A_PASSWORD") : " "}
        />
        <FormRowTextInput
          required
          label={t("PROFILE.NEW_PASSWORD")}
          type="password"
          id="new-password"
          variant="outlined"
          value={newPassword}
          onBlur={newPasswordInput.onBlur}
          onFocus={newPasswordInput.onFocus}
          error={newPasswordInput.showsError}
          onChange={newPasswordInput.onChange}
          helperText={
            newPasswordInput.showsError && !newPasswordInput.isValid
              ? newPassword.length > MAX_PASSWORD_LENGTH
                ? t("FORMS.PASSWORD_TOO_LONG", {
                    max: MAX_PASSWORD_LENGTH,
                  })
                : t("FORMS.PASSWORD_TOO_SHORT", {
                    min: MIN_PASSWORD_LENGTH,
                  })
              : t("FORMS.USE_NUMBERS_AND_SYMBOLS")
          }
        />
        <FormRowTextInput
          required
          label={t("PROFILE.CONFIRM_PASSWORD")}
          type="password"
          variant="outlined"
          id="confirm-new-password"
          value={retypeNewPassword}
          onBlur={retypeNewPasswordInput.onBlur}
          onFocus={retypeNewPasswordInput.onFocus}
          error={retypeNewPasswordInput.showsError}
          onChange={retypeNewPasswordInput.onChange}
          helperText={
            retypeNewPasswordInput.showsError && !retypeNewPasswordInput.isValid
              ? t("FORMS.MISMATCH_PASSWORD_HELPER")
              : " "
          }
        />
      </Box>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
