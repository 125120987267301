import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Divider, Button, Typography, Box } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

import { useTheme } from "ui";
import { websiteUrls } from "routes/urls";
import { PhoneNumberFilter } from "components/Core/Filters";
import SentStatusFilter from "components/Faxes/components/SentStatusFilter";
import {
  formatCdrDuration,
  formatFaxConfirmationTime,
  formatFaxConfirmationDate,
} from "helpers/date";
import FaxConfirmationLabel from "./FaxConfirmationLabel";

function ValidFaxConfirmation({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box mx={2}>
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <FontAwesomeIcon icon={faCheckCircle} size="5x" color={theme.alohi.green} />
      </Box>
      <Box my={4}>
        <Typography style={{ fontSize: "20px" }}>
          {t("CONFIRMATION.SCANNED_DOCUMENT_IS_VALID")}
        </Typography>
      </Box>
      <Divider />
      <Box mt={4}>
        <FaxConfirmationLabel
          label={t("CONFIRMATION.DATE")}
          value={formatFaxConfirmationDate(data.date, data.utc_offset)}
        />
        <FaxConfirmationLabel
          label={t("CONFIRMATION.TIME")}
          value={formatFaxConfirmationTime(data.date, data.utc_offset)}
        />
      </Box>
      <Box mt={4}>
        <FaxConfirmationLabel
          label={t("CONFIRMATION.FROM")}
          value={<PhoneNumberFilter number={data?.from ?? ""} />}
        />
        <FaxConfirmationLabel
          label={t("CONFIRMATION.TO")}
          value={<PhoneNumberFilter number={data?.to ?? ""} />}
        />
      </Box>
      <Box mt={4}>
        <FaxConfirmationLabel label={t("CONFIRMATION.PAGES_SENT")} value={data.pages} />
        <FaxConfirmationLabel
          label={t("CONFIRMATION.DURATION")}
          value={formatCdrDuration(data?.duration ?? "")}
        />
      </Box>
      <Box my={4}>
        <FaxConfirmationLabel
          label={t("CONFIRMATION.STATUS")}
          value={<SentStatusFilter value={data?.status ?? ""} />}
        />
      </Box>
      <Divider />
      <Box mx="auto" my={5}>
        <Button fullWidth href={websiteUrls.homepage}>
          {t("CONFIRMATION.GO_TO_HOMEPAGE")}
        </Button>
      </Box>
    </Box>
  );
}

ValidFaxConfirmation.propTypes = {
  data: PropTypes.object,
};

export default ValidFaxConfirmation;
