export const currencySymbols = {
  USD: "$",
  EUR: "€",
  CHF: "CHF",
  GBP: "£",
};

export const currencyNames = {
  eur: "Euro",
  usd: "US Dollar",
  chf: "Swiss Franc",
  gbp: "British Pound",
};
