import { useSnackbar } from "@alohi/kit";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  CustomerDocument,
  CustomerFragmentFragment,
  CustomerFragmentFragmentDoc,
  FaxPlusPlanSubscriptionItem,
  SignPlusPlanSubscriptionItem,
} from "api/gql/generated/graphql";
import { handleApiErrors } from "helpers/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectAccountCid } from "selectors/account.selector";
import { useAppSelector } from "stores/store";

export const useCustomer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const accountCid = useAppSelector(selectAccountCid);

  const { refetch: queryCustomer } = useQuery(CustomerDocument, {
    skip: true,
  });

  const handleQueryCustomer = async () => {
    try {
      await queryCustomer();
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        },
      });
    }
  };

  const client = useApolloClient();
  const data = client.readFragment<CustomerFragmentFragment>({
    id: `Customer:${accountCid}`,
    fragment: CustomerFragmentFragmentDoc,
    fragmentName: "CustomerFragment",
  });

  const activeSignSubscriptionWithPlan = (data?.subscriptions ?? [])
    .map((subscription) => {
      const planItem = subscription?.items?.find(
        (item): item is SignPlusPlanSubscriptionItem =>
          item?.__typename === "SignPlusPlanSubscriptionItem",
      );

      return planItem ? { ...subscription, planItem } : null;
    })
    .filter(Boolean)[0];

  const activeFaxSubscriptionWithPlan = (data?.subscriptions ?? [])
    .map((subscription) => {
      const planItem = subscription?.items?.find(
        (item): item is FaxPlusPlanSubscriptionItem =>
          item?.__typename === "FaxPlusPlanSubscriptionItem",
      );

      return planItem ? { ...subscription, planItem } : null;
    })
    .filter(Boolean)[0];

  const computed = useMemo(
    () => ({
      activeSignSubscriptionWithPlan,
      activeFaxSubscriptionWithPlan,
    }),
    [activeFaxSubscriptionWithPlan, activeSignSubscriptionWithPlan],
  );

  const customer = {
    ...({
      id: data?.id ?? "",
      subscriptions: data?.subscriptions ?? [],
      marketingData: {
        faxSignCrossSellBanner: data?.marketingData.faxSignCrossSellBanner ?? undefined,
      },
    } satisfies CustomerFragmentFragment),
    computed,
  };

  return {
    queryCustomer: handleQueryCustomer,
    customer,
  };
};
