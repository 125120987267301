import * as Sentry from "@sentry/react";
import { AsyncThunk, createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { areasApi, plansApi } from "api";
import { RootState } from "stores/store";
import RS, { FetchError, initialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";

type Plan = { is_default: boolean };

type PlanSelect = { label: string; options: Plan[] }[];

export interface PageOption {
  price: number;
  monthly_pages: number;
  marketing_discount: number;
}

type ListPageOptionsResponse = {
  page_options: PageOption[];
};

type PageOptionsIdResponse = {
  item_id: string;
};

type PageOptionsIdPayload = {
  pages: number;
  usCa: boolean;
  applyNow: boolean;
};

type ListPageOptionsPayload = {
  usCa: boolean;
};

const initialState = {
  cancelPlan: initialRequestState,
  resumePlan: initialRequestState,
  pageOptionsId: initialRequestState,
  listPageOptions: initialRequestState,
  currentPlan: initialRequestState as { response: unknown[] } & typeof initialRequestState,
  rechargePlan: initialRequestState,
  availablePlans: { ...initialRequestState, selected: null },
  availableAreas: { ...initialRequestState, selected: null },
  availableCountries: {
    ...(initialRequestState as {
      response: { [currency: string]: unknown };
    } & typeof initialRequestState),
    selected: null,
  },
};

export const clearNextPlan = createAction("PLANS/CLEAR_NEXT_PLAN");
export const clearCancelPlan = createAction("PLANS/CLEAR_CANCEL_PLAN");
export const clearResumePlan = createAction("PLANS/CLEAR_RESUME_PLAN");
export const clearRechargePlan = createAction("PLANS/CLEAR_RECHARGE_PLAN");
export const clearListPageOptions = createAction("PLANS/CLEAR_LIST_PAGE_OPTIONS");

export const setNextPlan = createAction(
  "PLANS/SET_NEXT_PLAN",
  (availablePlans: PlanSelect, toChange, nextPlanType = "") => {
    if (toChange) {
      if (nextPlanType) {
        return {
          payload: availablePlans.find((plan) => plan.label === nextPlanType)?.options[0] ?? {},
        };
      }
      return {
        payload: availablePlans,
      };
    }
    let nextBestPlan: Plan | undefined | Record<string, never>;
    if (nextPlanType) {
      nextBestPlan = availablePlans.reduce((accumulator, current) => {
        if (current.label === nextPlanType) {
          Object.assign(accumulator, current.options[0]);
        }
        return accumulator;
      }, {});
    } else {
      nextBestPlan = availablePlans
        .reduce((acc, curr) => {
          acc.push(curr.options);
          return acc;
        }, [] as Plan[][])
        .flat()
        .find((plan) => plan.is_default);
    }
    return {
      payload: nextBestPlan ?? {},
    };
  },
);

export const setCountryForPlan = createAction("PLANS/SET_COUNTRY_FOR_PLAN");
export const setAreaForCountry = createAction("PLANS/SET_AREA_FOR_COUNTRY");

export const clearCountryForPlan = createAction("PLANS/CLEAR_COUNTRY_FOR_PLAN");
export const clearAreaForCountry = createAction("PLANS/CLEAR_AREA_FOR_COUNTRY");

export const setPlanRechargeSuccess = createAction("PLANS/RECHARGE_PLAN/fulfilled");
export const setPlanRechargeError = createAction("PLANS/RECHARGE_PLAN/rejected");

export const getAvailablePlans = createAsyncThunk(
  "PLANS/GET_AVAILABLE_PLANS",
  async (purchaseId, { rejectWithValue }) => {
    try {
      const response = await plansApi.getAll({ purchaseId });
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const getAvailableCountries = createAsyncThunk<
  { response: unknown; currency: string },
  string
>(
  "PLANS/GET_AVAILABLE_COUNTRIES",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await plansApi.getAvailableCountries({ currency });
      return { response, currency };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
  {
    condition: (currency, { getState }) => {
      // Ideally use "RootState" but requires a refactor of the createSlice using the builder function
      // Otherwise there is a cyclic dependency of types
      const state = getState() as {
        plans: { availableCountries: { response: { [currency: string]: unknown } } };
      };
      return state.plans.availableCountries.response?.[currency] === undefined;
    },
  },
);

export const getAvailableAreas = createAsyncThunk(
  "PLANS/GET_AVAILABLE_AREAS",
  async (iso, { rejectWithValue }) => {
    try {
      const response = await areasApi.getAll({ iso });
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const getCurrentPlan = createAsyncThunk(
  "PLANS/GET_CURRENT_PLAN",
  async (_, { rejectWithValue }) => {
    try {
      const { plans: response } = await plansApi.get();
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const cancelCurrentPlan = createAsyncThunk<{ response: unknown }, string>(
  "PLANS/CANCEL_CURRENT_PLAN",
  async (planId, { rejectWithValue }) => {
    try {
      const response = await plansApi.cancelPlan(planId);
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const rechargePlan = createAsyncThunk(
  "PLANS/RECHARGE_PLAN",
  async (planId, { rejectWithValue }) => {
    try {
      const response = await plansApi.rechargePlan(planId);
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const resumePlan = createAsyncThunk(
  "PLANS/RESUME_PLAN",
  async (planId, { rejectWithValue }) => {
    try {
      const response = await plansApi.resumePlan(planId);
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const getListPageOptions: AsyncThunk<
  ListPageOptionsResponse,
  ListPageOptionsPayload,
  { state: RootState }
> = createAsyncThunk<ListPageOptionsResponse, ListPageOptionsPayload, { state: RootState }>(
  "PLANS/GET_LIST_PAGE_OPTIONS",
  async ({ usCa }, { getState, rejectWithValue }) => {
    try {
      const accountDetails = getState().account.accountDetails.response as { id: string };
      const uid = accountDetails.id;

      const response = await plansApi.listPageOptions({ uid, usCa });
      return response;
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
);

export const getPageOptionsId: AsyncThunk<
  PageOptionsIdResponse,
  PageOptionsIdPayload,
  { state: RootState }
> = createAsyncThunk<PageOptionsIdResponse, PageOptionsIdPayload, { state: RootState }>(
  "PLANS/GET_PAGE_OPTIONS_ID",
  async ({ usCa, applyNow, pages }, { getState, rejectWithValue }) => {
    try {
      const accountDetails = getState().account.accountDetails.response as { id: string };
      const uid = accountDetails.id;

      const response = await plansApi.getPageOptionsId({ uid, usCa, applyNow, pages });
      return response;
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
);

const { reducer } = createSlice({
  name: "PLANS",
  initialState,
  reducers: {},
  extraReducers: {
    [setNextPlan as never]: (state, action) => {
      const isEmpty = Object.keys(action.payload).length === 0;
      state.availablePlans.selected = isEmpty ? null : action.payload;
    },
    [clearNextPlan as never]: (state) => {
      state.availablePlans.response = null;
      state.availablePlans.status = RS.IDLE;
      state.availablePlans.error = null;
      state.availablePlans.selected = null;
    },
    [getAvailablePlans.pending as never]: (state) => {
      state.availablePlans.response = null;
      state.availablePlans.status = RS.RUNNING;
      state.availablePlans.error = null;
    },
    [getAvailablePlans.fulfilled as never]: (state, action) => {
      state.availablePlans.response = action.payload;
      state.availablePlans.status = RS.IDLE;
      state.availablePlans.error = null;
    },
    [getAvailablePlans.rejected as never]: (state, action) => {
      state.availablePlans.response = null;
      state.availablePlans.status = RS.ERROR;
      state.availablePlans.error = action.payload;
    },
    [setCountryForPlan as never]: (state, action) => {
      state.availableCountries.selected = action.payload;
    },
    [clearCountryForPlan as never]: (state) => {
      state.availableCountries.selected = null;
    },
    [getAvailableCountries.pending as never]: (state) => {
      state.availableCountries.status = RS.RUNNING;
      state.availableCountries.error = null;
    },
    [getAvailableCountries.fulfilled as never]: (state, { payload }) => {
      if (!state.availableCountries.response) {
        state.availableCountries.response = {};
      }
      state.availableCountries.response[payload.currency] = payload.response;
      state.availableCountries.status = RS.IDLE;
      state.availableCountries.error = null;
    },
    [getAvailableCountries.rejected as never]: (state, action) => {
      state.availableCountries.status = RS.ERROR;
      state.availableCountries.error = action.payload;
    },
    [setAreaForCountry as never]: (state, action) => {
      state.availableAreas.selected = action.payload;
    },
    [clearAreaForCountry as never]: (state) => {
      state.availableAreas.selected = null;
    },
    [getAvailableAreas.pending as never]: (state) => {
      state.availableAreas.response = null;
      state.availableAreas.status = RS.RUNNING;
      state.availableAreas.error = null;
    },
    [getAvailableAreas.fulfilled as never]: (state, action) => {
      state.availableAreas.response = action.payload;
      state.availableAreas.status = RS.IDLE;
      state.availableAreas.error = null;
    },
    [getAvailableAreas.rejected as never]: (state, action) => {
      state.availableAreas.response = null;
      state.availableAreas.status = RS.ERROR;
      state.availableAreas.error = action.payload;
    },
    [getCurrentPlan.pending as never]: (state) => {
      // Don't replace response to avoid flicker
      state.currentPlan.status = RS.RUNNING;
      state.currentPlan.error = null;
    },
    [getCurrentPlan.fulfilled as never]: (state, action) => {
      state.currentPlan.response = action.payload;
      state.currentPlan.status = RS.IDLE;
      state.currentPlan.error = null;
      const plans = state.currentPlan.response as { plan_type: string }[];
      const planType = plans?.[0]?.plan_type;
      if (planType) {
        Sentry.setTag("Plan", planType);
      }
    },
    [getCurrentPlan.rejected as never]: (state, action) => {
      // Don't replace response to avoid flicker
      state.currentPlan.status = RS.ERROR;
      state.currentPlan.error = action.payload;
      Sentry.setTag("Plan", "None");
    },
    [cancelCurrentPlan.pending as never]: (state) => {
      state.cancelPlan.response = null;
      state.cancelPlan.status = RS.RUNNING;
      state.cancelPlan.error = null;
    },
    [clearCancelPlan as never]: (state) => {
      state.cancelPlan = initialRequestState;
    },
    [cancelCurrentPlan.fulfilled as never]: (state, action) => {
      // Action.payload is getCurrentPlan response, manually set it to avoid 1 API call
      state.currentPlan.response[0] = action.payload;
      state.cancelPlan.response = action.payload;
      state.cancelPlan.status = RS.IDLE;
      state.cancelPlan.error = null;
    },
    [cancelCurrentPlan.rejected as never]: (state, action) => {
      state.cancelPlan.response = null;
      state.cancelPlan.status = RS.ERROR;
      state.cancelPlan.error = action.payload;
    },
    [clearResumePlan as never]: (state) => {
      state.resumePlan = initialRequestState;
    },
    [resumePlan.pending as never]: (state) => {
      state.resumePlan.response = null;
      state.resumePlan.status = RS.RUNNING;
      state.resumePlan.error = null;
    },
    [resumePlan.fulfilled as never]: (state, action) => {
      // Action.payload is getCurrentPlan response, manually set it to avoid 1 API call
      state.currentPlan.response[0] = action.payload;
      state.resumePlan.response = action.payload;
      state.resumePlan.status = RS.IDLE;
      state.resumePlan.error = null;
    },
    [resumePlan.rejected as never]: (state, action) => {
      state.resumePlan.response = null;
      state.resumePlan.status = RS.ERROR;
      state.resumePlan.error = action.payload;
    },
    [clearRechargePlan as never]: (state) => {
      state.rechargePlan = initialRequestState;
    },
    [rechargePlan.pending as never]: (state) => {
      state.rechargePlan.response = null;
      state.rechargePlan.status = RS.RUNNING;
      state.rechargePlan.error = null;
    },
    [rechargePlan.fulfilled as never]: (state, action) => {
      state.rechargePlan.response = action.payload;
      state.rechargePlan.status = RS.IDLE;
      state.rechargePlan.error = null;
    },
    [rechargePlan.rejected as never]: (state, action) => {
      state.rechargePlan.response = null;
      state.rechargePlan.status = RS.ERROR;
      state.rechargePlan.error = action.payload;
    },
    [clearListPageOptions as never]: (state) => {
      state.listPageOptions.response = null;
      state.listPageOptions.status = RS.IDLE;
      state.listPageOptions.error = null;
    },
    [getListPageOptions.pending as never]: (state) => {
      state.listPageOptions.response = null;
      state.listPageOptions.status = RS.RUNNING;
      state.listPageOptions.error = null;
    },
    [getListPageOptions.fulfilled as never]: (state, action) => {
      if (action.payload?.page_options) {
        state.listPageOptions.response = action.payload.page_options;
      }
      state.listPageOptions.status = RS.IDLE;
      state.listPageOptions.error = null;
    },
    [getListPageOptions.rejected as never]: (state, action) => {
      state.listPageOptions.response = null;
      state.listPageOptions.status = RS.ERROR;
      state.listPageOptions.error = action.payload;
    },
    [getPageOptionsId.pending as never]: (state) => {
      state.pageOptionsId.response = null;
      state.pageOptionsId.status = RS.RUNNING;
      state.pageOptionsId.error = null;
    },
    [getPageOptionsId.fulfilled as never]: (state, action) => {
      if (action.payload?.item_id) {
        state.pageOptionsId.response = action.payload.item_id;
      }
      state.pageOptionsId.status = RS.IDLE;
      state.pageOptionsId.error = null;
    },
    [getPageOptionsId.rejected as never]: (state, action) => {
      state.pageOptionsId.response = null;
      state.pageOptionsId.status = RS.ERROR;
      state.pageOptionsId.error = action.payload;
    },
    [logout.fulfilled as never]: () => initialState,
  },
});

export default reducer;
