import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { creditCardsApi } from "api";
import RS from "enums/requestStatus";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialPaymentMethodsState = {
  paymentMethods: {
    ...initialRequestState,
    selected: null,
    newProvider: null,
  },
  defaultPaymentMethod: initialRequestState,
};

export const setPaymentMethod = createAction("PAYMENT/SET_PAYMENT_METHOD");
export const clearPaymentMethods = createAction("PAYMENT/CLEAR_PAYMENT_METHOD");
export const clearSelectedPaymentMethod = createAction("PAYMENT/CLEAR_SELECTED_PAYMENT_METHOD");

export const setNewPaymentProvider = createAction("PAYMENT/SET_NEW_PAYMENT_PROVIDER");
export const clearNewPaymentProvider = createAction("PAYMENT/CLEAR_NEW_PAYMENT_PROVIDER");

export const clearDefaultPaymentMethod = createAction("PAYMENT/CLEAR_GET_DEFAULT_PAYMENT_METHOD");

const getPaymentMethods = createAsyncThunk(
  "PAYMENT/GET_PAYMENT_METHODS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await creditCardsApi.getPaymentMethods();
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getDefaultPaymentMethod = createAsyncThunk(
  "PAYMENT/GET_DEFAULT_PAYMENT_METHOD",
  async (_, api) => {
    try {
      const response = await creditCardsApi.getDefaultPaymentMethod();
      return response;
    } catch (error) {
      return api.rejectWithValue(error.origin);
    }
  },
);

const paymentMethodsSlice = {
  [setPaymentMethod]: (state, action) => {
    state.paymentMethods.selected = action.payload;
  },
  [setNewPaymentProvider]: (state, action) => {
    state.paymentMethods.newProvider = action.payload;
  },
  [clearNewPaymentProvider]: (state) => {
    state.paymentMethods.newProvider = null;
  },
  [clearSelectedPaymentMethod]: (state) => {
    state.paymentMethods.selected = null;
  },
  [clearPaymentMethods]: (state) => {
    state.paymentMethods.response = null;
    state.paymentMethods.status = RS.IDLE;
    state.paymentMethods.error = null;
    state.paymentMethods.selected = null;
  },
  [getPaymentMethods.pending]: (state) => {
    state.paymentMethods.status = RS.RUNNING;
    state.paymentMethods.error = null;
    state.paymentMethods.selected = null;
  },
  [getPaymentMethods.fulfilled]: (state, action) => {
    state.paymentMethods.response = action.payload;
    state.paymentMethods.status = RS.IDLE;
    state.paymentMethods.error = null;
    state.paymentMethods.selected = null;
  },
  [getPaymentMethods.rejected]: (state, action) => {
    state.paymentMethods.response = null;
    state.paymentMethods.status = RS.ERROR;
    state.paymentMethods.error = action.payload;
    state.paymentMethods.selected = null;
  },
  [getDefaultPaymentMethod.pending]: (state) => {
    state.defaultPaymentMethod.status = RS.RUNNING;
    state.defaultPaymentMethod.error = null;
  },
  [getDefaultPaymentMethod.fulfilled]: (state, action) => {
    state.defaultPaymentMethod.response = action.payload;
    state.defaultPaymentMethod.status = RS.IDLE;
    state.defaultPaymentMethod.error = null;
  },
  [getDefaultPaymentMethod.rejected]: (state, action) => {
    state.defaultPaymentMethod.status = RS.ERROR;
    state.defaultPaymentMethod.error = action.payload;
  },
};

export {
  getPaymentMethods,
  paymentMethodsSlice,
  getDefaultPaymentMethod,
  initialPaymentMethodsState,
};
