import PropTypes from "prop-types";
import { useCallback } from "react";
import { Box, Modal } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { paymentProviders } from "enums/payment";
import { triggerStripePayment } from "stores/reducers/payment.creditCard.reducer";
import AddPaymentMethodComponent from "components/Payment/AddPaymentMethodComponent";
import {
  selectNewPaymentProvider,
  selectIsCreditCardCompleted,
  selectIsPaymentProcessLoading,
} from "selectors/payment.selector";

function AddPaymentMethodModal({ handleClosure, initialTab }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoading = useSelector(selectIsPaymentProcessLoading);
  const newPaymentProvider = useSelector(selectNewPaymentProvider);
  const isCreditCardCompleted = useSelector(selectIsCreditCardCompleted);

  const handleOnConfirm = useCallback(() => {
    dispatch(triggerStripePayment());
  }, [dispatch]);

  return (
    <Modal
      hasCloseIcon={false}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      cancelTitle={t("COMMON.BACK")}
      data-cy={"AddPaymentMethodModal"}
      onCancel={() => handleClosure(null)}
      title={t("ADD_CREDIT.ADD_PAYMENT_METHOD")}
      isConfirmDisabled={!isCreditCardCompleted}
      confirmTitle={t("ADD_CREDIT.ADD_CREDIT_CARD")}
      onConfirm={newPaymentProvider === paymentProviders.STRIPE && handleOnConfirm}
    >
      <Box mb={1}>
        <AddPaymentMethodComponent handleClosure={handleClosure} initialTab={initialTab} />
      </Box>
    </Modal>
  );
}

AddPaymentMethodModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  initialTab: PropTypes.string,
};

export default AddPaymentMethodModal;
