import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, TextButton } from "@alohi/kit";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { selectAccountIsCorporateMember } from "selectors/account.selector";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import { getUserDataResidencySettings } from "stores/reducers/account.dataResidency.reducer";

const VIEWS = {
  NONE: "none",
  UPGRADE: "upgrade",
  CORPORATE_MEMBER_VIEW: "corporateMemberView",
};

function ActivateDataResidencyButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const [currentView, setCurrentView] = useState(VIEWS.NONE);

  const handleAction = useCallback(() => {
    if (isCorporateMember) {
      setCurrentView(VIEWS.CORPORATE_MEMBER_VIEW);
    } else {
      setCurrentView(VIEWS.UPGRADE);
    }
  }, [isCorporateMember]);

  const handleUpgradePlan = useCallback(
    (isSuccess) => {
      if (isSuccess) {
        dispatch(getUserDataResidencySettings());
      }
      setCurrentView(VIEWS.NONE);
    },
    [dispatch],
  );

  return (
    <>
      <TextButton onClick={handleAction}>
        <Typography type="panelFrontHeader">{t("PROFILE.DATA_RESIDENCY_EDIT")}</Typography>
        <Box sx={{ ml: 1, color: "white" }}>
          <FontAwesomeIcon icon={faPenToSquare} color="white" />
        </Box>
      </TextButton>
      {
        {
          [VIEWS.NONE]: null,
          [VIEWS.UPGRADE]: (
            <RequireUpgradeToEnterprise
              handleClosure={handleUpgradePlan}
              title={t("PROFILE.DATA_RESIDENCY_TITLE")}
              featureName={t("PROFILE.DATA_RESIDENCY_UPGRADE_FEATURE")}
            />
          ),
          [VIEWS.CORPORATE_MEMBER_VIEW]: (
            <ContactAdminToUseFeature
              handleClosure={() => setCurrentView(VIEWS.NONE)}
              title={t("PROFILE.DATA_RESIDENCY_TITLE")}
              description={t("PROFILE.DATA_RESIDENCY_CORPORATE_MEMBER_ACTIVATE")}
            />
          ),
        }[currentView]
      }
    </>
  );
}

export default ActivateDataResidencyButton;
