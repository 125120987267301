import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@alohi/kit";

import GoogleDriveLogo from "assets/images/google-drive.png";
import { requestGoogleDriveAuthorization } from "helpers/google";

interface RequireGoogleDriveAuthorizationModalProps {
  fileIds: string[] | null;
  workspaceFileIds: string[] | null;
  resourceKeys: string[] | null;
  userId: string | null;
}

function RequireGoogleDriveAuthorizationModal({
  fileIds,
  workspaceFileIds,
  userId,
  resourceKeys,
}: RequireGoogleDriveAuthorizationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      maxWidth={"xs"}
      confirmTitle={t("SENT_FAX.AUTHORIZE_GOOGLE_DRIVE_AUTHORIZE")}
      onConfirm={() => {
        requestGoogleDriveAuthorization({
          fileIds,
          userId,
          workspaceFileIds,
          resourceKeys,
        });
      }}
      title={t("SENT_FAX.AUTHORIZE_GOOGLE_DRIVE_TITLE")}
    >
      <Box p={4}>
        <Box display="flex" justifyContent="center" mb={1}>
          <img src={GoogleDriveLogo} alt="Google Drive" />
        </Box>
        <Typography type="body">{t("SENT_FAX.AUTHORIZE_GOOGLE_DRIVE_DESCRIPTION")}</Typography>
      </Box>
    </Modal>
  );
}

export default RequireGoogleDriveAuthorizationModal;
