import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { formatDefaultDate } from "helpers/date";
import InfiniTable from "components/InfiniTable/InfiniTable";
import { bundleStatuses, documentUserTypes } from "enums/bundle";
import CountryLabel from "./components/CountryLabel";

function RegulatoryDocumentsList({ documentStatus, documentData }) {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: "id",
        options: {
          display: false,
        },
      },
      {
        name: "address_linked",
        label: t("PROFILE.REGULATORY_COMPLIANCE_COUNTRY"),
        options: {
          customBodyRender: (addressId) => <CountryLabel addressId={addressId} />,
        },
      },
      {
        name: "end_user_type",
        label: t("PROFILE.REGULATORY_COMPLIANCE_USER_TYPE"),
        options: {
          customBodyRender: (type) => documentUserTypes[type] ?? "",
        },
      },
      {
        name: "date_updated",
        label: t("PROFILE.REGULATORY_COMPLIANCE_DATE"),
        options: {
          customBodyRender: (value) => formatDefaultDate(value),
        },
      },
    ],
    [t],
  );

  const noDocumentText = useMemo(() => {
    switch (documentStatus) {
      case bundleStatuses.pendingReview:
        return t("PROFILE.REGULATORY_COMPLIANCE_NO_PENDING");
      case bundleStatuses.twilioApproved:
        return t("PROFILE.REGULATORY_COMPLIANCE_NO_APPROVED");
      case bundleStatuses.twilioRejected:
        return t("PROFILE.REGULATORY_COMPLIANCE_NO_REJECTED");
      default:
    }
  }, [documentStatus, t]);

  const options = {
    sort: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    textLabels: {
      body: {
        noMatch: noDocumentText,
      },
    },
  };

  return (
    <InfiniTable
      isShowTableNoData
      isTopBorderHidden
      columns={columns}
      options={options}
      data={documentData}
    />
  );
}

RegulatoryDocumentsList.propTypes = {
  documentData: PropTypes.array,
  documentStatus: PropTypes.oneOf(Object.values(bundleStatuses)),
};

export default RegulatoryDocumentsList;
