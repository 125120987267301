import PropTypes from "prop-types";
import { TextField } from "@alohi/kit";
import FormRow from "./FormRow";

function FormRowTextInput({
  id,
  label,
  helperText,
  error,
  required,
  placeholder,
  type = "text",
  value,
  onChange,
  onBlur,
  onFocus,
  labelGridWidth = 4,
  disabled,
  multiline,
  rows,
  fullWidth = true,
  autoFocus = false,
  inputProps,
  endAdornment,
  name,
  "data-cy": dataCy,
}) {
  return (
    <FormRow
      labelGridWidth={labelGridWidth}
      htmlFor={id}
      label={label}
      error={error}
      helperText={helperText}
    >
      <TextField
        id={id}
        autoFocus={autoFocus}
        aria-describedby={`${id}-helper-text`}
        type={type}
        error={error}
        fullWidth={fullWidth}
        inputProps={inputProps}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        InputProps={{ endAdornment }}
        name={name}
        data-cy={dataCy}
      />
    </FormRow>
  );
}

FormRowTextInput.propTypes = {
  id: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.node,
  error: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  labelGridWidth: PropTypes.number,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  rows: PropTypes.number,
  inputProps: PropTypes.object,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  endAdornment: PropTypes.node,
  name: PropTypes.string,
  "data-cy": PropTypes.string,
};

export default FormRowTextInput;
