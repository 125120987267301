import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Divider, MenuItem, Box } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faFileUser,
  faFileInvoice,
  faChartNetwork,
} from "@fortawesome/pro-solid-svg-icons";

import { Typography, useTheme } from "ui";
import { useAppSelector } from "stores/store";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { selectCanISeeSharedCoverSheet } from "selectors/ability.selector";

interface PageOptionsProps {
  onSelect: (option: string) => void;
}

function PageOptions({ onSelect }: PageOptionsProps) {
  const { alohi } = useTheme();
  const { t } = useTranslation();

  const {
    sendFaxStore: { defaultCoverSheet, galleryCoverSheet, sharedCoverSheet, enforcedCoverSheet },
  } = useSendFaxContext();

  const canISeeSharedCoverSheet = useAppSelector(selectCanISeeSharedCoverSheet);

  const baseOptions = [
    t("SENT_FAX.RICH_TEXT_EDITOR"),
    "Divider",
    t("SENT_FAX.DEFAULT_COVER_SHEET"),
    "Divider",
    t("COVER_SHEET.GALLERY"),
  ] as const;

  const options = [
    ...baseOptions,
    ...(canISeeSharedCoverSheet ? (["Divider", t("SENT_FAX.SHARED_COVER_SHEET")] as const) : []),
  ] as const;

  const isOptionDisabled = useCallback(
    (type: string) => {
      switch (type) {
        case t("COVER_SHEET.GALLERY"):
        case t("SENT_FAX.SHARED_COVER_SHEET"):
        case t("SENT_FAX.DEFAULT_COVER_SHEET"):
          return (
            Boolean(defaultCoverSheet) ||
            Boolean(galleryCoverSheet) ||
            Boolean(sharedCoverSheet) ||
            Boolean(enforcedCoverSheet)
          );
        default:
          return false;
      }
    },
    [t, defaultCoverSheet, galleryCoverSheet, sharedCoverSheet, enforcedCoverSheet],
  );

  return (
    <>
      {options.map((option, index) => {
        if (option === "Divider") {
          return <Divider py={0.5} key={index} />;
        }

        return (
          <MenuItem
            key={option}
            onClick={() => onSelect(option)}
            disabled={isOptionDisabled(option)}
          >
            <Box width="15px" display="flex" justifyContent="center">
              {option === t("SENT_FAX.RICH_TEXT_EDITOR") ? (
                <FontAwesomeIcon icon={faFilePen} color={alohi.almostGray} />
              ) : null}

              {option === t("SENT_FAX.DEFAULT_COVER_SHEET") ? (
                <FontAwesomeIcon icon={faFileInvoice} color={alohi.almostGray} />
              ) : null}

              {option === t("COVER_SHEET.GALLERY") ? (
                <FontAwesomeIcon icon={faFileUser} color={alohi.almostGray} />
              ) : null}

              {option === t("SENT_FAX.SHARED_COVER_SHEET") ? (
                <FontAwesomeIcon icon={faChartNetwork} color={alohi.almostGray} />
              ) : null}
            </Box>

            <Typography sx={{ "&&": { ml: 1 } }}>{option}</Typography>
          </MenuItem>
        );
      })}
    </>
  );
}

export default PageOptions;
