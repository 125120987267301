import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const StripeBrandFilter = ({ brand }) => {
  const { t } = useTranslation();
  switch (brand?.toLowerCase()) {
    case "amex":
      return t("USAGE_PAYMENT.AMEX");

    case "visa":
      return t("USAGE_PAYMENT.VISA");

    default:
      return t("USAGE_PAYMENT.MASTER_CARD");
  }
};

export const PaymentMethodFilter = ({ via, amount, brand, last4, type }) => {
  const { t } = useTranslation();

  if (amount < 0 || type === "refund") {
    return t("USAGE_PAYMENT.REFUND");
  }

  switch (via?.toLowerCase()) {
    case "coupon":
      return t("USAGE_PAYMENT.COUPON");
    case "apple":
      return t("USAGE_PAYMENT.ITUNES");
    case "gift_card":
      return t("USAGE_PAYMENT.COUPON");
    case "offline":
      return t("USAGE_PAYMENT.OFFLINE");
    case "stripe":
      return (
        <Box>
          <StripeBrandFilter brand={brand} />
          <span>{` (${last4})`}</span>
        </Box>
      );
    case "referral":
      return t("REFERRAL.INVITE_GIFT");
    case "braintree":
      switch (brand?.toLowerCase()) {
        case "paypal":
          return t("ADD_CREDIT.PAYPAL_FILTER");
        default:
      }
      break;
    default:
  }
};

PaymentMethodFilter.propTypes = {
  amount: PropTypes.number,
  brand: PropTypes.string,
  children: PropTypes.string,
  last4: PropTypes.string,
  type: PropTypes.string,
  via: PropTypes.string,
};
