import { Box, Typography } from "@alohi/kit";

import { Loader } from "ui";

interface EmptyTableProps {
  text: string;
  isRunning?: boolean;
}

function EmptyTable({ text, isRunning }: EmptyTableProps) {
  if (isRunning) {
    return (
      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Loader />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={3}>
      <Typography type="body">{text}</Typography>
    </Box>
  );
}

export default EmptyTable;
