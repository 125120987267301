import { Tabs } from "@alohi/kit";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildings, faUsers } from "@fortawesome/pro-light-svg-icons";
import {
  faUsers as faUsersSolid,
  faBuildings as faBuildingsSolid,
} from "@fortawesome/pro-solid-svg-icons";

import Link from "routes/Link";
import { urls } from "routes/urls";
import { useTheme, useMediaQuery } from "ui";

function DashboardNavigation() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const theme = useTheme();
  const isVerticalOrientation = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Tabs value={pathname} orientation={isVerticalOrientation ? "vertical" : "horizontal"}>
      <Tabs.Tab
        component={Link}
        to={urls.dashboard.company}
        label={t("DASHBOARD.COMPANY_CONSUMPTION")}
        value={urls.dashboard.company}
        icon={
          <FontAwesomeIcon
            icon={pathname === urls.dashboard.company ? faBuildingsSolid : faBuildings}
            size="lg"
          />
        }
      />
      <Tabs.Tab
        component={Link}
        to={urls.dashboard.users}
        label={t("DASHBOARD.USERS_CONSUMPTION")}
        value={urls.dashboard.users}
        icon={
          <FontAwesomeIcon
            icon={pathname === urls.dashboard.users ? faUsersSolid : faUsers}
            size="lg"
          />
        }
      />
    </Tabs>
  );
}

export default DashboardNavigation;
