import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, makeStyles } from "@alohi/kit";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import i18n from "i18n/index";

function LanguageSwitcher() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const onLanguageClick = (event: MouseEvent<HTMLSpanElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <>
      <span onClick={onLanguageClick} className={classes.languageSwitcher}>
        {t("COMMON.LANGUAGE_NAME", { lng: i18n.language })} <FontAwesomeIcon icon={faCaretDown} />{" "}
      </span>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={() => {
          setMenuAnchor(null);
        }}
      >
        <MenuItem
          onClick={() => {
            i18n.changeLanguage("en")?.catch(() => undefined);
            setMenuAnchor(null);
          }}
        >
          {t("COMMON.LANGUAGE_NAME", { lng: "en" })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18n.changeLanguage("fr")?.catch(() => undefined);
            setMenuAnchor(null);
          }}
        >
          {t("COMMON.LANGUAGE_NAME", { lng: "fr" })}
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles({
  languageSwitcher: {
    "&:hover": { cursor: "pointer" },
  },
});

export default LanguageSwitcher;
