import createSelector from "selectorator";

import { ContactsSearchResponse } from "api/types/accounts";
import RS from "enums/requestStatus";
import { orderByNumber } from "helpers/common";
import i18n from "i18n/index";
import {
  transformContacts,
  transformGroups,
  transformRecent,
} from "stores/reducers/numbers.helpers";
import { RootState } from "stores/store";
import { MAX_UPLOAD_FILE_SIZE } from "views/SendFax/contexts/helpers";

export const selectIsFaxSent = (state: RootState): boolean =>
  Boolean(state.numbers.sendFax.response);

export const selectIsSendingFaxLoading = (state: RootState): boolean =>
  state.numbers.sendFax.status === RS.RUNNING;

export const selectSendFaxError = createSelector<RootState, string | null>(
  ["numbers.sendFax.status", "numbers.sendFax.error"],
  (status, error) => {
    if (status !== RS.ERROR) {
      return null;
    }

    if (error.status === 413) {
      return i18n.t("FORMS.LARGE_FILE", {
        replace: { size: MAX_UPLOAD_FILE_SIZE },
      });
    }

    switch (error?.reason) {
      case "plan_waiting_approval":
      case "number_waiting_verification":
        return i18n.t("SENT_FAX.PLAN_WAITING_APPROVAL");
      case "too_many_ongoing_faxes":
        return i18n.t("SENT_FAX.TOO_MANY_ONGOING_FAXES", {
          number: error?.data?.max_number_of_ongoing_faxes,
        });
      case "too_many_destinations":
        return i18n.t("SENT_FAX.MAX_RECIPIENTS_NUMBER", { count: 500 });
      case "no_destination_number":
        return i18n.t("SENT_FAX.DESTINATION_NUMBER_REQUIRED");
      case "Your file is not supported, please read the documentation and send an appropriate file.":
      case "invalid_file":
        return i18n.t("SENT_FAX.NOT_UPLOADED_FILE", { filename: error.filename });
      case "max_rate_exceeded_for_free_user":
        return i18n.t("SENT_FAX.MAX_RATE_EXCEEDED_FOR_FREE_USERS");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsSendFaxPlanInactiveError = createSelector<RootState, boolean>(
  ["numbers.sendFax.error"],
  (error) => {
    if (!error) {
      return false;
    }
    return error.reason === "plan_inactive";
  },
);

export const selectIsSendFaxAccountInactiveError = createSelector<RootState, boolean>(
  ["numbers.sendFax.error"],
  (error) => {
    if (!error) {
      return false;
    }
    return error.reason === "user_is_inactive";
  },
);

export const selectIsSendFaxCreditError = createSelector<RootState, boolean>(
  ["numbers.sendFax.error"],
  (error) => {
    if (!error) {
      return false;
    }
    return error.reason === "insufficient_credit";
  },
);

export const selectIsQuotaCorporateMemberReached = createSelector<RootState, boolean>(
  ["numbers.sendFax.error"],
  (error) => {
    if (!error) {
      return false;
    }
    return error.reason === "corporate_member_over_quota";
  },
);

export const selectCancelNumberError = createSelector<RootState, string>(
  ["numbers.cancelNumber.error"],
  (error) => error,
);

export const selectIsNumberCanceled = createSelector<RootState, boolean>(
  ["numbers.cancelNumber.response"],
  (response) => response,
);

export const selectIsNumberResumed = createSelector<RootState, boolean>(
  ["numbers.resumeNumber.response"],
  (response) => response,
);

export const selectIsNumberPatchLoading = createSelector<RootState, boolean>(
  ["numbers.patchNumber.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsNumberPatchError = createSelector<RootState, boolean>(
  ["numbers.patchNumber.status"],
  (status) => status === RS.ERROR,
);

export const selectIsNumberPatchSuccessful = createSelector<RootState, boolean>(
  ["numbers.patchNumber.response"],
  (response) => response,
);

export const selectAllNumbers = createSelector<RootState, [unknown]>(
  ["numbers.accountNumbers.response"],
  (response) => {
    return response?._embedded?.numbers.slice().sort(orderByNumber);
  },
);

export const selectAssignedNumbersFulfilled = createSelector(
  ["numbers.getAssignedNumbers.status", "numbers.getAssignedNumbers.response"],
  (status, response) => (status === RS.IDLE && Boolean(response)) || status === RS.ERROR,
);

export const selectAssignedNumbers = createSelector<
  RootState,
  {
    number: string;
    reception_enabled: boolean;
    id: string;
    status: string;
    friendly_name: string;
  }[]
>(["numbers.getAssignedNumbers.response"], (response) => {
  return response?.numbers?.slice().sort(orderByNumber) ?? null;
});

export const selectAllActiveNumbers = createSelector<RootState, { number: string }[]>(
  [selectAllNumbers],
  (accountNumbers) => {
    if (!accountNumbers) return null;
    return accountNumbers.filter((element: { status: string }) => element?.status === "active");
  },
);

export const selectDefaultNumber = createSelector([selectAllNumbers], (accountNumbers) => {
  if (!accountNumbers) return null;
  return (
    accountNumbers.find((element: { is_default: boolean }) => element.is_default)?.number ?? ""
  );
});

export const selectHasFreeNumber = createSelector([selectAssignedNumbers], (numbers) =>
  numbers?.length ? numbers[0]?.number.indexOf("number") > -1 : false,
);

export const selectOutboxSettingsByNumber = createSelector(
  ["numbers.outboxSettings.response"],
  (response) => {
    if (!response) {
      return null;
    }

    return response;
  },
);

export const selectIsReachedMinNumberOfNumbers = createSelector(
  [selectCancelNumberError],
  (error) => {
    if (!error) {
      return null;
    }
    return error.reason === "reached_minimum_number_of_numbers";
  },
);

export const selectCdrData = createSelector(["numbers.cdrData.response"], (response) => {
  if (!response) {
    return null;
  }
  return response;
});

export const selectIsCdrDataError = createSelector(
  ["numbers.cdrData.status"],
  (status) => status === RS.ERROR,
);

export const selectIsCdrDataLoading = createSelector(
  ["numbers.cdrData.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUpdateFriendlyNameSuccess = (state: RootState): boolean =>
  !!state.numbers.updateFriendlyName.response;

export const selectIsUpdateFriendlyNameRunning = (state: RootState): boolean =>
  state.numbers.updateFriendlyName.status === RS.RUNNING;

export const selectUpdateFriendlyNameError = createSelector(
  ["numbers.updateFriendlyName.error"],
  (error) => {
    if (!error) return null;

    switch (error.reason) {
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsUpdateFaxReceptionLoading = (state: RootState) =>
  state.numbers.updateFaxReception.status === RS.RUNNING;

export const selectIsUpdateFaxReceptionSuccess = (state: RootState) =>
  Boolean(state.numbers.updateFaxReception.response);

export const selectIndividualAssignedNumber = createSelector(
  [selectAssignedNumbers],
  (assignedNumbers) => {
    if (assignedNumbers?.[0]) {
      return assignedNumbers[0];
    }

    return null;
  },
);

export const selectIsIndividualPlanFaxReceptionEnabled = createSelector(
  [selectIndividualAssignedNumber],
  (assignedNumber) => {
    let isReceptionEnabled = true;

    if (assignedNumber) {
      isReceptionEnabled = assignedNumber.reception_enabled;
    }

    return isReceptionEnabled;
  },
);

export const selectEnvelopeMetadata = createSelector<
  unknown,
  { fileObjectUrl: string; fileName: string; mimeType: string }
>(["numbers.envelopeMetadata.response"], (response) => {
  if (!response) {
    return null;
  }

  return response;
});

export const selectEnvelopeMetadataError = createSelector(
  ["numbers.envelopeMetadata.status", "numbers.envelopeMetadata.error"],
  (status, error) => {
    if (status !== RS.ERROR) {
      return null;
    }

    if (error.reason === "Not authorized") {
      return i18n.t("FAXES.FILE_ON_DIFFERENT_ACCOUNT");
    }

    switch (error?.reason) {
      default:
        return i18n.t("FAXES.FILE_NOT_FOUND");
    }
  },
);

export const selectIsEnvelopeMetadataRunning = createSelector(
  ["numbers.envelopeMetadata.status"],
  (status) => status === RS.RUNNING,
);

export const selectUploadFilesIsRetrying = createSelector(
  ["numbers.uploadFiles.isRetrying"],
  (isRetrying) => isRetrying,
);

export const selectUploadFilesSessionId = createSelector(
  ["numbers.uploadFiles.sessionId"],
  (sessionId) => sessionId,
);

export const selectUploadFilesResponse = createSelector(
  ["numbers.uploadFiles.response"],
  (response) => response,
);

export const selectIsUploadFilesRunning = createSelector(
  ["numbers.uploadFiles.status"],
  (status) => status === RS.RUNNING,
);

export const selectUploadFilesError = createSelector(["numbers.uploadFiles.error"], (error) => {
  if (!error) {
    return "";
  }

  switch (error?.reason) {
    case "payloadTooLarge":
      return i18n.t("FORMS.LARGE_FILE", {
        replace: { size: MAX_UPLOAD_FILE_SIZE },
      });
    case "failedToUpload":
      return "Failed to upload some of the files";
    default:
      return i18n.t("COMMON.SERVER_ERROR");
  }
});

export const selectSearchedContacts = (response: ContactsSearchResponse) => {
  if (response) {
    return [
      {
        label: "recent_destinations",
        options: response.recent_destinations.map(transformRecent),
      },
      {
        label: "contacts",
        options: response.contacts.map(transformContacts),
      },
      {
        label: "tags",
        options: response.tags.map(transformGroups),
      },
    ];
  }

  return [];
};
