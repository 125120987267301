import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Panel, TextButton, Typography, TextField, Button, Box, useSnackbar } from "@alohi/kit";

import { Grid } from "ui";
import useBool from "hooks/useBool";
import { urlImage } from "helpers/common";
import { useInput } from "hooks/useInput";
import PhoneInput from "components/Forms/PhoneInput";
import CoreAvatar from "components/Core/Avatar/Avatar";
import { PhoneNumberFilter } from "components/Core/Filters";
import ChangeEmailModal from "views/Profile/modals/ChangeEmailModal";
import UploadProfileImageModal from "views/Image/UploadProfileImageModal";
import { getNotifications } from "stores/reducers/account.notifications.reducer";
import PhoneVerificationModal from "views/PhoneVerification/PhoneVerificationModal";
import { isValidAccountName, isValidEmail, isValidPhoneNumber } from "helpers/inputValidation";
import {
  selectAccountInfo,
  selectAccountIsCorporateMember,
  selectIsUpdateAccountDetailsRunning,
  selectIsUpdateAccountDetailsSuccessful,
  selectUpdateAccountDetailsErrorMessage,
} from "selectors/account.selector";
import {
  getAccountDetails,
  updateAccountDetails,
  clearUpdateAccountDetails,
} from "stores/reducers/account.reducer";

function MyInfoPanel() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const updateErrorMessage = useSelector(selectUpdateAccountDetailsErrorMessage);
  const isUpdateRunning = useSelector(selectIsUpdateAccountDetailsRunning);
  const isUpdateSuccessful = useSelector(selectIsUpdateAccountDetailsSuccessful);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const { profileImage, email, name, lastName, phone } = useSelector(selectAccountInfo);

  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState(profileImage);

  const [emailValue, emailInput] = useInput(email, isValidEmail);
  const [firstNameValue, firstNameInput] = useInput(name, isValidAccountName);
  const [lastNameValue, lastNameInput] = useInput(lastName, isValidAccountName);
  const [phoneValue, phoneInput] = useInput(
    phone,
    (newPhone) => newPhone === phone || isValidPhoneNumber(newPhone),
  );

  const setPhoneInput = phoneInput.setValue;
  const setEmailInput = emailInput.setValue;
  const setLastNameInput = lastNameInput.setValue;
  const setFirstNameInput = firstNameInput.setValue;

  const [editMode, setEditMode] = useState(false);
  const [openProfilePhoto, setOpenProfilePhoto] = useState(false);
  const [openPhoneVerification, setOpenPhoneVerification] = useState(false);
  const [isChangeEmailModalOpen, isChangeEmailModalOpenBool] = useBool(false);

  const isEmailChanged = emailValue !== email;

  const handleSave = useCallback(
    (isPhoneVerified) => {
      lastNameInput.touch();
      firstNameInput.touch();
      emailInput.touch();
      phoneInput.touch();

      if (!firstNameInput.isValid) {
        enqueueSnackbar(t("FORMS.FIRST_NAME_EMPTY"), { variant: "error" });
        return;
      }
      if (!lastNameInput.isValid) {
        enqueueSnackbar(t("FORMS.LAST_NAME_EMPTY"), { variant: "error" });
        return;
      }

      if (!emailInput.isValid) {
        enqueueSnackbar(t("FORMS.SELECT_ANOTHER_EMAIL"), { variant: "error" });
        return;
      }
      if (!phoneInput.isValid) {
        if (isCorporateMember) {
          phoneInput.setValue(phone);
          phoneInput.unTouch();
        } else {
          enqueueSnackbar(t("FORMS.INVALID_PHONE"), { variant: "error" });
          return;
        }
      }
      if (phoneInput.isValid && phoneValue !== phone && !(isPhoneVerified === true)) {
        setOpenPhoneVerification(true);
        return;
      }
      if (
        imageURL === profileImage &&
        emailValue === email &&
        firstNameValue === name &&
        lastNameValue === lastName
      ) {
        setEditMode(false);
        return;
      }

      dispatch(
        updateAccountDetails({
          updateType: "myInfo",
          accountData: {
            profileImage: imageURL,
            email: emailValue,
            lastName: lastNameValue,
            firstName: firstNameValue,
          },
        }),
      );
    },
    [
      t,
      name,
      email,
      phone,
      lastName,
      dispatch,
      imageURL,
      emailInput,
      phoneInput,
      emailValue,
      phoneValue,
      profileImage,
      lastNameValue,
      lastNameInput,
      firstNameInput,
      firstNameValue,
      enqueueSnackbar,
      isCorporateMember,
    ],
  );

  const handleCancel = useCallback(() => {
    setImage(urlImage(profileImage) || "");
    setImageURL(profileImage);
    setPhoneInput(phone);
    setEmailInput(email);
    setFirstNameInput(name);
    setLastNameInput(lastName);
    phoneInput.unTouch();
    setEditMode(false);
  }, [
    name,
    phone,
    email,
    lastName,
    phoneInput,
    profileImage,
    setPhoneInput,
    setEmailInput,
    setLastNameInput,
    setFirstNameInput,
  ]);

  const handleImageUpload = useCallback(({ url, base64 }) => {
    setImage(base64);
    setImageURL(url);
    setOpenProfilePhoto(false);
  }, []);

  const handleRemoveImage = useCallback(() => {
    setImage("");
    setImageURL("");
  }, []);

  const handlePhoneVerification = useCallback(
    (isVerified) => {
      setOpenPhoneVerification(false);
      if (isVerified === true) {
        handleSave(true);
      } else if (isVerified === false) {
        // It was not verified, set the current phone value to initial value
        setPhoneInput(phone);
        handleSave(true);
      }
    },
    [handleSave, phone, setPhoneInput],
  );

  function handleChangeEmail(isSuccess: boolean) {
    if (!isSuccess) {
      emailInput.setValue(email);
    } else {
      dispatch(getAccountDetails());
    }

    isChangeEmailModalOpenBool.setFalse();
    setEditMode(false);
  }

  useEffect(() => {
    if (updateErrorMessage) {
      enqueueSnackbar(updateErrorMessage, { variant: "error" });
      dispatch(clearUpdateAccountDetails());
    }
  }, [dispatch, emailInput, enqueueSnackbar, t, updateErrorMessage]);

  useEffect(() => {
    if (isUpdateSuccessful) {
      dispatch(getAccountDetails());
      dispatch(getNotifications());
      dispatch(clearUpdateAccountDetails());
      phoneInput.unTouch();
      setEditMode(false);

      if (isEmailChanged) {
        isChangeEmailModalOpenBool.setTrue();
      }
    }
  }, [dispatch, isChangeEmailModalOpenBool, isEmailChanged, isUpdateSuccessful, phoneInput]);

  useEffect(() => {
    // Reactive update, in case the image is updated from HeaderLinks
    setImage(urlImage(profileImage) || "");
  }, [profileImage, phone]);

  useEffect(() => {
    // Reactive update, in case the phone number is updated by updating the account data
    setPhoneInput(phone);
  }, [setPhoneInput, phone]);

  return (
    <>
      <Panel
        id="my-info"
        isFlipped={editMode}
        data-cy="ProfileMyInfo"
        headerTitle={t("PROFILE.BASIC_INFO")}
        sx={{ mb: 7 }}
      >
        <Panel.Front
          headerAction={
            <TextButton onClick={() => setEditMode(true)}>
              <Typography type="panelFrontHeader">{t("COMMON.EDIT")}</Typography>
              <Box sx={{ ml: 1, color: "white" }}>
                <FontAwesomeIcon icon={faPenToSquare} color="white" />
              </Box>
            </TextButton>
          }
        >
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.FIRST_NAME")}</Panel.Cell>
            <Panel.Cell variant="data">{firstNameValue ?? "-"}</Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.LAST_NAME")}</Panel.Cell>
            <Panel.Cell variant="data">{lastNameValue ?? "-"}</Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.EMAIL")}</Panel.Cell>
            <Panel.Cell variant="data">{emailValue ?? "-"}</Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.PHONE")}</Panel.Cell>
            <Panel.Cell variant="data">
              {phoneValue ? <PhoneNumberFilter number={phoneValue} /> : "-"}
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.PROFILE_PICTURE")}</Panel.Cell>
            <Panel.Cell variant="data">
              <CoreAvatar src={image} alt={firstNameValue} />
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>

        <Panel.Back
          onConfirm={() => handleSave(false)}
          onCancel={handleCancel}
          isConfirmLoading={isUpdateRunning}
          isPanelDisabled={isUpdateRunning}
          // Corporate members can always proceed
          isConfirmDisabled={
            emailInput.showsError ||
            lastNameInput.showsError ||
            firstNameInput.showsError ||
            (phoneInput.showsError && !isCorporateMember)
          }
        >
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.FIRST_NAME")}</Panel.Cell>
            <Panel.Cell variant="data">
              <TextField
                required
                id="firstName-input"
                value={firstNameValue}
                sx={{ minWidth: 200 }}
                onBlur={firstNameInput.onBlur}
                onFocus={firstNameInput.onFocus}
                error={firstNameInput.showsError}
                onChange={firstNameInput.onChange}
              />
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.LAST_NAME")}</Panel.Cell>
            <Panel.Cell variant="data">
              <TextField
                required
                id="lastName-input"
                value={lastNameValue}
                sx={{ minWidth: 200 }}
                onBlur={lastNameInput.onBlur}
                onFocus={lastNameInput.onFocus}
                error={lastNameInput.showsError}
                onChange={lastNameInput.onChange}
              />
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.EMAIL")}</Panel.Cell>
            <Panel.Cell variant="data">
              <TextField
                required
                id="email-input"
                value={emailValue}
                sx={{ minWidth: 200 }}
                onBlur={emailInput.onBlur}
                onFocus={emailInput.onFocus}
                error={emailInput.showsError}
                onChange={emailInput.onChange}
              />
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.PHONE")}</Panel.Cell>
            <Panel.Cell variant="data">
              <PhoneInput
                sx={{ minWidth: 200 }}
                value={phoneValue}
                onBlur={phoneInput.onBlur}
                onFocus={phoneInput.onFocus}
                error={phoneInput.showsError}
                onChange={phoneInput.onChange}
                placeholder={t("PROFILE.ENTER_PHONE_NUMBER")}
              />
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.PROFILE_PICTURE")}</Panel.Cell>
            <Panel.Cell variant="data">
              <CoreAvatar src={image} alt={firstNameValue} />
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <Grid container wrap="nowrap" justifyContent="flex-end">
                <Button variant="white" onClick={() => setOpenProfilePhoto(true)} sx={{ mr: 2 }}>
                  {t("PROFILE.UPLOAD")}
                </Button>
                <Button variant="white" onClick={handleRemoveImage}>
                  {t("PROFILE.REMOVE")}
                </Button>
              </Grid>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Back>
      </Panel>

      {openProfilePhoto ? (
        <UploadProfileImageModal initialImage={image} handleClosure={handleImageUpload} />
      ) : null}

      {openPhoneVerification ? (
        <PhoneVerificationModal
          defaultPhoneNumber={phoneValue}
          handleClosure={handlePhoneVerification}
        />
      ) : null}

      {isChangeEmailModalOpen ? (
        <ChangeEmailModal email={emailValue} handleClosure={handleChangeEmail} />
      ) : null}
    </>
  );
}

export default MyInfoPanel;
