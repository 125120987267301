export interface DatabaseFile {
  key: string;
  value: File;
}

class Database {
  private static instance: Database;
  private name: string;
  private version: number;
  private storeName: string;
  private database: null | IDBDatabase;

  static getInstance() {
    if (!Database.instance) {
      Database.instance = new Database();
    }

    return this.instance;
  }

  constructor() {
    this.version = 1;
    this.database = null;
    this.name = "database";
    this.storeName = "store";
  }

  private async open(): Promise<null | IDBDatabase> {
    return new Promise<null | IDBDatabase>((resolve, reject) => {
      try {
        const openRequest = window.indexedDB.open(this.name, this.version);

        openRequest.onerror = () => {
          reject(false);
        };
        openRequest.onblocked = () => {
          reject(false);
        };
        openRequest.onsuccess = () => {
          resolve(openRequest.result);
        };
        openRequest.onupgradeneeded = () => {
          const transaction = openRequest.result.createObjectStore(this.storeName);

          transaction.transaction.oncomplete = () => {
            resolve(openRequest.result);
          };
          transaction.transaction.onerror = () => {
            reject(false);
          };
        };
      } catch {
        reject(false);
      }
    });
  }

  private async ensureDatabaseIsOpen(): Promise<void> {
    if (this.database === null) {
      const database = await this.open();

      if (database) {
        this.database = database;
      }
    }
  }

  private async putObjectInStore(
    objectStore: IDBObjectStore,
    data: object, // Any object supported by the structured clone algorithm can be stored
    key: string,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const request = objectStore.put(data, key);

      request.onerror = () => {
        reject(false);
      };

      request.onsuccess = () => {
        resolve(true);
      };
    });
  }

  async putObject<T extends object>(key: string, value: T): Promise<boolean> {
    try {
      await this.ensureDatabaseIsOpen();

      if (!this.database) {
        return false;
      }

      const transaction = this.database.transaction(this.storeName, "readwrite");
      const objectStore = transaction.objectStore(this.storeName);

      await this.putObjectInStore(objectStore, value, key);

      return true;
    } catch {
      return false;
    }
  }

  async readAllFiles(): Promise<DatabaseFile[]> {
    const files: DatabaseFile[] = [];

    return new Promise(async (resolve, reject) => {
      try {
        await this.ensureDatabaseIsOpen();

        if (!this.database) {
          return false;
        }

        const transaction = this.database.transaction(this.storeName, "readonly");
        const objectStore = transaction.objectStore(this.storeName);
        const cursorRequest = objectStore.openCursor();

        cursorRequest.onerror = () => {
          reject(false);
        };

        cursorRequest.onsuccess = () => {
          const cursor = cursorRequest.result;

          if (cursor?.key && cursor?.value) {
            files.push({ key: cursor.key as string, value: cursor.value });
          }

          if (cursor) {
            cursor.continue();
          } else {
            resolve(files);
          }
        };
      } catch {
        reject(false);
      }
    });
  }

  async getFile<V, K>(key: K): Promise<{ key: K; value: V }> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.ensureDatabaseIsOpen();

        if (!this.database) {
          return false;
        }

        const transaction = this.database.transaction(this.storeName, "readonly");
        const objectStore = transaction.objectStore(this.storeName);
        const cursorRequest = objectStore.openCursor();

        cursorRequest.onerror = () => {
          reject(false);
        };

        cursorRequest.onsuccess = () => {
          const cursor = cursorRequest.result;

          if (cursor?.key && cursor?.value && cursor.key === key) {
            resolve({ key, value: cursor.value });
          }

          if (cursor) {
            cursor.continue();
          } else {
            reject(false);
          }
        };
      } catch {
        reject(false);
      }
    });
  }

  async removeFile(fileName: string): Promise<Event> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.ensureDatabaseIsOpen();

        if (!this.database) {
          return false;
        }

        const transaction = this.database.transaction(this.storeName, "readwrite");
        const objectStore = transaction.objectStore(this.storeName);
        const deleteRequest = objectStore.delete(fileName);

        deleteRequest.onerror = reject;
        deleteRequest.onsuccess = resolve;
      } catch {
        reject(false);
      }
    });
  }
}

export default Database.getInstance();
