import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { refreshToken } from "../stores/reducers/authentication.reducer";

function usePersistingSession() {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ready === false) {
      (async () => {
        await dispatch(refreshToken());
        setReady(true);
      })();
    }
  }, [dispatch, ready]);

  return ready;
}

export default usePersistingSession;
