import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Typography, Modal, Box } from "@alohi/kit";

import { makeStyles, Helper } from "ui";
import { boxNames } from "enums/faxes";
import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import { isValidNote } from "helpers/inputValidation";
import { selectCompanyName } from "selectors/account.selector";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";
import { selectCanIUseSharedNotes } from "selectors/ability.selector";
import { selectIsCdrFromCommonStorage } from "selectors/faxes.selector";

interface AddNoteOnFaxModalProps {
  cdrId: string;
  dataIndex: number;
  currentNote?: string;
  handleClosure: () => void;
  boxName: keyof typeof boxNames;
  currentSharedNote?: null | {
    text: string;
    name: string;
    date: string;
  };
}

function AddNoteOnFaxModal({
  cdrId,
  boxName,
  dataIndex,
  handleClosure,
  currentNote = "",
  currentSharedNote = null,
}: AddNoteOnFaxModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const companyName = useSelector(selectCompanyName);
  const canIUseSharedNoted = useSelector(selectCanIUseSharedNotes);
  const isCdrFromCommonStorage = useSelector(selectIsCdrFromCommonStorage(boxName, dataIndex));

  const [note, noteInput] = useInput(currentNote, isValidNote);
  const [sharedNote, sharedNoteInput] = useInput(currentSharedNote?.text ?? "", isValidNote);

  const handleConfirm = () => {
    noteInput.touch();
    sharedNoteInput.touch();

    if (noteInput.isValid && note !== currentNote) {
      setLoading(true);

      const updaterThunk = faxesAsyncActions.addNoteOnFax as (
        updatedObject: Record<string, unknown>,
      ) => ReturnType<typeof faxesAsyncActions.addNoteOnFax>;

      dispatch(updaterThunk({ cdrId, boxName, note: note }));
    }

    if (
      sharedNoteInput.isValid &&
      sharedNote !== currentSharedNote?.text &&
      boxName === boxNames.inbox
    ) {
      setLoading(true);

      const updaterThunk = faxesAsyncActions.addSharedNoteOnFax as (
        updatedObject: Record<string, unknown>,
      ) => ReturnType<typeof faxesAsyncActions.addSharedNoteOnFax>;

      dispatch(updaterThunk({ cdrId, boxName, note: sharedNote }));
    }

    if (noteInput.isValid || sharedNoteInput.isValid) {
      handleClosure();
    }
  };

  const isSharedNoteVisible =
    boxName === boxNames.inbox && canIUseSharedNoted && !isCdrFromCommonStorage;

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isConfirmLoading={loading}
      onPressEnter={handleConfirm}
      onPressEscape={handleClosure}
      isConfirmDisabled={noteInput.showsError}
      title={
        <Typography type="modalTitle">
          <Box display="flex" alignItems="center">
            <Box>{t("FAXES.ADD_NOTE")}</Box>
            <Box
              ml={1}
              component="a"
              target="_blank"
              rel="noreferrer"
              href={helpCenterUrls.addNoteFax}
            >
              <Helper size="lg" variant="light" />
            </Box>
          </Box>
        </Typography>
      }
    >
      <Box className={classes.personalNote}>
        <FormRowTextInput
          id="add-note"
          value={note}
          labelGridWidth={12}
          onBlur={noteInput.onBlur}
          onFocus={noteInput.onFocus}
          error={noteInput.showsError}
          onChange={noteInput.onChange}
          inputProps={{ maxLength: 100 }}
          label={<Box mb="5px">{t("FAXES.PERSONAL_NOTE")}</Box>}
        />
      </Box>

      {isSharedNoteVisible ? (
        <>
          <Divider />
          <Box className={classes.sharedNote}>
            <FormRowTextInput
              value={sharedNote}
              labelGridWidth={12}
              id="add-shared-note"
              inputProps={{ maxLength: 100 }}
              onBlur={sharedNoteInput.onBlur}
              onFocus={sharedNoteInput.onFocus}
              error={sharedNoteInput.showsError}
              onChange={sharedNoteInput.onChange}
              label={<Box mb="5px">{t("FAXES.SHARED_NOTE")}</Box>}
              helperText={
                <Box component="span" className={classes.sharedNoteHelperText}>
                  {currentSharedNote?.text
                    ? `${t("FAXES.LAST_MODIFIED_SHARED_NOTE", {
                        name: currentSharedNote?.name ?? "",
                      })} - ${currentSharedNote?.date ?? ""}`
                    : t("FAXES.SHARED_NOTE_VISIBLE_BY", {
                        company: companyName,
                      })}
                </Box>
              }
            />
          </Box>
        </>
      ) : null}
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  personalNote: {
    padding: spacing(3),
  },
  sharedNote: {
    padding: spacing(3),
  },
  sharedNoteHelperText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export default AddNoteOnFaxModal;
