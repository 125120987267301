import { createAction } from "@reduxjs/toolkit";

const initialCorporateState = {
  corporateUpgrade: {
    companyName: null,
    firstNumber: null,
    secondNumber: null,
  },
};

export const updateCorporateCompany = createAction("PAYMENT/UPDATE_CORPORATE_COMPANY");
export const updateCorporateFirstNumber = createAction("PAYMENT/UPDATE_CORPORATE_FIRST_NUMBER");
export const updateCorporateSecondNumber = createAction("PAYMENT/UPDATE_CORPORATE_SECOND_NUMBER");

export const clearCorporateUpgrade = createAction("PAYMENT/CLEAR_CORPORATE_UPGRADE");
export const clearFirstCorporateNumber = createAction("PAYMENT/CLEAR_FIRST_CORPORATE_NUMBER");
export const clearSecondCorporateNumber = createAction("PAYMENT/CLEAR_SECOND_CORPORATE_NUMBER");

const corporateSlice = {
  [clearCorporateUpgrade]: (state) => {
    state.corporateUpgrade.companyName = null;
    state.corporateUpgrade.firstNumber = null;
    state.corporateUpgrade.secondNumber = null;
  },
  [updateCorporateCompany]: (state, action) => {
    state.corporateUpgrade.companyName = action.payload;
  },
  [updateCorporateFirstNumber]: (state, action) => {
    state.corporateUpgrade.firstNumber = action.payload;
  },
  [updateCorporateSecondNumber]: (state, action) => {
    state.corporateUpgrade.secondNumber = action.payload;
  },
  [clearFirstCorporateNumber]: (state) => {
    state.corporateUpgrade.firstNumber = null;
  },
  [clearSecondCorporateNumber]: (state) => {
    state.corporateUpgrade.secondNumber = null;
  },
};

export { initialCorporateState, corporateSlice };
