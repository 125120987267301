import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { depositApi } from "api";

import RS from "enums/requestStatus";
import { orderByIssueDate } from "helpers/date";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialPaymentHistoryState = {
  paymentHistory: {
    ...initialRequestState,
    hasMore: false,
  },
};

export const clearPaymentHistory = createAction("PAYMENT/CLEAR_PAYMENT_HISTORY");

const getPaymentHistory = createAsyncThunk(
  "PAYMENT/GET_PAYMENT_HISTORY",
  async ({ before }, { rejectWithValue }) => {
    try {
      const limit = 20;
      const response = await depositApi.getAll({ limit, before });
      return { response, before };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const paymentHistorySlice = {
  [clearPaymentHistory]: (state) => {
    state.paymentHistory.response = null;
    state.paymentHistory.status = RS.IDLE;
    state.paymentHistory.error = null;
    state.paymentHistory.hasMore = false;
  },
  [getPaymentHistory.pending]: (state) => {
    state.paymentHistory.status = RS.RUNNING;
    state.paymentHistory.error = null;
  },
  [getPaymentHistory.fulfilled]: (state, action) => {
    const {
      payload: { response, before },
    } = action;
    if (state?.paymentHistory?.response?.length && before) {
      state.paymentHistory.response.push(...response.result);
    } else if (response?.result) {
      response.result.sort(orderByIssueDate);
      state.paymentHistory.response = response.result;
    }
    state.paymentHistory.status = response?.result ? RS.IDLE : RS.NO_CONTENT;
    state.paymentHistory.error = null;
    state.paymentHistory.hasMore = response?.has_more || false;
  },
  [getPaymentHistory.rejected]: (state, action) => {
    state.paymentHistory.response = null;
    state.paymentHistory.status = RS.ERROR;
    state.paymentHistory.error = action.payload;
    state.paymentHistory.hasMore = false;
  },
};

export { initialPaymentHistoryState, paymentHistorySlice, getPaymentHistory };
