import { Redirect, useLocation } from "react-router";
import { filterQueryParams } from "./urls";

function LoginRedirect() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return (
    <Redirect
      to={{
        pathname: "/login",
        search: filterQueryParams({
          filter: ["_gl"],
          currentParams: queryParams,
        }).toString(),
      }}
    />
  );
}

export default LoginRedirect;
