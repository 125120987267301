import { useCallback } from "react";
import { ActionMap } from "helpers/types";

import { PhoneVerificationStore, usePhoneVerificationContext } from "./context";

export type PhoneVerificationActionPayloads = {
  UPDATE: Partial<PhoneVerificationStore>;
};

export type PhoneVerificationAction =
  ActionMap<PhoneVerificationActionPayloads>[keyof ActionMap<PhoneVerificationActionPayloads>];

export function phoneVerificationReducer(
  store: PhoneVerificationStore,
  action: PhoneVerificationAction,
): PhoneVerificationStore {
  switch (action.type) {
    case "UPDATE": {
      return {
        ...store,
        ...action.payload,
      };
    }

    default:
      return store;
  }
}

export function usePhoneVerificationApi() {
  const { phoneVerificationDispatch, phoneVerificationStore } = usePhoneVerificationContext();

  const updateStore = useCallback(
    (payload: Partial<PhoneVerificationStore>) => {
      phoneVerificationDispatch({
        type: "UPDATE",
        payload: payload,
      });
    },
    [phoneVerificationDispatch],
  );

  return {
    store: phoneVerificationStore,
    updateStore,
  };
}
