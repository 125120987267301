import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { accountsApi } from "api";
import { RootState } from "stores/store";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";
import {
  Group,
  GroupResponse,
  transformGroups,
  GetGroupsResponse,
  CreateGroupResponse,
  UpdateGroupResponse,
  revertTransformGroups,
} from "./contacts.helpers";

const defaultRequestOptions = {
  limit: 20,
  offset: 0,
  search: "",
  sortBy: "",
  type: "json",
  shared: true,
  direction: "",
};

const initialState = {
  getSharedGroups: InitialRequestState(),
  updateSharedGroup: InitialRequestState(),
  createSharedGroup: InitialRequestState(),
  deleteSharedGroup: InitialRequestState(),
  getMoreSharedGroups: InitialRequestState(),

  hasMore: false,
  selection: [] as string[],
  groups: null as null | Group[],
  lastRequestOptions: defaultRequestOptions,
};

const getSharedGroups = createAsyncThunk<
  GetGroupsResponse,
  Partial<typeof defaultRequestOptions>,
  { state: RootState; rejectValue: FetchError }
>(
  "SHARED_GROUPS/GET",
  async (requestOptionsOverrides, { getState, rejectWithValue }) => {
    try {
      const lastRequestOptions = getState().sharedGroups.lastRequestOptions;
      const requestOptions = { ...lastRequestOptions, ...requestOptionsOverrides };

      const response = await accountsApi.getGroups(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().sharedGroups.getSharedGroups.status !== RS.RUNNING;
    },
  },
);

const getMoreSharedGroups = createAsyncThunk<
  GetGroupsResponse,
  undefined,
  { state: RootState; rejectValue: FetchError }
>(
  "SHARED_GROUPS/GET_MORE",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { groups, lastRequestOptions } = getState().sharedGroups;

      const requestOptions = {
        ...lastRequestOptions,
        offset: groups?.length ?? 0,
      };

      const response = await accountsApi.getGroups(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().sharedGroups.getMoreSharedGroups.status !== RS.RUNNING;
    },
  },
);

const createSharedGroup = createAsyncThunk<
  CreateGroupResponse,
  { newGroup: GroupResponse },
  { state: RootState; rejectValue: FetchError }
>("SHARED_GROUPS/CREATE", async ({ newGroup }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.createGroup(revertTransformGroups(newGroup), true);
    return { response };
  } catch (error) {
    const errorValue = error as FetchError;
    Object.assign(errorValue, { newGroup });
    return rejectWithValue(errorValue);
  }
});

const updateSharedGroup = createAsyncThunk<
  UpdateGroupResponse,
  { groupDiffs: Partial<GroupResponse> },
  { state: RootState; rejectValue: FetchError }
>("SHARED_GROUPS/UPDATE", async ({ groupDiffs }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.updateGroup(revertTransformGroups(groupDiffs));
    return { response, groupDiffs };
  } catch (error) {
    const errorValue = error as FetchError;
    Object.assign(errorValue, { groupDiffs });
    return rejectWithValue(errorValue);
  }
});

const deleteSharedGroup = createAsyncThunk<
  { idsToDelete: string[]; response: Record<string, never> },
  { idsToDelete: string[] },
  { state: RootState; rejectValue: FetchError }
>("SHARED_GROUPS/DELETE", async ({ idsToDelete }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.deleteGroupById(idsToDelete);
    return { response, idsToDelete };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const clearSharedGroups = createAction("SHARED_GROUPS/CLEAR");
export const clearUpdateSharedGroup = createAction("SHARED_GROUPS/CLEAR_UPDATE");
export const clearCreateSharedGroup = createAction("SHARED_GROUPS/CLEAR_CREATE");
export const clearFetchedSharedGroups = createAction("GROUPS/CLEAR_FETCHED_SHARED_GROUPS");
export const updateSharedGroupSelection = createAction<string[]>("SHARED_GROUPS/UPDATE_SELECTION");

const { reducer } = createSlice({
  name: "SHARED_GROUPS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSharedGroupSelection, (state, { payload }) => {
      state.selection = payload;
    });
    builder.addCase(getSharedGroups.pending, (state) => {
      state.getSharedGroups.status = RS.RUNNING;
      state.getSharedGroups.error = null;
    });
    builder.addCase(getSharedGroups.fulfilled, (state, { payload }) => {
      state.getSharedGroups.status = RS.IDLE;
      state.getSharedGroups.response = payload.response;

      state.groups = transformGroups(payload.response.result);
      state.hasMore = payload.response.has_more;
      state.lastRequestOptions = { ...state.lastRequestOptions, ...payload.requestOptions };
    });
    builder.addCase(getSharedGroups.rejected, (state, { payload }) => {
      state.getSharedGroups.status = RS.ERROR;
      state.getSharedGroups.error = payload;
    });

    builder.addCase(getMoreSharedGroups.pending, (state) => {
      state.getMoreSharedGroups.status = RS.RUNNING;
      state.getSharedGroups.error = null;
    });
    builder.addCase(getMoreSharedGroups.fulfilled, (state, { payload }) => {
      state.getMoreSharedGroups.status = RS.IDLE;
      state.getMoreSharedGroups.response = payload.response;

      if (state.groups) {
        state.groups.push(...transformGroups(payload.response.result));
        // Reset the indexes
        state.groups = state.groups.map((rest, index) => ({ ...rest, index }));
      } else {
        state.groups = transformGroups(payload.response.result);
      }

      state.hasMore = payload.response.has_more;
      state.lastRequestOptions = { ...state.lastRequestOptions, ...payload.requestOptions };
    });
    builder.addCase(getMoreSharedGroups.rejected, (state, { payload }) => {
      state.getMoreSharedGroups.status = RS.ERROR;
      state.getSharedGroups.error = payload;
    });

    builder.addCase(createSharedGroup.pending, (state) => {
      state.createSharedGroup.status = RS.RUNNING;
      state.createSharedGroup.error = null;
    });
    builder.addCase(createSharedGroup.fulfilled, (state, { payload }) => {
      state.createSharedGroup.status = RS.IDLE;
      state.createSharedGroup.response = payload.response;
      state.groups = null; // Refresh the list
      state.hasMore = false;
    });
    builder.addCase(createSharedGroup.rejected, (state, { payload }) => {
      state.createSharedGroup.status = RS.ERROR;
      state.createSharedGroup.error = payload;
    });

    builder.addCase(updateSharedGroup.pending, (state) => {
      state.updateSharedGroup.status = RS.RUNNING;
      state.updateSharedGroup.error = null;
    });
    builder.addCase(updateSharedGroup.fulfilled, (state, { payload }) => {
      state.updateSharedGroup.status = RS.IDLE;
      state.updateSharedGroup.response = payload.response;

      const indexToEdit = state.groups?.findIndex((group) => group.id === payload.groupDiffs.id);

      if (indexToEdit !== undefined && indexToEdit !== -1 && state.groups) {
        const newGroup = {
          ...state.groups[indexToEdit],
          ...payload.groupDiffs,
          index: indexToEdit,
        } as Group;

        state.groups[indexToEdit] = newGroup;
      }
    });
    builder.addCase(updateSharedGroup.rejected, (state, { payload }) => {
      state.updateSharedGroup.status = RS.ERROR;
      state.updateSharedGroup.error = payload;
    });

    builder.addCase(deleteSharedGroup.pending, (state) => {
      state.deleteSharedGroup.status = RS.RUNNING;
      state.deleteSharedGroup.error = null;
    });
    builder.addCase(deleteSharedGroup.fulfilled, (state, { payload }) => {
      state.deleteSharedGroup.status = RS.IDLE;

      if (state.groups) {
        state.groups = state.groups.filter((group) => !payload.idsToDelete.includes(group.id));
        // Reset the indexes
        state.groups = state.groups.map((rest, index) => ({ ...rest, index }));
      }
    });

    builder.addCase(clearCreateSharedGroup, (state) => {
      state.createSharedGroup.error = null;
      state.createSharedGroup.response = null;
      state.createSharedGroup.status = RS.IDLE;
    });

    builder.addCase(clearUpdateSharedGroup, (state) => {
      state.updateSharedGroup.error = null;
      state.updateSharedGroup.response = null;
      state.updateSharedGroup.status = RS.IDLE;
    });

    builder.addCase(clearFetchedSharedGroups, (state) => {
      state.hasMore = false;
      state.selection = [];
      state.groups = null;
      state.lastRequestOptions = defaultRequestOptions;
    });
    builder.addCase(clearSharedGroups, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export {
  getSharedGroups,
  deleteSharedGroup,
  createSharedGroup,
  updateSharedGroup,
  getMoreSharedGroups,
};

export default reducer;
