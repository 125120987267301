import { useTranslation } from "react-i18next";
import { IconButton, makeStyles, Box } from "@alohi/kit";
import { CSSProperties, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import {
  Draggable,
  Droppable,
  DropResult,
  DraggingStyle,
  DragDropContext,
  NotDraggingStyle,
} from "react-beautiful-dnd";

import { SendFaxFiles } from "views/SendFax/contexts/store";
import PreviewFile from "components/PreviewFile/PreviewFile";
import ConfirmDeleteFileItemModal from "views/SendFax/modals/ConfirmDeleteFileItemModal";
import { List, useTheme, ListItem, ListItemText, ListItemAvatar } from "ui";
import DragAndDropIcons from "./DragAndDropIcons";

interface DragAndDropListProps {
  items: SendFaxFiles;
  onReorder: (files: SendFaxFiles) => void;
}

function DragAndDropList({ items, onReorder }: DragAndDropListProps) {
  const classes = useStyles();
  const { alohi } = useTheme();
  const { t } = useTranslation();

  const [confirmModalIndex, setConfirmModalIndex] = useState(-1);
  const [previewModalIndex, setPreviewModalIndex] = useState(-1);

  const reorder = useCallback((list: SendFaxFiles, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      onReorder(reorder(items, result.source.index, result.destination.index));
    },
    [items, onReorder, reorder],
  );

  const onDelete = useCallback(
    (index) => {
      setConfirmModalIndex(-1);
      setPreviewModalIndex(-1);
      const newItems = items.slice();
      newItems.splice(index, 1);
      onReorder(newItems);
    },
    [items, onReorder],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List {...provided.droppableProps} className={classes.listStyle} ref={provided.innerRef}>
            {items.map((item, index) => {
              return (
                <Draggable key={item.key} index={index} draggableId={`item-${item.key}`}>
                  {(provided, snapshot) => (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      dense
                      ref={provided.innerRef}
                      className={classes.listItem}
                      onClick={() => setPreviewModalIndex(index)}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <ListItemAvatar>
                        <Box display="flex" justifyContent="space-around" alignItems="center">
                          <FontAwesomeIcon icon={faSort} size="1x" color={alohi.almostGray} />
                          <Box className={classes.iconContainer}>
                            <DragAndDropIcons
                              type={item.type}
                              mime={item.mime}
                              thumbnail={item.type === "faxFile" ? item.thumbnail : ""}
                            />
                          </Box>
                        </Box>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.name}
                        secondary={item.title}
                        classes={{
                          primary: classes.listItemPrimaryText,
                          secondary: classes.listItemSecondaryText,
                        }}
                      />
                      <IconButton
                        size="small"
                        color="secondary"
                        icon={faTrashAlt}
                        aria-label="Delete"
                        onClick={() => setConfirmModalIndex(index)}
                      />
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>

      {confirmModalIndex >= 0 ? (
        <ConfirmDeleteFileItemModal
          handleClosure={(isSuccess) => {
            if (isSuccess) {
              onDelete(confirmModalIndex);
            } else {
              setConfirmModalIndex(-1);
              setPreviewModalIndex(-1);
            }
          }}
          description={t("DELETE_ITEM.ARE_YOU_SURE_DELETE")}
        />
      ) : previewModalIndex >= 0 ? (
        <PreviewFile fileIndex={previewModalIndex} handleClosure={() => setPreviewModalIndex(-1)} />
      ) : null}
    </DragDropContext>
  );
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    background: theme.alohi.lightestBlue,
    borderRadius: theme.shape.borderRadius,
  },
  listItemPrimaryText: {
    display: "block",
    overflow: "hidden",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    color: theme.alohi.gray,
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  listItemSecondaryText: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    color: theme.alohi.lightGray,
  },
  listStyle: {
    width: "100%",
    paddingBottom: 0,
    background: "transparent",
    paddingTop: theme.spacing(1),
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const getItemStyle = (
  _: unknown,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
): CSSProperties => ({
  userSelect: "none",
  marginBottom: "5px",
  ...draggableStyle,
});

export default DragAndDropList;
