import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const get = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/available_credit_amounts?platform=web`;
  return fetchWithRefresh(path, requestOptions);
};

const post = (data) => {
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_ESHOP}/available_credit_amounts`;
  return fetchWithRefresh(path, requestOptions);
};

export const availableCreditAmountsApi = {
  get,
  post,
};
