import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, makeStyles, Box } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { List, ListItem, ListItemText, ListItemAvatar } from "ui";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import ViewCoverSheetModal from "../modals/ViewCoverSheetModal";
import ConfirmDeleteFileItemModal from "../modals/ConfirmDeleteFileItemModal";

function GalleryCoverSheetListItem() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    sendFaxDispatch,
    sendFaxStore: { galleryCoverSheet },
  } = useSendFaxContext();

  const [isConfirmDeleteModalOpen, isConfirmDeleteModalOpenHandlers] = useBool(false);
  const [idToView, setIdToView] = useState<null | { name: string; id: string }>(null);

  const handleConfirmDeleteModal = (isConfirmed: boolean) => {
    isConfirmDeleteModalOpenHandlers.setFalse();

    if (isConfirmed) {
      sendFaxDispatch({ type: "CLEAR_GALLERY_COVER_SHEET" });
    }
  };

  const viewCoverSheet = () => {
    if (galleryCoverSheet?.id && galleryCoverSheet?.name) {
      setIdToView({ id: galleryCoverSheet.id, name: galleryCoverSheet.name });
    }
  };

  return (
    <List className={classes.listStyle}>
      <ListItem dense className={classes.listItem} onClick={viewCoverSheet}>
        <ListItemAvatar>
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <FontAwesomeIcon icon={faSort} size="1x" color="transparent" />
            <Box className={classes.iconContainer}>
              <img
                alt="thumbnail"
                draggable={false}
                className={classes.image}
                src={`data:image/png;base64, ${galleryCoverSheet?.thumbnail}`}
              />
            </Box>
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={t("SENT_FAX.GALLERY_COVER_SHEET")}
          secondary={galleryCoverSheet?.name}
          classes={{
            primary: classes.listItemPrimaryText,
            secondary: classes.listItemSecondaryText,
          }}
        />

        <IconButton
          size="small"
          color="secondary"
          icon={faTrashAlt}
          aria-label="Delete"
          onClick={(event) => {
            if (event) {
              event.stopPropagation();
            }

            isConfirmDeleteModalOpenHandlers.setTrue();
          }}
        />
      </ListItem>

      {isConfirmDeleteModalOpen ? (
        <ConfirmDeleteFileItemModal
          handleClosure={handleConfirmDeleteModal}
          description={t("DELETE_ITEM.ARE_YOU_SURE_DELETE_COVER_SHEET")}
        />
      ) : null}

      {idToView ? (
        <ViewCoverSheetModal
          id={idToView.id}
          name={idToView.name}
          handleClosure={() => setIdToView(null)}
        />
      ) : null}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  listStyle: {
    width: "100%",
    cursor: "pointer",
    paddingBottom: 0,
    background: "transparent",
    paddingTop: theme.spacing(1),
  },
  listItem: {
    background: theme.alohi.lightestBlue,
    borderRadius: theme.shape.borderRadius,
  },
  listItemPrimaryText: {
    display: "block",
    overflow: "hidden",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    color: theme.alohi.gray,
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  listItemSecondaryText: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
    color: theme.alohi.lightGray,
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default GalleryCoverSheetListItem;
