import { useTranslation } from "react-i18next";
import { Button, Panel, SvgBox, makeStyles, useBool } from "@alohi/kit";

import SignPlusLogo from "assets/svg/sign_plus_logo_text.svg";
import ScanPlusLogo from "assets/svg/scan_plus_logo_text.svg";
import dataCy from "enums/dataCy";
import IncentiveButton from "components/Incentive/IncentiveButton";
import { MouseEvent } from "react";
import IncentiveModal from "components/Incentive/modals/IncentiveModal";
import { urls } from "routes/urls";
import { SIGN_APP_DOMAIN } from "config";

function AlohiSuitePanel() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isIncentiveModalOpen, isIncentiveModalOpenBool] = useBool(false);

  const handleOpenIncentiveModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    isIncentiveModalOpenBool.setTrue();
  };

  const handleCloseIncentiveModal = () => {
    isIncentiveModalOpenBool.setFalse();
  };

  const handleGoToSign = () => {
    window.open(SIGN_APP_DOMAIN, "_blank");
  };

  const handleGoToScan = () => {
    window.open(urls.scanPlus, "_blank");
  };

  return (
    <Panel
      className={classes.base}
      id="alohi-suite"
      data-cy={dataCy.alohiSuitePanel}
      headerTitle={t("PROFILE.ALOHI_SUITE")}
    >
      <Panel.Front>
        <Panel.Row>
          <Panel.Cell variant="title" className={classes.titleContainer}>
            <SvgBox className={classes.signLogo} src={SignPlusLogo} />
            <IncentiveButton
              className={classes.incentiveButton}
              onClick={handleOpenIncentiveModal}
            />
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button className={classes.button} variant="white" onClick={handleGoToSign}>
              {t("INCENTIVE_SIGN.GO_TO_SIGN")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">
            <SvgBox className={classes.scanLogo} src={ScanPlusLogo} />
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button className={classes.button} variant="white" onClick={handleGoToScan}>
              {t("PROFILE.GET_APP")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
      </Panel.Front>
      {isIncentiveModalOpen ? <IncentiveModal onClose={handleCloseIncentiveModal} /> : null}
    </Panel>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    //
  },
  titleContainer: {
    [breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginRight: spacing(2),
    },
  },
  signLogo: {
    width: "110px",
  },
  scanLogo: {
    width: "114px",
  },
  button: {
    minWidth: "125px !important",
    [breakpoints.down("sm")]: {
      marginTop: spacing(1),
    },
  },
  incentiveButton: {
    marginLeft: spacing(2),
  },
}));

export default AlohiSuitePanel;
