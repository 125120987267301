import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import {
  selectUserByUid,
  selectDisableUserRequestError,
  selectDisableUserRequestSuccess,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_DISABLE_USER_REQUEST,
} from "stores/reducers/corporate.reducer";

function ConfirmDisableUserModal({ userUid, handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(selectUserByUid(userUid));

  const success = useSelector(selectDisableUserRequestSuccess);

  useEffect(() => {
    if (success) {
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(corporateAsyncActions.getUsers());
      enqueueSnackbar(t("USERS.DISABLE_USER_SUCCESS"), { variant: "success" });
      dispatch(CLEAR_DISABLE_USER_REQUEST());
      handleClosure && handleClosure();
    }
  }, [success, dispatch, enqueueSnackbar, t, handleClosure]);

  const error = useSelector(selectDisableUserRequestError);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(CLEAR_DISABLE_USER_REQUEST());
    }
  }, [error, dispatch, enqueueSnackbar, t]);

  if (!user) return null;

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      title={`${t("USERS.DISABLE_USER")} (${user.email})`}
      onConfirm={() => dispatch(corporateAsyncActions.disableUser({ userUid }))}
    >
      <Box p={3}>
        <Typography variant="body">
          {t("USERS.QUESTION_DISABLE_MEMBER", {
            name: user.name || user.email,
          })}
        </Typography>
        <Box mt={1} />
        <Typography variant="body">{t("USERS.DESCRIBE_IF_USER_DISABLE")}</Typography>
      </Box>
    </Modal>
  );
}

ConfirmDisableUserModal.propTypes = {
  userUid: PropTypes.string,
  handleClosure: PropTypes.func,
};

export default ConfirmDisableUserModal;
