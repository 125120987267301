import { useCallback } from "react";
import { Button, Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useBool from "hooks/useBool";
import { Typography, makeStyles } from "ui";
import { alohiAdminUrls } from "routes/urls";
import { formatDefaultDate } from "helpers/date";
import { DigitFilter } from "components/Core/Filters";
import useAdminRedirections from "hooks/useAdminRedirections";
import { selectAllNumbersAndPlanInfo } from "selectors/common.selector";
import { selectFlattenedUsersHierarchy } from "selectors/corporate.selector";
import UpgradeEnterpriseModal from "views/UpgradeEnterprise/UpgradeEnterpriseModal";
import { selectPlanExpirationDate, selectPlanTotalPages } from "selectors/plan.selector";

function EnterpriseBanner() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const [isModalOpen, isModalOpenBool] = useBool(false);
  const totalPages = useSelector(selectPlanTotalPages);
  const numbersList = useSelector(selectAllNumbersAndPlanInfo);
  const planExpirationDate = useSelector(selectPlanExpirationDate);
  const flattenedUsersHierarchy = useSelector(selectFlattenedUsersHierarchy);

  const handleOpenUpgradePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.highVolume,
      fallbackAction: isModalOpenBool.setTrue,
    });
  }, [isModalOpenBool, redirectToAdmin]);

  return (
    <Box className={classes.root}>
      <Box className={classes.container} flex={1}>
        <Typography variant="subtitle3" className={classes.title}>
          {t("DASHBOARD.FAX_NUMBERS")}
        </Typography>
        <Typography variant="body" className={classes.subtitle}>
          <DigitFilter
            value={numbersList?.length ?? 0}
            renderText={(value) => <span>{value}</span>}
          />
        </Typography>
      </Box>
      <Box className={classes.container} flex={1}>
        <Typography variant="subtitle3" className={classes.title}>
          {t("DASHBOARD.USERS")}
        </Typography>
        <Typography variant="body" className={classes.subtitle}>
          <DigitFilter
            value={flattenedUsersHierarchy?.length ?? 0}
            renderText={(value) => <span>{value}</span>}
          />
        </Typography>
      </Box>
      <Box className={classes.container} flex={1.5}>
        <Typography variant="subtitle3" className={classes.title}>
          {t("DASHBOARD.PLAN_RENEWS")}
        </Typography>
        <Typography variant="body" className={classes.subtitle}>
          {formatDefaultDate(planExpirationDate)}
        </Typography>
      </Box>
      <Box className={classes.container} flex={1.5}>
        <Typography variant="subtitle3" className={classes.title}>
          {t("DASHBOARD.PAGES_ON_PLAN")}
        </Typography>
        <Typography variant="body" className={classes.subtitle}>
          <DigitFilter value={totalPages} renderText={(value) => <span>{value}</span>} />
          {t("DASHBOARD.PER_MONTH")}
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button className={classes.button} onClick={handleOpenUpgradePlan}>
          {t("DASHBOARD.UPGRADE")}
        </Button>
      </Box>
      {isModalOpen ? <UpgradeEnterpriseModal handleClosure={isModalOpenBool.setFalse} /> : null}
    </Box>
  );
}

const useStyles = makeStyles(({ shape, alohi, spacing }) => ({
  root: {
    height: 72,
    width: "100%",
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "space-around",
    boxShadow: alohi.bannerShadow,
    borderRadius: shape.borderRadius,
    backgroundColor: alohi.white,
    border: `1px solid ${alohi.lighterGray}`,
    paddingLeft: spacing(2),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    width: 160,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    minWidth: 110,
    height: 32,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    color: alohi.gray,
    textAlign: "center",
  },
  subtitle: {
    color: alohi.gray,
    textAlign: "center",
  },
}));

export default EnterpriseBanner;
