import clsx from "clsx";
import { makeStyles } from "@alohi/kit";

import "./Success.css";

function SuccessAlert() {
  const classes = useStyles();

  return (
    <div className={classes.icon}>
      <span className={clsx(classes.line, classes.tip)} />
      <span className={clsx(classes.line, classes.long)} />
      <div className={classes.placeholder} />
      <div className={classes.fix} />
    </div>
  );
}
const useStyles = makeStyles(({ alohi }) => ({
  icon: {
    width: 80,
    height: 80,
    border: "4px solid gray",
    borderRadius: "50%",
    margin: "20px auto",
    position: "relative",
    boxSizing: "content-box",
    borderColor: alohi.transparentGreen,

    "&::before, &::after": {
      // Emulate moving circular line
      content: "''",
      borderRadius: "50%",
      position: "absolute",
      width: 60,
      height: 120,
      background: alohi.white,
      transform: "rotate(45deg)",
    },
    "&::before": {
      borderRadius: "120px 0 0 120px",
      top: "-7px",
      left: "-33px",
      transform: "rotate(-45deg)",
      transformOrigin: "60px 60px",
    },
    "&::after": {
      borderRadius: "0 120px 120px 0",
      top: "-11px",
      left: "30px",
      transform: "rotate(-45deg)",
      transformOrigin: "0px 60px",
      animation: "rotatePlaceholder 4.25s ease-in",
    },
  },
  placeholder: {
    width: 80,
    height: 80,
    border: `4px solid gray`,
    borderColor: alohi.transparentGreen,
    borderRadius: "50%",
    boxSizing: "content-box",
    position: "absolute",
    left: "-4px",
    top: "-4px",
    zIndex: 2,
  },
  fix: {
    width: 5,
    height: 90,
    backgroundColor: alohi.white,
    position: "absolute",
    left: "28px",
    top: "8px",
    zIndex: 1,
    transform: "rotate(-45deg)",
  },
  line: {
    height: 5,
    backgroundColor: alohi.green,
    display: "block",
    borderRadius: "2px",
    position: "absolute",
    zIndex: 2,
    margin: "10px 0",
  },
  tip: {
    width: "25px",
    left: "14px",
    top: "36px",
    transform: "rotate(45deg)",
    animation: "animateSuccessTip 0.75s",
  },
  long: {
    width: 47,
    right: "8px",
    top: "28px",
    transform: "rotate(-45deg)",
    animation: "animateSuccessLong 0.75s",
  },
}));

export default SuccessAlert;
