import { useTranslation } from "react-i18next";

import { Typography } from "ui";

function NoMembersFound() {
  const { t } = useTranslation();

  return <Typography sx={{ textAlign: "center", mt: 3 }}>{t("USERS.NO_MEMBERS_FOUND")}</Typography>;
}

export default NoMembersFound;
