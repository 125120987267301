import { Box, Typography } from "@alohi/kit";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { NumberBundleAddress, NumberBundleDocument } from "api/types/numbers";
import { formatFullMonthDate } from "helpers/date";
import { selectAddresses } from "selectors/address.selector";

interface BundleLabelProps {
  bundle: NumberBundleDocument;
  hasSubmitDate?: boolean;
}

function BundleLabel({ bundle, hasSubmitDate }: BundleLabelProps) {
  const { t } = useTranslation();

  const addresses: NumberBundleAddress[] | undefined = useSelector(selectAddresses);
  const address = addresses?.find((address) => address.sid === bundle.address_linked);

  const isPending = bundle.status === "pending-review";

  if (!address) return <></>;
  return (
    <Box>
      <Typography>{`${address.city} (${bundle.end_user_type})`}</Typography>
      {hasSubmitDate ? (
        <Box display={"flex"} alignItems={"center"}>
          <Typography type="subtitle">
            {t("ADDRESS.BUNDLE_SUBMITTED_ON", {
              value_1: formatFullMonthDate(bundle.date_created),
            })}
          </Typography>
          {isPending ? (
            <Typography pl={0.5} fontWeight={"bold"} type="subtitle">{`(${t(
              "COMMON.PENDING",
            )})`}</Typography>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}

export default memo(BundleLabel);
