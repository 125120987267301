import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";

function Metadata() {
  const { t } = useTranslation();

  const isSignup = Boolean(useRouteMatch("/signup"));
  const isLogin = Boolean(useRouteMatch("/login"));
  const isInvite = Boolean(useRouteMatch("/invite/:token"));
  const isForgotPassword = Boolean(useRouteMatch("/forgot-password"));
  const isResetPassword = Boolean(useRouteMatch("/reset-password"));

  let metaTitle = t("METADATA.TITLE_DEFAULT");
  if (isSignup) {
    metaTitle = t("METADATA.TITLE_SIGNUP");
  } else if (isLogin) {
    metaTitle = t("METADATA.TITLE_LOGIN");
  } else if (isInvite) {
    metaTitle = t("METADATA.TITLE_INVITE");
  } else if (isForgotPassword) {
    metaTitle = t("METADATA.TITLE_FORGOT_PASSWORD");
  } else if (isResetPassword) {
    metaTitle = t("METADATA.TITLE_RESET_PASSWORD");
  }

  const metaDescription = t("METADATA.DESCRIPTION");

  return (
    <Helmet>
      <meta name="description" content={metaDescription} />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content=" Fax.Plus " />
      <meta property="og:see_also" content="https://www.fax.plus" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content="https://app.fax.plus/static/fax-plus.png" />
      <meta property="og:url" content="https://www.fax.plus" />
      <meta property="fb:app_id" content="1089593381155452" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content=" Fax.Plus " />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:creator" content=" Fax.Plus " />
      <meta name="twitter:image" content="https://app.fax.plus/static/fax-plus.png" />
    </Helmet>
  );
}

export default Metadata;
