import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { MouseEvent as ReactMouseEvent, memo, useState } from "react";

import { Box, Menu, Divider, MenuItem, Typography, FaButton } from "@alohi/kit";

interface Actions {
  label: string;
  disabled?: boolean;
  callback?: () => void;
}

interface ColumnActionsProps {
  dataIndex: number;
  createActions: (index: number) => (Actions | undefined)[];
}

function ColumnActions({ dataIndex, createActions }: ColumnActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const handleClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = createActions?.(dataIndex);

  if (!actions) {
    return null;
  }

  return (
    <Box textAlign="end">
      <FaButton
        aria-label="More"
        aria-haspopup="true"
        onClick={handleClick}
        icon={faEllipsisV}
        aria-controls={`actions-menu-${dataIndex}`}
      />

      <Menu
        elevation={2}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        id={`actions-menu-${dataIndex}`}
      >
        {actions.map((action, key) => {
          if (!action) {
            return null;
          }

          if (action.label === "Divider") {
            return (
              <Box py={1} key={key}>
                <Divider />
              </Box>
            );
          }

          return (
            <MenuItem
              key={action.label}
              disabled={action.disabled}
              onClick={() => {
                action.callback?.();
                handleClose();
              }}
            >
              <Typography type="body" style={{ fontSize: "0.875rem" }}>
                {action.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default memo(ColumnActions);
