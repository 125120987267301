import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { adaptV4Theme, createTheme } from "@mui/material/styles";
import { AdapterDayjs as DateDayjsAdapter } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";

import "./globals.css";
import v4Theme from "./theme";

LicenseInfo.setLicenseKey(
  "c01803d9fc7be4b3a37312967634686bTz0xMDc4MDIsRT0xNzcxMDI3MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
);

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const theme = createTheme(adaptV4Theme(v4Theme));

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateDayjsAdapter}>
          <CssBaseline />
          {children}
        </LocalizationProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
