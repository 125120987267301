import { Box, Link, Typography, alohi, makeStyles } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { helpCenterUrls } from "routes/urls";

function DocumentsTooltip() {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{t("ADDRESS.REGULATORY_DOCUMENTS")}</Typography>
      <Typography className={classes.text}>
        {t("PROFILE.REGULATORY_COMPLIANCE_DOCUMENTS_TOOLTIP_1")}
      </Typography>
      <Typography className={classes.text}>
        {t("PROFILE.REGULATORY_COMPLIANCE_DOCUMENTS_TOOLTIP_2")}
      </Typography>
      <Typography className={classes.text}>
        {t("PROFILE.REGULATORY_COMPLIANCE_DOCUMENTS_TOOLTIP_3")}
      </Typography>
      <Link
        href={helpCenterUrls.regulatoryCompliance}
        withIcon
        hasBullet
        className={classes.link}
        color={alohi.lightBlue}
        fontSize={12}
      >
        {t("ADDRESS.REGULATORY_DOCUMENTS")}
      </Link>
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1),
  },
  title: {
    color: alohi.white,
  },
  text: {
    color: alohi.white,
    fontSize: 12,
    marginTop: spacing(1),
  },
  link: {
    display: "flex",
    alignItems: "center",
    marginLeft: spacing(1),
    marginTop: spacing(0.5),
  },
}));

export default DocumentsTooltip;
