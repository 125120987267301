import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Box, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";

import { useInput } from "hooks/useInput";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import {
  clearCreateGroup,
  clearUpdateGroup,
  createGroup as createNormalGroup,
  updateGroup as updateNormalGroup,
} from "stores/reducers/contacts.groups.reducer";
import {
  selectGroupById,
  selectIsGroupUpdated,
  selectIsGroupCreated,
  selectSharedGroupById,
  selectCreateGroupError,
  selectUpdateGroupError,
  selectIsSharedGroupUpdated,
  selectIsSharedGroupCreated,
  selectUpdateSharedGroupError,
  selectCreateSharedGroupError,
} from "selectors/contacts.selector";
import {
  createSharedGroup,
  updateSharedGroup,
  clearCreateSharedGroup,
  clearUpdateSharedGroup,
} from "stores/reducers/contacts.sharedGroups.reducer";

function AddOrEditGroupModal({ isSharedGroup, groupToEditId, handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const normalGroup = useSelector(selectGroupById(groupToEditId));
  const sharedGroup = useSelector(selectSharedGroupById(groupToEditId));

  const isGroupUpdated = useSelector(selectIsGroupUpdated);
  const isGroupCreated = useSelector(selectIsGroupCreated);
  const isSharedGroupUpdated = useSelector(selectIsSharedGroupUpdated);
  const isSharedGroupCreated = useSelector(selectIsSharedGroupCreated);

  const createGroupError = useSelector(selectCreateGroupError);
  const updateGroupError = useSelector(selectUpdateGroupError);
  const updateSharedGroupError = useSelector(selectUpdateSharedGroupError);
  const createSharedGroupError = useSelector(selectCreateSharedGroupError);

  const group = useMemo(
    () => (isSharedGroup ? sharedGroup : normalGroup),
    [isSharedGroup, normalGroup, sharedGroup],
  );

  const [note, noteInput] = useInput(group?.note || "");
  const [name, nameInput] = useInput(group?.name || "", (value) => value.length > 0);

  const createGroup = useCallback(
    (newGroup) =>
      isSharedGroup
        ? dispatch(createSharedGroup({ newGroup }))
        : dispatch(createNormalGroup({ newGroup })),
    [dispatch, isSharedGroup],
  );

  const updateGroup = useCallback(
    (groupDiffs) =>
      isSharedGroup
        ? dispatch(updateSharedGroup({ groupDiffs }))
        : dispatch(updateNormalGroup({ groupDiffs })),
    [dispatch, isSharedGroup],
  );

  const handleConfirm = useCallback(() => {
    nameInput.touch();

    if (!nameInput.isValid) {
      enqueueSnackbar(t("FORMS.PLEASE_ENTER_A_VALID_NAME"), {
        variant: "error",
      });
      return;
    }

    groupToEditId
      ? updateGroup({
          name,
          note,
          id: groupToEditId,
        })
      : createGroup({
          name,
          note,
        });
  }, [createGroup, enqueueSnackbar, groupToEditId, name, nameInput, note, t, updateGroup]);

  const title = useMemo(() => {
    if (isSharedGroup) {
      return groupToEditId ? t("CONTACTS.MODIFY_SHARED_GROUP") : t("CONTACTS.ADD_SHARED_GROUP");
    }

    return groupToEditId ? t("CONTACTS.MODIFY_GROUP") : t("CONTACTS.ADD_NEW_GROUP");
  }, [groupToEditId, isSharedGroup, t]);

  useEffect(() => {
    if (isGroupUpdated || isGroupCreated || isSharedGroupUpdated || isSharedGroupCreated) {
      if (isSharedGroupCreated) {
        dispatch(clearCreateSharedGroup());
      } else if (isSharedGroupUpdated) {
        dispatch(clearUpdateSharedGroup());
      } else if (isGroupCreated) {
        dispatch(clearCreateGroup());
      } else if (isGroupUpdated) {
        dispatch(clearUpdateGroup());
      }

      if (isGroupCreated || isSharedGroupCreated) {
        handleClosure(name);
      } else {
        handleClosure();
      }
    }
  }, [
    name,
    dispatch,
    handleClosure,
    isGroupCreated,
    isGroupUpdated,
    isSharedGroupCreated,
    isSharedGroupUpdated,
  ]);

  useEffect(() => {
    if (createGroupError || updateGroupError || updateSharedGroupError || createSharedGroupError) {
      let error = "";

      if (createSharedGroupError) {
        error = createSharedGroupError;
        dispatch(clearCreateSharedGroup());
      } else if (updateSharedGroupError) {
        error = updateSharedGroupError;
        dispatch(clearUpdateSharedGroup());
      } else if (createGroupError) {
        error = createGroupError;
        dispatch(clearCreateGroup());
      } else if (updateGroupError) {
        error = updateGroupError;
        dispatch(clearUpdateGroup());
      }

      if (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }, [
    dispatch,
    enqueueSnackbar,
    createGroupError,
    updateGroupError,
    createSharedGroupError,
    updateSharedGroupError,
  ]);

  return (
    <Modal title={title} onCancel={handleClosure} onConfirm={handleConfirm} maxWidth="xs">
      <Box component="form" p={2} pr="15%">
        <FormRowTextInput
          type="text"
          value={name}
          variant="outlined"
          id="add-group-name"
          label={t("FORMS.NAME")}
          onBlur={nameInput.onBlur}
          onFocus={nameInput.onFocus}
          onChange={nameInput.onChange}
          placeholder={t("FORMS.NAME")}
          error={nameInput.isTouched && !nameInput.isValid}
        />
        <FormRowTextInput
          value={note}
          id="add-group-note"
          label={t("COMMON.NOTE")}
          onChange={noteInput.onChange}
          placeholder={t("COMMON.NOTE")}
        />
      </Box>
    </Modal>
  );
}

AddOrEditGroupModal.propTypes = {
  handleClosure: PropTypes.func,
  isSharedGroup: PropTypes.bool,
  groupToEditId: PropTypes.string,
};

export default AddOrEditGroupModal;
