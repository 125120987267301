import { Divider, Box } from "@alohi/kit";
import { ZendeskArticle } from "api/types/zendesk";

import { Link, ListItem, makeStyles } from "ui";
import Snippet from "./Snippet";

interface ArticleProps {
  article: ZendeskArticle;
}

function Article({ article }: ArticleProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <ListItem key={article.id} className={classes.listItem}>
        <Link className={classes.link} variant="body1" target="_blank" href={article.html_url}>
          {article.title}
        </Link>
        <Snippet snippet={article.snippet} />
      </ListItem>
      <Divider />
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
    marginBottom: spacing(1),
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "center",
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
}));

export default Article;
