import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { TextField, TextButton, makeStyles, Typography, Box, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import FormRow from "components/Forms/FormRow";
import { selectCurrentCreditCurrency } from "selectors/credit.selector";
import { applyDiscountCode, clearDiscountAmount } from "stores/reducers/payment.amounts.reducer";
import {
  selectDiscountCodeError,
  selectDiscountCodeResponse,
  selectSelectedPaymentAmount,
} from "selectors/payment.selector";
import useErrorHandler from "./useErrorHandler";

function AddDiscountCode() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const discountCode = useSelector(selectDiscountCodeResponse);
  const discountCodeError = useSelector(selectDiscountCodeError);
  const selectedPaymentAmount = useSelector(selectSelectedPaymentAmount);
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);

  const [isApplied, setIsApplied] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [discount, discountInput] = useInput("");

  useErrorHandler(discount);

  const handleApplyCode = useCallback(() => {
    if (discount === "") {
      return;
    }

    dispatch(
      applyDiscountCode({
        discountCode: discount,
        currency: currentCreditCurrency,
        amount: selectedPaymentAmount.amount,
      }),
    );
  }, [dispatch, discount, currentCreditCurrency, selectedPaymentAmount]);

  const handleRemoveCode = useCallback(() => {
    dispatch(clearDiscountAmount());
    discountInput.setValue("");
    setIsApplied(false);
  }, [discountInput, dispatch]);

  useEffect(() => {
    if (discountCode) {
      setIsApplied(true);
      enqueueSnackbar(t("ADD_CREDIT.DISCOUNT_CODE_IS_APPLIED"), {
        variant: "info",
      });
    }
  }, [discountCode, enqueueSnackbar, t]);

  useEffect(() => {
    if (discountCodeError) {
      discountInput.setShowsError(true);
      handleRemoveCode();
    }
  }, [discountCodeError, handleRemoveCode, discountInput]);

  useEffect(() => {
    /* Clear the discount code when availablePaymentAmount changes ex.: from 11.99 to 5.99 */
    if (!selectedPaymentAmount?.discount_code && isApplied) {
      handleRemoveCode();
    }
  }, [isApplied, selectedPaymentAmount, handleRemoveCode]);

  if (isInitialized) {
    return (
      <Box className={classes.wrapper}>
        <FormRow label={t("ADD_CREDIT.DISCOUNT_CODE")} id="discount-code">
          <TextField
            fullWidth
            value={discount}
            isDisabled={isApplied}
            id="discount-code-input"
            onBlur={discountInput.onBlur}
            onFocus={discountInput.onFocus}
            error={discountInput.showsError}
            onChange={discountInput.onChange}
            aria-describedby="discount-code-helper-text"
            InputProps={{
              endAdornment: (
                <TextButton
                  onClick={isApplied ? handleRemoveCode : handleApplyCode}
                  sx={{ pl: 2, pr: 1 }}
                >
                  {isApplied ? t("COMMON.CANCEL") : t("COMMON.APPLY")}
                </TextButton>
              ),
            }}
          />
        </FormRow>
      </Box>
    );
  }

  return (
    <Box className={classes.wrapper}>
      <FormRow label="" id="discount-code-add-button">
        <TextButton onClick={() => setIsInitialized(true)}>
          <Typography className={classes.addDiscount}>{t("ADD_CREDIT.USE_CODE")}</Typography>
        </TextButton>
      </FormRow>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  addDiscount: {
    textDecoration: "underline",
  },
}));

export default AddDiscountCode;
