export const borderAnimationSettings = {
  speed: 3,
  length: 2000,
  width: 1,
  stopColor1: "#0768FF",
  stopOpacity1: 1,
  stopColor2: "#6AE2B3",
  stopOpacity2: 1,
  glow: 0,
};
