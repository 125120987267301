import { ActionReducerMapBuilder, createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "stores/store";
import { accountsApi, supportApi } from "api";
import { selectAccountInfo } from "selectors/account.selector";
import RS, { FetchError, initialRequestState } from "enums/requestStatus";

const initialHipaaState = {
  requestBaa: initialRequestState,
  advancedSecurity: initialRequestState,
};

export const clearRequestBaa = createAction("ACCOUNT/CLEAR_REQUEST_BAA");
export const clearActivateAdvancedSecurity = createAction(
  "ACCOUNT/CLEAR_ACTIVATE_ADVANCED_SECURITY",
);

const activateAdvancedSecurity = createAsyncThunk<
  { response: unknown },
  undefined,
  { rejectValue: FetchError["origin"] }
>("ACCOUNT/ACTIVATE_ADVANCED_SECURITY", async (_, { rejectWithValue }) => {
  try {
    const response = await accountsApi.activateAdvancedSecurity();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const requestBaa = createAsyncThunk<
  { response: unknown },
  { signersName: string; signersEmail: string },
  { rejectValue: FetchError["origin"]; state: RootState }
>("ACCOUNT/REQUEST_BAA", async ({ signersName, signersEmail }, { rejectWithValue, getState }) => {
  try {
    const { name, email } = selectAccountInfo(getState());

    const response = await supportApi.support({
      name,
      email,
      request: {
        category: "BAA_REQUEST",
        signer_email: signersEmail,
        signer_full_name: signersName,
      },
    });
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const hipaaSlice = (builder: ActionReducerMapBuilder<RootState["account"]>) => {
  builder.addCase(clearActivateAdvancedSecurity, (state) => {
    state.advancedSecurity.response = null;
    state.advancedSecurity.status = RS.IDLE;
    state.advancedSecurity.error = null;
  });
  builder.addCase(activateAdvancedSecurity.pending, (state) => {
    state.advancedSecurity.response = null;
    state.advancedSecurity.status = RS.RUNNING;
    state.advancedSecurity.error = null;
  });
  builder.addCase(activateAdvancedSecurity.fulfilled, (state, { payload }) => {
    state.advancedSecurity.response = payload.response;
    state.advancedSecurity.status = RS.IDLE;
    state.advancedSecurity.error = null;
  });
  builder.addCase(activateAdvancedSecurity.rejected, (state, action) => {
    state.advancedSecurity.response = null;
    state.advancedSecurity.status = RS.ERROR;
    state.advancedSecurity.error = action.payload;
  });
  builder.addCase(clearRequestBaa, (state) => {
    state.requestBaa.response = null;
    state.requestBaa.status = RS.IDLE;
    state.requestBaa.error = null;
  });
  builder.addCase(requestBaa.pending, (state) => {
    state.requestBaa.response = null;
    state.requestBaa.status = RS.RUNNING;
    state.requestBaa.error = null;
  });
  builder.addCase(requestBaa.fulfilled, (state) => {
    localStorage.setItem("baaRequestSent", "true");
    state.requestBaa.response = true;
    state.requestBaa.status = RS.IDLE;
    state.requestBaa.error = null;
  });
  builder.addCase(requestBaa.rejected, (state, action) => {
    state.requestBaa.response = null;
    state.requestBaa.status = RS.ERROR;
    state.requestBaa.error = action.payload;
  });
};

export { initialHipaaState, hipaaSlice, activateAdvancedSecurity, requestBaa };
