import PropTypes from "prop-types";
import { useState } from "react";
import { FormLabel, FormHelperText } from "@alohi/kit";

import { FormControl, Grid, makeStyles } from "ui";
import FaxNumberInput from "./FaxNumberInput";

function FormRowFaxNumberInput({
  id,
  label,
  error,
  value,
  onBlur,
  onFocus,
  disabled,
  onChange,
  helperText,
  placeholder,
  labelGridWidth = 4,
  fullWidth = true,
}) {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = (e) => {
    setIsFocused(false);
    onBlur && onBlur(e);
  };

  const handleFocus = (e) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  return (
    <FormControl fullWidth>
      <Grid container alignItems="center">
        <Grid item xs={labelGridWidth} className={classes.labelContainer}>
          <FormLabel htmlFor={id} error={error} className={classes.label} focused={isFocused}>
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12 - labelGridWidth}>
          <div className={classes.inputContainer}>
            <FaxNumberInput
              id={id}
              value={value}
              isError={error}
              onChange={onChange}
              onBlur={handleBlur}
              disabled={disabled}
              onFocus={handleFocus}
              placeholder={placeholder}
              ariaDescribedby={`${id}-helper-text`}
              fullWidth={fullWidth}
            />
            <FormHelperText id={`${id}-helper-text`} error={error}>
              {helperText || " "}
            </FormHelperText>
          </div>
        </Grid>
      </Grid>
    </FormControl>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  labelContainer: {
    textAlign: "right",
  },
  label: {
    color: alohi.gray,
    marginRight: spacing(2),
    fontWeight: "bold",
    fontSize: 14,
  },
  inputContainer: {
    marginTop: 19,
    "& .Mui-disabled": {
      background: alohi.lighterGray,
    },
  },
}));

FormRowFaxNumberInput.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  labelGridWidth: PropTypes.number,
  fullWidth: PropTypes.bool,
};

export default FormRowFaxNumberInput;
