import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FormLabel, Divider, Checkbox, DatePicker, Modal, Box } from "@alohi/kit";

import dayjs from "helpers/date";
import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import { helpCenterUrls } from "routes/urls";
import { selectIsPlanFree } from "selectors/plan.selector";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import SettingsRetry from "views/SendFax/components/SettingsRetry";
import TimeZonesSelect from "views/SendFax/components/TimeZonesSelect";
import { getTimezones } from "stores/reducers/verifications.reducer";
import SettingsFaxToHuman from "views/SendFax/components/SettingsFaxToHuman";
import RequireUpgradeToBasicOrHigher from "views/RequireUpgrade/RequireUpgradeToBasicOrHigher";
import { Link, Helper, FormGroup, Typography, FormControl, FormControlLabel } from "ui";
import {
  selectAccountRetryFax,
  selectAccountFaxToHuman,
  selectAccountShouldEnhance,
  selectAccountNotifyReceiver,
  selectIsUpdateAccountDetailsRunning,
  selectIsUpdateAccountDetailsSuccessful,
} from "selectors/account.selector";
import {
  getAccountDetails,
  updateAccountDetails,
  clearUpdateAccountDetails,
} from "stores/reducers/account.reducer";

interface SettingsModalProps {
  handleClosure: (isSuccess: boolean) => void;
}

function SettingsModal({ handleClosure }: SettingsModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPlanFree = useSelector(selectIsPlanFree);
  const isAccountUpdated = useSelector(selectIsUpdateAccountDetailsSuccessful);
  const isUpdateAccountLoading = useSelector(selectIsUpdateAccountDetailsRunning);

  // Send Fax Settings - Initial Values
  const {
    sendFaxStore: {
      date: initialScheduledTransmissionTime,
      timezone: initialScheduledTransmissionTimeZone,
    },
    sendFaxDispatch,
  } = useSendFaxContext();
  const { hasFaxToHuman: initialHasFaxToHuman, faxToHuman: initialFaxToHuman } =
    useSelector(selectAccountFaxToHuman);
  const { hasFaxRetry: initialHasFaxRetry, faxRetry: initialFaxRetry } =
    useSelector(selectAccountRetryFax);
  const initialShouldEnhance = useSelector(selectAccountShouldEnhance);
  const initialNotifyReceiver = useSelector(selectAccountNotifyReceiver);

  // Send Fax Settings - Local State
  const [scheduledTransmissionTime, setScheduledTransmissionTime] = useState(
    initialScheduledTransmissionTime,
  );
  const [scheduledTransmissionTimeZone, setScheduledTransmissionTimeZone] = useState(
    initialScheduledTransmissionTimeZone,
  );
  const [faxRetry, setFaxRetry] = useState(initialFaxRetry);
  const [openChangePlan, openChangePlanBool] = useBool(false);
  const [faxToHuman, setFaxToHuman] = useState(initialFaxToHuman);
  const [hasFaxRetry, hasFaxRetryHandlers] = useBool(initialHasFaxRetry);
  const [shouldEnhance, shouldEnhanceBool] = useBool(initialShouldEnhance);
  const [notifyReceiver, notifyReceiverBool] = useBool(initialNotifyReceiver);
  const [hasFaxToHuman, hasFaxToHumanHandlers] = useBool(initialHasFaxToHuman);
  const [isRetrySettingsValid, setIsRetrySettingsValid] = useState(false);
  const [isScheduledTransmissionDateError, isScheduledTransmissionDateErrorBool] = useBool(false);

  const onConfirm = useCallback(() => {
    dispatch(
      updateAccountDetails({
        updateType: "settings",
        accountData: {
          shouldEnhance,
          notifyReceiver,
          faxRetry,
          hasFaxRetry,
          faxToHuman,
          hasFaxToHuman,
        },
      }),
    );
    if (scheduledTransmissionTime) {
      sendFaxDispatch({
        type: "SET_TIME",
        payload: scheduledTransmissionTime,
      });
      sendFaxDispatch({
        type: "SET_TIMEZONE",
        payload: scheduledTransmissionTimeZone,
      });
    } else {
      sendFaxDispatch({ type: "CLEAR_SCHEDULE" });
    }
  }, [
    dispatch,
    shouldEnhance,
    notifyReceiver,
    faxRetry,
    hasFaxRetry,
    faxToHuman,
    hasFaxToHuman,
    scheduledTransmissionTime,
    sendFaxDispatch,
    scheduledTransmissionTimeZone,
  ]);

  const onCancel = useCallback(() => {
    handleClosure(false);
  }, [handleClosure]);

  useEffect(() => {
    if (isAccountUpdated) {
      dispatch(clearUpdateAccountDetails());
      dispatch(getAccountDetails());
      handleClosure(false);
    }
  }, [isAccountUpdated, dispatch, handleClosure]);

  useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  const maxDate = dayjs().add(2, "weeks");

  return (
    <>
      <Modal
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={t("SENT_FAX.SETTINGS")}
        confirmTitle={t("COMMON.SAVE")}
        data-cy={dataCy.sendFaxSettingsModal}
        isConfirmLoading={isUpdateAccountLoading}
        isConfirmDisabled={
          isScheduledTransmissionDateError || (hasFaxRetry && isRetrySettingsValid)
        }
      >
        <Box p={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend" />
            <FormGroup>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={Boolean(scheduledTransmissionTime)}
                      checked={Boolean(scheduledTransmissionTime)}
                      onChange={(event) => {
                        if (isPlanFree) {
                          openChangePlanBool.setTrue();
                          return;
                        }

                        if (event.target.checked) {
                          setScheduledTransmissionTime(dayjs());
                        } else {
                          setScheduledTransmissionTime(null);
                        }
                      }}
                    />
                  }
                  label={<Typography>{t("SENT_FAX.SCHEDULED_TRANSMISSION")}</Typography>}
                />

                {Boolean(scheduledTransmissionTime) ? (
                  <Box py={2}>
                    <Box
                      mb={2}
                      width="50%"
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      data-cy={dataCy.scheduledTransmissionTimeInputContainer}
                    >
                      <DatePicker
                        isFullWidth
                        disablePast
                        maxDate={maxDate}
                        format="MMMM D, h:mm A"
                        onChange={setScheduledTransmissionTime as unknown as () => void}
                        value={dayjs(scheduledTransmissionTime)}
                        onError={(error) =>
                          Boolean(error)
                            ? isScheduledTransmissionDateErrorBool.setTrue()
                            : isScheduledTransmissionDateErrorBool.setFalse()
                        }
                      />
                      <Box position="absolute" left="50%" pl={1}>
                        <Helper
                          size="lg"
                          tooltip={t("SENT_FAX.MAX_DATE_INFO", {
                            value: maxDate.format("MMMM D, h:mm A"),
                          })}
                        />
                      </Box>
                    </Box>

                    <Box width="50%" data-cy={dataCy.scheduledTransmissionTimeZoneInputContainer}>
                      <TimeZonesSelect
                        timeZone={scheduledTransmissionTimeZone}
                        setTimeZone={setScheduledTransmissionTimeZone}
                      />
                    </Box>
                  </Box>
                ) : null}
              </Box>

              <Box my={2}>
                <Divider />
              </Box>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={hasFaxRetry}
                      checked={hasFaxRetry}
                      onChange={hasFaxRetryHandlers.toggle}
                    />
                  }
                  label={<Typography>{t("SENT_FAX.RETRY")}</Typography>}
                />
                {hasFaxRetry ? (
                  <Box py={2}>
                    <SettingsRetry
                      values={faxRetry}
                      onChange={setFaxRetry}
                      onCheckValidity={setIsRetrySettingsValid}
                    />
                  </Box>
                ) : null}
              </Box>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={hasFaxToHuman}
                      checked={hasFaxToHuman}
                      onChange={hasFaxToHumanHandlers.toggle}
                    />
                  }
                  label={<Typography>{t("SENT_FAX.TELEFAX")}</Typography>}
                />
                {hasFaxToHuman ? (
                  <Box py={2}>
                    <SettingsFaxToHuman values={faxToHuman} onChange={setFaxToHuman} />
                  </Box>
                ) : null}
              </Box>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={notifyReceiver}
                      checked={notifyReceiver}
                      onChange={notifyReceiverBool.toggle}
                    />
                  }
                  label={
                    <Box display="flex">
                      <Box mr={1}>
                        <Typography>{t("SENT_FAX.EMAIL_RECIPIENT")}</Typography>
                      </Box>
                      <Link href={helpCenterUrls.emailRecipient} target="_blank">
                        <Helper size="lg" />
                      </Link>
                    </Box>
                  }
                />
              </Box>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={shouldEnhance}
                      checked={shouldEnhance}
                      onChange={shouldEnhanceBool.toggle}
                    />
                  }
                  label={
                    <Box display="flex">
                      <Box mr={1}>
                        <Typography>{t("SENT_FAX.OPTIMIZED")}</Typography>
                      </Box>
                      <Link href={helpCenterUrls.optimize} target="_blank">
                        <Helper size={"lg"} />
                      </Link>
                    </Box>
                  }
                />
              </Box>
            </FormGroup>
            <Box mt={2}>
              <Typography
                variant="body"
                sx={{
                  "&&": {
                    fontStyle: "italic",
                    color: "alohi.almostGray",
                  },
                }}
              >
                {t("SENT_FAX.DESCRIPTION")}
              </Typography>
            </Box>
          </FormControl>
        </Box>
      </Modal>

      {openChangePlan ? (
        <RequireUpgradeToBasicOrHigher
          handleClosure={openChangePlanBool.setFalse}
          title={t("SENT_FAX.SCHEDULED_TRANSMISSION")}
          featureName={t("SENT_FAX.SCHEDULED_TRANSMISSION")}
        />
      ) : null}
    </>
  );
}
export default SettingsModal;
