import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import {
  selectUserByUid,
  selectResendInvitationByEmailRequestError,
  selectResendInvitationByEmailRequestSuccess,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_RESEND_INVITATION_BY_EMAIL_REQUEST,
} from "stores/reducers/corporate.reducer";

function ConfirmResendInvitationModal({ userUid, handleClosure }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const user = useSelector(selectUserByUid(userUid));

  const success = useSelector(selectResendInvitationByEmailRequestSuccess);
  useEffect(() => {
    if (success) {
      enqueueSnackbar(t("FORMS.INVITATION_HAS_BEEN_SENT"), {
        variant: "success",
      });
      dispatch(CLEAR_RESEND_INVITATION_BY_EMAIL_REQUEST());
      handleClosure && handleClosure();
    }
  }, [success, dispatch, enqueueSnackbar, t, handleClosure]);

  const error = useSelector(selectResendInvitationByEmailRequestError);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(CLEAR_RESEND_INVITATION_BY_EMAIL_REQUEST());
    }
  }, [error, dispatch, enqueueSnackbar, t]);

  if (!user) return null;

  return (
    <Modal
      maxWidth="xs"
      title={t("USERS.RESEND_INVITATION")}
      onCancel={handleClosure}
      onConfirm={() =>
        dispatch(corporateAsyncActions.resendInvitationByEmail({ email: user.email }))
      }
    >
      <Box p={3}>
        <Typography>
          {t("USERS.QUESTION_RESEND_INVITATION", {
            name: user.name || user.email,
          })}
        </Typography>
      </Box>
    </Modal>
  );
}

ConfirmResendInvitationModal.propTypes = {
  userUid: PropTypes.string,
  handleClosure: PropTypes.func,
};

export default ConfirmResendInvitationModal;
