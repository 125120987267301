import { Box } from "@alohi/kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "ui";
import FormRowTextInput from "components/Forms/FormRowTextInput";

const RETRIES_MIN = 1;
const RETRIES_MAX = 3;

const DELAY_MIN = 1;
const DELAY_MAX = 30;

interface SettingsRetryProps {
  values: {
    count: number;
    delay: number;
  };
  onChange: (values: { count: number; delay: number }) => void;
  onCheckValidity: (isValid: boolean) => void;
}

function SettingsRetry({ values, onChange, onCheckValidity }: SettingsRetryProps) {
  const { t } = useTranslation();

  const [isCountError, setIsCountError] = useState(false);
  const [isDelayError, setIsDelayError] = useState(false);

  const checkCountError = (count: number) => {
    if (count < RETRIES_MIN) {
      setIsCountError(true);
    } else if (count > RETRIES_MAX) {
      setIsCountError(true);
    } else {
      setIsCountError(false);
    }
  };
  const checkDelayError = (delay: number) => {
    if (delay < DELAY_MIN) {
      setIsDelayError(true);
    } else if (delay > DELAY_MAX) {
      setIsDelayError(true);
    } else {
      setIsDelayError(false);
    }
  };

  const onRetriesNumberChange = ({ target }: { target: { value: string } }) => {
    const numericValue = +target.value;
    onChange({ ...values, ...{ count: numericValue } });
  };

  const onRetryDelayChange = ({ target }: { target: { value: string } }) => {
    const numericValue = +target.value;
    onChange({ ...values, ...{ delay: numericValue } });
  };

  useEffect(() => {
    onCheckValidity(isCountError || isDelayError);
  }, [onCheckValidity, isCountError, isDelayError]);

  useEffect(() => {
    checkCountError(values.count);
  }, [values.count]);

  useEffect(() => {
    checkDelayError(values.delay);
  }, [values.delay]);

  return (
    <Grid container spacing={4}>
      <Grid item xs>
        <FormRowTextInput
          labelGridWidth={12}
          type="number"
          value={values.count || ""}
          id="number-of-retry"
          placeholder={t("SENT_FAX.NUM_OF_RETRY")}
          label={<Box mb="5px">{t("SENT_FAX.NUM_OF_RETRY")}</Box>}
          onChange={onRetriesNumberChange}
          inputProps={{
            min: RETRIES_MIN,
            max: RETRIES_MAX,
          }}
          error={isCountError}
          helperText={
            isCountError
              ? t("SENT_FAX.NUM_OF_RETRY_ERROR", {
                  max: RETRIES_MAX,
                })
              : " "
          }
        />
      </Grid>
      <Grid item xs>
        <FormRowTextInput
          labelGridWidth={12}
          type="number"
          value={values.delay || ""}
          id="number-of-delay"
          label={<Box mb="5px">{t("SENT_FAX.DELAY_TRY")}</Box>}
          placeholder={t("SENT_FAX.DELAY_TRY")}
          onChange={onRetryDelayChange}
          inputProps={{
            min: DELAY_MIN,
            max: DELAY_MAX,
          }}
          error={isDelayError}
          helperText={
            isDelayError
              ? t("SENT_FAX.DELAY_TRY_ERROR", {
                  max: DELAY_MAX,
                })
              : " "
          }
        />
      </Grid>
    </Grid>
  );
}

export default SettingsRetry;
