import { Reducer, Dispatch, ReactNode, useContext, useReducer, createContext } from "react";

import { PhoneVerificationAction, phoneVerificationReducer } from "./api";

// =================
// Store
// =================
export interface PhoneVerificationStore {
  phoneNumber: string;
}

export const initialPhoneVerificationStore: PhoneVerificationStore = {
  phoneNumber: "",
};

// =================
// Context / Provider
// =================
export type PhoneVerificationContextProps = {
  phoneVerificationStore: PhoneVerificationStore;
  phoneVerificationDispatch: Dispatch<PhoneVerificationAction>;
};

export const PhoneVerificationContext = createContext<PhoneVerificationContextProps>({
  phoneVerificationStore: initialPhoneVerificationStore,
  phoneVerificationDispatch: () => undefined,
});

export function usePhoneVerificationContext() {
  const context = useContext(PhoneVerificationContext);

  if (context === undefined) {
    throw new Error("usePhoneVerificationContext must be within PhoneVerificationContextProvider!");
  }

  return context;
}

interface PhoneVerificationContextProviderProps {
  children: ReactNode;
  initialUpdate?: Partial<PhoneVerificationStore>;
}

export function PhoneVerificationContextProvider({
  children,
  initialUpdate,
}: PhoneVerificationContextProviderProps) {
  const [phoneVerificationStore, phoneVerificationDispatch] = useReducer<
    Reducer<PhoneVerificationStore, PhoneVerificationAction>
  >(phoneVerificationReducer, {
    ...initialPhoneVerificationStore,
    ...initialUpdate,
  });

  return (
    <PhoneVerificationContext.Provider
      value={{ phoneVerificationStore, phoneVerificationDispatch }}
    >
      {children}
    </PhoneVerificationContext.Provider>
  );
}
