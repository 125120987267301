import { FaButton, Box } from "@alohi/kit";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

import { urls } from "routes/urls";
import ExportToCSV from "./ExportToCSV";

interface FaxesActionsProps {
  showFilters: () => void;
}

function FaxesActions({ showFilters }: FaxesActionsProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  switch (pathname) {
    case urls.faxes.inbox:
    case urls.faxes.sent:
    case urls.faxes.trash:
      return (
        <Box display={"flex"}>
          <ExportToCSV />
          <Box mr={1} />
          <FaButton
            variant="light"
            onClick={showFilters}
            icon={faMagnifyingGlass}
            tooltip={t("COMMON.SEARCH")}
          />
        </Box>
      );
    case urls.faxes.outbox:
    default:
      return null;
  }
}

export default FaxesActions;
