import { alohi, Box, makeStyles, Tabs, theme } from "@alohi/kit";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Link from "routes/Link";
import { useMediaQuery } from "ui";
import { MouseEvent as ReactMouseEvent, ReactNode, useRef } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { CustomScrollButton } from "./components/CustomScrollButton";

const SCROLL_AMOUNT = 300;

export interface AppSubMenuItem {
  label: string;
  to: string;
  icon: IconDefinition;
  ["data-cy"]?: string;
  isDisabled?: boolean;
  onClick?: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  component?: ReactNode;
}

interface AppSubMenuProps {
  items: AppSubMenuItem[];
}

function AppSubMenu({ items }: AppSubMenuProps) {
  const classes = useStyles();

  const location = useLocation();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const tabsRef = useRef<HTMLButtonElement | null>(null);

  const handleScroll = (direction: "left" | "right") => {
    if (tabsRef.current) {
      const scrollContainer = tabsRef.current.querySelector(".MuiTabs-scroller"); // Adjust selector if needed

      if (scrollContainer) {
        const offset = direction === "left" ? -SCROLL_AMOUNT : SCROLL_AMOUNT;
        scrollContainer.scrollBy({ left: offset, behavior: "smooth" });
      }
    }
  };

  return (
    <Box className={classes.base}>
      <Tabs
        ref={tabsRef}
        variant={isMobileLayout ? "scrollable" : "fullWidth"}
        scrollButtons={isMobileLayout}
        allowScrollButtonsMobile={isMobileLayout}
        ScrollButtonComponent={(data) => <CustomScrollButton {...data} onClick={handleScroll} />}
        className={classes.tabs}
        value={location.pathname}
        orientation="horizontal"
      >
        {items.map((item) => (
          <Tabs.Tab
            key={item.to}
            className={classes.tab}
            component={item.component ?? Link}
            label={item.label}
            value={item.to}
            to={item.to}
            icon={<FontAwesomeIcon icon={item.icon} size="lg" />}
            data-cy={item["data-cy"]}
            disabled={item.isDisabled}
            onClick={item.onClick}
          />
        ))}
      </Tabs>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints }) => ({
  base: {
    [breakpoints.down("md")]: {
      background: alohi.neutral0,
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: "solid",
      borderColor: alohi.neutral200,
    },
  },
  tabs: {
    //
  },
  tab: {
    whiteSpace: "nowrap",
    minWidth: "120px",
    flex: 1,
    [breakpoints.down("md")]: {
      "&&": {
        borderWidth: 0,
      },
      height: "60px",
    },
  },
}));

export default AppSubMenu;
