import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, makeStyles, Box } from "@alohi/kit";

import dayjs from "helpers/date";
import useBool from "hooks/useBool";
import { selectNextMigrationTime } from "selectors/account.selector";
import { getUserDataResidencySettings } from "stores/reducers/account.dataResidency.reducer";

function DataResidencyCooldown() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nextMigrationTime = useSelector(selectNextMigrationTime);
  const [showCooldown, showCooldownHandler] = useBool(false);

  useEffect(() => {
    if (!showCooldown) {
      // Refresh the data each time the modal is closed
      dispatch(getUserDataResidencySettings());
    }
  }, [dispatch, showCooldown]);

  return (
    <>
      <Box className={classes.editWrapper} onClick={showCooldownHandler.setTrue}>
        <Typography variant="body" className={classes.editText}>
          {t("PROFILE.DATA_RESIDENCY_EDIT")}
        </Typography>
      </Box>

      {showCooldown ? (
        <Modal
          confirmTitle={t("COMMON.CLOSE")}
          onConfirm={showCooldownHandler.setFalse}
          title={t("PROFILE.DATA_RESIDENCY_COOLDOWN_TITLE")}
        >
          <Box p={3}>
            <Typography>
              {nextMigrationTime
                ? t("PROFILE.DATA_RESIDENCY_COOLDOWN_TEXT", {
                    day: dayjs.utc(nextMigrationTime).local().format("dddd"),
                    time: dayjs.utc(nextMigrationTime).local().format("H:mm A"),
                  })
                : ""}
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ typography, alohi }) => ({
  editWrapper: {
    padding: "4px 5px",
    minWidth: "64px",
    textAlign: "center",
  },
  editText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: alohi.white,
    lineHeight: "normal",
    fontSize: typography.pxToRem(15),
  },
}));

export default DataResidencyCooldown;
