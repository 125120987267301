import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO, API_SSO, VERSION } from "config";

export type AuthenticationLoginResponse = {
  access_token?: string;
  next_action?: string;
  ["2fa_token"]?: string;
  manual_code?: string;
  qr_code?: string;
};

export type AuthenticationGetLoginLogsParams = { limit?: string; before?: string; reset?: boolean };

const login = (username: string, password: string) => {
  const requestOptions = {
    method: "POST",
    body: {
      username,
      password,
      scope: "all",
      extra_info: {
        platform: `Web React ${VERSION}`,
      },
    },
  };
  const path = `${API_AFFOGATO}/login?grant_type=password&use_cookie_flow=true`;

  return fetchWithRefresh(path, requestOptions, {
    disableRetry: true,
    excludeBearer: true,
    credentials: "include",
  });
};

function register(user: unknown) {
  const requestOptions = {
    method: "POST",
    body: user,
  };
  const path = `${API_AFFOGATO}/accounts`;
  return fetchWithRefresh(path, requestOptions);
}

function refreshToken() {
  const requestOptions = {
    method: "POST",
  };
  const path = `${API_AFFOGATO}/login?grant_type=refresh_token&scope=all&use_cookie_flow=true`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true, credentials: "include" });
}

function loginBy3rdParty({ provider, token }: { provider: string; token: string }) {
  const requestOptions = {
    method: "POST",
    body: {
      provider,
      token,
      api_version: "v2",
      scope: "all",
    },
  };
  const path = `${API_AFFOGATO}/login?grant_type=3rd_party_token&use_cookie_flow=true`;
  return fetchWithRefresh(path, requestOptions, { disableRetry: true, credentials: "include" });
}

function resetPassword(newPassword: string, token: string) {
  const requestOptions = {
    method: "POST",
    body: { new_password: newPassword, token },
  };
  const path = `${API_AFFOGATO}/accounts/change_password?with=token`;
  return fetchWithRefresh(path, requestOptions);
}

const changePasswordWithOld = ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) => {
  const requestOptions = {
    method: "POST",
    body: {
      old_password: oldPassword,
      new_password: newPassword,
    },
  };
  const path = `${API_AFFOGATO}/accounts/change_password?with=old_password`;
  return fetchWithRefresh(path, requestOptions);
};

function logout() {
  const requestOptions = {
    method: "POST",
  };
  const path = `${API_AFFOGATO}/logout`;
  return fetchWithRefresh(path, requestOptions, { disableRetry: true });
}

function getLoginLogs(options: AuthenticationGetLoginLogsParams = {}) {
  const { limit, before } = options;
  const requestOptions = {
    method: "GET",
  };
  const q = [];
  const q_s = { str: "" };
  if (limit) {
    q.push(`limit=${limit}`);
  }
  if (before) {
    q.push(`before=${before}`);
  }
  if (q.length) {
    q_s.str = q.join("&");
  }
  const path = q_s.str ? `${API_AFFOGATO}/login?${q_s.str}` : `${API_AFFOGATO}/login`;
  return fetchWithRefresh(path, requestOptions);
}

function forgotPassword(email: string) {
  const requestOptions = {
    method: "POST",
    body: {
      email,
    },
  };
  const path = `${API_AFFOGATO}/forgot_password`;
  return fetchWithRefresh(path, requestOptions);
}

function reviseAndGetToken(token: string) {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/forgot_password?token=${token}`;
  return fetchWithRefresh(path, requestOptions).then((data) => {
    if (data.token) {
      return Promise.resolve(data.token);
    }
  });
}

function initiateSsoLogin(email: string) {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_SSO}/sp_login?email=${encodeURIComponent(email)}&platform=web`;
  return fetchWithRefresh(path, requestOptions);
}

export const authenticationApi = {
  login,
  refreshToken,
  logout,
  register,
  resetPassword,
  changePasswordWithOld,
  loginBy3rdParty,
  getLoginLogs,
  forgotPassword,
  reviseAndGetToken,
  initiateSsoLogin,
};
