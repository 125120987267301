import { useTranslation } from "react-i18next";
import { Box, makeStyles, Button, TextButton, Typography } from "@alohi/kit";

interface FooterProps {
  onLogout: () => void;
  onContactUs: () => void;
  isConfirmDisabled: boolean;
}

function Footer({ isConfirmDisabled, onLogout, onContactUs }: FooterProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.customFooter}>
      <Button variant="gray" color="inherit" onClick={onLogout}>
        {t("COMMON.LOGOUT")}
      </Button>
      <TextButton onClick={onContactUs} disabled={isConfirmDisabled}>
        <Typography className={classes.contactUs}>{t("CONTACT_US.TITLE")}</Typography>
      </TextButton>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  customFooter: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  confirmContainer: {
    display: "flex",
  },
  contactUs: {
    color: alohi.blue,
  },
}));

export default Footer;
