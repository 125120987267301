import React from "react";
import { useSelector } from "react-redux";
import Avatar from "components/Core/Avatar/Avatar";
import { selectAccountInfo } from "selectors/account.selector";

import { API_STORAGE } from "config";

function AccountAvatar(props) {
  const accountInfo = useSelector(selectAccountInfo);

  const fullName = `${accountInfo?.name ?? ""} ${accountInfo?.lastname ?? ""}`;
  const profileImage = accountInfo?.profileImage ? `${API_STORAGE}${accountInfo.profileImage}` : "";

  return <>{fullName && profileImage && <Avatar src={profileImage} alt={fullName} {...props} />}</>;
}

export default AccountAvatar;
