import Select from "react-select";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};

function CoreSelect(props) {
  return <Select styles={customStyles} {...props} />;
}

export default CoreSelect;
