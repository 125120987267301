import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const CountryFlag = (props) => {
  const { code, phone, ...rest } = props;
  const country = { code: "" };

  if (phone) {
    country.code = parsePhoneNumberFromString(phone).country;
  } else {
    country.code = code;
  }

  return (
    <ReactCountryFlag
      svg
      araia-label={country.code}
      countryCode={country.code.toLowerCase()}
      {...rest}
    />
  );
};

CountryFlag.propTypes = {
  code: PropTypes.string,
  phone: PropTypes.string,
  style: PropTypes.object,
};

export default CountryFlag;
