import { useTranslation } from "react-i18next";
import { Box, Helper, Typography, Modal, useSnackbar } from "@alohi/kit";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import dataCy from "enums/dataCy";
import useCart from "hooks/useCart";
import useBool from "hooks/useBool";
import { helpCenterUrls } from "routes/urls";
import useForceRemount from "hooks/useForceRemount";
import ChargeError from "views/Transactions/ChargeError";
import { selectAccount } from "selectors/account.selector";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import { useAppDispatch, useAppSelector } from "stores/store";
import ModalInfo from "components/UpgradeEnterprise/ModalInfo";
import FooterInfo from "components/UpgradeEnterprise/FooterInfo";
import PlanOption from "components/UpgradeEnterprise/PlanOption";
import MoreOption from "components/UpgradeEnterprise/MoreOption";
import Destinations from "components/UpgradeEnterprise/Destinations";
import TransactionSuccess from "views/Transactions/TransactionSuccess";
import MoreOptionInfo from "components/UpgradeEnterprise/MoreOptionInfo";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import { clearChargeAmount } from "stores/reducers/payment.charge.reducer";
import FormRowReactSelectInput from "components/Forms/FormRowReactSelectInput";
import { setCustomPaymentAmount } from "stores/reducers/payment.amounts.reducer";
import { getCurrentCredit, getCreditDetails } from "stores/reducers/credit.reducer";
import { getAccountDetails, getCustomerInfo } from "stores/reducers/account.reducer";
import {
  selectPageOptionsId,
  selectListPageOptions,
  selectIsPageOptionsIdRunning,
  selectIsListPageOptionsRunning,
  selectPlanPeriod,
} from "selectors/plan.selector";
import {
  selectChargeAmount,
  selectCurrentCart,
  selectIsCartLoading,
  selectIsChargeAmountError,
  selectIsChargeProcessLoading,
  selectPaymentDepositError,
} from "selectors/payment.selector";
import {
  PageOption,
  getCurrentPlan,
  getPageOptionsId,
  getListPageOptions,
  clearListPageOptions,
} from "stores/reducers/plans.reducer";
import useOnMountCondition from "hooks/useOnMountCondition";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

export type Option = {
  value: string;
  label: ReactNode;
};

interface UpgradeEnterpriseProps {
  handleClosure: (closure: boolean) => void;
}

function UpgradeEnterpriseModal({ handleClosure }: UpgradeEnterpriseProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { sendCustomEvent } = useGoogleTagManager();

  const [{ creditNeeded, canChargeCart }, { chargeCart }] = useCart(false);
  const [key, forceRemount] = useForceRemount();

  const accountData = useAppSelector(selectAccount);
  const isCartLoading = useAppSelector(selectIsCartLoading);
  const isChargeSuccess = useAppSelector<boolean>(selectChargeAmount);
  const isChargeError = useAppSelector<boolean>(selectIsChargeAmountError);
  const isPaymentDepositError = useAppSelector<boolean>(selectPaymentDepositError);
  const currentCart = useAppSelector(selectCurrentCart);
  const pageOptionsId = useAppSelector<null | string>(selectPageOptionsId);
  const isChargeRunning = useAppSelector<boolean>(selectIsChargeProcessLoading);
  const isPageOptionsIdRunning = useAppSelector<boolean>(selectIsPageOptionsIdRunning);
  const isListPageOptionsRunning = useAppSelector<boolean>(selectIsListPageOptionsRunning);
  const listPageOptions = useAppSelector<null | PageOption[]>(selectListPageOptions);
  const currenPlanPeriod = useAppSelector(selectPlanPeriod);

  const [isUsCa, isUsCaBool] = useBool(false);
  const [pagesPerMonth, setPagesPerMonth] = useState<Option | undefined>();
  const [isShowAddCreditModalOpen, isShowAddCreditModalOpenBool] = useBool(false);
  const [status, setStatus] = useState<"INIT" | "FETCHING" | "READY">("INIT");
  const [requestedPages, setRequestedPages] = useState(0);
  const [isMaximumOption, setIsMaximumOption] = useState(false);
  const [openChargeError, setOpenChargeError] = useState(false);

  const pages = Number(pagesPerMonth?.value);
  const isLoading =
    isListPageOptionsRunning || isPageOptionsIdRunning || isCartLoading || isChargeRunning;

  const StandardOptionSelector = useCallback(
    ({ option }: { option: PageOption }) => (
      <Box whiteSpace="pre">
        {`${Intl.NumberFormat().format(option.monthly_pages)}`}{" "}
        {Boolean(option.marketing_discount) ? (
          <strong>{`(${t("PAGE_OPTIONS.SAVE")} ${option.marketing_discount}%)`}</strong>
        ) : null}
      </Box>
    ),
    [t],
  );

  const MoreOptionSelector = useCallback(
    () => (
      <Box whiteSpace="pre">
        <strong>{t("COMMON.MORE")}</strong>
      </Box>
    ),
    [t],
  );

  const pagesPerMonthOptions: Option[] = useMemo(
    () =>
      listPageOptions
        ?.map((option) => ({
          value: option.monthly_pages.toString(),
          label: <StandardOptionSelector option={option} />,
        }))
        .concat({
          value: "more",
          label: <MoreOptionSelector />,
        }) ?? [],
    [MoreOptionSelector, StandardOptionSelector, listPageOptions],
  );

  function handleClearOnClosure() {
    dispatch(clearChargeAmount());
    dispatch(clearListPageOptions());

    handleClosure(true);
  }

  function handleSuccess() {
    dispatch(getCurrentPlan());
    dispatch(getCustomerInfo());
    dispatch(getCreditDetails());
    dispatch(getCurrentCredit());
    dispatch(getAccountDetails());

    handleClearOnClosure();
  }

  function handleConfirmation() {
    if (canChargeCart) {
      chargeCart();
    } else if (creditNeeded) {
      dispatch(setCustomPaymentAmount(creditNeeded));
      isShowAddCreditModalOpenBool.setTrue();
    } else {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }

  function handleSalesRequest() {
    const destinations = isUsCa ? t("DASHBOARD.US_CA") : t("DASHBOARD.ALL_COUNTRIES");

    dispatch(
      corporateAsyncActions.requestUpgrade({
        name: accountData.name,
        email: accountData.email,
        destinations,
        requestedPages,
      }),
    );
    enqueueSnackbar(t("DASHBOARD.UPGRADE_SUCCESS"), { variant: "success" });
    handleClearOnClosure();
  }

  function handleAddCredit(isSuccess: boolean) {
    if (isSuccess) {
      chargeCart();
    }

    isShowAddCreditModalOpenBool.setFalse();
  }

  const handleClosureChargeError = (shouldCloseModal: boolean) => {
    if (shouldCloseModal) {
      forceRemount();
      setOpenChargeError(false);
    } else {
      handleClearOnClosure();
    }
  };

  useEffect(() => {
    // Get the new cart id for this selection
    if (!Number.isNaN(pages)) {
      dispatch(getPageOptionsId({ pages, usCa: isUsCa, applyNow: true }));
    }
  }, [dispatch, pages, isUsCa]);

  useEffect(() => {
    // Fetch the list of available pages whenever isUsCa changes
    if (!isMaximumOption) dispatch(getListPageOptions({ usCa: isUsCa }));
  }, [dispatch, isMaximumOption, isUsCa]);

  useEffect(() => {
    // Set the default value for the required pages per month input
    if (!pagesPerMonth && pagesPerMonthOptions.length) {
      setPagesPerMonth(pagesPerMonthOptions[0]);
    }
  }, [pagesPerMonth, pagesPerMonthOptions, setPagesPerMonth]);

  useEffect(() => {
    if (listPageOptions === null) {
      // Whenever we get a new list of pages, remove the cached React Select value (it can be from the previous listPageOptions)
      setPagesPerMonth(undefined);
    }
  }, [listPageOptions, setPagesPerMonth]);

  // Wait for the data to be ready before displaying the component
  useEffect(() => {
    if (status === "INIT" && isLoading) {
      setStatus("FETCHING");
    } else if (status === "FETCHING" && !isLoading) {
      setStatus("READY");
    } else if (status === "READY" && !isLoading) {
      setIsMaximumOption(pagesPerMonth?.value === "more" || pagesPerMonthOptions?.length === 0);
    }
  }, [isLoading, pagesPerMonth?.value, pagesPerMonthOptions?.length, status]);

  useEffect(() => {
    setOpenChargeError(true);
  }, [isChargeError]);

  useOnMountCondition(() => {
    if (!pagesPerMonthOptions[0]) {
      return;
    }
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartInitalization,
      page: "plan_upgrade",
      cart: {
        faxPlan: {
          planType: "ENTERPRISE",
          period: currenPlanPeriod,
          highVolumePages: parseInt(pagesPerMonthOptions[0].value),
        },
        totalPrice: undefined,
      },
    });
  }, Boolean(pagesPerMonthOptions[0]));

  useOnMountCondition(() => {
    if (!pagesPerMonth?.value) {
      return;
    }
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartPurchaseSuccess,
      page: "plan_upgrade",
      cart: {
        faxPlan: {
          planType: "ENTERPRISE",
          period: currenPlanPeriod,
          highVolumePages: parseInt(pagesPerMonth.value),
        },
        faxNumbers: {
          totalCount: currentCart.items.random.length + currentCart.items.custom.length,
          customCount: currentCart.items.custom.length,
        },
        totalPrice: currentCart.total_amount,
      },
    });
  }, Boolean(isChargeSuccess));

  useOnMountCondition(
    () => {
      if (!pagesPerMonth?.value) {
        return;
      }
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.cartPurchaseFailed,
        page: "plan_upgrade",
        cart: {
          faxPlan: {
            planType: "ENTERPRISE",
            period: currenPlanPeriod,
            highVolumePages: parseInt(pagesPerMonth.value),
          },
          faxNumbers: {
            totalCount: currentCart.items.random.length + currentCart.items.custom.length,
            customCount: currentCart.items.custom.length,
          },
          totalPrice: currentCart.total_amount,
        },
      });
    },
    Boolean(isChargeError) || Boolean(isPaymentDepositError),
  );

  const isConfirmMaximumOptionDisabled = !requestedPages || requestedPages === 0;
  const isConfirmRegularOptionDisabled = isLoading || !Boolean(pageOptionsId);
  const isConfirmDisabled = isMaximumOption
    ? isConfirmMaximumOptionDisabled
    : isConfirmRegularOptionDisabled;

  if (status !== "READY") {
    return null;
  }

  return (
    <Box key={key}>
      <Modal
        isModalDisabled={isLoading}
        isConfirmLoading={isLoading}
        data-cy={dataCy.highVolumePlanModal}
        onCancel={() => handleClosure(false)}
        isConfirmDisabled={isConfirmDisabled}
        onConfirm={isMaximumOption ? handleSalesRequest : handleConfirmation}
        title={
          <Typography type="modalTitle">
            {t("PAGE_OPTIONS.TITLE")}
            <Box
              ml={1}
              component="a"
              target="_blank"
              rel="noreferrer"
              href={helpCenterUrls.massFaxing}
            >
              <Helper size="lg" variant={"dark"} />
            </Box>
          </Typography>
        }
        confirmTitle={
          isMaximumOption
            ? t("PAGE_OPTIONS.MAXIMUM_CONFIRM_TITLE")
            : t("PAGE_OPTIONS.CONFIRM_TITLE")
        }
      >
        <Box p={3}>
          {isMaximumOption ? <MoreOptionInfo /> : <ModalInfo />}
          <Box mt={3}>
            <Destinations isUsCa={isUsCa} isUsCaBool={isUsCaBool} />
            {isMaximumOption ? null : (
              <FormRowReactSelectInput
                labelGridWidth={4}
                id="required_pages_per_month"
                label={t("PAGE_OPTIONS.REQUIRED_PAGES")}
                ReactSelectProps={{
                  placeholder: "",
                  maxMenuHeight: 245,
                  isSearchable: true,
                  value: pagesPerMonth,
                  onChange: setPagesPerMonth,
                  options: pagesPerMonthOptions,
                }}
              />
            )}

            {isMaximumOption ? <MoreOption onCustomPages={setRequestedPages} /> : null}
          </Box>
        </Box>
        {isMaximumOption ? null : <PlanOption pages={pages} />}
        {isMaximumOption ? null : <FooterInfo pageOptionsId={pageOptionsId} />}
      </Modal>

      {isShowAddCreditModalOpen ? (
        <AddCreditModal
          reason="change_plan"
          withSuccessModal={false}
          handleClosure={handleAddCredit}
        />
      ) : null}

      {isChargeSuccess ? (
        <TransactionSuccess
          handleClosure={handleSuccess}
          title={t("PAGE_OPTIONS.TITLE")}
          description={t("PAGE_OPTIONS.SUCCESS", {
            pages: pages.toLocaleString(),
          })}
        />
      ) : null}

      {openChargeError ? <ChargeError handleClosure={handleClosureChargeError} /> : null}
    </Box>
  );
}

export default UpgradeEnterpriseModal;
