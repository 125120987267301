import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Box, Modal, Typography, makeStyles } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

import { boxNames } from "enums/faxes";
import { helpCenterUrls } from "routes/urls";
import ContactUsModal from "views/ContactUs/ContactUsModal";
import Footer from "./components/Footer";

function AccountBlockedModal({ handleClosure }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [wasMessageSent, setWasMessageSent] = useState(false);
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState(false);

  const handleContactUs = useCallback((isSuccess) => {
    setWasMessageSent(isSuccess);
    setContactUsModalIsOpen(false);
  }, []);

  useEffect(() => {
    history.push(`/faxes/${boxNames.inbox}`);
  }, [history]);

  const handleLearnMore = () => {
    window.open(helpCenterUrls.blockedAccount, "_blank")?.focus();
  };

  return (
    <Modal
      maxWidth="xs"
      hasCloseIcon={false}
      onConfirm={handleLearnMore}
      isConfirmDisabled={wasMessageSent}
      title={t("PROFILE.ACCOUNT_BLOCKED")}
      confirmTitle={t("COMMON.LEARN_MORE")}
      customFooterInfo={
        <Footer
          isConfirmDisabled={wasMessageSent}
          onLogout={() => handleClosure(false)}
          onContactUs={() => setContactUsModalIsOpen(true)}
        />
      }
    >
      <Box className={classes.wrapper}>
        <FontAwesomeIcon className={classes.icon} size="6x" icon={faExclamationCircle} />
        <Box textAlign="center">
          <Typography variant="body">{t("PROFILE.ACCOUNT_BLOCKED_DESCRIPTION_FIRST")}</Typography>
          <Typography variant="body">{t("PROFILE.ACCOUNT_BLOCKED_DESCRIPTION_SECOND")}</Typography>
        </Box>
      </Box>

      {contactUsModalIsOpen ? <ContactUsModal blocked handleClosure={handleContactUs} /> : null}

      {wasMessageSent ? (
        <Typography variant="body" align="center" className={classes.text}>
          {t("CONTACT_US.SUCCESS")}
        </Typography>
      ) : null}
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing, palette, alohi }) => ({
  wrapper: {
    display: "flex",
    padding: spacing(3),
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    color: alohi.gold,
    marginBottom: spacing(3),
  },
  text: {
    marginBottom: spacing(2),
    color: palette.success.main,
  },
}));

AccountBlockedModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default AccountBlockedModal;
