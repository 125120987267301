import { useSelector } from "react-redux";

import { selectAccount } from "selectors/account.selector";
import { selectAllNumbers } from "selectors/numbers.selector";
import MyFaxPanel from "components/Profile/General/MyFaxPanel";
import MyInfoPanel from "components/Profile/General/MyInfoPanel";
import { selectCanISeeCompanyInfo } from "selectors/ability.selector";
import EmailToFaxPanel from "components/Profile/General/EmailToFaxPanel";
import CompanyInfoPanel from "components/Profile/General/CompanyInfoPanel";
import NotificationsPanel from "components/Profile/General/NotificationsPanel";

function General() {
  const account = useSelector(selectAccount);
  const accountNumbers = useSelector(selectAllNumbers);
  const canISeeCompanyInfo = useSelector(selectCanISeeCompanyInfo);

  return (
    <>
      {account ? <MyInfoPanel /> : null}
      {canISeeCompanyInfo && Boolean(account && accountNumbers) ? <CompanyInfoPanel /> : null}
      <MyFaxPanel />
      <EmailToFaxPanel />
      <NotificationsPanel />
    </>
  );
}

export default General;
