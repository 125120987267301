import { useDropzone } from "react-dropzone";
import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, SplitButton, Box, useSnackbar } from "@alohi/kit";

import dataCy from "enums/dataCy";
import { fileToFaxFile } from "helpers/file";
import MenuItems from "views/SendFax/components/MenuItems";
import { SendFaxFiles } from "views/SendFax/contexts/store";
import { faxAllowedFileTypes } from "enums/allowedFileTypes";
import CoverSheets from "views/SendFax/components/CoverSheets";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { useSendFaxHelpers } from "views/SendFax/contexts/helpers";
import DragAndDropList from "components/DragAndDrop/DragAndDropList";
import DragAndDropOverlay from "components/DragAndDrop/DragAndDropOverlay";

interface AddFilesProps {
  actionButtons: ReactNode;
}

function AddFiles({ actionButtons }: AddFilesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { addFiles } = useSendFaxHelpers();
  const {
    sendFaxStore: { files },
    sendFaxDispatch,
  } = useSendFaxContext();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (rejectedFiles?.length > 0) {
        enqueueSnackbar(t("FORMS.FILE_TYPE_NOT_ALLOWED"), { variant: "error" });
      }

      if (acceptedFiles?.length > 0) {
        let hasAtLeastOneError = false;
        const promises = acceptedFiles.map((file) => fileToFaxFile(file));

        const newFilesResult = await Promise.allSettled(promises);

        const newFiles = newFilesResult.reduce<SendFaxFiles>((accumulator, currentValue) => {
          if (currentValue.status === "fulfilled") {
            accumulator.push(currentValue.value);

            return accumulator;
          }

          if (currentValue.status === "rejected") {
            hasAtLeastOneError = true;
          }

          return accumulator;
        }, []);

        if (hasAtLeastOneError) {
          enqueueSnackbar(t("FORMS.FILE_TYPE_NOT_ALLOWED"), {
            variant: "error",
          });
        }

        addFiles(newFiles);
      }
    },
    [enqueueSnackbar, t, addFiles],
  );

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: faxAllowedFileTypes,
    onDrop,
    noClick: true,
  });

  return (
    <div className={classes.dropZone} {...getRootProps()}>
      {isDragActive && <DragAndDropOverlay />}
      <input
        data-cy={dataCy.sendFaxDropzoneHiddenInput}
        {...getInputProps()}
        accept={faxAllowedFileTypes.join(", ")}
      />

      <Box data-intro={t("SENT_FAX.FIRST_STEP")}>
        <SplitButton
          sx={{ mr: 2 }}
          onTitleClick={open}
          MenuItems={MenuItems}
          data-cy="AddFileButton"
          title={t("SENT_FAX.ADD_FILE")}
        />
        {actionButtons || null}
      </Box>

      <Box className={classes.files}>
        <CoverSheets />

        <DragAndDropList
          items={files}
          onReorder={(files: SendFaxFiles) =>
            sendFaxDispatch({ type: "REPLACE_FILES", payload: files })
          }
        />
      </Box>
    </div>
  );
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  dropZone: {
    flex: 1,
    width: "100%",
    display: "flex",
    minHeight: "152px",
    paddingTop: "24px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  files: {
    width: "80%",
    display: "flex",
    marginTop: "24px",
    flexDirection: "column",
    marginBottom: spacing(5),
    justifyContent: "space-around",
    [breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default AddFiles;
