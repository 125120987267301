import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  isRainbowLoading: true,
};

export const setTitle = createAction("LAYOUT/SET_TITLE");
export const setIsRainbowLoading = createAction("LAYOUT/RAINBOW");

const { reducer } = createSlice({
  name: "LAYOUT",
  initialState,
  reducers: {},
  extraReducers: {
    [setTitle]: (state, action) => {
      state.title = action.payload;
    },
    [setIsRainbowLoading]: (state, { payload }) => {
      state.isRainbowLoading = payload;
    },
  },
});

export default reducer;
