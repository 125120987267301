import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { Typography, Modal, Box, useSnackbar } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import { formatPhoneNumberIntl } from "helpers/numbers";
import { PhoneNumberFilter } from "components/Core/Filters";
import InfiniTable from "components/InfiniTable/InfiniTable";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import MemberAvatar from "components/AccountAvatar/MemberAvatar";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import { selectActiveCorporateUsers } from "selectors/corporate.selector";
import { List, Chip, ListItem, useTheme, TableRow, TableCell, makeStyles } from "ui";
import {
  selectIsNumberPatchError,
  selectIsNumberPatchLoading,
  selectIsNumberPatchSuccessful,
} from "selectors/numbers.selector";
import {
  patchNumber,
  getAllNumbers,
  clearPatchNumber,
  getAssignedNumbers,
} from "stores/reducers/numbers.reducer";

function AssignNumbersModal({ numberToAssign, onCancel, onConfirm }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const isError = useSelector(selectIsNumberPatchError);
  const isLoading = useSelector(selectIsNumberPatchLoading);
  const isSuccess = useSelector(selectIsNumberPatchSuccessful);
  const activeCorporateUsers = useSelector(selectActiveCorporateUsers);

  const [selectedRows, setSelectedRows] = useState([]);

  const renderExpandableRow = (rowData, rowMeta) => {
    const colSpan = rowData.length + 1;
    if (activeCorporateUsers[rowMeta.dataIndex]?.numbers) {
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" mx={3}>
              {activeCorporateUsers[rowMeta.dataIndex].numbers.map((num, index) => (
                <Box mr={1} mb={1} key={index + numberToAssign}>
                  <Chip
                    size="small"
                    style={{
                      margin: 2,
                      backgroundColor: theme.alohi.lightestBlue,
                    }}
                    label={<PhoneNumberFilter number={num} />}
                  />
                </Box>
              ))}
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  const defaultOptions = {
    expandableRows: true,
    expandableRowsHeader: false,
    rowsSelected: selectedRows,
    selectableRowsOnClick: true,
    isRowSelectable: () => true,
    onRowSelectionChange: (_, allRows) => {
      setSelectedRows(allRows.map((row) => row.dataIndex));
    },
    isRowExpandable: (dataIndex) => activeCorporateUsers[dataIndex]?.numbers?.length > 1,
    renderExpandableRow: renderExpandableRow,
  };

  const components = {
    ExpandButton: function Button(props) {
      if (props.isHeaderCell) {
        return <FontAwesomeIcon icon={faChevronDown} color="transparent" />;
      }

      const isRenderingArrow = activeCorporateUsers[props.dataIndex]?.numbers?.length > 1;
      const isExpanded = props.iconClass.includes("expanded");

      const handleClick = (event) => {
        event.stopPropagation();
        props.onExpand(event);
      };

      return (
        <FontAwesomeIcon
          onClick={handleClick}
          icon={isExpanded ? faChevronDown : faChevronRight}
          color={isRenderingArrow ? theme.alohi.almostGray : "transparent"}
        />
      );
    },
  };
  components.ExpandButton.propTypes = {
    isHeaderCell: PropTypes.bool,
    iconClass: PropTypes.string,
    dataIndex: PropTypes.number,
    onExpand: PropTypes.func,
  };

  const getColumns = () => [
    {
      name: "profileImage",
      label: " ",
      options: {
        customBodyRenderLite: function customBody(dataIndex) {
          return (
            <MemberAvatar
              member={{
                uid: activeCorporateUsers[dataIndex].uid,
                name: activeCorporateUsers[dataIndex].name,
                profileImage: activeCorporateUsers[dataIndex].profileImage,
              }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: t("COMMON.NAME"),
      options: {
        customBodyRenderLite: function customNameRender(dataIndex) {
          return (
            <>
              <Typography type="body" className={classes.text} stopPropagation>
                {activeCorporateUsers[dataIndex].name === "None None"
                  ? ""
                  : activeCorporateUsers[dataIndex].name}
              </Typography>
              <Typography
                type="body"
                color="textSecondary"
                className={classes.text}
                stopPropagation
              >
                {activeCorporateUsers[dataIndex].email}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "numbers",
      label: t("CONTACTS.FAX_NUMBER"),
      options: {
        customBodyRender: (value) => {
          let cellData = "";
          switch (value.length) {
            case 0:
              cellData = "";
              break;
            case 1:
              cellData = <PhoneNumberFilter number={value[0]} />;
              break;
            default:
              cellData = (
                <HelpTooltip
                  placement="bottom"
                  title={
                    <List>
                      {value.map((number, index) =>
                        index !== 0 ? (
                          <ListItem key={number}>
                            <PhoneNumberFilter number={number} />
                          </ListItem>
                        ) : null,
                      )}
                    </List>
                  }
                >
                  <Box
                    className={classes.text}
                    sx={{ display: "block" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Box sx={{ whiteSpace: "no-wrap" }} component="span">
                      <PhoneNumberFilter number={value[0]} />
                      {","}
                    </Box>
                    {t("NUMBERS.MORE_NUMBERS", { number: value.length - 1 })}
                  </Box>
                </HelpTooltip>
              );
              break;
          }
          return cellData;
        },
      },
    },
  ];

  const handleConfirm = useCallback(() => {
    const memberIds = selectedRows.map((element) => activeCorporateUsers[element].uid);

    dispatch(
      patchNumber({
        numberToAssign,
        memberIds,
      }),
    );
  }, [selectedRows, dispatch, numberToAssign, activeCorporateUsers]);

  useEffect(() => {
    !activeCorporateUsers && dispatch(corporateAsyncActions.getUsers());
  }, [activeCorporateUsers, dispatch]);

  useEffect(() => {
    if (activeCorporateUsers && numberToAssign) {
      const selected = [];
      activeCorporateUsers.forEach((user, key) => {
        if (user.numbers.includes(numberToAssign)) {
          selected.push(key);
        }
      });
      setSelectedRows(selected);
    }
  }, [numberToAssign, activeCorporateUsers]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearPatchNumber());
      dispatch(getAllNumbers());
      dispatch(getAssignedNumbers());
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      onConfirm();
    }
  }, [dispatch, isSuccess, onConfirm]);

  useEffect(() => {
    if (isError) {
      dispatch(clearPatchNumber());
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [t, enqueueSnackbar, dispatch, isError]);

  return (
    <Modal
      scroll="paper"
      withFooterDivider
      onCancel={onCancel}
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
      isConfirmDisabled={!(selectedRows.length || isLoading)}
      title={`${t("NUMBERS.MANAGE_ASSIGN")} (${formatPhoneNumberIntl(numberToAssign)})`}
      confirmTooltip={
        !selectedRows.length && t("NUMBERS.NUMBER_SHOULD_BE_ASSIGNED_TO_AT_LEAST_ONE_USER")
      }
    >
      <InfiniTable
        components={components}
        options={defaultOptions}
        data={activeCorporateUsers}
        columns={getColumns()}
        isLoading={!activeCorporateUsers}
      />
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "text",
    width: "max-content",
  },
}));

AssignNumbersModal.propTypes = {
  onCancel: PropTypes.func,
  numberToAssign: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default AssignNumbersModal;
