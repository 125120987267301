import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs, TextField, FaButton, Box, Modal } from "@alohi/kit";
import {
  faUsers,
  faSearch,
  faHistory,
  faUserCheck,
  faChartNetwork,
} from "@fortawesome/pro-light-svg-icons";

import { Typography } from "ui";
import { useInput } from "hooks/useInput";
import Recent from "components/Contacts/Recent";
import Groups from "components/Contacts/Groups";
import Whitelist from "components/Contacts/Whitelist";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { Destination } from "views/SendFax/contexts/store";
import SharedGroups from "components/Contacts/SharedGroups";
import { useAppDispatch, useAppSelector } from "stores/store";
import SharedDropdown from "components/Contacts/SharedDropdown";
import SharedContacts from "components/Contacts/SharedContacts";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import { Contact, Group, RecentContact } from "stores/reducers/contacts.helpers";
import {
  clearRecentContacts,
  updateRecentContactsSelection,
} from "stores/reducers/contacts.recent.reducer";
import {
  getGroups,
  clearContactsGroups,
  updateGroupSelection,
} from "stores/reducers/contacts.groups.reducer";
import {
  selectGroups,
  selectSharedGroups,
  selectRecentContacts,
  selectSharedContacts,
  selectGroupSelection,
  selectWhitelistContacts,
  selectSharedGroupsSelection,
  selectSharedContactsSelection,
  selectRecentContactsSelection,
  selectWhitelistContactsSelection,
} from "selectors/contacts.selector";
import {
  clearWhitelistContacts,
  getWhitelistContacts,
  updateWhitelistContactsSelection,
} from "stores/reducers/contacts.whitelist.reducer";
import {
  clearSharedGroups,
  getSharedGroups,
  updateSharedGroupSelection,
} from "stores/reducers/contacts.sharedGroups.reducer";
import {
  clearSharedContacts,
  getSharedContacts,
  updateSharedContactsSelection,
} from "stores/reducers/contacts.sharedContacts.reducer";

const VIEWS = {
  NAMES: "names",
  RECENT: "recent",
  GROUPS: "groups",
  SHARED_GROUPS: "sharedGroups",
  SHARED_CONTACTS: "sharedContacts",
};

interface AddFromContactsModalProps {
  handleClosure: () => void;
}

function AddFromContactsModal({ handleClosure }: AddFromContactsModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { sendFaxDispatch } = useSendFaxContext();
  const { handleGroupDestination, handleContactDestination, handleRecentDestination } =
    useSendFaxDestinationHelpers();

  const groups = useAppSelector<null | Group[]>(selectGroups);
  const sharedGroups = useAppSelector<null | Group[]>(selectSharedGroups);
  const contacts = useAppSelector<null | Contact[]>(selectWhitelistContacts);
  const recent = useAppSelector<null | RecentContact[]>(selectRecentContacts);
  const sharedContacts = useAppSelector<null | Contact[]>(selectSharedContacts);

  const groupsSelection = useAppSelector<string[]>(selectGroupSelection);
  const recentSelection = useAppSelector<string[]>(selectRecentContactsSelection);
  const sharedGroupsSelection = useAppSelector<string[]>(selectSharedGroupsSelection);
  const contactsSelection = useAppSelector<string[]>(selectWhitelistContactsSelection);
  const sharedWhitelistSelection = useAppSelector<string[]>(selectSharedContactsSelection);

  const [namesSearch, namesSearchInput] = useInput("");
  const [groupsSearch, groupsSearchInput] = useInput("");
  const [sharedGroupsSearch, sharedGroupsSearchInput] = useInput("");
  const [sharedContactsSearch, sharedContactsSearchInput] = useInput("");

  const [selectedTab, setSelectedTab] = useState(VIEWS.RECENT);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const totalSelectedCount = useMemo(() => {
    let total = 0;

    total += groupsSelection.length;
    total += recentSelection.length;
    total += contactsSelection.length;
    total += sharedGroupsSelection.length;
    total += sharedWhitelistSelection.length;

    return total;
  }, [
    groupsSelection.length,
    recentSelection.length,
    contactsSelection.length,
    sharedGroupsSelection.length,
    sharedWhitelistSelection.length,
  ]);

  const handleClearContacts = useCallback(() => {
    dispatch(clearWhitelistContacts());
    dispatch(clearSharedGroups());
    dispatch(clearSharedContacts());
    dispatch(clearRecentContacts());
    dispatch(clearContactsGroups());
  }, [dispatch]);

  const handleClearSelection = useCallback(() => {
    dispatch(updateGroupSelection([]));
    dispatch(updateSharedGroupSelection([]));
    dispatch(updateSharedContactsSelection([]));
    dispatch(updateRecentContactsSelection([]));
    dispatch(updateWhitelistContactsSelection([]));
  }, [dispatch]);

  function onConfirm() {
    const allValues: Destination[] = [];

    if (groupsSelection.length) {
      const map = new Map<string, Group>();
      groups?.forEach((group) => map.set(group.id, group));

      groupsSelection.forEach((id) => {
        const group = map.get(id);

        if (group) {
          allValues.push(handleGroupDestination(group, "group"));
        }
      });
    }

    if (contactsSelection.length) {
      const map = new Map<string, Contact>();
      contacts?.forEach((contact) => map.set(contact.id, contact));

      contactsSelection.forEach((id) => {
        const contact = map.get(id);

        if (contact) {
          allValues.push(handleContactDestination(contact, "contact"));
        }
      });
    }

    if (recentSelection.length) {
      const map = new Map<string, RecentContact>();
      recent?.forEach((entry) => map.set(entry.id, entry));

      recentSelection.forEach((id) => {
        const entry = map.get(id);

        if (entry) {
          allValues.push(handleRecentDestination(entry));
        }
      });
    }

    if (sharedWhitelistSelection.length) {
      const map = new Map<string, Contact>();
      sharedContacts?.forEach((contact) => map.set(contact.id, contact));

      sharedWhitelistSelection.forEach((id) => {
        const contact = map.get(id);

        if (contact) {
          allValues.push(handleContactDestination(contact, "sharedContact"));
        }
      });
    }

    if (sharedGroupsSelection.length) {
      const map = new Map<string, Group>();
      sharedGroups?.forEach((group) => map.set(group.id, group));

      sharedGroupsSelection.forEach((id) => {
        const group = map.get(id);

        if (group) {
          allValues.push(handleGroupDestination(group, "sharedGroup"));
        }
      });
    }

    sendFaxDispatch({
      type: "ADD_DESTINATION",
      payload: allValues,
    });

    handleClosure();
  }

  const handleNameSearch = useCallback(() => {
    dispatch(
      getWhitelistContacts({
        offset: 0,
        type: "json",
        shared: false,
        search: namesSearch,
      }),
    );
  }, [dispatch, namesSearch]);

  const handleGroupSearch = useCallback(() => {
    dispatch(
      getGroups({
        offset: 0,
        type: "json",
        shared: false,
        search: groupsSearch,
      }),
    );
  }, [dispatch, groupsSearch]);

  const handleSharedGroupsSearch = useCallback(() => {
    dispatch(
      getSharedGroups({
        offset: 0,
        type: "json",
        shared: true,
        search: sharedGroupsSearch,
      }),
    );
  }, [dispatch, sharedGroupsSearch]);

  const handleSharedContactsSearch = useCallback(() => {
    dispatch(
      getSharedContacts({
        offset: 0,
        type: "json",
        shared: true,
        search: sharedContactsSearch,
      }),
    );
  }, [dispatch, sharedContactsSearch]);

  useOnPressEnter(() => {
    if (namesSearchInput.isFocused) handleNameSearch();
    if (groupsSearchInput.isFocused) handleGroupSearch();
    if (sharedGroupsSearchInput.isFocused) handleSharedGroupsSearch();
    if (sharedContactsSearchInput.isFocused) handleSharedContactsSearch();
  });

  useEffect(() => {
    return () => {
      // Clear checkboxes when this components unmounts
      // Otherwise we might have some items selected once we go to Contacts view
      handleClearSelection();
      handleClearContacts();
    };
  }, [handleClearContacts, handleClearSelection]);

  const sharedURLValue =
    selectedTab === VIEWS.SHARED_GROUPS ? VIEWS.SHARED_GROUPS : VIEWS.SHARED_CONTACTS;

  return (
    <>
      <Modal
        scroll="paper"
        minHeight="70vh"
        disableRestoreFocus
        onConfirm={onConfirm}
        onCancel={handleClosure}
        onPressEscape={handleClosure}
        isConfirmDisabled={!totalSelectedCount}
        title={t("CONTACTS.ADD_FROM_CONTACTS")}
        customFooterInfo={
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography variant="body">{totalSelectedCount}/500</Typography>
          </Box>
        }
      >
        <Tabs
          value={selectedTab}
          orientation="horizontal"
          onChange={(_, tab) => {
            if (tab !== VIEWS.SHARED_GROUPS && tab !== VIEWS.SHARED_CONTACTS) {
              setSelectedTab(tab);
            }
          }}
        >
          <Tabs.Tab
            value={VIEWS.RECENT}
            label={t("CONTACTS.RECENT")}
            icon={<FontAwesomeIcon icon={faHistory} size="lg" />}
          />
          <Tabs.Tab
            value={VIEWS.NAMES}
            label={t("CONTACTS.NAMES")}
            icon={<FontAwesomeIcon icon={faUserCheck} size="lg" />}
          />
          <Tabs.Tab
            value={VIEWS.GROUPS}
            label={t("CONTACTS.GROUPS")}
            icon={<FontAwesomeIcon icon={faUsers} size="lg" />}
          />
          <Tabs.Tab
            label={t("CONTACTS.SHARED")}
            value={sharedURLValue}
            icon={<FontAwesomeIcon icon={faChartNetwork} size="lg" />}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          />
        </Tabs>

        {
          {
            [VIEWS.NAMES]: (
              <>
                <Box mx={4} my={3}>
                  <TextField
                    fullWidth
                    value={namesSearch}
                    onChange={namesSearchInput.onChange}
                    onBlur={namesSearchInput.onBlur}
                    onFocus={namesSearchInput.onFocus}
                    placeholder={t("COMMON.SEARCH")}
                    InputProps={{
                      startAdornment: <FaButton onClick={handleNameSearch} icon={faSearch} />,
                    }}
                  />
                </Box>
                <Whitelist hasActions={false} noBorder canShare={false} areTabsClickable />
              </>
            ),
            [VIEWS.GROUPS]: (
              <>
                <Box mx={4} my={3}>
                  <TextField
                    fullWidth
                    value={groupsSearch}
                    placeholder={t("COMMON.SEARCH")}
                    onBlur={groupsSearchInput.onBlur}
                    onFocus={groupsSearchInput.onFocus}
                    onChange={groupsSearchInput.onChange}
                    InputProps={{
                      startAdornment: <FaButton onClick={handleGroupSearch} icon={faSearch} />,
                    }}
                  />
                </Box>
                <Groups hasActions={false} noBorder canShare={false} showEmptyGroups={false} />
              </>
            ),
            [VIEWS.RECENT]: <Recent hasActions={false} noBorder hasSelection areTabsClickable />,
            [VIEWS.SHARED_GROUPS]: (
              <>
                <Box mx={4} my={3}>
                  <TextField
                    fullWidth
                    value={sharedGroupsSearch}
                    placeholder={t("COMMON.SEARCH")}
                    onBlur={sharedGroupsSearchInput.onBlur}
                    onFocus={sharedGroupsSearchInput.onFocus}
                    onChange={sharedGroupsSearchInput.onChange}
                    InputProps={{
                      startAdornment: (
                        <FaButton onClick={handleSharedGroupsSearch} icon={faSearch} />
                      ),
                    }}
                  />
                </Box>
                <SharedGroups
                  hasActions={false}
                  noBorder
                  hasSelection
                  showEmptyGroups={false}
                  areTabsClickable
                />
              </>
            ),
            [VIEWS.SHARED_CONTACTS]: (
              <>
                <Box mx={4} my={3}>
                  <TextField
                    fullWidth
                    value={sharedContactsSearch}
                    placeholder={t("COMMON.SEARCH")}
                    onBlur={sharedContactsSearchInput.onBlur}
                    onFocus={sharedContactsSearchInput.onFocus}
                    onChange={sharedContactsSearchInput.onChange}
                    InputProps={{
                      startAdornment: (
                        <FaButton onClick={handleSharedContactsSearch} icon={faSearch} />
                      ),
                    }}
                  />
                </Box>
                <SharedContacts hasActions={false} noBorder hasSelection areTabsClickable />
              </>
            ),
          }[selectedTab]
        }
      </Modal>

      {anchorEl ? (
        <SharedDropdown
          anchorEl={anchorEl}
          clearAnchorEl={() => setAnchorEl(null)}
          onSelection={(type) => {
            if (type === "sharedContacts") {
              setSelectedTab(VIEWS.SHARED_CONTACTS);
            } else if (type === "sharedGroups") {
              setSelectedTab(VIEWS.SHARED_GROUPS);
            }
          }}
        />
      ) : null}
    </>
  );
}

export default AddFromContactsModal;
