import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history } from "../../helpers";
import RS from "../../enums/requestStatus";
import { boxNames } from "../../enums/faxes";
import { historyApi, faxConfirmationApi, numbersApi, storageApi, syncHistoryApi } from "../../api";
import { logout } from "./authentication.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  fetchBox: initialRequestState,
  fetchBoxMore: initialRequestState,
  fetchThumbnails: initialRequestState,
  previewFile: initialRequestState,
  previewReport: initialRequestState,
  download: initialRequestState,
  moveToTrash: initialRequestState,
  restore: initialRequestState,
  markAsRead: initialRequestState,
  markAsUnread: initialRequestState,
  addNoteOnFax: initialRequestState,
  addSharedNoteOnFax: initialRequestState,
  deleteFax: initialRequestState,
  fetchCdrsByIds: initialRequestState,
  cdrToken: initialRequestState,

  selectedNumber: null,
  selectedMemberUid: null,

  fileViewer: {
    isOpen: false,
    downloadName: null,
    fileUrl: null,
    boxName: null,
    cdrId: null,
    isFaxReport: null,
  },
};

export const clearCdrToken = createAction("NUMBERS/CLEAR_CDR_TOKEN");

const fetchBox = createAsyncThunk(
  "FAXES/FETCH_BOX",
  async ({ boxName, fetchOptions: fetchOptionsOverrides }, { getState, rejectWithValue }) => {
    const state = getState();
    const fetchOptions = {
      ...state[boxName].currentFetchOptions,
      ...fetchOptionsOverrides,
    };
    try {
      const response = await historyApi.get(fetchOptions);
      return { boxName, response, fetchOptions };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().faxes.fetchBox.status !== RS.RUNNING;
    },
  },
);

const fetchBoxMore = createAsyncThunk(
  "FAXES/FETCH_BOX_MORE",
  async ({ boxName }, { getState, rejectWithValue }) => {
    const boxState = getState()[boxName];
    if (!boxState.hasMore) {
      return { boxName };
    }
    const fetchOptions = {
      ...boxState.currentFetchOptions,
    };
    const recordsLength = boxState?.records?.length ?? 0;

    if (
      boxState.currentFetchOptions?.sortBy ||
      boxState.currentFetchOptions?.status ||
      boxState.currentFetchOptions?.searchPhrase
    ) {
      fetchOptions.offset = recordsLength;
    } else {
      fetchOptions.before = boxState.records?.[recordsLength - 1]?.date ?? null;
    }
    try {
      const response = await historyApi.get(fetchOptions);
      return { boxName, response, fetchOptions };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const fetchThumbnails = createAsyncThunk(
  "FAXES/FETCH_THUMBNAILS",
  async ({ boxName, fetchOptions = {} }, { getState, rejectWithValue }) => {
    const box = getState()[boxName];
    const records = box.records;
    const currentThumbnails = box.thumbnails;

    if (records) {
      const thumbnailsToFetch = records
        .map(({ id }) => id)
        .filter((id) => !currentThumbnails?.[id]);
      if (thumbnailsToFetch.length) {
        try {
          const response = await storageApi.getThumbnailsByNameList(
            {
              ids: thumbnailsToFetch,
              operation: boxName === boxNames.outbox ? "fax_thumbnail" : "cdr_thumbnail",
            },
            fetchOptions,
          );
          return { boxName, response };
        } catch (error) {
          return rejectWithValue(error.origin);
        }
      }
    }
    return { boxName };
  },
);

const previewFile = createAsyncThunk(
  "FAXES/PREVIEW_FILE",
  async (
    { boxName, cdrId, fetchOptions = {}, downloadFileType = "pdf", excludeDeleted },
    { getState, rejectWithValue },
  ) => {
    let cdr;
    if (!boxName) {
      const { result } = await historyApi.getByIds({ cdrIds: [cdrId] });
      cdr = result[0];
    } else {
      const state = getState();
      cdr = state[boxName].records.find((record) => record.id === cdrId);
    }

    if (!cdr) return rejectWithValue("cdrNotFound");

    if (excludeDeleted && cdr.is_deleted) {
      if (["user", "trash_permanent"].includes(cdr.delete_reason)) {
        return rejectWithValue("isPermanentlyDeleted");
      } else return rejectWithValue("isInTrash");
    }

    const downloadName =
      boxName === boxNames.outbox ? "fax-in-outbox" : cdr.track[0].data.file_name;
    try {
      let response = await storageApi.download({
        file_type: downloadFileType,
        cdrId,
        idType: boxName === boxNames.outbox ? "fax" : "cdr",
        ...fetchOptions,
      });
      return { fileUrl: URL.createObjectURL(response), downloadName, boxName, cdrId };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const previewReport = createAsyncThunk(
  "FAXES/PREVIEW_REPORT",
  async ({ boxName, cdrId }, { getState, rejectWithValue }) => {
    const state = getState();
    const cdr = state[boxName].records.find((record) => record.id === cdrId);
    if (!cdr) return;

    const downloadName = cdr.track[0].data.file_name;
    try {
      const response = await faxConfirmationApi.getByCdrId(cdrId);
      return { fileUrl: URL.createObjectURL(response), downloadName, boxName, cdrId };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const download = createAsyncThunk(
  "FAXES/DOWNLOAD",
  async ({ boxName, downloadFileType = "pdf", cdrId }, { getState, rejectWithValue }) => {
    const state = getState();
    const selectedMemberUid = state.faxes.selectedMemberUid;

    let cdr;
    if (!boxName) {
      const { result } = await historyApi.getByIds({ cdrIds: [cdrId] });
      cdr = result[0];
    } else {
      cdr = state[boxName].records.find((record) => record.id === cdrId);
    }
    if (!cdr) return;

    const downloadName =
      boxName === boxNames.outbox ? "fax-in-outbox" : cdr.track[0].data.file_name;

    try {
      const response = await storageApi.download({
        cdrId,
        download_name: downloadName,
        file_type: downloadFileType,
        idType: boxName === boxNames.outbox ? "fax" : "cdr",
        ...(selectedMemberUid && { uid: selectedMemberUid }),
      });
      return {
        fileUrl: URL.createObjectURL(response),
        downloadName: downloadName,
      };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      const { download } = getState().faxes;
      return download.status !== RS.RUNNING;
    },
  },
);

const moveToTrash = createAsyncThunk(
  "FAXES/MOVE_TO_TRASH",
  async ({ boxName, cdrIds }, { rejectWithValue }) => {
    try {
      const response = await syncHistoryApi.moveToTrashByIds(cdrIds);
      return { boxName, response, cdrIds };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const restore = createAsyncThunk(
  "FAXES/RESTORE",
  async ({ boxName, cdrIds }, { rejectWithValue }) => {
    try {
      const response = await syncHistoryApi.restoreFromTrashByIds(cdrIds);
      return { boxName, response, cdrIds };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const markAsRead = createAsyncThunk(
  "FAXES/MARK_AS_READ",
  async ({ boxName, cdrIds }, { rejectWithValue }) => {
    try {
      const response = await syncHistoryApi.markAsReadByIds(cdrIds);
      return { boxName, response, cdrIds };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: ({ boxName }) => {
      return boxName !== boxNames.outbox;
    },
  },
);

const markAsUnread = createAsyncThunk(
  "FAXES/MARK_AS_UNREAD",
  async ({ boxName, cdrIds }, { rejectWithValue }) => {
    try {
      const response = await syncHistoryApi.markAsUnreadByIds(cdrIds);
      return { boxName, response, cdrIds };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getCdrToken = createAsyncThunk(
  "FAXES/GET_CDR_TOKEN",
  async ({ boxName, cdrId }, { rejectWithValue }) => {
    try {
      const response = await storageApi.getCdrToken({ cdrId });
      return { boxName, response, cdrId };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const addNoteOnFax = createAsyncThunk(
  "FAXES/ADD_NOTE_ON_FAX",
  async ({ boxName, cdrId, note }, { rejectWithValue }) => {
    let response;
    try {
      if (boxName === boxNames.outbox) {
        response = await numbersApi.putCommentByCdrId(cdrId, note);
      } else {
        response = await syncHistoryApi.commentById(cdrId, note);
      }
      return { cdrId, boxName, response, note };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const addSharedNoteOnFax = createAsyncThunk(
  "FAXES/ADD_SHARED_NOTE_ON_FAX",
  async ({ boxName, cdrId, note }, { rejectWithValue }) => {
    try {
      const response = await syncHistoryApi.sharedNoteById(cdrId, note);
      return { cdrId, boxName, response, note };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const deleteFax = createAsyncThunk(
  "FAXES/DELETE_FAX",
  async ({ boxName, cdrIds }, { rejectWithValue }) => {
    let response;
    try {
      if (boxName === boxNames.outbox) {
        response = await numbersApi.deleteByCdrIds(cdrIds);
      } else {
        response = await syncHistoryApi.deleteByIds(cdrIds);
      }
      return { boxName, cdrIds, response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const reply = createAction("FAXES/REPLY", ({ number, cdrId }) => ({
  payload: { number, cdrId },
}));
export const forward = createAction("FAXES/FORWARD", ({ cdrId }) => ({ payload: { cdrId } }));
export const resend = createAction("FAXES/RESEND", ({ cdrId }) => ({ payload: { cdrId } }));

export const clearRestore = createAction("FAXES/CLEAR_RESTORE");
export const clearDeleteFax = createAction("FAXES/CLEAR_DELETE_FAX");
export const clearMoveToTrash = createAction("FAXES/CLEAR_MOVE_TO_TRASH");
export const clearPreviewFile = createAction("FAXES/CLEAR_PREVIEW_FILE");

export const toggleCdrCheckbox = createAction("FAXES/TOGGLE_CDR_CHECKBOX");
export const setAllCdrCheckbox = createAction("FAXES/SET_ALL_CDR_CHECKBOX");

const { actions, reducer } = createSlice({
  name: "FAXES",
  initialState,
  reducers: {
    SET_SELECTED_NUMBER: (state, action) => {
      state.selectedNumber = action.payload;
      if (action.payload) {
        localStorage.setItem("archive_selected_number", action.payload);
      } else {
        localStorage.removeItem("archive_selected_number");
      }
    },
    SET_SELECTED_MEMBER_UID: (state, action) => {
      state.selectedMemberUid = action.payload;
      if (action.payload) {
        localStorage.setItem("archive_selected_member", action.payload);
      } else {
        localStorage.removeItem("archive_selected_member");
      }
    },
    SET_FILE_VIEWER: (state, { payload }) => {
      state.fileViewer = { ...state.fileViewer, ...payload };
    },
  },
  extraReducers: {
    [reply]: (_, { payload }) => {
      !!payload?.number && history.push(`/reply-fax/${payload.number}/${payload.cdrId}`);
    },
    [forward]: (_, { payload }) => {
      !!payload?.cdrId && history.push(`/forward-fax/${payload.cdrId}`);
    },
    [resend]: (_, { payload }) => {
      !!payload?.cdrId && history.push(`/resend-fax/${payload.cdrId}`);
    },
    [fetchBox.pending]: (state) => {
      state.fetchBox.status = RS.RUNNING;
      state.fetchBox.error = null;
    },
    [fetchBox.fulfilled]: (state, { payload }) => {
      state.fetchBox.status = RS.IDLE;
      state.fetchBox.response = payload.response;
    },
    [fetchBox.rejected]: (state, { payload }) => {
      state.fetchBox.status = RS.ERROR;
      state.fetchBox.error = payload;
    },
    [fetchBoxMore.pending]: (state) => {
      state.fetchBoxMore.status = RS.RUNNING;
      state.fetchBoxMore.error = null;
    },
    [fetchBoxMore.fulfilled]: (state, { payload }) => {
      state.fetchBoxMore.status = RS.IDLE;
      state.fetchBoxMore.response = payload.response;
    },
    [fetchBoxMore.rejected]: (state, { payload }) => {
      state.fetchBoxMore.status = RS.ERROR;
      state.fetchBoxMore.error = payload;
    },
    [fetchThumbnails.pending]: (state) => {
      state.fetchThumbnails.status = RS.RUNNING;
      state.fetchThumbnails.error = null;
    },
    [fetchThumbnails.fulfilled]: (state, { payload }) => {
      state.fetchThumbnails.status = RS.IDLE;
      state.fetchThumbnails.response = payload.response;
    },
    [fetchThumbnails.rejected]: (state, { payload }) => {
      state.fetchThumbnails.status = RS.ERROR;
      state.fetchThumbnails.error = payload;
    },
    [previewFile.pending]: (state) => {
      state.previewFile.status = RS.RUNNING;
      state.previewFile.error = null;
    },
    [previewFile.fulfilled]: (state, { payload }) => {
      state.previewFile.status = RS.IDLE;
      state.fileViewer = {
        isOpen: true,
        downloadName: payload.downloadName,
        fileUrl: payload.fileUrl,
        boxName: payload.boxName,
        cdrId: payload.cdrId,
        isFaxReport: false,
      };
    },
    [previewFile.rejected]: (state, { payload }) => {
      state.previewFile.status = RS.ERROR;
      state.previewFile.error = payload;
    },
    [clearPreviewFile]: (state) => {
      state.previewFile = initialRequestState;
    },
    [previewReport.pending]: (state) => {
      state.previewReport.status = RS.RUNNING;
      state.previewReport.error = null;
    },
    [previewReport.fulfilled]: (state, { payload }) => {
      state.previewReport.status = RS.IDLE;
      if (!payload) return;
      state.fileViewer = {
        isOpen: true,
        downloadName: `report-${payload.downloadName}`,
        fileUrl: payload.fileUrl,
        boxName: payload.boxName,
        cdrId: payload.cdrId,
        isFaxReport: true,
      };
    },
    [previewReport.rejected]: (state, { payload }) => {
      state.previewReport.status = RS.ERROR;
      state.previewReport.error = payload;
    },
    [download.pending]: (state) => {
      state.download.status = RS.RUNNING;
      state.download.error = null;
    },
    [download.fulfilled]: (state, { payload }) => {
      state.download.status = RS.IDLE;
      if (!payload) return;
      const url = payload.fileUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", payload.downloadName);
      link.click();
    },
    [download.rejected]: (state, { payload }) => {
      state.download.status = RS.ERROR;
      state.download.error = payload;
    },
    [moveToTrash.pending]: (state) => {
      state.moveToTrash.status = RS.RUNNING;
      state.moveToTrash.error = null;
    },
    [moveToTrash.fulfilled]: (state, { payload }) => {
      state.moveToTrash.status = RS.IDLE;
      state.moveToTrash.response = payload.response;
    },
    [moveToTrash.rejected]: (state, { payload }) => {
      state.moveToTrash.status = RS.ERROR;
      state.moveToTrash.error = payload;
    },
    [clearMoveToTrash]: (state) => {
      state.moveToTrash = initialRequestState;
    },
    [restore.pending]: (state) => {
      state.restore.status = RS.RUNNING;
      state.restore.error = null;
    },
    [restore.fulfilled]: (state, { payload }) => {
      state.restore.status = RS.IDLE;
      state.restore.response = payload.response;
    },
    [restore.rejected]: (state, { payload }) => {
      state.restore.status = RS.ERROR;
      state.restore.error = payload;
    },
    [clearRestore]: (state) => {
      state.restore = initialRequestState;
    },
    [markAsRead.pending]: (state) => {
      state.markAsRead.status = RS.RUNNING;
      state.markAsRead.error = null;
    },
    [markAsRead.fulfilled]: (state, { payload }) => {
      state.markAsRead.status = RS.IDLE;
      state.markAsRead.response = payload.response;
    },
    [markAsRead.rejected]: (state, { payload }) => {
      state.markAsRead.status = RS.ERROR;
      state.markAsRead.error = payload;
    },
    [markAsUnread.pending]: (state) => {
      state.markAsUnread.status = RS.RUNNING;
      state.markAsUnread.error = null;
    },
    [markAsUnread.fulfilled]: (state, { payload }) => {
      state.markAsUnread.status = RS.IDLE;
      state.markAsUnread.response = payload.response;
    },
    [markAsUnread.rejected]: (state, { payload }) => {
      state.markAsUnread.status = RS.ERROR;
      state.markAsUnread.error = payload;
    },
    [clearCdrToken]: (state) => {
      state.cdrToken = initialRequestState;
    },
    [getCdrToken.pending]: (state) => {
      state.cdrToken.status = RS.RUNNING;
      state.cdrToken.error = null;
    },
    [getCdrToken.fulfilled]: (state, { payload }) => {
      state.cdrToken.status = RS.IDLE;
      state.cdrToken.response = payload.response;
    },
    [getCdrToken.rejected]: (state, { payload }) => {
      state.cdrToken.status = RS.ERROR;
      state.cdrToken.error = payload;
    },
    [addNoteOnFax.pending]: (state) => {
      state.addNoteOnFax.status = RS.RUNNING;
      state.addNoteOnFax.error = null;
    },
    [addNoteOnFax.fulfilled]: (state, { payload }) => {
      state.addNoteOnFax.status = RS.IDLE;
      state.addNoteOnFax.response = payload.response;
    },
    [addNoteOnFax.rejected]: (state, { payload }) => {
      state.addNoteOnFax.status = RS.ERROR;
      state.addNoteOnFax.error = payload;
    },
    [addSharedNoteOnFax.pending]: (state) => {
      state.addSharedNoteOnFax.status = RS.RUNNING;
      state.addSharedNoteOnFax.error = null;
    },
    [addSharedNoteOnFax.fulfilled]: (state, { payload }) => {
      state.addSharedNoteOnFax.status = RS.IDLE;
      state.addSharedNoteOnFax.response = payload.response;
    },
    [addSharedNoteOnFax.rejected]: (state, { payload }) => {
      state.addSharedNoteOnFax.status = RS.ERROR;
      state.addSharedNoteOnFax.error = payload;
    },
    [deleteFax.pending]: (state) => {
      state.deleteFax.status = RS.RUNNING;
      state.deleteFax.error = null;
    },
    [deleteFax.fulfilled]: (state, { payload }) => {
      state.deleteFax.status = RS.IDLE;
      state.deleteFax.response = payload.response;
    },
    [deleteFax.rejected]: (state, { payload }) => {
      state.deleteFax.status = RS.ERROR;
      state.deleteFax.error = payload;
    },
    [clearDeleteFax]: (state) => {
      state.deleteFax = initialRequestState;
    },
    [logout.fulfilled]: () => {
      return { ...initialState, selectedNumber: null, selectedMemberUid: null };
    },
  },
});

export default reducer;
export const { SET_SELECTED_NUMBER, SET_SELECTED_MEMBER_UID, SET_FILE_VIEWER } = actions;

export const faxesAsyncActions = {
  fetchBox,
  fetchBoxMore,
  fetchThumbnails,
  reply,
  forward,
  moveToTrash,
  download,
  markAsRead,
  markAsUnread,
  previewFile,
  previewReport,
  addNoteOnFax,
  addSharedNoteOnFax,
  resend,
  restore,
  deleteFax,
  getCdrToken,
};
