import { FaButton } from "@alohi/kit";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { faUsersMedical } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import { contactsRoutes } from "enums/contacts";
import ManageGroupsModal from "views/Contacts/modals/ManageGroupsModal";
import {
  selectSharedContactsSelection,
  selectWhitelistContactsSelection,
  selectGroupNamesOfCheckedWhitelist,
  selectGroupNamesOfCheckedSharedContacts,
  selectWhitelistSelectionHaveDifferentGroups,
  selectSharedContactsSelectionHaveDifferentGroups,
} from "selectors/contacts.selector";

function ManageGroupsButton() {
  const { t } = useTranslation();
  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const [isManageGroupsModalOpen, isManageGroupsModalOpenBool] = useBool(false);

  const whitelistSelection = useAppSelector<string[]>(selectWhitelistContactsSelection);
  const sharedContactsSelection = useAppSelector<string[]>(selectSharedContactsSelection);
  const whitelistGroupsDiffer = useAppSelector<boolean>(
    selectWhitelistSelectionHaveDifferentGroups,
  );
  const sharedContactsGroupsDiffer = useAppSelector<boolean>(
    selectSharedContactsSelectionHaveDifferentGroups,
  );
  const checkedSharedContactsGroups = useAppSelector<string[]>(
    selectGroupNamesOfCheckedSharedContacts,
  );
  const checkedWhitelistGroups = useAppSelector<string[]>(selectGroupNamesOfCheckedWhitelist);

  if (
    !(contactsTab === contactsRoutes.whitelist || contactsTab === contactsRoutes["shared-contacts"])
  ) {
    return null;
  }

  const isShared = contactsTab === contactsRoutes["shared-contacts"];
  const selection = isShared ? sharedContactsSelection : whitelistSelection;
  const groupsDiffer = isShared ? sharedContactsGroupsDiffer : whitelistGroupsDiffer;
  const checkedGroupNames = isShared ? checkedSharedContactsGroups : checkedWhitelistGroups;

  return (
    <>
      <FaButton
        variant="light"
        icon={faUsersMedical}
        tooltip={t("CONTACTS.MANAGE_GROUPS")}
        onClick={isManageGroupsModalOpenBool.setTrue}
      />

      {isManageGroupsModalOpen ? (
        <ManageGroupsModal
          hasActions
          isShared={isShared}
          contactsToEdit={selection}
          groupsDiffer={groupsDiffer}
          checkedGroupNames={checkedGroupNames}
          handleClosure={isManageGroupsModalOpenBool.setFalse}
        />
      ) : null}
    </>
  );
}

export default ManageGroupsButton;
