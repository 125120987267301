import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";

// https://api.fax.alohi.io/swagger#/export

export type DataExportGetStatus = {
  export_jobs: {
    [key in "ExportJobType.FAXES" | "ExportJobType.CONTACTS"]: {
      cid: string;
      job_type: string;
      id: string;
      creation_date: string;
      scheduled_date: string;
      status: string;
      retry_left: number;
      embedded_data: unknown;
      path_to_final_files: string[];
    } | null;
  };
};

const getStatus = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/export`;
  return fetchWithRefresh(path, requestOptions) as Promise<DataExportGetStatus>;
};

const createJob = ({ jobType }: { jobType: "faxes" | "contacts" }) => {
  const requestOptions = {
    method: "POST",
    body: { job_type: jobType },
  };
  const path = `${API_AFFOGATO}/export`;
  return fetchWithRefresh(path, requestOptions);
};

const download = ({ fileName }: { fileName: string }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: `application/zip`,
    },
  };
  const path = `${API_AFFOGATO}/export/${fileName}`;
  return fetchWithRefresh(path, requestOptions);
};

export const dataExportApi = {
  getStatus,
  createJob,
  download,
};
