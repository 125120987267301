import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { useAppDispatch } from "stores/store";
import {
  getPersonalAccessTokens,
  deletePersonalAccessToken,
} from "stores/reducers/integrations.api.reducer";

interface DeleteTokenModalProps {
  id: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function DeleteTokenModal({ id, onCancel, onConfirm }: DeleteTokenModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        deletePersonalAccessToken({
          id,
        }),
      );
    } catch {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
    setIsLoading(false);

    dispatch(getPersonalAccessTokens());
    onConfirm();
  };

  return (
    <Modal
      onCancel={onCancel}
      onConfirm={handleConfirm}
      isConfirmDisabled={!id}
      isConfirmLoading={isLoading}
      title={t("API_TOKEN.DELETE")}
      maxWidth="xs"
      disableAnimation
    >
      <Box p={3}>
        <Typography pb={2}>{t("API_TOKEN.DELETE_CONFIRMATION")}</Typography>
        <Typography pb={2}>{t("API_TOKEN.DELETE_WARNING")}</Typography>
        <Typography>{t("API_TOKEN.DELETE_WARNING_2")}</Typography>
      </Box>
    </Modal>
  );
}

export default DeleteTokenModal;
