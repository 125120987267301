import { Modal, makeStyles } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { useInput } from "hooks/useInput";

interface MessageModalProps {
  message: string;
  handleClosure: (message: string) => void;
}

function MessageModal({ message, handleClosure }: MessageModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, valueInput] = useInput(message);

  return (
    <Modal
      maxWidth="sm"
      scroll="paper"
      minHeight="60vh"
      hasCloseIcon={false}
      title={t("FORMS.EDIT_MESSAGE")}
      onConfirm={() => handleClosure(value)}
    >
      <textarea
        cols={40}
        rows={5}
        value={value}
        maxLength={1000}
        placeholder="..."
        className={classes.input}
        onBlur={valueInput.onBlur}
        onFocus={valueInput.onFocus}
        onChange={valueInput.onChange}
      />
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  input: {
    width: "100%",
    height: "100%",
    resize: "none",
    border: "unset",
    padding: spacing(2),
    overflowY: "scroll",
    "&:hover": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));

export default MessageModal;
