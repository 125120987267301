import createSelector from "selectorator";

import RS from "enums/requestStatus";
import i18n from "../i18n/index";

export const selectWhitelistContacts = createSelector(
  ["whitelist.contacts"],
  (contacts) => contacts,
);

export const selectBlacklistContacts = createSelector(
  ["blacklist.contacts"],
  (contacts) => contacts,
);

export const selectBoxContacts = (boxName) =>
  createSelector([`${boxName}.contacts`], (contacts) => contacts);

export const selectContact = (boxName, dataIndex) =>
  createSelector([`${boxName}.contacts[${dataIndex}]`], (contact) => contact);

export const selectContactId = (boxName, dataIndex) =>
  createSelector([selectContact(boxName, dataIndex)], (contact) => contact?.id);

export const selectIsContactChecked = (boxName, dataIndex) =>
  createSelector([selectContact(boxName, dataIndex)], (contact) => {
    return !!contact?.isChecked;
  });

export const selectIdsOfCheckedContacts = (boxName) =>
  createSelector(
    [`${boxName}.selection`, "sharedContacts.selection", "sharedGroups.selection"],
    (selection, sharedContactsSelection, sharedGroupsSelection) => {
      if (boxName === "shared-contacts") {
        return sharedContactsSelection;
      }
      if (boxName === "shared-groups") {
        return sharedGroupsSelection;
      }

      return selection;
    },
  );

export const selectContactBoxHeader = (boxName) =>
  createSelector([selectBoxContacts(boxName)], (contacts) => {
    if (!contacts?.length) {
      return { isChecked: false, isIndeterminate: false };
    }
    let numberOfChecked = 0;
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].isChecked) numberOfChecked++;
      if (0 < numberOfChecked && numberOfChecked < i + 1) {
        return {
          isChecked: false,
          isIndeterminate: true,
        };
      }
    }
    return { isChecked: contacts.length === numberOfChecked, isIndeterminate: false };
  });

export const selectRecentContacts = createSelector(["recent.contacts"], (contacts) => contacts);

export const selectIsRecentContactsLoading = createSelector(
  ["recent.getRecentContacts.status"],
  (status) => status === RS.RUNNING,
);

export const selectGroups = createSelector(["groups.groups"], (groups) => groups);

export const selectGroupNames = createSelector(
  ["groups.groups"],
  (groups) => groups?.map((group) => group.name) ?? [],
);

export const selectIsGettingGroupsLoading = createSelector(
  ["groups.getGroups.status"],
  (status) => status === RS.RUNNING,
);

export const selectGroupSelection = createSelector(["groups.selection"], (selection) => selection);

export const selectRecentContactsSelection = createSelector(
  ["recent.selection"],
  (selection) => selection,
);

export const selectGroupById = (groupId) =>
  createSelector(["groups.groups"], (groups) => {
    return groups?.find((group) => group.id === groupId) ?? null;
  });

export const selectWhitelistHasMoreContacts = createSelector(
  ["whitelist.hasMore"],
  (hasMore) => hasMore,
);

export const selectBlacklistHasMoreContacts = createSelector(
  ["blacklist.hasMore"],
  (hasMore) => hasMore,
);

export const selectGroupsHasMore = createSelector(["groups.hasMore"], (hasMore) => hasMore);

export const selectWhitelistContactById = (contactId) =>
  createSelector(["whitelist.contacts"], (contacts) => {
    return contacts?.find((contact) => contact.id === contactId) ?? null;
  });

export const selectBlacklistContactById = (contactId) =>
  createSelector(["blacklist.contacts"], (contacts) => {
    return contacts?.find((contact) => contact.id === contactId) ?? null;
  });

export const selectContactsWhitelistCurrentFetchOptions = createSelector(
  ["whitelist.lastRequestOptions"],
  (currentFetchOptions) => currentFetchOptions,
);

export const selectGroupsCurrentFetchOptions = createSelector(
  ["groups.lastRequestOptions"],
  (currentFetchOptions) => currentFetchOptions,
);

export const selectSharedGroupsCurrentFetchOptions = createSelector(
  ["sharedGroups.lastRequestOptions"],
  (currentFetchOptions) => currentFetchOptions,
);

export const selectSharedContactsCurrentFetchOptions = createSelector(
  ["sharedContacts.lastRequestOptions"],
  (currentFetchOptions) => currentFetchOptions,
);

export const selectUserCanSearchContacts = createSelector(
  [
    selectContactsWhitelistCurrentFetchOptions,
    selectGroupsCurrentFetchOptions,
    selectSharedContactsCurrentFetchOptions,
    selectSharedGroupsCurrentFetchOptions,
  ],
  (contacts, groups, sharedContacts, sharedGroups) =>
    contacts?.search || groups?.search || sharedGroups?.search || sharedContacts?.search,
);

export const selectShareWhitelistContacts = createSelector(
  ["whitelist.shareWhitelistContacts.response"],
  (response) => {
    if (!response) {
      return null;
    }

    return response;
  },
);

export const selectShareWhitelistContactsError = createSelector(
  ["whitelist.shareWhitelistContacts.error"],
  (error) => error,
);

export const selectContactsGroupsCurrentFetchOptions = createSelector(
  ["groups.lastRequestOptions"],
  (currentFetchOptions) => currentFetchOptions,
);

export const selectSharedContacts = createSelector(
  ["sharedContacts.contacts"],
  (contacts) => contacts,
);

export const selectSharedContactsSelection = createSelector(
  ["sharedContacts.selection"],
  (contacts) => contacts,
);

export const selectHasMoreSharedContacts = createSelector(
  ["sharedContacts.hasMore"],
  (hasMore) => hasMore,
);

export const selectIsSharedContactsLoading = createSelector(
  ["sharedContacts.getSharedContacts.status"],
  (contactsStatus) => contactsStatus === RS.RUNNING,
);

export const selectIsWhitelistContactsLoading = createSelector(
  ["whitelist.getWhitelistContacts.status"],
  (contactsStatus) => contactsStatus === RS.RUNNING,
);

export const selectCreateBlacklistContact = createSelector(
  ["blacklist.createBlacklistContact.response"],
  (response) => Boolean(response),
);

export const selectCreateBlacklistContactError = createSelector(
  ["blacklist.createBlacklistContact.error"],
  (error) => {
    if (!error) {
      return "";
    }
    const name = error?.newContact?.faxNumber;

    if (error?.origin?.id?.reason === "already_exists" && name) {
      return i18n.t("CONTACTS.DUPLICATE_BLACKLIST_NAME", { name });
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectUpdateBlacklistContact = createSelector(
  ["blacklist.updateBlacklistContact.response"],
  (response) => Boolean(response),
);

export const selectUpdateBlacklistContactError = createSelector(
  ["blacklist.updateBlacklistContact.error"],
  (error) => {
    if (!error) {
      return "";
    }
    const name = error?.contactDiffs?.faxNumber;

    if (error?.origin?.id?.reason === "already_exists" && name) {
      return i18n.t("CONTACTS.DUPLICATE_BLACKLIST_NAME", { name });
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectIsBlacklistContactsLoading = createSelector(
  ["blacklist.getBlacklistContacts.status"],
  (contactsStatus) => contactsStatus === RS.RUNNING,
);

export const selectWhitelistContactsSelection = createSelector(
  ["whitelist.selection"],
  (selection) => selection,
);

export const selectBlacklistContactsSelection = createSelector(
  ["blacklist.selection"],
  (selection) => selection,
);

export const selectSharedContactById = (contactId) =>
  createSelector(["sharedContacts.contacts"], (sharedContacts) => {
    return sharedContacts?.find((contact) => contact.id === contactId) ?? null;
  });

export const selectSharedGroups = createSelector(["sharedGroups.groups"], (groups) => groups);

export const selectSharedGroupNames = createSelector(
  ["sharedGroups.groups"],
  (groups) => groups?.map((group) => group.name) ?? [],
);

export const selectHasMoreSharedGroups = createSelector(
  ["sharedGroups.hasMore"],
  (hasMore) => hasMore,
);

export const selectIsSharedGroupsLoading = createSelector(
  ["sharedGroups.getSharedGroups.status"],
  (groupsStatus) => groupsStatus === RS.RUNNING,
);

export const selectSharedGroupsSelection = createSelector(
  ["sharedGroups.selection"],
  (selection) => selection,
);

export const selectSharedGroupById = (groupId) =>
  createSelector(
    ["sharedGroups.groups"],
    (groups) => groups?.find((group) => group.id === groupId) ?? null,
  );

export const selectShareGroup = createSelector(["groups.shareGroup.response"], (response) => {
  if (!response) {
    return null;
  }

  return response;
});

export const selectShareGroupError = createSelector(["groups.shareGroup.error"], (error) => error);

export const selectIsGroupUpdated = createSelector(["groups.updateGroup.response"], (response) =>
  Boolean(response),
);

export const selectIsGroupCreated = createSelector(["groups.createGroup.response"], (response) =>
  Boolean(response),
);

export const selectIsSharedGroupUpdated = createSelector(
  ["sharedGroups.updateSharedGroup.response"],
  (response) => Boolean(response),
);

export const selectIsSharedGroupCreated = createSelector(
  ["sharedGroups.createSharedGroup.response"],
  (response) => Boolean(response),
);

export const selectCreateGroupError = createSelector(["groups.createGroup.error"], (error) => {
  if (!error) {
    return "";
  }
  const name = error?.newGroup?.name;

  if (error?.reason === "duplicate_entry" && name) {
    return i18n.t("CONTACTS.DUPLICATE_GROUP_NAME", { name });
  }

  return i18n.t("COMMON.SERVER_ERROR");
});

export const selectUpdateGroupError = createSelector(["groups.updateGroup.error"], (error) => {
  if (!error) {
    return "";
  }
  const name = error?.groupDiffs?.name;

  if (error?.reason === "duplicate_entry" && name) {
    return i18n.t("CONTACTS.DUPLICATE_GROUP_NAME", { name });
  }

  return i18n.t("COMMON.SERVER_ERROR");
});

export const selectCreateSharedGroupError = createSelector(
  ["sharedGroups.createSharedGroup.error"],
  (error) => {
    if (!error) {
      return "";
    }
    const name = error?.newGroup?.name;

    if (error?.reason === "duplicate_entry" && name) {
      return i18n.t("CONTACTS.DUPLICATE_GROUP_NAME", { name });
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectUpdateSharedGroupError = createSelector(
  ["sharedGroups.updateSharedGroup.error"],
  (error) => {
    if (!error) {
      return "";
    }
    const name = error?.groupDiffs?.name;

    if (error?.reason === "duplicate_entry" && name) {
      return i18n.t("CONTACTS.DUPLICATE_GROUP_NAME", { name });
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectGroupNamesOfCheckedWhitelist = createSelector(
  [selectWhitelistContactsSelection, selectWhitelistContacts],
  (selection, contacts) => {
    const contactsMap = new Map();

    contacts?.forEach((contact) => {
      contactsMap.set(contact.id, contact.groups);
    });

    const allGroupsArray = selection.reduce((accumulator, id) => {
      const groups = contactsMap.get(id);

      if (groups?.length) {
        accumulator.push(...groups);
      }

      return accumulator;
    }, []);

    return Array.from(new Set(allGroupsArray));
  },
);

export const selectWhitelistSelectionHaveDifferentGroups = createSelector(
  [selectWhitelistContactsSelection, selectWhitelistContacts],
  (selection, contacts) => {
    let min = -1;
    // Represents the minimum sum of all arrays
    const groupSet = new Set();
    const contactsMap = new Map();

    contacts?.forEach((contact) => {
      // Added for performance reasons
      // Cache each id
      contactsMap.set(contact.id, contact.groups);
    });

    selection?.forEach((id) => {
      // Read from cache
      const groups = contactsMap.get(id);

      if (groups !== undefined) {
        if (min === -1) {
          // Initialize the minimum with the first array length
          min = groups.length;
        }

        // Each iteration set the minimum with the group length
        min = Math.min(groups.length, min);

        // Create a minimum sum of all elements in groups
        groups.forEach(groupSet.add, groupSet);
      }
    });

    // Check the minimum array length with the set of minimum array lengths
    // Order does not matter
    // If we have two different arrays in size, it will be caught by min
    // Because the set size can't be lower than min
    // If we have the same size arrays but with different elements, it will be caught by set size
    return groupSet.size !== min;
  },
);

// Read the comments above
export const selectSharedContactsSelectionHaveDifferentGroups = createSelector(
  [selectSharedContactsSelection, selectSharedContacts],
  (selection, contacts) => {
    let min = -1;
    const groupSet = new Set();
    const contactsMap = new Map();

    contacts?.forEach((contact) => {
      contactsMap.set(contact.id, contact.groups);
    });

    selection?.forEach((id) => {
      const groups = contactsMap.get(id);

      if (groups !== undefined) {
        if (min === -1) {
          min = groups.length;
        }

        min = Math.min(groups.length, min);

        groups.forEach(groupSet.add, groupSet);
      }
    });

    return groupSet.size !== min;
  },
);

export const selectGroupNamesOfCheckedSharedContacts = createSelector(
  [selectSharedContactsSelection, selectSharedContacts],
  (selection, contacts) => {
    const contactsMap = new Map();

    contacts?.forEach((contact) => {
      contactsMap.set(contact.id, contact.groups);
    });

    const allGroupsArray = selection.reduce((accumulator, id) => {
      const groups = contactsMap.get(id);

      if (groups?.length) {
        accumulator.push(...groups);
      }

      return accumulator;
    }, []);

    return Array.from(new Set(allGroupsArray));
  },
);

export const selectSetWhitelistGroupsIsRunning = createSelector(
  ["whitelist.setWhitelistGroups.status"],
  (status) => status === RS.RUNNING,
);

export const selectSetWhitelistGroupsResponse = createSelector(
  ["whitelist.setWhitelistGroups.response"],
  (response) => Boolean(response),
);

export const selectSetSharedContactsGroupsIsRunning = createSelector(
  ["sharedContacts.setSharedContactsGroups.status"],
  (status) => status === RS.RUNNING,
);

export const selectSetSharedContactsGroupsResponse = createSelector(
  ["sharedContacts.setSharedContactsGroups.response"],
  (response) => Boolean(response),
);
