import createSelector from "selectorator";
import i18n from "../i18n/index";
import { RootState } from "../stores/store";

export const selectIsSetAsAdminSuccess = (state: RootState): boolean =>
  !!state.account.setAsAdmin.response;

export const selectIsRemoveAsAdminSuccess = (state: RootState): boolean =>
  !!state.account.removeAsAdmin.response;

export const selectIsTransferOwnershipSuccess = (state: RootState): boolean =>
  !!state.account.transferOwnership.response;

export const selectSetAsAdminError = createSelector<RootState, string | null>(
  ["account.setAsAdmin.error"],
  (error) => {
    if (!error) return null;

    switch (error.reason) {
      case "admin_limit_reached":
        return i18n.t("USERS.ADMIN_LIMIT_REACHED", { amount: 10 });
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectRemoveAsAdminError = createSelector<RootState, string | null>(
  ["account.removeAsAdmin.error"],
  (error) => {
    if (!error) return null;

    switch (error.reason) {
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectTransferOwnershipError = createSelector<RootState, string | null>(
  ["account.transferOwnership.error"],
  (error) => {
    if (!error) {
      return null;
    }

    switch (error.reason) {
      case "account_not_verified":
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);
