import queryString from "query-string";
import { useLocation } from "react-router";
import { memo, Dispatch, SetStateAction, useEffect } from "react";

import useBool from "hooks/useBool";
import { authenticationApi } from "api";
import { ResetPasswordSteps } from "views/Authentication/ResetPassword";

interface CheckTokenProps {
  setRevisedLink: Dispatch<SetStateAction<string>>;
  onCompleted: (step: ResetPasswordSteps) => void;
}

function CheckToken({ onCompleted, setRevisedLink }: CheckTokenProps) {
  const { search } = useLocation();
  const { token } = queryString.parse(search);

  const [isRevised, isRevisedBool] = useBool(false);

  useEffect(() => {
    if (!isRevised) {
      isRevisedBool.setTrue();

      if (token && typeof token === "string") {
        (async () => {
          try {
            const revisedToken = await authenticationApi.reviseAndGetToken(token);

            if (revisedToken) {
              setRevisedLink(revisedToken);
              onCompleted("NEW_PASSWORD");
            } else {
              onCompleted("LINK_EXPIRED");
            }
          } catch {
            onCompleted("LINK_EXPIRED");
          }
        })();
      } else {
        onCompleted("REQUEST_LINK");
      }
    }
  }, [isRevised, isRevisedBool, onCompleted, setRevisedLink, token]);

  return null;
}

export default memo(CheckToken);
