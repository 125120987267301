import parseNumber from "libphonenumber-js/max";
import { isPossibleNumber, parsePhoneNumberFromString } from "libphonenumber-js";

import { parseExtension } from "./numbers";

const emailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const isValidEmail = (email) => {
  // Inspired by https://github.com/manishsaraan/email-validator/blob/master/index.js
  if (!email) return false;
  if (email.length > 256) return false;
  if (!emailRegex.test(email)) return false;

  // Further checking of some things regex can't handle
  const emailParts = email.split("@");
  const account = emailParts[0];
  const address = emailParts[1];
  if (account.length > 64) return false;

  const domainParts = address.split(".");
  return !domainParts.some(function (part) {
    return part.length > 63;
  });
};

export const parseEmailsString = (emails) => {
  const splitEmails = emails.split(/[,;| ]/).filter(Boolean);
  const filteredEmails = splitEmails.filter(isValidEmail);
  const uniqueEmails = Array.from(new Set(filteredEmails));

  return {
    uniqueEmails,
    someEmailsAreInvalid: splitEmails.length !== filteredEmails.length,
  };
};

export const isValidEmails = (emails) => {
  if (emails === "") {
    return true;
  }

  const { someEmailsAreInvalid } = parseEmailsString(emails);

  return !someEmailsAreInvalid;
};

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 60;
export const isValidPassword = (password) => {
  if (!password) {
    return false;
  }
  return !(password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH);
};

export const isValidPasswordAttempt = (value) => {
  return value?.length > 0;
};

export const isValidTwoFaCode = (code) => {
  return code.trim().length === 6;
};

export const isValidRedirectUrl = (tag) => {
  return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/.test(
    tag,
  );
};

export const isValidPostalCode = (code) => {
  return code.length > 2 && code.length < 13;
};

export const isValidAccountName = (name) => {
  return name.length > 0 && name.length < 51;
};

export const isValidName = (name) => {
  return (
    name.length > 0 &&
    name.length < 51 &&
    !["^", "<", ">", "{", "}", "%", "!", "@", "#", "$", "&", "*"].some((forbiddenChar) =>
      name.includes(forbiddenChar),
    )
  );
};

export const isValidFriendlyName = (name) => {
  if (typeof name !== "string") {
    return false;
  } else {
    return (
      name.length >= 0 &&
      name.length < 51 &&
      !["^", "<", ">", "{", "}", "%", "!", "@", "#", "$", "&", "*"].some((forbiddenChar) =>
        name.includes(forbiddenChar),
      )
    );
  }
};

export const isValidCardholderName = (name) => {
  return (
    name.length > 0 &&
    name.length < 101 &&
    !["^", "<", ">", "{", "}", "%", "!", "@", "#", "$", "&", "*"].some((forbiddenChar) =>
      name.includes(forbiddenChar),
    )
  );
};

export const isValidStreetName = (name) => {
  return (
    name.length > 0 &&
    name.length < 51 &&
    !["^", "<", ">", "{", "}", "%", "!", "@", "#", "$", "&", "*"].some((forbiddenChar) =>
      name.includes(forbiddenChar),
    )
  );
};

export const isValidCompanyName = (companyName) => {
  return companyName.length === 0 || companyName.length < 61;
};

export const isValidNote = (note) => {
  if (!note) return false;
  return note.length < 101;
};

export const isValidRedeemCode = (code) => {
  return code.length > 15 && code.length < 65;
};

export const isValidUserQuota = (quota) => {
  if (quota === null || quota === "") {
    return false;
  }

  const number = Number(quota);
  return number >= 0;
};

export const MAX_NUMBER_OF_DAYS = 1825;
export const isValidDocumentRetentionDays = (number) => {
  if (number === "") {
    return false;
  }

  if (!Number.isNaN(Number(number))) {
    return number >= 1 && number <= 1825;
  }

  return false;
};

export const isValidPhoneNumber = (phone) => {
  const phoneNumber = parseNumber(phone);
  if (phoneNumber) {
    return phoneNumber.isValid();
  }
  return false;
};

export const isValidFaxNumber = (input) => {
  const { number, isValidExtension } = parseExtension(input);
  const isValidNumber = Boolean(parsePhoneNumberFromString(number)?.isValid());

  return isValidExtension && isValidNumber;
};

export const isValidEmailVerificationCode = (code) => {
  return code.trim().length === 6 && code.match(/^[0-9]+$/) !== null;
};

export const isPossiblePhoneNumber = (number) => {
  return isPossibleNumber(number);
};

export const isValidJobTitle = (code) => code.length < 100;

export const isValidBlacklistNumber = (value) => {
  const regex = /^\+\d{2,}/;
  return regex.test(value);
};

export const isValidNonEmptyName = (name) => {
  if (name?.replaceAll(/\s/g, "")?.length === 0) return false;
  return isValidName(name);
};
