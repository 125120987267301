import { Box, makeStyles } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";

import { getPowerSetOfAddressRequirements } from "stores/reducers/address.reducer";
import { CreateAddressBundleContext } from "views/CreateAddressBundle/context/createAddressBundle.context";
import {
  selectUserAddressDocumentsType,
  selectUserAddressDocumentsRemaining,
} from "selectors/address.selector";
import DocumentContainer from "./components/DocumentContainer/DocumentContainer";

function Documents() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [{ customFields, numberType, userType, userDocuments, country }, localDispatch] =
    useContext(CreateAddressBundleContext);

  const allDocumentsType = useSelector(selectUserAddressDocumentsType(userType));
  const documentsRemaining = useSelector(selectUserAddressDocumentsRemaining(userType));

  const handleUpdateUserDocuments = useCallback(
    (name, file) => (documentType) => {
      localDispatch({
        type: "UPDATE_USER_DOCUMENTS",
        payload: {
          name,
          file,
          documentType,
        },
      });

      localDispatch({
        type: "RESET_CUSTOM_FIELDS",
        payload: name,
      });

      const documentsAlreadyUploaded = Object.values(userDocuments ?? {})
        .map((documentTypes) => Object.values(documentTypes).map((type) => Object.keys(type)))
        .flat(5);

      dispatch(
        getPowerSetOfAddressRequirements({
          country,
          userType,
          numberType,
          uploadedDocuments: [...documentsAlreadyUploaded, documentType],
        }),
      );
    },
    [country, dispatch, localDispatch, numberType, userDocuments, userType],
  );

  const handleDeleteFile = useCallback(
    (inputId, documentType) => {
      localDispatch({
        type: "REMOVE_DOCUMENT_FILES",
        payload: {
          inputId,
          documentType,
        },
      });

      const documentsAlreadyUploaded = Object.values(userDocuments ?? {})
        .map((documentTypes) => Object.values(documentTypes).map((type) => Object.keys(type)))
        .flat(5);

      dispatch(
        getPowerSetOfAddressRequirements({
          country,
          userType,
          numberType,
          uploadedDocuments: documentsAlreadyUploaded,
        }),
      );
    },
    [country, dispatch, localDispatch, numberType, userDocuments, userType],
  );

  const isContainerDisabled = useCallback(
    (inputId, name, numberOfRequiredDocuments) => {
      const uploadedDocuments = Object.keys(userDocuments?.[userType]?.[inputId] ?? {}).length;

      if (numberOfRequiredDocuments <= uploadedDocuments) {
        return false;
      }

      if (documentsRemaining) {
        const isDocumentInTheList = documentsRemaining.find(
          (documentType) => documentType.name === name,
        );
        // The remaining documents was updated, what we can't find in the list, is not needed anymore
        if (!isDocumentInTheList) {
          return true;
        }
        // We found the document in the list, user should upload that document
        return false;
      }

      return false;
    },
    [userDocuments, userType, documentsRemaining],
  );

  const isSubmitEnabled = useMemo(() => {
    if (allDocumentsType?.length === 0) {
      return true;
    }

    if (documentsRemaining) {
      const selectedCustomFields = Object.keys(customFields?.[userType] ?? [])
        .map((customField) =>
          Object.values(customFields?.[userType]?.[customField]).filter(
            (subField) => subField === "",
          ),
        )
        .flat();

      const isDocumentArrayEmpty = documentsRemaining.length === 0;
      const allDocumentsAreOptional = documentsRemaining.every(
        ({ numberOfRequiredDocuments }) => numberOfRequiredDocuments === 0,
      );

      return (isDocumentArrayEmpty || allDocumentsAreOptional) && selectedCustomFields.length === 0;
    }

    return false;
  }, [allDocumentsType?.length, customFields, documentsRemaining, userType]);

  useEffect(() => {
    localDispatch({
      type: "UPDATE_IS_USER_DOCUMENTS_VALID",
      payload: isSubmitEnabled,
    });
  }, [isSubmitEnabled, localDispatch]);

  return (
    <Box className={classes.wrapper}>
      {allDocumentsType?.map(
        ({ name, inputId, description, numberOfRequiredDocuments, acceptedDocuments }) => (
          <DocumentContainer
            name={name}
            key={inputId}
            inputId={inputId}
            description={description}
            onDeleteFile={handleDeleteFile}
            acceptedDocuments={acceptedDocuments}
            userDocuments={userDocuments?.[userType]}
            onUpdateUserDocuments={handleUpdateUserDocuments}
            numberOfRequiredDocuments={numberOfRequiredDocuments}
            selectedDocuments={userDocuments?.[userType]?.[inputId]}
            isContainerDisabled={isContainerDisabled(inputId, name, numberOfRequiredDocuments)}
          />
        ),
      )}
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(4),
  },
}));

export default memo(Documents);
