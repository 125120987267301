import { apm } from "@elastic/apm-rum";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { accountsApi, authenticationApi } from "api";
import { ContactsSearchResponse } from "api/types/accounts";
import { Contact } from "api/types/storage";
import RS, { FetchError, initialRequestState } from "enums/requestStatus";
import { gaEvents } from "helpers/googleAnalytics";
import { selectAccountCid, selectIsCustomerInfoRunning } from "selectors/account.selector";

import { dataResidencySlice, initialDataResidencyState } from "./account.dataResidency.reducer";
import { hipaaSlice, initialHipaaState } from "./account.hipaa.reducer";
import { initialNotificationsState, notificationsSlice } from "./account.notifications.reducer";
import { initialPermissionsState, permissionsSlice } from "./account.permissions.reducer";
import { initialSsoState, ssoSlice } from "./account.sso.reducer";
import { logout } from "./authentication.reducer";

const SLICE_NAME = "ACCOUNT";

const initialState = {
  contacts: initialRequestState,
  accountDetails: initialRequestState,
  importContacts: initialRequestState,
  changePassword: initialRequestState,
  forgotUsername: initialRequestState,
  updateAccountDetails: initialRequestState,
  getCustomerInfo: initialRequestState,
  acceptTos: initialRequestState,
  documentRetention: initialRequestState,
  changeAnonymous: initialRequestState,
  accountDelete: initialRequestState,
  ...initialSsoState,
  ...initialHipaaState,
  ...initialDataResidencyState,
  ...initialNotificationsState,
  ...initialPermissionsState,
};

export const clearContacts = createAction(`${SLICE_NAME}/CLEAR_GET_CONTACTS`);
export const clearImportContacts = createAction(`${SLICE_NAME}/CLEAR_IMPORT_CONTACTS`);
export const clearChangePassword = createAction(`${SLICE_NAME}/CLEAR_CHANGE_PASSWORD`);
export const clearForgotUsername = createAction(`${SLICE_NAME}/CLEAR_FORGOT_USERNAME`);
export const clearUpdateAccountDetails = createAction(`${SLICE_NAME}/CLEAR_UPDATE_ACCOUNT_DETAILS`);
export const clearAcceptTos = createAction(`${SLICE_NAME}/CLEAR_ACCEPT_TOS`);
export const clearDocumentRetention = createAction(`${SLICE_NAME}/CLEAR_DOCUMENT_RETENTION`);
export const clearChangeAnonymous = createAction(`${SLICE_NAME}/CHANGE_ANONYMOUS`);
export const clearAccountDelete = createAction(`${SLICE_NAME}/CLEAR_DELETE_ACCOUNT`);

export const getAccountDetails = createAsyncThunk(
  `${SLICE_NAME}/GET_ACCOUNT_DETAILS`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountsApi.getAccountDetails();
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const accountDelete = createAsyncThunk(
  `${SLICE_NAME}/DELETE_ACCOUNT`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountsApi.accountDelete();
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const updateAccountDetails = createAsyncThunk<
  { response: unknown },
  { updateType: string; accountData: unknown }
>(
  `${SLICE_NAME}/UPDATE_ACCOUNT_DETAILS`,
  async ({ updateType, accountData }, { rejectWithValue }) => {
    try {
      const response = await accountsApi.updateAccountDetails(accountData, updateType);
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const importContacts = createAsyncThunk<{ response: unknown }, Contact[]>(
  `${SLICE_NAME}/IMPORT_CONTACTS`,
  async (contacts, { rejectWithValue }) => {
    try {
      const response = await accountsApi.importContacts(contacts);
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const getContactsSuggestions = createAsyncThunk<
  ContactsSearchResponse,
  {
    type: string;
    search: string;
    limit?: number;
    offset: number;
    sortBy: string;
    shared: boolean;
    direction: string;
  }
>(`${SLICE_NAME}/GET_CONTACTS_SUGGESTIONS`, async (contactsObject, { rejectWithValue }) => {
  try {
    const response = await accountsApi.getContacts(contactsObject);
    return response;
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const changePassword = createAsyncThunk<
  { response: unknown },
  { oldPassword: string; newPassword: string }
>(`${SLICE_NAME}/CHANGE_PASSWORD`, async (accountData, { rejectWithValue }) => {
  try {
    const response = await authenticationApi.changePasswordWithOld(accountData);
    return response;
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const forgotUsername = createAsyncThunk<{ response: unknown }, { phoneNumber: string }>(
  "ACCOUNT/FORGOT_USERNAME",
  async ({ phoneNumber }, { rejectWithValue }) => {
    try {
      const response = await accountsApi.forgotUsername(phoneNumber);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const getCustomerInfo = createAsyncThunk(
  "ACCOUNT/GET_CUSTOMER_INFO",
  async (_, { rejectWithValue, getState }) => {
    const cid = selectAccountCid(getState());
    try {
      const response = await accountsApi.getCustomerInfo({ cid });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
  {
    condition(_, { getState }) {
      const state = getState();
      return !selectIsCustomerInfoRunning(state) && Boolean(selectAccountCid(state));
    },
  },
);

export const acceptTos = createAsyncThunk("ACCOUNT/ACCEPT_TOS", async (_, { rejectWithValue }) => {
  try {
    const response = await accountsApi.acceptTos();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const updateDocumentRetention = createAsyncThunk<
  { response: unknown },
  { incomingRetentionDays: number | null; outgoingRetentionDays: number | null },
  { rejectValue: FetchError["origin"] }
>(
  "ACCOUNT/UPDATE_DOCUMENT_RETENTION",
  async ({ incomingRetentionDays, outgoingRetentionDays }, { rejectWithValue }) => {
    try {
      const response = await accountsApi.updateDocumentRetention({
        incomingRetentionDays,
        outgoingRetentionDays,
      });

      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const updateAnonymous = createAsyncThunk<{ response: unknown }, { allow: boolean }>(
  `${SLICE_NAME}/CHANGE_ANONYMOUS`,
  async ({ allow }, { rejectWithValue }) => {
    try {
      const response = await accountsApi.updateAnonymous({ allow });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const { reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountDetails.pending, (state) => {
      state.accountDetails.status = RS.RUNNING;
      state.accountDetails.error = null;
    });
    builder.addCase(getAccountDetails.fulfilled, (state, action) => {
      if (action?.payload?.id) {
        Sentry.setUser({ id: action.payload.id });

        apm.setUserContext({
          id: action.payload.id,
          email: action.payload.email,
        });

        gaEvents.setUserId(action.payload.id);
      }
      state.accountDetails.response = action.payload;
      state.accountDetails.status = RS.IDLE;
      state.accountDetails.error = null;
    });

    builder.addCase(accountDelete.pending, (state) => {
      state.accountDelete.status = RS.RUNNING;
      state.accountDelete.error = null;
    });
    builder.addCase(accountDelete.fulfilled, (state) => {
      state.accountDelete.response = {};
      state.accountDelete.status = RS.IDLE;
      state.accountDelete.error = null;
    });
    builder.addCase(accountDelete.rejected, (state, action) => {
      state.accountDelete.status = RS.ERROR;
      state.accountDelete.error = action.payload;
    });
    builder.addCase(clearAccountDelete, (state) => {
      state.accountDelete.response = null;
      state.accountDelete.status = RS.IDLE;
      state.accountDelete.error = null;
    });

    builder.addCase(getAccountDetails.rejected, (state, action) => {
      state.accountDetails.status = RS.ERROR;
      state.accountDetails.error = action.payload;
    });
    builder.addCase(getCustomerInfo.pending, (state) => {
      state.getCustomerInfo.status = RS.RUNNING;
      state.getCustomerInfo.error = null;
    });
    builder.addCase(getCustomerInfo.fulfilled, (state, action) => {
      state.getCustomerInfo.response = action.payload.response;
      state.getCustomerInfo.status = RS.IDLE;
      state.getCustomerInfo.error = null;
    });
    builder.addCase(getCustomerInfo.rejected, (state, action) => {
      state.getCustomerInfo.status = RS.ERROR;
      state.getCustomerInfo.error = action.payload;
    });
    builder.addCase(clearUpdateAccountDetails, (state) => {
      state.updateAccountDetails.response = null;
      state.updateAccountDetails.status = RS.IDLE;
      state.updateAccountDetails.error = null;
    });
    builder.addCase(updateAccountDetails.pending, (state) => {
      state.updateAccountDetails.response = null;
      state.updateAccountDetails.status = RS.RUNNING;
      state.updateAccountDetails.error = null;
    });
    builder.addCase(updateAccountDetails.fulfilled, (state, action) => {
      state.updateAccountDetails.response = action.payload;
      state.updateAccountDetails.status = RS.IDLE;
      state.updateAccountDetails.error = null;
    });
    builder.addCase(updateAccountDetails.rejected, (state, action) => {
      state.updateAccountDetails.response = null;
      state.updateAccountDetails.status = RS.ERROR;
      state.updateAccountDetails.error = action.payload;
    });
    builder.addCase(clearImportContacts, (state) => {
      state.importContacts.response = null;
      state.importContacts.status = RS.IDLE;
      state.importContacts.error = null;
    });
    builder.addCase(importContacts.pending, (state) => {
      state.importContacts.response = null;
      state.importContacts.status = RS.RUNNING;
      state.importContacts.error = null;
    });
    builder.addCase(importContacts.fulfilled, (state, action) => {
      state.importContacts.response = action.payload;
      state.importContacts.status = RS.IDLE;
      state.importContacts.error = null;
    });
    builder.addCase(importContacts.rejected, (state, action) => {
      state.importContacts.response = null;
      state.importContacts.status = RS.ERROR;
      state.importContacts.error = action.payload;
    });
    builder.addCase(clearContacts, (state) => {
      state.contacts.response = null;
      state.contacts.status = RS.IDLE;
      state.contacts.error = null;
    });
    builder.addCase(clearChangePassword, (state) => {
      state.changePassword.response = null;
      state.changePassword.status = RS.IDLE;
      state.changePassword.error = null;
    });
    builder.addCase(changePassword.pending, (state) => {
      state.changePassword.response = null;
      state.changePassword.status = RS.RUNNING;
      state.changePassword.error = null;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.changePassword.response = action.payload;
      state.changePassword.status = RS.IDLE;
      state.changePassword.error = null;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePassword.response = null;
      state.changePassword.status = RS.ERROR;
      state.changePassword.error = action.payload;
    });
    builder.addCase(forgotUsername.pending, (state) => {
      state.forgotUsername.status = RS.RUNNING;
      state.forgotUsername.error = null;
    });
    builder.addCase(forgotUsername.fulfilled, (state, { payload }) => {
      state.forgotUsername.status = RS.IDLE;
      state.forgotUsername.response = payload.response;
    });
    builder.addCase(forgotUsername.rejected, (state, { payload }) => {
      state.forgotUsername.status = RS.ERROR;
      state.forgotUsername.error = payload;
    });
    builder.addCase(clearForgotUsername, (state) => {
      state.forgotUsername = initialRequestState;
    });
    builder.addCase(acceptTos.pending, (state) => {
      state.acceptTos.status = RS.RUNNING;
      state.acceptTos.error = null;
    });
    builder.addCase(acceptTos.fulfilled, (state, { payload }) => {
      state.acceptTos.status = RS.IDLE;
      state.acceptTos.response = payload.response;
    });
    builder.addCase(acceptTos.rejected, (state, { payload }) => {
      state.acceptTos.status = RS.ERROR;
      state.acceptTos.error = payload;
    });
    builder.addCase(clearAcceptTos, (state) => {
      state.acceptTos = initialRequestState;
    });
    builder.addCase(updateDocumentRetention.pending, (state) => {
      state.documentRetention.response = null;
      state.documentRetention.status = RS.RUNNING;
      state.documentRetention.error = null;
    });
    builder.addCase(updateDocumentRetention.fulfilled, (state, { payload }) => {
      state.documentRetention.response = payload.response;
      state.documentRetention.status = RS.IDLE;
      state.documentRetention.error = null;
    });
    builder.addCase(updateDocumentRetention.rejected, (state, action) => {
      state.documentRetention.response = null;
      state.documentRetention.status = RS.ERROR;
      state.documentRetention.error = action.payload;
    });
    builder.addCase(clearDocumentRetention, (state) => {
      state.documentRetention = initialRequestState;
    });
    builder.addCase(updateAnonymous.pending, (state) => {
      state.changeAnonymous.response = null;
      state.changeAnonymous.status = RS.RUNNING;
      state.changeAnonymous.error = null;
    });
    builder.addCase(updateAnonymous.fulfilled, (state, { payload }) => {
      state.changeAnonymous.response = payload.response;
      state.changeAnonymous.status = RS.IDLE;
      state.changeAnonymous.error = null;
    });
    builder.addCase(updateAnonymous.rejected, (state, action) => {
      state.changeAnonymous.response = null;
      state.changeAnonymous.status = RS.ERROR;
      state.changeAnonymous.error = action.payload;
    });
    builder.addCase(clearChangeAnonymous, (state) => {
      state.changeAnonymous = initialRequestState;
    });
    ssoSlice(builder);
    hipaaSlice(builder);
    dataResidencySlice(builder);
    permissionsSlice(builder);
    notificationsSlice(builder);

    builder.addCase(logout.fulfilled, () => {
      gaEvents.setUserId(undefined);

      return initialState;
    });
  },
});

export default reducer;
