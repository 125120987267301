import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "stores/store";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import { selectors, thunks } from "stores/reducers/coverSheet.reducer";
import { useSendFaxContext } from "../contexts/context";

function CoverSheetEnforcementManager() {
  const dispatch = useAppDispatch();

  const {
    sendFaxDispatch,
    sendFaxStore: { enforcedCoverSheet, defaultCoverSheet, galleryCoverSheet, sharedCoverSheet },
  } = useSendFaxContext();

  const enforced = useAppSelector(selectors.enforced);
  const isPlanEnterprise = useAppSelector(selectIsPlanEnterprise);
  const shouldClearOtherCoverSheets =
    enforcedCoverSheet !== null && (sharedCoverSheet || defaultCoverSheet || galleryCoverSheet);

  useEffect(() => {
    if (!isPlanEnterprise) {
      return;
    }

    dispatch(thunks.enforced());
  }, [dispatch, isPlanEnterprise]);

  useEffect(() => {
    if (!isPlanEnterprise) {
      return;
    }

    // We have an enforced cover sheet, but nothing in the context store yet
    if (enforced && enforcedCoverSheet === null) {
      sendFaxDispatch({
        type: "SET_ENFORCED_COVER_SHEET",
        payload: {
          id: enforced.id,
          name: enforced.name,
          sharedBy: enforced.sharedBy,
          ownerName: enforced.ownerName,
          thumbnail: enforced.thumbnail,
          lastTimeUsed: enforced.lastTimeUsed,
        },
      });
    } else if (enforced === null && enforcedCoverSheet !== null) {
      sendFaxDispatch({
        type: "SET_ENFORCED_COVER_SHEET",
        payload: null,
      });
    }
  }, [enforced, enforcedCoverSheet, isPlanEnterprise, sendFaxDispatch]);

  useEffect(() => {
    if (!isPlanEnterprise) {
      return;
    }

    if (shouldClearOtherCoverSheets) {
      sendFaxDispatch({
        type: "CLEAR_FOR_COVER_SHEET_ENFORCEMENT",
      });
    }
  }, [isPlanEnterprise, sendFaxDispatch, shouldClearOtherCoverSheets]);

  return null;
}

export default CoverSheetEnforcementManager;
