import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import useBool from "hooks/useBool";
import useCart from "hooks/useCart";
import usePrevious from "hooks/usePrevious";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import { clearCart } from "stores/reducers/payment.cart.reducer";
import { selectApprovedDocuments } from "selectors/address.selector";
import { selectIsChangePlanDisabled } from "selectors/common.selector";
import { selectIsBundleRequiredToBeCreated } from "selectors/plan.selector";
import { setCustomPaymentAmount } from "stores/reducers/payment.amounts.reducer";
import CreateAddressBundleModal from "views/CreateAddressBundle/CreateAddressBundleModal";
import ChangePlanFromFreeComponent from "components/Payment/ChangePlanFromFree/ChangePlanFromFreeComponent";
import ChangePlanFromFreeFooterInfo from "components/Payment/ChangePlanFromFree/ChangePlanFromFreeFooterInfo";
import {
  selectCartError,
  selectIsChargeProcessLoading,
  selectHasNoActiveBundleCartError,
} from "selectors/payment.selector";
import {
  selectSelectedNumberItemType,
  selectSelectedNumberCountryRequirePhoneType,
} from "selectors/verifications.selector";

function ChangePlanFromFreeModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cartError = useSelector(selectCartError);
  const isProceedLoading = useSelector(selectIsChargeProcessLoading);
  const isChangePlanDisabled = useSelector(selectIsChangePlanDisabled);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);
  const hasNoActiveBundleError = useSelector(selectHasNoActiveBundleCartError);
  const approvedDocuments = useSelector(selectApprovedDocuments);
  const isPhoneTypeRequired = useSelector(selectSelectedNumberCountryRequirePhoneType);
  const selectedNumberType = useSelector(selectSelectedNumberItemType);

  const prevCartError = usePrevious(cartError);
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [openNewBundle, openNewBundleHandler] = useBool(false);
  const [{ creditNeeded, canChargeCart }, { chargeCart }] = useCart(true);

  const handleOnConfirm = useCallback(() => {
    if (isBundleRequiredToBeCreated) {
      openNewBundleHandler.setTrue();
      return;
    }

    if (canChargeCart) {
      chargeCart();
    } else {
      dispatch(setCustomPaymentAmount(creditNeeded));
      setShowAddCredit(true);
    }
  }, [
    dispatch,
    chargeCart,
    creditNeeded,
    canChargeCart,
    openNewBundleHandler,
    isBundleRequiredToBeCreated,
  ]);

  const handleAddCredit = useCallback(
    (isSuccess) => {
      if (isSuccess) {
        chargeCart();
      }
      setShowAddCredit(false);
    },
    [chargeCart],
  );

  useEffect(() => {
    if (cartError) {
      enqueueSnackbar(cartError, { variant: "error" });
      dispatch(clearCart());
    }
  }, [cartError, dispatch, t, enqueueSnackbar]);

  const hasNoValidBundleAvailable = useMemo(() => {
    // If the number does not require a specific type: no invalidity
    if (!isPhoneTypeRequired) return false;
    // If no approved bundle yet: no invalidity. The user will create one by proceeding
    if (approvedDocuments?.length === 0) return false;
    // Check if an approved bundle matches the required number type
    const hasValidBundleApproved = Boolean(
      approvedDocuments?.find((document) => document.number_type === selectedNumberType),
    );
    return !hasValidBundleApproved;
  }, [approvedDocuments, isPhoneTypeRequired, selectedNumberType]);

  const isConfirmDisabled =
    !(hasNoActiveBundleError && isBundleRequiredToBeCreated) &&
    (isChangePlanDisabled || !!cartError || !!prevCartError || hasNoValidBundleAvailable);

  return (
    <>
      <Modal
        onConfirm={handleOnConfirm}
        title={t("COMMON.CHANGE_PLAN")}
        data-cy="ChangePlanFromFreeModal"
        isModalDisabled={isProceedLoading}
        isConfirmLoading={isProceedLoading}
        onCancel={() => handleClosure(null)}
        isConfirmDisabled={isConfirmDisabled}
        confirmTitle={
          isBundleRequiredToBeCreated ? t("CHANGE_PLAN.PROVIDE_ID") : t("ADD_NUMBER.PROCEED")
        }
      >
        <ChangePlanFromFreeComponent />
        <ChangePlanFromFreeFooterInfo />
      </Modal>

      {showAddCredit ? (
        <AddCreditModal
          reason="change_plan"
          withSuccessModal={false}
          handleClosure={handleAddCredit}
        />
      ) : null}

      {openNewBundle ? (
        <CreateAddressBundleModal handleClosure={openNewBundleHandler.setFalse} />
      ) : null}
    </>
  );
}

ChangePlanFromFreeModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ChangePlanFromFreeModal;
