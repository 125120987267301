import { Box, makeStyles } from "@alohi/kit";

import { useAppSelector } from "stores/store";
import { selectIsAllowedToChooseAreaCode } from "selectors/plan.selector";
import CountryInfo from "../CountryInfo";
import PreviewNumber from "../PreviewNumber";
import AvailableAreas from "../AvailableAreas";
import AvailableCountries from "../AvailableCountries";
import AvailableNumberTypes from "../AvailableNumberTypes";
import AddressVerification from "../../AddressBundle/AddressVerification/AddressVerification";
import CurrentPlan from "./CurrentPlan";

function ChangeNumberComponent() {
  const classes = useStyles();

  const isAllowedToChooseAreaCode = useAppSelector(selectIsAllowedToChooseAreaCode);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.options} pr={"22%"} mt={2}>
        <CurrentPlan />
        <AvailableCountries />
        {isAllowedToChooseAreaCode ? (
          <>
            <AvailableAreas />
            <AvailableNumberTypes actionType="number_replacement" />
            <AddressVerification />
          </>
        ) : null}
      </Box>
      {!isAllowedToChooseAreaCode ? <CountryInfo /> : null}
      <PreviewNumber />
    </Box>
  );
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
    minHeight: "300px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  options: {
    display: "flex",
    flexDirection: "column",
  },
});

export default ChangeNumberComponent;
