import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { boxNames } from "../../enums/faxes";
import syncHistoryEvents from "../../enums/syncHistory";
import { selectSyncHistoryEvents } from "../../selectors/syncHistory.selector";
import { clearSyncHistoryEvents, syncCdrs } from "../../stores/reducers/syncHistory.reducer";
import {
  selectBoxHasMore,
  selectSelectedNumber,
  selectSelectedMemberUid,
  selectIsSyncHistoryAllowed,
} from "../../selectors/faxes.selector";

function SyncHistory() {
  const dispatch = useDispatch();

  const syncEvents = useSelector(selectSyncHistoryEvents);
  const selectedNumber = useSelector(selectSelectedNumber);
  const selectedMemberUid = useSelector(selectSelectedMemberUid);
  const inboxHasMore = useSelector(selectBoxHasMore(boxNames.inbox));
  const sentboxHasMore = useSelector(selectBoxHasMore(boxNames.sentbox));
  const trashboxHasMore = useSelector(selectBoxHasMore(boxNames.trashbox));
  const isInboxSyncAllowed = useSelector(selectIsSyncHistoryAllowed(boxNames.inbox));
  const isSentboxSyncAllowed = useSelector(selectIsSyncHistoryAllowed(boxNames.sentbox));
  const isTrashboxSyncAllowed = useSelector(selectIsSyncHistoryAllowed(boxNames.trashbox));

  useEffect(() => {
    if (syncEvents?.length) {
      // Only certain events require the download of the cdr.
      const downloadCdrs = new Set();
      let boxData = {
        [boxNames.inbox]: {
          events: {},
          selectedNumber,
          hasMore: inboxHasMore,
          isSyncAllowed: !isInboxSyncAllowed,
        },
        [boxNames.sentbox]: {
          events: {},
          selectedNumber,
          hasMore: sentboxHasMore,
          isSyncAllowed: !isSentboxSyncAllowed,
        },
        [boxNames.trashbox]: {
          events: {},
          selectedNumber,
          hasMore: trashboxHasMore,
          isSyncAllowed: !isTrashboxSyncAllowed,
        },
      };

      for (let i = 0; i < syncEvents.length; i++) {
        const id = syncEvents[i].cdr_id;
        const action = syncEvents[i].action;
        const direction =
          syncEvents[i].cdr_direction === "incoming" ? boxNames.inbox : boxNames.sentbox;

        if (
          action === syncHistoryEvents.ADD ||
          action === syncHistoryEvents.TRASH ||
          action === syncHistoryEvents.MODIFY ||
          action === syncHistoryEvents.RESTORE
        ) {
          downloadCdrs.add(id);
        }

        if (direction in boxData) {
          if (action in boxData[direction]["events"]) {
            boxData[direction]["events"][action].push(id);
          } else {
            boxData[direction]["events"][action] = [id];
          }
        } else {
          boxData[direction]["events"][action] = [id];
        }

        // Manually add events to trashbox, to update the cdrs based on the events.
        if (
          action === syncHistoryEvents.DELETE ||
          action === syncHistoryEvents.TRASH ||
          action === syncHistoryEvents.MODIFY ||
          action === syncHistoryEvents.UNREAD ||
          action === syncHistoryEvents.RESTORE ||
          action === syncHistoryEvents.READ
        ) {
          if (action in boxData[boxNames.trashbox]["events"]) {
            boxData[boxNames.trashbox]["events"][action].push(id);
          } else {
            boxData[boxNames.trashbox]["events"][action] = [id];
          }
        }
      }
      dispatch(
        syncCdrs({
          boxData,
          number: "all",
          memberUid: selectedMemberUid,
          downloadCdrs: Array.from(downloadCdrs),
        }),
      );
      dispatch(clearSyncHistoryEvents());
    }
  }, [
    dispatch,
    syncEvents,
    inboxHasMore,
    sentboxHasMore,
    selectedNumber,
    trashboxHasMore,
    selectedMemberUid,
    isInboxSyncAllowed,
    isSentboxSyncAllowed,
    isTrashboxSyncAllowed,
  ]);

  return null;
}

export default memo(SyncHistory);
