import { useTranslation } from "react-i18next";
import { Box, Modal, Typography } from "@alohi/kit";

interface ContactAdminToUseFeatureProps {
  title: string;
  description: string;
  handleClosure: () => void;
}

function ContactAdminToUseFeature({
  description,
  title,
  handleClosure,
}: ContactAdminToUseFeatureProps) {
  const { t } = useTranslation();

  return (
    <Modal
      maxWidth="xs"
      title={title}
      confirmTitle={t("COMMON.CLOSE")}
      onConfirm={() => handleClosure()}
      onPressEnter={() => handleClosure()}
      onPressEscape={() => handleClosure()}
    >
      <Box p={3}>
        <Typography>{description}</Typography>
      </Box>
    </Modal>
  );
}

export default ContactAdminToUseFeature;
