import { Modal, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";

import useBool from "hooks/useBool";
import { helpCenterUrls } from "routes/urls";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import { SharedCoverSheet } from "views/SendFax/contexts/store";
import { selectors, thunks } from "stores/reducers/coverSheet.reducer";
import { Table, Typography, makeStyles, TableColumnsProp, GridSelectionModel } from "ui";
import EmptyTable from "../components/EmptyTable";
import TableRadio from "../components/TableRadio";
import SharedByText from "../components/SharedByText";
import LastTimeUsed from "../components/LastTimeUsed";
import UploadButton from "../components/UploadButton";
import TableThumbnail from "../components/TableThumbnail";
import useDownloadCoverSheet from "../hooks/useDownloadCoverSheet";
import ModalTitleWithHelperLink from "../components/ModalTitleWithHelperLink";
import AddCoverSheetModal from "../modals/AddCoverSheetModal/AddCoverSheetModal";
import ViewCoverSheetModal from "./ViewCoverSheetModal";
import RemoveCoverSheetModal from "./RemoveCoverSheetModal";

interface CoverSheetEnforcementModalProps {
  handleClosure: (isSuccess: boolean) => void;
  enforcedCoverSheet: null | { id: string; name: string };
  setNewEnforcedCoverSheet: (enforcedCoverSheet: null | { id: string; name: string }) => void;
}

function CoverSheetEnforcementModal({
  handleClosure,
  enforcedCoverSheet,
  setNewEnforcedCoverSheet,
}: CoverSheetEnforcementModalProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const shared = useAppSelector(selectors.shared);
  const enforced = useAppSelector(selectors.enforced);
  const isListRunning = useAppSelector(selectors.isListRunning);

  const [idToRemove, setIdToRemove] = useState<null | string>(null);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(
    enforcedCoverSheet ? [enforcedCoverSheet.id] : [],
  );
  const [isAddCoverSheetModalOpen, isAddCoverSheetModalOpenBool] = useBool(false);
  const [idToView, setIdToView] = useState<null | { name: string; id: string }>(null);

  const [selectedRowId] = selectionModel;
  const { downloadById } = useDownloadCoverSheet();

  const handleSelectionModel = useCallback((newSelectionModel: GridSelectionModel) => {
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((newId) => !prevModel.includes(newId)),
    );
  }, []);

  const rows = useMemo(() => shared ?? [], [shared]);
  const isLoading = useMemo(() => isListRunning && shared === null, [shared, isListRunning]);

  const rowActions = useMemo(() => {
    return (index: number) =>
      rows.map(({ id, name }) => [
        {
          label: t("COMMON.VIEW"),
          callback: () => {
            setIdToView({ id, name });
          },
        },
        {
          label: t("COMMON.DOWNLOAD"),
          callback: () => {
            downloadById(id, true);
          },
        },
        {
          label: "Divider",
        },
        {
          label: t("COMMON.DELETE"),
          callback: () => {
            setIdToRemove(id);
          },
        },
      ])[index];
  }, [rows, t, downloadById]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    columns.push({
      flex: 0.1,
      headerName: " ",
      sortable: false,
      field: "thumbnailUrl",
      renderCell: function render({ row }: { row: SharedCoverSheet }) {
        return <TableThumbnail thumbnail={row.thumbnail} />;
      },
    });

    columns.push({
      flex: 0.3,
      field: "name",
      sortable: false,
      headerName: t("COMMON.NAME"),
      renderCell: function render({ row }: { row: SharedCoverSheet }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.name}
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.2,
      sortable: false,
      field: "sharedBy",
      headerName: t("COVER_SHEET.SHARED_BY"),
      renderCell: function render({ row }: { row: SharedCoverSheet }) {
        return <SharedByText sharedBy={row.sharedBy} ownerName={row.ownerName} />;
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "lastTimeUsed",
      headerName: t("COVER_SHEET.LAST_TIME_USED"),
      renderCell: function render({ row }: { row: SharedCoverSheet }) {
        return <LastTimeUsed timestamp={row.lastTimeUsed} />;
      },
    });

    columns.push({
      flex: 0.1,
      sortable: false,
      field: "actions",
      headerName: " ",
      renderCell: function render({ row }: { row: SharedCoverSheet }) {
        return <ColumnActions dataIndex={row.index} createActions={rowActions} />;
      },
    });

    return columns;
  }, [t, classes.row, rowActions]);

  const handleOnSelect = () => {
    const selection = rows.find(({ id }) => id === selectedRowId);

    if (selection) {
      setNewEnforcedCoverSheet({ id: selection.id, name: selection.name });
    }
  };

  const handleRemove = () => {
    if (idToRemove === enforcedCoverSheet?.id) {
      setNewEnforcedCoverSheet(enforced ? { id: enforced.id, name: enforced.name } : null);
    }

    setIdToRemove(null);
  };

  useEffect(() => {
    dispatch(thunks.list());
  }, [dispatch]);

  if (isAddCoverSheetModalOpen) {
    return (
      <AddCoverSheetModal
        isShared
        disableAnimation
        title={t("COVER_SHEET.ADD_NEW_SHARED")}
        handleClosure={isAddCoverSheetModalOpenBool.setFalse}
      />
    );
  }

  if (idToRemove) {
    return <RemoveCoverSheetModal disableAnimation id={idToRemove} handleClosure={handleRemove} />;
  }

  if (idToView) {
    return (
      <ViewCoverSheetModal
        id={idToView.id}
        disableAnimation
        name={idToView.name}
        handleClosure={() => setIdToView(null)}
      />
    );
  }

  return (
    <Modal
      maxWidth="sm"
      scroll="paper"
      disableAnimation
      onConfirm={handleOnSelect}
      onCancel={() => handleClosure(false)}
      isConfirmDisabled={!Boolean(selectedRowId)}
      customFooterInfo={
        <UploadButton
          onOpen={isAddCoverSheetModalOpenBool.setTrue}
          isDisabled={Boolean(shared && shared?.length >= 50)}
        />
      }
      title={
        <ModalTitleWithHelperLink
          title={t("COVER_SHEET.ENFORCEMENT")}
          link={helpCenterUrls.coverSheetEnforcement}
        />
      }
    >
      <Box mt={isLoading ? 2 : 0}>
        <Table
          noBorder
          rows={rows}
          rowHeight={76}
          columns={columns}
          checkboxSelection
          showTableWhenEmpty
          disableSelectionOnClick={false}
          selectionModel={selectionModel}
          getCellClassName={() => classes.cell}
          isLoading={isLoading}
          onSelectionModelChange={handleSelectionModel}
          components={{
            BaseCheckbox: TableRadio,
            NoRowsOverlay: () => (
              <EmptyTable
                isRunning={isListRunning}
                text="The list of Shared Cover Sheets is empty"
              />
            ),
          }}
        />
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    cursor: "text",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cell: {
    cursor: "pointer",
  },
}));

export default CoverSheetEnforcementModal;
