import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Box } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";

import { selectCanIEditBlacklist } from "selectors/ability.selector";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import { deleteBlacklistContacts } from "stores/reducers/contacts.blacklist.reducer";
import SingleBlacklistMessage from "./SingleBlacklistMessage";

function ConfirmDeleteBlacklistModal({ onCancel, onConfirm, contactIds }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canIEditBlacklist = useSelector(selectCanIEditBlacklist);

  const renderMessage = () => {
    if (contactIds.length === 1) {
      return <SingleBlacklistMessage contactId={contactIds[0]} />;
    }
    return t("CONTACTS.QUESTION_REMOVE_SELECTED_CONTACTS", {
      listName: t("CONTACTS.BLACKLIST").toLowerCase(),
    });
  };

  if (!canIEditBlacklist)
    return (
      <ContactAdminToUseFeature
        description={t("CONTACTS.CONTACT_ADMIN_TO_EDIT_BLACKLIST")}
        title={t("CONTACTS.DELETE")}
        handleClosure={onCancel}
      />
    );

  return (
    <Modal
      maxWidth="xs"
      title={t("CONTACTS.DELETE")}
      onCancel={onCancel}
      onConfirm={() => {
        dispatch(deleteBlacklistContacts({ idsToDelete: contactIds }));
        onConfirm();
      }}
    >
      <Box p={3}>
        <Typography variant="body">{renderMessage()}</Typography>
      </Box>
    </Modal>
  );
}

ConfirmDeleteBlacklistModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  contactIds: PropTypes.arrayOf(PropTypes.string),
};

export default ConfirmDeleteBlacklistModal;
