import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { faInbox, faCheckToSlot, faInboxOut, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import {
  faInbox as faInboxSolid,
  faInboxOut as faInboxOutSolid,
  faTrashCan as faTrashCanSolid,
  faCheckToSlot as faCheckToSlotSolid,
} from "@fortawesome/pro-solid-svg-icons";

import dataCy from "enums/dataCy";
import { urls } from "routes/urls";
import { clearInbox } from "stores/reducers/faxes.inbox.reducer";
import { clearSentbox } from "stores/reducers/faxes.sentbox.reducer";
import { clearOutbox } from "stores/reducers/faxes.outbox.reducer";
import { clearTrashbox } from "stores/reducers/faxes.trashbox.reducer";
import AppSubMenu, {
  AppSubMenuItem,
} from "components/Layout/PrivateLayout/components/AppSubMenu/AppSubMenu";
import { useMemo } from "react";

function FaxesNavigation() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const items: AppSubMenuItem[] = useMemo(
    () =>
      [
        {
          label: t("FAXES.INBOX"),
          to: urls.faxes.inbox,
          icon: pathname === urls.faxes.inbox ? faInboxSolid : faInbox,
          onClick: () => {
            if (pathname === urls.faxes.inbox) {
              dispatch(clearInbox());
            }
          },
          "data-cy": dataCy.tabToInbox,
        },
        {
          label: t("FAXES.SENT"),
          to: urls.faxes.sent,
          icon: pathname === urls.faxes.sent ? faCheckToSlotSolid : faCheckToSlot,
          onClick: () => {
            if (pathname === urls.faxes.sent) {
              dispatch(clearSentbox());
            }
          },
          "data-cy": dataCy.tabToSent,
        },
        {
          label: t("FAXES.OUTBOX"),
          to: urls.faxes.outbox,
          icon: pathname === urls.faxes.outbox ? faInboxOutSolid : faInboxOut,
          onClick: () => {
            if (pathname === urls.faxes.outbox) {
              dispatch(clearOutbox());
            }
          },
          "data-cy": dataCy.tabToOutbox,
        },

        {
          label: t("FAXES.TRASH"),
          to: urls.faxes.trash,
          icon: pathname === urls.faxes.trash ? faTrashCanSolid : faTrashCan,
          onClick: () => {
            if (pathname === urls.faxes.trash) {
              dispatch(clearTrashbox());
            }
          },
          "data-cy": dataCy.tabToTrash,
        },
      ] satisfies AppSubMenuItem[],
    [dispatch, pathname, t],
  );

  return <AppSubMenu items={items} />;
}

export default FaxesNavigation;
