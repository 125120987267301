import { API_AFFOGATO } from "config";
import { notificationAttachmentsValues } from "../enums/notifications";
import { fetchWithRefresh } from "../helpers/fetch";

const transformUpdateNotificationBody = (
  { notificationAttachments, sendFax, receiveFax, emails, hasMultipleEmails },
  updateType,
  prevAccountData,
) => {
  let body = {};
  switch (updateType) {
    case "confirmation":
      body = {
        ...prevAccountData,
        attachments: {
          ...prevAccountData.attachments,
          confirmation_page:
            notificationAttachments === notificationAttachmentsValues.confirmationPage,
        },
      };
      break;
    case "email":
      body = {
        ...prevAccountData,
        addresses: hasMultipleEmails ? emails.filter((email) => email) : [emails[0] ?? ""],
        receive_fax: receiveFax !== "off",
        send_fax: sendFax !== "off",
        attachments: {
          ...prevAccountData.attachments,
          send_fax: sendFax !== "off" && sendFax === "withAttachment",
          receive_fax: receiveFax !== "off" && receiveFax === "withAttachment",
        },
      };
      break;
    default:
      break;
  }
  return body;
};

const getNotifications = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/notifications`;
  return fetchWithRefresh(path, requestOptions);
};

const updateNotifications = (accountData, updateType, prevAccountData) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      email: transformUpdateNotificationBody(accountData, updateType, prevAccountData),
    },
  };
  const path = `${API_AFFOGATO}/notifications`;
  return fetchWithRefresh(path, requestOptions);
};

export const notificationsApi = { updateNotifications, getNotifications };
