import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles, Divider, Box } from "@alohi/kit";

import Currency from "components/Core/Currency/Currency";
import {
  selectDiscountCodeType,
  selectDiscountCodeAmount,
  selectDiscountCodeApplied,
  selectDiscountCodeCurrency,
  selectDiscountCodeResponse,
  selectDiscountCodeReducedAmount,
} from "selectors/payment.selector";

function DiscountCodeInvoice() {
  const classes = useStyles();
  const { t } = useTranslation();

  const discountType = useSelector(selectDiscountCodeType);
  const discountAmount = useSelector(selectDiscountCodeAmount);
  const discountCode = useSelector(selectDiscountCodeResponse);
  const discountApplied = useSelector(selectDiscountCodeApplied);
  const discountCurrency = useSelector(selectDiscountCodeCurrency);
  const discountCodeReducedAmount = useSelector(selectDiscountCodeReducedAmount);

  if (!discountCode) {
    return null;
  }

  const discountLabel =
    discountType === "percent" ? (
      discountCodeReducedAmount + "%"
    ) : (
      <Currency value={discountCodeReducedAmount} currency={discountCurrency} />
    );

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box>{t("ADD_CREDIT.ORIGINAL_AMOUNT")}</Box>
        <Box style={{ textDecoration: "line-through" }}>
          <Currency value={discountAmount} currency={discountCurrency} />
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Trans i18nKey="ADD_CREDIT.AMOUNT_TO_PAY" components={{ amount: discountLabel }} />
        </Box>
        <Box>
          <Currency value={discountApplied} currency={discountCurrency} />
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  root: {
    flex: 1,
    display: "flex",
    borderRadius: "8px",
    padding: spacing(3),
    flexDirection: "column",
    backgroundColor: alohi.neutral100,
    margin: spacing(3, 3, 0, 3),
    border: `1px solid ${alohi.neutral200}`,
  },
  divider: {
    margin: spacing(1.5, 0),
  },
}));

export default DiscountCodeInvoice;
