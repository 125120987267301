import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { oauthApi } from "../../api/oauth.api";
import RS from "../../enums/requestStatus";
import { logout } from "./authentication.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  removeAppAccess: initialRequestState,
  getClientPublicInfo: initialRequestState,
  getAllGrants: initialRequestState,
  updateClientPrivateInfo: initialRequestState,
  getAuthNToken: initialRequestState,
  authorizeApp: initialRequestState,
  cancelAppAuthorization: initialRequestState,
};

const removeAppAccess = createAsyncThunk(
  "APPS/REMOVE_APP_ACCESS",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await oauthApi.revokeGrant(id);
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getClientPublicInfo = createAsyncThunk(
  "APPS/GET_CLIENT_PUBLIC_INFO",
  async ({ clientId }, { rejectWithValue }) => {
    try {
      const response = await oauthApi.getClientInfo(clientId);
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getAllGrants = createAsyncThunk("APPS/GET_ALL_GRANTS", async (_, { rejectWithValue }) => {
  try {
    const response = await oauthApi.getGrants();
    return { response };
  } catch (error) {
    return rejectWithValue(error.origin);
  }
});

const getAuthNToken = createAsyncThunk(
  "APPS/GET_AUTH_N_TOKEN",
  async (_, { getState, rejectWithValue }) => {
    const accessToken = getState().authentication.accessToken;
    try {
      const response = await oauthApi.getAuthNToken({ accessToken });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const authorizeApp = createAsyncThunk(
  "APPS/AUTHORIZE_APP",
  async ({ authNToken, authZToken, state }, { rejectWithValue }) => {
    try {
      const response = await oauthApi.authorizeApp({
        authNToken,
        authZToken,
        state,
        isCancel: false,
      });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const cancelAppAuthorization = createAsyncThunk(
  "APPS/CANCEL_APP_AUTHORIZATION",
  async ({ authNToken, authZToken, state }, { rejectWithValue }) => {
    try {
      const response = await oauthApi.authorizeApp({
        authNToken,
        authZToken,
        state,
        isCancel: true,
      });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);
export const clearRemoveAppAccess = createAction("APPS/CLEAR_REMOVE_APP_ACCESS");
export const clearGetAuthNToken = createAction("APPS/CLEAR_GET_AUTH_N_TOKEN");
export const clearAuthorizeApp = createAction("APPS/CLEAR_AUTHORIZE_APP");
export const clearCancelAppAuthorization = createAction("APPS/CLEAR_CANCEL_APP_AUTHORIZATION");

const { reducer } = createSlice({
  name: "APPS",
  initialState,
  extraReducers: {
    [removeAppAccess.pending]: (state) => {
      state.removeAppAccess.status = RS.RUNNING;
      state.removeAppAccess.error = null;
    },
    [removeAppAccess.fulfilled]: (state, { payload }) => {
      state.removeAppAccess.status = RS.IDLE;
      state.removeAppAccess.response = payload.response;
    },
    [removeAppAccess.rejected]: (state, { payload }) => {
      state.removeAppAccess.status = RS.ERROR;
      state.removeAppAccess.error = payload;
    },
    [clearRemoveAppAccess]: (state) => {
      state.removeAppAccess = initialRequestState;
    },
    [getClientPublicInfo.pending]: (state) => {
      state.getClientPublicInfo.status = RS.RUNNING;
      state.getClientPublicInfo.error = null;
    },
    [getClientPublicInfo.fulfilled]: (state, { payload }) => {
      state.getClientPublicInfo.status = RS.IDLE;
      state.getClientPublicInfo.response = payload.response;
    },
    [getClientPublicInfo.rejected]: (state, { payload }) => {
      state.getClientPublicInfo.status = RS.ERROR;
      state.getClientPublicInfo.error = payload;
    },
    [getAllGrants.pending]: (state) => {
      state.getAllGrants.status = RS.RUNNING;
      state.getAllGrants.error = null;
    },
    [getAllGrants.fulfilled]: (state, { payload }) => {
      state.getAllGrants.status = RS.IDLE;
      state.getAllGrants.response = payload.response;
    },
    [getAllGrants.rejected]: (state, { payload }) => {
      state.getAllGrants.status = RS.ERROR;
      state.getAllGrants.error = payload;
    },
    [getAuthNToken.pending]: (state) => {
      state.getAuthNToken.status = RS.RUNNING;
      state.getAuthNToken.error = null;
    },
    [getAuthNToken.fulfilled]: (state, { payload }) => {
      state.getAuthNToken.status = RS.IDLE;
      state.getAuthNToken.response = payload.response;
    },
    [getAuthNToken.rejected]: (state, { payload }) => {
      state.getAuthNToken.status = RS.ERROR;
      state.getAuthNToken.error = payload;
    },
    [clearGetAuthNToken]: (state) => {
      state.getAuthNToken = initialRequestState;
    },
    [authorizeApp.pending]: (state) => {
      state.authorizeApp.status = RS.RUNNING;
      state.authorizeApp.error = null;
    },
    [authorizeApp.fulfilled]: (state, { payload }) => {
      state.authorizeApp.status = RS.IDLE;
      state.authorizeApp.response = payload.response;
    },
    [authorizeApp.rejected]: (state, { payload }) => {
      state.authorizeApp.status = RS.ERROR;
      state.authorizeApp.error = payload;
    },
    [clearAuthorizeApp]: (state) => {
      state.authorizeApp = initialRequestState;
    },
    [cancelAppAuthorization.pending]: (state) => {
      state.cancelAppAuthorization.status = RS.RUNNING;
      state.cancelAppAuthorization.error = null;
    },
    [cancelAppAuthorization.fulfilled]: (state, { payload }) => {
      state.cancelAppAuthorization.status = RS.IDLE;
      state.cancelAppAuthorization.response = payload.response;
    },
    [cancelAppAuthorization.rejected]: (state, { payload }) => {
      state.cancelAppAuthorization.status = RS.ERROR;
      state.cancelAppAuthorization.error = payload;
    },
    [clearCancelAppAuthorization]: (state) => {
      state.cancelAppAuthorization = initialRequestState;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export {
  removeAppAccess,
  getClientPublicInfo,
  getAllGrants,
  getAuthNToken,
  authorizeApp,
  cancelAppAuthorization,
};
export default reducer;
