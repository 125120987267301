import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, Helper, Typography } from "@alohi/kit";

import useBool from "hooks/useBool";
import { getI18nCountry } from "helpers/numbers";
import InfiniTable from "components/InfiniTable/InfiniTable";
import { selectAddresses } from "selectors/address.selector";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import { clearPostNewAddress, getAddresses } from "stores/reducers/address.reducer";
import AddressesTooltip from "components/Profile/General/RegulatoryCompliancePanel/components/AddressesTooltip";
import CreateNewAddressModal from "./CreateNewAddressModal";

function AddressesModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addresses = useSelector(selectAddresses);
  const [openCreateAddress, openCreateAddressHandler] = useBool(false);

  const columns = useMemo(
    () => [
      {
        name: "id",
        options: {
          display: false,
        },
      },
      {
        name: "iso_country",
        label: t("PROFILE.REGULATORY_COMPLIANCE_COUNTRY"),
        options: {
          customBodyRender: function customBodyRender(country) {
            if (!country) {
              return null;
            }
            return (
              <Box display="flex" flexDirection="row">
                <CountryFlag
                  style={{
                    marginTop: "3px",
                    marginRight: "8px",
                    width: "fit-content !important",
                  }}
                  code={country}
                />
                <Box>{getI18nCountry(country)?.label ?? ""}</Box>
              </Box>
            );
          },
        },
      },
      {
        name: "region",
        label: t("PROFILE.REGULATORY_COMPLIANCE_REGION"),
        options: {
          customBodyRender: (region) => region ?? "",
        },
      },
      {
        name: "city",
        label: t("PROFILE.REGULATORY_COMPLIANCE_CITY"),
        options: {
          customBodyRender: (city) => city ?? "",
        },
      },
    ],
    [t],
  );

  const options = {
    sort: false,
    selectableRows: "none",
    selectableRowsHeader: false,
    textLabels: {
      body: {
        noMatch: t("ADDRESS.NO_ADDRESS"),
      },
    },
  };

  const handleNewAddress = useCallback(
    (address) => {
      if (address) {
        dispatch(getAddresses({ force: true }));
        dispatch(clearPostNewAddress());
      }
      openCreateAddressHandler.setFalse();
    },
    [dispatch, openCreateAddressHandler],
  );

  return (
    <Modal
      scroll="paper"
      onCancel={handleClosure}
      title={
        <Box>
          <Typography type="modalTitle">{t("ADDRESS.TITLE")}</Typography>
          <Box ml={1} component="a">
            <Helper
              size="lg"
              variant="light"
              sx={{ cursor: "pointer" }}
              placement="right"
              tooltip={<AddressesTooltip />}
            />
          </Box>
        </Box>
      }
      confirmTitle={t("ADDRESS.ADD_ADDRESS")}
      onConfirm={openCreateAddressHandler.setTrue}
    >
      <InfiniTable
        data={addresses}
        isShowTableNoData
        isTopBorderHidden
        columns={columns}
        options={options}
      />
      {openCreateAddress ? (
        <CreateNewAddressModal country="" handleClosure={handleNewAddress} />
      ) : null}
    </Modal>
  );
}

AddressesModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default AddressesModal;
