import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Box, Typography, useSnackbar, makeStyles } from "@alohi/kit";

import googleLogo from "assets/images/google.svg";
import { signInWithGoogle } from "helpers/google";
import { loginWithGoogle } from "stores/reducers/authentication.reducer";
import {
  selectIsSignupWithGoogleRequired,
  selectLoginWithGoogleErrorNotification,
} from "selectors/authentication.selector";
import SignupByProvider from "./SignupByProvider";

function LoginWithGoogle({ referrerUid, invitationToken, invitationEmail }) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <Button
        fullWidth
        variant="white"
        aria-label="google"
        onClick={() =>
          signInWithGoogle({
            redirectTo: location.state?.redirectTo,
            invitationToken,
            referrerUid,
            invitationEmail,
          })
        }
      >
        <Box className={classes.googleWrapper}>
          <img src={googleLogo} alt="Google" width={25} height={25} />
          <Typography className={classes.google}>Google</Typography>
        </Box>
      </Button>
      <HandleGoogleCallback />
    </>
  );
}

// Handle redirection from Google
function HandleGoogleCallback() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const errorNotification = useSelector(selectLoginWithGoogleErrorNotification);
  const isUserNotFound = useSelector(selectIsSignupWithGoogleRequired);

  const googleResponseData = useRef(queryString.parse(location.hash));

  const [googleIdToken, setGoogleIdToken] = useState(null);
  const [googleAccessToken, setGoogleAccessToken] = useState(null);
  const [invitationToken, setInvitationToken] = useState(null);
  const [invitationEmail, setInvitationEmail] = useState(null);
  const [referrerUid, setReferrerUid] = useState(null);
  const [signupModalState, setSignupModalState] = useState({
    isOpen: false,
    newUserInfo: {
      name: null,
      lastname: null,
      email: null,
      imageUrl: null,
      token: null,
    },
  });

  const isSignUpProcess = isUserNotFound || !!invitationToken;

  // Handle "hash fragment" callback from Google
  useEffect(() => {
    !!googleResponseData.current?.state &&
      (async () => {
        try {
          const googleResponseDataState = await JSON.parse(googleResponseData.current?.state);
          history.replace({
            pathname: window.location.pathname,
            state: { redirectTo: googleResponseDataState?.redirectTo },
          });
          if (
            googleResponseDataState?.provider === "google" &&
            !!googleResponseData.current.access_token
          ) {
            setGoogleAccessToken(googleResponseData.current.access_token);
            setInvitationToken(googleResponseDataState?.invitationToken ?? null);
            setInvitationEmail(googleResponseDataState?.invitationEmail ?? null);
            setReferrerUid(googleResponseDataState?.referrerUid ?? null);
            setGoogleIdToken(googleResponseData.current.id_token);

            if (!googleResponseDataState?.invitationToken) {
              window.__loadingScreen?.show?.();

              dispatch(
                loginWithGoogle({
                  token: googleResponseData.current.access_token,
                }),
              );
            }
          }
        } catch {
          history.replace({
            pathname: window.location.pathname,
          });
          enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        }
      })();
  }, [googleResponseData, dispatch, enqueueSnackbar, history, t]);

  useEffect(() => {
    if (isSignUpProcess && googleIdToken) {
      const googleUserBasicInfo = jwt_decode(googleIdToken);
      if (!!invitationEmail && invitationEmail !== googleUserBasicInfo.email) {
        enqueueSnackbar(t("FORMS.CORPORATE_MEMBER_WRONG_EMAIL"), {
          variant: "error",
        });
      } else {
        setSignupModalState({
          isOpen: true,
          newUserInfo: {
            name: googleUserBasicInfo.given_name,
            lastname: googleUserBasicInfo.family_name,
            email: googleUserBasicInfo.email,
            imageUrl: googleUserBasicInfo.picture + "-s145",
            token: googleAccessToken,
          },
        });
      }
    }
  }, [
    isSignUpProcess,
    googleIdToken,
    googleAccessToken,
    invitationToken,
    invitationEmail,
    enqueueSnackbar,
    t,
  ]);

  useEffect(() => {
    if (errorNotification) {
      enqueueSnackbar(errorNotification, { variant: "error" });
    }
  }, [enqueueSnackbar, errorNotification]);

  if (signupModalState.isOpen) {
    return (
      <Modal isFooterHidden={true} maxWidth="xs">
        <SignupByProvider
          provider={"google"}
          referrerUid={referrerUid}
          invitationToken={invitationToken}
          newUserInfo={signupModalState.newUserInfo}
          handleClosure={() =>
            setSignupModalState({
              isOpen: false,
              newUserInfo: {
                name: null,
                lastname: null,
                email: null,
                imageUrl: null,
                token: null,
              },
            })
          }
        />
      </Modal>
    );
  }

  return null;
}

const useStyles = makeStyles(({ spacing }) => ({
  googleWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  google: {
    fontWeight: "unset",
    marginLeft: spacing(2),
  },
}));

LoginWithGoogle.propTypes = {
  referrerUid: PropTypes.string,
  invitationToken: PropTypes.string,
  invitationEmail: PropTypes.string,
};

export default LoginWithGoogle;
