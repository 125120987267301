import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifications } from "api";

import RS from "../../enums/requestStatus";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialCurrenciesState = {
  currencies: {
    availableCurrencies: initialRequestState,
  },
};

const getAvailableCurrencies = createAsyncThunk(
  `VERIFICATIONS/GET_AVAILABLE_CURRENCIES`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await verifications.getAvailableCurrencies();
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().verifications.currencies.availableCurrencies.response === null;
    },
  },
);

const currenciesSlice = {
  [getAvailableCurrencies.pending]: (state) => {
    state.currencies.availableCurrencies.status = RS.RUNNING;
    state.currencies.availableCurrencies.error = null;
  },
  [getAvailableCurrencies.fulfilled]: (state, action) => {
    state.currencies.availableCurrencies.response = action.payload;
    state.currencies.availableCurrencies.status = RS.IDLE;
    state.currencies.availableCurrencies.error = null;
  },
  [getAvailableCurrencies.rejected]: (state, action) => {
    state.currencies.availableCurrencies.status = RS.ERROR;
    state.currencies.availableCurrencies.error = action.payload;
  },
};

export { getAvailableCurrencies, currenciesSlice, initialCurrenciesState };
