import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigationModalApi, NavigationModal, Box, useSnackbar } from "@alohi/kit";

import { Typography } from "ui";
import { useInput } from "hooks/useInput";
import PhoneInput from "components/Forms/PhoneInput";
import useOnMountCondition from "hooks/useOnMountCondition";
import { isValidPhoneNumber } from "helpers/inputValidation";
import {
  requestSmsPhoneVerification,
  clearRequestSmsPhoneVerification,
} from "stores/reducers/verifications.reducer";
import {
  selectRequestSmsPhoneVerificationError,
  selectRequestSmsPhoneVerificationSuccess,
  selectRequestSmsPhoneVerificationErrorInvalidNumber,
  selectRequestSmsPhoneVerificationErrorDuplicatePhone,
} from "selectors/verifications.selector";
import { VIEWS } from "../PhoneVerificationModal";
import { usePhoneVerificationApi } from "../context/api";

function PhoneVerificationEnterPhone({ onCancel, defaultPhoneNumber = "" }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { updateStore } = usePhoneVerificationApi();
  const { updateStep, goToStep } = useNavigationModalApi();

  const error = useSelector(selectRequestSmsPhoneVerificationError);
  const success = useSelector(selectRequestSmsPhoneVerificationSuccess);
  const errorDuplicatePhone = useSelector(selectRequestSmsPhoneVerificationErrorDuplicatePhone);
  const errorInvalidNumber = useSelector(selectRequestSmsPhoneVerificationErrorInvalidNumber);

  const [phone, phoneInput] = useInput(defaultPhoneNumber, isValidPhoneNumber);
  const setShowsError = phoneInput.setShowsError;

  const isDefaultNumber = !!defaultPhoneNumber;
  const isPhoneInputValid = phoneInput.isValid;

  const touchInput = phoneInput.touch;

  const handleEnterPhone = useCallback(() => {
    touchInput();

    if (!isPhoneInputValid) {
      enqueueSnackbar(t("FORMS.INVALID_PHONE"), { variant: "error" });
    } else {
      dispatch(requestSmsPhoneVerification({ phoneNumber: phone }));
    }
  }, [dispatch, enqueueSnackbar, isPhoneInputValid, phone, touchInput, t]);

  useEffect(() => {
    if (success) {
      updateStore({
        phoneNumber: phone,
      });
      goToStep(VIEWS.ENTER_CODE);
    }
  }, [goToStep, phone, success, updateStore]);

  useEffect(() => {
    if (error) {
      if (errorDuplicatePhone) {
        updateStore({
          phoneNumber: phone,
        });
        goToStep(VIEWS.DUPLICATE_PHONE);
      } else if (errorInvalidNumber) {
        enqueueSnackbar(errorInvalidNumber, { variant: "error" });
        setShowsError(true);
      }
      dispatch(clearRequestSmsPhoneVerification());
    }
  }, [
    dispatch,
    enqueueSnackbar,
    error,
    errorDuplicatePhone,
    errorInvalidNumber,
    goToStep,
    phone,
    setShowsError,
    updateStore,
  ]);

  useEffect(() => {
    return () => dispatch(clearRequestSmsPhoneVerification());
  }, [dispatch]);

  useEffect(() => {
    if (phone && !isPhoneInputValid) {
      phoneInput.touch();
    }
  }, [isPhoneInputValid, phone, phoneInput]);

  useEffect(() => {
    updateStep({
      onConfirm: handleEnterPhone,
      onPressEnter: handleEnterPhone,
      isConfirmDisabled: phoneInput.showsError,
    });
  }, [handleEnterPhone, phoneInput.showsError, updateStep]);

  useOnMountCondition(() => {
    updateStep({
      onCancel,
      cancelTitle: t("COMMON.CANCEL"),
      confirmTitle: t("COMMON.CONFIRM"),
      title: t("VERIFY.MOBILE_VERIFICATION"),
      "data-cy": "PhoneVerificationEnterPhone",
    });
  }, true);

  return (
    <NavigationModal.Content>
      <Box
        sx={{
          py: 4,
          px: [2, 10],
        }}
      >
        <Typography variant="body">
          {isDefaultNumber
            ? t("VERIFY.CONFIRM_YOUR_PHONE_NUMBER")
            : t("VERIFY.ENTER_YOUR_PHONE_NUMBER")}
        </Typography>
        <Box mb={2} />
        <PhoneInput
          fullWidth
          value={phone}
          onBlur={phoneInput.onBlur}
          onFocus={phoneInput.onFocus}
          error={phoneInput.showsError}
          onChange={phoneInput.onChange}
          placeholder={t("COMMON.PHONE_NUMBER")}
        />
      </Box>
    </NavigationModal.Content>
  );
}

PhoneVerificationEnterPhone.propTypes = {
  onCancel: PropTypes.func.isRequired,
  defaultPhoneNumber: PropTypes.string,
};

export default PhoneVerificationEnterPhone;
