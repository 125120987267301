import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Panel, Button, Typography, Box, Tooltip } from "@alohi/kit";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import AddressesModal from "views/Address/AddressesModal";
import { getAddresses, getBundles } from "stores/reducers/address.reducer";
import RegulatoryDocumentsModal from "views/RegulatoryDocuments/RegulatoryDocumentsModal";
import {
  selectAddresses,
  selectPendingDocuments,
  selectApprovedDocuments,
  selectRejectedDocuments,
} from "selectors/address.selector";
import DocumentsTooltip from "./components/DocumentsTooltip";
import AddressesTooltip from "./components/AddressesTooltip";

function RegulatoryCompliancePanel() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addresses = useSelector(selectAddresses);
  const pendingDocuments = useSelector(selectPendingDocuments);
  const approvedDocuments = useSelector(selectApprovedDocuments);
  const rejectedDocuments = useSelector(selectRejectedDocuments);

  const [openDocuments, openDocumentsHandler] = useBool(false);
  const [openAddresses, openAddressesHandler] = useBool(false);

  const documentsRowText = useMemo(() => {
    const content = [];
    if (approvedDocuments?.length >= 0) {
      content.push(
        t("PROFILE.REGULATORY_COMPLIANCE_APPROVED", {
          count: approvedDocuments.length,
        }),
      );
    }
    if (pendingDocuments?.length >= 0) {
      content.push(
        t("PROFILE.REGULATORY_COMPLIANCE_PENDING", {
          count: pendingDocuments.length,
        }),
      );
    }
    if (rejectedDocuments?.length >= 0) {
      content.push(
        t("PROFILE.REGULATORY_COMPLIANCE_REJECTED", {
          count: rejectedDocuments.length,
        }),
      );
    }
    return content.join(", ");
  }, [approvedDocuments?.length, pendingDocuments?.length, rejectedDocuments?.length, t]);

  const addressesRowText = t("PROFILE.REGULATORY_COMPLIANCE_ADDRESSES_ROW", {
    count: addresses?.length ?? 0,
  });

  useEffect(() => {
    dispatch(getBundles());
    dispatch(getAddresses());
  }, [dispatch]);

  return (
    <>
      <Panel
        id="regulatory-compliance"
        data-cy={dataCy.regulatoryCompliancePanel}
        headerTitle={
          <Box>
            <Typography type="panelFrontHeader">
              {t("PROFILE.REGULATORY_COMPLIANCE_TITLE")}
            </Typography>
          </Box>
        }
      >
        <Panel.Front>
          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.REGULATORY_COMPLIANCE_REGULATORY_DOCUMENTS_TITLE")}
            </Panel.Cell>
            <Panel.Cell variant="data">{documentsRowText}</Panel.Cell>
            <Panel.Cell variant="actions">
              <Tooltip title={<DocumentsTooltip />} placement="right-end">
                <Button variant="white" onClick={openDocumentsHandler.setTrue}>
                  {t("COMMON.MANAGE")}
                </Button>
              </Tooltip>
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.REGULATORY_COMPLIANCE_ADDRESSES_TITLE")}
            </Panel.Cell>
            <Panel.Cell variant="data">{addressesRowText}</Panel.Cell>
            <Panel.Cell variant="actions">
              <Tooltip title={<AddressesTooltip />} placement="right-start">
                <Button variant="white" onClick={openAddressesHandler.setTrue}>
                  {t("COMMON.MANAGE")}
                </Button>
              </Tooltip>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>

        {openDocuments ? (
          <RegulatoryDocumentsModal handleClosure={openDocumentsHandler.setFalse} />
        ) : null}

        {openAddresses ? <AddressesModal handleClosure={openAddressesHandler.setFalse} /> : null}
      </Panel>
    </>
  );
}

export default RegulatoryCompliancePanel;
