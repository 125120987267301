import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  TwoFaActivateFromEnforcedResponse,
  TwoFaActivateResponse,
  twoFaApi,
  TwoFaGetActivationCodesResponse,
} from "api/twoFa.api";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { RootState } from "stores/store";
import { login, loginWithGoogle, logout } from "./authentication.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  getTwoFaSettings: InitialRequestState(),

  getTwoFaActivationCodes: InitialRequestState<TwoFaGetActivationCodesResponse>(),

  activateTwoFa: InitialRequestState<TwoFaActivateResponse>(),
  activateTwoFaFromEnforced: InitialRequestState(),
  deactivateTwoFa: InitialRequestState(),

  activateCorporateTwoFa: InitialRequestState(),
  deactivateCorporateTwoFa: InitialRequestState(),

  isActive: null as null | boolean,
  nextAction: null as null | string,
  token: null as null | string,
  qrCode: null as null | string,
  manualCode: null as null | string,
  recoveryCodes: null as null | string[],
  pendingAccessToken: null as null | string,
  corporateMemberUid: null as null | string,
};

const getTwoFaSettings = createAsyncThunk<
  { response: unknown },
  undefined,
  { state: RootState; rejectValue: FetchError }
>(
  "TWO_FA/GET_SETTINGS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await twoFaApi.getTwoFaSettings();
      return { response };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().twoFa.getTwoFaSettings.status !== RS.RUNNING;
    },
  },
);

const getTwoFaActivationCodes = createAsyncThunk<
  { response: TwoFaGetActivationCodesResponse },
  undefined,
  { state: RootState; rejectValue: FetchError }
>("TWO_FA/GET_ACTIVATION_CODES", async (_, { rejectWithValue, getState }) => {
  const corporateMemberUid = getState().twoFa.corporateMemberUid;
  try {
    if (corporateMemberUid) {
      const response = await twoFaApi.getTwoFaActivationCodesFromSso(corporateMemberUid);
      return { response };
    } else {
      const response = await twoFaApi.getTwoFaActivationCodes();
      return { response };
    }
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const activateTwoFa = createAsyncThunk<
  { response: TwoFaActivateResponse },
  string,
  { state: RootState; rejectValue: FetchError }
>("TWO_FA/ACTIVATE", async (twoFaCode, { rejectWithValue }) => {
  try {
    const response = await twoFaApi.activateTwoFa(twoFaCode);
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const activateTwoFaFromEnforced = createAsyncThunk<
  { response: TwoFaActivateFromEnforcedResponse },
  string,
  { state: RootState; rejectValue: FetchError }
>("TWO_FA/ACTIVATE_FROM_ENFORCED", async (twoFaCode, { getState, rejectWithValue }) => {
  const twoFaToken = getState().twoFa.token as string;
  try {
    const response = await twoFaApi.activateTwoFaFromEnforced({ twoFaCode, twoFaToken });
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const deactivateTwoFa = createAsyncThunk<
  { response: unknown },
  string,
  { rejectValue: FetchError }
>("TWO_FA/DEACTIVATE", async (twoFaCode, { rejectWithValue }) => {
  try {
    const response = await twoFaApi.deactivateTwoFa(twoFaCode);
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const activateCorporateTwoFa = createAsyncThunk<
  { response: unknown },
  { password: string; username: string },
  { rejectValue: FetchError }
>(`TWO_FA/ACTIVATE_CORPORATE`, async (payload, { rejectWithValue }) => {
  try {
    const response = await twoFaApi.activateCorporateTwoFa(payload);
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const deactivateCorporateTwoFa = createAsyncThunk<
  { response: unknown },
  { username: string; password: string },
  { rejectValue: FetchError }
>(`TWO_FA/DEACTIVATE_CORPORATE`, async (payload, { rejectWithValue }) => {
  try {
    const response = await twoFaApi.deactivateCorporateTwoFa(payload);
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const handleTwoFaFromSso = createAction(
  "TWO_FA/HANDLE_FROM_SSO",
  ({ nextAction, twoFaToken, corporateMemberUid }) => ({
    payload: { nextAction, twoFaToken, corporateMemberUid },
  }),
);

export const clearTwoFaNextAction = createAction("TWO_FA/CLEAR_NEXT_ACTION");
export const clearTwoFaActivationCodes = createAction("TWO_FA/CLEAR_CODES");
export const clearActivateTwoFa = createAction("TWO_FA/CLEAR_ACTIVATE");
export const clearActivateTwoFaFromEnforced = createAction("TWO_FA/CLEAR_ACTIVATE_FROM_ENFORCED");
export const clearDeactivateTwoFa = createAction("TWO_FA/CLEAR_DEACTIVATE");
export const clearActivateCorporateTwoFa = createAction(`TWO_FA/CLEAR_ACTIVATE_CORPORATE`);
export const clearDeactivateCorporateTwoFa = createAction(`TWO_FA/CLEAR_DEACTIVATE_CORPORATE`);

const { reducer } = createSlice({
  name: "twoFa",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearTwoFaNextAction, (state) => {
      state.nextAction = null;
    });
    builder.addCase(clearTwoFaActivationCodes, (state) => {
      state.token = null;
      state.manualCode = null;
      state.qrCode = null;
    });
    builder.addCase(getTwoFaSettings.pending, (state) => {
      state.getTwoFaSettings.status = RS.RUNNING;
      state.getTwoFaSettings.error = null;
    });
    builder.addCase(getTwoFaSettings.fulfilled, (state, { payload }) => {
      state.getTwoFaSettings.status = RS.IDLE;
      state.getTwoFaSettings.response = payload.response;
      state.isActive = true;
    });
    builder.addCase(getTwoFaSettings.rejected, (state, { payload }) => {
      state.getTwoFaSettings.status = RS.ERROR;
      state.getTwoFaSettings.error = payload;
      state.isActive = false;
    });
    builder.addCase(getTwoFaActivationCodes.pending, (state) => {
      state.getTwoFaActivationCodes.status = RS.RUNNING;
      state.getTwoFaActivationCodes.error = null;
    });
    builder.addCase(getTwoFaActivationCodes.fulfilled, (state, { payload }) => {
      state.getTwoFaActivationCodes.status = RS.IDLE;
      state.getTwoFaActivationCodes.response = payload.response;
      state.manualCode = payload.response.manual_code ?? null;
      state.qrCode = payload.response.qr_code ?? null;
    });
    builder.addCase(getTwoFaActivationCodes.rejected, (state, { payload }) => {
      state.getTwoFaActivationCodes.status = RS.ERROR;
      state.getTwoFaActivationCodes.error = payload;
    });
    builder.addCase(activateTwoFa.pending, (state) => {
      state.activateTwoFa.status = RS.RUNNING;
      state.activateTwoFa.error = null;
    });
    builder.addCase(activateTwoFa.fulfilled, (state, { payload }) => {
      state.activateTwoFa.status = RS.IDLE;
      state.activateTwoFa.response = payload.response;
      state.recoveryCodes = payload.response.recovery_codes;
      state.isActive = true;
    });
    builder.addCase(activateTwoFa.rejected, (state, { payload }) => {
      state.activateTwoFa.status = RS.ERROR;
      state.activateTwoFa.error = payload;
    });
    builder.addCase(clearActivateTwoFa, (state) => {
      state.activateTwoFa = initialRequestState;
    });
    builder.addCase(activateTwoFaFromEnforced.pending, (state) => {
      state.activateTwoFaFromEnforced.status = RS.RUNNING;
      state.activateTwoFaFromEnforced.error = null;
    });
    builder.addCase(activateTwoFaFromEnforced.fulfilled, (state, { payload }) => {
      state.activateTwoFaFromEnforced.status = RS.IDLE;
      state.activateTwoFaFromEnforced.response = payload.response;
      state.recoveryCodes = payload.response.recovery_codes;
      state.pendingAccessToken = payload.response.access_token;
      state.isActive = true;
    });
    builder.addCase(activateTwoFaFromEnforced.rejected, (state, { payload }) => {
      state.activateTwoFaFromEnforced.status = RS.ERROR;
      state.activateTwoFaFromEnforced.error = payload;
    });
    builder.addCase(clearActivateTwoFaFromEnforced, (state) => {
      state.activateTwoFaFromEnforced = initialRequestState;
    });
    builder.addCase(deactivateTwoFa.pending, (state) => {
      state.deactivateTwoFa.status = RS.RUNNING;
      state.deactivateTwoFa.error = null;
    });
    builder.addCase(deactivateTwoFa.fulfilled, (state, { payload }) => {
      state.deactivateTwoFa.status = RS.IDLE;
      state.deactivateTwoFa.response = payload.response;
      state.isActive = false;
    });
    builder.addCase(deactivateTwoFa.rejected, (state, { payload }) => {
      state.deactivateTwoFa.status = RS.ERROR;
      state.deactivateTwoFa.error = payload;
    });
    builder.addCase(clearDeactivateTwoFa, (state) => {
      state.deactivateTwoFa = initialRequestState;
    });
    builder.addCase(activateCorporateTwoFa.pending, (state) => {
      state.activateCorporateTwoFa.status = RS.RUNNING;
      state.activateCorporateTwoFa.error = null;
    });
    builder.addCase(activateCorporateTwoFa.fulfilled, (state, { payload }) => {
      state.activateCorporateTwoFa.status = RS.IDLE;
      state.activateCorporateTwoFa.response = payload.response;
    });
    builder.addCase(activateCorporateTwoFa.rejected, (state, { payload }) => {
      state.activateCorporateTwoFa.status = RS.ERROR;
      state.activateCorporateTwoFa.error = payload;
    });
    builder.addCase(clearActivateCorporateTwoFa, (state) => {
      state.activateCorporateTwoFa = initialRequestState;
    });
    builder.addCase(deactivateCorporateTwoFa.pending, (state) => {
      state.deactivateCorporateTwoFa.status = RS.RUNNING;
      state.deactivateCorporateTwoFa.error = null;
    });
    builder.addCase(deactivateCorporateTwoFa.fulfilled, (state, { payload }) => {
      state.deactivateCorporateTwoFa.status = RS.IDLE;
      state.deactivateCorporateTwoFa.response = payload.response;
    });
    builder.addCase(deactivateCorporateTwoFa.rejected, (state, { payload }) => {
      state.deactivateCorporateTwoFa.status = RS.ERROR;
      state.deactivateCorporateTwoFa.error = payload;
    });
    builder.addCase(clearDeactivateCorporateTwoFa, (state) => {
      state.deactivateCorporateTwoFa = initialRequestState;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.nextAction = payload.response.next_action ?? null;
      state.token = payload.response["2fa_token"] ?? null;
      state.manualCode = payload.response.manual_code ?? null;
      state.qrCode = payload.response.qr_code ?? null;
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, { payload }) => {
      state.nextAction = payload.response.next_action ?? null;
      state.token = payload.response["2fa_token"] ?? null;
      state.manualCode = payload.response.manual_code ?? null;
      state.qrCode = payload.response.qr_code ?? null;
    });
    builder.addCase(handleTwoFaFromSso, (state, { payload }) => {
      state.nextAction = payload.nextAction ?? null;
      state.token = payload.twoFaToken ?? null;
      state.corporateMemberUid = payload.corporateMemberUid ?? null;
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export {
  getTwoFaSettings,
  getTwoFaActivationCodes,
  activateTwoFa,
  activateTwoFaFromEnforced,
  deactivateTwoFa,
  activateCorporateTwoFa,
  deactivateCorporateTwoFa,
};

export default reducer;
