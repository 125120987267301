import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Select,
  useBool,
  MenuItem,
  FaButton,
  TextButton,
  Typography,
  makeStyles,
  DateRangePicker,
} from "@alohi/kit";

import { InputBase } from "ui";
import dayjs from "helpers/date";
import { boxNames } from "enums/faxes";
import { useInput } from "hooks/useInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { useOnPressEscape } from "hooks/useOnPressEscape";
import { clearInbox } from "stores/reducers/faxes.inbox.reducer";
import { clearSentbox } from "stores/reducers/faxes.sentbox.reducer";
import { clearTrashbox } from "stores/reducers/faxes.trashbox.reducer";
import { faxesAsyncActions, setAllCdrCheckbox } from "stores/reducers/faxes.reducer";
import { selectAreFiltersApplied, selectBoxCurrentFetchOptions } from "selectors/faxes.selector";

function FaxesFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    params: { boxName = boxNames.inbox },
  } = useRouteMatch();

  const isFiltering = useSelector(selectAreFiltersApplied(boxName));
  const currentFetchOptions = useSelector(selectBoxCurrentFetchOptions(boxName));

  const afterFilter = currentFetchOptions.afterFilter;
  const beforeFilter = currentFetchOptions.beforeFilter;
  const [searchPhrase, searchPhraseInput] = useInput(currentFetchOptions.searchPhrase);
  const [dateInputTo, setDateInputTo] = useState(beforeFilter ? dayjs.utc(beforeFilter) : null);
  const [dateInputFrom, setDateInputFrom] = useState(afterFilter ? dayjs.utc(afterFilter) : null);
  const [statusFilter, statusFilterInput] = useInput(
    currentFetchOptions.excludeStatus === "success"
      ? "exclude_success"
      : currentFetchOptions.status || "all",
  );

  const [isOpen, isOpenBool] = useBool(false);

  const statusFilterOptions = useMemo(() => {
    switch (boxName) {
      case boxNames.sentbox:
      case boxNames.trashbox:
        return [
          { label: t("FAXES.ALL_STATUS"), value: "all" },
          { label: t("FAXES.SEND_SUCCESS"), value: "success" },
          { label: t("FAXES.ALL_FAILED"), value: "exclude_success" },
        ];
      case boxNames.inbox:
      default:
        return [
          { label: t("FAXES.ALL_STATUS"), value: "all" },
          { label: t("FAXES.SUCCESS_RECEIVED"), value: "success" },
          { label: t("FAXES.PARTIALLY_RECEIVED"), value: "partially_received" },
        ];
    }
  }, [boxName, t]);

  const setNewPhrase = searchPhraseInput.setValue;
  const setNewStatus = statusFilterInput.setValue;

  const applyMoreFilter = useCallback(
    ({ searchValue, dateFrom, dateTo, statusFilter } = {}) => {
      const afterFilter = (dateFrom ?? dateInputFrom)?.utc().format("YYYY-MM-DD HH:mm:ss");
      const beforeFilter = (dateTo ?? dateInputTo)?.utc().format("YYYY-MM-DD HH:mm:ss");

      dispatch(
        faxesAsyncActions.fetchBox({
          boxName,
          fetchOptions: {
            searchPhrase: searchValue ?? searchPhrase,
            status: ["all", "exclude_success"].includes(statusFilter ?? statusFilter)
              ? ""
              : statusFilter ?? statusFilter,
            excludeStatus: (statusFilter ?? statusFilter) === "exclude_success" ? "success" : "",
            offset: 0,
            afterFilter: dayjs(afterFilter).isValid() ? afterFilter : null,
            after: 0,
            beforeFilter: dayjs(beforeFilter).isValid() ? beforeFilter : null,
            before: 0,
          },
        }),
      );
      dispatch(setAllCdrCheckbox({ boxName, value: false }));
    },
    [boxName, dateInputFrom, dateInputTo, dispatch, searchPhrase],
  );

  const handleReset = useCallback(() => {
    switch (boxName) {
      case boxNames.inbox:
        dispatch(clearInbox());
        break;
      case boxNames.sentbox:
        dispatch(clearSentbox());
        break;
      case boxNames.trashbox:
        dispatch(clearTrashbox());
        break;
      default:
        return;
    }
  }, [dispatch, boxName]);

  const onPressEnter = useCallback(() => {
    if (searchPhraseInput.isFocused) {
      applyMoreFilter();
    }
  }, [applyMoreFilter, searchPhraseInput.isFocused]);

  const onPressEscape = useCallback(() => {
    if (searchPhraseInput.isFocused) {
      searchPhraseInput.setValue("");
    }
  }, [searchPhraseInput]);

  const onDateChange = useCallback(([from, to]) => {
    let newTo = to;

    if (to?.isValid()) {
      // Whenever the given date has 00:00 as the hour, set it to 23:59, just so it includes the whole day
      // But if the hour is changed manually, then don't modify it to 23:59
      if (to?.hour() === 0 && to?.minute() === 0) {
        newTo = dayjs(to).set("hour", 23).set("minute", 59);
      }
    }

    setDateInputTo(newTo);
    setDateInputFrom(from);
  }, []);

  useEffect(() => {
    setDateInputFrom(afterFilter ? dayjs.utc(afterFilter) : null);
  }, [afterFilter]);

  useEffect(() => {
    setDateInputTo(beforeFilter ? dayjs.utc(beforeFilter) : null);
  }, [beforeFilter]);

  useEffect(() => {
    setNewPhrase(currentFetchOptions?.searchPhrase ?? "");
  }, [currentFetchOptions?.searchPhrase, setNewPhrase]);

  useEffect(() => {
    setNewStatus(
      currentFetchOptions?.excludeStatus === "success"
        ? "exclude_success"
        : currentFetchOptions.status || "all",
    );
  }, [currentFetchOptions?.excludeStatus, currentFetchOptions.status, setNewStatus]);

  useOnPressEnter(onPressEnter);

  useOnPressEscape(onPressEscape);

  if ([boxNames.outbox].includes(boxName)) {
    return <Box sx={{ py: 1 }} />;
  }

  return (
    <>
      <Box className={classes.filters}>
        <DateRangePicker
          isOpen={isOpen}
          onOpen={isOpenBool.setTrue}
          onChange={onDateChange}
          onClose={() => {
            isOpenBool.setFalse();
            applyMoreFilter();
          }}
          rightLabel={`${t("COMMON.TO")}:`}
          leftLabel={`${t("COMMON.FROM")}:`}
          value={[dateInputFrom, dateInputTo]}
        />

        <Select
          id="status-filter"
          value={statusFilter}
          input={<InputBase />}
          sx={{ minWidth: 190 }}
          className={classes.filterBase}
          onChange={(event) => {
            applyMoreFilter({ statusFilter: event.target.value });
          }}
        >
          {statusFilterOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        <InputBase
          id="keyword-filter"
          value={searchPhrase}
          className={classes.filterBase}
          onBlur={searchPhraseInput.onBlur}
          onFocus={searchPhraseInput.onFocus}
          onChange={searchPhraseInput.onChange}
          placeholder={`${t("COMMON.SEARCH")}...`}
          endAdornment={
            <Box sx={{ mr: 1 }}>
              <FaButton icon={faMagnifyingGlass} onClick={applyMoreFilter} fontSize={14} />
            </Box>
          }
        />

        {isFiltering ? (
          <TextButton onClick={handleReset}>
            <Typography className={classes.reset}>Reset</Typography>
          </TextButton>
        ) : null}
      </Box>
    </>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape, breakpoints }) => ({
  filters: {
    display: "flex",
    flexFlow: "wrap",
    position: "relative",
    [breakpoints.down("sm")]: {
      margin: spacing(2, 0),
    },
  },
  filterBase: {
    margin: spacing(1, 0),
    "& .MuiInputBase-input": {
      padding: spacing(1, 5, 1, 2),
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: 0,
      },
      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: 0,
      },
    },
    "&.MuiInputBase-root, & .MuiInputBase-root": {
      height: 32,
      borderRadius: shape.borderRadius,
      boxShadow: alohi.bannerShadow,
      border: `1px solid ${alohi.lighterGray}`,
      background: alohi.white,
      display: "flex",
      alignItems: "center",
      marginRight: spacing(2),
      [breakpoints.down("sm")]: {
        flexBasis: "100%",
        margin: spacing(0),
      },
    },
  },
  reset: {
    "&&": {
      color: alohi.red,
      textDecoration: "underline",
    },
  },
}));

export default memo(FaxesFilters);
