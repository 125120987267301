import { createSlice } from "@reduxjs/toolkit";

import { logout } from "./authentication.reducer";
import { initialCartState, cartSlice } from "./payment.cart.reducer";
import { initialRefundState, refundSlice } from "./payment.refund.reducer";
import { initialDepositState, depositSlice } from "./payment.deposit.reducer";
import { initialCorporateState, corporateSlice } from "./payment.corporate.reducer";
import { initialChargeAmountState, chargeAmountSlice } from "./payment.charge.reducer";
import { initialCreditCardState, creditCardSlice } from "./payment.creditCard.reducer";
import { initialPaymentAmountsState, paymentAmountsSlice } from "./payment.amounts.reducer";
import { initialPaymentMethodsState, paymentMethodsSlice } from "./payment.methods.reducer";
import { initialPaymentHistoryState, paymentHistorySlice } from "./payment.history.reducer";
import { initialPaymentReceiptState, paymentReceiptSlice } from "./payment.receipt.reducer";

const initialState = {
  ...initialCartState,
  ...initialRefundState,
  ...initialDepositState,
  ...initialCorporateState,
  ...initialCreditCardState,
  ...initialChargeAmountState,
  ...initialPaymentHistoryState,
  ...initialPaymentMethodsState,
  ...initialPaymentAmountsState,
  ...initialPaymentReceiptState,
};

const { reducer } = createSlice({
  name: "PAYMENT",
  initialState,
  reducers: {},
  extraReducers: {
    ...cartSlice,
    ...refundSlice,
    ...depositSlice,
    ...corporateSlice,
    ...creditCardSlice,
    ...chargeAmountSlice,
    ...paymentHistorySlice,
    ...paymentAmountsSlice,
    ...paymentMethodsSlice,
    ...paymentReceiptSlice,
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
