import { Trans, useTranslation } from "react-i18next";

import { selectBlacklistContactById } from "selectors/contacts.selector";
import { useAppSelector } from "stores/store";
import { BlacklistContact } from "stores/reducers/contacts.helpers";

interface SingleBlacklistMessageProps {
  contactId: string;
}

function SingleBlacklistMessage({ contactId }: SingleBlacklistMessageProps) {
  const { t } = useTranslation();

  const contact = useAppSelector<BlacklistContact | null>(selectBlacklistContactById(contactId));

  if (!contact) {
    return null;
  }

  return (
    <Trans i18nKey="CONTACTS.QUESTION_REMOVE_CONTACT">
      Are you sure you want to remove
      <strong>{{ name: contact.fullName || contact.faxNumber }}</strong>from your
      {{ listName: t("CONTACTS.BLACKLIST").toLowerCase() }}?
    </Trans>
  );
}

export default SingleBlacklistMessage;
