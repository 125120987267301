import createSelector from "selectorator";

import i18next from "../i18n/index";
import { boxNames, cdrStatuses } from "../enums/faxes";
import dayjs, { formatCdrDate, formatCdrSendTime, formatOutboxDate } from "../helpers/date";
import RS from "../enums/requestStatus";
import { selectFlattenedUsersHierarchy } from "./corporate.selector";

export const selectBoxRecords = (boxName) =>
  createSelector([`${boxName}.records`], (records) => records);

export const selectBoxHeader = (boxName) =>
  createSelector([selectBoxRecords(boxName)], (records) => {
    if (!records?.length) {
      return { isChecked: false, isIndeterminate: false };
    }
    let numberOfChecked = 0;
    for (let i = 0; i < records.length; i++) {
      if (records[i].isChecked) numberOfChecked++;
      if (0 < numberOfChecked && numberOfChecked < i + 1) {
        return {
          isChecked: false,
          isIndeterminate: true,
        };
      }
    }
    return { isChecked: records.length === numberOfChecked, isIndeterminate: false };
  });

export const selectIsSyncHistoryAllowed = (boxName) =>
  createSelector([`${boxName}.currentFetchOptions`], (options) => {
    if (options?.sortBy || options?.status || options?.searchPhrase) {
      return true;
    }
    return false;
  });

export const selectAreFiltersApplied = (boxName) =>
  createSelector(
    [`${boxName}.currentFetchOptions`],
    (currentFetchOptions) =>
      currentFetchOptions?.afterFilter ||
      currentFetchOptions?.beforeFilter ||
      currentFetchOptions?.searchPhrase ||
      currentFetchOptions?.status ||
      currentFetchOptions?.excludeStatus,
  );

export const selectIsFetchBoxRunning = createSelector(
  [`faxes.fetchBox.status`],
  (status) => status === RS.RUNNING,
);

export const selectFetchBoxError = createSelector([`faxes.fetchBox.error`], (error) => {
  if (!error) return null;
  if (error.status === 429) {
    // If too many requests (too many tabs opened), we can silently fail some
    return null;
  }
  return i18next.t("COMMON.SERVER_ERROR");
});

export const selectBoxHasMore = (boxName) =>
  createSelector([`${boxName}.hasMore`], (hasMore) => hasMore);

export const selectBoxCurrentFetchOptions = (boxName) =>
  createSelector([`${boxName}.currentFetchOptions`], (options) => options);

export const selectSelectedNumberOfFetched = (boxName) =>
  createSelector([`${boxName}.currentFetchOptions`], (options) => options?.number);

export const selectSelectedMemberUidOfFetched = (boxName) =>
  createSelector([`${boxName}.currentFetchOptions`], (options) => options?.memberUid);

export const selectSelectedNumber = createSelector(["faxes.selectedNumber"], (number) => number);

export const selectSelectedMemberUid = createSelector(["faxes.selectedMemberUid"], (uid) => uid);

export const selectFileViewerState = createSelector(["faxes.fileViewer"], (state) => state);

export const selectMoveToTrashSuccess = createSelector(
  ["faxes.moveToTrash.response"],
  (response) => response?.length || null,
);

export const selectRestoreSuccess = createSelector(
  ["faxes.restore.response"],
  (response) => response?.length || null,
);

export const selectIsDeleteFaxSuccess = createSelector(["faxes.deleteFax.response"], (response) => {
  if (!response?.length) return null;
  if (response.some((item) => item?.code !== 200)) return false;
  return true;
});

export const selectIsDeleteFaxError = createSelector(["faxes.deleteFax.response"], (response) => {
  if (!response?.length) return null;
  if (response.some((item) => item?.code !== 200)) return true;
  return false;
});

export const selectCdr = (boxName, dataIndex) =>
  createSelector([`${boxName}.records[${dataIndex}]`], (cdr) => cdr);

export const selectCdrById = (boxName, cdrId) =>
  createSelector([`${boxName}.records`], (records) => records?.find((cdr) => cdr?.id === cdrId));

export const selectCdrDate = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    const date = cdr?.date;
    return date ? formatCdrDate(date) : null;
  });

export const selectCdrFromToNumber = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    let number;
    switch (boxName) {
      case boxNames.sentbox:
        number = cdr?.to;
        break;
      case boxNames.outbox:
        number = cdr?.to?.[0];
        break;
      case boxNames.trashbox:
        number = cdr?.track?.[0]?.direction === "incoming" ? cdr?.from : cdr?.to;
        break;
      case boxNames.inbox:
      default:
        number = cdr?.from;
    }
    return number ?? null;
  });

export const selectIsCdrFromCommonStorage = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    return cdr?.track?.[0]?.data?.file?.includes?.("/common/");
  });

export const selectCdrContactName = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    const contactName = cdr?.track?.[0]?.contact_name ?? cdr?.contact_name;
    return contactName ?? null;
  });

export const selectCdrIsRead = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    if (!cdr) return null;
    return cdr?.is_read ?? null;
  });

export const selectCdrIsReadById = (boxName, cdrId) =>
  createSelector([selectCdrById(boxName, cdrId)], (cdr) => {
    return cdr?.is_read ?? null;
  });

export const selectCdrIsFailed = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    return cdr?.track?.[0].data.status !== "success" ?? null;
  });

export const selectCdrFullNoteById = (boxName, cdrId) =>
  createSelector([selectCdrById(boxName, cdrId)], (cdr) => {
    if (!cdr) return null;
    return cdr.comment?.text;
  });

export const selectCdrSharedNoteById = (boxName, cdrId) =>
  createSelector([selectCdrById(boxName, cdrId)], (cdr) => {
    if (!cdr) {
      return null;
    }

    return {
      text: cdr?.shared_comment?.text || "",
      name: cdr?.shared_comment?.editor?.name || "",
      date: cdr?.shared_comment?.date ? formatCdrDate(cdr.shared_comment.date) : "",
    };
  });

export const selectCdrIsFromAnonymous = (boxName, cdrId) =>
  createSelector([selectCdrById(boxName, cdrId)], (cdr) => {
    if (!cdr) {
      return null;
    }

    return cdr.from === "Anonymous";
  });

export const selectCdrDisplayedNote = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    if (!cdr) {
      return null;
    }
    const note = cdr.comment?.text;

    if (note) {
      return note.substr(0, 50);
    }

    return null;
  });

export const selectCdrSharedNote = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    if (!cdr) {
      return null;
    }

    return {
      text: cdr?.shared_comment?.text || "",
      name: cdr?.shared_comment?.editor?.name || "",
      date: cdr?.shared_comment?.date ? formatCdrDate(cdr.shared_comment.date) : "",
    };
  });

export const selectCdrStatusMessage = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    if (!cdr) {
      return null;
    }
    const status = cdr.track?.[0]?.data?.status ?? "";
    const direction = cdr.track?.[0]?.direction;

    switch (boxName) {
      case boxNames.sentbox:
        if (status !== "success") {
          return getSentCdrDisplayedStatus(status);
        }
        break;
      case boxNames.trashbox: {
        var diffDay = dayjs(dayjs.utc().format("YYYY-MM-DD 23:59:59")).diff(
          dayjs([cdr?.deletion_date.split(" ")?.[0], "00:00:01"].join(" ")),
          "days",
        );
        return direction === "incoming"
          ? i18next.t(`FAXES.DELETED_FROM_INBOX${diffDay === 0 ? "_TODAY" : ""}`, {
              count: diffDay,
            })
          : i18next.t(`FAXES.DELETED_FROM_SENT${diffDay === 0 ? "_TODAY" : ""}`, {
              count: diffDay,
            });
      }
      case boxNames.inbox:
      case boxNames.outbox:
        return getOuboxCdrStatus(cdr.status);
      default:
        return "";
    }
  });

export const selectOutboxCdrIsSending = (dataIndex) =>
  createSelector([selectCdr(boxNames.outbox, dataIndex)], (cdr) => {
    return cdr?.status === "sending";
  });

export const selectCdrId = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    return cdr?.id;
  });

export const selectCdrPages = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    return cdr?.track?.[0]?.data?.pages ?? 0;
  });

export const selectCdrSendTime = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    const time = cdr?.send_time;
    if (boxName === boxNames.outbox) {
      return time ? formatOutboxDate(time) : null;
    }
    return time ? formatCdrSendTime(time) : null;
  });

export const selectIsCdrReadyForPreview = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    if (!cdr) {
      return null;
    }
    if (boxName === boxNames.outbox) {
      return [
        cdrStatuses.converted,
        cdrStatuses.scheduledForSending,
        cdrStatuses.sending,
        cdrStatuses.insufficientCredit,
      ].includes(cdr.status);
    } else return !!cdr.track[0].data.file;
  });

export const selectIsCdrChecked = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex)], (cdr) => {
    return !!cdr?.isChecked;
  });

export const selectIdsOfCheckedCdrs = (boxName) =>
  createSelector([`${boxName}.records`], (records) => {
    if (!records) return [];

    return records.reduce((acc, curr) => {
      if (curr.isChecked) {
        acc.push(curr.id);
      }
      return acc;
    }, []);
  });

export const selectCdrThumbnail = (boxName, dataIndex) =>
  createSelector([selectCdr(boxName, dataIndex), `${boxName}.thumbnails`], (cdr, thumbnails) => {
    if (!cdr) {
      return null;
    }
    return thumbnails?.[cdr?.id];
  });

export const selectIsCdrLoading = (boxName, dataIndex) =>
  createSelector(
    [selectCdr(boxName, dataIndex), `${boxName}.cdrIdOfLoading`],
    (cdr, cdrIdOfLoading) => cdr?.id === cdrIdOfLoading,
  );

export const selectMemberObjectOfSelectedMember = createSelector(
  ["faxes.selectedMemberUid", selectFlattenedUsersHierarchy],
  (selectedMemberUid, corporateList) => {
    return corporateList?.find((el) => selectedMemberUid && el.uid === selectedMemberUid) ?? {};
  },
);

export const selectCdrToken = createSelector(["faxes.cdrToken.response"], (response) => {
  if (!response?.token) {
    return null;
  }

  return response.token;
});

export const selectIsCdrTokenError = createSelector(
  ["faxes.cdrToken.status"],
  (status) => status === RS.ERROR,
);

export const selectPreviewFileErrorMessage = createSelector(
  ["faxes.previewFile.error"],
  (error) => {
    if (!error) return null;
    if (error.reason === "Fax job not found") {
      return i18next.t("FAXES.FAX_ALREADY_SENT");
    }
    switch (error) {
      case "isInTrash":
        return i18next.t("FAXES.FAX_IN_TRASH");
      case "isPermanentlyDeleted":
        return i18next.t("FAXES.FAX_DELETED");
      case "cdrNotFound":
        return i18next.t("FAXES.FAX_NOT_FOUND");
      default:
        return i18next.t("COMMON.SERVER_ERROR");
    }
  },
);

function getSentCdrDisplayedStatus(status) {
  switch (status) {
    case "success":
      return i18next.t("FAXES.SUCCESSFULLY");
    case "failed_fs_2":
    case "failed_fs_3":
    case "failed_fs_49_no_fax_detected":
    case "failed_fs_49_no_fax_detected_no_txfax":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.NO_FAX_DETECTED") + ")";
    case "failed_fs_5":
    case "failed_fs_6":
    case "failed_fs_13":
    case "failed_fs_14":
    case "failed_fs_15":
    case "failed_fs_17":
    case "failed_fs_18":
    case "failed_fs_20":
    case "failed_fs_21":
    case "failed_fs_23":
    case "failed_fs_31":
    case "failed_fs_32":
    case "failed_fs_38":
    case "failed_fs_35":
    case "failed_no_fax_transmitted":
    case "failed_zero_page_transmitted":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.FAX_NEGOTIATION_FAILED") + ")";
    case "failed_no_answer":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.NO_ANSWER") + ")";
    case "failed_fs_48":
    case "failed_fs_49":
    case "failed_fs_49_allotted_timeout":
    case "failed_normal_temporary_failure":
    case "failed_exchange_routing_error":
    case "failed_recovery_on_timer_expire":
    case "failed_destination_out_of_order":
    case "failed_normal_circuit_congestion":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.COMMUNICATION_ERROR") + ")";
    case "partially_sent":
    case "partially_sent_allotted_timeout":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.FAX_PARTIALLY_SENT") + ")";
    case "failed_user_busy":
    case "failed_normal_clearing":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.NUMBER_BUSY") + ")";
    case "failed_unallocated_number":
    case "failed_invalid_number_format":
    case "failed_no_route_destination":
      return (
        i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.INVALID_DESTINATION_NUMBER") + ")"
      );
    case "failed_call_rejected":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.NUMBER_BLOCKED") + ")";
    case "failed_destination_not_supported":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.DESTINATION_NOT_SUPPORTED") + ")";

    case "failed_os_error":
    case "failed_file_not_found":
    case "failed_requested_chan_unavail":
    case "failed_network_out_of_order":
    case "failed_render_header_issue":
    case "failed_internal_process_error":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.TEMPORARY_SYSTEM_ISSUE") + ")";
    case "failed_mimetype_not_supported":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.UN_SUPPORT_FILE_TYPE") + ")";
    case "failed_office_converter_issue":
    case "failed_jpeg_converter_issue":
    case "failed_png_converter_issue":
    case "failed_tiff_converter_issue":
    case "failed_html_converter_issue":
    case "failed_unknown_converter_issue":
    case "failed_merge_pdf_issue":
    case "failed_count_pdf_page_issue":
    case "failed_convert_pdf_to_tiff_issue":
    case "failed_convert_to_tiff_issue":
    case "failed_image_preparation":
    case "failed_separate_file_pages_issue":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.FILE_CONVERSION_ISSUE") + ")";
    case "failed_enhancer_issue":
      return (
        i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.DOCUMENT_OPTIMIZATION_ISSUE") + ")"
      );
    case "failed_pdf_page_count_limit_exceeded":
      return (
        i18next.t("COMMON.FAILED") +
        " (" +
        i18next.t("FAXES.FAILED_PDF_PAGE_COUNT_LIMIT_EXCEEDED") +
        ")"
      );
    case "insufficient_credit":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.INSUFFICIENT_CREDIT") + ")";
    case "user_canceled_failed_fs_13":
    case "user_canceled_failed_fs_49":
    case "user_canceled_failed_fs_49_no_fax_detected":
    case "user_canceled_failed_fs_49_no_fax_detected_no_txfax":
      return i18next.t("FAXES.CANCELED") + " (" + i18next.t("FAXES.FAX_NOT_SENT") + ")";
    case "user_canceled_partially_sent":
      return i18next.t("FAXES.CANCELED") + " (" + i18next.t("FAXES.FAX_PARTIALLY_SENT") + ")";
    case "failed_page_limit_exceeded":
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.TOO_MANY_PAGES") + ")";

    default:
      return i18next.t("COMMON.FAILED") + " (" + i18next.t("FAXES.FAX_NOT_SENT") + ")";
  }
}

function getOuboxCdrStatus(status) {
  switch (status) {
    case "sending":
      return null;
    case "submitted":
      return i18next.t("FAXES.SCHEDULED_FOR_SENDING");
    case "queued":
      return i18next.t("FAXES.SENDING");
    case "queued_enhancement":
      return i18next.t("FAXES.AWAITING_OPTIMIZATION");
    case "converting":
      return i18next.t("FAXES.CONVERTING");
    case "converted":
      return i18next.t("FAXES.CONVERTED");
    case "scheduled_for_sending":
      return i18next.t("FAXES.SCHEDULED_FOR_SENDING");
    case "insufficient_credit":
      return i18next.t("COMMON.FAILED") + ": " + i18next.t("FAXES.INSUFFICIENT_CREDIT");
    default:
      return status || "";
  }
}
