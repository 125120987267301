import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as GenericApolloProvider,
  HttpLink,
} from "@apollo/client";
import { ADMIN_GRAPHQL_ENDPOINT, KIBANA_RUM_URL, NODE_ENV, X_ZANG_CLIENT_ID } from "config";
import { onError } from "@apollo/client/link/error";
import * as React from "react";
import { store } from "stores/store";
import { cache } from "./settings/cache";
import { apm } from "@elastic/apm-rum";
import * as Sentry from "@sentry/react";

export interface ApolloProviderProps {
  children: React.ReactNode;
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ children }) => {
  const isKibanaEnabled = NODE_ENV === "production" && Boolean(KIBANA_RUM_URL);

  function getAuthorizationHeader(params: {
    tokenType: string | undefined;
    accessToken: string | undefined;
  }) {
    if (!params.accessToken) {
      return null;
    }

    switch (params.tokenType) {
      case "Bearer":
        return `Bearer ${params.accessToken}`;
      case "ApiKey":
        return `ApiKey ${params.accessToken}`;
      default:
        return null;
    }
  }

  const httpLink = new HttpLink({
    uri: ADMIN_GRAPHQL_ENDPOINT,
    credentials: "include",
  });

  const authLink = new ApolloLink((operation, forward) => {
    const accessToken = store.getState().authentication.accessToken;

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "X-Zang-App-Version": "web",
        "X-Client-Id": X_ZANG_CLIENT_ID,
        Authorization: accessToken
          ? getAuthorizationHeader({
              tokenType: "Bearer",
              accessToken: accessToken,
            })
          : undefined,
      },
    }));

    return forward(operation);
  });

  const responseLink = new ApolloLink((operation, forward) => {
    // Breadcrumb that request started
    Sentry.addBreadcrumb({
      category: "graphql",
      message: `${operation.operationName}: Request`,
      level: "info",
      data: { operationName: operation.operationName },
    });

    // Kibana APM: add a transaction span
    const transaction = isKibanaEnabled ? apm?.getCurrentTransaction() : undefined;
    const span = transaction?.startSpan(`GraphQL: ${operation.operationName}`, "graphql");

    return forward(operation).map((response) => {
      const context = operation.getContext();
      const responseHeaders = context.response?.headers;

      if (responseHeaders) {
        const requestId = responseHeaders.get("X-Request-ID");
        const cfRayId = responseHeaders.get("Cf-Ray");

        Sentry.addBreadcrumb({
          category: "graphql",
          message: `${operation.operationName}: Response Headers`,
          level: "info",
          data: { requestId, cfRayId },
        });

        if (requestId && isKibanaEnabled) {
          span?.addLabels({
            x_request_id: requestId,
            cf_ray_id: cfRayId,
          });
        }
      }

      span?.end();
      return response;
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext();
    const responseHeaders = context.response?.headers;
    const requestId = responseHeaders?.get("X-Request-ID") ?? "";
    const cfRayId = responseHeaders?.get("Cf-Ray") ?? "";

    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        Sentry.withScope((scope) => {
          const fieldNames = graphQLErrors
            .map((error) => error.path?.join(" -> ") || "unknown")
            .join(", ");
          const errorCode =
            (graphQLError.extensions?.code as string | undefined) || "UNKNOWN_ERROR_CODE";
          const transactionName = `GraphQL Error: ${operation.operationName} (${fieldNames})`;

          scope.setTransactionName(transactionName);
          scope.setFingerprint([
            operation.operationName,
            fieldNames,
            errorCode,
            graphQLError.message,
          ]);
          scope.setLevel("error");

          // Tags for filtering in Sentry
          scope.setTags({
            "graphql.name": operation.operationName,
            "graphql.info.field_names": fieldNames,
            "graphql.info.error_code": errorCode,
            "graphql.debug.x_request_id": requestId,
            "graphql.debug.cf_ray_id": cfRayId,
          });

          // Extra data for debugging
          scope.setExtras({
            variables: JSON.stringify(operation.variables, null, 2),
            response: JSON.stringify(graphQLError, null, 2),
            headers: JSON.stringify(operation.getContext().headers, null, 2),
          });

          Sentry.captureException(new Error(graphQLError.message));
        });
      }
    }

    if (networkError) {
      Sentry.withScope((scope) => {
        const transactionName = `Network Error: ${operation.operationName} - ${networkError.message}`;

        scope.setTransactionName(transactionName);
        scope.setFingerprint(["NETWORK_ERROR", operation.operationName]);
        scope.setLevel("error");

        // Tags for filtering in Sentry
        scope.setTags({
          "request.name": operation.operationName,
          "request.error.name": networkError.name,
          "request.error.message": networkError.message,
        });

        // Extra data for debugging
        scope.setExtras({
          error: JSON.stringify(networkError, null, 2),
          variables: JSON.stringify(operation.variables, null, 2),
          headers: JSON.stringify(operation.getContext().headers, null, 2),
        });

        Sentry.captureException(new Error(networkError.message));
      });
    }
  });

  const link = ApolloLink.from([authLink, responseLink, errorLink, httpLink]);

  const client = new ApolloClient({
    connectToDevTools: NODE_ENV !== "production",
    link,
    cache,
  });

  return <GenericApolloProvider client={client}>{children}</GenericApolloProvider>;
};
