import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { Box, Modal, Button, useSnackbar } from "@alohi/kit";
import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography, makeStyles } from "ui";
import withStripeHOC from "components/Payment/WithStripeHOC";
import useStripe3dsOnRecharge from "hooks/useStripe3dsOnRecharge";
import ListPaymentMethod from "components/Payment/ListPaymentMethod";
import AddPaymentMethodModal from "views/AddPaymentMethod/AddPaymentMethodModal";
import { clearRechargePlan, getCurrentPlan, rechargePlan } from "stores/reducers/plans.reducer";
import {
  selectPaymentMethods,
  selectIsPaymentMethodsError,
  selectIsPaymentMethodsLoading,
  selectIsLoadingDeleteCreditCard,
  selectIsLoadingUpdateCreditCard,
} from "selectors/payment.selector";
import {
  selectPlanId,
  selectRechargePlanError,
  selectIsRechargePlanRunning,
  selectIsRechargePlanSuccessful,
  selectRechargePlanRequiresAuthentication,
} from "selectors/plan.selector";
import {
  getPaymentMethods,
  clearPaymentMethods,
  clearSelectedPaymentMethod,
} from "stores/reducers/payment.methods.reducer";

function ManagePaymentMethodModal({
  withAutomaticRecharge = false,
  handleClosure,
  initialTab = undefined,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirmPayment = useStripe3dsOnRecharge();

  const planId = useSelector(selectPlanId);
  const paymentMethods = useSelector(selectPaymentMethods);
  const isLoadingDelete = useSelector(selectIsLoadingDeleteCreditCard);
  const isLoadingUpdate = useSelector(selectIsLoadingUpdateCreditCard);
  const isRechargePlanLoading = useSelector(selectIsRechargePlanRunning);
  const rechargePlanError = useSelector(selectRechargePlanError);
  const isRechargePlanSuccessful = useSelector(selectIsRechargePlanSuccessful);
  const rechargePlanRequiresAuthentication = useSelector(selectRechargePlanRequiresAuthentication);

  const isPaymentMethodsError = useSelector(selectIsPaymentMethodsError);
  const isPaymentMethodsLoading = useSelector(selectIsPaymentMethodsLoading);

  const [openAddPaymentMethod, setOpenAddPaymentMethod] = useState(false);
  const hasPaymentMethods = paymentMethods && !!paymentMethods.length;

  const handleConfirm = useCallback(() => {
    if (withAutomaticRecharge) {
      dispatch(rechargePlan(planId));
    } else handleClosure();
  }, [dispatch, handleClosure, planId, withAutomaticRecharge]);

  useEffect(() => {
    if (initialTab) {
      setOpenAddPaymentMethod(true);
    }
  }, [initialTab]);

  useEffect(() => {
    dispatch(clearSelectedPaymentMethod());
    dispatch(getPaymentMethods());
  }, [hasPaymentMethods, dispatch]);

  useEffect(() => {
    if (isPaymentMethodsError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearPaymentMethods());
      handleClosure();
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isPaymentMethodsError, t]);

  useEffect(() => {
    if (isRechargePlanSuccessful) {
      dispatch(getCurrentPlan());
      enqueueSnackbar(t("CHANGE_PLAN.RENEW_SUCCESS"), { variant: "success" });
      handleClosure(true);
    } else if (rechargePlanError) {
      dispatch(getCurrentPlan());
      enqueueSnackbar(rechargePlanError, { variant: "error" });
    } else if (rechargePlanRequiresAuthentication) {
      dispatch(getCurrentPlan());
      confirmPayment();
    }
  }, [
    dispatch,
    enqueueSnackbar,
    handleClosure,
    rechargePlanError,
    isRechargePlanSuccessful,
    t,
    rechargePlanRequiresAuthentication,
    confirmPayment,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearRechargePlan());
    };
  }, [dispatch]);

  if (!paymentMethods) {
    return null;
  }

  return (
    <Modal
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      title={t("ADD_CREDIT.PAYMENT_METHOD", { count: 2 })}
      isConfirmLoading={isRechargePlanLoading || rechargePlanRequiresAuthentication}
      confirmTitle={withAutomaticRecharge ? t("ADD_CREDIT.CONFIRM") : t("COMMON.DONE")}
      isModalDisabled={
        isPaymentMethodsLoading ||
        isRechargePlanLoading ||
        isLoadingUpdate ||
        isLoadingDelete ||
        rechargePlanRequiresAuthentication
      }
    >
      <>
        <ListPaymentMethod />
        <Box className={classes.info}>
          <Box className={classes.legend}>
            <FontAwesomeIcon icon={faStarSharp} className={classes.legendIcon} />
          </Box>
          <Typography variant="body">{t("ADD_CREDIT.DEFAULT_PAYMENT_METHOD")}</Typography>
        </Box>
        <Box className={classes.addPaymentMethod}>
          <Button variant="blue" onClick={() => setOpenAddPaymentMethod(true)}>
            {t("ADD_CREDIT.ADD_PAYMENT_METHOD")}
          </Button>
        </Box>
      </>

      {openAddPaymentMethod || !hasPaymentMethods ? (
        <AddPaymentMethodModal
          handleClosure={(newCreditCard) => {
            // Return from AddPaymentMethod but didn't add a method therefore can't show an empty list.
            !newCreditCard && !hasPaymentMethods && handleClosure();
            setOpenAddPaymentMethod(false);
          }}
          initialTab={initialTab}
        />
      ) : null}
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  defaultBox: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "40px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  addPaymentMethod: {
    margin: spacing(2, 0, 3, 6.5),
  },
  info: {
    display: "flex",
    alignItems: "center",
    marginLeft: spacing(1.5),
  },
  legend: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  legendIcon: {
    fontSize: 16,
    color: alohi.almostGray,
  },
}));

ManagePaymentMethodModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  withAutomaticRecharge: PropTypes.bool,
  initialTab: PropTypes.string,
};

export default withStripeHOC(ManagePaymentMethodModal);
