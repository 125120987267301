import { Box } from "@alohi/kit";
import { useAppSelector } from "stores/store";
import { selectIsAllowedToChooseAreaCode } from "selectors/plan.selector";
import CountryInfo from "../CountryInfo";
import PreviewNumber from "../PreviewNumber";
import AvailableAreas from "../AvailableAreas";
import AvailableCountries from "../AvailableCountries";
import AvailableNumberTypes from "../AvailableNumberTypes";
import AddressVerification from "../../AddressBundle/AddressVerification/AddressVerification";

function ChangeToIndividualPlan() {
  const isAllowedToChooseAreaCode = useAppSelector(selectIsAllowedToChooseAreaCode);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Box pr="22%">
        <AvailableCountries />
        {isAllowedToChooseAreaCode ? (
          <>
            <AvailableAreas />
            <AvailableNumberTypes actionType="number" />
            <AddressVerification />
          </>
        ) : null}
      </Box>
      {!isAllowedToChooseAreaCode ? <CountryInfo /> : null}
      <PreviewNumber />
    </Box>
  );
}

export default ChangeToIndividualPlan;
