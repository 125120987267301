import { useCallback, useState } from "react";

function useClipboard() {
  const [isCopied, setIsCopied] = useState(false);

  const copy = useCallback(async (text) => {
    // Use this as a mean to reset the state, only show success state.
    setIsCopied(false);
    try {
      if (text) {
        // Check if its not empty string or null/undefined
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
      }
    } catch (err) {
      setIsCopied(false);
    }
  }, []);

  return {
    copy,
    isCopied,
  };
}

export default useClipboard;
