import { makeStyles, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Skeleton, Typography } from "ui";
import { useAppSelector } from "stores/store";
import Currency from "components/Core/Currency/Currency";
import { PageOption } from "stores/reducers/plans.reducer";
import { selectCurrentCreditCurrency } from "selectors/credit.selector";
import { selectListPageOptions, selectPlanDuration } from "selectors/plan.selector";

interface PlanOptionProps {
  pages: number;
}

function PlanOption({ pages }: PlanOptionProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const creditCurrency = useAppSelector(selectCurrentCreditCurrency);
  const listPageOptions = useAppSelector<null | PageOption[]>(selectListPageOptions);
  const planDuration = useAppSelector(selectPlanDuration);

  const isMonthly = planDuration === "month";
  const pricePerMonth = listPageOptions?.find((option) => option.monthly_pages === pages);

  return (
    <Box className={classes.wrapper}>
      <Typography>{t("PAGE_OPTIONS.BILLED")}</Typography>
      <Box fontWeight="fontWeightBold" fontSize="h6.fontSize" color="alohi.gray">
        {pricePerMonth ? (
          <>
            <Currency
              decimalScale={2}
              fixedDecimalScale
              currency={creditCurrency}
              value={pricePerMonth.price}
              hideZeroDecimal
            />
            {isMonthly ? t("DASHBOARD.PER_MONTH") : t("DASHBOARD.PER_YEAR")}
          </>
        ) : (
          <Skeleton variant="text" width={100} />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  wrapper: {
    height: "90px",
    display: "flex",
    borderRadius: "8px",
    textAlign: "center",
    alignItems: "center",
    margin: spacing(0, 3),
    flexDirection: "column",
    justifyContent: "center",
    background: alohi.blue200,
  },
}));

export default PlanOption;
