import { Dayjs } from "dayjs";
import { CountryCode } from "libphonenumber-js";
import { TimezoneType } from "helpers/date";

export const FAX_FILE = "faxFile";
export const RICH_TEXT = "richText";
export const TIMESTAMP = "timestamp";
export const DESTINATION = "destination";
export const DEFAULT_COVER_SHEET = "coverSheet";

export type DestinationIcon =
  | "group"
  | "recent"
  | "contact"
  | "newEntry"
  | "sharedGroup"
  | "sharedContact";

export type DestinationTarget = "to" | "tag_ids";

export interface NewEntryLabel {
  number: string;
  geoLocation: CountryCode;
}

export interface BaseDestination {
  value: string;
  caption: string;
  icon: DestinationIcon;
  target: DestinationTarget;
}

export interface ContactDestination extends BaseDestination {
  target: "to";
  label: string;
  icon: "contact" | "sharedContact";
}

export interface GroupDestination extends BaseDestination {
  label: string;
  target: "tag_ids";
  membersCount: number;
  icon: "group" | "sharedGroup";
}

export interface RecentDestination extends BaseDestination {
  label: string;
  target: "to";
  icon: "recent";
}

export interface NewEntryDestination extends BaseDestination {
  target: "to";
  isNew: boolean;
  icon: "newEntry";
  label: NewEntryLabel;
}

export type Destination =
  | NewEntryDestination
  | ContactDestination
  | GroupDestination
  | RecentDestination;

export interface FaxFile {
  key: string;
  name: string; // file name
  mime: string; // mime type
  title: string; // Usually the size as prettyBytes
  path: null | string; // the path from the file server after we upload
  file: File; // the actual file
  content: string; // File content as a string
  size: number;
  thumbnail: null | string; // only available for image mime types
  type: typeof FAX_FILE;
}

export interface RichText {
  blob: Blob;
  key: string;
  lastModifiedDate: Date;
  name: string; // file name
  mime: string; // mime type
  title: string; // Usually the size as prettyBytes
  path: null | string; // the path from the file server after we upload
  content: {
    html: string; // html as a string, should be passed to the backend
    text: string; // text as a string
  };
  size: number;
  type: typeof RICH_TEXT;
}

export interface DefaultCoverSheet {
  to: string;
  from: string;
  subject: string;
  message: string;
  isUrgent: boolean;
  isForReview: boolean;
  companyLogo: boolean;
  isPleaseReply: boolean;
  isConfidential: boolean;
}

export interface GalleryCoverSheet {
  id: string;
  name: string;
  index: number;
  thumbnail: string;
  lastTimeUsed: number;
}

export enum SharedBy {
  me = "me",
  name = "name",
  deleted = "deleted",
}

export interface SharedCoverSheet {
  id: string;
  name: string;
  index: number;
  sharedBy: SharedBy;
  thumbnail: string;
  lastTimeUsed: number;
  ownerName: string;
}

export interface EnforcedCoverSheet {
  id: string;
  name: string;
  sharedBy: SharedBy;
  thumbnail: string;
  ownerName: string;
  lastTimeUsed: number;
}

export type SendFaxFiles = (FaxFile | RichText)[];

export type Destinations = Destination[];

export interface SendFaxInitiatedFrom {
  id?: string;
  from: string;
}

export type SendFaxStore = {
  from: string;
  comment: string;
  date: Dayjs | null;
  wasSynced: boolean;
  files: SendFaxFiles;
  isOverlayOpen: boolean;
  errorMessage: string | null;
  destinations: Destination[];
  timezone: TimezoneType | null;
  initiatedFrom?: SendFaxInitiatedFrom;
  defaultCoverSheet: DefaultCoverSheet | null;
  galleryCoverSheet: GalleryCoverSheet | null;
  sharedCoverSheet: SharedCoverSheet | null;
  enforcedCoverSheet: EnforcedCoverSheet | null;
};

export const initialSendFaxStore: SendFaxStore = {
  from: "",
  files: [],
  date: null,
  comment: "",
  timezone: null,
  isOverlayOpen: false,
  destinations: [],
  errorMessage: null,
  // Refers to files being retreived from the local IndexedDB or not
  wasSynced: false,
  initiatedFrom: undefined,
  defaultCoverSheet: null,
  galleryCoverSheet: null,
  sharedCoverSheet: null,
  enforcedCoverSheet: null,
};
