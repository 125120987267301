import PropTypes from "prop-types";
import { components } from "react-select";

import CountryFlag from "components/Core/CountryFlag/CountryFlag";

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.getValue() && props.getValue().length && props.getValue()[0].value && (
        <CountryFlag
          code={props.getValue()[0].value}
          style={{
            marginRight: "5px",
            marginBottom: "3px",
          }}
        />
      )}
      {children}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node,
  getValue: PropTypes.func,
};

export default SingleValue;
