import { useMemo } from "react";
import { useHistory, useParams } from "react-router";

import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import { selectCanIUseSendFax } from "selectors/ability.selector";
import { SendFaxContextProvider } from "views/SendFax/contexts/context";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import { Destination, SendFaxFiles, SendFaxInitiatedFrom } from "views/SendFax/contexts/store";
import CoverSheetEnforcementManager from "views/SendFax/components/CoverSheetEnforcementManager";
import InitSendFax from "./InitSendFax";
import ResumeFaxes from "./ResumeFaxes";
import Promotion from "./Promotion/Promotion";

interface InitSendFaxProps {
  files?: SendFaxFiles;
  destination?: Destination[];
  initiatedFrom?: SendFaxInitiatedFrom;
}

export interface SendFaxRouteState {
  destination: Destination[];
}

function InitSendFaxProviders({
  files,
  initiatedFrom: initiatedFromProps,
  destination: destinationFromProps,
}: InitSendFaxProps) {
  const { handleNewEntryDestination } = useSendFaxDestinationHelpers();

  const { to } = useParams<{ to: string }>();
  const history = useHistory<SendFaxRouteState>();
  const routeDestination = history?.location?.state?.destination;

  const canIUseSendFax = useAppSelector(selectCanIUseSendFax);

  const [isPromotionShown, isPromotionShownBool] = useBool(false);

  const { destination, initiatedFrom } = useMemo(() => {
    let destinationFromRoute: Destination[] | undefined = undefined;
    let initiatedFrom: SendFaxInitiatedFrom | undefined = undefined;

    // To from params has priority over the route state
    if (typeof to === "string") {
      destinationFromRoute = [handleNewEntryDestination(to)];
      initiatedFrom = { from: "send-fax" };
    } else if (routeDestination) {
      destinationFromRoute = routeDestination;
      initiatedFrom = { from: "send-fax" };
    }

    return {
      destination: destinationFromRoute,
      // Overwrite initiatedFrom, to avoid loading send fax sessions from indexeddb
      // only overwrite initiatedFrom if we have "to" or "routeDestination"
      initiatedFrom,
    };
  }, [handleNewEntryDestination, routeDestination, to]);

  if (!canIUseSendFax || isPromotionShown) {
    return <Promotion isPromotionShownBool={isPromotionShownBool} />;
  }

  return (
    <SendFaxContextProvider>
      <InitSendFax
        files={files}
        destination={destination || destinationFromProps}
        initiatedFrom={initiatedFrom || initiatedFromProps}
      />
      {/* Pass the initiatedFrom through props, because reading from store takes 1 extra render */}
      {/* And the session might be initialized when we don't want it to be */}
      <ResumeFaxes initiatedFrom={initiatedFrom || initiatedFromProps} />

      <CoverSheetEnforcementManager />
    </SendFaxContextProvider>
  );
}

export default InitSendFaxProviders;
