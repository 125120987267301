import queryString from "query-string";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  ComboFields,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  useSnackbar,
  TextButton,
} from "@alohi/kit";

import { Grid } from "ui";
import Link from "routes/Link";
import { urls } from "routes/urls";
import useTitle from "hooks/useTitle";
import { useInput } from "hooks/useInput";
import { twoFaActions } from "enums/twoFa";
import ActivateTwoFaModal from "views/TwoFa/ActivateTwoFaModal";
import CommonLayout from "components/Authentication/CommonLayout";
import { handleTwoFaFromSso } from "stores/reducers/twoFa.reducer";
import ValidateTwoFaCodeModal from "views/TwoFa/ValidateTwoFaCodeModal";
import LoginWithGoogle from "components/Authentication/LoginWithGoogle";
import { isValidEmail, isValidPasswordAttempt } from "helpers/inputValidation";
import {
  login,
  clearLogin,
  loginWithSso,
  clearLoginWithSso,
} from "stores/reducers/authentication.reducer";
import {
  selectTwoFaAction,
  selectIsLoginRunning,
  selectIsLoginWithSsoError,
  selectLoginErrorNotification,
  selectLoginWithSsoRedirection,
  selectLoginWithSsoErrorEmailNotFound,
} from "selectors/authentication.selector";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation<{ redirectTo: string }>();
  const { enqueueSnackbar } = useSnackbar();

  // Query params when login from SSO
  const {
    next_action: nextActionFromSso,
    "2fa_token": twoFaTokenFromSso,
    uid: corporateMemberUidFromSso,
  } = queryString.parse(location.search);

  useTitle(t("LOGIN.INTRO"));

  const twoFaAction = useSelector(selectTwoFaAction);
  const errorNotification = useSelector(selectLoginErrorNotification);
  const isLoginRunning = useSelector(selectIsLoginRunning);

  const isLoginWithSsoError = useSelector(selectIsLoginWithSsoError);
  const loginWithSsoError = useSelector(selectLoginWithSsoErrorEmailNotFound);
  const ssoRedirection = useSelector(selectLoginWithSsoRedirection);

  const [isSso, setIsSso] = useState(false);
  const [email, emailInput] = useInput("", isValidEmail);
  const [password, passwordInput] = useInput("", isValidPasswordAttempt);

  const [isTwoFaValidateCodeModalOpen, setIsTwoFaValidationCodeModalOpen] = useState(false);
  const [isTwoFaActivationModalOpen, setIsTwoFaActivationModalOpen] = useState(false);
  const [isSsoLoading, setIsSsoLoading] = useState(false);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      emailInput.touch();

      if (isSso) {
        if (!emailInput.isValid) {
          enqueueSnackbar(t("FORMS.INVALID_EMAIL"), { variant: "error" });
        } else {
          setIsSsoLoading(true);
          dispatch(loginWithSso({ email: email }));
        }
      } else {
        passwordInput.touch();

        if (emailInput.isValid && passwordInput.isValid) {
          dispatch(login({ username: email, password: password }));
        }
      }
    },
    [dispatch, email, emailInput, enqueueSnackbar, isSso, password, passwordInput, t],
  );

  useEffect(() => {
    if (nextActionFromSso || twoFaTokenFromSso) {
      dispatch(
        handleTwoFaFromSso({
          nextAction: nextActionFromSso,
          twoFaToken: twoFaTokenFromSso,
          corporateMemberUid: corporateMemberUidFromSso,
        }),
      );
      history.replace(window.location.pathname);
    }
  }, [corporateMemberUidFromSso, dispatch, history, nextActionFromSso, twoFaTokenFromSso]);

  useEffect(() => {
    if (twoFaAction === twoFaActions.validateTwoFa) {
      setIsTwoFaValidationCodeModalOpen(true);
    } else if (twoFaAction === twoFaActions.enableTwoFa) {
      setIsTwoFaActivationModalOpen(true);
    }
  }, [dispatch, twoFaAction]);

  useEffect(() => {
    if (errorNotification) {
      enqueueSnackbar(errorNotification, { variant: "error" });
      passwordInput.setShowsError(true);
      dispatch(clearLogin());
    }
  }, [dispatch, enqueueSnackbar, errorNotification, passwordInput, t]);

  useEffect(() => {
    if (isLoginWithSsoError) {
      if (loginWithSsoError) {
        enqueueSnackbar(loginWithSsoError, { variant: "error" });
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
      setIsSsoLoading(false);
      dispatch(clearLoginWithSso());
    }
  }, [dispatch, enqueueSnackbar, loginWithSsoError, isLoginWithSsoError, t]);

  useEffect(() => {
    if (ssoRedirection) {
      window.location.href = ssoRedirection;
      dispatch(clearLoginWithSso());
    }
  }, [dispatch, history, ssoRedirection]);

  useEffect(() => {
    return () => {
      dispatch(clearLogin());
    };
  }, [dispatch]);

  return (
    <>
      <CommonLayout>
        <form onSubmit={handleSubmit}>
          <ComboFields>
            <TextField
              id="email"
              name="email"
              type="email"
              value={email}
              autoComplete="username"
              onBlur={emailInput.onBlur}
              onFocus={emailInput.onFocus}
              error={emailInput.showsError}
              onChange={emailInput.onChange}
              placeholder={t("COMMON.EMAIL")}
              InputProps={{
                inputProps: { "data-cy": "EmailInput" },
              }}
              helperText={emailInput.showsError ? t("FORMS.INVALID_EMAIL") : " "}
            />
            {!isSso && (
              <TextField
                id="password"
                name="password"
                type="password"
                value={password}
                onBlur={passwordInput.onBlur}
                onFocus={passwordInput.onFocus}
                autoComplete="current-password"
                error={passwordInput.showsError}
                onChange={passwordInput.onChange}
                placeholder={t("FORMS.PASSWORD")}
                InputProps={{
                  inputProps: { "data-cy": "PasswordInput" },
                }}
                helperText={
                  passwordInput.showsError && !passwordInput.isValid
                    ? t("FORMS.ENTER_A_PASSWORD")
                    : " "
                }
              />
            )}
          </ComboFields>

          {!isSso && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                p: "6px",
                pr: 0,
              }}
            >
              <Link to={urls.resetPassword}>
                <TextButton>{t("LOGIN.FORGOT_PWD")}</TextButton>
              </Link>
            </Box>
          )}

          <Button
            size="large"
            fullWidth
            type="submit"
            sx={{ mt: 2 }}
            data-cy="LogInButton"
            isLoading={isLoginRunning || isSsoLoading}
          >
            <Typography type="loginButton">
              {isSso ? t("LOGIN.LOG_IN_WITH_SSO") : t("COMMON.LOG_IN")}
            </Typography>
          </Button>

          {!isSso && (
            <>
              <Divider sx={{ my: 3 }} text={t("LOGIN.OR_LOGIN_WITH")} />

              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoginWithGoogle />
                </Grid>
              </Grid>
            </>
          )}

          <TextButton sx={{ mt: 2, mx: "auto" }} onClick={() => setIsSso((current) => !current)}>
            {isSso ? t("LOGIN.USE_PASSWORD") : t("LOGIN.USE_SSO")}
          </TextButton>

          <Divider sx={{ mt: 3, mb: 1 }} />

          <Typography type="body" sx={{ display: "flex", alignItems: "center" }}>
            {t("LOGIN.DONT_HAVE_ACCOUNT")}
            <Link
              to={{
                pathname: `/signup`,
                state: { redirectTo: location.state?.redirectTo },
              }}
            >
              <TextButton sx={{ py: "6px", px: 1 }}>{t("COMMON.SIGN_UP")}</TextButton>
            </Link>
          </Typography>
        </form>
      </CommonLayout>

      {isTwoFaValidateCodeModalOpen && (
        <ValidateTwoFaCodeModal handleClosure={() => setIsTwoFaValidationCodeModalOpen(false)} />
      )}

      {isTwoFaActivationModalOpen && (
        <ActivateTwoFaModal handleClosure={() => setIsTwoFaActivationModalOpen(false)} />
      )}
    </>
  );
}

export default Login;
