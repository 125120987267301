import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ZendeskSearchArticleResponse } from "selectors/support.selector";
import { RootState } from "stores/store";
import { SearchArticlesParams, supportApi } from "api/support.api";
import { selectAccountInfo } from "selectors/account.selector";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";

interface ContactUsParams {
  name: string;
  body: string;
  email: string;
  subject: string;
}

const initialState = {
  contactUs: InitialRequestState(),
  requestSocReport: InitialRequestState(),
  requestDataDeletion: InitialRequestState(),
  requestSearchArticles: InitialRequestState(),
};

export const clearContactUs = createAction("SUPPORT/CLEAR_CONTACT_US");
export const clearRequestSocReport = createAction("SUPPORT/CLEAR_REQUEST_SOC_REPORT");
export const clearRequestDataDeletion = createAction("SUPPORT/CLEAR_REQUEST_DATA_DELETION");
export const clearRequestSearchArticles = createAction("SUPPORT/CLEAR_REQUEST_SEARCH_ARTICLES");

export const contactUs = createAsyncThunk<
  { response: Record<string, never> },
  ContactUsParams,
  { rejectValue: FetchError }
>("SUPPORT/CONTACT_US", async ({ name, email, body, subject }, { rejectWithValue }) => {
  try {
    return await supportApi.support({
      name,
      email,
      request: {
        category: "FEEDBACK",
        body,
        subject,
      },
    });
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const requestSocReport = createAsyncThunk<
  { response: Record<string, never> },
  { signersName: string; signersEmail: string },
  { rejectValue: FetchError; state: RootState }
>(
  "SUPPORT/REQUEST_SOC_REPORT",
  async ({ signersName, signersEmail }, { rejectWithValue, getState }) => {
    try {
      const { name, email } = selectAccountInfo(getState());

      return await supportApi.support({
        name,
        email,
        request: {
          category: "SOC2_REQUEST",
          signer_email: signersEmail,
          signer_full_name: signersName,
        },
      });
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
);

export const requestDataDeletion = createAsyncThunk<
  { response: Record<string, never> },
  undefined,
  { rejectValue: FetchError; state: RootState }
>("SUPPORT/REQUEST_DATA_DELETION", async (_, { rejectWithValue, getState }) => {
  try {
    const { name, email } = selectAccountInfo(getState());

    return await supportApi.support({
      name,
      email,
      request: {
        category: "DELETION_WEB",
      },
    });
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const requestSearchArticles = createAsyncThunk<
  ZendeskSearchArticleResponse,
  SearchArticlesParams,
  { rejectValue: FetchError }
>("SUPPORT/SEARCH_ARTICLES", async (params, { rejectWithValue }) => {
  try {
    const response = await supportApi.searchArticles(params);
    return response.json();
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const { reducer } = createSlice({
  name: "SUPPORT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(contactUs.pending, (state) => {
      state.contactUs.status = RS.RUNNING;
      state.contactUs.error = null;
    });
    builder.addCase(contactUs.fulfilled, (state) => {
      state.contactUs.status = RS.IDLE;
      state.contactUs.response = true;
    });
    builder.addCase(contactUs.rejected, (state, { payload }) => {
      state.contactUs.status = RS.ERROR;
      state.contactUs.error = payload;
    });
    builder.addCase(clearContactUs, (state) => {
      state.contactUs = InitialRequestState();
    });
    builder.addCase(requestSocReport.pending, (state) => {
      state.requestSocReport.status = RS.RUNNING;
      state.requestSocReport.error = null;
    });
    builder.addCase(requestSocReport.fulfilled, (state) => {
      localStorage.setItem("socRequestSent", "true");
      state.requestSocReport.status = RS.IDLE;
      state.requestSocReport.response = true;
    });
    builder.addCase(requestSocReport.rejected, (state, { payload }) => {
      state.requestSocReport.status = RS.ERROR;
      state.requestSocReport.error = payload;
    });
    builder.addCase(clearRequestSocReport, (state) => {
      state.requestSocReport = InitialRequestState();
    });
    builder.addCase(requestDataDeletion.pending, (state) => {
      state.requestDataDeletion.status = RS.RUNNING;
      state.requestDataDeletion.error = null;
    });
    builder.addCase(requestDataDeletion.fulfilled, (state) => {
      localStorage.setItem("socRequestSent", "true");
      state.requestDataDeletion.status = RS.IDLE;
      state.requestDataDeletion.response = true;
    });
    builder.addCase(requestDataDeletion.rejected, (state, { payload }) => {
      state.requestDataDeletion.status = RS.ERROR;
      state.requestDataDeletion.error = payload;
    });
    builder.addCase(clearRequestDataDeletion, (state) => {
      state.requestDataDeletion = InitialRequestState();
    });
    builder.addCase(requestSearchArticles.pending, (state) => {
      state.requestSearchArticles.status = RS.RUNNING;
      state.requestSearchArticles.error = null;
    });
    builder.addCase(requestSearchArticles.fulfilled, (state, { payload }) => {
      state.requestSearchArticles.status = RS.IDLE;
      state.requestSearchArticles.response = payload;
    });
    builder.addCase(requestSearchArticles.rejected, (state, { payload }) => {
      state.requestSearchArticles.status = RS.ERROR;
      state.requestSearchArticles.error = payload;
    });
    builder.addCase(clearRequestSearchArticles, (state) => {
      state.requestSearchArticles = InitialRequestState();
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export default reducer;
