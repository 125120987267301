import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { thirdPartyApi } from "../../api/3rd_party.api";
import RS from "../../enums/requestStatus";
import { selectAccountIsCorporateMember } from "../../selectors/account.selector";
import { selectIsPlanCorporate } from "../../selectors/plan.selector";
import { logout } from "./authentication.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  getSlackChannels: initialRequestState,
  getSlackToken: initialRequestState,
  deactivateSlack: initialRequestState,
  postSlackToken: initialRequestState,
  getSlackTest: initialRequestState,
  updateSlackSettings: initialRequestState,
};

const getSlackChannels = createAsyncThunk(
  "SLACK/GET_SLACK_CHANNELS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await thirdPartyApi.getSlackChannels();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getSlackToken = createAsyncThunk(
  "SLACK/GET_SLACK_TOKEN",
  async (_, { rejectWithValue, getState }) => {
    const isCorporate = selectIsPlanCorporate(getState());
    const isCorporateMember = selectAccountIsCorporateMember(getState());

    if (!isCorporate && !isCorporateMember) {
      return rejectWithValue("not corporate");
    }
    try {
      const response = await thirdPartyApi.getSlackToken();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const deactivateSlack = createAsyncThunk(
  "SLACK/DEACTIVATE_SLACK",
  async (_, { rejectWithValue }) => {
    try {
      const response = await thirdPartyApi.deleteSlackToken();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const postSlackToken = createAsyncThunk(
  "SLACK/POST_SLACK_TOKEN",
  async ({ code, redirectUri }, { rejectWithValue }) => {
    try {
      const response = await thirdPartyApi.postSlackToken({ code, redirectUri });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getSlackTest = createAsyncThunk(
  "SLACK/GET_SLACK_TEST",
  async (_, { rejectWithValue }) => {
    try {
      const response = await thirdPartyApi.getSlackTest();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition(_, { getState }) {
      return !(getState().slack?.getSlackTest?.status === RS.RUNNING);
    },
  },
);

const updateSlackSettings = createAsyncThunk(
  "SLACK/UPDATE_SLACK_SETTINGS",
  async (
    { targetChannel, sentFaxNotifications, receivedFaxNotifications },
    { rejectWithValue },
  ) => {
    try {
      const response = await thirdPartyApi.updateSlackSettings({
        targetChannel,
        sentFaxNotifications,
        receivedFaxNotifications,
      });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const clearUpdateSlackSettings = createAction("SLACK/CLEAR_UPDATE_SLACK_SETTINGS");
export const clearDeactivateSlack = createAction("SLACK/CLEAR_DEACTIVATE_SLACK");
export const clearPostSlackToken = createAction("SLACK/CLEAR_POST_SLACK_TOKEN");
export const clearGetSlackTest = createAction("SLACK/CLEAR_GET_SLACK_TEST");

const { reducer } = createSlice({
  name: "slack",
  initialState,
  extraReducers: {
    [getSlackChannels.pending]: (state) => {
      state.getSlackChannels.status = RS.RUNNING;
      state.getSlackChannels.error = null;
    },
    [getSlackChannels.fulfilled]: (state, { payload }) => {
      state.getSlackChannels.status = RS.IDLE;
      state.getSlackChannels.response = payload.response;
    },
    [getSlackChannels.rejected]: (state, { payload }) => {
      state.getSlackChannels.status = RS.ERROR;
      state.getSlackChannels.error = payload;
    },
    [getSlackToken.pending]: (state) => {
      state.getSlackToken.status = RS.RUNNING;
      state.getSlackToken.error = null;
    },
    [getSlackToken.fulfilled]: (state, { payload }) => {
      state.getSlackToken.status = RS.IDLE;
      state.getSlackToken.response = payload.response;
    },
    [getSlackToken.rejected]: (state, { payload }) => {
      state.getSlackToken.status = RS.ERROR;
      state.getSlackToken.error = payload;
      state.getSlackToken.response = false; // Error can mean Slack is disabled
    },
    [deactivateSlack.pending]: (state) => {
      state.deactivateSlack.status = RS.RUNNING;
      state.deactivateSlack.error = null;
    },
    [deactivateSlack.fulfilled]: (state, { payload }) => {
      state.deactivateSlack.status = RS.IDLE;
      state.deactivateSlack.response = payload.response;
      state.getSlackToken = initialRequestState;
      state.getSlackChannels = initialRequestState;
    },
    [deactivateSlack.rejected]: (state, { payload }) => {
      state.deactivateSlack.status = RS.ERROR;
      state.deactivateSlack.error = payload;
    },
    [clearDeactivateSlack]: (state) => {
      state.deactivateSlack = initialRequestState;
    },
    [postSlackToken.pending]: (state) => {
      state.postSlackToken.status = RS.RUNNING;
      state.postSlackToken.error = null;
    },
    [postSlackToken.fulfilled]: (state, { payload }) => {
      state.postSlackToken.status = RS.IDLE;
      state.postSlackToken.response = payload.response;
    },
    [postSlackToken.rejected]: (state, { payload }) => {
      state.postSlackToken.status = RS.ERROR;
      state.postSlackToken.error = payload;
    },
    [clearPostSlackToken]: (state) => {
      state.postSlackToken = initialRequestState;
    },
    [getSlackTest.pending]: (state) => {
      state.getSlackTest.status = RS.RUNNING;
      state.getSlackTest.error = null;
    },
    [getSlackTest.fulfilled]: (state, { payload }) => {
      state.getSlackTest.status = RS.IDLE;
      state.getSlackTest.response = payload.response;
    },
    [getSlackTest.rejected]: (state, { payload }) => {
      state.getSlackTest.status = RS.ERROR;
      state.getSlackTest.error = payload;
    },
    [clearGetSlackTest]: (state) => {
      state.getSlackTest = initialRequestState;
    },
    [updateSlackSettings.pending]: (state) => {
      state.updateSlackSettings.status = RS.RUNNING;
      state.updateSlackSettings.error = null;
    },
    [updateSlackSettings.fulfilled]: (state, { payload }) => {
      state.updateSlackSettings.status = RS.IDLE;
      state.updateSlackSettings.response = payload.response;
    },
    [updateSlackSettings.rejected]: (state, { payload }) => {
      state.updateSlackSettings.status = RS.ERROR;
      state.updateSlackSettings.error = payload;
    },
    [clearUpdateSlackSettings]: (state) => {
      state.updateSlackSettings = initialRequestState;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export {
  getSlackChannels,
  getSlackToken,
  deactivateSlack,
  postSlackToken,
  getSlackTest,
  updateSlackSettings,
};

export default reducer;
