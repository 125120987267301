import { useEffect } from "react";
import { useSnackbar } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectDiscountCodeError } from "selectors/payment.selector";

function useErrorHandler(usedCode) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const discountCodeError = useSelector(selectDiscountCodeError);

  useEffect(() => {
    if (discountCodeError?.code && discountCodeError?.reason) {
      let error;
      switch (discountCodeError.reason) {
        case "code_not_enabled":
        case "code_not_started":
        case "code_currency_mismatch":
        case "code_uid_mismatch":
        case "code_type_unknown":
        case "code_not_found":
          error = t("ADD_CREDIT.DISCOUNT_CODE_IS_INVALID", { code: usedCode });
          break;
        case "code_ended":
        case "code_max_uses_reached":
        case "code_already_used_for_user":
          error = t("ADD_CREDIT.DISCOUNT_CODE_HAS_EXPIRED", { code: usedCode });
          break;
        case "code_total_amount_exceeded":
        case "code_total_amount_not_reached":
        case "code_not_possible_for_amount":
          error = t("ADD_CREDIT.DISCOUNT_CODE_CANNOT_USE_AMOUNT", {
            code: usedCode,
          });
          break;
        case "code_is_first_purchase_only":
          error = t("ADD_CREDIT.DISCOUNT_CODE_ONLY_FIRST_PURCHASE", {
            code: usedCode,
          });
          break;
        case "code_is_paying_user_only":
          error = t("ADD_CREDIT.DISCOUNT_CODE_NOTVALID_FIRST_PURCHASE", {
            code: usedCode,
          });
          break;
        default:
          error = t("ADD_CREDIT.DISCOUNT_CODE_IS_INVALID", {
            code: usedCode,
          });
          break;
      }
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [discountCodeError, enqueueSnackbar, t, usedCode]);
}

export default useErrorHandler;
