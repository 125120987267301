import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, BigButton, Typography, Box, Modal } from "@alohi/kit";
import { faFileAlt, faFileImage } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { helpCenterUrls } from "routes/urls";
import { FormControlLabel, useTheme } from "ui";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import { selectIsSendingFaxLoading } from "selectors/numbers.selector";
import { updateAccountDetails, clearUpdateAccountDetails } from "stores/reducers/account.reducer";
import {
  selectAccountShouldEnhance,
  selectIsLoadingAccountDetails,
  selectIsUpdateAccountDetailsSuccessful,
} from "selectors/account.selector";

function TransmissionOptimizationModal({ onCancel, onConfirm }) {
  const { alohi } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSendingFaxLoading = useSelector(selectIsSendingFaxLoading);
  const initialIsOptimizeText = useSelector(selectAccountShouldEnhance);
  const isAccountDetailsLoading = useSelector(selectIsLoadingAccountDetails);
  const isUpdateAccountDetailsSuccess = useSelector(selectIsUpdateAccountDetailsSuccessful);

  const [isOptimizeText, isOptimizeTextHandlers] = useBool(initialIsOptimizeText);
  const [rememberOptimization, rememberOptimizationHandler] = useBool(
    localStorage.getItem("remember_transmission_optimization") === "true",
  );

  const handleOnConfirm = useCallback(() => {
    if (initialIsOptimizeText !== isOptimizeText) {
      dispatch(
        updateAccountDetails({
          updateType: "settings",
          accountData: {
            shouldEnhance: isOptimizeText,
          },
        }),
      );
    } else {
      localStorage.setItem(
        "remember_transmission_optimization",
        rememberOptimization ? "true" : "false",
      );
      onConfirm(true);
    }
  }, [onConfirm, rememberOptimization, initialIsOptimizeText, isOptimizeText, dispatch]);

  useEffect(() => {
    if (isUpdateAccountDetailsSuccess) {
      dispatch(clearUpdateAccountDetails());
      onConfirm(true);
    }
  }, [initialIsOptimizeText, dispatch, isUpdateAccountDetailsSuccess, onConfirm]);

  const isLoading = isAccountDetailsLoading || isSendingFaxLoading;

  return (
    <Modal
      height={300}
      maxWidth="xs"
      onCancel={onCancel}
      onConfirm={handleOnConfirm}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      confirmTitle={t("COMMON.SEND")}
      data-cy="TransmissionOptimizationModal"
      title={
        <Typography type="modalTitle">
          <Box display="flex" alignItems="center">
            <Box>{t("SENT_FAX.TRANSMISSION_OPTIMIZATION")}</Box>
            <Box
              ml={1}
              component="a"
              target="_blank"
              rel="noreferrer"
              href={helpCenterUrls.optimize}
            >
              <HelpTooltip size={"lg"} variant="light" />
            </Box>
          </Box>
        </Typography>
      }
    >
      <Box p={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            height: "240px",
            alignItems: "center",
          }}
        >
          <BigButton
            width="150px"
            height="150px"
            isSelected={isOptimizeText}
            onClick={isOptimizeTextHandlers.setTrue}
          >
            <FontAwesomeIcon icon={faFileAlt} color={alohi.almostGray} style={{ fontSize: 45 }} />
            <Box m={1.5} />
            <Typography variant="subtitle3" style={{ fontSize: 14 }}>
              {t("SENT_FAX.OPTIMIZE_TEXT")}
            </Typography>
          </BigButton>
          <BigButton
            width="150px"
            height="150px"
            isSelected={!isOptimizeText}
            onClick={isOptimizeTextHandlers.setFalse}
          >
            <FontAwesomeIcon icon={faFileImage} color={alohi.almostGray} style={{ fontSize: 45 }} />
            <Box m={1.5} />
            <Typography variant="subtitle3" style={{ fontSize: 14 }}>
              {t("SENT_FAX.OPTIMIZE_IMAGE")}
            </Typography>
          </BigButton>
        </Box>
        <Box px={2}>
          <FormControlLabel
            control={
              <Checkbox
                value={rememberOptimization}
                checked={rememberOptimization}
                onChange={rememberOptimizationHandler.toggle}
                style={{ alignSelf: "start", paddingTop: 0 }}
              />
            }
            label={
              <Box mr={1} display="flex" flexDirection="column">
                <Typography>{t("SENT_FAX.REMEMBER_TRANSMISSION")}</Typography>
                <Typography>{t("SENT_FAX.REMEMBER_TRANSMISSION_INFO")}</Typography>
              </Box>
            }
          />
        </Box>
      </Box>
    </Modal>
  );
}

TransmissionOptimizationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default TransmissionOptimizationModal;
