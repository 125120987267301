import { Checkbox, Box } from "@alohi/kit";
import { CreateTokenProps } from "api/personal_access_tokens.api";

import { Helper, Typography, useTheme } from "ui";
import i18n from "i18n/index";

interface ScopesSelectorProps {
  selectedScopes: CreateTokenProps["scopes"];
  onScopeSelect?: (scope: CreateTokenProps["scopes"][0]) => void;
  disabled?: boolean;
}

const scopesList: {
  value: CreateTokenProps["scopes"][0];
  tooltip: string;
}[] = [
  {
    value: "fax:all:read",
    tooltip: i18n.t("PAT.SCOPE_FAX_ALL_READ"),
  },
  {
    value: "fax:all:edit",
    tooltip: i18n.t("PAT.SCOPE_FAX_ALL_WRITE"),
  },
  {
    value: "fax:webhook:read",
    tooltip: i18n.t("PAT.SCOPE_WEBHOOK_READ"),
  },
  {
    value: "fax:webhook:edit",
    tooltip: i18n.t("PAT.SCOPE_WEBHOOK_WRITE"),
  },
  {
    value: "fax:fax:read",
    tooltip: i18n.t("PAT.SCOPE_FAX_READ"),
  },
  {
    value: "fax:fax:edit",
    tooltip: i18n.t("PAT.SCOPE_FAX_WRITE"),
  },
  {
    value: "fax:user:read",
    tooltip: i18n.t("PAT.SCOPE_USER_READ"),
  },
  {
    value: "fax:user:edit",
    tooltip: i18n.t("PAT.SCOPE_USER_EDIT"),
  },
  {
    value: "fax:shop:read",
    tooltip: i18n.t("PAT.SCOPE_SHOP_READ"),
  },
  {
    value: "fax:shop:edit",
    tooltip: i18n.t("PAT.SCOPE_SHOP_EDIT"),
  },
  {
    value: "fax:member:read",
    tooltip: i18n.t("PAT.SCOPE_MEMBER_READ"),
  },
  {
    value: "fax:member:edit",
    tooltip: i18n.t("PAT.SCOPE_MEMBER_WRITE"),
  },
  {
    value: "fax:numbers:read",
    tooltip: i18n.t("PAT.SCOPE_NUMBERS_READ"),
  },
  {
    value: "fax:numbers:edit",
    tooltip: i18n.t("PAT.SCOPE_NUMBERS_WRITE"),
  },
];

function ScopesSelector({ selectedScopes, onScopeSelect, disabled }: ScopesSelectorProps) {
  const theme = useTheme();
  return (
    <>
      {scopesList.map((scope, index) => (
        <Box
          onClick={() => onScopeSelect?.(scope.value)}
          key={index}
          sx={{
            margin: theme.spacing(0, 1, 1.5, 1),
            cursor: disabled ? null : "pointer",
            display: "flex",
            flexDirection: "flex",
            alignItems: "center",
            "& > span:nth-of-type(1)": {
              background: "transparent",
              color: theme.alohi.almostGray,
            },
          }}
        >
          <Checkbox
            disabled
            size="small"
            sx={{ padding: 0 }}
            checked={selectedScopes.some((selectedScope) => selectedScope === scope.value)}
          />

          <Typography px={1} variant="warning" sx={{ userSelect: "none" }}>
            {scope.value}
          </Typography>
          <Helper placement="right" tooltip={scope.tooltip} />
        </Box>
      ))}
    </>
  );
}

export default ScopesSelector;
