import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
  makeStyles,
  useBool,
} from "@alohi/kit";
import { formatPhoneNumberIntl } from "helpers/numbers";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  selectIsUpdateFaxReceptionLoading,
  selectIsUpdateFaxReceptionSuccess,
} from "selectors/numbers.selector";
import {
  clearUpdateFaxReception,
  getAllNumbers,
  updateFaxReception,
} from "stores/reducers/numbers.reducer";
import { useAppDispatch, useAppSelector } from "stores/store";

interface DisableFaxReceptionModalProps {
  number: string;
  isChecked: boolean;
  handleClosure: (isSuccess: boolean) => void;
}

function DisableFaxReceptionModal({
  number,
  handleClosure,
  isChecked: isCheckedInitially,
}: DisableFaxReceptionModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isChecked, isCheckedBool] = useBool(isCheckedInitially);
  const isFaxReceptionLoading = useAppSelector<boolean>(selectIsUpdateFaxReceptionLoading);
  const isFaxReceptionUpdated = useAppSelector<boolean>(selectIsUpdateFaxReceptionSuccess);

  const handleConfirmation = () => {
    dispatch(
      updateFaxReception({
        toEnable: false,
        numberToUpdate: number,
        notifyWhenDisabled: isChecked,
      }),
    );
  };

  useEffect(() => {
    if (isFaxReceptionUpdated) {
      dispatch(getAllNumbers());
      dispatch(clearUpdateFaxReception());
      handleClosure(true);
    }
  }, [dispatch, isFaxReceptionUpdated, handleClosure]);

  return (
    <Modal
      hasCloseIcon
      maxWidth="xs"
      onConfirm={handleConfirmation}
      onCancel={() => handleClosure(false)}
      isModalDisabled={isFaxReceptionLoading}
      isConfirmLoading={isFaxReceptionLoading}
      title={t("NUMBERS.DISABLE_FAX_RECEPTION")}
    >
      <Box p={3}>
        <Typography>
          {t("NUMBERS.DISABLE_FAX_RECEPTION_QUESTION", { number: formatPhoneNumberIntl(number) })}
        </Typography>
        <Typography type="subtitle" className={classes.subtitle} component="div">
          {t("NUMBERS.DISABLE_FAX_RECEPTION_NOTIFICATIONS")}
        </Typography>
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox value={isChecked} checked={isChecked} onChange={isCheckedBool.toggle} />
          }
          label={<Typography>{t("NUMBERS.DISABLE_FAX_RECEPTION_ALERTS_TOOLTIP")}</Typography>}
        />
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  subtitle: {
    marginTop: spacing(2),
  },
  checkbox: {
    marginTop: spacing(5),
    marginLeft: spacing(1),
  },
}));

export default DisableFaxReceptionModal;
