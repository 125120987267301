import { faCheck, faClose } from "@fortawesome/pro-light-svg-icons";
import { Period } from "api/gql/generated/graphql";
import { useTranslation } from "react-i18next";
import { alohi, Box, Icon, List, makeStyles, TextButton, Typography } from "@alohi/kit";
import clsx from "clsx";
import { useCustomer } from "hooks/gql/useCustomer/useCustomer";
import { SIGN_APP_DOMAIN } from "config";

const IncludedPerk = ({ children }: { children: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.item}>
      <Icon className={classes.successIcon} icon={faCheck} />
      {children}
    </Box>
  );
};

const ExcludedPerk = ({ children }: { children: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.item}>
      <Icon className={classes.failIcon} icon={faClose} />
      {children}
    </Box>
  );
};

interface PerksProps {
  className?: string;
}

function Perks({ className }: PerksProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const EIDAS = "eIDAS/ZertES";
  const HIPAA = "HIPAA";

  const {
    customer: {
      computed: { activeSignSubscriptionWithPlan },
    },
  } = useCustomer();

  const handleGoTo = () => {
    window.open(SIGN_APP_DOMAIN, "_blank");
  };

  if (!activeSignSubscriptionWithPlan) return null;

  const period = activeSignSubscriptionWithPlan.planItem.period;
  const planType = activeSignSubscriptionWithPlan.planItem.planType;
  const name = activeSignSubscriptionWithPlan.planItem.marketingData.name;
  const requestSignature = activeSignSubscriptionWithPlan.planItem.requestSignature.value ?? 0;
  const hasApi = activeSignSubscriptionWithPlan.planItem.hasApi;

  const planLabel = `${t("COMMON.PLAN_X", { value_1: name })} (${period === Period.Annual ? t("CHANGE_PLAN.YEAR") : t("CHANGE_PLAN.MONTH")})`;

  if (!planType) return null;
  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.header}>
        <Typography isBold>{planLabel}</Typography>
      </Box>
      <Box className={classes.content}>
        <Typography>{t("PERKS.WHATSINCLUDED")}</Typography>
        {hasApi
          ? {
              BASIC: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>
                        {t("PERKS.SIGNATURESPERMONTH", {
                          value_1: requestSignature,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t("PERKS.TEMPLATES", { value_1: 5 })}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.NOTIFICATIONANDREMINDERS")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.TEMPERPROOFAUDITTRAIL")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("SIGNFEATURES.CLOUDSTORAGEINTEGRATION")}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <ExcludedPerk>{t("SIGNFEATURES.CUSTOMBRANDING")}</ExcludedPerk>
                      <ExcludedPerk>{t("SIGNFEATURES.SMSAUTHENTICATION")}</ExcludedPerk>
                      <ExcludedPerk>{HIPAA}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              PREMIUM: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>
                        {t("PERKS.SIGNATURESPERMONTH", {
                          value_1: requestSignature,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t("PERKS.TEMPLATES", { value_1: 10 })}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.NOTIFICATIONANDREMINDERS")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.TEMPERPROOFAUDITTRAIL")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("SIGNFEATURES.CLOUDSTORAGEINTEGRATION")}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.CUSTOMBRANDING")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.SMSAUTHENTICATION")}</IncludedPerk>
                      <ExcludedPerk>{HIPAA}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              BUSINESS: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>
                        {t("PERKS.SIGNATURESPERMONTH", {
                          value_1: requestSignature,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDTEMPLATES")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.NOTIFICATIONANDREMINDERS")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.TEMPERPROOFAUDITTRAIL")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("SIGNFEATURES.CLOUDSTORAGEINTEGRATION")}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.CUSTOMBRANDING")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.SMSAUTHENTICATION")}</IncludedPerk>
                      <ExcludedPerk>{HIPAA}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              ENTERPRISE: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>
                        {t("PERKS.SIGNATURESPERMONTH", {
                          value_1: requestSignature,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDTEMPLATES")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.NOTIFICATIONANDREMINDERS")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.TEMPERPROOFAUDITTRAIL")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("SIGNFEATURES.CLOUDSTORAGEINTEGRATION")}</IncludedPerk>
                      <IncludedPerk>{EIDAS}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.CUSTOMBRANDING")}</IncludedPerk>
                      <IncludedPerk>{t("SIGNFEATURES.SMSAUTHENTICATION")}</IncludedPerk>
                      <IncludedPerk>{HIPAA}</IncludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
            }[planType]
          : {
              BASIC: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.ONEUSER")}</IncludedPerk>
                      <IncludedPerk>
                        {t("PERKS.SIGNATURESPERMONTH", {
                          value_1: requestSignature,
                        })}
                      </IncludedPerk>
                      <IncludedPerk>{t("PERKS.TEMPLATES", { value_1: 5 })}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.REALTIMEAUDITTRAIL")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.MOBILEAPP")}</IncludedPerk>
                      <ExcludedPerk>{t("PERKS.FOLDERS")}</ExcludedPerk>
                      <ExcludedPerk>{t("PERKS.SMSAUTHENTICATION", { value_1: 5 })}</ExcludedPerk>
                      <ExcludedPerk>{t("PERKS.IPS")}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              PREMIUM: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.UPTOFIVEUSERS")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDSIGNATURES")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.TEMPLATES", { value_1: 10 })}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.SMSAUTHENTICATION")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.IPS")}</IncludedPerk>
                      <ExcludedPerk>{t("PERKS.ZAPIERINTEGRATION")}</ExcludedPerk>
                      <ExcludedPerk>{t("PERKS.DOCUMENTSHARING")}</ExcludedPerk>
                      <ExcludedPerk>{t("PERKS.SSO")}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              BUSINESS: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.MULTIPLEUSERS")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDSIGNATURES")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDTEMPLATES")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.ZAPIERINTEGRATION")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.DOCUMENTSHARING")}</IncludedPerk>
                      <ExcludedPerk>{t("PERKS.SSO")}</ExcludedPerk>
                      <ExcludedPerk>{t("PERKS.PRIORITYSUPPORT")}</ExcludedPerk>
                      <ExcludedPerk>{t("PERKS.DATARESIDENCY")}</ExcludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
              ENTERPRISE: (
                <Box className={classes.perksContainer}>
                  <Box className={classes.sectionLeft}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.MULTIPLEUSERS")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDSIGNATURES")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.UNLIMITEDTEMPLATES")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.SSO")}</IncludedPerk>
                    </List>
                  </Box>
                  <Box className={classes.sectionRight}>
                    <List className={classes.list}>
                      <IncludedPerk>{t("PERKS.DATARESIDENCY")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.BULKSEND")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.PRIORITYSUPPORT")}</IncludedPerk>
                      <IncludedPerk>{t("PERKS.ASC")}</IncludedPerk>
                    </List>
                  </Box>
                </Box>
              ),
            }[planType]}
      </Box>
      <TextButton className={classes.goTo} onClick={handleGoTo}>
        {t("INCENTIVE_SIGN.GO_TO_SIGN")}
      </TextButton>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    //
  },
  header: {
    borderRadius: "8px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: alohi.neutral200,
    padding: spacing(1, 2, 1, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    borderRadius: "8px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: alohi.neutral200,
    padding: spacing(2),
    marginTop: spacing(1),
  },
  list: {
    padding: 0,
  },
  perksContainer: {
    display: "flex",
    alignItems: "flex-start",
    [breakpoints.down("sm")]: {
      display: "block",
    },
  },
  sectionLeft: {
    //
  },
  sectionRight: {
    marginLeft: spacing(4),
    [breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  successIcon: {
    marginTop: spacing(0.5),
    color: alohi.blue400,
    marginRight: spacing(1),
  },
  failIcon: {
    marginTop: spacing(0.5),
    color: alohi.red700,
    marginRight: spacing(1),
  },
  item: {
    marginTop: spacing(1),
    display: "flex",
    alignItems: "flex-start",
  },
  filler: {
    flex: 1,
  },
  goTo: {
    marginTop: spacing(1),
    color: alohi.blue400,
  },
}));

export default Perks;
