import { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { capitalizeString } from "helpers/string";
import Currency from "components/Core/Currency/Currency";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";

function AvailablePlansTooltip({ availablePlans }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <HelpTooltip
      placement="bottom"
      title={
        <Box m={1} className={classes.tooltip}>
          {availablePlans?.map((plans) => (
            <ul key={plans.label}>
              {capitalizeString(plans.label)}
              {[plans.options[0]].map((plan) => (
                <ul key={plan.purchase_id} className={classes.ul}>
                  <li>
                    {t("CHANGE_PLAN.TOOLTIP_PAGES_PER_MONTH", {
                      value: plan.pages,
                    })}
                  </li>
                  <li>
                    <Currency value={plan.extra_rate} currency={plan.currency} />{" "}
                    {t("CHANGE_PLAN.TOOLTIP_PRICE_PER_EXTRA_PAGE")}
                  </li>
                  {plan.plan_target === "corporate" && (
                    <li>{t("CHANGE_PLAN.TOOLTIP_MULTIPLE_FAX_NB")}</li>
                  )}
                </ul>
              ))}
            </ul>
          ))}
        </Box>
      }
    />
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: spacing(1),
  },
  ul: {
    marginLeft: spacing(4),
  },
}));

AvailablePlansTooltip.propTypes = {
  availablePlans: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.array,
    }),
  ),
};

export default memo(AvailablePlansTooltip);
