import PropTypes from "prop-types";
import { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, TextButton, makeStyles } from "@alohi/kit";

import { formatPhoneNumberIntl } from "helpers/numbers";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";
import {
  selectCdrId,
  selectIsCdrLoading,
  selectCdrContactName,
  selectCdrFromToNumber,
  selectSelectedMemberUid,
  selectIsCdrReadyForPreview,
} from "selectors/faxes.selector";

function FaxesColumnFromTo({ boxName, dataIndex }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedMemberUid = useSelector(selectSelectedMemberUid);
  const cdrId = useSelector(selectCdrId(boxName, dataIndex));
  const number = useSelector(selectCdrFromToNumber(boxName, dataIndex));
  const isLoading = useSelector(selectIsCdrLoading(boxName, dataIndex));
  const contactName = useSelector(selectCdrContactName(boxName, dataIndex));
  const isReadyForPreview = useSelector(selectIsCdrReadyForPreview(boxName, dataIndex));

  const handleClick = useCallback(() => {
    if (!isLoading) {
      dispatch(faxesAsyncActions.markAsRead({ boxName, cdrIds: [cdrId] }));
      dispatch(
        faxesAsyncActions.previewFile({
          boxName,
          cdrId,
          fetchOptions: { uid: selectedMemberUid },
        }),
      );
    }
  }, [isLoading, dispatch, boxName, cdrId, selectedMemberUid]);

  return (
    <Box className={classes.row}>
      {isReadyForPreview ? (
        <TextButton onClick={handleClick}>
          <Typography
            type="bold"
            className={classes.fromNumber}
            data-cy={`CdrFaxNumber-row-${dataIndex}`}
          >
            {contactName || (number && formatPhoneNumberIntl(number)) || number}
          </Typography>
        </TextButton>
      ) : (
        <Typography classes={classes.fromNumber} data-cy={`CdrFaxNumber-row-${dataIndex}`}>
          {contactName || (number && formatPhoneNumberIntl(number)) || number}
        </Typography>
      )}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  fromNumber: {
    padding: 0,
    display: "block",
    cursor: "pointer",
    textAlign: "start",
    userSelect: "text",
    textDecoration: "underline",
  },
  row: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

FaxesColumnFromTo.propTypes = {
  boxName: PropTypes.string,
  dataIndex: PropTypes.number,
};

export default memo(FaxesColumnFromTo);
