import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";

const getByCdrId = (id) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/fax_confirmation/page/${id}`;
  return fetchWithRefresh(path, requestOptions);
};

const getDataByToken = ({ token }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/fax_confirmation/data/${token}`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true });
};

export const faxConfirmationApi = {
  getByCdrId,
  getDataByToken,
};
