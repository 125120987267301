// For non-production builds, the config can be overwritten at runtime with global variables in /static/constants.js
/// <reference types="vite/client" />

declare global {
  interface Window {
    WEB_CONFIG: Record<string, string>;
  }
}

const constructFeatureNameURL = (urlToReplace: string, urlToExtract: string) => {
  try {
    if (!urlToReplace) {
      return "";
    }
    if (!urlToExtract) {
      return urlToReplace;
    }
    const [featureNameToExtract] = new URL(urlToExtract).hostname.split(".");
    const [featureNameToReplace] = new URL(urlToReplace).hostname.split(".");

    return urlToReplace.replace(featureNameToReplace, featureNameToExtract);
  } catch {
    return "";
  }
};

const constructAdminUrl = (prefixToReplaceWith: string) => {
  const url = window.location.origin.replace("app", "admin");
  const [currentPrefix] = url.replace("https://", "").split(".");

  if (prefixToReplaceWith === "dev" && currentPrefix === "dev") {
    // Dev will go to dev
    return url;
  }

  if (prefixToReplaceWith === "dev" && currentPrefix !== prefixToReplaceWith) {
    // FAXWEB-123 with prefixToReplace === "dev"
    // Admin target will be FAXWEB-123.admin.fax.alohi.io
    return url;
  }

  // prefixToReplace is ADMINWEB-22
  // Admin target will be ADMINWEB-22.admin.fax.alohi.io
  return url.replace(currentPrefix, prefixToReplaceWith);
};

export const NODE_ENV = import.meta.env?.PROD ? "production" : "development";

export const ENVIRONMENT_NAME = import.meta.env?.VITE_ENVIRONMENT_NAME;

export const isLocal = ENVIRONMENT_NAME === "LOCAL";

export const isDev = ENVIRONMENT_NAME === "DEV";

export const DOMAIN = import.meta.env?.VITE_DOMAIN;

export const SUB_DOMAIN = import.meta.env?.VITE_SUB_DOMAIN;

export const WEBSITE_NAME = import.meta.env?.WEBSITE_NAME;

export const VERSION = import.meta.env?.VITE_VERSION;

export const MARKETING_WEBSITE_URL = import.meta.env?.VITE_MARKETING_WEBSITE_URL;

export const API_SSO = import.meta.env?.VITE_API_SSO;

export const STRIPE_CLIENT_ID = import.meta.env?.VITE_STRIPE_CLIENT_ID;

export const SLACK_CLIENT_ID = import.meta.env?.VITE_SLACK_CLIENT_ID;

export const SLACK_OAUTH_URI = import.meta.env?.VITE_SLACK_OAUTH_URI;

export const BRAINTREE_CLIENT_ID = import.meta.env?.VITE_BRAINTREE_CLIENT_ID;

export const BACKEND_URL = import.meta.env?.VITE_BACKEND_URL;

export const CHAILATTE_URL = import.meta.env?.VITE_CHAILATTE_URL;

export const COFFEE_URL = import.meta.env?.VITE_COFFEE_URL;

export const ZORRO_URL = import.meta.env?.VITE_ZORRO_URL;

export const STORYBOOK_URL = import.meta.env?.VITE_STORYBOOK_URL;

export const X_ZANG_CLIENT_ID = import.meta.env?.VITE_X_ZANG_CLIENT_ID;

export const BASIC_AUTH_BEARER = import.meta.env?.VITE_BASIC_AUTH_BEARER;

export const GOOGLE_CLIENT_ID = import.meta.env?.VITE_GOOGLE_CLIENT_ID;

export const GOOGLE_ADDON_CLIENT_ID = import.meta.env?.VITE_GOOGLE_ADDON_CLIENT_ID;

export const SENTRY_SDK = import.meta.env?.VITE_SENTRY_SDK;

export const SENTRY_AUTH_TOKEN = import.meta.env?.VITE_SENTRY_AUTH_TOKEN;

export const KIBANA_RUM_URL = import.meta.env?.VITE_KIBANA_RUM_URL;

export const KIBANA_RUM_NAME = import.meta.env?.VITE_KIBANA_RUM_NAME;

export const GOOGLE_TAG_MANAGER_ID = import.meta.env?.VITE_GOOGLE_TAG_MANAGER_ID;

export const SIGN_PLUS_DOCUMENT_REDIRECT_URL = import.meta.env
  ?.VITE_SIGN_PLUS_DOCUMENT_REDIRECT_URL;

export const GOOGLE_PICKER_OAUTH = import.meta.env?.VITE_GOOGLE_PICKER_OAUTH;

export const DROPBOX_PICKER_OAUTH = import.meta.env?.VITE_DROPBOX_PICKER_OAUTH;

export const ONE_DRIVE_PICKER_OAUTH = import.meta.env?.VITE_ONE_DRIVE_PICKER_OAUTH;

export const BOX_CLIENT_ID_PICKER_OAUTH = import.meta.env?.VITE_BOX_CLIENT_ID_PICKER_OAUTH;

export const BOX_CLIENT_SECRET_PICKER_OAUTH = import.meta.env?.VITE_BOX_CLIENT_SECRET_PICKER_OAUTH;

export const ZENDESK_URL = import.meta.env?.VITE_ZENDESK_URL;

export const API_PREFIX = import.meta.env?.VITE_API_PREFIX;

export const SIGN_WEBAPP_PREFIX = import.meta.env?.VITE_SIGN_WEBAPP_PREFIX;

export const SIGN_APP_DOMAIN = import.meta.env?.VITE_SIGN_APP_DOMAIN;

export const UNLEASH_URL = import.meta.env?.VITE_UNLEASH_URL;

export const UNLEASH_CLIENT_KEY = import.meta.env?.VITE_UNLEASH_CLIENT_KEY;

export const ADMIN_WEBAPP_PREFIX = import.meta.env?.VITE_ADMIN_WEBAPP_PREFIX;

export const ADMIN_DOMAIN =
  isLocal || isDev ? constructAdminUrl(ADMIN_WEBAPP_PREFIX) : import.meta.env?.VITE_ADMIN_DOMAIN;

export const SOCKET_URL =
  (isLocal || isDev) && typeof window !== "undefined" && window.WEB_CONFIG?.["fax_api_target"]
    ? constructFeatureNameURL(import.meta.env?.VITE_SOCKET_URL, window.WEB_CONFIG["fax_api_target"])
    : import.meta.env?.VITE_SOCKET_URL;

export const ADMIN_GRAPHQL_ENDPOINT =
  (isLocal || isDev) && typeof window !== "undefined" && window.WEB_CONFIG?.["admin_fax_api_target"]
    ? constructFeatureNameURL(
        import.meta.env?.VITE_ADMIN_GRAPHQL_ENDPOINT,
        window.WEB_CONFIG["admin_fax_api_target"],
      )
    : import.meta.env?.VITE_ADMIN_GRAPHQL_ENDPOINT;

export const API_ESHOP =
  (isLocal || isDev) && typeof window !== "undefined" && window.WEB_CONFIG?.["fax_api_target"]
    ? constructFeatureNameURL(import.meta.env?.VITE_API_ESHOP, window.WEB_CONFIG["fax_api_target"])
    : import.meta.env?.VITE_API_ESHOP;

export const API_AFFOGATO =
  (isLocal || isDev) && typeof window !== "undefined" && window.WEB_CONFIG?.["fax_api_target"]
    ? constructFeatureNameURL(
        import.meta.env?.VITE_API_AFFOGATO,
        window.WEB_CONFIG["fax_api_target"],
      )
    : import.meta.env?.VITE_API_AFFOGATO;

export const API_STORAGE =
  (isLocal || isDev) && typeof window !== "undefined" && window.WEB_CONFIG?.["fax_api_target"]
    ? constructFeatureNameURL(
        import.meta.env?.VITE_API_STORAGE,
        window.WEB_CONFIG["fax_api_target"],
      )
    : import.meta.env?.VITE_API_STORAGE;

export const API_RISTRETTO =
  (isLocal || isDev) && typeof window !== "undefined" && window.WEB_CONFIG?.["fax_api_target"]
    ? constructFeatureNameURL(
        import.meta.env?.VITE_API_RISTRETTO,
        window.WEB_CONFIG["fax_api_target"],
      )
    : import.meta.env?.VITE_API_RISTRETTO;
