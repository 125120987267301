import createSelector from "selectorator";

import i18n from "../i18n/index";
import RS from "../enums/requestStatus";
import { twoFaActions } from "../enums/twoFa";

export const selectIsLoggedIn = createSelector(
  ["authentication.loggedIn"],
  (isLoggedIn) => isLoggedIn,
);

export const selectAccessToken = createSelector(["authentication.accessToken"], (token) => token);

export const selectIsTwoFaActive = createSelector(["twoFa.isActive"], (isActive) => isActive);

export const selectTwoFaAction = createSelector(["twoFa.nextAction"], (action) => {
  switch (action) {
    case "validate_2fa":
      return twoFaActions.validateTwoFa;
    case "enable_2fa":
      return twoFaActions.enableTwoFa;
    default:
      return null;
  }
});

export const selectIsLoginRunning = createSelector(
  ["authentication.login.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsLoginWithSsoRunning = createSelector(
  ["authentication.loginWithSso.status"],
  (status) => status === RS.RUNNING,
);

export const selectLoginWithSsoRedirection = createSelector(
  ["authentication.loginWithSso.response"],
  (response) => {
    if (!response) return null;
    return response.redirect_url;
  },
);

export const selectIsLoginWithSsoError = createSelector(
  ["authentication.loginWithSso.status"],
  (status) => status === RS.ERROR,
);

export const selectLoginWithSsoErrorEmailNotFound = createSelector(
  ["authentication.loginWithSso.error"],
  (error) => {
    if (!error) return null;
    if (error.reason === "User not found") {
      return i18n.t("SSO.EMAIL_NOT_FOUND");
    }
    if (error.reason === "SSO access is not allowed for this account") {
      return i18n.t("SSO.EMAIL_NOT_CONFIGURED");
    }
    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const loginWithTwoFaSuccess = createSelector(
  ["authentication.loginWithTwoFa.response"],
  (response) => !!response,
);

export const loginWithTwoFaError = createSelector(
  ["authentication.loginWithTwoFa.error"],
  (error) => {
    return !!error || null;
  },
);

export const loginWithTwoFaErrorExpiredToken = createSelector(
  ["authentication.loginWithTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return ["2faـtokenـhasـbeenـexpired", "2fa_token_has_been_expired"].includes(error.reason);
  },
);

export const loginWithTwoFaErrorInvalidCode = createSelector(
  ["authentication.loginWithTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return ["invalidـcode", "invalid_code"].includes(error.reason);
  },
);

export const selectTwoFaQrCode = createSelector(["twoFa.qrCode"], (codes) => codes);

export const selectTwoFaManualCode = createSelector(["twoFa.manualCode"], (codes) => codes);

export const selectTwoFaRecoveryCodes = createSelector(["twoFa.recoveryCodes"], (codes) => codes);

export const selectTwoFaActionEnforcedByAdminOnLogin = createSelector(
  ["twoFa.nextAction"],
  (action) => action === "enable_2fa",
);

export const selectIsActivateTwoFaSuccess = createSelector(
  ["twoFa.activateTwoFa.response"],
  (response) => !!response,
);

export const selectIsActivateTwoFaError = createSelector(
  ["twoFa.activateTwoFa.status"],
  (status) => status === RS.ERROR,
);

export const selectActivateTwoFaErrorInvalidCode = createSelector(
  ["twoFa.activateTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return ["invalidـcode", "invalid_code"].includes(error.reason);
  },
);

export const selectIsDeactivateTwoFaSuccess = createSelector(
  ["twoFa.deactivateTwoFa.response"],
  (response) => !!response,
);

export const selectIsDeactivateTwoFaError = createSelector(
  ["twoFa.deactivateTwoFa.status"],
  (status) => status === RS.ERROR,
);

export const selectDeactivateTwoFaErrorInvalidCode = createSelector(
  ["twoFa.deactivateTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return ["invalidـcode", "invalid_code"].includes(error.reason);
  },
);

export const selectDeactivateTwoFaErrorEnforced = createSelector(
  ["twoFa.deactivateTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return ["enforcedـuser", "enforced_user"].includes(error.reason);
  },
);

export const selectIsActivateTwoFaFromEnforcedSuccess = createSelector(
  ["twoFa.activateTwoFaFromEnforced.response"],
  (response) => !!response,
);

export const selectIsActivateTwoFaFromEnforcedError = createSelector(
  ["twoFa.activateTwoFaFromEnforced.status"],
  (status) => status === RS.ERROR,
);

export const selectActivateTwoFaFromEnforcedErrorInvalidCode = createSelector(
  ["twoFa.activateTwoFaFromEnforced.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return ["invalidـcode", "invalid_code"].includes(error.reason);
  },
);

export const selectPendingAccessToken = createSelector(
  ["twoFa.pendingAccessToken"],
  (token) => token,
);

export const selectIsEnforceTwoFaLoading = createSelector(
  ["twoFa.activateCorporateTwoFa.status", "twoFa.deactivateCorporateTwoFa.status"],
  (activateStatus, deactivateStatus) =>
    activateStatus === RS.RUNNING || deactivateStatus === RS.RUNNING,
);

export const selectIsActivateCorporateTwoFaSuccess = createSelector(
  ["twoFa.activateCorporateTwoFa.response"],
  (response) => !!response,
);
export const selectIsDeactivateCorporateTwoFaSuccess = createSelector(
  ["twoFa.deactivateCorporateTwoFa.response"],
  (response) => !!response,
);

export const selectIsActivateCorporateTwoFaError = createSelector(
  ["twoFa.activateCorporateTwoFa.status"],
  (status) => status === RS.ERROR,
);

export const selectIsDeactivateCorporateTwoFaError = createSelector(
  ["twoFa.deactivateCorporateTwoFa.status"],
  (status) => status === RS.ERROR,
);

export const selectActivateCorporateTwoFaErrorInvalidPassword = createSelector(
  ["twoFa.activateCorporateTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return error.reason === "invalid_username_or_password";
  },
);

export const selectDeactivateCorporateTwoFaErrorInvalidPassword = createSelector(
  ["twoFa.deactivateCorporateTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return error.reason === "invalid_username_or_password";
  },
);

export const selectActivateCorporateTwoFaErrorShouldBeEnabled = createSelector(
  ["twoFa.activateCorporateTwoFa.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return error.reason === "2fa_should_be_enable";
  },
);

export const selectActivityLogs = createSelector(["authentication.activityLogs"], (logs) => {
  return logs.reduce((acc, curr) => {
    const newLogsObject = curr;
    const { extra_info, ...rest } = newLogsObject;
    rest.platform = {
      ...extra_info,
      clientPlatform: rest.client_platform,
    };
    acc.push(rest);
    return acc;
  }, []);
});

export const selectIsActivityLogsRunning = createSelector(
  ["authentication.getActivityLogs.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsRegisterSuccess = createSelector(
  ["authentication.register.response"],
  (response) => Boolean(response),
);

export const selectRegisterError = createSelector(
  ["authentication.register.error", "authentication.register.status"],
  (error, status) => {
    if (!error && status !== RS.ERROR) {
      return null;
    }

    switch (error?.reason) {
      case "already_registered":
        return i18n.t("SIGNUP.ALREADY_REGISTERED");
      case "corporate_member_has_not_signed_up":
        return i18n.t("FORMS.CORPORATE_MEMBER_HAS_NOT_SIGNED_UP");
      case "unsecure_password":
        return i18n.t("FORMS.PASSWORD_MUST_BE_MORE_SECURE");
      case "similar_email_already_used":
        return i18n.t("FORMS.SIMILAR_EMAIL_ALREADY_USED");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectLoginErrorNotification = createSelector(
  ["authentication.login.error"],
  (error) => {
    if (!error) return null;

    switch (error.reason) {
      case "Invalid Username or Password":
        return i18n.t("LOGIN.INVALID_CREDENTIAL");
      case "account_is_disabled_by_admin":
        return i18n.t("LOGIN.ACCOUNT_IS_DISABLED_BY_ADMIN");
      case "corporate_member_can_only_log_with_sso":
        return i18n.t("SSO.CORPORATE_MEMBER_CAN_ONLY_LOG_WITH_SSO");
      case "corporate_admin_can_only_log_with_sso":
        return i18n.t("SSO.CORPORATE_ADMIN_CAN_ONLY_LOG_WITH_SSO");
      case "corporate_member_has_not_signed_up":
        return i18n.t("FORMS.CORPORATE_MEMBER_HAS_NOT_SIGNED_UP");
      case "no_fax_license":
        return i18n.t("LOGIN.NO_FAX_LICENSE");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsResetPasswordSuccess = (state) =>
  Boolean(state.authentication.resetPassword?.response);

export const selectIsResetPasswordRunning = createSelector(
  ["authentication.resetPassword.status"],
  (status) => status === RS.RUNNING,
);

export const selectResetPasswordError = createSelector(
  ["authentication.resetPassword.error"],
  (error) => {
    if (!error) {
      return null;
    }
    switch (error.reason) {
      case "unsecure_password":
        return i18n.t("FORMS.PASSWORD_MUST_BE_MORE_SECURE");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsGracefulLogout = createSelector(
  ["authentication.isGracefulLogout"],
  (isGracefulLogout) => {
    return !!isGracefulLogout;
  },
);

export const selectIsForgotPasswordSuccess = createSelector(
  ["authentication.forgotPassword.response"],
  (response) => !!response,
);

export const selectIsForgotPasswordRunning = createSelector(
  ["authentication.forgotPassword.status"],
  (status) => status === RS.RUNNING,
);

export const selectForgotPasswordErrorMessage = createSelector(
  ["authentication.forgotPassword.error"],
  (error) => {
    if (!error) return null;
    switch (error.reason) {
      case "No user is registered with this email.":
        return i18n.t("FORGOT.NOT_EXIST_EMAIL");
      case "cannot_change_password_with_sso":
        return i18n.t("SSO.CANNOT_CHANGE_PASSWORD_WITH_SSO");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectLoginWithGoogleErrorNotification = createSelector(
  ["authentication.loginWithGoogle.error"],
  (error) => {
    if (!error) return null;
    switch (error.reason) {
      case "corporate_member_can_only_log_with_sso":
        return i18n.t("SSO.CORPORATE_MEMBER_CAN_ONLY_LOG_WITH_SSO");
      case "corporate_admin_can_only_log_with_sso":
        return i18n.t("SSO.CORPORATE_ADMIN_CAN_ONLY_LOG_WITH_SSO");
      case "corporate_member_has_not_signed_up":
        return i18n.t("FORMS.CORPORATE_MEMBER_HAS_NOT_SIGNED_UP");
      case "User Not Found":
        return null;
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsSignupWithGoogleRequired = createSelector(
  ["authentication.loginWithGoogle.error"],
  (error) => {
    return error?.reason === "User Not Found";
  },
);
