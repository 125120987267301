import { ContactsResponse, RecentResponse, GroupsResponse } from "api/types/accounts";
import {
  GroupDestination,
  RecentDestination,
  ContactDestination,
} from "views/SendFax/contexts/store";

function transformContacts(contact: ContactsResponse): ContactDestination {
  return {
    target: "to",
    value: contact.fax_number,
    label: contact.fullname || contact.fax_number,
    icon: contact.shared ? "sharedContact" : "contact",
    caption: contact.fullname ? contact.fax_number : "",
  };
}

function transformRecent(recent: RecentResponse): RecentDestination {
  return {
    target: "to",
    icon: "recent",
    value: recent.number,
    label: recent.number,
    caption: recent.number,
  };
}

function transformGroups(group: GroupsResponse): GroupDestination {
  return {
    value: group.id,
    label: group.name,
    target: "tag_ids",
    membersCount: group.members_count,
    icon: group.shared ? "sharedGroup" : "group",
    caption: group.members_count + " member" + (group.members_count !== 1 ? "s" : ""),
  };
}

export { transformGroups, transformRecent, transformContacts };
