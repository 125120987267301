import PropTypes from "prop-types";
import { Select } from "@alohi/kit";
import FormRow from "./FormRow";

function FormRowSelectInput({
  id,
  label,
  helperText,
  error,
  children,
  labelGridWidth = 4,
  value,
  onChange,
  placeholder,
  disabled,
}) {
  return (
    <FormRow
      labelGridWidth={labelGridWidth}
      htmlFor={id}
      label={label}
      error={error}
      helperText={helperText}
    >
      <Select
        fullWidth
        variant="outlined"
        labelId={`${id}-label`}
        id={id}
        displayEmpty
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      >
        {children}
      </Select>
    </FormRow>
  );
}

FormRowSelectInput.propTypes = {
  id: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.node,
  labelGridWidth: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

export default FormRowSelectInput;
