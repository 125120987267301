import createSelector from "selectorator";
import { default as parsePhoneNumber } from "libphonenumber-js";

import i18n from "../i18n/index";
import RS from "../enums/requestStatus";
import { numberTypes } from "../enums/numbers";
import { orderByLabel } from "../helpers/common";
import { selectAccountPhone } from "../selectors/account.selector";
import { selectCurrentCreditCurrency } from "../selectors/credit.selector";

export const selectNewVerifyNumber = createSelector(
  ["verifications.verifyNumber.response"],
  (number) => number?.item?.number || "",
);

export const selectVerifyNumber = createSelector(
  ["verifications.verifyNumber.response"],
  (numberObject) => {
    if (numberObject?.random) {
      return numberObject.random;
    }
    return numberObject;
  },
);

export const selectIsVerifyNumberLoading = createSelector(
  ["verifications.verifyNumber.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsVerifyCustomNumberLoading = createSelector(
  ["verifications.verifyCustomNumber.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsVerifyNumberError = createSelector(
  ["verifications.verifyNumber.status"],
  (status) => status === RS.ERROR,
);

export const selectIsVerifyNumberNoContent = createSelector(
  [
    "verifications.verifyNumber.status",
    "verifications.verifyCustomNumber.status",
    "verifications.verifyBulkNumbers.status",
  ],
  (firstStatus, secondStatus, thirdStatus) =>
    firstStatus === RS.NO_CONTENT ||
    secondStatus === RS.NO_CONTENT ||
    thirdStatus === RS.NO_CONTENT,
);

export const selectAvailableCustomNumbers = createSelector(
  ["verifications.verifyCustomNumber.response"],
  (response) => {
    if (response?.data?.custom) {
      return response.data.custom.reduce((accumulator, currentValue) => {
        const currentObject = {};
        Object.assign(currentObject, {
          priceAndCurrency: {
            price: currentValue.price,
            currency: currentValue.currency.toLowerCase(),
          },
        });
        Object.assign(currentObject, currentValue);
        accumulator.push(currentObject);
        return accumulator;
      }, []);
    }
    return null;
  },
);

export const selectVerifyCustomNumber = createSelector(
  ["verifications.verifyCustomNumber.selected"],
  (selection) => selection,
);

export const selectSelectedNumberType = createSelector(
  [selectVerifyNumber, selectVerifyCustomNumber],
  (verifyRandom, verifyCustom) => {
    if (!!verifyCustom) return numberTypes.custom;
    else if (!!verifyRandom) return numberTypes.random;
    return null;
  },
);

export const selectIsGeoLocationFulfilled = createSelector(
  ["verifications.geoLocation.status", "verifications.geoLocation.response"],
  (status, response) => (status === RS.IDLE && Boolean(response)) || status === RS.ERROR,
);

export const selectIsGeoLocationRunning = createSelector(
  ["verifications.geoLocation.status"],
  (status) => status === RS.RUNNING,
);

export const selectDefaultCountry = createSelector(
  ["verifications.geoLocation.response", selectAccountPhone],
  (response, phone) => {
    let phoneCountry = null;
    let geoLocation = null;
    if (phone && parsePhoneNumber(phone)?.country) {
      phoneCountry = parsePhoneNumber(phone).country;
    }
    if (response?.geoip_iso2) {
      geoLocation = response.geoip_iso2;
    }
    return {
      geoLocation,
      phoneCountry,
      defaultCountry: phoneCountry || geoLocation || "US",
    };
  },
);

export const selectRequestSmsPhoneVerificationSuccess = createSelector(
  ["verifications.requestSmsPhoneVerification.response"],
  (response) => !!response,
);

export const selectRequestSmsPhoneVerificationError = createSelector(
  ["verifications.requestSmsPhoneVerification.error"],
  (error) => !!error,
);

export const selectRequestSmsPhoneVerificationErrorDuplicatePhone = createSelector(
  ["verifications.requestSmsPhoneVerification.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return error.reason === "duplicated_phone";
  },
);

export const selectRequestSmsPhoneVerificationErrorInvalidNumber = createSelector(
  [
    "verifications.requestSmsPhoneVerification.status",
    "verifications.requestSmsPhoneVerification.error",
  ],
  (status, error) => {
    if (!(status === RS.ERROR)) {
      return false;
    }
    switch (error?.reason) {
      case "zang_phone":
        return i18n.t("VERIFY.ZANG_PHONE");
      case "fake_phone":
        return i18n.t("VERIFY.FAKE_PHONE");
      case "invalid_number":
        return i18n.t("VERIFY.VOIP_MESSAGE");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectPhoneVerificationReachedDayQuota = createSelector(
  ["verifications.requestSmsPhoneVerification.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.reason === "quota_max_try_in_day_limit_reached";
  },
);

export const selectPhoneVerificationBackoffTimeSeconds = createSelector(
  ["verifications.phoneVerificationBackoffTime"],
  (seconds) => seconds,
);

export const selectSubmitPhoneVerificationCodeSuccess = createSelector(
  ["verifications.submitPhoneVerificationCode.response"],
  (response) => !!response,
);

export const selectSubmitPhoneVerificationCodeErrorInvalid = createSelector(
  ["verifications.submitPhoneVerificationCode.error"],
  (error) => {
    if (error?.reason === "invalid_token") {
      return true;
    }
    return null;
  },
);

export const selectRequestCallPhoneVerificationSuccess = createSelector(
  ["verifications.requestCallPhoneVerification.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectAvailableCurrencies = createSelector(
  ["verifications.currencies.availableCurrencies.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectIsSubscribeProcessRunning = createSelector(
  [
    "verifications.subscribe.userInfo.status",
    "verifications.subscribe.unsubscribe.status",
    "verifications.subscribe.resubscribe.status",
  ],
  (userInfoStatus, unsubscribeStatus, resubscribeStatus) =>
    userInfoStatus === RS.RUNNING ||
    unsubscribeStatus === RS.RUNNING ||
    resubscribeStatus === RS.RUNNING,
);

export const selectIsSubscribeError = createSelector(
  [
    "verifications.subscribe.userInfo.status",
    "verifications.subscribe.unsubscribe.status",
    "verifications.subscribe.resubscribe.status",
  ],
  (userInfoStatus, unsubscribeStatus, resubscribeStatus) =>
    userInfoStatus === RS.ERROR || unsubscribeStatus === RS.ERROR || resubscribeStatus === RS.ERROR,
);

export const selectCalculatePageResponse = (number) =>
  createSelector(["verifications.calculatePage.response"], (response) => {
    if (!response?.[number]) {
      return null;
    }
    return response[number];
  });

export const selectIsCalculatePageError = createSelector(
  ["verifications.calculatePage.status"],
  (status) => status === RS.ERROR,
);

export const selectUserInfoByToken = createSelector(
  ["verifications.subscribe.userInfo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response?.email ?? "";
  },
);

export const selectIsUnsubscribed = createSelector(
  ["verifications.subscribe.unsubscribe.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsResubscribed = createSelector(
  ["verifications.subscribe.resubscribe.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectReferrerInfo = createSelector(
  ["verifications.checkReferrer.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectIsReferrerError = createSelector(
  ["verifications.checkReferrer.status"],
  (status) => status === RS.ERROR,
);

export const selectIsFaxConfirmationError = createSelector(
  ["verifications.faxConfirmationByToken.status"],
  (status) => status === RS.ERROR,
);

export const selectFaxConfirmation = createSelector(
  ["verifications.faxConfirmationByToken.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectIsRedeemCodeRunning = createSelector(
  ["verifications.redeemCode.status"],
  (status) => status === RS.RUNNING,
);

export const selectRedeemCodeResponse = createSelector(
  ["verifications.redeemCode.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectRedeemCodeError = createSelector(
  ["verifications.redeemCode.status", "verifications.redeemCode.error"],
  (status, error) => {
    if (status !== RS.ERROR) {
      return null;
    }
    let errorMessage = i18n.t("COMMON.SERVER_ERROR");
    if (error?.reason) {
      switch (error.reason) {
        case "coupon_does_not_exist":
          errorMessage = i18n.t("REDEEM.COUPON_DOES_NOT_EXIST");
          break;
        case "coupon_is_used":
          errorMessage = i18n.t("REDEEM.COUPON_IS_USED");
          break;
        case "user_is_unverified_phone":
          errorMessage = i18n.t("REDEEM.USER_IS_UNVERIFIED_PHONE");
          break;
        default:
          break;
      }
    }
    return errorMessage;
  },
);

export const selectReferralLink = createSelector(
  ["verifications.referralLink.response", selectCurrentCreditCurrency],
  (response, currency) => {
    return response?.[currency];
  },
);

export const selectReferralInviteCode = createSelector(
  [selectReferralLink],
  (response) => response?.invite_code,
);

export const selectReferralInviteCurrency = createSelector(
  [selectReferralLink],
  (response) => response?.currency,
);

export const selectReferralInviteReferrerAmount = createSelector(
  [selectReferralLink],
  (response) => response?.referrer_amount,
);

export const selectReferralInviteRefereeAmount = createSelector(
  [selectReferralLink],
  (response) => response?.referee_amount,
);

export const selectReferralInviteAmountOnVerification = createSelector(
  [selectReferralLink],
  (response) => response?.amount_on_verification,
);

export const selectTimeZoneSelectOptions = createSelector(
  ["verifications.timezones.response"],
  (timezones) => {
    if (!timezones) {
      return [];
    }

    return Object.entries(timezones)
      .reduce((acc, [zone, values]) => {
        acc.push({
          label: zone,
          options: values.map((element) => ({
            label: element,
            value: element,
            zone,
          })),
        });
        return acc;
      }, [])
      .sort(orderByLabel);
  },
);

export const selectIsBuyingBulkNumbers = createSelector(
  ["verifications.verifyBulkNumbers.amount"],
  (amount) => amount > 1,
);

export const selectBulkNumbersAmount = createSelector(
  ["verifications.verifyBulkNumbers.amount"],
  (amount) => amount,
);

export const selectBulkNumbersResponse = createSelector(
  ["verifications.verifyBulkNumbers.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectFirstBulkNumber = createSelector([selectBulkNumbersResponse], (response) => {
  if (!response) {
    return null;
  }
  return response[0] ?? null;
});

export const selectBulkNumbers = createSelector([selectBulkNumbersResponse], (response) => {
  if (!response) {
    return null;
  }
  return response.map(({ id }) => id);
});

export const selectBulkNumbersNumOfMonths = createSelector(
  ["verifications.verifyBulkNumbers.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response[0].number_of_months;
  },
);

export const selectBulkNumberMonthlyFee = createSelector(
  ["verifications.verifyBulkNumbers.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response[0].monthly_fee;
  },
);

export const selectIsVerifyBulkNumberLoading = createSelector(
  ["verifications.verifyBulkNumbers.status"],
  (status) => status === RS.RUNNING,
);

/*
 * This selector is used for different Change Plan/Buying a number components
 * It's a response from server that is used to buy a number / change number / change plan
 * It can be either a normal number or a custom one. Possible for the future: golden number.
 */
export const selectSelectedNumberSource = createSelector(
  [selectVerifyNumber, selectVerifyCustomNumber],
  (selectedNumber, selectedCustomNumber) => {
    return selectedNumber?.item?.number
      ? selectedNumber.item.number
      : selectedCustomNumber?.item?.number
        ? selectedCustomNumber.item.number
        : null;
  },
);

export const selectSelectedNumberObject = createSelector(
  [selectVerifyNumber, selectVerifyCustomNumber, selectFirstBulkNumber],
  (selectedNumber, selectedCustomNumber, selectedBulkNumber) => {
    return selectedNumber || selectedCustomNumber || selectedBulkNumber;
  },
);

export const selectSelectedNumberCountry = createSelector(
  [selectSelectedNumberObject],
  (numberObject) => {
    if (!numberObject?.item?.iso) {
      return null;
    }
    return numberObject.item.iso;
  },
);

export const selectSelectedNumberCountryRequirePhoneType = createSelector(
  [selectSelectedNumberCountry],
  (country) => {
    const COUNTRY_ISO = "GB";
    return country === COUNTRY_ISO;
  },
);

export const selectSelectedNumberItemType = createSelector(
  [selectSelectedNumberObject],
  (numberObject) => {
    if (!numberObject?.item?.number_type) {
      return null;
    }
    return numberObject.item.number_type;
  },
);

export const selectSelectedNumberWithBundle = createSelector(
  ["verifications.assignBundleToNumber.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectVerifyEmail = createSelector(
  ["verifications.verifyEmail.response"],
  (response) => {
    if (!response) {
      return null;
    }

    return Boolean(response);
  },
);

export const selectIsVerifyEmailLoading = createSelector(
  ["verifications.verifyEmail.status"],
  (status) => status === RS.RUNNING,
);

export const selectVerifyEmailError = createSelector(
  ["verifications.verifyEmail.error"],
  (error) => {
    if (!error) {
      return null;
    }

    switch (error?.message || error?.reason) {
      case "invalid_code":
        return i18n.t("VERIFY.INVALID_TOKEN");
      case "token_not_found":
        return i18n.t("VERIFY.ACCESS_LINK_INVALID");
      case "code_not_found":
      case "too_many_attempts":
        return i18n.t("VERIFY.CODE_EXPIRED");
      case "email_already_registered":
      default:
        break;
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectResendEmailCode = createSelector(
  ["verifications.resendEmailCode.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return Boolean(response);
  },
);

export const selectIsResendEmailLoading = createSelector(
  ["verifications.resendEmailCode.status"],
  (status) => status === RS.RUNNING,
);
