import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";

import useBool from "hooks/useBool";
import { OptionArea } from "components/Payment/CustomizedSelect";
import FormRowReactSelectInput from "components/Forms/FormRowReactSelectInput";
import { selectAddresses } from "selectors/address.selector";
import { getAddresses } from "stores/reducers/address.reducer";
import CreateNewAddressModal from "views/Address/CreateNewAddressModal";

function AddressesSelect({ country, value, handleClosure, label, labelGridWidth }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addresses = useSelector(selectAddresses);
  const [openNewAddress, openNewAddressHandler] = useBool(false);

  const handleSelectAddress = useCallback(
    (address) => {
      if (address.value === "add") {
        openNewAddressHandler.setTrue();
        return;
      }
      handleClosure(address.value);
    },
    [handleClosure, openNewAddressHandler],
  );

  const handleNewAddress = useCallback(
    (newAddress) => {
      if (newAddress) {
        dispatch(getAddresses({ force: true }));
        handleClosure(newAddress.address_sid);
      }
      openNewAddressHandler.setFalse();
    },
    [dispatch, openNewAddressHandler, handleClosure],
  );

  const addressOptions = useMemo(() => {
    const options = [{ label: t("ADDRESS.NEW_ADDRESS_OPTION"), value: "add" }];
    if (addresses) {
      addresses.forEach(({ iso_country, sid, city, region }) => {
        // Only use addresses that have the same country as the country selected for the number purchase
        if (iso_country === country) {
          options.push({ label: `${city}, ${region}`, value: sid });
        }
      });
    }
    return options;
  }, [addresses, country, t]);

  useEffect(() => {
    dispatch(getAddresses());
  }, [dispatch]);

  useEffect(() => {
    if (value === "") {
      // Auto select the first address that matches the iso_country with the country from props
      // addressOptions is already filtered by this condition
      const firstAddress = addressOptions.find((address) => address.value !== "add");
      if (firstAddress) {
        handleClosure(firstAddress.value);
      }
    }
  }, [addressOptions, handleClosure, value]);

  const selectValue = useMemo(() => {
    if (value) {
      return addressOptions.find((address) => address.value === value) ?? "";
    }
    return "";
  }, [addressOptions, value]);

  return (
    <>
      <FormRowReactSelectInput
        id="select-address-input"
        label={label}
        labelGridWidth={labelGridWidth}
        ReactSelectProps={{
          value: selectValue,
          isSearchable: true,
          maxMenuHeight: 250,
          options: addressOptions,
          onChange: handleSelectAddress,
          components: { Option: OptionArea },
        }}
      />
      {openNewAddress ? (
        <CreateNewAddressModal country={country} handleClosure={handleNewAddress} />
      ) : null}
    </>
  );
}

AddressesSelect.propTypes = {
  label: PropTypes.string,
  country: PropTypes.string,
  labelGridWidth: PropTypes.number,
  handleClosure: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default AddressesSelect;
