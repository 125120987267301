import clsx from "clsx";
import { Tooltip, Box, BoxProps, makeStyles } from "@alohi/kit";
import { TooltipProps as MuiTooltipProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core/index";
import { faQuestionCircle as faQuestionCircleLight } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle as faQuestionCircleDuotone } from "@fortawesome/pro-duotone-svg-icons";

interface HelperProps extends BoxProps {
  /** Content of the tooltip */
  tooltip?: MuiTooltipProps["title"];
  placement?: MuiTooltipProps["placement"];
  variant?: "light" | "dark" | "blue";
  /** "xs" | "lg" | "sm" | "1x" | "2x"...*/
  size?: SizeProp;
}

function Helper({
  tooltip,
  placement = "top-start",
  variant = "dark",
  className,
  size = "sm",
  ...rest
}: HelperProps) {
  const classes = useStyles();

  const iconContainer = (
    <Box className={clsx(className, classes.container)} {...rest}>
      <FontAwesomeIcon
        icon={variant === "dark" ? faQuestionCircleDuotone : faQuestionCircleLight}
        className={clsx(className, classes[variant])}
        size={size}
      />
    </Box>
  );

  if (!tooltip) return iconContainer;

  return (
    <Tooltip title={tooltip} placement={placement}>
      {iconContainer}
    </Tooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  container: { display: "inline-flex", verticalAlign: "sub" },
  dark: {
    display: "inline-block",
    "--fa-primary-color": theme.alohi.white,
    "--fa-secondary-color": theme.alohi.lightGray,
    "--fa-secondary-opacity": 1,
  },
  light: { color: "white" },
  blue: { color: theme.alohi.blue },
}));

export default Helper;
