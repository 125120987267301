import { Panel, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Helper, Typography } from "ui";
import { helpCenterUrls } from "routes/urls";

interface FrontPanelRowProps {
  enforcedCoverSheet: { id: string; name: string } | null;
}

function FrontPanelRow({ enforcedCoverSheet }: FrontPanelRowProps) {
  const { t } = useTranslation();

  return (
    <Panel.Row>
      <Panel.Cell variant="title">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Typography variant="panelCellTitle">{t("COVER_SHEET.ENFORCEMENT")}</Typography>
          </Box>
          <a href={helpCenterUrls.coverSheetEnforcement} target="_blank" rel="noreferrer">
            <Helper size="lg" variant="dark" />
          </a>
        </Box>
      </Panel.Cell>

      <Panel.Cell variant="data">{enforcedCoverSheet?.name || t("COMMON.INACTIVE")}</Panel.Cell>
    </Panel.Row>
  );
}

export default FrontPanelRow;
