import { useSelector } from "react-redux";
import FaxPlusLogo from "assets/svg/fax_plus_logo.svg?react";
import FaxPlusByAlohiLogo from "assets/svg/fax_plus_logo_text.svg?react";

import { useMediaQuery, useTheme } from "ui";
import { selectCanISeeDashboardTab } from "selectors/ability.selector";
import { useHistory } from "react-router";
import { urls } from "routes/urls";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

function AppLogo() {
  const theme = useTheme();
  const history = useHistory();

  const { sendCustomEvent } = useGoogleTagManager();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("lg"));
  const canISeeDashboardTab = useSelector(selectCanISeeDashboardTab);

  const handleClick = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarLogo,
      page: location.pathname,
      extraData: {
        label: "logo",
        to: urls.faxes.root,
      },
    });
    history.push(urls.faxes.root);
  };

  if (canISeeDashboardTab && isMobileLayout) {
    // Use smaller logo when space is limited
    return (
      <FaxPlusLogo
        width={32}
        height={32}
        cursor={"pointer"}
        data-cy="faxPlusLogo"
        onClick={handleClick}
      />
    );
  }

  return (
    <FaxPlusByAlohiLogo
      width={112}
      height={21}
      cursor={"pointer"}
      data-cy="faxPlusLogo"
      onClick={handleClick}
    />
  );
}

export default AppLogo;
