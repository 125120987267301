import { useTranslation } from "react-i18next";
import { Button, makeStyles, Box } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

import { useInput } from "hooks/useInput";
import { numberTypes } from "enums/numbers";
import FormRow from "components/Forms/FormRow";
import AddNumberModal from "views/AddNumber/AddNumberModal";
import { PhoneNumberFilter } from "components/Core/Filters";
import { isValidCompanyName } from "helpers/inputValidation";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import { selectCompanyName } from "selectors/account.selector";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import { selectCorporateUpgradeState } from "selectors/payment.selector";
import { selectCurrentPlanNumber, selectPlanId } from "selectors/plan.selector";
import {
  clearAssignBundleToNumber,
  clearVerifyNumber,
  verifyNumber,
} from "stores/reducers/verifications.reducer";
import { selectIsVerifyNumberLoading, selectVerifyNumber } from "selectors/verifications.selector";
import {
  updateCorporateCompany,
  updateCorporateSecondNumber,
} from "stores/reducers/payment.corporate.reducer";

function UpgradeIndividualPlan() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planId = useSelector(selectPlanId);
  const selectedNumber = useSelector(selectVerifyNumber);
  const initialCompanyName = useSelector(selectCompanyName);
  const currentPlanNumber = useSelector(selectCurrentPlanNumber);
  const { secondNumber } = useSelector(selectCorporateUpgradeState);
  const isVerifyNumberLoading = useSelector(selectIsVerifyNumberLoading);

  const [isGenerated, setIsGenerated] = useState(false);
  const [showNumberModal, setShowNumberModal] = useState(false);
  const [companyName, companyNameInput] = useInput(initialCompanyName || "", isValidCompanyName);

  const handleAddNumberModal = useCallback(
    (numberObject) => {
      if (numberObject) {
        // Only update the second number because first number
        // is already present in the account
        dispatch(updateCorporateSecondNumber(numberObject));
      }
      setShowNumberModal(false);
    },
    [dispatch],
  );

  useEffect(() => {
    // Heavy temporary solution for this input to send the value to the button as soon as possible
    if (companyNameInput.isValid) {
      dispatch(updateCorporateCompany(companyName));
    }
  }, [companyName, companyNameInput.isValid, dispatch]);

  useEffect(() => {
    // Scope of this effect is auto request the second number using the country from the current number
    // Then wait for second selectedNumber to get updated
    if (
      currentPlanNumber &&
      !secondNumber && // Ensure that at least one number was selected by the user
      !showNumberModal && // Ensure that the AddNumberModal is not open
      !isGenerated // Ensure that we request a number to be generated just once
    ) {
      dispatch(
        verifyNumber({
          planId,
          areaCode: "",
          action: "number",
          numberType: "local",
          countryCode: currentPlanNumber.iso,
          verificationType: numberTypes.random,
        }),
      );
    }
  }, [currentPlanNumber, dispatch, isGenerated, planId, secondNumber, showNumberModal]);

  useEffect(() => {
    // selectedNumber was updated with the new random number requested at line 73
    if (
      selectedNumber &&
      !isGenerated &&
      currentPlanNumber &&
      selectedNumber.item.number !== currentPlanNumber.number // @TODO Do this check on the backend
    ) {
      handleAddNumberModal(selectedNumber);
      dispatch(clearVerifyNumber());
      dispatch(clearAssignBundleToNumber());
      setIsGenerated(true); // Don't generate again
    }
  }, [currentPlanNumber, dispatch, handleAddNumberModal, isGenerated, selectedNumber]);

  if (showNumberModal) {
    return <AddNumberModal numberType="secondNumber" handleClosure={handleAddNumberModal} />;
  }

  return (
    <Box mb={2}>
      <FormRowTextInput
        labelGridWidth={5}
        value={companyName}
        id="select-company-name"
        onBlur={companyNameInput.onBlur}
        label={t("PROFILE.COMPANY_NAME")}
        onFocus={companyNameInput.onFocus}
        error={companyNameInput.showsError}
        onChange={companyNameInput.onChange}
        inputProps={{ "data-cy": "CompanyNameInput" }}
      />

      <FormRow label={t("CHANGE_PLAN.FIRST_FAX_NUMBER")} labelGridWidth={5}>
        <Button isDisabled variant="white" className={classes.chooseNumberButton}>
          {currentPlanNumber?.number ? (
            <Box display="flex" alignItems="center">
              {currentPlanNumber?.iso ? <CountryFlag code={currentPlanNumber.iso} /> : null}
              <Box marginLeft="6px">
                <PhoneNumberFilter number={currentPlanNumber.number} />
              </Box>
            </Box>
          ) : null}
        </Button>
      </FormRow>

      <Box display="flex" alignItems="center">
        <FormRow label={t("CHANGE_PLAN.SECOND_FAX_NUMBER")} labelGridWidth={5}>
          <Button
            variant="white"
            className={classes.chooseNumberButton}
            onClick={() => {
              !isVerifyNumberLoading && setShowNumberModal(true);
            }}
          >
            <Box className={classes.numberWrapper}>
              {secondNumber?.item?.number ? (
                <>
                  <Box className={classes.phone}>
                    <CountryFlag code={secondNumber.item.iso} />
                    <Box marginLeft="6px">
                      <PhoneNumberFilter number={secondNumber.item.number} />
                    </Box>
                  </Box>
                  <Box className={classes.change}>{t("COMMON.CHANGE")}</Box>
                </>
              ) : (
                t("CHANGE_PLAN.CLICK_CHOOSE_NUMBER")
              )}
              {secondNumber ? null : <FontAwesomeIcon icon={faChevronRight} />}
            </Box>
          </Button>
        </FormRow>
        <Box width={0} style={{ transform: "translate(16px, 0)" }}>
          <HelpTooltip placement="bottom" title={t("CHANGE_PLAN.YOU_CAN_ADD_MORE_NUMBERS")} />
        </Box>
      </Box>
    </Box>
  );
}
const useStyles = makeStyles(({ spacing, alohi }) => ({
  chooseNumberButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  numberWrapper: {
    display: "flex",
    flexBasis: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  change: {
    color: alohi.red,
    paddingRight: spacing(1),
  },
  phone: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default UpgradeIndividualPlan;
