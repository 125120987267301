import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigationModalApi, NavigationModal, Box } from "@alohi/kit";

import { Typography } from "ui";
import useOnMountCondition from "hooks/useOnMountCondition";
import { VIEWS } from "../PhoneVerificationModal";

function PhoneVerificationUsernameSent({ onConfirm }) {
  const { t } = useTranslation();

  const { updateStep, goToStep } = useNavigationModalApi();

  useOnMountCondition(() => {
    updateStep({
      title: t("VERIFY.MOBILE_VERIFICATION"),
      cancelTitle: t("COMMON.CANCEL"),
      confirmTitle: t("FORMS.DONE"),
      onConfirm: handleConfirm,
      onPressEnter: handleConfirm,
    });
  }, true);

  const handleConfirm = () => {
    goToStep(VIEWS.ENTER_PHONE);
    onConfirm(false);
  };

  return (
    <NavigationModal.Content>
      <Box p={3}>
        <Typography variant="body">{t("VERIFY.USERNAME_VIA_SMS")}</Typography>
      </Box>
    </NavigationModal.Content>
  );
}

PhoneVerificationUsernameSent.propTypes = {
  onConfirm: PropTypes.func,
};

export default PhoneVerificationUsernameSent;
