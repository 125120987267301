import basicSvg from "assets/images/plan-type/basic.svg";
import businessSvg from "assets/images/plan-type/business.svg";
import enterpriseSvg from "assets/images/plan-type/enterprise.svg";
import premiumSvg from "assets/images/plan-type/premium.svg";

export default {
  basic: basicSvg,
  premium: premiumSvg,
  business: businessSvg,
  enterprise: enterpriseSvg,
};
