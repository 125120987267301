import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Panel, Typography, Button, Modal, Box, useSnackbar } from "@alohi/kit";

import { API_STORAGE } from "config";
import Avatar from "components/Core/Avatar/Avatar";
import {
  selectAppsList,
  selectIsRemoveAppAccessError,
  selectIsRemoveAppAccessSuccess,
} from "selectors/integrations.selector";
import {
  getAllGrants,
  removeAppAccess,
  clearRemoveAppAccess,
} from "stores/reducers/integrations.apps.reducer";

function AppsPanel() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const appsList = useSelector(selectAppsList);
  const isSuccess = useSelector(selectIsRemoveAppAccessSuccess);
  const isError = useSelector(selectIsRemoveAppAccessError);

  const [removeAccessModalState, setRemoveAccessModalState] = useState({
    isOpen: false,
    appId: null,
  });

  useEffect(() => {
    dispatch(getAllGrants());

    return () => {
      dispatch(clearRemoveAppAccess());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(getAllGrants());
      setRemoveAccessModalState({
        isOpen: false,
        appId: null,
        appName: null,
      });
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearRemoveAppAccess());
    }
  }, [dispatch, enqueueSnackbar, isError, t]);

  if (!appsList?.length) {
    return null;
  }

  return (
    <>
      <Panel id="connected-apps" headerTitle={t("PROFILE.CONNECTED_APPS")}>
        <Panel.Front>
          {appsList.map((app) => (
            <Panel.Row key={app.client_id}>
              <Panel.Cell variant="title">
                <Box display="flex" alignItems="center">
                  <Box mr={2}>
                    {app.fullname && (
                      <Avatar
                        style={{ width: 30, height: 30 }}
                        src={`${API_STORAGE}/storage/api_logo?id=${app.client_id}`}
                        alt={app.fullname}
                      />
                    )}
                  </Box>
                  {app.fullname}
                </Box>
              </Panel.Cell>
              <Panel.Cell variant="actions">
                <Button
                  variant="white"
                  onClick={() =>
                    setRemoveAccessModalState({
                      isOpen: true,
                      appId: app.client_id,
                      appName: app.fullname,
                    })
                  }
                >
                  {t("WEB_SERVICE.REMOVE_ACCESS")}
                </Button>
              </Panel.Cell>
            </Panel.Row>
          ))}
        </Panel.Front>
      </Panel>

      {removeAccessModalState.isOpen ? (
        <Modal
          maxWidth="xs"
          title={t("DELETE_ITEM.ARE_YOU_SURE")}
          onCancel={() =>
            setRemoveAccessModalState({
              isOpen: false,
              appId: null,
              appName: null,
            })
          }
          onConfirm={() => {
            dispatch(removeAppAccess({ id: removeAccessModalState.appId }));
          }}
        >
          <Box p={3}>
            <Typography variant="body">
              {t("WEB_SERVICE.REMOVE_ACCESS_QUESTION", {
                fullname: removeAccessModalState.appName,
              })}
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

export default AppsPanel;
