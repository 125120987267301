import { useTranslation } from "react-i18next";
import { makeStyles, Divider, MenuItem, Select, SelectChangeEvent } from "@alohi/kit";

interface ExpirationSelectorProps {
  expiration: ExpirationEnum;
  onExpiration: (expiration: ExpirationEnum) => void;
}

export enum ExpirationEnum {
  ONE_WEEK = "oneWeek",
  THREE_MONTHS = "threeMonths",
  ONE_YEAR = "oneYear",
  CUSTOM = "custom",
  NO_EXPIRATION = "noExpiration",
}

function ExpirationSelector({ expiration, onExpiration }: ExpirationSelectorProps) {
  const { t } = useTranslation();

  const classes = useStyles();

  const onSelect = (event: SelectChangeEvent<unknown>) => {
    const newExpiration = event.target.value as ExpirationEnum;
    onExpiration(newExpiration);
  };

  return (
    <Select fullWidth value={expiration} onChange={onSelect}>
      <MenuItem className={classes.menuItem} value={ExpirationEnum.ONE_WEEK}>
        {t("API_TOKEN.EXPIRATION_ONE_WEEK")}
      </MenuItem>

      <MenuItem className={classes.menuItem} value={ExpirationEnum.THREE_MONTHS}>
        {t("API_TOKEN.EXPIRATION_THREE_MONTHS")}
      </MenuItem>

      <MenuItem className={classes.menuItem} value={ExpirationEnum.ONE_YEAR}>
        {t("API_TOKEN.EXPIRATION_ONE_YEAR")}
      </MenuItem>

      <Divider sx={{ my: 1 }} />

      <MenuItem className={classes.menuItem} value={ExpirationEnum.CUSTOM}>
        {t("API_TOKEN.EXPIRATION_CUSTOM")}
      </MenuItem>

      <Divider sx={{ my: 1 }} />

      <MenuItem className={classes.menuItem} value={ExpirationEnum.NO_EXPIRATION}>
        {t("API_TOKEN.EXPIRATION_NO_EXPIRATION")}
      </MenuItem>
    </Select>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  menuItem: {
    width: 250,
    overflow: "hidden",
    color: alohi.gray,
  },
}));

export default ExpirationSelector;
