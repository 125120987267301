import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, makeStyles, useMediaQuery, theme, alohi, Helper } from "@alohi/kit";
import clsx from "clsx";

import useBool from "hooks/useBool";
import { DigitFilter } from "components/Core/Filters";
import { getCreditDetails } from "stores/reducers/credit.reducer";
import { selectCreditRemainingPages } from "selectors/credit.selector";
import RemainingPagesModal from "views/RemainingPages/RemainingPagesModal";
import UpgradeEnterpriseModal from "views/UpgradeEnterprise/UpgradeEnterpriseModal";
import {
  selectPlanId,
  selectIsPlanInactive,
  selectPlanIsWaitingApproval,
} from "selectors/plan.selector";
import dataCy from "enums/dataCy";

interface PreviewRemainingPagesProps {
  className?: string;
}

function PreviewRemainingPages({ className }: PreviewRemainingPagesProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const currentPlanId = useSelector(selectPlanId);
  const isPlanInactive = useSelector(selectIsPlanInactive);
  const remainingPages = useSelector(selectCreditRemainingPages);
  const isWaitingApproval = useSelector(selectPlanIsWaitingApproval);

  const [openRemainingPages, setOpenRemainingPages] = useState(false);
  const [isUpgradeEnterpriseModalOpen, isUpgradeEnterpriseModalOpenBool] = useBool(false);

  useEffect(() => {
    // Whenever the current plan id changes, refresh the credit details since it requires the plan id as a parameter
    if (currentPlanId) {
      dispatch(getCreditDetails());
    }
  }, [currentPlanId, dispatch]);

  if (isWaitingApproval || isPlanInactive) {
    return null;
  }

  return (
    <Box className={clsx(classes.base, className)} data-cy={dataCy.planDetailsPanelRemainingPages}>
      {isMobileLayout ? (
        <Box className={classes.container}>
          <Typography type="panelCellTitle">{t("PROFILE.REMAINING_PAGES")}</Typography>
          <Box className={classes.row}>
            <Box className={classes.label}>
              {remainingPages >= 0 ? <DigitFilter value={remainingPages} /> : ""}
            </Box>
            <Helper
              variant="dark"
              className={classes.helper}
              onClick={() => setOpenRemainingPages(true)}
            />
          </Box>
        </Box>
      ) : (
        <Box className={classes.container}>
          <Box className={classes.row}>
            <Typography type="panelCellTitle">{t("PROFILE.REMAINING_PAGES")}</Typography>
            <Helper
              variant="dark"
              className={classes.helper}
              onClick={() => setOpenRemainingPages(true)}
            />
          </Box>
          <Box className={classes.label}>
            {remainingPages >= 0 ? <DigitFilter value={remainingPages} /> : ""}
          </Box>
        </Box>
      )}

      {openRemainingPages ? (
        <RemainingPagesModal handleClosure={() => setOpenRemainingPages(false)} />
      ) : null}

      {isUpgradeEnterpriseModalOpen ? (
        <UpgradeEnterpriseModal handleClosure={isUpgradeEnterpriseModalOpenBool.setFalse} />
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    //
  },
  container: {
    display: "flex",
    flexDirection: "column",
    [breakpoints.down("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    marginTop: spacing(0.5),
    [breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: spacing(0.5),
      color: alohi.neutral500,
    },
  },
  helper: {
    marginLeft: spacing(0.5),
    cursor: "pointer",
  },
}));

export default PreviewRemainingPages;
