import RS from "enums/requestStatus";
import { RootState } from "stores/store";
import i18n from "../i18n";

export const selectIsExportPageLoading = (state: RootState) =>
  state.dataExport.getExportStatus.status === RS.RUNNING &&
  !state.dataExport.getExportStatus.response;

export const selectIsExportDataForbidden = (state: RootState) =>
  Boolean(state.dataExport.getExportStatus.status === RS.ERROR);

export const selectIsStartFaxesExportSuccess = (state: RootState) =>
  Boolean(state.dataExport.startFaxesExport.response);

export const selectIsStartContactsExportSuccess = (state: RootState) =>
  Boolean(state.dataExport.startContactsExport.response);

export const selectIsStartFaxesExportRunning = (state: RootState) =>
  Boolean(state.dataExport.startFaxesExport.status === RS.RUNNING);

export const selectIsStartContactsRunning = (state: RootState) =>
  Boolean(state.dataExport.startContactsExport.status === RS.RUNNING);

export const selectStartFaxesExportError = (state: RootState) => {
  if (!state.dataExport.startFaxesExport.error) return null;
  switch (state.dataExport.startFaxesExport.error) {
    default:
      return i18n.t("COMMON.SERVER_ERROR");
  }
};

export const selectStartContactsExportError = (state: RootState) => {
  if (!state.dataExport.startContactsExport.error) return null;
  switch (state.dataExport.startContactsExport.error) {
    default:
      return i18n.t("COMMON.SERVER_ERROR");
  }
};

export const selectExportFaxesInfo = (state: RootState) => ({
  status: (() => {
    switch (state.dataExport.getExportStatus.response?.export_jobs["ExportJobType.FAXES"]?.status) {
      case "in_queue":
        return "IN_PROGRESS";
      case "finished":
        return "READY";
      default:
        return "EMPTY";
    }
  })(),
  files:
    state.dataExport.getExportStatus.response?.export_jobs["ExportJobType.FAXES"]
      ?.path_to_final_files,
  date: state.dataExport.getExportStatus.response?.export_jobs["ExportJobType.FAXES"]
    ?.creation_date,
});

export const selectExportContactsInfo = (state: RootState) => ({
  status: (() => {
    switch (
      state.dataExport.getExportStatus.response?.export_jobs["ExportJobType.CONTACTS"]?.status
    ) {
      case "in_queue":
        return "IN_PROGRESS";
      case "finished":
        return "READY";
      default:
        return "EMPTY";
    }
  })(),
  files:
    state.dataExport.getExportStatus.response?.export_jobs["ExportJobType.CONTACTS"]
      ?.path_to_final_files,
  date: state.dataExport.getExportStatus.response?.export_jobs["ExportJobType.CONTACTS"]
    ?.creation_date,
});
