import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Checkbox, Button, makeStyles, Box, useSnackbar } from "@alohi/kit";

import { websiteUrls } from "routes/urls";
import { randomPassword } from "helpers/string";
import { Typography, Avatar, FormControlLabel } from "ui";
import defaultAvatar from "assets/images/avatar-small.png";
import { selectRegisterError } from "selectors/authentication.selector";
import { clearRegister, register } from "stores/reducers/authentication.reducer";

const SignupByProvider = ({
  newUserInfo,
  provider,
  referrerUid,
  invitationToken,
  handleClosure,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const registerError = useSelector(selectRegisterError);

  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSignup = useCallback(() => {
    setIsLoading(true);
    dispatch(
      register({
        name: newUserInfo.name,
        lastname: newUserInfo.lastname,
        email: newUserInfo.email,
        password: randomPassword(20, "aA#!"),
        provider,
        provider_token: newUserInfo.token,
        ...(referrerUid && { referrer_uid: referrerUid }),
        ...(invitationToken && {
          invitation_token: invitationToken,
          account_type: "corporate_member",
        }),
      }),
    );
  }, [
    dispatch,
    invitationToken,
    newUserInfo.email,
    newUserInfo.lastname,
    newUserInfo.name,
    newUserInfo.token,
    provider,
    referrerUid,
  ]);

  useEffect(() => {
    if (registerError) {
      enqueueSnackbar(registerError, { variant: "error" });
      dispatch(clearRegister());
      setIsLoading(false);
      handleClosure();
    }
  }, [dispatch, enqueueSnackbar, handleClosure, registerError, t]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={4} px={8}>
      <Typography variant="h5">Hi {newUserInfo.name}</Typography>
      <Typography>Please sign up to start using Fax.Plus.</Typography>
      <Avatar src={newUserInfo.imageUrl ?? defaultAvatar} className={classes.avatar} />
      <Typography className={classes.email}>{newUserInfo.email}</Typography>
      <FormControlLabel
        control={
          <Checkbox
            value={agree}
            onChange={() => {
              setAgree(!agree);
            }}
          />
        }
        label={
          <Typography>
            I agree to Fax.Plus{" "}
            <a target="_blank" rel="noopener noreferrer" href={websiteUrls.terms}>
              Terms
            </a>
          </Typography>
        }
      />
      <Button
        fullWidth
        size="large"
        isDisabled={!agree || isLoading}
        onClick={handleSignup}
        isLoading={isLoading}
        sx={{ mt: 2 }}
      >
        <Typography variant="loginButton">{t("COMMON.SIGN_UP")}</Typography>
      </Button>
    </Box>
  );
};

SignupByProvider.propTypes = {
  newUserInfo: PropTypes.shape({
    name: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    imageUrl: PropTypes.string,
    token: PropTypes.string,
  }),
  provider: PropTypes.oneOf(["google", "microsoft"]).isRequired,
  referrerUid: PropTypes.string,
  invitationToken: PropTypes.string,
  handleClosure: PropTypes.func,
};

const useStyles = makeStyles({
  avatar: {
    height: 145,
    width: 145,
    margin: "70px auto 10px auto",
  },
  email: {
    marginBottom: 50,
  },
});

export default SignupByProvider;
