import { useSnackbar } from "@alohi/kit";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { NumberBundleDocument } from "api/types/numbers";
import FormRow from "components/Forms/FormRow";
import FormRowReactSelectInput from "components/Forms/FormRowReactSelectInput";
import { OptionArea } from "components/Payment/CustomizedSelect";
import { bundleStatuses } from "enums/bundle";
import { getI18nCountry } from "helpers/numbers";
import { capitalizeString } from "helpers/string";
import {
  selectAddressBundles,
  selectAddresses,
  selectApprovedDocuments,
  selectPendingDocuments,
} from "selectors/address.selector";
import {
  selectAreaForCountry,
  selectIsAddressRequired,
  selectIsBundleRequired,
  selectIsBundleRequiredToBeCreated,
} from "selectors/plan.selector";
import {
  selectSelectedNumberCountry,
  selectSelectedNumberCountryRequirePhoneType,
  selectSelectedNumberItemType,
  selectVerifyNumber,
} from "selectors/verifications.selector";
import { getAddresses, getBundles } from "stores/reducers/address.reducer";
import { assignBundleToNumber } from "stores/reducers/verifications.reducer";
import { useTheme } from "ui";
import AddressesSelect from "views/CreateAddressBundle/components/BundlePersonalInformation/components/AddressesSelect";

import BundleLabel from "./components/BundleLabel";

function AddressVerification() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedArea = useSelector(selectAreaForCountry);
  const addressBundles = useSelector(selectAddressBundles);
  const country = useSelector(selectSelectedNumberCountry);
  const numberType = useSelector(selectSelectedNumberItemType);
  const isBundleRequired = useSelector(selectIsBundleRequired);
  const pendingDocuments: NumberBundleDocument[] = useSelector(selectPendingDocuments);
  const approvedDocuments: NumberBundleDocument[] = useSelector(selectApprovedDocuments);
  const isAddressRequired = useSelector(selectIsAddressRequired);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);
  const addresses = useSelector(selectAddresses);
  const selectedNumber = useSelector(selectVerifyNumber);
  const selectedNumberCountryRequirePhoneType = useSelector(
    selectSelectedNumberCountryRequirePhoneType,
  );

  // This state will be kept for UI only, and not passed to the backend.
  // The requirement is to have at least one address from the current coutnry selected
  const [address, setAddress] = useState("");

  const [selectedBundle, setSelectedBundle] = useState<NumberBundleDocument>();

  const getNumberTypeLabel = useCallback(
    (type: string | undefined) => {
      switch (type) {
        case "local":
          return t("ADD_NUMBER.LOCAL");
        case "tollfree":
          return t("ADD_NUMBER.TOLL_FREE");
        default:
          return type ?? "";
      }
    },
    [t],
  );

  const handleBundleChange = useCallback(
    (bundle) => {
      const selectedBundle = pendingDocuments.find(({ sid }) => bundle.value === sid);
      if (selectedBundle?.status === bundleStatuses.pendingReview) {
        enqueueSnackbar(t("ADDRESS.NO_PENDING"), {
          variant: "info",
        });
      } else {
        const selected = approvedDocuments.find(({ sid }) => bundle.value === sid);
        if (selectedNumberCountryRequirePhoneType && selected?.number_type !== numberType) {
          enqueueSnackbar(
            t("ADDRESS.WRONG_BUNDLE_TYPE", {
              value_1: getNumberTypeLabel(selected?.number_type),
              value_2: getNumberTypeLabel(numberType),
            }),
            {
              variant: "info",
            },
          );
          return;
        }
        setSelectedBundle(selected);
        if (selectedNumber) {
          /* eslint-disable-next-line */
          // @ts-ignore
          dispatch(assignBundleToNumber({ numberId: selectedNumber.id, bundleId: selected?.sid }));
        }
      }
    },
    [
      approvedDocuments,
      dispatch,
      enqueueSnackbar,
      getNumberTypeLabel,
      numberType,
      pendingDocuments,
      selectedNumber,
      selectedNumberCountryRequirePhoneType,
      t,
    ],
  );

  const bundleOptions = useMemo(() => {
    const options: { value: string; label: ReactNode }[] = [];
    if (approvedDocuments) {
      approvedDocuments.forEach((bundle) => {
        if (bundle.iso_country === country) {
          options.push({
            value: bundle.sid,
            label: <BundleLabel bundle={bundle} hasSubmitDate />,
          });
        }
      });
    }
    if (pendingDocuments) {
      pendingDocuments.forEach((bundle) => {
        if (bundle.iso_country === country) {
          options.push({
            value: bundle.sid,
            label: <BundleLabel bundle={bundle} hasSubmitDate />,
          });
        }
      });
    }
    return options;
  }, [approvedDocuments, pendingDocuments, country]);

  useEffect(() => {
    if (country && numberType && selectedArea) {
      // If the country changed we reset the selected address
      // A new filtering of all the available countries will be made
      // If found, AddressesSelect will setAddress with the new value
      setAddress("");
    }
  }, [dispatch, country, numberType, selectedArea]);

  useEffect(() => {
    if (!addressBundles) {
      dispatch(getBundles());
    }
  }, [addressBundles, dispatch]);

  useEffect(() => {
    if (!addresses) {
      dispatch(getAddresses());
    }
  }, [addresses, dispatch]);

  useEffect(() => {
    // Only for UK regulation, the bundle needs to be associated with the phone type
    if (selectedNumberCountryRequirePhoneType) {
      setSelectedBundle(
        approvedDocuments?.find((approvedDocument) => approvedDocument.number_type === numberType),
      );
    } else {
      setSelectedBundle(approvedDocuments?.[0]);
    }
  }, [approvedDocuments, numberType, selectedNumberCountryRequirePhoneType]);

  // No bundle was created yet for the selected country
  if (isBundleRequiredToBeCreated) {
    return (
      <FormRow
        labelGridWidth={4}
        label={<FontAwesomeIcon icon={faExclamationTriangle} size="lg" color={theme.alohi.gold} />}
      >
        {t("ADDRESS.REQUIRE_PROOF_OF_ID", {
          numberType: capitalizeString(numberType),
          country: getI18nCountry(country)?.label ?? "",
        })}
      </FormRow>
    );
  }

  // Only address is required to be selected or created if there is none for the selected country
  if (isAddressRequired) {
    return (
      <AddressesSelect
        value={address}
        country={country}
        labelGridWidth={5}
        handleClosure={setAddress}
        label={t("ADDRESS.TITLE")}
      />
    );
  }
  // Render this if we have any documents and the bundle is required
  if ((pendingDocuments?.length >= 1 || approvedDocuments?.length >= 1) && isBundleRequired) {
    return (
      <>
        <FormRowReactSelectInput
          id="select-bundle"
          labelGridWidth={5}
          label={t("ADDRESS.REGULATORY_DOCUMENTS")}
          ReactSelectProps={{
            value: selectedBundle
              ? {
                  value: selectedBundle.sid,
                  label: <BundleLabel bundle={selectedBundle} />,
                }
              : null,
            maxMenuHeight: 250,
            isSearchable: true,
            options: bundleOptions,
            onChange: handleBundleChange,
            menuPortalTarget: document.body,
            components: { Option: OptionArea },
          }}
        />
      </>
    );
  }

  return null;
}

export default memo(AddressVerification);
