import PropTypes from "prop-types";
import { NavigationModal } from "@alohi/kit";
import { useCallback, useEffect } from "react";

import { usePhoneVerificationApi } from "./context/api";
import { PhoneVerificationContextProvider } from "./context/context";
import PhoneVerificationEnterCode from "./components/PhoneVerificationEnterCode";
import PhoneVerificationEnterPhone from "./components/PhoneVerificationEnterPhone";
import PhoneVerificationUsernameSent from "./components/PhoneVerificationUsernameSent";
import PhoneVerificationDuplicatePhone from "./components/PhoneVerificationDuplicatePhone";
import PhoneVerificationForgotUsername from "./components/PhoneVerificationForgotUsername";

export const VIEWS = {
  ENTER_CODE: "enterCode",
  ENTER_PHONE: "enterPhone",
  USERNAME_SENT: "usernameSent",
  DUPLICATE_PHONE: "duplicatePhone",
  FORGOT_USERNAME: "forgotUsername",
};

function PhoneVerificationModal({ defaultPhoneNumber = "", handleClosure }) {
  const { updateStore } = usePhoneVerificationApi();

  const handleUsernameSent = useCallback(
    (closure) => {
      if (defaultPhoneNumber !== "") {
        handleClosure(closure);
      }
    },
    [defaultPhoneNumber, handleClosure],
  );

  const onClose = () => {
    // No closure. The user must verify his number
  };

  useEffect(() => {
    if (defaultPhoneNumber) {
      updateStore({
        phoneNumber: defaultPhoneNumber,
      });
    }
  }, [defaultPhoneNumber, updateStore]);

  return (
    <PhoneVerificationContextProvider>
      <NavigationModal
        settings={{
          maxWidth: "xs",
        }}
        onClose={onClose}
        stepId={VIEWS.ENTER_PHONE}
        steps={{
          [VIEWS.ENTER_PHONE]: {
            id: VIEWS.ENTER_PHONE,
            component: (
              <PhoneVerificationEnterPhone
                onCancel={handleClosure}
                defaultPhoneNumber={defaultPhoneNumber}
              />
            ),
          },
          [VIEWS.ENTER_CODE]: {
            id: VIEWS.ENTER_CODE,
            component: <PhoneVerificationEnterCode onSuccess={handleClosure} />,
          },
          [VIEWS.DUPLICATE_PHONE]: {
            id: VIEWS.DUPLICATE_PHONE,
            component: <PhoneVerificationDuplicatePhone />,
          },
          [VIEWS.FORGOT_USERNAME]: {
            id: VIEWS.FORGOT_USERNAME,
            component: <PhoneVerificationForgotUsername />,
          },
          [VIEWS.USERNAME_SENT]: {
            id: VIEWS.USERNAME_SENT,
            component: <PhoneVerificationUsernameSent onConfirm={handleUsernameSent} />,
          },
        }}
      />
    </PhoneVerificationContextProvider>
  );
}

PhoneVerificationModal.propTypes = {
  defaultPhoneNumber: PropTypes.string,
  handleClosure: PropTypes.func.isRequired,
  defaultView: PropTypes.oneOf(Object.values(VIEWS)),
};

export default PhoneVerificationModal;
