import { fileToFaxFile } from "helpers/file";
import { faxAllowedFileTypes, faxAllowedMimeTypes } from "enums/allowedFileTypes";
import FileUploadOptions, {
  FileUploadOptionsProps,
} from "views/Common/components/FileUploadOptions";
import { FaxFile } from "../contexts/store";
import { useSendFaxContext } from "../contexts/context";
import { useSendFaxHelpers } from "../contexts/helpers";

function MenuItems(props: Partial<FileUploadOptionsProps>) {
  const { addFiles } = useSendFaxHelpers();

  const { sendFaxDispatch } = useSendFaxContext();

  function onLoading(isLoading: boolean) {
    sendFaxDispatch({
      type: "SET_IS_OVERLAY_OPEN",
      payload: isLoading,
    });
  }

  async function onAddDocuments(documents: FileList | File[]) {
    try {
      const filesArray = Array.from(documents).reduce<Promise<FaxFile>[]>(
        (accumulator, currentValue) => {
          accumulator.push(fileToFaxFile(currentValue));
          return accumulator;
        },
        [],
      );
      const resolvedDocuments = await Promise.all(filesArray);

      addFiles(resolvedDocuments);
    } catch {
      // Nothing to do with the error
    }
  }

  return (
    <FileUploadOptions
      onLoading={onLoading}
      onAddDocuments={onAddDocuments}
      allowedMimes={faxAllowedMimeTypes}
      allowedFileTypes={faxAllowedFileTypes}
      {...props}
    />
  );
}

export default MenuItems;
