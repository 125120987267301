import { memo, useState } from "react";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, Typography, FaButton } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { faEye, faEyeSlash, faInbox, faTrash } from "@fortawesome/pro-light-svg-icons";

import { boxNames } from "enums/faxes";
import { selectIdsOfCheckedCdrs } from "selectors/faxes.selector";
import ConfirmDeleteFaxModal from "views/Faxes/ConfirmDeleteFaxModal";
import { faxesAsyncActions, setAllCdrCheckbox } from "stores/reducers/faxes.reducer";

function FaxesBulkActions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    params: { boxName = boxName.inbox },
  } = useRouteMatch();

  const idsOfCheckedCdrs = useSelector(selectIdsOfCheckedCdrs(boxName));

  const [confirmDeleteFaxDialog, setConfirmDeleteFaxModal] = useState({
    isOpen: false,
    cdrIds: null,
  });

  let actionButtons;
  switch (boxName) {
    case boxNames.inbox:
      actionButtons = (
        <>
          <FaButton
            variant={"light"}
            tooltip={t("COMMON.DELETE")}
            icon={faTrash}
            onClick={() => {
              dispatch(
                faxesAsyncActions.moveToTrash({
                  boxName: boxName,
                  cdrIds: idsOfCheckedCdrs,
                }),
              );
            }}
            sx={{ mr: 1 }}
          />
          <FaButton
            variant={"light"}
            tooltip={t("FAXES.READ")}
            icon={faEye}
            onClick={() => {
              dispatch(
                faxesAsyncActions.markAsRead({
                  boxName: boxName,
                  cdrIds: idsOfCheckedCdrs,
                }),
              );
              dispatch(setAllCdrCheckbox({ boxName, value: false }));
            }}
            sx={{ mr: 1 }}
          />
          <FaButton
            variant={"light"}
            tooltip={t("FAXES.UNREAD")}
            icon={faEyeSlash}
            onClick={() => {
              dispatch(
                faxesAsyncActions.markAsUnread({
                  boxName: boxName,
                  cdrIds: idsOfCheckedCdrs,
                }),
              );
              dispatch(setAllCdrCheckbox({ boxName, value: false }));
            }}
          />
        </>
      );

      break;
    case boxNames.sentbox:
      actionButtons = (
        <FaButton
          variant={"light"}
          tooltip={t("COMMON.DELETE")}
          icon={faTrash}
          onClick={() => {
            dispatch(
              faxesAsyncActions.moveToTrash({
                boxName: boxName,
                cdrIds: idsOfCheckedCdrs,
              }),
            );
          }}
        />
      );
      break;
    case boxNames.trashbox:
      actionButtons = (
        <>
          <FaButton
            variant={"light"}
            tooltip={t("COMMON.DELETE")}
            icon={faTrash}
            onClick={() => {
              setConfirmDeleteFaxModal({
                isOpen: true,
                cdrIds: idsOfCheckedCdrs,
              });
            }}
            sx={{ mr: 1 }}
          />
          <FaButton
            variant={"light"}
            tooltip={t("COMMON.RESTORE")}
            icon={faInbox}
            onClick={() => {
              dispatch(
                faxesAsyncActions.restore({
                  boxName: boxNames.trashbox,
                  cdrIds: idsOfCheckedCdrs,
                }),
              );
            }}
          />
        </>
      );
      break;
    case boxNames.outbox:
      actionButtons = (
        <FaButton
          variant={"light"}
          tooltip={t("COMMON.DELETE")}
          icon={faTrash}
          onClick={() => {
            setConfirmDeleteFaxModal({
              isOpen: true,
              cdrIds: idsOfCheckedCdrs,
            });
          }}
        />
      );
      break;
    default:
      actionButtons = null;
  }

  return idsOfCheckedCdrs?.length > 0 ? (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography type="panelFrontHeader" sx={{ "&&": { mr: 1 } }}>
          Apply to selection:
        </Typography>
        {actionButtons}
      </Box>

      {confirmDeleteFaxDialog.isOpen && (
        <ConfirmDeleteFaxModal
          boxName={boxName}
          cdrIds={confirmDeleteFaxDialog.cdrIds}
          handleClosure={() =>
            setConfirmDeleteFaxModal({
              isOpen: false,
              cdrIds: null,
            })
          }
        />
      )}
    </>
  ) : null;
}

export default memo(FaxesBulkActions);
