import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { accountsApi } from "api";
import { RootState } from "stores/store";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";
import {
  BlacklistContact,
  UpdateContactResponse,
  CreateContactResponse,
  DefaultRequestOptions,
  transformBlacklistContacts,
  GetBlacklistContactsResponse,
  revertTransformBlacklistContact,
} from "./contacts.helpers";

const defaultRequestOptions = {
  limit: 10,
  offset: 0,
  shared: false,
  search: "",
  sortBy: "",
  type: "json",
  direction: "",
};

const initialState = {
  getBlacklistContacts: InitialRequestState(),
  createBlacklistContact: InitialRequestState(),
  updateBlacklistContact: InitialRequestState(),
  deleteBlacklistContacts: InitialRequestState(),
  getMoreBlacklistContacts: InitialRequestState(),

  hasMore: false,
  selection: [] as string[],
  contacts: null as null | BlacklistContact[],
  lastRequestOptions: defaultRequestOptions,
};

export const clearBlacklistContacts = createAction("BLACKLIST/CLEAR");
export const updateBlacklistContactsSelection = createAction<string[]>(
  "BLACKLIST/UPDATE_SELECTION",
);
export const clearUpdateBlacklist = createAction("BLACKLIST/CLEAR_UPDATE");
export const clearCreateBlacklist = createAction("BLACKLIST/CLEAR_CREATE");

const getBlacklistContacts = createAsyncThunk<
  GetBlacklistContactsResponse,
  Partial<DefaultRequestOptions>,
  { state: RootState; rejectValue: FetchError }
>(
  "BLACKLIST/GET",
  async (requestOptionsOverrides = {}, { getState, rejectWithValue }) => {
    try {
      const lastRequestOptions = getState().blacklist.lastRequestOptions;
      const requestOptions = { ...lastRequestOptions, ...requestOptionsOverrides };

      const response = await accountsApi.getBlackList(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }: { getState: () => RootState }) => {
      return getState().blacklist.getBlacklistContacts.status !== RS.RUNNING;
    },
  },
);

const getMoreBlacklistContacts = createAsyncThunk<
  GetBlacklistContactsResponse,
  undefined,
  { state: RootState; rejectValue: FetchError }
>(
  "BLACKLIST/GET_MORE",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { contacts, lastRequestOptions } = getState().blacklist;
      const requestOptions = {
        ...lastRequestOptions,
        offset: contacts?.length ?? 0,
      };
      const response = await accountsApi.getBlackList(requestOptions);
      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().blacklist.getMoreBlacklistContacts.status !== RS.RUNNING;
    },
  },
);

const createBlacklistContact = createAsyncThunk<
  CreateContactResponse,
  { newContact: Partial<BlacklistContact> },
  { state: RootState; rejectValue: FetchError }
>("BLACKLIST/CREATE", async ({ newContact }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.createBlackList(revertTransformBlacklistContact(newContact));
    return { response };
  } catch (error) {
    const errorValue = error as FetchError;
    Object.assign(errorValue, { newContact });
    return rejectWithValue(errorValue);
  }
});

const updateBlacklistContact = createAsyncThunk<
  UpdateContactResponse,
  { contactDiffs: Partial<BlacklistContact> },
  { state: RootState; rejectValue: FetchError }
>("BLACKLIST/UPDATE", async ({ contactDiffs }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.updateBlackList(
      revertTransformBlacklistContact(contactDiffs),
    );
    return { response, contactDiffs };
  } catch (error) {
    const errorValue = error as FetchError;
    Object.assign(errorValue, { contactDiffs });
    return rejectWithValue(errorValue);
  }
});

const deleteBlacklistContacts = createAsyncThunk<
  { idsToDelete: string[] },
  { idsToDelete: string[] },
  { state: RootState; rejectValue: FetchError }
>("BLACKLIST/DELETE", async ({ idsToDelete }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.deleteBlackList(idsToDelete);
    return { response, idsToDelete };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const { reducer } = createSlice({
  name: "BLACKLIST",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateBlacklistContactsSelection, (state, { payload }) => {
      state.selection = payload;
    });

    builder.addCase(getBlacklistContacts.pending, (state) => {
      state.getBlacklistContacts.status = RS.RUNNING;
      state.getBlacklistContacts.error = null;
    });
    builder.addCase(getBlacklistContacts.fulfilled, (state, { payload }) => {
      state.getBlacklistContacts.status = RS.IDLE;
      state.getBlacklistContacts.response = payload.response;

      state.contacts = transformBlacklistContacts(payload.response.result);
      state.hasMore = payload.response.has_more;
      state.lastRequestOptions = { ...state.lastRequestOptions, ...payload.requestOptions };
    });
    builder.addCase(getBlacklistContacts.rejected, (state, { payload }) => {
      state.getBlacklistContacts.status = RS.ERROR;
      state.getBlacklistContacts.error = payload;
    });

    builder.addCase(getMoreBlacklistContacts.pending, (state) => {
      state.getMoreBlacklistContacts.status = RS.RUNNING;
      state.getMoreBlacklistContacts.error = null;
    });
    builder.addCase(getMoreBlacklistContacts.fulfilled, (state, { payload }) => {
      state.getMoreBlacklistContacts.status = RS.IDLE;
      state.getMoreBlacklistContacts.response = payload.response;

      if (state.contacts) {
        state.contacts.push(...transformBlacklistContacts(payload.response.result));
        // Reset the indexes
        state.contacts = state.contacts.map((rest, index) => ({ ...rest, index }));
      } else {
        state.contacts = transformBlacklistContacts(payload.response.result);
      }

      state.hasMore = payload.response.has_more;
      state.lastRequestOptions = { ...state.lastRequestOptions, ...payload.requestOptions };
    });
    builder.addCase(getMoreBlacklistContacts.rejected, (state, { payload }) => {
      state.getMoreBlacklistContacts.status = RS.ERROR;
      state.getMoreBlacklistContacts.error = payload;
    });

    builder.addCase(createBlacklistContact.pending, (state) => {
      state.createBlacklistContact.status = RS.RUNNING;
      state.createBlacklistContact.error = null;
    });
    builder.addCase(createBlacklistContact.fulfilled, (state, { payload }) => {
      state.createBlacklistContact.status = RS.IDLE;
      state.createBlacklistContact.response = payload.response;
      state.contacts = null; // Refresh the list
      state.hasMore = false;
    });
    builder.addCase(createBlacklistContact.rejected, (state, { payload }) => {
      state.createBlacklistContact.status = RS.ERROR;
      state.createBlacklistContact.error = payload;
    });
    builder.addCase(clearCreateBlacklist, (state) => {
      state.createBlacklistContact.status = RS.IDLE;
      state.createBlacklistContact.response = null;
      state.createBlacklistContact.error = null;
    });

    builder.addCase(updateBlacklistContact.pending, (state) => {
      state.updateBlacklistContact.status = RS.RUNNING;
      state.updateBlacklistContact.error = null;
    });
    builder.addCase(updateBlacklistContact.fulfilled, (state, { payload }) => {
      state.updateBlacklistContact.status = RS.IDLE;
      state.updateBlacklistContact.response = payload.response;

      const indexToEdit = state.contacts?.findIndex(
        (contact) => contact.id === payload.contactDiffs.id,
      );

      if (indexToEdit !== undefined && indexToEdit !== -1 && state.contacts) {
        const newContact = {
          ...state.contacts[indexToEdit],
          ...payload.contactDiffs,
          index: indexToEdit,
        } as BlacklistContact;

        state.contacts[indexToEdit] = newContact;
      }
    });
    builder.addCase(updateBlacklistContact.rejected, (state, { payload }) => {
      state.updateBlacklistContact.status = RS.ERROR;
      state.updateBlacklistContact.error = payload;
    });
    builder.addCase(clearUpdateBlacklist, (state) => {
      state.updateBlacklistContact.status = RS.IDLE;
      state.updateBlacklistContact.response = null;
      state.updateBlacklistContact.error = null;
    });

    builder.addCase(deleteBlacklistContacts.pending, (state) => {
      state.deleteBlacklistContacts.status = RS.RUNNING;
      state.deleteBlacklistContacts.error = null;
    });
    builder.addCase(deleteBlacklistContacts.fulfilled, (state, { payload }) => {
      state.deleteBlacklistContacts.status = RS.IDLE;

      if (state.contacts) {
        state.contacts = state.contacts.filter(
          (contact) => !payload.idsToDelete.includes(contact.id),
        );
        // Reset the indexes
        state.contacts = state.contacts.map((rest, index) => ({ ...rest, index }));
      }
    });

    builder.addCase(clearBlacklistContacts, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export {
  getBlacklistContacts,
  createBlacklistContact,
  updateBlacklistContact,
  deleteBlacklistContacts,
  getMoreBlacklistContacts,
};

export default reducer;
