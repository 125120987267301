export enum RequestStatus {
  IDLE = "IDLE",
  ERROR = "ERROR",
  RUNNING = "RUNNING",
  NO_CONTENT = "NO_CONTENT",
}

export default {
  IDLE: RequestStatus.IDLE,
  ERROR: RequestStatus.ERROR,
  RUNNING: RequestStatus.RUNNING,
  NO_CONTENT: RequestStatus.NO_CONTENT,
};

export type FetchError =
  | {
      message: string;
      status: string;
      code: number;
      description: string;
      reason: string;
      origin: Record<string, unknown>;
    }
  | Record<string, unknown>;

export function InitialRequestState<R = unknown>(): {
  status: RequestStatus;
  response: R | null;
  error: FetchError | null | undefined;
} {
  return {
    status: RequestStatus.IDLE,
    response: null,
    error: null,
  };
}

export const initialRequestState: {
  status: RequestStatus;
  response: unknown;
  error: unknown;
} = {
  status: RequestStatus.IDLE,
  response: null,
  error: null,
};
