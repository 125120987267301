import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Box } from "@alohi/kit";

import { selectCanIEditSlackNotifications } from "selectors/ability.selector";

function SlackNotificationsRequiresActivation({ handleClosure }) {
  const { t } = useTranslation();
  const canIEditSlackNotifications = useSelector(selectCanIEditSlackNotifications);

  return (
    <Modal
      maxWidth="xs"
      onConfirm={handleClosure}
      title={t("SLACK.ACTIVATION")}
      confirmTitle={t("FORMS.DONE")}
    >
      <Box p={3}>
        <Typography variant="body">
          {canIEditSlackNotifications ? t("SLACK.LINK_WITH_APPS") : t("SLACK.CONTACT_ADMIN")}
        </Typography>
      </Box>
    </Modal>
  );
}

SlackNotificationsRequiresActivation.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default SlackNotificationsRequiresActivation;
