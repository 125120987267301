import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import RS, { FetchError, initialRequestState } from "enums/requestStatus";
import { chargeApi } from "api";
import { RootState } from "../store";

const initialChargeAmountState = {
  chargeAmount: {
    ...initialRequestState,
  },
  purchasedNumbers: null as null | object,
};

export const clearChargeAmount = createAction("PAYMENT/CLEAR_CHARGE_AMOUNT");

export const clearPurchasedNumbers = createAction("PAYMENT/CLEAR_PURCHASED_NUMBERS");

const chargeAmount = createAsyncThunk<unknown, { cartId: string; isAsync: boolean }>(
  "PAYMENT/CHARGE_AMOUNT",
  async ({ cartId, isAsync }, { rejectWithValue }) => {
    try {
      const isMocked = localStorage.getItem("chargeMocked") === "true";
      if (isMocked) {
        const customError = {
          origin: {
            reason: "do_not_honor",
          },
        };
        throw customError;
      }
      const response = await chargeApi.post({ cartId, isAsync });
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const chargeAmountSlice: unknown = {
  [clearPurchasedNumbers as never]: (state: RootState["payment"]) => {
    state.purchasedNumbers = null;
  },
  [clearChargeAmount as never]: (state: RootState["payment"]) => {
    state.chargeAmount.response = null;
    state.chargeAmount.status = RS.IDLE;
    state.chargeAmount.error = null;
  },
  [chargeAmount.pending as never]: (state: RootState["payment"]) => {
    state.chargeAmount.response = null;
    state.chargeAmount.status = RS.RUNNING;
    state.chargeAmount.error = null;
  },
  [chargeAmount.fulfilled as never]: (
    state: RootState["payment"],
    action: { payload: unknown },
  ) => {
    state.chargeAmount.response = action.payload;
    state.chargeAmount.status = RS.IDLE;
    state.chargeAmount.error = null;
    if (
      action.payload &&
      typeof action.payload === "object" &&
      "purchased_numbers" in action.payload &&
      typeof action.payload.purchased_numbers === "object"
    ) {
      state.purchasedNumbers = action.payload.purchased_numbers;
    }
  },
  [chargeAmount.rejected as never]: (
    state: RootState["payment"],
    action: { payload: FetchError["origin"] },
  ) => {
    state.chargeAmount.response = null;
    state.chargeAmount.status = RS.ERROR;
    state.chargeAmount.error = action.payload;
  },
};

export { initialChargeAmountState, chargeAmountSlice, chargeAmount };
