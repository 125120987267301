import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const getDefaultPaymentMethod = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/credit_cards/default`;
  return fetchWithRefresh(path, requestOptions);
};

const getPaymentMethods = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/credit_cards`;
  return fetchWithRefresh(path, requestOptions);
};

const post = (data) => {
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_ESHOP}/credit_cards`;
  return fetchWithRefresh(path, requestOptions);
};

const patch = (data) => {
  const requestOptions = {
    method: "PATCH",
    body: data,
  };
  const path = `${API_ESHOP}/credit_cards`;
  return fetchWithRefresh(path, requestOptions);
};

const deleteCard = (card_id) => {
  const requestOptions = {
    method: "DELETE",
  };
  const path = `${API_ESHOP}/credit_cards/${card_id}`;
  return fetchWithRefresh(path, requestOptions);
};

export const creditCardsApi = {
  post,
  patch,
  deleteCard,
  getPaymentMethods,
  getDefaultPaymentMethod,
};
