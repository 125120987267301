import { useOnMount } from "@alohi/kit";
import { useUnleashContext } from "@unleash/proxy-client-react";

import { useAppSelector } from "stores/store";
import { selectAccountUid } from "selectors/account.selector";

function useUpdateUnleashContext() {
  const uid = useAppSelector(selectAccountUid);

  const updateContext = useUnleashContext();

  useOnMount(async () => {
    try {
      await updateContext({ userId: uid });
    } catch {
      // Nothing to do
    }
  }, Boolean(uid));
}

export default useUpdateUnleashContext;
