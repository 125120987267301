import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { setTitle } from "../stores/reducers/layout.reducer";

const useTitle = (title) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateTitle = useCallback(
    (title) => {
      dispatch(setTitle(title));
      document.title = title ? `${title} - ${t("COMMON.BRAND")}` : t("COMMON.BRAND");
    },
    [dispatch, t],
  );

  useEffect(() => {
    updateTitle(title);
    return () => dispatch(setTitle(""));
  }, [dispatch, title, updateTitle]);

  return { updateTitle };
};

export default useTitle;
