import queryString from "query-string";
import { API_AFFOGATO, API_ESHOP } from "config";
import { fetchWithRefresh } from "../helpers";

const email = (email, code, token) => {
  const body = token ? { token } : { email, code };

  const requestOptions = {
    method: "POST",
    body,
  };

  const path = `${API_AFFOGATO}/email_verification`;
  return fetchWithRefresh(path, requestOptions);
};

const resendEmailCode = () => {
  const requestOptions = {
    method: "GET",
  };

  const path = `${API_AFFOGATO}/resend_email_verification`;
  return fetchWithRefresh(path, requestOptions);
};

const refund = (planId) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/refund/${planId}`;
  return fetchWithRefresh(path, requestOptions);
};

const geoLocation = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/location`;
  return fetchWithRefresh(path, requestOptions);
};

const verifyNumber = ({
  action,
  planId,
  areaCode,
  numberType,
  countryCode,
  verificationType,
} = {}) => {
  const requestOptions = {
    method: "GET",
  };
  const qs = {
    country_code: countryCode,
    [action === "corporate_number" ? "number_type" : "type"]: numberType,
  };
  if (areaCode) {
    qs.area_code = areaCode;
  }
  if (planId) {
    qs.plan_id = planId;
  }
  const path = `${API_ESHOP}/${action}/${verificationType}?` + queryString.stringify(qs);

  return fetchWithRefresh(path, requestOptions);
};

const verifyCustomNumber = ({
  action,
  planId,
  areaCode,
  contains,
  numberType,
  countryCode,
  verificationType,
} = {}) => {
  const requestOptions = {
    method: "GET",
  };
  const qs = {
    country_code: countryCode,
    [action === "corporate_number" ? "number_type" : "type"]: numberType,
  };
  if (areaCode) {
    qs.area_code = areaCode;
  }
  if (planId) {
    qs.plan_id = planId;
  }
  if (contains) {
    qs.contains = contains;
  }
  const path = `${API_ESHOP}/${action}/${verificationType}?` + queryString.stringify(qs);

  return fetchWithRefresh(path, requestOptions);
};

const getAvailableCurrencies = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/available_currencies`;
  return fetchWithRefresh(path, requestOptions);
};

const calculatePage = ({ number }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/calculate_page/${number}`;
  return fetchWithRefresh(path, requestOptions);
};

const getTimezones = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/timezones`;
  return fetchWithRefresh(path, requestOptions);
};

const verifyBulkNumbers = ({ amount, planId, areaCode, numberType, countryCode }) => {
  const requestOptions = {
    method: "POST",
    body: {
      amount: +amount,
      plan_id: planId,
      number_type: numberType,
      country_code: countryCode,
    },
  };
  if (areaCode) {
    requestOptions.body["area_code"] = areaCode;
  }
  const path = `${API_ESHOP}/corporate_number`;

  return fetchWithRefresh(path, requestOptions);
};

const assignBundleToNumber = ({ numberId, bundleId }) => {
  const requestOptions = {
    method: "POST",
    body: {
      number_id: numberId,
      bundle_id: bundleId,
    },
  };
  const path = `${API_ESHOP}/number/assign_bundle`;
  return fetchWithRefresh(path, requestOptions);
};

export const verifications = {
  email,
  refund,
  geoLocation,
  verifyNumber,
  getTimezones,
  calculatePage,
  resendEmailCode,
  verifyBulkNumbers,
  verifyCustomNumber,
  getAvailableCurrencies,
  assignBundleToNumber,
};
