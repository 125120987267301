import { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import io from "socket.io-client";

import { SOCKET_URL, VERSION, X_ZANG_CLIENT_ID } from "config";
import { boxNames } from "enums/faxes";
import useBool from "hooks/useBool";
import useInterval from "hooks/useInterval";
import useThrottle from "hooks/useThrottle";
import { selectAccountId } from "selectors/account.selector";
import { selectAccessToken } from "selectors/authentication.selector";
import { selectSelectedMemberUid } from "selectors/faxes.selector";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";
import { getSyncHistory } from "stores/reducers/syncHistory.reducer";

function Websocket() {
  const ws = useRef(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const accountId = useSelector(selectAccountId);
  const accessToken = useSelector(selectAccessToken);
  const selectedMemberUid = useSelector(selectSelectedMemberUid);

  const currentId = useRef(accountId);
  const currentMemberUid = useRef(selectedMemberUid);
  const currentView = useRef({ isOutbox: false, isFaxes: false });

  const [isFallbackRunning, isFallbackRunningBool] = useBool(false);

  const handleUpdateOutbox = useCallback(() => {
    // Expensive call
    if (currentView.current.isOutbox) {
      dispatch(
        faxesAsyncActions.fetchBox({
          boxName: boxNames.outbox,
          fetchOptions: {
            url: `numbers/all/applications/fax-${currentId.current}/outbox`,
          },
        }),
      );
    }
  }, [dispatch]);

  const handleUpdateHistory = useCallback(() => {
    dispatch(getSyncHistory({ number: "all", memberUid: currentMemberUid.current }));
  }, [dispatch]);

  const handleUpdateHistoryWhileOnFaxesRoute = useCallback(() => {
    if (currentView.current.isFaxes) {
      dispatch(getSyncHistory({ number: "all", memberUid: currentMemberUid.current }));
    }
  }, [dispatch]);

  const handleForceUpdate = useCallback(() => {
    window.location.reload();
  }, []);

  const throttledUpdateOutbox = useThrottle(handleUpdateOutbox, 1000);
  const throttledUpdateHistory = useThrottle(handleUpdateHistory, 1000);

  useEffect(() => {
    currentId.current = accountId;
    currentMemberUid.current = selectedMemberUid;
    currentView.current.isFaxes = pathname.search("faxes") !== -1;
    currentView.current.isOutbox = pathname.search("outbox") !== -1;
  }, [accountId, pathname, selectedMemberUid]);

  useEffect(() => {
    if (accessToken && ws.current === null) {
      // Init socket io instance once per component mount
      ws.current = io(SOCKET_URL, {
        transports: ["websocket"],
        query: {
          token: accessToken,
          "client-id": X_ZANG_CLIENT_ID,
          "x-zang-app-version": "web",
          "x-zang-app": `platform=web;appname=fax.plus;appver=${VERSION}`,
        },
        reconnectionDelay: 5000,
        reconnectionAttempts: 3,
        // requires manual open() invoke
        autoConnect: false,
      });
      ws.current.on("force_update", handleForceUpdate);
      ws.current.on("update_outbox", throttledUpdateOutbox);
      ws.current.on("update_history", throttledUpdateHistory);
      ws.current.on("connect", isFallbackRunningBool.setFalse);
      ws.current.on("disconnect", isFallbackRunningBool.setTrue);
      ws.current.on("connect_error", isFallbackRunningBool.setTrue);
    }

    if (accessToken && ws.current?.connected) {
      // AccessToken was updated and the socket was still connected
      ws.current.close();
    }
    if (accessToken && ws.current?.disconnected) {
      // Grab the only socket io instance, and set the accessToken to the new one
      ws.current.query.token = accessToken;
      // Reconnect with the new accessToken
      ws.current.open();
    }
  }, [
    accessToken,
    handleForceUpdate,
    throttledUpdateOutbox,
    throttledUpdateHistory,
    isFallbackRunningBool.setTrue,
    isFallbackRunningBool.setFalse,
  ]);

  useInterval(handleUpdateHistoryWhileOnFaxesRoute, isFallbackRunning ? 1000 * 45 : null);

  useEffect(() => {
    // Disconnect when the component unmounts
    return () => ws.current?.disconnect?.();
  }, []);

  return null;
}

export default memo(Websocket);
