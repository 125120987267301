import debounce from "debounce-promise";
import { components } from "react-select";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { FaButton, makeStyles, Box, useSnackbar } from "@alohi/kit";
import { faNoteSticky, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { faNoteSticky as faNoteStickyRegular } from "@fortawesome/pro-solid-svg-icons";

import { useTheme } from "ui";
import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { AppDispatch, useAppDispatch } from "stores/store";
import AddNoteModal from "views/SendFax/modals/AddNoteModal";
import AsyncSelect from "components/Forms/Select/AsyncSelect";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { selectSearchedContacts } from "selectors/numbers.selector";
import AddFromContactsModal from "views/Contacts/AddFromContactsModal";
import { getContactsSuggestions } from "stores/reducers/account.reducer";
import { useSendFaxDestinationHelpers, useSendFaxHelpers } from "views/SendFax/contexts/helpers";
import {
  GroupDestination,
  RecentDestination,
  ContactDestination,
  Destination as DestinationType,
} from "views/SendFax/contexts/store";
import Label from "./components/Label";
import Options from "./components/Options";

function Destination() {
  const classes = useStyles();
  const { alohi } = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    sendFaxDispatch,
    sendFaxStore: { destinations, comment },
  } = useSendFaxContext();
  const { extractDestinations } = useSendFaxHelpers();
  const { handleNumberValidation, handleNewEntryDestination } = useSendFaxDestinationHelpers();

  const [value, { setValue, onFocus, showsError, setShowsError }] = useInput("");

  const [isAddNoteModalOpen, isAddNoteModalOpenBool] = useBool(false);
  const [isAddFromContactsModalOpen, setIsAddFromContactsModalOpen] = useState(false);

  const loadOptions = useCallback(
    (inputValue, callback) => debouncedSearch(inputValue, callback, dispatch),
    [dispatch],
  );

  const handleOnBlur = useCallback(() => {
    const { isValid, isDuplicate } = handleNumberValidation(value, destinations);

    if (value && value.length > 3) {
      if (isDuplicate) {
        setShowsError(true);
        enqueueSnackbar(t("SENT_FAX.DUPLICATE_NUMBER", { number: value }), {
          variant: "error",
        });
      } else if (!isValid) {
        setShowsError(true);
        enqueueSnackbar(t("SENT_FAX.NUMBER_NOT_VALID", { number: value }), {
          variant: "error",
        });
      } else if (isValid && !isDuplicate) {
        // If the number is valid, add it to the list.
        setValue("");

        sendFaxDispatch({
          type: "ADD_DESTINATION",
          payload: [handleNewEntryDestination(value)],
        });
      }
    }
  }, [
    t,
    value,
    setValue,
    destinations,
    setShowsError,
    sendFaxDispatch,
    enqueueSnackbar,
    handleNumberValidation,
    handleNewEntryDestination,
  ]);

  const handleInputChange = useCallback(
    (value, { action }) => {
      if (action === "input-change") {
        setValue(value);

        if (showsError) {
          setShowsError(false);
        }
      }
    },
    [setValue, showsError, setShowsError],
  );

  const createDestinationFromInput = useCallback(
    (text: string) => {
      extractDestinations({
        text: text,
        onDestinations: (destinations) => {
          sendFaxDispatch({
            type: "ADD_DESTINATION",
            payload: [...destinations],
          });
        },
        onSuccess: (text) => {
          enqueueSnackbar(text, { variant: "info" });
        },
        onErrors: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    },
    [enqueueSnackbar, extractDestinations, sendFaxDispatch],
  );

  const handleOnChange = useCallback(
    (destination, { action }) => {
      switch (action) {
        // The values will have the latest data based on action
        // We just need to update the store
        case "select-option": {
          setValue("");
          sendFaxDispatch({
            type: "ADD_DESTINATION",
            payload: destination || [],
          });
          break;
        }
        case "remove-value":
        case "pop-value": {
          sendFaxDispatch({
            type: "SET_DESTINATION",
            payload: destination || [],
          });
          break;
        }
        case "clear": {
          setValue("");
          sendFaxDispatch({
            type: "SET_DESTINATION",
            payload: [],
          });
          break;
        }
        case "set-value":
        case "deselect-option":
          break;
        case "create-option":
          setValue("");
          sendFaxDispatch({
            type: "ADD_DESTINATION",
            payload: destination,
          });
          break;
        default:
      }
    },
    [sendFaxDispatch, setValue],
  );

  const handlePaste = useCallback(
    (event) => {
      // Avoid possible copy paste clashes if one of the modal is opened
      if (isAddNoteModalOpen || isAddFromContactsModalOpen) {
        return;
      }

      const pastedText = event.clipboardData.getData("Text");
      event.preventDefault();
      createDestinationFromInput(pastedText);
    },
    [createDestinationFromInput, isAddFromContactsModalOpen, isAddNoteModalOpen],
  );

  const handleEnterPress = useCallback(() => {
    createDestinationFromInput(value);
    setValue("");
  }, [createDestinationFromInput, setValue, value]);

  useOnPressEnter(handleEnterPress);

  return (
    <Box className={classes.to} onPaste={handlePaste} data-cy="Destination">
      <Box component="span" className={classes.label}>
        {t("COMMON.TO_COLON")}
      </Box>
      <Box className={classes.wrapper} data-intro={t("SENT_FAX.SECOND_STEP")}>
        <AsyncSelect
          isMulti
          cacheOptions
          placeholder=""
          onFocus={onFocus}
          defaultMenuIsOpen
          inputValue={value}
          value={destinations}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          loadOptions={loadOptions}
          className={classes.select}
          onInputChange={handleInputChange}
          getNewOptionData={handleNewEntryDestination}
          isValidNewOption={(value: string, values: DestinationType[]) =>
            handleNumberValidation(value, values).isValid
          }
          styles={{
            input: (provided: Record<string, string>) => ({
              ...provided,
              color: showsError ? alohi.red : alohi.gray,
            }),
            group: () => ({}),
          }}
          components={{
            Option: Options,
            MultiValueLabel: Label,
            GroupHeading: () => null,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Menu: !!value ? components.Menu : () => null,
          }}
        />
      </Box>
      <Box className={classes.icons}>
        <FaButton
          fontSize={25}
          icon={faUserCircle}
          data-intro={t("SENT_FAX.FOURTH_STEP")}
          tooltip={t("CONTACTS.ADD_FROM_CONTACTS")}
          onClick={() => setIsAddFromContactsModalOpen(true)}
        />
        <FaButton
          fontSize={25}
          tooltip={t("FAXES.ADD_NOTE")}
          data-cy={dataCy.sendFaxNoteButton}
          data-intro={t("SENT_FAX.FIFTH_STEP")}
          onClick={isAddNoteModalOpenBool.setTrue}
          icon={Boolean(comment) ? faNoteStickyRegular : faNoteSticky}
        />
      </Box>

      {isAddFromContactsModalOpen && (
        <AddFromContactsModal handleClosure={() => setIsAddFromContactsModalOpen(false)} />
      )}

      {isAddNoteModalOpen ? <AddNoteModal handleClosure={isAddNoteModalOpenBool.setFalse} /> : null}
    </Box>
  );
}

const debouncedSearch = debounce(
  async (
    inputValue: string,
    callback: (
      response: {
        label: string;
        options: (RecentDestination | ContactDestination | GroupDestination)[];
      }[],
    ) => void,
    dispatch: AppDispatch,
  ) => {
    const response = await dispatch(
      getContactsSuggestions({
        limit: 5,
        offset: 0,
        search: inputValue,
        type: "autocomplete",
        sortBy: "",
        direction: "",
        shared: false,
      }),
    );

    const result = unwrapResult(response);
    const transformedResult = selectSearchedContacts(result);

    callback(transformedResult);
  },
  200,
);

const useStyles = makeStyles(({ alohi, spacing }) => ({
  to: {
    display: "flex",
    alignSelf: "normal",
    alignItems: "center",
    padding: spacing(2, 1, 2, 2),
    justifyContent: "space-between",
    borderBottom: `1px solid ${alohi.lighterGray}`,
  },
  wrapper: {
    flex: 1,
    minWidth: 0,
    display: "block",
  },
  select: {
    flex: 1,
  },
  label: {
    color: alohi.gray,
    marginRight: spacing(1),
  },
  icons: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "row",
    marginLeft: spacing(1),
  },
}));

export default Destination;
