import createSelector from "selectorator";

import RS from "../enums/requestStatus";
import { selectSelectedNumberCountry } from "./verifications.selector";

export const selectIsNewBundleLoading = createSelector(
  ["address.newBundle.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsAddressPowersetLoading = createSelector(
  ["address.powerset.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsNewBundleError = createSelector(
  ["address.newBundle.status"],
  (status) => status === RS.ERROR,
);

export const selectIsNewBundleErrorMessage = createSelector(
  ["address.newBundle.error"],
  (error) => {
    if (!error) return null;

    return error.reason;
  },
);

export const selectIsNewBundleSuccessful = createSelector(
  ["address.newBundle.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return Boolean(response);
  },
);

export const selectIsUserAddressRequirementsLoading = createSelector(
  ["address.requirements.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUserAddressRequirementsSuccessful = createSelector(
  ["address.requirements.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return Boolean(response);
  },
);

export const selectUserAddressRequirements = (userType) =>
  createSelector(["address.requirements.response"], (response) => {
    if (!response?.[userType]?.end_user_attributes) {
      return null;
    }

    return response[userType].end_user_attributes.map((field) => ({
      inputId: field.machine_name,
      friendlyName: field.friendly_name,
      acceptedValues: field.accepted_values,
    }));
  });

export const selectDocumentTypeDictionary = (userType) =>
  createSelector(["address.requirements.response"], (response) => {
    if (!response?.[userType]?.document_type_attributes) {
      return null;
    }
    return response[userType].document_type_attributes;
  });

export const selectUserAddressDocuments = (userType) =>
  createSelector(["address.requirements.response"], (response) => {
    if (!response?.[userType]?.supporting_document_lists) {
      return null;
    }
    return response[userType].supporting_document_lists;
  });

export const selectUserAddressDocumentsRemaining = (userType) =>
  createSelector(["address.powerset.response"], (documents) => {
    if (!documents?.[userType]) {
      return null;
    }

    return (documents[userType]?.supporting_document_lists?.[0] ?? [])
      .flat()
      .map(({ name, number_of_required_documents: numberOfRequiredDocuments }) => ({
        name,
        numberOfRequiredDocuments: numberOfRequiredDocuments ?? 1,
      }));
  });

export const selectUserAddressDocumentsType = (userType) =>
  createSelector([selectUserAddressDocuments(userType)], (documents) => {
    if (!documents) {
      return null;
    }
    let length = 0;

    return documents[0].reduce(
      (
        acc,
        {
          name,
          description,
          accepted_documents,
          number_of_required_documents: numberOfRequiredDocuments,
        },
      ) => {
        acc.push({
          name,
          description: description ?? "",
          inputId: name + length++, // Added length for uniqueness
          numberOfRequiredDocuments: numberOfRequiredDocuments ?? 1,
          acceptedDocuments: accepted_documents.map(
            ({ document_name, document_type, ...rest }) => ({
              documentName: document_name,
              documentType: document_type,
              ...rest,
            }),
          ),
        });
        return acc;
      },
      [],
    );
  });

export const selectPostedAddress = createSelector(["address.newAddress.response"], (response) => {
  if (!response) {
    return null;
  }
  return response;
});

export const selectPostedAddressIsRunning = createSelector(
  ["address.newAddress.status"],
  (status) => {
    return status === RS.RUNNING;
  },
);

export const selectPostedAddressIsError = createSelector(
  ["address.newAddress.status"],
  (status) => {
    return status === RS.ERROR;
  },
);

export const selectAddresses = createSelector(["address.addresses.response"], (response) => {
  if (!response?.addresses) {
    return null;
  }
  return response.addresses;
});

export const selectAddressBundles = createSelector(["address.bundles.response"], (response) => {
  if (!response) {
    return null;
  }

  return response;
});

export const selectApprovedDocuments = createSelector(
  ["address.bundles.response", selectSelectedNumberCountry],
  (response, country) => {
    if (!response) {
      return null;
    }
    if (!country) {
      return response.approved;
    }
    return response.approved.filter(({ iso_country }) => iso_country === country);
  },
);

export const selectRejectedDocuments = createSelector(
  ["address.bundles.response", selectSelectedNumberCountry],
  (response, country) => {
    if (!response) {
      return null;
    }
    if (!country) {
      return response.rejected;
    }
    return response.rejected.filter(({ iso_country }) => iso_country === country);
  },
);

export const selectAllPendingDocuments = createSelector(
  ["address.bundles.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.pending;
  },
);

export const selectAllApprovedDocuments = createSelector(
  ["address.bundles.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.approved;
  },
);

export const selectPendingDocuments = createSelector(
  ["address.bundles.response", selectSelectedNumberCountry],
  (response, country) => {
    if (!response) {
      return null;
    }

    if (!country) {
      return response.pending;
    }

    return response.pending.filter(({ iso_country }) => iso_country === country);
  },
);
