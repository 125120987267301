import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import { webHookTypes } from "enums/webHooks";
import { isValidRedirectUrl } from "helpers/inputValidation";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import FormRowMultiSelectTextInput from "components/Forms/FormRowMultiSelectTextInput";
import {
  selectIncomingWebHooks,
  selectOutgoingWebHooks,
  selectUpdateWebHooksError,
  selectIsUpdateWebHooksSuccess,
} from "selectors/integrations.webHooks.selector";
import {
  getWebHooks,
  createWebHook,
  deleteWebHook,
  clearCreateWebHook,
  clearDeleteWebHook,
} from "stores/reducers/integrations.webHooks.reducer";
import RequireUpgradeToEnterprise from "../RequireUpgrade/RequireUpgradeToEnterprise";

function EditWebHooksModal({ handleClosure, type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);
  const incomingWebHooks = useSelector(selectIncomingWebHooks);
  const outgoingWebHooks = useSelector(selectOutgoingWebHooks);
  const isSuccess = useSelector(selectIsUpdateWebHooksSuccess);
  const error = useSelector(selectUpdateWebHooksError);

  const webHooks = type === webHookTypes.outgoing ? outgoingWebHooks : incomingWebHooks;

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    const deletePromises = [];
    const createPromises = [];

    // Check new URI(s)
    urisInput.forEach((uriInput) => {
      if (!webHooks.find((hook) => hook.id === uriInput.value))
        createPromises.push(dispatch(createWebHook({ hookUrl: uriInput.value, hookType: type })));
    });

    // Check deleted URI(s)
    webHooks.forEach((webHook) => {
      if (!urisInput.find((input) => input.value === webHook.id))
        deletePromises.push(dispatch(deleteWebHook(webHook.id)));
    });

    await Promise.all(deletePromises);
    await Promise.all(createPromises);
    await dispatch(getWebHooks());
    handleClosure();
  };

  const [urisInput, setUrisInput] = useState(
    webHooks?.map((webHook) => ({
      label: webHook.target,
      value: webHook.id,
    })) ?? [],
  );

  const handleUrisInputOnChange = useCallback((value) => {
    setUrisInput(value);
  }, []);

  useEffect(() => {
    (async () => {
      if (isSuccess) {
        dispatch(clearCreateWebHook());
        await dispatch(getWebHooks());
        enqueueSnackbar(t("WEB_HOOKS.CREATE_SUCCESS"), { variant: "success" });
        setIsLoading(false);
        handleClosure();
      }
    })();
  }, [dispatch, enqueueSnackbar, handleClosure, isSuccess, t]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearCreateWebHook());
      dispatch(clearDeleteWebHook());
    }
  }, [dispatch, enqueueSnackbar, error, t]);

  if (!isPlanEnterprise)
    return (
      <RequireUpgradeToEnterprise
        handleClosure={handleClosure}
        title={t("WEB_HOOKS.CONFIGURE")}
        featureName={t("WEB_HOOKS.TITLE")}
        isPlural
      />
    );

  return (
    <Modal
      title={t("WEB_HOOKS.CONFIGURE")}
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
      isModalDisabled={isLoading}
      onCancel={handleClosure}
    >
      <Box p={3} component="form" onSubmit={(e) => e.preventDefault()}>
        <FormRowMultiSelectTextInput
          labelGridWidth={0}
          id={"web-hooks-input"}
          initialValues={urisInput}
          label={type === webHookTypes.outgoing ? t("WEB_HOOKS.OUTGOING") : t("WEB_HOOKS.INCOMING")}
          helperText={t("WEB_SERVICE.TOOLTIP_URI")}
          onChange={handleUrisInputOnChange}
          validator={isValidRedirectUrl}
          unique
          maxCount={3}
          validationErrorMessage={t("WEB_SERVICE.PLEASE_ENTER_VALID_URI")}
          uniqueErrorMessage={t("WEB_SERVICE.DUPLICATE_DOMAIN")}
          maxCountErrorMessage={t("WEB_SERVICE.LIMIT_REACHED_URIS", {
            count: 3,
          })}
          placeholder={t("WEB_HOOKS.URIS")}
        />
      </Box>
    </Modal>
  );
}

EditWebHooksModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  type: PropTypes.oneOf([webHookTypes.incoming, webHookTypes.outgoing]),
};

export default EditWebHooksModal;
