import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Panel, Button, makeStyles, Typography } from "@alohi/kit";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import useBool from "hooks/useBool";
import { selectIsBaaSigned } from "selectors/account.selector";
import BaaActivationModal from "views/Profile/BaaActivationModal";

function SignBaa() {
  const { t } = useTranslation();

  const isBaaRequestAlreadySent = localStorage.getItem("baaRequestSent") === "true";

  const isBaaSigned = useSelector(selectIsBaaSigned);
  const [isBaaActivationModalOpen, isBaaActivationModalOpenBool] = useBool(false);

  const classes = useStyles({ isBaaSigned });

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">
          {isBaaSigned ? (
            <FontAwesomeIcon className={classes.activeIcon} size="1x" icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon className={classes.inactiveIcon} size="1x" icon={faTimesCircle} />
          )}
          <Typography type="panelCellTitle">{t("PROFILE.HIPAA_BAA_FEATURE_NAME")}</Typography>
        </Panel.Cell>
        <Panel.Cell variant="actions">
          {!isBaaSigned ? (
            <Button
              variant="white"
              onClick={isBaaActivationModalOpenBool.setTrue}
              isDisabled={isBaaRequestAlreadySent}
            >
              {isBaaRequestAlreadySent ? t("COMMON.REQUESTED") : t("PROFILE.HIPAA_REQUEST_BAA")}
            </Button>
          ) : null}
        </Panel.Cell>
      </Panel.Row>

      {isBaaActivationModalOpen && (
        <BaaActivationModal handleClosure={isBaaActivationModalOpenBool.setFalse} />
      )}
    </>
  );
}

const useStyles = makeStyles(({ spacing, alohi, typography }) => ({
  inactiveIcon: {
    color: alohi.red,
    marginRight: spacing(1),
    fontSize: typography.pxToRem(17),
  },
  activeIcon: {
    color: alohi.lightGreen,
    marginRight: spacing(1),
    fontSize: typography.pxToRem(17),
  },
}));

export default SignBaa;
