import { Box, makeStyles } from "@alohi/kit";
import clsx from "clsx";
import ChangePlanButton from "components/Profile/PlanBilling/PlanDetailsPanel/components/ChangePlanButton";
import {
  selectIsPlanCancelled,
  selectIsPlanFree,
  selectIsPlanInactive,
} from "selectors/plan.selector";
import { useAppSelector } from "stores/store";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import RenewPlanButton from "components/Profile/PlanBilling/PlanDetailsPanel/components/RenewPlanButton";
import ResumePlanButton from "components/Profile/PlanBilling/PlanDetailsPanel/components/ResumePlanButton";

interface UpgradeSuggestionProps {
  className?: string;
}

function UpgradeSuggestion({ className }: UpgradeSuggestionProps) {
  const classes = useStyles();

  const isCorporateMember = useAppSelector(selectAccountIsCorporateMember);
  const isPlanFree = useAppSelector(selectIsPlanFree);
  const isPlanCancelled = useAppSelector(selectIsPlanCancelled);
  const isPlanInactive = useAppSelector(selectIsPlanInactive);

  if (isCorporateMember) {
    return null;
  }

  return (
    <Box className={clsx(classes.base, className)}>
      {isPlanFree ? <ChangePlanButton /> : null}
      {isPlanInactive && isPlanCancelled ? <RenewPlanButton /> : null}
      {isPlanCancelled && !isPlanInactive ? <ResumePlanButton /> : null}
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  base: {
    display: "flex",
    alignItems: "center",
    marginRight: spacing(1),
  },
}));

export default UpgradeSuggestion;
