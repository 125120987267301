import { memo, ReactNode } from "react";
import { Box, makeStyles } from "@alohi/kit";

import TableThumbnailPlaceholder from "./TableThumbnailPlaceholder";

interface StyleProps {
  scale: number;
  hasChildren: boolean;
}

interface TableThumbnailProps {
  scale?: number;
  children?: ReactNode;
  thumbnail: string;
}

const WIDTH = 47 / 1.5;
const HEIGHT = 60 / 1.5;

function TableThumbnail({ children, thumbnail, scale = 1 }: TableThumbnailProps) {
  const hasChildren = Boolean(children);

  const classes = useStyles({ hasChildren, scale });

  return (
    <Box className={classes.root}>
      {hasChildren ? <Box className={classes.radioRoot}>{children}</Box> : null}

      <Box className={classes.imageRoot}>
        {thumbnail ? (
          <Box className={classes.imageParent}>
            <img
              alt="thumbnail"
              draggable={false}
              className={classes.image}
              src={`data:image/png;base64, ${thumbnail}`}
            />
          </Box>
        ) : (
          <TableThumbnailPlaceholder width={WIDTH * scale} height={HEIGHT * scale} />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
  },
  imageRoot: ({ hasChildren, scale }: StyleProps) => ({
    opacity: 1,
    zIndex: 1000,
    width: WIDTH * scale,
    height: HEIGHT * scale,
    ...(hasChildren
      ? {
          "&:hover": {
            opacity: 0,
            zIndex: 989,
          },
        }
      : {}),
  }),
  radioRoot: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 990,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    "&:hover": {
      zIndex: 1500,
      backgroundColor: theme.alohi.lightestGray,
    },
  },
  imageParent: ({ scale }: StyleProps) => ({
    display: "flex",
    width: WIDTH * scale,
    height: HEIGHT * scale,
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.alohi.lighterGray}`,
  }),
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default memo(TableThumbnail);
