import { useEffect } from "react";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Modal,
  Tooltip,
  TextButton,
  Typography,
  makeStyles,
  useSnackbar,
  useCountdownTimer,
} from "@alohi/kit";

import { CodeInput } from "ui";
import { useInput } from "hooks/useInput";
import { useAppDispatch, useAppSelector } from "stores/store";
import { logout } from "stores/reducers/authentication.reducer";
import { selectAccountEmail } from "selectors/account.selector";
import { getAccountDetails } from "stores/reducers/account.reducer";
import { isValidEmailVerificationCode } from "helpers/inputValidation";
import {
  selectVerifyEmail,
  selectResendEmailCode,
  selectVerifyEmailError,
  selectIsResendEmailLoading,
  selectIsVerifyEmailLoading,
} from "selectors/verifications.selector";
import {
  verifyEmail,
  resendEmailCode,
  clearVerifyEmail,
  clearResendEmailCode,
} from "stores/reducers/verifications.reducer";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

const BUTTON_TIMEOUT = 60;

interface EmailVerificationModalProps {
  handleClosure: () => void;
}

function EmailVerificationModal({ handleClosure }: EmailVerificationModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { sendCustomEvent } = useGoogleTagManager();

  const email = useAppSelector(selectAccountEmail);
  const isVerifyEmail = useAppSelector(selectVerifyEmail);
  const isResendCode = useAppSelector(selectResendEmailCode);
  const verifyEmailError = useAppSelector(selectVerifyEmailError);
  const isVerifyEmailLoading = useAppSelector(selectIsVerifyEmailLoading);
  const isResendEmailLoading = useAppSelector(selectIsResendEmailLoading);

  const [code, codeInput] = useInput<string>("", isValidEmailVerificationCode);
  const { isInProgress, remainingTime, start: startCountdownTimer } = useCountdownTimer();

  const confirmTooltip = remainingTime
    ? t("VERIFY.AVAILABLE_IN", {
        value_1: remainingTime,
      })
    : undefined;

  function handleConfirmation() {
    codeInput.touch();

    if (codeInput.isValid) {
      type DispatchInput = Record<string, unknown>;
      type DispatchReturn = AsyncThunkAction<DispatchInput, void, DispatchInput>;

      const verifyEmailTyped = verifyEmail as (input: DispatchInput) => DispatchReturn;

      dispatch(
        verifyEmailTyped({
          code,
          email,
        }),
      );
    }
  }

  function handleResendEmailVerification() {
    if (!isInProgress) {
      startCountdownTimer(BUTTON_TIMEOUT);
      dispatch(resendEmailCode());
    }
  }

  function handleLogout() {
    dispatch(logout());
  }

  useEffect(() => {
    if (isResendCode) {
      enqueueSnackbar(t("VERIFY.NEW_LINK_EMAIL"), { variant: "success" });
      dispatch(clearResendEmailCode());
    }
  }, [dispatch, enqueueSnackbar, isResendCode, t]);

  useEffect(() => {
    if (isVerifyEmail) {
      enqueueSnackbar(t("VERIFY.ACCOUNT_VERIFY"), { variant: "success" });
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.emailVerification,
      });
      dispatch(getAccountDetails());
      dispatch(clearVerifyEmail());
      handleClosure();
    }
  }, [t, enqueueSnackbar, dispatch, isVerifyEmail, handleClosure, sendCustomEvent]);

  useEffect(() => {
    if (verifyEmailError) {
      enqueueSnackbar(verifyEmailError, { variant: "error" });
      dispatch(clearVerifyEmail());
    }
  }, [dispatch, enqueueSnackbar, verifyEmailError]);

  const isLoading = isVerifyEmailLoading || isResendEmailLoading;

  return (
    <Modal
      maxWidth="xs"
      hasCloseIcon={false}
      onCancel={handleLogout}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      onConfirm={handleConfirmation}
      title={t("COMMON.GET_STARTED")}
      cancelTitle={t("COMMON.LOGOUT")}
      onPressEnter={handleConfirmation}
      confirmTitle={t("COMMON.CONFIRM")}
      isConfirmDisabled={codeInput.showsError}
    >
      <Box className={classes.parent}>
        <Typography textAlign="center">
          <Trans
            i18nKey="VERIFY.INSERT_CODE"
            components={{
              email,
              strong: <strong />,
            }}
          />
        </Typography>
        <Box className={classes.codeWrapper}>
          <CodeInput
            autoFocus
            fields={6}
            value={code}
            id="VerificationCodeInput"
            onChange={codeInput.setValue}
            isError={codeInput.showsError}
          />
        </Box>
        <Box className={classes.sendAgain}>
          <Typography className={classes.didntReceiveIt}>{t("VERIFY.DIDNOT_RECEIVE")}</Typography>
          <Tooltip placement="right" title={confirmTooltip}>
            <Box>
              <TextButton disabled={isInProgress} onClick={handleResendEmailVerification}>
                {t("VERIFY.SEND_AGAIN")}
              </TextButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  codeWrapper: {
    display: "flex",
    minHeight: "58px",
    position: "relative",
    alignItems: "center",
    marginTop: spacing(3),
    justifyContent: "center",
  },
  parent: {
    display: "flex",
    padding: spacing(3),
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  didntReceiveIt: {
    marginRight: spacing(0.5),
  },
  sendAgain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing(3),
  },
}));

export default EmailVerificationModal;
