import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { webHooksApi } from "../../api/web_hooks.api";
import RS from "../../enums/requestStatus";
import { selectIsGetWebHooksRunning } from "../../selectors/integrations.webHooks.selector";
import { selectIsPlanEnterprise } from "../../selectors/plan.selector";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

export const initialWebHooksState = {
  webHooks: {
    getWebHooks: initialRequestState,
    createWebHook: initialRequestState,
    deleteWebHook: initialRequestState,
  },
};

const getWebHooks = createAsyncThunk(
  "INTEGRATIONS/GET_WEB_HOOKS",
  async (_, { rejectWithValue, getState }) => {
    const isEnterprise = selectIsPlanEnterprise(getState());
    if (!isEnterprise) {
      return rejectWithValue("not enterprise");
    }
    try {
      const response = await webHooksApi.getWebHooks();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return !selectIsGetWebHooksRunning(getState());
    },
  },
);

const createWebHook = createAsyncThunk(
  "INTEGRATIONS/CREATE_WEB_HOOK",
  async ({ hookType, hookUrl }, { rejectWithValue }) => {
    try {
      const response = await webHooksApi.createWebHook({ hookType, hookUrl });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const deleteWebHook = createAsyncThunk(
  "INTEGRATIONS/DELETE_WEB_HOOK",
  async (hookId, { rejectWithValue }) => {
    try {
      const response = await webHooksApi.deleteWebHook(hookId);
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const clearGetWebHooks = createAction("INTEGRATIONS/CLEAR_GET_WEBHOOKS_SETTINGS");
export const clearCreateWebHook = createAction("INTEGRATIONS/CLEAR_UPDATE_WEBHOOKS_SETTINGS");
export const clearDeleteWebHook = createAction("INTEGRATIONS/CLEAR_DEACTIVATE_WEBHOOKS");

export const webHooksSlice = {
  [getWebHooks.pending]: (state) => {
    state.webHooks.getWebHooks.status = RS.RUNNING;
    state.webHooks.getWebHooks.error = null;
  },
  [getWebHooks.fulfilled]: (state, { payload }) => {
    state.webHooks.getWebHooks.status = RS.IDLE;
    state.webHooks.getWebHooks.response = payload.response;
  },
  [getWebHooks.rejected]: (state, { payload }) => {
    state.webHooks.getWebHooks.status = RS.ERROR;
    state.webHooks.getWebHooks.error = payload;
  },
  [clearGetWebHooks]: (state) => {
    state.webHooks.getWebHooks = initialRequestState;
  },
  [createWebHook.pending]: (state) => {
    state.webHooks.createWebHook.status = RS.RUNNING;
    state.webHooks.createWebHook.error = null;
  },
  [createWebHook.fulfilled]: (state, { payload }) => {
    state.webHooks.createWebHook.status = RS.IDLE;
    state.webHooks.createWebHook.response = payload.response;
  },
  [createWebHook.rejected]: (state, { payload }) => {
    state.webHooks.createWebHook.status = RS.ERROR;
    state.webHooks.createWebHook.error = payload;
  },
  [clearCreateWebHook]: (state) => {
    state.webHooks.createWebHook = initialRequestState;
  },
  [deleteWebHook.pending]: (state) => {
    state.webHooks.deleteWebHook.status = RS.RUNNING;
    state.webHooks.deleteWebHook.error = null;
  },
  [deleteWebHook.fulfilled]: (state, { payload }) => {
    state.webHooks.deleteWebHook.status = RS.IDLE;
    state.webHooks.deleteWebHook.response = payload.response;
  },
  [deleteWebHook.rejected]: (state, { payload }) => {
    state.webHooks.deleteWebHook.status = RS.ERROR;
    state.webHooks.deleteWebHook.error = payload;
  },
  [clearDeleteWebHook]: (state) => {
    state.webHooks.deleteWebHook = initialRequestState;
  },
};

export { getWebHooks, createWebHook, deleteWebHook };
