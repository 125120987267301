import { Box } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartNetwork, faUser, faUsers, faHistory } from "@fortawesome/pro-light-svg-icons";

import IconWrapper from "components/Icons/IconWrapper";
import { DestinationIcon } from "views/SendFax/contexts/store";

interface IconProps {
  icon: DestinationIcon;
}

function Icon({ icon }: IconProps) {
  let iconToRender = null;

  switch (icon) {
    case "group":
      iconToRender = faUsers;
      break;
    case "recent":
      iconToRender = faHistory;
      break;
    case "contact":
      iconToRender = faUser;
      break;
    case "sharedGroup":
    case "sharedContact":
      iconToRender = faChartNetwork;
      break;
    case "newEntry":
      iconToRender = null;
      break;
    default:
  }

  return (
    <Box>
      {iconToRender ? (
        <IconWrapper>
          <FontAwesomeIcon icon={iconToRender} size="xs" />
        </IconWrapper>
      ) : (
        <Box height="2em" />
      )}
    </Box>
  );
}

export default Icon;
