import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Panel, Box, SvgBox, makeStyles, alohi } from "@alohi/kit";

import dataCy from "enums/dataCy";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import { selectCanISeeNumberOfFaxNumbersRow } from "selectors/ability.selector";
import {
  selectIsPlanFree,
  selectIsPlanActive,
  selectIsPlanInactive,
  selectIsPlanCancelled,
  selectPlanExpirationDate,
  selectPlanIsWaitingApproval,
  selectIsChangePlanButtonDisplayed,
} from "selectors/plan.selector";
import ChangePlanButton from "./components/ChangePlanButton";
import PreviewRemainingPages from "./components/PreviewRemainingPagesRow";
import PlanDetailsForCorporateMembers from "../PlanDetailsOfCorporateMembersPanel";
import FaxPlusLogo from "assets/svg/fax_plus_logo_text.svg";
import PreviewPlanType from "./components/PreviewPlanType";
import PlanRenewalDate from "./components/PlanRenewalDate";
import FaxNumbers from "./components/FaxNumbers";
import RenewPlanButton from "./components/RenewPlanButton";
import CancelPlanButton from "./components/CancelPlanButton";
import ResumePlanButton from "./components/ResumePlanButton";

function PlanDetailsPanel() {
  const { t } = useTranslation();
  const classes = useStyles();

  const isPlanFree = useSelector(selectIsPlanFree);
  const isPlanActive = useSelector(selectIsPlanActive);
  const isPlanInactive = useSelector(selectIsPlanInactive);
  const isPlanCancelled = useSelector(selectIsPlanCancelled);
  const planExpirationDate = useSelector(selectPlanExpirationDate);
  const isWaitingApproval = useSelector(selectPlanIsWaitingApproval);
  const isChangePlanButtonDisplayed = useSelector(selectIsChangePlanButtonDisplayed);
  const canISeeNumberOfFaxNumbersRow = useSelector(selectCanISeeNumberOfFaxNumbersRow);

  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  if (isCorporateMember) {
    return <PlanDetailsForCorporateMembers />;
  }

  return (
    <Panel
      id="plan-details"
      data-cy={dataCy.planDetailsPanel}
      headerTitle={t("PROFILE.SUBSCRIPTION_INFO")}
    >
      <Panel.Front>
        <Box className={classes.row}>
          <Box className={classes.rowContent}>
            <Box className={classes.rowContentHeader}>
              <SvgBox className={classes.logo} src={FaxPlusLogo} />
            </Box>
            <Box className={classes.rowContentButtonsContainer}>
              {!isCorporateMember && (
                <>
                  {isPlanInactive && isPlanCancelled && <RenewPlanButton />}
                  {!isPlanFree &&
                    (isPlanActive || isWaitingApproval || isPlanInactive) &&
                    !isPlanCancelled && <CancelPlanButton />}
                  {isPlanCancelled && !isPlanInactive && <ResumePlanButton />}
                  {isChangePlanButtonDisplayed ? <ChangePlanButton /> : null}
                </>
              )}
            </Box>
          </Box>

          <Box className={classes.rowDetails}>
            <Box className={classes.rowDetailsContent}>
              <PreviewPlanType
                className={isPlanFree ? classes.freePreviewPlanType : classes.rowDetailsContentItem}
                isPlanInactive={Boolean(isPlanInactive)}
              />
              {!isPlanFree && planExpirationDate ? (
                <PlanRenewalDate className={classes.rowDetailsContentItem} />
              ) : null}
              {canISeeNumberOfFaxNumbersRow ? (
                <FaxNumbers className={classes.rowDetailsContentItem} />
              ) : null}
              <PreviewRemainingPages />
            </Box>
          </Box>
        </Box>
      </Panel.Front>
    </Panel>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  logo: {
    width: "107px",
  },
  row: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    background: alohi.neutral0,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  rowContent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    [breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  rowContentHeader: {
    padding: spacing(2),
  },
  rowContentButtonsContainer: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: spacing(2),
    gap: spacing(2),
    [breakpoints.down("sm")]: {
      paddingTop: spacing(1),
    },
  },
  rowDetails: {
    padding: spacing(1, 2, 2, 2),
    width: "100%",
  },
  rowDetailsContent: {
    padding: spacing(1, 2, 1, 2),
    background: alohi.neutral100,
    display: "flex",
    borderRadius: "8px",
    [breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  rowDetailsContentItem: {
    flex: 1,
    [breakpoints.down("md")]: {
      marginBottom: spacing(2),
    },
  },
  freePreviewPlanType: {
    marginRight: spacing(8),
    [breakpoints.down("md")]: {
      marginBottom: spacing(2),
      marginRight: spacing(0),
    },
  },
}));

export default PlanDetailsPanel;
