import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { Box, Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";

import { useInput } from "hooks/useInput";
import { isValidBlacklistNumber } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import FormRowPhoneInput from "components/Forms/FormRowPhoneInput";
import { selectCanIEditBlacklist } from "selectors/ability.selector";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import {
  selectBlacklistContactById,
  selectCreateBlacklistContact,
  selectUpdateBlacklistContact,
  selectCreateBlacklistContactError,
  selectUpdateBlacklistContactError,
} from "selectors/contacts.selector";
import {
  clearCreateBlacklist,
  clearUpdateBlacklist,
  createBlacklistContact,
  updateBlacklistContact,
} from "stores/reducers/contacts.blacklist.reducer";

function AddOrEditBlacklistContactModal({ contactToEditId, handleClosure, prefilledFaxNumber }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const title = contactToEditId ? t("CONTACTS.MODIFY_BLACKLIST") : t("CONTACTS.ADD_BLACKLIST");

  const canIEditBlacklist = useSelector(selectCanIEditBlacklist);
  const contact = useSelector(selectBlacklistContactById(contactToEditId));

  const isBlacklistUpdated = useSelector(selectUpdateBlacklistContact);
  const isBlacklistCreated = useSelector(selectCreateBlacklistContact);

  const createBlacklistError = useSelector(selectCreateBlacklistContactError);
  const updateBlacklistError = useSelector(selectUpdateBlacklistContactError);

  const [note, noteInput] = useInput(contact?.note || "");
  const [fullName, fullNameInput] = useInput(contact?.fullName || "");
  const [faxNumber, faxNumberInput] = useInput(
    contact?.faxNumber || (prefilledFaxNumber ?? ""),
    (value) => isValidBlacklistNumber(value),
  );

  const createContact = useCallback(
    (newContact) => dispatch(createBlacklistContact({ newContact })),
    [dispatch],
  );
  const updateContact = useCallback(
    (contactDiffs) => dispatch(updateBlacklistContact({ contactDiffs })),
    [dispatch],
  );

  const handleConfirm = useCallback(() => {
    faxNumberInput.touch();

    if (!faxNumberInput.isValid) {
      enqueueSnackbar(t("FORMS.INVALID_FAX"), { variant: "error" });
      return;
    }

    const toUpdateOrCreate = {
      note,
      fullName,
      faxNumber,
    };

    if (contactToEditId) {
      Object.assign(toUpdateOrCreate, {
        id: contactToEditId,
      });

      updateContact(toUpdateOrCreate);
    } else {
      createContact(toUpdateOrCreate);
    }
  }, [
    t,
    note,
    fullName,
    faxNumber,
    updateContact,
    createContact,
    faxNumberInput,
    contactToEditId,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    if (isBlacklistCreated) {
      dispatch(clearCreateBlacklist());
      handleClosure();
    }

    if (isBlacklistUpdated) {
      dispatch(clearUpdateBlacklist());
      handleClosure();
    }
  }, [dispatch, handleClosure, isBlacklistCreated, isBlacklistUpdated]);

  useEffect(() => {
    if (updateBlacklistError || createBlacklistError) {
      let error = "";

      if (updateBlacklistError) {
        error = updateBlacklistError;
        dispatch(clearUpdateBlacklist());
      } else if (createBlacklistError) {
        error = createBlacklistError;
        dispatch(clearCreateBlacklist());
      }

      if (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
  }, [createBlacklistError, dispatch, enqueueSnackbar, updateBlacklistError]);

  if (!canIEditBlacklist) {
    return (
      <ContactAdminToUseFeature
        title={title}
        handleClosure={handleClosure}
        description={t("CONTACTS.CONTACT_ADMIN_TO_EDIT_BLACKLIST")}
      />
    );
  }

  return (
    <Modal
      maxWidth="xs"
      title={title}
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isConfirmDisabled={!faxNumber || faxNumberInput.showsError}
    >
      <Box component="form" p={2} pr="10%">
        <FormRowTextInput
          type="text"
          value={fullName}
          label={t("FORMS.NAME")}
          id="add-blacklist-name"
          placeholder={t("FORMS.NAME")}
          onChange={fullNameInput.onChange}
        />
        <FormRowPhoneInput
          value={faxNumber}
          id="add-blacklist-fax-number"
          onBlur={faxNumberInput.onBlur}
          label={t("CONTACTS.FAX_NUMBER")}
          onFocus={faxNumberInput.onFocus}
          error={faxNumberInput.showsError}
          onChange={faxNumberInput.onChange}
          placeholder={t("CONTACTS.ENTER_FAX_NUMBER")}
        />
        <FormRowTextInput
          value={note}
          id="add-blacklist-note"
          label={t("COMMON.NOTE")}
          onChange={noteInput.onChange}
          placeholder={t("COMMON.NOTE")}
        />
      </Box>
    </Modal>
  );
}

AddOrEditBlacklistContactModal.propTypes = {
  contactToEditId: PropTypes.string,
  handleClosure: PropTypes.func,
  prefilledFaxNumber: PropTypes.string,
};

export default AddOrEditBlacklistContactModal;
