import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";

const getWebHooks = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/hooks`;
  return fetchWithRefresh(path, requestOptions);
};

const createWebHook = ({ hookType, hookUrl }) => {
  const requestOptions = {
    method: "POST",
    body: { event: hookType, target: hookUrl },
  };
  const path = `${API_AFFOGATO}/hooks`;
  return fetchWithRefresh(path, requestOptions);
};

const deleteWebHook = (hookId) => {
  const requestOptions = {
    method: "DELETE",
  };
  const path = `${API_AFFOGATO}/hooks/${hookId}`;
  return fetchWithRefresh(path, requestOptions);
};

export const webHooksApi = {
  getWebHooks,
  createWebHook,
  deleteWebHook,
};
