import { memo, useCallback, useEffect, useRef } from "react";

import dayjs from "helpers/date";
import database from "helpers/database";
import { useAppSelector } from "stores/store";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { selectIsEnabledAdvancedSecurity } from "selectors/account.selector";
import {
  FAX_FILE,
  TIMESTAMP,
  DESTINATION,
  Destination,
  SendFaxFiles,
  Destinations,
  DefaultCoverSheet,
  DEFAULT_COVER_SHEET,
  SendFaxInitiatedFrom,
} from "views/SendFax/contexts/store";

interface Timestamp {
  value: number;
}

interface ResumeFaxesProps {
  initiatedFrom?: SendFaxInitiatedFrom;
}

function ResumeFaxes({ initiatedFrom }: ResumeFaxesProps) {
  const {
    sendFaxDispatch,
    sendFaxStore: { destinations, files, defaultCoverSheet, wasSynced },
  } = useSendFaxContext();

  const dbRef = useRef(database);
  const isEnabledAdvancedSecurity = useAppSelector(selectIsEnabledAdvancedSecurity);

  const clearDatabase = useCallback(async () => {
    try {
      await clearFaxesDatabase();
    } catch {}
  }, []);

  const loadFromDatabase = useCallback(() => {
    const innerPromise = async () => {
      try {
        const db = dbRef.current;
        let lastSyncDays = 0;
        let files: SendFaxFiles = [];
        let destinations: Destinations = [];
        let defaultCoverSheet: DefaultCoverSheet | null = null;

        const promises = await Promise.allSettled([
          db.getFile<Timestamp, typeof TIMESTAMP>(TIMESTAMP),
          db.getFile<SendFaxFiles, typeof FAX_FILE>(FAX_FILE),
          db.getFile<Destination[], typeof DESTINATION>(DESTINATION),
          db.getFile<DefaultCoverSheet, typeof DEFAULT_COVER_SHEET>(DEFAULT_COVER_SHEET),
        ]);

        promises.forEach((result) => {
          if (result.status === "fulfilled") {
            switch (result.value.key) {
              case FAX_FILE:
                files = result.value.value;
                break;
              case DEFAULT_COVER_SHEET:
                defaultCoverSheet = result.value.value;
                break;
              case DESTINATION:
                destinations = result.value.value;
                break;
              case TIMESTAMP:
                const lastSyncDate = dayjs.unix(result.value.value.value);
                lastSyncDays = lastSyncDate.diff(dayjs(), "days");
                break;
            }
          }
        });

        if (lastSyncDays > 7) {
          // The date is too old, so we clear the database, and don't resume the files
          clearDatabase();
          return;
        }

        sendFaxDispatch({
          type: "INIT_FROM_INDEXEDDB",
          payload: { files, destinations, defaultCoverSheet },
        });
      } catch {
        clearDatabase();
      }
    };

    innerPromise().catch(() => undefined);
  }, [clearDatabase, sendFaxDispatch]);

  const saveToDatabase = useCallback(() => {
    const innerPromise = async () => {
      try {
        const db = dbRef.current;
        // The purpose of this effect is to always sync the context store with indexeddb
        if (wasSynced) {
          // Before saving new objects to the database, wait for the INIT_FROM_INDEXEDDB to finish
          // Otherwise we might overwrite the db with empty objects before retrieving them
          const timestamp = {
            value: dayjs().unix(),
          } satisfies Timestamp;

          await Promise.allSettled([
            db.putObject(FAX_FILE, files),
            db.putObject(TIMESTAMP, timestamp),
            db.putObject(DESTINATION, destinations),
            defaultCoverSheet
              ? db.putObject(DEFAULT_COVER_SHEET, defaultCoverSheet)
              : db.removeFile(DEFAULT_COVER_SHEET),
          ]);
        }
      } catch {
        clearDatabase();
      }
    };

    innerPromise().catch(() => undefined);
  }, [clearDatabase, defaultCoverSheet, files, destinations, wasSynced]);

  useEffect(() => {
    if (!isEnabledAdvancedSecurity && !initiatedFrom) {
      // Load from IndexedDB once, on mount
      loadFromDatabase();
    }
  }, [initiatedFrom, isEnabledAdvancedSecurity, loadFromDatabase]);

  useEffect(() => {
    if (initiatedFrom) {
      clearDatabase();
    }
  }, [clearDatabase, initiatedFrom]);

  useEffect(() => {
    if (initiatedFrom && !wasSynced) {
      sendFaxDispatch({
        type: "UPDATE_IS_SYNCED",
        payload: true,
      });
    }
  }, [initiatedFrom, sendFaxDispatch, wasSynced]);

  useEffect(() => {
    // The purpose of this effect is to always sync the context store with indexeddb
    if (!isEnabledAdvancedSecurity && wasSynced) {
      // Before saving new objects to the database, wait for the INIT_FROM_INDEXEDDB to finish
      // Otherwise we might overwrite the db with empty objects before retrieving them
      saveToDatabase();
    }
  }, [saveToDatabase, wasSynced, isEnabledAdvancedSecurity]);

  return null;
}

export async function clearFaxesDatabase() {
  await Promise.allSettled([
    database.removeFile(FAX_FILE),
    database.removeFile(TIMESTAMP),
    database.removeFile(DESTINATION),
    database.removeFile(DEFAULT_COVER_SHEET),
  ]);
}

export default memo(ResumeFaxes);
