import { Button } from "@alohi/kit";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import CancelPlanModal from "views/CancelPlan/CancelPlanModal";

function CancelPlanButton() {
  const { t } = useTranslation();

  const [openCancelPlan, setOpenCancelPlan] = useState(false);

  return (
    <>
      <Button variant="white" onClick={() => setOpenCancelPlan(true)}>
        {t("PROFILE.CANCEL_PLAN")}
      </Button>
      {openCancelPlan && <CancelPlanModal handleClosure={() => setOpenCancelPlan(false)} />}
    </>
  );
}

export default memo(CancelPlanButton);
