import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifications } from "api";
import RS from "enums/requestStatus";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialRefundState = {
  refund: {
    ...initialRequestState,
  },
};

const refund = createAsyncThunk(
  "PAYMENT/GET_PAYMENT_REFUND",
  async (planId, { rejectWithValue }) => {
    try {
      const response = await verifications.refund(planId);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const refundSlice = {
  [refund.pending]: (state) => {
    state.refund.response = null;
    state.refund.status = RS.RUNNING;
    state.refund.error = null;
  },
  [refund.fulfilled]: (state, action) => {
    state.refund.response = action.payload;
    state.refund.status = RS.IDLE;
    state.refund.error = null;
  },
  [refund.rejected]: (state, action) => {
    state.refund.response = null;
    state.refund.status = RS.ERROR;
    state.refund.error = action.payload;
  },
};

export { initialRefundState, refundSlice, refund };
