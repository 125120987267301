import PropTypes from "prop-types";
import { Typography, Box, makeStyles } from "@alohi/kit";

function FaxConfirmationLabel({ label, value }) {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Typography type="body" className={classes.label}>
        {label}:
      </Typography>
      <Typography type="body">{value}</Typography>
    </Box>
  );
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    fontSize: "1.1em",
    justifyContent: "center",
  },
  label: {
    fontWeight: "bolder",
    marginRight: "6px",
  },
});

FaxConfirmationLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FaxConfirmationLabel;
