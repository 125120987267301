import { useRouteMatch } from "react-router";

import { useAppSelector } from "stores/store";
import { contactsRoutes } from "enums/contacts";
import DeleteGroupModal from "views/Contacts/DeleteGroupModal";
import DeleteContactModal from "views/Contacts/DeleteContactModal";
import { selectIdsOfCheckedContacts } from "selectors/contacts.selector";
import ConfirmDeleteBlacklistModal from "../ConfirmDeleteBlacklistModal";

interface DeleteConfirmationProps {
  handleClosure: () => void;
}

function DeleteConfirmation({ handleClosure }: DeleteConfirmationProps) {
  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const idsOfCheckedContacts = useAppSelector(selectIdsOfCheckedContacts(contactsTab));

  if (contactsTab === contactsRoutes.whitelist) {
    return (
      <DeleteContactModal
        isSharedContact={false}
        handleClosure={handleClosure}
        contactIds={idsOfCheckedContacts}
      />
    );
  }

  if (contactsTab === contactsRoutes["shared-contacts"]) {
    return (
      <DeleteContactModal
        isSharedContact
        handleClosure={handleClosure}
        contactIds={idsOfCheckedContacts}
      />
    );
  }

  if (contactsTab === contactsRoutes.groups) {
    return (
      <DeleteGroupModal
        isSharedGroup={false}
        handleClosure={handleClosure}
        groupIds={idsOfCheckedContacts}
      />
    );
  }

  if (contactsTab === contactsRoutes["shared-groups"]) {
    return (
      <DeleteGroupModal
        isSharedGroup
        handleClosure={handleClosure}
        groupIds={idsOfCheckedContacts}
      />
    );
  }

  if (contactsTab === contactsRoutes.blacklist) {
    return (
      <ConfirmDeleteBlacklistModal
        onCancel={handleClosure}
        onConfirm={handleClosure}
        contactIds={idsOfCheckedContacts}
      />
    );
  }

  return null;
}

export default DeleteConfirmation;
