import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Box, useBool } from "@alohi/kit";

import { alohiAdminUrls } from "routes/urls";
import ChangePlan from "views/ChangePlan/ChangePlan";
import useAdminRedirections from "hooks/useAdminRedirections";

function UpgradeToChangeDialogue({ handleClosure }) {
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: isChangePlanOpenBool.setTrue,
    });
  }, [redirectToAdmin, isChangePlanOpenBool]);

  const handleUpgrade = useCallback(
    (isSucces) => {
      if (isSucces) {
        handleClosure(true);
      }
      isChangePlanOpenBool.setFalse();
    },
    [handleClosure, isChangePlanOpenBool],
  );

  return (
    <>
      <Modal
        maxWidth="xs"
        isAllowingOverlap
        onConfirm={handleOpenChangePlan}
        title={t("CHANGE_PLAN.UPGRADE_PLAN")}
        onCancel={() => handleClosure(false)}
        confirmTitle={t("CHANGE_PLAN.UPGRADE_PLAN")}
      >
        <Box p={2}>
          <Typography variant="body">
            {t("DESCRIPTIONS.PLEASE_UPGRADE_YOUR_ACCOUNT_TO_CHANGE_THE_ANNOUNCEMENT_DIALOGUE")}
          </Typography>
        </Box>
      </Modal>

      {isChangePlanOpen ? <ChangePlan handleClosure={handleUpgrade} /> : null}
    </>
  );
}

UpgradeToChangeDialogue.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default UpgradeToChangeDialogue;
