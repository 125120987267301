import queryString from "query-string";
import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";
const ENTITY = "sync_history";

const commentById = (id, text) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      {
        op: "comment",
        path: id,
        text,
      },
    ],
  };
  const path = `${API_AFFOGATO}/${ENTITY}`;
  return fetchWithRefresh(path, requestOptions);
};

const sharedNoteById = (id, text) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      {
        op: "shared_comment",
        path: id,
        text,
      },
    ],
  };
  const path = `${API_AFFOGATO}/${ENTITY}`;
  return fetchWithRefresh(path, requestOptions);
};

const deleteByIds = (ids) => {
  const requestOptions = {
    method: "DELETE",
    body: {
      ids,
      delete_type: "permanent",
      sync_history: true, // emit the sync history event (defaults to true)
    },
  };
  const path = `${API_AFFOGATO}/history/all`;
  return fetchWithRefresh(path, requestOptions);
};

const moveToTrashByIds = (ids) => {
  const requestOptions = {
    method: "DELETE",
    body: {
      ids,
      delete_type: "trash",
      sync_history: true, // emit the sync history event (defaults to true)
    },
  };
  const path = `${API_AFFOGATO}/history/all`;
  return fetchWithRefresh(path, requestOptions);
};

const restoreFromTrashByIds = (ids) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      ids,
      op: "restore",
      sync_history: true, // emit the sync history event (defaults to true)
    },
  };
  const path = `${API_AFFOGATO}/history/all`;
  return fetchWithRefresh(path, requestOptions);
};

const markAsReadByIds = (ids) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      ids,
      op: "mark_as_read",
      sync_history: true, // emit the sync history event (defaults to true)
    },
  };
  const path = `${API_AFFOGATO}/history/all`;
  return fetchWithRefresh(path, requestOptions);
};

const markAsUnreadByIds = (ids) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      ids,
      op: "mark_as_unread",
      sync_history: true, // emit the sync history event (defaults to true)
    },
  };
  const path = `${API_AFFOGATO}/history/all`;
  return fetchWithRefresh(path, requestOptions);
};

const getSyncHistory = ({
  limit = 1000,
  number = "all",
  since = "9999-01-01 00:00:00",
  order = "asc",
  memberUid,
}) => {
  const requestOptions = {
    method: "GET",
  };
  const qs = { limit, number, order };
  if (since) {
    qs.since = since;
  }
  if (memberUid) {
    qs.member_id = memberUid;
  }
  const path = `${API_AFFOGATO}/${ENTITY}?${queryString.stringify(qs)}`;
  return fetchWithRefresh(path, requestOptions);
};

export const syncHistoryApi = {
  commentById,
  sharedNoteById,
  deleteByIds,
  moveToTrashByIds,
  restoreFromTrashByIds,
  markAsReadByIds,
  markAsUnreadByIds,
  getSyncHistory,
};
