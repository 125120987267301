import { useSelector } from "react-redux";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, TextButton, Box } from "@alohi/kit";

import { Typography, makeStyles } from "ui";
import { paymentTypes } from "enums/payment";
import PaymentMethodImg from "components/Core/PaymentMethod/PaymentMethodImg";
import SelectPaymentMethod from "views/SelectPaymentMetod/SelectPaymentMethod";
import {
  selectSelectedPaymentMethod,
  selectSelectedPaymentLast4Digits,
} from "selectors/payment.selector";
import FormRow from "../Forms/FormRow";

function PaymentMethodInput() {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedPayment = useSelector(selectSelectedPaymentMethod);
  const last4Digits = useSelector(selectSelectedPaymentLast4Digits);

  const [openSelectPaymentMethod, setOpenSelectPaymentMethod] = useState(false);

  const changeMethod = useMemo(
    () => (
      <TextButton
        className={classes.change}
        onClick={() => setOpenSelectPaymentMethod((prev) => !prev)}
      >
        {t("COMMON.CHANGE")}
      </TextButton>
    ),
    [t, classes.change],
  );

  if (!selectedPayment) {
    return null;
  }

  const isPaypal = selectedPayment?.type === paymentTypes.PAYPAL;
  const selectedValue = selectedPayment.brand || selectedPayment.type;

  return (
    <>
      <FormRow htmlFor={"payment-method"} label={t("ADD_CREDIT.PAYMENT_METHOD")}>
        <Select
          disabled
          fullWidth
          displayEmpty
          variant="outlined"
          id="payment-method"
          value={selectedValue}
          className={classes.select}
          labelId="payment-method-label"
          IconComponent={() => changeMethod}
          classes={{ select: classes.selectInput }}
        >
          <MenuItem value={selectedValue}>
            <PaymentMethodImg brand={selectedPayment.brand || selectedPayment.type} />
            <Box className={classes.methodText}>
              {isPaypal ? (
                <Typography variant="body" className={classes.email}>
                  {selectedPayment.email}
                </Typography>
              ) : (
                <Box className={classes.creditCard}>
                  <Typography>{`**** **** **** ${last4Digits}`}</Typography>
                  <Typography>{`${t("ADD_CREDIT.EXP")} ${selectedPayment.exp_month}/${
                    selectedPayment.exp_year
                  }`}</Typography>
                </Box>
              )}
            </Box>
          </MenuItem>
        </Select>
      </FormRow>
      {openSelectPaymentMethod ? (
        <SelectPaymentMethod handleClosure={() => setOpenSelectPaymentMethod(false)} />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  selectInput: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    paddingRight: "12px",
    justifyContent: "flex-start",
    background: "white !important",
  },
  change: {
    "& span": {
      color: alohi.red,
    },
  },
  select: {
    height: "58px !important",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "12px",
    justifyContent: "space-between",
    "& .MuiSelect-outlined": {
      paddingRight: spacing(1),
    },
    background: "white !important",
  },
  methodText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: spacing(1),
  },
  email: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  creditCard: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default PaymentMethodInput;
