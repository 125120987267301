import { ReactNode, useEffect } from "react";
import { Box, makeStyles } from "@alohi/kit";

import DevFab from "components/DevFab/DevFab";
import { allowedRoutesForSuggestingMobile, urls } from "routes/urls";
import SuggestMobile from "./SuggestMobile/SuggestMobile";

interface PublicLayoutProps {
  children: ReactNode;
}

function PublicLayout({ children }: PublicLayoutProps) {
  const classes = useStyles();

  useEffect(() => {
    window.__loadingScreen?.hide?.();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.mainContent}>{children}</Box>
      <SuggestMobile uri={urls.login} pathnames={allowedRoutesForSuggestingMobile} />
      <DevFab />
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  mainContent: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    padding: spacing(4),
  },
}));

export default PublicLayout;
