import { useEffect, useState } from "react";

function useObjectToUrl(object) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!object) {
      setUrl("");
      return;
    }
    let url = URL.createObjectURL(object);
    setUrl(url);
    return () => URL.revokeObjectURL(url);
  }, [object]);

  return url;
}

export default useObjectToUrl;
