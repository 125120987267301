import { InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    SignPlusPlanSubscriptionItem: {
      keyFields: false,
    },
    FaxPlusPlanSubscriptionItem: {
      keyFields: false,
    },
    FaxPlusNumberSubscriptionItem: {
      keyFields: false,
    },
  },
  possibleTypes: {
    SubscriptionItem: [
      "SignPlusPlanSubscriptionItem",
      "FaxPlusPlanSubscriptionItem",
      "FaxPlusNumberSubscriptionItem",
    ],
  },
});
