import { Box, makeStyles, SvgBox, theme, Typography } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import FramedSignPlusLogo from "assets/svg/sign_plus_framed_logo_text.svg";
import { alohi } from "@alohi/kit";
import IncentiveButton from "./IncentiveButton";
import { useBool } from "@alohi/kit";
import { MouseEvent } from "react";
import IncentiveModal from "./modals/IncentiveModal";
import { useMediaQuery } from "@alohi/kit";

interface IncentiveBannerProps {
  className?: string;
}

function IncentiveBanner({ className }: IncentiveBannerProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const [isIncentiveModalOpen, isIncentiveModalOpenBool] = useBool(false);

  const handleOpenIncentiveModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    isIncentiveModalOpenBool.setTrue();
  };

  const handleCloseIncentiveModal = () => {
    isIncentiveModalOpenBool.setFalse();
  };

  return (
    <Box className={clsx(classes.base, className)}>
      <SvgBox className={classes.logo} src={FramedSignPlusLogo} />
      {isMobileLayout ? undefined : <Box className={classes.line} />}
      <Typography>{t("INCENTIVE_SIGN.UNLOCK_OUR_ESIGNATURE")}</Typography>
      <IncentiveButton className={classes.incentiveButton} onClick={handleOpenIncentiveModal} />
      {isIncentiveModalOpen ? <IncentiveModal onClose={handleCloseIncentiveModal} /> : null}
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    display: "flex",
    alignItems: "center",
    [breakpoints.down("md")]: {
      padding: spacing(2, 0, 2, 0),
      flexDirection: "column",
      textAlign: "center",
    },
  },
  logo: {
    width: "97px",
    [breakpoints.down("md")]: {
      marginBottom: spacing(2),
    },
  },
  line: {
    margin: spacing(0.2, 1, 0, 1),
    borderWidth: 0.5,
    borderColor: alohi.neutral900,
    borderStyle: "solid",
    height: "1px",
    width: "14px",
  },
  incentiveButton: {
    marginLeft: spacing(2),
    [breakpoints.down("md")]: {
      marginTop: spacing(2),
    },
  },
}));

export default IncentiveBanner;
