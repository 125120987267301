import createSelector from "selectorator";

import { Contact, NetworkContactData } from "api/types/storage";

import RS from "../enums/requestStatus";
import { isValidFaxNumber, isValidPhoneNumber } from "../helpers/inputValidation";
import i18n from "../i18n/index";
import { sentryCaptureMessage } from "helpers/sentry";

export enum ContactsErrors {
  LIST_EMPTY = "listEmpty",
  UNHEALTHY_DATA = "unhealthyData",
  UNKOWN_ERROR = "unknownError",
}

export type ContactsData = {
  error: ContactsErrors | null;
  invalidPhoneCount: number;
  invalidFaxCount: number;
  list: Contact[];
};

export const selectIsContactsFilesRunning = createSelector(
  ["storage.contactsDocuments.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsContactsFilesError = createSelector(
  ["storage.contactsDocuments.error"],
  (error) => {
    if (!error?.reason) {
      return null;
    }
    switch (error?.reason) {
      case "bad_file_format":
        return i18n.t("CONTACTS.FILE_UPLOADER_HAVE_PROBLEM");

      default:
        sentryCaptureMessage({
          message: `Something went wrong when importing from contacts from Excel`,
          level: "error",
          breadcumbs: {
            category: "fetch",
            level: "warning",
            message: JSON.stringify(error),
          },
        });
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectUploadedContactsFiles = createSelector(
  ["storage.contactsDocuments.files"],
  (contacts: { contacts: NetworkContactData[] }[]) => {
    const contactsData: ContactsData = {
      error: null,
      invalidPhoneCount: 0,
      invalidFaxCount: 0,
      list: [],
    };

    const numOfContacts = contacts?.[0]?.contacts.length ?? 0;
    const list: Contact[] =
      contacts?.[0]?.contacts.reduce((acc: Contact[], element) => {
        const { fullname, fax_number, ...rest } = element.contact;
        if (fax_number) {
          const isValidPhone = isValidPhoneNumber(fax_number);
          if (!isValidPhone) {
            contactsData.invalidPhoneCount += 1;
          }

          const isValidFax = isValidFaxNumber(fax_number);
          if (!isValidPhone) {
            contactsData.invalidFaxCount += 1;
          }

          if (isValidPhone || isValidFax) {
            acc.push({
              ...rest,
              numOfContacts,
              nameAndDuplicate: {
                name: fullname,
                isDuplicate: element.is_duplicate,
              },
              faxNumber: fax_number,
              status: element.validation.status,
            });
          }
        }
        return acc;
      }, []) ?? [];

    if (numOfContacts && list.length === 0) {
      contactsData.error = ContactsErrors.LIST_EMPTY;
    } else if (numOfContacts > list.length) {
      contactsData.error = ContactsErrors.UNHEALTHY_DATA;
    }
    contactsData.list = list;
    return contactsData;
  },
);

export const selectUploadedProfileImage = createSelector(
  ["storage.profileImage.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.path;
  },
);

export const selectIsUploadedProfileRunning = createSelector(
  ["storage.profileImage.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUploadedProfileError = createSelector(
  ["storage.profileImage.status"],
  (status) => status === RS.ERROR,
);

export const selectIsUploadApiLogoSuccess = createSelector(
  ["storage.uploadApiLogo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsUploadApiLogoError = createSelector(
  ["storage.uploadApiLogo.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);

export const selectIsUploadApiLogoRunning = createSelector(
  ["storage.uploadApiLogo.status"],
  (status) => status === RS.RUNNING,
);

export const selectUploadedApiLogo = createSelector(
  ["storage.uploadApiLogo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.path;
  },
);
