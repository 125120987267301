import { useLocation } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Checkbox,
  TextField,
  TextButton,
  Typography,
  useSnackbar,
  ComboFields,
} from "@alohi/kit";

import { Grid } from "ui";
import Link from "routes/Link";
import { websiteUrls } from "routes/urls";
import { useInput } from "hooks/useInput";
import { twoFaActions } from "enums/twoFa";
import ActivateTwoFaModal from "views/TwoFa/ActivateTwoFaModal";
import { clearLogin, clearRegister, login, register } from "stores/reducers/authentication.reducer";
import {
  isValidEmail,
  isValidPassword,
  isValidAccountName,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "helpers/inputValidation";
import {
  selectTwoFaAction,
  selectRegisterError,
  selectIsRegisterSuccess,
  selectLoginErrorNotification,
} from "selectors/authentication.selector";
import LoginWithGoogle from "./LoginWithGoogle";
import { useOnMount } from "@alohi/kit";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

interface SignUpFormProps {
  email?: string;
  token?: string;
  referrerUid?: string;
  disableMemberSection?: boolean;
}

function SignUpForm({ email, token, referrerUid, disableMemberSection = false }: SignUpFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation<{ redirectTo: string }>();

  const twoFaAction = useSelector(selectTwoFaAction);
  const registerError = useSelector(selectRegisterError);
  const loginError = useSelector(selectLoginErrorNotification);
  const isRegisterSuccess = useSelector(selectIsRegisterSuccess);

  const { sendCustomEvent } = useGoogleTagManager();

  const [passwordValue, passwordInput] = useInput("", isValidPassword);
  const [emailValue, emailInput] = useInput(email ?? "", isValidEmail);
  const [lastNameValue, lastNameInput] = useInput("", isValidAccountName);
  const [firstNameValue, firstNameInput] = useInput("", isValidAccountName);

  const [isLoading, setIsLoading] = useState(false);
  const [readTermsChecked, setReadTermsChecked] = useState(false);
  const [isTwoFaActivationModalOpen, setIsTwoFaActivationModalOpen] = useState(false);

  useEffect(() => {
    if (twoFaAction === twoFaActions.enableTwoFa) {
      setIsTwoFaActivationModalOpen(true);
      setIsLoading(false);
    }
  }, [dispatch, twoFaAction]);

  useEffect(() => {
    if (registerError) {
      enqueueSnackbar(registerError, { variant: "error" });
      setIsLoading(false);
      dispatch(clearRegister());
    }
  }, [dispatch, emailInput, enqueueSnackbar, registerError, t]);

  useEffect(() => {
    if (loginError && isRegisterSuccess) {
      enqueueSnackbar(loginError, { variant: "error" });
      setIsLoading(false);
      dispatch(clearLogin());
    }
  }, [dispatch, enqueueSnackbar, isRegisterSuccess, loginError, passwordInput]);

  useOnMount(() => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.signUp,
    });
  }, isRegisterSuccess);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    firstNameInput.touch();
    lastNameInput.touch();
    emailInput.touch();
    passwordInput.touch();

    if (
      firstNameInput.isValid &&
      lastNameInput.isValid &&
      emailInput.isValid &&
      passwordInput.isValid
    ) {
      if (!readTermsChecked) {
        enqueueSnackbar(t("SIGNUP.AGREE_ERROR"), { variant: "error" });
      } else {
        setIsLoading(true);

        if (isRegisterSuccess) {
          dispatch(
            login({
              username: emailValue,
              password: passwordValue,
            }),
          );
        } else {
          dispatch(
            register({
              name: firstNameValue,
              lastname: lastNameValue,
              email: emailValue,
              password: passwordValue,
              ...(referrerUid && { referrer_uid: referrerUid }),
              ...(token && {
                invitation_token: token,
                account_type: "corporate_member",
              }),
            }),
          );
        }
      }
    }
  };

  const isEmailDisabled = Boolean(email) && Boolean(token);

  useEffect(() => {
    return () => {
      dispatch(clearRegister());
    };
  }, [dispatch]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ComboFields defaultHelperText={t("FORMS.USE_NUMBERS_AND_SYMBOLS")}>
          <TextField
            id="firstNameInput"
            name="firstName"
            placeholder={t("COMMON.FIRST_NAME")}
            autoComplete="name"
            value={firstNameValue}
            onBlur={firstNameInput.onBlur}
            onFocus={firstNameInput.onFocus}
            error={firstNameInput.showsError}
            onChange={firstNameInput.onChange}
            helperText={firstNameInput.showsError ? t("FORMS.PLEASE_ENTER_A_VALID_NAME") : ""}
          />
          <TextField
            name="lastName"
            id="lastNameInput"
            autoComplete="lastname"
            placeholder={t("COMMON.LAST_NAME")}
            value={lastNameValue}
            onBlur={lastNameInput.onBlur}
            onFocus={lastNameInput.onFocus}
            error={lastNameInput.showsError}
            onChange={lastNameInput.onChange}
            helperText={lastNameInput.showsError ? t("FORMS.PLEASE_ENTER_A_VALID_NAME") : ""}
          />
          <TextField
            id="email"
            name="email"
            placeholder={t("COMMON.EMAIL")}
            type="email"
            autoComplete="username"
            value={emailValue}
            onBlur={emailInput.onBlur}
            onFocus={emailInput.onFocus}
            isDisabled={isEmailDisabled}
            error={emailInput.showsError}
            onChange={emailInput.onChange}
            helperText={emailInput.showsError ? t("FORMS.INVALID_EMAIL") : ""}
          />
          <TextField
            id="password"
            name="password"
            type="password"
            placeholder={t("FORMS.PASSWORD")}
            autoComplete="new-password"
            value={passwordValue}
            onBlur={passwordInput.onBlur}
            onFocus={passwordInput.onFocus}
            error={passwordInput.showsError}
            onChange={passwordInput.onChange}
            helperText={
              passwordInput.showsError
                ? passwordValue.length > MAX_PASSWORD_LENGTH
                  ? t("FORMS.PASSWORD_TOO_LONG", {
                      max: MAX_PASSWORD_LENGTH,
                    })
                  : t("FORMS.PASSWORD_TOO_SHORT", {
                      min: MIN_PASSWORD_LENGTH,
                    })
                : ""
            }
          />
        </ComboFields>

        <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
          <Checkbox
            name="readTerms"
            checked={readTermsChecked}
            sx={{ marginLeft: "-11px" }}
            data-cy="FaxPlusTermsCheckbox"
            onChange={(event) => setReadTermsChecked(event.target.checked)}
          />
          <Typography type="body">
            <Trans
              i18nKey="SIGNUP.AGREE"
              components={{
                termsLink: (
                  <a key={0} target="_blank" rel="noopener noreferrer" href={websiteUrls.terms}>
                    terms
                  </a>
                ),
              }}
            />
          </Typography>
        </Box>

        <Button size="large" fullWidth type="submit" isLoading={isLoading}>
          <Typography type="loginButton">{t("SIGNUP.SIGNUP_FOR_FREE")}</Typography>
        </Button>

        <Divider sx={{ my: 3 }} text={t("SIGNUP.OR_SIGN_UP_WITH")} />

        <Grid container spacing={2}>
          <Grid item sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <LoginWithGoogle
              referrerUid={referrerUid}
              invitationToken={token}
              invitationEmail={email}
            />
          </Grid>
        </Grid>

        {!disableMemberSection ? (
          <>
            <Divider sx={{ my: 3 }} />
            <Typography type="body" sx={{ display: "flex", alignItems: "center" }}>
              {t("SIGNUP.ALREADY_MEMBER")}
              <Link
                to={{
                  pathname: `/login`,
                  state: { redirectTo: location.state?.redirectTo },
                }}
              >
                <TextButton sx={{ ml: 1 }}>{t("COMMON.LOG_IN")}</TextButton>
              </Link>
            </Typography>
          </>
        ) : null}
      </form>

      {isTwoFaActivationModalOpen && (
        <ActivateTwoFaModal handleClosure={() => setIsTwoFaActivationModalOpen(false)} />
      )}
    </>
  );
}

export default SignUpForm;
