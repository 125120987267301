import {
  makeStyles,
  CodeInput as KitCodeInput,
  CodeInputProps as KitCodeInputProps,
} from "@alohi/kit";

type CodeInputProps = KitCodeInputProps;

function CodeInput(props: CodeInputProps) {
  const classes = useStyles({ isError: props.isError ?? false });

  return <KitCodeInput className={classes.container} {...props} />;
}

const useStyles = makeStyles({
  container: {
    display: "inline-block",
    "&& input": {
      fontSize: "32px !important",
    },
  },
});

export default CodeInput;
