import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { TextField, Panel, Box, useSnackbar } from "@alohi/kit";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import { useAppDispatch } from "stores/store";
import { isValidDocumentRetentionDays } from "helpers/inputValidation";
import DocumentRetentionPeriodModal from "views/Profile/DocumentRetentionPeriodModal";
import { Radio, RadioGroup, Typography, makeStyles, FormControlLabel } from "ui";
import {
  getCustomerInfo,
  clearDocumentRetention,
  updateDocumentRetention,
} from "stores/reducers/account.reducer";
import {
  selectIncomingRetentionDays,
  selectOutgoingRetentionDays,
  selectIsDocumentRetentionError,
  selectIsDocumentRetentionRunning,
  selectIsDocumentRetentionSuccess,
} from "selectors/account.selector";
import DocumentRetentionTitle from "./DocumentRetentionTitle";
import DocumentRetentionEditButton from "./DocumentRetentionEditButton";

function DocumentRetentionPanel() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isFlipped, isFlippedBool] = useBool(false);
  const [isConfirmationModalOpen, isConfirmationModalOpenBool] = useBool(false);

  const incomingRetentionDays = useSelector(selectIncomingRetentionDays);
  const outgoingRetentionDays = useSelector(selectOutgoingRetentionDays);
  const isIncomingRetentionDaysUnlimited = incomingRetentionDays === null;
  const isOutgoingRetentionDaysUnlimited = outgoingRetentionDays === null;

  const isDocumentRetentionError = useSelector(selectIsDocumentRetentionError);
  const isDocumentRetentionRunning = useSelector(selectIsDocumentRetentionRunning);
  const isDocumentRetentionSuccess = useSelector(selectIsDocumentRetentionSuccess);

  const [sentFaxesSelect, setSentFaxesSelect] = useState("");
  const [receivedFaxesSelect, setReceivedFaxesSelect] = useState("");
  const [sentFaxes, sentFaxesInput] = useInput<string | undefined>(
    undefined,
    isValidDocumentRetentionDays,
  );
  const [receivedFaxes, receivedFaxesInput] = useInput<string | undefined>(
    undefined,
    isValidDocumentRetentionDays,
  );

  const handleOnSave = useCallback(() => {
    sentFaxesInput.touch();
    receivedFaxesInput.touch();

    if (sentFaxesSelect === "days" && !sentFaxesInput.isValid) {
      return;
    }
    if (receivedFaxesSelect === "days" && !receivedFaxesInput.isValid) {
      return;
    }

    dispatch(
      updateDocumentRetention({
        outgoingRetentionDays: sentFaxesSelect === "unlimited" ? null : Number(sentFaxes),
        incomingRetentionDays: receivedFaxesSelect === "unlimited" ? null : Number(receivedFaxes),
      }),
    );
  }, [
    dispatch,
    sentFaxes,
    receivedFaxes,
    sentFaxesInput,
    sentFaxesSelect,
    receivedFaxesInput,
    receivedFaxesSelect,
  ]);

  const handleOnCancel = useCallback(() => {
    sentFaxesInput.unTouch();
    sentFaxesInput.setValue(undefined);
    setSentFaxesSelect("");

    receivedFaxesInput.unTouch();
    receivedFaxesInput.setValue(undefined);
    setReceivedFaxesSelect("");

    isFlippedBool.setFalse();
  }, [isFlippedBool, receivedFaxesInput, sentFaxesInput]);

  useEffect(() => {
    if (isDocumentRetentionSuccess) {
      dispatch(getCustomerInfo());
      dispatch(clearDocumentRetention());
      isFlippedBool.setFalse();
    }
  }, [dispatch, isDocumentRetentionSuccess, isFlippedBool]);

  useEffect(() => {
    if (isDocumentRetentionError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearDocumentRetention());
    }
  }, [dispatch, enqueueSnackbar, isDocumentRetentionError, t]);

  useEffect(() => {
    // Initialize both the select components based on the backend data
    if (sentFaxesSelect === "" && receivedFaxesSelect === "") {
      if (isIncomingRetentionDaysUnlimited) {
        setReceivedFaxesSelect("unlimited");
      } else if (!Number.isNaN(Number(incomingRetentionDays))) {
        setReceivedFaxesSelect("days");
      }

      if (isOutgoingRetentionDaysUnlimited) {
        setSentFaxesSelect("unlimited");
      } else if (!Number.isNaN(Number(outgoingRetentionDays))) {
        setSentFaxesSelect("days");
      }
    }
  }, [
    sentFaxesSelect,
    receivedFaxesSelect,
    incomingRetentionDays,
    outgoingRetentionDays,
    isIncomingRetentionDaysUnlimited,
    isOutgoingRetentionDaysUnlimited,
  ]);

  useEffect(() => {
    // Initialize the input to the correct backend data only once
    if (sentFaxes === undefined && receivedFaxes === undefined) {
      if (isIncomingRetentionDaysUnlimited) {
        receivedFaxesInput.setValue("");
      } else if (!Number.isNaN(Number(incomingRetentionDays))) {
        receivedFaxesInput.setValue(incomingRetentionDays);
      }

      if (isOutgoingRetentionDaysUnlimited) {
        sentFaxesInput.setValue("");
      } else if (!Number.isNaN(Number(outgoingRetentionDays))) {
        sentFaxesInput.setValue(outgoingRetentionDays);
      }
    }
  }, [
    sentFaxes,
    receivedFaxes,
    sentFaxesInput,
    receivedFaxesInput,
    incomingRetentionDays,
    outgoingRetentionDays,
    isIncomingRetentionDaysUnlimited,
    isOutgoingRetentionDaysUnlimited,
  ]);

  return (
    <>
      <Panel
        sx={{ mb: 7 }}
        isFlipped={isFlipped}
        id="document-retention-panel"
        data-cy={dataCy.documentRetentionPanel}
        headerTitle={<DocumentRetentionTitle isFlipped={isFlipped} />}
      >
        <Panel.Front headerAction={<DocumentRetentionEditButton isFlippedBool={isFlippedBool} />}>
          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.DOCUMENT_RETENTION_RECEIVED_FAXES")}
            </Panel.Cell>
            <Panel.Cell variant="data">
              {isIncomingRetentionDaysUnlimited
                ? t("PROFILE.DOCUMENT_RETENTION_UNLIMITED")
                : t("PROFILE.DOCUMENT_RETENTION_DAYS", {
                    count: incomingRetentionDays,
                  })}
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.DOCUMENT_RETENTION_SENT_FAXES")}</Panel.Cell>
            <Panel.Cell variant="data">
              {isOutgoingRetentionDaysUnlimited
                ? t("PROFILE.DOCUMENT_RETENTION_UNLIMITED")
                : t("PROFILE.DOCUMENT_RETENTION_DAYS", {
                    count: outgoingRetentionDays,
                  })}
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>

        <Panel.Back
          onConfirm={() => {
            if (sentFaxesSelect === "days" || receivedFaxesSelect === "days") {
              isConfirmationModalOpenBool.setTrue();
            } else {
              handleOnSave();
            }
          }}
          onCancel={handleOnCancel}
          isConfirmLoading={isDocumentRetentionRunning}
          isConfirmDisabled={
            isDocumentRetentionError ||
            (sentFaxesInput.showsError && sentFaxesSelect === "days") ||
            (receivedFaxesInput.showsError && receivedFaxesSelect === "days")
          }
        >
          <Panel.Row>
            <Panel.Cell variant="title">
              {t("PROFILE.DOCUMENT_RETENTION_RECEIVED_FAXES")}
            </Panel.Cell>
            <Panel.Cell variant="data">
              <RadioGroup
                name="receivedFaxes"
                value={receivedFaxesSelect}
                className={classes.radiosRow}
                aria-label="received-faxes-radio"
                onChange={({ target }) => {
                  setReceivedFaxesSelect(target.value);
                }}
              >
                <FormControlLabel
                  value="unlimited"
                  control={<Radio color="primary" />}
                  label={t("PROFILE.DOCUMENT_RETENTION_UNLIMITED")}
                />
                <FormControlLabel
                  value="days"
                  control={<Radio data-cy={dataCy.receivedFaxesSelect} color="primary" />}
                  label={
                    <Box className={classes.days}>
                      <TextField
                        required
                        type="number"
                        sx={{ width: 100 }}
                        value={receivedFaxes}
                        onBlur={receivedFaxesInput.onBlur}
                        onFocus={receivedFaxesInput.onFocus}
                        error={receivedFaxesInput.showsError}
                        onChange={receivedFaxesInput.onChange}
                        isDisabled={receivedFaxesSelect === "unlimited"}
                        inputProps={{
                          min: 1,
                          max: 1825,
                          "data-cy": dataCy.receivedFaxesInput,
                        }}
                      />
                      <Typography sx={{ "&&": { ml: 1 } }}>
                        {t("PROFILE.DOCUMENT_RETENTION_DAYS_OPTION")}
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.DOCUMENT_RETENTION_SENT_FAXES")}</Panel.Cell>
            <Panel.Cell variant="data">
              <RadioGroup
                name="sentFaxes"
                value={sentFaxesSelect}
                aria-label="sent-faxes-radio"
                className={classes.radiosRow}
                onChange={({ target }) => {
                  setSentFaxesSelect(target.value);
                }}
              >
                <FormControlLabel
                  value="unlimited"
                  control={<Radio color="primary" />}
                  label={t("PROFILE.DOCUMENT_RETENTION_UNLIMITED")}
                />
                <FormControlLabel
                  value="days"
                  control={<Radio data-cy={dataCy.sentFaxesSelect} color="primary" />}
                  label={
                    <Box className={classes.days}>
                      <TextField
                        required
                        type="number"
                        value={sentFaxes}
                        sx={{ width: 100 }}
                        onBlur={sentFaxesInput.onBlur}
                        onFocus={sentFaxesInput.onFocus}
                        error={sentFaxesInput.showsError}
                        onChange={sentFaxesInput.onChange}
                        isDisabled={sentFaxesSelect === "unlimited"}
                        inputProps={{
                          min: 1,
                          max: 1825,
                          "data-cy": dataCy.sentFaxesInput,
                        }}
                      />
                      <Typography sx={{ "&&": { ml: 1 } }}>
                        {t("PROFILE.DOCUMENT_RETENTION_DAYS_OPTION")}
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Back>
      </Panel>

      {isConfirmationModalOpen ? (
        <DocumentRetentionPeriodModal
          handleClosure={(isSuccess) => {
            if (isSuccess) {
              handleOnSave();
            }
            isConfirmationModalOpenBool.setFalse();
          }}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(() => ({
  days: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  radiosRow: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default DocumentRetentionPanel;
