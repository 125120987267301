import { Box, Typography } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { SuccessAlert } from "ui";

function RequestSent() {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Typography type="authenticationTitle">{t("COMMON.SUCCESS")}</Typography>
      <Typography type="body">{t("VERIFY.WE_HAVE_SENT_YOU_EMAIL_RESET_PASSWORD")}</Typography>
      <SuccessAlert />
    </Box>
  );
}

export default RequestSent;
