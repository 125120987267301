import createSelector from "selectorator";

export const selectSyncHistoryLastUpdate = createSelector(
  ["syncHistory.lastUpdate"],
  (lastUpdate) => lastUpdate,
);

export const selectTotalUnreadFaxesCount = createSelector(
  ["syncHistory.getSyncHistoryRequest.response"],
  (response) => {
    if (!response) return null;
    if (response.unread) {
      return Object.values(response.unread).reduce((acc, current) => acc + current, 0);
    }
    return 0;
  },
);

export const selectSyncHistoryEvents = createSelector(["syncHistory.events"], (events) => events);
