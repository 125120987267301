import { useState } from "react";
import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faHashtag } from "@fortawesome/pro-light-svg-icons";

import { capitalizeString } from "helpers/string";
import planTypeImages from "enums/planTypeImages";
import Currency from "components/Core/Currency/Currency";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import { Avatar, Chip, Collapse, makeStyles, Typography, useTheme } from "ui";

function GroupHeadingPlan(props) {
  const classes = useStyles();
  const { onClick, ...restProps } = props;
  const [up, setUp] = useState(false);
  const handleClick = () => {
    setUp(onClick());
  };
  // @TODO better solutin for this hack
  const isLast = props.children === "enterprise";
  return (
    <Box
      onClick={handleClick}
      data-cy="PlanGroupHeading"
      className={`${classes.groupHeadingWrapper} ${isLast ? classes.groupHeadingWrapperLast : ""}`}
    >
      <Avatar
        className={classes.avatar}
        alt={props.children + "_plan"}
        src={planTypeImages[props.children]}
      />
      <Box mt={0.5} flex={1}>
        <components.GroupHeading {...restProps} />
      </Box>
      <FontAwesomeIcon icon={!up ? faCaretDown : faCaretUp} />
    </Box>
  );
}

function SingleValuePlan({ children, ...props }) {
  const classes = useStyles();
  return (
    <components.SingleValue {...props}>
      <Box display="flex" alignItems="center">
        <Avatar
          className={classes.avatar}
          src={planTypeImages[props.data.plan_type]}
          alt={props.data.plan_type}
        />
        <Box mr={1}>{capitalizeString(props.data.plan_type)}</Box>
        <Box color="gray" sx={{ fontSize: 12, marginTop: "2px" }}>
          {children}
        </Box>
      </Box>
    </components.SingleValue>
  );
}

function OptionPlan({ children, ...props }) {
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between" data-cy="PlanOption">
        <Box color="inherit">{children}</Box>
        <Box display="flex" alignItems="center">
          <Box fontWeight="fontWeightBold">
            <Currency currency={props.data.currency} value={props.data.price} />
          </Box>
          /
          <Box color="inherit">
            {props.data.duration_unit === "month" ? t("COMMON.MONTH") : t("COMMON.YEAR")}
          </Box>
        </Box>
      </Box>
    </components.Option>
  );
}

function GroupPlan(props) {
  const [inCollapse, setInCollapse] = useState(false);
  props.headingProps.onClick = () => {
    const result = !inCollapse;
    setInCollapse(result);
    return result;
  };
  return (
    <components.Group {...props}>
      <Collapse
        unmountOnExit
        timeout="auto"
        in={inCollapse}
        style={{
          borderBottom: "solid 1px #f0f0f0",
        }}
      >
        {props.children}
      </Collapse>
    </components.Group>
  );
}

function SingleValueCountry({ children, ...props }) {
  return (
    <components.SingleValue {...props}>
      {props.getValue() && props.getValue().length && props.getValue()[0].value && (
        <CountryFlag
          code={props.getValue()[0].value}
          style={{
            marginRight: "12px",
            marginBottom: "3px",
          }}
        />
      )}
      <Typography variant="body" component="span">
        {children}
      </Typography>
    </components.SingleValue>
  );
}
SingleValueCountry.propTypes = {
  children: PropTypes.node,
};

function OptionCountry(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { children, data, selectProps, ...rest } = props;

  return (
    <components.Option
      {...rest}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <CountryFlag
          code={props.value}
          style={{
            width: "30px",
            height: "30px",
            marginRight: "8px",
          }}
        />
        <Box display="flex" width={1} alignItems="center" justifyContent="space-between">
          <Box>
            <Typography sx={{ fontSize: "14px !important" }}>{children}</Typography>
            {data?.isAddressVerification && !selectProps?.hideRequireProofId ? (
              <Typography sx={{ fontSize: "12px !important" }}>
                {t("ADD_NUMBER.REQUIRES_PROOF")}
              </Typography>
            ) : null}
          </Box>
          <Box fontSize="caption.fontSize">
            {data?.isPremiumChipVisible ? (
              <Chip
                size="small"
                color="primary"
                label="Premium Plan"
                classes={{ sizeSmall: classes.chipSizeSmall }}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </components.Option>
  );
}

function OptionArea({ children, data, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  if (children === t("ADD_NUMBER.ANY_CITY")) {
    children = (
      <Box component="span" data-cy="RandomAreaCodeOption">
        <FontAwesomeIcon size="lg" icon={faHashtag} className={classes.randomArea} />
        {t("ADD_NUMBER.ANY_CITY")}
      </Box>
    );
  } else if (children === t("ADD_NUMBER.TOLL_FREE")) {
    return (
      <components.Option {...rest}>
        <Box display="flex" alignItems="center" justifyContent={"space-between"}>
          <Box component={"span"}>
            <Box component="span" className={classes.tollFreeTag}>
              800
            </Box>
            <Typography variant={"body"} component={"span"}>
              {t("ADD_NUMBER.TOLL_FREE")}
            </Typography>
          </Box>
          <Box component={"span"} fontSize="caption.fontSize">
            {data?.isPremiumChipVisible ? (
              <Chip
                size="small"
                color="primary"
                label="Premium Plan"
                classes={{ sizeSmall: classes.chipSizeSmall }}
              />
            ) : null}
          </Box>
        </Box>
      </components.Option>
    );
  } else if (children === t("ADD_NUMBER.AVAILABLE_AREA_CODES")) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        style={{
          backgroundColor: theme.alohi.lightGray,
          color: "white",
          padding: 0,
        }}
      >
        {children}
      </Box>
    );
  }
  return (
    <components.Option {...rest}>
      <Box display="flex" alignItems="center" justifyContent={"space-between"}>
        <Typography variant="body" component="span">
          {children}
        </Typography>
        <Box component="span">
          {!!data?.areaCode ? (
            <Typography variant="body">
              {`(+${data.countryCode ? data.countryCode + " " : ""}${data.areaCode})`}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </components.Option>
  );
}

const useStyles = makeStyles(({ typography }) => ({
  avatar: {
    width: 22,
    height: 22,
    marginLeft: 0,
    marginRight: 5,
  },
  groupHeadingWrapper: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    padding: "0 10px 0 10px",
    justifyContent: "flex-start",
    borderBottom: "solid 1px #f0f0f0",
  },
  groupHeadingWrapperLast: {
    borderBottom: "none",
  },
  chipSizeSmall: {
    fontSize: typography.caption.fontSize,
    borderRadius: 3,
    backgroundColor: "#e3edf5",
    color: "#2c71af",
  },
  randomArea: {
    marginRight: 9,
    background: "darkgray",
    color: "white",
    borderRadius: 3,
    padding: 1,
    // width: 100,
  },
  tollFreeTag: {
    display: "inline",
    marginRight: 8,
    color: "white",
    borderRadius: 3,
    fontSize: ".6em",
    padding: "4px 2px",
    background: "darkgray",
  },
}));

GroupPlan.propTypes = { headingProps: PropTypes.any, children: PropTypes.node };
OptionPlan.propTypes = { children: PropTypes.node, data: PropTypes.object };
OptionArea.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
  data: PropTypes.object,
};
OptionCountry.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object,
  data: PropTypes.object,
  value: PropTypes.any,
};
SingleValuePlan.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};
GroupHeadingPlan.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};
SingleValueCountry.propTypes = {
  getValue: PropTypes.func,
  children: PropTypes.node,
};

export {
  GroupPlan,
  OptionPlan,
  SingleValuePlan,
  GroupHeadingPlan,
  OptionCountry,
  SingleValueCountry,
  OptionArea,
};
