import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import RS, { FetchError, InitialRequestState } from "../../enums/requestStatus";
import { dataExportApi, DataExportGetStatus } from "../../api/dataExport.api";
import { RootState } from "../store";
import { logout } from "./authentication.reducer";

const initialState = {
  getExportStatus: InitialRequestState<DataExportGetStatus>(),
  startFaxesExport: InitialRequestState(),
  startContactsExport: InitialRequestState(),
  downloadExport: InitialRequestState(),
};

export const clearStartFaxesExport = createAction("EXPORT/CLEAR_START_FAXES_EXPORT");
export const clearStartContactsExport = createAction("PLANS/CLEAR_START_CONTACTS_EXPORT");

export const getExportStatus = createAsyncThunk<
  { response: DataExportGetStatus },
  undefined,
  {
    rejectValue: FetchError;
  }
>("DATA_EXPORT/GET_EXPORT_STATUS", async (_, { rejectWithValue }) => {
  try {
    const response = await dataExportApi.getStatus();
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const startFaxesExport = createAsyncThunk<
  { response: Record<string, never> },
  undefined,
  {
    rejectValue: FetchError;
  }
>("DATA_EXPORT/START_FAXES_EXPORT", async (_, { rejectWithValue }) => {
  try {
    const response = await dataExportApi.createJob({ jobType: "faxes" });
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const startContactsExport = createAsyncThunk<
  { response: Record<string, never> },
  undefined,
  { rejectValue: FetchError }
>("DATA_EXPORT/START_CONTACTS_EXPORT", async (_, { rejectWithValue }) => {
  try {
    const response = await dataExportApi.createJob({ jobType: "contacts" });
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const downloadExport = createAsyncThunk<
  { fileUrl: string; downloadName: string },
  { fileName: string; downloadName: string },
  { state: RootState; rejectValue: FetchError }
>(
  "DATA_EXPORT/DOWNLOAD",
  async (
    { fileName, downloadName }: { fileName: string; downloadName: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await dataExportApi.download({
        fileName,
      });
      return {
        fileUrl: URL.createObjectURL(response),
        downloadName: downloadName,
      };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => getState().dataExport.downloadExport?.status !== RS.RUNNING,
  },
);

const { reducer } = createSlice({
  name: "DATA_EXPORT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExportStatus.pending, (state) => {
      state.getExportStatus.status = RS.RUNNING;
      state.getExportStatus.error = null;
    });
    builder.addCase(getExportStatus.fulfilled, (state, action) => {
      state.getExportStatus.response = action.payload.response;
      state.getExportStatus.status = RS.IDLE;
      state.getExportStatus.error = null;
    });
    builder.addCase(getExportStatus.rejected, (state, action) => {
      state.getExportStatus.response = null;
      state.getExportStatus.status = RS.ERROR;
      state.getExportStatus.error = action.payload;
    });
    builder.addCase(startFaxesExport.pending, (state) => {
      state.startFaxesExport.status = RS.RUNNING;
      state.startFaxesExport.error = null;
    });
    builder.addCase(startFaxesExport.fulfilled, (state, action) => {
      state.startFaxesExport.response = action.payload;
      state.startFaxesExport.status = RS.IDLE;
      state.startFaxesExport.error = null;
    });
    builder.addCase(startFaxesExport.rejected, (state, action) => {
      state.startFaxesExport.response = null;
      state.startFaxesExport.status = RS.ERROR;
      state.startFaxesExport.error = action.payload;
    });
    builder.addCase(clearStartFaxesExport, (state) => {
      state.startFaxesExport = InitialRequestState();
    });
    builder.addCase(startContactsExport.pending, (state) => {
      state.startContactsExport.status = RS.RUNNING;
      state.startContactsExport.error = null;
    });
    builder.addCase(startContactsExport.fulfilled, (state, action) => {
      state.startContactsExport.response = action.payload;
      state.startContactsExport.status = RS.IDLE;
      state.startContactsExport.error = null;
    });
    builder.addCase(startContactsExport.rejected, (state, action) => {
      state.startContactsExport.response = null;
      state.startContactsExport.status = RS.ERROR;
      state.startContactsExport.error = action.payload;
    });
    builder.addCase(clearStartContactsExport, (state) => {
      state.startContactsExport = InitialRequestState();
    });
    builder.addCase(downloadExport.pending, (state) => {
      state.downloadExport.status = RS.RUNNING;
      state.downloadExport.error = null;
    });
    builder.addCase(downloadExport.fulfilled, (state, action) => {
      state.downloadExport.status = RS.IDLE;
      state.downloadExport.error = null;
      if (!action.payload) return;
      const url = action.payload.fileUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action.payload.downloadName);
      link.click();
    });
    builder.addCase(downloadExport.rejected, (state, action) => {
      state.downloadExport.response = null;
      state.downloadExport.status = RS.ERROR;
      state.downloadExport.error = action.payload;
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export default reducer;
