import { useCallback } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import {
  confirmPaymentDeposit,
  setPaymentDepositError,
} from "../stores/reducers/payment.deposit.reducer";
import {
  selectPaymentTokenId,
  selectPaymentDepositRequiresAction,
} from "../selectors/payment.selector";
import { sentryCaptureMessage } from "helpers/sentry";

function useStripe3dsOnDeposit() {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const paymentToken = useSelector(selectPaymentTokenId);
  const paymentDepositRequiresAction = useSelector(selectPaymentDepositRequiresAction);

  const authenticateCard = useCallback(async () => {
    const response = await stripe.handleCardAction(
      paymentDepositRequiresAction.next_action.client_secret,
      {
        payment_method: paymentToken,
      },
    );

    if (response?.paymentIntent) {
      dispatch(confirmPaymentDeposit(paymentDepositRequiresAction.id));
    } else if (response?.error) {
      sentryCaptureMessage({
        message: "Something went wrong during 3DS payment confirmation for deposit",
        level: "error",
        breadcumbs: {
          category: "fetch",
          level: "warning",
          message: JSON.stringify(response?.error),
        },
      });
      dispatch(
        setPaymentDepositError({
          isStripeError: true,
          reason: response?.error?.code,
          message: response?.error?.message,
        }),
      );
    }
  }, [stripe, dispatch, paymentToken, paymentDepositRequiresAction]);

  return authenticateCard;
}

export default useStripe3dsOnDeposit;
