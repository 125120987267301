import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, TextButton, makeStyles, Modal, Box } from "@alohi/kit";
import { faArrowUpRightAndArrowDownLeftFromCenter } from "@fortawesome/pro-regular-svg-icons";

import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import { Grid, FormControlLabel } from "ui";
import FormRow from "components/Forms/FormRow";
import { selectAccountInfo } from "selectors/account.selector";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import ModalTitleWithHelperLink from "views/SendFax/components/ModalTitleWithHelperLink";
import MessageModal from "../MessageModal";
import CompanyLogo from "./components/CompanyLogo";

interface DefaultCoverSheetModalProps {
  handleClosure: (isSuccess: boolean) => void;
}

function DefaultCoverSheetModal({ handleClosure }: DefaultCoverSheetModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { name, lastName } = useSelector(selectAccountInfo);

  const {
    sendFaxDispatch,
    sendFaxStore: { defaultCoverSheet },
  } = useSendFaxContext();

  const initialCompanyLogo = localStorage.getItem("coverSheet-logo") === "true";

  const [to, toInput] = useInput(defaultCoverSheet?.to || "");
  const [subject, subjectInput] = useInput(defaultCoverSheet?.subject || "");
  const [message, messageInput] = useInput(defaultCoverSheet?.message || "");
  const [from, fromInput] = useInput(defaultCoverSheet?.from || `${name} ${lastName}`);

  const [checkboxes, setCheckboxes] = useState({
    isUrgent: defaultCoverSheet?.isUrgent || false,
    isForReview: defaultCoverSheet?.isForReview || false,
    isPleaseReply: defaultCoverSheet?.isPleaseReply || false,
    isConfidential: defaultCoverSheet?.isConfidential || false,
  });
  const [isUsingCompanyLogo, isUsingCompanyLogoBool] = useBool(
    defaultCoverSheet?.companyLogo ?? initialCompanyLogo,
  );
  const [isMessageModalOpen, isMessageModalOpenBool] = useBool(false);

  const handleCheckboxChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [target.name]: target.checked,
    }));
  };

  const handleOnConfirm = () => {
    sendFaxDispatch({
      type: "SET_DEFAULT_COVER_SHEET",
      payload: {
        ...checkboxes,
        to,
        from,
        subject,
        message,
        companyLogo: isUsingCompanyLogo,
      },
    });

    handleClosure(true);
  };

  const handleNewMessage = (message: string) => {
    messageInput.setValue(message);
    isMessageModalOpenBool.setFalse();
  };

  useEffect(() => {
    localStorage.setItem("coverSheet-logo", `${isUsingCompanyLogo}`);
  }, [isUsingCompanyLogo]);

  return (
    <>
      <Modal
        maxWidth="sm"
        scroll="paper"
        onConfirm={handleOnConfirm}
        onCancel={() => handleClosure(false)}
        title={
          <ModalTitleWithHelperLink
            link={helpCenterUrls.defaultCoverSheet}
            title={t("SENT_FAX.DEFAULT_COVER_SHEET")}
          />
        }
      >
        <Box component="form" py={2} pr="15%">
          <FormRowTextInput
            labelGridWidth={4}
            id="cover-sheet-to"
            value={to}
            onBlur={toInput.onBlur}
            label={t("SENT_FAX.TO")}
            onFocus={toInput.onFocus}
            error={toInput.showsError}
            onChange={toInput.onChange}
            inputProps={{ maxLength: 50 }}
          />
          <FormRowTextInput
            labelGridWidth={4}
            id="cover-sheet-from"
            value={from}
            onBlur={fromInput.onBlur}
            label={t("SENT_FAX.FROM")}
            onFocus={fromInput.onFocus}
            error={fromInput.showsError}
            onChange={fromInput.onChange}
            inputProps={{ maxLength: 50 }}
          />
          <FormRowTextInput
            labelGridWidth={4}
            id="cover-sheet-subject"
            value={subject}
            onBlur={subjectInput.onBlur}
            label={t("SENT_FAX.SUBJECT")}
            inputProps={{ maxLength: 50 }}
            onFocus={subjectInput.onFocus}
            error={subjectInput.showsError}
            onChange={subjectInput.onChange}
          />
          <FormRow label="">
            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isUrgent"
                      id="cover-sheet-isUrgent"
                      checked={checkboxes.isUrgent}
                      onChange={handleCheckboxChange}
                      inputProps={{ "aria-label": "isUrgent" }}
                    />
                  }
                  label={t("SENT_FAX.URGENT")}
                />
              </Grid>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isPleaseReply"
                      id="cover-sheet-please-reply"
                      checked={checkboxes.isPleaseReply}
                      onChange={handleCheckboxChange}
                      inputProps={{ "aria-label": "isPleaseReply" }}
                    />
                  }
                  label={t("SENT_FAX.PLEASE_REPLY")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isForReview"
                      id="cover-sheet-for-review"
                      checked={checkboxes.isForReview}
                      onChange={handleCheckboxChange}
                      inputProps={{ "aria-label": "isForReview" }}
                    />
                  }
                  label={t("SENT_FAX.FOR_REVIEW")}
                />
              </Grid>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isConfidential"
                      id="cover-sheet-isConfidential"
                      onChange={handleCheckboxChange}
                      checked={checkboxes.isConfidential}
                      inputProps={{ "aria-label": "isConfidential" }}
                    />
                  }
                  label={t("SENT_FAX.CONFIDENTIAL")}
                />
              </Grid>
            </Grid>
          </FormRow>
          <FormRowTextInput
            rows={4}
            multiline
            value={message}
            labelGridWidth={4}
            id="cover-sheet-message"
            onBlur={messageInput.onBlur}
            label={t("SENT_FAX.MESSAGE")}
            onFocus={messageInput.onFocus}
            error={messageInput.showsError}
            inputProps={{ maxLength: 1000 }}
            onChange={messageInput.onChange}
            endAdornment={
              <TextButton className={classes.button} onClick={isMessageModalOpenBool.setTrue}>
                <FontAwesomeIcon
                  size="xs"
                  className={classes.icon}
                  icon={faArrowUpRightAndArrowDownLeftFromCenter}
                />
              </TextButton>
            }
          />

          <CompanyLogo
            isUsingCompanyLogo={isUsingCompanyLogo}
            isUsingCompanyLogoBool={isUsingCompanyLogoBool}
          />
        </Box>
      </Modal>

      {isMessageModalOpen ? (
        <MessageModal message={message} handleClosure={handleNewMessage} />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  icon: {
    color: alohi.almostGray,
  },
  button: {
    zIndex: "1000",
    top: spacing(-0.85),
    position: "absolute",
    right: spacing(-0.85),
    padding: spacing(0.5),
    borderRadius: "100vmin",
    backgroundColor: alohi.white,
    border: `1px solid ${alohi.darkGray}`,
  },
}));

export default DefaultCoverSheetModal;
