import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Card, Container, Box, useMediaQuery, theme } from "@alohi/kit";

import { Typography } from "ui";
import { urls } from "routes/urls";
import useBool from "hooks/useBool";
import useTitle from "hooks/useTitle";
import { contactsRoutes } from "enums/contacts";
import Recent from "components/Contacts/Recent";
import Groups from "components/Contacts/Groups";
import Whitelist from "components/Contacts/Whitelist";
import Blacklist from "components/Contacts/Blacklist";
import SharedGroups from "components/Contacts/SharedGroups";
import SharedContacts from "components/Contacts/SharedContacts";
import ContactsFilters from "components/Contacts/ContactsFilters";
import ContactsActions from "components/Contacts/ContactsActions";
import ContactsNavigation from "components/Contacts/ContactsNavigation";
import { selectCanIEditSharedContacts } from "selectors/ability.selector";
import ContactsBulkActions from "components/Contacts/ContactsBulkActions";
import {
  selectIdsOfCheckedContacts,
  selectUserCanSearchContacts,
} from "selectors/contacts.selector";

function Contacts() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const userCanSearchContacts = useSelector(selectUserCanSearchContacts);
  const canIEditSharedContacts = useSelector(selectCanIEditSharedContacts);
  const areContactsSelected = useSelector(selectIdsOfCheckedContacts(contactsTab))?.length > 0;

  const [isShowingFilters, isShowingFiltersBool] = useBool(false);

  const title = useMemo(() => {
    switch (contactsTab) {
      case contactsRoutes.whitelist:
        return t("CONTACTS.TITLE");
      case contactsRoutes.groups:
        return `${t("CONTACTS.TITLE")} - ${t("CONTACTS.GROUPS")}`;
      case contactsRoutes.recent:
        return `${t("CONTACTS.TITLE")} - ${t("CONTACTS.RECENT")}`;
      case contactsRoutes.blacklist:
        return `${t("CONTACTS.TITLE")} - ${t("CONTACTS.BLACKLIST")}`;
      case contactsRoutes["shared-contacts"]:
        return `${t("CONTACTS.TITLE")} - ${t("CONTACTS.SHARED_CONTACTS")}`;
      case contactsRoutes["shared-groups"]:
        return `${t("CONTACTS.TITLE")} - ${t("CONTACTS.SHARED_GROUPS")}`;
      default:
        return "";
    }
  }, [contactsTab, t]);

  useEffect(() => {
    if (contactsTab && userCanSearchContacts) {
      isShowingFiltersBool.setTrue();
    }
  }, [contactsTab, isShowingFiltersBool, userCanSearchContacts]);

  useTitle(title);

  return (
    <Container maxWidth="sm">
      {isMobileLayout ? null : <ContactsNavigation />}

      {isShowingFilters ? (
        <Box sx={{ py: 1 }}>
          <ContactsFilters />
        </Box>
      ) : (
        <Box sx={{ py: 2 }} />
      )}

      <Card>
        <Card.Header
          sx={{
            "&&": {
              bgcolor: areContactsSelected ? "alohi.almostGray" : "alohi.blue",
            },
          }}
        >
          {areContactsSelected ? (
            <ContactsBulkActions />
          ) : (
            <>
              <Typography variant="panelFrontHeader">{title}</Typography>
              <ContactsActions showFilters={isShowingFiltersBool.toggle} />
            </>
          )}
        </Card.Header>

        {
          {
            [urls.contacts.root]: (
              <Whitelist hasActions noBorder={false} canShare={canIEditSharedContacts} />
            ),
            [urls.contacts.blacklist]: <Blacklist hasActions noBorder={false} />,
            [urls.contacts.recent]: <Recent hasActions noBorder={false} hasSelection={false} />,
            [urls.contacts.groups]: (
              <Groups
                hasActions
                showEmptyGroups
                noBorder={false}
                canShare={canIEditSharedContacts}
              />
            ),
            [urls.contacts.sharedContacts]: (
              <SharedContacts
                noBorder={false}
                hasActions={canIEditSharedContacts}
                hasSelection={canIEditSharedContacts}
              />
            ),
            [urls.contacts.sharedGroups]: (
              <SharedGroups
                showEmptyGroups
                noBorder={false}
                hasActions={canIEditSharedContacts}
                hasSelection={canIEditSharedContacts}
              />
            ),
          }[pathname]
        }
      </Card>
    </Container>
  );
}

export default Contacts;
