import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";

import { useTheme } from "ui";

function UserProgress({ currentStep = 2, stepNames = ["One", "Two", "Three", "Four"] }) {
  const theme = useTheme();

  const R = 8;
  const HEIGHT = 50;
  const STROKEWIDTH = 2;
  const X_OFFSET_PERCENT = 15;
  const COLOR_COMPLETE = theme.alohi.lighterBlue;
  const COLOR_INCOMPLETE = theme.alohi.lightGray;
  const totalNumberOfSteps = stepNames.length;
  const currentProgressPercent = Math.floor(((currentStep - 1) / (totalNumberOfSteps - 1)) * 100);

  const stepCircles = useMemo(() => {
    let circles = [];
    for (let step = 1; step <= totalNumberOfSteps; step++) {
      const cx =
        Math.floor(
          ((step - 1) / (totalNumberOfSteps - 1)) * (100 - 2 * X_OFFSET_PERCENT) + X_OFFSET_PERCENT,
        ) + "%";
      const isComplete = currentStep >= step;

      circles.push(
        <Fragment key={stepNames[step - 1]}>
          <circle
            r={R}
            cx={cx}
            cy={HEIGHT / 3}
            stroke={COLOR_COMPLETE}
            strokeWidth={STROKEWIDTH}
            fill={isComplete ? COLOR_COMPLETE : "white"}
          />
          <text
            x={cx}
            y={(7 / 8) * HEIGHT}
            key={stepNames[step - 1]}
            textAnchor="middle"
            fill={isComplete ? COLOR_COMPLETE : COLOR_INCOMPLETE}
          >
            {stepNames[step - 1]}
          </text>
        </Fragment>,
      );
    }
    return circles;
  }, [totalNumberOfSteps, currentStep, COLOR_COMPLETE, stepNames, COLOR_INCOMPLETE]);

  return (
    <Box>
      <svg width="100%" version="1.1" height={HEIGHT + "px"} xmlns="http://www.w3.org/2000/svg">
        <line
          x1={X_OFFSET_PERCENT + "%"}
          x2={100 - X_OFFSET_PERCENT + "%"}
          y1={HEIGHT / 3}
          y2={HEIGHT / 3}
          stroke={COLOR_INCOMPLETE}
          strokeWidth="1"
          strokeDasharray="4 4"
        />
        <line
          x1={X_OFFSET_PERCENT + "%"}
          x2={currentProgressPercent * (1 - (2 * X_OFFSET_PERCENT) / 100) + X_OFFSET_PERCENT + "%"}
          y1={HEIGHT / 3}
          y2={HEIGHT / 3}
          strokeWidth="1.5"
          stroke={COLOR_COMPLETE}
        />
        {stepCircles}
      </svg>
    </Box>
  );
}

UserProgress.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UserProgress;
