import { useRef } from "react";
import { Box } from "@alohi/kit";
import { useSelector } from "react-redux";

import { selectPaymentMethods } from "../../selectors/payment.selector";
import AddDiscountCode from "../../components/AddDiscountCode/AddDiscountCode";
import AddCredit from "./AddCredit";
import PaymentMethodInput from "./PaymentMethodInput";
import AddPaymentMethodComponent from "./AddPaymentMethodComponent";

function AddCreditCardOrCredit() {
  const paymentMethods = useSelector(selectPaymentMethods);

  /* Keep the initial value for lifetime of the component, to avoid weird UI updates */
  const hasPaymentMethods = useRef(!!paymentMethods?.length).current;

  return (
    <>
      {!hasPaymentMethods ? <AddPaymentMethodComponent handleClosure={() => null} /> : null}
      <Box pr="15%">
        <AddCredit />
        {hasPaymentMethods ? <PaymentMethodInput /> : null}
        <AddDiscountCode />
      </Box>
    </>
  );
}

export default AddCreditCardOrCredit;
