import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";

import useBool from "hooks/useBool";
import useInjectTag from "hooks/useInjectTag";
import { ONE_DRIVE_PICKER_OAUTH } from "config";

interface UseOneDrivePickerProps {
  allowedFileTypes: string[];
  onLoading?: (isLoading: boolean) => void;
  onAddDocuments: (documents: FileList | File[]) => void;
}

interface OneDriveFile {
  id: string;
  name: string;
  size: number;
  "@odata.context": string;
  "@content.downloadUrl": string;
}

interface OneDriveFiles {
  webUrl: null | string;
  accessToken: string;
  apiEndpoint: string;
  value: OneDriveFile[];
}

interface OneDriveOptions {
  action: string;
  clientId: string;
  multiSelect: boolean;
  openInNewWindow: boolean;
  advanced: {
    filter: string;
    redirectUri?: string;
    endpointHint?: string;
  };
  cancel: () => void;
  error: (error: unknown) => void;
  success: (files: OneDriveFiles) => void;
}

interface ExtendedWindow extends Window {
  OneDrive: {
    open: (options: OneDriveOptions) => undefined;
  };
}

declare const window: ExtendedWindow;

const ONE_DRIVE_SDK_URL = `https://js.live.net/v7.2/OneDrive.js`;

function useOneDrivePicker({
  onLoading,
  onAddDocuments,
  allowedFileTypes,
}: UseOneDrivePickerProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { setTag, isError, isLoaded, isLoading } = useInjectTag("script");

  const [wasPickerOpened, wasPickerOpenedBool] = useBool(false);

  const handleTransformFile = useCallback(async (oneDriveFile: OneDriveFile) => {
    try {
      const response = await fetch(oneDriveFile["@content.downloadUrl"]);

      const blob = await response.blob();
      let type = blob.type;
      const fileExtensionRegex = /\.[0-9a-z]+$/i;
      const fileExtension = oneDriveFile.name.match(fileExtensionRegex)?.[0];

      // OneDrive returns "application/octet-stream" as a type for RTF files
      if (blob.type === "application/octet-stream" && fileExtension === ".rtf") {
        type = "application/rtf";
      }

      const file = new File([blob], oneDriveFile.name, { type });

      return file;
    } catch {
      throw new Error("Can't download file");
    }
  }, []);

  const handlePickedFiles = useCallback(
    async (oneDriveFiles: OneDriveFiles) => {
      try {
        onLoading?.(true);

        const promises = oneDriveFiles.value.map(handleTransformFile);
        const response = await Promise.allSettled(promises);

        const files = response.reduce<File[]>((accumulator, currentValue) => {
          if (currentValue.status === "fulfilled") {
            accumulator.push(currentValue.value);
          }
          return accumulator;
        }, []);

        onAddDocuments(files);
      } catch {
        throw new Error("Can't download files");
      } finally {
        onLoading?.(false);
      }
    },
    [handleTransformFile, onAddDocuments, onLoading],
  );

  const handleOpenPicker = useCallback(() => {
    if ("OneDrive" in window) {
      window.OneDrive.open({
        advanced: {
          endpointHint: "api.onedrive.com",
          filter: allowedFileTypes.join(","),
          redirectUri: window.location.origin + "/one-drive",
        },
        multiSelect: true,
        action: "download",
        openInNewWindow: true,
        clientId: ONE_DRIVE_PICKER_OAUTH,
        success: function (files) {
          handlePickedFiles(files);
        },
        error: wasPickerOpenedBool.setTrue,
        cancel: wasPickerOpenedBool.setTrue,
      });
    }
  }, [allowedFileTypes, handlePickedFiles, wasPickerOpenedBool.setTrue]);

  const handleLoadScripts = useCallback(() => {
    if (isLoaded) {
      handleOpenPicker();
    } else {
      setTag({
        src: ONE_DRIVE_SDK_URL,
        async: "true",
      });
    }
  }, [handleOpenPicker, isLoaded, setTag]);

  useEffect(() => {
    if (isLoaded && window.OneDrive && !wasPickerOpened) {
      handleOpenPicker();
      wasPickerOpenedBool.setTrue();
    }
  }, [handleOpenPicker, isLoaded, wasPickerOpened, wasPickerOpenedBool]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("SENT_FAX.ERROR_ONE_DRIVE"), { variant: "error" });
    }
  }, [enqueueSnackbar, isError, t]);

  return {
    isOneDrivePickerLoading: isLoading,
    loadOneDrivePicker: handleLoadScripts,
  };
}

export default useOneDrivePicker;
