import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const applyCode = ({ token }) => {
  const requestOptions = {
    method: "POST",
    body: { token },
  };
  const path = `${API_ESHOP}/redeem`;
  return fetchWithRefresh(path, requestOptions);
};

export const redeemApi = {
  applyCode,
};
