import { Box } from "@alohi/kit";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect } from "react";

import { urls } from "routes/urls";
import { Group } from "stores/reducers/contacts.helpers";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import DeleteGroupModal from "views/Contacts/DeleteGroupModal";
import EmptyContacts from "components/InfiniTable/EmptyContacts";
import AddOrEditGroupModal from "views/Contacts/AddOrEditGroupModal";
import { SendFaxRouteState } from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import ShareContactsOrGroupsModal from "views/Contacts/ShareContactsOrGroupsModal";
import {
  Table,
  Typography,
  makeStyles,
  TableColumnsProp,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "ui";
import {
  selectGroups,
  selectGroupsHasMore,
  selectGroupSelection,
  selectIsGettingGroupsLoading,
} from "selectors/contacts.selector";
import {
  getGroups,
  getMoreGroups,
  clearContactsGroups,
  updateGroupSelection,
} from "stores/reducers/contacts.groups.reducer";

interface ModalState {
  isOpen: boolean;
  contactId: null | string;
}

interface GroupsProps {
  noBorder: boolean;
  canShare: boolean;
  hasActions: boolean;
  showEmptyGroups: boolean;
  areTabsClickable?: boolean;
}

function Groups({
  showEmptyGroups,
  canShare,
  hasActions,
  noBorder,
  areTabsClickable,
}: GroupsProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleGroupDestination } = useSendFaxDestinationHelpers();

  const hasMore = useAppSelector(selectGroupsHasMore);
  const groupList = useAppSelector<null | Group[]>(selectGroups);

  const selection = useAppSelector<string[]>(selectGroupSelection);
  const isLoading = useAppSelector<boolean>(selectIsGettingGroupsLoading);

  const [editModal, setEditModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });
  const [deleteModal, setDeleteModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });
  const [shareModal, setShareModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });

  const rows = useMemo(
    () => (showEmptyGroups ? groupList : groupList?.filter((group) => group.membersCount)) ?? [],
    [groupList, showEmptyGroups],
  );

  const actions = useMemo(() => {
    return (index: number) =>
      rows.map((row) => {
        const actions = [];

        if (row.membersCount) {
          actions.push({
            label: t("COMMON.SEND_FAX"),
            callback: () =>
              history.push(urls.sendFax, {
                destination: [handleGroupDestination(row, "group")],
              } satisfies SendFaxRouteState),
          });

          actions.push({
            label: "Divider",
          });
        }

        actions.push({
          label: t("COMMON.EDIT"),
          callback: () => {
            setEditModal({
              isOpen: true,
              contactId: row.id,
            });
          },
        });

        actions.push({
          label: t("COMMON.DELETE"),
          callback: () => {
            setDeleteModal({
              isOpen: true,
              contactId: row.id,
            });
          },
        });

        if (canShare) {
          actions.push({
            label: "Divider",
          });

          actions.push({
            label: t("CONTACTS.ADD_GROUP_TO_SHARED"),
            callback: () => {
              setShareModal({
                isOpen: true,
                contactId: row.id,
              });
            },
          });
        }

        return actions;
      })[index];
  }, [canShare, handleGroupDestination, history, rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    columns.push({
      width: 16,
      minWidth: 16,
      headerName: " ",
      sortable: false,
      field: "id",
      renderCell: function render() {
        return <Box> </Box>;
      },
    });

    columns.push({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.05,
    });

    columns.push({
      width: 15,
      minWidth: 15,
      headerName: " ",
      sortable: false,
      field: "index",
      renderCell: function render() {
        return <Box> </Box>;
      },
    });

    columns.push({
      flex: 0.25,
      field: "name",
      sortable: false,
      headerName: t("CONTACTS.NAME"),
      renderCell: function render({ row }: { row: Group }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.name}
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "membersCount",
      headerName: t("CONTACTS.MEMBERS_COUNT"),
      renderCell: function render({ row }: { row: Group }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.membersCount}
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.255,
      sortable: false,
      field: "comment",
      headerName: t("CONTACTS.NOTE"),
      renderCell: function render({ row }: { row: Group }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.note}
          </Typography>
        );
      },
    });

    if (hasActions) {
      columns.push({
        flex: 0.0925,
        sortable: false,
        field: "actions",
        headerName: " ",
        renderCell: function render({ row }: { row: Group }) {
          return <ColumnActions dataIndex={row.index} createActions={actions} />;
        },
      });
    }

    return columns;
  }, [actions, classes.row, hasActions, t]);

  const onWaypoint = useMemo(() => {
    if (!hasMore) {
      return undefined;
    }

    return () => {
      dispatch(getMoreGroups());
    };
  }, [dispatch, hasMore]);

  useEffect(() => {
    // Initial Fetch
    if (!groupList) {
      dispatch(getGroups({ offset: 0 }));
    }
  }, [dispatch, groupList]);

  useEffect(() => {
    return () => {
      dispatch(clearContactsGroups());
    };
  }, [dispatch]);

  return (
    <>
      <Table
        rows={rows}
        rowHeight={76}
        columns={columns}
        checkboxSelection
        noBorder={noBorder}
        isLoading={isLoading}
        onWaypoint={onWaypoint}
        className={classes.root}
        selectionModel={selection}
        disableSelectionOnClick={!areTabsClickable}
        onSelectionModelChange={(selection) =>
          dispatch(updateGroupSelection(selection as string[]))
        }
        components={{
          NoRowsOverlay: function render() {
            return <EmptyContacts text={t("CONTACTS.NO_CONTACTS_HERE_YET")} />;
          },
        }}
      />

      {editModal.isOpen && editModal.contactId ? (
        <AddOrEditGroupModal
          groupToEditId={editModal.contactId}
          handleClosure={() => setEditModal({ isOpen: false, contactId: null })}
        />
      ) : null}

      {deleteModal.isOpen && deleteModal.contactId ? (
        <DeleteGroupModal
          isSharedGroup={false}
          groupIds={[deleteModal.contactId]}
          handleClosure={() => setDeleteModal({ isOpen: false, contactId: null })}
        />
      ) : null}

      {shareModal.isOpen && shareModal.contactId ? (
        <ShareContactsOrGroupsModal
          isContact={false}
          idsToShare={[shareModal.contactId]}
          handleClosure={() => setShareModal({ isOpen: false, contactId: null })}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "&& .MuiDataGrid-columnHeader": {
      padding: spacing(0, 1),
    },
    "&& .MuiDataGrid-columnHeader:nth-child(2)": {
      paddingLeft: spacing(0.5),
    },
    "&&& .MuiDataGrid-cell": {
      overflowY: "auto",
      padding: spacing(2, 1),
    },
    "&&&& .MuiDataGrid-row > div:first-child": {
      padding: 0,
    },
  },
  row: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "text",
  },
}));

export default Groups;
