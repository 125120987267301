import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPlanId } from "selectors/plan.selector";
import { selectCurrentCredit } from "selectors/credit.selector";
import { selectAdminInfoPlanType } from "selectors/corporate.selector";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import { selectAccountIsCorporateMember } from "selectors/account.selector";

import MyCreditPanel from "components/Profile/PlanBilling/MyCreditPanel";
import PlanDetailsPanel from "components/Profile/PlanBilling/PlanDetailsPanel/PlanDetailsPanel";
import PaymentDetailsPanel from "components/Profile/PlanBilling/PaymentDetailsPanel";
import PlanBillingHandleQueryString from "components/Profile/PlanBilling/PlanBillingHandleQueryString";
import AlohiSuitePanel from "components/Profile/PlanBilling/AlohiSuitePanel";
import { Box, makeStyles } from "@alohi/kit";

function PlanBilling() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentPlanId = useSelector(selectPlanId);
  const currentCredit = useSelector(selectCurrentCredit);
  const adminInfoPlanType = useSelector(selectAdminInfoPlanType);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  useEffect(() => {
    isCorporateMember && dispatch(corporateAsyncActions.getAdminInfo());
  }, [dispatch, isCorporateMember]);

  const isPlanDetailsReady = Boolean(
    (currentPlanId && currentCredit !== undefined) || adminInfoPlanType,
  );

  return (
    <Box className={classes.base}>
      {isPlanDetailsReady && <PlanDetailsPanel />}
      <AlohiSuitePanel />
      {!isCorporateMember && <MyCreditPanel />}
      {!isCorporateMember && <PaymentDetailsPanel />}
      <PlanBillingHandleQueryString />
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  base: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(7),
  },
}));

export default PlanBilling;
