import { Box, Modal, useSnackbar } from "@alohi/kit";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import { Typography, Link } from "ui";
import { useInput } from "hooks/useInput";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import { getCustomerInfo } from "stores/reducers/account.reducer";
import { isValidEmail, isValidName } from "helpers/inputValidation";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import {
  requestBaa,
  clearRequestBaa,
  activateAdvancedSecurity,
  clearActivateAdvancedSecurity,
} from "stores/reducers/account.hipaa.reducer";
import {
  selectIsRequestBaaError,
  selectIsRequestBaaRunning,
  selectIsRequestBaaSuccess,
  selectIsAdvancedSecurityError,
  selectAccountIsCorporateMember,
  selectIsEnabledAdvancedSecurity,
  selectIsAdvancedSecuritySuccess,
  selectIsAdvancedSecurityRunning,
} from "selectors/account.selector";

enum VIEWS {
  REQUIRE_UPGRADE = "upgrade",
  CONFIRM_REQUEST = "confirmActivate",
  CORPORATE_MEMBER = "corporateMemberView",
  REQUIRE_ADVANCED_SECURITY = "requireAdvanceSecurity",
}

interface BaaActivationModalProps {
  handleClosure: () => void;
}

function BaaActivationModal({ handleClosure }: BaaActivationModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const isEnabledAdvancedSecurity = useSelector(selectIsEnabledAdvancedSecurity);

  const isRequestBaaError = useSelector(selectIsRequestBaaError);
  const isRequestBaaRunning = useSelector(selectIsRequestBaaRunning);
  const isRequestBaaSuccess = useSelector(selectIsRequestBaaSuccess);

  const isAdvancedSecurityError = useSelector(selectIsAdvancedSecurityError);
  const isAdvancedSecurityRunning = useSelector(selectIsAdvancedSecurityRunning);
  const isAdvancedSecuritySuccess = useSelector(selectIsAdvancedSecuritySuccess);

  const [currentView, setCurrentView] = useState<VIEWS>();
  const [signersEmail, signersEmailInput] = useInput("", isValidEmail);
  const [signersFullName, signersFullNameInput] = useInput("", isValidName);

  useEffect(() => {
    if (isCorporateMember) {
      setCurrentView(VIEWS.CORPORATE_MEMBER);
    } else if (!isPlanEnterprise) {
      setCurrentView(VIEWS.REQUIRE_UPGRADE);
    } else if (!isEnabledAdvancedSecurity) {
      setCurrentView(VIEWS.REQUIRE_ADVANCED_SECURITY);
    } else {
      setCurrentView(VIEWS.CONFIRM_REQUEST);
    }
  }, [isCorporateMember, isEnabledAdvancedSecurity, isPlanEnterprise]);

  const handleActivateAdvancedSecurity = useCallback(() => {
    dispatch(activateAdvancedSecurity());
  }, [dispatch]);

  const handleRequestBaa = useCallback(() => {
    signersEmailInput.touch();
    signersFullNameInput.touch();

    if (signersFullNameInput.isValid && signersEmailInput.isValid) {
      dispatch(
        requestBaa({
          signersEmail: signersEmail,
          signersName: signersFullName,
        }),
      );
    }
  }, [dispatch, signersEmail, signersEmailInput, signersFullName, signersFullNameInput]);

  useEffect(() => {
    if (isAdvancedSecurityError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearActivateAdvancedSecurity());
    }
    if (isRequestBaaError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearRequestBaa());
    }
    if (isAdvancedSecuritySuccess) {
      enqueueSnackbar(t("PROFILE.HIPAA_ADVANCED_SECURITY_SUCCESS"), {
        variant: "success",
      });
      dispatch(getCustomerInfo());
      dispatch(clearActivateAdvancedSecurity());
    }
    if (isRequestBaaSuccess) {
      enqueueSnackbar(t("PROFILE.HIPAA_BAA_SUCCESS"), {
        variant: "success",
      });
      dispatch(clearRequestBaa());
      handleClosure();
    }
  }, [
    t,
    dispatch,
    enqueueSnackbar,
    isRequestBaaError,
    isAdvancedSecurityError,
    isAdvancedSecuritySuccess,
    isRequestBaaSuccess,
    handleClosure,
  ]);

  if (!currentView) {
    return null;
  } else
    return {
      [VIEWS.REQUIRE_UPGRADE]: (
        <RequireUpgradeToEnterprise
          handleClosure={handleClosure}
          title={t("PROFILE.HIPAA_SIGN_BAA")}
          featureName={t("PROFILE.HIPAA_UPGRADE_BAA_FEATURE")}
        />
      ),
      [VIEWS.CORPORATE_MEMBER]: (
        <ContactAdminToUseFeature
          handleClosure={handleClosure}
          title={t("PROFILE.HIPAA_SIGN_BAA")}
          description={t("PROFILE.HIPAA_UPGRADE_FEATURE")}
        />
      ),
      [VIEWS.REQUIRE_ADVANCED_SECURITY]: (
        <Modal
          maxWidth="xs"
          onConfirm={handleActivateAdvancedSecurity}
          onCancel={handleClosure}
          onPressEscape={handleClosure}
          title={t("PROFILE.HIPAA_SIGN_BAA")}
          confirmTitle={t("PROFILE.HIPAA_ACTIVATE")}
          isModalDisabled={isAdvancedSecurityRunning}
          isConfirmLoading={isAdvancedSecurityRunning}
        >
          <Box p={3}>
            <Typography variant="body">
              <Trans i18nKey={"PROFILE.HIPAA_ACTIVATE_BAA_REQUIRES_ADVANCED_TITLE"} />
            </Typography>
            <Box mt={1} />
            <Typography variant="body">
              <Trans i18nKey={"PROFILE.HIPAA_ACTIVATE_ADVANCED_QUESTION"} />
            </Typography>
          </Box>
        </Modal>
      ),
      [VIEWS.CONFIRM_REQUEST]: (
        <Modal
          maxWidth="xs"
          onConfirm={handleRequestBaa}
          onCancel={handleClosure}
          title={t("PROFILE.HIPAA_SIGN_BAA")}
          confirmTitle={t("PROFILE.HIPAA_REQUEST")}
          isModalDisabled={isRequestBaaRunning}
          isConfirmLoading={isRequestBaaRunning}
          isConfirmDisabled={signersFullNameInput.showsError || signersEmailInput.showsError}
        >
          <Box p={3}>
            <Typography variant="body">
              <Trans
                i18nKey="PROFILE.COMPLIANCE_DOCUMENTS_YOU_WILL_RECEIVE_BAA_VIA_SIGN_PLUS"
                components={{
                  signLink: <Link href="https://sign.plus" target="_blank" rel="noreferrer" />,
                }}
              />
            </Typography>
            <Box sx={{ mt: 1 }} />
            <Typography variant="body">
              {t("PROFILE.COMPLIANCE_DOCUMENTS_YOU_NEED_TO_PROVIDE_INFORMATION")}
            </Typography>
            <Box sx={{ mt: 2, pr: 3 }}>
              <FormRowTextInput
                required
                label={t("PROFILE.COMPLIANCE_DOCUMENTS_SIGNERS_FULL_NAME")}
                name="name"
                type="text"
                value={signersFullName}
                onBlur={signersFullNameInput.onBlur}
                onFocus={signersFullNameInput.onFocus}
                error={signersFullNameInput.showsError}
                onChange={signersFullNameInput.onChange}
              />
              <FormRowTextInput
                required
                label={t("PROFILE.COMPLIANCE_DOCUMENTS_SIGNERS_EMAIL")}
                name="email"
                type="email"
                value={signersEmail}
                onBlur={signersEmailInput.onBlur}
                onFocus={signersEmailInput.onFocus}
                error={signersEmailInput.showsError}
                onChange={signersEmailInput.onChange}
              />
            </Box>
          </Box>
        </Modal>
      ),
    }[currentView];
}

export default BaaActivationModal;
