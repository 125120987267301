import clsx from "clsx";
import { useRouteMatch } from "react-router";
import Link, { LinkProps } from "routes/Link";
import { makeStyles, Typography } from "@alohi/kit";
import { ForwardedRef, forwardRef, MouseEventHandler } from "react";

interface NavigationLinkProps extends LinkProps {
  className?: string;
  label: string;
  dataCy: string;
  matchPath: string | string[];
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const NavigationLink = forwardRef(
  (
    { className, label, to, dataCy, onClick, matchPath }: NavigationLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const isActive = Boolean(
      useRouteMatch({
        path: matchPath,
      }),
    );

    const classes = useStyles({
      isActive,
    });

    return (
      <Link to={to} className={clsx(classes.root, className)} ref={ref} onClick={onClick}>
        <Typography type="navigationLabel" className={classes.label} data-cy={dataCy}>
          {label}
        </Typography>
        <Typography type="navigationLabel" className={classes.hiddenLabel}>
          {label}
        </Typography>
      </Link>
    );
  },
);

NavigationLink.displayName = "NavigationLink";

const useStyles = makeStyles(({ breakpoints, spacing, alohi }) => ({
  root: (props: { isActive: boolean }) => ({
    height: "34px",
    display: "grid",
    borderRadius: "6px",
    alignContent: "center",
    justifyItems: "center",
    padding: spacing(0, 2),
    color: alohi.almostGray,
    boxSizing: "border-box",
    ...(props.isActive && {
      color: alohi.blue,
      backgroundColor: alohi.blue100,
    }),
    "&:hover": {
      backgroundColor: alohi.blue100,
    },
    [breakpoints.down("sm")]: {
      height: "48px",
    },
  }),
  label: (props: { isActive: boolean }) => ({
    gridArea: "1 / 1",
    color: alohi.neutral900,
    ...(props.isActive && {
      fontWeight: "bold",
      color: "#395F9D",
    }),
  }),
  hiddenLabel: {
    gridArea: "1 / 1",
    fontWeight: "bold",
    color: "transparent",
  },
}));

export default NavigationLink;
