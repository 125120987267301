import createSelector from "selectorator";
import RS from "../enums/requestStatus";
import i18n from "../i18n/index";

export const selectIsGetWebHooksRunning = (state) =>
  state.integrations.webHooks.getWebHooks.status === RS.RUNNING;

export const selectIncomingWebHooks = createSelector(
  ["integrations.webHooks.getWebHooks.response"],
  (response) => {
    if (!response) return null;
    return response.hooks.filter((hook) => hook.event === "fax_received");
  },
);

export const selectOutgoingWebHooks = createSelector(
  ["integrations.webHooks.getWebHooks.response"],
  (response) => {
    if (!response) return null;
    return response.hooks.filter((hook) => hook.event === "fax_sent");
  },
);

// Create or Delete
export const selectIsUpdateWebHooksSuccess = (state) =>
  !!state.integrations.webHooks.createWebHook.response &&
  !!state.integrations.webHooks.deleteWebHook.response;

export const selectIsCreateWebHookRunning = (state) =>
  state.integrations.webHooks.createWebHook.status === RS.RUNNING;

export const selectIsDeleteWebHookRunning = (state) =>
  state.integrations.webHooks.deleteWebHook.status === RS.RUNNING;

export const selectIsDeleteWebHookSuccess = (state) =>
  !!state.integrations.webHooks.deleteWebHook.response;

export const selectUpdateWebHooksError = createSelector(
  ["integrations.webHooks.createWebHook.error", "integrations.webHooks.deleteWebHook.error"],
  (createError, deleteError) => {
    if (!createError && !deleteError) return null;
    else return i18n.t("COMMON.SERVER_ERROR");
  },
);
