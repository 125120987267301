import { Panel, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import { helpCenterUrls } from "routes/urls";
import { useAppSelector } from "stores/store";
import { Typography, makeStyles, Helper } from "ui";
import { selectIsBaaSigned, selectIsEnabledAdvancedSecurity } from "selectors/account.selector";
import ActivateAdvancedSecurityButton from "./ActivateAdvancedSecurityButton";

interface AdvancedSecurityControlProps {
  withIcon: boolean;
  withStatus: boolean;
}

function AdvancedSecurityControlRow({ withIcon, withStatus }: AdvancedSecurityControlProps) {
  const { t } = useTranslation();

  const isBaaSigned = useAppSelector(selectIsBaaSigned);
  const isEnabledAdvancedSecurity = useAppSelector(selectIsEnabledAdvancedSecurity);

  const classes = useStyles({ isBaaSigned });

  return (
    <Panel.Row>
      <Panel.Cell variant="title">
        {withIcon ? (
          isEnabledAdvancedSecurity ? (
            <FontAwesomeIcon className={classes.activeIcon} size="1x" icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon className={classes.inactiveIcon} size="1x" icon={faTimesCircle} />
          )
        ) : null}
        <Typography variant="panelCellTitle">
          {t("PROFILE.HIPAA_ADVANCED_SECURITY")}
          <Box
            ml={1}
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={helpCenterUrls.advancedSecurity}
          >
            <Helper variant="dark" size="lg" />
          </Box>
        </Typography>
      </Panel.Cell>
      {withStatus && (
        <Panel.Cell variant="data">
          {isEnabledAdvancedSecurity ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
        </Panel.Cell>
      )}
      <Panel.Cell variant="actions">
        <ActivateAdvancedSecurityButton />
      </Panel.Cell>
    </Panel.Row>
  );
}

const useStyles = makeStyles(({ spacing, alohi, typography }) => ({
  inactiveIcon: {
    color: alohi.red,
    marginRight: spacing(1),
    fontSize: typography.pxToRem(17),
  },
  activeIcon: {
    color: alohi.lightGreen,
    marginRight: spacing(1),
    fontSize: typography.pxToRem(17),
  },
}));

export default AdvancedSecurityControlRow;
