import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { Switch, TextButton, Panel, Typography, Box } from "@alohi/kit";

import useBool from "hooks/useBool";
import { updateAccountDetails, clearUpdateAccountDetails } from "stores/reducers/account.reducer";
import {
  selectIsEnabledAdvancedSecurity,
  selectIsIncludeEmailContentEnabled,
  selectIsUpdateAccountDetailsSuccessful,
  selectIsAbilityToSendFaxViaEmailEnabled,
} from "selectors/account.selector";
import DisabledForCompliance from "./DisabledForCompliance";

function EmailToFaxPanel() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEnabledAdvancedSecurity = useSelector(selectIsEnabledAdvancedSecurity);
  const isAccountUpdated = useSelector(selectIsUpdateAccountDetailsSuccessful);
  const isAbilityToSendFaxViaEmailEnabled = useSelector(selectIsAbilityToSendFaxViaEmailEnabled);
  const isIncludeEmailContentEnabled = useSelector(selectIsIncludeEmailContentEnabled);

  const [editMode, setEditMode] = useState(false);
  const [abilityToSendFaxViaEmailCheckbox, abilityToSendFaxViaEmailCheckboxHandlers] = useBool(
    isAbilityToSendFaxViaEmailEnabled,
  );
  const [includeEmailContentInFaxCheckbox, includeEmailContentInFaxCheckboxHandlers] = useBool(
    isIncludeEmailContentEnabled,
  );
  const handleCancel = useCallback(() => {
    abilityToSendFaxViaEmailCheckboxHandlers.reset();
    setEditMode(false);
  }, [abilityToSendFaxViaEmailCheckboxHandlers]);

  const handleSave = useCallback(() => {
    dispatch(
      updateAccountDetails({
        updateType: "emailToFax",
        accountData: {
          sendFaxViaEmail: abilityToSendFaxViaEmailCheckbox,
          ignoreEmailContent: !includeEmailContentInFaxCheckbox,
        },
      }),
    );
  }, [dispatch, abilityToSendFaxViaEmailCheckbox, includeEmailContentInFaxCheckbox]);

  useEffect(() => {
    if (isAccountUpdated) {
      dispatch(clearUpdateAccountDetails());
      setEditMode(false);
    }
  }, [isAccountUpdated, dispatch]);

  return (
    <Panel
      id="email-to-fax"
      headerTitle={t("EMAIL_TO_FAX.TITLE")}
      isFlipped={editMode}
      sx={{ mb: 7 }}
    >
      <Panel.Front
        headerAction={
          <TextButton onClick={() => setEditMode(true)}>
            <Typography type="panelFrontHeader">{t("COMMON.EDIT")}</Typography>
            <Box sx={{ ml: 1, color: "white" }}>
              <FontAwesomeIcon icon={faPenToSquare} color="white" />
            </Box>
          </TextButton>
        }
      >
        <Panel.Row>
          <Panel.Cell variant="title">{t("EMAIL_TO_FAX.ABILITY_TO_SEND_FAX_VIA_EMAIL")}</Panel.Cell>
          <Panel.Cell variant="data">
            {isAbilityToSendFaxViaEmailEnabled ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("EMAIL_TO_FAX.INCLUDE_EMAIL_CONTENT_IN_FAX")}</Panel.Cell>
          <Panel.Cell variant="data">
            {isIncludeEmailContentEnabled ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
          </Panel.Cell>
        </Panel.Row>
      </Panel.Front>

      <Panel.Back onConfirm={handleSave} onCancel={handleCancel}>
        <Panel.Row>
          <Panel.Cell variant="title">{t("EMAIL_TO_FAX.ABILITY_TO_SEND_FAX_VIA_EMAIL")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Switch
              value={abilityToSendFaxViaEmailCheckbox}
              checked={abilityToSendFaxViaEmailCheckbox}
              disabled={isEnabledAdvancedSecurity}
              onChange={() => {
                if (abilityToSendFaxViaEmailCheckbox)
                  includeEmailContentInFaxCheckboxHandlers.setFalse();
                abilityToSendFaxViaEmailCheckboxHandlers.toggle();
              }}
            />
            {isEnabledAdvancedSecurity && t("PROFILE.INACTIVE")}
          </Panel.Cell>
          <Panel.Cell variant="actions">
            {isEnabledAdvancedSecurity && <DisabledForCompliance />}
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("EMAIL_TO_FAX.INCLUDE_EMAIL_CONTENT_IN_FAX")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Switch
              value={includeEmailContentInFaxCheckbox}
              checked={includeEmailContentInFaxCheckbox}
              disabled={!abilityToSendFaxViaEmailCheckbox || isEnabledAdvancedSecurity}
              onChange={includeEmailContentInFaxCheckboxHandlers.toggle}
            />
            {isEnabledAdvancedSecurity && t("PROFILE.INACTIVE")}
          </Panel.Cell>
          <Panel.Cell variant="actions">
            {isEnabledAdvancedSecurity && <DisabledForCompliance />}
          </Panel.Cell>
        </Panel.Row>
      </Panel.Back>
    </Panel>
  );
}

export default EmailToFaxPanel;
