import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar, makeStyles } from "@alohi/kit";

import {
  selectUserByUid,
  selectDeleteUserRequestError,
  selectDeleteUserRequestSuccess,
  selectIsDeleteUserRequestLoading,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_DELETE_USER_REQUEST,
} from "stores/reducers/corporate.reducer";

function ConfirmDeleteUserModal({ userUid, handleClosure }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(selectUserByUid(userUid));

  const success = useSelector(selectDeleteUserRequestSuccess);
  const error = useSelector(selectDeleteUserRequestError);
  const isLoading = useSelector(selectIsDeleteUserRequestLoading);

  useEffect(() => {
    if (success) {
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(corporateAsyncActions.getUsers());
      enqueueSnackbar(t("USERS.REMOVE_USER_SUCCESS"), { variant: "success" });
      dispatch(CLEAR_DELETE_USER_REQUEST());
      handleClosure && handleClosure();
    }
  }, [success, dispatch, enqueueSnackbar, t, handleClosure]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(CLEAR_DELETE_USER_REQUEST());
    }
  }, [error, dispatch, enqueueSnackbar, t]);

  if (!user) return null;

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      isConfirmLoading={isLoading}
      title={`${t("COMMON.DELETE")} (${user.email})`}
      onConfirm={() => dispatch(corporateAsyncActions.deleteUser({ userUid }))}
    >
      <Box p={3}>
        <Typography type="body">
          {t("USERS.QUESTION_REMOVE_USER", { name: user.name || user.email })}
        </Typography>
        <Box mt={1} />
        <Typography className={classes.remove}>{t("USERS.REMOVE_USER_DESCRIPTION")}</Typography>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  remove: {
    color: alohi.red,
  },
}));

ConfirmDeleteUserModal.propTypes = {
  userUid: PropTypes.string,
  handleClosure: PropTypes.func,
};

export default ConfirmDeleteUserModal;
