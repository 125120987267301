import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import useBool from "hooks/useBool";
import { selectDefaultCountry } from "selectors/verifications.selector";
import { clearPostNewAddress, postNewAddress } from "stores/reducers/address.reducer";
import CreateNewAddressComponent from "components/AddressBundle/CreateNewAddressComponent";
import {
  selectPostedAddress,
  selectPostedAddressIsError,
  selectPostedAddressIsRunning,
} from "selectors/address.selector";

function CreateNewAddressModal({ country, handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isValid, isValidHandler] = useBool(false);
  const [addressData, setAddressData] = useState({});

  const postedAddress = useSelector(selectPostedAddress);
  const isError = useSelector(selectPostedAddressIsError);
  const isRunning = useSelector(selectPostedAddressIsRunning);
  const { defaultCountry } = useSelector(selectDefaultCountry);

  const valuesCallback = useCallback(
    (addressData) => {
      const canProceed = Object.values(addressData).every((element) => !!element.length);
      canProceed ? isValidHandler.setTrue() : isValidHandler.setFalse();
      setAddressData(addressData);
    },
    [isValidHandler],
  );

  const handlePostNewAddress = useCallback(() => {
    dispatch(
      postNewAddress({
        city: addressData.city,
        street: addressData.street,
        region: addressData.region,
        zipCode: addressData.zipCode,
        isoCountry: addressData.country,
        customerName: addressData.customerName,
      }),
    );
  }, [addressData, dispatch]);

  useEffect(() => {
    if (isError) {
      dispatch(clearPostNewAddress());
      enqueueSnackbar(t("ADDRESS.INVALID_ADDRESS"), { variant: "error" });
    }
  }, [enqueueSnackbar, t, isError, dispatch]);

  useEffect(() => {
    if (postedAddress) {
      dispatch(clearPostNewAddress());
      handleClosure(postedAddress);
    }
  }, [dispatch, handleClosure, postedAddress]);

  const sourceCountry = country || defaultCountry;

  return (
    <Modal
      hasCloseIcon={false}
      isModalDisabled={isRunning}
      isConfirmDisabled={!isValid}
      isConfirmLoading={isRunning}
      confirmTitle={t("COMMON.SAVE")}
      cancelTitle={t("COMMON.CANCEL")}
      onConfirm={handlePostNewAddress}
      onCancel={() => handleClosure(null)}
      title={t("ADDRESS.NEW_ADDRESS_TITLE")}
    >
      <Box p={2}>
        <CreateNewAddressComponent
          country={sourceCountry}
          handleClosure={valuesCallback}
          isCountryDisabled={Boolean(country)}
        />
      </Box>
    </Modal>
  );
}

CreateNewAddressModal.propTypes = {
  country: PropTypes.string.isRequired,
  handleClosure: PropTypes.func.isRequired,
};

export default CreateNewAddressModal;
