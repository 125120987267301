import { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCircleInfo } from "@fortawesome/pro-light-svg-icons";

import { useTheme } from "ui/theme";

interface MessageBoxProps extends BoxProps {
  children: ReactNode;
  noIcon?: boolean;
  variant: "info" | "warning";
}

function MessageBox({ children, noIcon, variant, ...rest }: MessageBoxProps) {
  const theme = useTheme();

  const icon = variant === "info" ? faCircleInfo : faExclamationTriangle;
  const iconColor = variant === "info" ? theme.alohi.almostGray : theme.alohi.almostGray;
  const bgColor = variant === "info" ? theme.alohi.lightestBlue : theme.alohi.lightestYellow;

  return (
    <Box
      p={1}
      mt={1}
      display={"flex"}
      borderRadius={2}
      bgcolor={bgColor}
      alignItems={"center"}
      {...rest}
    >
      {noIcon ? null : (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={1} pr={2}>
          <FontAwesomeIcon icon={icon} size="lg" color={iconColor} />
        </Box>
      )}

      {children}
    </Box>
  );
}

export default MessageBox;
