import { Panel } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PhoneNumberFilter } from "components/Core/Filters";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import { selectAssignedNumbers } from "selectors/numbers.selector";
import {
  selectIsPlanFree,
  selectIsPlanInactive,
  selectPlanHasNumbers,
  selectPlanIsWaitingApproval,
} from "selectors/plan.selector";

import BuyNumber from "./BuyNumber";
import ChangeNumber from "./ChangeNumber";
import FaxNumberTooltip from "./FaxNumberTooltip";

function FaxNumberFrontRow() {
  const { t } = useTranslation();

  const isPlanFree = useSelector(selectIsPlanFree);
  const isPlanInactive = useSelector(selectIsPlanInactive);
  const assignedNumbers = useSelector(selectAssignedNumbers);
  const planHasNumbers = useSelector(selectPlanHasNumbers);
  const isWaitingApproval = useSelector(selectPlanIsWaitingApproval);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const number = assignedNumbers[0]?.number || "";

  return (
    <Panel.Row>
      <Panel.Cell variant="title">{t("PROFILE.FAX_NUMBER")}</Panel.Cell>
      {isPlanFree || !assignedNumbers?.length ? (
        <Panel.Cell variant="data">{t("COMMON.NO_AVAILABLE")}</Panel.Cell>
      ) : (
        <Panel.Cell variant="data">
          <PhoneNumberFilter number={number} />
          {assignedNumbers?.length > 1 ? <FaxNumberTooltip /> : null}
        </Panel.Cell>
      )}
      <Panel.Cell variant="actions">
        {!isCorporateMember && planHasNumbers && !isWaitingApproval && !isPlanInactive ? (
          <ChangeNumber number={number} />
        ) : isPlanFree ? (
          <BuyNumber />
        ) : null}
      </Panel.Cell>
    </Panel.Row>
  );
}

export default FaxNumberFrontRow;
