import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, SplitButton, useSnackbar } from "@alohi/kit";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";

import useBool from "hooks/useBool";
import useTitle from "hooks/useTitle";
import { randomPassword } from "helpers/string";
import { RichText } from "views/SendFax/contexts/store";
import AddFiles from "views/SendFax/components/AddFiles";
import SendFaxCard from "views/SendFax/components/SendFaxCard";
import SendFaxError from "views/SendFax/components/SendFaxError";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { useSendFaxHelpers } from "views/SendFax/contexts/helpers";
import LoadingOverlay from "views/SendFax/components/LoadingOverlay";
import TransmissionOptimizationModal from "views/TransmissionOptimization/TransmissionOptimizationModal";
import {
  selectIsFaxSent,
  selectUploadFilesError,
  selectUploadFilesResponse,
  selectUploadFilesSessionId,
} from "selectors/numbers.selector";
import {
  sendFax,
  uploadFiles,
  clearSendFax,
  clearUploadFiles,
} from "stores/reducers/numbers.reducer";
import PageOptions from "./components/PageOptions";
import SubmissionModal from "./modals/SubmissionModal";
import Destination from "./components/Destination/Destination";
import PageOptionsModals from "./components/PageOptionsModals";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

const LazyAddTextModal = lazy(() => import("views/AddText/AddTextModal"));

function SendFax() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { sendCustomEvent } = useGoogleTagManager();

  const { addFiles } = useSendFaxHelpers();
  const { sendFaxStore, sendFaxDispatch } = useSendFaxContext();

  useTitle(t("COMMON.SEND_FAX"));

  const isSkippingTransmissionOptimization =
    localStorage.getItem("remember_transmission_optimization") === "true";

  const isFaxSent = useSelector(selectIsFaxSent);
  const uploadFilesError = useSelector(selectUploadFilesError);
  const uploadFilesResponse = useSelector(selectUploadFilesResponse);
  const uploadFilesSessionId = useSelector(selectUploadFilesSessionId);

  const [sessionId] = useState(randomPassword(10, "!"));
  const [isAddTextModalOpen, isAddTextModalOpenBool] = useBool(false);
  const [isSubmissionModalOpen, isSubmissionModalOpenBool] = useBool(false);
  const [isOptimizationModalOpen, isOptimizationModalOpenBool] = useBool(false);

  const setIsSending = useCallback(
    (isSending: boolean) => {
      sendFaxDispatch({
        type: "SET_IS_OVERLAY_OPEN",
        payload: isSending,
      });
    },
    [sendFaxDispatch],
  );

  const onSend = () => {
    isOptimizationModalOpenBool.setFalse();
    dispatch(uploadFiles({ files: sendFaxStore.files, sessionId }));
    setIsSending(true);
  };

  const onBeforeSend = () => {
    if (isSkippingTransmissionOptimization) {
      onSend();
    } else {
      isOptimizationModalOpenBool.setTrue();
    }
  };

  const clearFaxData = useCallback(() => {
    sendFaxDispatch({ type: "INIT_SESSION" });

    dispatch(clearSendFax());
  }, [dispatch, sendFaxDispatch]);

  const handleNewTextFile = useCallback(
    (newFile: RichText | null) => {
      isAddTextModalOpenBool.setFalse();

      if (newFile) {
        addFiles([newFile]);
      }
    },
    [addFiles, isAddTextModalOpenBool],
  );

  const isSessionIdMatching = sessionId === uploadFilesSessionId;

  useEffect(() => {
    if (!isSessionIdMatching) {
      // Clear previously uploaded files. These uploads might be from a different session
      dispatch(clearUploadFiles());
      return;
    }

    const uploadFilesResponseLength = uploadFilesResponse?.length;

    if (uploadFilesResponseLength || uploadFilesError) {
      if (uploadFilesResponseLength) {
        // Cache the uploaded paths
        sendFaxDispatch({
          type: "SET_FILES_PATH",
          payload: uploadFilesResponse,
        });
      }

      if (uploadFilesError) {
        enqueueSnackbar(uploadFilesError, { variant: "error" });
        setIsSending(false);
      } else if (uploadFilesResponseLength) {
        // Call outbox with the fax details, no errors
        dispatch(sendFax({ sendFaxStore }));
      }

      // Clear this to avoid infinite loop
      dispatch(clearUploadFiles());
    }
  }, [
    dispatch,
    sendFaxStore,
    setIsSending,
    enqueueSnackbar,
    sendFaxDispatch,
    uploadFilesError,
    isSessionIdMatching,
    uploadFilesResponse,
  ]);

  useEffect(() => {
    if (isFaxSent) {
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.faxSubmission,
      });
      clearFaxData();
      setIsSending(false);
      isSubmissionModalOpenBool.setTrue();
      isOptimizationModalOpenBool.setFalse();
    }
  }, [
    isFaxSent,
    clearFaxData,
    setIsSending,
    isSubmissionModalOpenBool,
    isOptimizationModalOpenBool,
    sendCustomEvent,
  ]);

  useEffect(() => {
    if (sendFaxStore.errorMessage) {
      enqueueSnackbar(sendFaxStore.errorMessage, { variant: "error" });
      setIsSending(false);
      sendFaxDispatch({ type: "CLEAR_ERROR" });
    }
  }, [enqueueSnackbar, sendFaxDispatch, sendFaxStore.errorMessage, setIsSending]);

  return (
    <Container maxWidth="sm">
      <SendFaxCard onSend={onBeforeSend}>
        <Destination />
        <AddFiles
          actionButtons={
            <SplitButton
              data-cy="AddPageButton"
              MenuItems={PageOptions}
              title={t("SENT_FAX.ADD_PAGE")}
              MenuItemsModals={PageOptionsModals}
              onTitleClick={isAddTextModalOpenBool.setTrue}
            />
          }
        />
        <LoadingOverlay />
      </SendFaxCard>

      <SendFaxError handleClosure={isOptimizationModalOpenBool.setFalse} />

      {isAddTextModalOpen ? (
        <Suspense fallback={""}>
          <LazyAddTextModal handleClosure={handleNewTextFile} />
        </Suspense>
      ) : null}

      {isSubmissionModalOpen ? (
        <SubmissionModal onClose={isSubmissionModalOpenBool.setFalse} />
      ) : null}

      {isOptimizationModalOpen ? (
        <TransmissionOptimizationModal
          onConfirm={onSend}
          onCancel={isOptimizationModalOpenBool.setFalse}
        />
      ) : null}
    </Container>
  );
}

export default SendFax;
