import { Panel } from "@alohi/kit";
import { useSelector } from "react-redux";

import { capitalizeString } from "helpers/string";
import { selectAdminInfoPlanType } from "selectors/corporate.selector";
import { selectMemberOutboundQuota, selectMemberQuota } from "selectors/account.selector";

function PlanDetailsForCorporateMembers() {
  const outboundQuota = useSelector(selectMemberOutboundQuota);
  const memberQuota = useSelector(selectMemberQuota);
  const adminInfoPlanType = useSelector(selectAdminInfoPlanType);

  return (
    <Panel headerTitle="Plan Details" id="plan-details" data-cy="PlanDetailsPanel">
      <Panel.Front>
        <Panel.Row>
          <Panel.Cell variant="title">Plan Type</Panel.Cell>
          <Panel.Cell variant="data">{`${capitalizeString(adminInfoPlanType)}`}</Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">Remaining Pages</Panel.Cell>
          <Panel.Cell variant="data">{`${memberQuota} (Outbound Quota: ${outboundQuota})`}</Panel.Cell>
        </Panel.Row>
      </Panel.Front>
    </Panel>
  );
}

export default PlanDetailsForCorporateMembers;
