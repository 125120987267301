import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Box, makeStyles, Divider, Typography } from "@alohi/kit";

import { urls } from "routes/urls";
import alohiLogo from "assets/svg/alohi_logo.svg";
import faxPlusByAlohi from "assets/svg/fax_plus_logo_text_by_alohi.svg";
import LanguageSwitcher from "./LanguageSwitcher";

interface CommonLayoutProps {
  children: ReactNode;
}

function CommonLayout({ children }: CommonLayoutProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Box sx={{ mb: 4 }}>
          <a href={urls.homePage}>
            <Box
              width={200}
              height={53}
              component="img"
              src={faxPlusByAlohi}
              alt="Fax.Plus by Alohi"
              data-cy="faxPlusLogo"
            />
          </a>
        </Box>
        <Box className={classes.children}>{children}</Box>
      </Paper>

      <Box className={classes.languageSwitcherContainer}>
        <LanguageSwitcher />
      </Box>

      <Divider sx={{ mt: 3, maxWidth: ["300px", "428px"], minWidth: "300px" }} />
      <Box
        width={100}
        height={40}
        sx={{ mt: 3 }}
        component="img"
        src={alohiLogo}
        alt="Alohi Logo"
      />
      <Typography
        sx={{
          "&&": {
            display: "flex",
            alignItems: "center",
            mt: 1,
            textAlign: "center",
          },
        }}
      >
        {t("LOGIN.ONE_ACCOUNT")}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, alohi, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: spacing(4, 2, 2, 2),
  },
  paper: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    height: "fit-content",
    flexDirection: "column",
    padding: spacing(6, 8, 4),
    border: `1px solid ${alohi.lighterGray}`,
    [breakpoints.down("sm")]: {
      padding: 0,
      border: "unset",
    },
    borderRadius: 12,
  },
  children: {
    width: 300,
    display: "flex",
    flexDirection: "column",
  },
  languageSwitcherContainer: {
    marginTop: spacing(3),
    width: 400,
    [breakpoints.down("sm")]: {
      width: "fit-content",
    },
  },
}));

export default CommonLayout;
