import paypalCheckout from "braintree-web/paypal-checkout";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@alohi/kit";

import { BRAINTREE_CLIENT_ID } from "config";
import Loading from "../../../components/Loading/Loading";
import { selectIsPaymentProcessLoading } from "../../../selectors/payment.selector";

function PaypalButton({ handleClosure }) {
  const classes = useStyles();

  const [isPaypalLoading, setIsPaypalLoading] = useState(false);
  const isPaymentProcessLoading = useSelector(selectIsPaymentProcessLoading);

  useEffect(() => {
    return () => {
      Object.keys(window).forEach((key) => {
        if (/paypal|zoid|post_robot/.test(key)) {
          delete window[key];
        }
      });
      document
        .querySelectorAll('iframe[src*="checkout.paypal.com/web"]')
        .forEach((node) => node.remove());
    };
  }, []);

  useEffect(() => {
    setIsPaypalLoading(true);
    (async () => {
      try {
        let paypalCheckoutInstance = await paypalCheckout.create({
          authorization: BRAINTREE_CLIENT_ID,
        });

        paypalCheckoutInstance = await paypalCheckoutInstance.loadPayPalSDK({
          vault: true,
          intent: "tokenize",
        });

        const paypal = window.paypal;
        await paypal
          .Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
              label: "paypal",
              height: 35,
              shape: "rect", // pill | rect
              color: "blue", // gold | blue | silver | black
              tagline: false,
            },
            createBillingAgreement: () => {
              return paypalCheckoutInstance.createPayment({
                flow: "vault",
              });
            },
            onApprove: async (data) => {
              setIsPaypalLoading(true);
              const { nonce } = await paypalCheckoutInstance.tokenizePayment(data);
              handleClosure(nonce);
            },
            onCancel: () => {
              setIsPaypalLoading(false);
              handleClosure(null);
            },
            onError: () => {
              setIsPaypalLoading(false);
              handleClosure(null);
            },
          })
          .render("#paypal-button");
        setIsPaypalLoading(false);
      } catch (error) {
        handleClosure(null);
      }
    })();
  }, [handleClosure]);

  const isLoading = isPaypalLoading || isPaymentProcessLoading;

  return (
    <Box my={3} className={classes.root}>
      {isLoading && <Loading size={25} />}
      <Box id="paypal-button" style={{ display: isLoading ? "none" : "block", width: 250 }} />
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "80px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
});

PaypalButton.propTypes = {
  handleClosure: PropTypes.func,
};

export default memo(PaypalButton);
