import createSelector from "selectorator";
import { RootState } from "../stores/store";
import { accountTypes } from "../enums/account";
import {
  selectIsPlanCorporate,
  selectIsPlanEnterprise,
  selectIsCurrentPlanPremium,
  selectIsCurrentPlanBusiness,
  selectIsPlanFree,
} from "./plan.selector";
import {
  selectAccountType,
  selectPhoneIsUnverified,
  selectIsAwardedFreePages,
  selectAccountIsCorporateOwner,
  selectAccountIsCorporateMember,
} from "./account.selector";
import { selectCreditRemainingPages, selectCurrentCredit } from "./credit.selector";

export const selectCanISeeDashboardTab = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType),
);

export const selectCanISeeUsersTab = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType),
);

export const selectCanISeeNumbersTab = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType),
);

export const selectCanISeeIntegrationsTab = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => accountType !== accountTypes.corporateMember,
);

export const selectCanISeeMyFaxRow = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.individual, accountTypes.corporateMember].includes(accountType),
);

export const selectCanISeeNumberOfFaxNumbersRow = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType),
);

export const selectCanISeeCompanyInfo = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType),
);

export const selectCanIEditSlackNotifications = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) => [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType),
);

export const selectCanIEditDataResidency = createSelector<RootState, boolean>(
  [selectAccountType, selectIsPlanEnterprise],
  (accountType, isPlanEnterprise) =>
    [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType) &&
    isPlanEnterprise,
);

export const selectCanIManageCorporateAdmins = createSelector<RootState, boolean>(
  [selectAccountIsCorporateOwner, selectIsPlanEnterprise],
  (isCorporateOwner, isPlanEnterprise) => isCorporateOwner && isPlanEnterprise,
);

export const selectCanIManageCorporateTwoFa = selectAccountIsCorporateOwner;

export const selectIsPhoneVerificationRequired = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) =>
    ![accountTypes.corporateAdmin, accountTypes.corporateMember].includes(accountType),
);

export const selectIsEmailVerificationRequired = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) =>
    ![accountTypes.corporateAdmin, accountTypes.corporateMember].includes(accountType),
);

export const selectCanIAccessFaxesFromOtherMembers = createSelector<RootState, boolean>(
  [selectAccountIsCorporateOwner],
  (isCorporateOwner) => !isCorporateOwner,
);

export const selectCanIManageStoreFaxesInFaxPlus = createSelector<RootState, boolean>(
  [selectAccountIsCorporateMember],
  (isCorporateMember) => !isCorporateMember,
);

export const selectCanIRequestHighVolumePlan = createSelector<RootState, boolean>(
  [selectAccountType, selectIsPlanEnterprise],
  (accountType, isPlanEnterprise) =>
    [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType) &&
    isPlanEnterprise,
);

export const selectCanIEditBlacklist = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) =>
    [accountTypes.individual, accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(
      accountType,
    ),
);

export const selectCanIEditSharedContacts = createSelector<RootState, boolean>(
  [selectAccountType, selectIsPlanEnterprise],
  (accountType, isPlanEnterprise) =>
    [accountTypes.corporateAdmin, accountTypes.corporateOwner].includes(accountType) &&
    isPlanEnterprise,
);

export const selectCanISearchSharedContacts = createSelector<RootState, boolean>(
  [selectAccountType],
  (accountType) =>
    [
      accountTypes.corporateAdmin,
      accountTypes.corporateOwner,
      accountTypes.corporateMember,
    ].includes(accountType),
);

export const selectCanIUseSharedContacts = createSelector<RootState, boolean>(
  [selectIsPlanEnterprise],
  (isPlanEnterprise) => isPlanEnterprise,
);

export const selectCanIUseSharedNotes = createSelector<RootState, boolean>(
  [selectIsPlanCorporate],
  (isPlanCorporate) => isPlanCorporate,
);

export const selectCanIUseGalleryCoverSheet = createSelector<RootState, boolean>(
  [selectIsCurrentPlanPremium, selectIsPlanCorporate],
  (isPlanPremium, isPlanCorporate) => isPlanPremium || isPlanCorporate,
);

export const selectCanIShareGalleryCoverSheet = createSelector<RootState, boolean>(
  [selectIsPlanEnterprise, selectAccountIsCorporateMember],
  (isPlanEnterprise, isCorporateMember) => isPlanEnterprise && !isCorporateMember,
);

export const selectCanISeeSharedCoverSheet = createSelector<RootState, boolean>(
  [selectIsPlanCorporate, selectIsCurrentPlanBusiness, selectAccountIsCorporateMember],
  (isPlanCorporate, isPlanBusiness, isCorporateMember) =>
    isPlanCorporate && !(isPlanBusiness && isCorporateMember),
);

export const selectCanIUseSharedCoverSheet = createSelector<RootState, boolean>(
  [selectIsPlanEnterprise],
  (isPlanEnterprise) => isPlanEnterprise,
);

export const selectCanIUseCoverSheetBranding = createSelector<RootState, boolean>(
  [selectIsPlanCorporate],
  (isPlanCorporate) => isPlanCorporate,
);

export const selectCanIUseCoverSheetEnforcement = createSelector<RootState, boolean>(
  [selectIsPlanEnterprise],
  (isPlanEnterprise) => isPlanEnterprise,
);

export const selectCanIUseSendFax = createSelector<RootState, boolean>(
  [
    selectIsPlanFree,
    selectIsPhoneVerificationRequired,
    selectPhoneIsUnverified,
    selectIsAwardedFreePages,
    selectCreditRemainingPages,
    selectCurrentCredit,
  ],
  (
    isPlanFree,
    isPhoneVerificationRequired,
    isPhoneUnverified,
    isAwarded,
    remainingPages,
    currentCredit,
  ) => {
    return !(
      isPlanFree &&
      isPhoneVerificationRequired &&
      isPhoneUnverified &&
      !isAwarded &&
      (remainingPages <= 0 || currentCredit <= 0)
    );
  },
);
