import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigationModalApi, NavigationModal, Box, useSnackbar } from "@alohi/kit";

import { Typography } from "ui";
import useOnMountCondition from "hooks/useOnMountCondition";
import { clearForgotUsername, forgotUsername } from "stores/reducers/account.reducer";
import { selectForgotUsernameError, selectForgotUsernameSuccess } from "selectors/account.selector";
import { VIEWS } from "../PhoneVerificationModal";
import { usePhoneVerificationApi } from "../context/api";

function PhoneVerificationForgotUsername() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    store: { phoneNumber },
  } = usePhoneVerificationApi();

  const success = useSelector(selectForgotUsernameSuccess);
  const error = useSelector(selectForgotUsernameError);

  const { updateStep, goToStep } = useNavigationModalApi();

  useOnMountCondition(() => {
    updateStep({
      title: t("VERIFY.MOBILE_VERIFICATION"),
      onCancel: handleCancel,
      onConfirm: handleConfirm,
      onPressEscape: handleCancel,
      onPressEnter: handleConfirm,
      cancelTitle: t("FORMS.NO"),
      confirmTitle: t("FORMS.YES"),
    });
  }, true);

  const handleConfirm = useCallback(() => {
    dispatch(forgotUsername({ phoneNumber }));
  }, [dispatch, phoneNumber]);

  useEffect(() => {
    updateStep({
      onConfirm: handleConfirm,
      onPressEnter: handleConfirm,
    });
  }, [handleConfirm, updateStep]);

  const handleCancel = () => {
    goToStep(VIEWS.ENTER_PHONE);
  };

  useEffect(() => {
    if (success) {
      goToStep(VIEWS.USERNAME_SENT);
    }
  }, [goToStep, success]);

  useEffect(() => {
    if (error) {
      // We don't display errors to the user
    }
  }, [enqueueSnackbar, error, t]);

  useEffect(() => {
    return () => dispatch(clearForgotUsername());
  }, [dispatch]);

  return (
    <NavigationModal.Content>
      <Box p={3}>
        <Typography variant="body">
          {t("VERIFY.RECEIVE_USERNAME_ASSOCIATED_WITH_PHONE_NUMBER_VIA_SMS", {
            phone: phoneNumber,
          })}
        </Typography>
      </Box>
    </NavigationModal.Content>
  );
}

PhoneVerificationForgotUsername.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  phoneNumber: PropTypes.string,
};

export default PhoneVerificationForgotUsername;
