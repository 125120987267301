import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "stores/store";
import RS from "enums/requestStatus";
import i18n from "i18n/index";
import { ZendeskArticle } from "api/types/zendesk";

export const selectIsContactUsSuccess = (state: RootState) =>
  Boolean(state.support.contactUs.response);

export const selectContactUsError = createSelector(
  (state: RootState) => state.support.contactUs.error,
  (error) => {
    if (!error) return null;

    if (error.status === 429) {
      return i18n.t("CONTACT_US.TOO_MANY_ATTEMPTS");
    }
    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectIsContactUsRunning = (state: RootState) =>
  Boolean(state.support.contactUs.status === RS.RUNNING);

export const selectIsRequestSocReportSuccess = (state: RootState) =>
  Boolean(state.support.requestSocReport.response);

export const selectIsRequestSocReportRunning = (state: RootState) =>
  Boolean(state.support.requestSocReport.status === RS.RUNNING);

export const selectRequestSocReportError = createSelector(
  (state: RootState) => state.support.requestSocReport.error,
  (error) => {
    if (!error) return null;

    if (error.status === 429) {
      return i18n.t("CONTACT_US.TOO_MANY_ATTEMPTS");
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export const selectIsRequestDataDeletionSuccess = (state: RootState) =>
  Boolean(state.support.requestDataDeletion.response);

export const selectRequestDataDeletionError = createSelector(
  (state: RootState) => state.support.requestDataDeletion.error,
  (error) => {
    if (!error) return null;

    if (error.status === 429) {
      return i18n.t("CONTACT_US.TOO_MANY_ATTEMPTS");
    }

    return i18n.t("COMMON.SERVER_ERROR");
  },
);

export type ZendeskSearchArticleResponse = {
  count: number;
  next_page: string | null;
  page: number;
  page_count: number;
  per_page: number;
  previous_page: string | null;
  results: ZendeskArticle[];
};

export const selectRequestSearchArticles = (state: RootState): ZendeskSearchArticleResponse => {
  const response = state.support.requestSearchArticles.response as ZendeskSearchArticleResponse;
  if (response) {
    return response;
  } else {
    return {
      results: [],
      count: 0,
      next_page: null,
      page: 0,
      page_count: 0,
      per_page: 0,
      previous_page: null,
    };
  }
};

export const selectIsRequestSearchArticlesRunning = (state: RootState) =>
  Boolean(state.support.requestSearchArticles.status === RS.RUNNING);
