import { alohi, Box, Button, Icon, makeStyles } from "@alohi/kit";
import clsx from "clsx";
import { MouseEvent, ReactNode } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PrimaryButtonProps {
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProp;
  children: ReactNode;
}

function PrimaryButton({ className, children, icon, onClick }: PrimaryButtonProps) {
  const classes = useStyles();

  return (
    <Button className={clsx(classes.base, className)} onClick={onClick}>
      {icon ? <Icon className={classes.icon} icon={icon} /> : null}
      <Box className={classes.text}>{children}</Box>
    </Button>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    [breakpoints.down("sm")]: {
      order: 2,
      padding: spacing(1, 1, 1, 1) + "!important",
      minHeight: "48px !important",
      flexGrow: 1,
    },
  },
  icon: {
    fontSize: 18,
    color: alohi.neutral0,
    [breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  text: {
    "& p": {
      color: alohi.neutral0 + "!important",
    },
    marginLeft: spacing(1),
    whiteSpace: "normal",
  },
}));

export default PrimaryButton;
