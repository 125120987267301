import { Box, CountryFlag, Typography, getI18nCountry, makeStyles } from "@alohi/kit";
import { CountryCode } from "libphonenumber-js";
import { useSelector } from "react-redux";

import { selectAddresses } from "selectors/address.selector";

interface CountryLabelProps {
  addressId: string;
}

function CountryLabel({ addressId }: CountryLabelProps) {
  const classes = useStyles();

  const addresses: { sid: string; iso_country: CountryCode; city: string }[] | undefined =
    useSelector(selectAddresses);

  const address = addresses?.find((address) => address.sid === addressId);

  if (!address) return <></>;
  return (
    <Box className={classes.root}>
      <CountryFlag className={classes.flag} code={address.iso_country} />
      <Box>
        <Typography>{getI18nCountry(address.iso_country)?.label}</Typography>
        <Typography type={"subtitle"}>{address.city}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  flag: {
    marginRight: spacing(1),
    width: "fit-content !important",
  },
}));

export default CountryLabel;
