import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef } from "react";
import { faCloudUpload, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { Box, Icon, theme, makeStyles, Typography, useSnackbar, useMediaQuery } from "@alohi/kit";

import { urls } from "routes/urls";
import useBool from "hooks/useBool";
import database from "helpers/database";
import { faxAllowedMimeTypes } from "enums/allowedFileTypes";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

const OFFSET = 4;
const WIDTH = 940;
const HEIGHT = 715;
export const IFRAME_UPLOAD_FILES = "FilesFromLandingPage";

function Uploader() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { sendCustomEvent } = useGoogleTagManager();

  const dbRef = useRef(database);
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFileSelectorOpen, isFileSelectorOpenBool] = useBool(false);

  const openNewTab = useCallback(async () => {
    if (!fileInputRef.current) {
      return;
    }

    const database = dbRef.current;
    const files = fileInputRef.current?.files;

    if (files && files.length > 0) {
      await database.removeFile(IFRAME_UPLOAD_FILES);
      await database.putObject(IFRAME_UPLOAD_FILES, files);

      // SetTimeout is here to untie the popup from the triggering event
      // This is to simulate a user action in order to bypass Browser popup blockers.
      setTimeout(() => {
        sendCustomEvent({
          event: GoogleAnalyticsCustomEvents.uploadIframe,
        });
        window.open(urls.inlineFrameToFax, "_blank");
      }, 0);
    } else {
      alert("Please select a file to upload.");
    }
  }, [sendCustomEvent]);

  const handleOnDrop = useCallback(
    (acceptedDocuments: File[], rejectedDocuments: unknown[]) => {
      if (!fileInputRef.current) {
        return;
      }

      fileInputRef.current.value = ""; // Reset the input value

      if (rejectedDocuments.length) {
        enqueueSnackbar(t("ERRORS.UNSUPPORTED_FILES"), {
          variant: "error",
        });
      }

      const dataTransfer = new DataTransfer();

      acceptedDocuments.forEach((file) => {
        dataTransfer.items.add(file);
      });

      fileInputRef.current.files = dataTransfer.files;
      openNewTab();
    },
    [enqueueSnackbar, openNewTab, t],
  );

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: handleOnDrop,
    accept: faxAllowedMimeTypes,
  });

  const handleClick = () => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.value = ""; // Reset the input value
    fileInputRef.current.click();
  };

  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.addEventListener("click", isFileSelectorOpenBool.setTrue);
      fileInput.addEventListener("cancel", isFileSelectorOpenBool.setFalse);
      fileInput.addEventListener("change", isFileSelectorOpenBool.setFalse);
    }

    return () => {
      if (fileInput) {
        fileInput.removeEventListener("input", isFileSelectorOpenBool.setTrue);
        fileInput.removeEventListener("cancel", isFileSelectorOpenBool.setFalse);
        fileInput.removeEventListener("change", isFileSelectorOpenBool.setFalse);
      }
    };
  }, [isFileSelectorOpenBool]);

  return (
    <Box className={classes.base} {...getRootProps()} onClick={handleClick}>
      <svg
        width="100%"
        height="100%"
        cursor="pointer"
        viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect
          rx="50"
          ry="50"
          x={OFFSET}
          y={OFFSET}
          fill="none"
          stroke="blue"
          strokeWidth="2"
          strokeDasharray="18"
          strokeDashoffset="8"
          width={WIDTH - OFFSET * 2}
          height={HEIGHT - OFFSET * 2}
        />
        <foreignObject
          x={OFFSET}
          y={OFFSET}
          width={WIDTH - OFFSET * 2}
          height={HEIGHT - OFFSET * 2}
        >
          <div className={classes.uploader}>
            {isDragActive ? <Box className={classes.dropOverlay} /> : null}
            <input
              {...getInputProps()}
              multiple
              type="file"
              ref={fileInputRef}
              onChange={openNewTab}
              className={classes.input}
              accept={faxAllowedMimeTypes.join(", ")}
            />
            <Icon className={classes.icon} icon={faCloudUpload} />
            <Typography className={classes.title}>{"Drag & drop your documents here"}</Typography>
            <Box className={clsx(classes.button, isFileSelectorOpen ? classes.buttonOpened : null)}>
              <Box className={classes.buttonContent}>
                <Icon
                  className={
                    isMobileLayout
                      ? isFileSelectorOpen
                        ? classes.hoveredButtonIcon
                        : classes.buttonIcon
                      : isFileSelectorOpen
                        ? classes.hoveredButtonIcon
                        : classes.buttonIcon
                  }
                  icon={faUpload}
                />
                <Typography className={classes.buttonText}>{"Upload Documents"}</Typography>
              </Box>
            </Box>
            <Typography className={classes.descriptionText}>
              {"Start the faxing process - Send fax online to one or multiple recipients"}
            </Typography>
          </div>
        </foreignObject>
      </svg>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  base: {
    height: "100vh",
    display: "block",
    padding: spacing(1),
    margin: "0 auto",
  },
  uploader: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  input: {
    display: "none",
  },
  icon: {
    height: "84px",
    color: "#0A69FF",
    marginTop: "81px",
  },
  title: {
    fontWeight: 400,
    fontSize: "40px",
    marginTop: "81px",
    textAlign: "center",
  },
  button: {
    color: "white",
    cursor: "pointer",
    marginTop: "44px",
    borderStyle: "solid",
    borderColor: "#0A69FF",
    borderRadius: "3.125rem",
    borderWidth: "0.0625rem",
    backgroundColor: "#0A69FF",
    transition: "background-color 0.1s, color 0.1s",
    "&:hover": {
      color: alohi.gray,
      backgroundColor: alohi.white,
      "& svg": {
        color: "#0A69FF",
      },
    },
  },
  buttonOpened: {
    color: "black",
    backgroundColor: "transparent",
  },
  buttonContent: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  buttonIcon: {
    fontSize: "32px",
    marginRight: "16px",
    transition: "color 0.1s",
    color: "white",
  },
  hoveredButtonIcon: {
    color: "#0A69FF",
    fontSize: "32px",
    marginRight: "16px",
    transition: "color 0.1s",
  },
  buttonText: {
    fontWeight: 400,
    fontSize: "32px",
    textAlign: "center",
    color: "inherit",
    userSelect: "none",
    WebkitUserSelect: "none",
  },
  descriptionText: {
    marginTop: "134px",
    marginBottom: "81px",
    fontWeight: 400,
    fontSize: "22px",
    textAlign: "center",
  },
  dropOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: 50,
    backgroundColor: alohi.blue + "2e", // alohi.blue with transparency
  },
}));

export default Uploader;
