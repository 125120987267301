import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { cartsApi } from "api";
import RS, { FetchError, initialRequestState } from "enums/requestStatus";
import { RootState } from "../store";

export const clearCart = createAction("PAYMENT/CLEAR_CART");
export const clearCartStatus = createAction("PAYMENT/CLEAR_CART_STATUS");

const initialCartState = {
  cart: {
    cartStatus: initialRequestState,
    updateCart: initialRequestState,
  },
};

const updateCart = createAsyncThunk<unknown, unknown, { rejectValue: FetchError["origin"] }>(
  "PAYMENT/UPDATE_CART",
  async (cartData, { rejectWithValue }) => {
    try {
      const response = await cartsApi.post({ id: cartData });
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getCartStatus = createAsyncThunk<unknown, unknown>(
  "PAYMENT/GET_CART_STATUS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await cartsApi.getCartStatus();
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const cartSlice: unknown = {
  [clearCart as never]: (state: RootState["payment"]) => {
    state.cart.updateCart.response = null;
    state.cart.updateCart.status = RS.IDLE;
    state.cart.updateCart.error = null;
  },
  [updateCart.pending as never]: (state: RootState["payment"]) => {
    state.cart.updateCart.response = null;
    state.cart.updateCart.status = RS.RUNNING;
    state.cart.updateCart.error = null;
  },
  [updateCart.fulfilled as never]: (state: RootState["payment"], action: { payload: unknown }) => {
    state.cart.updateCart.response = action.payload;
    state.cart.updateCart.status = RS.IDLE;
    state.cart.updateCart.error = null;
  },
  [updateCart.rejected as never]: (
    state: RootState["payment"],
    action: { payload: FetchError["origin"] },
  ) => {
    state.cart.updateCart.response = null;
    state.cart.updateCart.status = RS.ERROR;
    state.cart.updateCart.error = action.payload;
  },
  [clearCartStatus as never]: (state: RootState["payment"]) => {
    state.cart.cartStatus.response = null;
    state.cart.cartStatus.status = RS.IDLE;
    state.cart.cartStatus.error = null;
  },
  [getCartStatus.pending as never]: (state: RootState["payment"]) => {
    state.cart.cartStatus.response = null;
    state.cart.cartStatus.status = RS.RUNNING;
    state.cart.cartStatus.error = null;
  },
  [getCartStatus.fulfilled as never]: (
    state: RootState["payment"],
    action: { payload: unknown },
  ) => {
    state.cart.cartStatus.response = action.payload;
    state.cart.cartStatus.status = RS.IDLE;
    state.cart.cartStatus.error = null;
  },
  [getCartStatus.rejected as never]: (
    state: RootState["payment"],
    action: { payload: FetchError["origin"] },
  ) => {
    state.cart.cartStatus.response = null;
    state.cart.cartStatus.status = RS.ERROR;
    state.cart.cartStatus.error = action.payload;
  },
};

export { getCartStatus, updateCart, cartSlice, initialCartState };
