import { Box } from "@alohi/kit";
import { useSelector } from "react-redux";

import { selectIsCorporateUpgrade } from "selectors/common.selector";
import AvailablePlans from "../AvailablePlans";
import ChangeToCorporatePlan from "./ChangeToCorporatePlan";
import ChangeToIndividualPlan from "./ChangeToIndividualPlan";

function ChangePlanFromFreeComponent() {
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={2}>
      <AvailablePlans />
      {isCorporateUpgrade ? <ChangeToCorporatePlan /> : null}
      <Box flex="1 0 auto">{!isCorporateUpgrade ? <ChangeToIndividualPlan /> : null}</Box>
    </Box>
  );
}

export default ChangePlanFromFreeComponent;
