import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Modal, Typography, useBool } from "@alohi/kit";
import { useSelector } from "react-redux";

import { Link } from "ui";
import ChangePlanToCustom from "components/Payment/ChangePlanToCustom";
import { alohiAdminUrls, websiteUrls } from "routes/urls";
import useAdminRedirections from "hooks/useAdminRedirections";
import { selectPlanPeriod } from "selectors/plan.selector";

interface RequireUpgradeToPremiumOrHigherProps {
  title: string;
  featureName: string;
  handleClosure: () => void;
}

function RequireUpgradeToPremiumOrHigher({
  title,
  featureName,
  handleClosure,
}: RequireUpgradeToPremiumOrHigherProps) {
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const period = useSelector(selectPlanPeriod);

  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: isChangePlanOpenBool.setTrue,
      adminParams: {
        "plan-type": "PREMIUM",
        period: period ?? "",
      },
    });
  }, [redirectToAdmin, isChangePlanOpenBool.setTrue, period]);

  return (
    <>
      <Modal
        maxWidth="xs"
        title={title}
        onCancel={handleClosure}
        onConfirm={handleOpenChangePlan}
        confirmTitle={t("COMMON.CHANGE_PLAN")}
      >
        <Box p={3}>
          <Typography>
            <Trans
              values={{ featureName }}
              i18nKey={"CHANGE_PLAN.REQUIRE_UPGRADE_TO_PREMIUM_OR_HIGHER"}
            />
          </Typography>
          <Box mt={1} />
          <Typography>
            <Trans
              i18nKey={"CHANGE_PLAN.PLEASE_UPGRADE_TO_PREMIUM_OR_HIGHER"}
              components={{
                pricingLink: <Link target="_blank" rel="noopener" href={websiteUrls.pricing} />,
              }}
            />
          </Typography>
        </Box>
      </Modal>

      {isChangePlanOpen ? (
        <ChangePlanToCustom type="premium" handleClosure={handleClosure} />
      ) : null}
    </>
  );
}

export default RequireUpgradeToPremiumOrHigher;
