import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef } from "react";

import { GOOGLE_PICKER_OAUTH } from "config";
import useInjectTag from "hooks/useInjectTag";
import useOnMountCondition from "hooks/useOnMountCondition";

interface UseGoogleDrivePickerProps {
  allowedMimes: string[];
  onLoading?: (isLoading: boolean) => void;
  onAddDocuments: (documents: FileList | File[]) => void;
}

interface ExtendedWindow extends Window {
  gapi: typeof gapi;
  google: typeof google;
}

declare const window: ExtendedWindow;

const GOOGLE_API_URL = `https://apis.google.com/js/api.js`;
const GOOGLE_GSI_URL = `https://accounts.google.com/gsi/client`;
const SCOPE = `https://www.googleapis.com/auth/drive.readonly`;
const DRIVE_DOWNLOAD_URL = `https://www.googleapis.com/drive/v3/files`;

const googleMimeTypes = [
  "application/vnd.google-apps.document",
  "application/vnd.google-apps.spreadsheet",
];

function useGoogleDrivePicker({
  onLoading,
  allowedMimes,
  onAddDocuments,
}: UseGoogleDrivePickerProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    isError: isGapiError,
    isLoaded: isGapiLoaded,
    isLoading: isGapiLoading,
    setTag: setGapiScript,
  } = useInjectTag("script");
  const {
    isError: isGoogleError,
    isLoaded: isGoogleLoaded,
    isLoading: isGooleLoading,
    setTag: setGoogleScript,
  } = useInjectTag("script");

  const oauthState = useRef<{ accessToken: null | string; isOpen: boolean }>({
    accessToken: null,
    isOpen: false,
  });

  const handleTransformFile = useCallback(async (document: google.picker.DocumentObject) => {
    try {
      if (!oauthState.current.accessToken) {
        throw new Error("No access token");
      }

      const headers = {
        Authorization: `Bearer ${oauthState.current.accessToken}`,
      };
      const fileId = document[google.picker.Document.ID];
      const fileName = document[google.picker.Document.NAME];
      const mimeType = document[google.picker.Document.MIME_TYPE];

      const isGoogleMimeType = googleMimeTypes.includes(mimeType);
      const downloadUrl = isGoogleMimeType
        ? `${DRIVE_DOWNLOAD_URL}/${fileId}/export?mimeType=application%2Fpdf`
        : `${DRIVE_DOWNLOAD_URL}/${fileId}?alt=media`;

      const response = await fetch(downloadUrl, { headers });
      const blob = await response.blob();
      const file = new File([blob], fileName, {
        type: isGoogleMimeType ? "application/pdf" : mimeType,
      });

      return file;
    } catch {
      throw new Error("Can't download file");
    }
  }, []);

  const handlePickedFiles = useCallback(
    async (data: google.picker.ResponseObject) => {
      try {
        onLoading?.(true);

        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
          const promises = data[google.picker.Response.DOCUMENTS].map(handleTransformFile);
          const response = await Promise.allSettled(promises);

          const files = response.reduce<File[]>((accumulator, currentValue) => {
            if (currentValue.status === "fulfilled") {
              accumulator.push(currentValue.value);
            }
            return accumulator;
          }, []);

          onAddDocuments(files);
        }
      } catch {
        throw new Error("Can't download files");
      } finally {
        onLoading?.(false);
      }
    },
    [handleTransformFile, onAddDocuments, onLoading],
  );

  const handleOpenPicker = useCallback(
    (acessToken: string) => {
      new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .addView(google.picker.ViewId.DOCS)
        .addView(google.picker.ViewId.PDFS)
        .addView(google.picker.ViewId.DOCUMENTS)
        .addView(google.picker.ViewId.DOCS_IMAGES)
        // Also allow Google's proprietary files (Docs + Spreadsheets):
        // 'application/vnd.google-apps.document' and 'application/vnd.google-apps.spreadsheet'
        // These will be converted to pdfs
        .setSelectableMimeTypes([...allowedMimes, ...googleMimeTypes].join(","))
        .setOAuthToken(acessToken)
        .setCallback(handlePickedFiles)
        .build()
        .setVisible(true);
    },
    [allowedMimes, handlePickedFiles],
  );

  const handleCreatePicker = useCallback(() => {
    oauthState.current.isOpen = true;

    window.google.accounts.oauth2
      .initTokenClient({
        scope: SCOPE,
        client_id: GOOGLE_PICKER_OAUTH,
        error_callback: () => {
          oauthState.current.isOpen = false;
        },
        callback: (tokenResponse) => {
          oauthState.current.isOpen = false;

          if (tokenResponse.error !== undefined) {
            enqueueSnackbar(t("SENT_FAX.ERROR_DROPBOX"), { variant: "error" });
          } else {
            oauthState.current.accessToken = tokenResponse.access_token;
            handleOpenPicker(tokenResponse.access_token);
          }
        },
      })
      .requestAccessToken({
        prompt: oauthState.current.accessToken === null ? "consent" : "",
      });
  }, [enqueueSnackbar, t, handleOpenPicker]);

  const handleLoadScripts = useCallback(() => {
    if (isGoogleLoaded && isGapiLoaded) {
      // We have the scripts ready, decide between opening GDrive picker or Google Oauth picker
      if (oauthState.current.accessToken) {
        handleOpenPicker(oauthState.current.accessToken);
      } else if (!oauthState.current.isOpen) {
        window.gapi.load("picker", handleCreatePicker);
      }
    } else {
      setGapiScript({ src: GOOGLE_API_URL, async: "true" });
      setGoogleScript({ src: GOOGLE_GSI_URL, async: "true" });
    }
  }, [
    isGapiLoaded,
    setGapiScript,
    isGoogleLoaded,
    setGoogleScript,
    handleOpenPicker,
    handleCreatePicker,
  ]);

  useOnMountCondition(() => {
    // Create the picker only once on mount
    window.gapi.load("picker", handleCreatePicker);
  }, Boolean(isGapiLoaded && isGoogleLoaded && window.gapi && !oauthState.current.accessToken && !oauthState.current.isOpen));

  useEffect(() => {
    if (isGoogleError || isGapiError) {
      enqueueSnackbar(t("SENT_FAX.ERROR_DROPBOX"), { variant: "error" });
    }
  }, [enqueueSnackbar, isGapiError, isGoogleError, t]);

  return {
    loadGooglePicker: handleLoadScripts,
    isGooglePickerLoading: isGapiLoading || isGooleLoading,
  };
}

export default useGoogleDrivePicker;
