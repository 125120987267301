import createSelector from "selectorator";
import { paymentProviders, paymentTypes } from "../enums/payment";
import RS from "../enums/requestStatus";

import i18n from "../i18n/index";
import { selectCurrentCreditCurrency } from "../selectors/credit.selector";
import { sentryCaptureMessage } from "helpers/sentry";

export const selectChargeAmount = createSelector(
  ["payment.chargeAmount.response"],
  (chargeAmount) => chargeAmount,
);

export const selectPurchasedNumbers = createSelector(
  ["payment.purchasedNumbers"],
  (purchasedNumbers) => purchasedNumbers,
);

export const selectIsChargeAmountLoading = createSelector(
  ["payment.chargeAmount.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsChargeAmountError = createSelector(
  ["payment.chargeAmount.status"],
  (status) => status === RS.ERROR,
);

export const selectChargeAmountError = createSelector(
  [selectIsChargeAmountError, "payment.chargeAmount.error"],
  (isError, error) => {
    if (!isError || !error) {
      return "";
    }

    if (error?.isStripeError && error.message) {
      return error.message;
    }

    switch (error.reason) {
      case "charge_error":
      case "switch_not_allowed":
      case "credit_card_charge_failed":
        return i18n.t("CHARGE.ERROR");
      case "purchase_error":
        return i18n.t("CHARGE.PURCHASE_ERROR");
      case "add_number_error":
        return i18n.t("CHARGE.ADD_NUMBER_ERROR");
      case "cart_does_not_exist.":
        return i18n.t("CHARGE.CART_DOES_NOT_EXIST");
      case "numbers_limit_reached":
        return i18n.t("CHARGE.NUMBERS_LIMIT_REACHED");
      case "item_expired":
        return i18n.t("CHARGE.CARD_ITEM_EXPIRED");
      case "purchase_config_error":
        return i18n.t("CHARGE.PURCHASE_CONFIG_ERROR");
      case "GENERIC_PAYMENT_DECLINED":
        return i18n.t("ERRORS.GENERIC_PAYMENT_DECLINED");
      case "CARD_PAYMENT_DECLINED":
        return i18n.t("ERRORS.CARD_PAYMENT_DECLINED");
      case "ERROR_PAYPAL":
      case "PAYPAL_PAYMENT_DECLINED":
        return i18n.t("ERRORS.PAYPAL_PAYMENT_DECLINED");
      case "INVALID_PAYMENT_METHOD":
        return i18n.t("ERRORS.INVALID_PAYMENT_METHOD");
      case "INCORRECT_CARD_CVC_CODE":
        return i18n.t("ERRORS.INCORRECT_CARD_CVC_CODE");
      case "INCORRECT_CARD_NUMBER":
        return i18n.t("ERRORS.INCORRECT_CARD_NUMBER");
      case "PAYMENT_METHOD_EXPIRED":
        return i18n.t("ERRORS.PAYMENT_METHOD_EXPIRED");
      case "PAYMENT_METHOD_EXCEEDED_LIMIT":
        return i18n.t("ERRORS.PAYMENT_METHOD_EXCEEDED_LIMIT");
      case "PAYMENT_FRAUD_PREVENTION":
        return i18n.t("ERRORS.PAYMENT_FRAUD_PREVENTION");
      case "PAYMENT_3DS_ERROR":
        return i18n.t("ERRORS.PAYMENT_3DS_ERROR");
      case "CARD_NOT_SUPPORTED":
        return i18n.t("ERRORS.CARD_NOT_SUPPORTED");
      case "PAYMENT_TECHNICAL_ERROR":
        return i18n.t("ERRORS.PAYMENT_TECHNICAL_ERROR");
      // In these cases we will take care of the message using selectIsNumberBundleNotFound
      // meaning, the returned string here will not be shown to the user
      case "address_requirement_not_set":
      case "purchase_error:address_not_found":
        return i18n.t("COMMON.SERVER_ERROR");
      // In these cases we will take care of the message using selectIsNumberNotAvailable
      // meaning, the returned string here will not be shown to the user
      case "number_belongs_to_another_user":
      case "purchase_error:number_is_reserved":
      case "purchase_error:number_not_available":
        return i18n.t("COMMON.SERVER_ERROR");
      // In these cases we will take care of the message using selectIsNotHonoredPayment
      // meaning, the returned string here will not be shown to the user
      case "do_not_honor":
        return i18n.t("COMMON.SERVER_ERROR");
      case "invalid_cart":
      case "card_declined":
      case "Internal Error.":
      case "internal_server_error":
      case "purchase_error:general-('Connection aborted.', RemoteDisconnected('Remote end closed connection without response'))":
      default:
        sentryCaptureMessage({
          message: `Something went wrong during charge amount`,
          level: "error",
          breadcumbs: {
            category: "fetch",
            level: "warning",
            message: JSON.stringify(error),
          },
        });
        return i18n.t("COMMON.PAYMENT_ERROR");
    }
  },
);

export const selectIsNumberNotAvailable = createSelector(
  [selectIsChargeAmountError, "payment.chargeAmount.error"],
  (isError, error) => {
    if (!isError || !error) {
      return false;
    }

    switch (error?.reason) {
      case "number_belongs_to_another_user":
      case "purchase_error:number_is_reserved":
      case "purchase_error:number_not_available":
        return true;
      default:
        return false;
    }
  },
);

export const selectIsNotHonoredPayment = createSelector(
  [selectIsChargeAmountError, "payment.chargeAmount.error"],
  (isError, error) => {
    if (!isError || !error) {
      return false;
    }

    switch (error?.reason) {
      case "do_not_honor":
        return true;
      default:
        return false;
    }
  },
);

export const selectIsNumberBundleNotFound = createSelector(
  [selectIsChargeAmountError, "payment.chargeAmount.error"],
  (isError, error) => {
    if (!isError || !error) {
      return false;
    }

    switch (error?.reason) {
      case "address_requirement_not_set":
      case "purchase_error:address_not_found":
        return true;
      default:
        return false;
    }
  },
);

export const selectPossibleAmounts = createSelector(
  ["payment.paymentAmounts.possibleAmount.response", selectCurrentCreditCurrency],
  (response, currentCreditCurrency) => {
    if (!response) {
      return [];
    }
    const uniqueAmounts = new Map();
    for (const value of Object.values(response.amounts)) {
      if (value?.currency && value.currency === currentCreditCurrency) {
        uniqueAmounts.set(value.amount, value);
      }
    }
    return Array.from(uniqueAmounts.values());
  },
);

export const selectIsLoadingPossibleAmount = createSelector(
  ["payment.paymentAmounts.possibleAmount.status"],
  (status) => status === RS.RUNNING,
);

export const selectDiscountCodeResponse = createSelector(
  ["payment.paymentAmounts.discountAmount.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectDiscountCodeType = createSelector([selectDiscountCodeResponse], (response) => {
  if (!response) {
    return null;
  }
  return response.discount_code.type;
});

export const selectDiscountCodeAmount = createSelector([selectDiscountCodeResponse], (response) => {
  if (!response) {
    return null;
  }
  return response.amount;
});

export const selectDiscountCodeReducedAmount = createSelector(
  [selectDiscountCodeResponse],
  (response) => {
    if (!response) {
      return null;
    }
    return response.discount_code.amount;
  },
);

export const selectDiscountCodeCurrency = createSelector(
  [selectDiscountCodeResponse],
  (response) => {
    if (!response) {
      return null;
    }
    return response.currency;
  },
);

export const selectDiscountCodeApplied = createSelector(
  [selectDiscountCodeResponse],
  (response) => {
    if (!response) {
      return null;
    }
    return response.amount_with_discount;
  },
);

export const selectDiscountCodeError = createSelector(
  ["payment.paymentAmounts.discountAmount.error"],
  (error) => error,
);

export const selectCustomAmountResponse = createSelector(
  ["payment.paymentAmounts.customAmount.response"],
  (response) => response,
);

export const selectCustomAmount = createSelector(
  ["payment.paymentAmounts.customAmount.selected"],
  (selected) => selected,
);

export const selectSelectedPaymentAmount = createSelector(
  [
    "payment.paymentAmounts.discountAmount.response",
    "payment.paymentAmounts.customAmount.response",
    "payment.paymentAmounts.possibleAmount.selected",
  ],
  (discountAmount, customAmount, possibleAmount) => {
    if (discountAmount) {
      return discountAmount;
    }
    if (customAmount) {
      return customAmount;
    }
    return possibleAmount;
  },
);

export const selectPaymentDepositError = createSelector(
  [
    "payment.deposit.status",
    "payment.deposit.error",
    "payment.confirmDeposit.status",
    "payment.confirmDeposit.error",
  ],
  (depositStatus, error, confirmDepositStatus, confirmDepositError) => {
    if (
      (depositStatus !== RS.ERROR && confirmDepositStatus !== RS.ERROR) ||
      (!error && !confirmDepositError)
    ) {
      return "";
    }

    error = error || confirmDepositError;

    if (error?.isStripeError && error.message) {
      return error.message;
    }

    switch (error?.reason) {
      case "GENERIC_PAYMENT_DECLINED":
        return i18n.t("ERRORS.GENERIC_PAYMENT_DECLINED");
      case "CARD_PAYMENT_DECLINED":
        return i18n.t("ERRORS.CARD_PAYMENT_DECLINED");
      case "ERROR_PAYPAL":
      case "PAYPAL_PAYMENT_DECLINED":
        return i18n.t("ERRORS.PAYPAL_PAYMENT_DECLINED");
      case "INVALID_PAYMENT_METHOD":
        return i18n.t("ERRORS.INVALID_PAYMENT_METHOD");
      case "INCORRECT_CARD_CVC_CODE":
        return i18n.t("ERRORS.INCORRECT_CARD_CVC_CODE");
      case "INCORRECT_CARD_NUMBER":
        return i18n.t("ERRORS.INCORRECT_CARD_NUMBER");
      case "PAYMENT_METHOD_EXPIRED":
        return i18n.t("ERRORS.PAYMENT_METHOD_EXPIRED");
      case "PAYMENT_METHOD_EXCEEDED_LIMIT":
        return i18n.t("ERRORS.PAYMENT_METHOD_EXCEEDED_LIMIT");
      case "PAYMENT_FRAUD_PREVENTION":
        return i18n.t("ERRORS.PAYMENT_FRAUD_PREVENTION");
      case "PAYMENT_3DS_ERROR":
        return i18n.t("ERRORS.PAYMENT_3DS_ERROR");
      case "PAYMENT_TECHNICAL_ERROR":
        return i18n.t("ERRORS.PAYMENT_TECHNICAL_ERROR");
      case "CARD_NOT_SUPPORTED":
        return i18n.t("ERRORS.CARD_NOT_SUPPORTED");
      case "purchase_error:number_not_available":
        // Handled by selectIsNumberNotAvailable
        return i18n.t("COMMON.SERVER_ERROR");
      case "do_not_honor":
        // Handled by selectIsNotHonoredPayment
        return i18n.t("COMMON.SERVER_ERROR");
      case "card_declined":
      case "internal_server_error":
      default:
        sentryCaptureMessage({
          message: `Something went wrong during deposit or confirmDeposit`,
          level: "error",
          breadcumbs: {
            category: "fetch",
            level: "warning",
            message: JSON.stringify(error),
          },
        });

        return i18n.t("COMMON.PAYMENT_ERROR");
    }
  },
);

export const selectDepositIsNotHonored = createSelector(
  [selectPaymentDepositError, "payment.deposit.error", "payment.confirmDeposit.error"],
  (isError, error, confirmDepositError) => {
    if (!isError) {
      return false;
    }

    error = error || confirmDepositError;

    switch (error?.reason) {
      case "do_not_honor":
        return true;
      default:
        return false;
    }
  },
);

export const selectPaymentDeposit = createSelector(
  ["payment.deposit.response", "payment.confirmDeposit.response"],
  (newDeposit, confirmedDeposit) => {
    if (confirmedDeposit) {
      return {
        amount: confirmedDeposit.amount,
        currency: confirmedDeposit.currency,
      };
    }
    if (newDeposit?.status === "requires_action") {
      return null;
    }
    return newDeposit
      ? {
          amount: newDeposit.amount,
          currency: newDeposit.currency,
        }
      : null;
  },
);

export const selectPaymentDepositRequiresAction = createSelector(
  ["payment.deposit.response"],
  (response) => {
    if (response?.status === "requires_action") {
      return response;
    }
    return null;
  },
);

export const selectIsPaymentMethodsLoading = createSelector(
  ["payment.paymentMethods.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsPaymentMethodsError = createSelector(
  ["payment.paymentMethods.status"],
  (status) => status === RS.ERROR,
);

export const selectDefaultPaymentMethod = createSelector(
  ["payment.defaultPaymentMethod.response"],
  (response) => {
    if (!response) {
      return null;
    }
    if (response?.type === "paypal") {
      return paymentTypes.PAYPAL;
    }
    if (response?.object === "card") {
      return paymentTypes.STRIPE;
    }
    return null;
  },
);

export const selectDefaultPaymentThumbnail = createSelector(
  ["payment.defaultPaymentMethod.response"],
  (response) => {
    if (!response) {
      return null;
    }
    if (response?.type === "paypal" && response.email) {
      return `${response.email}`;
    }
    if (response?.object === "card" && response?.last4) {
      return `**** **** **** ${response.last4}`;
    }
    return null;
  },
);

export const selectDefaultPaymentMethodText = createSelector(
  ["payment.defaultPaymentMethod.response"],
  (response) => {
    if (!response) {
      return null;
    }
    if (response?.type === "paypal") {
      return response.email;
    }
    if (response?.object === "card") {
      return response.last4;
    }
    return null;
  },
);

export const selectIsDefaultPaymentMethodError = createSelector(
  ["payment.defaultPaymentMethod.status"],
  (status) => status === RS.ERROR,
);

export const selectPaymentMethods = createSelector(
  ["payment.paymentMethods.response"],
  (methods) => methods?.payment_methods,
);

export const selectDefaultPaymentSource = createSelector(
  ["payment.paymentMethods.response"],
  (response) => response?.default_source,
);

export const selectSelectedPaymentMethod = createSelector(
  ["payment.paymentMethods.selected", "payment.paymentMethods.response"],
  (selected, defaultSelected) => {
    if (selected) {
      const newObject = {
        ...selected,
        method: selected?.type === "paypal" ? paymentProviders.PAYPAL : paymentProviders.STRIPE,
      };
      return newObject;
    }
    if (defaultSelected?.default_source && defaultSelected?.payment_methods) {
      const [selected] = defaultSelected.payment_methods.filter(
        (element) => element.id === defaultSelected.default_source,
      );
      const newObject = {
        ...selected,
        method: selected?.type === "paypal" ? paymentProviders.PAYPAL : paymentProviders.STRIPE,
      };
      return newObject;
    }
    return null;
  },
);

export const selectNewPaymentProvider = createSelector(
  ["payment.paymentMethods.newProvider"],
  (provider) => provider,
);

export const selectSelectedPaymentLast4Digits = createSelector(
  [selectSelectedPaymentMethod],
  (selectedPayment) => {
    if (!selectedPayment) {
      return "";
    }
    return selectedPayment.last4;
  },
);

export const selectSelectedPaymentType = createSelector(
  [selectSelectedPaymentMethod],
  (selectedPayment) => {
    if (!selectedPayment) {
      return "";
    }
    if (selectedPayment?.type === "paypal") {
      return paymentTypes.PAYPAL;
    }
    if (selectedPayment?.object === "card") {
      return paymentTypes.STRIPE;
    }
    return null;
  },
);

export const selectIsPaymentProcessLoading = createSelector(
  [
    "payment.creditCard.stripe.status",
    "payment.creditCard.newCreditCard.status",
    "payment.deposit.status",
    "payment.paymentMethods.status",
    "payment.paymentAmounts.discountAmount.status",
    "payment.paymentAmounts.customAmount.status",
    "payment.paymentAmounts.possibleAmount.status",
    "payment.confirmDeposit.status",
    selectPaymentDepositRequiresAction,
  ],
  (
    stripeStatus,
    newCreditCardStatus,
    depositStatus,
    methodsStatus,
    discountStatus,
    customStatus,
    possibleStatus,
    confirmDepositStatus,
    depositRequiresAction,
  ) =>
    stripeStatus === RS.RUNNING ||
    newCreditCardStatus === RS.RUNNING ||
    depositStatus === RS.RUNNING ||
    methodsStatus === RS.RUNNING ||
    discountStatus === RS.RUNNING ||
    customStatus === RS.RUNNING ||
    possibleStatus === RS.RUNNING ||
    confirmDepositStatus === RS.RUNNING ||
    (!!depositRequiresAction && confirmDepositStatus !== RS.ERROR),
);

export const selectIsChargeProcessLoading = createSelector(
  ["payment.chargeAmount.status", "cart.updateCart.status", "plans.availablePlans.status"],
  (charge, cart, plans) => charge === RS.RUNNING || cart === RS.RUNNING || plans === RS.RUNNING,
);

export const selectCartError = createSelector(
  ["payment.cart.updateCart.status", "payment.cart.updateCart.error"],
  (status, error) => {
    if (status === RS.ERROR) {
      if (error?.reason) {
        switch (error.reason) {
          case "advanced_security_is_enabled":
            return i18n.t("CHANGE_PLAN.PLEASE_CONTACT_SUPPORT");
          case "plan_members_limit_reached":
            return i18n.t("FORMS.THE_CURRENT_NUMBER_OF_MEMBERS_IN_YOUR_TEAM_EXCEEDS_5");
          case "outbox_is_not_empty":
            return i18n.t("CHANGE_NUMBER.OUTBOX_IS_NOT_EMPTY");
          // In these cases we will take care of the message using selectHasNoActiveBundleCartError
          // meaning, the returned string here will not be shown to the user
          case "no_active_bundle":
            return null;
          default:
        }
      }
      return i18n.t("COMMON.SERVER_ERROR");
    }
    return null;
  },
);

export const selectHasNoActiveBundleCartError = createSelector(
  ["payment.cart.updateCart.status", "payment.cart.updateCart.error"],
  (status, error) => {
    if (status === RS.ERROR) {
      if (error?.reason) {
        switch (error.reason) {
          case "no_active_bundle":
            return true;
          default:
            return false;
        }
      }
      return false;
    }
  },
);

export const selectIsCartLoading = createSelector(
  ["payment.cart.updateCart.status"],
  (status) => status === RS.RUNNING,
);

export const selectCurrentCart = createSelector(
  ["payment.cart.updateCart.response"],
  (response) => response,
);

export const selectCreditCardInfo = createSelector(
  ["payment.creditCard.creditCardInfo"],
  (creditInfo) => creditInfo,
);

export const selectIsCreditCardCompleted = createSelector(
  ["payment.creditCard.isCreditCardCompleted"],
  (isCompleted) => isCompleted,
);

export const selectTriggerStripePayment = createSelector(
  ["payment.creditCard.stripe.status"],
  (status) => status === RS.RUNNING,
);

export const selectStripePaymentId = createSelector(
  ["payment.creditCard.stripe.response"],
  (stripePaymentId) => stripePaymentId,
);

export const selectNewCreditCard = createSelector(
  ["payment.creditCard.newCreditCard.response"],
  (response) => response?.payment_method_id,
);

export const selectNewCreditCardError = createSelector(
  ["payment.creditCard.newCreditCard.status", "payment.creditCard.newCreditCard.error"],
  (status, error) => {
    if (status === RS.ERROR) {
      switch (error?.reason) {
        case "GENERIC_PAYMENT_DECLINED":
          return i18n.t("ERRORS.GENERIC_PAYMENT_DECLINED");
        case "CARD_PAYMENT_DECLINED":
          return i18n.t("ERRORS.CARD_PAYMENT_DECLINED");
        case "ERROR_PAYPAL":
        case "PAYPAL_PAYMENT_DECLINED":
          return i18n.t("ERRORS.PAYPAL_PAYMENT_DECLINED");
        case "INVALID_PAYMENT_METHOD":
          return i18n.t("ERRORS.INVALID_PAYMENT_METHOD");
        case "INCORRECT_CARD_CVC_CODE":
          return i18n.t("ERRORS.INCORRECT_CARD_CVC_CODE");
        case "INCORRECT_CARD_NUMBER":
          return i18n.t("ERRORS.INCORRECT_CARD_NUMBER");
        case "PAYMENT_METHOD_EXPIRED":
          return i18n.t("ERRORS.PAYMENT_METHOD_EXPIRED");
        case "PAYMENT_METHOD_EXCEEDED_LIMIT":
          return i18n.t("ERRORS.PAYMENT_METHOD_EXCEEDED_LIMIT");
        case "PAYMENT_FRAUD_PREVENTION":
          return i18n.t("ERRORS.PAYMENT_FRAUD_PREVENTION");
        case "PAYMENT_3DS_ERROR":
          return i18n.t("ERRORS.PAYMENT_3DS_ERROR");
        case "PAYMENT_TECHNICAL_ERROR":
          return i18n.t("ERRORS.PAYMENT_TECHNICAL_ERROR");
        case "CARD_NOT_SUPPORTED":
          return i18n.t("ERRORS.CARD_NOT_SUPPORTED");
        // In these cases we will take care of the message using selectIsNotHonoredPayment
        // meaning, the returned string here will not be shown to the user
        case "do_not_honor":
          return i18n.t("COMMON.SERVER_ERROR");
        case "card_declined":
        case "internal_server_error":
        default:
          sentryCaptureMessage({
            message: `Something went wrong during adding the credit card`,
            level: "error",
            breadcumbs: {
              category: "fetch",
              level: "warning",
              message: JSON.stringify(error),
            },
          });

          return i18n.t("COMMON.PAYMENT_ERROR");
      }
    }

    return "";
  },
);

export const selectNewCreditCardIsNotHonored = createSelector(
  ["payment.creditCard.newCreditCard.status", "payment.creditCard.newCreditCard.error"],
  (status, error) => {
    if (status !== RS.ERROR) {
      return false;
    }

    switch (error?.reason) {
      case "do_not_honor":
        return true;
      default:
        return false;
    }
  },
);

export const selectIsLoadingDeleteCreditCard = createSelector(
  ["payment.creditCard.deleteCreditCard.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsErrorDeleteCreditCard = createSelector(
  ["payment.creditCard.deleteCreditCard.status"],
  (status) => status === RS.ERROR,
);

export const selectIsDeleteCreditCardSuccessful = createSelector(
  ["payment.creditCard.deleteCreditCard.response"],
  (response) => response,
);

export const selectIsLoadingUpdateCreditCard = createSelector(
  ["payment.creditCard.updateCreditCard.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUpdateCreditCardSuccessful = createSelector(
  ["payment.creditCard.updateCreditCard.response"],
  (response) => response,
);

export const selectIsErrorUpdateCreditCard = createSelector(
  ["payment.creditCard.updateCreditCard.status"],
  (status) => status === RS.ERROR,
);

export const selectRefundId = createSelector(
  ["payment.refund.response"],
  (response) => response?.id,
);

export const selectCorporateUpgradeState = createSelector(
  ["payment.corporateUpgrade"],
  (object) => object,
);

export const selectPaymentHistory = createSelector(
  ["payment.paymentHistory.response"],
  (list) => list,
);

export const selectPaymentHistoryHasMore = createSelector(
  ["payment.paymentHistory.hasMore"],
  (hasMore) => hasMore,
);

export const selectIsPaymentHistoryLoading = createSelector(
  ["payment.paymentHistory.status"],
  (status) => status === RS.RUNNING,
);

export const selectPaymentReceipt = createSelector(
  ["payment.paymentReceipt.response"],
  (receipt) => receipt,
);

export const selectIsPaymentReceiptLoading = createSelector(
  ["payment.paymentReceipt.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsPaymentReceiptError = createSelector(
  ["payment.paymentReceipt.status"],
  (status) => status === RS.ERROR,
);

/*
 * If there is a new payment id, we return that for the deposit,
 * otherwise we return the selected payment id.
 */
export const selectPaymentTokenId = createSelector(
  [selectSelectedPaymentMethod, selectNewCreditCard],
  (selectedPayment, newCreditCard) => {
    if (newCreditCard) {
      return newCreditCard;
    }
    if (selectedPayment?.id) {
      return selectedPayment.id;
    }
    return null;
  },
);

export const selectIsCartInProgress = createSelector(
  [
    "payment.cart.cartStatus.response",
    "payment.cart.cartStatus.status",
    "payment.cart.cartStatus.error",
  ],
  (response, status, error) => {
    if (status === RS.ERROR && error === "Not found") {
      return false;
    }

    if (response) {
      return Boolean(
        response?.items?.random?.some((cartItem) => cartItem.item_type === "number-corporate-bulk"),
      );
    }

    return false;
  },
);
