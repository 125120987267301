import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";

const getUserInfo = (token) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/unsubscribe_user_info/${token}`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true });
};

const postUnsubscribe = (token) => {
  const requestOptions = {
    method: "POST",
    body: {
      token,
    },
  };
  const path = `${API_AFFOGATO}/unsubscribe`;
  return fetchWithRefresh(path, requestOptions);
};

const postResubscribe = (token) => {
  const requestOptions = {
    method: "POST",
    body: {
      token,
    },
  };
  const path = `${API_AFFOGATO}/resubscribe`;
  return fetchWithRefresh(path, requestOptions);
};

export const unsubscribeApi = {
  getUserInfo,
  postUnsubscribe,
  postResubscribe,
};
