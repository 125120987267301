import PropTypes from "prop-types";
import CurrencyFormat from "react-currency-format";
import { hasNonZeroDecimal } from "helpers/numbers";

function Currency(props) {
  const map = {
    USD: "$",
    EUR: "€",
    CHF: "CHF",
    GBP: "£",
  };
  const { currency, value, hideZeroDecimal, decimalScale, ...rest } = props;

  if (currency) {
    return (
      <CurrencyFormat
        value={Number(value)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={map[currency.toUpperCase()]}
        renderText={function renderCurrency(formattedValue) {
          return <span data-cy={props.testId}>{formattedValue}</span>;
        }}
        decimalScale={hideZeroDecimal && !hasNonZeroDecimal(Number(value)) ? 0 : decimalScale}
        {...rest}
      />
    );
  }
  return null;
}

Currency.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  testId: PropTypes.string,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  hideZeroDecimal: PropTypes.bool,
};

export default Currency;
