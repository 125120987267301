import map from "lodash/map";
import range from "lodash/range";
import PropTypes from "prop-types";
import { Box } from "@alohi/kit";

import useStyles from "./LinearInfiniteProgressStyle";

const LinearInfiniteProgress = ({ dotCount = 8, diameter = 7, wrapperStyle, wrapperClassName }) => {
  const classes = useStyles();
  return (
    <Box
      component="div"
      style={wrapperStyle}
      className={`${classes.circleContainer} ${wrapperClassName || ""}`}
    >
      {map(range(dotCount), (i) => (
        <Box component="span" width={diameter} height={diameter} key={i} />
      ))}
    </Box>
  );
};

LinearInfiniteProgress.propTypes = {
  dotCount: PropTypes.number,
  diameter: PropTypes.number,
  wrapperStyle: PropTypes.object,
  wrapperClassName: PropTypes.string,
};

export default LinearInfiniteProgress;
