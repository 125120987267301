import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useMemo, useReducer } from "react";

import StepsModal from "components/Modal/StepsModal";
import Documents from "views/CreateAddressBundle/components/Documents/Documents";
import BundlePersonalInformation from "views/CreateAddressBundle/components/BundlePersonalInformation/BundlePersonalInformation";
import {
  selectIsNewBundleError,
  selectIsNewBundleLoading,
  selectIsNewBundleSuccessful,
  selectDocumentTypeDictionary,
  selectIsNewBundleErrorMessage,
  selectIsAddressPowersetLoading,
  selectIsUserAddressRequirementsLoading,
} from "selectors/address.selector";
import {
  selectSelectedNumberCountry,
  selectSelectedNumberItemType,
} from "selectors/verifications.selector";
import {
  getBundles,
  postNewBundle,
  clearPostNewBundle,
  clearAddressPowerset,
  getAddressRequirements,
  clearAddressRequirements,
} from "stores/reducers/address.reducer";
import reducer, {
  createAddressBundleState,
  CreateAddressBundleContext,
} from "./context/createAddressBundle.context";
import BundleType from "./components/BundleType/BundleType";

interface CreateAddressBundleStepsProps {
  handleClosure: () => void;
  withCountrySelector?: boolean;
  preselectedCountry?: string;
  preselectedNumberType?: string;
}

function CreateAddressBundleModal({
  handleClosure,
  withCountrySelector,
  preselectedCountry,
  preselectedNumberType,
}: CreateAddressBundleStepsProps) {
  const [store, localDispatch] = useReducer(reducer, createAddressBundleState);

  return (
    <CreateAddressBundleContext.Provider value={[store, localDispatch]}>
      <CreateAddressBundleSteps
        handleClosure={handleClosure}
        withCountrySelector={withCountrySelector}
        preselectedCountry={preselectedCountry}
        preselectedNumberType={preselectedNumberType}
      />
    </CreateAddressBundleContext.Provider>
  );
}

function CreateAddressBundleSteps({
  handleClosure,
  withCountrySelector,
  preselectedCountry,
  preselectedNumberType,
}: CreateAddressBundleStepsProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      country,
      userData,
      userType,
      stepNames,
      numberType,
      currentStep,
      customFields,
      userDocuments,
      isUserDataValid,
      isUserDocumentsValid,
    },
    localDispatch,
  ] = useContext(CreateAddressBundleContext);

  const isBundleError = useSelector(selectIsNewBundleError);
  const bundleErrorMessage = useSelector(selectIsNewBundleErrorMessage);
  const isBundleLoading = useSelector(selectIsNewBundleLoading);
  const isBundleCreated = useSelector(selectIsNewBundleSuccessful);
  const numberCountry = useSelector(selectSelectedNumberCountry);
  const numberItemType = useSelector(selectSelectedNumberItemType);
  const isPowersetLoading = useSelector(selectIsAddressPowersetLoading);
  const documentDictionary = useSelector(selectDocumentTypeDictionary(userType));
  const isRequirementsLoading = useSelector(selectIsUserAddressRequirementsLoading);

  useEffect(() => {
    if (isBundleCreated) {
      handleClosure();
      dispatch(clearPostNewBundle());
      dispatch(getBundles());
    }
  }, [dispatch, handleClosure, isBundleCreated]);

  const isNextDisabled = useMemo(() => {
    switch (currentStep) {
      case 1:
        return !userType;
      case 2:
        return !isUserDataValid[userType];
      case 3:
        return !isUserDocumentsValid[userType];
      default:
        return false;
    }
  }, [currentStep, userType, isUserDataValid, isUserDocumentsValid]);

  const onForward = useCallback(() => {
    switch (currentStep) {
      case 3:
        dispatch(
          /* eslint-disable-next-line */
          // @ts-ignore
          postNewBundle({
            country,
            userType,
            numberType,
            documentDictionary,
            userData: userData[userType],
            customFields: customFields[userType],
            userDocuments: userDocuments[userType],
          }),
        );
        break;
      default:
        localDispatch({ type: "STEP_FORWARD" });
    }
  }, [
    currentStep,
    dispatch,
    country,
    userType,
    numberType,
    documentDictionary,
    userData,
    customFields,
    userDocuments,
    localDispatch,
  ]);

  const onBack = useCallback(() => {
    switch (currentStep) {
      case 1:
        localDispatch({ type: "RESET_USER_DATA" });
        handleClosure();
        break;
      case 3:
        dispatch(clearAddressPowerset());
        localDispatch({ type: "RESET_ALL_DOCUMENT_FIELDS" });
        localDispatch({ type: "STEP_BACK" });
        break;
      default:
        localDispatch({ type: "RESET_USER_DATA" });
        localDispatch({ type: "STEP_BACK" });
    }
  }, [localDispatch, currentStep, dispatch, handleClosure]);

  useEffect(() => {
    // Store is not initialised, numberCountry, numberItemType, will never change
    // Because those values are only changed from ChangePlan flow
    if (numberType === null && country === null) {
      localDispatch({ type: "UPDATE_COUNTRY", payload: numberCountry });
      localDispatch({ type: "UPDATE_NUMBER_TYPE", payload: numberItemType });
    } else {
      dispatch(clearAddressRequirements());
      localDispatch({ type: "RESET_USER_DATA" });
      if (userType !== null) {
        dispatch(
          /* eslint-disable-next-line */
          // @ts-ignore
          getAddressRequirements({
            country,
            numberType,
            userType,
          }),
        );
      }
    }
  }, [localDispatch, numberItemType, country, numberCountry, numberType, dispatch, userType]);

  useEffect(() => {
    if (isBundleError) {
      enqueueSnackbar(bundleErrorMessage ?? t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearPostNewBundle());
    }
  }, [dispatch, enqueueSnackbar, t, isBundleError, bundleErrorMessage]);

  const isNextLoading = isRequirementsLoading || isBundleLoading;

  return (
    <StepsModal
      onBack={onBack}
      stepNames={stepNames}
      onForward={onForward}
      currentStep={currentStep}
      isNextLoading={isNextLoading}
      isNextDisabled={isNextDisabled}
      isModalDisabled={isPowersetLoading}
      title={t("ADDRESS.REGULATORY_DOCUMENTS")}
      confirmTooltip={isNextDisabled ? t("FORMS.ALL_FIELD_REQUIRED") : undefined}
      nextTitle={currentStep === 3 ? t("ADDRESS.BUNDLE_SUBMIT_DOCUMENTS") : t("COMMON.NEXT")}
      stepComponents={[
        <BundleType
          withCountrySelector={withCountrySelector}
          preselectedCountry={preselectedCountry}
          preselectedNumberType={preselectedNumberType}
          key={1}
        />,
        <BundlePersonalInformation key={2} />,
        <Documents key={3} />,
      ]}
    />
  );
}

export default CreateAddressBundleModal;
