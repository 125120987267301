import { Box } from "@alohi/kit";
import Routes from "routes/Routes";
import usePersistingSession from "./hooks/usePersistingSession";

function App() {
  const isAppReady = usePersistingSession();

  return <Box sx={{ height: "100%" }}>{isAppReady ? <Routes /> : null}</Box>;
}

export default App;
