import PropTypes from "prop-types";
import { Box, Typography } from "@alohi/kit";

import EmptyContactsSvg from "../../assets/images/empty-contacts.svg?react";

function EmptyContacts({ text }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <EmptyContactsSvg width={100} />
      <Typography type="body">{text}</Typography>
    </Box>
  );
}

EmptyContacts.propTypes = {
  text: PropTypes.string,
};

export default EmptyContacts;
