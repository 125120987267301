import PropTypes from "prop-types";
import { TextField } from "@alohi/kit";
import { useSelector } from "react-redux";
import { AsYouType } from "libphonenumber-js";
import { useCallback, useEffect, useRef, useState } from "react";

import { InputAdornment } from "ui";
import { selectDefaultCountry } from "selectors/verifications.selector";
import {
  getI18nCountry,
  parseExtension,
  getI18nCountryList,
  getPrefixForCountry,
  sanitizePhoneNumber,
} from "helpers/numbers";
import CountryFlagDropdown from "../Core/CountryFlag/CountryFlagDropdown";

function FaxNumberInput({
  id,
  value,
  onBlur,
  onFocus,
  isError,
  onChange,
  disabled,
  className,
  placeholder,
  ariaDescribedby,
  fullWidth = true,
}) {
  const { defaultCountry } = useSelector(selectDefaultCountry);

  const [formattedValue, setFormattedValue] = useState(value);
  const [country, setCountry] = useState(defaultCountry);

  const initialValue = useRef(value);

  const handleCountryChange = useCallback(
    ({ target }) => {
      if (getI18nCountry(target.value)?.label) {
        onChange(getPrefixForCountry(target.value));
        setCountry(target.value);
      }
    },
    [onChange],
  );

  const handleValueChange = useCallback(
    ({ target }) => {
      // Value is formatted here
      // Before sending it back to the parent, unformat it (remove whitespace) and add the extension if any
      const { number, extension } = parseExtension(target.value);
      onChange(sanitizePhoneNumber(number) + extension);
    },
    [onChange],
  );

  useEffect(() => {
    // Value will be unformatted when it gets updated from props
    // Format the value to be displayed on DOM and add the extension
    let defaultValue = value;
    if (!defaultValue.includes("+") && !(defaultValue === "")) {
      defaultValue = "+" + defaultValue;
    }
    const asYouType = new AsYouType();
    const { number, extension } = parseExtension(defaultValue);
    setFormattedValue(asYouType.input(number) + extension);
    if (asYouType.country) {
      setCountry(asYouType.country);
    }
  }, [value]);

  useEffect(() => {
    if (initialValue.current === "") {
      setFormattedValue(getPrefixForCountry(defaultCountry));
      setCountry(defaultCountry);
    }
  }, [defaultCountry]);

  return (
    <TextField
      id={id}
      onBlur={onBlur}
      error={isError}
      onFocus={onFocus}
      disabled={disabled}
      fullWidth={fullWidth}
      value={formattedValue}
      placeholder={placeholder}
      className={className || ""}
      onChange={handleValueChange}
      aria-describedby={ariaDescribedby}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CountryFlagDropdown
              value={country}
              options={getI18nCountryList()}
              onChange={handleCountryChange}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

FaxNumberInput.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  setError: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default FaxNumberInput;
