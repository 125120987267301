import { useEffect } from "react";

import useInjectTag from "hooks/useInjectTag";

const ONE_DRIVE_SDK_URL = `https://js.live.net/v7.2/OneDrive.js`;

function OneDrive() {
  const { setTag } = useInjectTag("script");

  useEffect(() => {
    setTag({
      async: "true",
      src: ONE_DRIVE_SDK_URL,
    });
  }, [setTag]);

  return null;
}

export default OneDrive;
