import * as Sentry from "@sentry/react";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { sleep } from "helpers/common";
import { RootState } from "stores/store";
import dayjs, { sendFaxTimezone } from "helpers/date";
import { historyApi, numbersApi, storageApi } from "api";
import { clearFaxesDatabase } from "views/SendFax/components/ResumeFaxes";
import RS, { FetchError, initialRequestState } from "enums/requestStatus";
import { SendFaxFiles, SendFaxStore } from "views/SendFax/contexts/store";
import { selectAccountDefaultFaxFileType } from "selectors/account.selector";
import { logout } from "./authentication.reducer";
import { sentryCaptureMessage } from "helpers/sentry";

const RETRY_AMOUNT = 5;
const RETRY_TIMEOUT = 1000;

const initialState = {
  uploadFiles: {
    ...initialRequestState,
    isRetrying: false,
    sessionId: "",
  },
  sendFax: initialRequestState,
  cdrData: initialRequestState,
  patchNumber: initialRequestState,
  resumeNumber: initialRequestState,
  cancelNumber: initialRequestState,
  accountNumbers: initialRequestState,
  outboxSettings: initialRequestState,
  envelopeMetadata: initialRequestState,
  getAssignedNumbers: initialRequestState,
  updateFaxReception: initialRequestState,
  updateFriendlyName: initialRequestState,
};

export const clearSendFax = createAction("NUMBERS/CLEAR_SEND_FAX");
export const clearGetCdrData = createAction("NUMBERS/CLEAR_GET_CDR_DATA");
export const clearUploadFiles = createAction("NUMBER/CLEAR_UPLOAD_FILES");
export const clearPatchNumber = createAction("NUMBERS/CLEAR_PATCH_NUMBER");
export const clearCancelNumber = createAction("NUMBERS/CLEAR_CANCEL_NUMBER");
export const clearResumeNumber = createAction("NUMBERS/CLEAR_RESUME_NUMBER");
export const clearUpdateFaxReception = createAction("NUMBERS/CLEAR_FAX_RECEPTION");
export const clearUpdateFriendlyName = createAction("NUMBERS/CLEAR_UPDATE_FRIENDLY_NAME");
export const clearGetEnvelopeMetadata = createAction("NUMBERS/CLEAR_GET_ENVELOPE_METADATA");
export const setUploadFilesIsRetrying = createAction<boolean>("NUMBERS/SET_UPLOAD_FILES_RETRY");

export interface CdrData {
  to: string;
  path: string;
  mime: string;
  fileName: string;
  blobString: string;
}

export type UploadFilesResponse = { index: number; path: string }[];

export const uploadFiles = createAsyncThunk<
  { response: UploadFilesResponse; error: { reason: string } | null; sessionId: string },
  { files: SendFaxFiles; sessionId: string },
  { rejectValue: { reason: string; sessionId: string } }
>("NUMBERS/UPLOAD_FILES", async ({ files, sessionId }, { rejectWithValue, dispatch }) => {
  try {
    const copyOfFiles = files.slice();

    const response = copyOfFiles.reduce<UploadFilesResponse>((accumulator, currentValue, index) => {
      if (currentValue.path) {
        accumulator.push({ index, path: currentValue.path });
      }
      return accumulator;
    }, []);

    function loopAndUploadFiles(files: SendFaxFiles): Promise<UploadFilesResponse[number]>[] {
      const promises: Promise<UploadFilesResponse[number]>[] = [];

      for (let currentIndex = 0; currentIndex < files.length; currentIndex++) {
        const file = files[currentIndex];
        const isPathPresent = Boolean(file.path);

        if (!isPathPresent) {
          const promise = new Promise<UploadFilesResponse[number]>(async (resolve, reject) => {
            try {
              const fileSource = file.type === "faxFile" ? file.file : file.blob;

              const { path } = await storageApi.uploadFaxDocument(
                fileSource,
                `${file.name}${currentIndex}`,
              );

              resolve({ index: currentIndex, path });
            } catch (error) {
              let cfRayId = null;

              if (error && typeof error === "object" && "cfRay" in error) {
                cfRayId = error.cfRay;
              }

              reject({ index: currentIndex, cfRayId, mime: file.mime });
            }
          });

          promises.push(promise);
        }
      }

      return promises;
    }

    const errors: Map<number, string | null> = new Map();

    function mapResultAndError(promiseResult: PromiseSettledResult<UploadFilesResponse[number]>[]) {
      return promiseResult.forEach((result) => {
        if (result.status === "rejected") {
          errors.set(result.reason.index, `${result.reason.cfRayId} - ${result.reason.mime}`);
        }

        if (result.status === "fulfilled") {
          // Remove the index from error set, if we have a result for it now
          errors.delete(result.value.index);

          response.push(result.value);
          // Save the path of each uploaded files in case we need to retry
          copyOfFiles[result.value.index].path = result.value.path;
        }
      });
    }

    mapResultAndError(await Promise.allSettled(loopAndUploadFiles(copyOfFiles)));

    if (errors.size) {
      const time = dayjs().format("LTS");

      // Start the sentry trace
      Sentry.addBreadcrumb({
        category: "fetch",
        level: "warning",
        message: `Failed to upload some files, retrying: ${RETRY_AMOUNT} times, with: ${RETRY_TIMEOUT} ms timeouts, at ${time}`,
      });

      let uploadedAllFiles = false;

      // We have at least one error, we retry RETRY_AMOUNT times;
      for (let retryCount = 0; retryCount < RETRY_AMOUNT; retryCount++) {
        const time = dayjs().format("LTS");

        Sentry.addBreadcrumb({
          category: "fetch",
          level: "warning",
          message: `Internal upload retry #${retryCount} at ${time}`,
        });

        // Update the Send Fax Button tooltip
        dispatch(setUploadFilesIsRetrying(true));

        mapResultAndError(await Promise.allSettled(loopAndUploadFiles(copyOfFiles)));

        // We uploaded all the files, stop the loop
        if (errors.size === 0) {
          uploadedAllFiles = true;
          break;
        }

        const errorMapToString = Array.from(errors).map(
          ([index, cfRayId]) => ` ${index} - ${cfRayId}`,
        );

        const responseMapToString = Array.from(response)
          .map(({ index }) => index)
          .sort()
          .join(", ");

        Sentry.addBreadcrumb({
          category: "fetch",
          level: "warning",
          message: `Errors: ${errorMapToString} | Uploaded: ${responseMapToString}`,
        });

        await sleep(RETRY_TIMEOUT);
      }

      dispatch(setUploadFilesIsRetrying(false));

      sentryCaptureMessage({
        message: `Something went wrong during send fax file upload`,
        level: "error",
        tags: {
          "Uploaded-All-Files": uploadedAllFiles,
        },
      });
    }

    return {
      response,
      sessionId,
      error: errors.size ? { reason: "failedToUpload" } : null,
    };
  } catch {
    return rejectWithValue({ reason: "failedToUpload", sessionId });
  }
});

export const sendFax = createAsyncThunk<
  { response: unknown },
  { sendFaxStore: SendFaxStore },
  { state: RootState; rejectValue: FetchError }
>("NUMBERS/SEND_FAX", async ({ sendFaxStore }, { rejectWithValue }) => {
  try {
    const tagIds: Set<string> = new Set();
    const destinations: Set<string> = new Set();
    const uploadedFiles = sendFaxStore.files
      .filter((file) => Boolean(file.path))
      .map((file) => file.path);

    sendFaxStore.destinations?.forEach((destination) => {
      if (destination.icon === "group" || destination.icon === "sharedGroup") {
        tagIds.add(destination.value);
      } else {
        // Manually typed numbers, recent numbers, contacts, sharedContacts
        destinations.add(destination.value);
      }
    });

    const sendTime =
      sendFaxStore.date !== null && sendFaxStore.timezone !== null
        ? sendFaxTimezone(sendFaxStore.date, sendFaxStore.timezone)
        : `now ${dayjs().format("ZZ")}`;

    const sendFaxRequestObject = {
      sendTime,
      files: uploadedFiles,
      from: sendFaxStore.from,
      tagIds: Array.from(tagIds),
      to: Array.from(destinations),
      comment: sendFaxStore.comment,
      initiatedFrom: sendFaxStore.initiatedFrom,
      defaultCoverSheet: sendFaxStore.defaultCoverSheet,
      customCoverSheetId:
        sendFaxStore.enforcedCoverSheet?.id ||
        sendFaxStore.sharedCoverSheet?.id ||
        sendFaxStore.galleryCoverSheet?.id ||
        null,
    };

    const response = await numbersApi.sendFax(sendFaxRequestObject);
    await clearFaxesDatabase();

    return response;
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const getAllNumbers = createAsyncThunk<
  unknown,
  undefined,
  { rejectValue: FetchError["origin"] }
>("NUMBERS/GET_ALL_NUMBERS", async (_, { rejectWithValue }) => {
  try {
    return await numbersApi.getAll();
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const getAssignedNumbers = createAsyncThunk<
  { response: unknown },
  undefined,
  { rejectValue: FetchError["origin"] }
>("NUMBERS/GET_ASSIGNED_NUMBERS", async (_, { rejectWithValue }) => {
  try {
    const response = await numbersApi.getAssignedNumbers();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const patchNumber = createAsyncThunk<
  unknown,
  { numberToAssign: string; memberIds: string[] },
  { rejectValue: FetchError["origin"] }
>("NUMBERS/PATCH_NUMBER", async (numberData, { rejectWithValue }) => {
  try {
    const response = await numbersApi.patch(numberData);
    return response;
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const resumeNumber = createAsyncThunk<
  unknown,
  { planId: string; number: string },
  { rejectValue: FetchError["origin"] }
>("NUMBERS/RESUME_NUMBER", async ({ planId, number }, { rejectWithValue }) => {
  try {
    const response = await numbersApi.resumeNumber({ planId, number });
    return response;
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const cancelNumber = createAsyncThunk<
  unknown,
  { planId: string; number: string },
  { rejectValue: FetchError["origin"] }
>("NUMBERS/CANCEL_NUMBER", async ({ planId, number }, { rejectWithValue }) => {
  try {
    const response = await numbersApi.cancelNumber({ planId, number });
    return response;
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const updateFaxReception = createAsyncThunk<
  { response: unknown },
  { numberToUpdate: string; toEnable: boolean; notifyWhenDisabled: boolean | undefined },
  { rejectValue: FetchError["origin"] }
>(
  "NUMBERS/UPDATE_FAX_RECEPTION",
  async ({ notifyWhenDisabled, numberToUpdate, toEnable }, { rejectWithValue }) => {
    try {
      const response = await numbersApi.updateFaxReception({
        numberToUpdate,
        toEnable,
        notifyWhenDisabled,
      });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const updateFriendlyName = createAsyncThunk<
  { response: unknown },
  { number: string; friendlyName: string },
  { rejectValue: FetchError["origin"] }
>("NUMBERS/UPDATE_FRIENDLY_NAME", async ({ number, friendlyName }, { rejectWithValue }) => {
  try {
    const response = await numbersApi.updateFriendlyName({ number, friendlyName });
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const getOutboxSettings = createAsyncThunk<
  { response: { telefax: unknown }; number: string },
  string
>("NUMBERS/GET_OUTBOX_SETTINGS", async (number, { rejectWithValue }) => {
  try {
    const response = (await numbersApi.getOutboxSettings(number)) as { telefax: unknown };
    return { response, number };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const getCdrData = createAsyncThunk<unknown, string, { rejectValue: FetchError["origin"] }>(
  "NUMBERS/GET_CDR_DATA",
  async (cdrId, { rejectWithValue, getState }) => {
    try {
      const defaultFaxFileType = selectAccountDefaultFaxFileType(getState());
      const fileResponse = await storageApi.download({
        cdrId,
        idType: "cdr",
        file_type: defaultFaxFileType,
      });

      const {
        result: [result],
      } = await historyApi.getByIds({ cdrIds: [cdrId] });

      const mime = defaultFaxFileType === "pdf" ? "application/pdf" : "image/tiff";
      const fileName = result?.track[0]?.data?.file_name ?? `${cdrId}.${defaultFaxFileType}`;

      return {
        mime,
        to: result.to,
        path: result.track[0].data.file,
        blobString: URL.createObjectURL(fileResponse),
        fileName: `${fileName}.${defaultFaxFileType}`,
      };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const getEnvelopeMetadata = createAsyncThunk<
  { response: unknown },
  { token: string },
  { rejectValue: FetchError["origin"] }
>("NUMBERS/GET_ENVELOPE_METADATA", async ({ token }, { rejectWithValue }) => {
  try {
    const verifyResponse = await storageApi.getEnvelopeMetadata({ token, type: "verify", url: "" });

    const fileName = verifyResponse?.name;
    const mimeType = verifyResponse?.mime_type;
    const downloadUrl = verifyResponse?.download_url;

    const file = await storageApi.getEnvelopeMetadata({
      token: "",
      type: "download",
      url: downloadUrl,
    });

    return { response: { fileObjectUrl: URL.createObjectURL(file), fileName, mimeType } };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const { reducer } = createSlice({
  name: "NUMBERS",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(clearSendFax, (state) => {
      state.sendFax.response = null;
      state.sendFax.status = RS.IDLE;
      state.sendFax.error = null;
    });
    builder.addCase(sendFax.pending, (state) => {
      state.sendFax.response = null;
      state.sendFax.status = RS.RUNNING;
      state.sendFax.error = null;
    });
    builder.addCase(sendFax.fulfilled, (state, action) => {
      state.sendFax.response = action.payload;
      state.sendFax.status = RS.IDLE;
      state.sendFax.error = null;
    });
    builder.addCase(sendFax.rejected, (state, action) => {
      state.sendFax.response = null;
      state.sendFax.status = RS.ERROR;
      state.sendFax.error = action.payload;
    });
    builder.addCase(getAllNumbers.pending, (state) => {
      state.accountNumbers.status = RS.RUNNING;
      state.accountNumbers.error = null;
    });
    builder.addCase(getAllNumbers.fulfilled, (state, action) => {
      state.accountNumbers.response = action.payload;
      state.accountNumbers.status = RS.IDLE;
      state.accountNumbers.error = null;
    });
    builder.addCase(getAllNumbers.rejected, (state, action) => {
      state.accountNumbers.response = null;
      state.accountNumbers.status = RS.ERROR;
      state.accountNumbers.error = action.payload;
    });
    builder.addCase(getAssignedNumbers.pending, (state) => {
      state.getAssignedNumbers.status = RS.RUNNING;
      state.getAssignedNumbers.error = null;
    });
    builder.addCase(getAssignedNumbers.fulfilled, (state, action) => {
      state.getAssignedNumbers.response = action.payload.response;
      state.getAssignedNumbers.status = RS.IDLE;
      state.getAssignedNumbers.error = null;
    });
    builder.addCase(getAssignedNumbers.rejected, (state, action) => {
      state.getAssignedNumbers.response = null;
      state.getAssignedNumbers.status = RS.ERROR;
      state.getAssignedNumbers.error = action.payload;
    });
    builder.addCase(clearPatchNumber, (state) => {
      state.patchNumber.response = null;
      state.patchNumber.status = RS.IDLE;
      state.patchNumber.error = null;
    });
    builder.addCase(patchNumber.pending, (state) => {
      state.patchNumber.status = RS.RUNNING;
      state.patchNumber.error = null;
    });
    builder.addCase(patchNumber.fulfilled, (state, action) => {
      state.patchNumber.response = action.payload;
      state.patchNumber.status = RS.IDLE;
      state.patchNumber.error = null;
    });
    builder.addCase(patchNumber.rejected, (state, action) => {
      state.patchNumber.response = null;
      state.patchNumber.status = RS.ERROR;
      state.patchNumber.error = action.payload;
    });
    builder.addCase(resumeNumber.pending, (state) => {
      state.resumeNumber.response = null;
      state.resumeNumber.status = RS.RUNNING;
      state.resumeNumber.error = null;
    });
    builder.addCase(resumeNumber.fulfilled, (state, action) => {
      state.resumeNumber.response = action.payload;
      state.resumeNumber.status = RS.IDLE;
      state.resumeNumber.error = null;
    });
    builder.addCase(resumeNumber.rejected, (state, action) => {
      state.resumeNumber.response = null;
      state.resumeNumber.status = RS.ERROR;
      state.resumeNumber.error = action.payload;
    });
    builder.addCase(clearResumeNumber, (state) => {
      state.resumeNumber.response = null;
      state.resumeNumber.status = RS.IDLE;
      state.resumeNumber.error = null;
    });
    builder.addCase(cancelNumber.pending, (state) => {
      state.cancelNumber.response = null;
      state.cancelNumber.status = RS.RUNNING;
      state.cancelNumber.error = null;
    });
    builder.addCase(cancelNumber.fulfilled, (state, action) => {
      state.cancelNumber.response = action.payload;
      state.cancelNumber.status = RS.IDLE;
      state.cancelNumber.error = null;
    });
    builder.addCase(cancelNumber.rejected, (state, action) => {
      state.cancelNumber.response = null;
      state.cancelNumber.status = RS.ERROR;
      state.cancelNumber.error = action.payload;
    });
    builder.addCase(clearCancelNumber, (state) => {
      state.cancelNumber.response = null;
      state.cancelNumber.status = RS.IDLE;
      state.cancelNumber.error = null;
    });
    builder.addCase(getOutboxSettings.pending, (state) => {
      state.outboxSettings.status = RS.RUNNING;
      state.outboxSettings.error = null;
    });
    builder.addCase(getOutboxSettings.fulfilled, (state, action) => {
      state.outboxSettings.response = {
        ...(state.outboxSettings.response as Record<string, unknown>),
        [action.payload.number]: action.payload.response,
      };
      state.outboxSettings.status = RS.IDLE;
      state.outboxSettings.error = null;
    });
    builder.addCase(getOutboxSettings.rejected, (state, action) => {
      state.outboxSettings.status = RS.ERROR;
      state.outboxSettings.error = action.payload;
    });
    builder.addCase(clearGetCdrData, (state) => {
      state.cdrData = initialRequestState;
    });
    builder.addCase(getCdrData.pending, (state) => {
      state.cdrData.response = null;
      state.cdrData.status = RS.RUNNING;
      state.cdrData.error = null;
    });
    builder.addCase(getCdrData.fulfilled, (state, action) => {
      state.cdrData.response = action.payload;
      state.cdrData.status = RS.IDLE;
      state.cdrData.error = null;
    });
    builder.addCase(getCdrData.rejected, (state, action) => {
      state.cdrData.response = null;
      state.cdrData.status = RS.ERROR;
      state.cdrData.error = action.payload;
    });
    builder.addCase(clearGetEnvelopeMetadata, (state) => {
      state.envelopeMetadata = initialRequestState;
    });
    builder.addCase(getEnvelopeMetadata.pending, (state) => {
      state.envelopeMetadata.response = null;
      state.envelopeMetadata.status = RS.RUNNING;
      state.envelopeMetadata.error = null;
    });
    builder.addCase(getEnvelopeMetadata.fulfilled, (state, action) => {
      state.envelopeMetadata.response = action.payload.response;
      state.envelopeMetadata.status = RS.IDLE;
      state.envelopeMetadata.error = null;
    });
    builder.addCase(getEnvelopeMetadata.rejected, (state, action) => {
      state.envelopeMetadata.response = null;
      state.envelopeMetadata.status = RS.ERROR;
      state.envelopeMetadata.error = action.payload;
    });
    builder.addCase(updateFriendlyName.pending, (state) => {
      state.updateFriendlyName.status = RS.RUNNING;
      state.updateFriendlyName.error = null;
    });
    builder.addCase(updateFriendlyName.fulfilled, (state, { payload }) => {
      state.updateFriendlyName.status = RS.IDLE;
      state.updateFriendlyName.response = payload.response;
    });
    builder.addCase(updateFriendlyName.rejected, (state, { payload }) => {
      state.updateFriendlyName.status = RS.ERROR;
      state.updateFriendlyName.error = payload;
    });
    builder.addCase(clearUpdateFriendlyName, (state) => {
      state.updateFriendlyName = initialRequestState;
    });
    builder.addCase(updateFaxReception.pending, (state) => {
      state.updateFaxReception.status = RS.RUNNING;
      state.updateFaxReception.error = null;
    });
    builder.addCase(updateFaxReception.fulfilled, (state, { payload }) => {
      state.updateFaxReception.status = RS.IDLE;
      state.updateFaxReception.response = payload.response;
    });
    builder.addCase(updateFaxReception.rejected, (state, { payload }) => {
      state.updateFaxReception.status = RS.ERROR;
      state.updateFaxReception.error = payload;
    });
    builder.addCase(clearUpdateFaxReception, (state) => {
      state.updateFaxReception = initialRequestState;
    });
    builder.addCase(uploadFiles.pending, (state) => {
      state.uploadFiles.status = RS.RUNNING;
      state.uploadFiles.error = null;
    });
    builder.addCase(uploadFiles.fulfilled, (state, { payload }) => {
      state.uploadFiles.status = RS.IDLE;
      state.uploadFiles.error = payload.error;
      state.uploadFiles.response = payload.response;
      state.uploadFiles.sessionId = payload.sessionId;
    });
    builder.addCase(uploadFiles.rejected, (state, { payload }) => {
      state.uploadFiles.status = RS.ERROR;
      state.uploadFiles.error = payload;
      state.uploadFiles.sessionId = payload?.sessionId ?? "";
    });
    builder.addCase(setUploadFilesIsRetrying, (state, { payload }) => {
      state.uploadFiles.isRetrying = payload;
    });
    builder.addCase(clearUploadFiles, (state) => {
      state.uploadFiles = { ...initialRequestState, isRetrying: false, sessionId: "" };
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export default reducer;
