import { fetchWithRefresh } from "helpers";
import { API_AFFOGATO } from "config";
import { transformNotificationsValue } from "../stores/transforms/toServer";

const getSlackChannels = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/3rd_party/slack/channels`;
  return fetchWithRefresh(path, requestOptions);
};

const getSlackToken = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/3rd_party/slack/token`;
  return fetchWithRefresh(path, requestOptions);
};

const deleteSlackToken = () => {
  const requestOptions = {
    method: "DELETE",
  };
  const path = `${API_AFFOGATO}/3rd_party/slack/token`;
  return fetchWithRefresh(path, requestOptions);
};

const postSlackToken = ({ code, redirectUri }) => {
  const requestOptions = {
    method: "POST",
    body: { code, redirect_uri: redirectUri },
  };
  const path = `${API_AFFOGATO}/3rd_party/slack/token`;
  return fetchWithRefresh(path, requestOptions);
};

const getSlackTest = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/3rd_party/slack/test`;
  return fetchWithRefresh(path, requestOptions);
};

const updateSlackSettings = ({ targetChannel, sentFaxNotifications, receivedFaxNotifications }) => {
  const body = {
    target_channel: targetChannel,
    send_fax: transformNotificationsValue(sentFaxNotifications),
    receive_fax: transformNotificationsValue(receivedFaxNotifications),
  };

  const requestOptions = {
    method: "PATCH",
    body,
  };

  const path = `${API_AFFOGATO}/3rd_party/slack/settings`;
  return fetchWithRefresh(path, requestOptions);
};

export const thirdPartyApi = {
  getSlackChannels,
  getSlackToken,
  getSlackTest,
  updateSlackSettings,
  postSlackToken,
  deleteSlackToken,
};
