import createSelector from "selectorator";
import { accountStatuses, accountTypes } from "../enums/account";
import RS from "../enums/requestStatus";

import i18n from "../i18n/index";

function shapeUserTreeNode({
  user_data,
  account_status,
  account_type,
  email,
  members,
  name,
  numbers,
  profile_image,
  uid,
}) {
  return {
    accountStatus: account_status,
    accountType: account_type,
    email,
    name,
    numbers,
    profileImage: profile_image,
    uid,
    role: user_data?.role,

    expanded: true,
    children: Object.values(members).map((child) => child && shapeUserTreeNode(child)),
  };
}

const shapeFlattenedUsers = ({
  user_data,
  account_data,
  account_status,
  account_type,
  email,
  members,
  name,
  lastname,
  numbers,
  profile_image,
  uid,
}) => {
  const arr = [
    {
      uid,
      name,
      email,
      lastname,
      profileImage: profile_image,
      numbers,
      accountStatus: account_status,
      accountType: account_type,
      members,
      role: user_data?.role || account_data?.role,
    },
  ];
  if (members) {
    arr.push(
      ...Object.values(members).reduce((acc, item) => {
        acc.push(...shapeFlattenedUsers(item));
        return acc;
      }, []),
    );
  }
  return arr;
};

export const selectUsersHierarchy = createSelector(["corporate.getHierarchy.response"], (users) => {
  if (!users) return null;
  return Object.values(users).reduce((acc, item) => {
    // Always keep the owner on top, keep the rest of the backend order
    if (item.account_type === accountTypes.corporateOwner) acc.unshift(item);
    else acc.push(item);
    return acc;
  }, []);
});

export const selectUsersHierarchyTree = createSelector([selectUsersHierarchy], (users) => {
  if (!users) return null;
  return users.map((user) => shapeUserTreeNode(user));
});

// V1
export const selectFlattenedUsersHierarchy = createSelector([selectUsersHierarchy], (users) =>
  users
    ? Object.values(users).reduce((acc, item) => {
        acc.push(...shapeFlattenedUsers(item));
        return acc;
      }, [])
    : null,
);

// V1
export const selectActiveCorporateUsers = createSelector(
  [selectFlattenedUsersHierarchy],
  (flattenedMembers) =>
    !!flattenedMembers
      ? flattenedMembers.filter((item) => item.accountStatus === accountStatuses.active)
      : null,
);

// V1
export const selectCuratedCorporateUsers = createSelector(
  ["corporate.getUsersRequest.response"],
  (users) => {
    if (!users) return null;
    return Object.values(users)
      .reduce((acc, item) => {
        acc.push(...shapeFlattenedUsers(item));
        return acc;
      }, [])
      .filter(
        (item) =>
          item.accountStatus !== accountStatuses.waitingForSignup &&
          item.accountType !== accountTypes.corporateAdmin,
      );
  },
);

// V1
export const selectUserByUid = (userUid) =>
  createSelector([selectFlattenedUsersHierarchy], (members) =>
    members ? members.find((member) => member.uid === userUid) : null,
  );

export const selectInviteByEmailsRequestIsRunning = createSelector(
  ["corporate.inviteByEmailsRequest.status"],
  (status) => status === RS.RUNNING,
);

export const selectInviteByEmailsRequestSuccessEmails = createSelector(
  ["corporate.inviteByEmailsRequest.response"],
  (response) => response?.map((user) => user.email),
);

export const selectInviteByEmailsRequestErrorMessage = createSelector(
  ["corporate.inviteByEmailsRequest.response", "corporate.inviteByEmailsRequest.error"],
  (response, error) => {
    if (!!error) {
      switch (error.reason) {
        case "members_limit_reached":
          return i18n.t("USERS.REACHED_MAXIMUM_NUMBER");
        case "cannot_invite_users_with_sso":
          return i18n.t("SSO.CANNOT_INVITE_USERS_WITH_SSO");
        case "already_registered":
          return i18n.t("FORMS.EMAIL_ALREADY_HAVE_ACCOUNT", {
            count: error.details?.length,
            mails: error.details?.join(", "),
          });
        case "similar_email_already_used":
          return i18n.t("USERS.SIMILAR_EMAIL_ALREADY_USED", {
            count: error.details?.length,
            mails: error.details?.join(", "),
          });
        default:
          return i18n.t("COMMON.SERVER_ERROR");
      }
    }

    // if (!!response) {
    //   const duplicateEmails = [];
    //   const similarEmails = []; // e.g john.doe+marketing@gmail.com
    //
    //   for (const email in response) {
    //     if (response[email].code === 400 && response[email].data.reason === "already_registered") {
    //       duplicateEmails.push(email);
    //     }
    //
    //     if (
    //       response[email].code === 400 &&
    //       response[email].data.reason === "similar_email_already_used"
    //     ) {
    //       similarEmails.push(email);
    //     }
    //   }
    //
    //   if (duplicateEmails.length > 0)
    //     return i18n.t("FORMS.EMAIL_ALREADY_HAVE_ACCOUNT", {
    //       count: duplicateEmails.length,
    //       mails: duplicateEmails.join(", "),
    //     });
    //
    //   if (similarEmails.length > 0)
    //     return i18n.t("FORMS.SIMILAR_EMAIL_ALREADY_USED", {
    //       count: similarEmails.length,
    //       mails: similarEmails.join(", "),
    //     });
    // }

    return null;
  },
);

export const selectResendInvitationByEmailRequestSuccess = createSelector(
  ["corporate.resendInvitationByEmailRequest.response"],
  (response) => !!response,
);

export const selectResendInvitationByEmailRequestError = createSelector(
  ["corporate.resendInvitationByEmailRequest.error"],
  (error) => !!error,
);

export const selectUpdateUserRoleRequestSuccess = createSelector(
  ["corporate.updateUserRoleRequest.response", "corporate.updateUserRoleRequest.error"],
  (response, error) => {
    if (!!error) {
      return false;
    } else return response?.result?.[0]?.status === "success";
  },
);

export const selectUpdateUserRoleRequestError = createSelector(
  ["corporate.updateUserRoleRequest.error"],
  (error) => !!error,
);

export const selectUpdateUserQuotaRequestSuccess = createSelector(
  ["corporate.updateUserQuotaRequest.response", "corporate.updateUserQuotaRequest.error"],
  (response, error) => {
    if (!!error) {
      return false;
    } else return response?.result?.[0]?.status === "success";
  },
);

export const selectUpdateUserQuotaRequestError = createSelector(
  ["corporate.updateUserQuotaRequest.response", "corporate.updateUserQuotaRequest.error"],
  (response, error) => {
    if (!!error) {
      return true;
    } else return response?.result?.[0]?.status === "failed";
  },
);

export const selectUserQuotaByUid = (userUid) =>
  createSelector(["corporate.getUserQuotaRequest.response"], (response) =>
    response?.data?.uid === userUid ? response.data?.quota : null,
  );

export const selectDisableUserRequestSuccess = createSelector(
  ["corporate.disableUserRequest.response"],
  (response) => !!response,
);

export const selectDisableUserRequestError = createSelector(
  ["corporate.disableUserRequest.error"],
  (error) => !!error,
);

export const selectDeleteUserRequestSuccess = createSelector(
  ["corporate.deleteUserRequest.response"],
  (response) => !!response,
);

export const selectDeleteUserRequestError = createSelector(
  ["corporate.deleteUserRequest.error"],
  (error) => !!error,
);

export const selectIsDeleteUserRequestLoading = createSelector(
  ["corporate.deleteUserRequest.status"],
  (status) => status === RS.RUNNING,
);

export const selectUpdateHierarchyRequestSuccess = createSelector(
  ["corporate.updateHierarchyRequest.response"],
  (response) => !!response,
);

export const selectUpdateHierarchyRequestError = createSelector(
  ["corporate.updateHierarchyRequest.error"],
  (error) => !!error,
);

export const selectGetLimitsRequestSuccess = createSelector(
  ["corporate.getLimitsRequest.response"],
  (response) => !!response,
);

// Here, null value can mean no limit (enterprise)
export const selectRemainingNumberOfUsers = createSelector(
  ["corporate.getLimitsRequest.response"],
  (response) => response?.members?.remained ?? null,
);

export const selectAdminInfoPlanType = createSelector(
  ["corporate.getAdminInfoRequest.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.plan_type;
  },
);

export const selectIsUpdateCompanyInfoError = createSelector(
  ["corporate.updateCompanyInfo.status"],
  (status) => status === RS.ERROR,
);

export const selectIsUpdateCompanyInfoSuccessful = createSelector(
  ["corporate.updateCompanyInfo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsUpdateCompanyDefaultNumberError = createSelector(
  ["corporate.updateDefaultNumber.status"],
  (status) => status === RS.ERROR,
);

export const selectIsUpdateCompanyDefaultNumberSuccessful = createSelector(
  ["corporate.updateDefaultNumber.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsUpdateCompanyRunning = createSelector(
  ["corporate.updateCompanyInfo.status", "corporate.updateDefaultNumber.status"],
  (updateInfoStatus, updateNumberStatus) =>
    updateInfoStatus === RS.RUNNING || updateNumberStatus === RS.RUNNING,
);

export const selectIsAssignAndRevokeUserNumbersSuccess = createSelector(
  ["corporate.assignAndRevokeUserNumbersRequest.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !response.result?.some?.((result) => result.status !== "success");
  },
);

export const selectAssignAndRevokeUserNumbersError = createSelector(
  [
    "corporate.assignAndRevokeUserNumbersRequest.response",
    "corporate.assignAndRevokeUserNumbersRequest.error",
  ],
  (response, error) => {
    if (!!error) {
      switch (error.reason) {
        default:
          return i18n.t("COMMON.SERVER_ERROR");
      }
    }

    if (!!response) {
      if (response.result?.some?.((result) => result.reason === "number_non_assignment")) {
        return i18n.t("NUMBERS.NUMBER_SHOULD_BE_ASSIGNED_TO_AT_LEAST_ONE_USER");
      }
    }

    return null;
  },
);

export const selectIsAssignAndRevokeUserNumbersRunning = (state) =>
  state.corporate.assignAndRevokeUserNumbersRequest.status === RS.RUNNING;

export const selectIsEnableUserSuccess = (state) => !!state.corporate.enableUserRequest.response;

export const selectIsUsagePeriodsRunning = createSelector(
  ["corporate.usagePeriods.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUsagePeriodsError = createSelector(
  ["corporate.usagePeriods.status"],
  (status) => status === RS.ERROR,
);

export const selectUsagePeriods = createSelector(
  ["corporate.usagePeriods.response"],
  (response) => {
    if (!response) {
      return null;
    }

    return response;
  },
);

export const selectDashbordSelectedPeriod = createSelector(
  ["corporate.pageConsumption.selectedPeriod"],
  (period) => period,
);

export const selectCompanySelectedPeriod = createSelector(
  ["corporate.pageConsumption.response", "corporate.pageConsumption.selectedPeriod"],
  (response, selectedPeriod) => {
    if (!response?.company?.[selectedPeriod]) {
      return null;
    }
    return response?.company?.[selectedPeriod];
  },
);

export const selectUsersSelectedPeriod = createSelector(
  ["corporate.pageConsumption.response", "corporate.pageConsumption.selectedPeriod"],
  (response, selectedPeriod) => {
    if (!response?.users?.[selectedPeriod]) {
      return null;
    }
    return response?.users?.[selectedPeriod];
  },
);

export const selectListCorporateMembersFromSign = createSelector(
  ["corporate.listCorporateMembers.response"],
  (response) => {
    if (!response?.["SIGN_PLUS"]?.data) {
      return null;
    }

    return response["SIGN_PLUS"].data;
  },
);

export const selectListCorporateMembersFromFax = createSelector(
  ["corporate.listCorporateMembers.response"],
  (response) => {
    if (!response?.["FAX_PLUS"]?.data) {
      return null;
    }

    return response["FAX_PLUS"].data;
  },
);

export const selectListCorporateMembersIsRunning = createSelector(
  ["corporate.listCorporateMembers.status"],
  (status) => status === RS.RUNNING,
);
