import { Button, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import ChargeError from "views/Transactions/ChargeError";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import ChangeNumberModal from "views/ChangeNumber/ChangeNumberModal";
import PurchasedNumbersSuccess from "views/Transactions/PurchasedNumbersSuccess";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import { getAllNumbers, getAssignedNumbers } from "stores/reducers/numbers.reducer";
import { getAccountDetails, getCustomerInfo } from "stores/reducers/account.reducer";
import { selectChargeAmount, selectIsChargeAmountError } from "selectors/payment.selector";
import useAdminRedirections from "hooks/useAdminRedirections";
import { alohiAdminUrls } from "routes/urls";

function ChangeNumber({ number }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cleanReducers = useCleanPlansReducers();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const isChargeSuccess = useSelector(selectChargeAmount);
  const isChargeError = useSelector(selectIsChargeAmountError);

  const [openChargeError, setOpenChargeError] = useState(false);
  const [openChargeSuccess, setOpenChargeSuccess] = useState(false);
  const [openChangeNumber, setOpenChangeNumber] = useState(false);

  const openModal = useCallback(() => {
    cleanReducers();
    setOpenChangeNumber(true);
  }, [cleanReducers]);

  const handleClosure = useCallback(() => {
    cleanReducers(); // Clean all the information about the cart or numbers
    setOpenChangeNumber(false); // Close everything, cartId can't be used for another charge.
    setOpenChargeError(false);
  }, [cleanReducers]);

  const handleOpenChangeNumber = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.changeNumber,
      fallbackAction: openModal,
      adminParams: {
        number,
      },
    });
  }, [number, openModal, redirectToAdmin]);

  useEffect(() => {
    if (isChargeSuccess) {
      setOpenChangeNumber(false);
      setOpenChargeSuccess(true);
      dispatch(getCurrentCredit());
      dispatch(getAllNumbers());
      dispatch(getAssignedNumbers());
      dispatch(getAccountDetails());
      dispatch(getCustomerInfo());
      dispatch(getCreditDetails());
      cleanReducers();
    } else if (isChargeError) {
      setOpenChangeNumber(false);
      setOpenChargeError(true);
    }
  }, [dispatch, isChargeError, isChargeSuccess, cleanReducers]);

  return (
    <Box>
      <Button variant="white" onClick={handleOpenChangeNumber}>
        {t("PROFILE.CHANGE_NUMBER")}
      </Button>

      {openChangeNumber ? <ChangeNumberModal handleClosure={handleClosure} /> : null}

      {openChargeSuccess ? (
        <PurchasedNumbersSuccess
          title={t("COMMON.CHANGE_NUMBER")}
          description={t("CHANGE_PLAN.YOUR_NUMBER_SETUP")}
          handleClosure={() => setOpenChargeSuccess(false)}
        />
      ) : null}

      {openChargeError ? (
        <ChargeError
          handleClosure={(shouldCloseModal) => {
            if (shouldCloseModal) {
              openModal();
              setOpenChargeError(false);
            } else {
              handleClosure();
            }
          }}
        />
      ) : null}
    </Box>
  );
}

ChangeNumber.propTypes = {
  number: PropTypes.string,
};

export default ChangeNumber;
