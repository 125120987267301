import PropTypes from "prop-types";
import { Box, Typography } from "@alohi/kit";

import EmptyFaxesSvg from "../../assets/images/empty-faxes.svg?react";

function EmptyFaxes({ text }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <EmptyFaxesSvg width={100} />
      <Typography type="body">{text}</Typography>
    </Box>
  );
}

EmptyFaxes.propTypes = {
  text: PropTypes.string,
};

export default EmptyFaxes;
