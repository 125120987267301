import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Modal } from "@alohi/kit";
import { Trans, useTranslation } from "react-i18next";

import { DigitFilter } from "components/Core/Filters";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import { selectCreditRemainingPages } from "selectors/credit.selector";
import RemainingPagesDetails from "components/RemainingPages/RemainingPagesDetails";

interface RemainingPagesModalProps {
  handleClosure: () => void;
}

function RemainingPagesModal({ handleClosure }: RemainingPagesModalProps) {
  const { t } = useTranslation();

  const remainingPages = useSelector(selectCreditRemainingPages);

  const [showAddCredit, setShowAddCredit] = useState(false);

  return (
    <>
      <Modal
        maxWidth="xxs"
        onCancel={() => handleClosure()}
        cancelTitle={t("COMMON.CANCEL")}
        confirmTitle={t("COMMON.ADD_CREDIT")}
        onConfirm={() => setShowAddCredit(true)}
        title={
          <Typography type="modalTitle">
            <Trans
              i18nKey="PROFILE.REMAINING_PAGES_UNITS"
              components={{
                unit: (
                  <DigitFilter
                    value={remainingPages}
                    renderText={(value) => <Typography type="modalTitle">{value}</Typography>}
                  />
                ),
              }}
            />
          </Typography>
        }
      >
        <Box p={3}>
          <RemainingPagesDetails />
        </Box>
      </Modal>

      {showAddCredit ? (
        <AddCreditModal
          withSuccessModal
          reason="add_credit"
          handleClosure={() => {
            setShowAddCredit(false);
            handleClosure();
          }}
        />
      ) : null}
    </>
  );
}

export default RemainingPagesModal;
