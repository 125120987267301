import { useTranslation } from "react-i18next";

interface SentStatusFilterProps {
  value: string;
}

function SentStatusFilter({ value }: SentStatusFilterProps) {
  const { t } = useTranslation();

  switch (value) {
    case "success":
      return t("FAXES.SUCCESSFULLY");
    case "failed_fs_2":
    case "failed_fs_3":
    case "failed_fs_49_no_fax_detected":
    case "failed_fs_49_no_fax_detected_no_txfax":
      return t("COMMON.FAILED") + " (" + t("FAXES.NO_FAX_DETECTED") + ")";
    case "failed_fs_5":
    case "failed_fs_6":
    case "failed_fs_8":
    case "failed_fs_9":
    case "failed_fs_11":
    case "failed_fs_13":
    case "failed_fs_14":
    case "failed_fs_15":
    case "failed_fs_16":
    case "failed_fs_17":
    case "failed_fs_18":
    case "failed_fs_19":
    case "failed_fs_20":
    case "failed_fs_21":
    case "failed_fs_22":
    case "failed_fs_23":
    case "failed_fs_31":
    case "failed_fs_32":
    case "failed_fs_35":
    case "failed_fs_38":
    case "failed_fs_39":
    case "failed_no_fax_transmitted":
    case "failed_zero_page_transmitted":
      return t("COMMON.FAILED") + " (" + t("FAXES.FAX_NEGOTIATION_FAILED") + ")";
    case "failed_no_answer":
      return t("COMMON.FAILED") + " (" + t("FAXES.NO_ANSWER") + ")";
    case "failed_fs_48":
    case "failed_fs_49":
    case "failed_fs_49_allotted_timeout":
    case "failed_normal_temporary_failure":
    case "failed_exchange_routing_error":
    case "failed_recovery_on_timer_expire":
    case "recovery_on_timer_expire":
    case "failed_destination_out_of_order":
    case "failed_normal_circuit_congestion":
      return t("COMMON.FAILED") + " (" + t("FAXES.COMMUNICATION_ERROR") + ")";
    case "partially_sent":
    case "partially_sent_allotted_timeout":
      return t("COMMON.FAILED") + " (" + t("FAXES.FAX_PARTIALLY_SENT") + ")";
    case "failed_user_busy":
    case "failed_normal_clearing":
      return t("COMMON.FAILED") + " (" + t("FAXES.NUMBER_BUSY") + ")";
    case "failed_unallocated_number":
    case "failed_invalid_number_format":
    case "failed_no_route_destination":
      return t("COMMON.FAILED") + " (" + t("FAXES.INVALID_DESTINATION_NUMBER") + ")";
    case "failed_call_rejected":
      return t("COMMON.FAILED") + " (" + t("FAXES.NUMBER_BLOCKED") + ")";
    case "failed_destination_not_supported":
      return t("COMMON.FAILED") + " (" + t("FAXES.DESTINATION_NOT_SUPPORTED") + ")";

    case "failed_os_error":
    case "failed_file_not_found":
    case "failed_requested_chan_unavail":
    case "failed_network_out_of_order":
    case "failed_render_header_issue":
    case "failed_internal_process_error":
      return t("COMMON.FAILED") + " (" + t("FAXES.TEMPORARY_SYSTEM_ISSUE") + ")";
    case "failed_mimetype_not_supported":
      return t("COMMON.FAILED") + " (" + t("FAXES.UN_SUPPORT_FILE_TYPE") + ")";
    case "failed_office_converter_issue":
    case "failed_jpeg_converter_issue":
    case "failed_png_converter_issue":
    case "failed_tiff_converter_issue":
    case "failed_html_converter_issue":
    case "failed_unknown_converter_issue":
    case "failed_merge_pdf_issue":
    case "failed_count_pdf_page_issue":
    case "failed_convert_pdf_to_tiff_issue":
    case "failed_convert_to_tiff_issue":
    case "failed_image_preparation":
    case "failed_separate_file_pages_issue":
      return t("COMMON.FAILED") + " (" + t("FAXES.FILE_CONVERSION_ISSUE") + ")";
    case "failed_enhancer_issue":
      return t("COMMON.FAILED") + " (" + t("FAXES.DOCUMENT_OPTIMIZATION_ISSUE") + ")";
    case "failed_pdf_page_count_limit_exceeded":
      return t("COMMON.FAILED") + " (" + t("FAXES.FAILED_PDF_PAGE_COUNT_LIMIT_EXCEEDED") + ")";
    case "insufficient_credit":
      return t("COMMON.FAILED") + " (" + t("FAXES.INSUFFICIENT_CREDIT") + ")";
    case "user_canceled_failed_fs_13":
    case "user_canceled_failed_fs_49":
    case "user_canceled_failed_fs_49_no_fax_detected":
    case "user_canceled_failed_fs_49_no_fax_detected_no_txfax":
      return t("FAXES.CANCELED") + " (" + t("FAXES.FAX_NOT_SENT") + ")";
    case "user_canceled_partially_sent":
      return t("FAXES.CANCELED") + " (" + t("FAXES.FAX_PARTIALLY_SENT") + ")";
    case "failed_page_limit_exceeded":
      return t("COMMON.FAILED") + " (" + t("FAXES.TOO_MANY_PAGES") + ")";

    default:
      return t("COMMON.FAILED") + " (" + t("FAXES.FAX_NOT_SENT") + ")";
  }
}

export default SentStatusFilter;
