import { notificationsValues } from "../../enums/notifications";

export const transformNotificationsValue = (notificationsValue) => {
  switch (notificationsValue) {
    case notificationsValues.noAttachment:
      return "no_attachment";
    case notificationsValues.withAttachment:
      return "with_attachment";
    case notificationsValues.off:
    default:
      return "off";
  }
};
