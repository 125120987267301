import introJs from "intro.js";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, FaButton, TextButton, Typography, Button, Box } from "@alohi/kit";
import { faCog, faClock, faInfoCircle, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

import "intro.js/introjs.css";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import From from "views/SendFax/components/From";
import { DigitFilter } from "components/Core/Filters";
import { selectIsPlanActive } from "selectors/plan.selector";
import SettingsModal from "views/SendFax/modals/SettingsModal";
import { formatCdrSendTime, sendFaxTimezone } from "helpers/date";
import { getCreditDetails } from "stores/reducers/credit.reducer";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { selectCreditRemainingPages } from "selectors/credit.selector";
import RemainingPagesModal from "views/RemainingPages/RemainingPagesModal";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import {
  selectIsSendingFaxLoading,
  selectIsUploadFilesRunning,
  selectUploadFilesIsRetrying,
} from "selectors/numbers.selector";
import { sentryCaptureMessage } from "helpers/sentry";

interface SendFaxCardProps {
  children: ReactNode;
  onSend: () => void;
}

function SendFaxCard({ children, onSend }: SendFaxCardProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    sendFaxStore: { from, date, files, timezone, defaultCoverSheet, destinations, isOverlayOpen },
  } = useSendFaxContext();

  const isPlanTypeActive = useSelector(selectIsPlanActive);
  const isRetrying = useSelector(selectUploadFilesIsRetrying);
  const remainingPages = useSelector(selectCreditRemainingPages);
  const isUploadingFiles = useSelector(selectIsUploadFilesRunning);
  const isSendingFaxLoading = useSelector(selectIsSendingFaxLoading);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const [isSettingsModalOpen, isSettingsModalOpenBool] = useBool(false);
  const [showRemainingPagesModal, setShowRemainingPagesModal] = useState(false);

  const isScheduled = Boolean(date) && Boolean(timezone);
  const isLoading = isSendingFaxLoading || isUploadingFiles || isOverlayOpen || isRetrying;

  const tooltip = useMemo(() => {
    if (isRetrying) {
      return {
        isOpen: true,
        isDisabled: true,
        text: t("SENT_FAX.ERROR_RETRY"),
      };
    }

    if (isLoading) {
      return {
        text: t("COMMON.LOADING"),
        isDisabled: true,
        isOpen: false,
      };
    } else if (files.length < 1) {
      if (defaultCoverSheet) {
        return {
          text: t("SENT_FAX.ERROR_ONLY_COVER_SHEET"),
          isDisabled: true,
          isOpen: false,
        };
      }

      return {
        text: t("SENT_FAX.ERROR_NO_FILE"),
        isDisabled: true,
        isOpen: false,
      };
    } else if (destinations.length < 1) {
      return {
        text: t("SENT_FAX.ERROR_NO_DESTINATION"),
        isDisabled: true,
        isOpen: false,
      };
    } else if (!from) {
      sentryCaptureMessage({
        message: `Something went wrong during Send Fax, from number not found.`,
        level: "error",
      });

      return {
        text: t("SENT_FAX.ERROR_CONTACT_US"),
        isDisabled: true,
        isOpen: false,
      };
    } else if (date && timezone) {
      return {
        text: `${t("SENT_FAX.SCHEDULED_AT")} ${formatCdrSendTime(sendFaxTimezone(date, timezone))}`,
        isDisabled: false,
        isOpen: false,
      };
    }

    return {
      text: "",
      isDisabled: false,
      isOpen: false,
    };
  }, [
    t,
    from,
    date,
    timezone,
    isLoading,
    isRetrying,
    files.length,
    defaultCoverSheet,
    destinations.length,
  ]);

  const isDisabled = tooltip.isDisabled || isLoading;

  useEffect(() => {
    // To refresh the remainingPages
    dispatch(getCreditDetails());
  }, [dispatch]);

  return (
    <>
      <Card>
        <Card.Header>
          <From />
          {isPlanTypeActive && !isCorporateMember ? (
            <TextButton onClick={() => setShowRemainingPagesModal(true)}>
              <Typography type="panelFrontHeader" sx={{ "&&": { fontWeight: "normal" } }}>
                <Box component="span" sx={{ mr: 1 }}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Box>
                <Trans
                  i18nKey="PROFILE.REMAINING_PAGES_UNITS"
                  components={{
                    unit: (
                      <DigitFilter
                        value={remainingPages}
                        renderText={(value) => <span>{value}</span>}
                      />
                    ),
                  }}
                />
              </Typography>
            </TextButton>
          ) : null}
        </Card.Header>

        <Card.Content variant="border" position="relative">
          {children}
        </Card.Content>

        <Card.Footer sx={{ "&&": { display: "flex", justifyContent: "flex-end" } }}>
          <FaButton
            tooltip={t("COMMON.HELP")}
            fontSize={25}
            icon={faQuestionCircle}
            disabled={isLoading || isRetrying}
            onClick={() => {
              introJs().setOption("showStepNumbers", false).start();
            }}
          />
          <Box mr={1} />
          <div data-intro={t("SENT_FAX.THIRD_STEP")}>
            <FaButton
              icon={faCog}
              fontSize={25}
              tooltip={t("SENT_FAX.SETTINGS")}
              disabled={isLoading || isRetrying}
              data-cy={dataCy.sendFaxSettingButton}
              onClick={isSettingsModalOpenBool.setTrue}
            />
          </div>
          <Box mr={1} />
          <Button
            variant="blue"
            onClick={onSend}
            data-cy="SendButton"
            isLoading={isLoading}
            tooltip={tooltip.text}
            isDisabled={isDisabled}
            isTooltipOpen={tooltip.isOpen || undefined}
            {...(isScheduled ? { startIcon: faClock } : undefined)}
          >
            {t("COMMON.SEND")}
          </Button>
        </Card.Footer>
      </Card>

      {showRemainingPagesModal ? (
        <RemainingPagesModal handleClosure={() => setShowRemainingPagesModal(false)} />
      ) : null}

      {isSettingsModalOpen ? (
        <SettingsModal handleClosure={isSettingsModalOpenBool.setFalse} />
      ) : null}
    </>
  );
}

export default SendFaxCard;
