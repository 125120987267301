import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";

import { useInput } from "hooks/useInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { isValidJobTitle } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import {
  selectUserByUid,
  selectUpdateUserRoleRequestError,
  selectUpdateUserRoleRequestSuccess,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_UPDATE_USER_ROLE_REQUEST,
} from "stores/reducers/corporate.reducer";

function EditUserJobTitleModal({ userUid, handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(selectUserByUid(userUid));
  const error = useSelector(selectUpdateUserRoleRequestError);
  const success = useSelector(selectUpdateUserRoleRequestSuccess);

  const [role, roleInput] = useInput(user?.role ?? "", isValidJobTitle);

  const handleConfirm = useCallback(() => {
    roleInput.touch();

    if (roleInput.isValid) {
      dispatch(corporateAsyncActions.updateUserRole({ userUid, role: role }));
    }
  }, [dispatch, role, roleInput, userUid]);

  useEffect(() => {
    if (success) {
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(CLEAR_UPDATE_USER_ROLE_REQUEST());
      handleClosure?.();
    }
  }, [success, enqueueSnackbar, t, dispatch, handleClosure]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(CLEAR_UPDATE_USER_ROLE_REQUEST());
      handleClosure?.();
    }
  }, [error, enqueueSnackbar, t, dispatch, handleClosure]);

  useOnPressEnter(() => {
    if (roleInput.isFocused) {
      handleConfirm();
    }
  });

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      title={t("USERS.MODIFY_JOB_TITLE")}
      isConfirmDisabled={roleInput.showsError}
    >
      <Box p={3} pr="25%">
        <FormRowTextInput
          value={role}
          id="edit-role"
          onBlur={roleInput.onBlur}
          onFocus={roleInput.onFocus}
          error={roleInput.showsError}
          label={t("USERS.JOB_TITLE")}
          onChange={roleInput.onChange}
          inputProps={{ maxLength: 50 }}
        />
      </Box>
    </Modal>
  );
}

EditUserJobTitleModal.propTypes = {
  userUid: PropTypes.string,
  handleClosure: PropTypes.func,
};

export default EditUserJobTitleModal;
