import { useSnackbar } from "@alohi/kit";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef } from "react";

import useBool from "hooks/useBool";
import BoxModal from "views/Box/BoxModal";
import useInjectTag from "hooks/useInjectTag";
import { fileMimeTypeMap } from "enums/allowedFileTypes";
import { BOX_CLIENT_ID_PICKER_OAUTH, BOX_CLIENT_SECRET_PICKER_OAUTH } from "config";

interface UseBoxPickerProps {
  allowedFileTypes: string[];
  onLoading?: (isLoading: boolean) => void;
  onAddDocuments: (documents: FileList | File[]) => void;
}

interface BoxFile {
  name: string;
  authenticated_download_url: string;
  extension: keyof typeof fileMimeTypeMap;
}

interface PickerInstance {
  new (): {
    show(folderId: string | number, accessToken: string, params: Record<string, unknown>): void;
    addListener(eventName: "choose", listener: (files: BoxFile[]) => void): void;
    addListener(eventName: "cancel", listener: () => void): void;
  };
}

interface ExtendedWindow extends Window {
  Box: {
    FilePicker: PickerInstance;
  };
}

declare const window: ExtendedWindow;

const BOX_SDK_URL = `https://cdn01.boxcdn.net/platform/elements/17.1.0/en-US/picker.js`;

function useBoxPicker({ onAddDocuments, onLoading, allowedFileTypes }: UseBoxPickerProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = useRef<null | string>(null);
  const [isModalOpen, isModalOpenBool] = useBool(false);
  const { isLoaded, isLoading, setTag } = useInjectTag("script");
  const [wasPickerOpened, wasPickerOpenedBool] = useBool(false);

  const { search } = window.location;
  const isCodeToGetAccessTokenAvailable = new URLSearchParams(search).get("code") !== null;

  const handleTransformFile = useCallback(async (boxFile: BoxFile) => {
    try {
      const response = await fetch(boxFile.authenticated_download_url, {
        headers: { Authorization: `Bearer ${accessToken.current}` },
      });

      const blob = await response.blob();
      const file = new File([blob], boxFile.name, {
        type: fileMimeTypeMap[boxFile.extension],
      });

      return file;
    } catch {
      throw new Error("Can't download file");
    }
  }, []);

  const handlePickedFiles = useCallback(
    async (boxFiles: BoxFile[]) => {
      try {
        isModalOpenBool.setFalse();

        onLoading?.(true);

        const promises = boxFiles.map(handleTransformFile);
        const response = await Promise.allSettled(promises);

        const files = response.reduce<File[]>((accumulator, currentValue) => {
          if (currentValue.status === "fulfilled") {
            accumulator.push(currentValue.value);
          }
          return accumulator;
        }, []);

        onAddDocuments(files);
      } catch {
        throw new Error("Can't download files");
      } finally {
        onLoading?.(false);
      }
    },
    [handleTransformFile, isModalOpenBool, onAddDocuments, onLoading],
  );

  const handleOpenPicker = useCallback(() => {
    if (accessToken.current) {
      const filePicker = new window.Box.FilePicker();

      filePicker.addListener("choose", handlePickedFiles);

      filePicker.addListener("cancel", isModalOpenBool.setFalse);

      filePicker.show("0", accessToken.current, {
        container: ".box-container",
        extensions: allowedFileTypes.map((extension) => extension.slice(1)),
      });
    }
  }, [allowedFileTypes, handlePickedFiles, isModalOpenBool.setFalse]);

  const redirectToGetCode = useCallback(() => {
    if (accessToken.current !== null) {
      isModalOpenBool.setTrue();
    } else {
      const baseUrl = "https://account.box.com/api/oauth2/authorize";
      const clientId = BOX_CLIENT_ID_PICKER_OAUTH;
      const redirectUri = window.location.href;

      const authorizationUrl = `${baseUrl}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

      window.location.assign(authorizationUrl);
    }
  }, [isModalOpenBool]);

  const getAccessCode = useCallback(
    async (code: string) => {
      try {
        const authenticationUrl = "https://api.box.com/oauth2/token";

        const clientId = BOX_CLIENT_ID_PICKER_OAUTH;
        const clientSecret = BOX_CLIENT_SECRET_PICKER_OAUTH;

        const queryStrings = new URLSearchParams({
          code: code,
          client_id: clientId,
          client_secret: clientSecret,
          grant_type: "authorization_code",
        });

        const response = await fetch(authenticationUrl, {
          method: "post",
          body: queryStrings,
        });

        const json: { access_token: string } = await response.json();

        accessToken.current = json.access_token;

        setTag({ src: BOX_SDK_URL, async: "true" });
      } catch {
        enqueueSnackbar(t("SENT_FAX.ERROR_DROPBOX"), { variant: "error" });
      }
    },
    [enqueueSnackbar, setTag, t],
  );

  useEffect(() => {
    if (isCodeToGetAccessTokenAvailable) {
      const currentURL = new URL(window.location.href);
      const code = currentURL.searchParams.get("code");

      currentURL.searchParams.delete("code");
      history.replace(currentURL.pathname + currentURL.search);

      if (code) {
        getAccessCode(code);
      }
    }
  }, [history, isCodeToGetAccessTokenAvailable, getAccessCode]);

  useEffect(() => {
    if (isLoaded && window.Box && !wasPickerOpened) {
      isModalOpenBool.setTrue();
      wasPickerOpenedBool.setTrue();
    }
  }, [isLoaded, isModalOpenBool, wasPickerOpened, wasPickerOpenedBool]);

  return {
    loadBoxPicker: redirectToGetCode,
    isBoxPickerLoading: isLoading,
    BoxModal: isModalOpen ? <BoxModal onOpen={handleOpenPicker} /> : null,
  };
}

export default useBoxPicker;
