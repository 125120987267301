import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TextButton, makeStyles, Box, Loader, useSnackbar } from "@alohi/kit";

import { getDefaultPaymentMethod } from "stores/reducers/payment.methods.reducer";
import {
  selectPaymentMethods,
  selectSelectedPaymentType,
  selectDefaultPaymentThumbnail,
  selectIsLoadingUpdateCreditCard,
  selectIsUpdateCreditCardSuccessful,
} from "selectors/payment.selector";
import {
  updateCreditCard,
  clearUpdateCreditCard,
} from "stores/reducers/payment.creditCard.reducer";

interface SwitchPaymentFooterProps {
  type?: "credit_card" | "paypal";
  onOpenPaymentMethod: (type: "list" | "credit_card" | "paypal") => void;
}
function SwitchPaymentFooter({ type, onOpenPaymentMethod }: SwitchPaymentFooterProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const selectedPaymentMethod = useSelector(selectSelectedPaymentType);
  const isUpdateSuccessful = useSelector(selectIsUpdateCreditCardSuccessful);
  const isUpdateLoading = useSelector(selectIsLoadingUpdateCreditCard);
  const defaultPaymentMethodThumbnail = useSelector(selectDefaultPaymentThumbnail);
  const isPaypal = type === "paypal" || (!type && selectedPaymentMethod === "paypal");

  const paymentMethods: { id: string; type?: string; object?: string }[] =
    useSelector(selectPaymentMethods);
  const firstPaypalMethod = paymentMethods?.find((method) => method.type === "paypal");
  const firstCreditCardMethod = paymentMethods?.find((method) => method.object === "card");

  const handleSwitchPaymentMethod = useCallback(() => {
    if (!type && !selectedPaymentMethod) {
      onOpenPaymentMethod("paypal");
      return;
    }
    if (isPaypal) {
      if (firstCreditCardMethod?.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updateCreditCard(firstCreditCardMethod.id));
      } else {
        onOpenPaymentMethod("credit_card");
      }
    } else {
      if (firstPaypalMethod?.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updateCreditCard(firstPaypalMethod.id));
      } else {
        onOpenPaymentMethod("paypal");
      }
    }
  }, [
    selectedPaymentMethod,
    dispatch,
    firstCreditCardMethod,
    firstPaypalMethod,
    isPaypal,
    onOpenPaymentMethod,
    type,
  ]);

  useEffect(() => {
    if (!type && !selectedPaymentMethod) {
      dispatch(getDefaultPaymentMethod());
    }
  }, [selectedPaymentMethod, dispatch, type]);

  useEffect(() => {
    if (isUpdateSuccessful) {
      dispatch(getDefaultPaymentMethod());
      dispatch(clearUpdateCreditCard());
      onOpenPaymentMethod("list");
    }
  }, [
    dispatch,
    enqueueSnackbar,
    isUpdateSuccessful,
    defaultPaymentMethodThumbnail,
    t,
    selectedPaymentMethod,
    onOpenPaymentMethod,
    isPaypal,
  ]);

  return (
    <Box className={classes.base}>
      <TextButton disabled={isUpdateLoading} onClick={handleSwitchPaymentMethod}>
        {isPaypal ? t("ERRORS.TRY_TO_PAY_WITH_CREDIT_CARD") : t("ERRORS.TRY_TO_PAY_WITH_PAYPAL")}
      </TextButton>
      {isUpdateLoading ? <Loader /> : null}
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  base: {
    display: "flex",
    alignItems: "center",
    paddingRight: spacing(2),
    justifyContent: "flex-end",
  },
}));

export default SwitchPaymentFooter;
