import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import RS from "enums/requestStatus";
import { accountsApi } from "../../api/accounts.api";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

export const initialSsoState = {
  sso: {
    getSsoSettings: initialRequestState,
    updateSsoSettings: initialRequestState,
    deactivateSso: initialRequestState,
  },
};

const getSsoSettings = createAsyncThunk(
  "ACCOUNT/GET_SSO_SETTINGS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountsApi.getSsoSettings();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().account.sso.getSsoSettings.status !== RS.RUNNING;
    },
  },
);

const updateSsoSettings = createAsyncThunk(
  "ACCOUNT/UPDATE_SSO_SETTINGS",
  async (
    {
      ssoUrl,
      entityId,
      x509Cert,
      isLegacy,
      isOnlyAllowSso,
      isAddFaxLicense,
      isAddSignLicense,
      refreshTokenLifetime,
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await accountsApi.updateSsoSettings({
        ssoUrl,
        entityId,
        x509Cert,
        isLegacy,
        isOnlyAllowSso,
        isAddFaxLicense,
        isAddSignLicense,
        refreshTokenLifetime,
      });
      await dispatch(getSsoSettings()).unwrap();

      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const deactivateSso = createAsyncThunk("ACCOUNT/DEACTIVATE_SSO", async (_, { rejectWithValue }) => {
  try {
    const response = await accountsApi.deactivateSso();
    return { response };
  } catch (error) {
    return rejectWithValue(error.origin);
  }
});

export const clearGetSsoSettings = createAction("ACCOUNT/CLEAR_GET_SSO_SETTINGS");
export const clearUpdateSsoSettings = createAction("ACCOUNT/CLEAR_UPDATE_SSO_SETTINGS");
export const clearDeactivateSso = createAction("ACCOUNT/CLEAR_DEACTIVATE_SSO");

export const ssoSlice = (builder) => {
  builder.addCase(getSsoSettings.pending, (state) => {
    state.sso.getSsoSettings.status = RS.RUNNING;
    state.sso.getSsoSettings.error = null;
  });
  builder.addCase(getSsoSettings.fulfilled, (state, { payload }) => {
    state.sso.getSsoSettings.status = RS.IDLE;
    state.sso.getSsoSettings.response = payload.response;
  });
  builder.addCase(getSsoSettings.rejected, (state, { payload }) => {
    state.sso.getSsoSettings.status = RS.ERROR;
    state.sso.getSsoSettings.error = payload;
    state.sso.getSsoSettings.response = false; // An error can mean sso is disabled
  });
  builder.addCase(clearGetSsoSettings, (state) => {
    state.sso.getSsoSettings = initialRequestState;
  });
  builder.addCase(updateSsoSettings.pending, (state) => {
    state.sso.updateSsoSettings.status = RS.RUNNING;
    state.sso.updateSsoSettings.error = null;
  });
  builder.addCase(updateSsoSettings.fulfilled, (state, { payload }) => {
    state.sso.updateSsoSettings.status = RS.IDLE;
    state.sso.updateSsoSettings.response = payload.response;
  });
  builder.addCase(updateSsoSettings.rejected, (state, { payload }) => {
    state.sso.updateSsoSettings.status = RS.ERROR;
    state.sso.updateSsoSettings.error = payload;
  });
  builder.addCase(clearUpdateSsoSettings, (state) => {
    state.sso.updateSsoSettings = initialRequestState;
  });
  builder.addCase(deactivateSso.pending, (state) => {
    state.sso.deactivateSso.status = RS.RUNNING;
    state.sso.deactivateSso.error = null;
  });
  builder.addCase(deactivateSso.fulfilled, (state, { payload }) => {
    state.sso.deactivateSso.status = RS.IDLE;
    state.sso.deactivateSso.response = payload.response;
  });
  builder.addCase(deactivateSso.rejected, (state, { payload }) => {
    state.sso.deactivateSso.status = RS.ERROR;
    state.sso.deactivateSso.error = payload;
  });
  builder.addCase(clearDeactivateSso, (state) => {
    state.sso.deactivateSso = initialRequestState;
  });
};

export { getSsoSettings, updateSsoSettings, deactivateSso };
