import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MenuItem, Select, Panel, Typography, TextButton, Box, useSnackbar } from "@alohi/kit";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import { Helper, makeStyles } from "ui";
import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import MigrateDataModal from "views/MigrateData/MigrateDataModal";
import { selectCanIEditDataResidency } from "selectors/ability.selector";
import LinearInfiniteProgress from "components/Core/LinearInfiniteProgress/LinearInfiniteProgress";
import {
  selectUserLiveDataId,
  selectUserUpdatedData,
  selectUserBackupDataId,
  selectNextMigrationTime,
  selectLiveDataLocations,
  selectBackupDataLocations,
  selectIsMigrationInProgress,
  selectIsUserAllowedToMigrate,
  selectIsUserUpdateLocationError,
  selectIsUserUpdateLocationRunning,
} from "selectors/account.selector";
import {
  getMigrationStatus,
  updateDataLocation,
  setUserDataSettings,
  clearUpdateDataLocation,
  getDataResidencyLocations,
  getUserDataResidencySettings,
} from "stores/reducers/account.dataResidency.reducer";
import DataResidencyCooldown from "./DataResidencyCooldown";
import ActivateDataResidencyButton from "./ActivateDataResidencyButton";

function DataResidencyPanel() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const liveData = useSelector(selectLiveDataLocations);

  const updatedData = useSelector(selectUserUpdatedData);
  const backupData = useSelector(selectBackupDataLocations);
  const currentLiveData = useSelector(selectUserLiveDataId);
  const currentBackupData = useSelector(selectUserBackupDataId);
  const nextMigrationTime = useSelector(selectNextMigrationTime);
  const isAllowedToMigrate = useSelector(selectIsUserAllowedToMigrate);
  const isMigrationInProgress = useSelector(selectIsMigrationInProgress);
  const isDataUpdateError = useSelector(selectIsUserUpdateLocationError);
  const isDataUpdateRunning = useSelector(selectIsUserUpdateLocationRunning);
  const canIEditDataResidency = useSelector(selectCanIEditDataResidency);

  const [isFlipped, setIsFlipped] = useState(false);
  const [migrateData, migrateDataHandler] = useBool(false);

  const [liveDataValue, liveDataInput] = useInput("");
  const [backupDataValue, backupDataInput] = useInput("");
  const [initialLiveData, setInitialLiveData] = useState("");
  const [initialBackupData, setInitialBackupData] = useState("");

  const handleOnSave = useCallback(() => {
    if (
      currentLiveData &&
      liveDataValue &&
      currentBackupData &&
      backupDataValue &&
      (liveDataValue !== currentLiveData || backupDataValue !== currentBackupData)
    ) {
      dispatch(
        updateDataLocation({
          liveData: liveDataValue,
          backupData: backupDataValue,
        }),
      );
    } else {
      setIsFlipped(false);
    }
  }, [dispatch, currentLiveData, currentBackupData, liveDataValue, backupDataValue]);

  const handleClosure = useCallback(() => {
    migrateDataHandler.setFalse();
    setIsFlipped(false);
  }, [migrateDataHandler]);

  const onEdit = useCallback(() => {
    if (isAllowedToMigrate) {
      setIsFlipped(true);
    }
  }, [isAllowedToMigrate]);

  const panelTitle = useMemo(
    () => (
      <Typography type={isFlipped ? "panelBackHeader" : "panelFrontHeader"}>
        {t("PROFILE.DATA_RESIDENCY_TITLE")}
        <Box
          ml={1}
          component="a"
          href={helpCenterUrls.dataResidency}
          target="_blank"
          rel="noreferrer"
        >
          <Helper size="lg" variant={isFlipped ? "blue" : "light"} />
        </Box>
      </Typography>
    ),
    [isFlipped, t],
  );

  const headerAction = useMemo(() => {
    if (canIEditDataResidency && !nextMigrationTime) {
      if (isMigrationInProgress) {
        return (
          <Box className={classes.migrationText}>
            <LinearInfiniteProgress dotCount={3} diameter={4} wrapperClassName={classes.loader} />
            {t("PROFILE.DATA_RESIDENCY_MIGRATING")}
          </Box>
        );
      } else {
        return (
          <TextButton onClick={onEdit}>
            <Typography type="panelFrontHeader" className={!isAllowedToMigrate && classes.disabled}>
              {t("COMMON.EDIT")}
            </Typography>
            <Box sx={{ ml: 1, color: "white" }}>
              <FontAwesomeIcon icon={faPenToSquare} color="white" />
            </Box>
          </TextButton>
        );
      }
    }

    return nextMigrationTime ? <DataResidencyCooldown /> : <ActivateDataResidencyButton />;
  }, [
    canIEditDataResidency,
    nextMigrationTime,
    isMigrationInProgress,
    classes.migrationText,
    classes.loader,
    classes.disabled,
    t,
    onEdit,
    isAllowedToMigrate,
  ]);

  const handleOnCancel = useCallback(() => {
    liveDataInput.setValue(currentLiveData);
    backupDataInput.setValue(currentBackupData);
    setIsFlipped(false);
  }, [backupDataInput, currentBackupData, currentLiveData, liveDataInput]);

  useEffect(() => {
    dispatch(getDataResidencyLocations());
    dispatch(getUserDataResidencySettings());
    dispatch(getMigrationStatus());
  }, [dispatch]);

  useEffect(() => {
    if (currentLiveData && liveDataValue === "") {
      liveDataInput.setValue(currentLiveData);
      setInitialLiveData(currentLiveData);
    }

    if (currentBackupData && backupDataValue === "") {
      backupDataInput.setValue(currentBackupData);
      setInitialBackupData(currentBackupData);
    }
  }, [
    liveDataInput,
    liveDataValue,
    backupDataInput,
    backupDataValue,
    currentLiveData,
    currentBackupData,
  ]);

  useEffect(() => {
    if (updatedData) {
      dispatch(setUserDataSettings(updatedData));
      migrateDataHandler.setTrue();
      dispatch(getMigrationStatus());
      dispatch(clearUpdateDataLocation());
    }
  }, [
    dispatch,
    migrateDataHandler,
    updatedData,
    backupDataValue,
    liveDataValue,
    currentLiveData,
    currentBackupData,
  ]);

  useEffect(() => {
    if (isDataUpdateError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      liveDataInput.setValue(currentLiveData);
      backupDataInput.setValue(currentBackupData);
      dispatch(clearUpdateDataLocation());
      setIsFlipped(false);
    }
  }, [
    t,
    dispatch,
    liveDataInput,
    enqueueSnackbar,
    backupDataInput,
    currentLiveData,
    currentBackupData,
    isDataUpdateError,
  ]);

  return (
    <>
      <Panel
        sx={{ mb: 7 }}
        id="data-residency"
        isFlipped={isFlipped}
        headerTitle={panelTitle}
        data-cy={dataCy.dataResidencyPanel}
      >
        <Panel.Front headerAction={headerAction}>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.DATA_RESIDENCY_LIVE_DATA")}</Panel.Cell>
            <Panel.Cell variant="data">
              {liveData?.nameMap[liveDataValue]?.description ?? ""}
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.DATA_RESIDENCY_BACKUP_DATA")}</Panel.Cell>
            <Panel.Cell variant="data">
              {backupData?.nameMap[backupDataValue]?.description ?? ""}
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>

        <Panel.Back
          onConfirm={handleOnSave}
          onCancel={handleOnCancel}
          isConfirmLoading={isDataUpdateRunning}
        >
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.DATA_RESIDENCY_LIVE_DATA")}</Panel.Cell>
            <Panel.Cell variant="data">
              <Select
                sx={{ minWidth: 190 }}
                displayEmpty
                variant="outlined"
                id="live-data-input"
                value={liveDataValue}
                labelId={`live-data-input-label`}
                onChange={liveDataInput.onChange}
              >
                {liveData?.ids.map((id) => (
                  <MenuItem key={id} value={id}>
                    {liveData?.nameMap[id]?.description ?? ""}
                  </MenuItem>
                ))}
              </Select>
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("PROFILE.DATA_RESIDENCY_BACKUP_DATA")}</Panel.Cell>
            <Panel.Cell variant="data">
              <Select
                sx={{ minWidth: 190 }}
                displayEmpty
                variant="outlined"
                id="live-data-input"
                value={backupDataValue}
                labelId={`live-data-input-label`}
                onChange={backupDataInput.onChange}
              >
                {backupData?.ids.map((id) => (
                  <MenuItem key={id} value={id}>
                    {backupData?.nameMap[id]?.description ?? ""}
                  </MenuItem>
                ))}
              </Select>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Back>
      </Panel>

      {migrateData ? (
        <MigrateDataModal
          handleClosure={handleClosure}
          initialLiveData={initialLiveData}
          initialBackupData={initialBackupData}
          currentLiveData={currentLiveData}
          currentBackupData={currentBackupData}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ typography, alohi, spacing }) => ({
  loader: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    marginRight: spacing(1),
    justifyContent: "center",
    "& > span": {
      backgroundColor: alohi.white,
    },
  },
  migrationText: {
    display: "flex",
    cursor: "default",
    fontWeight: "bold",
    color: alohi.white,
    lineHeight: "normal",
    paddingRight: "7px",
    fontSize: typography.pxToRem(15),
  },
  disabled: {
    cursor: "not-allowed",
    "&&": {
      color: alohi.darkGray,
    },
  },
}));

export default DataResidencyPanel;
