import { Box } from "@alohi/kit";
import PropTypes from "prop-types";

import { parseExtension, formatPhoneNumberIntl } from "helpers/numbers";

function PhoneNumberFilter({ number: input }) {
  if (!input) {
    return null;
  }

  const { number, extension } = parseExtension(input);

  return (
    <Box component="span">
      {/* Display formatted number if possible, raw otherwise */}
      {formatPhoneNumberIntl(number) || number}
      {extension}
    </Box>
  );
}

PhoneNumberFilter.propTypes = {
  number: PropTypes.string,
};

export default PhoneNumberFilter;
