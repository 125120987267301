import { ComponentType, memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Elements } from "@stripe/react-stripe-js";
import stripe, { loadStripe, StripeElementLocale } from "@stripe/stripe-js";

import { STRIPE_CLIENT_ID } from "config";
import { allowedStripeLocales } from "i18n";

let stripeOutsideRef: null | PromiseLike<stripe.Stripe | null> = null;

const WithStripeHOC = <T extends object>(WrappedComponent: ComponentType<T>) => {
  function HOC(props: T) {
    const [stripePromise, setStripePromise] = useState<typeof stripeOutsideRef>(null);
    const { i18n } = useTranslation();

    // Allowed Stripe locale from StripeElementLocale
    const locale = (
      allowedStripeLocales.includes(i18n.language as StripeElementLocale) ? i18n.language : "en"
    ) as StripeElementLocale;

    const load = useCallback(() => {
      if (stripeOutsideRef === null && STRIPE_CLIENT_ID) {
        stripeOutsideRef = loadStripe(STRIPE_CLIENT_ID, { locale });
      }
      setStripePromise(stripeOutsideRef);
    }, [locale]);

    useEffect(() => {
      if ("Stripe" in window) {
        load();
      }
    }, [load]);

    return (
      <Elements stripe={stripePromise} options={{ locale }}>
        <WrappedComponent {...props} />
      </Elements>
    );
  }

  return memo(HOC);
};

export default WithStripeHOC;
