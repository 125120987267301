import { Box } from "@alohi/kit";
import { useSelector } from "react-redux";

import { selectIsCorporateUpgrade } from "../../../selectors/common.selector";
import AvailablePlans from "../AvailablePlans";
import PreviewPlans from "./PreviewPlans";
import UpgradeIndividualPlan from "./UpgradeIndividualPlan";

function ChangePlanFromPaidComponent() {
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);

  return (
    <Box display="flex" flexDirection="column" height="100%" pt={2}>
      <AvailablePlans />
      <PreviewPlans />
      <Box pr="22%">{isCorporateUpgrade ? <UpgradeIndividualPlan /> : null}</Box>
    </Box>
  );
}

export default ChangePlanFromPaidComponent;
