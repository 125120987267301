import { Typography, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import ShareButton from "./components/ShareButton";
import DeleteButton from "./components/DeleteButton";
import SendFaxButton from "./components/SendFaxButton";
import ManageGroupsButton from "./components/ManageGroupsButton";

function ContactsBulkActions() {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography type="panelFrontHeader" sx={{ "&&": { mr: 1 } }}>
        {t("CONTACTS.APPLY_TO_SELECTION")}
      </Typography>

      <SendFaxButton />
      <ManageGroupsButton />
      <ShareButton />
      <DeleteButton />
    </Box>
  );
}

export default ContactsBulkActions;
