import {
  Box,
  Card,
  Container,
  FaButton,
  makeStyles,
  Modal,
  Tooltip,
  Typography,
  useSnackbar,
  useTheme,
} from "@alohi/kit";
import { faCartPlus, faPhoneSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";

import ColumnActions from "components/Columns/ColumnActions";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import { PhoneNumberFilter } from "components/Core/Filters";
import LinearInfiniteProgress from "components/Core/LinearInfiniteProgress/LinearInfiniteProgress";
import InfiniTable from "components/InfiniTable/InfiniTable";
import dataCy from "enums/dataCy";
import { formatDefaultDate } from "helpers/date";
import { capitalizeString } from "helpers/string";
import useAdminRedirections from "hooks/useAdminRedirections";
import useTitle from "hooks/useTitle";
import { alohiAdminUrls, filterQueryParams } from "routes/urls";
import { selectCanISeeNumbersTab } from "selectors/ability.selector";
import { selectAllNumbersAndPlanInfo } from "selectors/common.selector";
import {
  selectCancelNumberError,
  selectIsNumberCanceled,
  selectIsNumberResumed,
  selectIsReachedMinNumberOfNumbers,
  selectIsUpdateFaxReceptionSuccess,
} from "selectors/numbers.selector";
import { selectIsCartInProgress } from "selectors/payment.selector";
import { selectPlanExpirationDate, selectPlanId, selectPlanType } from "selectors/plan.selector";
import {
  cancelNumber,
  clearCancelNumber,
  clearResumeNumber,
  clearUpdateFaxReception,
  getAllNumbers,
  resumeNumber,
  updateFaxReception,
} from "stores/reducers/numbers.reducer";
import { clearCartStatus, getCartStatus } from "stores/reducers/payment.cart.reducer";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import AddCorporateNumberModal from "views/AddNumber/AddCorporateNumberModal";
import AssignNumbersModal from "views/AssignNumbers/AssignNumbersModal";
import DisableFaxReceptionModal from "./DisableFaxReceptionModal";
import EditFriendlyNameModal from "./EditFriendlyNameModal";
import ResumeNumberModal from "./ResumeNumberModal";

function Numbers() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleRedirection: redirectToAdmin, canBeRedirectedToAdmin } = useAdminRedirections();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reroutedQueryParams = filterQueryParams({
    filter: ["_gl"],
    currentParams: queryParams,
  }).toString();

  const planId = useSelector(selectPlanId);
  const planType = useSelector(selectPlanType);
  const isNumberResumed = useSelector(selectIsNumberResumed);
  const isCartInProgress = useSelector(selectIsCartInProgress);
  const isNumberCanceled = useSelector(selectIsNumberCanceled);
  const numbersList = useSelector(selectAllNumbersAndPlanInfo);
  const cancelNumberError = useSelector(selectCancelNumberError);
  const canISeeNumbersTab = useSelector(selectCanISeeNumbersTab);
  const planExpirationDate = useSelector(selectPlanExpirationDate);
  const isFaxReceptionUpdated = useSelector(selectIsUpdateFaxReceptionSuccess);
  const isMinNumberReachedError = useSelector(selectIsReachedMinNumberOfNumbers);

  const [selectedRowData, setSelectedRowData] = useState();
  const selectedRowNumber = selectedRowData?.[0];

  const [openAddNumber, setOpenAddNumber] = useState(false);
  const [openManageAssign, setOpenManageAssign] = useState(false);
  const [isFriendlyNameModalOpen, setIsFriendlyNameModalOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openResumeConfirmation, setOpenResumeConfirmation] = useState(false);
  const [disableReception, setDisableReception] = useState(null);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.addNumber,
      fallbackAction: () => setOpenAddNumber(true),
    });
  }, [redirectToAdmin]);

  const handleOpenChangeNumber = useCallback(
    (number) => {
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.changeNumber,
        adminParams: {
          number,
        },
      });
    },
    [redirectToAdmin],
  );

  const getActions = (rowData) => () => {
    const actions = [];
    const id = rowData[4];
    const {
      number,
      status,
      friendly_name,
      expiration_date,
      reception_enabled,
      notify_when_disabled: notifyWhenDisabled,
    } = numbersList.find((number) => number.id === id);

    if (status === "active") {
      if (canBeRedirectedToAdmin) {
        actions.push({
          label: t("COMMON.CHANGE_NUMBER"),
          callback: () => {
            handleOpenChangeNumber(number);
          },
        });
      }

      actions.push({
        label: t("NUMBERS.MANAGE_ASSIGN"),
        callback: () => {
          setSelectedRowData(rowData);
          setOpenManageAssign(true);
        },
      });

      if (!!friendly_name) {
        actions.push({
          label: t("NUMBERS.MODIFY_FRIENDLY_NAME"),
          callback: () => {
            setSelectedRowData(rowData);
            setIsFriendlyNameModalOpen(true);
          },
        });
      } else {
        actions.push({
          label: t("NUMBERS.ADD_FRIENDLY_NAME"),
          callback: () => {
            setSelectedRowData(rowData);
            setIsFriendlyNameModalOpen(true);
          },
        });
      }
    }

    actions.push({
      label: reception_enabled
        ? t("NUMBERS.DISABLE_FAX_RECEPTION")
        : t("NUMBERS.ENABLE_FAX_RECEPTION"),
      callback: () => {
        if (reception_enabled) {
          setDisableReception({ number, notifyWhenDisabled });
        } else {
          dispatch(
            updateFaxReception({
              toEnable: true,
              numberToUpdate: number,
            }),
          );
        }
      },
    });

    if (Boolean(expiration_date)) {
      actions.push({
        label: t("NUMBERS.RESUME"),
        callback: () => {
          setSelectedRowData(rowData);
          setOpenResumeConfirmation(true);
        },
      });
    } else {
      actions.push({
        label: t("NUMBERS.DELETE"),
        callback: () => {
          setSelectedRowData(rowData);
          setOpenDeleteConfirmation(true);
        },
      });
    }
    return actions;
  };

  const getColumns = () => [
    {
      name: "number",
      label: " ",
      options: {
        customBodyRender: function bodyRender(value) {
          return (
            <CountryFlag
              phone={value}
              style={{
                width: "32px",
                height: "auto",
                float: "right",
              }}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: t("CONTACTS.FAX_NUMBER"),
      options: {
        customBodyRender: function bodyRender(id) {
          const { number, friendly_name } = numbersList.find((number) => number.id === id);
          return (
            <div>
              <Typography type="body" style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                <PhoneNumberFilter number={number} />
              </Typography>
              <Typography
                type="body"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 150,
                }}
              >
                {friendly_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "assigned_to",
      label: t("NUMBERS.ASSIGN_TO"),
      options: {
        sortCompare: (order) => (val1, val2) => {
          return (val1.data.length - val2.data.length) * (order === "asc" ? 1 : -1);
        },
        customBodyRender: function bodyRender(value) {
          if (value.length > 0) {
            return (
              <Typography type="body">
                {value.length} {t("NUMBERS.USER", { count: value.length })}
              </Typography>
            );
          }
          return "";
        },
      },
    },
    {
      name: "acquired_date",
      label: t("NUMBERS.ACQUIRED_ON"),
      options: {
        customBodyRender: function bodyRender(value) {
          return <Typography type="body">{formatDefaultDate(value)}</Typography>;
        },
      },
    },
    {
      name: "id",
      label: t("COMMON.STATUS"),
      options: {
        customBodyRender: function bodyRender(id) {
          const {
            statusAndExpiration: { status: statusValue, expiration },
          } = numbersList.find((number) => number.id === id);
          let cellData = statusValue;
          if (cellData === "active") {
            cellData = t("COMMON.ACTIVE");
          }
          let expirationData = null;
          if (expiration) {
            expirationData = (
              <Tooltip title={<>Will be removed on {formatDefaultDate(expiration)}</>}>
                <Typography
                  style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    color: theme.palette.error.main,
                  }}
                >
                  {t("NUMBERS.EXPIRES_ON", {
                    date: formatDefaultDate(expiration),
                  })}
                </Typography>
              </Tooltip>
            );
          }
          return (
            <Box display="flex" flexDirection="column">
              {capitalizeString(cellData)} {expirationData}
            </Box>
          );
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: function bodyRender(_, { rowData }) {
          const { reception_enabled } = numbersList.find((number) => number.id === rowData[1]);
          return (
            <Box className={classes.actions}>
              {!reception_enabled ? (
                <FontAwesomeIcon icon={faPhoneSlash} data-cy={dataCy.phoneSlashIcon} />
              ) : null}
              <ColumnActions createActions={getActions(rowData)} />
            </Box>
          );
        },
        setCellHeaderProps: () => ({ style: { width: "80px" } }),
        setCellProps: () => ({ style: { paddingLeft: 0 } }),
      },
    },
  ];

  const onDeleteConfirm = useCallback(() => {
    dispatch(
      cancelNumber({
        planId,
        number: selectedRowNumber,
      }),
    );
    setOpenDeleteConfirmation(false);
  }, [dispatch, planId, selectedRowNumber]);

  const onResume = useCallback(
    (isConfirmed) => {
      if (isConfirmed) {
        dispatch(
          resumeNumber({
            planId,
            number: selectedRowNumber,
          }),
        );
      }
      setOpenResumeConfirmation(false);
    },
    [planId, dispatch, selectedRowNumber],
  );

  useTitle(t("PROFILE.NUMBERS"));

  useEffect(() => {
    if (isNumberResumed || isNumberCanceled) {
      isNumberResumed && dispatch(clearResumeNumber());
      isNumberCanceled && dispatch(clearCancelNumber());
      dispatch(getCurrentPlan());
      dispatch(getAllNumbers());
    }
  }, [dispatch, isNumberResumed, isNumberCanceled]);

  useEffect(() => {
    if (cancelNumberError) {
      let error;
      if (isMinNumberReachedError) {
        error = (
          <Trans
            i18nKey="NUMBERS.REACHED_MINIMUM_NUMBER_OF_NUMBERS"
            components={{ plan: capitalizeString(planType) }}
          />
        );
      } else {
        error = t("COMMON.SERVER_ERROR");
      }
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearCancelNumber());
    }
  }, [dispatch, planType, t, enqueueSnackbar, cancelNumberError, isMinNumberReachedError]);

  useEffect(() => {
    dispatch(getCartStatus());
    dispatch(getCurrentPlan());
    dispatch(getAllNumbers());

    return () => {
      dispatch(clearCartStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isFaxReceptionUpdated) {
      dispatch(getAllNumbers());
      dispatch(clearUpdateFaxReception());
    }
  }, [dispatch, isFaxReceptionUpdated]);

  if (!canISeeNumbersTab) {
    return <Redirect to={{ pathname: `/`, search: reroutedQueryParams }} />;
  }

  return (
    <>
      {numbersList ? (
        <Container maxWidth="sm">
          <Card>
            <Card.Header>
              <Typography type="cardFrontHeader">{t("PROFILE.NUMBERS")}</Typography>
              {isCartInProgress ? (
                <Typography type="cardFrontHeader" sx={{ display: "flex" }}>
                  <LinearInfiniteProgress
                    dotCount={3}
                    diameter={4}
                    wrapperClassName={classes.loader}
                  />
                  {t("ADD_NUMBER.CART_IN_PROGRESS")}
                </Typography>
              ) : (
                <FaButton
                  variant={"light"}
                  icon={faCartPlus}
                  disabled={isCartInProgress}
                  onClick={handleOpenChangePlan}
                  tooltip={t("ADD_NUMBER.ADD_NEW_NUMBER")}
                />
              )}
            </Card.Header>
            <InfiniTable
              title=""
              data={numbersList}
              columns={getColumns()}
              options={{
                serverSide: false,
                responsive: "simple",
                selectableRows: "none",
                selectableRowsHeader: false,
              }}
            />
          </Card>
        </Container>
      ) : null}

      {openAddNumber ? (
        <AddCorporateNumberModal handleClosure={() => setOpenAddNumber(false)} />
      ) : null}

      {isFriendlyNameModalOpen ? (
        <EditFriendlyNameModal
          number={selectedRowNumber}
          handleClosure={() => setIsFriendlyNameModalOpen(false)}
        />
      ) : null}

      {openManageAssign && selectedRowData ? (
        <AssignNumbersModal
          numberToAssign={selectedRowNumber}
          onCancel={() => setOpenManageAssign(false)}
          onConfirm={() => setOpenManageAssign(false)}
        />
      ) : null}

      {openResumeConfirmation && selectedRowData ? (
        <ResumeNumberModal handleClosure={onResume} number={selectedRowNumber} />
      ) : null}

      {disableReception ? (
        <DisableFaxReceptionModal
          number={disableReception.number}
          isChecked={disableReception.notifyWhenDisabled}
          handleClosure={() => setDisableReception(null)}
        />
      ) : null}

      {openDeleteConfirmation && selectedRowData ? (
        <Modal
          maxWidth="xs"
          onConfirm={onDeleteConfirm}
          onCancel={() => setOpenDeleteConfirmation(false)}
          title={
            <Typography type="modalTitle">
              <Trans
                i18nKey="CONFIRMATION.DELETE"
                components={{
                  data: <PhoneNumberFilter number={selectedRowNumber} />,
                }}
              />
            </Typography>
          }
        >
          <Box p={3}>
            <Typography type="body">
              <Trans
                i18nKey="NUMBERS.QUESTION_REMOVE_NUMBER"
                components={{
                  number: <PhoneNumberFilter number={selectedRowNumber} />,
                }}
              />
            </Typography>
            <Box mt={1} />
            <Typography type="body">
              <Trans
                i18nKey="NUMBERS.DESCRIBE_IF_REMOVE_NUMBER"
                components={{
                  date: formatDefaultDate(planExpirationDate),
                }}
              />
            </Typography>
            <Box mt={1} />
            <Typography type="body">
              <Trans i18nKey="NUMBERS.DESCRIBE_IF_REMOVE_NUMBER_SECOND" />
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  actions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  loader: {
    padding: spacing(1),
    paddingTop: 10, // Better alignment due to size of dots
    "& > span": {
      backgroundColor: alohi.white,
    },
  },
}));

export default Numbers;
