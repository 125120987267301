import { useRouteMatch } from "react-router";

import { useAppSelector } from "stores/store";
import { contactsRoutes } from "enums/contacts";
import { selectIdsOfCheckedContacts } from "selectors/contacts.selector";
import ShareContactsOrGroupsModal from "views/Contacts/ShareContactsOrGroupsModal";

interface ShareConfirmationProps {
  handleClosure: () => void;
}

function ShareConfirmation({ handleClosure }: ShareConfirmationProps) {
  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const idsOfCheckedContacts = useAppSelector(selectIdsOfCheckedContacts(contactsTab));

  return (
    <>
      {contactsTab === contactsRoutes.whitelist ? (
        <ShareContactsOrGroupsModal
          isContact
          handleClosure={handleClosure}
          idsToShare={idsOfCheckedContacts}
        />
      ) : null}

      {contactsTab === contactsRoutes.groups ? (
        <ShareContactsOrGroupsModal
          isContact={false}
          handleClosure={handleClosure}
          idsToShare={idsOfCheckedContacts}
        />
      ) : null}
    </>
  );
}

export default ShareConfirmation;
