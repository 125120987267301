import { Panel } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { selectIsPlanFree } from "selectors/plan.selector";
import { PhoneNumberFilter } from "components/Core/Filters";
import { selectAssignedNumbers } from "selectors/numbers.selector";

import FaxNumberTooltip from "./FaxNumberTooltip";

function FaxNumberBackRow() {
  const { t } = useTranslation();

  const isPlanFree = useSelector(selectIsPlanFree);
  const assignedNumbers = useSelector(selectAssignedNumbers);

  return (
    <Panel.Row>
      <Panel.Cell variant="title">{t("PROFILE.FAX_NUMBER")}</Panel.Cell>
      {isPlanFree || !assignedNumbers?.length ? (
        <Panel.Cell variant="data">{t("COMMON.NO_AVAILABLE")}</Panel.Cell>
      ) : (
        <Panel.Cell variant="data">
          <PhoneNumberFilter number={assignedNumbers[0]?.number || ""} />
          {assignedNumbers?.length > 1 ? <FaxNumberTooltip /> : null}
        </Panel.Cell>
      )}
    </Panel.Row>
  );
}

export default FaxNumberBackRow;
