import queryString from "query-string";
import { Box, useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Typography } from "ui";
import { fileToFaxFile } from "helpers/file";
import Loading from "components/Loading/Loading";
import { FaxFile } from "views/SendFax/contexts/store";
import { clearGetEnvelopeMetadata, getEnvelopeMetadata } from "stores/reducers/numbers.reducer";
import {
  selectEnvelopeMetadata,
  selectEnvelopeMetadataError,
  selectIsEnvelopeMetadataRunning,
} from "selectors/numbers.selector";
import InitSendFaxProviders from "./InitSendFaxProviders";

function DocumentToFax() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { token } = queryString.parse(search);
  const extractedToken = typeof token === "string" ? token : (token?.[0] ?? "");

  const envelopeMetadata = useSelector(selectEnvelopeMetadata);
  const metadataError = useSelector(selectEnvelopeMetadataError);
  const isMetadataLoading = useSelector(selectIsEnvelopeMetadataRunning);

  const [file, setFile] = useState<FaxFile[]>([]);

  const handleDownloadBlob = useCallback(
    async (fileObjectUrl: string, fileName: string, mimeType: string) => {
      try {
        const response = await fetch(fileObjectUrl);
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: mimeType });
        const faxFile = await fileToFaxFile(file);

        setFile([faxFile]);
      } catch {
        enqueueSnackbar(t("FAXES.FILE_NOT_FOUND"), { variant: "info" });
      } finally {
        replace("/document-fax");
      }
    },
    [enqueueSnackbar, replace, t],
  );

  useEffect(() => {
    if (Boolean(extractedToken)) {
      // First verify that the token is valid
      dispatch(getEnvelopeMetadata({ token: extractedToken }));
    }
  }, [dispatch, extractedToken]);

  useEffect(() => {
    if (
      envelopeMetadata?.fileObjectUrl &&
      envelopeMetadata?.fileName &&
      envelopeMetadata?.mimeType
    ) {
      // Download the file if the token was valid
      handleDownloadBlob(
        envelopeMetadata.fileObjectUrl,
        envelopeMetadata.fileName,
        envelopeMetadata.mimeType,
      );
      dispatch(clearGetEnvelopeMetadata());
    }
  }, [dispatch, envelopeMetadata, handleDownloadBlob]);

  useEffect(() => {
    if (metadataError) {
      replace("/document-fax");
      enqueueSnackbar(metadataError, { variant: "info" });
      dispatch(clearGetEnvelopeMetadata());
    }
  }, [dispatch, enqueueSnackbar, metadataError, replace, t]);

  useEffect(() => {
    return () => {
      dispatch(clearGetEnvelopeMetadata());
    };
  }, [dispatch]);

  if (isMetadataLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body">{t("FAXES.DOWNLOADING_FILES")}</Typography>
        <Loading />
      </Box>
    );
  }

  return (
    <InitSendFaxProviders files={file} initiatedFrom={{ id: extractedToken, from: "sign.plus" }} />
  );
}

export default DocumentToFax;
