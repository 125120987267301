import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Modal, Typography } from "@alohi/kit";

import { List, ListItem, ListItemText } from "ui";
import { loginAfterTwoFaActivation } from "stores/reducers/authentication.reducer";
import { clearActivateTwoFa, clearActivateTwoFaFromEnforced } from "stores/reducers/twoFa.reducer";
import {
  selectPendingAccessToken,
  selectTwoFaRecoveryCodes,
} from "selectors/authentication.selector";

const ActivateTwoFaRecoveryCodesStepModal = ({ handleClosure }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const pendingAccessToken = useSelector(selectPendingAccessToken);
  const recoveryCodes = useSelector(selectTwoFaRecoveryCodes);

  const handleConfirm = useCallback(() => {
    // Only enforce the login if it's done for corporate owners/members
    if (pendingAccessToken) {
      dispatch(loginAfterTwoFaActivation(pendingAccessToken));
    }
    handleClosure();
  }, [dispatch, handleClosure, pendingAccessToken]);

  useEffect(() => {
    return () => {
      dispatch(clearActivateTwoFa());
      dispatch(clearActivateTwoFaFromEnforced());
    };
  }, [dispatch]);

  return recoveryCodes ? (
    <Modal
      maxWidth="xs"
      onConfirm={handleConfirm}
      confirmTitle={t("FORMS.DONE")}
      title={t("PROFILE.2FA_BACKUP_CODES")}
    >
      <Box p={3}>
        <Typography type="body">{t("PROFILE.2FA_BACKUP_CODES_DESCRIPTION")}</Typography>
        <List dense className={classes.codeList}>
          {recoveryCodes.map((rc) => (
            <ListItem key={rc}>
              <ListItemText primary={rc} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  ) : null;
};

const useStyles = makeStyles(({ spacing, alohi }) => ({
  codeList: {
    columnCount: 2,
    border: "solid 2px",
    marginTop: spacing(2),
    borderColor: alohi.lighterGray,
  },
}));

ActivateTwoFaRecoveryCodesStepModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ActivateTwoFaRecoveryCodesStepModal;
