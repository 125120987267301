import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO } from "config";

const requestSmsVerification = (phoneNumber) => {
  const requestOptions = {
    method: "POST",
    body: {
      phone: phoneNumber,
      method: "sms",
    },
  };
  const path = `${API_AFFOGATO}/contact_verification?for=phone_verification`;
  return fetchWithRefresh(path, requestOptions);
};

const requestCallVerification = (phoneNumber) => {
  const requestOptions = {
    method: "POST",
    body: {
      phone: phoneNumber,
      method: "call",
    },
  };
  const path = `${API_AFFOGATO}/contact_verification?for=phone_verification`;
  return fetchWithRefresh(path, requestOptions);
};

const submitVerificationCode = (verificationCode) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/contact_verification?for=phone_verification&token=${verificationCode}`;
  return fetchWithRefresh(path, requestOptions);
};

export const phoneVerificationApi = {
  requestSmsVerification,
  requestCallVerification,
  submitVerificationCode,
};
