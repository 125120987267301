import { useMemo } from "react";

import FormRowReactSelectInput from "components/Forms/FormRowReactSelectInput";
import { Typography } from "ui";

interface AcceptedValuesSelectProps {
  value: string;
  acceptedValues: Record<string, string>[];
  onSelectValue: (value: string) => void;
  label: string;
  labelGridWidth: number;
}

function AcceptedValuesSelect({
  value,
  acceptedValues,
  onSelectValue,
  label,
  labelGridWidth,
}: AcceptedValuesSelectProps) {
  const selectValue = useMemo(() => {
    if (value) {
      return acceptedValues.find((acceptedValue) => acceptedValue[0] === value);
    }
  }, [acceptedValues, value]);

  const options = useMemo(
    () =>
      acceptedValues.map((acceptedValue) => ({ value: acceptedValue[0], label: acceptedValue[1] })),
    [acceptedValues],
  );

  const handleSelectValue = ({ value }: { value: string }) => {
    onSelectValue(value);
  };

  return (
    <FormRowReactSelectInput
      id="select-selected_values-input"
      label={label}
      labelGridWidth={labelGridWidth}
      ReactSelectProps={{
        value: selectValue,
        isSearchable: true,
        maxMenuHeight: 250,
        options: options,
        onChange: handleSelectValue,
        components: {
          SingleValue: () => <Typography>{selectValue?.[1] ?? ""}</Typography>,
        },
      }}
    />
  );
}

export default AcceptedValuesSelect;
