import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { Box, Modal, Typography, STATUS_BOX_ENUM, StatusBox, useSnackbar } from "@alohi/kit";

import { API_STORAGE } from "config";
import { Link, makeStyles } from "ui";
import { contactsAllowedFileTypes } from "enums/allowedFileTypes";
import DragAndDropOverlay from "components/DragAndDrop/DragAndDropOverlay";
import { getWhitelistContacts } from "stores/reducers/contacts.whitelist.reducer";
import {
  selectIsContactsFilesError,
  selectUploadedContactsFiles,
  selectIsContactsFilesRunning,
  ContactsErrors,
} from "selectors/storage.selector";
import {
  uploadContactsDocuments,
  clearUploadContactsDocuments,
} from "stores/reducers/storage.reducer";

import CandidateContactsModal from "./CandidateContactsModal";

const LINK_HREF_EXCEL_SAMPLE = `${API_STORAGE}/storage/common/faxplus_sample_contact_import.xlsx`;

function ImportContactsModal({ handleClosure }: { handleClosure: () => void }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const contactsFilesError = useSelector(selectIsContactsFilesError);
  const isLoading = useSelector(selectIsContactsFilesRunning);
  const uploadedContactsData = useSelector(selectUploadedContactsFiles);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (files) => {
      if (!files.length) {
        return;
      }
      dispatch(uploadContactsDocuments(files[0]));
    },
    noClick: true,
  });

  useEffect(() => {
    return () => {
      dispatch(clearUploadContactsDocuments());
      dispatch(getWhitelistContacts({ offset: 0 }));
    };
  }, [dispatch]);

  useEffect(() => {
    if (contactsFilesError) {
      dispatch(clearUploadContactsDocuments());
      enqueueSnackbar(contactsFilesError, { variant: "error" });
    } else if (uploadedContactsData.error) {
      switch (uploadedContactsData.error) {
        case ContactsErrors.LIST_EMPTY:
          enqueueSnackbar(t("CONTACTS.IMPORT_NO_VALID_LINE"), {
            variant: "error",
          });
          dispatch(clearUploadContactsDocuments());
          break;
        case ContactsErrors.UNHEALTHY_DATA:
          enqueueSnackbar(t("CONTACTS.IMPORT_UNHEALTHY_DATA"), {
            variant: "error",
          });
          break;
        case ContactsErrors.UNKOWN_ERROR:
        default:
          enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
          dispatch(clearUploadContactsDocuments());
          break;
      }
    }
  }, [enqueueSnackbar, t, contactsFilesError, dispatch, uploadedContactsData.error, handleClosure]);

  if (Boolean(uploadedContactsData.list.length)) {
    return (
      <CandidateContactsModal contactsData={uploadedContactsData} handleClosure={handleClosure} />
    );
  }

  return (
    <Modal
      onConfirm={open}
      onCancel={handleClosure}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      data-cy={"ImportContactsModal"}
      title={t("CONTACTS.IMPORT_EXCEL")}
      confirmTitle={t("CONTACTS.IMPORT")}
    >
      <Box p={3} {...getRootProps()}>
        <input
          data-cy={"ImportContactsModalInput"}
          {...getInputProps()}
          accept={contactsAllowedFileTypes.join(",")}
        />
        {isDragActive && <DragAndDropOverlay />}

        <Typography type="body" onClick={(event) => event.stopPropagation()}>
          {t("CONTACTS.DOWNLOAD_SAMPLE")}{" "}
          <Link rel="noopener" href={LINK_HREF_EXCEL_SAMPLE}>
            {t("CONTACTS.EXCEL_SAMPLE")}
          </Link>
          {"."}
        </Typography>

        <Box onClick={(event) => event.stopPropagation()}>
          <Typography pt={2} type="body">
            {t("CONTACTS.THEN_FILL")}
          </Typography>
          <Typography pt={2} type="body">
            {t("CONTACTS.PLEASE_ENSURE_THAT")}
          </Typography>
          <Box p={2}>
            <Box display={"flex"} alignContent={"center"}>
              <FontAwesomeIcon icon={faCheckCircle} size="lg" className={classes.icon} />
              <Typography type="body">
                <Trans
                  i18nKey="CONTACTS.LOAD_FILE_HELP_FIRST"
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </Box>
            <Box pt={1} display={"flex"} alignContent={"center"}>
              <FontAwesomeIcon icon={faCheckCircle} size="lg" className={classes.icon} />
              <Typography type="body">
                <Trans
                  i18nKey="CONTACTS.LOAD_FILE_HELP_SECOND"
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </Box>

            <Box pt={1} display={"flex"} alignContent={"center"}>
              <FontAwesomeIcon icon={faCheckCircle} size="lg" className={classes.icon} />
              <Typography type="body">
                <Trans
                  i18nKey="CONTACTS.LOAD_FILE_HELP_THIRD"
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </Box>

            <Box pt={1} display={"flex"} alignContent={"center"}>
              <FontAwesomeIcon icon={faCheckCircle} size="lg" className={classes.icon} />
              <Typography type="body">
                <Trans
                  i18nKey="CONTACTS.LOAD_FILE_HELP_FOURTH"
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </Box>
          </Box>

          <StatusBox animation={{ skip: true }} type={STATUS_BOX_ENUM.INFOS} mt={1}>
            <Typography type="bold">{t("CONTACTS.COMPLETE_DESCRIPTION")}</Typography>
            <ul className={classes.furtherDetailsContainer}>
              <li>
                <Typography type="body">{t("CONTACTS.COMPLETE_DESCRIPTION_FIRST")}</Typography>
              </li>
              <li>
                <Typography type="body">{t("CONTACTS.COMPLETE_DESCRIPTION_SECOND")}</Typography>
              </li>
              <li>
                <Typography type="body">{t("CONTACTS.COMPLETE_DESCRIPTION_THIRD")}</Typography>
              </li>
              <li>
                <Typography type="body">{t("CONTACTS.COMPLETE_DESCRIPTION_FOURTH")}</Typography>
              </li>
            </ul>
          </StatusBox>
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  icon: {
    color: alohi.green,
    marginRight: spacing(0.5),
  },
  furtherDetailsContainer: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
    paddingLeft: spacing(3),
  },
}));

export default ImportContactsModal;
