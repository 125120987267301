import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Modal, Typography, makeStyles } from "@alohi/kit";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { websiteUrls } from "routes/urls";
import { clearNextPlan } from "stores/reducers/plans.reducer";
import { selectIsCorporateUpgrade } from "selectors/common.selector";
import { clearAddressRequirements } from "stores/reducers/address.reducer";
import { clearChargeAmount } from "stores/reducers/payment.charge.reducer";
import { selectSelectedNumberSource } from "selectors/verifications.selector";
import ManagePaymentMethodModal from "views/ManagePaymentMethod/ManagePaymentMethodModal";
import {
  selectChargeAmountError,
  selectIsChargeAmountError,
  selectIsNotHonoredPayment,
  selectSelectedPaymentType,
  selectIsNumberNotAvailable,
  selectCorporateUpgradeState,
  selectIsNumberBundleNotFound,
} from "selectors/payment.selector";
import {
  clearFirstCorporateNumber,
  clearSecondCorporateNumber,
} from "stores/reducers/payment.corporate.reducer";
import {
  clearVerifyNumber,
  clearVerifyCustomNumber,
  clearAssignBundleToNumber,
} from "stores/reducers/verifications.reducer";

import SwitchPaymentFooter from "./SwitchPaymentFooter";

function ChargeError({ handleClosure }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const numberSource = useSelector(selectSelectedNumberSource);
  const chargeAmountError = useSelector(selectChargeAmountError);
  const isChargeAmountError = useSelector(selectIsChargeAmountError);
  const isNumberNotAvailable = useSelector(selectIsNumberNotAvailable);
  const isNotHonoredPayment = useSelector(selectIsNotHonoredPayment);
  const isNumberBundleNotFound = useSelector(selectIsNumberBundleNotFound);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentType);

  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);
  const { firstNumber, secondNumber } = useSelector(selectCorporateUpgradeState);

  const [isManagePaymentMethodModalOpened, isManagePaymentMethodModalOpenedBool] = useBool(false);
  const [hasSwitchPaymentFooter, hasSwitchPaymentFooterBool] = useBool(false);
  const [errorText, setErrorText] = useState("");
  const [secondErrorText, setSecondErrorText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState(t("ADD_CREDIT.PAYMENT_FAILED"));
  const [confirmTitle, setConfirmTitle] = useState(t("FORMS.DONE"));
  const [handleConfirm, setHandleConfirm] = useState(() => {
    return () => undefined;
  });
  const [newPaymentMethodTab, setNewPaymentMethodTab] = useState("");

  const handleSwitchPaymentMethod = (type) => {
    isManagePaymentMethodModalOpenedBool.setTrue();
    if (type !== "list") {
      setNewPaymentMethodTab(type);
    }
  };

  useEffect(() => {
    if (isChargeAmountError) {
      if (isNumberNotAvailable || isNumberBundleNotFound) {
        if (isNumberBundleNotFound) {
          setConfirmTitle(t("FORMS.DONE"));
          setTitle(t("ADD_CREDIT.PAYMENT_FAILED"));
          setErrorText(t("ADDRESS.BUNDLE_ERROR_DESCRIPTION"));
          setSecondErrorText("");
          setHandleConfirm(() => {
            return () => handleClosure(false);
          });
        } else {
          setConfirmTitle(
            !!numberSource ? t("COMMON.SEARCH_AGAIN_ONE") : t("COMMON.SEARCH_AGAIN_TWO"),
          );
          setTitle(t("ADD_CREDIT.PAYMENT_FAILED"));
          setErrorText(
            !!numberSource
              ? t("ADD_NUMBER.RETRY", { number: numberSource })
              : t("ADD_NUMBER.RETRY_UNKNOWN_NUMBER"),
          );
          setSecondErrorText("");
          setHandleConfirm(() => {
            return () => handleClosure(true);
          });
        }

        setOpenModal(true);

        dispatch(clearVerifyNumber());
        dispatch(clearVerifyCustomNumber());
        dispatch(clearAssignBundleToNumber());
        dispatch(clearAddressRequirements());
        dispatch(clearChargeAmount());

        if (isCorporateUpgrade) {
          if (firstNumber) {
            dispatch(clearFirstCorporateNumber());
          }
          if (secondNumber) {
            dispatch(clearSecondCorporateNumber());
          }
        }

        return; // Don't close whatever modal (ChangePlan/ChangeNumber, etc) is open yet.
      }

      if (isNotHonoredPayment) {
        setOpenModal(true);

        setConfirmTitle(t("TITLES.RETRY"));
        setTitle(t("ADD_CREDIT.PAYMENT_DECLINED"));
        if (selectedPaymentMethod === "paypal") {
          setErrorText(t("ERRORS.PAYPAL_PAYMENT_DECLINED"));
          setHandleConfirm(() => {
            return () => isManagePaymentMethodModalOpenedBool.setTrue();
          });
        } else {
          setErrorText(t("ERRORS.DO_NOT_HONOR_1"));
          setSecondErrorText(t("ERRORS.DO_NOT_HONOR_2"));
          setHandleConfirm(() => {
            return () => isManagePaymentMethodModalOpenedBool.setTrue();
          });
        }
        hasSwitchPaymentFooterBool.setTrue();
        dispatch(clearNextPlan());
        dispatch(clearVerifyNumber());
        dispatch(clearVerifyCustomNumber());
        dispatch(clearAssignBundleToNumber());
        dispatch(clearAddressRequirements());
        dispatch(clearChargeAmount());

        return; // Don't close whatever modal (ChangePlan/ChangeNumber, etc) is open yet.
      }

      if (chargeAmountError) {
        if (chargeAmountError === "purchase_error") {
          setErrorText(
            <Trans
              i18nKey={"CHARGE.PURCHASE_ERROR"}
              components={{
                mail: <Link href={`mailto:${websiteUrls.supportEmail}`} />,
              }}
            />,
          );
          setSecondErrorText("");
        } else {
          setErrorText(chargeAmountError);
          setSecondErrorText("");
        }
        setOpenModal(true);
        dispatch(clearChargeAmount());
        setConfirmTitle(t("FORMS.DONE"));
        setTitle(t("ADD_CREDIT.PAYMENT_FAILED"));
        setHandleConfirm(() => () => handleClosure(true));
      }
    }
  }, [
    t,
    dispatch,
    firstNumber,
    secondNumber,
    numberSource,
    handleClosure,
    chargeAmountError,
    isCorporateUpgrade,
    isChargeAmountError,
    isNotHonoredPayment,
    isNumberNotAvailable,
    selectedPaymentMethod,
    isNumberBundleNotFound,
    hasSwitchPaymentFooterBool,
    isManagePaymentMethodModalOpenedBool,
  ]);

  if (!openModal) {
    return null;
  }

  return (
    <Modal
      title={title}
      hasCloseIcon={false}
      onConfirm={handleConfirm}
      confirmTitle={confirmTitle}
      customFooterInfo={
        hasSwitchPaymentFooter ? (
          <SwitchPaymentFooter onOpenPaymentMethod={handleSwitchPaymentMethod} />
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <FontAwesomeIcon className={classes.icon} size="6x" icon={faExclamationCircle} />
        <Typography variant="body" align="center">
          {errorText}
        </Typography>
        {secondErrorText ? (
          <Typography className={classes.secondText} variant="body" align="center">
            {secondErrorText}
          </Typography>
        ) : null}
      </Box>

      {isManagePaymentMethodModalOpened ? (
        <ManagePaymentMethodModal
          initialTab={newPaymentMethodTab}
          handleClosure={() => handleClosure(true)}
        />
      ) : null}
    </Modal>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  wrapper: {
    display: "flex",
    margin: spacing(2),
    alignItems: "center",
    alignContent: "start",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    fontSize: "6rem",
    color: alohi.gold,
    marginBottom: spacing(2),
  },
  secondText: {
    margin: spacing(2, 0, 1, 0),
  },
}));

ChargeError.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ChargeError;
