import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import useCart from "hooks/useCart";
import Currency from "components/Core/Currency/Currency";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import { capitalizeString, durationMap } from "helpers/string";
import {
  selectVerifyNumber,
  selectVerifyCustomNumber,
  selectIsBuyingBulkNumbers,
  selectBulkNumberMonthlyFee,
  selectBulkNumbersNumOfMonths,
} from "selectors/verifications.selector";

function CartTooltip() {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedNumber = useSelector(selectVerifyNumber);
  const selectedCustomNumber = useSelector(selectVerifyCustomNumber);
  const isBuyingBulkNumbers = useSelector(selectIsBuyingBulkNumbers);
  const bulkNumberMonthlyFee = useSelector(selectBulkNumberMonthlyFee);
  const bulkNumberNumOfMonths = useSelector(selectBulkNumbersNumOfMonths);

  const [{ cartItems }] = useCart(false);

  const cartItemsNodes = useMemo(() => {
    if (!cartItems) {
      return null;
    }

    const nodes = [];
    const items = Object.entries(cartItems);

    for (let i = 0; i < items.length; i++) {
      switch (items[i][0]) {
        case "plan":
          const planItems = items[i][1].slice();
          while (planItems.length) {
            const node = planItems.shift();
            nodes.push(
              <Box key={node.id} my={0.25}>
                {`${capitalizeString(node.item.plan_type)} ${durationMap(
                  node.item.duration_unit,
                )}: `}
                <Currency
                  decimalScale={2}
                  fixedDecimalScale
                  value={node.price}
                  currency={node.currency}
                />
              </Box>,
            );
          }
          break;
        case "custom":
          const customItems = items[i][1].slice();
          while (customItems.length) {
            const node = customItems.shift();
            // Don't display what is already assigned
            if (node.availability === "assigned") {
              break;
            }
            const isCorporateCart = node?.item_type === "number-corporate";
            const numberOfMonths = (selectedNumber || selectedCustomNumber)?.number_of_months ?? 1;

            if (isCorporateCart) {
              const monthlyFee =
                (selectedNumber || selectedCustomNumber)?.monthly_fee / numberOfMonths;

              nodes.push(
                <Box key={node.id + numberOfMonths} my={0.25}>
                  {t("CHANGE_PLAN.RANDOM_FAX_NUMBER_PER_MONTH")}
                  <Currency
                    decimalScale={2}
                    fixedDecimalScale
                    value={monthlyFee}
                    currency={node.currency}
                  />
                  {t("CHANGE_PLAN.MONTH_SUM", { count: numberOfMonths })}
                </Box>,
              );
              nodes.push(
                <Box key={node.id} my={0.25}>
                  {t("CHANGE_PLAN.CUSTOM_FAX_NUMBER")}
                  <Currency
                    decimalScale={2}
                    fixedDecimalScale
                    value={node.price}
                    currency={node.currency}
                  />
                </Box>,
              );
              break;
            }
            nodes.push(
              <Box key={node.id} my={0.25}>
                {t("CHANGE_PLAN.CUSTOM_FAX_NUMBER")}
                <Currency
                  decimalScale={2}
                  fixedDecimalScale
                  value={node.price}
                  currency={node.currency}
                />
              </Box>,
            );
          }
          break;
        case "random":
          const randomItems = items[i][1].slice();
          while (randomItems.length) {
            const node = randomItems.shift();
            // Don't display what is already assigned
            if (node.availability === "assigned") {
              break;
            }
            const isCorporateNumberCart = node?.item_type === "number-corporate";
            const isCorporateBulkNumberCart = node?.item_type === "number-corporate-bulk";

            if (isCorporateNumberCart || isCorporateBulkNumberCart) {
              const numberOfMonths = isBuyingBulkNumbers
                ? bulkNumberNumOfMonths
                : (selectedNumber || selectedCustomNumber)?.number_of_months;

              if (!numberOfMonths) {
                break;
              }

              const monthlyFee = isBuyingBulkNumbers
                ? bulkNumberMonthlyFee / numberOfMonths
                : (selectedNumber || selectedCustomNumber)?.monthly_fee / numberOfMonths;

              nodes.push(
                <Box key={node.id + numberOfMonths} my={0.25}>
                  {t("CHANGE_PLAN.RANDOM_FAX_NUMBER_PER_MONTH")}
                  <Currency
                    decimalScale={2}
                    fixedDecimalScale
                    value={monthlyFee}
                    currency={node.currency}
                  />
                  {t("CHANGE_PLAN.MONTH_SUM", { count: numberOfMonths })}
                </Box>,
              );
              nodes.push(
                <Box key={node.id} my={0.25}>
                  {t("CHANGE_PLAN.RANDOM_FAX_NUMBER")}
                  <Currency
                    decimalScale={2}
                    fixedDecimalScale
                    value={node.price}
                    currency={node.currency}
                  />
                </Box>,
              );
              break;
            }

            nodes.push(
              <Box key={node.id} my={0.25}>
                {t("CHANGE_PLAN.RANDOM_FAX_NUMBER")}
                <Currency
                  decimalScale={2}
                  fixedDecimalScale
                  value={node.price}
                  currency={node.currency}
                />
              </Box>,
            );
          }
          break;
        default:
          break;
      }
    }

    return nodes;
  }, [
    t,
    cartItems,
    selectedNumber,
    isBuyingBulkNumbers,
    selectedCustomNumber,
    bulkNumberMonthlyFee,
    bulkNumberNumOfMonths,
  ]);

  if (!cartItems) {
    return null;
  }

  return (
    <HelpTooltip
      placement="top-start"
      iconClassName={classes.icon}
      title={<Box className={classes.tooltip}>{cartItemsNodes}</Box>}
    />
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  tooltip: {
    flex: 1,
    display: "flex",
    minWidth: "225px",
    margin: spacing(0.5),
    flexDirection: "column",
  },
  icon: {
    alignSelf: "center",
    justifySelf: "center",
    marginLeft: spacing(0.5),
  },
  li: {
    marginLeft: spacing(2),
  },
}));

export default memo(CartTooltip);
