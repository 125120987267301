import SignInPanel from "components/Profile/Security/SignInPanel";
import CompanyWideSettingsPanel from "components/Profile/Security/CompanyWideSettingsPanel";

function Security() {
  return (
    <>
      <SignInPanel />
      <CompanyWideSettingsPanel />
    </>
  );
}

export default Security;
