// Also matching the last URL slice for "Faxes" (fax.plus/faxes/inbox)
export const boxNames = {
  inbox: "inbox",
  sentbox: "sent",
  outbox: "outbox",
  trashbox: "trash",
};

export const cdrStatuses = {
  submitted: "submitted",
  converting: "converting",
  converted: "converted",
  scheduledForSending: "scheduled_for_sending",
  sending: "sending",
  insufficientCredit: "insufficient_credit",
};
