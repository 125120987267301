import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { selectAccountEmail, selectAccountUid } from "selectors/account.selector";
import { selectCurrentPlan, selectPlanPeriod } from "selectors/plan.selector";

export enum CrossSellType {
  faxToSign = "fax_to_sign",
  faxToScan = "fax_to_scan",
}

export enum IncentiveSignPlusUpgradeAction {
  openModal = "open_modal",
  activateTrial = "activate_trial",
  showTrialPlans = "show_trial_plans",
  claimDiscount = "claim_discount",
  showPlans = "show_plans",
}

export enum GoogleAnalyticsCustomEvents {
  cartInitalization = "old_cart_initialization",
  cartPurchaseSuccess = "old_cart_purchase_success",
  cartPurchaseFailed = "old_cart_purchase_failed",
  signUp = "sign_up",
  planCancellation = "plan_cancellation",
  phoneVerification = "phone_verification",
  emailVerification = "email_verification",
  faxSubmission = "fax_submission",
  reactivateSubscription = "reactivate_subscription",
  firstTransaction = "first_transaction",
  uploadIframe = "fax_upload_iframe",
  incentiveSignPlusUpgrade = "incentive_signplus_upgrade",
  navbarLogo = "navbar_logo",
  navbarNavigation = "navbar_navigation",
  navbarProfileDropdown = "navbar_profile_dropdown",
  navbarAppSwitcher = "navbar_app_switcher",
}

type GtmDataLayerProduct = "fax" | "sign";
type GtmDataLayerOrigin = "fax" | "sign" | "admin";

export interface GtmDataLayerFaxSubscription {
  planType: string;
  period: string;
  highVolumePages: number | undefined;
}

export interface GtmDataLayer {
  user: {
    id: string;
    isInternalUser: boolean;
    subscriptions: {
      fax: GtmDataLayerFaxSubscription | undefined;
    };
  };
  context: {
    origin: GtmDataLayerOrigin;
    product: GtmDataLayerProduct;
    page?: string;
  };
}

export interface GtmDataLayerCart {
  faxPlan?: Partial<GtmDataLayerFaxSubscription> & {
    customCount?: number;
  };
  faxNumbers?: {
    totalCount?: number;
    customCount?: number;
  };
  faxExtraNumbers?: {
    totalCount?: number;
    customCount?: number;
  };
  totalPrice: number | undefined;
}

const checkIfUserIsInternal = (email: string): boolean => {
  return email.includes("@alohi.com");
};

export const useGoogleTagManager = () => {
  const uid = useSelector(selectAccountUid);
  const email = useSelector(selectAccountEmail);
  const currentPlan = useSelector(selectCurrentPlan);
  const currenPlanPeriod = useSelector(selectPlanPeriod);
  const pages = (currentPlan?.total_pages as number) ?? 0;

  const buildDataLayer = (params: {
    product: GtmDataLayerProduct;
    page?: string;
  }): GtmDataLayer => {
    const user = {
      id: uid,
      email,
    };

    return {
      user: {
        id: user.id,
        isInternalUser: checkIfUserIsInternal(user.email ?? ""),
        subscriptions: {
          fax:
            currentPlan && currenPlanPeriod
              ? {
                  planType: (currentPlan.plan_type as string).toLocaleUpperCase(),
                  period: currenPlanPeriod,
                  highVolumePages: pages,
                }
              : undefined,
        },
      },
      context: {
        origin: "fax",
        product: params.product,
        page: params.page,
      },
    };
  };

  const sendCustomEvent = (params: {
    event: string;
    page?: string;
    cart?: GtmDataLayerCart;
    extraData?: Record<string, string | number | boolean | undefined | null>;
  }) => {
    // First, reset the GTM dataLayer to prevent accumulation
    TagManager.dataLayer({
      dataLayer: {
        event: "reset_data_layer",
        context: undefined,
        user: undefined,
        cart: undefined,
        custom: undefined,
      },
    });
    const baseDataLayer = buildDataLayer({ ...params, product: "sign" });
    TagManager.dataLayer({
      dataLayer: {
        ...(params.cart ? { cart: params.cart } : undefined),
        custom: { ...(params.extraData ?? {}) },
        ...baseDataLayer,
        event: params.event,
      },
    });
  };

  return {
    buildDataLayer,
    sendCustomEvent,
  };
};
