import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { paymentTypes } from "../../../enums/payment";
import { getDefaultPaymentMethod } from "../../../stores/reducers/payment.methods.reducer";
import {
  selectDefaultPaymentMethod,
  selectDefaultPaymentMethodText,
  selectIsDefaultPaymentMethodError,
} from "../../../selectors/payment.selector";

function PreviewDefaultPayment() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isError = useSelector(selectIsDefaultPaymentMethodError);
  const previewText = useSelector(selectDefaultPaymentMethodText);
  const defaultPaymentMethod = useSelector(selectDefaultPaymentMethod);

  useEffect(() => {
    if (!defaultPaymentMethod) {
      dispatch(getDefaultPaymentMethod());
    }
  }, [defaultPaymentMethod, dispatch]);

  if (isError) {
    return <>{t("COMMON.NO_AVAILABLE")}</>;
  }

  if (defaultPaymentMethod === paymentTypes.PAYPAL) {
    return <>{`PayPal (${previewText})`}</>;
  }

  if (defaultPaymentMethod === paymentTypes.STRIPE) {
    return <>{`**** **** **** ${previewText}`}</>;
  }

  return null;
}

export default PreviewDefaultPayment;
