import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography, makeStyles } from "@alohi/kit";

import useCart from "hooks/useCart";
import Currency from "components/Core/Currency/Currency";
import { selectIsCorporateUpgrade } from "selectors/common.selector";
import { selectRefundId, selectCorporateUpgradeState } from "selectors/payment.selector";
import { selectIsBundleRequiredToBeCreated, selectNextPlan } from "selectors/plan.selector";
import { selectCurrentCredit, selectCurrentCreditCurrency } from "selectors/credit.selector";
import CartTooltip from "../CartTooltip";

function ChangePlanFromPaidFooterInfo() {
  const classes = useStyles();
  const { t } = useTranslation();

  const nextPlan = useSelector(selectNextPlan);
  const refundId = useSelector(selectRefundId);
  const credit = useSelector(selectCurrentCredit);
  const creditCurrency = useSelector(selectCurrentCreditCurrency);
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);
  const corporateUpgrade = useSelector(selectCorporateUpgradeState);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);

  const [{ cartTotalAmount }, { addIdToCart, updateCart }] = useCart(false);

  useEffect(() => {
    if (nextPlan?.purchase_id && refundId) {
      addIdToCart(`plan_${nextPlan.purchase_id}`);
      addIdToCart(refundId);
      if (isCorporateUpgrade && corporateUpgrade.secondNumber?.id) {
        // Send one id from selectedNumber to complete the corporate upgrade
        addIdToCart(corporateUpgrade.secondNumber.id);
      }
      updateCart();
    }
  }, [
    refundId,
    updateCart,
    addIdToCart,
    isCorporateUpgrade,
    nextPlan?.purchase_id,
    corporateUpgrade.secondNumber?.id,
  ]);

  if (isBundleRequiredToBeCreated) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flex="1">
          {cartTotalAmount >= 0 ? (
            <Box display="flex" flexDirection="row">
              <Typography>{t("CHANGE_PLAN.CREDIT_NEEDED_FOR_CHANGE")}</Typography>
              <CartTooltip />
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              <Typography>{t("CHANGE_PLAN.AMOUNT_ADDED_FOR_CHANGE")}</Typography>
              <CartTooltip />
            </Box>
          )}
        </Box>
        <Typography component="div" type="bold" style={{ textAlign: "end", minWidth: "100px" }}>
          {cartTotalAmount !== null && creditCurrency ? (
            <Currency value={Math.abs(cartTotalAmount)} currency={creditCurrency} />
          ) : null}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flex="1">
          <Typography> {t("ADD_NUMBER.YOUR_CURRENT_CREDIT")}</Typography>
        </Box>
        <div style={{ textAlign: "end", minWidth: "100px" }}>
          {credit !== null && creditCurrency ? (
            <Typography type="bold" className={classes.credit}>
              <Currency value={credit} currency={creditCurrency} />
            </Typography>
          ) : null}
        </div>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  root: {
    flex: 1,
    display: "flex",
    borderRadius: "8px",
    padding: spacing(3),
    flexDirection: "column",
    backgroundColor: alohi.neutral100,
    margin: spacing(3, 3, 0, 3),
    border: `1px solid ${alohi.neutral200}`,
  },
  divider: {
    margin: spacing(1.5, 0),
  },
  credit: {
    color: alohi.green,
  },
}));

export default ChangePlanFromPaidFooterInfo;
