import { Box } from "@alohi/kit";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useTitle from "hooks/useTitle";
import { corporateApi } from "api/index";
import SignUpForm from "components/Authentication/SignUpForm";
import CommonLayout from "components/Authentication/CommonLayout";

function SignUpComponent() {
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const [emailFromToken, setEmailFromToken] = useState(null);
  const [emailLinkIsExpired, setEmailLinkIsExpired] = useState(false);

  const { t } = useTranslation();
  useTitle(t("SIGNUP.INTRO"));

  useEffect(() => {
    token &&
      corporateApi
        .getEmailByToken(token)
        .then(({ email }) => {
          setEmailFromToken(email);
        })
        .catch((err) => {
          err.reason === "token_not_valid" && setEmailLinkIsExpired(true);
        });
  }, [token]);

  return (
    <CommonLayout>
      {!token ? (
        <SignUpForm />
      ) : emailFromToken ? (
        <SignUpForm email={emailFromToken} token={typeof token === "string" ? token : token[0]} />
      ) : emailLinkIsExpired ? (
        <Box component="h3" sx={{ textAlign: "center", mt: 4 }}>
          {t("REFERRAL.TOKEN_EXPIRED")}
        </Box>
      ) : null}
    </CommonLayout>
  );
}

export default SignUpComponent;
