export const plans = {
  free: "free",
  basic: "basic",
  premium: "premium",
  business: "business",
  enterprise: "enterprise",
};

export const possibleNextPlan = {
  free: "basic",
  basic: "premium",
  premium: "business",
  business: "enterprise",
  enterprise: "business",
};

export const planDurations = {
  annual: "annual",
  month: "month",
};
