import { Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import { Contact } from "stores/reducers/contacts.helpers";
import { PhoneNumberFilter } from "components/Core/Filters";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import EmptyContacts from "components/InfiniTable/EmptyContacts";
import {
  Table,
  Typography,
  makeStyles,
  TableColumnsProp,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "ui";
import {
  selectBlacklistContacts,
  selectBlacklistHasMoreContacts,
  selectBlacklistContactsSelection,
  selectIsBlacklistContactsLoading,
} from "selectors/contacts.selector";
import {
  getBlacklistContacts,
  getMoreBlacklistContacts,
  updateBlacklistContactsSelection,
} from "stores/reducers/contacts.blacklist.reducer";
import AddOrEditBlacklistContactModal from "views/Contacts/AddOrEditBlacklistContactModal";
import ConfirmDeleteBlacklistModal from "./ConfirmDeleteBlacklistModal";

interface ModalState {
  isOpen: boolean;
  contactId: null | string;
}

interface BlacklistProps {
  noBorder: boolean;
  hasActions: boolean;
  areTabsClickable?: boolean;
}

function Blacklist({ noBorder, hasActions, areTabsClickable }: BlacklistProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const hasMore = useAppSelector(selectBlacklistHasMoreContacts);
  const isLoading = useAppSelector<boolean>(selectIsBlacklistContactsLoading);
  const selection = useAppSelector<string[]>(selectBlacklistContactsSelection);
  const contactList = useAppSelector<null | Contact[]>(selectBlacklistContacts);

  const [editModal, setEditModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });
  const [deleteModal, setDeleteModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });

  const rows = useMemo(() => contactList ?? [], [contactList]);

  const actions = useMemo(() => {
    return (index: number) =>
      rows.map((row) => [
        {
          label: t("COMMON.EDIT"),
          callback: () => {
            setEditModal({
              isOpen: true,
              contactId: row.id,
            });
          },
        },
        {
          label: t("COMMON.DELETE"),
          callback: () => {
            setDeleteModal({
              isOpen: true,
              contactId: row.id,
            });
          },
        },
      ])[index];
  }, [rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    columns.push({
      width: 16,
      minWidth: 16,
      headerName: " ",
      sortable: false,
      field: "id",
      renderCell: function render() {
        return <Box> </Box>;
      },
    });

    columns.push({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.05,
    });

    columns.push({
      width: 15,
      minWidth: 15,
      headerName: " ",
      sortable: false,
      field: "index",
      renderCell: function render() {
        return <Box> </Box>;
      },
    });

    columns.push({
      flex: 0.25,
      sortable: false,
      field: "fullName",
      headerName: t("CONTACTS.NAME"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.fullName}
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "faxNumber",
      headerName: t("CONTACTS.FAX_NUMBER"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Typography className={classes.row} stopPropagation>
            <PhoneNumberFilter number={row.faxNumber} />
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.255,
      field: "note",
      sortable: false,
      headerName: t("CONTACTS.NOTE"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.note}
          </Typography>
        );
      },
    });

    if (hasActions) {
      columns.push({
        flex: 0.0925,
        sortable: false,
        field: "actions",
        headerName: " ",
        renderCell: function render({ row }: { row: Contact }) {
          return <ColumnActions dataIndex={row.index} createActions={actions} />;
        },
      });
    }

    return columns;
  }, [actions, classes.row, hasActions, t]);

  const onWaypoint = useMemo(() => {
    if (!hasMore) {
      return undefined;
    }

    return () => {
      dispatch(getMoreBlacklistContacts());
    };
  }, [dispatch, hasMore]);

  useEffect(() => {
    if (!contactList) {
      dispatch(getBlacklistContacts({ offset: 0 }));
    }
  }, [dispatch, contactList]);

  return (
    <>
      <Table
        rows={rows}
        rowHeight={76}
        columns={columns}
        checkboxSelection
        noBorder={noBorder}
        isLoading={isLoading}
        onWaypoint={onWaypoint}
        className={classes.root}
        selectionModel={selection}
        disableSelectionOnClick={!areTabsClickable}
        onSelectionModelChange={(selection) =>
          dispatch(updateBlacklistContactsSelection(selection as string[]))
        }
        components={{
          NoRowsOverlay: function render() {
            return <EmptyContacts text={t("CONTACTS.NO_CONTACTS_HERE_YET")} />;
          },
        }}
      />

      {editModal.isOpen && editModal.contactId ? (
        <AddOrEditBlacklistContactModal
          contactToEditId={editModal.contactId}
          handleClosure={() => setEditModal({ isOpen: false, contactId: null })}
        />
      ) : null}

      {deleteModal.isOpen && deleteModal.contactId ? (
        <ConfirmDeleteBlacklistModal
          contactIds={[deleteModal.contactId]}
          onCancel={() => setDeleteModal({ isOpen: false, contactId: null })}
          onConfirm={() => setDeleteModal({ isOpen: false, contactId: null })}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "&& .MuiDataGrid-columnHeader": {
      padding: spacing(0, 1),
    },
    "&& .MuiDataGrid-columnHeader:nth-child(2)": {
      paddingLeft: spacing(0.5),
    },
    "&&& .MuiDataGrid-cell": {
      overflowY: "auto",
      padding: spacing(2, 1),
    },
    "&&&& .MuiDataGrid-row > div:first-child": {
      padding: 0,
    },
  },
  row: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "text",
  },
}));

export default Blacklist;
