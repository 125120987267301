import { createPath } from "history";
import { ForwardedRef, forwardRef } from "react";
import {
  useRouteMatch,
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";

export type { ReactRouterLinkProps as LinkProps };

const Link = forwardRef(
  ({ to, replace, ...rest }: ReactRouterLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    let destinationPath: string;
    switch (typeof to) {
      case "string":
        destinationPath = to;
        break;
      case "object":
        destinationPath = createPath(to);
        break;
      default:
        // We can't evaluate when "to" is provided a function type
        destinationPath = "";
    }
    const isAlreadyMatchingCurrentPath = useRouteMatch(destinationPath);

    return (
      <ReactRouterLink
        draggable={false}
        ref={ref}
        {...rest}
        to={to}
        replace={Boolean(destinationPath) ? Boolean(isAlreadyMatchingCurrentPath) : replace}
      />
    );
  },
);

Link.displayName = "Link";

export default Link;
