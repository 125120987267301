import { Box, Card, Container, useSnackbar } from "@alohi/kit";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";

import FaxesActions from "components/Faxes/FaxesActions";
import FaxesBulkActions from "components/Faxes/FaxesBulkActions";
import FaxesFilters from "components/Faxes/FaxesFilters";
import FaxesHandleQueryString from "components/Faxes/FaxesHandleQueryString";
import FaxesNavigation from "components/Faxes/FaxesNavigation";
import FaxesNumberChooser from "components/Faxes/FaxesNumberChooser";
import FaxViewer from "components/Faxes/FaxViewer";
import Inbox from "components/Faxes/Inbox";
import Outbox from "components/Faxes/Outbox";
import PlanCanceled from "components/Faxes/PlanCanceled";
import PlanInactive from "components/Faxes/PlanInactive";
import Sentbox from "components/Faxes/Sentbox";
import Trashbox from "components/Faxes/Trashbox";
import UpgradeToReceiveFaxes from "components/Faxes/UpgradeToReceiveFaxes";
import { boxNames } from "enums/faxes";
import useBool from "hooks/useBool";
import useTitle from "hooks/useTitle";
import { websiteUrls } from "routes/urls";
import {
  selectAreFiltersApplied,
  selectCdrToken,
  selectFetchBoxError,
  selectFileViewerState,
  selectIdsOfCheckedCdrs,
  selectIsCdrTokenError,
  selectIsDeleteFaxError,
  selectIsDeleteFaxSuccess,
  selectMoveToTrashSuccess,
  selectRestoreSuccess,
} from "selectors/faxes.selector";
import {
  clearCdrToken,
  clearDeleteFax,
  clearMoveToTrash,
  clearRestore,
} from "stores/reducers/faxes.reducer";
import { Helper, Typography, useMediaQuery, useTheme } from "ui";

const Faxes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const {
    params: { boxName = boxNames.inbox },
  } = useRouteMatch<{ boxName: string }>();

  const isCdrTokenError = useSelector(selectIsCdrTokenError);
  const cdrTokenForRedirection = useSelector(selectCdrToken);
  const areFaxesSelected = useSelector(selectIdsOfCheckedCdrs(boxName))?.length > 0;
  const fileViewerState = useSelector(selectFileViewerState);
  const moveToTrashSuccess = useSelector(selectMoveToTrashSuccess);
  const restoreSuccess = useSelector(selectRestoreSuccess);
  const deleteSuccess = useSelector(selectIsDeleteFaxSuccess);
  const deleteError = useSelector(selectIsDeleteFaxError);
  const fetchBoxError = useSelector(selectFetchBoxError);
  const isFiltering = useSelector(selectAreFiltersApplied(boxName));

  const [isShowingFilters, isShowingFiltersBool] = useBool(Boolean(isFiltering));

  const title = useMemo(() => {
    switch (boxName) {
      case boxNames.inbox:
        return `${t("COMMON.FAXES")} - ${t("FAXES.INBOX")}`;
      case boxNames.sentbox:
        return `${t("COMMON.FAXES")} - ${t("FAXES.SENT")}`;
      case boxNames.outbox:
        return `${t("COMMON.FAXES")} - ${t("FAXES.OUTBOX")}`;
      case boxNames.trashbox:
        return `${t("COMMON.FAXES")} - ${t("FAXES.TRASH")}`;
      default:
        return "";
    }
  }, [boxName, t]);

  useTitle(title);

  useEffect(() => {
    if (isFiltering && boxName) {
      isShowingFiltersBool.setTrue();
    }
  }, [boxName, isFiltering, isShowingFiltersBool]);

  useEffect(() => {
    if (moveToTrashSuccess) {
      enqueueSnackbar(t("FAXES.DOCUMENT_MOVED_TO_TRASH", { count: moveToTrashSuccess }), {
        variant: "success",
      });
      dispatch(clearMoveToTrash());
    }
  });

  useEffect(() => {
    if (restoreSuccess) {
      enqueueSnackbar(t("FAXES.DOCUMENT_RESTORED", { count: restoreSuccess }), {
        variant: "success",
      });
      dispatch(clearRestore());
    }
  });

  useEffect(() => {
    if (deleteSuccess) {
      enqueueSnackbar(t("FAXES.DOCUMENT_DELETED"), {
        variant: "success",
      });
      dispatch(clearDeleteFax());
    }
  });

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), {
        variant: "error",
      });
      dispatch(clearDeleteFax());
    }
  });

  useEffect(() => {
    if (fetchBoxError) {
      enqueueSnackbar(fetchBoxError, {
        variant: "error",
      });
      dispatch(clearMoveToTrash());
    }
  }, [dispatch, enqueueSnackbar, fetchBoxError, t]);

  useEffect(() => {
    if (cdrTokenForRedirection) {
      // Redirect to Sign.Plus
      window.location.assign(websiteUrls.cdrRedirection(cdrTokenForRedirection));
    }
  }, [cdrTokenForRedirection]);

  useEffect(() => {
    if (isCdrTokenError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearCdrToken());
    }
  }, [dispatch, enqueueSnackbar, isCdrTokenError, t]);

  return (
    <>
      <FaxesHandleQueryString />

      <Container maxWidth="xs" sx={{ "&&": { pb: 0 } }}>
        <FaxesNumberChooser />
        <UpgradeToReceiveFaxes />
        <PlanInactive />
        <PlanCanceled />
      </Container>
      <Container maxWidth="lg">
        {isMobileLayout ? null : <FaxesNavigation />}

        {isShowingFilters ? (
          <Box sx={{ py: 1 }}>
            <FaxesFilters />
          </Box>
        ) : (
          <Box sx={{ py: 2 }} />
        )}

        <Card>
          <Card.Header
            sx={{
              "&&": {
                bgcolor: areFaxesSelected ? "alohi.almostGray" : "alohi.blue",
              },
            }}
          >
            {areFaxesSelected ? (
              <FaxesBulkActions />
            ) : (
              <>
                {isMobileLayout ? (
                  <Box>
                    <Typography variant="cardFrontHeader">{title}</Typography>
                    {boxName === boxNames.trashbox && (
                      <Helper ml={1} tooltip={t("FAXES.TRASH_COMMENT")} variant="light" size="lg" />
                    )}
                  </Box>
                ) : (
                  <>
                    <Typography variant="cardFrontHeader">{title}</Typography>
                    {boxName === boxNames.trashbox && (
                      <Typography variant="cardFrontHeader" sx={{ "&&": { fontWeight: "normal" } }}>
                        {t("FAXES.TRASH_COMMENT")}
                      </Typography>
                    )}
                  </>
                )}
                <FaxesActions showFilters={isShowingFiltersBool.toggle} />
              </>
            )}
          </Card.Header>

          {
            {
              [boxNames.inbox]: <Inbox />,
              [boxNames.sentbox]: <Sentbox />,
              [boxNames.outbox]: <Outbox />,
              [boxNames.trashbox]: <Trashbox />,
            }[boxName]
          }
        </Card>
      </Container>
      {fileViewerState.isOpen ? <FaxViewer /> : null}
    </>
  );
};

export default Faxes;
