import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar, Modal, Box, Typography } from "@alohi/kit";

import { useAppSelector } from "stores/store";
import SingleShareMessage from "components/Contacts/SingleShareMessage";
import { clearSharedGroups } from "stores/reducers/contacts.sharedGroups.reducer";
import { clearSharedContacts } from "stores/reducers/contacts.sharedContacts.reducer";
import {
  clearShareGroup,
  shareGroup,
  updateGroupSelection,
} from "stores/reducers/contacts.groups.reducer";
import {
  selectShareGroup,
  selectShareGroupError,
  selectShareWhitelistContacts,
  selectShareWhitelistContactsError,
} from "selectors/contacts.selector";
import {
  shareWhitelistContacts,
  clearShareWhitelistContacts,
  updateWhitelistContactsSelection,
} from "stores/reducers/contacts.whitelist.reducer";

interface ShareContactsOrGroupsModalProps {
  isContact: boolean;
  idsToShare: string[];
  handleClosure: () => void;
}

function ShareContactsOrGroupsModal({
  isContact,
  idsToShare,
  handleClosure,
}: ShareContactsOrGroupsModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const sharedGroup = useAppSelector(selectShareGroup);
  const sharedGroupError = useAppSelector(selectShareGroupError);
  const sharedWhitelistContacts = useAppSelector(selectShareWhitelistContacts);
  const sharedWhitelistContactsError = useAppSelector(selectShareWhitelistContactsError);

  function handleConfirmation() {
    if (isContact) {
      dispatch(shareWhitelistContacts({ idsToShare }));
    } else {
      dispatch(shareGroup({ idsToShare }));
    }
  }

  const renderMessage = () => {
    if (idsToShare.length === 1) {
      return <SingleShareMessage idToShare={idsToShare[0]} isContact={isContact} />;
    }

    return t("CONTACTS.QUESTION_SHARE_SELECTED", {
      listName: (isContact ? t("CONTACTS.CONTACTS") : t("CONTACTS.GROUPS")).toLowerCase(),
    });
  };

  useEffect(() => {
    // A contact was shared, inform the user and refetch the shared contacts list
    if (sharedWhitelistContacts) {
      enqueueSnackbar(t("CONTACTS.CONTACT_SHARED_SUCCESS", { count: idsToShare.length }), {
        variant: "success",
      });

      dispatch(clearSharedContacts());
      dispatch(clearShareWhitelistContacts());
      dispatch(updateWhitelistContactsSelection([]));

      handleClosure();
    }
  }, [dispatch, enqueueSnackbar, handleClosure, idsToShare.length, sharedWhitelistContacts, t]);

  useEffect(() => {
    if (sharedWhitelistContactsError) {
      enqueueSnackbar(t("CONTACTS.CANT_SHARE_CONTACT", { count: idsToShare.length }), {
        variant: "error",
      });
      dispatch(clearShareWhitelistContacts());
    }
  }, [dispatch, enqueueSnackbar, idsToShare.length, sharedWhitelistContactsError, t]);

  useEffect(() => {
    // A group was shared, inform the user and refetch the shared group list
    if (sharedGroup) {
      enqueueSnackbar(t("CONTACTS.GROUP_SHARED_SUCCESS", { count: idsToShare.length }), {
        variant: "success",
      });

      dispatch(clearSharedGroups());
      dispatch(clearShareGroup());
      dispatch(updateGroupSelection([]));

      handleClosure();
    }
  }, [dispatch, enqueueSnackbar, handleClosure, idsToShare.length, sharedGroup, t]);

  useEffect(() => {
    if (sharedGroupError) {
      enqueueSnackbar(t("CONTACTS.CANT_SHARE_GROUP", { count: idsToShare.length }), {
        variant: "error",
      });
      dispatch(clearShareGroup());
    }
  }, [dispatch, enqueueSnackbar, idsToShare.length, sharedGroupError, t]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirmation}
      title={!isContact ? t("CONTACTS.SHARE_GROUP") : t("CONTACTS.SHARE_CONTACT")}
    >
      <Box p={3}>
        <Typography type="body" sx={{ wordBreak: "break-word" }}>
          {renderMessage()}
        </Typography>
      </Box>
    </Modal>
  );
}

export default ShareContactsOrGroupsModal;
