import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Divider, makeStyles } from "@alohi/kit";

import { Skeleton } from "ui";
import useCart from "hooks/useCart";
import { useAppSelector } from "stores/store";
import Currency from "components/Core/Currency/Currency";
import { selectIsCartLoading } from "selectors/payment.selector";
import { selectCurrentCredit, selectCurrentCreditCurrency } from "selectors/credit.selector";
import {
  selectIsPageOptionsIdRunning,
  selectIsListPageOptionsRunning,
} from "selectors/plan.selector";

interface FooterInfoProps {
  pageOptionsId: string | null;
}

function FooterInfo({ pageOptionsId }: FooterInfoProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const credit = useAppSelector(selectCurrentCredit);
  const isCartLoading = useAppSelector(selectIsCartLoading);
  const creditCurrency = useAppSelector(selectCurrentCreditCurrency);
  const isPageOptionsIdRunning = useAppSelector<boolean>(selectIsPageOptionsIdRunning);
  const isListPageOptionsRunning = useAppSelector<boolean>(selectIsListPageOptionsRunning);

  const [{ cartTotalAmount }, { clearCart, addIdToCart, updateCart }] = useCart(false);

  useEffect(() => {
    if (pageOptionsId) {
      addIdToCart(pageOptionsId);
      updateCart();
    } else {
      clearCart();
    }
  }, [clearCart, addIdToCart, pageOptionsId, updateCart]);

  const isLoading = isListPageOptionsRunning || isPageOptionsIdRunning || isCartLoading;
  const canDisplayCartAmount =
    cartTotalAmount !== null && Boolean(pageOptionsId) && Boolean(creditCurrency);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flex="1">
          <Box display="flex" flexDirection="row">
            <Typography>{t("CHANGE_PLAN.PAYING_NOW")}</Typography>
          </Box>
        </Box>

        <Typography component="div" type="bold" style={{ textAlign: "end", minWidth: "32px" }}>
          {canDisplayCartAmount && !isLoading ? (
            <Currency value={Math.abs(cartTotalAmount)} currency={creditCurrency} />
          ) : (
            <Skeleton />
          )}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flex="1">
          <Typography> {t("ADD_NUMBER.YOUR_CURRENT_CREDIT")}</Typography>
        </Box>
        <div style={{ textAlign: "end", minWidth: "100px" }}>
          {credit !== null && creditCurrency ? (
            <Typography type="bold" className={classes.credit}>
              <Currency value={credit} currency={creditCurrency} />
            </Typography>
          ) : null}
        </div>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  root: {
    flex: 1,
    display: "flex",
    borderRadius: "8px",
    padding: spacing(3),
    flexDirection: "column",
    margin: spacing(3, 3, 0, 3),
    backgroundColor: alohi.neutral100,
    border: `1px solid ${alohi.neutral200}`,
  },
  divider: {
    margin: spacing(1.5, 0),
  },
  credit: {
    color: alohi.green,
  },
}));

export default FooterInfo;
