import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Modal, Box } from "@alohi/kit";

import FormRow from "components/Forms/FormRow";
import { capitalizeString } from "helpers/string";
import { PersonalAccessToken } from "selectors/integrations.selector";
import ScopesSelector from "./components/ScopesSelector";
import DeleteTokenModal from "./DeleteTokenModal";
import { NO_EXPIRATION_DATE } from "./CreateTokenModal";

interface ViewTokenModalProps {
  token: PersonalAccessToken;
  onCancel: () => void;
  onDelete: () => void;
}

function ViewTokenModal({ token, onCancel, onDelete }: ViewTokenModalProps) {
  const { t } = useTranslation();

  const [isDeleteTokenModalOpen, setIsDeleteTokenModalOpen] = useState(false);

  const handleDelete = () => {
    setIsDeleteTokenModalOpen(true);
  };

  const onDeleteTokenConfirm = () => {
    onDelete();
  };

  const onDeleteTokenCancel = () => {
    setIsDeleteTokenModalOpen(false);
  };

  return (
    <Modal
      onCancel={onCancel}
      onConfirm={handleDelete}
      confirmButtonVariant="red"
      cancelTitle={t("COMMON.CLOSE")}
      confirmTitle={t("COMMON.DELETE")}
      title={t("API_TOKEN.TOKEN")}
      maxWidth="xs"
      disableAnimation
    >
      <Box p={3}>
        <FormRow label={t("COMMON.NAME")}>
          <Box mr={10}>
            <TextField
              fullWidth
              isDisabled
              placeholder={t("API_TOKEN.TOKEN_NAME")}
              value={capitalizeString(token.name)}
            />
          </Box>
        </FormRow>

        <FormRow label={t("API_TOKEN.EXPIRATION_DATE")}>
          <Box mr={10}>
            {token.expires_at === NO_EXPIRATION_DATE.unix() ? (
              <TextField fullWidth isDisabled value={t("COMMON.NEVER")} />
            ) : (
              <TextField
                fullWidth
                isDisabled
                value={dayjs.unix(token.expires_at).format("MMM DD, YYYY")}
              />
            )}
          </Box>
        </FormRow>

        <FormRow alignLabel={"flex-start"} label={t("API_TOKEN.SCOPES_LIST")}>
          <Box mr={10}>
            <ScopesSelector selectedScopes={token.scopes} disabled />
          </Box>
        </FormRow>
      </Box>

      {isDeleteTokenModalOpen ? (
        <DeleteTokenModal
          id={token.id}
          onCancel={onDeleteTokenCancel}
          onConfirm={onDeleteTokenConfirm}
        />
      ) : null}
    </Modal>
  );
}

export default ViewTokenModal;
