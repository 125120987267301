import PropTypes from "prop-types";
import { Box, makeStyles } from "@alohi/kit";

import { useAppSelector } from "stores/store";
import { selectIsAllowedToChooseAreaCode } from "selectors/plan.selector";
import AddressVerification from "components/AddressBundle/AddressVerification/AddressVerification";
import CountryInfo from "../CountryInfo";
import PreviewNumber from "../PreviewNumber";
import AvailableAreas from "../AvailableAreas";
import AddBulkNumbers from "../AddBulkNumbers";
import AvailableCountries from "../AvailableCountries";
import AvailableNumberTypes from "../AvailableNumberTypes";

function AddNumberComponent({ actionType }) {
  const classes = useStyles();

  const isAllowedToChooseAreaCode = useAppSelector(selectIsAllowedToChooseAreaCode);

  return (
    <Box className={classes.wrapper} mt={2}>
      <Box className={classes.options} pr={"22%"}>
        <AvailableCountries />
        {isAllowedToChooseAreaCode ? (
          <>
            <AvailableAreas />
            {actionType === "corporate_number" ? <AddBulkNumbers /> : null}
            <AvailableNumberTypes actionType={actionType} />
            <AddressVerification />
          </>
        ) : null}
      </Box>
      {!isAllowedToChooseAreaCode ? <CountryInfo /> : null}
      <PreviewNumber />
    </Box>
  );
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
    minHeight: "300px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  options: {
    display: "flex",
    flexDirection: "column",
  },
});

AddNumberComponent.propTypes = {
  actionType: PropTypes.oneOf(["corporate_number", "number"]).isRequired,
};

export default AddNumberComponent;
