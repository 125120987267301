import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectFileViewerState } from "../../selectors/faxes.selector";
import { selectAccountDefaultFaxFileType } from "../../selectors/account.selector";
import { faxesAsyncActions, SET_FILE_VIEWER } from "../../stores/reducers/faxes.reducer";
import PdfViewer from "../PreviewFile/PdfViewer";

const FaxViewer = () => {
  const dispatch = useDispatch();

  const { fileUrl, boxName, cdrId, isFaxReport, downloadName } = useSelector(selectFileViewerState);
  const downloadFileType = useSelector(selectAccountDefaultFaxFileType);

  const handleClose = useCallback(() => dispatch(SET_FILE_VIEWER({ isOpen: false })), [dispatch]);

  const handleDownloadFromLocal = useCallback(() => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", downloadName ?? "file");
    link.click();
  }, [downloadName, fileUrl]);

  const handleDownloadFromBackend = useCallback(
    () => dispatch(faxesAsyncActions.download({ boxName, cdrId, downloadFileType })),
    [boxName, cdrId, dispatch, downloadFileType],
  );

  const handleDownload =
    downloadFileType === "pdf" || !!isFaxReport
      ? handleDownloadFromLocal
      : handleDownloadFromBackend;

  return <PdfViewer pdfUrl={fileUrl} handleClosure={handleClose} handleDownload={handleDownload} />;
};

export default FaxViewer;
