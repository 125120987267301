import { useCallback } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

import { profileRoutes } from "enums/profile";
import RegulatoryDocumentsModal from "views/RegulatoryDocuments/RegulatoryDocumentsModal";
import AddressesModal from "views/Address/AddressesModal";

function ComplianceHandleQueryString() {
  const history = useHistory();
  const { search } = useLocation();
  const { direct_open_modal } = queryString.parse(search);

  const closeCurrentModal = useCallback(() => {
    history.replace(`/profile/${profileRoutes.compliance}`);
  }, [history]);

  switch (direct_open_modal) {
    case "regulatory_documents":
      return <RegulatoryDocumentsModal handleClosure={closeCurrentModal} />;
    case "addresses":
      return <AddressesModal handleClosure={closeCurrentModal} />;
    default:
      return null;
  }
}

export default ComplianceHandleQueryString;
