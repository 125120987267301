import i18n from "i18next";
import dayjs, { Dayjs } from "dayjs";

import Utc from "dayjs/plugin/utc";
import Calendar from "dayjs/plugin/calendar";
import Timezone from "dayjs/plugin/timezone";
import Duration from "dayjs/plugin/duration";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import UpdateLocale from "dayjs/plugin/updateLocale";

export interface TimezoneType {
  zone: string;
  label: string;
  value: string;
}

dayjs.extend(Utc);
dayjs.extend(Calendar);
dayjs.extend(Duration);
dayjs.extend(Timezone);
dayjs.extend(UpdateLocale);
dayjs.extend(LocalizedFormat);
dayjs.extend(CustomParseFormat);

export const formatOutboxDate = (cdrDate: string) => {
  return dayjs.utc(cdrDate, "YYYY-MM-DD HH:mm:ss ZZ").utcOffset(dayjs().utcOffset()).calendar();
};

export const formatCdrDate = (cdrDate: string) => {
  return dayjs.utc(cdrDate, "YYYY-MM-DD HH:mm:ss").local().calendar();
};

export const formatLastTimeUsed = (cdrDate: string) => {
  const date = dayjs(cdrDate);

  const isTheSameYear = dayjs().isSame(date, "year");

  switch (i18n.language) {
    case "en":
      if (isTheSameYear) {
        return date.local().calendar(null, {
          sameDay: "[Today -] H:mm", // Today - 06:23
          lastDay: "[Yesterday -] H:mm", // Yesterday - 06:32
          lastWeek: "dddd [-] H:mm", // Last Tuesday - 06:21
          sameElse: "ddd[,] MMM DD [-] H:mm", // Tue, Sep 08 - 06:19
        });
      }
      return date.local().format("MMM DD[,] YYYY [-] H:mm"); // Sep 08, 2020 - 06:19
    case "fr":
      if (isTheSameYear) {
        return date.local().calendar(null, {
          sameDay: "[Aujourd'hui -] H:mm",
          lastDay: "[Hier -] H:mm",
          lastWeek: "dddd [-] H:mm",
          sameElse: "DD MMM [-] H:mm",
        });
      }
      return date.local().format("ddd[,] MMM DD [-] H:mm");
    default:
      if (isTheSameYear) {
        return date.local().calendar(null, {
          sameDay: "[Today -] H:mm", // Today - 06:23
          lastDay: "[Yesterday -] H:mm", // Yesterday - 06:32
          lastWeek: "dddd [-] H:mm", // Last Tuesday - 06:21
          sameElse: "ddd[,] MMM DD [-] H:mm", // Tue, Sep 08 - 06:19
        });
      }
      return date.local().format("MMM DD[,] YYYY [-] H:mm"); // Sep 08, 2020 - 06:19
  }
};

export const formatCdrSendTime = (sendTime: string) => {
  return dayjs.utc(sendTime, "YYYY-MM-DD HH:mm:ss ZZ").local().calendar();
};

export const formatCdrDuration = (cdrDuration: number) =>
  dayjs.utc(dayjs.duration({ seconds: cdrDuration }).asMilliseconds()).format("HH:mm:ss");

export const formatDefaultDate = (date = "") => {
  switch (i18n.language) {
    case "en":
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("MMM DD, YYYY");
    case "fr":
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("DD MMM YYYY");
    default:
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("MMM DD, YYYY");
  }
};

export const formatDateByFormat = (date = "") => {
  switch (i18n.language) {
    case "en":
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("MMM DD, YYYY");
    case "fr":
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("DD MMM YYYY");
    default:
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("MMM DD, YYYY");
  }
};

export const formatFullMonthDate = (date = "") => {
  switch (i18n.language) {
    case "en":
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("MMMM D, YYYY");
    case "fr":
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("D MMMM YYYY");
    default:
      return dayjs.utc(date).utcOffset(dayjs().utcOffset()).format("MMMM D, YYYY");
  }
};

export const formatCountdownDuration = (seconds: number) => {
  const t = dayjs.duration({ seconds: seconds });
  if (t.asHours() > 1) {
    return dayjs.utc(t.asMilliseconds()).format("HH:mm:ss");
  } else {
    return dayjs.utc(t.asMilliseconds()).format("mm:ss");
  }
};

export const orderByIssueDate = (a: { issue_date: string }, b: { issue_date: string }) => {
  return (
    dayjs.utc(b.issue_date, "YYYY-MM-DD HH:mm:ss").unix() -
    dayjs.utc(a.issue_date, "YYYY-MM-DD HH:mm:ss").unix()
  );
};

export const orderBySendTimeDate = (a: { send_time: string }, b: { send_time: string }) => {
  return (
    dayjs.utc(a.send_time, "YYYY-MM-DD HH:mm:ss").unix() -
    dayjs.utc(b.send_time, "YYYY-MM-DD HH:mm:ss").unix()
  );
};

export const orderCdrByDateAsc = (a: { date: string }, b: { date: string }) => {
  return (
    dayjs.utc(a.date, "YYYY-MM-DD HH:mm:ss").unix() -
    dayjs.utc(b.date, "YYYY-MM-DD HH:mm:ss").unix()
  );
};

export const formatFaxConfirmationDate = (date: string, offset: string) =>
  dayjs
    .utc(date)
    .utcOffset(dayjs(`2000-01-01T00:00:00Z`).diff(dayjs(`2000-01-01T00:00:00${offset}`), "hour"))
    .local()
    .format("ll (ddd)");

export const formatFaxConfirmationTime = (date: string, offset: string) =>
  dayjs
    .utc(date)
    .utcOffset(dayjs(`2000-01-01T00:00:00Z`).diff(dayjs(`2000-01-01T00:00:00${offset}`), "hour"))
    .local()
    .format("hh:mm:ss A ([UTC]Z)");

export const isCdrDateSameOrBefore = (firstDate: string, secondDate: string) => {
  return (
    dayjs.utc(firstDate, "YYYY-MM-DD HH:mm:ss").unix() <=
    dayjs.utc(secondDate, "YYYY-MM-DD HH:mm:ss").unix()
  );
};

export const transformDateToUnix = (date: string) => {
  // Fix for Safari and Chrome to have the same timestamp
  return new Date(date.replace(/ /g, "T") + "Z").getTime();
};

export const pageConsumptionDate = (date: string) => {
  switch (i18n.language) {
    case "en":
      return dayjs(transformDateToUnix(date)).format("MMM DD[,] YYYY");
    case "fr":
      return dayjs(transformDateToUnix(date)).format("DD MMM YYYY");
    default:
      return dayjs(transformDateToUnix(date)).format("MMM DD[,] YYYY");
  }
};

export const isLessTheOneDayFromNow = (date: string) => {
  return Math.abs(dayjs.utc(date, "YYYY-MM-DD HH:mm:ss").unix() - dayjs.utc().unix()) <= 86400;
};

export const sendFaxTimezone = (date: Dayjs, timezone: TimezoneType) => {
  return `${dayjs(date).tz(timezone.label.split(" ")[0], true).format("YYYY-MM-DD HH:mm:ss Z")}`;
};

export { Dayjs };

export default dayjs;
