import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar, makeStyles } from "@alohi/kit";

import { CodeInput, Grid } from "ui";
import { history } from "helpers/index";
import { useInput } from "hooks/useInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { isValidTwoFaCode } from "helpers/inputValidation";
import { clearTwoFaNextAction } from "stores/reducers/twoFa.reducer";
import { clearLoginWithTwoFa, loginWithTwoFa } from "stores/reducers/authentication.reducer";
import {
  loginWithTwoFaError,
  loginWithTwoFaSuccess,
  loginWithTwoFaErrorInvalidCode,
  loginWithTwoFaErrorExpiredToken,
} from "selectors/authentication.selector";

function ValidateTwoFaCodeModal({ handleClosure }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const hasError = useSelector(loginWithTwoFaError);
  const isSuccess = useSelector(loginWithTwoFaSuccess);
  const errorInvalidCode = useSelector(loginWithTwoFaErrorInvalidCode);
  const errorExpiredToken = useSelector(loginWithTwoFaErrorExpiredToken);

  const [twoFaCode, twoFaCodeInput] = useInput("", isValidTwoFaCode);

  const handleSubmit = () => {
    twoFaCodeInput.touch();

    if (twoFaCodeInput.isValid) {
      dispatch(loginWithTwoFa(twoFaCode));
    }
  };

  useOnPressEnter(handleSubmit);

  useEffect(() => {
    if (isSuccess) {
      handleClosure();
      dispatch(clearLoginWithTwoFa());
    } else if (hasError) {
      if (errorInvalidCode) {
        enqueueSnackbar(t("PROFILE.2FA_INVALID_CODE"), { variant: "error" });
        twoFaCodeInput.setShowsError(true);
        dispatch(clearLoginWithTwoFa());
      } else if (errorExpiredToken) {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        dispatch(clearLoginWithTwoFa());
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        dispatch(clearLoginWithTwoFa());
        history.push("/");
      }
    }
  }, [
    t,
    dispatch,
    hasError,
    isSuccess,
    handleClosure,
    twoFaCodeInput,
    enqueueSnackbar,
    errorInvalidCode,
    errorExpiredToken,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearTwoFaNextAction());
    };
  }, [dispatch]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleSubmit}
      title={t("PROFILE.2FA_TITLE")}
      isConfirmDisabled={twoFaCodeInput.showsError}
    >
      <Box p={3}>
        <Typography className={classes.text}>{t("PROFILE.2FA_INTRO")}</Typography>
        <Grid container justifyContent="center" alignItems="center">
          <CodeInput
            autoFocus
            fields={6}
            sx={{ my: 2 }}
            value={twoFaCode}
            onChange={twoFaCodeInput.setValue}
            isError={twoFaCodeInput.showsError}
          />
        </Grid>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles({
  text: {
    textAlign: "center",
  },
});

ValidateTwoFaCodeModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ValidateTwoFaCodeModal;
