import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { selectIsPlanEnterprise } from "selectors/plan.selector";
import SsoConfigurationStep from "components/Sso/SsoConfigurationStep";
import ScimConfigurationStep from "components/Sso/ScimConfigurationStep";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";

interface SsoModalProps {
  handleClosure: () => void;
}

enum Step {
  SSO = "SSO",
  SCIM = "SCIM",
}

function SsoModal({ handleClosure }: SsoModalProps) {
  const { t } = useTranslation();

  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);

  const [currentStep, setCurrentStep] = useState<Step>(Step.SSO);

  if (!isPlanEnterprise)
    return (
      <RequireUpgradeToEnterprise
        handleClosure={handleClosure}
        title={t("SSO.ACTIVATION")}
        featureName={t("SSO.TITLE")}
      />
    );

  return (
    <>
      {
        {
          [Step.SSO]: (
            <SsoConfigurationStep
              handleClosure={(isNext) => {
                isNext ? setCurrentStep(Step.SCIM) : handleClosure();
              }}
            />
          ),
          [Step.SCIM]: (
            <ScimConfigurationStep
              handleClosure={(isNext) => (isNext ? handleClosure() : setCurrentStep(Step.SSO))}
            />
          ),
        }[currentStep]
      }
    </>
  );
}

export default SsoModal;
