import PropTypes from "prop-types";
import { useSnackbar } from "@alohi/kit";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { plans } from "enums/plans";
import useBool from "hooks/useBool";
import { alohiAdminUrls } from "routes/urls";
import ChangePlan from "views/ChangePlan/ChangePlan";
import useAdminRedirections from "hooks/useAdminRedirections";
import { getAvailablePlans, setCountryForPlan, setNextPlan } from "stores/reducers/plans.reducer";
import {
  selectNextPlan,
  selectIsPlanActive,
  selectAvailablePlans,
  selectPlanPurchaseId,
  selectIsPlanInactive,
  selectAvailableCountries,
} from "selectors/plan.selector";

function ChangePlanToCustom({ handleClosure, type, iso, planType, period }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const nextPlan = useSelector(selectNextPlan);
  const isPlanActive = useSelector(selectIsPlanActive);
  const isPlanInactive = useSelector(selectIsPlanInactive);
  const availablePlans = useSelector(selectAvailablePlans);
  const planPurchaseId = useSelector(selectPlanPurchaseId);
  const availableCountries = useSelector(selectAvailableCountries);

  const [isPlanSet, isPlanSetHandler] = useBool(false);
  const [isCountrySet, isCountrySetHandler] = useBool(false);
  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    const redirectToAdminObject = {
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: isChangePlanOpenBool.setTrue,
    };

    redirectToAdminObject["adminParams"] = {};
    if (typeof planType === "string") {
      redirectToAdminObject["adminParams"]["plan-type"] = planType;
      if (typeof period === "string") {
        redirectToAdminObject["adminParams"].period = period;
      }
    }
    if (typeof iso === "string") {
      redirectToAdminObject["adminParams"].iso = iso;
    }
    redirectToAdmin(redirectToAdminObject);
  }, [isChangePlanOpenBool.setTrue, planType, redirectToAdmin, period, iso]);

  const handleOnClose = useCallback(
    (isSuccess) => {
      if (handleClosure) {
        handleClosure(isSuccess);
      } else {
        history.replace("/");
        isChangePlanOpenBool.setFalse();
      }
    },
    [handleClosure, history, isChangePlanOpenBool],
  );

  useEffect(() => {
    if (planPurchaseId) {
      dispatch(getAvailablePlans(planPurchaseId));
    }
  }, [dispatch, planPurchaseId]);

  useEffect(() => {
    if (isPlanInactive || isPlanActive) {
      if (availablePlans?.length === 0) {
        enqueueSnackbar(t("COMMON.NO_AVAILABLE"), { variant: "error" });
      } else if (!!availablePlans?.length && !isPlanSet) {
        dispatch(setNextPlan(availablePlans, false, type));
        isPlanSetHandler.setTrue();
      }
    } else {
      enqueueSnackbar(t("FAXES.PLAN_WAITING_APPROVAL"), { variant: "error" });
    }
  }, [
    availablePlans,
    dispatch,
    enqueueSnackbar,
    isPlanActive,
    isPlanInactive,
    isPlanSet,
    isPlanSetHandler,
    t,
    type,
  ]);

  useEffect(() => {
    if (isPlanInactive || isPlanActive) {
      if (!isCountrySet && availableCountries) {
        const countryToBeSelected = availableCountries?.find((country) => country.iso === iso);
        if (iso && countryToBeSelected) {
          dispatch(setCountryForPlan(countryToBeSelected));
          isCountrySetHandler.setTrue();
        }
      }
    } else {
      enqueueSnackbar(t("FAXES.PLAN_WAITING_APPROVAL"), { variant: "error" });
    }
  }, [
    availableCountries,
    dispatch,
    enqueueSnackbar,
    isCountrySet,
    isCountrySetHandler,
    isPlanActive,
    isPlanInactive,
    iso,
    t,
  ]);

  useEffect(() => {
    if (nextPlan) {
      handleOpenChangePlan();
    }
  }, [nextPlan, handleOpenChangePlan]);

  if (isChangePlanOpen) {
    return <ChangePlan handleClosure={handleOnClose} />;
  }

  return null;
}

ChangePlanToCustom.propTypes = {
  planType: PropTypes.string,
  period: PropTypes.string,
  iso: PropTypes.string,
  handleClosure: PropTypes.func,
  type: PropTypes.oneOf(Object.values(plans)),
};

export default ChangePlanToCustom;
