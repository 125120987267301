import { ForwardedRef, forwardRef } from "react";
import { Radio } from "ui";

const TableRadio = forwardRef(
  (props: Record<string, boolean>, ref: ForwardedRef<HTMLButtonElement>) => {
    const isIndeterminate = "indeterminate" in props;

    if (isIndeterminate) {
      return null;
    }

    return <Radio ref={ref} {...props} />;
  },
);

TableRadio.displayName = "TableRadio";

export default TableRadio;
