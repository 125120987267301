import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDefaultDate } from "helpers/date";
import { selectLastPaymentDate } from "selectors/credit.selector";
import { selectIsDefaultPaymentMethodError } from "selectors/payment.selector";

function PreviewLastPayment() {
  const { t } = useTranslation();

  const isError = useSelector(selectIsDefaultPaymentMethodError);
  const lastPaymentDate = useSelector(selectLastPaymentDate);

  if (lastPaymentDate) {
    return <>{t("PROFILE.LAST_PAYMENT", { date: formatDefaultDate(lastPaymentDate) })}</>;
  }

  if (isError) {
    return <>{t("COMMON.NO_AVAILABLE")}</>;
  }

  return null;
}

export default PreviewLastPayment;
