import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { Divider, Menu, MenuItem, Typography, FaButton, Box } from "@alohi/kit";

import useBool from "hooks/useBool";
import { boxNames } from "enums/faxes";
import FaxStatusModal from "views/Faxes/FaxStatusModal";
import { isPossiblePhoneNumber } from "helpers/inputValidation";
import AddNoteOnFaxModal from "views/Faxes/modals/AddNoteOnFaxModal";
import AddOrEditContactModal from "views/Contacts/AddOrEditContactModal";
import { faxesAsyncActions, forward, reply } from "stores/reducers/faxes.reducer";
import AddOrEditBlacklistContactModal from "views/Contacts/AddOrEditBlacklistContactModal";
import HandleAnonymousSendersModal from "views/Contacts/modals/HandleAnonymousSendersModal";
import { selectAccountDefaultFaxFileType, selectAllowAnonymous } from "selectors/account.selector";
import {
  selectCdrId,
  selectCdrById,
  selectCdrIsReadById,
  selectCdrContactName,
  selectCdrFromToNumber,
  selectCdrFullNoteById,
  selectCdrIsFromAnonymous,
  selectIsCdrFromCommonStorage,
  selectCdrSharedNoteById,
} from "selectors/faxes.selector";

function InboxActions({ dataIndex }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allowAnonymous = useSelector(selectAllowAnonymous);
  const initialCdrId = useSelector(selectCdrId(boxNames.inbox, dataIndex));
  const initialFromNumber = useSelector(selectCdrFromToNumber(boxNames.inbox, dataIndex));
  const initialContactName = useSelector(selectCdrContactName(boxNames.inbox, dataIndex));
  const isCdrFromCommonStorage = useSelector(
    selectIsCdrFromCommonStorage(boxNames.inbox, dataIndex),
  );

  // Freeze ID of the original CDR, even when indexes change
  // Make sure that the menu is still tied to the CDR it was opened from
  const [cdrId, setCdrId] = useState(initialCdrId);
  const [isNoteModalOpen, isNoteModalOpenBool] = useBool(false);
  const [fromNumber, setFromNumber] = useState(initialFromNumber);
  const [contactName, setContactName] = useState(initialContactName);
  const [isHandleAnonymousSendersModalOpen, isHandleAnonymousSendersModalOpenBool] = useBool(false);

  const cdr = useSelector(selectCdrById(boxNames.inbox, cdrId));
  const note = useSelector(selectCdrFullNoteById(boxNames.inbox, cdrId));
  const isRead = useSelector(selectCdrIsReadById(boxNames.inbox, cdrId));
  const downloadFileType = useSelector(selectAccountDefaultFaxFileType);
  const sharedNote = useSelector(selectCdrSharedNoteById(boxNames.inbox, cdrId));
  const isAnonymous = useSelector(selectCdrIsFromAnonymous(boxNames.inbox, cdrId));

  const [actions, setActions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [faxStatusDialog, setFaxStatusDialog] = useState({
    isOpen: false,
    cdrId: null,
  });
  const [addToWhitelistModal, setAddToWhitelistModal] = useState({
    isOpen: false,
    prefilledFaxNumber: null,
  });
  const [addToBlacklistModal, setAddToBlacklistModal] = useState({
    isOpen: false,
    prefilledFaxNumber: null,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCdrId(initialCdrId);
    setFromNumber(initialFromNumber);
    setContactName(initialContactName);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Close menu if CDR disappears from the box
  useEffect(() => {
    if (!cdr && !!anchorEl) {
      handleClose();
    }
  }, [anchorEl, cdr]);

  useEffect(() => {
    if (!!anchorEl) {
      const isFromValidPhoneNumber = isPossiblePhoneNumber(fromNumber); // "From number" can be "Fax.Plus User", "Anonymous", "Fax.Plus"...
      let newActions = [];

      if (isFromValidPhoneNumber) {
        newActions.push({
          label: t("FAXES.REPLY"),
          callback: () => {
            dispatch(reply({ number: fromNumber, cdrId }));
          },
        });
      }

      newActions.push({
        label: t("FAXES.FORWARD"),
        callback: () => {
          dispatch(forward({ cdrId }));
        },
      });

      newActions.push({
        label: t("FAXES.MOVE_TO_TRASH"),
        callback: () => {
          dispatch(
            faxesAsyncActions.moveToTrash({
              boxName: boxNames.inbox,
              cdrIds: [cdrId],
            }),
          );
        },
      });

      newActions.push({ label: "Divider" });

      newActions.push({
        label: t("COMMON.DOWNLOAD"),
        callback: () => {
          dispatch(
            faxesAsyncActions.download({
              cdrId,
              downloadFileType,
              boxName: boxNames.inbox,
            }),
          );
        },
      });

      if (!isCdrFromCommonStorage) {
        newActions.push({
          label: t("FAXES.FORWARD_SIGN"),
          callback: () => {
            dispatch(
              faxesAsyncActions.getCdrToken({
                cdrId,
                boxName: boxNames.inbox,
              }),
            );
          },
        });
      }

      newActions.push({ label: "Divider" });

      if (isRead) {
        newActions.push({
          label: t("FAXES.UNREAD"),
          callback: () => {
            dispatch(
              faxesAsyncActions.markAsUnread({
                boxName: boxNames.inbox,
                cdrIds: [cdrId],
              }),
            );
          },
        });
      } else {
        newActions.push({
          label: t("FAXES.READ"),
          callback: () => {
            dispatch(
              faxesAsyncActions.markAsRead({
                boxName: boxNames.inbox,
                cdrIds: [cdrId],
              }),
            );
          },
        });
      }

      newActions.push({
        label:
          Boolean(note) || Boolean(sharedNote?.text) ? t("FAXES.MODIFY_NOTE") : t("FAXES.ADD_NOTE"),
        callback: isNoteModalOpenBool.setTrue,
      });

      newActions.push({
        label: t("FAXES.FAX_STATUS"),
        callback: () => {
          setFaxStatusDialog({
            isOpen: true,
            cdrId,
          });
        },
      });

      if (isFromValidPhoneNumber) {
        newActions.push({ label: "Divider" });
        if (!contactName) {
          newActions.push({
            label: t("CONTACTS.ADD_TO_CONTACTS"),
            callback: () => {
              setAddToWhitelistModal({
                isOpen: true,
                prefilledFaxNumber: fromNumber,
              });
            },
          });
        }

        newActions.push({
          label: t("CONTACTS.ADD_BLACKLIST"),
          callback: () => {
            setAddToBlacklistModal({
              isOpen: true,
              prefilledFaxNumber: fromNumber,
            });
          },
        });
      }

      if (allowAnonymous && isAnonymous) {
        newActions.push({ label: "Divider" });

        newActions.push({
          label: t("CONTACTS.ADD_BLACKLIST"),
          callback: isHandleAnonymousSendersModalOpenBool.setTrue,
        });
      }

      setActions(newActions);
    }
  }, [
    t,
    note,
    cdrId,
    isRead,
    dispatch,
    anchorEl,
    fromNumber,
    isAnonymous,
    contactName,
    allowAnonymous,
    sharedNote?.text,
    downloadFileType,
    isCdrFromCommonStorage,
    isNoteModalOpenBool.setTrue,
    isHandleAnonymousSendersModalOpenBool.setTrue,
  ]);

  return (
    <>
      <Box textAlign="end">
        <FaButton
          aria-label="More"
          aria-haspopup="true"
          onClick={handleClick}
          aria-controls={`actions-menu-${dataIndex}`}
          icon={faEllipsisV}
        />
        <Menu
          id={`actions-menu-${dataIndex}`}
          anchorEl={anchorEl}
          open={!!anchorEl && !!actions}
          onClose={handleClose}
          elevation={2}
        >
          {actions.map((action, key) => {
            if (action.label === "Divider") {
              return (
                <Box py={1} key={key}>
                  <Divider />
                </Box>
              );
            } else {
              return (
                <MenuItem
                  key={action.label}
                  disabled={action.disabled}
                  onClick={() => {
                    action.callback();
                    handleClose();
                  }}
                >
                  <Typography variant="body" style={{ fontSize: "0.875rem" }}>
                    {action.label}
                  </Typography>
                </MenuItem>
              );
            }
          })}
        </Menu>
      </Box>

      {isNoteModalOpen ? (
        <AddNoteOnFaxModal
          cdrId={cdrId}
          currentNote={note}
          dataIndex={dataIndex}
          boxName={boxNames.inbox}
          currentSharedNote={sharedNote}
          handleClosure={isNoteModalOpenBool.setFalse}
        />
      ) : null}

      {faxStatusDialog.isOpen ? (
        <FaxStatusModal
          handleClosure={() => setFaxStatusDialog({ isOpen: false, cdrId: null })}
          cdrId={faxStatusDialog.cdrId}
          boxName={boxNames.inbox}
        />
      ) : null}

      {addToWhitelistModal.isOpen && (
        <AddOrEditContactModal
          prefilledFaxNumber={addToWhitelistModal.prefilledFaxNumber}
          handleClosure={() => setAddToWhitelistModal({ isOpen: false })}
        />
      )}

      {addToBlacklistModal.isOpen && (
        <AddOrEditBlacklistContactModal
          prefilledFaxNumber={addToBlacklistModal.prefilledFaxNumber}
          handleClosure={() => setAddToBlacklistModal({ isOpen: false })}
        />
      )}

      {isHandleAnonymousSendersModalOpen ? (
        <HandleAnonymousSendersModal
          handleClosure={isHandleAnonymousSendersModalOpenBool.setFalse}
        />
      ) : null}
    </>
  );
}

InboxActions.propTypes = {
  dataIndex: PropTypes.number.isRequired,
};

export default memo(InboxActions);
