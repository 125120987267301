import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, useSnackbar, Modal } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { useAppSelector } from "stores/store";
import { isValidFriendlyName } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { selectNumberFriendlyName } from "selectors/common.selector";
import {
  getAllNumbers,
  updateFriendlyName,
  getAssignedNumbers,
  clearUpdateFriendlyName,
} from "stores/reducers/numbers.reducer";
import {
  selectUpdateFriendlyNameError,
  selectIsUpdateFriendlyNameRunning,
  selectIsUpdateFriendlyNameSuccess,
} from "selectors/numbers.selector";

interface EditFriendlyNameModalProps {
  number: string;
  handleClosure: () => void;
}

function EditFriendlyNameModal({ number, handleClosure }: EditFriendlyNameModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const error = useAppSelector(selectUpdateFriendlyNameError);
  const isRunning = useAppSelector(selectIsUpdateFriendlyNameRunning);
  const isSuccess = useAppSelector(selectIsUpdateFriendlyNameSuccess);
  const friendlyName = useAppSelector(selectNumberFriendlyName(number));

  const [friendlyNameValue, friendlyNameInput] = useInput(friendlyName ?? "", isValidFriendlyName);

  const handleConfirm = () => {
    friendlyNameInput.touch();
    if (friendlyNameInput.isValid) {
      dispatch(updateFriendlyName({ number, friendlyName: friendlyNameValue }));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(getAllNumbers());
      dispatch(getAssignedNumbers());
      dispatch(clearUpdateFriendlyName());
      handleClosure?.();
    }
  }, [enqueueSnackbar, t, dispatch, handleClosure, isSuccess]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearUpdateFriendlyName());
      handleClosure?.();
    }
  }, [error, enqueueSnackbar, t, dispatch, handleClosure]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      onPressEnter={handleConfirm}
      isConfirmLoading={isRunning}
      title={t("NUMBERS.MODIFY_FRIENDLY_NAME")}
      isConfirmDisabled={friendlyNameInput.showsError}
    >
      <Box p={3} pr="15%">
        <FormRowTextInput
          id="edit-friendly-name"
          value={friendlyNameValue}
          inputProps={{ maxLength: 50 }}
          onBlur={friendlyNameInput.onBlur}
          label={t("NUMBERS.FRIENDLY_NAME")}
          onFocus={friendlyNameInput.onFocus}
          error={friendlyNameInput.showsError}
          onChange={friendlyNameInput.onChange}
        />
      </Box>
    </Modal>
  );
}

export default EditFriendlyNameModal;
