import useBool from "hooks/useBool";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import DefaultCoverSheetListItem from "views/SendFax/components/DefaultCoverSheetListItem";
import DefaultCoverSheetModal from "../modals/DefaultCoverSheetModal/DefaultCoverSheetModal";
import GalleryCoverSheetListItem from "./GalleryCoverSheetListItem";
import SharedCoverSheetListItem from "./SharedCoverSheetListItem";
import EnforcedCoverSheetListItem from "./EnforcedCoverSheetListItem";

function CoverSheets() {
  const {
    sendFaxStore: { galleryCoverSheet, defaultCoverSheet, sharedCoverSheet, enforcedCoverSheet },
  } = useSendFaxContext();

  const [isCreateDefaultCoverSheetOpen, isCreateDefaultCoverSheetOpenBool] = useBool(false);

  return (
    <>
      {enforcedCoverSheet ? <EnforcedCoverSheetListItem /> : null}

      {defaultCoverSheet ? (
        <DefaultCoverSheetListItem
          openCreateCoverSheet={isCreateDefaultCoverSheetOpenBool.setTrue}
        />
      ) : null}

      {galleryCoverSheet ? <GalleryCoverSheetListItem /> : null}

      {sharedCoverSheet ? <SharedCoverSheetListItem /> : null}

      {isCreateDefaultCoverSheetOpen ? (
        <DefaultCoverSheetModal handleClosure={isCreateDefaultCoverSheetOpenBool.setFalse} />
      ) : null}
    </>
  );
}

export default CoverSheets;
