import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Modal, useSnackbar, Box, Divider } from "@alohi/kit";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import ChangePlan from "views/ChangePlan/ChangePlan";
import useAdminRedirections from "hooks/useAdminRedirections";
import InviteUsersInput from "components/Users/InviteUsersInput";
import { Member } from "components/Users/InviteFromSignPlusTable";
import InviteFromSignPlus from "components/Users/InviteFromSignPlus";
import { isValidEmails, parseEmailsString } from "helpers/inputValidation";
import RemainingNumberOfUsers from "components/Users/RemainingNumberOfUsers";
import {
  selectRemainingNumberOfUsers,
  selectGetLimitsRequestSuccess,
  selectListCorporateMembersFromSign,
  selectInviteByEmailsRequestIsRunning,
  selectInviteByEmailsRequestErrorMessage,
  selectInviteByEmailsRequestSuccessEmails,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_INVITE_BY_EMAILS_REQUEST,
} from "stores/reducers/corporate.reducer";
import { alohiAdminUrls } from "routes/urls";

interface InviteUsersModalProps {
  handleClosure: (isSuccess: boolean) => void;
}

function InviteUsersModal({ handleClosure }: InviteUsersModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const isLoading = useSelector(selectInviteByEmailsRequestIsRunning);
  const remainingNumberOfUsers = useSelector(selectRemainingNumberOfUsers);
  const getLimitsRequestSuccess = useSelector(selectGetLimitsRequestSuccess);
  const successEmails = useSelector(selectInviteByEmailsRequestSuccessEmails);
  const errorMessageEmails = useSelector(selectInviteByEmailsRequestErrorMessage);
  const corporateMembers = useSelector<never, Member[] | null>(selectListCorporateMembersFromSign);

  const [emailsToInvite, setEmailsToInvite] = useState<string[]>([]);
  const [currentEmail, emailInput] = useInput<string>("", isValidEmails);
  const [isChangePlanModalOpen, isChangePlanModalOpenBool] = useBool(false);

  const hasCorporateMembers = corporateMembers?.length;
  const isUpgradeRequired = remainingNumberOfUsers === 0;

  const handleConfirmation = useCallback(() => {
    // User might have an email inside the input but not 'inserted yet'
    // Parse it manually
    if (emailsToInvite.length === 0) {
      const { uniqueEmails } = parseEmailsString(emailInput.ref.current);

      if (uniqueEmails.length) {
        setEmailsToInvite(uniqueEmails);
        emailInput.setValue("");
        emailInput.unTouch();
        dispatch(corporateAsyncActions.inviteByEmails({ emails: uniqueEmails }));
      } else {
        emailInput.setShowsError(true);
      }
    } else {
      dispatch(corporateAsyncActions.inviteByEmails({ emails: emailsToInvite }));
    }
  }, [dispatch, emailInput, emailsToInvite]);

  const handleOpenChangePlan = useCallback(() => {
    if (isUpgradeRequired) {
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.upgrade,
        fallbackAction: isChangePlanModalOpenBool.setTrue,
      });
    } else {
      handleConfirmation();
    }
  }, [handleConfirmation, isChangePlanModalOpenBool, isUpgradeRequired, redirectToAdmin]);

  useEffect(() => {
    if (remainingNumberOfUsers === null) {
      // Fetch the limits before rendering anything in this Modal
      dispatch(corporateAsyncActions.getLimits());
    }
  }, [dispatch, remainingNumberOfUsers]);

  useEffect(() => {
    if (successEmails && successEmails.length > 0) {
      enqueueSnackbar(t("FORMS.INVITATION_HAS_BEEN_SENT", { count: successEmails.length }), {
        variant: "success",
      });
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(corporateAsyncActions.getLimits());

      dispatch(CLEAR_INVITE_BY_EMAILS_REQUEST());

      handleClosure(true);
    }
  }, [dispatch, enqueueSnackbar, handleClosure, successEmails, t]);

  useEffect(() => {
    if (errorMessageEmails) {
      enqueueSnackbar(errorMessageEmails, { variant: "error" });
      dispatch(CLEAR_INVITE_BY_EMAILS_REQUEST());
    }
  }, [dispatch, enqueueSnackbar, errorMessageEmails]);

  useEffect(() => {
    dispatch(corporateAsyncActions.listCorporateMembers({ type: "SIGN_PLUS" }));
  }, [dispatch]);

  if (!getLimitsRequestSuccess) {
    return null;
  }

  return (
    <>
      <Modal
        scroll="paper"
        isModalDisabled={isLoading}
        isConfirmLoading={isLoading}
        onConfirm={handleOpenChangePlan}
        cancelTitle={t("COMMON.CANCEL")}
        data-cy={dataCy.inviteUsersModal}
        onCancel={() => handleClosure(false)}
        title={t("USERS.INVITE_NEW_USER", { count: 2 })}
        isConfirmDisabled={
          isLoading ||
          (emailInput.showsError && !emailsToInvite.length) ||
          remainingNumberOfUsers - emailsToInvite.length < 0
        }
        confirmTitle={isUpgradeRequired ? t("CHANGE_PLAN.UPGRADE_PLAN") : t("FORMS.CONFIRM")}
      >
        <InviteUsersInput
          emailInput={emailInput}
          currentEmail={currentEmail}
          emailsToInvite={emailsToInvite}
          setEmailsToInvite={setEmailsToInvite}
        />
        <Box px={3} py={2}>
          <RemainingNumberOfUsers
            remainingNumberOfUsers={remainingNumberOfUsers - emailsToInvite.length}
          />
        </Box>
        {hasCorporateMembers ? (
          <>
            <Box sx={{ mx: 3 }}>
              <Divider />
            </Box>
            <InviteFromSignPlus
              corporateMembers={corporateMembers}
              emailsToInvite={emailsToInvite}
              setEmailsToInvite={setEmailsToInvite}
            />
          </>
        ) : null}
      </Modal>

      {isChangePlanModalOpen ? (
        <ChangePlan
          handleClosure={(isSuccess) => {
            if (isSuccess) dispatch(corporateAsyncActions.getLimits());
            isChangePlanModalOpenBool.setFalse();
          }}
        />
      ) : null}
    </>
  );
}

export default InviteUsersModal;
