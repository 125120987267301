import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Panel, Button, makeStyles, Typography } from "@alohi/kit";

import { selectIsPlanInactive } from "selectors/plan.selector";
import { selectCurrentCreditCurrency } from "selectors/credit.selector";
import { selectIsPaymentHistoryLoading } from "selectors/payment.selector";
import ChangeCurrencyModal from "views/ChangeCurrency/ChangeCurrencyModal";
import PaymentHistoryModal from "views/PaymentHistory/PaymentHistoryModal";
import ManagePaymentMethodModal from "views/ManagePaymentMethod/ManagePaymentMethodModal";
import PreviewDefaultPayment from "./PreviewDefaultPayment";
import PreviewLastPayment from "./PreviewLastPayment";

function PaymentDetailsPanel() {
  const classes = useStyles();
  const { t } = useTranslation();

  const isPlanInactive = useSelector(selectIsPlanInactive);
  const creditCurrency = useSelector(selectCurrentCreditCurrency);
  const isPaymentHistoryLoading = useSelector(selectIsPaymentHistoryLoading);

  const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
  const [openManageCurrency, setOpenManageCurrency] = useState(false);
  const [openManagePaymentMethod, setOpenManagePaymentMethod] = useState(false);

  return (
    <Panel
      id={"payment-details"}
      data-cy="PaymentDetails"
      headerTitle={t("PROFILE.PAYMENT_DETAILS")}
    >
      <Panel.Front>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.PAYMENT_METHOD")}</Panel.Cell>
          <Panel.Cell variant="data" className={classes.left}>
            <Typography type="panelCellData">
              <PreviewDefaultPayment />
            </Typography>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button variant="white" onClick={() => setOpenManagePaymentMethod(true)}>
              {t("COMMON.MANAGE")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.DEFAULT_CURRENCY")}</Panel.Cell>
          <Panel.Cell variant="data" className={classes.left}>
            {creditCurrency ? creditCurrency.toUpperCase() : ""}
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button
              variant="white"
              data-cy="ChangeCurrency"
              onClick={() => setOpenManageCurrency(true)}
            >
              {t("COMMON.CHANGE")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("COMMON.PAYMENT_HISTORY")}</Panel.Cell>
          <Panel.Cell variant="data" className={classes.left}>
            <Typography type="panelCellData">
              <PreviewLastPayment />
            </Typography>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button
              variant="white"
              onClick={() => setOpenPaymentHistory(true)}
              isLoading={isPaymentHistoryLoading}
            >
              {t("PROFILE.VIEW_HISTORY")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
      </Panel.Front>

      {openPaymentHistory ? (
        <PaymentHistoryModal handleClosure={() => setOpenPaymentHistory(false)} />
      ) : null}

      {openManageCurrency ? (
        <ChangeCurrencyModal handleClosure={() => setOpenManageCurrency(false)} />
      ) : null}

      {openManagePaymentMethod ? (
        <ManagePaymentMethodModal
          withAutomaticRecharge={isPlanInactive}
          handleClosure={() => setOpenManagePaymentMethod(false)}
        />
      ) : null}
    </Panel>
  );
}

const useStyles = makeStyles({
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
});

export default PaymentDetailsPanel;
