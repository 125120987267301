import { ActionMap } from "helpers/types";
import { UploadFilesResponse } from "stores/reducers/numbers.reducer";
import { Destination, initialSendFaxStore, SendFaxStore } from "views/SendFax/contexts/store";

export type SendFaxActionPayloads = {
  SET_ERROR: string;
  CLEAR_ERROR: undefined;
  INIT_SESSION: undefined;
  CLEAR_SESSION: undefined;
  CLEAR_SCHEDULE: undefined;
  SET_TIME: SendFaxStore["date"];
  SET_FROM: SendFaxStore["from"];
  SET_FILES_PATH: UploadFilesResponse;
  CLEAR_SHARED_COVER_SHEET: undefined;
  CLEAR_DEFAULT_COVER_SHEET: undefined;
  CLEAR_GALLERY_COVER_SHEET: undefined;
  SET_COMMENT: SendFaxStore["comment"];
  REPLACE_FILES: SendFaxStore["files"];
  SET_TIMEZONE: SendFaxStore["timezone"];
  CLEAR_FOR_COVER_SHEET_ENFORCEMENT: undefined;
  SET_DESTINATION: SendFaxStore["destinations"];
  ADD_DESTINATION: SendFaxStore["destinations"];
  SET_INITIATED_FROM: SendFaxStore["initiatedFrom"];
  SET_IS_OVERLAY_OPEN: SendFaxStore["isOverlayOpen"];
  SET_SHARED_COVER_SHEET: SendFaxStore["sharedCoverSheet"];
  SET_DEFAULT_COVER_SHEET: SendFaxStore["defaultCoverSheet"];
  SET_GALLERY_COVER_SHEET: SendFaxStore["galleryCoverSheet"];
  SET_ENFORCED_COVER_SHEET: SendFaxStore["enforcedCoverSheet"];
  UPDATE_IS_SYNCED: SendFaxStore["wasSynced"];
  INIT_FROM_INDEXEDDB: {
    files: SendFaxStore["files"];
    destinations: SendFaxStore["destinations"];
    defaultCoverSheet: SendFaxStore["defaultCoverSheet"];
  };
};

export type SendFaxAction =
  ActionMap<SendFaxActionPayloads>[keyof ActionMap<SendFaxActionPayloads>];

export function sendFaxReducer(
  store: SendFaxStore = initialSendFaxStore,
  action: SendFaxAction,
): SendFaxStore {
  switch (action.type) {
    case "SET_IS_OVERLAY_OPEN": {
      return {
        ...store,
        isOverlayOpen: action.payload,
      };
    }
    case "INIT_FROM_INDEXEDDB": {
      const { destinations, files, defaultCoverSheet } = action.payload;

      const filesWithThumbnails = files.map((file) => {
        if (file.mime.search("image") !== -1 && file.type === "faxFile") {
          Object.assign(file, {
            thumbnail: URL.createObjectURL(file.file),
          });
        }

        return file;
      });

      return {
        ...store,
        files: filesWithThumbnails,
        defaultCoverSheet,
        destinations,
        wasSynced: true,
      };
    }
    case "UPDATE_IS_SYNCED": {
      return {
        ...store,
        wasSynced: action.payload,
      };
    }
    case "CLEAR_SESSION":
      return {
        ...store,
        files: [],
        destinations: [],
        sharedCoverSheet: null,
        defaultCoverSheet: null,
        galleryCoverSheet: null,
      };
    case "SET_TIME":
      return {
        ...store,
        date: action.payload,
      };
    case "SET_INITIATED_FROM":
      return {
        ...store,
        initiatedFrom: action.payload,
      };
    case "SET_TIMEZONE":
      return {
        ...store,
        timezone: action.payload,
      };
    case "CLEAR_SCHEDULE":
      return {
        ...store,
        date: null,
        timezone: null,
      };
    case "SET_ERROR":
      return {
        ...store,
        errorMessage: action.payload,
      };
    case "CLEAR_ERROR":
      return {
        ...store,
        errorMessage: null,
      };
    case "INIT_SESSION":
      store.files.forEach((file) => {
        if ("thumbnail" in file && file.thumbnail) {
          URL.revokeObjectURL(file.thumbnail);
        }
      });

      return {
        ...store,
        files: [],
        date: null,
        timezone: null,
        destinations: [],
        defaultCoverSheet: null,
      };
    case "REPLACE_FILES": {
      const newFiles = action.payload.filter((file) => Boolean(file));

      return {
        ...store,
        files: newFiles,
      };
    }
    case "SET_DEFAULT_COVER_SHEET": {
      const defaultCoverSheet = action.payload
        ? {
            ...store.defaultCoverSheet,
            ...action.payload,
          }
        : null;

      return {
        ...store,
        defaultCoverSheet,
      };
    }
    case "CLEAR_DEFAULT_COVER_SHEET": {
      return {
        ...store,
        defaultCoverSheet: null,
      };
    }
    case "CLEAR_GALLERY_COVER_SHEET": {
      return {
        ...store,
        galleryCoverSheet: null,
      };
    }
    case "CLEAR_SHARED_COVER_SHEET": {
      return {
        ...store,
        sharedCoverSheet: null,
      };
    }
    case "SET_DESTINATION": {
      const destinations = action.payload;

      return {
        ...store,
        destinations,
      };
    }
    case "ADD_DESTINATION": {
      const destinations = action.payload;
      const stateDestinations = store.destinations;

      // Make sure the numbers and groups are unique by value
      // Get the previous numbers form state, get the new numbers from payload, and place them in a map.
      // Created a new array with the values above.
      const uniqueItems = new Map<string, Destination>();

      stateDestinations.forEach((destination) => uniqueItems.set(destination.value, destination));
      destinations.forEach((destination) => uniqueItems.set(destination.value, destination));

      return {
        ...store,
        destinations: Array.from(uniqueItems.values()),
      };
    }
    case "SET_FILES_PATH": {
      const copyOfFiles = store.files.slice();

      action.payload.forEach((file) => {
        copyOfFiles[file.index].path = file.path;
      });

      return {
        ...store,
        files: copyOfFiles,
      };
    }
    case "SET_COMMENT":
      return {
        ...store,
        comment: action.payload,
      };
    case "SET_FROM":
      return {
        ...store,
        from: action.payload,
      };
    case "SET_GALLERY_COVER_SHEET":
      const newGalleryCoverSheet = action.payload ? { ...action.payload } : null;

      return {
        ...store,
        galleryCoverSheet: newGalleryCoverSheet,
      };
    case "SET_SHARED_COVER_SHEET":
      const newSharedCoverSheet = action.payload ? { ...action.payload } : null;

      return {
        ...store,
        sharedCoverSheet: newSharedCoverSheet,
      };
    case "SET_ENFORCED_COVER_SHEET":
      const enforcedCoverSheet = action.payload ? { ...action.payload } : null;

      if (Boolean(enforcedCoverSheet)) {
        return {
          ...store,
          enforcedCoverSheet,
          // Clear other cover sheets, the enforced one has priority, and we only dispaly one cover sheet at a time
          sharedCoverSheet: null,
          defaultCoverSheet: null,
          galleryCoverSheet: null,
        };
      }

      return {
        ...store,
        enforcedCoverSheet: null,
      };
    case "CLEAR_FOR_COVER_SHEET_ENFORCEMENT":
      return {
        ...store,
        sharedCoverSheet: null,
        defaultCoverSheet: null,
        galleryCoverSheet: null,
      };
    default:
      return store;
  }
}
