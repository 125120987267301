import { useHistory } from "react-router";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Typography, Box } from "@alohi/kit";

import { boxNames } from "enums/faxes";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { useSendFaxContext } from "views/SendFax/contexts/context";

interface SubmissionModalProps {
  onClose: () => void;
}

function SubmissionModal({ onClose }: SubmissionModalProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { sendFaxDispatch } = useSendFaxContext();

  const handleViewStatus = useCallback(() => {
    history.push(`/faxes/${boxNames.outbox}`);
  }, [history]);

  useEffect(() => {
    sendFaxDispatch({
      type: "CLEAR_SESSION",
    });
  }, [sendFaxDispatch]);

  useOnPressEnter(handleViewStatus);

  return (
    <Modal
      maxWidth="xs"
      title={t("SENT_FAX.REDIRECT_MODAL_TITLE")}
      customFooterInfo={
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose}>{t("SENT_FAX.DONE")}</Button>
          <Button autoFocus onClick={handleViewStatus} data-cy="ViewStatusButton">
            {t("SENT_FAX.VIEW_STATUS")}
          </Button>
        </Box>
      }
    >
      <Box p={3} width="100%">
        <Typography type="body">{t("SENT_FAX.REDIRECT_MODAL_TEXT")}</Typography>
      </Box>
    </Modal>
  );
}

export default SubmissionModal;
