import PropTypes from "prop-types";
import { MenuItem, Select, Box } from "@alohi/kit";
import { getCountryCallingCode } from "libphonenumber-js";

import { makeStyles } from "ui";
import CountryFlag from "../CountryFlag/CountryFlag";
import NoCountrySVG from "../../../assets/NoCountrySVG";

function CountryFlagDropdown({ value, options, onChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.parent}>
      <Select
        value={value || ""}
        onChange={onChange}
        className={classes.select}
        inputProps={{ tabIndex: "-1" }}
        classes={{
          select: classes.selected,
        }}
      >
        {options.map(
          ({ value, label, divider }) =>
            !divider && (
              <MenuItem key={label} value={value || "ZZ"}>
                {label} {value && value !== "ZZ" && `(+${getCountryCallingCode(value)})`}
              </MenuItem>
            ),
        )}
      </Select>
      {value ? (
        <CountryFlag
          code={value}
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      ) : (
        <Box className={classes.flag}>
          <NoCountrySVG />
        </Box>
      )}
    </Box>
  );
}

CountryFlagDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      divider: PropTypes.bool,
    }),
  ).isRequired,
};

const useStyles = makeStyles({
  selected: {
    "&&": {
      paddingRight: "0px",
    },
  },
  parent: {
    marginBottom: "2px",
    position: "relative",
  },
  flag: {
    width: "20px",
    height: "20px",
  },
  select: {
    top: 0,
    left: 0,
    zIndex: 1,
    border: 0,
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "absolute",
  },
});

export default CountryFlagDropdown;
