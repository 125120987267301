import { decodeHtmlEntities } from "helpers/string";
import { Typography, makeStyles } from "ui";

interface SnippetProps {
  snippet: string;
}

function Snippet({ snippet }: SnippetProps) {
  const classes = useStyles();

  const formattedSnippet = snippet.replaceAll("<em>", "").replaceAll("</em>", "");

  return (
    <Typography variant="body" component="div" className={classes.text}>
      {decodeHtmlEntities(formattedSnippet)}
    </Typography>
  );
}

const useStyles = makeStyles({
  text: {
    textOverflow: "ellipsis",
    width: "100%",
  },
  highlight: {
    fontWeight: "bold",
    background: "#FFF3CA",
    paddingLeft: "3px",
    paddingRight: "3px",
    borderRadius: 2,
  },
});

export default Snippet;
