import { fetchWithRefresh } from "helpers";
import { API_AFFOGATO, API_ESHOP } from "config";
import { defaultCoverSheetTypes } from "enums/coverSheet";

import dayjs from "../helpers/date";

const sendFax = ({
  from,
  to,
  files,
  tagIds,
  sendTime,
  initiatedFrom,
  defaultCoverSheet,
  customCoverSheetId,
  comment,
}) => {
  const body = {
    to,
    files,
    tag_ids: tagIds,
    send_time: sendTime || `now ${dayjs().format("ZZ")}`,
    comment: { text: comment ?? "", tags: "" },
    initiated_from: initiatedFrom
      ? { type: initiatedFrom.from, id: initiatedFrom.id }
      : { type: "", from_id: "" },
  };

  if (defaultCoverSheet) {
    const { to, from, subject, message, companyLogo, ...checkboxes } = defaultCoverSheet;
    const flags = Object.entries(checkboxes);

    body.cover_page = {
      name_to: to,
      name_from: from,
      subject: subject,
      message: message,
      include_company_logo: companyLogo,
    };

    if (flags.length) {
      body.cover_page.flags = flags.reduce((accumulator, [key, value]) => {
        if (value) {
          accumulator.push(defaultCoverSheetTypes[key]);
        }

        return accumulator;
      }, []);
    }
  } else if (customCoverSheetId) {
    body.custom_cover_sheet = {
      id: customCoverSheetId,
    };
  }

  const requestOptions = {
    method: "POST",
    body,
  };

  const path = `${API_AFFOGATO}/numbers/${from}/applications/bGFzYWduYQ==/outbox`;
  return fetchWithRefresh(path, requestOptions);
};

const putCommentByCdrId = (cdrId, comment) => {
  const requestOptions = {
    method: "PUT",
    body: { comment },
  };
  const path = `${API_AFFOGATO}/numbers/all/applications/applicationsId/outbox/${cdrId}`;
  return fetchWithRefresh(path, requestOptions);
};
const deleteByCdrIds = (ids) => {
  const requestOptions = {
    method: "PATCH",
    body: ids.map((id) => ({ job_id: id })),
  };
  const path = `${API_AFFOGATO}/numbers/all/applications/applicationsId/outbox`;
  return fetchWithRefresh(path, requestOptions);
};

const getAll = ({ for_plan = true } = {}) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/numbers?for_plan=${for_plan}`;
  return fetchWithRefresh(path, requestOptions);
};

const getAssignedNumbers = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/assigned_numbers`;
  return fetchWithRefresh(path, requestOptions);
};

const getOutboxSettings = (number) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/numbers/${number}/outbox_settings`;
  return fetchWithRefresh(path, requestOptions);
};

const patch = ({ numberToAssign, memberIds }) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "update_assignment",
      member_ids: memberIds,
    },
  };
  const path = `${API_AFFOGATO}/numbers/${numberToAssign}`;
  return fetchWithRefresh(path, requestOptions);
};

const updateFaxReception = ({ numberToUpdate, toEnable, notifyWhenDisabled }) => {
  const options = !toEnable
    ? {
        opts: {
          notify_when_disabled: notifyWhenDisabled,
        },
      }
    : {};

  const requestOptions = {
    method: "PATCH",
    body: {
      op: toEnable ? "enable_reception" : "disable_reception",
      ...options,
    },
  };
  const path = `${API_AFFOGATO}/numbers/${numberToUpdate}`;
  return fetchWithRefresh(path, requestOptions);
};

const updateFriendlyName = ({ number, friendlyName }) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "update_friendly_name",
      friendly_name: friendlyName,
    },
  };
  const path = `${API_AFFOGATO}/numbers/${number}`;
  return fetchWithRefresh(path, requestOptions);
};

const cancelNumber = ({ planId, number }) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "is_canceled",
      value: true,
    },
  };
  const path = `${API_ESHOP}/plans/${planId}/numbers/${number}`;
  return fetchWithRefresh(path, requestOptions);
};

const resumeNumber = ({ planId, number }) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "is_canceled",
      value: false,
    },
  };
  const path = `${API_ESHOP}/plans/${planId}/numbers/${number}`;
  return fetchWithRefresh(path, requestOptions);
};

export const numbersApi = {
  sendFax,
  getAll,
  getAssignedNumbers,
  patch,
  cancelNumber,
  resumeNumber,
  deleteByCdrIds,
  putCommentByCdrId,
  getOutboxSettings,
  updateFriendlyName,
  updateFaxReception,
};
