import { makeStyles } from "ui";

function DragAndDropOverlay() {
  const classes = useStyles();
  return <div className={classes.dropZoneOverlay} />;
}

const useStyles = makeStyles(({ alohi }) => ({
  dropZoneOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: alohi.blue + "2e", // alohi.blue with transparency
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: alohi.lightBlue,
    zIndex: 2,
    boxSizing: "border-box",
  },
}));

export default DragAndDropOverlay;
