import { useDispatch, useSelector } from "react-redux";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import FormRowSelectInput from "../Forms/FormRowReactSelectInput";
import { clearCart } from "../../stores/reducers/payment.cart.reducer";
import { clearAddressRequirements } from "../../stores/reducers/address.reducer";
import {
  selectCountryForPlan,
  selectIsAllowedToBuyBulkNumbers,
} from "../../selectors/plan.selector";
import {
  selectIsVerifyNumberLoading,
  selectIsVerifyBulkNumberLoading,
  selectIsVerifyCustomNumberLoading,
} from "../../selectors/verifications.selector";
import {
  clearVerifyNumber,
  clearVerifyBulkNumbers,
  updateBulkNumbersAmount,
  clearVerifyCustomNumber,
  clearAssignBundleToNumber,
} from "../../stores/reducers/verifications.reducer";

const defaultValue = { value: 1, label: 1 };

function AddBulkNumbers() {
  const dispatch = useDispatch();

  const countryForPlan = useSelector(selectCountryForPlan);
  const isVeryNumberLoading = useSelector(selectIsVerifyNumberLoading);
  const isVerifyBulkNumberLoading = useSelector(selectIsVerifyBulkNumberLoading);
  const isAllowedToBuyBulkNumbers = useSelector(selectIsAllowedToBuyBulkNumbers);
  const isVeryCustomNumberLoading = useSelector(selectIsVerifyCustomNumberLoading);

  const [selectedQuantity, setSelectedQuantity] = useState(defaultValue);

  const quantityOptions = useMemo(
    () => Array.from({ length: 100 }, (_, i) => ({ value: i + 1, label: i + 1 })),
    [],
  );

  const handleOnChange = useCallback(
    (option: { value: number; label: number }) => {
      setSelectedQuantity(option);

      // Clear the numbers that were previously fetched
      dispatch(clearVerifyNumber());
      dispatch(clearVerifyCustomNumber());
      dispatch(clearAssignBundleToNumber());
      dispatch(clearVerifyBulkNumbers());
      dispatch(clearAddressRequirements());
      // Clear the cart to refresh the price
      dispatch(clearCart());
      // Update the amount so other components update accordingly
      dispatch(updateBulkNumbersAmount(option.value));
    },
    [dispatch],
  );

  useEffect(() => {
    setSelectedQuantity(defaultValue);
    dispatch(updateBulkNumbersAmount(defaultValue.value));
    // Whenever country changes, reset the state
  }, [countryForPlan, dispatch]);

  if (!isAllowedToBuyBulkNumbers) {
    return null;
  }

  return (
    <FormRowSelectInput
      id="quantity"
      label="Quantity"
      labelGridWidth={5}
      ReactSelectProps={{
        placeholder: "",
        maxMenuHeight: 245,
        isSearchable: true,
        value: selectedQuantity,
        options: quantityOptions,
        onChange: handleOnChange,
        disabled: isVerifyBulkNumberLoading || isVeryCustomNumberLoading || isVeryNumberLoading,
        styles: {
          container: (provided: CSSProperties) => ({
            ...provided,
            width: 80,
          }),
        },
      }}
    />
  );
}

export default AddBulkNumbers;
