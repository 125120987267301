import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { plans } from "enums/plans";
import { clearCart } from "stores/reducers/payment.cart.reducer";
import FormRowReactSelectInput from "../Forms/FormRowReactSelectInput";
import { selectCurrentCreditCurrency } from "../../selectors/credit.selector";
import { clearAddressRequirements } from "../../stores/reducers/address.reducer";
import {
  selectIsCorporateUpgrade,
  selectCountryHasPlanRequirements,
} from "../../selectors/common.selector";
import {
  selectCountryForPlan,
  selectAvailablePlans,
  selectIsNextPlanBasic,
  selectAvailableCountries,
  selectIsCurrentPlanBasic,
} from "../../selectors/plan.selector";
import {
  clearVerifyNumber,
  clearVerifyCustomNumber,
  clearAssignBundleToNumber,
} from "../../stores/reducers/verifications.reducer";
import {
  setNextPlan,
  setCountryForPlan,
  clearCountryForPlan,
  clearAreaForCountry,
  getAvailableCountries,
} from "../../stores/reducers/plans.reducer";
import { OptionCountry, SingleValueCountry } from "./CustomizedSelect";

function AvailableCountries() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const countryForPlan = useSelector(selectCountryForPlan);
  const availablePlans = useSelector(selectAvailablePlans);
  const isNextPlanBasic = useSelector(selectIsNextPlanBasic);
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);
  const isCurrentPlanBasic = useSelector(selectIsCurrentPlanBasic);
  const availableCountries = useSelector(selectAvailableCountries);
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);
  const planRequirements = useSelector(selectCountryHasPlanRequirements);

  useEffect(() => {
    if (currentCreditCurrency) {
      dispatch(getAvailableCountries(currentCreditCurrency));
    }
  }, [currentCreditCurrency, dispatch]);

  const handleOnChange = useCallback(
    (country) => {
      dispatch(clearCart());
      dispatch(clearVerifyNumber());
      dispatch(clearAreaForCountry());
      dispatch(clearVerifyCustomNumber());
      dispatch(clearAssignBundleToNumber());
      dispatch(clearAddressRequirements());
      dispatch(setCountryForPlan(country));
    },
    [dispatch],
  );

  useEffect(() => {
    if (planRequirements && countryForPlan) {
      /*
       * Should be triggered when the user is already basic or
       * the plan he selected is basic and he tries to change / buy
       * a number with a premium one.
       */
      if (isCurrentPlanBasic && !isCorporateUpgrade) {
        // User is basic, not upgrading to a corporate plan and he tried to change his number to a premium one.
        enqueueSnackbar(
          t("CHANGE_PLAN.PLAN_RESTRICTION_NEED_FOR_THE_COUNTRY", { country: countryForPlan.name }),
          {
            variant: "info",
          },
        );
        dispatch(clearCountryForPlan());
      } else if (isNextPlanBasic) {
        // User is free and he tried to change his number to a premium one.
        enqueueSnackbar(
          t("CHANGE_PLAN.CHANGE_PLAN_RESTRICTION_NEED_FOR_THE_COUNTRY", {
            country: countryForPlan.name,
          }),
          {
            variant: "info",
          },
        );
        // Premium monthly will be auto-selected by us for the user
        dispatch(setNextPlan(availablePlans, true, plans.premium));
      }
    }
  }, [
    t,
    dispatch,
    countryForPlan,
    availablePlans,
    enqueueSnackbar,
    isNextPlanBasic,
    planRequirements,
    isCorporateUpgrade,
    isCurrentPlanBasic,
  ]);

  return (
    <FormRowReactSelectInput
      id="select-country"
      label={t("COMMON.COUNTRY")}
      labelGridWidth={5}
      ReactSelectProps={{
        isSearchable: true,
        maxMenuHeight: 245,
        value: countryForPlan,
        onChange: handleOnChange,
        options: availableCountries,
        placeholder: t("COMMON.SELECT_COUNTRY"),
        components: { Option: OptionCountry, SingleValue: SingleValueCountry },
      }}
    />
  );
}

export default AvailableCountries;
