import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Typography, Box } from "@alohi/kit";
import { faStars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useBool from "hooks/useBool";
import { useTheme, makeStyles } from "ui";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";

function BusinessBanner() {
  const classes = useStyles();
  const { alohi } = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isModalOpen, isModalOpenBool] = useBool(false);

  return (
    <Box className={classes.root}>
      <Box className={classes.iconWrapper}>
        <FontAwesomeIcon icon={faStars} color={alohi.gold} size="2x" />
      </Box>
      <Box className={classes.textWrapper}>
        <Typography className={classes.title}>{t("DASHBOARD.BUSINESS_TITLE")}</Typography>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button className={classes.button} onClick={isModalOpenBool.setTrue}>
          {t("DASHBOARD.UPGRADE")}
        </Button>
      </Box>
      {isModalOpen ? (
        <RequireUpgradeToEnterprise
          isPlural={false}
          title={t("DASHBOARD.TITLE")}
          featureName={t("DASHBOARD.FEATURE_NAME")}
          handleClosure={(isSuccess) => {
            if (isSuccess) {
              dispatch(corporateAsyncActions.getUsagePeriods({ period: 6 }));
            }
            isModalOpenBool.setFalse();
          }}
        />
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  root: {
    height: 70,
    width: "100%",
    display: "flex",
    borderRadius: 0,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "space-around",
    boxShadow: alohi.bannerShadow,
    backgroundColor: "#f6f8f8",
    border: `1px solid ${alohi.lighterGray}`,
  },
  button: {
    minWidth: 110,
    height: 32,
    backgroundColor: alohi.lightBlue,
  },
  iconWrapper: {
    display: "flex",
    width: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: spacing(2),
    color: "#616161",
  },
  buttonWrapper: {
    display: "flex",
    width: 150,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: spacing(1),
  },
}));

export default BusinessBanner;
