import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const listPageOptions = ({ uid, usCa }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  };

  const path = `${API_ESHOP}/plans/list_page_options/${uid}?us_ca=${usCa}`;
  return fetchWithRefresh(path, requestOptions);
};

const getPageOptionsId = ({ uid, pages, usCa, applyNow }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  };

  const path = `${API_ESHOP}/plans/page_option_id/${uid}?pages=${pages}&us_ca=${usCa}&apply_now=${applyNow}`;
  return fetchWithRefresh(path, requestOptions);
};

const get = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/plans`;
  return fetchWithRefresh(path, requestOptions);
};

const getCreditDetails = (planId) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/plans/${planId}/credit_details`;
  return fetchWithRefresh(path, requestOptions);
};

const cancelPlan = (planId) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      is_canceled: true,
    },
  };
  const path = `${API_ESHOP}/plans/${planId}`;
  return fetchWithRefresh(path, requestOptions);
};

const resumePlan = (planId) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      is_canceled: false,
    },
  };
  const path = `${API_ESHOP}/plans/${planId}`;
  return fetchWithRefresh(path, requestOptions);
};

const rechargePlan = (planId) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "recharge",
    },
  };
  const path = `${API_ESHOP}/plans/${planId}`;
  return fetchWithRefresh(path, requestOptions);
};

const getAll = ({ purchaseId, includeCorportate = true }) => {
  const requestOptions = { method: "GET" };
  const path = `${API_ESHOP}/available_plans?purchase_id=${purchaseId}&include_corporate=${includeCorportate}`;
  return fetchWithRefresh(path, requestOptions);
};

const getAvailableCountries = ({ include_corporate = true, currency }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/available_countries?currency=${currency}&include_corporate=${include_corporate}`;
  return fetchWithRefresh(path, requestOptions);
};

export const plansApi = {
  get,
  getAll,
  cancelPlan,
  resumePlan,
  rechargePlan,
  listPageOptions,
  getCreditDetails,
  getPageOptionsId,
  getAvailableCountries,
};
