import { store } from "stores/store";
import { refreshToken } from "stores/reducers/authentication.reducer";
import { BASIC_AUTH_BEARER, VERSION, X_ZANG_CLIENT_ID } from "config";
import { sentryHandleFetchError } from "./sentry";

let refreshTokenPromise;

export const fetchWithRefresh = async (url, configs, customOptions) => {
  try {
    const [newUrl, newConfig] = handleRequest(url, configs, customOptions);
    const response = await fetch(newUrl, newConfig);

    return await handleResponse(response);
  } catch (error) {
    if (error.status === 401 && !customOptions?.disableRetry) {
      if (!Boolean(refreshTokenPromise)) {
        refreshTokenPromise = store.dispatch(refreshToken());
      }
      await refreshTokenPromise;

      refreshTokenPromise = null;

      const [newUrl, newConfig] = handleRequest(url, configs, customOptions);
      const response = await fetch(newUrl, newConfig);

      return handleResponse(response);
    } else {
      sentryHandleFetchError(url, configs, customOptions, error);
      return Promise.reject(error);
    }
  }
};

export const handleRequest = (url, config, { excludeBearer = false, credentials } = {}) => {
  const accessToken = store.getState().authentication.accessToken;

  const isStorageUrl =
    url.search("/storage") !== -1 || url.search("/accounts/contacts/upload_excel") !== -1;
  const isDataLocation = url.search("/user_settings") !== -1;
  const hasBody = ["POST", "PUT", "PATCH", "DELETE"].indexOf(config.method?.toUpperCase()) !== -1;
  const isBearerNeeded = !!accessToken && !!url && !excludeBearer;

  const newConfig = { ...config };

  newConfig.headers = {
    "X-Zang-App": `platform=web;appname=fax.plus;appver=${VERSION}`,
    "X-Zang-App-Version": "web",
    "X-Zang-Client-Id": X_ZANG_CLIENT_ID,
    Authorization: `Basic ${BASIC_AUTH_BEARER}`,
    ...(hasBody &&
      !isStorageUrl && {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json; charset=UTF-8",
      }),
    ...config.headers,
    ...(isBearerNeeded && { Authorization: `Bearer ${accessToken}` }),
  };

  newConfig.credentials = credentials ?? "omit";

  if (hasBody && (!isStorageUrl || isDataLocation)) {
    newConfig.body = JSON.stringify(config.body ?? {});
  }

  return [url, newConfig];
};

function handleResponse(response) {
  const cfRay = response.headers.get("CF-Ray");
  const contentType = response.headers.get("Content-Type");
  const xRequestId = response.headers.get("X-Request-ID");
  const headers = {};

  // Iterate through all headers and add them to the object
  for (const [key, value] of response.headers.entries()) {
    headers[key] = value;
  }

  if (
    !!contentType &&
    (contentType.search("image") !== -1 ||
      contentType.search("application/pdf") !== -1 ||
      contentType.search("application/octet-stream") !== -1 ||
      contentType.search("text/csv") !== -1 ||
      contentType.search("application/zip") !== -1)
  ) {
    return response.blob();
  }

  if (!response.json) {
    return false;
  }

  if (response.statusText === "NO CONTENT" || response.status === 204) {
    return null;
  }

  return response
    .json()
    .catch(() => {
      return _errorHandler(response);
    })
    .then((jsonBody) => {
      if (!response.ok) {
        return _errorHandler(response, jsonBody);
      }

      return jsonBody;
    });

  function _errorHandler(response, jsonBody) {
    const status = response.status;
    const message = jsonBody?.message || response.statusText;
    const code = jsonBody?.code;
    const description = jsonBody?.description;
    const reason = jsonBody?.reason;

    return Promise.reject({
      code,
      cfRay,
      xRequestId,
      status,
      reason,
      message,
      description,
      origin: jsonBody,
      headers,
    });
  }
}
