import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiVerificationApi } from "api/api_verification.api";
import { oauthApi } from "api/oauth.api";
import {
  CreateTokenProps,
  DeleteTokenProps,
  apiPersonalAccessToken,
} from "api/personal_access_tokens.api";
import RS, { FetchError, RequestStatus } from "enums/requestStatus";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import { logout } from "./authentication.reducer";

const initialRequestState: {
  status: RequestStatus;
  response: unknown;
  error: unknown;
} = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  getApiVerification: initialRequestState,
  activateApi: initialRequestState,
  getClientPrivateInfo: initialRequestState,
  updateClientPrivateInfo: initialRequestState,
  getPersonalAccessTokens: initialRequestState,
  createPersonalAccessToken: initialRequestState,
  deletePersonalAccessToken: initialRequestState,
};

const getApiVerification = createAsyncThunk(
  "API/GET_API_VERIFICATION",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiVerificationApi.getAPIVerification();
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const activateApi = createAsyncThunk(
  "API/ACTIVATE_API",
  async (
    {
      imageUrl,
      fullName,
      redirectionUris,
    }: { imageUrl: string; fullName: string; redirectionUris: string[] },
    { rejectWithValue },
  ) => {
    try {
      const response = await apiVerificationApi.sendAPIVerification({
        imageUrl,
        fullName,
        redirectionUris,
      });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getClientPrivateInfo = createAsyncThunk(
  "API/GET_CLIENT_PRIVATE_INFO",
  async (_, { rejectWithValue, getState }) => {
    const isEnterprise = selectIsPlanEnterprise(getState());
    if (!isEnterprise) {
      return rejectWithValue("not enterprise");
    }
    try {
      const response = await oauthApi.getClientPrivateInfo();
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const updateClientPrivateInfo = createAsyncThunk(
  "APPS/UPDATE_CLIENT_PRIVATE_INFO",
  async ({ redirectionUris }: { redirectionUris: string[] }, { rejectWithValue }) => {
    try {
      const response = await oauthApi.updateClientPrivateInfo({ redirectionUris });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getPersonalAccessTokens = createAsyncThunk(
  "API/GET_PERSONAL_ACCESS_TOKENS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiPersonalAccessToken.getTokensList();
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const createPersonalAccessToken = createAsyncThunk(
  "API/CREATE_PERSONAL_ACCESS_TOKEN",
  async ({ name, scopes, expires_at }: CreateTokenProps, { rejectWithValue }) => {
    try {
      const response = await apiPersonalAccessToken.createToken({
        name,
        scopes,
        expires_at,
      });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const deletePersonalAccessToken = createAsyncThunk(
  "API/DELETE_PERSONAL_ACCESS_TOKEN",
  async ({ id }: DeleteTokenProps, { rejectWithValue }) => {
    try {
      const response = await apiPersonalAccessToken.deleteToken({
        id,
      });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const clearUpdateClientPrivateInfo = createAction("API/CLEAR_UPDATE_CLIENT_PRIVATE_INFO");
export const clearActivateApi = createAction("API/CLEAR_ACTIVATE_API");
export const clearCreatePersonalAccessToken = createAction("API/CREATE_PERSONAL_ACCESS_TOKEN");

const { reducer } = createSlice({
  name: "API",
  initialState,
  reducers: {},
  extraReducers: {
    [getApiVerification.pending as never]: (state) => {
      state.getApiVerification.status = RS.RUNNING;
      state.getApiVerification.error = null;
    },
    [getApiVerification.fulfilled as never]: (state, { payload }) => {
      state.getApiVerification.status = RS.IDLE;
      state.getApiVerification.response = payload.response;
    },
    [getApiVerification.rejected as never]: (state, { payload }) => {
      state.getApiVerification.status = RS.ERROR;
      state.getApiVerification.error = payload;
    },
    [activateApi.pending as never]: (state) => {
      state.activateApi.status = RS.RUNNING;
      state.activateApi.error = null;
    },
    [activateApi.fulfilled as never]: (state, { payload }) => {
      state.activateApi.status = RS.IDLE;
      state.activateApi.response = payload.response;
    },
    [activateApi.rejected as never]: (state, { payload }) => {
      state.activateApi.status = RS.ERROR;
      state.activateApi.error = payload;
    },
    [clearActivateApi as never]: (state) => {
      state.activateApi = initialRequestState;
    },
    [getClientPrivateInfo.pending as never]: (state) => {
      state.getClientPrivateInfo.status = RS.RUNNING;
      state.getClientPrivateInfo.error = null;
    },
    [getClientPrivateInfo.fulfilled as never]: (state, { payload }) => {
      state.getClientPrivateInfo.status = RS.IDLE;
      state.getClientPrivateInfo.response = payload.response;
    },
    [getClientPrivateInfo.rejected as never]: (state, { payload }) => {
      state.getClientPrivateInfo.status = RS.ERROR;
      state.getClientPrivateInfo.error = payload;
      state.getClientPrivateInfo.response = false;
    },
    [updateClientPrivateInfo.pending as never]: (state) => {
      state.updateClientPrivateInfo.status = RS.RUNNING;
      state.updateClientPrivateInfo.error = null;
    },
    [updateClientPrivateInfo.fulfilled as never]: (state, { payload }) => {
      state.updateClientPrivateInfo.status = RS.IDLE;
      state.updateClientPrivateInfo.response = payload.response;
    },
    [updateClientPrivateInfo.rejected as never]: (state, { payload }) => {
      state.updateClientPrivateInfo.status = RS.ERROR;
      state.updateClientPrivateInfo.error = payload;
    },
    [clearUpdateClientPrivateInfo as never]: (state) => {
      state.updateClientPrivateInfo = initialRequestState;
    },
    [getPersonalAccessTokens.pending as never]: (state) => {
      state.getPersonalAccessTokens.status = RS.RUNNING;
      state.getPersonalAccessTokens.error = null;
    },
    [getPersonalAccessTokens.fulfilled as never]: (state, { payload }) => {
      state.getPersonalAccessTokens.status = RS.IDLE;
      state.getPersonalAccessTokens.response = payload.response;
    },
    [getPersonalAccessTokens.rejected as never]: (state, { payload }) => {
      state.getPersonalAccessTokens.status = RS.ERROR;
      state.getPersonalAccessTokens.error = payload;
    },
    [createPersonalAccessToken.pending as never]: (state) => {
      state.createPersonalAccessToken.status = RS.RUNNING;
      state.createPersonalAccessToken.error = null;
    },
    [createPersonalAccessToken.fulfilled as never]: (state, { payload }) => {
      state.createPersonalAccessToken.status = RS.IDLE;
      state.createPersonalAccessToken.response = payload.response;
    },
    [createPersonalAccessToken.rejected as never]: (state, { payload }) => {
      state.createPersonalAccessToken.status = RS.ERROR;
      state.createPersonalAccessToken.error = payload;
    },
    [clearCreatePersonalAccessToken as never]: (state) => {
      state.createPersonalAccessToken = initialRequestState;
    },
    [deletePersonalAccessToken.pending as never]: (state) => {
      state.deletePersonalAccessToken.status = RS.RUNNING;
      state.deletePersonalAccessToken.error = null;
    },
    [deletePersonalAccessToken.fulfilled as never]: (state, { payload }) => {
      state.deletePersonalAccessToken.status = RS.IDLE;
      state.deletePersonalAccessToken.response = payload.response;
    },
    [deletePersonalAccessToken.rejected as never]: (state, { payload }) => {
      state.deletePersonalAccessToken.status = RS.ERROR;
      state.deletePersonalAccessToken.error = payload;
    },
    [logout.fulfilled as never]: () => initialState,
  },
});

export {
  activateApi,
  createPersonalAccessToken,
  deletePersonalAccessToken,
  getApiVerification,
  getClientPrivateInfo,
  getPersonalAccessTokens,
  updateClientPrivateInfo,
};

export default reducer;
