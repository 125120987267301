import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { accountsApi } from "api";
import { RootState } from "stores/store";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";
import {
  Contact,
  transformContacts,
  GetContactsResponse,
  CreateContactResponse,
  DefaultRequestOptions,
  UpdateContactResponse,
  revertTransformContact,
} from "./contacts.helpers";

const defaultRequestOptions = {
  limit: 50,
  offset: 0,
  shared: false,
  search: "",
  sortBy: "",
  type: "json",
  direction: "",
};

const initialState = {
  getWhitelistContacts: InitialRequestState(),
  shareWhitelistContacts: InitialRequestState(),
  createWhitelistContact: InitialRequestState(),
  updateWhitelistContact: InitialRequestState(),
  deleteWhitelistContacts: InitialRequestState(),
  getMoreWhitelistContacts: InitialRequestState(),
  setWhitelistGroups: InitialRequestState(),

  hasMore: false,
  selection: [] as string[],
  contacts: null as null | Contact[],
  lastRequestOptions: defaultRequestOptions,
};

export const clearWhitelistContacts = createAction("WHITELIST/CLEAR");
export const clearSetWhitelistGroups = createAction("WHITELIST/CLEAR_SET_GROUPS");
export const clearShareWhitelistContacts = createAction("WHITELIST/CLEAR_SHARE");
export const updateWhitelistContactsSelection = createAction<string[]>(
  "WHITELIST/UPDATE_SELECTION",
);

const getWhitelistContacts = createAsyncThunk<
  GetContactsResponse,
  Partial<DefaultRequestOptions>,
  { state: RootState; rejectValue: FetchError }
>(
  "WHITELIST/GET",
  async (requestOptionsOverrides = {}, { getState, rejectWithValue }) => {
    try {
      const lastRequestOptions = getState().whitelist.lastRequestOptions;
      const requestOptions = {
        ...lastRequestOptions,
        ...requestOptionsOverrides,
      };

      const response = await accountsApi.getContacts(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }: { getState: () => RootState }) => {
      return getState().whitelist.getWhitelistContacts.status !== RS.RUNNING;
    },
  },
);

const getMoreWhitelistContacts = createAsyncThunk<
  GetContactsResponse,
  undefined,
  { state: RootState; rejectValue: FetchError }
>(
  "WHITELIST/GET_MORE",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { contacts, lastRequestOptions } = getState().whitelist;

      const requestOptions = {
        ...lastRequestOptions,
        offset: contacts?.length ?? 0,
      };

      const response = await accountsApi.getContacts(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().whitelist.getMoreWhitelistContacts.status !== RS.RUNNING;
    },
  },
);

const createWhitelistContact = createAsyncThunk<
  CreateContactResponse,
  { newContact: Partial<Contact> },
  { state: RootState; rejectValue: FetchError }
>("WHITELIST/CREATE", async ({ newContact }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.createContact(revertTransformContact(newContact));
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const updateWhitelistContact = createAsyncThunk<
  UpdateContactResponse,
  { contactDiffs: Partial<Contact> },
  { state: RootState; rejectValue: FetchError }
>("WHITELIST/UPDATE", async ({ contactDiffs }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.updateContact(revertTransformContact(contactDiffs));
    return { response, contactDiffs };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const deleteWhitelistContacts = createAsyncThunk<
  { idsToDelete: string[]; response: Record<string, string> },
  { idsToDelete: string[] },
  { state: RootState; rejectValue: FetchError }
>("WHITELIST/DELETE", async ({ idsToDelete }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.deleteContacts(idsToDelete);
    return { response, idsToDelete };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const shareWhitelistContacts = createAsyncThunk<
  { idsToShare: string[]; response: Record<string, string> },
  { idsToShare: string[] },
  { state: RootState; rejectValue: FetchError }
>("WHITELIST/SHARE", async ({ idsToShare }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.shareContacts(idsToShare);
    return { response, idsToShare };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const setWhitelistGroups = createAsyncThunk<
  { response: Record<string, string> },
  { contactIds: string[]; groups: string[]; isShared: boolean },
  { state: RootState; rejectValue: FetchError }
>("WHITELIST/SET_GROUPS", async ({ contactIds, isShared, groups }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.setContactsGroups({
      groups: groups as never[],
      isShared,
      contactIds: contactIds as never[],
    });

    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const { reducer } = createSlice({
  name: "WHITELIST",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateWhitelistContactsSelection, (state, { payload }) => {
      state.selection = payload;
    });

    builder.addCase(getWhitelistContacts.pending, (state) => {
      state.getWhitelistContacts.status = RS.RUNNING;
      state.getWhitelistContacts.error = null;
    });
    builder.addCase(getWhitelistContacts.fulfilled, (state, { payload }) => {
      state.getWhitelistContacts.status = RS.IDLE;
      state.getWhitelistContacts.response = payload.response;

      state.contacts = transformContacts(payload.response.contacts.result ?? []);
      state.hasMore = payload.response.contacts.has_more;
      state.lastRequestOptions = {
        ...state.lastRequestOptions,
        ...payload.requestOptions,
      };
    });
    builder.addCase(getWhitelistContacts.rejected, (state, { payload }) => {
      state.getWhitelistContacts.status = RS.ERROR;
      state.getWhitelistContacts.error = payload;
    });

    builder.addCase(getMoreWhitelistContacts.pending, (state) => {
      state.getMoreWhitelistContacts.status = RS.RUNNING;
      state.getMoreWhitelistContacts.error = null;
    });
    builder.addCase(getMoreWhitelistContacts.fulfilled, (state, { payload }) => {
      state.getMoreWhitelistContacts.status = RS.IDLE;
      state.getMoreWhitelistContacts.response = payload.response;

      if (state.contacts) {
        state.contacts.push(...transformContacts(payload.response.contacts.result));
        // Reset the indexes
        state.contacts = state.contacts.map((rest, index) => ({
          ...rest,
          index,
        }));
      } else {
        state.contacts = transformContacts(payload.response.contacts.result ?? []);
      }

      state.hasMore = payload.response.contacts.has_more;
      state.lastRequestOptions = {
        ...state.lastRequestOptions,
        ...payload.requestOptions,
      };
    });
    builder.addCase(getMoreWhitelistContacts.rejected, (state, { payload }) => {
      state.getMoreWhitelistContacts.status = RS.ERROR;
      state.getMoreWhitelistContacts.error = payload;
    });

    builder.addCase(createWhitelistContact.pending, (state) => {
      state.createWhitelistContact.status = RS.RUNNING;
      state.createWhitelistContact.error = null;
    });
    builder.addCase(createWhitelistContact.fulfilled, (state, { payload }) => {
      state.createWhitelistContact.status = RS.IDLE;
      state.createWhitelistContact.response = payload.response;
      state.contacts = null; // Refresh the list
      state.hasMore = false;
    });

    builder.addCase(updateWhitelistContact.pending, (state) => {
      state.updateWhitelistContact.status = RS.RUNNING;
      state.updateWhitelistContact.error = null;
    });
    builder.addCase(updateWhitelistContact.fulfilled, (state, { payload }) => {
      state.updateWhitelistContact.status = RS.IDLE;

      const indexToEdit = state.contacts?.findIndex(
        (contact) => contact.id === payload.contactDiffs.id,
      );

      if (indexToEdit !== undefined && indexToEdit !== -1 && state.contacts) {
        const newContact = {
          ...state.contacts[indexToEdit],
          ...payload.contactDiffs,
          index: indexToEdit,
        } as Contact;

        state.contacts[indexToEdit] = newContact;
      }
    });

    builder.addCase(deleteWhitelistContacts.pending, (state) => {
      state.deleteWhitelistContacts.status = RS.RUNNING;
      state.deleteWhitelistContacts.error = null;
    });
    builder.addCase(deleteWhitelistContacts.fulfilled, (state, { payload }) => {
      state.deleteWhitelistContacts.status = RS.IDLE;

      if (state.contacts) {
        state.contacts = state.contacts.filter(
          (contact) => !payload.idsToDelete.includes(contact.id),
        );
        // Reset the indexes
        state.contacts = state.contacts.map((rest, index) => ({
          ...rest,
          index,
        }));
      }
    });

    builder.addCase(clearShareWhitelistContacts, (state) => {
      state.shareWhitelistContacts.status = RS.IDLE;
      state.shareWhitelistContacts.error = null;
      state.shareWhitelistContacts.response = null;
    });
    builder.addCase(shareWhitelistContacts.pending, (state) => {
      state.shareWhitelistContacts.status = RS.RUNNING;
      state.shareWhitelistContacts.error = null;
    });
    builder.addCase(shareWhitelistContacts.fulfilled, (state, { payload }) => {
      state.shareWhitelistContacts.status = RS.IDLE;
      state.shareWhitelistContacts.response = payload.response;
    });
    builder.addCase(shareWhitelistContacts.rejected, (state, { payload }) => {
      state.shareWhitelistContacts.status = RS.ERROR;
      state.shareWhitelistContacts.error = payload;
    });

    builder.addCase(clearSetWhitelistGroups, (state) => {
      state.setWhitelistGroups.status = RS.IDLE;
      state.setWhitelistGroups.error = null;
      state.setWhitelistGroups.response = null;
    });
    builder.addCase(setWhitelistGroups.pending, (state) => {
      state.setWhitelistGroups.status = RS.RUNNING;
      state.setWhitelistGroups.error = null;
    });
    builder.addCase(setWhitelistGroups.fulfilled, (state, { payload }) => {
      state.setWhitelistGroups.status = RS.IDLE;
      state.setWhitelistGroups.response = payload.response;
      state.contacts = null; // Refresh the list
    });
    builder.addCase(setWhitelistGroups.rejected, (state, { payload }) => {
      state.setWhitelistGroups.status = RS.ERROR;
      state.setWhitelistGroups.error = payload;
    });

    builder.addCase(clearWhitelistContacts, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export {
  setWhitelistGroups,
  getWhitelistContacts,
  createWhitelistContact,
  updateWhitelistContact,
  shareWhitelistContacts,
  deleteWhitelistContacts,
  getMoreWhitelistContacts,
};

export default reducer;
