import { Box } from "@alohi/kit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Avatar, makeStyles } from "ui";
import { possibleNextPlan } from "enums/plans";
import planTypeImages from "enums/planTypeImages";
import Currency from "components/Core/Currency/Currency";
import { durationMap, capitalizeString } from "helpers/string";
import { refund } from "stores/reducers/payment.refund.reducer";
import LinearInfiniteProgress from "components/Core/LinearInfiniteProgress/LinearInfiniteProgress";
import {
  selectPlanId,
  selectNextPlan,
  selectPlanType,
  getPlanCurrency,
  selectPlanDuration,
  selectPlanPurchasePrice,
} from "selectors/plan.selector";
import PlanCard from "./PlanCard";

function PreviewPlans() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planId = useSelector(selectPlanId);
  const nextPlan = useSelector(selectNextPlan);
  const planType = useSelector(selectPlanType);
  const planCurrency = useSelector(getPlanCurrency);
  const planDuration = useSelector(selectPlanDuration);
  const planPurchasePrice = useSelector(selectPlanPurchasePrice);

  useEffect(() => {
    if (planId) {
      /* Get refund id based on currentPlan id
       * it will be used to create a cart
       */
      dispatch(refund(planId));
    }
  }, [dispatch, planId]);

  const nextPlanType = nextPlan?.plan_type;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <PlanCard
        title={t("CHANGE_PLAN.CURRENT_PLAN")}
        img={<Avatar className={classes.bigAvatar} src={planTypeImages[planType]} alt={planType} />}
        primary={`${capitalizeString(planType)} ${durationMap(planDuration, false)}`}
        secondary={
          <>
            <Currency value={planPurchasePrice} currency={planCurrency} />
            <Box component="span">{` ${t("COMMON.PER")} ${durationMap(planDuration, true)}`}</Box>
          </>
        }
      />
      <Box className={classes.spacer}>
        <LinearInfiniteProgress />
      </Box>
      <PlanCard
        title={t("CHANGE_PLAN.NEXT_PLAN")}
        img={
          <Avatar
            alt={nextPlanType}
            className={classes.bigAvatar}
            src={planTypeImages[nextPlanType] ?? planTypeImages[possibleNextPlan[planType]]}
          />
        }
        primary={
          nextPlanType ? (
            `${capitalizeString(nextPlanType)} ${durationMap(nextPlan.duration_unit, false)}`
          ) : (
            <Box className={classes.minSpan}> </Box>
          )
        }
        secondary={
          nextPlan ? (
            <>
              <Currency value={nextPlan.price} currency={nextPlan.currency.toLowerCase()} />
              <Box component="span">{` ${t("COMMON.PER")} ${durationMap(
                nextPlan.duration_unit,
                true,
              )}`}</Box>
            </>
          ) : (
            <Box className={classes.minSpan}> </Box>
          )
        }
      />
    </Box>
  );
}

const useStyles = makeStyles({
  bigAvatar: {
    width: 60,
    height: 60,
  },
  spacer: {
    margin: "0 24px",
  },
  minSpan: {
    height: "21px",
    width: "21px",
  },
});

export default PreviewPlans;
