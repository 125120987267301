import throttle from "lodash/throttle";
import { useMemo, useEffect, useRef } from "react";

function useThrottle(callback, delay) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(
    () =>
      throttle((...args) => callbackRef.current(...args), delay, {
        leading: true,
        trailing: false,
      }),
    [delay],
  );
}

export default useThrottle;
