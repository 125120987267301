import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme, TextButton, Typography } from "@alohi/kit";

import { useAppSelector } from "stores/store";
import useBool, { UseBoolType } from "hooks/useBool";
import { selectIsPlanEnterprise } from "selectors/plan.selector";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import {
  selectAccountIsCorporateAdmin,
  selectAccountIsCorporateMember,
  selectAccountIsCorporateOwner,
} from "selectors/account.selector";

interface DocumentRetentionEditButtonProps {
  isFlippedBool: UseBoolType[1];
}

function DocumentRetentionEditButton({ isFlippedBool }: DocumentRetentionEditButtonProps) {
  const { alohi } = useTheme();
  const { t } = useTranslation();

  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const isCorporateAdmin = useAppSelector(selectAccountIsCorporateAdmin);
  const isCorporateOwner = useAppSelector(selectAccountIsCorporateOwner);

  const [isContactAdminModalOpen, isContactAdminModalOpenBool] = useBool(false);
  const [isRequireToUpgradeModalOpen, isRequireToUpgradeModalOpenBool] = useBool(false);

  const handleAction = useCallback(() => {
    if (isCorporateMember) {
      isContactAdminModalOpenBool.setTrue();
    } else if (!isPlanEnterprise) {
      isRequireToUpgradeModalOpenBool.setTrue();
    } else {
      if (isCorporateAdmin || isCorporateOwner) {
        isFlippedBool.setTrue();
      } else {
        isContactAdminModalOpenBool.setTrue();
      }
    }
  }, [
    isFlippedBool,
    isPlanEnterprise,
    isCorporateAdmin,
    isCorporateOwner,
    isCorporateMember,
    isContactAdminModalOpenBool,
    isRequireToUpgradeModalOpenBool,
  ]);

  return (
    <>
      <TextButton onClick={handleAction}>
        <Typography type="panelFrontHeader">{t("COMMON.EDIT")}</Typography>
        <Box sx={{ ml: 1, color: alohi.white }}>
          <FontAwesomeIcon icon={faPenToSquare} color="white" />
        </Box>
      </TextButton>

      {isRequireToUpgradeModalOpen ? (
        <RequireUpgradeToEnterprise
          title={t("PROFILE.DOCUMENT_RETENTION_TITLE")}
          handleClosure={isRequireToUpgradeModalOpenBool.setFalse}
          featureName={t("PROFILE.DOCUMENT_RETENTION_TITLE")}
        />
      ) : null}

      {isContactAdminModalOpen ? (
        <ContactAdminToUseFeature
          handleClosure={isContactAdminModalOpenBool.setFalse}
          title={t("PROFILE.DOCUMENT_RETENTION_TITLE")}
          description={t("PROFILE.DOCUMENT_RETENTION_UPGRADE_FEATURE")}
        />
      ) : null}
    </>
  );
}

export default DocumentRetentionEditButton;
