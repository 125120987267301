import { useTranslation } from "react-i18next";
import { Modal, Box, Typography, Button } from "@alohi/kit";
import { useCallback, useEffect, useMemo, useState } from "react";

import useBool from "hooks/useBool";
import { helpCenterUrls } from "routes/urls";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import { GalleryCoverSheet } from "views/SendFax/contexts/store";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { selectors, thunks } from "stores/reducers/coverSheet.reducer";
import { Table, GridSelectionModel, TableColumnsProp, makeStyles } from "ui";
import { selectCanIShareGalleryCoverSheet } from "selectors/ability.selector";
import EmptyTable from "../components/EmptyTable";
import TableRadio from "../components/TableRadio";
import LastTimeUsed from "../components/LastTimeUsed";
import UploadButton from "../components/UploadButton";
import TableThumbnail from "../components/TableThumbnail";
import useDownloadCoverSheet from "../hooks/useDownloadCoverSheet";
import ModalTitleWithHelperLink from "../components/ModalTitleWithHelperLink";
import AddCoverSheetModal from "../modals/AddCoverSheetModal/AddCoverSheetModal";
import ViewCoverSheetModal from "./ViewCoverSheetModal";
import RemoveCoverSheetModal from "./RemoveCoverSheetModal";
import UpdateCoverSheetModal from "./UpdateCoverSheetModal";
import ShareConfirmationModal from "./ShareConfirmationModal";

interface GalleryCoverSheetModalProps {
  handleClosure: (isSuccess: boolean) => void;
}

function GalleryCoverSheetModal({ handleClosure }: GalleryCoverSheetModalProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    sendFaxDispatch,
    sendFaxStore: { galleryCoverSheet },
  } = useSendFaxContext();

  const gallery = useAppSelector(selectors.gallery);
  const isListRunning = useAppSelector(selectors.isListRunning);
  const canShareCoverSheets = useAppSelector(selectCanIShareGalleryCoverSheet);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(
    galleryCoverSheet ? [galleryCoverSheet.id] : [],
  );
  const [idToRemove, setIdToRemove] = useState<null | string>(null);
  const [isAddCoverSheetModalOpen, isAddCoverSheetModalOpenBool] = useBool(false);
  const [idToView, setIdToView] = useState<null | { name: string; id: string }>(null);
  const [idToShare, setIdToShare] = useState<null | {
    name: string;
    id: string;
  }>(null);
  const [updateName, setUpdateName] = useState<null | {
    name: string;
    id: string;
  }>(null);

  const [selectedRowId] = selectionModel;
  const { downloadById } = useDownloadCoverSheet();

  const handleSelectionModel = useCallback((newSelectionModel: GridSelectionModel) => {
    setSelectionModel((prevModel) =>
      newSelectionModel.filter((newId) => !prevModel.includes(newId)),
    );
  }, []);

  const rows = useMemo(() => gallery ?? [], [gallery]);
  const isLoading = useMemo(() => isListRunning && gallery === null, [gallery, isListRunning]);

  const rowActions = useMemo(() => {
    return (index: number) => {
      return rows.map(({ id, name }) => {
        const options = [];

        options.push({
          label: t("COMMON.VIEW"),
          callback: () => {
            setIdToView({ id, name });
          },
        });

        options.push({
          label: t("COMMON.RENAME"),
          callback: () => {
            setUpdateName({ id, name });
          },
        });

        options.push({
          label: t("COMMON.DOWNLOAD"),
          callback: () => {
            downloadById(id, true);
          },
        });

        if (canShareCoverSheets) {
          options.push({
            label: "Divider",
          });

          options.push({
            label: t("COVER_SHEET.MOVE_TO_SHARED"),
            callback: () => {
              setIdToShare({ id, name });
            },
          });
        }

        options.push({
          label: "Divider",
        });

        options.push({
          label: t("COMMON.DELETE"),
          callback: () => {
            setIdToRemove(id);
          },
        });

        return options;
      })[index];
    };
  }, [canShareCoverSheets, downloadById, rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    columns.push({
      flex: 0.1,
      headerName: " ",
      sortable: false,
      field: "thumbnail",
      renderCell: function render({ row }: { row: GalleryCoverSheet }) {
        return <TableThumbnail thumbnail={row.thumbnail} />;
      },
    });

    columns.push({
      flex: 0.3,
      field: "name",
      sortable: false,
      headerName: t("COMMON.NAME"),
      renderCell: function render({ row }: { row: GalleryCoverSheet }) {
        return <Typography className={classes.row}>{row.name}</Typography>;
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "lastTimeUsed",
      headerName: t("COVER_SHEET.LAST_TIME_USED"),
      renderCell: function render({ row }: { row: GalleryCoverSheet }) {
        return <LastTimeUsed timestamp={row.lastTimeUsed} />;
      },
    });

    columns.push({
      flex: 0.1,
      sortable: false,
      field: "actions",
      headerName: " ",
      renderCell: function render({ row }: { row: GalleryCoverSheet }) {
        return <ColumnActions dataIndex={row.index} createActions={rowActions} />;
      },
    });

    return columns;
  }, [t, classes.row, rowActions]);

  const handleOnSelect = () => {
    const selection = rows.find(({ id }) => id === selectedRowId);

    if (selection) {
      sendFaxDispatch({
        type: "SET_GALLERY_COVER_SHEET",
        payload: {
          id: selection.id,
          name: selection.name,
          index: selection.index,
          thumbnail: selection.thumbnail,
          lastTimeUsed: selection.lastTimeUsed,
        },
      });
    }

    handleClosure(true);
  };

  const handleUpdateName = (updatedName: string | null) => {
    if (galleryCoverSheet && updatedName) {
      sendFaxDispatch({
        type: "SET_GALLERY_COVER_SHEET",
        payload: {
          ...galleryCoverSheet,
          name: updatedName,
        },
      });
    }

    setUpdateName(null);
  };

  const handleRemove = (isSuccess: boolean) => {
    if (isSuccess) {
      if (idToRemove === galleryCoverSheet?.id) {
        sendFaxDispatch({
          type: "CLEAR_GALLERY_COVER_SHEET",
        });
      }
    }

    setIdToRemove(null);
  };

  useEffect(() => {
    dispatch(thunks.list());
  }, [dispatch]);

  if (isAddCoverSheetModalOpen) {
    return (
      <AddCoverSheetModal
        isShared={false}
        disableAnimation
        title={t("COVER_SHEET.ADD_NEW_GALLERY")}
        handleClosure={isAddCoverSheetModalOpenBool.setFalse}
      />
    );
  }

  if (updateName) {
    return (
      <UpdateCoverSheetModal
        disableAnimation
        id={updateName.id}
        currentName={updateName.name}
        handleClosure={handleUpdateName}
      />
    );
  }

  if (idToRemove) {
    return <RemoveCoverSheetModal disableAnimation id={idToRemove} handleClosure={handleRemove} />;
  }

  if (idToView) {
    return (
      <ViewCoverSheetModal
        id={idToView.id}
        disableAnimation
        name={idToView.name}
        handleClosure={() => setIdToView(null)}
      />
    );
  }

  if (idToShare) {
    return (
      <ShareConfirmationModal
        id={idToShare.id}
        name={idToShare.name}
        handleClosure={() => setIdToShare(null)}
      />
    );
  }

  return (
    <Modal
      maxWidth="sm"
      scroll="paper"
      disableAnimation
      onConfirm={handleOnSelect}
      confirmTitle={t("COMMON.CONFIRM")}
      isConfirmDisabled={!Boolean(selectedRowId)}
      customFooterInfo={
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Button variant="gray" onClick={() => handleClosure(false)}>
            {t("COMMON.CANCEL")}
          </Button>
          <UploadButton
            onOpen={isAddCoverSheetModalOpenBool.setTrue}
            isDisabled={Boolean(gallery && gallery?.length >= 50)}
          />
        </Box>
      }
      title={
        <ModalTitleWithHelperLink
          title={t("COVER_SHEET.GALLERY")}
          link={helpCenterUrls.coverSheetHelp}
        />
      }
    >
      <Box mt={isLoading ? 2 : 0}>
        <Table
          noBorder
          rows={rows}
          rowHeight={76}
          columns={columns}
          checkboxSelection
          showTableWhenEmpty
          isLoading={isLoading}
          disableSelectionOnClick={false}
          selectionModel={selectionModel}
          getCellClassName={() => classes.cell}
          onSelectionModelChange={handleSelectionModel}
          components={{
            BaseCheckbox: TableRadio,
            NoRowsOverlay: () => (
              <EmptyTable isRunning={isListRunning} text="Cover Sheet Gallery is empty" />
            ),
          }}
        />
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    cursor: "text",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cell: {
    cursor: "pointer",
  },
}));

export default GalleryCoverSheetModal;
