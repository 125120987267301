import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { MouseEvent as ReactMouseEvent, useMemo, useState } from "react";
import { faUser, faHistory, faUserLock, faChartNetwork } from "@fortawesome/pro-light-svg-icons";
import {
  faUser as faUserSolid,
  faHistory as faHistorySolid,
  faUserLock as faUserLockSolid,
  faChartNetwork as faChartNetworkSolid,
} from "@fortawesome/pro-solid-svg-icons";

import { urls } from "routes/urls";
import { ButtonBase } from "ui";
import { clearRecentContacts } from "stores/reducers/contacts.recent.reducer";
import { clearWhitelistContacts } from "stores/reducers/contacts.whitelist.reducer";
import { clearBlacklistContacts } from "stores/reducers/contacts.blacklist.reducer";
import SharedDropdown from "./SharedDropdown";
import AppSubMenu, {
  AppSubMenuItem,
} from "components/Layout/PrivateLayout/components/AppSubMenu/AppSubMenu";

function ContactsNavigation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function handleOpenSharedContactsOrGroups(event: ReactMouseEvent<HTMLDivElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  const sharedURL =
    pathname === urls.contacts.sharedContacts
      ? urls.contacts.sharedContacts
      : urls.contacts.sharedGroups;

  const items: AppSubMenuItem[] = useMemo(
    () =>
      [
        {
          label: t("CONTACTS.CONTACTS"),
          to: urls.contacts.root,
          icon: pathname === urls.contacts.root ? faUserSolid : faUser,
          onClick: () => {
            if (pathname === urls.contacts.root) {
              dispatch(clearWhitelistContacts());
            }
          },
        },
        {
          label: t("CONTACTS.BLACKLIST"),
          to: urls.contacts.blacklist,
          icon: pathname === urls.contacts.blacklist ? faUserLockSolid : faUserLock,
          onClick: () => {
            if (pathname === urls.contacts.blacklist) {
              dispatch(clearBlacklistContacts());
            }
          },
        },
        {
          label: t("CONTACTS.RECENT"),
          to: urls.contacts.recent,
          icon: pathname === urls.contacts.recent ? faHistorySolid : faHistory,
          onClick: () => {
            if (pathname === urls.contacts.recent) {
              dispatch(clearRecentContacts());
            }
          },
        },
        {
          label: t("CONTACTS.SHARED"),
          to: sharedURL,
          component: ButtonBase,
          icon:
            pathname === urls.contacts.sharedContacts || pathname === urls.contacts.sharedGroups
              ? faChartNetworkSolid
              : faChartNetwork,
          onClick: handleOpenSharedContactsOrGroups,
        },
      ] satisfies AppSubMenuItem[],
    [dispatch, pathname, sharedURL, t],
  );

  return (
    <>
      <AppSubMenu items={items} />
      {anchorEl ? (
        <SharedDropdown anchorEl={anchorEl} clearAnchorEl={() => setAnchorEl(null)} />
      ) : null}
    </>
  );
}

export default ContactsNavigation;
