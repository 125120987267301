import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const getAll = ({ iso }, { signal } = {}) => {
  const requestOptions = {
    method: "GET",
    // headers: authHeader()
  };
  const path = `${API_ESHOP}/available_countries/${iso}/areas`;
  return fetchWithRefresh(path, { ...requestOptions, signal });
};
export const areasApi = {
  getAll,
};
