import { makeId } from "helpers/string";

export interface DefaultRequestOptions {
  limit: number;
  offset: number;
  shared: boolean;
  search: string;
  sortBy: string;
  type: string;
  direction: string;
}

export interface Contact {
  id: string;
  note: string;
  index: number;
  groups: string[];
  fullName: string;
  faxNumber: string;
  phone: string;
  cellPhone: string;
  email: string;
  isTelefax: boolean;
}

export interface ContactResponse {
  id: string;
  phone: string;
  email: string;
  tags: string[];
  comment: string;
  fullname: string;
  cellphone: string;
  fax_number: string;
  is_telefax: boolean;
}

export interface RecentContact {
  id: string;
  index: number;
  faxNumber: string;
}

export interface Group {
  id: string;
  note: string;
  name: string;
  index: number;
  membersCount: number;
}

export interface GroupResponse {
  id: string;
  name: string;
  comment: string;
  members_count: number;
}

export interface RecentContactResponse {
  number: string;
  timestamp: string;
}

export interface GetContactsResponse {
  requestOptions: DefaultRequestOptions;
  response: {
    contacts: {
      has_more: boolean;
      result: ContactResponse[];
    };
  };
}

export interface GetGroupsResponse {
  requestOptions: DefaultRequestOptions;
  response: {
    has_more: boolean;
    result: GroupResponse[];
  };
}

export interface CreateContactResponse {
  response: Record<string, string>;
}

export interface UpdateContactResponse {
  response: Record<string, never>;
  contactDiffs: Partial<ContactResponse>;
}

export interface CreateGroupResponse {
  response: Record<string, string>;
}

export interface UpdateGroupResponse {
  response: Record<string, string>;
  groupDiffs: Partial<GroupResponse>;
}

export interface BlacklistContact {
  id: string;
  note: string;
  index: number;
  fullName: string;
  faxNumber: string;
}

export interface BlacklistContactResponse {
  id: string;
  comment: string;
  fullname: string;
  fax_number: string;
}

export interface GetBlacklistContactsResponse {
  requestOptions: DefaultRequestOptions;
  response: {
    has_more: boolean;
    result: BlacklistContactResponse[];
  };
}

export interface GetRecentContactsResponse {
  response: {
    recent_destinations: RecentContactResponse[];
  };
}

export function transformContacts(contacts: ContactResponse[]): Contact[] {
  return contacts.map((contact, index) => ({
    index,
    id: contact.id ?? "",
    phone: contact.phone ?? "",
    email: contact.email ?? "",
    note: contact.comment ?? "",
    groups: contact.tags ?? [],
    fullName: contact.fullname ?? "",
    faxNumber: contact.fax_number ?? "",
    cellPhone: contact.cellphone ?? "",
    isTelefax: contact.is_telefax ?? false,
  }));
}

export function revertTransformContact(contact: Partial<Contact>): ContactResponse {
  return {
    id: contact.id ?? "",
    phone: contact.phone ?? "",
    email: contact.email ?? "",
    comment: contact.note ?? "",
    tags: contact.groups ?? [],
    fullname: contact.fullName ?? "",
    fax_number: contact.faxNumber ?? "",
    cellphone: contact.cellPhone ?? "",
    is_telefax: contact.isTelefax ?? false,
  };
}

export function transformBlacklistContacts(
  contacts: BlacklistContactResponse[],
): BlacklistContact[] {
  return contacts.map((contact, index) => ({
    index,
    id: contact.id ?? "",
    note: contact.comment ?? "",
    fullName: contact.fullname ?? "",
    faxNumber: contact.fax_number ?? "",
  }));
}

export function revertTransformBlacklistContact(
  contact: Partial<BlacklistContact>,
): BlacklistContactResponse {
  return {
    id: contact.id ?? "",
    comment: contact.note ?? "",
    fullname: contact.fullName ?? "",
    fax_number: contact.faxNumber ?? "",
  };
}

export function transformRecentContacts(contacts: RecentContactResponse[]): RecentContact[] {
  return contacts.map((contact, index) => ({
    index,
    id: makeId(5),
    faxNumber: contact.number ?? "",
  }));
}

export function transformGroups(groups: GroupResponse[]): Group[] {
  return groups.map((group, index) => ({
    index,
    name: group.name,
    id: group.id ?? "",
    note: group.comment ?? "",
    membersCount: group.members_count ?? 0,
  }));
}

export function revertTransformGroups(group: Partial<Group>): GroupResponse {
  return {
    id: group.id ?? "",
    name: group.name ?? "",
    comment: group.note ?? "",
    members_count: group.membersCount ?? 0,
  };
}
