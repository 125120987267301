import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { faComments } from "@fortawesome/pro-light-svg-icons";
import { FaButton, Typography, makeStyles, Box } from "@alohi/kit";

import useBool from "hooks/useBool";
import { boxNames } from "enums/faxes";
import AddNoteOnFaxModal from "views/Faxes/modals/AddNoteOnFaxModal";
import { selectCanIUseSharedNotes } from "selectors/ability.selector";
import {
  selectCdrId,
  selectCdrSharedNote,
  selectCdrFullNoteById,
  selectCdrDisplayedNote,
  selectCdrStatusMessage,
} from "selectors/faxes.selector";

interface FaxesColumnNoteProps {
  dataIndex: number;
  boxName: keyof typeof boxNames;
}

function FaxesColumnNote({ boxName, dataIndex }: FaxesColumnNoteProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isNoteModalOpen, isNoteModalOpenBool] = useBool(false);

  const cdrId = useSelector(selectCdrId(boxName, dataIndex));
  const canIUseSharedNoted = useSelector(selectCanIUseSharedNotes);
  const fullNote = useSelector(selectCdrFullNoteById(boxName, cdrId));
  const note = useSelector(selectCdrDisplayedNote(boxName, dataIndex));
  const sharedNote = useSelector(selectCdrSharedNote(boxName, dataIndex));
  const statusMessage = useSelector(selectCdrStatusMessage(boxName, dataIndex));

  const renderNotes = useMemo(() => {
    if (note || sharedNote?.text) {
      return (
        <Box className={classes.root}>
          {sharedNote?.text && boxName === boxNames.inbox && canIUseSharedNoted ? (
            <FaButton
              icon={faComments}
              className={classes.button}
              onClick={isNoteModalOpenBool.setTrue}
              tooltip={
                <Box>
                  <Box>{sharedNote.text}</Box>
                  <Box>
                    {t("FAXES.LAST_MODIFIED_SHARED_NOTE", {
                      name: sharedNote.name ?? "",
                    })}
                  </Box>
                  <Box>{sharedNote.date}</Box>
                </Box>
              }
            />
          ) : null}

          <Typography type="body" className={classes.ellipsis} data-cy={`CdrNote-row-${dataIndex}`}>
            {note}
          </Typography>
        </Box>
      );
    }

    return null;
  }, [
    t,
    note,
    boxName,
    dataIndex,
    sharedNote,
    classes.root,
    classes.button,
    classes.ellipsis,
    canIUseSharedNoted,
    isNoteModalOpenBool.setTrue,
  ]);

  const renderStatusMessage = useMemo(() => {
    if (boxName !== boxNames.outbox && statusMessage) {
      return (
        <Typography
          type="body"
          style={{ fontStyle: "italic" }}
          data-cy={`CdrNote-row-${dataIndex}`}
        >
          {statusMessage}
        </Typography>
      );
    }

    return null;
  }, [boxName, dataIndex, statusMessage]);

  return (
    <>
      {renderNotes}

      {renderStatusMessage}

      {isNoteModalOpen ? (
        <AddNoteOnFaxModal
          cdrId={cdrId}
          boxName={boxName}
          dataIndex={dataIndex}
          currentNote={fullNote ?? ""}
          currentSharedNote={sharedNote}
          handleClosure={isNoteModalOpenBool.setFalse}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ alohi, breakpoints }) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    background: alohi.lightestBlue,
  },
  ellipsis: {
    maxWidth: 300,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [breakpoints.down("md")]: {
      maxWidth: 200,
    },
    [breakpoints.down("sm")]: {
      maxWidth: 100,
    },
  },
}));

export default memo(FaxesColumnNote);
