import { alohi, Box, makeStyles, SvgBox, Typography } from "@alohi/kit";
import FaxPlusLogo from "assets/svg/fax_plus_logo.svg?react";
import MobileIcon from "assets/svg/icons/mobile.svg";
import SignatureRequestIcon from "assets/svg/icons/signature-request.svg";
import SignatureIcon from "assets/svg/icons/signature.svg";
import TeamsIcon from "assets/svg/icons/teams.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface KeyFeaturesProps {
  className?: string;
}

function KeyFeatures({ className }: KeyFeaturesProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.keyFeatureContainer}>
        <Box className={classes.svgContainer}>
          <FaxPlusLogo width={24} />
        </Box>
        <Box className={classes.textContainer}>
          <Typography type="flow-body" isBold>
            {t("INCENTIVE_SIGN.INTEGRATED_WITH_FAX")}
          </Typography>
          <Typography className={classes.textSecondLine} type="flow-body">
            {t("INCENTIVE_SIGN.EASILY_MANAGE_SIGNATURES")}
          </Typography>
        </Box>
      </Box>

      <Typography className={classes.keyFeatures} type="flow-body" isBold>
        {t("INCENTIVE_SIGN.KEY_FEATURES")}
      </Typography>

      <Box className={classes.grid}>
        <Box className={classes.keyFeatureContainer}>
          <Box className={classes.svgContainer}>
            <SvgBox className={classes.svg} src={SignatureIcon} />
          </Box>
          <Box className={classes.textContainer}>
            <Typography type="flow-body" isBold>
              {t("INCENTIVE_SIGN.ESIGNATURE")}
            </Typography>
            <Typography className={classes.textSecondLine} type="flow-body">
              {t("INCENTIVE_SIGN.EASY_AND_LEGALLY_BINDING")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.keyFeatureContainer}>
          <Box className={classes.svgContainer}>
            <SvgBox className={classes.svg} src={SignatureRequestIcon} />
          </Box>
          <Box className={classes.textContainer}>
            <Typography type="flow-body" isBold>
              {t("INCENTIVE_SIGN.SIGNATURE_REQUESTS")}
            </Typography>
            <Typography className={classes.textSecondLine} type="flow-body">
              {t("INCENTIVE_SIGN.FROM_ONE_OR_MULTIPLE")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.keyFeatureContainer}>
          <Box className={classes.svgContainer}>
            <SvgBox className={classes.svg} src={TeamsIcon} />
          </Box>
          <Box className={classes.textContainer}>
            <Typography type="flow-body" isBold>
              {t("INCENTIVE_SIGN.TEAMS")}
            </Typography>
            <Typography className={classes.textSecondLine} type="flow-body">
              {t("INCENTIVE_SIGN.TO_COLLABORATE_TOGETHER")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.keyFeatureContainer}>
          <Box className={classes.svgContainer}>
            <SvgBox className={classes.svg} src={MobileIcon} />
          </Box>
          <Box className={classes.textContainer}>
            <Typography type="flow-body" isBold>
              {t("INCENTIVE_SIGN.INTEGRATIONS")}
            </Typography>
            <Typography className={classes.textSecondLine} type="flow-body">
              {t("INCENTIVE_SIGN.MOBILE_API_OTHERS")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    display: "flex",
    flexDirection: "column",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    rowGap: spacing(3),
    [breakpoints.down("sm")]: {
      rowGap: spacing(0),
    },
  },
  keyFeatureContainer: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    paddingRight: spacing(1),
    [breakpoints.down("sm")]: {
      marginTop: spacing(2),
    },
  },
  svgContainer: {
    minWidth: "40px",
    height: "40px",
    borderRadius: 999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: alohi.neutral150,
  },
  svg: {
    width: "24px",
  },
  textContainer: {
    marginLeft: spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  textSecondLine: {
    marginTop: spacing(0.5),
    color: alohi.neutral500,
  },
  keyFeatures: {
    padding: spacing(4, 0, 3, 0),
    [breakpoints.down("sm")]: {
      padding: spacing(4, 0, 2, 0),
    },
  },
}));

export default KeyFeatures;
