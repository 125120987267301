import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO, API_STORAGE } from "config";
const ENTITY = "accounts";

const transformUpdateAccountBody = (newAccountData, updateType) => {
  let body = {};
  switch (updateType) {
    case "profileImage":
      body = {
        profile_image: newAccountData.profileImage,
      };
      break;
    case "myFax":
      body = {
        account_data: {
          save_history: newAccountData.saveHistory,
          default_file_type: newAccountData.defaultFileType,
        },
        settings: {
          caller_id_name: newAccountData.callerIdName,
        },
      };
      break;
    case "settings":
      body = {
        settings: {
          send_fax: {
            retry: newAccountData.hasFaxRetry ? newAccountData.faxRetry : { count: 0, delay: 0 },
            should_enhance: newAccountData.shouldEnhance,
            notify_receiver: newAccountData.notifyReceiver,
            options: newAccountData.hasFaxToHuman
              ? {
                  announcement: {
                    data: {
                      gender: newAccountData.faxToHuman.gender,
                      language: newAccountData.faxToHuman.language,
                      from_fax_plus: newAccountData.faxToHuman.dialogue === "withad",
                    },
                  },
                }
              : {},
          },
        },
      };
      break;
    case "myInfo":
      body = {
        email: newAccountData.email,
        name: newAccountData.firstName,
        lastname: newAccountData.lastName,
        profile_image: newAccountData.profileImage,
      };
      break;
    case "emailToFax":
      body = {
        settings: {
          external_call_initiation: {
            email_to_fax: {
              enabled: newAccountData.sendFaxViaEmail,
              ignore_email_content: newAccountData.ignoreEmailContent,
            },
          },
        },
      };
      break;
    default:
      break;
  }
  return body;
};

const getAccountDetails = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/accounts/v2`;
  return fetchWithRefresh(path, requestOptions);
};

const getContacts = ({ type, search, limit = 20, offset, sortBy, shared, direction } = {}) => {
  const requestOptions = {
    method: "GET",
  };

  let path = `${API_AFFOGATO}/${ENTITY}/v2/contacts`;

  const qs = [];

  if (search) {
    qs.push(`search=${encodeURIComponent(search)}`);
  }
  if (type) {
    qs.push(`type=${type}`);
  }
  if (limit) {
    qs.push(`limit=${limit}`);
  }
  if (offset !== 0) {
    qs.push(`offset=${offset}`);
  }
  if (sortBy) {
    qs.push(`sort=${sortBy}`);
  }
  if (direction) {
    qs.push(`direction=${direction}`);
  }

  if (qs.length) {
    path += "?" + qs.join("&");
  }

  if (shared !== undefined) {
    path += `&shared=${shared}`;
  }

  return fetchWithRefresh(path, requestOptions);
};

const getGroups = ({ type, search, limit, offset, shared } = {}) => {
  const requestOptions = {
    method: "GET",
  };

  let path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/tags`;

  const qs = [];

  if (limit) {
    qs.push(`limit=${limit}`);
  }
  if (offset !== 0) {
    qs.push(`offset=${offset}`);
  }
  if (search) {
    qs.push(`tag=${search}`);
  }
  if (type) {
    qs.push(`type=${type}`);
  }
  if (shared) {
    qs.push(`shared=${shared}`);
  }

  if (qs.length) {
    path += "?" + qs.join("&");
  }
  return fetchWithRefresh(path, requestOptions);
};

const createContact = (data, isShared = false) => {
  const requestOptions = {
    method: "POST",
    body: {
      cellphone: "",
      comment: "",
      email: "",
      fax_number: "",
      fullname: "",
      is_telefax: false,
      phone: "",
      tags: [],
      ...data,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/create?shared=${isShared}`;

  return fetchWithRefresh(path, requestOptions);
};

const updateContact = (contact) => {
  const { id, ...restOfContact } = contact;

  const requestOptions = {
    method: "POST",
    body: {
      contact_id: id,
      cellphone: "",
      comment: "",
      email: "",
      fax_number: "",
      fullname: "",
      is_telefax: false,
      phone: "",
      tags: [],
      ...restOfContact,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/update`;

  return fetchWithRefresh(path, requestOptions);
};

const importContacts = (value) => {
  const requestOptions = {
    method: "PATCH",
    body: [
      {
        op: "add_contacts",
        value: value.map((element) => ({
          tags: element.tags,
          email: element.email,
          comment: element.comment,
          fullname: element.nameAndDuplicate.name,
          phone: element.phone ?? "",
          cellphone: element.cellphone ?? "",
          fax_number: element.faxNumber ?? "",
        })),
      },
    ],
  };
  const path = `${API_AFFOGATO}/${ENTITY}/contacts`;
  return fetchWithRefresh(path, requestOptions);
};

const deleteContacts = (values) => {
  const requestOptions = {
    method: "POST",
    body: { contact_ids: values },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/delete`;

  return fetchWithRefresh(path, requestOptions);
};

const shareContacts = (values) => {
  const requestOptions = {
    method: "POST",
    body: { contact_ids: values },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/share`;

  return fetchWithRefresh(path, requestOptions);
};

const shareGroups = (values) => {
  const requestOptions = {
    method: "POST",
    body: { tag_ids: values },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/tags/share`;

  return fetchWithRefresh(path, requestOptions);
};

const createBlackList = (data) => {
  if ("id" in data) {
    delete data["id"];
  }

  const requestOptions = {
    method: "POST",
    body: {
      comment: "",
      fullname: "",
      fax_number: "",
      ...data,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/blacklist`;

  return fetchWithRefresh(path, requestOptions);
};
const updateBlackList = (contact) => {
  const { id, ...restContact } = contact;

  const requestOptions = {
    method: "PUT",
    body: {
      comment: "",
      fax_number: "",
      fullname: "",
      ...restContact,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/blacklist/${id}`;
  return fetchWithRefresh(path, requestOptions);
};
const deleteBlackList = (arr_ids) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "delete",
      list: arr_ids,
    },
  };
  const path = `${API_AFFOGATO}/${ENTITY}/blacklist`;
  return fetchWithRefresh(path, requestOptions);
};

const createGroup = (data, isShared = false) => {
  const requestOptions = {
    method: "POST",
    body: {
      name: "",
      comment: "",
      ...data,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/tags/create?shared=${isShared}`;

  return fetchWithRefresh(path, requestOptions);
};

const updateGroup = (group) => {
  const { id, ...restGroup } = group;

  if ("members_count" in restGroup) {
    delete restGroup["members_count"];
  }

  const requestOptions = {
    method: "POST",
    body: {
      name: "",
      comment: "",
      tag_id: id,
      ...restGroup,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/tags/update`;

  return fetchWithRefresh(path, requestOptions);
};

const deleteGroupById = (ids) => {
  const requestOptions = {
    method: "POST",
    body: {
      tag_ids: ids,
    },
  };

  const path = `${API_AFFOGATO}/${ENTITY}/v2/contacts/tags/delete`;

  return fetchWithRefresh(path, requestOptions);
};

const getBlackList = ({ type, search, limit = 20, offset, sortBy, direction } = {}) => {
  const requestOptions = {
    method: "GET",
  };
  let path = `${API_AFFOGATO}/${ENTITY}/blacklist`;
  const qs = [];
  if (search) {
    qs.push(`search=${search}`);
    qs.push(`type=full_contacts`);
  }
  if (type) {
    qs.push(`type=${type}`);
  }
  if (limit) {
    qs.push(`limit=${limit}`);
  }
  if (offset !== 0) {
    qs.push(`offset=${offset}`);
  }
  if (sortBy) {
    qs.push(`sort=${sortBy}`);
  }
  if (direction) {
    qs.push(`direction=${direction}`);
  }
  if (qs.length) {
    path += "?" + qs.join("&");
  }
  return fetchWithRefresh(path, requestOptions);
};

const getRecent = ({ type, search } = {}) => {
  const requestOptions = {
    method: "GET",
  };

  let path = `${API_AFFOGATO}/${ENTITY}/recent_destinations`;

  const qs = [];

  if (search) {
    qs.push(`search=${search}`);
  }
  if (type) {
    qs.push(`type=${type}`);
  }
  if (qs.length) {
    path += "?" + qs.join("&");
  }

  return fetchWithRefresh(path, requestOptions);
};

const updateAccountDetails = (accountData, updateType) => {
  const requestOptions = {
    method: "PUT",
    body: transformUpdateAccountBody(accountData, updateType),
  };
  const path = `${API_AFFOGATO}/accounts/self`;
  return fetchWithRefresh(path, requestOptions);
};

const forgotUsername = (phoneNumber) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/accounts/forgot_username?phone=${encodeURIComponent(phoneNumber)}`;
  return fetchWithRefresh(path, requestOptions);
};

const getSsoSettings = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/accounts/sso_settings`;
  return fetchWithRefresh(path, requestOptions);
};

const updateSsoSettings = ({
  ssoUrl,
  x509Cert,
  entityId,
  isLegacy,
  isOnlyAllowSso,
  isAddFaxLicense,
  isAddSignLicense,
  refreshTokenLifetime = 3600,
}) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      sso_url: ssoUrl,
      entity_id: entityId,
      x509_cert: x509Cert,
      legacy_sso: isLegacy,
      only_allow_sso: !isOnlyAllowSso,
      add_fax_license: isAddFaxLicense,
      add_sign_license: isAddSignLicense,
      refresh_token_lifetime: refreshTokenLifetime,
    },
  };
  const path = `${API_AFFOGATO}/accounts/sso_settings`;
  return fetchWithRefresh(path, requestOptions);
};

const deactivateSso = () => {
  const requestOptions = {
    method: "DELETE",
  };
  const path = `${API_AFFOGATO}/accounts/sso_settings`;
  return fetchWithRefresh(path, requestOptions);
};

const activateAdvancedSecurity = () => {
  const requestOptions = {
    method: "PUT",
    body: {
      settings: {
        security: {
          advanced_security: {
            enabled: true,
          },
        },
      },
    },
  };
  const path = `${API_AFFOGATO}/accounts/self`;
  return fetchWithRefresh(path, requestOptions);
};

const updateDocumentRetention = ({ incomingRetentionDays, outgoingRetentionDays }) => {
  const requestOptions = {
    method: "PUT",
    body: {
      settings: {
        security: {
          incoming_retention_days: incomingRetentionDays,
          outgoing_retention_days: outgoingRetentionDays,
        },
      },
    },
  };

  const path = `${API_AFFOGATO}/accounts/self`;
  return fetchWithRefresh(path, requestOptions);
};

const fetchDataRegions = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_STORAGE}/storage_region`;
  return fetchWithRefresh(path, requestOptions);
};

const fetchUserDataResidencySettings = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_STORAGE}/storage/user_settings`;
  return fetchWithRefresh(path, requestOptions);
};

const updateDataLocation = ({ liveData, backupData }) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "set_region",
      region: liveData,
      backup_region: backupData,
    },
  };
  const path = `${API_STORAGE}/storage/user_settings`;
  return fetchWithRefresh(path, requestOptions);
};

const getMigrationStatus = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_STORAGE}/storage/migration`;
  return fetchWithRefresh(path, requestOptions);
};

const setPermissions = ({ cid, permissionSet, uid }) => {
  const requestOptions = {
    method: "PUT",
    body: {
      cid,
      permission_set: permissionSet,
      uid,
    },
  };
  const path = `${API_AFFOGATO}/accounts/v2/permissions`;
  return fetchWithRefresh(path, requestOptions);
};

const getCustomerInfo = ({ cid }) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/accounts/v2/customer_info/${cid}`;
  return fetchWithRefresh(path, requestOptions);
};

const transferOwnership = ({ newOwnerUid, cid }) => {
  const requestOptions = {
    method: "POST",
    body: {
      cid,
      uid_new_owner: newOwnerUid,
    },
  };
  const path = `${API_AFFOGATO}/accounts/v2/transfer_ownership`;
  return fetchWithRefresh(path, requestOptions);
};

const acceptTos = () => {
  const requestOptions = {
    method: "POST",
  };
  const path = `${API_AFFOGATO}/accounts/v2/tos`;
  return fetchWithRefresh(path, requestOptions);
};

const updateAnonymous = ({ allow }) => {
  const requestOptions = {
    method: "POST",
    body: {
      allow,
    },
  };
  const path = `${API_AFFOGATO}/accounts/blacklist/settings/anonymous`;
  return fetchWithRefresh(path, requestOptions);
};

const setContactsGroups = ({ contactIds = [], groups = [], isShared = false }) => {
  const requestOptions = {
    method: "POST",
    body: {
      tags: groups,
      shared: isShared,
      contact_ids: contactIds,
    },
  };

  const path = `${API_AFFOGATO}/accounts/v2/contacts/tags_bulk`;

  return fetchWithRefresh(path, requestOptions);
};

const accountDelete = () => {
  const requestOptions = {
    method: "DELETE",
  };

  const path = `${API_AFFOGATO}/accounts/self`;

  return fetchWithRefresh(path, requestOptions);
};

export const accountsApi = {
  getAccountDetails,
  accountDelete,
  updateAccountDetails,
  getContacts,
  createContact,
  updateContact,
  deleteContacts,
  getBlackList,
  createBlackList,
  updateBlackList,
  deleteBlackList,
  getRecent,
  getGroups,
  createGroup,
  updateGroup,
  importContacts,
  deleteGroupById,
  forgotUsername,
  getSsoSettings,
  updateSsoSettings,
  deactivateSso,
  fetchDataRegions,
  updateDataLocation,
  getMigrationStatus,
  fetchUserDataResidencySettings,
  activateAdvancedSecurity,
  setPermissions,
  transferOwnership,
  getCustomerInfo,
  acceptTos,
  updateDocumentRetention,
  shareContacts,
  shareGroups,
  updateAnonymous,
  setContactsGroups,
};
