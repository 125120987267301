import { useState } from "react";

const useForceRemount = (): [number, () => void] => {
  const [key, setKey] = useState(0);

  const forceRemount = () => {
    setKey((prevKey) => prevKey + 1);
  };

  return [key, forceRemount];
};

export default useForceRemount;
