import { Button } from "@alohi/kit";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import InactivePlanModal from "views/InactivePlan/InactivePlanModal";

function RenewPlanButton() {
  const { t } = useTranslation();

  const [openInactivePlan, setOpenInactivePlan] = useState(false);

  return (
    <>
      <Button variant="white" onClick={() => setOpenInactivePlan(true)}>
        {t("PROFILE.RENEW_PLAN")}
      </Button>
      {openInactivePlan && <InactivePlanModal handleClosure={() => setOpenInactivePlan(false)} />}
    </>
  );
}

export default memo(RenewPlanButton);
