import { useTranslation } from "react-i18next";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "ui";

function DisabledForCompliance() {
  const { t } = useTranslation();
  return (
    <Typography variant="caption">
      <FontAwesomeIcon style={{ marginRight: 8 }} icon={faLock} />
      {t("PROFILE.DISABLED_FOR_SECURITY_COMPLIANCE")}
    </Typography>
  );
}

export default DisabledForCompliance;
