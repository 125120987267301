import queryString from "query-string";
import { useSnackbar } from "@alohi/kit";
import { useLocation } from "react-router";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { plans } from "enums/plans";
import { getI18nCountry } from "helpers/numbers";
import { selectIsPlanFree } from "selectors/plan.selector";
import ChangePlanToCustom from "components/Payment/ChangePlanToCustom";
import { selectPreviewFileErrorMessage } from "selectors/faxes.selector";
import { selectIsEmailVerificationRequired } from "selectors/ability.selector";
import { clearPreviewFile, faxesAsyncActions } from "stores/reducers/faxes.reducer";
import { selectAccountIsInactive, selectEmailIsUnverified } from "selectors/account.selector";

function FaxesHandleQueryString() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { iso, from, to, type, fax, ["plan-type"]: planType, period } = queryString.parse(search);

  const isPlanFree = useSelector(selectIsPlanFree);
  const accountIsInactive = useSelector(selectAccountIsInactive);
  const emailIsUnverified = useSelector(selectEmailIsUnverified);
  const previewFileError = useSelector(selectPreviewFileErrorMessage);
  const isEmailVerificationRequired = useSelector(selectIsEmailVerificationRequired);

  const handleReplacePath = useCallback(() => {
    const url = new URL(window.location.href);
    url.search = ""; // This removes all query parameters
    window.history.replaceState({}, "", url);
  }, []);

  // Open cdr from email link
  useEffect(() => {
    if (fax && !accountIsInactive && !accountIsInactive) {
      handleReplacePath();
      dispatch(faxesAsyncActions.markAsRead({ cdrIds: [fax] }));
      dispatch(
        faxesAsyncActions.previewFile({
          cdrId: fax,
          excludeDeleted: true,
        }),
      );
    }
  }, [accountIsInactive, dispatch, fax, handleReplacePath]);

  useEffect(() => {
    if (previewFileError) {
      enqueueSnackbar(previewFileError, { variant: "error" });
      dispatch(clearPreviewFile());
    }
  }, [dispatch, enqueueSnackbar, previewFileError]);

  if ((emailIsUnverified && isEmailVerificationRequired) || accountIsInactive) {
    return null;
  }

  if (from === "pricing" && to === "change_plan" && type && Object.values(plans).includes(type)) {
    if (type === "free") {
      return null;
    }
    handleReplacePath();

    return <ChangePlanToCustom planType={planType} period={period} type={type} iso={iso} />;
  }

  if (
    from === "pricing" &&
    to === "change_plan" &&
    iso &&
    (getI18nCountry(iso)?.label ?? "") &&
    isPlanFree
  ) {
    handleReplacePath();

    return (
      <ChangePlanToCustom planType={planType} period={period} type={plans.premium} iso={iso} />
    );
  }

  if (from === "pricing" && to === "change_plan") {
    handleReplacePath();

    return (
      <ChangePlanToCustom planType={planType} period={period} type={plans.premium} iso={iso} />
    );
  }

  return null;
}

export default FaxesHandleQueryString;
