import { API_AFFOGATO, API_RISTRETTO } from "config";
import { fetchWithRefresh } from "helpers";

const get = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/referral_invite_link`;
  return fetchWithRefresh(path, requestOptions);
};

const getClientInfo = (clientId) => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/oauth/client_public?client_id=${clientId}`;
  return fetchWithRefresh(path, requestOptions);
};

const getClientPrivateInfo = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/oauth/client_private`;
  return fetchWithRefresh(path, requestOptions);
};

const updateClientPrivateInfo = ({ redirectionUris } = {}) => {
  const requestOptions = {
    method: "PUT",
    body: { redirection_uris: redirectionUris },
  };
  const path = `${API_AFFOGATO}/oauth/client_private`;
  return fetchWithRefresh(path, requestOptions);
};

const getAuthNToken = ({ accessToken }) => {
  const requestOptions = {
    method: "POST",
    body: { access_token: accessToken },
  };
  const path = `${API_AFFOGATO}/oauth/authorize/by_access_token`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true });
};

const authorizeApp = ({ authNToken, authZToken, state, isCancel = false }) => {
  const queryStr = isCancel ? "&user_canceled=true" : "";
  const hasState = state ? "&state=" + state : "";

  const requestOptions = {
    method: "GET",
  };
  const path = `${API_RISTRETTO}/code?authn_token=${authNToken}&authz_token=${
    authZToken + queryStr + hasState
  }`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true });
};

const getGrants = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/oauth/third_party_grants`;
  return fetchWithRefresh(path, requestOptions);
};

const revokeGrant = (client_id) => {
  const requestOptions = {
    method: "DELETE",
  };
  const path = `${API_AFFOGATO}/oauth/third_party_grants/${client_id}`;
  return fetchWithRefresh(path, requestOptions);
};

export const oauthApi = {
  get,
  getClientInfo,
  getClientPrivateInfo,
  updateClientPrivateInfo,
  getAuthNToken,
  authorizeApp,
  getGrants,
  revokeGrant,
};
