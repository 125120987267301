import { DefaultTheme } from "@alohi/kit";
import { alohi, Box, Icon, makeStyles, useBool, useSnackbar } from "@alohi/kit";
import { useMutation } from "@apollo/client";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { DismissBannerDocument, MarketingBannerName } from "api/gql/generated/graphql";
import clsx from "clsx";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";
import IncentiveBanner from "components/Incentive/IncentiveBanner";
import { handleApiErrors } from "helpers/graphql";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

interface DismissableBannerProps {
  className?: string;
}

function DismissableBanner({ className }: DismissableBannerProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [mutateDismissBanner] = useMutation(DismissBannerDocument);

  const { showBanner } = useTrialIncentive();

  const isInitiallyOpen = showBanner;

  const bannerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [isOpen, isOpenBool] = useBool(isInitiallyOpen);

  const classes = useStyles({ isOpen });

  const handleClose = async () => {
    try {
      isOpenBool.setFalse();
      await mutateDismissBanner({
        variables: {
          name: MarketingBannerName.FaxSignCross_2025,
        },
      });
    } catch (error) {
      handleApiErrors(error, {
        default: () => {
          enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        },
      });
    }
  };

  const measureHeight = useCallback(() => {
    const currentHeight = contentRef.current?.clientHeight;
    if (!bannerRef.current || currentHeight === undefined) return;
    bannerRef.current.style.height = `${currentHeight}px`;
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(measureHeight);
    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [measureHeight]);

  return (
    <Box ref={bannerRef} className={clsx(classes.base, className)}>
      <Box className={classes.content} ref={contentRef}>
        {isOpen ? (
          <>
            <Box className={classes.leftContainer}></Box>
            <Box className={classes.centerContainer}>
              <IncentiveBanner />
            </Box>
            <Box className={classes.rightContainer}>
              <Icon className={classes.icon} icon={faClose} onClick={handleClose} />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles<DefaultTheme, { isOpen: boolean }>(({ breakpoints, spacing }) => ({
  base: ({ isOpen }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: alohi.neutral0,
    transition: "height 0.2s ease-in-out, opacity 0.2s ease-in-out;",
    overflow: "hidden",
    opacity: 1,
    borderWidth: 0,
    borderTopWidth: isOpen ? 1 : 0,
    borderStyle: "solid",
    borderColor: alohi.neutral200,
  }),
  content: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    gap: spacing(2),
    [breakpoints.down("md")]: {
      alignItems: "start",
    },
  },
  icon: {
    fontSize: 20,
    color: alohi.neutral900,
    cursor: "pointer",
    "& :hover": {
      color: alohi.neutral500,
    },
    [breakpoints.down("md")]: {
      fontSize: 26,
      marginTop: spacing(2),
    },
  },
  leftContainer: {
    width: spacing(8),
  },
  centerContainer: {
    flexGrow: 1,
    minHeight: "58px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightContainer: {
    width: spacing(8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default DismissableBanner;
