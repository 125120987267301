import { useState, useEffect } from "react";

export const useDelayedRender = (component, delay = 500) => {
  const [isDelaying, setIsDelaying] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsDelaying(false), delay);
    return () => {
      clearTimeout(timeout);
      setIsDelaying(true);
    };
  }, [delay]);

  return isDelaying ? null : component;
};
