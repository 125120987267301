import clsx from "clsx";
import { makeStyles, Box } from "@alohi/kit";
import { useSendFaxContext } from "views/SendFax/contexts/context";

interface StyleProps {
  isOverlayOpen: boolean;
}

function LoadingOverlay() {
  const {
    sendFaxStore: { isOverlayOpen },
  } = useSendFaxContext();
  const classes = useStyles({ isOverlayOpen });

  return <Box className={clsx(classes.center, classes.overlay)} />;
}

const useStyles = makeStyles(({ alohi }) => ({
  center: ({ isOverlayOpen }: StyleProps) => ({
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    zIndex: isOverlayOpen ? 100 : -100,
  }),
  overlay: ({ isOverlayOpen }: StyleProps) => ({
    opacity: isOverlayOpen ? 0.5 : 1,
    transition: "background-color .5s ease, opacity .5s ease",
    backgroundColor: isOverlayOpen ? alohi.lightestGray : "transparent",
  }),
}));

export default LoadingOverlay;
