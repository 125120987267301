import { Box } from "@alohi/kit";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import { urls } from "routes/urls";
import { useAppSelector } from "stores/store";
import { Group } from "stores/reducers/contacts.helpers";
import ColumnActions from "components/Columns/ColumnActions";
import DeleteGroupModal from "views/Contacts/DeleteGroupModal";
import EmptyContacts from "components/InfiniTable/EmptyContacts";
import AddOrEditGroupModal from "views/Contacts/AddOrEditGroupModal";
import { SendFaxRouteState } from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import {
  Table,
  makeStyles,
  Typography,
  TableColumnsProp,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "ui";
import {
  selectSharedGroups,
  selectHasMoreSharedGroups,
  selectIsSharedGroupsLoading,
  selectSharedGroupsSelection,
} from "selectors/contacts.selector";
import {
  getSharedGroups,
  getMoreSharedGroups,
  updateSharedGroupSelection,
} from "stores/reducers/contacts.sharedGroups.reducer";

interface SharedGroupsProps {
  noBorder: boolean;
  hasActions: boolean;
  hasSelection: boolean;
  showEmptyGroups: boolean;
  areTabsClickable?: boolean;
}

function SharedGroups({
  showEmptyGroups,
  hasSelection,
  noBorder,
  hasActions,
  areTabsClickable,
}: SharedGroupsProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleGroupDestination } = useSendFaxDestinationHelpers();

  const hasMore = useAppSelector<boolean>(selectHasMoreSharedGroups);
  const isLoading = useAppSelector<boolean>(selectIsSharedGroupsLoading);
  const selection = useAppSelector<string[]>(selectSharedGroupsSelection);
  const sharedGroupsList = useAppSelector<null | Group[]>(selectSharedGroups);

  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [targetedGroupId, setTargetedGroupId] = useState<null | string>(null);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);

  const rows = useMemo(
    () =>
      (showEmptyGroups
        ? sharedGroupsList
        : sharedGroupsList?.filter((group) => group.membersCount)) ?? [],
    [sharedGroupsList, showEmptyGroups],
  );

  const actions = useMemo(() => {
    return (index: number) =>
      rows.map((row) => [
        row.membersCount
          ? {
              label: t("COMMON.SEND_FAX"),
              callback: () =>
                history.push(urls.sendFax, {
                  destination: [handleGroupDestination(row, "sharedGroup")],
                } satisfies SendFaxRouteState),
            }
          : undefined,
        row.membersCount
          ? {
              label: "Divider",
            }
          : undefined,
        {
          label: t("COMMON.EDIT"),
          callback: () => {
            setTargetedGroupId(row.id);
            setIsEditGroupModalOpen(true);
          },
        },
        {
          label: t("COMMON.DELETE"),
          callback: () => {
            setTargetedGroupId(row.id);
            setIsDeleteGroupModalOpen(true);
          },
        },
      ])[index];
  }, [handleGroupDestination, history, rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    if (hasSelection) {
      columns.push({
        width: 16,
        minWidth: 16,
        headerName: " ",
        sortable: false,
        field: "id",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });

      columns.push({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        flex: 0.05,
      });

      columns.push({
        width: 15,
        minWidth: 15,
        headerName: " ",
        sortable: false,
        field: "index",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });
    } else {
      columns.push({
        flex: 0.115,
        headerName: " ",
        sortable: false,
        field: "id",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });
    }

    columns.push({
      flex: 0.25,
      field: "name",
      sortable: false,
      headerName: t("CONTACTS.NAME"),
      renderCell: function render({ row }: { row: Group }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.name}
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "membersCount",
      headerName: t("CONTACTS.MEMBERS_COUNT"),
      renderCell: function render({ row }: { row: Group }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.membersCount}
          </Typography>
        );
      },
    });

    columns.push({
      flex: 0.255,
      sortable: false,
      field: "comment",
      headerName: t("CONTACTS.NOTE"),
      renderCell: function render({ row }: { row: Group }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.note}
          </Typography>
        );
      },
    });

    if (hasActions) {
      columns.push({
        flex: 0.0925,
        sortable: false,
        field: "actions",
        headerName: " ",
        renderCell: function render({ row }: { row: Group }) {
          return <ColumnActions dataIndex={row.index} createActions={actions} />;
        },
      });
    }

    return columns;
  }, [actions, classes.row, hasActions, hasSelection, t]);

  const onWaypoint = useMemo(() => {
    if (!hasMore) {
      return undefined;
    }

    return () => {
      dispatch(getMoreSharedGroups());
    };
  }, [dispatch, hasMore]);

  useEffect(() => {
    if (!sharedGroupsList) {
      dispatch(getSharedGroups({ offset: 0 }));
    }
  }, [dispatch, sharedGroupsList]);

  return (
    <>
      <Table
        rows={rows}
        rowHeight={76}
        checkboxSelection={hasSelection}
        columns={columns}
        noBorder={noBorder}
        isLoading={isLoading}
        onWaypoint={onWaypoint}
        className={classes.root}
        selectionModel={selection}
        disableSelectionOnClick={!areTabsClickable}
        onSelectionModelChange={(selection) =>
          dispatch(updateSharedGroupSelection(selection as string[]))
        }
        components={{
          NoRowsOverlay: function render() {
            return <EmptyContacts text={t("CONTACTS.NO_GROUPS_HERE_YET")} />;
          },
        }}
      />

      {isEditGroupModalOpen ? (
        <AddOrEditGroupModal
          isSharedGroup
          groupToEditId={targetedGroupId}
          handleClosure={() => setIsEditGroupModalOpen(false)}
        />
      ) : null}

      {isDeleteGroupModalOpen && targetedGroupId ? (
        <DeleteGroupModal
          isSharedGroup
          groupIds={[targetedGroupId]}
          handleClosure={() => setIsDeleteGroupModalOpen(false)}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "&& .MuiDataGrid-columnHeader": {
      padding: spacing(0, 1),
    },
    "&& .MuiDataGrid-columnHeader:nth-child(2)": {
      paddingLeft: spacing(0.5),
    },
    "&&& .MuiDataGrid-cell": {
      overflowY: "auto",
      padding: spacing(2, 1),
    },
    "&&&& .MuiDataGrid-row > div:first-child": {
      padding: 0,
    },
  },
  row: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "text",
  },
}));

export default SharedGroups;
