import { useEffect } from "react";
import { MenuItem, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Currency from "components/Core/Currency/Currency";
import { selectCustomAmount } from "selectors/payment.selector";
import { getCustomPaymentAmount } from "stores/reducers/payment.amounts.reducer";
import FormRowDisabledSelectInput from "components/Forms/FormRowDisabledSelectInput";
import { selectCurrentCredit, selectCurrentCreditCurrency } from "selectors/credit.selector";
import AvailableCreditAmounts from "./AvailableCreditAmounts";

function AddCredit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customAmount = useSelector(selectCustomAmount);
  const currentCredit = useSelector(selectCurrentCredit) ?? 0;
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);

  useEffect(() => {
    if (customAmount) {
      dispatch(
        getCustomPaymentAmount({
          amount: customAmount,
          currency: currentCreditCurrency,
        }),
      );
    }
  }, [dispatch, customAmount, currentCreditCurrency]);

  return (
    <Box pt={2}>
      <FormRowDisabledSelectInput
        labelGridWidth={4}
        value={currentCredit}
        id="current-credit-input"
        label={t("ADD_CREDIT.CURRENT_CREDIT")}
      >
        <MenuItem value={currentCredit}>
          <Currency value={currentCredit} currency={currentCreditCurrency} />
        </MenuItem>
      </FormRowDisabledSelectInput>

      {customAmount ? (
        <FormRowDisabledSelectInput
          labelGridWidth={4}
          value={customAmount}
          id="custom-amount-input"
          label={t("ADD_CREDIT.ADD_AMOUNT")}
        >
          <MenuItem value={customAmount}>
            <Currency value={customAmount} currency={currentCreditCurrency} />
          </MenuItem>
        </FormRowDisabledSelectInput>
      ) : (
        <AvailableCreditAmounts />
      )}
    </Box>
  );
}

export default AddCredit;
