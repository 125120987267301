import { createAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "stores/store";
import { fetchWithRefresh } from "helpers";
import { API_AFFOGATO, API_STORAGE } from "config";
import { SharedBy } from "views/SendFax/contexts/store";
import { selectAccountId } from "selectors/account.selector";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import {
  ListCoverSheetResponse,
  CreateCoverSheetResponse,
  UploadCoverSheetResponse,
  RemoveCoverSheetResponse,
  UpdateCoverSheetResponse,
  EnforceCoverSheetResponse,
  EnforcedCoverSheetResponse,
} from "api/types/coverSheet";
import { logout } from "./authentication.reducer";
import { sentryCaptureMessage } from "helpers/sentry";

const name = "coverSheet";

type CreatePayload = {
  name: string;
  filename: string;
  isShared: boolean;
};

type UploadPayload = {
  file: File;
};

type ByIdPayload = {
  id: string;
};

type RemovePayload = {
  id: string;
};

type EnforcePayload = {
  id: string;
  enforceType: "enforce" | "disable";
};

type UpdatePayload = {
  id: string;
  type: "isShared" | "name";
  data: { name: string } | { isShared: true }; // You can't unshare, but you can delete
};

const initialState = {
  list: InitialRequestState<ListCoverSheetResponse>(),
  update: {
    ...InitialRequestState<UpdateCoverSheetResponse>(),
    updateType: null as null | UpdatePayload["type"],
  },
  remove: InitialRequestState<RemoveCoverSheetResponse>(),
  create: InitialRequestState<CreateCoverSheetResponse>(),
  upload: InitialRequestState<UploadCoverSheetResponse>(),
  enforce: {
    ...InitialRequestState<EnforceCoverSheetResponse>(),
    enforceType: null as null | EnforcePayload["enforceType"],
  },
  enforced: InitialRequestState<EnforcedCoverSheetResponse>(),
  byId: InitialRequestState<string>(),
};

const actions = {
  clearById: createAction(`${name}/clear-byId`),
  clearList: createAction(`${name}/clear-list`),
  clearUpdate: createAction(`${name}/clear-update`),
  clearRemove: createAction(`${name}/clear-remove`),
  clearCreate: createAction(`${name}/clear-create`),
  clearUpload: createAction(`${name}/clear-upload`),
  clearEnforce: createAction(`${name}/clear-enforce`),
  clearEnforced: createAction(`${name}/clear-enforced`),
};

const selectors = {
  createResponse: createSelector(
    (state: RootState) => state[name].create.response,
    (response) => response ?? null,
  ),
  isCreateRunning: createSelector(
    (state: RootState) => state[name].create.status,
    (status) => status === RS.RUNNING,
  ),
  isCreateError: createSelector(
    (state: RootState) => state[name].create.status,
    (status) => status === RS.ERROR,
  ),

  uploadFilename: createSelector(
    (state: RootState) => state[name].upload.response,
    (response) => response?.filename ?? null,
  ),
  isMimeTypeNotSupported: createSelector(
    (state: RootState) => state[name].upload.error,
    (error) => error?.reason === "unsupported_mimetype",
  ),
  isUploadRunning: createSelector(
    (state: RootState) => state[name].upload.status,
    (status) => status === RS.RUNNING,
  ),
  isUploadError: createSelector(
    (state: RootState) => state[name].upload.status,
    (status) => status === RS.ERROR,
  ),

  gallery: createSelector(
    (state: RootState) => state[name].list.response,
    (response) => {
      if (!response?.cover_sheets) {
        return null;
      }

      const gallery = response.cover_sheets.filter(({ is_shared }) => !Boolean(is_shared));

      return gallery.map(({ last_used, ...rest }, index) => ({
        index,
        lastTimeUsed: last_used,
        ...rest,
      }));
    },
  ),
  shared: createSelector(
    (state: RootState) => state[name].list.response,
    selectAccountId,
    (response, uid) => {
      if (!response?.cover_sheets) {
        return null;
      }

      const shared = response.cover_sheets.filter(({ is_shared }) => Boolean(is_shared));

      // Transform the shared cover sheet model
      return shared.map(({ is_enforced, last_used, owner, ...rest }, index) => ({
        index,
        isEnforced: is_enforced,
        lastTimeUsed: last_used,
        ownerName: `${owner.firstname} ${owner.lastname}`,
        sharedBy: owner.is_deleted
          ? SharedBy.deleted
          : uid === owner.uid
            ? SharedBy.me
            : SharedBy.name,
        ...rest,
      }));
    },
  ),
  isListRunning: createSelector(
    (state: RootState) => state[name].list.status,
    (status) => status === RS.RUNNING,
  ),
  isListError: createSelector(
    (state: RootState) => state[name].list.status,
    (status) => status === RS.ERROR,
  ),

  remove: createSelector(
    (state: RootState) => state[name].remove.response,
    (response) => response ?? null,
  ),
  isRemoveRunning: createSelector(
    (state: RootState) => state[name].remove.status,
    (status) => status === RS.RUNNING,
  ),
  isRemoveError: createSelector(
    (state: RootState) => state[name].remove.status,
    (status) => status === RS.ERROR,
  ),
  isEnforcedAndCantBeRemoved: createSelector(
    (state: RootState) => state[name].remove.error,
    (error) => error?.reason === "cover_sheet_enforced",
  ),

  updateType: createSelector(
    (state: RootState) => state[name].update.updateType,
    (updateType) => updateType,
  ),
  isUpdateRunning: createSelector(
    (state: RootState) => state[name].update.status,
    (status) => status === RS.RUNNING,
  ),
  isUpdateError: createSelector(
    (state: RootState) => state[name].update.status,
    (status) => status === RS.ERROR,
  ),

  enforceType: createSelector(
    (state: RootState) => state[name].enforce.response,
    (state: RootState) => state[name].enforce.enforceType,
    (response, enforceType) => (Boolean(response && enforceType !== null) ? enforceType : null),
  ),
  isEnforceRunning: createSelector(
    (state: RootState) => state[name].enforce.status,
    (status) => status === RS.RUNNING,
  ),
  isEnforceError: createSelector(
    (state: RootState) => state[name].enforce.status,
    (status) => status === RS.ERROR,
  ),

  enforced: createSelector(
    (state: RootState) => state[name].enforced.response,
    selectAccountId,
    (response, uid) => {
      if (response) {
        const { is_enforced, last_used, owner, ...rest } = response;

        return {
          index: 0,
          isEnforced: is_enforced,
          lastTimeUsed: last_used,
          ownerName: `${owner.firstname} ${owner.lastname}`,
          sharedBy: owner.is_deleted
            ? SharedBy.deleted
            : uid === owner.uid
              ? SharedBy.me
              : SharedBy.name,
          ...rest,
        };
      }

      return null;
    },
  ),
  isEnforcedRunning: createSelector(
    (state: RootState) => state[name].enforced.status,
    (status) => status === RS.RUNNING,
  ),
  isEnforcedError: createSelector(
    (state: RootState) => state[name].enforced.status,
    (status) => status === RS.ERROR,
  ),

  byId: createSelector(
    (state: RootState) => state[name].byId.response,
    (response) => response ?? null,
  ),
  isByIdRunning: createSelector(
    (state: RootState) => state[name].byId.status,
    (status) => status === RS.RUNNING,
  ),
  isByIdError: createSelector(
    (state: RootState) => state[name].byId.status,
    (status) => status === RS.ERROR,
  ),
};

export const upload = createAsyncThunk(
  `${name}/upload`,
  async ({ file }: UploadPayload, thunkInfo) => {
    try {
      const path = `${API_STORAGE}/storage/cover-sheet`;

      const data = new FormData();
      data.append("file", file);

      const response = await fetchWithRefresh(path, {
        method: "POST",
        body: data,
      });

      return response;
    } catch (error) {
      sentryCaptureMessage({
        message: `Something went wrong with cover sheet handle`,
        level: "error",
        tags: {
          "Cover-Sheet-Error": "Upload",
        },
      });

      return thunkInfo.rejectWithValue(error as FetchError);
    }
  },
);

const create = createAsyncThunk<
  CreateCoverSheetResponse,
  CreatePayload,
  { rejectValue: FetchError }
>(`${name}/create`, async ({ isShared, filename, name }: CreatePayload, thunkInfo) => {
  try {
    const path = `${API_AFFOGATO}/cover-sheet`;

    const response = await fetchWithRefresh(path, {
      method: "POST",
      body: {
        name,
        filename,
        is_shared: isShared,
      },
    });

    return response;
  } catch (error) {
    sentryCaptureMessage({
      message: `Something went wrong with cover sheet handle`,
      level: "error",
      tags: {
        "Cover-Sheet-Error": "Create",
      },
    });

    return thunkInfo.rejectWithValue(error as FetchError);
  }
});

const list = createAsyncThunk<ListCoverSheetResponse, void, { rejectValue: FetchError }>(
  `${name}/list`,
  async (_, thunkInfo) => {
    try {
      const path = `${API_AFFOGATO}/cover-sheet`;

      const response = await fetchWithRefresh(path, {
        method: "GET",
      });

      return response;
    } catch (error) {
      sentryCaptureMessage({
        message: `Something went wrong with cover sheet handle`,
        level: "error",
        tags: {
          "Cover-Sheet-Error": "List",
        },
      });

      return thunkInfo.rejectWithValue(error as FetchError);
    }
  },
);

const remove = createAsyncThunk<
  { response: RemoveCoverSheetResponse; id: string },
  RemovePayload,
  { rejectValue: FetchError }
>(`${name}/remove`, async ({ id }: RemovePayload, thunkInfo) => {
  try {
    const path = `${API_AFFOGATO}/cover-sheet/${id}`;

    const response = await fetchWithRefresh(path, {
      method: "DELETE",
    });

    return { response, id };
  } catch (error) {
    sentryCaptureMessage({
      message: `Something went wrong with cover sheet handle`,
      level: "error",
      tags: {
        "Cover-Sheet-Error": "Remove",
      },
    });

    return thunkInfo.rejectWithValue(error as FetchError);
  }
});

const update = createAsyncThunk<
  { response: UpdateCoverSheetResponse } & UpdatePayload,
  UpdatePayload,
  { rejectValue: FetchError }
>(`${name}/update`, async ({ id, type, data }: UpdatePayload, thunkInfo) => {
  try {
    const path = `${API_AFFOGATO}/cover-sheet/${id}`;

    const response = await fetchWithRefresh(path, {
      method: "PATCH",
      body:
        "name" in data
          ? {
              name: data.name,
            }
          : {
              is_shared: data.isShared,
            },
    });

    return { response, id, type, data };
  } catch (error) {
    sentryCaptureMessage({
      message: `Something went wrong with cover sheet handle`,
      level: "error",
      tags: {
        "Cover-Sheet-Error": "Update",
      },
    });

    return thunkInfo.rejectWithValue(error as FetchError);
  }
});

const enforce = createAsyncThunk<
  { response: EnforceCoverSheetResponse } & EnforcePayload,
  EnforcePayload,
  { rejectValue: FetchError }
>(`${name}/enforce`, async ({ id, enforceType }: EnforcePayload, thunkInfo) => {
  try {
    const path = `${API_AFFOGATO}/cover-sheet/${id}/enforce`;

    const response = await fetchWithRefresh(path, {
      method: "POST",
      body: {
        is_enforced: enforceType === "enforce",
      },
    });

    return { response, id, enforceType };
  } catch (error) {
    sentryCaptureMessage({
      message: `Something went wrong with cover sheet handle`,
      level: "error",
      tags: {
        "Cover-Sheet-Error": "Enforce",
      },
    });

    return thunkInfo.rejectWithValue(error as FetchError);
  }
});

const enforced = createAsyncThunk<EnforcedCoverSheetResponse, void, { rejectValue: FetchError }>(
  `${name}/enforced`,
  async (_, thunkInfo) => {
    try {
      const path = `${API_AFFOGATO}/cover-sheet/enforced`;

      const response = await fetchWithRefresh(path, {
        method: "GET",
      });

      return response;
    } catch (error) {
      return thunkInfo.rejectWithValue(error as FetchError);
    }
  },
);

const byId = createAsyncThunk<string, ByIdPayload, { rejectValue: FetchError }>(
  `${name}/byId`,
  async ({ id }, thunkInfo) => {
    try {
      const path = `${API_STORAGE}/storage/cover-sheet/${id}`;

      const response: Blob = await fetchWithRefresh(path, {
        method: "GET",
      });

      const blobUrl = URL.createObjectURL(response);

      return blobUrl;
    } catch (error) {
      return thunkInfo.rejectWithValue(error as FetchError);
    }
  },
);

const thunks = {
  list,
  byId,
  update,
  remove,
  create,
  upload,
  enforce,
  enforced,
};

const { reducer } = createSlice({
  name: "COVER_SHEET",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(upload.pending, (state) => {
      state.upload.status = RS.RUNNING;
      state.upload.error = null;
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      state.upload.response = action.payload;
      state.upload.status = RS.IDLE;
      state.upload.error = null;
    });
    builder.addCase(upload.rejected, (state, action) => {
      state.upload.response = null;
      state.upload.status = RS.ERROR;

      if (action.payload) {
        state.upload.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearUpload, (state) => {
      state.upload = InitialRequestState();
    });

    builder.addCase(create.pending, (state) => {
      state.create.status = RS.RUNNING;
      state.create.error = null;
    });
    builder.addCase(create.fulfilled, (state, action) => {
      state.create.response = action.payload;
      state.create.status = RS.IDLE;
      state.create.error = null;

      // Update the list too
      if (state.create.response) {
        state.list.response = action.payload;
      }
    });
    builder.addCase(create.rejected, (state, action) => {
      state.create.response = null;
      state.create.status = RS.ERROR;

      if (action.payload) {
        state.create.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearCreate, (state) => {
      state.create = InitialRequestState();
    });

    builder.addCase(list.pending, (state) => {
      state.list.status = RS.RUNNING;
      state.list.error = null;
    });
    builder.addCase(list.fulfilled, (state, action) => {
      state.list.response = action.payload;
      state.list.status = RS.IDLE;
      state.list.error = null;
    });
    builder.addCase(list.rejected, (state, action) => {
      state.list.status = RS.ERROR;

      if (action.payload) {
        state.list.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearList, (state) => {
      state.list = InitialRequestState();
    });

    builder.addCase(remove.pending, (state) => {
      state.remove.status = RS.RUNNING;
      state.remove.error = null;
    });
    builder.addCase(remove.fulfilled, (state, action) => {
      state.remove.response = action.payload.response;
      state.remove.status = RS.IDLE;
      state.remove.error = null;

      if (state.list.response) {
        const newList = state.list.response.cover_sheets.filter(
          ({ id }) => id !== action.payload.id,
        );

        state.list.response.cover_sheets = newList;
      }
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.remove.response = null;
      state.remove.status = RS.ERROR;

      if (action.payload) {
        state.remove.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearRemove, (state) => {
      state.remove = InitialRequestState();
    });

    builder.addCase(update.pending, (state) => {
      state.update.status = RS.RUNNING;
      state.update.error = null;
    });
    builder.addCase(update.fulfilled, (state, action) => {
      state.update.response = action.payload.response;
      state.update.status = RS.IDLE;
      state.update.error = null;
      state.update.updateType = action.payload.type;

      if (state.list.response) {
        // Update only the name in the list
        const newList = state.list.response.cover_sheets.map((coverSheet) => {
          if (coverSheet.id === action.payload.id && "name" in action.payload.data) {
            return {
              ...coverSheet,
              name: action.payload.data.name,
            };
          }
          if (coverSheet.id === action.payload.id && "isShared" in action.payload.data) {
            return {
              ...coverSheet,
              is_shared: action.payload.data.isShared,
            };
          }

          return coverSheet;
        });

        state.list.response.cover_sheets = newList;
      }
    });
    builder.addCase(update.rejected, (state, action) => {
      state.update.response = null;
      state.update.status = RS.ERROR;

      if (action.payload) {
        state.update.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearUpdate, (state) => {
      state.update = {
        ...InitialRequestState(),
        updateType: null,
      };
    });

    builder.addCase(enforce.pending, (state) => {
      state.enforce.status = RS.RUNNING;
      state.enforce.error = null;
      state.enforce.enforceType = null;
    });
    builder.addCase(enforce.fulfilled, (state, action) => {
      state.enforce.response = action.payload.response;
      state.enforce.status = RS.IDLE;
      state.enforce.error = null;
      state.enforce.enforceType = action.payload.enforceType;

      // Manually update the enforced cover sheet
      if (action.payload.enforceType === "disable") {
        state.enforced.response = null;
      }

      if (action.payload.enforceType === "enforce" && state.list.response) {
        const enforcedCoverSheet = state.list.response.cover_sheets.find(
          ({ id }) => id === action.payload.id,
        );

        if (enforcedCoverSheet) {
          state.enforced.response = {
            ...enforcedCoverSheet,
            is_enforced: true,
          };
        }
      }
    });
    builder.addCase(enforce.rejected, (state, action) => {
      state.enforce.response = null;
      state.enforce.status = RS.ERROR;
      state.enforce.enforceType = null;

      if (action.payload) {
        state.enforce.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearEnforce, (state) => {
      state.enforce = {
        ...InitialRequestState(),
        enforceType: null,
      };
    });

    builder.addCase(enforced.pending, (state) => {
      state.enforced.status = RS.RUNNING;
      state.enforced.error = null;
    });
    builder.addCase(enforced.fulfilled, (state, action) => {
      state.enforced.response = action.payload;
      state.enforced.status = RS.IDLE;
      state.enforced.error = null;
    });
    builder.addCase(enforced.rejected, (state, action) => {
      state.enforced.response = null;
      state.enforced.status = RS.ERROR;

      if (action.payload) {
        state.enforced.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearEnforced, (state) => {
      state.enforced = InitialRequestState();
    });

    builder.addCase(byId.pending, (state) => {
      state.byId.status = RS.RUNNING;
      state.byId.error = null;
    });
    builder.addCase(byId.fulfilled, (state, action) => {
      state.byId.response = action.payload;
      state.byId.status = RS.IDLE;
      state.byId.error = null;
    });
    builder.addCase(byId.rejected, (state, action) => {
      state.byId.response = null;
      state.byId.status = RS.ERROR;

      if (action.payload) {
        state.byId.error = action.payload as FetchError;
      }
    });
    builder.addCase(actions.clearById, (state) => {
      if (state.byId.response) {
        URL.revokeObjectURL(state.byId.response);
      }

      state.byId = InitialRequestState();
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.list = initialState.list;
      state.byId = initialState.byId;
      state.create = initialState.create;
      state.remove = initialState.remove;
      state.update = initialState.update;
      state.upload = initialState.upload;
      state.enforce = initialState.enforce;
      state.enforced = initialState.enforced;
    });
  },
});

export { thunks, actions, selectors };

export default reducer;
