import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FaButton, Modal, Box, useSnackbar } from "@alohi/kit";

import { formatDefaultDate } from "helpers/date";
import Currency from "components/Core/Currency/Currency";
import InfiniTable from "components/InfiniTable/InfiniTable";
import { PaymentMethodFilter } from "components/Core/Filters/PaymentMethodFilter";
import { getPaymentHistory, clearPaymentHistory } from "stores/reducers/payment.history.reducer";
import { clearPaymentReceipt, getPaymentReceipt } from "stores/reducers/payment.receipt.reducer";
import {
  selectPaymentHistory,
  selectIsPaymentReceiptError,
  selectPaymentHistoryHasMore,
} from "selectors/payment.selector";

function PaymentHistoryModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const paymentHistory = useSelector(selectPaymentHistory);
  const isReceiptError = useSelector(selectIsPaymentReceiptError);
  const paymentHistoryHasMore = useSelector(selectPaymentHistoryHasMore);

  const handleDownloadReceipt = useCallback(
    (id) => {
      dispatch(getPaymentReceipt(id));
    },
    [dispatch],
  );

  const getColumns = useMemo(
    () => [
      {
        name: "issue_date",
        label: t("COMMON.DATE"),
        options: {
          customBodyRender: function bodyRender(value) {
            return formatDefaultDate(value);
          },
        },
      },
      {
        name: "id",
        label: t("PROFILE.PAYMENT_METHOD"),
        options: {
          customBodyRender: function bodyRender(id) {
            const { via, data, amount } = paymentHistory.find((element) => element.id === id);
            return (
              <PaymentMethodFilter
                via={via}
                amount={amount}
                type={data?.type}
                brand={data?.brand}
                last4={data?.last4}
              />
            );
          },
        },
      },
      {
        name: "id",
        label: t("COMMON.AMOUNT"),
        options: {
          customBodyRender: function bodyRender(id) {
            const { amount, currency } = paymentHistory.find((element) => element.id === id);
            return <Currency value={amount} currency={currency} />;
          },
        },
      },
      {
        name: "id",
        label: t("COMMON.DOWNLOAD"),
        options: {
          print: false,
          filter: false,
          downloadL: false,
          setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
          customBodyRender: function bodyRender(id) {
            const { amount, via } = paymentHistory.find((element) => element.id === id);
            if (["stripe", "apple", "braintree"].includes(via.toLowerCase()) && amount > 0) {
              return (
                <Box
                  m={-1}
                  display="flex"
                  justifyContent="center"
                  onClick={() => handleDownloadReceipt(id)}
                >
                  <FaButton icon={faDownload} tooltip={t("COMMON.DOWNLOAD")} />
                </Box>
              );
            }
            return null;
          },
        },
      },
    ],
    [paymentHistory, t, handleDownloadReceipt],
  );

  const paginate = useCallback(() => {
    // Get the last item from the list, and fetch before that.
    const { issue_date: issueDate } = paymentHistory[paymentHistory.length - 1];
    dispatch(getPaymentHistory({ before: issueDate }));
  }, [dispatch, paymentHistory]);

  useEffect(() => {
    // Use before null, as a way to differentiate between fetching the whole list or just a portion
    dispatch(getPaymentHistory({ before: null }));
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(clearPaymentHistory());
  }, [dispatch]);

  useEffect(() => {
    if (isReceiptError) {
      dispatch(clearPaymentReceipt());
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [isReceiptError, enqueueSnackbar, t, dispatch]);

  if (!paymentHistory?.length) {
    return null;
  }

  return (
    <Modal
      scroll="paper"
      onConfirm={handleClosure}
      confirmTitle={t("COMMON.CLOSE")}
      title={t("COMMON.PAYMENT_HISTORY")}
    >
      <InfiniTable
        options={{
          sort: false,
          filter: false,
          selectableRows: "none",
          selectableRowsHeader: false,
          textLabels: {
            body: {
              noMatch: t("USAGE_PAYMENT.EMPTY"),
            },
          },
        }}
        columns={getColumns}
        data={paymentHistory}
        onWaypoint={paginate}
        hasWaypoint={paymentHistoryHasMore}
      />
    </Modal>
  );
}

PaymentHistoryModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default PaymentHistoryModal;
