import queryString from "query-string";
import { Box, useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";

import { Typography } from "ui";
import { urls } from "routes/urls";
import { fileToFaxFile } from "helpers/file";
import Loading from "components/Loading/Loading";
import { FaxFile } from "views/SendFax/contexts/store";
import { useAppDispatch, useAppSelector } from "stores/store";
import InitSendFaxProviders from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import { CdrData, clearGetCdrData, getCdrData } from "stores/reducers/numbers.reducer";
import {
  selectCdrData,
  selectIsCdrDataError,
  selectIsCdrDataLoading,
} from "selectors/numbers.selector";

function ReuseFax() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleNewEntryDestination } = useSendFaxDestinationHelpers();

  const isReply = Boolean(useRouteMatch(urls.replyFax));
  const isResend = Boolean(useRouteMatch(urls.resendFax));
  const isForward = Boolean(useRouteMatch(urls.forwardFax));

  const { withFile, withDestination, from } = useMemo(() => {
    let from = "";
    let withFile = false;
    let withDestination = false;

    if (isReply) {
      from = "reply";
      withFile = false;
      withDestination = true;
    } else if (isResend) {
      from = "resend";
      withFile = true;
      withDestination = true;
    } else if (isForward) {
      from = "forward";
      withFile = true;
      withDestination = false;
    }

    return {
      from,
      withFile,
      withDestination,
    };
  }, [isForward, isReply, isResend]);

  const { search } = useLocation();
  const { id } = queryString.parse(search);
  const isError = useAppSelector(selectIsCdrDataError);
  const isLoading = useAppSelector(selectIsCdrDataLoading);
  const cdrData = useAppSelector<null | CdrData>(selectCdrData);
  const { cdrId, to } = useParams<{ cdrId: string; to: string }>();

  const destination = cdrData?.to;
  const toString = typeof to === "string" ? to : to?.[0] ?? "";
  const cdrStringId = typeof cdrId === "string" ? cdrId : cdrId?.[0] ?? "";
  const idString = typeof id === "string" ? id : id?.[0] ?? "";

  const cdrIdSource = cdrStringId || idString;

  const [files, setFiles] = useState<FaxFile[] | undefined>(undefined);

  const rebuildFile = useCallback(
    async (cdrData: CdrData) => {
      try {
        const response = await fetch(cdrData.blobString);
        const blob = await response.blob();
        const file = new File([blob], cdrData.fileName, { type: cdrData.mime });
        const faxFile = await fileToFaxFile(file);
        // Save the path, just so at upload step, we only pass the path instead of actually uploading
        // Commented for now because the file path doesn't include the coversheet
        // faxFile.path = cdrData.path;

        setFiles([faxFile]);
      } catch {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        dispatch(clearGetCdrData());
      }
    },
    [dispatch, enqueueSnackbar, t],
  );

  useEffect(() => {
    if (Boolean(cdrIdSource) && withFile) {
      // Download the cdr info
      dispatch(getCdrData(cdrIdSource));
    }
  }, [dispatch, cdrIdSource, withFile]);

  useEffect(() => {
    if (cdrData && !files?.length) {
      rebuildFile(cdrData);
    }
  }, [cdrData, files?.length, rebuildFile]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearGetCdrData());
    }
  }, [dispatch, isError, t, enqueueSnackbar]);

  useEffect(() => {
    return () => {
      dispatch(clearGetCdrData());
    };
  }, [dispatch]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body">{t("FAXES.DOWNLOADING_FILES")}</Typography>
        <Loading />
      </Box>
    );
  }

  const destinationSource = toString || destination;

  return (
    <InitSendFaxProviders
      files={files}
      initiatedFrom={{ id: cdrIdSource, from }}
      destination={
        withDestination && destinationSource
          ? [handleNewEntryDestination(destinationSource)]
          : undefined
      }
    />
  );
}

export default ReuseFax;
