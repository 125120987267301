import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AvatarInitials,
  Box,
  Chip,
  DefaultTheme,
  Divider,
  Menu,
  MenuItem,
  Typography,
  alohi,
} from "@alohi/kit";

import { makeStyles } from "ui";
import useBool from "hooks/useBool";
import { urlImage } from "helpers/common";
import ChangePlan from "views/ChangePlan/ChangePlan";
import Currency from "components/Core/Currency/Currency";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import ContactUsModal from "views/ContactUs/ContactUsModal";
import useAdminRedirections from "hooks/useAdminRedirections";
import { logout } from "stores/reducers/authentication.reducer";
import { alohiAdminUrls, helpCenterUrls, urls } from "routes/urls";
import UploadProfileImageModal from "views/Image/UploadProfileImageModal";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import UpgradeEnterpriseModal from "views/UpgradeEnterprise/UpgradeEnterpriseModal";
import { selectCurrentCredit, selectCurrentCreditCurrency } from "selectors/credit.selector";
import { clearUpdateAccountDetails, updateAccountDetails } from "stores/reducers/account.reducer";
import {
  selectAccountInfo,
  selectCompanyLogo,
  selectCompanyName,
  selectIsBaaSigned,
  selectAccountIsCorporateMember,
  selectIsUpdateAccountDetailsSuccessful,
} from "selectors/account.selector";
import {
  selectPlanType,
  selectIsPlanCorporate,
  selectIsPlanCanBeUpgraded,
} from "selectors/plan.selector";
import { fetchWithRefresh } from "helpers";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

function UserMenu() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();
  const { sendCustomEvent } = useGoogleTagManager();

  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const { profileImage, name, lastName, email } = useSelector(selectAccountInfo);
  const isUpdateSuccessful = useSelector(selectIsUpdateAccountDetailsSuccessful);
  const isBaaSigned = useSelector(selectIsBaaSigned);
  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const companyLogo = useSelector(selectCompanyLogo);
  const companyName = useSelector(selectCompanyName);
  const planType = useSelector(selectPlanType);
  const isUpgradeButton = useSelector(selectIsPlanCanBeUpgraded);

  const currentCredit = useSelector(selectCurrentCredit);
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);

  const [localSrc, setLocalSrc] = useState<string | null>(null);
  const [openAddCredit, setOpenAddCredit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);
  const [isUploadModalOpen, isUploadModalOpenBool] = useBool(false);
  const [isContactUsModalOpen, isContactUsModalOpenBool] = useBool(false);
  const [isUpgradeEnterpriseModalOpen, isUpgradeEnterpriseModalOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    if (isUpgradeButton) {
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
        page: location.pathname,
        extraData: {
          label: "upgrade",
          to: alohiAdminUrls.fax.highVolume,
        },
      });
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.highVolume,
        fallbackAction: () => {
          isUpgradeEnterpriseModalOpenBool.setTrue();
          handleClose();
        },
      });
    } else {
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
        page: location.pathname,
        extraData: {
          label: "upgrade_plan",
          to: alohiAdminUrls.fax.upgrade,
        },
      });
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.upgrade,
        fallbackAction: () => {
          isChangePlanOpenBool.setTrue();
          handleClose();
        },
      });
    }
  }, [
    isUpgradeButton,
    sendCustomEvent,
    redirectToAdmin,
    isUpgradeEnterpriseModalOpenBool,
    isChangePlanOpenBool,
  ]);

  const handleOpenCredit = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
      page: location.pathname,
      extraData: {
        label: "add_credit",
      },
    });
    setOpenAddCredit(true);
    handleClose();
  };

  const handleOpenMenu = (event: MouseEvent<HTMLDivElement>) => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
      page: location.pathname,
      extraData: {
        label: "open",
      },
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchCurrentCredit = useCallback(() => {
    dispatch(getCreditDetails());
    dispatch(getCurrentCredit());
  }, [dispatch]);

  const handleLogout = useCallback(
    (event) => {
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
        page: location.pathname,
        extraData: {
          label: "log_out",
        },
      });
      event.preventDefault();
      dispatch(logout());
      handleClose();
    },
    [dispatch, sendCustomEvent],
  );

  const handleImageUpload = useCallback(
    ({ url }) => {
      if (url) {
        dispatch(
          updateAccountDetails({
            updateType: "profileImage",
            accountData: {
              profileImage: url,
            },
          }),
        );
      } else {
        isUploadModalOpenBool.setFalse();
      }
    },
    [dispatch, isUploadModalOpenBool],
  );

  const handleOpenUpload = () => {
    isUploadModalOpenBool.setTrue();
    handleClose();
  };

  const handleManageAccount = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
      page: location.pathname,
      extraData: {
        label: "manage_account",
        to: urls.profile.general,
      },
    });
    history.replace(urls.profile.general);
    handleClose();
  };

  const handleContactUs = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
      page: location.pathname,
      extraData: {
        label: "contact_us",
      },
    });
    isContactUsModalOpenBool.setTrue();
    handleClose();
  };

  const handleHelpCenter = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarProfileDropdown,
      page: location.pathname,
      extraData: {
        label: "help_center",
        to: helpCenterUrls.homepage,
      },
    });
    window.open(helpCenterUrls.homepage, "_blank", "noreferrer");
    handleClose();
  };

  const fetchImage = async (src: string) => {
    if (Boolean(src)) {
      let localUrl = "";
      try {
        const blob = await fetchWithRefresh(src, { cache: "force-cache" });
        localUrl = URL.createObjectURL(blob);
        setLocalSrc(localUrl);
      } catch {
        setLocalSrc(null);
      }
    } else {
      setLocalSrc(null);
    }
  };

  useEffect(() => {
    if (isUpdateSuccessful) {
      isUploadModalOpenBool.setFalse();
      dispatch(clearUpdateAccountDetails());
    }
  }, [isUpdateSuccessful, dispatch, isUploadModalOpenBool]);

  useEffect(() => {
    fetchCurrentCredit();
  }, [fetchCurrentCredit]);

  useEffect(() => {
    if (profileImage) {
      fetchImage(urlImage(profileImage) as string).catch(() => {
        //Nothing to do
      });
    }
  }, [profileImage]);

  return (
    <>
      <Box
        id="user-menu"
        data-cy="UserMenu"
        aria-haspopup="true"
        className={classes.root}
        aria-controls="user-menu"
        onClick={handleOpenMenu}
      >
        <Box className={classes.userCard}>
          <AvatarInitials
            className={classes.corporateLogo}
            name={name}
            lastName={lastName}
            alt={companyName}
            src={localSrc ?? ""}
          />
        </Box>
      </Box>
      <Menu
        sx={{ mt: 2 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "user-menu" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className={classes.userInfoContainer}>
          <AvatarInitials
            className={classes.userProfileImage}
            name={name}
            lastName={lastName}
            src={localSrc ?? ""}
            alt="user"
            onClick={handleOpenUpload}
          />
          <Box display={"flex"} flexDirection={"column"} ml={2}>
            <Typography className={classes.userInfoName} type="bold">
              {`${name} ${lastName}`}
            </Typography>
            <Typography className={classes.name} type="subtitle">
              {email}
            </Typography>
          </Box>
          {isBaaSigned ? (
            <Chip
              className={classes.hipaaChip}
              icon={<FontAwesomeIcon icon={faCheck} className={classes.hipaaCheckIcon} />}
              label={<Typography className={classes.hipaaChipLabel}>{"HIPAA"}</Typography>}
            />
          ) : null}
        </Box>
        <Divider sx={{ my: 1 }} />
        <MenuItem className={classes.menuItem} onClick={handleManageAccount}>
          <Typography>{t("USERMENU.MANAGE_ACCOUNT")}</Typography>
        </MenuItem>
        {isCorporateMember ? null : <Divider sx={{ my: 1 }} />}

        {isCorporateMember ? null : (
          <MenuItem className={classes.menuItem} onClick={handleOpenChangePlan}>
            <Typography flex={1}>
              {isUpgradeButton ? t("COMMON.UPGRADE") : t("CHANGE_PLAN.UPGRADE_PLAN")}
            </Typography>
            {planType === "free" ? null : (
              <Chip
                className={classes.planChip}
                label={
                  <Typography className={classes.planChipLabel}>
                    {planType?.toUpperCase()}
                  </Typography>
                }
              />
            )}
          </MenuItem>
        )}
        {isCorporateMember ? null : (
          <MenuItem className={classes.menuItem} onClick={handleOpenCredit}>
            <Typography flex={1}>{t("COMMON.ADD_CREDIT")}</Typography>
            <Chip
              className={classes.planChip}
              label={
                <Typography className={classes.planChipLabel}>
                  <Currency value={currentCredit || ""} currency={currentCreditCurrency || ""} />
                </Typography>
              }
            />
          </MenuItem>
        )}
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleHelpCenter} className={classes.menuItem}>
          <Typography>{t("WEB_SERVICE.HELP_CENTER")}</Typography>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleContactUs}>
          <Typography>{t("CONTACT_US.TITLE")}</Typography>
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem className={classes.menuItem} onClick={handleLogout}>
          <Typography color={alohi.red}>{t("COMMON.LOGOUT")}</Typography>
        </MenuItem>
        {isPlanCorporate && companyName ? (
          <Box>
            <Divider sx={{ my: 1 }} />
            <Box className={classes.corporateContainer}>
              {companyLogo ? (
                <AvatarInitials
                  className={classes.corporateLogo}
                  name={name}
                  lastName={lastName}
                  alt={companyName}
                  src={urlImage(companyLogo) ?? ""}
                />
              ) : null}
              <Typography type="subtitle" className={classes.corporateTypography}>
                {t("USERMENU.MANAGED_BY")}
              </Typography>
              <Typography type="subtitle" className={classes.corporateNameTypography}>
                {companyName}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Menu>
      {isUploadModalOpen ? (
        <UploadProfileImageModal initialImage={localSrc} handleClosure={handleImageUpload} />
      ) : null}
      {isContactUsModalOpen ? (
        <ContactUsModal blocked={false} handleClosure={isContactUsModalOpenBool.setFalse} />
      ) : null}
      {openAddCredit ? (
        <AddCreditModal
          withSuccessModal
          reason="add_credit"
          handleClosure={() => setOpenAddCredit(false)}
        />
      ) : null}

      {isChangePlanOpen ? <ChangePlan handleClosure={isChangePlanOpenBool.setFalse} /> : null}

      {isUpgradeEnterpriseModalOpen ? (
        <UpgradeEnterpriseModal handleClosure={isUpgradeEnterpriseModalOpenBool.setFalse} />
      ) : null}
    </>
  );
}

const useStyles = makeStyles<DefaultTheme>(({ spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: spacing(1, 0),
    maxWidth: spacing(28),
  },
  userCard: () => ({
    display: "flex",
    alignItems: "center",
    padding: spacing(1),
    borderRadius: 10,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
  userCardName: {
    color: alohi.almostBlack,
    marginRight: spacing(1),
  },
  userCardAvatar: {
    width: 32,
    height: 32,
  },
  icon: {
    fontSize: 14,
    margin: spacing(0, 1),
    color: alohi.almostGray,
  },
  menuItem: {
    minWidth: 250,
    overflow: "hidden",
    display: "flex",
  },
  userInfoContainer: {
    margin: spacing(1, 2, 1, 2),
    display: "flex",
    alignItems: "center",
  },
  userProfileImage: {
    cursor: "pointer",
  },
  logout: {
    color: alohi.red,
  },
  userInfoName: {
    color: alohi.almostBlack,
    fontSize: 14,
  },
  name: {
    fontSize: 12,
  },
  planChip: {
    height: "fit-content",
    paddingTop: spacing(0.2),
    paddingBottom: spacing(0.2),
  },
  planChipLabel: {
    fontSize: 10,
  },
  corporateContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(0.5, 4, 0.5, 4),
  },
  corporateLogo: {
    //
  },
  corporateTypography: {
    marginLeft: spacing(1),
    fontSize: 12,
  },
  corporateNameTypography: {
    fontWeight: "bold",
    marginLeft: spacing(0.3),
    fontSize: 12,
  },
  hipaaCheckIcon: {
    color: alohi.darkerGreen,
    fontSize: 14,
    paddingLeft: spacing(0.5),
  },
  hipaaChip: {
    marginLeft: spacing(2),
    background: alohi.lightestGreen,
    height: "fit-content",
    paddingTop: spacing(0.2),
    paddingBottom: spacing(0.2),
  },
  hipaaChipLabel: {
    color: alohi.darkerGreen,
    fontSize: 10,
  },
}));

export default UserMenu;
