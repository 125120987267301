import { lazy, Suspense } from "react";

import useObjectToUrl from "hooks/useObjectToUrl";
import { RichText } from "views/SendFax/contexts/store";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import PdfViewer from "./PdfViewer";
import ImageViewer from "./ImageViewer";

const LazyAddTextModal = lazy(() => import("views/AddText/AddTextModal"));

interface PreviewFileProps {
  fileIndex: number;
  handleClosure: () => void;
}

function PreviewFile({ handleClosure, fileIndex }: PreviewFileProps) {
  const { sendFaxStore, sendFaxDispatch } = useSendFaxContext();

  const fileObject = sendFaxStore.files?.[fileIndex];
  const isRichText = fileObject?.type === "richText";

  const url = useObjectToUrl(isRichText ? "" : fileObject?.file ?? "");

  const updateRichText = (newFile: RichText | null) => {
    if (newFile) {
      const newFiles = [...sendFaxStore.files];

      if (newFiles[fileIndex]) {
        newFiles[fileIndex] = newFile;

        sendFaxDispatch({
          type: "REPLACE_FILES",
          payload: newFiles,
        });
      }
    }

    handleClosure();
  };

  const handleDownload = () => {
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", fileObject.title ?? "file");
    link.click();
  };

  if (!fileObject?.type) {
    return null;
  }

  switch (fileObject.type) {
    case "faxFile":
      switch (fileObject.mime) {
        case "text/plain":
        case "application/pdf":
          return (
            <PdfViewer pdfUrl={url} handleDownload={handleDownload} handleClosure={handleClosure} />
          );
        case "image/jpg":
        case "image/png":
        case "image/jpeg":
          return (
            <ImageViewer imageUrl={url} title={fileObject?.title} handleClosure={handleClosure} />
          );
        default:
          return null;
      }
    case "richText":
      return (
        <Suspense fallback="">
          <LazyAddTextModal defaultValue={fileObject.content.text} handleClosure={updateRichText} />
        </Suspense>
      );
    default:
      return null;
  }
}

export default PreviewFile;
