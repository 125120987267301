import { Box } from "@alohi/kit";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import { urls } from "routes/urls";
import { PhoneNumberFilter } from "components/Core/Filters";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import EmptyContacts from "components/InfiniTable/EmptyContacts";
import { RecentContact } from "stores/reducers/contacts.helpers";
import AddOrEditContactModal from "views/Contacts/AddOrEditContactModal";
import { SendFaxRouteState } from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import AddOrEditBlacklistContactModal from "views/Contacts/AddOrEditBlacklistContactModal";
import {
  Table,
  Typography,
  makeStyles,
  TableColumnsProp,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "ui";
import {
  selectRecentContacts,
  selectIsRecentContactsLoading,
  selectRecentContactsSelection,
} from "selectors/contacts.selector";
import {
  getRecentContacts,
  updateRecentContactsSelection,
} from "stores/reducers/contacts.recent.reducer";

interface ModalState {
  isOpen: boolean;
  faxNumber: null | string;
}

interface RecentProps {
  noBorder: boolean;
  hasActions: boolean;
  hasSelection: boolean;
  areTabsClickable?: boolean;
}

interface StyleProps {
  hasSelection: boolean;
}

function Recent({ noBorder, hasActions, hasSelection, areTabsClickable }: RecentProps) {
  const classes = useStyles({ hasSelection });
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleNewEntryDestination } = useSendFaxDestinationHelpers();

  const isLoading = useAppSelector<boolean>(selectIsRecentContactsLoading);
  const selection = useAppSelector<string[]>(selectRecentContactsSelection);
  const contactList = useAppSelector<null | RecentContact[]>(selectRecentContacts);

  const [addToWhitelistModal, setAddToWhitelistModal] = useState<ModalState>({
    isOpen: false,
    faxNumber: null,
  });
  const [addToBlacklistModal, setIsAddToBlacklistModal] = useState<ModalState>({
    isOpen: false,
    faxNumber: null,
  });

  const rows = useMemo(() => contactList ?? [], [contactList]);

  const actions = useMemo(() => {
    return (index: number) =>
      rows.map((row) => [
        {
          label: t("COMMON.SEND_FAX"),
          callback: () =>
            history.push(urls.sendFax, {
              destination: [handleNewEntryDestination(row.faxNumber)],
            } satisfies SendFaxRouteState),
        },
        {
          label: "Divider",
        },
        {
          label: t("CONTACTS.ADD_TO_CONTACTS"),
          callback: () => {
            setAddToWhitelistModal({
              isOpen: true,
              faxNumber: row.faxNumber,
            });
          },
        },
        {
          label: t("CONTACTS.ADD_BLACKLIST"),
          callback: () => {
            setIsAddToBlacklistModal({
              isOpen: true,
              faxNumber: row.faxNumber,
            });
          },
        },
      ])[index];
  }, [handleNewEntryDestination, history, rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    if (hasSelection) {
      columns.push({
        width: 15,
        minWidth: 15,
        headerName: " ",
        sortable: false,
        field: "id",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });

      columns.push({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        flex: 0.05,
      });

      columns.push({
        width: 15,
        minWidth: 15,
        headerName: " ",
        sortable: false,
        field: "index",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });
    } else {
      columns.push({
        flex: 0.13,
        headerName: " ",
        sortable: false,
        field: "id",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });
    }

    columns.push({
      flex: 0.87,
      sortable: false,
      field: "faxNumber",
      headerName: t("CONTACTS.FAX_NUMBER"),
      renderCell: function render({ row }: { row: RecentContact }) {
        return (
          <Typography className={classes.row} stopPropagation>
            <PhoneNumberFilter number={row.faxNumber} />
          </Typography>
        );
      },
    });

    if (hasActions) {
      columns.push({
        flex: 0.1,
        sortable: false,
        field: "actions",
        headerName: " ",
        renderCell: function render({ row }: { row: RecentContact }) {
          return <ColumnActions dataIndex={row.index} createActions={actions} />;
        },
      });
    }

    return columns;
  }, [actions, classes.row, hasActions, hasSelection, t]);

  useEffect(() => {
    if (!contactList) {
      dispatch(getRecentContacts({ search: "" }));
    }
  }, [dispatch, contactList]);

  return (
    <>
      <Table
        rows={rows}
        rowHeight={76}
        columns={columns}
        noBorder={noBorder}
        isLoading={isLoading}
        className={classes.root}
        selectionModel={selection}
        checkboxSelection={hasSelection}
        disableSelectionOnClick={!areTabsClickable}
        onSelectionModelChange={(selection) => {
          dispatch(updateRecentContactsSelection(selection as string[]));
        }}
        components={{
          NoRowsOverlay: function render() {
            return <EmptyContacts text={t("CONTACTS.NO_CONTACTS_HERE_YET")} />;
          },
        }}
      />

      {addToWhitelistModal.isOpen && addToWhitelistModal.faxNumber ? (
        <AddOrEditContactModal
          prefilledFaxNumber={addToWhitelistModal.faxNumber}
          handleClosure={() => setAddToWhitelistModal({ isOpen: false, faxNumber: null })}
        />
      ) : null}

      {addToBlacklistModal.isOpen && addToBlacklistModal.faxNumber ? (
        <AddOrEditBlacklistContactModal
          prefilledFaxNumber={addToBlacklistModal.faxNumber}
          handleClosure={() => setIsAddToBlacklistModal({ isOpen: false, faxNumber: null })}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: ({ hasSelection }: StyleProps) => ({
    "&& .MuiDataGrid-columnHeader": {
      padding: spacing(0, 1),
    },
    "&& .MuiDataGrid-columnHeader:nth-child(2)": {
      paddingLeft: 2,
    },
    "&&& .MuiDataGrid-cell": {
      overflowY: "auto",
      padding: spacing(2, hasSelection ? 1 : 0),
    },
    "&&&& .MuiDataGrid-row > div:first-child": {
      padding: 0,
    },
  }),
  row: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "text",
  },
}));

export default Recent;
