import PropTypes from "prop-types";
import { components } from "react-select";
import AsyncSelect from "react-select/async-creatable";
import { useTheme } from "ui";

function CoreAsyncSelect({ loadOptions, components, styles, ...rest }) {
  const theme = useTheme();
  return (
    <AsyncSelect
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          borderRadius: theme.shape.borderRadius,
          borderColor: isFocused ? theme.alohi.blue : theme.alohi.lightGray,
          "&:hover": {
            borderColor: isFocused ? theme.alohi.blue : "black",
          },
        }),
        menu: (styles) => ({ ...styles, borderRadius: theme.shape.borderRadius }),
        ...styles,
      }}
      loadOptions={loadOptions}
      components={{ GroupHeading, ...components }}
      {...rest}
      theme={(provided) => ({
        ...provided,
        colors: {
          ...provided.colors,
          primary: theme.alohi.blue,
        },
      })}
    />
  );
}

const groupStyles = {
  color: "white",
  display: "flex",
  background: "#ccc",
  padding: "5px 0px",
  border: "1px solid #ccc",
};

const GroupHeading = (props) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

CoreAsyncSelect.propTypes = {
  components: PropTypes.any,
  loadOptions: PropTypes.any,
  styles: PropTypes.object,
};

export default CoreAsyncSelect;
