import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { IconButton, Divider, Typography, makeStyles, Box, useSnackbar } from "@alohi/kit";

import { InputBase } from "ui";
import { useInput } from "hooks/useInput";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import Currency from "components/Core/Currency/Currency";
import { PhoneNumberFilter } from "components/Core/Filters";
import InfiniTable from "components/InfiniTable/InfiniTable";
import CustomNumber from "assets/images/number-type/custom-number.svg?react";
import { selectCustomNumber, verifyCustomNumber } from "stores/reducers/verifications.reducer";
import {
  selectPlanId,
  selectNextPlan,
  selectAreaForCountry,
  selectCountryForPlan,
} from "selectors/plan.selector";
import {
  selectAvailableCustomNumbers,
  selectIsVerifyNumberNoContent,
  selectIsVerifyCustomNumberLoading,
} from "selectors/verifications.selector";

function AddCustomNumberComponent({ handleClosure, actionType }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentPlanId = useSelector(selectPlanId);
  const selectedPlan = useSelector(selectNextPlan);
  const selectedArea = useSelector(selectAreaForCountry);
  const selectedCountry = useSelector(selectCountryForPlan);
  const isNoContent = useSelector(selectIsVerifyNumberNoContent);
  const isLoading = useSelector(selectIsVerifyCustomNumberLoading);
  const { country_code: countryCode } = useSelector(selectCountryForPlan);
  const availableCustomNumbers = useSelector(selectAvailableCustomNumbers);

  const [numberValue, numberInput] = useInput("");

  const handleNumberSelection = useCallback(
    (_, __, indexSelected) => {
      dispatch(selectCustomNumber(availableCustomNumbers[indexSelected]));
      handleClosure(null);
    },
    [availableCustomNumbers, handleClosure, dispatch],
  );

  const handleNumberVerification = useCallback(() => {
    if (numberValue?.length < 2) {
      enqueueSnackbar(t("ADD_NUMBER.MIN_CHAR_SEARCH"), { variant: "warning" });
      return;
    }

    const numberType = selectedArea.isTollFree ? "tollfree" : "local";
    const planId = selectedPlan?.id ? selectedPlan.id : currentPlanId;
    const areaCode = selectedArea.areaCode;
    const countryCode = selectedCountry.value;

    dispatch(
      verifyCustomNumber({
        planId,
        areaCode,
        numberType,
        countryCode,
        action: actionType,
        verificationType: "custom",
        contains: numberValue,
      }),
    );
  }, [
    t,
    dispatch,
    actionType,
    numberValue,
    selectedArea,
    selectedPlan,
    currentPlanId,
    selectedCountry,
    enqueueSnackbar,
  ]);

  const tableComponents = useMemo(
    () => ({
      Checkbox: function thumbnailImage() {
        return <CustomNumber />;
      },
    }),
    [],
  );

  const hasCustomNumbers = availableCustomNumbers && availableCustomNumbers.length;

  useOnPressEnter(handleNumberVerification);

  return (
    <>
      <Box px={3} pt={1} pb={2}>
        <Box className={classes.root}>
          <Typography aria-label="country code" className={classes.countryCode}>
            {`+${countryCode}`}
            {selectedArea?.calling_code ? ` (${selectedArea.calling_code})` : null}
          </Typography>
          <InputBase
            elevation={0}
            autoFocus={true}
            value={numberValue}
            className={classes.input}
            onChange={numberInput.onChange}
            placeholder={t("ADD_NUMBER.SEARCH_DIGIT_PHRASE")}
            inputProps={{ "aria-label": "search by digits or phrases" }}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            size="large"
            color="primary"
            aria-label="search"
            isLoading={isLoading}
            icon={faMagnifyingGlass}
            className={classes.iconButton}
            onClick={handleNumberVerification}
          />
        </Box>
      </Box>

      {hasCustomNumbers ? (
        <Box mt={1}>
          <InfiniTable
            columns={TableColumns}
            components={tableComponents}
            data={availableCustomNumbers}
            options={{
              sort: false,
              print: false,
              selectableRows: "single",
              disableToolbarSelect: true,
              selectableRowsOnClick: true,
              selectableRowsHeader: false,
              selectableRowsHideCheckboxes: false,
              onRowSelectionChange: handleNumberSelection,
            }}
          />
        </Box>
      ) : null}

      {isNoContent ? (
        <Box mt={1} textAlign="center">
          {t("COMMON.NO_RESULT_FOUND")}
        </Box>
      ) : null}
    </>
  );
}

const TableColumns = [
  {
    name: "item",
    label: "Number",
    options: {
      customBodyRender: function body({ number }) {
        return <PhoneNumberFilter number={number} />;
      },
    },
  },
  {
    name: "priceAndCurrency",
    label: "One Time Fee",
    options: {
      customBodyRender: function body({ price, currency }) {
        return <Currency value={price} currency={currency} />;
      },
    },
  },
  {
    name: "",
    label: " ",
    options: {
      customBodyRender: function body() {
        return <FontAwesomeIcon icon={faChevronRight} size="sm" />;
      },
    },
  },
];

const useStyles = makeStyles(({ spacing, alohi, shape }) => ({
  root: {
    display: "flex",
    padding: "2px 4px",
    alignItems: "center",
    border: `1px solid ${alohi.lightGray}`,
    borderRadius: shape.borderRadius,
  },
  input: {
    marginLeft: spacing(1),
    flex: 1,
  },
  countryCode: {
    lineHeight: "unset",
    padding: spacing(1),
    paddingRight: 0,
  },
  iconButton: {
    padding: spacing(1),
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

AddCustomNumberComponent.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  actionType: PropTypes.oneOf(["number", "number_replacement", "corporate_number"]).isRequired,
};

export default AddCustomNumberComponent;
