import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Box, makeStyles } from "@alohi/kit";

import { Typography } from "ui";
import AnimatedSuccessIcon from "components/Animations/AnimatedSuccessIcon";

function TransactionSuccessModal({ title, description, handleClosure }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal title={title} confirmTitle={t("COMMON.DONE")} onConfirm={() => handleClosure(true)}>
      <Box className={classes.wrapper} p={4}>
        <Box my={2}>
          <AnimatedSuccessIcon />
        </Box>
        <Box textAlign="center">
          <Typography variant="h4">{t("COMMON.THANKS")}</Typography>
          <Box mt={2}>
            <Typography variant="subtitle3">{description}</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "start",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    fontSize: "6rem",
    color: alohi.green,
    marginBottom: spacing(3),
  },
}));

TransactionSuccessModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default TransactionSuccessModal;
