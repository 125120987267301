import { makeStyles, Typography } from "ui";
import dayjs, { formatLastTimeUsed } from "helpers/date";

interface LastTimeUsedProps {
  timestamp: number;
}

function LastTimeUsed({ timestamp }: LastTimeUsedProps) {
  const classes = useStyles();

  return (
    <Typography className={classes.row} stopPropagation>
      {timestamp === 0 ? "" : formatLastTimeUsed(`${dayjs.unix(timestamp).format()}`)}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    cursor: "text",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default LastTimeUsed;
