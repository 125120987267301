import PropTypes from "prop-types";
import { Box, Modal } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { lazy, Suspense, useMemo } from "react";

import { Typography } from "ui";
import { selectLiveDataLocations, selectBackupDataLocations } from "selectors/account.selector";

const LazyWordMap = lazy(() => import("components/Profile/Compliance/WordMap"));

function MigrateDataModal({
  handleClosure,
  initialLiveData,
  initialBackupData,
  currentLiveData,
  currentBackupData,
}) {
  const { t } = useTranslation();

  const liveData = useSelector(selectLiveDataLocations);
  const backupData = useSelector(selectBackupDataLocations);

  const liveDataCountries = useMemo(() => {
    if (initialLiveData !== currentLiveData && liveData) {
      return {
        initial: liveData.nameMap[initialLiveData].location,
        current: liveData.nameMap[currentLiveData].location,
      };
    }
    return undefined;
  }, [currentLiveData, initialLiveData, liveData]);

  const backupDataCountries = useMemo(() => {
    if (initialBackupData !== currentBackupData && backupData) {
      return {
        initial: backupData.nameMap[initialBackupData].location,
        current: backupData.nameMap[currentBackupData].location,
      };
    }
    return undefined;
  }, [currentBackupData, initialBackupData, backupData]);

  return (
    <Modal
      onConfirm={handleClosure}
      title={t("PROFILE.DATA_RESIDENCY_TRANSFER")}
      confirmTitle={t("PROFILE.DATA_RESIDENCY_CLOSE")}
    >
      <Box p={2}>
        <Typography variant="body" style={{ whiteSpace: "pre-line" }}>
          {t("PROFILE.DATA_RESIDENCY_TRANSFER_INFO")}
        </Typography>
        <Suspense fallback="">
          <LazyWordMap
            liveDataCountries={liveDataCountries}
            backupDataCountries={backupDataCountries}
          />
        </Suspense>
      </Box>
    </Modal>
  );
}

MigrateDataModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  initialLiveData: PropTypes.string.isRequired,
  initialBackupData: PropTypes.string.isRequired,
  currentLiveData: PropTypes.string.isRequired,
  currentBackupData: PropTypes.string.isRequired,
};

export default MigrateDataModal;
