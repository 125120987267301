import { Button, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import { alohiAdminUrls } from "routes/urls";
import useForceRemount from "hooks/useForceRemount";
import ChargeError from "views/Transactions/ChargeError";
import useAdminRedirections from "hooks/useAdminRedirections";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import { selectAccountInfo } from "selectors/account.selector";
import { selectAvailablePlans } from "selectors/plan.selector";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import { selectIsCorporateUpgrade } from "selectors/common.selector";
import { clearChargeAmount } from "stores/reducers/payment.charge.reducer";
import NewBundleRedirect from "components/AddressBundle/NewBundleRedirect";
import ChangePlanFromFreeModal from "views/ChangePlan/ChangePlanFromFreeModal";
import PurchasedNumbersSuccess from "views/Transactions/PurchasedNumbersSuccess";
import { clearCorporateUpgrade } from "stores/reducers/payment.corporate.reducer";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import { getAllNumbers, getAssignedNumbers } from "stores/reducers/numbers.reducer";
import { getAccountDetails, getCustomerInfo } from "stores/reducers/account.reducer";
import { clearUpdateCompany, updateCompanyInfo } from "stores/reducers/corporate.reducer";
import {
  selectChargeAmount,
  selectIsChargeAmountError,
  selectCorporateUpgradeState,
} from "selectors/payment.selector";

function BuyNumber() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cleanReducers = useCleanPlansReducers();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const [key, forceRemount] = useForceRemount();

  const { name } = useSelector(selectAccountInfo);
  const isChargeSuccess = useSelector(selectChargeAmount);
  const availablePlans = useSelector(selectAvailablePlans);
  const isChargeError = useSelector(selectIsChargeAmountError);
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);
  const corporateUpgradeState = useSelector(selectCorporateUpgradeState);

  const [openChargeError, setOpenChargeError] = useState(false);
  const [openChargeSuccess, setOpenChargeSuccess] = useState(false);
  const [openChangePlanFromFree, setOpenChangePlanFromFree] = useState(false);

  const openModal = useCallback(() => {
    cleanReducers();
    setOpenChangePlanFromFree(true);
  }, [cleanReducers]);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: openModal,
    });
  }, [redirectToAdmin, openModal]);

  const handleClosure = useCallback(() => {
    cleanReducers(); // Clean all the information about the cart or numbers
    setOpenChangePlanFromFree(false); // Close everything, cartId can't be used for another charge.
    setOpenChargeError(false);
  }, [cleanReducers]);

  const handleSuccess = useCallback(() => {
    dispatch(getCurrentPlan());
    dispatch(getCurrentCredit());
    dispatch(getAllNumbers());
    dispatch(getAssignedNumbers());
    dispatch(getAccountDetails());
    dispatch(getCustomerInfo());
    dispatch(getCreditDetails());
    cleanReducers();
    setOpenChangePlanFromFree(false);
  }, [cleanReducers, dispatch]);

  useEffect(() => {
    if (isChargeSuccess) {
      setOpenChangePlanFromFree(false);
      setOpenChargeSuccess(true);
      if (isCorporateUpgrade) {
        // Update with firstName if there was no input from the user while upgrading to corporate
        dispatch(
          updateCompanyInfo({
            companyName: corporateUpgradeState.companyName || name,
          }),
        );
        dispatch(clearCorporateUpgrade());
        dispatch(clearUpdateCompany());
      }
      dispatch(clearChargeAmount());
    } else if (isChargeError) {
      setOpenChargeError(true);
    }
  }, [
    name,
    dispatch,
    isChargeError,
    isChargeSuccess,
    isCorporateUpgrade,
    corporateUpgradeState.companyName,
  ]);

  useEffect(() => {
    if (availablePlans && availablePlans.length === 0) {
      setOpenChangePlanFromFree(false);
    }
  }, [availablePlans]);

  return (
    <Box key={key}>
      <Button variant="white" onClick={handleOpenChangePlan}>
        {t("PROFILE.GET_FAX_NUMBER")}
      </Button>
      {/* To buy a number you need to change the plan from free to any paid one */}
      {openChangePlanFromFree ? <ChangePlanFromFreeModal handleClosure={handleClosure} /> : null}

      {openChargeSuccess ? (
        <PurchasedNumbersSuccess
          handleClosure={handleSuccess}
          title={t("COMMON.CHANGE_PLAN")}
          description={t("CHANGE_PLAN.YOUR_PLAN_SETUP")}
        />
      ) : null}

      {openChargeError ? (
        <ChargeError
          handleClosure={(shouldCloseModal) => {
            if (shouldCloseModal) {
              forceRemount();
              setOpenChargeError(false);
            } else {
              handleClosure();
            }
          }}
        />
      ) : null}
      <NewBundleRedirect handleClosure={handleClosure} />
    </Box>
  );
}

export default BuyNumber;
