import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Panel, Button, Typography, Box } from "@alohi/kit";

import { Helper } from "ui";
import { API_STORAGE } from "config";
import Avatar from "components/Core/Avatar/Avatar";
import { developerUrls, helpCenterUrls } from "routes/urls";
import ApiActivationModal from "views/Profile/ApiActivationModal";
import { getApiVerification, getClientPrivateInfo } from "stores/reducers/integrations.api.reducer";
import {
  selectIsApiActive,
  selectApiRedirectionUris,
  selectApiClientPrivateInfo,
} from "selectors/integrations.selector";
import ApiEditRedirectionUrisModal from "./ApiEditRedirectionUrisModal";
import PersonalTokensRow from "./components/PersonalTokensRow/PersonalTokensRow";

function ApiPanel() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isApiActive = useSelector(selectIsApiActive);
  const redirectionUris = useSelector(selectApiRedirectionUris);
  const clientPrivateInfo = useSelector(selectApiClientPrivateInfo);

  const [isApiActivationModalOpen, setIsApiActivationModalOpen] = useState(false);
  const [isApiEditRedirectionUrisModalOpen, setIsApiEditRedirectionUrisModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getApiVerification());
    dispatch(getClientPrivateInfo());
  }, [dispatch]);

  if (isApiActive === null) {
    return null;
  }
  return (
    <>
      <Panel
        id="api"
        headerTitle={
          <Box>
            <Typography type="panelFrontHeader">{t("WEB_SERVICE.TITLE")}</Typography>
            <Box ml={1} component="a" href={helpCenterUrls.api} target="_blank" rel="noreferrer">
              <Helper size="lg" variant="light" />
            </Box>
          </Box>
        }
        sx={{ mb: 7 }}
      >
        <Panel.Front>
          <PersonalTokensRow />

          <Panel.Row>
            <Panel.Cell variant="title">{t("WEB_SERVICE.OAUTH2_TOKEN")}</Panel.Cell>
            <Panel.Cell variant="data">
              {isApiActive ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              {!isApiActive && (
                <Button
                  variant="white"
                  onClick={() => {
                    setIsApiActivationModalOpen(true);
                  }}
                >
                  {t("PROFILE.ACTIVATE")}
                </Button>
              )}
            </Panel.Cell>
          </Panel.Row>

          {!!clientPrivateInfo && (
            <>
              <Panel.Row>
                <Panel.Cell variant="title">{t("WEB_SERVICE.CLIENT_ID")}</Panel.Cell>
                <Panel.Cell variant="data">{clientPrivateInfo.clientId}</Panel.Cell>
              </Panel.Row>
              <Panel.Row>
                <Panel.Cell variant="title">{t("WEB_SERVICE.CLIENT_SECRET")}</Panel.Cell>
                <Panel.Cell variant="data">{clientPrivateInfo.secret}</Panel.Cell>
              </Panel.Row>
              <Panel.Row>
                <Panel.Cell variant="title">{t("WEB_SERVICE.DEVELOPER_NAME")}</Panel.Cell>
                <Panel.Cell variant="data">{clientPrivateInfo.name}</Panel.Cell>
              </Panel.Row>
              <Panel.Row>
                <Panel.Cell variant="title">{t("WEB_SERVICE.DEVELOPER_LOGO")}</Panel.Cell>
                <Panel.Cell variant="data">
                  <Avatar
                    src={`${API_STORAGE}/storage/api_logo?client_id=${clientPrivateInfo.clientId}`}
                    alt={clientPrivateInfo.name}
                  />
                </Panel.Cell>
              </Panel.Row>
              <Panel.Row>
                <Panel.Cell variant="title">{t("WEB_SERVICE.REDIRECT_URI")}</Panel.Cell>
                <Panel.Cell variant="data">
                  {redirectionUris &&
                    redirectionUris.map((uri: string, index: string) => (
                      <Typography key={index}>{uri}</Typography>
                    ))}
                </Panel.Cell>
                <Panel.Cell variant="actions">
                  <Button
                    variant="white"
                    onClick={() => setIsApiEditRedirectionUrisModalOpen(true)}
                  >
                    {t("COMMON.EDIT")}
                  </Button>
                </Panel.Cell>
              </Panel.Row>
            </>
          )}

          <Panel.Row>
            <Panel.Cell variant="title">{t("WEB_SERVICE.DOCUMENTATION")}</Panel.Cell>
            <Panel.Cell variant="actions">
              <a href={developerUrls.github} target="_blank" rel="noopener noreferrer">
                <Button variant="white">{t("WEB_SERVICE.GITHUB")}</Button>
              </a>
              <Box
                component="a"
                sx={{ ml: 2 }}
                href={developerUrls.apiDocs}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="white">{t("COMMON.VIEW")}</Button>
              </Box>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>
      </Panel>

      {isApiActivationModalOpen && (
        <ApiActivationModal handleClosure={() => setIsApiActivationModalOpen(false)} />
      )}

      {isApiEditRedirectionUrisModalOpen && (
        <ApiEditRedirectionUrisModal
          handleClosure={() => setIsApiEditRedirectionUrisModalOpen(false)}
        />
      )}
    </>
  );
}

export default ApiPanel;
