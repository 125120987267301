import { API_STORAGE } from "config";

export function orderByNumber(a, b) {
  if (a.number < b.number) {
    return -1;
  } else if (a.number > b.number) {
    return 1;
  }
  return 0;
}

export function orderByName(a, b) {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function orderByLabel(a, b) {
  if (a.label < b.label) {
    return -1;
  } else if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export function orderByNativeName(a, b) {
  if (a.native_name < b.native_name) {
    return -1;
  } else if (a.native_name > b.native_name) {
    return 1;
  }
  return 0;
}

export function getKeysRecursively(object, keyName) {
  const result = [];
  function recurse(object) {
    for (const key in object) {
      if (typeof object[key] === "object") {
        if (key === keyName) {
          result.push(...Object.values(object[key]));
        }
        recurse(object[key]);
      }
    }
  }
  recurse(object);
  return result;
}

export function urlImage(image) {
  return image ? `${API_STORAGE}${image}` : null;
}

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }

  return navigator.language || "en";
};

export const slackTargetChannelString = (type, name) => {
  let prefix = "";

  switch (type) {
    case "channel":
      prefix = "#";
      break;
    case "direct":
      prefix = "@";
      break;
    case "id":
      return `ID: ${name}`;
    default:
      return name;
  }

  return `${prefix}${name}`;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function generateId(params) {
  // Probabilty of twice the same ID for 10000 generation of 20 alphanumeric characters IDs : 0.000229
  const crypto = window.crypto;
  const array = new Uint8Array(params?.length || 20); // 20 bytes = 160 bits (20 characters * 8 bits per character)
  crypto.getRandomValues(array);
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from(array, (byte) => characters[byte % characters.length]).join("");
}
