import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { Box, Modal, useSnackbar } from "@alohi/kit";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "ui";
import { useInput } from "hooks/useInput";
import Currency from "components/Core/Currency/Currency";
import { isValidRedeemCode } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import { getCurrentCredit } from "stores/reducers/credit.reducer";
import { clearRedeemCode, redeemCode } from "stores/reducers/verifications.reducer";
import {
  selectRedeemCodeError,
  selectRedeemCodeResponse,
  selectIsRedeemCodeRunning,
} from "selectors/verifications.selector";

function RedeemModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isRunning = useSelector(selectIsRedeemCodeRunning);
  const redeemCodeError = useSelector(selectRedeemCodeError);
  const redeemCodeResponse = useSelector(selectRedeemCodeResponse);

  const [redeem, redeemInput] = useInput("", isValidRedeemCode);

  const applyCode = useCallback(() => {
    redeemInput.touch();

    if (redeemInput.isValid) {
      dispatch(redeemCode({ token: redeem }));
    }
  }, [dispatch, redeem, redeemInput]);

  useEffect(() => {
    if (redeemCodeResponse) {
      dispatch(getCurrentCredit());
      enqueueSnackbar(
        <Typography variant="body">
          <Trans
            i18nKey="REDEEM.SUCCESS"
            components={{
              amount: (
                <Currency
                  style={{ display: "contents" }}
                  value={redeemCodeResponse.amount}
                  currency={redeemCodeResponse.currency}
                />
              ),
            }}
          />
        </Typography>,
        { variant: "success" },
      );
      handleClosure();
    }
  }, [enqueueSnackbar, dispatch, redeemCodeResponse, handleClosure]);

  useEffect(() => {
    if (redeemCodeError) {
      enqueueSnackbar(redeemCodeError, { variant: "error" });
      redeemInput.setShowsError(true);
      dispatch(clearRedeemCode());
    }
  }, [dispatch, redeemInput, redeemCodeError, enqueueSnackbar]);

  useEffect(() => {
    return () => {
      dispatch(clearRedeemCode());
    };
  }, [dispatch]);

  return (
    <Modal
      maxWidth="xs"
      onConfirm={applyCode}
      onCancel={handleClosure}
      isConfirmLoading={isRunning}
      title={t("REDEEM.INSERT_CODE")}
      confirmTitle={t("REDEEM.SEND")}
      isConfirmDisabled={redeemInput.showsError}
    >
      <Box py={2} px={3}>
        <FormRowTextInput
          required
          type="text"
          value={redeem}
          id="redeem-input"
          labelGridWidth={12}
          onBlur={redeemInput.onBlur}
          onFocus={redeemInput.onFocus}
          error={redeemInput.showsError}
          onChange={redeemInput.onChange}
          helperText={
            redeemInput.showsError && !redeemInput.isValid
              ? t("FORMS.REDEEM_CODE_LENGTH", { min: 16, max: 64 })
              : null
          }
        />
      </Box>
    </Modal>
  );
}

RedeemModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default RedeemModal;
