import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextButton,
  Typography,
  useSnackbar,
  NavigationModal,
  useNavigationModalApi,
} from "@alohi/kit";

import { Grid, CodeInput } from "ui";
import { useInput } from "hooks/useInput";
import useCountdown from "hooks/useCountdown";
import { gaEvents } from "helpers/googleAnalytics";
import { formatCountdownDuration } from "helpers/date";
import { formatPhoneNumberIntl } from "helpers/numbers";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import { getAccountDetails } from "stores/reducers/account.reducer";
import { selectPhoneIsUnverified } from "selectors/account.selector";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import {
  selectSubmitPhoneVerificationCodeSuccess,
  selectPhoneVerificationBackoffTimeSeconds,
  selectRequestCallPhoneVerificationSuccess,
  selectSubmitPhoneVerificationCodeErrorInvalid,
} from "selectors/verifications.selector";
import {
  submitPhoneVerificationCode,
  requestCallPhoneVerification,
  clearSubmitPhoneVerificationCode,
  clearRequestCallPhoneVerification,
} from "stores/reducers/verifications.reducer";

import { VIEWS } from "../PhoneVerificationModal";
import { usePhoneVerificationApi } from "../context/api";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

function PhoneVerificationEnterCode({ onSuccess }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    store: { phoneNumber },
  } = usePhoneVerificationApi();

  const { sendCustomEvent } = useGoogleTagManager();

  const phoneIsUnverified = useSelector(selectPhoneIsUnverified);
  const success = useSelector(selectSubmitPhoneVerificationCodeSuccess);
  const errorInvalid = useSelector(selectSubmitPhoneVerificationCodeErrorInvalid);
  const isCallMeRequested = useSelector(selectRequestCallPhoneVerificationSuccess);
  const backoffTimeSeconds = useSelector(selectPhoneVerificationBackoffTimeSeconds);

  const [isLoading, setIsLoading] = useState(false);
  const [code, codeInput] = useInput(
    "",
    (code) => code.replaceAll(/\s/g, "")?.length === 6 && !isNaN(code),
  );

  const { updateStep, goToStep } = useNavigationModalApi();

  const handleSubmitCode = useCallback(() => {
    if (codeInput.isValid) {
      setIsLoading(true);
      dispatch(submitPhoneVerificationCode({ code: code }));
    }
  }, [code, codeInput.isValid, dispatch]);

  const handleCancel = useCallback(() => {
    goToStep(VIEWS.ENTER_PHONE);
  }, [goToStep]);

  useEffect(() => {
    updateStep({
      title: `${t("VERIFY.MOBILE_VERIFICATION")} (${formatPhoneNumberIntl(phoneNumber)})`,
      confirmTitle: t("COMMON.CONFIRM"),
      onConfirm: handleSubmitCode,
      onPressEnter: handleSubmitCode,
      onCancel: handleCancel,
      onPressEscape: handleCancel,
      isConfirmLoading: isLoading,
      cancelTitle: t("COMMON.CANCEL"),
      "data-cy": "PhoneVerificationEnterCode",
      isConfirmDisabled: !codeInput.isValid && codeInput.isTouched,
    });
  }, [
    codeInput.isTouched,
    codeInput.isValid,
    handleCancel,
    handleSubmitCode,
    isLoading,
    phoneNumber,
    t,
    updateStep,
  ]);

  const {
    seconds: countdownToUnlockCall,
    start: startCountdownToUnlockCall,
    stop: stopCountdownToUnlockCall,
  } = useCountdown(null);

  useEffect(() => {
    if (backoffTimeSeconds) {
      startCountdownToUnlockCall(backoffTimeSeconds);
      return () => stopCountdownToUnlockCall();
    }
  }, [backoffTimeSeconds, startCountdownToUnlockCall, stopCountdownToUnlockCall]);

  useEffect(() => {
    (async () => {
      if (success) {
        dispatch(clearSubmitPhoneVerificationCode());
        if (phoneIsUnverified) {
          sendCustomEvent({
            event: GoogleAnalyticsCustomEvents.phoneVerification,
          });
        }
        // Update the user information after verification
        // Order is important because ChangePlan modal can be waiting
        await dispatch(getCurrentPlan());
        await dispatch(getCurrentCredit());
        await dispatch(getCreditDetails());
        await dispatch(getAccountDetails()); // Phone verification completed
        setIsLoading(false);
        onSuccess(true);
      }
    })();
  }, [dispatch, onSuccess, phoneIsUnverified, success, sendCustomEvent]);

  useEffect(() => {
    if (errorInvalid) {
      setIsLoading(false);
      enqueueSnackbar(t("VERIFY.INVALID_TOKEN"), { variant: "info" });
      dispatch(clearSubmitPhoneVerificationCode());
    }
  }, [codeInput, dispatch, enqueueSnackbar, errorInvalid, onSuccess, success, t]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      dispatch(clearSubmitPhoneVerificationCode());
      dispatch(clearRequestCallPhoneVerification());
    };
  }, [dispatch]);

  useEffect(() => {
    if (code) {
      codeInput.touch();
    }
  }, [code, codeInput]);

  return (
    <NavigationModal.Content>
      <Box p={4}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography>{t("VERIFY.SENT_SMS_CODE_ABOVE_NUMBER")}</Typography>
              <Typography pt={2}>{t("VERIFY.ENTER_SIX_DIGIT_CODE")}</Typography>
            </Box>
            <Box m={1} textAlign="center">
              <CodeInput
                autoFocus
                fields={6}
                value={code}
                sx={{ mt: 2 }}
                error={codeInput.showsError}
                onChange={codeInput.setValue}
                onBlur={codeInput.onBlur}
                onFocus={codeInput.onFocus}
              />
            </Box>
            <Box display="flex" justifyContent="center" mt={3}>
              {isCallMeRequested ? (
                <Typography>{t("VERIFY.DIALED_YOUR_NUMBER")}</Typography>
              ) : countdownToUnlockCall > 0 ? (
                <Typography>
                  {t("VERIFY.CALL_ME_IN", {
                    timer: formatCountdownDuration(countdownToUnlockCall),
                  })}
                </Typography>
              ) : (
                <TextButton onClick={() => dispatch(requestCallPhoneVerification({ phoneNumber }))}>
                  {t("VERIFY.CALL_ME")}
                </TextButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </NavigationModal.Content>
  );
}

PhoneVerificationEnterCode.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  phoneNumber: PropTypes.string,
};

export default PhoneVerificationEnterCode;
