/* eslint-disable */
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "ui";
import UsersHierarchyTreeUserNode from "./UsersHierarchyTreeUserNode";

/* Our custom theme (node renderer) for react-sortable-tree
 * Reference for the default nodeRenderer can be found here:
 * https://github.com/frontend-collective/react-sortable-tree/blob/master/src/node-renderer-default.js
 * This implementation is also inspired by the "full-node-drag" theme
 * https://github.com/frontend-collective/react-sortable-tree-theme-full-node-drag
 * */

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== "function" &&
    older.children.some((child) => child === younger || isDescendant(child, younger))
  );
}

function UsersHierarchyTreeNodeRenderer({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop,
  canDrag,
  node,
  title,
  subtitle,
  draggedNode,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  className,
  style,
  didDrop,
  treeId, // Not needed, but preserved for other renderers
  isOver, // Not needed, but preserved for other renderers
  parentNode, // Needed for dndManager
  rowDirection,
  ...otherProps
}) {
  const classes = useStyles({ isExpanded: node.expanded ?? true });

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  const nodeContent = connectDragPreview(
    <div
      className={classnames(className, classes.row, {
        [`${classes.rowLandingPad}`]: isLandingPadActive,
        [`${classes.rowLandingCancel}`]: isLandingPadActive && !canDrop,
        [`${classes.rowSearchMatch}`]: isSearchMatch,
        [`${classes.rowSearchFocus}`]: isSearchFocus,
      })}
      style={{
        opacity: isDraggedDescendant ? 0.5 : 1,
        ...style,
      }}
    >
      <UsersHierarchyTreeUserNode node={node} canDrag={canDrag} />
    </div>,
  );

  return (
    <div style={{ height: "100%" }} {...otherProps}>
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === "function") && (
          <div>
            <button
              type="button"
              aria-label={node.expanded ? "Collapse" : "Expand"}
              className={classes.collapseExpandButton}
              style={{ left: -0.5 * scaffoldBlockPxWidth }}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })
              }
            />

            {node.expanded && !isDragging && (
              <div style={{ width: scaffoldBlockPxWidth }} className={classes.lineChildren} />
            )}
          </div>
        )}

      <div
        className={classnames(classes.rowWrapper, {
          [`${classes.rowWrapperDragDisabled}`]: !canDrag,
        })}
      >
        {canDrag ? connectDragSource(nodeContent, { dropEffect: "copy" }) : nodeContent}
      </div>
    </div>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  row: {
    height: "100%",
    whiteSpace: "nowrap",
    display: "flex",
  },
  rowWrapper: {
    padding: 0,
    height: "100%",
    boxSizing: "border-box",
    cursor: "move",
    "&:hover": {
      opacity: 0.7,
    },
    "&:active": {
      opacity: 1,
    },
  },
  rowWrapperDragDisabled: {
    cursor: "default",
    "&:hover": {
      opacity: 1,
    },
  },
  rowLandingPad: {
    border: "none !important",
    boxShadow: "none !important",
    outline: "none !important",
    "& *": {
      opacity: "0 !important",
    },
    "&::before": {
      backgroundColor: alohi.lightestBlue,
      border: "3px dashed white",
      content: '""',
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      zIndex: "-1",
    },
  },
  rowLandingCancel: {
    "&::before": {
      backgroundColor: "#e6a8ad",
    },
  },
  rowSearchMatch: {
    outline: "solid 3px #0080ff",
  },
  rowSearchFocus: {
    outline: "solid 3px #fc6421",
  },
  collapseExpandButton: (props) => ({
    appearance: "none",
    border: "none",
    position: "absolute",
    borderRadius: "100%",
    boxShadow: "0 0 0 1px #000",
    width: "16px",
    height: "16px",
    padding: "0",
    top: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: props.isExpanded
      ? `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==")`
      : `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+")`,
    "&:focus": {
      outline: "none",
      boxShadow: "0 0 0 1px #000, 0 0 1px 3px #83bef9",
    },
    "&:hover:not(:active)": {
      backgroundSize: "24px",
      height: "20px",
      width: "20px",
    },
  }),
  lineChildren: {
    height: "100%",
    display: "inline-block",
    position: "absolute",
    "&::after": {
      content: '""',
      position: "absolute",
      backgroundColor: "black",
      width: "1px",
      left: "50%",
      bottom: "0",
      height: "0px",
    },
  },
}));

UsersHierarchyTreeNodeRenderer.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: "ltr",
};

UsersHierarchyTreeNodeRenderer.propTypes = {
  node: PropTypes.shape({ children: PropTypes.any, expanded: PropTypes.bool }).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,
  // rtl support
  rowDirection: PropTypes.string,
};

export default UsersHierarchyTreeNodeRenderer;
