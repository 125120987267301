import { Trans } from "react-i18next";

import { useAppSelector } from "stores/store";
import { Contact, Group } from "stores/reducers/contacts.helpers";
import { selectGroupById, selectWhitelistContactById } from "selectors/contacts.selector";

interface SingleGroupMessageProps {
  idToShare: string;
  isContact: boolean;
}

function SingleGroupMessage({ idToShare, isContact }: SingleGroupMessageProps) {
  const group = useAppSelector<Group | null>(selectGroupById(idToShare));
  const contact = useAppSelector<Contact | null>(selectWhitelistContactById(idToShare));

  if (isContact && contact) {
    return (
      <Trans i18nKey="CONTACTS.QUESTION_SHARE_CONTACT">
        Are you sure you wish to share your contact
        <strong>{{ name: contact?.fullName }}</strong>
        with all the users from your team?
      </Trans>
    );
  }

  if (group) {
    return (
      <Trans i18nKey="CONTACTS.QUESTION_SHARE_GROUP">
        Are you sure you wish to share your group
        <strong>{{ name: group?.name }}</strong>
        with all the users from your team?
      </Trans>
    );
  }

  return null;
}

export default SingleGroupMessage;
