import { Box, makeStyles } from "@alohi/kit";
import { ZendeskArticle } from "api/types/zendesk";

import ArticlesList from "./components/ArticlesList/ArticlesList";

interface SuggestedArticlesProps {
  articles: ZendeskArticle[];
}

function SuggestedArticles({ articles }: SuggestedArticlesProps) {
  const classes = useStyles({ articlesNb: articles.length });

  if (articles.length === 0) return null;
  return <Box className={classes.container}>{<ArticlesList articles={articles} />}</Box>;
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: "100%",
    maxHeight: "60vh",
    overflowY: "scroll",
    padding: spacing(3),
  },
}));

export default SuggestedArticles;
