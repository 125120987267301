import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { components } from "react-select";

import CountryFlag from "components/Core/CountryFlag/CountryFlag";

const Option = (props) => {
  const { value } = props;
  const { children, ...rest } = props;

  return (
    <components.Option
      {...rest}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <CountryFlag
          code={value}
          style={{
            width: "30px",
            height: "30px",
            marginRight: "8px",
          }}
        />
        <Box display="flex" width={1} alignItems="center" justifyContent="space-between">
          {children}
        </Box>
      </Box>
    </components.Option>
  );
};

Option.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
};

export default Option;
