import { alohi, Box, makeStyles, Typography } from "@alohi/kit";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import Perks from "./Perks";
import { faCircleUp } from "@fortawesome/pro-light-svg-icons";
import useAdminRedirections, { SuccessRedirectToApp } from "hooks/useAdminRedirections";
import { alohiAdminUrls, signPlusUrls } from "routes/urls";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

interface ContentFreeTrialInProgressProps {
  className?: string;
  onClose: () => void;
}

function ContentFreeTrialInProgress({ className, onClose }: ContentFreeTrialInProgressProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { handleRedirection: redirectToAdmin } = useAdminRedirections();
  const { trialInProgressDaysLeft } = useTrialIncentive();

  const handlePrimaryButtonClick = () => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.sign.upgrade,
      adminParams: {
        ["success-redirect-to-app"]: SuccessRedirectToApp.Sign,
      },
    });
  };

  const handleSecondaryButtonClick = () => {
    window.open(signPlusUrls.profile.plans, "_blank");
    onClose();
  };

  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.content}>
        <Typography className={classes.title} type="flow-h1">
          <Trans
            i18nKey="INCENTIVE_SIGN.YOU_HAVE_X_DAYS_LEFT_ON_TRIAL"
            components={{
              strong: <strong />,
            }}
            values={{
              count: trialInProgressDaysLeft,
              value_1: trialInProgressDaysLeft,
            }}
          />
        </Typography>
        <Typography className={classes.description} type="flow-body">
          {t("INCENTIVE_SIGN.MAKE_THE_MOST_OF_YOUR_TRIAL")}
        </Typography>
        <Perks className={classes.perks} />
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.buttonsContainer}>
          <PrimaryButton icon={faCircleUp} onClick={handlePrimaryButtonClick}>
            <Typography>{t("INCENTIVE_SIGN.UPGRADE_NOW")}</Typography>
          </PrimaryButton>
          <SecondaryButton onClick={handleSecondaryButtonClick}>
            <Typography>{t("INCENTIVE_SIGN.MANAGE_PLAN")}</Typography>
          </SecondaryButton>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "scroll",
    [breakpoints.down("sm")]: {
      padding: spacing("90px", 2, 2, 2),
    },
  },
  title: {
    fontWeight: "normal",
  },
  description: {
    marginTop: spacing(2),
  },
  perks: {
    marginTop: spacing(4),
  },
  footer: {
    height: "fit-content",
    [breakpoints.down("sm")]: {
      padding: spacing(2),
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: "solid",
      borderColor: alohi.neutral200,
    },
  },
  buttonsContainer: {
    marginTop: spacing(4),
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
    [breakpoints.down("sm")]: {
      marginTop: spacing(0),
    },
  },
}));

export default ContentFreeTrialInProgress;
