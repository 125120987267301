import { Trans, useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Container, Button, Typography, Paper, Box } from "@alohi/kit";

import useBool from "hooks/useBool";
import useTitle from "hooks/useTitle";
import { websiteUrls } from "routes/urls";
import { useAppDispatch } from "stores/store";
import { clearRequestDataDeletion } from "stores/reducers/support.reducer";
import ConfirmPasswordModal from "./modals/ConfirmPasswordModal";

function DataDeletionRequest() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useTitle(t("DATA_DELETION.TITLE"));

  const [successMessage, setSuccessMessage] = useState("");

  const [isPasswordModalOpen, isPasswordModalOpenBool] = useBool(false);

  const handlePasswordConfirmation = useCallback(
    (successMessage: string | null) => {
      if (successMessage) {
        setSuccessMessage(successMessage);
      }

      isPasswordModalOpenBool.setFalse();
    },
    [isPasswordModalOpenBool],
  );

  useEffect(() => {
    return () => {
      dispatch(clearRequestDataDeletion());
    };
  }, [dispatch]);

  return (
    <Container maxWidth="sm">
      <Box mb={4}>
        <Typography>
          <Trans
            i18nKey={"DATA_DELETION.PRIVACY_STATEMENT"}
            components={{
              anchor: (
                <a href={websiteUrls.privacyPolicy} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              ),
            }}
          />
        </Typography>
        <Paper
          sx={{
            p: 3,
            mt: 4,
            width: "100%",
            height: "fit-content",
          }}
        >
          {successMessage ? (
            <Typography>{successMessage}</Typography>
          ) : (
            <>
              <Typography sx={{ "&&": { color: "alohi.red" } }}>
                {t("DATA_DELETION.ONCE_YOUR_DELETION_REQUEST_IS_RECEIVED")}
              </Typography>
              <Button variant="red" sx={{ mt: 2 }} onClick={isPasswordModalOpenBool.setTrue}>
                {t("DATA_DELETION.REQUEST_DELETION")}
              </Button>
            </>
          )}
        </Paper>
      </Box>

      {isPasswordModalOpen ? (
        <ConfirmPasswordModal handleClosure={handlePasswordConfirmation} />
      ) : null}
    </Container>
  );
}

export default DataDeletionRequest;
