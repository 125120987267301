import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useAppSelector } from "stores/store";
import { Contact } from "stores/reducers/contacts.helpers";
import { selectSharedContactById, selectWhitelistContactById } from "selectors/contacts.selector";

interface SingleWhitelistMessageProps {
  contactId: string;
  isSharedContact: boolean;
}

function SingleWhitelistMessage({ isSharedContact, contactId }: SingleWhitelistMessageProps) {
  const { t } = useTranslation();

  const sharedContact = useAppSelector<Contact | null>(selectSharedContactById(contactId));
  const normalContact = useAppSelector<Contact | null>(selectWhitelistContactById(contactId));

  const contact = useMemo(
    () => (isSharedContact ? sharedContact : normalContact),
    [isSharedContact, normalContact, sharedContact],
  );

  if (!contact) {
    return null;
  }

  return (
    <Trans i18nKey="CONTACTS.QUESTION_REMOVE_CONTACT">
      Are you sure you want to remove
      <strong>{{ name: contact.fullName || contact.faxNumber }}</strong>from your
      {{ listName: t("CONTACTS.CONTACTS").toLowerCase() }}?
    </Trans>
  );
}

export default SingleWhitelistMessage;
