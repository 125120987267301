import { useSnackbar } from "@alohi/kit";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export const ONE_MB = 1000000;
export const MAX_UPLOAD_FILE_SIZE = 10;
export const allowedMimes = ["application/pdf", "image/png"];

function useFilesManager() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<File | null>(null);

  const checkFile = useCallback(
    (files: File[] | FileList) => {
      const file = files[0];

      if (file) {
        const isAllowedFileType = allowedMimes.some((element) => file.type.indexOf(element) !== -1);

        if (!isAllowedFileType) {
          enqueueSnackbar(t("FORMS.FILE_TYPE_NOT_ALLOWED"), { variant: "error" });

          return;
        }

        if (file.size > MAX_UPLOAD_FILE_SIZE * ONE_MB) {
          enqueueSnackbar(
            t("FORMS.LARGE_FILE", {
              replace: { size: MAX_UPLOAD_FILE_SIZE },
            }),
            { variant: "error" },
          );

          return;
        }

        setFile(file);
      }
    },
    [enqueueSnackbar, t],
  );

  const removeFile = useCallback(() => setFile(null), []);

  return {
    file,
    checkFile,
    removeFile,
  };
}

export default useFilesManager;
