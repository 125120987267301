import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUser } from "@fortawesome/pro-light-svg-icons";
import { makeStyles, Box, MenuItem, Menu, Typography, Divider } from "@alohi/kit";

import { urls } from "routes/urls";
import { plans } from "enums/plans";
import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import ChangePlanToCustom from "components/Payment/ChangePlanToCustom";
import { selectCanIUseSharedContacts } from "selectors/ability.selector";
import { clearSharedGroups } from "stores/reducers/contacts.sharedGroups.reducer";
import { clearSharedContacts } from "stores/reducers/contacts.sharedContacts.reducer";
import UpgradeDropdown from "./UpgradeDropdown";

const ITEM_HEIGHT = 44;

interface SharedDropdownProps {
  clearAnchorEl: () => void;
  anchorEl: HTMLElement | null;
  onSelection?: (type: "sharedContacts" | "sharedGroups") => void;
}

function SharedDropdown({ onSelection, anchorEl, clearAnchorEl }: SharedDropdownProps) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const canIUseSharedContacts = useAppSelector(selectCanIUseSharedContacts);

  const [isUpgradeModalOpen, isUpgradeModalOpenBool] = useBool(false);

  function handleOnSelection(type: "sharedContacts" | "sharedGroups") {
    return () => {
      if (onSelection) {
        onSelection(type);
      } else {
        if (pathname === urls.contacts.sharedContacts && type === "sharedContacts") {
          dispatch(clearSharedContacts());
        } else if (pathname === urls.contacts.sharedGroups && type === "sharedGroups") {
          dispatch(clearSharedGroups());
        }

        history.push(
          type === "sharedContacts" ? urls.contacts.sharedContacts : urls.contacts.sharedGroups,
        );
      }
      clearAnchorEl();
    };
  }

  if (isUpgradeModalOpen) {
    return (
      <ChangePlanToCustom
        type={plans.enterprise}
        handleClosure={() => {
          clearAnchorEl();
          isUpgradeModalOpenBool.setFalse();
        }}
      />
    );
  }

  if (!canIUseSharedContacts) {
    return (
      <UpgradeDropdown
        anchorEl={anchorEl}
        clearAnchorEl={clearAnchorEl}
        isUpgradeModalOpenBool={isUpgradeModalOpenBool}
      />
    );
  }

  return (
    <Menu
      open
      anchorEl={anchorEl}
      onClose={clearAnchorEl}
      MenuListProps={{ "aria-labelledby": "folders-menu" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 7 + 8 } }}
    >
      <MenuItem className={classes.item} onClick={handleOnSelection("sharedContacts")}>
        <Box className={classes.row}>
          <FontAwesomeIcon className={classes.rowIcon} icon={faUser} />
          <Typography>{t("CONTACTS.SHARED_CONTACTS")}</Typography>
        </Box>
      </MenuItem>

      <Divider />

      <MenuItem className={classes.item} onClick={handleOnSelection("sharedGroups")}>
        <Box className={classes.row}>
          <FontAwesomeIcon className={classes.rowIcon} icon={faUsers} />
          <Typography>{t("CONTACTS.SHARED_GROUPS")}</Typography>
        </Box>
      </MenuItem>
    </Menu>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  item: {
    "&&": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: ITEM_HEIGHT,
      padding: spacing(0, 2, 0, 2),
      justifyContent: "space-between",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowIcon: {
    width: 20,
    color: alohi.almostGray,
    marginRight: spacing(1.5),
  },
}));

export default SharedDropdown;
