import { Box } from "@alohi/kit";
import { Trans, useTranslation } from "react-i18next";

import { Typography } from "ui";
import { useAppSelector } from "stores/store";
import { formatDefaultDate } from "helpers/date";
import { DigitFilter } from "components/Core/Filters";
import Currency from "components/Core/Currency/Currency";
import { selectPlanPageResetDate, selectIsPlanFree } from "selectors/plan.selector";
import {
  selectCurrentCredit,
  selectCreditPagesInPlan,
  selectCreditPagesInCredit,
  selectCurrentCreditCurrency,
} from "selectors/credit.selector";

function RemainingPagesDetails() {
  const { t } = useTranslation();

  const isPlanFree = useAppSelector(selectIsPlanFree);
  const currentCredit = useAppSelector(selectCurrentCredit);
  const pagesInCredit = useAppSelector(selectCreditPagesInCredit);
  const creditPagesInPlan = useAppSelector(selectCreditPagesInPlan);
  const pageResetDate = useAppSelector(selectPlanPageResetDate);
  const currentCreditCurrency = useAppSelector(selectCurrentCreditCurrency);

  if (!creditPagesInPlan && !(creditPagesInPlan >= 0)) {
    return null;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box mb={2}>
          <Typography variant="body">{t("PROFILE.REMAINING_PAGES_ON_YOUR_PLAN")}</Typography>
          {pageResetDate && !isPlanFree ? (
            <Typography variant="signatureConsent">
              {t("PROFILE.REMAINING_PAGES_ON_YOUR_PLAN_RESET_DATE", {
                date: formatDefaultDate(pageResetDate),
              })}
            </Typography>
          ) : null}
        </Box>
        <Typography ml={4}>
          <DigitFilter value={creditPagesInPlan} />
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body">
          {currentCreditCurrency ? (
            <Trans
              i18nKey="PROFILE.REMAINING_PAGES_FOR_YOUR_CREDIT"
              components={{
                credit: (
                  <Currency
                    key={currentCredit}
                    value={currentCredit}
                    currency={currentCreditCurrency}
                  />
                ),
              }}
            />
          ) : null}
        </Typography>

        <Typography ml={4}>
          <DigitFilter value={pagesInCredit} />
        </Typography>
      </Box>
    </>
  );
}

export default RemainingPagesDetails;
