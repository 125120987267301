import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import Select from "components/Forms/Select/Select";
import { getI18nCountryList } from "helpers/numbers";
import { selectDefaultCountry } from "selectors/verifications.selector";
import { useTheme } from "ui";

import Option from "./Option";
import SingleValue from "./SingleValue";

/**
 * https://ipapi.co/json/
 *
 * {
  ip: "185.19.29.41",
  city: "Crissier",
  region: "Vaud",
  region_code: "VD",
  country: "CH",
  country_name: "Switzerland",
  continent_code: "EU",
  in_eu: false,
  postal: "1023",
  latitude: 46.5459,
  longitude: 6.5757,
  timezone: "Europe/Zurich",
  utc_offset: "+0200",
  country_calling_code: "+41",
  currency: "CHF",
  languages: "de-CH,fr-CH,it-CH,rm",
  asn: "AS61098",
  org: "Akenes SA"
  }
 *  */

function CountrySelect(props) {
  const { styles, ...rest } = props;
  const theme = useTheme();
  const { defaultCountry } = useSelector(selectDefaultCountry);

  const countries = useMemo(() => {
    const countryArray = getI18nCountryList();
    if (!defaultCountry) {
      return countryArray;
    }
    const countryIndex = countryArray.findIndex((element) => element.value === defaultCountry);
    if (countryIndex === -1) {
      return countryArray;
    }
    const userCountry = countryArray[countryIndex];
    countryArray.splice(countryIndex, 1);
    countryArray.unshift(userCountry);
    return countryArray;
  }, [defaultCountry]);

  const selectStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    control: (styles) => ({ ...styles, margin: "8px auto" }),
    option: (provided, state) => ({
      ...provided,
      color: theme.alohi.gray,
      background: state.isSelected ? theme.alohi.lightestBlue : theme.alohi.white,
      ":hover": {
        background: theme.alohi.lighterGray,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.alohi.gray,
    }),
  };

  return (
    <Select
      cacheOptions
      defaultOptions
      options={countries}
      defaultValue={countries[0]}
      menuPortalTarget={document.body}
      components={{ Option, SingleValue }}
      styles={{ ...selectStyles, ...styles }}
      {...rest}
    />
  );
}

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  styles: PropTypes.object,
};

export default CountrySelect;
