import {
  PaletteOptions as MuiPaletteOptions,
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
  useTheme,
} from "@mui/material";

const alohi = {
  almostBlack: "#14140F",
  gray: "#14140F",
  almostGray: "#14140F",
  lightGray: "#a6a6a6",
  darkGray: "#c8c8cd",
  lighterGray: "#DADADA",
  lightestGray: "#FAFBFC",
  darkBlue: "#236290",
  blue: "#4B77BE",
  lightBlue: "#0A69FF",
  lighterBlue: "#59abe3",
  lightestBlue: "#E8EFFB",
  darkerGreen: "#002d32",
  green: "#169f0f",
  progressGreen: "#02a200",
  lightGreen: "#3bc46d",
  progressGreenPrimary: "#92ed9b",
  transparentGreen: "rgba(39,194,76,0.2)",
  lighterGreen: "#b9f6de",
  lightestGreen: "#dcf1db",
  red: "#FF4B5F",
  lightRed: "#ea8168",
  lighterRed: "#FFE4E7",
  lightestRed: "#fadfd9",
  yellow: "#f1c40f",
  lightestYellow: "#fdf6db",
  white: "#ffffff",
  whiteBackground: "rgba(255, 255, 255, 0.25)",
  shadow: "0px 0px 2px 0px #E0E0E0,0px 1px 4px -2px #18274B05,0px 4px 4px -2px #18274B0F",
  bannerShadow: "0px 0px 2px 0px #E0E0E0,0px 1px 4px -2px #18274B05,0px 4px 4px -2px #18274B0F",
  borderShadow: "0 1px 4px 1px #e0e0e0",
  pinkGradient: "linear-gradient(261deg, #b0c9ea 0px, #ddc1fa 100%)",
  gold: "#f9d21a",
};

export default {
  zIndex: {
    modal: 1000,
  },
  alohi,
  typography: {
    useNextVariants: true,
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
  },
  shape: { borderRadius: 8 },
  palette: {
    alohi,
    primary: {
      main: alohi.blue,
    },
    secondary: {
      main: "#DD2C00",
    },
  },
  overrides: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: alohi.gray,
          "& .MuiDataGrid-cell": {
            padding: "0 16px",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "0 16px",
          },
        },
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      },
    },
    MuiButton: {
      root: {
        textTransform: "none" as const,
      },
      textPrimary: {
        color: alohi.lightBlue,
        lineHeight: 1.5, // Match body
        "&:hover": { backgroundColor: "transparent" },
      },
      textSecondary: {
        lineHeight: 1.5, // Match body
        "&:hover": { backgroundColor: "transparent" },
      },
      text: {
        "&:hover": { backgroundColor: "transparent" },
      },
      contained: {
        backgroundColor: alohi.lightGray,
        color: alohi.white,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none !important",
          backgroundColor: alohi.lightGray,
        },
      },
      containedPrimary: {
        backgroundColor: alohi.lightBlue,
        "&:hover": { backgroundColor: `${alohi.lightBlue} !important` },
        "&.Mui-disabled": {
          color: alohi.white,
          backgroundColor: alohi.lightBlue,
          opacity: 0.4,
        },
      },
    },
    MuiFormControlLabel: {
      label: { color: alohi.gray, fontSize: "0.875rem" },
    },
    MuiToolbar: {
      root: {
        backgroundColor: alohi.lighterBlue,
      },
    },
    MuiInputBase: {
      input: {
        color: alohi.gray,
        fontSize: 14,
      },
    },
    MuiSelect: {
      icon: {
        color: alohi.gray,
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
        "&:hover": {
          backgroundColor: "#d0d0d040",
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.875rem",
        padding: "8px 16px",
        "&:hover": {
          background: alohi.lighterGray,
        },
        "&.Mui-selected": {
          background: alohi.lightestBlue,
        },
      },
    },
    MuiListSubheader: {
      root: {
        fontWeight: "bold",
        color: alohi.gray,
      },
    },

    // MUI Datatable Overrides
    MUIDataTable: {
      root: {},
      responsiveBase: {
        overflow: "hidden",
      },
      paper: { height: "100%" },
      tableRoot: {
        display: "table",
        width: "100%",
        "& .MuiTableSortLabel-icon": {
          color: alohi.gray + "!important",
        },
      },
    },
    MUIDataTableBodyRow: {
      root: {
        backgroundColor: "white",
        "&:hover": { backgroundColor: "white !important" },
        "&:nth-of-type(odd)": {
          backgroundColor: alohi.lightestGray,
          "&:hover": { backgroundColor: `${alohi.lightestGray} !important` },
        },
        "&.mui-row-selected": {
          backgroundColor: "white",
          "&:nth-of-type(odd)": {
            backgroundColor: alohi.lightestGray,
          },
        },
        borderLeft: `1px solid ${alohi.lighterGray}`,
        borderRight: `1px solid ${alohi.lighterGray}`,
        borderBottom: `1px solid ${alohi.lighterGray}`,
        "&:last-child": {
          borderBottom: `1px solid ${alohi.lighterGray}`,
        },
      },
    },
    MUIDataTableHeadRow: {
      root: {
        borderLeft: `1px solid ${alohi.lighterGray}`,
        borderRight: `1px solid ${alohi.lighterGray}`,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        padding: "0 16px",
        background: alohi.lightestBlue,
        borderTopStyle: "none",
        fontWeight: "bold",
        color: alohi.gray,
        height: 48,
      },
      data: {
        whiteSpace: "nowrap",
      },
      sortActive: {
        color: "inherit",
      },
      toolButton: {
        fontWeight: "bold",
        color: alohi.gray + "!important",
        "&:hover": { backgroundColor: "transparent !important" },
      },
    },
    MUIDataTableSelectCell: {
      root: {
        padding: "0 16px",
      },
      headerCell: {
        opacity: 1,
        background: alohi.lightestBlue,
      },
    },
    MUIDataTableHead: {
      main: {
        border: `1px solid ${alohi.lightestBlue}`,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        "&:is(td)": { border: `none` },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiOutlinedInput: {
      margin: "dense" as const,
    },
    MuiMenu: {
      autoFocus: false,
      PaperProps: {
        elevation: 4,
      },
    },
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
};

declare module "@alohi/kit" {
  interface PaletteOptions extends MuiPaletteOptions {
    alohi: typeof alohi;
  }
  interface DefaultTheme extends MuiTheme {
    alohi: typeof alohi;
  }
  interface ThemeOptions extends MuiThemeOptions {
    alohi?: typeof alohi;
  }
}

type ExtendedMuiTheme = { alohi: typeof alohi } & MuiTheme;

const themeHook: <T = ExtendedMuiTheme>() => T = useTheme;

export { themeHook as useTheme };
