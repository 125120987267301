import { Redirect } from "react-router";
import { Route, Router, Switch } from "react-router-dom";

import PrivateRouteLayout from "components/Layout/PrivateLayout/PrivateLayout";
import PublicLayout from "components/Layout/PublicLayout";
import StaticLayout from "components/Layout/StaticLayout";
import Metadata from "components/Metadata/Metadata";
import { ENVIRONMENT_NAME } from "config";
import { history } from "helpers/history";
import { localStorageKeys } from "helpers/localStorage";
import PrivateRoute from "routes/PrivateRoute";
import AuthenticatePayment from "views/AuthenticatePayment/AuthenticatePayment";
import LoginView from "views/Authentication/Login";
import ResetPassword from "views/Authentication/ResetPassword";
import SignUp from "views/Authentication/SignUp";
import SignUpFromReferral from "views/Authentication/SignUpFromReferral";
import Contacts from "views/Contacts/Contacts";
import Dashboard from "views/Dashboard/Dashboard";
import DataDeletionRequest from "views/DataRequests/DataDeletionRequest";
import DataExportRequest from "views/DataRequests/DataExportRequest";
import FaxConfirmation from "views/FaxConfirmation/FaxConfirmation";
import Faxes from "views/Faxes/Faxes";
import UploaderView from "views/InlineFrames/UploaderView/UploaderView";
import Newsletter from "views/Newsletter/Newsletter";
import Numbers from "views/Numbers/Numbers";
import Oauth from "views/Oauth/Oauth";
import OneDrive from "views/OneDrive/OneDrive";
import Profile from "views/Profile/Profile";
import Rates from "views/Rates/Rates";
import Redeem from "views/Redeem/Redeem";
import DocumentToFax from "views/SendFax/components/DocumentToFax";
import InitSendFaxProviders from "views/SendFax/components/InitSendFaxProviders";
import InlineFrameToFax from "views/SendFax/components/InlineFrameToFax";
import ReuseFax from "views/SendFax/components/ReuseFax";
import SendFaxGoogleDrive from "views/SendFax/components/SendFaxGoogleDrive";
import Users from "views/Users/Users";
import VerifyEmail from "views/VerifyEmail/VerifyEmail";

import DevRedirection from "./DevRedirection";
import NotFound from "./NotFound";
import PublicRoute from "./PublicRoute";
import { urls } from "./urls";
import LoginRedirect from "./LoginRedirect";

export const publicRoutes = [
  "/",
  urls.login,
  urls.signup.root,
  urls.signup.corporateMember,
  urls.invite,
  urls.forgotPassword,
  urls.resetPassword,
] as const; // Only "not" logged in

export const privateRoutes = [
  urls.users,
  urls.numbers,
  urls.dashboard.users,
  urls.dashboard.company,
  urls.faxes.inbox,
  urls.faxes.sent,
  urls.faxes.outbox,
  urls.faxes.trash,
  urls.documentFax,
  urls.sendFax + "/:to?",
  urls.resendFax + "/:cdrId?",
  urls.forwardFax + "/:cdrId",
  urls.replyFax + "/:to/:cdrId",
  urls.inlineFrameToFax,
  urls.sendFaxGoogleDrive,
  urls.contacts.root,
  urls.contacts.blacklist,
  urls.contacts.recent,
  urls.contacts.groups,
  urls.contacts.sharedContacts,
  urls.contacts.sharedGroups,
  urls.profile.general,
  urls.profile.planAndBilling,
  urls.profile.compliance,
  urls.profile.security,
  urls.profile.integrations,
  urls.dataExportRequest,
  urls.dataDeletionRequest,
  urls.oneDrive,
  urls.verifyEmail,
] as const; // Only logged in

export const staticRoutes = [
  urls.confirmation,
  urls.unsubscribe,
  urls.resubscribe,
  urls.authenticatePayment.root,
  urls.authenticatePayment.secret,
  urls.iframe.upload,
] as const; // Logged in or not

const autoRedirectionDomain = localStorage.getItem(localStorageKeys.autoRedirection);

function Routes() {
  return (
    <Router history={history}>
      <Metadata />

      <Switch>
        <PublicRoute exact path="/">
          <LoginRedirect />
        </PublicRoute>

        {/* Only when not logged in, otherwise redirects to faxes */}
        <PublicRoute exact path={publicRoutes}>
          <PublicLayout>
            <Switch>
              <Route path={urls.login} component={LoginView} />
              <Route path={[urls.signup.root, urls.signup.corporateMember]} component={SignUp} />
              <Route path={urls.invite} component={SignUpFromReferral} />
              <Route path={[urls.forgotPassword, urls.resetPassword]} component={ResetPassword} />
            </Switch>
          </PublicLayout>
        </PublicRoute>

        {/* Logged in or not */}
        <Route exact path={staticRoutes}>
          <StaticLayout>
            <Switch>
              <Route path={urls.confirmation} component={FaxConfirmation} />
              <Route path={[urls.unsubscribe, urls.resubscribe]} component={Newsletter} />
              <Route path={urls.authenticatePayment.root} component={AuthenticatePayment} />
              <Route path={urls.authenticatePayment.secret} component={AuthenticatePayment} />
              <Route path={urls.iframe.upload} component={UploaderView} />
            </Switch>
          </StaticLayout>
        </Route>
        <Route exact path={["/irates"]}>
          <Switch>
            <Route path="/irates" component={Rates} />
          </Switch>
        </Route>

        {/* Only when logged in, otherwise redirects to login */}
        <PrivateRoute exact path={privateRoutes}>
          <PrivateRouteLayout>
            <Switch>
              <Route path={urls.users} component={Users} />
              <Route path={urls.numbers} component={Numbers} />
              <Route path={urls.oneDrive} component={OneDrive} />
              <Route path={urls.faxes.root + "/:boxName"} component={Faxes} />
              <Route path={urls.dataExportRequest} component={DataExportRequest} />
              <Route path={urls.profile.root + "/:profileTab"} component={Profile} />
              <Route path={urls.dataDeletionRequest} component={DataDeletionRequest} />
              <Route path={urls.contacts.root + "/:contactsTab?"} component={Contacts} />
              <Route path={[urls.dashboard.users, urls.dashboard.company]} component={Dashboard} />
              <Route path={urls.documentFax} component={DocumentToFax} />
              <Route path={urls.resendFax + "/:cdrId?"} component={ReuseFax} />
              <Route path={urls.forwardFax + "/:cdrId"} component={ReuseFax} />
              <Route path={urls.replyFax + "/:to/:cdrId"} component={ReuseFax} />
              <Route path={urls.documentFax} component={DocumentToFax} />
              <Route path={urls.inlineFrameToFax} component={InlineFrameToFax} />
              <Route path={urls.sendFaxGoogleDrive} component={SendFaxGoogleDrive} />
              <Route path={urls.sendFax + "/:to?"} component={InitSendFaxProviders} />
              <Route path={urls.verifyEmail} component={VerifyEmail} />
            </Switch>
          </PrivateRouteLayout>
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/oauth/authorize"
          render={() => (
            <PrivateRouteLayout hideNavigation={true}>
              <Oauth />
            </PrivateRouteLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/redeem/:id?"
          render={() => (
            <PrivateRouteLayout hideNavigation={true}>
              <Redeem />
            </PrivateRouteLayout>
          )}
        />

        {/* Redirections */}
        <PrivateRoute
          exact
          path={["/archive/inbox", "/archive/sent", "/archive/outbox", "/archive/trash"]}
        >
          <Route
            exact
            path="/archive/:boxName"
            render={({ location, match }) => (
              <Redirect to={`/faxes/${match.params.boxName}${location.search}${location.hash}`} />
            )}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/faxes"
          render={({ location }) => <Redirect to={`/faxes/inbox${location.search}`} />}
        />
        <PrivateRoute
          exact
          path="/faxes/spam"
          render={({ location }) => <Redirect to={`/faxes/inbox${location.search}`} />}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          render={({ location }) => <Redirect to={`/dashboard/company${location.search}`} />}
        />
        <PrivateRoute
          exact
          path="/profile"
          render={({ location }) => <Redirect to={`/profile/general${location.search}`} />}
        />
        {/* Not Found */}
        <Route
          render={() => (
            <StaticLayout>
              <NotFound />
            </StaticLayout>
          )}
        />
      </Switch>

      {ENVIRONMENT_NAME !== "MASTER" && autoRedirectionDomain ? (
        <DevRedirection domain={autoRedirectionDomain} />
      ) : null}
    </Router>
  );
}

export default Routes;
