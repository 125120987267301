import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { depositApi } from "api";
import RS from "enums/requestStatus";
import { selectAccountCid } from "selectors/account.selector";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialDepositState = {
  deposit: initialRequestState,
  confirmDeposit: initialRequestState,
  confirmPurchase: initialRequestState,
};

const clearNewPaymentDeposit = createAction("PAYMENT/CLEAR_NEW_PAYMENT_DEPOSIT");
const clearConfirmPaymentDeposit = createAction("PAYMENT/CLEAR_DEPOSIT_PAYMENT_DEPOSIT");
const clearConfirmPurchaseDeposit = createAction("PAYMENT/CLEAR_DEPOSIT_PURCHASE_DEPOSIT");
const setPaymentDepositError = createAction("PAYMENT/NEW_PAYMENT_DEPOSIT/rejected");

const newPaymentDeposit = createAsyncThunk(
  "PAYMENT/NEW_PAYMENT_DEPOSIT",
  async ({ depositReason, data }, { rejectWithValue }) => {
    try {
      const isMocked = localStorage.getItem("depositMocked") === "true";
      if (isMocked) {
        const customError = {
          origin: {
            reason: "do_not_honor",
          },
        };
        throw customError;
      }
      const response = await depositApi.newDeposit(depositReason, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const confirmPaymentDeposit = createAsyncThunk(
  "PAYMENT/CONFIRM_PAYMENT_DEPOSIT",
  async (id, { rejectWithValue }) => {
    try {
      const response = await depositApi.confirmPaymentDeposit(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const confirmPurchaseDeposit = createAsyncThunk(
  "PAYMENT/CONFIRM_PURCHASE_DEPOSIT",
  async (_, { rejectWithValue, getState }) => {
    try {
      const cid = selectAccountCid(getState());
      const response = await depositApi.confirmPurchaseDeposit(cid);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const depositSlice = {
  [setPaymentDepositError]: (state, action) => {
    state.deposit.response = null;
    state.deposit.status = RS.ERROR;
    state.deposit.error = action.payload;
  },
  [clearNewPaymentDeposit]: (state) => {
    state.deposit.response = null;
    state.deposit.status = RS.IDLE;
    state.deposit.error = null;
  },
  [newPaymentDeposit.pending]: (state) => {
    state.deposit.response = null;
    state.deposit.status = RS.RUNNING;
    state.deposit.error = null;
  },
  [newPaymentDeposit.fulfilled]: (state, action) => {
    state.deposit.response = action.payload;
    state.deposit.status = RS.IDLE;
    state.deposit.error = null;
  },
  [newPaymentDeposit.rejected]: (state, action) => {
    state.deposit.response = null;
    state.deposit.status = RS.ERROR;
    state.deposit.error = action.payload;
  },
  [clearConfirmPaymentDeposit]: (state) => {
    state.confirmDeposit.response = null;
    state.confirmDeposit.status = RS.IDLE;
    state.confirmDeposit.error = null;
  },
  [confirmPaymentDeposit.pending]: (state) => {
    state.confirmDeposit.response = null;
    state.confirmDeposit.status = RS.RUNNING;
    state.confirmDeposit.error = null;
  },
  [confirmPaymentDeposit.fulfilled]: (state, action) => {
    state.confirmDeposit.response = action.payload;
    state.confirmDeposit.status = RS.IDLE;
    state.confirmDeposit.error = null;
  },
  [confirmPaymentDeposit.rejected]: (state, action) => {
    state.confirmDeposit.response = null;
    state.confirmDeposit.status = RS.ERROR;
    state.confirmDeposit.error = action.payload;
  },
  [clearConfirmPurchaseDeposit]: (state) => {
    state.confirmPurchase.response = null;
    state.confirmPurchase.status = RS.IDLE;
    state.confirmPurchase.error = null;
  },
  [confirmPurchaseDeposit.pending]: (state) => {
    state.confirmPurchase.response = null;
    state.confirmPurchase.status = RS.RUNNING;
    state.confirmPurchase.error = null;
  },
  [confirmPurchaseDeposit.fulfilled]: (state, action) => {
    state.confirmPurchase.response = action.payload;
    state.confirmPurchase.status = RS.IDLE;
    state.confirmPurchase.error = null;
  },
  [confirmPurchaseDeposit.rejected]: (state, action) => {
    state.confirmPurchase.response = null;
    state.confirmPurchase.status = RS.ERROR;
    state.confirmPurchase.error = action.payload;
  },
};

export {
  depositSlice,
  newPaymentDeposit,
  initialDepositState,
  confirmPaymentDeposit,
  clearNewPaymentDeposit,
  confirmPurchaseDeposit,
  setPaymentDepositError,
  clearConfirmPaymentDeposit,
  clearConfirmPurchaseDeposit,
};
