import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import { triggerDownload } from "helpers/file";
import { useAppDispatch, useAppSelector } from "stores/store";
import { actions, selectors, thunks } from "stores/reducers/coverSheet.reducer";

const FILE_NAME = "cover-sheet.pdf";

function useDownloadCoverSheet() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<null | File>(null);
  const isError = useAppSelector(selectors.isByIdError);

  const getBlob = useCallback(
    async (blobUrl: string, shouldTriggerDownload: boolean) => {
      try {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        const file = new File([blob], FILE_NAME);

        setFile(file);

        if (shouldTriggerDownload) {
          triggerDownload(blobUrl, FILE_NAME);
        }
      } catch {
        throw new Error(t("ERRORS.UNABLE_TO_DOWNLOAD_FILE"));
      }
    },
    [t],
  );

  const downloadById = useCallback(
    async (id: string, shouldTriggerDownload: boolean) => {
      try {
        const blobUrl = await dispatch(thunks.byId({ id })).unwrap();

        await getBlob(blobUrl, shouldTriggerDownload);
      } catch {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      } finally {
        dispatch(actions.clearById());
      }
    },
    [dispatch, enqueueSnackbar, getBlob, t],
  );

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });

      dispatch(actions.clearById());
    }
  }, [dispatch, enqueueSnackbar, isError, t]);

  return {
    file,
    downloadById,
  };
}

export default useDownloadCoverSheet;
