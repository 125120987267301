import { useEffect, useRef } from "react";

const useUnmount = (callback: () => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    const functionRef = callbackRef.current;
    return () => {
      functionRef();
    };
  }, []);
};

export default useUnmount;
