import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Typography, makeStyles } from "@alohi/kit";

import useCart from "hooks/useCart";
import Currency from "components/Core/Currency/Currency";
import { selectIsCorporateUpgrade } from "selectors/common.selector";
import { selectCorporateUpgradeState } from "selectors/payment.selector";
import { selectIsBundleRequiredToBeCreated, selectNextPlan } from "selectors/plan.selector";
import { selectCurrentCredit, selectCurrentCreditCurrency } from "selectors/credit.selector";
import {
  selectVerifyNumber,
  selectVerifyCustomNumber,
  selectSelectedNumberWithBundle,
} from "selectors/verifications.selector";
import CartTooltip from "../CartTooltip";

function ChangePlanFromFreeFooterInfo() {
  const classes = useStyles();
  const { t } = useTranslation();

  const nextPlan = useSelector(selectNextPlan);
  const credit = useSelector(selectCurrentCredit);
  const selectedNumber = useSelector(selectVerifyNumber);
  const creditCurrency = useSelector(selectCurrentCreditCurrency);
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);
  const corporateUpgrade = useSelector(selectCorporateUpgradeState);
  const selectedCustomNumber = useSelector(selectVerifyCustomNumber);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);
  const selectedNumberWithBundle = useSelector(selectSelectedNumberWithBundle);

  const [lastNumberWithBundleId, setLastNumberWithBundleId] = useState();
  const [lastSelectedNumberId, setLastSelectedNumberId] = useState();
  const [lastSelectedCustomNumberId, setLastSelectedCustomNumberId] = useState();

  const [{ cartTotalAmount }, { updateCart, addIdToCart }] = useCart(false);

  useEffect(() => {
    if (isCorporateUpgrade && nextPlan?.purchase_id) {
      addIdToCart(`plan_${nextPlan.purchase_id}`);
      // Send two ids from two numbers selected to be added to the cart
      if (corporateUpgrade.firstNumber?.id && corporateUpgrade.secondNumber?.id) {
        addIdToCart(corporateUpgrade.firstNumber.id);
        addIdToCart(corporateUpgrade.secondNumber.id);
      }
      updateCart();
    }
  }, [
    updateCart,
    addIdToCart,
    isCorporateUpgrade,
    nextPlan?.purchase_id,
    corporateUpgrade.firstNumber?.id,
    corporateUpgrade.secondNumber?.id,
  ]);

  useEffect(() => {
    if (!isCorporateUpgrade && nextPlan?.purchase_id) {
      if (selectedNumber?.id && selectedNumber?.id !== lastSelectedNumberId) {
        addIdToCart(`plan_${nextPlan.purchase_id}`);
        addIdToCart(selectedNumber.id);
        setLastSelectedNumberId(selectedNumber.id);
        updateCart();
      }
      if (selectedCustomNumber?.id && selectedCustomNumber?.id !== lastSelectedCustomNumberId) {
        addIdToCart(`plan_${nextPlan.purchase_id}`);
        addIdToCart(selectedCustomNumber.id);
        setLastSelectedCustomNumberId(selectedCustomNumber.id);
        updateCart();
      }
      if (selectedNumberWithBundle?.id && selectedNumberWithBundle?.id !== lastNumberWithBundleId) {
        addIdToCart(`plan_${nextPlan.purchase_id}`);
        addIdToCart(selectedNumberWithBundle.id);
        setLastNumberWithBundleId(selectedNumberWithBundle.id);
        updateCart();
      }
    }
  }, [
    addIdToCart,
    isCorporateUpgrade,
    lastNumberWithBundleId,
    lastSelectedCustomNumberId,
    lastSelectedNumberId,
    nextPlan?.purchase_id,
    selectedCustomNumber?.id,
    selectedNumber?.id,
    selectedNumberWithBundle?.id,
    updateCart,
  ]);

  if (isBundleRequiredToBeCreated) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flex="1">
          {cartTotalAmount >= 0 ? (
            <Box display="flex" flexDirection="row">
              <Typography>{t("CHANGE_PLAN.CREDIT_NEEDED_FOR_CHANGE")}</Typography>
              <CartTooltip />
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              <Typography>{t("CHANGE_PLAN.AMOUNT_ADDED_FOR_CHANGE")}</Typography>
              <CartTooltip />
            </Box>
          )}
        </Box>
        <Typography type="bold" component="div" style={{ textAlign: "end", minWidth: "100px" }}>
          {cartTotalAmount !== null && creditCurrency ? (
            <Currency value={Math.abs(cartTotalAmount)} currency={creditCurrency} />
          ) : null}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flex="1">
          <Typography>{t("ADD_NUMBER.YOUR_CURRENT_CREDIT")}</Typography>
        </Box>
        <div style={{ textAlign: "end", minWidth: "100px" }}>
          {credit !== null && creditCurrency ? (
            <Typography type="bold" className={classes.credit}>
              <Currency value={credit} currency={creditCurrency} />
            </Typography>
          ) : null}
        </div>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  root: {
    flex: 1,
    display: "flex",
    borderRadius: "8px",
    padding: spacing(3),
    flexDirection: "column",
    backgroundColor: alohi.neutral100,
    margin: spacing(3, 3, 0, 3),
    border: `1px solid ${alohi.neutral200}`,
  },
  divider: {
    margin: spacing(1.5, 0),
  },
  credit: {
    color: alohi.green,
  },
}));

export default ChangePlanFromFreeFooterInfo;
