import { Redirect } from "react-router";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, makeStyles, Box } from "@alohi/kit";
import { Suspense, lazy, useState, useEffect, useMemo } from "react";

import { Typography } from "ui";
import { urls } from "routes/urls";
import useTitle from "hooks/useTitle";
import Loading from "components/Loading/Loading";
import DateRangeSelect from "components/Dashboard/DateRangeSelect";
import EnterpriseBanner from "components/Dashboard/EnterpriseBanner";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import DashboardNavigation from "components/Dashboard/DashboardNavigation";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import BusinessUpgradePerks from "components/Dashboard/BusinessUpgradePerks";
import { selectIsCurrentPlanBusiness, selectIsPlanCorporate } from "selectors/plan.selector";
import { selectUsagePeriods, selectIsUsagePeriodsRunning } from "selectors/corporate.selector";
import UsersGraphSelect, { UsersGraphDisplayTypes } from "components/Dashboard/UsersGraphSelect";
import CompanyGraphSelect, {
  CompanyGraphDisplayTypes,
} from "components/Dashboard/CompanyGraphSelect";

const UsersConsumption = lazy(() => import("components/Dashboard/UsersConsumption"));
const CompanyConsumption = lazy(() => import("components/Dashboard/CompanyConsumption"));

function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const isPlanBusiness = useSelector(selectIsCurrentPlanBusiness);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const usagePeriods = useSelector(selectUsagePeriods);
  const isRunning = useSelector(selectIsUsagePeriodsRunning);

  const { pathname } = useLocation();

  const [activeUsersGraphType, setActiveUsersGraphType] = useState<UsersGraphDisplayTypes>("graph");
  const [activeCompanyGraphType, setActiveCompanyGraphType] =
    useState<CompanyGraphDisplayTypes>("cumulative");

  const title = useMemo(() => {
    if (pathname === urls.dashboard.company) {
      return `${t("DASHBOARD.TITLE")} - ${t("DASHBOARD.COMPANY_CONSUMPTION")}`;
    } else if (pathname === urls.dashboard.users) {
      return `${t("DASHBOARD.TITLE")} - ${t("DASHBOARD.USERS_CONSUMPTION")}`;
    }

    return t("DASHBOARD.TITLE");
  }, [pathname, t]);

  useTitle(title);

  useEffect(() => {
    if (!Boolean(usagePeriods)) {
      // Fetch the last 6 billing periods
      dispatch(corporateAsyncActions.getUsagePeriods({ period: 6 }));
    }
  }, [dispatch, usagePeriods]);

  if (!isPlanCorporate || isCorporateMember) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="sm">
      {!isPlanBusiness ? (
        <>
          <Box sx={{ mb: 6 }}>
            <EnterpriseBanner />
          </Box>

          <DashboardNavigation />

          <Box className={classes.filters}>
            {
              {
                [urls.dashboard.company]: (
                  <CompanyGraphSelect
                    activeGraphType={activeCompanyGraphType}
                    setActiveGraphType={setActiveCompanyGraphType}
                  />
                ),
                [urls.dashboard.users]: (
                  <UsersGraphSelect
                    activeGraphType={activeUsersGraphType}
                    setActiveGraphType={setActiveUsersGraphType}
                  />
                ),
                loading: null,
              }[pathname]
            }
            <DateRangeSelect />
          </Box>

          <Card>
            <Card.Header>{title}</Card.Header>
            <Suspense
              fallback={
                <Box className={classes.loader}>
                  <Typography variant="subtitle3" align="center" component="span">
                    {t("COMMON.LOADING")}
                  </Typography>
                  <Loading />
                </Box>
              }
            >
              {
                {
                  [urls.dashboard.company]: (
                    <Card.Content
                      sx={{ p: activeCompanyGraphType === "table" ? 0 : 3 }}
                      variant={activeCompanyGraphType === "table" ? "noborder" : "border"}
                    >
                      <CompanyConsumption activeGraphType={activeCompanyGraphType} />
                    </Card.Content>
                  ),
                  [urls.dashboard.users]: (
                    <Card.Content
                      sx={{ p: activeUsersGraphType === "table" ? 0 : 3 }}
                      variant={activeUsersGraphType === "table" ? "noborder" : "border"}
                    >
                      <UsersConsumption activeGraphType={activeUsersGraphType} />
                    </Card.Content>
                  ),
                  loading: (
                    <Card.Content variant="noborder">
                      <Box className={classes.loader}>
                        <Typography variant="subtitle3" align="center" component="span">
                          {t("COMMON.LOADING")}
                        </Typography>
                        <Loading />
                      </Box>
                    </Card.Content>
                  ),
                }[isRunning ? "loading" : pathname]
              }
            </Suspense>
          </Card>
        </>
      ) : (
        <BusinessUpgradePerks />
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  filters: {
    padding: theme.spacing(1, 0),
    display: "flex",
    flexFlow: "wrap",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

export default Dashboard;
