import queryString from "query-string";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import { boxNames } from "../enums/faxes";
import { selectIsGracefulLogout, selectIsLoggedIn } from "../selectors/authentication.selector";

const PublicRoute = ({ children, ...otherProps }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const { _gl } = queryString.parse(location.search);

  const isGracefulLogout = useSelector(selectIsGracefulLogout);

  let redirectTo;
  if (isGracefulLogout) {
    redirectTo = `/faxes/${boxNames.inbox}`;
  } else {
    redirectTo = location.state?.redirectTo ?? `/faxes/${boxNames.inbox}`;
  }
  if (_gl) redirectTo += `?_gl=${_gl}`;

  return isLoggedIn ? (
    <Redirect to={redirectTo} />
  ) : (
    <Route render={() => children} {...otherProps} />
  );
};

PublicRoute.propTypes = {
  children: PropTypes.node,
};

export default PublicRoute;
