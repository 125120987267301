import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { creditCardsApi } from "api";

import RS from "enums/requestStatus";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialCreditCardState = {
  creditCard: {
    creditCardInfo: null,
    isCreditCardCompleted: false,
    stripe: initialRequestState,
    newCreditCard: initialRequestState,
    updateCreditCard: initialRequestState,
    deleteCreditCard: initialRequestState,
  },
};

export const clearCreditCardInfo = createAction("CREDIT_CARD/CLEAR_CREDIT_CARD_INFO");
export const isCreditCardCompleted = createAction("CREDIT_CARD/IS_CREDIT_CARD_COMPLETED");
export const updateCreditCardInfo = createAction("CREDIT_CARD/UPDATE_CREDIT_CARD_INFO");
export const triggerStripePayment = createAction("CREDIT_CARD/TRIGGER_STRIPE_PAYMENT");
export const updateStripePaymentId = createAction("CREDIT_CARD/UPDATE_STRIPE_PAYMENT_ID");

export const clearStripePayment = createAction("CREDIT_CARD/CLEAR_STRIPE_PAYMENT");
export const clearDeleteCreditCard = createAction("CREDIT_CARD/CLEAR_DELETE_CREDIT_CARD");
export const clearUpdateCreditCard = createAction("CREDIT_CARD/CLEAR_UPDATE_CREDIT_CARD");
export const clearNewCreditCard = createAction("CREDIT_CARD/CLEAR_NEW_CREDIT_CARD");

const getCreditCardById = createAsyncThunk(
  "CREDIT_CARD/GET_CREDIT_CARD_BY_ID",
  async (cardId, { rejectWithValue }) => {
    try {
      const response = await creditCardsApi.get(cardId);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const deleteCreditCardById = createAsyncThunk(
  "CREDIT_CARD/DELETE_CREDIT_CARD_BY_ID",
  async (cardId, { rejectWithValue }) => {
    try {
      const response = await creditCardsApi.deleteCard(cardId);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const newCreditCard = createAsyncThunk(
  "CREDIT_CARD/NEW_CREDIT_CARD",
  async (cardData, { rejectWithValue }) => {
    try {
      const isMocked = localStorage.getItem("newCreditCardMocked") === "true";
      if (isMocked) {
        const customError = {
          origin: {
            reason: "do_not_honor",
          },
        };
        throw customError;
      }
      const response = await creditCardsApi.post(cardData);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const updateCreditCard = createAsyncThunk(
  "CREDIT_CARD/UPDATE_CREDIT_CARD",
  async (cardId, { rejectWithValue }) => {
    try {
      const response = await creditCardsApi.patch({
        default_source: cardId,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const creditCardSlice = {
  [isCreditCardCompleted]: (state, action) => {
    state.creditCard.isCreditCardCompleted = action.payload;
  },
  [updateCreditCardInfo]: (state, action) => {
    state.creditCard.creditCardInfo = action.payload;
  },
  [clearCreditCardInfo]: (state) => {
    state.creditCard.creditCardInfo = null;
    state.creditCard.isCreditCardCompleted = false;
  },
  [clearDeleteCreditCard]: (state) => {
    state.creditCard.deleteCreditCard.response = null;
    state.creditCard.deleteCreditCard.status = RS.IDLE;
    state.creditCard.deleteCreditCard.error = null;
  },
  [clearUpdateCreditCard]: (state) => {
    state.creditCard.updateCreditCard.response = null;
    state.creditCard.updateCreditCard.status = RS.IDLE;
    state.creditCard.updateCreditCard.error = null;
  },
  [clearNewCreditCard]: (state) => {
    state.creditCard.newCreditCard.response = null;
    state.creditCard.newCreditCard.status = RS.IDLE;
    state.creditCard.newCreditCard.error = null;
  },
  [triggerStripePayment]: (state) => {
    state.creditCard.stripe.status = RS.RUNNING;
    state.creditCard.stripe.response = null;
  },
  [clearStripePayment]: (state) => {
    state.creditCard.stripe.status = RS.IDLE;
    state.creditCard.stripe.response = null;
  },
  [updateStripePaymentId]: (state, action) => {
    state.creditCard.stripe.status = RS.IDLE;
    state.creditCard.stripe.response = action.payload;
  },
  [newCreditCard.pending]: (state) => {
    state.creditCard.newCreditCard.response = null;
    state.creditCard.newCreditCard.status = RS.RUNNING;
    state.creditCard.newCreditCard.error = null;
  },
  [newCreditCard.fulfilled]: (state, action) => {
    state.creditCard.newCreditCard.response = action.payload;
    state.creditCard.newCreditCard.status = RS.IDLE;
    state.creditCard.newCreditCard.error = null;
  },
  [newCreditCard.rejected]: (state, action) => {
    state.creditCard.newCreditCard.response = null;
    state.creditCard.newCreditCard.status = RS.ERROR;
    state.creditCard.newCreditCard.error = action.payload;
  },
  [updateCreditCard.pending]: (state) => {
    state.creditCard.updateCreditCard.response = null;
    state.creditCard.updateCreditCard.status = RS.RUNNING;
    state.creditCard.updateCreditCard.error = null;
  },
  [updateCreditCard.fulfilled]: (state, action) => {
    state.creditCard.updateCreditCard.response = action.payload || true;
    state.creditCard.updateCreditCard.status = RS.IDLE;
    state.creditCard.updateCreditCard.error = null;
  },
  [updateCreditCard.rejected]: (state, action) => {
    state.creditCard.updateCreditCard.response = null;
    state.creditCard.updateCreditCard.status = RS.ERROR;
    state.creditCard.updateCreditCard.error = action.payload;
  },
  [deleteCreditCardById.pending]: (state) => {
    state.creditCard.deleteCreditCard.response = null;
    state.creditCard.deleteCreditCard.status = RS.RUNNING;
    state.creditCard.deleteCreditCard.error = null;
  },
  [deleteCreditCardById.fulfilled]: (state, action) => {
    state.creditCard.deleteCreditCard.response = action.payload;
    state.creditCard.deleteCreditCard.status = RS.IDLE;
    state.creditCard.deleteCreditCard.error = null;
  },
  [deleteCreditCardById.rejected]: (state, action) => {
    state.creditCard.deleteCreditCard.response = null;
    state.creditCard.deleteCreditCard.status = RS.ERROR;
    state.creditCard.deleteCreditCard.error = action.payload;
  },
};

export {
  newCreditCard,
  creditCardSlice,
  updateCreditCard,
  getCreditCardById,
  deleteCreditCardById,
  initialCreditCardState,
};
