import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { animated, config, useTransition } from "react-spring";
import { FaButton, Typography, Box, useSnackbar } from "@alohi/kit";

import { MessageBox } from "ui";
import useClipboard from "hooks/useClipboard";
import { selectCreatePersonalAccessToken } from "selectors/integrations.selector";

function DisplayTokenStep() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { copy, isCopied } = useClipboard();
  const token = useSelector(selectCreatePersonalAccessToken);

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar(t("REFERRAL.COPIED_TO_CLIPBOARD"), {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, isCopied, t]);

  const transitions = useTransition(true, {
    config: { ...config.stiff, duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const onCopyTokenToClipboard = () => {
    copy(token);
  };

  return transitions((innerStyles) => (
    <animated.div style={innerStyles}>
      <Box px={2}>
        <Typography>{t("API_TOKEN.CREATE_SUCCESS_HEADER")}</Typography>

        <MessageBox mx={1} mt={2} variant="info" noIcon>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flex={1} p={0.5}>
            <Typography
              type="span"
              sx={{
                textAlign: "left",
                overflow: "hidden",
                wordBreak: "break-all",
              }}
            >
              {token}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} width={50}>
            <FaButton tooltip={t("COMMON.COPY")} icon={faCopy} onClick={onCopyTokenToClipboard} />
          </Box>
        </MessageBox>

        <MessageBox mx={1} mt={2} variant="warning">
          <Box display={"flex"} flexDirection={"column"}>
            <Typography type="span">{t("API_TOKEN.CREATE_SUCCESS_WARNING")}</Typography>
            <Typography type="span">{t("API_TOKEN.CREATE_SUCCESS_WARNING_LINE2")}</Typography>
          </Box>
        </MessageBox>
      </Box>
    </animated.div>
  ));
}

export default DisplayTokenStep;
