import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FaButton, Box, TextButton } from "@alohi/kit";
import { useCallback, useEffect, useMemo } from "react";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

import { useInput } from "hooks/useInput";
import { contactsRoutes } from "enums/contacts";
import { makeStyles, InputBase, Typography } from "ui";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { useOnPressEscape } from "hooks/useOnPressEscape";
import { clearContactsGroups, getGroups } from "stores/reducers/contacts.groups.reducer";
import { clearSharedGroups, getSharedGroups } from "stores/reducers/contacts.sharedGroups.reducer";
import {
  selectGroupsCurrentFetchOptions,
  selectSharedGroupsCurrentFetchOptions,
  selectSharedContactsCurrentFetchOptions,
  selectContactsWhitelistCurrentFetchOptions,
} from "selectors/contacts.selector";
import {
  getWhitelistContacts,
  clearWhitelistContacts,
} from "stores/reducers/contacts.whitelist.reducer";
import {
  getSharedContacts,
  clearSharedContacts,
} from "stores/reducers/contacts.sharedContacts.reducer";

const allowedTabs = [
  contactsRoutes.whitelist,
  contactsRoutes.groups,
  contactsRoutes["shared-groups"],
  contactsRoutes["shared-contacts"],
];

function ContactsFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const groupsCurrentFetchOptions = useSelector(selectGroupsCurrentFetchOptions);
  const sharedGroupsCurrentFetchOptions = useSelector(selectSharedGroupsCurrentFetchOptions);
  const contactsCurrentFetchOptions = useSelector(selectContactsWhitelistCurrentFetchOptions);
  const sharedContactsCurrentFetchOptions = useSelector(selectSharedContactsCurrentFetchOptions);

  const groupsSearch = groupsCurrentFetchOptions?.search ?? "";
  const contactsSearch = contactsCurrentFetchOptions?.search ?? "";
  const sharedGroupsSearch = sharedGroupsCurrentFetchOptions?.search ?? "";
  const sharedContactsSearch = sharedContactsCurrentFetchOptions?.search ?? "";

  const searchStringByTab = useMemo(() => {
    switch (contactsTab) {
      case contactsRoutes.whitelist:
        return contactsSearch;
      case contactsRoutes.groups:
        return groupsSearch;
      case contactsRoutes["shared-groups"]:
        return sharedGroupsSearch;
      case contactsRoutes["shared-contacts"]:
        return sharedContactsSearch;
    }

    return "";
  }, [contactsSearch, contactsTab, groupsSearch, sharedContactsSearch, sharedGroupsSearch]);

  const [searchPhrase, searchPhraseInput] = useInput<string>(searchStringByTab);
  const setValue = searchPhraseInput.setValue;

  const handleSearch = useCallback(() => {
    switch (contactsTab) {
      case contactsRoutes.whitelist:
        dispatch(
          getWhitelistContacts({
            offset: 0,
            type: "json",
            search: searchPhrase,
          }),
        );

        break;
      case contactsRoutes.groups:
        dispatch(
          getGroups({
            offset: 0,
            type: "json",
            shared: false,
            search: searchPhrase,
          }),
        );

        break;
      case contactsRoutes["shared-groups"]:
        dispatch(
          getSharedGroups({
            offset: 0,
            type: "json",
            shared: true,
            search: searchPhrase,
          }),
        );

        break;
      case contactsRoutes["shared-contacts"]:
        dispatch(
          getSharedContacts({
            offset: 0,
            type: "json",
            shared: true,
            search: searchPhrase,
          }),
        );

        break;
    }
  }, [contactsTab, dispatch, searchPhrase]);

  const handleReset = useCallback(() => {
    switch (contactsTab) {
      case contactsRoutes.whitelist:
        dispatch(clearWhitelistContacts());

        break;
      case contactsRoutes.groups:
        dispatch(clearContactsGroups());

        break;
      case contactsRoutes["shared-groups"]:
        dispatch(clearSharedGroups());

        break;
      case contactsRoutes["shared-contacts"]:
        dispatch(clearSharedContacts());

        break;
    }

    setValue("");
  }, [contactsTab, dispatch, setValue]);

  const onPressEnter = useCallback(() => {
    if (searchPhraseInput.isFocused) {
      handleSearch();
    }
  }, [handleSearch, searchPhraseInput.isFocused]);

  const onPressEscape = useCallback(() => {
    if (searchPhraseInput.isFocused) {
      setValue("");
    }
  }, [searchPhraseInput.isFocused, setValue]);

  useOnPressEnter(onPressEnter);
  useOnPressEscape(onPressEscape);

  useEffect(() => {
    if (allowedTabs.includes(contactsTab)) {
      setValue(searchStringByTab);
    }
  }, [contactsTab, searchStringByTab, setValue]);

  if (!allowedTabs.includes(contactsTab)) {
    return <Box sx={{ py: 2 }} />;
  }

  return (
    <Box className={classes.filters}>
      <InputBase
        id="contacts-filter"
        value={searchPhrase}
        placeholder={`${t("COMMON.SEARCH")}...`}
        className={classes.filterBase}
        onBlur={searchPhraseInput.onBlur}
        onFocus={searchPhraseInput.onFocus}
        onChange={searchPhraseInput.onChange}
        endAdornment={<FaButton icon={faMagnifyingGlass} onClick={handleSearch} fontSize={14} />}
      />

      {Boolean(searchStringByTab) ? (
        <TextButton onClick={handleReset}>
          <Typography className={classes.reset}>Reset</Typography>
        </TextButton>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape, breakpoints }) => ({
  filters: {
    display: "flex",
    flexFlow: "wrap",
    [breakpoints.down("sm")]: {
      margin: spacing(2, 0),
    },
  },
  filterBase: {
    margin: spacing(1, 0),
    "& .MuiInputBase-input": {
      padding: spacing(1, 5, 1, 2),
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: 0,
      },
      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: 0,
      },
    },
    "&.MuiInputBase-root, & .MuiInputBase-root": {
      height: 32,
      borderRadius: shape.borderRadius,
      boxShadow: alohi.bannerShadow,
      border: `1px solid ${alohi.lighterGray}`,
      background: alohi.white,
      display: "flex",
      alignItems: "center",
      marginRight: spacing(2),
      [breakpoints.down("sm")]: {
        flexBasis: "100%",
        margin: spacing(0),
      },
    },
  },
  reset: {
    "&&": { color: alohi.red, textDecoration: "underline" },
  },
}));

export default ContactsFilters;
