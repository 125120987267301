import createSelector from "selectorator";
import i18n from "../i18n/index";

import RS from "../enums/requestStatus";

export const selectIsSsoActive = createSelector(
  ["account.sso.getSsoSettings.response"],
  (response) => {
    if (response === null) return null;
    if (!response?.idp) return false;
    return !!response.idp.entity_id || !!response.idp.sso_url || !!response.idp.x509_cert;
  },
);

export const selectSsoSettings = createSelector(
  ["account.sso.getSsoSettings.response"],
  (response) => {
    if (!response) {
      return null;
    }

    return {
      ssoUrl: response.idp.sso_url,
      x509Cert: response.idp.x509_cert,
      entityId: response.idp.entity_id,
      isLegacy: response.idp.legacy_sso,
      scimToken: response.idp.scim_token,
      isOnlyAllowSso: !response.idp.only_allow_sso,
      isAddFaxLicense: response.idp.add_fax_license,
      isAddSignLicense: response.idp.add_sign_license,
      refreshTokenLifetime: response.idp.refresh_token_lifetime,
    };
  },
);

export const selectIsSsoUnset = createSelector(
  ["account.sso.getSsoSettings.response"],
  (response) => {
    if (!response) {
      return false;
    }

    return response.idp?.id === null;
  },
);

export const selectIsUpdateSsoSettingsRunning = createSelector(
  ["account.sso.updateSsoSettings.status"],
  (status) => {
    return status === RS.RUNNING;
  },
);

export const selectIsUpdateSsoSettingsSuccess = createSelector(
  ["account.sso.updateSsoSettings.response"],
  (response) => {
    if (!response) return null;
    return !!response;
  },
);

export const selectUpdateSsoSettingsError = createSelector(
  ["account.sso.updateSsoSettings.error"],
  (error) => {
    if (!error) return null;
    switch (error.reason) {
      case "IdP entity ID is used by another user !":
        return i18n.t("SSO.ENTITY_ID_ALREADY_IN_USE");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsDeactivateSsoSuccess = createSelector(
  ["account.sso.deactivateSso.response"],
  (response) => {
    if (!response) return null;
    return !!response;
  },
);

export const selectIsDeactivateSsoError = createSelector(
  ["account.sso.deactivateSso.error"],
  (error) => {
    return !!error;
  },
);
