import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import RS from "enums/requestStatus";
import { availableCreditAmountsApi } from "api";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialPaymentAmountsState = {
  paymentAmounts: {
    customAmount: {
      ...initialRequestState,
      selected: null,
    },
    possibleAmount: {
      ...initialRequestState,
      selected: null,
    },
    discountAmount: initialRequestState,
  },
};

export const clearDiscountAmount = createAction("PAYMENT/CLEAR_DISCOUNT_AMOUNT");
export const clearCustomPaymentAmount = createAction("PAYMENT/CLEAR_CUSTOM_PAYMENT_AMOUNT");
export const setPossiblePaymentAmount = createAction("PAYMENT/SET_PAYMENT_AMOUNT");

export const setCustomPaymentAmount = createAction(
  "PAYMENT/SET_CUSTOM_PAYMENT_AMOUNT",
  (creditNeeded) => {
    return {
      payload: creditNeeded,
    };
  },
);

const getAvailableCreditAmounts = createAsyncThunk(
  "PAYMENT/CREDIT_AVAILABLE_AMOUNTS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await availableCreditAmountsApi.get();
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getCustomPaymentAmount = createAsyncThunk(
  "PAYMENT/GET_CUSTOM_PAYMENT_AMOUNT",
  async ({ amount, currency }, { rejectWithValue }) => {
    try {
      const response = await availableCreditAmountsApi.post({
        amount,
        currency,
        discount_code: "",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const applyDiscountCode = createAsyncThunk(
  "PAYMENT/APPLY_DISCOUNT_CODE",
  async ({ amount, currency, discountCode }, { rejectWithValue }) => {
    try {
      const response = await availableCreditAmountsApi.post({
        amount,
        currency,
        discount_code: discountCode,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const paymentAmountsSlice = {
  [getCustomPaymentAmount.pending]: (state) => {
    state.paymentAmounts.customAmount.response = null;
    state.paymentAmounts.customAmount.status = RS.RUNNING;
    state.paymentAmounts.customAmount.error = null;
  },
  [getCustomPaymentAmount.fulfilled]: (state, action) => {
    state.paymentAmounts.customAmount.response = action.payload;
    state.paymentAmounts.customAmount.status = RS.IDLE;
    state.paymentAmounts.customAmount.error = null;
  },
  [getCustomPaymentAmount.rejected]: (state, action) => {
    state.paymentAmounts.customAmount.response = null;
    state.paymentAmounts.customAmount.status = RS.ERROR;
    state.paymentAmounts.customAmount.error = action.payload;
  },
  [getAvailableCreditAmounts.pending]: (state) => {
    state.paymentAmounts.possibleAmount.status = RS.RUNNING;
    state.paymentAmounts.possibleAmount.error = null;
    state.paymentAmounts.possibleAmount.selected = null;
  },
  [getAvailableCreditAmounts.fulfilled]: (state, action) => {
    state.paymentAmounts.possibleAmount.response = action.payload;
    state.paymentAmounts.possibleAmount.status = RS.IDLE;
    state.paymentAmounts.possibleAmount.error = null;
    state.paymentAmounts.possibleAmount.selected = null;
  },
  [getAvailableCreditAmounts.rejected]: (state, action) => {
    state.paymentAmounts.possibleAmount.status = RS.ERROR;
    state.paymentAmounts.possibleAmount.error = action.payload;
    state.paymentAmounts.possibleAmount.selected = null;
  },
  [applyDiscountCode.pending]: (state) => {
    state.paymentAmounts.discountAmount.response = null;
    state.paymentAmounts.discountAmount.status = RS.RUNNING;
    state.paymentAmounts.discountAmount.error = null;
  },
  [applyDiscountCode.fulfilled]: (state, action) => {
    state.paymentAmounts.discountAmount.response = action.payload;
    state.paymentAmounts.discountAmount.status = RS.IDLE;
    state.paymentAmounts.discountAmount.error = null;
  },
  [applyDiscountCode.rejected]: (state, action) => {
    state.paymentAmounts.discountAmount.response = null;
    state.paymentAmounts.discountAmount.status = RS.ERROR;
    state.paymentAmounts.discountAmount.error = action.payload;
  },
  [clearDiscountAmount]: (state) => {
    state.paymentAmounts.discountAmount.response = null;
    state.paymentAmounts.discountAmount.status = RS.IDLE;
    state.paymentAmounts.discountAmount.error = null;
  },
  [setCustomPaymentAmount]: (state, action) => {
    state.paymentAmounts.customAmount.selected = action.payload;
  },
  [clearCustomPaymentAmount]: (state) => {
    state.paymentAmounts.customAmount.response = null;
    state.paymentAmounts.customAmount.status = RS.IDLE;
    state.paymentAmounts.customAmount.error = null;
    state.paymentAmounts.customAmount.selected = null;
  },
  [setPossiblePaymentAmount]: (state, action) => {
    state.paymentAmounts.possibleAmount.selected = action.payload;
  },
};

export {
  applyDiscountCode,
  paymentAmountsSlice,
  getCustomPaymentAmount,
  getAvailableCreditAmounts,
  initialPaymentAmountsState,
};
