import { fetchWithRefresh } from "helpers/fetch";
import { API_AFFOGATO, ZENDESK_URL } from "config";

interface Feedback {
  category: "FEEDBACK";
  body: string;
  subject: string;
}

interface Baa_Request {
  category: "BAA_REQUEST";
  signer_email: string;
  signer_full_name: string;
}

interface Soc2_Request {
  category: "SOC2_REQUEST";
  signer_email: string;
  signer_full_name: string;
}

interface Deletion {
  category: "DELETION_WEB" | "DELETION_IOS";
}

interface HighValue {
  category: "HIGH_VOLUME";
  destinations: string;
  requested_pages: number;
}

interface ContactSales {
  category: "CONTACT_SALES";
  country: string;
  message: string;
  fax_numbers: string;
  company_name: string;
  company_size: string;
  phone_number: string;
  monthly_fax_transmission: string;
}

type SupportRequest = Baa_Request | Soc2_Request | Feedback | Deletion | HighValue | ContactSales;

export interface ContactUsParams {
  name: string;
  email: string;
  request: SupportRequest;
}

const support = (data: ContactUsParams) => {
  const { request, ...rest } = data;

  const requestOptions = {
    method: "POST",
    body: { ...rest, ...request },
  };

  const path = `${API_AFFOGATO}/support`;
  return fetchWithRefresh(path, requestOptions, { excludeBearer: true });
};

enum ZendeskCategories {
  FAX_PLUS = "9739430082076",
  SIGN_PLUS = "9739497086876",
  SCAN_PLUS = "10087537723676",
  ALOHI_ACCOUNT = "10087549456668",
}
export interface SearchArticlesParams {
  query: string;
  category?: string;
}
const searchArticles = ({ query }: SearchArticlesParams) => {
  const queryParams = new URLSearchParams({
    query,
    sort_by: "relevance",
    category: `${ZendeskCategories.FAX_PLUS},${ZendeskCategories.ALOHI_ACCOUNT}`,
  });

  const path = `${ZENDESK_URL}/api/v2/help_center/articles/search?${queryParams}`;
  try {
    return fetch(path, {
      method: "GET",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const supportApi = {
  support,
  searchArticles,
};
