import { useMemo } from "react";
import { Box } from "@alohi/kit";
import { CountryCode } from "libphonenumber-js";
import { components as Components, MultiValueProps } from "react-select";

import { parseNumber } from "helpers/numbers";
import { PhoneNumberFilter } from "components/Core/Filters";
import { Destination } from "views/SendFax/contexts/store";
import Icon from "./Icon";

type ChildrenLabel = { number: string; geoLocation: CountryCode } | string;

interface LabelProps extends MultiValueProps<object> {
  data: Destination;
  children: ChildrenLabel;
}

function Label({ data, ...rest }: LabelProps) {
  const node = useMemo(() => {
    switch (data.icon) {
      case "group":
      case "sharedGroup":
        return `${data.label} - ${data.membersCount}`;
      case "contact":
      case "sharedContact":
      case "recent": {
        const source = data.label || data.value;
        const { number, extension } = parseNumber(source);

        if (number) {
          return (
            <>
              <PhoneNumberFilter number={number} />
              {extension}
            </>
          );
        }

        return source;
      }
      case "newEntry": {
        const { number, extension } = parseNumber(data.label.number, data.label.geoLocation);

        if (number) {
          return (
            <>
              <PhoneNumberFilter number={number} />
              {extension}
            </>
          );
        }

        return data.value;
      }
    }
  }, [data]);

  return (
    <Components.MultiValueLabel {...rest} data={data}>
      <Box display="flex" alignItems="center">
        <Box mr={0.5}>
          <Icon icon={data.icon} />
        </Box>
        {node}
      </Box>
    </Components.MultiValueLabel>
  );
}

export default Label;
