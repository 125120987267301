import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, FormControlLabel, Modal, Switch, Tooltip, Typography } from "@alohi/kit";

import useBool from "hooks/useBool";
import { useAppDispatch, useAppSelector } from "stores/store";
import {
  updateAnonymous,
  getCustomerInfo,
  clearChangeAnonymous,
} from "stores/reducers/account.reducer";
import {
  selectAllowAnonymous,
  selectIsAllowAnonymousRunning,
  selectIsAllowAnonymousSuccess,
} from "selectors/account.selector";
import { selectCanIEditBlacklist } from "selectors/ability.selector";

interface HandleAnonymousSendersModalProps {
  handleClosure: () => void;
}

function HandleAnonymousSendersModal({ handleClosure }: HandleAnonymousSendersModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const allowAnonymous = useAppSelector(selectAllowAnonymous);
  const isUpdateAnonymousRunning = useAppSelector(selectIsAllowAnonymousRunning);
  const isUpdateAnonymousSuccessful = useAppSelector(selectIsAllowAnonymousSuccess);
  const canIEditBlacklist = useAppSelector(selectCanIEditBlacklist);

  const [isChecked, isCheckedBool] = useBool(!allowAnonymous);

  const handleConfirm = () => {
    if (canIEditBlacklist) {
      dispatch(
        updateAnonymous({
          allow: !isChecked,
        }),
      );
    } else {
      handleClosure();
    }
  };

  useEffect(() => {
    if (isUpdateAnonymousSuccessful) {
      dispatch(clearChangeAnonymous());
      dispatch(getCustomerInfo());

      handleClosure();
    }
  }, [isUpdateAnonymousSuccessful, dispatch, handleClosure]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={canIEditBlacklist ? handleClosure : undefined}
      onConfirm={handleConfirm}
      confirmTitle={canIEditBlacklist ? t("COMMON.CONFIRM") : t("COMMON.CLOSE")}
      title={t("CONTACTS.ANONYMOUS_SENDERS")}
      isModalDisabled={isUpdateAnonymousRunning}
      isConfirmLoading={isUpdateAnonymousRunning}
    >
      <Box p={3} display="flex" flexDirection="column">
        <FormControlLabel
          control={
            <Tooltip
              title={canIEditBlacklist ? "" : t("CONTACTS.CONTACT_ADMIN_TO_EDIT_BLACKLIST")}
              placement={"bottom"}
            >
              <Box>
                <Switch
                  disabled={!canIEditBlacklist}
                  name="switch"
                  checked={isChecked}
                  id="block-anonymous"
                  onChange={isCheckedBool.toggle}
                  inputProps={{ "aria-label": "block-anonymous-switch" }}
                />
              </Box>
            </Tooltip>
          }
          label={t("CONTACTS.BLOCK_ANONYMOUS")}
        />
        <Typography type="subtitle">{t("CONTACTS.BLOCK_ANONYMOUS_INFO")}</Typography>
      </Box>
    </Modal>
  );
}

export default HandleAnonymousSendersModal;
