import _map from "lodash/map";
import { Modal } from "@alohi/kit";
import PropTypes from "prop-types";
import { useCallback } from "react";
import _orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faPhoneSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { accountStatuses } from "enums/account";
import MemberAvatar from "components/AccountAvatar/MemberAvatar";
import { Grid, Typography, List, ListItem, makeStyles } from "ui";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import AccountAvatar from "components/AccountAvatar/AccountAvatar";
import { selectAssignedNumbers } from "selectors/numbers.selector";
import { PhoneNumberFilter as PNF } from "components/Core/Filters/index";
import { selectCuratedCorporateUsers } from "selectors/corporate.selector";
import { selectCanIAccessFaxesFromOtherMembers } from "selectors/ability.selector";
import { SET_SELECTED_NUMBER, SET_SELECTED_MEMBER_UID } from "stores/reducers/faxes.reducer";

function FaxesNumberModal({ onCancel, handleClose, setIsFakeChoiceForCorporateMember }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const numbers = useSelector(selectAssignedNumbers);
  const curatedMembers = useSelector(selectCuratedCorporateUsers);
  const canIAccessFaxesFromOtherMembers = useSelector(selectCanIAccessFaxesFromOtherMembers);

  const setStoreSelectedNumber = useCallback(
    (number) => dispatch(SET_SELECTED_NUMBER(number)),
    [dispatch],
  );
  const setStoreSelectedMemberUid = useCallback(
    (uid) => dispatch(SET_SELECTED_MEMBER_UID(uid)),
    [dispatch],
  );

  return (
    <Modal
      title={t("FAXES.SELECT_NUMBER")}
      hasCloseIcon
      isFooterHidden
      onBackdropClick={onCancel}
      onCancel={onCancel}
      maxWidth="xs"
      scroll="paper"
    >
      <List disablePadding>
        <MyFaxesListItem
          setStoreSelectedMemberUid={setStoreSelectedMemberUid}
          setStoreSelectedNumber={setStoreSelectedNumber}
          handleClose={handleClose}
          setIsFakeChoiceForCorporateMember={setIsFakeChoiceForCorporateMember}
        />
        {numbers.map(
          (number) =>
            number.number &&
            number.number.indexOf("number") < 0 && (
              <ListItem
                button
                component="a"
                divider={true}
                key={number.id}
                onClick={() => {
                  setStoreSelectedNumber(number.number);
                  setStoreSelectedMemberUid(null);
                  handleClose();
                }}
                className={classes.listItem}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <CountryFlag
                      phone={number.number}
                      style={{
                        width: "38px",
                        height: "38px",
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    container
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography variant="body">
                      <PNF number={number.number} />
                    </Typography>

                    {number?.friendly_name ? (
                      <Typography variant="subtitle">({number.friendly_name})</Typography>
                    ) : null}
                  </Grid>

                  {!number?.reception_enabled ? (
                    <Grid item xs={1} container justifyContent="center" alignContent={"center"}>
                      <FontAwesomeIcon icon={faPhoneSlash} />
                    </Grid>
                  ) : null}
                </Grid>
              </ListItem>
            ),
        )}
        {canIAccessFaxesFromOtherMembers &&
          _map(_orderBy(curatedMembers, ["name"]), (member) => (
            <MemberListItem
              setStoreSelectedNumber={setStoreSelectedNumber}
              setStoreSelectedMemberUid={setStoreSelectedMemberUid}
              handleClose={handleClose}
              member={member}
              button
              component="a"
              divider={true}
              key={member.uid}
            />
          ))}
      </List>
    </Modal>
  );
}

function MyFaxesListItem({ handleClose, setIsFakeChoiceForCorporateMember }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setStoreSelectedNumber = useCallback(
    (number) => dispatch(SET_SELECTED_NUMBER(number)),
    [dispatch],
  );
  const setStoreSelectedMemberUid = useCallback(
    (uid) => dispatch(SET_SELECTED_MEMBER_UID(uid)),
    [dispatch],
  );

  return (
    <ListItem
      button
      component="a"
      divider
      onClick={() => {
        setIsFakeChoiceForCorporateMember(false);
        setStoreSelectedNumber("all");
        setStoreSelectedMemberUid(null);
        handleClose();
      }}
      className={classes.listItem}
    >
      <Grid container>
        <Grid item xs={1}>
          <AccountAvatar className={classes.accountAvatar} />
        </Grid>
        <Grid item xs={10} container justifyContent={"center"} alignItems={"center"}>
          <Typography variant="body">{t("FAXES.ALL_FAXES")}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}

function MemberListItem({ member, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const setStoreSelectedNumber = useCallback(
    (number) => dispatch(SET_SELECTED_NUMBER(number)),
    [dispatch],
  );
  const setStoreSelectedMemberUid = useCallback(
    (uid) => dispatch(SET_SELECTED_MEMBER_UID(uid)),
    [dispatch],
  );

  const listItems = [];
  listItems.push(
    <ListItem
      button
      component="a"
      divider={true}
      onClick={() => {
        setStoreSelectedNumber("all");
        setStoreSelectedMemberUid(member.uid);
        handleClose();
      }}
      key={`${member.uid}-all`}
      className={classes.listItem}
    >
      <Grid container>
        <Grid item xs={1} container alignItems="center">
          <MemberAvatar member={member} className={classes.accountAvatar} />
        </Grid>
        <Grid item xs={10} container justifyContent="center" alignItems="center" direction="column">
          <Typography variant="body">
            {member.name}
            {member.accountStatus === "disabled" ? " - Disabled" : ""}
          </Typography>
          <Typography variant="body" color="textSecondary">
            {!member.numbers.length ? "(Unassigned Number)" : "(All Numbers)"}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>,
  );

  if (member.numbers.length) {
    return listItems.concat(
      _map(_orderBy(member.numbers), (number) => (
        <ListItem
          button
          component="a"
          divider={true}
          onClick={() => {
            setStoreSelectedNumber(number);
            setStoreSelectedMemberUid(member.uid);
            handleClose();
          }}
          key={`${member.uid}-${number}`}
          className={classes.listItem}
        >
          <Grid container>
            <Grid item xs={1} container alignItems="center">
              <MemberAvatar member={member} className={classes.accountAvatar} />
            </Grid>
            <Grid
              item
              xs={10}
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Typography variant="body">
                {member.name}
                {member.accountStatus === accountStatuses.disabled ? " - Disabled" : ""}
              </Typography>
              <Typography variant="body" color="textSecondary">
                ({<PNF number={number} />})
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )),
      [],
    );
  }
  return listItems;
}

const useStyles = makeStyles(({ spacing }) => ({
  accountAvatar: {
    width: 38,
    height: 38,
  },
  listItem: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    fontSize: 16,
    minHeight: 56,
  },
}));

FaxesNumberModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  handleClose: PropTypes.func,
  setIsFakeChoiceForCorporateMember: PropTypes.func,
};

MyFaxesListItem.propTypes = {
  handleClose: PropTypes.func,
  setIsFakeChoiceForCorporateMember: PropTypes.func,
};

export default FaxesNumberModal;
