import { useState } from "react";
import { useSelector } from "react-redux";
import { Panel, Button } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { formatCdrDate } from "helpers/date";
import { selectLastPasswordDate } from "selectors/account.selector";
import ChangePasswordModal from "views/ChangePassword/ChangePasswordModal";
import TwoFaRow from "./TwoFaRow";

function SignInPanel() {
  const { t } = useTranslation();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  const lastDate = useSelector(selectLastPasswordDate);

  return (
    <>
      <Panel id="sign-in" headerTitle={t("COMMON.SIGNIN")} sx={{ mb: 7 }}>
        <Panel.Front>
          <Panel.Row>
            <Panel.Cell variant="title">{t("FORMS.PASSWORD")}</Panel.Cell>
            <Panel.Cell variant="data">
              {lastDate
                ? t("PROFILE.LAST_CHANGED_PASS", {
                    date: formatCdrDate(lastDate),
                  })
                : null}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <Button variant="white" onClick={() => setIsChangePasswordModalOpen(true)}>
                {t("COMMON.CHANGE")}
              </Button>
            </Panel.Cell>
          </Panel.Row>
          <TwoFaRow />
        </Panel.Front>
      </Panel>

      {isChangePasswordModalOpen && (
        <ChangePasswordModal handleClosure={() => setIsChangePasswordModalOpen(false)} />
      )}
    </>
  );
}

export default SignInPanel;
