import { useState } from "react";
import { Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { Typography, makeStyles } from "ui";
import { formatDefaultDate } from "helpers/date";
import ResumePlanModal from "views/ResumePlan/ResumePlanModal";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import {
  selectIsPlanActive,
  selectIsPlanCancelled,
  selectPlanExpirationDate,
} from "selectors/plan.selector";

function PlanCanceled() {
  const classes = useStyles();
  const { t } = useTranslation();

  const isPlanCancelled = useSelector(selectIsPlanCancelled);
  const isPlanActive = useSelector(selectIsPlanActive);
  const planExpirationDate = useSelector(selectPlanExpirationDate);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const [openResumePlan, setOpenResumePlan] = useState(false);

  if (isCorporateMember) return null;

  return (
    <>
      {isPlanCancelled && isPlanActive && (
        <>
          <Box onClick={() => setOpenResumePlan(true)} className={classes.root}>
            <Typography variant="subtitle3">
              <FontAwesomeIcon className={classes.icon} icon={faExclamationTriangle} />
              {t("CHANGE_PLAN.PLAN_CANCELLED")}
            </Typography>
            <Typography>
              {t("CHANGE_PLAN.PLAN_EXPIRED_PLEASE_RESUME", {
                date: formatDefaultDate(planExpirationDate),
              })}
            </Typography>
          </Box>

          {openResumePlan ? (
            <ResumePlanModal handleClosure={() => setOpenResumePlan(false)} />
          ) : null}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape }) => ({
  root: {
    textAlign: "center",
    padding: spacing(1, 3),
    backgroundColor: alohi.lightestBlue,
    borderRadius: shape.borderRadius,
    marginTop: spacing(2),
    cursor: "pointer",
  },
  icon: {
    marginRight: spacing(1),
  },
}));

export default PlanCanceled;
