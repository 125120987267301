import { Button } from "@alohi/kit";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import ResumePlanModal from "views/ResumePlan/ResumePlanModal";

function ResumePlanButton() {
  const { t } = useTranslation();

  const [openResumePlan, setOpenResumePlan] = useState(false);

  return (
    <>
      <Button variant="white" onClick={() => setOpenResumePlan(true)}>
        {t("PROFILE.RESUME_PLAN")}
      </Button>
      {openResumePlan && <ResumePlanModal handleClosure={() => setOpenResumePlan(false)} />}
    </>
  );
}

export default memo(ResumePlanButton);
