import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useTitle from "../../hooks/useTitle";
import ValidFaxConfirmation from "../../components/FaxConfirmation/ValidFaxConfirmation";
import InvalidFaxConfirmation from "../../components/FaxConfirmation/InvalidFaxConfirmation";
import {
  getFaxConfirmation,
  clearFaxConfirmation,
} from "../../stores/reducers/verifications.reducer";
import {
  selectFaxConfirmation,
  selectIsFaxConfirmationError,
} from "../../selectors/verifications.selector";

function FaxConfirmation() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const faxConfirmation = useSelector(selectFaxConfirmation);
  const faxConfirmationError = useSelector(selectIsFaxConfirmationError);

  useTitle(t("CONFIRMATION.FAX_REPORT_VALIDATION_PAGE"));

  useEffect(() => {
    dispatch(getFaxConfirmation({ token }));
    return () => {
      dispatch(clearFaxConfirmation());
    };
  }, [dispatch, token]);

  if (faxConfirmationError) {
    return <InvalidFaxConfirmation />;
  }

  if (faxConfirmation) {
    return <ValidFaxConfirmation data={faxConfirmation} />;
  }

  return null;
}

export default FaxConfirmation;
