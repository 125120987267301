import { useMemo } from "react";
import { Fab, Icon, PopoverOrigin, alohi, makeStyles, useBool } from "@alohi/kit";
import { faCode } from "@fortawesome/pro-light-svg-icons";
import { ENVIRONMENT_NAME } from "config";

import Menu from "./components/Menu";

const FAB_SIZE = 25;
const isFeatureBranch = ENVIRONMENT_NAME === "LOCAL";

function DevFab() {
  const classes = useStyles();

  const [isOpen, isOpenHandler] = useBool(false);

  const anchorOrigin: PopoverOrigin = useMemo(
    () => ({
      horizontal: "left",
      vertical: "bottom",
    }),
    [],
  );

  const transformOrigin: PopoverOrigin = useMemo(
    () => ({
      horizontal: "right",
      vertical: "bottom",
    }),
    [],
  );

  if (!isFeatureBranch) return null;
  return (
    <Fab
      className={classes.base}
      size="small"
      isOpen={isOpen}
      onOpen={isOpenHandler.set}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      color="secondary"
      icon={<Icon size="sm" icon={faCode} color={alohi.white} />}
      menuSx={{
        marginLeft: -0.5,
      }}
      sx={{
        maxHeight: FAB_SIZE,
        minHeight: FAB_SIZE,
        minWidth: FAB_SIZE,
        maxWidth: FAB_SIZE,
      }}
      menuList={<Menu isMenuOpen={isOpen} onOpen={isOpenHandler.set} />}
    />
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  base: {
    position: "absolute",
    zIndex: 1000,
    bottom: spacing(2),
    right: spacing(2),
    "&&": {
      boxShadow: alohi.bannerShadow,
    },
  },
}));

export default DevFab;
