import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Helper, Link, Typography, makeStyles } from "@alohi/kit";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

import { websiteUrls } from "routes/urls";

function ActivationModalTitle() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography type="modalTitle">
      {t("PROFILE.HIPAA_ADVANCED_SECURITY")}
      <Helper
        size="lg"
        variant="light"
        placement="right-end"
        className={classes.helper}
        tooltip={
          <Box className={classes.container}>
            <Typography className={classes.title}>
              {t("PROFILE.HIPAA_ADVANCED_SECURITY")}
            </Typography>
            <Typography className={classes.text} pt={1}>
              {t("PROFILE.HIPAA_TOOLTIP_INFO")}
            </Typography>
            <Typography className={classes.text} pt={1}>
              <FontAwesomeIcon icon={faTriangleExclamation} className={classes.icon} />
              {t("PROFILE.HIPAA_DISABLE_ZAPIER")}
            </Typography>
            <Typography className={classes.text} pt={2}>
              {t("PROFILE.HIPAA_VISIT_HELP_CENTER")}
            </Typography>
            <Typography pt={0.5}>
              <Link
                withIcon
                hasBullet
                fontSize={12}
                className={classes.link}
                href={websiteUrls.advancedSecurity}
              >
                <span>{t("PROFILE.HIPAA_TOOLTIP_LINK")}</span>
              </Link>
            </Typography>
          </Box>
        }
      />
    </Typography>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  helper: {
    paddingLeft: spacing(0.5),
  },
  icon: {
    color: alohi.gold,
    marginRight: spacing(0.5),
  },
  container: {
    padding: spacing(1),
  },
  title: {
    color: alohi.white,
  },
  text: {
    color: alohi.white,
    fontSize: 12,
  },
  link: {
    display: "flex",
    alignItems: "center",
    marginLeft: spacing(1),
  },
}));

export default ActivationModalTitle;
