import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Box, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import { isValidRedirectUrl } from "helpers/inputValidation";
import {
  selectApiRedirectionUris,
  selectIsUpdateClientPrivateInfoError,
  selectIsUpdateClientPrivateInfoSuccess,
} from "selectors/integrations.selector";
import {
  getClientPrivateInfo,
  updateClientPrivateInfo,
  clearUpdateClientPrivateInfo,
} from "stores/reducers/integrations.api.reducer";
import FormRowMultiSelectTextInput from "../../Forms/FormRowMultiSelectTextInput";

function ApiEditRedirectionUrisModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const redirectionUris = useSelector(selectApiRedirectionUris);
  const isSuccess = useSelector(selectIsUpdateClientPrivateInfoSuccess);
  const isError = useSelector(selectIsUpdateClientPrivateInfoError);

  const [urisInput, setUrisInput] = useState(
    redirectionUris?.map((uri) => ({
      label: uri,
      value: uri,
    })) ?? [],
  );
  const [urisInputShowsError, setUrisInputShowsError] = useState(false);

  const handleConfirm = useCallback(() => {
    if (!!urisInput.length) {
      dispatch(
        updateClientPrivateInfo({
          redirectionUris: urisInput.map(({ value }) => value),
        }),
      );
    }
  }, [dispatch, urisInput]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("WEB_SERVICE.UPDATE_REDIRECT_URI_SUCCESS"), {
        variant: "success",
      });
      dispatch(getClientPrivateInfo());
      handleClosure(true);
    }
  }, [dispatch, enqueueSnackbar, handleClosure, isSuccess, t]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    return () => dispatch(clearUpdateClientPrivateInfo());
  }, [dispatch]);

  const handleUrisInputOnChange = useCallback((value) => {
    setUrisInput(value);
    setUrisInputShowsError(!value.length);
  }, []);

  const handleUrisInputOnBlur = useCallback(() => {
    setUrisInputShowsError(!urisInput.length);
  }, [urisInput.length]);

  return (
    <Modal
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isConfirmDisabled={urisInputShowsError}
      title={t("WEB_SERVICE.UPDATE_REDIRECT_URI")}
    >
      <Box p={3} component="form" onSubmit={(e) => e.preventDefault()}>
        <FormRowMultiSelectTextInput
          labelGridWidth={0}
          id={"redirect-uris-input"}
          initialValues={urisInput}
          label={t("WEB_SERVICE.REDIRECT_URI") + "*"}
          helperText={t("WEB_SERVICE.TOOLTIP_URI")}
          onChange={handleUrisInputOnChange}
          onBlur={handleUrisInputOnBlur}
          validator={isValidRedirectUrl}
          unique
          maxCount={5}
          validationErrorMessage={t("WEB_SERVICE.PLEASE_ENTER_VALID_URI")}
          uniqueErrorMessage={t("WEB_SERVICE.DUPLICATE_DOMAIN")}
          maxCountErrorMessage={t("WEB_SERVICE.LIMIT_REACHED_URIS", {
            count: 5,
          })}
          placeholder={t("WEB_SERVICE.REDIRECT_URI")}
          error={urisInputShowsError}
        />
      </Box>
    </Modal>
  );
}

ApiEditRedirectionUrisModal.propTypes = {
  handleClosure: PropTypes.func,
};

export default ApiEditRedirectionUrisModal;
