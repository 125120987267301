import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, makeStyles, Modal, Typography, useSnackbar } from "@alohi/kit";

import { selectAllActiveNumbers } from "selectors/numbers.selector";
import {
  selectAssignAndRevokeUserNumbersError,
  selectIsAssignAndRevokeUserNumbersRunning,
  selectIsAssignAndRevokeUserNumbersSuccess,
  selectUserByUid,
} from "selectors/corporate.selector";
import {
  corporateAsyncActions,
  CLEAR_ASSIGN_AND_REVOKE_USER_NUMBERS_REQUEST,
} from "stores/reducers/corporate.reducer";
import { PhoneNumberFilter } from "../Core/Filters";
import InfiniTable from "../InfiniTable/InfiniTable";

function ManageUserNumbersModal({ userUid, handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(selectUserByUid(userUid));
  const numbers = useSelector(selectAllActiveNumbers);
  const isSuccess = useSelector(selectIsAssignAndRevokeUserNumbersSuccess);
  const isLoading = useSelector(selectIsAssignAndRevokeUserNumbersRunning);
  const error = useSelector(selectAssignAndRevokeUserNumbersError);

  const [numbersChecked, setNumbersChecked] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const checkBoxState = useMemo(() => {
    let numberOfChecked = 0;
    let isIndeterminate = false;

    for (let i = 0; i < numbersChecked.length; i++) {
      if (numbersChecked[i][1]) {
        numberOfChecked++;
      }
      if (0 < numberOfChecked && numberOfChecked < i + 1) {
        isIndeterminate = true;
      }
    }

    return {
      isIndeterminate,
      numberOfChecked,
      isAllChecked: numbersChecked.length === numberOfChecked,
    };
  }, [numbersChecked]);

  const handleCheckboxChange = useCallback(
    (isHeader, index) => () => {
      const numbersCopy = numbersChecked.slice();
      if (isHeader) {
        const newNumbers = [];
        numbersCopy.forEach((numberData) => {
          newNumbers.push([
            numberData[0],
            checkBoxState.isAllChecked ? false : !checkBoxState.isIndeterminate,
          ]);
        });
        setNumbersChecked(newNumbers);
      } else {
        numbersCopy.splice(index, 1, [numbersCopy[index][0], !numbersCopy[index][1]]);
        setNumbersChecked(numbersCopy);
      }
    },
    [checkBoxState.isAllChecked, checkBoxState.isIndeterminate, numbersChecked],
  );

  const handleAssignNumbers = useCallback(() => {
    const numbersToAssign = [];
    const numbersToRevoke = [];
    for (let i = 0; i < numbersChecked.length; i++) {
      if (numbersChecked[i][1]) {
        numbersToAssign.push(numbersChecked[i][0]);
      } else {
        numbersToRevoke.push(numbersChecked[i][0]);
      }
    }
    dispatch(
      corporateAsyncActions.assignAndRevokeUserNumbers({
        userUid,
        numbersToAssign,
        numbersToRevoke,
      }),
    );
  }, [dispatch, numbersChecked, userUid]);

  const onRowSelectionChange = (_, allRows) => {
    setSelectedRows(allRows.map((row) => row.dataIndex));
    setNumbersChecked((prevNumbersChecked) => {
      return [...prevNumbersChecked].map((number, index) => {
        if (allRows.some((row) => row.dataIndex === index)) {
          return [number[0], true];
        } else {
          return [number[0], false];
        }
      });
    });
  };

  const options = {
    sort: false,
    rowsSelected: selectedRows,
    selectableRowsOnClick: true,
    isRowSelectable: () => true,
    onRowSelectionChange: onRowSelectionChange,
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "number",
      label: t("USERS.NUMBER"),
      options: {
        customBodyRender: function customBodyRender(value) {
          return (
            <Typography variant="body" className={classes.numberLabel} stopPropagation>
              <PhoneNumberFilter number={value} />
            </Typography>
          );
        },
      },
    },
    {
      name: "friendly_name",
      label: t("NUMBERS.FRIENDLY_NAME"),
      options: {
        customBodyRender: function customBodyRender(value) {
          return (
            <Typography variant="body" className={classes.numberLabel} stopPropagation>
              {value}
            </Typography>
          );
        },
      },
    },
  ];

  const components = {
    Checkbox: (props) => {
      const index = props?.["data-index"] ?? 0;
      const isHeader = props?.["data-description"] === "row-select-header";

      if (isHeader) {
        return (
          <Checkbox
            {...props}
            variant="dark"
            checked={checkBoxState.isAllChecked}
            indeterminate={checkBoxState.isIndeterminate}
            onChange={handleCheckboxChange(isHeader, index)}
          />
        );
      }

      return (
        <Checkbox
          {...props}
          variant="dark"
          checked={numbersChecked[index][1]}
          onChange={handleCheckboxChange(isHeader, index)}
        />
      );
    },
  };
  components.Checkbox.displayName = "Checkbox";
  components.Checkbox.propTypes = {
    "data-index": PropTypes.number,
    "data-description": PropTypes.string,
  };

  useEffect(() => {
    if (numbers.length > 0) {
      setNumbersChecked(
        numbers.reduce((acc, item) => {
          acc.push([item.number, user?.numbers?.includes(item.number) ?? false]);
          return acc;
        }, []),
      );
    }
  }, [numbers, user]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(CLEAR_ASSIGN_AND_REVOKE_USER_NUMBERS_REQUEST());
      handleClosure && handleClosure();
    }
  }, [dispatch, handleClosure, isSuccess]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(CLEAR_ASSIGN_AND_REVOKE_USER_NUMBERS_REQUEST());
    }
  }, [dispatch, enqueueSnackbar, error, handleClosure, isSuccess]);

  return (
    <Modal
      maxWidth="xs"
      scroll="paper"
      withFooterDivider
      onCancel={handleClosure}
      isConfirmLoading={isLoading}
      onPressEscape={handleClosure}
      isConfirmDisabled={isLoading}
      onConfirm={handleAssignNumbers}
      title={t("USERS.MANAGE_NUMBERS")}
      onPressEnter={handleAssignNumbers}
    >
      <InfiniTable data={numbers} options={options} columns={columns} components={components} />
    </Modal>
  );
}

const useStyles = makeStyles({
  numberLabel: {
    maxWidth: 200,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "text",
    width: "max-content",
  },
});

ManageUserNumbersModal.propTypes = {
  userUid: PropTypes.string.isRequired,
  handleClosure: PropTypes.func.isRequired,
};

export default ManageUserNumbersModal;
