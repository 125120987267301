import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@alohi/kit";

import { makeStyles, InputBase } from "ui";

export type CompanyGraphDisplayTypes = "cumulative" | "daily" | "table";

interface CompanyGraphSelectProps {
  activeGraphType: CompanyGraphDisplayTypes;
  setActiveGraphType: Dispatch<SetStateAction<CompanyGraphDisplayTypes>>;
}

function CompanyGraphSelect({ activeGraphType, setActiveGraphType }: CompanyGraphSelectProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Select
      className={classes.filterBase}
      value={activeGraphType}
      onChange={(event: SelectChangeEvent<unknown>) =>
        setActiveGraphType(event.target.value as CompanyGraphDisplayTypes)
      }
      input={<InputBase />}
    >
      <MenuItem value="cumulative">{t("DASHBOARD.CUMULATIVE")}</MenuItem>
      <MenuItem value="daily">{t("DASHBOARD.DAILY")}</MenuItem>
      <MenuItem value="table">{t("DASHBOARD.TABLE")}</MenuItem>
    </Select>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape, breakpoints }) => ({
  filterBase: {
    margin: spacing(1, 0),
    "& .MuiInputBase-input": {
      padding: spacing(1, 5, 1, 2),
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: 0,
      },
      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: 0,
      },
    },
    "&.MuiInputBase-root, & .MuiInputBase-root": {
      height: 32,
      borderRadius: shape.borderRadius,
      boxShadow: alohi.bannerShadow,
      border: `1px solid ${alohi.lighterGray}`,
      background: alohi.white,
      display: "flex",
      alignItems: "center",
      marginRight: spacing(2),
      [breakpoints.down("sm")]: {
        flexBasis: "100%",
        margin: spacing(0),
      },
    },
  },
}));

export default CompanyGraphSelect;
