import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BigButton, Box, useSnackbar } from "@alohi/kit";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import Currency from "components/Core/Currency/Currency";
import {
  selectAreaForCountry,
  selectBasePricesForNumber,
  selectCountryForPlan,
  selectCurrencyBasePrices,
  selectNextPlan,
  selectPlanId,
  selectPricesForChangeNumber,
} from "selectors/plan.selector";
import {
  selectBulkNumbers,
  selectBulkNumbersAmount,
  selectIsBuyingBulkNumbers,
  selectIsVerifyBulkNumberLoading,
  selectIsVerifyCustomNumberLoading,
  selectIsVerifyNumberLoading,
  selectSelectedNumberType,
} from "selectors/verifications.selector";
import {
  clearAssignBundleToNumber,
  clearVerifyCustomNumber,
  clearVerifyNumber,
  verifyBulkNumbers,
  verifyNumber,
} from "stores/reducers/verifications.reducer";
import { Grid, Typography } from "ui";
import AddCustomNumberModal from "views/AddNumber/AddCustomNumberModal";
import CustomNumber from "../../assets/images/number-type/custom-number.svg?react";
import GoldenNumber from "../../assets/images/number-type/golden-number.svg?react";
import RandomNumber from "../../assets/images/number-type/random-number.svg?react";
import { numberTypes } from "../../enums/numbers";
import { clearAddressRequirements } from "../../stores/reducers/address.reducer";
import FormRow from "../Forms/FormRow";

/*
 * This component is used for:
 * 1. Buying a number (with no plan)
 * 2. Changing a number with a plan
 * 3. Adding a new number for a corporate plan
 */
function AvailableNumberTypes({ actionType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentPlanId = useSelector(selectPlanId);
  const selectedPlan = useSelector(selectNextPlan);
  const bulkNumbers = useSelector(selectBulkNumbers);
  const selectedArea = useSelector(selectAreaForCountry);
  const areaForCountry = useSelector(selectAreaForCountry);
  const selectedCountry = useSelector(selectCountryForPlan);
  const bulkNumberAmount = useSelector(selectBulkNumbersAmount);
  const basePricesCurrency = useSelector(selectCurrencyBasePrices);
  const selectedNumberType = useSelector(selectSelectedNumberType);
  const isBuyingBulkNumbers = useSelector(selectIsBuyingBulkNumbers);
  const basePricesForNumber = useSelector(selectBasePricesForNumber);
  const isRandomNumberLoading = useSelector(selectIsVerifyNumberLoading);
  const pricesForChangeNumber = useSelector(selectPricesForChangeNumber);
  const isCustomNumberLoading = useSelector(selectIsVerifyCustomNumberLoading);
  const isVerifyBulkNumberLoading = useSelector(selectIsVerifyBulkNumberLoading);

  const [addCustomNumber, setAddCustomNumber] = useState(false);

  const priceSource =
    actionType === "number_replacement" ? pricesForChangeNumber : basePricesForNumber;

  const handleNumberVerification = useCallback(
    (type, isBuyingBulkNumbers, bulkNumberAmount) => () => {
      if (type === numberTypes.golden) {
        enqueueSnackbar(t("ADD_NUMBER.NO_CONTENT_GOLDEN"), { variant: "info" });
        return;
      }

      if (type === numberTypes.custom) {
        dispatch(clearVerifyCustomNumber());
        dispatch(clearVerifyNumber());
        dispatch(clearAssignBundleToNumber());
        dispatch(clearAddressRequirements());
        setAddCustomNumber(true);
        return;
      }

      const numberType = selectedArea.isTollFree ? "tollfree" : "local";
      const planId = selectedPlan?.id ? selectedPlan.id : currentPlanId;
      const areaCode = selectedArea.areaCode;
      const countryCode = selectedCountry.value;

      if (isBuyingBulkNumbers && bulkNumberAmount > 1) {
        dispatch(clearVerifyCustomNumber());
        dispatch(clearVerifyNumber());
        dispatch(clearAssignBundleToNumber());
        dispatch(clearAddressRequirements());
        dispatch(
          verifyBulkNumbers({
            amount: bulkNumberAmount,
            planId,
            areaCode,
            numberType,
            countryCode,
          }),
        );
        return;
      }

      if (type === numberTypes.random && selectedNumberType !== numberTypes.random) {
        dispatch(clearVerifyCustomNumber());
        dispatch(clearVerifyNumber());
        dispatch(clearAssignBundleToNumber());
        dispatch(clearAddressRequirements());

        const numberType = selectedArea.isTollFree ? "tollfree" : "local";
        const planId = selectedPlan?.id ? selectedPlan.id : currentPlanId;
        const areaCode = selectedArea.areaCode;
        const countryCode = selectedCountry.value;

        dispatch(
          verifyNumber({
            planId,
            areaCode,
            numberType,
            countryCode,
            action: actionType,
            verificationType: type,
          }),
        );
      }
    },
    [
      dispatch,
      selectedNumberType,
      enqueueSnackbar,
      t,
      selectedArea,
      selectedPlan,
      currentPlanId,
      selectedCountry,
      actionType,
    ],
  );

  if (!priceSource || !areaForCountry) {
    return null;
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <FormRow
          label={
            <Box display="flex" flexDirection="column" m={2}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("ADD_NUMBER.NUMBER_TYPE")}
              </Typography>
              <Typography variant="subLabel">{t("ADD_NUMBER.ONE_TIME_FEE")}</Typography>
            </Box>
          }
          labelGridWidth={5}
        >
          <Grid container wrap={"nowrap"}>
            <Grid item xs>
              <BigButton
                width={80}
                height={85}
                isLoading={isRandomNumberLoading || isVerifyBulkNumberLoading}
                isSelected={selectedNumberType === "random" || (bulkNumbers && isBuyingBulkNumbers)}
                onClick={handleNumberVerification("random", isBuyingBulkNumbers, bulkNumberAmount)}
              >
                <Box display={"flex"} justifyContent={"center"} mb="5px">
                  <RandomNumber />
                </Box>
                <Typography variant="body">{t("ADD_NUMBER.RANDOM")}</Typography>
              </BigButton>
              <Box mt={1} display={"flex"} justifyContent={"center"}>
                <Typography variant="caption" color="textPrimary">
                  <Currency value={priceSource.random} currency={basePricesCurrency} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <BigButton
                isDisabled={isBuyingBulkNumbers}
                isSelected={selectedNumberType === "custom"}
                onClick={handleNumberVerification("custom")}
                isLoading={isCustomNumberLoading}
                width={80}
                height={85}
              >
                <Box display={"flex"} justifyContent={"center"} mb="5px">
                  <CustomNumber />
                </Box>
                <Typography variant="body">{t("ADD_NUMBER.CUSTOM")}</Typography>
              </BigButton>
              <Box mt={1} display={"flex"} justifyContent={"center"}>
                <Typography variant="caption" color="textPrimary">
                  <Currency value={priceSource.custom} currency={basePricesCurrency} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <BigButton
                isDisabled={isBuyingBulkNumbers}
                onClick={handleNumberVerification("golden")}
                width={80}
                height={85}
              >
                <Box display={"flex"} justifyContent={"center"} mb="5px">
                  <GoldenNumber />
                </Box>
                <Typography variant="body">{t("ADD_NUMBER.GOLDEN")}</Typography>
              </BigButton>
              <Box mt={1} display={"flex"} justifyContent={"center"}>
                <Typography variant="caption" color="textPrimary">
                  <Currency value={priceSource.golden} currency={basePricesCurrency} />{" "}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </FormRow>
        <Box width={0} style={{ transform: "translate(16px, -8px)" }}>
          <HelpTooltip
            placement="left"
            title={<Box m={1}>{t("CHANGE_PLAN.SEARCH_NUMBER_TOOLTIP")}</Box>}
          />
        </Box>
      </Box>
      {addCustomNumber ? (
        <AddCustomNumberModal
          actionType={actionType}
          handleClosure={() => setAddCustomNumber(false)}
        />
      ) : null}
    </>
  );
}

AvailableNumberTypes.propTypes = {
  actionType: PropTypes.oneOf(["number", "number_replacement", "corporate_number"]).isRequired,
};

export default AvailableNumberTypes;
