import prettyBytes from "pretty-bytes";

import { faxAllowedMagicBytes } from "enums/allowedFileTypes";
import { FaxFile, RichText } from "views/SendFax/contexts/store";
import { base64, makeId } from "./string";

export async function verifyMimeMagicNumbers(file: File): Promise<boolean> {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = reject;

    fileReader.readAsArrayBuffer(file.slice(0, 4));

    fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
      if (event?.target?.result) {
        let header = "";

        const result = event.target.result as ArrayBuffer;
        const uintArr = new Uint8Array(result);

        for (let i = 0; i < uintArr.length; i++) {
          header += uintArr[i].toString(16).padStart(2, "0");
        }

        if (faxAllowedMagicBytes.includes(header.toUpperCase())) {
          resolve(true);
        } else {
          reject(false);
        }
      } else {
        reject(false);
      }
    };
  });
}

export function fileToFaxFile(file: File): Promise<FaxFile> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = reject;

    fileReader.readAsBinaryString(file);

    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      if (event?.target?.result) {
        const result = event.target.result as string;
        const thumbnail = file.type.search("image") !== -1 ? URL.createObjectURL(file) : null;

        resolve({
          file,
          thumbnail,
          path: null,
          key: makeId(6),
          name: file.name,
          mime: file.type,
          size: file.size,
          content: result,
          type: "faxFile",
          title: prettyBytes(file.size),
        });
      } else {
        reject(false);
      }
    };
  });
}

export function textToRichText(text: string): RichText {
  const dom = document.createElement("div");
  dom.innerHTML = text;
  const plainText = dom.textContent || dom.innerText;

  const html = base64.encode(
    `<!DOCTYPE html><head><meta http-equiv="Content-Type" content="text/html;charset=UTF-8"></head><body><div style="padding-top:20px;width:900px;word-wrap:break-word;font-size:18px;font-family:Lato;">` +
      text +
      "</div></body></html>",
  );

  const blob = new Blob([html], { type: "text/plain;charset=utf-8" });

  return {
    blob,
    path: null,
    key: makeId(6),
    mime: blob.type,
    size: blob.size,
    type: "richText",
    content: {
      html,
      text,
    },
    lastModifiedDate: new Date(),
    title: prettyBytes(blob.size),
    name: `Text - ${plainText.substring(0, 34).trim()}` + (plainText.length > 34 ? "..." : ""),
  };
}

export const triggerDownload = (path: string, filename: string) => {
  const anchor = document.createElement("a");

  anchor.href = path;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
};

export const getFilenameFromHeader = (disposition: string) => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName = "";

  if (utf8FilenameRegex.test(disposition)) {
    const utf8Filename = utf8FilenameRegex.exec(disposition);

    if (utf8Filename) {
      fileName = decodeURIComponent(utf8Filename[1]);
    }
  } else {
    const matches = asciiFilenameRegex.exec(disposition);
    if (matches != null && matches[2]) {
      fileName = matches[2];
    }
  }

  return fileName;
};
