import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { CodeInput } from "ui";
import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import { useOnPressEnter } from "hooks/useOnPressEnter";
import { isValidTwoFaCode } from "helpers/inputValidation";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import {
  activateTwoFa,
  clearActivateTwoFa,
  clearTwoFaNextAction,
  getTwoFaActivationCodes,
  activateTwoFaFromEnforced,
  clearTwoFaActivationCodes,
  clearActivateTwoFaFromEnforced,
} from "stores/reducers/twoFa.reducer";
import {
  selectTwoFaQrCode,
  selectTwoFaManualCode,
  selectIsActivateTwoFaError,
  selectIsActivateTwoFaSuccess,
  selectActivateTwoFaErrorInvalidCode,
  selectIsActivateTwoFaFromEnforcedError,
  selectTwoFaActionEnforcedByAdminOnLogin,
  selectIsActivateTwoFaFromEnforcedSuccess,
  selectActivateTwoFaFromEnforcedErrorInvalidCode,
} from "selectors/authentication.selector";

const ActivateTwoFaEnterCodeStepModal = ({ handleClosure, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const qrCode = useSelector(selectTwoFaQrCode);
  const manualCode = useSelector(selectTwoFaManualCode);
  const isActivateTwoFaError = useSelector(selectIsActivateTwoFaError);
  const isActivateTwoFaSuccess = useSelector(selectIsActivateTwoFaSuccess);
  const isEnforcedByAdmin = useSelector(selectTwoFaActionEnforcedByAdminOnLogin);
  const activateTwoFaErrorInvalidCode = useSelector(selectActivateTwoFaErrorInvalidCode);
  const isActivateTwoFaFromEnforcedError = useSelector(selectIsActivateTwoFaFromEnforcedError);
  const isActivateTwoFaFromEnforcedSuccess = useSelector(selectIsActivateTwoFaFromEnforcedSuccess);
  const activateTwoFaFromEnforcedErrorInvalidCode = useSelector(
    selectActivateTwoFaFromEnforcedErrorInvalidCode,
  );

  const [code, codeInput] = useInput("", isValidTwoFaCode);

  useEffect(() => {
    if (!qrCode) {
      dispatch(getTwoFaActivationCodes());
    }
  }, [dispatch, qrCode]);

  useEffect(() => {
    if (isActivateTwoFaFromEnforcedSuccess || isActivateTwoFaSuccess) {
      onSuccess();
    }
  }, [isActivateTwoFaFromEnforcedSuccess, isActivateTwoFaSuccess, onSuccess]);

  useEffect(() => {
    if (isActivateTwoFaError) {
      if (activateTwoFaErrorInvalidCode) {
        enqueueSnackbar(t("PROFILE.2FA_INVALID_CODE"), { variant: "error" });
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
      codeInput.setShowsError(true);
      dispatch(clearActivateTwoFa());
    }
  }, [
    t,
    dispatch,
    codeInput,
    enqueueSnackbar,
    isActivateTwoFaError,
    activateTwoFaErrorInvalidCode,
  ]);

  useEffect(() => {
    if (isActivateTwoFaFromEnforcedError) {
      if (activateTwoFaFromEnforcedErrorInvalidCode) {
        enqueueSnackbar(t("PROFILE.2FA_INVALID_CODE"), { variant: "error" });
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
      codeInput.setShowsError(true);
      dispatch(clearActivateTwoFaFromEnforced());
    }
  }, [
    t,
    dispatch,
    onSuccess,
    codeInput,
    enqueueSnackbar,
    isActivateTwoFaFromEnforcedError,
    isActivateTwoFaFromEnforcedSuccess,
    activateTwoFaFromEnforcedErrorInvalidCode,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearTwoFaActivationCodes());
      dispatch(clearTwoFaNextAction());
    };
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    codeInput.touch();

    if (codeInput.isValid) {
      isEnforcedByAdmin ? dispatch(activateTwoFaFromEnforced(code)) : dispatch(activateTwoFa(code));
    }
  }, [code, codeInput, dispatch, isEnforcedByAdmin]);

  useOnPressEnter(handleConfirm);

  if (!qrCode) {
    return null;
  }

  return (
    <Modal
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      cancelTitle={t("COMMON.CANCEL")}
      title={t("PROFILE.ACTIVATE_2FA_TITLE")}
      isConfirmDisabled={codeInput.showsError}
      confirmTitle={t("PROFILE.2FA_VERIFY_CODE")}
    >
      <Box textAlign="center" p={3}>
        {isEnforcedByAdmin ? (
          <Typography type="body">{t("PROFILE.2FA_MEMBER_ENABLE_2FA_REASON")}</Typography>
        ) : null}
        <Box px={6}>
          <Typography type="body">{t("PROFILE.2FA_SCAN_QR_CODE")}</Typography>
          <Typography type="body" component="div">
            {t("PROFILE.2FA_ENTER_SIX_DIGIT_CODE")}{" "}
            <a href={helpCenterUrls.setupTwoFa} target="_blank" rel="noreferrer">
              <HelpTooltip size={"lg"} />
            </a>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={3} mb={2}>
          {qrCode && <img width="150" height="150" src={qrCode} alt="qr code" />}
        </Box>
        {t("PROFILE.2FA_YOUR_MANUAL_CODE_IS")}
        <Typography type="body" style={{ fontWeight: "bold" }}>
          {manualCode}
        </Typography>
        <Box display="flex" justifyContent="center" mt={3}>
          <CodeInput
            autoFocus
            fields={6}
            value={code}
            onChange={codeInput.setValue}
            isError={codeInput.showsError}
          />
        </Box>
      </Box>
    </Modal>
  );
};

ActivateTwoFaEnterCodeStepModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  handleClosure: PropTypes.func.isRequired,
};

export default ActivateTwoFaEnterCodeStepModal;
