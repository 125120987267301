import PropTypes from "prop-types";
import { MenuItem, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "ui";
import { API_STORAGE } from "config";
import { capitalizeString } from "helpers/string";
import Player from "components/Core/Player/Player";
import { orderByNativeName } from "helpers/common";
import { selectIsPlanFree } from "selectors/plan.selector";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { getOutboxSettings } from "stores/reducers/numbers.reducer";
import FormRowSelectInput from "components/Forms/FormRowSelectInput";
import { selectOutboxSettingsByNumber } from "selectors/numbers.selector";
import UpgradeToChangeDialogue from "./UpgradeToChangeDialogue";

function SettingsFaxToHuman({ values: { language, gender, dialogue }, onChange }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isPlanFree = useSelector(selectIsPlanFree);
  const {
    sendFaxStore: { from },
  } = useSendFaxContext();
  const outboxSettings = useSelector(selectOutboxSettingsByNumber);

  const outboxSettingsByNumber = useMemo(() => {
    if (!from || !outboxSettings) {
      return [];
    }

    const answers = [];
    let firstOption = {};
    const settings = outboxSettings[from]?.telefax
      ? Object.entries(outboxSettings[from].telefax)
      : null;
    const map = new Map(settings);

    for (const [key, value] of map) {
      if (key === "en") {
        firstOption = value;
        continue;
      }
      answers.push(value);
    }

    answers.sort(orderByNativeName);
    answers.unshift(firstOption);

    return answers;
  }, [from, outboxSettings]);

  const [openChangePlan, setOpenChangePlan] = useState(false);

  const transformedAudio = useMemo(() => {
    if (!outboxSettingsByNumber) {
      return "";
    }
    const currentAudio = outboxSettingsByNumber.find(
      (element) => element.language === language,
    )?.files;
    const currentFile = `${language}_${gender}_${dialogue}`;
    return currentAudio?.[currentFile] ?? "";
  }, [outboxSettingsByNumber, gender, language, dialogue]);

  useEffect(() => {
    dispatch(getOutboxSettings(from));
  }, [from, dispatch]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 5 }}>
          <FormRowSelectInput
            displayEmpty
            id="language"
            value={language}
            variant="outlined"
            labelGridWidth={12}
            labelId="language-label"
            label={<Box mb="5px">{t("SENT_FAX.LANGUAGE")}</Box>}
            onChange={({ target }) => onChange((prev) => ({ ...prev, language: target.value }))}
          >
            {outboxSettingsByNumber
              ? outboxSettingsByNumber.map((element, index) => (
                  <MenuItem value={element.language} key={element.language + index}>
                    {element.native_name} ({element.name})
                  </MenuItem>
                ))
              : null}
          </FormRowSelectInput>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs style={{ marginBottom: 5 }}>
          <FormRowSelectInput
            id="gender"
            displayEmpty
            value={gender}
            variant="outlined"
            labelGridWidth={12}
            labelId="gender-label"
            label={<Box mb="5px">{t("SENT_FAX.ANNOUNCER")}</Box>}
            onChange={({ target }) => onChange((prev) => ({ ...prev, gender: target.value }))}
          >
            {outboxSettingsByNumber
              ? outboxSettingsByNumber
                  .find((element) => element.language === language)
                  ?.voices.map((voiceType) => (
                    <MenuItem value={voiceType} key={voiceType}>
                      {capitalizeString(voiceType)}
                    </MenuItem>
                  ))
              : null}
          </FormRowSelectInput>
        </Grid>
        <Grid
          item
          xs
          style={{ marginBottom: 5 }}
          onClick={() => isPlanFree && setOpenChangePlan(true)}
        >
          <FormRowSelectInput
            id="dialogue"
            displayEmpty
            value={dialogue}
            variant="outlined"
            labelGridWidth={12}
            disabled={isPlanFree}
            labelId="dialogue-label"
            label={<Box mb="5px">{t("SENT_FAX.DIALOGUE")}</Box>}
            onChange={({ target }) => onChange((prev) => ({ ...prev, dialogue: target.value }))}
          >
            <MenuItem value="withad">{t("SENT_FAX.WITHAD")}</MenuItem>
            <MenuItem value="noad">{t("SENT_FAX.NOAD")}</MenuItem>
          </FormRowSelectInput>
        </Grid>
      </Grid>

      <Box display="flex" mb={2} width="100%" justifyContent="center">
        {transformedAudio ? (
          <Player controls url={`${API_STORAGE}/storage${transformedAudio}`} />
        ) : null}
      </Box>

      {openChangePlan ? (
        <UpgradeToChangeDialogue handleClosure={() => setOpenChangePlan(false)} />
      ) : null}
    </>
  );
}

SettingsFaxToHuman.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    gender: PropTypes.string,
    language: PropTypes.string,
    dialogue: PropTypes.oneOf(["withad", "noad"]),
  }),
};

export default SettingsFaxToHuman;
