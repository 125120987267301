export const bundleStatuses = {
  draft: "draft",
  approved: "approved",
  inReview: "in-review",
  twilioRejected: "rejected",
  pendingReview: "pending-review",
  twilioApproved: "twilio-approved",
};

export const documentUserTypes = {
  individual: "Individual",
  business: "Business",
  Individual: "Individual",
  Business: "Business",
};
