import queryString from "query-string";
import { fetchWithRefresh } from "helpers";

import { API_AFFOGATO, API_STORAGE } from "config";

const getRequirements = ({ country, numberType, userType, uploadedDocuments = [] }) => {
  const requestOptions = {
    method: "GET",
  };
  const qs = {
    iso_country: country,
    number_type: numberType,
    end_user_type: userType,
  };

  let path = `${API_AFFOGATO}/address_verification/v2/requirements?${queryString.stringify(qs)}`;

  if (uploadedDocuments && uploadedDocuments.length) {
    path += uploadedDocuments.map((doc) => `&uploaded_document=${doc}`).join("");
  }

  return fetchWithRefresh(path, requestOptions);
};

const postNewAddress = ({ city, street, region, zipCode, isoCountry, customerName }) => {
  const requestOptions = {
    method: "POST",
    body: {
      customer_name: customerName,
      street,
      city,
      postal_code: zipCode,
      region,
      iso_country: isoCountry,
    },
  };
  const path = `${API_AFFOGATO}/address_verification/v2/addresses`;
  return fetchWithRefresh(path, requestOptions);
};

const getAddresses = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/address_verification/v2/addresses`;
  return fetchWithRefresh(path, requestOptions);
};

const getBundles = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/address_verification/v2/bundles`;
  return fetchWithRefresh(path, requestOptions);
};

const documentUpload = (file) => {
  const data = new FormData();
  data.append("file", file);
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_STORAGE}/storage/support`;
  return fetchWithRefresh(path, requestOptions);
};

const postNewBundle = ({
  country,
  userType,
  userData,
  numberType,
  customFields,
  uploadedFiles,
  userDocuments,
  documentDictionary,
}) => {
  const documentMap = documentDictionary.reduce((accumulator, { attributes, document_type }) => {
    accumulator[document_type] = attributes;
    return accumulator;
  }, {});
  const endUserMap = Object.entries(userData).reduce((accumulator, [key, value]) => {
    accumulator[key] = value;
    return accumulator;
  }, {});
  const documents = Object.entries(userDocuments).reduce((accumulator, [name, documentTypes]) => {
    Object.keys(documentTypes).forEach((type) => {
      accumulator.push([name, type]);
    });
    return accumulator;
  }, []);

  const requestOptions = {
    method: "POST",
    body: {
      iso_country: country,
      number_type: numberType,
      end_user_type: userType,
      end_user_attributes: endUserMap,
      documents: documents.map(([name, documentType]) => {
        const attributesObject = documentMap[documentType].reduce(
          (accumulator, { machine_name }) => {
            if (machine_name === "address_sids") {
              if (customFields[name][machine_name]) {
                accumulator[machine_name] = [customFields[name][machine_name]];
              }
              return accumulator;
            }
            accumulator[machine_name] =
              customFields[name][machine_name] || endUserMap[machine_name];
            return accumulator;
          },
          {},
        );

        return {
          document_type: documentType,
          attributes: attributesObject,
          file_name: uploadedFiles[documentType],
        };
      }),
    },
  };

  const path = `${API_AFFOGATO}/address_verification/v2/full_bundle`;
  return fetchWithRefresh(path, requestOptions);
};

export const addressApi = {
  getBundles,
  getAddresses,
  postNewBundle,
  postNewAddress,
  documentUpload,
  getRequirements,
};
