import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback, useEffect, useMemo } from "react";

import { boxNames } from "../../enums/faxes";
import { selectAccountId } from "../../selectors/account.selector";
import { selectBoxRecords } from "../../selectors/faxes.selector";
import { faxesAsyncActions } from "../../stores/reducers/faxes.reducer";
import FaxesColumnFromTo from "../Columns/FaxesColumnFromTo";
import FaxesColumnNote from "../Columns/FaxesColumnNote";
import FaxesColumnOutboxStatus from "../Columns/FaxesColumnOutboxStatus";
import FaxesColumnSendTime from "../Columns/FaxesColumnSendTime";
import InfiniTable from "../InfiniTable/InfiniTable";
import EmptyFaxes from "../InfiniTable/EmptyFaxes";
import FaxCheckbox from "./FaxCheckbox";
import OutboxActions from "./OutboxActions";

function Outbox() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accountId = useSelector(selectAccountId);
  const outboxRecords = useSelector(selectBoxRecords(boxNames.outbox));

  const fetchOutbox = useCallback(
    (fetchOptions = { url: `numbers/all/applications/fax-${accountId}/outbox` }) => {
      dispatch(faxesAsyncActions.fetchBox({ boxName: boxNames.outbox, fetchOptions }));
    },
    [accountId, dispatch],
  );

  useEffect(() => {
    fetchOutbox();
  }, [fetchOutbox]);

  useEffect(() => {
    !outboxRecords && fetchOutbox();
  }, [fetchOutbox, outboxRecords]);

  useEffect(() => {
    outboxRecords && dispatch(faxesAsyncActions.fetchThumbnails({ boxName: boxNames.outbox }));
  }, [outboxRecords, dispatch]);

  const outboxComponents = useMemo(
    () => ({
      Checkbox: function thumbnailCheckboxRender({
        "data-index": dataIndex,
        "data-description": dataDescription,
        ...rest
      }) {
        return (
          <FaxCheckbox
            boxName={boxNames.outbox}
            {...rest}
            dataIndex={dataIndex}
            dataDescription={dataDescription}
          />
        );
      },
    }),
    [],
  );

  const outboxColumns = useMemo(
    () => [
      {
        name: "to",
        label: t("FAXES.TO"),
        options: {
          filter: false,
          customBodyRenderLite: function columnFromRender(dataIndex) {
            return <FaxesColumnFromTo boxName={boxNames.outbox} dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "status",
        label: t("COMMON.STATUS"),
        options: {
          sort: false,
          customBodyRenderLite: function columnStatusRender(dataIndex) {
            return <FaxesColumnOutboxStatus dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "send_time",
        label: t("COMMON.DATE"),
        options: {
          customBodyRenderLite: function columnSendTimeRender(dataIndex) {
            return <FaxesColumnSendTime boxName={boxNames.outbox} dataIndex={dataIndex} />;
          },
          sort: false,
        },
      },
      {
        name: "comment",
        label: t("COMMON.NOTE"),
        options: {
          filter: false,
          customBodyRenderLite: function columnNoteRender(dataIndex) {
            return <FaxesColumnNote boxName={boxNames.outbox} dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "actions",
        label: " ",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => {
            return {
              style: {
                display: "table-cell",
                width: 50,
              },
            };
          },
          customBodyRenderLite: function columnActionsRender(dataIndex) {
            return <OutboxActions dataIndex={dataIndex} />;
          },
        },
      },
    ],
    [t],
  );

  const outboxOptions = useMemo(
    () => ({
      responsive: "simple",
      textLabels: { body: { noMatch: <EmptyFaxes text={t("FAXES.YOUR_OUTBOX_EMPTY")} /> } },
    }),
    [t],
  );

  return (
    <>
      <InfiniTable
        rowHeight={76}
        columns={outboxColumns}
        data={outboxRecords}
        components={outboxComponents}
        isLoading={!outboxRecords}
        options={outboxOptions}
      />
    </>
  );
}

export default memo(Outbox);
