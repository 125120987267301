import { useTranslation } from "react-i18next";
import { Modal, Box, useSnackbar } from "@alohi/kit";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useInput } from "hooks/useInput";
import { Typography, makeStyles } from "ui";
import { selectAccount } from "selectors/account.selector";
import { useAppDispatch, useAppSelector } from "stores/store";
import { clearContactUs, contactUs, requestSearchArticles } from "stores/reducers/support.reducer";
import {
  selectContactUsError,
  selectIsContactUsRunning,
  selectIsContactUsSuccess,
  selectRequestSearchArticles,
  selectIsRequestSearchArticlesRunning,
} from "selectors/support.selector";
import TextFieldMessage from "./components/TextFieldMessage/TextFieldMessage";
import SuggestedArticles from "./components/SuggestedArticles/SuggestedArticles";
import SelectSubject, { SUBJECTS } from "./components/SelectSubject/SelectSubject";

enum VIEWS {
  GET_HELP = "getHelp",
  SEND_REQUEST = "sendRequest",
  SUGGESTED_ARTICLES = "suggestedArticles",
}

interface ContactUsModalProps {
  blocked: boolean;
  isHelpBypass?: boolean;
  preSelectedSubject?: SUBJECTS;
  handleClosure: (isSuccess: boolean) => void;
}

function ContactUsModal({
  blocked,
  isHelpBypass,
  handleClosure,
  preSelectedSubject,
}: ContactUsModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const account = useAppSelector(selectAccount);
  const isError = useAppSelector(selectContactUsError);
  const isSuccess = useAppSelector(selectIsContactUsSuccess);
  const isRunning = useAppSelector(selectIsContactUsRunning);

  const [currentView, setCurrentView] = useState<VIEWS>(
    blocked || isHelpBypass ? VIEWS.SEND_REQUEST : VIEWS.GET_HELP,
  );
  const [selectedSubject, setSelectedSubject] = useState<SUBJECTS>(
    preSelectedSubject ?? SUBJECTS.SUBJECT,
  );
  const [inputMessage, inputMessageHandler] = useInput("");

  const isFetchingArticles = useAppSelector(selectIsRequestSearchArticlesRunning);
  const { results: relatedArticles } = useAppSelector(selectRequestSearchArticles);

  const classes = useStyles();

  const confirmTooltip: string = useMemo(() => {
    if (!blocked && selectedSubject === SUBJECTS.SUBJECT) {
      return t("CONTACT_US.TOOLTIP_SUBJECT");
    }
    if (inputMessage.length === 0) {
      return t("CONTACT_US.TOOLTIP_MESSAGE");
    }
    return "";
  }, [blocked, inputMessage.length, selectedSubject, t]);

  const isConfirmDisabled: boolean = useMemo(() => {
    return (!blocked && selectedSubject === SUBJECTS.SUBJECT) || inputMessage.length === 0;
  }, [blocked, inputMessage.length, selectedSubject]);

  const confirmTitle: string | undefined = useMemo(() => {
    return currentView === VIEWS.GET_HELP
      ? t("CONTACT_US.GET_HELP")
      : currentView === VIEWS.SUGGESTED_ARTICLES
      ? t("CONTACT_US.ARTICLES_CONFIRM")
      : t("FORMS.SUBMIT");
  }, [currentView, t]);

  const cancelTitle: string | undefined = useMemo(() => {
    return currentView === VIEWS.GET_HELP
      ? undefined
      : currentView === VIEWS.SUGGESTED_ARTICLES
      ? t("CONTACT_US.ARTICLES_CANCEL")
      : undefined;
  }, [currentView, t]);

  const handleCancel = () => {
    if (blocked) {
      handleClosure(false);
    }
    switch (currentView) {
      case VIEWS.GET_HELP:
        handleClosure(false);
        break;

      case VIEWS.SUGGESTED_ARTICLES:
        setCurrentView(VIEWS.SEND_REQUEST);
        break;

      case VIEWS.SEND_REQUEST:
        handleClosure(false);
        break;

      default:
        break;
    }
  };

  const searchArticles = async () => {
    try {
      const response = await dispatch(
        requestSearchArticles({
          query: inputMessage,
        }),
      ).unwrap();
      if (response.results.length === 0) {
        sendRequest();
      } else {
        setCurrentView(VIEWS.SUGGESTED_ARTICLES);
      }
    } catch {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  };

  const sendRequest = useCallback(() => {
    if (inputMessage && (blocked || selectedSubject)) {
      dispatch(
        contactUs({
          body: inputMessage,
          name: account.name,
          email: account.email,
          subject: blocked ? t("PROFILE.ACCOUNT_BLOCKED") : selectedSubject,
        }),
      );
    } else {
      enqueueSnackbar(t("FORMS.ALL_FIELD_REQUIRED"), { variant: "error" });
    }
  }, [
    t,
    blocked,
    dispatch,
    inputMessage,
    account.name,
    account.email,
    enqueueSnackbar,
    selectedSubject,
  ]);

  const handleConfirmation = async () => {
    switch (currentView) {
      case VIEWS.GET_HELP:
        await searchArticles();
        break;

      case VIEWS.SUGGESTED_ARTICLES:
        handleClosure(false);
        break;

      case VIEWS.SEND_REQUEST:
        sendRequest();
        break;

      default:
        break;
    }
  };

  const onPressEscape = () => {
    handleClosure(false);
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("CONTACT_US.SUCCESS"), { variant: "success" });
      dispatch(clearContactUs());
      handleClosure(true);
    }
  }, [blocked, dispatch, enqueueSnackbar, isSuccess, handleClosure, t]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(isError, { variant: "error" });
      dispatch(clearContactUs());
    }
  }, [dispatch, enqueueSnackbar, isError]);

  return (
    <Modal
      onCancel={handleCancel}
      cancelTitle={cancelTitle}
      tooltipPlacement="bottom"
      isModalDisabled={isRunning}
      confirmTitle={confirmTitle}
      title={t("CONTACT_US.TITLE")}
      onPressEscape={onPressEscape}
      onConfirm={handleConfirmation}
      confirmTooltip={confirmTooltip}
      isConfirmDisabled={isConfirmDisabled}
      isConfirmLoading={isRunning || isFetchingArticles}
    >
      {
        {
          [VIEWS.GET_HELP]: (
            <Box width={"100%"} p={3}>
              <Typography pb={2} variant="body">
                {t("CONTACT_US.TELL_US")}
              </Typography>
              <SelectSubject
                blocked={blocked}
                selectedSubject={selectedSubject}
                onSelectedSubject={setSelectedSubject}
              />
              <TextFieldMessage
                message={inputMessage}
                className={classes.message}
                onMessage={inputMessageHandler.setValue}
              />
            </Box>
          ),
          [VIEWS.SUGGESTED_ARTICLES]: <SuggestedArticles articles={relatedArticles} />,
          [VIEWS.SEND_REQUEST]: (
            <Box width={"100%"} p={3}>
              <Typography sx={{ mb: 2 }}>{t("CONTACT_US.CONTENT")}</Typography>
              <Box className={classes.inputsWrapper}>
                <SelectSubject
                  blocked={blocked}
                  selectedSubject={selectedSubject}
                  onSelectedSubject={setSelectedSubject}
                />
                <TextFieldMessage
                  className={classes.message}
                  message={inputMessage}
                  onMessage={inputMessageHandler.setValue}
                />
              </Box>
            </Box>
          ),
        }[currentView]
      }
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  selectEmpty: {
    width: "100%",
    marginTop: spacing(2),
  },
  inputsWrapper: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    marginTop: spacing(2),
  },
  message: {
    marginTop: spacing(2),
  },
}));

export default ContactUsModal;
