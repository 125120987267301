import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Switch, Modal, Box, useSnackbar, TextButton } from "@alohi/kit";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

import { Collapse, Grid } from "ui";
import { useInput } from "hooks/useInput";
import FormRow from "components/Forms/FormRow";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import FormRowPhoneInput from "components/Forms/FormRowPhoneInput";
import FormRowFaxNumberInput from "components/Forms/FormRowFaxNumberInput";
import { clearFetchedGroups, getGroups } from "stores/reducers/contacts.groups.reducer";
import { isValidEmail, isValidFaxNumber, isValidPhoneNumber } from "helpers/inputValidation";
import { selectSharedContactById, selectWhitelistContactById } from "selectors/contacts.selector";
import {
  createWhitelistContact,
  updateWhitelistContact,
} from "stores/reducers/contacts.whitelist.reducer";
import {
  getSharedGroups,
  clearFetchedSharedGroups,
} from "stores/reducers/contacts.sharedGroups.reducer";
import {
  createSharedContact,
  updateSharedContact,
} from "stores/reducers/contacts.sharedContacts.reducer";
import GroupAutoComplete from "./components/GroupAutoComplete";

function AddOrEditContactModal({
  handleClosure,
  isSharedContact,
  contactToEditId,
  prefilledFaxNumber,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const sharedContact = useSelector(selectSharedContactById(contactToEditId));
  const normalContact = useSelector(selectWhitelistContactById(contactToEditId));

  const contact = useMemo(
    () => (isSharedContact ? sharedContact : normalContact),
    [isSharedContact, normalContact, sharedContact],
  );
  const initFaxNumber = contact?.faxNumber ?? prefilledFaxNumber ?? "";

  const [note, noteInput] = useInput(contact?.note || "");
  const [isTelefax, setIsTelefax] = useState(!!contact?.isTelefax);
  const [fullName, fullNameInput] = useInput(contact?.fullName || "");
  const [groupsState, setGroupsState] = useState(contact?.groups ?? []);
  const [faxNumber, faxNumberInput] = useInput(initFaxNumber, isValidFaxNumber);
  const [phone, phoneInput] = useInput(contact?.phone || "", isValidPhoneNumber);
  const [cellPhone, cellPhoneInput] = useInput(contact?.cellPhone || "", isValidPhoneNumber);
  const [email, emailInput] = useInput(
    contact?.email || "",
    (value) => value === "" || isValidEmail(value),
  );
  const [expanded, setExpanded] = useState(
    !!contact?.isTelefax || !!contact?.cellPhone || !!contact?.phone || !!contact?.email || false,
  );

  const getAllGroups = useCallback(() => {
    isSharedContact
      ? dispatch(getSharedGroups({ offset: 0, limit: 100 }))
      : dispatch(getGroups({ offset: 0, limit: 100 }));
  }, [dispatch, isSharedContact]);

  const createContact = useCallback(
    (newContact) => {
      if (isSharedContact) {
        dispatch(createSharedContact({ newContact }));
        dispatch(clearFetchedSharedGroups());
      } else {
        dispatch(createWhitelistContact({ newContact }));
        dispatch(clearFetchedGroups());
      }
    },
    [dispatch, isSharedContact],
  );

  const updateContact = useCallback(
    (contactDiffs) => {
      if (isSharedContact) {
        dispatch(updateSharedContact({ contactDiffs }));
        dispatch(clearFetchedSharedGroups());
      } else {
        dispatch(updateWhitelistContact({ contactDiffs }));
        dispatch(clearFetchedGroups());
      }
    },
    [dispatch, isSharedContact],
  );

  function handleConfirm() {
    faxNumberInput.touch();

    if (!faxNumberInput.isValid) {
      enqueueSnackbar(t("FORMS.INVALID_FAX"), { variant: "error" });
      return;
    }

    if (emailInput.hasValue && !emailInput.isValid) {
      enqueueSnackbar(t("FORMS.INVALID_EMAIL"), { variant: "error" });
      return;
    }

    const toUpdateOrCreate = {
      note,
      email,
      phone,
      fullName,
      cellPhone,
      faxNumber,
      isTelefax,
      groups: groupsState,
    };

    if (contactToEditId) {
      Object.assign(toUpdateOrCreate, {
        id: contactToEditId,
      });
      updateContact(toUpdateOrCreate);
    } else {
      createContact(toUpdateOrCreate);
    }

    handleClosure();
  }

  const title = useMemo(() => {
    if (isSharedContact) {
      return contactToEditId ? t("CONTACTS.MODIFY_SHARED") : t("CONTACTS.ADD_SHARED");
    }

    return contactToEditId ? t("CONTACTS.MODIFY") : t("CONTACTS.ADD");
  }, [contactToEditId, isSharedContact, t]);

  useEffect(() => {
    getAllGroups();
  }, [getAllGroups]);

  return (
    <Modal
      title={title}
      maxWidth="sm"
      scroll="paper"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
    >
      <Box component="form" p={2} pr="10%">
        <FormRowTextInput
          type="text"
          value={fullName}
          id="add-contact-name"
          label={t("FORMS.NAME")}
          placeholder={t("FORMS.NAME")}
          onChange={fullNameInput.onChange}
        />
        <FormRowFaxNumberInput
          disabled={false}
          value={faxNumber}
          id="add-fax-number-name"
          onBlur={faxNumberInput.onBlur}
          label={t("CONTACTS.FAX_NUMBER")}
          onFocus={faxNumberInput.onFocus}
          error={faxNumberInput.showsError}
          onChange={faxNumberInput.setValue}
          placeholder={t("CONTACTS.ENTER_FAX_NUMBER")}
        />
        <FormRow label="Groups" labelGridWidth={4}>
          <GroupAutoComplete
            hasActions
            groupsState={groupsState}
            isShared={isSharedContact}
            setGroupsState={setGroupsState}
          />
        </FormRow>
        <FormRowTextInput
          id="add-contact-note"
          value={note}
          onChange={noteInput.onChange}
          label={t("COMMON.NOTE")}
          placeholder={t("COMMON.NOTE")}
        />

        <Grid container>
          <Grid item xs={4} container justifyContent="flex-end">
            <TextButton
              aria-label="show more"
              aria-expanded={expanded}
              onClick={() => setExpanded((current) => !current)}
            >
              {t("COMMON.MORE")}
              <Box mr={2} ml={1}>
                <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} size="xs" />
              </Box>
            </TextButton>
          </Grid>
        </Grid>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FormRow label={t("CONTACTS.IS_TELEFAX_NUMBER")}>
            <Box ml={-1}>
              <Switch
                value={isTelefax}
                checked={isTelefax}
                onChange={() => setIsTelefax((current) => !current)}
              />
            </Box>
          </FormRow>
          <FormRowTextInput
            value={email}
            id="add-contact-email"
            label={t("COMMON.EMAIL")}
            onBlur={emailInput.onBlur}
            onFocus={emailInput.onFocus}
            error={emailInput.showsError}
            onChange={emailInput.onChange}
            placeholder={t("COMMON.EMAIL")}
          />

          <FormRowPhoneInput
            value={phone}
            onBlur={phoneInput.onBlur}
            onFocus={phoneInput.onFocus}
            error={phoneInput.showsError}
            onChange={phoneInput.onChange}
            label={t("COMMON.PHONE_NUMBER")}
            placeholder={t("COMMON.PHONE_NUMBER")}
          />

          <FormRowPhoneInput
            value={cellPhone}
            onBlur={cellPhoneInput.onBlur}
            label={t("COMMON.CELL_NUMBER")}
            onFocus={cellPhoneInput.onFocus}
            error={cellPhoneInput.showsError}
            onChange={cellPhoneInput.onChange}
            placeholder={t("COMMON.CELL_NUMBER")}
          />
        </Collapse>
      </Box>
    </Modal>
  );
}

AddOrEditContactModal.propTypes = {
  isSharedContact: PropTypes.bool,
  contactToEditId: PropTypes.string,
  handleClosure: PropTypes.func,
  prefilledFaxNumber: PropTypes.string,
};

export default AddOrEditContactModal;
