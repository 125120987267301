import { Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Typography, makeStyles } from "ui";
import dashboard from "../../assets/images/dashboard.png";
import BusinessBanner from "./BusinessBanner";

function BusinessUpgradePerks() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.businessWrapper}>
      <BusinessBanner />
      <ul className={classes.list}>
        <Typography component="li" className={classes.listItem}>
          {t("DASHBOARD.BUSINESS_PERK_ONE")}
        </Typography>
        <Typography component="li" className={classes.listItem}>
          {t("DASHBOARD.BUSINESS_PERK_TWO")}
        </Typography>
      </ul>
      <img alt="business-upgrade" src={dashboard} width={357} />
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  businessWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  list: {
    margin: `${spacing(4)} ${spacing(8)}`,
  },
  listItem: {
    marginTop: spacing(2),
  },
}));

export default BusinessUpgradePerks;
