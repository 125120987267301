import { Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Helper, Typography } from "ui";
import { helpCenterUrls } from "routes/urls";

interface DocumentRetentionTitleProps {
  isFlipped: boolean;
}

function DocumentRetentionTitle({ isFlipped }: DocumentRetentionTitleProps) {
  const { t } = useTranslation();

  return (
    <Typography variant={isFlipped ? "panelBackHeader" : "panelFrontHeader"}>
      {t("PROFILE.DOCUMENT_RETENTION_TITLE")}
      <Box
        ml={1}
        component="a"
        target="_blank"
        rel="noreferrer"
        href={helpCenterUrls.documentRetention}
      >
        <Helper size="lg" variant={isFlipped ? "blue" : "light"} />
      </Box>
    </Typography>
  );
}

export default DocumentRetentionTitle;
