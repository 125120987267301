import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Panel, Button, Typography } from "@alohi/kit";

import { webHookTypes } from "enums/webHooks";
import EditWebHooksModal from "views/WebHooks/EditWebHooksModal";
import { getWebHooks } from "stores/reducers/integrations.webHooks.reducer";
import {
  selectIncomingWebHooks,
  selectOutgoingWebHooks,
} from "selectors/integrations.webHooks.selector";

function WebHooks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const incomingWebHooks = useSelector(selectIncomingWebHooks) as {
    target: string;
    id: string;
  }[];
  const outgoingWebHooks = useSelector(selectOutgoingWebHooks) as {
    target: string;
    id: string;
  }[];

  const [editWebHooksModalState, setEditWebHooksModalState] = useState({
    isOpen: false,
    type: webHookTypes.incoming,
  });

  useEffect(() => {
    dispatch(getWebHooks());
  }, [dispatch]);

  return (
    <>
      <Panel id={"webhooks"} headerTitle={t("WEB_HOOKS.TITLE")} sx={{ mb: 7 }}>
        <Panel.Front>
          <Panel.Row>
            <Panel.Cell variant="title">{t("WEB_HOOKS.INCOMING")}</Panel.Cell>
            <Panel.Cell variant="data">
              {incomingWebHooks?.map((webHook) => (
                <Typography key={webHook.id}>{webHook.target}</Typography>
              ))}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <Button
                variant="white"
                onClick={() =>
                  setEditWebHooksModalState({
                    isOpen: true,
                    type: webHookTypes.incoming,
                  })
                }
              >
                {t("COMMON.MANAGE")}
              </Button>
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title">{t("WEB_HOOKS.OUTGOING")}</Panel.Cell>
            <Panel.Cell variant="data">
              {outgoingWebHooks?.map((webHook) => (
                <Typography key={webHook.id}>{webHook.target}</Typography>
              ))}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              <Button
                variant="white"
                onClick={() =>
                  setEditWebHooksModalState({
                    isOpen: true,
                    type: webHookTypes.outgoing,
                  })
                }
              >
                {t("COMMON.MANAGE")}
              </Button>
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>
      </Panel>

      {editWebHooksModalState.isOpen && (
        <EditWebHooksModal
          handleClosure={() =>
            setEditWebHooksModalState({
              isOpen: false,
              type: webHookTypes.incoming,
            })
          }
          type={editWebHooksModalState.type}
        />
      )}
    </>
  );
}

export default WebHooks;
