export type KeyOf<T> = keyof T;

export type ValueOf<T> = T[keyof T];

export type Optional<T> = { [P in keyof T]?: T[P] };

export type Union<T> = DistributedProperties<T>[keyof DistributedProperties<T>];

export type DistributedProperties<T> = { [P in keyof T]: { key: P; value: T[P] } };

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type Values<T> = {
  [K in keyof T]: T[K];
}[keyof T][];

export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export function entries<T>(obj: T): Entries<T> {
  return Object.entries(obj as Record<string, unknown>) as Entries<T>;
}

export function values<T>(obj: T): Values<T> {
  return Object.values(obj as Record<string, unknown>) as Values<T>;
}
