import { ReactNode, useMemo } from "react";
import { Box, makeStyles, useMediaQuery } from "@alohi/kit";
import DismissableBanner from "components/DismissableBanner/DismissableBanner";
import { theme } from "@alohi/kit";
import ProfileTabs from "components/Profile/ProfileTabs";
import { useLocation } from "react-router";
import { urls } from "routes/urls";
import ContactsNavigation from "components/Contacts/ContactsNavigation";
import FaxesNavigation from "components/Faxes/FaxesNavigation";

interface AppBarProps {
  children: ReactNode;
  withDismissableBanner?: boolean;
  noShadow?: boolean;
}

interface StyleProps {
  noShadow?: AppBarProps["noShadow"];
}

const HEADER_HEIGHT = 64;

function AppBar({ children, withDismissableBanner = false, noShadow }: AppBarProps) {
  const classes = useStyles({ noShadow });
  const { pathname } = useLocation();

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const MobileTab = useMemo(() => {
    if (pathname.startsWith(urls.profile.root)) {
      return <ProfileTabs />;
    }
    if (pathname.startsWith(urls.contacts.root)) {
      return <ContactsNavigation />;
    }
    if (pathname.startsWith(urls.faxes.root)) {
      return <FaxesNavigation />;
    }
  }, [pathname]);

  return (
    <Box className={classes.base}>
      <Box className={classes.content}>{children}</Box>
      {withDismissableBanner ? <DismissableBanner /> : null}
      {isMobileLayout ? MobileTab : null}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  base: ({ noShadow }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    position: "relative",
    zIndex: 90,
    ...(!noShadow && { boxShadow: theme.alohi.shadow }),
  }),
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: HEADER_HEIGHT,
    minHeight: HEADER_HEIGHT,
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    background: theme.alohi.white,
  },
}));

export { HEADER_HEIGHT };

export default AppBar;
