import { Box } from "@alohi/kit";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";

import { urls } from "routes/urls";
import { Contact } from "stores/reducers/contacts.helpers";
import { PhoneNumberFilter } from "components/Core/Filters";
import ColumnActions from "components/Columns/ColumnActions";
import { useAppDispatch, useAppSelector } from "stores/store";
import EmptyContacts from "components/InfiniTable/EmptyContacts";
import DeleteContactModal from "views/Contacts/DeleteContactModal";
import AddOrEditContactModal from "views/Contacts/AddOrEditContactModal";
import { SendFaxRouteState } from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import {
  Table,
  makeStyles,
  Typography,
  TableColumnsProp,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "ui";
import {
  selectSharedContacts,
  selectHasMoreSharedContacts,
  selectSharedContactsSelection,
  selectIsSharedContactsLoading,
} from "selectors/contacts.selector";
import {
  getSharedContacts,
  getMoreSharedContacts,
  updateSharedContactsSelection,
} from "stores/reducers/contacts.sharedContacts.reducer";
import GroupsRow from "./GroupsRow";

interface EditModalState {
  isOpen: boolean;
  contactId: null | string;
}

interface ConfirmDeleteModalState {
  isOpen: boolean;
  contactId: null | string;
}

interface SharedContactsProps {
  noBorder: boolean;
  hasActions: boolean;
  hasSelection: boolean;
  areTabsClickable?: boolean;
}

function SharedContacts({
  hasSelection,
  noBorder,
  hasActions,
  areTabsClickable,
}: SharedContactsProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleContactDestination } = useSendFaxDestinationHelpers();

  const hasMore = useAppSelector<boolean>(selectHasMoreSharedContacts);
  const isLoading = useAppSelector<boolean>(selectIsSharedContactsLoading);
  const selection = useAppSelector<string[]>(selectSharedContactsSelection);
  const sharedContactsList = useAppSelector<null | Contact[]>(selectSharedContacts);

  const [editContactModalState, setEditContactModalState] = useState<EditModalState>({
    isOpen: false,
    contactId: null,
  });
  const [confirmDeleteContactsModalState, setConfirmDeleteContactsModalState] =
    useState<ConfirmDeleteModalState>({
      isOpen: false,
      contactId: null,
    });

  const rows = useMemo(() => sharedContactsList ?? [], [sharedContactsList]);

  const actions = useMemo(() => {
    return (index: number) =>
      rows.map((row) => [
        {
          label: t("COMMON.SEND_FAX"),
          callback: () =>
            history.push(urls.sendFax, {
              destination: [handleContactDestination(row, "sharedContact")],
            } satisfies SendFaxRouteState),
        },
        {
          label: "Divider",
        },
        {
          label: t("COMMON.EDIT"),
          callback: () => {
            setEditContactModalState({
              isOpen: true,
              contactId: row.id,
            });
          },
        },
        {
          label: t("COMMON.DELETE"),
          callback: () => {
            setConfirmDeleteContactsModalState({
              isOpen: true,
              contactId: row.id,
            });
          },
        },
      ])[index];
  }, [handleContactDestination, history, rows, t]);

  const columns: TableColumnsProp = useMemo(() => {
    const columns: TableColumnsProp = [];

    if (hasSelection) {
      columns.push({
        width: 16,
        minWidth: 16,
        headerName: " ",
        sortable: false,
        field: "id",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });

      columns.push({
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        flex: 0.05,
      });

      columns.push({
        width: 15,
        minWidth: 15,
        headerName: " ",
        sortable: false,
        field: "index",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });
    } else {
      columns.push({
        flex: 0.115,
        headerName: " ",
        sortable: false,
        field: "id",
        renderCell: function render() {
          return <Box> </Box>;
        },
      });
    }

    columns.push({
      flex: 0.25,
      sortable: false,
      field: "fullName",
      headerName: t("CONTACTS.NAME"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Box sx={{ overflow: "hidden" }}>
            <Typography className={classes.row} sx={{ fontWeight: "bold" }} stopPropagation>
              {row.fullName}
            </Typography>
            <Typography className={classes.row} sx={{ mt: 2 }} stopPropagation>
              <PhoneNumberFilter number={row.faxNumber} />
            </Typography>
          </Box>
        );
      },
    });

    columns.push({
      flex: 0.3,
      sortable: false,
      field: "groups",
      headerName: t("CONTACTS.GROUPS"),
      renderCell: function render({ row }: { row: Contact }) {
        return <GroupsRow row={row} isSharedContact hasActions={hasActions} />;
      },
    });

    columns.push({
      flex: 0.255,
      field: "note",
      sortable: false,
      headerName: t("CONTACTS.NOTE"),
      renderCell: function render({ row }: { row: Contact }) {
        return (
          <Typography className={classes.row} stopPropagation>
            {row.note}
          </Typography>
        );
      },
    });

    if (hasActions) {
      columns.push({
        flex: 0.0925,
        sortable: false,
        field: "actions",
        headerName: " ",
        renderCell: function render({ row }: { row: Contact }) {
          return <ColumnActions dataIndex={row.index} createActions={actions} />;
        },
      });
    }

    return columns;
  }, [actions, classes, hasActions, hasSelection, t]);

  const onWaypoint = useMemo(() => {
    if (!hasMore) {
      return undefined;
    }

    return () => {
      dispatch(getMoreSharedContacts());
    };
  }, [dispatch, hasMore]);

  useEffect(() => {
    if (!sharedContactsList) {
      dispatch(getSharedContacts({ offset: 0 }));
    }
  }, [dispatch, sharedContactsList]);

  return (
    <>
      <Table
        rows={rows}
        rowHeight={76}
        columns={columns}
        noBorder={noBorder}
        isLoading={isLoading}
        onWaypoint={onWaypoint}
        className={classes.root}
        selectionModel={selection}
        checkboxSelection={hasSelection}
        disableSelectionOnClick={!areTabsClickable}
        onSelectionModelChange={(selection) =>
          dispatch(updateSharedContactsSelection(selection as string[]))
        }
        components={{
          NoRowsOverlay: function render() {
            return <EmptyContacts text={t("CONTACTS.NO_CONTACTS_HERE_YET")} />;
          },
        }}
      />

      {editContactModalState.isOpen ? (
        <AddOrEditContactModal
          isSharedContact
          contactToEditId={editContactModalState.contactId}
          handleClosure={() => setEditContactModalState({ isOpen: false, contactId: null })}
        />
      ) : null}

      {confirmDeleteContactsModalState.isOpen && confirmDeleteContactsModalState.contactId ? (
        <DeleteContactModal
          isSharedContact
          contactIds={[confirmDeleteContactsModalState.contactId]}
          handleClosure={() =>
            setConfirmDeleteContactsModalState({
              isOpen: false,
              contactId: null,
            })
          }
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "&& .MuiDataGrid-columnHeader": {
      padding: spacing(0, 1),
    },
    "&& .MuiDataGrid-columnHeader:nth-child(2)": {
      paddingLeft: spacing(0.5),
    },
    "&&& .MuiDataGrid-cell": {
      padding: spacing(2, 1),
    },
    "&&&& .MuiDataGrid-row > div:first-child": {
      padding: 0,
    },
  },
  row: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "text",
  },
}));

export default SharedContacts;
