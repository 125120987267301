import _sortBy from "lodash/sortBy";
import createSelector from "selectorator";
import { CreateTokenProps } from "api/personal_access_tokens.api";

import RS from "../enums/requestStatus";

export const selectIsGetSlackChannelsRunning = createSelector(
  ["slack.getSlackChannels.status"],
  (status) => status === RS.RUNNING,
);

export const selectSlackChannels = createSelector(
  ["slack.getSlackChannels.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return _sortBy(response.channels, ["type", "name"]);
  },
);

export const selectSlackToken = createSelector(["slack.getSlackToken.response"], (response) => {
  if (!response) {
    return null;
  }
  return response;
});

export const selectIsSlackActive = createSelector(["slack.getSlackToken.response"], (response) => {
  if (response === null) return null;
  return !!response;
});

export const selectIsApiActive = createSelector(
  ["api.getApiVerification.response", "api.getClientPrivateInfo.response"],
  (apiResponse, privateInfoResponse) => {
    if (apiResponse === null || privateInfoResponse === null) {
      return null;
    }
    return apiResponse.requirements?.[0]?.state === "verified" && privateInfoResponse.is_active;
  },
);

export const selectApiRedirectionUris = createSelector(
  ["api.getClientPrivateInfo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.redirection_uris;
  },
);

export const selectApiClientPrivateInfo = createSelector(
  ["api.getClientPrivateInfo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return {
      clientId: response.client_id,
      secret: response.secret,
      name: response.name,
    };
  },
);

export const selectIsUpdateClientPrivateInfoSuccess = createSelector(
  ["api.updateClientPrivateInfo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsUpdateClientPrivateInfoError = createSelector(
  ["api.updateClientPrivateInfo.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);

export const selectIsActivateApiSuccess = createSelector(
  ["api.activateApi.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsActivateApiError = createSelector(["api.activateApi.error"], (error) => {
  if (!error) {
    return null;
  }
  return !!error;
});

export const selectIsActivateApiRunning = createSelector(["api.activateApi.status"], (status) => {
  return status === RS.RUNNING;
});

export const selectAuthNToken = createSelector(["apps.getAuthNToken.response"], (response) => {
  if (!response) {
    return null;
  }
  return response.authn_token;
});

export const selectClientPublicInfo = createSelector(
  ["apps.getClientPublicInfo.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectIsAuthorizeAppSuccess = createSelector(
  ["apps.authorizeApp.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsAuthorizeAppError = createSelector(["apps.authorizeApp.error"], (error) => {
  if (!error) {
    return null;
  }
  return !!error;
});

export const selectIsAuthorizeAppRunning = createSelector(
  ["apps.authorizeApp.status"],
  (status) => {
    return status === RS.RUNNING;
  },
);

export const selectIsAuthorizeAppErrorInvalidAuthorization = createSelector(
  ["apps.authorizeApp.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return error.domain === "invalid_authorization";
  },
);

export const selectIsCancelAppAuthorizationSuccess = createSelector(
  ["apps.cancelAppAuthorization.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsCancelAppAuthorizationError = createSelector(
  ["apps.cancelAppAuthorization.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);

export const selectIsCancelAppAuthorizationRunning = createSelector(
  ["apps.cancelAppAuthorization.status"],
  (status) => {
    return status === RS.RUNNING;
  },
);

export const selectAppAuthorizationRedirection = createSelector(
  ["apps.cancelAppAuthorization.response", "apps.authorizeApp.response"],
  (cancelResponse, successResponse) => {
    if (cancelResponse?.redirect_uri) return cancelResponse.redirect_uri + "?status=canceled";
    if (successResponse?.redirect_uri) return successResponse.redirect_uri;
    return null;
  },
);

export const selectAppsList = createSelector(["apps.getAllGrants.response"], (response) => {
  if (!response) {
    return null;
  }
  return response.grants;
});

export const selectIsRemoveAppAccessSuccess = createSelector(
  ["apps.removeAppAccess.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsRemoveAppAccessError = createSelector(
  ["apps.removeAppAccess.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);

export const selectIsUpdateSlackSettingsSuccess = createSelector(
  ["slack.updateSlackSettings.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsUpdateSlackSettingsError = createSelector(
  ["slack.updateSlackSettings.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);

export const selectIsPostSlackTokenSuccess = createSelector(
  ["slack.postSlackToken.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsPostSlackTokenError = createSelector(
  ["slack.postSlackToken.error"],
  (error) => {
    if (!error) return null;
    else return !!error;
  },
);

export const selectIsGetSlackTestSuccess = createSelector(
  ["slack.getSlackTest.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsGetSlackTestError = createSelector(["slack.getSlackTest.error"], (error) => {
  if (!error) {
    return null;
  }
  return !!error;
});

export const selectIsGetSlackTestRunning = createSelector(
  ["slack.getSlackTest.status"],
  (status) => status === RS.RUNNING,
);

export interface PersonalAccessToken {
  id: string;
  last_used: number;
  expires_at: number;
  name: string;
  scopes: CreateTokenProps["scopes"];
  token_str: string;
}

export interface PersonalAccessTokens {
  tokens: PersonalAccessToken[];
  total: number;
}

export const selectPersonalAccessTokens = createSelector(
  ["api.getPersonalAccessTokens.response"],
  (response: PersonalAccessTokens) => {
    if (!response) {
      return { tokens: [], total: 0 };
    }
    return response;
  },
);

export const selectPersonalAccessTokensError = createSelector(
  ["api.getPersonalAccessTokens.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);

export const selectCreatePersonalAccessToken = createSelector(
  ["api.createPersonalAccessToken.response"],
  (response) => {
    const token: PersonalAccessToken = response?.token ?? null;
    return token;
  },
);

export const selectCreatePersonalAccessTokenError = createSelector(
  ["api.createPersonalAccessToken.error"],
  (error) => {
    if (!error) {
      return null;
    }
    return !!error;
  },
);
