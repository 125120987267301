import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useCallback } from "react";
import { Box, Modal, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "ui";
import { durationMap, capitalizeString } from "helpers/string";
import { getCurrentPlan, clearResumePlan, resumePlan } from "stores/reducers/plans.reducer";
import {
  selectPlanId,
  selectPlanType,
  selectPlanDuration,
  selectIsPlanCancelled,
  selectIsResumePlanError,
  selectIsResumePlanRunning,
  selectIsResumePlanSuccessful,
} from "selectors/plan.selector";

function ResumePlanModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const planId = useSelector(selectPlanId);
  const planType = useSelector(selectPlanType);
  const planDuration = useSelector(selectPlanDuration);

  const isError = useSelector(selectIsResumePlanError);
  const isRunning = useSelector(selectIsResumePlanRunning);
  const isSuccessful = useSelector(selectIsResumePlanSuccessful);

  const isPlanCancelled = useSelector(selectIsPlanCancelled);

  const handleConfirmation = useCallback(() => {
    if (planId) {
      dispatch(resumePlan(planId));
    }
  }, [dispatch, planId]);

  useEffect(() => {
    if (isSuccessful || !isPlanCancelled) {
      handleClosure();
    }
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      handleClosure();
    }
  }, [handleClosure, t, enqueueSnackbar, isSuccessful, isError, isPlanCancelled]);

  useEffect(() => {
    dispatch(getCurrentPlan());
    return () => {
      dispatch(clearResumePlan());
    };
  }, [dispatch]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      isModalDisabled={isRunning}
      isConfirmLoading={isRunning}
      onConfirm={handleConfirmation}
      title={t("CHANGE_PLAN.RESUME_PLAN")}
    >
      <Box p={3}>
        <Typography variant="body">
          {t("CHANGE_PLAN.QUESTION_RESUME_PLAN", {
            planType: capitalizeString(planType),
            duration: durationMap(planDuration, false),
          })}
        </Typography>
      </Box>
    </Modal>
  );
}

ResumePlanModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ResumePlanModal;
