import { FaButton } from "@alohi/kit";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import { contactsRoutes } from "enums/contacts";
import { selectCanIEditSharedContacts } from "selectors/ability.selector";
import DeleteConfirmation from "./DeleteConfirmation";

function DeleteButton() {
  const { t } = useTranslation();

  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const canIEditSharedContacts = useAppSelector(selectCanIEditSharedContacts);

  const [isConfirmDeletionOpen, isConfirmDeletionOpenBool] = useBool(false);

  if (
    (contactsTab === contactsRoutes["shared-contacts"] ||
      contactsTab === contactsRoutes["shared-groups"]) &&
    !canIEditSharedContacts
  ) {
    return null;
  }

  return (
    <>
      <FaButton
        icon={faTrash}
        variant="light"
        tooltip={t("COMMON.DELETE")}
        onClick={isConfirmDeletionOpenBool.setTrue}
      />

      {isConfirmDeletionOpen ? (
        <DeleteConfirmation handleClosure={isConfirmDeletionOpenBool.setFalse} />
      ) : null}
    </>
  );
}

export default DeleteButton;
