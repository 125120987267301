import createSelector from "selectorator";
import RS from "enums/requestStatus";

export const selectCurrentCredit = createSelector(
  ["credit.currentCredit.response"],
  (response) => response?.credit,
);

export const selectCurrentCreditCurrency = createSelector(
  ["credit.currentCredit.response"],
  (response) => response?.currency,
);

export const selectCreditRemainingPages = createSelector(
  ["credit.creditDetails.response"],
  (response) => response?.remaining_pages,
);

export const selectCreditPagesInPlan = createSelector(
  ["credit.creditDetails.response"],
  (response) => response?.pages_in_plan,
);

export const selectCreditPagesInCredit = createSelector(
  ["credit.creditDetails.response"],
  (response) => response?.pages_in_credit,
);

export const selectCurrentCreditFreeCredit = createSelector(
  ["credit.currentCredit.response"],
  (response) => response?.details?.free_credit_earned,
);

export const selectLastPaymentDate = createSelector(
  ["credit.currentCredit.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.details.last_payment_date;
  },
);

export const selectFirstPaymentDate = createSelector(
  ["credit.currentCredit.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.details.first_payment_date;
  },
);

export const selectIsCurrentCreditLoading = createSelector(
  ["credit.currentCredit.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsCreditDetailsLoading = createSelector(
  ["credit.creditDetails.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUpdateCurrencyLoading = createSelector(
  ["credit.updateCurrency.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsUpdateCurrencySuccesful = createSelector(
  ["credit.updateCurrency.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response;
  },
);

export const selectIsUpdateCurrencyError = createSelector(
  ["credit.updateCurrency.status"],
  (status) => status === RS.ERROR,
);

export const selectMinAmountForCustomAmount = createSelector(
  ["credit.currentCredit.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.details?.minimum_credit_amount;
  },
);
