import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divider, MenuItem, Box } from "@alohi/kit";
import { faDesktop } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDropbox, faGoogleDrive } from "@fortawesome/free-brands-svg-icons";

import { Typography, useTheme } from "ui";
import Loading from "components/Loading/Loading";
import BoxIcon from "assets/svg/box_logo.svg?react";
import OneDriveIcon from "assets/svg/onedrive.svg?react";
import useBoxPicker from "views/Common/hooks/useBoxPicker";
import useDropboxPicker from "views/Common/hooks/useDropboxPicker";
import useOneDrivePicker from "views/Common/hooks/useOneDrivePicker";
import useLocalFilesPicker from "views/Common/hooks/useLocalFilesPicker";
import useGoogleDrivePicker from "views/Common/hooks/useGoogleDrivePicker";

const SCALE_SIZE = 0.7;
const LOADING_SIZE = 14;

export interface FileUploadOptionsProps {
  onSelect?: () => void;
  allowedMimes: string[];
  allowedFileTypes: string[];
  isBoxIntegrationEnabled?: boolean;
  onLoading?: (isLoading: boolean) => void;
  onAddDocuments: (documents: FileList | File[]) => void;
}

function FileUploadOptions({
  onSelect,
  onLoading,
  allowedMimes,
  onAddDocuments,
  allowedFileTypes,
  isBoxIntegrationEnabled = true,
}: FileUploadOptionsProps) {
  const { alohi } = useTheme();
  const { t } = useTranslation();

  const handleNewFiles = useMemo(
    () => ({
      onLoading,
      allowedMimes,
      onAddDocuments,
      allowedFileTypes,
    }),
    [allowedFileTypes, allowedMimes, onAddDocuments, onLoading],
  );

  const { loadLocalFiles, Input } = useLocalFilesPicker(handleNewFiles);
  const { loadBoxPicker, isBoxPickerLoading, BoxModal } = useBoxPicker(handleNewFiles);
  const { loadDropboxPicker, isDropboxPickerLoading } = useDropboxPicker(handleNewFiles);
  const { loadGooglePicker, isGooglePickerLoading } = useGoogleDrivePicker(handleNewFiles);
  const { loadOneDrivePicker, isOneDrivePickerLoading } = useOneDrivePicker(handleNewFiles);

  const baseOptions = [
    t("COMMON.MY_COMPUTER"),
    "Divider",
    "Google Drive",
    "Divider",
    "Dropbox",
    "Divider",
    "OneDrive",
  ] as const;

  const options = [
    ...baseOptions,
    ...(isBoxIntegrationEnabled ? (["Divider", "Box"] as const) : []),
  ] as const;

  const handleSelection = useCallback(
    (type: (typeof options)[number]) => () => {
      switch (type) {
        case t("COMMON.MY_COMPUTER"):
          loadLocalFiles();
          onSelect?.();
          break;
        case "Google Drive":
          loadGooglePicker();
          onSelect?.();
          break;
        case "Dropbox":
          loadDropboxPicker();
          onSelect?.();
          break;
        case "OneDrive":
          loadOneDrivePicker();
          onSelect?.();
          break;
        case "Box":
          loadBoxPicker();
          onSelect?.();
          break;
        default:
          break;
      }
    },
    [
      t,
      loadLocalFiles,
      onSelect,
      loadGooglePicker,
      loadDropboxPicker,
      loadOneDrivePicker,
      loadBoxPicker,
    ],
  );

  return (
    <>
      {Input}

      {options.map((option, index) => {
        if (option === "Divider") {
          return <Divider py={0.5} key={index} />;
        }

        return (
          <MenuItem key={option} onClick={handleSelection(option)}>
            {option === t("COMMON.MY_COMPUTER") ? (
              <Box width="15px">
                <FontAwesomeIcon icon={faDesktop} color={alohi.almostGray} />
              </Box>
            ) : null}

            {option === "Google Drive" ? (
              isGooglePickerLoading ? (
                <Loading size={LOADING_SIZE} scale={SCALE_SIZE} />
              ) : (
                <Box width="15px">
                  <FontAwesomeIcon icon={faGoogleDrive} color={alohi.almostGray} />
                </Box>
              )
            ) : null}

            {option === "Dropbox" ? (
              isDropboxPickerLoading ? (
                <Loading size={LOADING_SIZE} scale={SCALE_SIZE} />
              ) : (
                <Box width="15px">
                  <FontAwesomeIcon icon={faDropbox} color={alohi.almostGray} />
                </Box>
              )
            ) : null}

            {option === "OneDrive" ? (
              isOneDrivePickerLoading ? (
                <Loading size={LOADING_SIZE} scale={SCALE_SIZE} />
              ) : (
                <Box width="15px">
                  <OneDriveIcon width="14" />
                </Box>
              )
            ) : null}

            {option === "Box" ? (
              isBoxPickerLoading ? (
                <Loading size={LOADING_SIZE} scale={SCALE_SIZE} />
              ) : (
                <Box width="15px">
                  <BoxIcon width="14" />
                </Box>
              )
            ) : null}

            <Typography sx={{ "&&": { ml: 1 } }}>{option}</Typography>
          </MenuItem>
        );
      })}

      {BoxModal}
    </>
  );
}

export default FileUploadOptions;
