import { createContext } from "react";

import i18n from "../../../i18n/index";

export const createAddressBundleState = {
  currentStep: 1,
  stepNames: [
    i18n.t("ADDRESS.BUNDLE_STEPS_CUSTOMER"),
    i18n.t("ADDRESS.BUNDLE_STEPS_PERSONAL_INFO"),
    i18n.t("ADDRESS.BUNDLE_STEPS_DOCUMENTS"),
  ],

  country: null,
  numberType: null,

  // step 1
  userType: null,

  // step 2
  userData: null,
  isUserDataValid: false,

  // step 3
  userDocuments: null,
  isUserDocumentsValid: false,

  customFields: null,
};

export default function reducer(state, action) {
  switch (action.type) {
    case "REMOVE_DOCUMENT_FILES":
      const stateObject = {
        ...state,
        customFields: {
          ...state.customFields,
          [state.userType]: {
            ...state.customFields?.[state.userType],
          },
        },
        userDocuments: {
          ...state.userDocuments,
          [state.userType]: {
            ...state.userDocuments?.[state.userType],
          },
        },
      };

      delete stateObject.customFields[state.userType][action.payload.inputId];
      delete stateObject.userDocuments[state.userType][action.payload.inputId][
        action.payload.documentType
      ];

      return stateObject;
    case "UPDATE_NUMBER_TYPE":
      return {
        ...state,
        numberType: action.payload,
      };
    case "UPDATE_USER_TYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "UPDATE_COUNTRY":
      return {
        ...state,
        country: action.payload,
      };
    case "UPDATE_USER_DATA":
      const newUserData = {
        ...state.userData,
        [state.userType]: {
          ...state.userData?.[state.userType],
          ...action.payload,
        },
      };
      return {
        ...state,
        userData: newUserData,
        isUserDataValid: {
          ...state.isUserDataValid,
          [state.userType]: Object.values(newUserData[state.userType]).every((value) =>
            Boolean(value),
          ),
        },
      };
    case "UPDATE_USER_DOCUMENTS":
      const newUserDocuments = {
        ...state.userDocuments,
        [state.userType]: {
          ...state.userDocuments?.[state.userType],
          [action.payload.name]: {
            ...state.userDocuments?.[state.userType]?.[action.payload.name],
            [action.payload.documentType]: action.payload.file,
          },
        },
      };

      return {
        ...state,
        userDocuments: newUserDocuments,
      };
    case "RESET_USER_DATA":
      return {
        ...state,
        userData: createAddressBundleState.userData,
      };
    case "RESET_ALL_DOCUMENT_FIELDS":
      return {
        ...state,
        userDocuments: null,
        customFields: null,
        isUserDocumentsValid: false,
      };
    case "RESET_CUSTOM_FIELDS": {
      const customFieldsObject = {
        ...state,
        customFields: {
          ...state.customFields,
          [state.userType]: {
            ...state.customFields?.[state.userType],
          },
        },
      };
      delete customFieldsObject.customFields[state.userType][action.payload];
      return customFieldsObject;
    }
    case "INIT_CUSTOM_FIELDS": {
      const customFieldsObject = {
        ...state.customFields,
        [state.userType]: {
          ...state.customFields?.[state.userType],
          ...action.payload,
        },
      };
      return {
        ...state,
        customFields: customFieldsObject,
      };
    }
    case "UPDATE_CUSTOM_FIELDS": {
      const { inputId, fieldType, value } = action.payload;
      const customFieldsObject = {
        ...state.customFields,
        [state.userType]: {
          ...state.customFields?.[state.userType],
          [inputId]: {
            ...state.customFields?.[state.userType]?.[inputId],
            [fieldType]: value,
          },
        },
      };
      return {
        ...state,
        customFields: customFieldsObject,
      };
    }
    case "UPDATE_IS_USER_DOCUMENTS_VALID":
      return {
        ...state,
        isUserDocumentsValid: {
          ...state.isUserDocumentsValid,
          [state.userType]: action.payload,
        },
      };
    case "STEP_FORWARD":
      return {
        ...state,
        currentStep: state.currentStep === 3 ? 3 : state.currentStep + 1,
      };
    case "STEP_BACK":
      return {
        ...state,
        currentStep: state.currentStep === 1 ? 1 : state.currentStep - 1,
      };
    default:
      return state;
  }
}

export const CreateAddressBundleContext = createContext();
