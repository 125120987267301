import PropTypes from "prop-types";
import { Box, Modal, Typography } from "@alohi/kit";
import { Trans, useTranslation } from "react-i18next";

import { PhoneNumberFilter } from "components/Core/Filters";

function ResumeNumberModal({ handleClosure, number }) {
  const { t } = useTranslation();

  return (
    <Modal
      maxWidth="xs"
      title={t("NUMBERS.RESUME_NUMBER")}
      onCancel={() => handleClosure(false)}
      onConfirm={() => handleClosure(true)}
    >
      <Box p={3}>
        <Typography type="body">
          <Trans
            i18nKey="NUMBERS.QUESTION_RESUME_NUMBER"
            components={{
              number: <PhoneNumberFilter number={number} />,
            }}
          />
        </Typography>
      </Box>
    </Modal>
  );
}

ResumeNumberModal.propTypes = {
  number: PropTypes.string.isRequired,
  handleClosure: PropTypes.func.isRequired,
};

export default ResumeNumberModal;
