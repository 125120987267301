import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { alohiAdminUrls } from "routes/urls";
import ChangePlan from "views/ChangePlan/ChangePlan";
import { selectIsPlanFree } from "selectors/plan.selector";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import useAdminRedirections from "hooks/useAdminRedirections";
import { clearSendFax } from "stores/reducers/numbers.reducer";
import { logout } from "stores/reducers/authentication.reducer";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import InactivePlanModal from "views/InactivePlan/InactivePlanModal";
import AccountBlockedModal from "views/AccountBlocked/AccountBlockedModal";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import {
  selectSendFaxError,
  selectIsSendFaxCreditError,
  selectIsSendFaxPlanInactiveError,
  selectIsQuotaCorporateMemberReached,
  selectIsSendFaxAccountInactiveError,
} from "selectors/numbers.selector";

function SendFaxError({ handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();
  const { sendFaxDispatch } = useSendFaxContext();

  const error = useSelector(selectSendFaxError);
  const isPlanFree = useSelector(selectIsPlanFree);
  const isPlanInactive = useSelector(selectIsSendFaxPlanInactiveError);
  const isInsufficientCredit = useSelector(selectIsSendFaxCreditError);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const isUserInactive = useSelector(selectIsSendFaxAccountInactiveError);
  const isQuotaCorpMemberReached = useSelector(selectIsQuotaCorporateMemberReached);

  const [openAddCredit, setOpenAddCredit] = useState(false);
  const [openQuotaModal, setOpenQuotaModal] = useState(false);
  const [openNoCreditModal, setOpenNoCreditModal] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [openInactivePlanModal, setOpenInactivePlanModal] = useState(false);
  const [openAccountBlockedModal, setOpenAccountBlockedModal] = useState(false);
  const [openNoCreditForCorporateMember, setOpenNoCreditForCorporateMember] = useState(false);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: () => setOpenChangePlanModal(true),
    });
  }, [redirectToAdmin]);

  const handleAddCredit = useCallback((isSuccess) => {
    if (isSuccess) {
      setOpenNoCreditModal(false);
    }
    setOpenAddCredit(false);
  }, []);

  const handleChangePlan = useCallback((isSuccess) => {
    if (isSuccess) {
      setOpenUpgradePlanModal(false);
    }
    setOpenChangePlanModal(false);
  }, []);

  useEffect(() => {
    if (error) {
      sendFaxDispatch({ type: "SET_IS_OVERLAY_OPEN", payload: false });

      dispatch(clearSendFax());

      if (isInsufficientCredit) {
        if (isCorporateMember) {
          setOpenNoCreditForCorporateMember(true);
          return;
        }
        isPlanFree ? setOpenUpgradePlanModal(true) : setOpenNoCreditModal(true);
        return;
      }
      if (isQuotaCorpMemberReached) {
        setOpenQuotaModal(true);
        return;
      }

      if (isUserInactive) {
        setOpenAccountBlockedModal(true);
        return;
      }

      if (isPlanInactive) {
        if (isCorporateMember) {
          enqueueSnackbar(t("CHANGE_PLAN.EXPIRE_PLAN_CONTACT_YOUR_ADMIN"), {
            variant: "error",
          });
        } else {
          setOpenInactivePlanModal(true);
        }
        return;
      }

      enqueueSnackbar(error, { variant: "error" });
    }
  }, [
    t,
    error,
    dispatch,
    isPlanFree,
    isPlanInactive,
    isUserInactive,
    enqueueSnackbar,
    sendFaxDispatch,
    isCorporateMember,
    isInsufficientCredit,
    isQuotaCorpMemberReached,
  ]);

  if (openNoCreditModal) {
    return (
      <Modal
        maxWidth="xs"
        cancelTitle={t("COMMON.CLOSE")}
        confirmTitle={t("COMMON.ADD_CREDIT")}
        onConfirm={() => setOpenAddCredit(true)}
        title={t("SENT_FAX.NO_PAGES_REMAINING")}
        onCancel={() => {
          setOpenNoCreditModal(false);
          handleClosure();
        }}
      >
        <Box p={3}>
          <Typography>
            <Trans i18nKey="PROFILE.ZERO_PAGES_ADD_CREDIT" />
          </Typography>
        </Box>

        {openAddCredit ? (
          <AddCreditModal withSuccessModal reason="add_credit" handleClosure={handleAddCredit} />
        ) : null}
      </Modal>
    );
  }

  if (openNoCreditForCorporateMember) {
    return (
      <Modal
        maxWidth="xs"
        confirmTitle={t("COMMON.CLOSE")}
        title={t("SENT_FAX.NO_PAGES_REMAINING")}
        onConfirm={() => {
          setOpenNoCreditForCorporateMember(false);
          handleClosure();
        }}
      >
        <Box p={3}>
          <Typography>
            <Trans i18nKey="PROFILE.ZERO_QUOTA_CORP_MEMBER" />
          </Typography>
        </Box>
      </Modal>
    );
  }

  if (openUpgradePlanModal) {
    return (
      <Modal
        maxWidth="xs"
        cancelTitle={t("COMMON.CLOSE")}
        onConfirm={handleOpenChangePlan}
        confirmTitle={t("COMMON.CHANGE_PLAN")}
        title={t("SENT_FAX.NO_PAGES_REMAINING")}
        onCancel={() => {
          setOpenUpgradePlanModal(false);
          handleClosure();
        }}
      >
        <Box p={3}>
          <Typography>
            <Trans i18nKey="PROFILE.ZERO_PAGES_CHANGE_PLAN" />
          </Typography>
        </Box>
        {openChangePlanModal ? <ChangePlan handleClosure={handleChangePlan} /> : null}
      </Modal>
    );
  }

  if (openQuotaModal) {
    return (
      <Modal
        maxWidth="xs"
        confirmTitle={t("COMMON.CLOSE")}
        title={t("SENT_FAX.NO_PAGES_REMAINING")}
        onConfirm={() => {
          setOpenQuotaModal(false);
          handleClosure();
        }}
      >
        <Box p={3}>
          <Typography>
            <Trans i18nKey="PROFILE.ZERO_QUOTA_CORP_MEMBER" />
          </Typography>
        </Box>
      </Modal>
    );
  }

  if (openAccountBlockedModal) {
    return <AccountBlockedModal handleClosure={() => dispatch(logout())} />;
  }

  if (openInactivePlanModal) {
    return <InactivePlanModal handleClosure={() => setOpenInactivePlanModal(false)} />;
  }

  return null;
}

SendFaxError.propTypes = {
  handleClosure: PropTypes.func,
};

export default SendFaxError;
