import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ComboFields, TextField, Typography, Box, Button, useSnackbar } from "@alohi/kit";

import { urls } from "routes/urls";
import { useInput } from "hooks/useInput";
import { clearResetPassword, resetPassword } from "stores/reducers/authentication.reducer";
import { isValidPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "helpers/inputValidation";
import {
  selectResetPasswordError,
  selectIsResetPasswordRunning,
  selectIsResetPasswordSuccess,
} from "selectors/authentication.selector";

interface NewPasswordProps {
  token: string;
}

function NewPassword({ token }: NewPasswordProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const errorMessage = useSelector(selectResetPasswordError);
  const isRunning = useSelector(selectIsResetPasswordRunning);
  const isSuccessful = useSelector(selectIsResetPasswordSuccess);

  const [password, passwordInput] = useInput("", isValidPassword);
  const [repeatPassword, repeatPasswordInput] = useInput("", (value) => value === password);

  const handleConfirmation = useCallback(async () => {
    passwordInput.touch();
    repeatPasswordInput.touch();

    if (passwordInput.isValid && repeatPasswordInput.isValid) {
      dispatch(
        resetPassword({
          revisedToken: token,
          newPassword: password,
        }),
      );
    }
  }, [dispatch, password, passwordInput, repeatPasswordInput, token]);

  useEffect(() => {
    if (isSuccessful) {
      enqueueSnackbar(t("FORGOT.SUCCESS_CHANGE"), { variant: "success" });
      history.push(urls.login);
      dispatch(clearResetPassword());
    }
  }, [dispatch, enqueueSnackbar, history, isSuccessful, t]);

  useEffect(() => {
    if (Boolean(errorMessage)) {
      enqueueSnackbar(errorMessage, { variant: "error" });
      dispatch(clearResetPassword());
    }
  }, [dispatch, enqueueSnackbar, errorMessage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography type="authenticationTitle">{t("LOGIN.NEW_PASSWORD")}</Typography>
      <Typography type="body">{t("LOGIN.PLEASE_CHOOSE_NEW_PASSWORD")}</Typography>
      <ComboFields sx={{ mt: 2, mb: 2 }} defaultHelperText={t("FORMS.USE_NUMBERS_AND_SYMBOLS")}>
        <TextField
          fullWidth
          name="Password"
          type="password"
          id="passwordInput"
          value={password}
          autoComplete="new-password"
          onBlur={passwordInput.onBlur}
          onFocus={passwordInput.onFocus}
          error={passwordInput.showsError}
          onChange={passwordInput.onChange}
          placeholder={t("LOGIN.NEW_PASSWORD")}
          helperText={
            passwordInput.showsError
              ? password.length > MAX_PASSWORD_LENGTH
                ? t("FORMS.PASSWORD_TOO_LONG", {
                    max: MAX_PASSWORD_LENGTH,
                  })
                : t("FORMS.PASSWORD_TOO_SHORT", {
                    min: MIN_PASSWORD_LENGTH,
                  })
              : undefined
          }
        />
        <TextField
          fullWidth
          type="password"
          name="Repeat Password"
          id="repeatPasswordInput"
          autoComplete="new-password"
          value={repeatPassword}
          onBlur={repeatPasswordInput.onBlur}
          onFocus={repeatPasswordInput.onFocus}
          error={repeatPasswordInput.showsError}
          onChange={repeatPasswordInput.onChange}
          placeholder={t("LOGIN.CONFIRM_PASSWORD")}
          helperText={
            repeatPasswordInput.showsError ? t("FORMS.MISMATCH_PASSWORD_HELPER") : undefined
          }
        />
      </ComboFields>
      <Button
        size="large"
        fullWidth
        isLoading={isRunning}
        onClick={handleConfirmation}
        isDisabled={passwordInput.showsError || repeatPasswordInput.showsError}
      >
        <Typography type="loginButton">{t("COMMON.CONFIRM")}</Typography>
      </Button>
    </Box>
  );
}

export default NewPassword;
