import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Box, Typography, Modal, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { currencyNames } from "enums/currency";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import FormRowSelectInput from "components/Forms/FormRowSelectInput";
import { selectAvailableCurrencies } from "selectors/verifications.selector";
import { getAvailableCurrencies } from "stores/reducers/verifications.currencies.reducer";
import {
  selectCurrentCreditCurrency,
  selectIsUpdateCurrencyError,
  selectIsUpdateCurrencyLoading,
  selectIsUpdateCurrencySuccesful,
} from "selectors/credit.selector";
import {
  updateCurrency,
  getCurrentCredit,
  getCreditDetails,
  clearUpdateCurrency,
} from "stores/reducers/credit.reducer";

function ChangeCurrencyModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isUpdateError = useSelector(selectIsUpdateCurrencyError);
  const isUpdateLoading = useSelector(selectIsUpdateCurrencyLoading);
  const availableCurrencies = useSelector(selectAvailableCurrencies);
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);
  const isUpdateSuccessful = useSelector(selectIsUpdateCurrencySuccesful);

  const [selectValue, selectValueInput] = useInput("");

  const handleOnConfirm = useCallback(() => {
    if (currentCreditCurrency !== selectValue) {
      dispatch(updateCurrency(selectValue));
    } else {
      handleClosure();
    }
  }, [currentCreditCurrency, dispatch, selectValue, handleClosure]);

  useEffect(() => {
    if (isUpdateSuccessful) {
      dispatch(getCreditDetails());
      dispatch(getCurrentPlan());
      dispatch(getCurrentCredit());
      handleClosure();
    } else if (isUpdateError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      handleClosure();
    }
  }, [dispatch, handleClosure, isUpdateSuccessful, enqueueSnackbar, isUpdateError, t]);

  useEffect(() => {
    dispatch(getAvailableCurrencies());

    return () => {
      dispatch(clearUpdateCurrency());
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentCreditCurrency && selectValue === "") {
      selectValueInput.setValue(currentCreditCurrency);
    }
  }, [currentCreditCurrency, selectValue, selectValueInput]);

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleOnConfirm}
      isModalDisabled={isUpdateLoading}
      isConfirmLoading={isUpdateLoading}
      title={t("USAGE_PAYMENT.DEFAULT_CURRENCY")}
    >
      <Box p={3}>
        <FormRowSelectInput
          labelGridWidth={4}
          value={selectValue}
          id="change-default-currency"
          label={t("USAGE_PAYMENT.CURRENCY")}
          onChange={selectValueInput.onChange}
        >
          {availableCurrencies?.map((currency, index) => (
            <MenuItem key={index} value={currency}>
              {`${currency.toUpperCase()} (${currencyNames[currency]})`}
            </MenuItem>
          ))}
        </FormRowSelectInput>
        <Box mb={2}>
          <Typography type="body">{t("USAGE_PAYMENT.DEFAULT_CURRENCY_INFO")}</Typography>
        </Box>
      </Box>
    </Modal>
  );
}

ChangeCurrencyModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ChangeCurrencyModal;
