import PropTypes from "prop-types";
import CurrencyFormat from "react-currency-format";
import { Typography } from "ui";

const DigitFilter = ({ value, renderText }) => (
  <CurrencyFormat
    value={value}
    displayType={"text"}
    thousandSeparator={true}
    renderText={
      renderText ??
      function renderCurrency(formattedValue) {
        return (
          <Typography variant={"body"} component="span">
            {formattedValue}
          </Typography>
        );
      }
    }
  />
);
DigitFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderText: PropTypes.func,
};
export default DigitFilter;
