import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@alohi/kit";

interface ConfirmDeleteFileItemModalProps {
  description: string;
  handleClosure: (isSuccess: boolean) => void;
}

function ConfirmDeleteFileItemModal({
  handleClosure,
  description,
}: ConfirmDeleteFileItemModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      maxWidth="xs"
      onCancel={() => handleClosure(false)}
      onConfirm={() => handleClosure(true)}
      title={t("DELETE_ITEM.ARE_YOU_SURE")}
    >
      <Box p={3}>
        <Typography>{description}</Typography>
      </Box>
    </Modal>
  );
}

export default ConfirmDeleteFileItemModal;
