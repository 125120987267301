import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, DatePickerValue, DatePicker, Modal, Box, useSnackbar } from "@alohi/kit";

import dayjs from "dayjs";
import { useInput } from "hooks/useInput";
import useUnmount from "hooks/useUnmount";
import FormRow from "components/Forms/FormRow";
import { capitalizeString } from "helpers/string";
import { isValidNonEmptyName } from "helpers/inputValidation";
import { CreateTokenProps } from "api/personal_access_tokens.api";
import {
  selectCreatePersonalAccessToken,
  selectCreatePersonalAccessTokenError,
} from "selectors/integrations.selector";
import {
  createPersonalAccessToken,
  clearCreatePersonalAccessToken,
} from "stores/reducers/integrations.api.reducer";
import DisplayTokenStep from "./components/DisplayTokenStep";
import ExpirationSelector, { ExpirationEnum } from "./components/ExpirationSelector";
import ScopesSelector from "./components/ScopesSelector";

export const NO_EXPIRATION_DATE = dayjs("9000-12-1");
const EXPIRATION_DATE_MAX_YEARS = 5;

const VIEWS = {
  CREATE_TOKEN: "createToken",
  DISPLAY_TOKEN: "displayToken",
};

interface CreateTokenModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function CreateTokenModal({ onCancel, onConfirm }: CreateTokenModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [currentView, setCurrentView] = useState(VIEWS.CREATE_TOKEN);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nameValue, nameInput] = useInput("", isValidNonEmptyName);
  const [selectedScopes, setSelectedScopes] = useState<CreateTokenProps["scopes"]>([]);
  const [expiration, setExpiration] = useState<ExpirationEnum>(ExpirationEnum.ONE_WEEK);
  const [expiresAt, setExpiresAt] = useState(dayjs().add(1, "week").valueOf());
  const [date, setDate] = useState<DatePickerValue>(dayjs().add(1, "week"));

  const token = useSelector(selectCreatePersonalAccessToken);
  const isCreatePresonalAccessTokenError = useSelector(selectCreatePersonalAccessTokenError);

  const isDisabled =
    currentView === VIEWS.CREATE_TOKEN
      ? !nameInput.isValid || !expiresAt || selectedScopes.length === 0
      : !token;

  const tooltip = useMemo(() => {
    if (!nameInput.isValid) return t("API_TOKEN.TOOLTIP_INVALID_NAME");
    if (!expiresAt) return t("API_TOKEN.TOOLTIP_INVALID_EXPIRATION_DATE");
    if (selectedScopes.length === 0) return t("API_TOKEN.TOOLTIP_INVALID_SCOPES");
    else return undefined;
  }, [expiresAt, nameInput.isValid, selectedScopes.length, t]);

  useEffect(() => {
    if (isCreatePresonalAccessTokenError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [dispatch, enqueueSnackbar, isCreatePresonalAccessTokenError, t]);

  useEffect(() => {
    if (token) {
      setCurrentView(VIEWS.DISPLAY_TOKEN);
    }
  }, [token, dispatch]);

  useUnmount(() => {
    dispatch(clearCreatePersonalAccessToken());
  });

  const handleConfirm = async () => {
    setIsLoading(true);
    if (currentView === VIEWS.CREATE_TOKEN) {
      if (!nameValue || !expiresAt || selectedScopes.length === 0) return;
      try {
        await dispatch(
          createPersonalAccessToken({
            name: capitalizeString(nameValue),
            scopes: selectedScopes,
            expires_at: expiresAt,
          }),
        );
      } catch {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
    } else if (currentView === VIEWS.DISPLAY_TOKEN) {
      onConfirm();
    }
    setIsLoading(false);
  };

  const onScopeSelect = (scope: CreateTokenProps["scopes"][0]) => {
    if (!selectedScopes.includes(scope)) {
      setSelectedScopes([...selectedScopes, ...[scope]]);
    } else {
      setSelectedScopes(selectedScopes.filter((selectedScope) => selectedScope !== scope));
    }
  };

  const onExpiration = (expiration: ExpirationEnum) => {
    setExpiration(expiration);
    computeExpirationTimestamp(expiration);
  };

  const computeExpirationTimestamp = (expiration: ExpirationEnum, customDate?: DatePickerValue) => {
    switch (expiration) {
      case ExpirationEnum.ONE_WEEK:
        setExpiresAt(dayjs().add(1, "week").valueOf());
        break;
      case ExpirationEnum.THREE_MONTHS:
        setExpiresAt(dayjs().add(3, "months").valueOf());
        break;
      case ExpirationEnum.ONE_YEAR:
        setExpiresAt(dayjs().add(1, "year").valueOf());
        break;
      case ExpirationEnum.CUSTOM:
        if (customDate?.valueOf()) {
          setExpiresAt(customDate?.valueOf());
        }
        break;
      case ExpirationEnum.NO_EXPIRATION:
        setExpiresAt(NO_EXPIRATION_DATE.valueOf());
        break;
      default:
        setExpiresAt(0);
        break;
    }
  };

  const onDate = (date: DatePickerValue) => {
    setDate(date);
    computeExpirationTimestamp(expiration, date);
  };

  return (
    <Modal
      onCancel={currentView === VIEWS.CREATE_TOKEN ? onCancel : undefined}
      onConfirm={handleConfirm}
      confirmTitle={currentView === VIEWS.CREATE_TOKEN ? t("COMMON.CONFIRM") : t("COMMON.DONE")}
      isConfirmDisabled={isDisabled}
      isConfirmLoading={isLoading}
      cancelTitle={t("COMMON.CLOSE")}
      title={t("API_TOKEN.CREATE_TITLE")}
      maxWidth="xs"
      disableAnimation
      confirmTooltip={tooltip}
    >
      <Box py={3} px={1}>
        {
          {
            [VIEWS.CREATE_TOKEN]: (
              <>
                <FormRow label={t("COMMON.NAME")}>
                  <Box mr={10}>
                    <TextField
                      fullWidth
                      placeholder={t("API_TOKEN.TOKEN_NAME")}
                      value={capitalizeString(nameValue)}
                      onBlur={nameInput.onBlur}
                      onFocus={nameInput.onFocus}
                      error={nameInput.showsError}
                      onChange={nameInput.onChange}
                    />
                  </Box>
                </FormRow>

                <FormRow label={t("API_TOKEN.EXPIRATION_DATE")}>
                  <Box display={"flex"} alignItems={"center"} mr={10}>
                    <ExpirationSelector expiration={expiration} onExpiration={onExpiration} />
                  </Box>
                </FormRow>

                {expiration === ExpirationEnum.CUSTOM ? (
                  <FormRow label={t("API_TOKEN.CUSTOM_DATE")}>
                    <Box display={"flex"} alignItems={"center"} mr={10}>
                      <DatePicker
                        isFullWidth
                        disablePast
                        minDate={dayjs().add(1, "day")}
                        maxDate={dayjs().add(EXPIRATION_DATE_MAX_YEARS, "year")}
                        format={"MMM DD, YYYY"}
                        onChange={onDate}
                        value={date}
                      />
                    </Box>
                  </FormRow>
                ) : null}

                <FormRow alignLabel={"flex-start"} label={t("API_TOKEN.SCOPES_LIST")}>
                  <Box minHeight={90} mr={10}>
                    <ScopesSelector selectedScopes={selectedScopes} onScopeSelect={onScopeSelect} />
                  </Box>
                </FormRow>
              </>
            ),
            [VIEWS.DISPLAY_TOKEN]: <DisplayTokenStep />,
          }[currentView]
        }
      </Box>
    </Modal>
  );
}

export default CreateTokenModal;
