import clsx from "clsx";
import { Box } from "@mui/material";
import { makeStyles } from "@alohi/kit";
import { Waypoint } from "react-waypoint";
import { DataGridProProps, DataGridPro, GridColDef, GridRowsProp } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";

import Typography from "../Display/Typography";
import Loader from "./Loader";

export type TableColumnsProp = GridColDef[];
export type TableRowsProp = GridRowsProp;

interface TableProps extends DataGridProProps {
  rows: TableRowsProp;
  columns: TableColumnsProp;
  /** Use this to override components, like the empty state component **/
  components?: DataGridProProps["components"];
  /** Display loading state **/
  isLoading?: boolean;
  /** Callback fired on scroll, use this to fetch more **/
  onWaypoint?: () => void;
  /** Render table without borders **/
  noBorder?: boolean;
  disableSelectionOnClick?: boolean;
  showTableWhenEmpty?: boolean;
}

function Table({
  rows,
  className,
  onWaypoint,
  isLoading = false,
  noBorder,
  disableSelectionOnClick = true,
  showTableWhenEmpty = false,
  ...rest
}: TableProps) {
  const classes = useStyles({
    noBorder,
    areRowsClickable: disableSelectionOnClick === false,
  });
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
        }}
      >
        <Typography variant="body">{t("COMMON.LOADING")}</Typography>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <DataGridPro
        hideFooter
        autoHeight
        rows={rows}
        rowHeight={76}
        disableColumnMenu
        sortingMode="server"
        disableColumnReorder
        disableColumnSelector
        className={clsx(className, classes.root)}
        disableSelectionOnClick={disableSelectionOnClick}
        headerHeight={showTableWhenEmpty ? 48 : !rows.length ? 0 : 48}
        {...rest}
      />
      {/* Can't use onRowsScrollEnd here because it does not work with autoHeight
      https://github.com/mui-org/material-ui-x/issues/404#issuecomment-792722922 */}
      {Boolean(onWaypoint) && Boolean(rows.length) ? (
        <Waypoint onEnter={onWaypoint} bottomOffset="-401px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Typography variant="body">{t("COMMON.LOADING")}</Typography>
            <Loader />
          </Box>
        </Waypoint>
      ) : null}
    </>
  );
}

type StyleProps = {
  noBorder?: boolean;
  areRowsClickable: boolean;
};

const useStyles = makeStyles(({ alohi, shape }) => ({
  root: (props: StyleProps) => ({
    "&&": {
      height: "auto",
      borderWidth: 0,
      "& .MuiDataGrid-main": {
        overflow: "visible",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: `1px solid ${alohi.lighterGray}`,
      },
      "& .MuiDataGrid-cell, .MuiDataGrid-colCell": {
        "&:focus-within, :focus-within ": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeader:first-child": {
        paddingLeft: "12px",
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus-within, :focus-within ": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnHeaderCheckbox": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeadersInner": {
        width: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
      },
      "& .MuiDataGrid-virtualScrollerRenderZone": {
        position: "relative",
      },
      "& .MuiDataGrid-columnHeaders": {
        background: alohi.lightestBlue,
        fontWeight: "bold",
        borderRadius: "unset",
        borderLeft: `1px solid ${alohi.lighterGray}`,
        borderRight: `1px solid ${alohi.lighterGray}`,
        color: alohi.gray + "!important",
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          color: alohi.gray + "!important",
        },
      },
      "& .MuiDataGrid-colCellTitle": {
        fontWeight: "bold",
      },
      "&& .MuiDataGrid-row": {
        cursor: props.areRowsClickable ? "pointer" : undefined,
        width: "unset",
        ...(!props.noBorder && {
          borderLeft: `1px solid ${alohi.lighterGray}`,
          borderRight: `1px solid ${alohi.lighterGray}`,
        }),
        "&:nth-of-type(even)": {
          backgroundColor: alohi.white,
        },
        "&:nth-of-type(odd)": {
          backgroundColor: alohi.lightestGray,
        },
        "&:last-child": {
          ...(props.noBorder
            ? {
                borderBottom: `1px solid ${alohi.lighterGray}`,
              }
            : {
                borderBottomLeftRadius: shape.borderRadius,
                borderBottomRightRadius: shape.borderRadius,
                borderBottom: `1px solid ${alohi.lighterGray}`,
              }),
          "& .MuiDataGrid-cell": {
            borderBottomWidth: 0,
            "&:first-child": {
              borderBottomLeftRadius: shape.borderRadius,
            },
            "&:last-child": {
              borderBottomRightRadius: shape.borderRadius,
            },
          },
        },
      },
    },
  }),
}));

export default Table;
