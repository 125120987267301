import { Box, useOnMount } from "@alohi/kit";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import useBool from "hooks/useBool";
import TosModal from "views/Sso/TosModal";
import DevFab from "components/DevFab/DevFab";
import SyncHistory from "components/Faxes/SyncHistory";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import { Grid, makeStyles, useMediaQuery, useTheme } from "ui";
import FeatureFlags from "components/FeatureFlags/FeatureFlags";
import useUpdateUnleashContext from "hooks/useUpdateUnleashContext";
import { allowedRoutesForSuggestingMobile, urls } from "routes/urls";
import { getSyncHistory } from "stores/reducers/syncHistory.reducer";
import { getGeoLocation } from "stores/reducers/verifications.reducer";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import AccountBlockedModal from "views/AccountBlocked/AccountBlockedModal";
import { getPaymentMethods } from "stores/reducers/payment.methods.reducer";
import { selectSyncHistoryLastUpdate } from "selectors/syncHistory.selector";
import { selectIsEmailVerificationRequired } from "selectors/ability.selector";
import { getNotifications } from "stores/reducers/account.notifications.reducer";
import { getAllNumbers, getAssignedNumbers } from "stores/reducers/numbers.reducer";
import EmailVerificationModal from "views/EmailVerification/EmailVerificationModal";
import { getAccountDetails, getCustomerInfo } from "stores/reducers/account.reducer";
import { clearIsGracefulLogout, logout } from "stores/reducers/authentication.reducer";
import {
  selectAccountCid,
  selectAccountType,
  selectIsTosAccepted,
  selectEmailIsUnverified,
  selectAccountIsInactive,
} from "selectors/account.selector";
import AppBar from "./components/AppBar";
import AppLogo from "../AppLogo";
import UserMenu from "../UserMenu";
import PageTitle from "../PageTitle";
import Websocket from "../Websocket";
import Navigation from "../Navigation";
import AppSwitcher from "../AppSwitcher";
import SuggestMobile from "../SuggestMobile/SuggestMobile";
import LoadingHandler from "./components/LoadingHandler";
import { useCustomer } from "hooks/gql/useCustomer/useCustomer";
import { useFlag } from "@unleash/proxy-client-react";
import UpgradeSuggestion from "../UpgradeSuggestion";
import PrivateLayoutHandleQueryString from "../PrivateLayoutHandleQueryString";

function PrivateLayoutContent({ children, hideNavigation }) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const mainContentRef = useRef();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const hasFaxSignCrossSellingBanner = useFlag("fax-sign-cross-selling-banner");

  const cid = useSelector(selectAccountCid);
  const accountType = useSelector(selectAccountType);
  const isTosAccepted = useSelector(selectIsTosAccepted);
  const emailIsUnverified = useSelector(selectEmailIsUnverified);
  const accountIsInactive = useSelector(selectAccountIsInactive);
  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const lastSyncHistoryUpdate = useSelector(selectSyncHistoryLastUpdate);
  const accessToken = useSelector((state) => state.authentication.accessToken);
  const isEmailVerificationRequired = useSelector(selectIsEmailVerificationRequired);

  const { queryCustomer } = useCustomer();

  const [isAccountBlockedModalOpen, isAccountBlockedModalOpenBool] = useBool(false);
  const [isEmailVerificationModalOpen, isEmailVerificationModalOpenBool] = useBool(false);

  const syncLogout = useCallback(
    (event) => {
      if (event.key === "logout" && Boolean(event.newValue)) {
        dispatch(logout.fulfilled());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (loggedIn) {
      dispatch(getAllNumbers());
      dispatch(getAccountDetails());
      dispatch(getCurrentPlan());
      dispatch(getNotifications());
      dispatch(getAssignedNumbers());
      dispatch(corporateAsyncActions.getUsers());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(getPaymentMethods());
      dispatch(getGeoLocation());
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    if (Boolean(cid)) {
      dispatch(getCustomerInfo());
    }
  }, [cid, dispatch]);

  useEffect(() => {
    dispatch(clearIsGracefulLogout(false));
  }, [dispatch]);

  useEffect(() => {
    if (!lastSyncHistoryUpdate) {
      dispatch(getSyncHistory());
    }
  }, [accessToken, dispatch, lastSyncHistoryUpdate]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);

    return () => window.removeEventListener("storage", syncLogout);
  }, [syncLogout]);

  useEffect(() => {
    isEmailVerificationModalOpenBool.set(emailIsUnverified && isEmailVerificationRequired);
    isAccountBlockedModalOpenBool.set(accountIsInactive);
  }, [
    accountIsInactive,
    emailIsUnverified,
    isEmailVerificationRequired,
    isAccountBlockedModalOpenBool,
    isEmailVerificationModalOpenBool,
  ]);

  useOnMount(async () => {
    try {
      await queryCustomer();
    } catch {
      // Nothing to do
    }
  }, Boolean(loggedIn));

  useUpdateUnleashContext();

  const scrollToTop = () => mainContentRef?.current?.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <LoadingHandler>
      <Box className={classes.root}>
        <AppBar withDismissableBanner={hasFaxSignCrossSellingBanner}>
          <Grid container wrap="nowrap">
            <Grid item mr={3} sx={{ display: "flex", alignItems: "center" }}>
              {isMobileLayout ? (
                !hideNavigation ? (
                  <Navigation scrollToTop={scrollToTop} />
                ) : null
              ) : (
                <>
                  <AppSwitcher />
                  <Box sx={{ ml: 2 }} />
                  <AppLogo />
                </>
              )}
            </Grid>
            <Grid item ml={3}>
              {!isMobileLayout ? (
                !hideNavigation ? (
                  <Navigation scrollToTop={scrollToTop} />
                ) : null
              ) : (
                <PageTitle />
              )}
            </Grid>
            <Grid item xs className={classes.right}>
              <UpgradeSuggestion />
              <UserMenu />
            </Grid>
          </Grid>
        </AppBar>

        {accountType ? (
          <Box className={classes.mainContent} ref={mainContentRef}>
            {children}
          </Box>
        ) : null}

        <Websocket />
        <SyncHistory />
        <SuggestMobile uri={urls.faxes.inbox} pathnames={allowedRoutesForSuggestingMobile} />
        <DevFab />

        {!isTosAccepted ? <TosModal /> : null}

        {!isAccountBlockedModalOpen && isEmailVerificationModalOpen ? (
          <EmailVerificationModal handleClosure={isEmailVerificationModalOpenBool.setFalse} />
        ) : null}

        {isAccountBlockedModalOpen ? (
          <AccountBlockedModal handleClosure={() => dispatch(logout())} />
        ) : null}
      </Box>

      <PrivateLayoutHandleQueryString />
    </LoadingHandler>
  );
}

function PrivateLayout({ children, hideNavigation }) {
  return (
    <FeatureFlags>
      <PrivateLayoutContent hideNavigation={hideNavigation}>{children}</PrivateLayoutContent>
    </FeatureFlags>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    background: alohi.lightestGray,
  },
  mainContent: {
    flex: 1,
    display: "block",
    overflow: "auto",
    scrollbarGutter: "stable",
    background: alohi.lightestGray,
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

PrivateLayout.propTypes = {
  children: PropTypes.element,
  hideNavigation: PropTypes.bool,
};

PrivateLayoutContent.propTypes = {
  children: PropTypes.element,
  hideNavigation: PropTypes.bool,
};

export default PrivateLayout;
