import { Box, Helper, Typography } from "@alohi/kit";

interface ModalTitleWithHelperLinkProps {
  link: string;
  title: string;
}

function ModalTitleWithHelperLink({ link, title }: ModalTitleWithHelperLinkProps) {
  return (
    <Typography type="modalTitle">
      <Box display="flex" alignItems="center">
        <Box>{title}</Box>
        <Box ml={1} href={link} component="a" target="_blank" rel="noreferrer">
          <Helper size="lg" variant="dark" />
        </Box>
      </Box>
    </Typography>
  );
}

export default ModalTitleWithHelperLink;
