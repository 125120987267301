import _get from "lodash/get";
import _last from "lodash/last";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { faDesktop } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faApple,
  faChrome,
  faSafari,
  faFirefox,
  faAndroid,
  faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";

import { useTheme } from "ui";
import { formatDefaultDate } from "helpers/date";
import InfiniTable from "components/InfiniTable/InfiniTable";
import { selectActivityLogs } from "selectors/authentication.selector";
import { getActivityLogs } from "stores/reducers/authentication.reducer";

function ActivityLogsModal({ onConfirm }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const activityLogs = useSelector(selectActivityLogs);

  const [hasMore, setHasMore] = useState(true);
  const [getOptions, setGetOptions] = useState({
    reset: false,
    limit: 50,
    before: _get(_last(activityLogs), "date", ""),
  });
  const [options, setOptions] = useState({
    sort: false,
    selectableRows: "none",
    selectableRowsHeader: false,
  });

  const getLoginLogs = useCallback((options) => dispatch(getActivityLogs(options)), [dispatch]);

  const paginate = useCallback(() => {
    getLoginLogs(getOptions).then((res) => {
      res.payload.response.length ? setHasMore(true) : setHasMore(false);
    });
  }, [getLoginLogs, getOptions]);

  const getColumns = () => [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "date",
      label: t("COMMON.DATE"),
      options: {
        customBodyRender: function customBodyRender(value) {
          return <Typography variant="body">{formatDefaultDate(value)}</Typography>;
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "username",
      label: t("COMMON.USERNAME"),
      options: {
        setCellProps: () => ({
          style: {
            maxWidth: 190,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.alohi.gray,
          },
        }),
      },
    },
    {
      name: "location",
      label: t("PROFILE.SECURITY_ACTIVITY_HEADER_LOCATION"),
      options: {
        setCellProps: () => ({
          style: {
            maxWidth: 118,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.alohi.gray,
          },
        }),
      },
    },
    {
      name: "platform",
      label: t("LOGIN.LOGIN"),
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: function customBodyRender(value) {
          let icon = faChrome;

          switch (value?.browser) {
            case "safari":
              icon = faSafari;
              break;
            case "chrome":
              icon = faChrome;
              break;
            case "firefox":
              icon = faFirefox;
              break;
            case "msie":
              icon = faInternetExplorer;
              break;
            default: {
              switch (value?.clientPlatform) {
                case "iOS":
                  icon = faApple;
                  break;
                case "Android":
                  icon = faAndroid;
                  break;
                default:
                  icon = faDesktop;
              }
            }
          }
          return (
            <Box display="flex" justifyContent="center">
              <FontAwesomeIcon icon={icon} style={{ color: theme.alohi.gray }} />
            </Box>
          );
        },
      },
    },
    {
      name: "status",
      label: t("COMMON.STATUS"),
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: function customBodyRender(value) {
          return (
            <Box display="flex" justifyContent="center">
              <FontAwesomeIcon
                icon={value === "success" ? faCheck : faTimes}
                style={{ color: theme.alohi.gray }}
              />
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    setGetOptions((prevGetOptions) => ({
      ...prevGetOptions,
      before: _get(_last(activityLogs), "date", ""),
    }));
  }, [activityLogs]);

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      wayPointOptions: {
        ...prevOptions.wayPointOptions,
        hasMore: hasMore,
        onEnter: paginate,
      },
    }));
  }, [hasMore, paginate]);

  useEffect(() => {
    getLoginLogs({ reset: true }).then((res) => {
      res.payload.response.length ? setHasMore(true) : setHasMore(false);
    });
  }, [getLoginLogs]);

  if (activityLogs && !!activityLogs.length) {
    return (
      <Modal
        scroll="paper"
        minHeight="60vh"
        confirmTitle="Done"
        onConfirm={onConfirm}
        title={t("PROFILE.SECURITY_ACTIVITY_LOGS")}
      >
        <InfiniTable
          options={options}
          data={activityLogs}
          hasWaypoint={hasMore}
          onWaypoint={paginate}
          columns={getColumns()}
        />
      </Modal>
    );
  }

  return null;
}

ActivityLogsModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  getLoginLogs: PropTypes.func,
  authentication: PropTypes.object,
};

export default ActivityLogsModal;
