import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Box, useSnackbar } from "@alohi/kit";

import { selectUserByUid } from "selectors/corporate.selector";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import { getAccountDetails, getCustomerInfo } from "stores/reducers/account.reducer";
import {
  selectTransferOwnershipError,
  selectIsTransferOwnershipSuccess,
} from "selectors/account.permissions.selector";
import {
  transferOwnership,
  clearTransferOwnership,
} from "stores/reducers/account.permissions.reducer";

interface ConfirmTransferOwnershipModalProps {
  userUid: string;
  handleClosure?: () => void;
}

function ConfirmTransferOwnershipModal({
  userUid,
  handleClosure,
}: ConfirmTransferOwnershipModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const user = useSelector(selectUserByUid(userUid));
  const success = useSelector(selectIsTransferOwnershipSuccess);
  const error = useSelector(selectTransferOwnershipError);

  useEffect(() => {
    if (success) {
      dispatch(getAccountDetails());
      dispatch(getCustomerInfo());
      dispatch(corporateAsyncActions.getHierarchy());
      dispatch(corporateAsyncActions.getUsers());
      enqueueSnackbar(t("USERS.TRANSFER_OWNERSHIP_SUCCESS"), {
        variant: "success",
      });
      dispatch(clearTransferOwnership());
      handleClosure && handleClosure();
    }
  }, [success, dispatch, enqueueSnackbar, t, handleClosure]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearTransferOwnership());
    }
  }, [error, dispatch, enqueueSnackbar, t]);

  if (!user) {
    return null;
  }

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      title={t("USERS.TRANSFER_OWNERSHIP")}
      data-cy="ConfirmTransferOwnershipModal"
      onConfirm={() => dispatch(transferOwnership({ newOwnerUid: userUid }))}
    >
      <Box p={3}>
        <Typography type="body">
          {t("USERS.TRANSFER_OWNERSHIP_QUESTION", {
            name: user.name || user.email,
          })}
        </Typography>
        <Box mt={1} />
        <Typography type="body">{t("USERS.TRANSFER_OWNERSHIP_DESCRIPTION")}</Typography>
      </Box>
    </Modal>
  );
}

export default ConfirmTransferOwnershipModal;
