import { alohi, BorderAnimation, Box, makeStyles, Typography } from "@alohi/kit";
import { faCircleUp } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import KeyFeatures from "./KeyFeatures";
import StreamlineSignatureContainer from "./StreamlineSignatureContainer";
import { borderAnimationSettings } from "components/Incentive/helpers/animations";
import useAdminRedirections, { SuccessRedirectToApp } from "hooks/useAdminRedirections";
import { alohiAdminUrls } from "routes/urls";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";
import {
  GoogleAnalyticsCustomEvents,
  IncentiveSignPlusUpgradeAction,
  useGoogleTagManager,
} from "hooks/useGoogleTagManager";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

interface ContentPaidEligibleProps {
  className?: string;
}

function ContentPaidEligible({ className }: ContentPaidEligibleProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { discountCode } = useTrialIncentive();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();
  const { sendCustomEvent } = useGoogleTagManager();

  const animation = useMemo(() => borderAnimationSettings, []);

  const handlePrimaryButtonClick = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.incentiveSignPlusUpgrade,
      page: location.pathname,
      extraData: {
        action: IncentiveSignPlusUpgradeAction.claimDiscount,
      },
    });
    redirectToAdmin({
      adminUrl: alohiAdminUrls.sign.upgrade,
      adminParams: {
        ["discount-code"]: discountCode,
        ["success-redirect-to-app"]: SuccessRedirectToApp.Sign,
      },
    });
  };

  const handleSecondaryButtonClick = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.incentiveSignPlusUpgrade,
      page: location.pathname,
      extraData: {
        action: IncentiveSignPlusUpgradeAction.activateTrial,
      },
    });
    redirectToAdmin({
      adminUrl: alohiAdminUrls.sign.upgrade,
      adminParams: {
        trial: true,
        ["success-redirect-to-app"]: SuccessRedirectToApp.Sign,
      },
    });
  };

  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.content}>
        <StreamlineSignatureContainer />
        <KeyFeatures className={classes.keyFeatures} />
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.animatedContainer}>
          <BorderAnimation
            isHovering={false}
            isActive={false}
            borderRadius={8}
            idleAnimation={animation}
            hoverAnimation={animation}
          />
          <Typography type="flow-body">
            {t("INCENTIVE_SIGN.AS_FAX_USER_CLAIM_DISCOUNT_FIRST_SUBSCRIPTION", {
              value_1: 50,
            })}
          </Typography>
          <Box className={classes.buttonsContainer}>
            <PrimaryButton icon={faCircleUp} onClick={handlePrimaryButtonClick}>
              <Typography>
                <Trans
                  i18nKey="INCENTIVE_SIGN.UPGRADE_NOW_X_OFF"
                  components={{
                    strong: <strong />,
                  }}
                  values={{
                    value_1: 50,
                  }}
                />
              </Typography>
            </PrimaryButton>
            <SecondaryButton onClick={handleSecondaryButtonClick}>
              <Typography>{t("INCENTIVE_SIGN.START_FREE_TRIAL")}</Typography>
            </SecondaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "scroll",
    [breakpoints.down("sm")]: {
      padding: spacing("90px", 2, 2, 2),
    },
  },
  keyFeatures: {
    marginTop: spacing(3),
    [breakpoints.down("sm")]: {
      marginTop: spacing(2),
    },
  },
  footer: {
    height: "fit-content",
    [breakpoints.down("sm")]: {
      padding: spacing(2),
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: "solid",
      borderColor: alohi.neutral200,
    },
  },
  animatedContainer: {
    marginTop: spacing(4),
    padding: spacing(2),
    borderRadius: "8px",
    position: "relative",
    [breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  buttonsContainer: {
    marginTop: spacing(2),
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
}));

export default ContentPaidEligible;
