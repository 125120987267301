import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, makeStyles, Typography, Box, useOnMount } from "@alohi/kit";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

import useTitle from "hooks/useTitle";
import Loading from "components/Loading/Loading";
import WithStripeHOC from "components/Payment/WithStripeHOC";
import { useSelector } from "react-redux";
import {
  selectIsPlanInactive,
  selectIsRechargePlanSuccessful,
  selectPlanId,
  selectRechargePlanError,
  selectRechargePlanRequiresAuthentication,
} from "selectors/plan.selector";
import { getCurrentPlan, rechargePlan } from "stores/reducers/plans.reducer";
import { useAppDispatch } from "stores/store";
import useStripe3dsOnRecharge from "hooks/useStripe3dsOnRecharge";
import { getAccountDetails } from "stores/reducers/account.reducer";

function AuthenticatePayment() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  useTitle(t("PAYMENT.AUTHENTICATE_PAYMENT"));

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const confirmPayment = useStripe3dsOnRecharge();

  const planId = useSelector(selectPlanId);
  const isPlanInactive = useSelector(selectIsPlanInactive);
  const isRechargePlanSuccessful = useSelector(selectIsRechargePlanSuccessful);
  const rechargePlanRequiresAuthentication = useSelector(selectRechargePlanRequiresAuthentication);
  const rechargePlanError = useSelector(selectRechargePlanError);

  useOnMount(async () => {
    setIsLoading(true);
    dispatch(getCurrentPlan());
    dispatch(getAccountDetails());
  });

  useOnMount(async () => {
    if (isPlanInactive) {
      dispatch(rechargePlan(planId));
    } else {
      setIsLoading(false);
    }
  }, Boolean(planId));

  useEffect(() => {
    if (isRechargePlanSuccessful) {
      setIsLoading(false);
      setSuccessMessage(true);
    } else if (rechargePlanError) {
      setErrorMessage(rechargePlanError);
      setIsLoading(false);
      setSuccessMessage(false);
    } else if (rechargePlanRequiresAuthentication) {
      confirmPayment();
    }
  }, [
    rechargePlanError,
    isRechargePlanSuccessful,
    rechargePlanRequiresAuthentication,
    confirmPayment,
  ]);

  return (
    <Box className={classes.wrapper}>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <Loading size={30} scale={2} />
        </Box>
      ) : null}
      {successMessage ? (
        <Box className={classes.successBox}>
          <Box
            mb={3}
            mt={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon size="6x" icon={faCheckCircle} className={classes.successIcon} />
          </Box>
          <Typography align="center" className={classes.successTitle}>
            {t("PAYMENT.PAYMENT_AUTHENTICATION_COMPLETED")}
          </Typography>
        </Box>
      ) : null}
      {errorMessage ? (
        <Box className={classes.errorBox}>
          <Box
            mb={3}
            mt={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon className={classes.errorIcon} size="6x" icon={faExclamationCircle} />
          </Box>
          <Typography align="center" className={classes.errorTitle}>
            {t("PAYMENT.PAYMENT_AUTHENTICATION_FAILED")}
          </Typography>
          <Typography align="center">{errorMessage}</Typography>
        </Box>
      ) : null}
      <Divider className={classes.divider} />
      <Button fullWidth className={classes.button} onClick={() => (window.location.href = "/")}>
        {t("CONFIRMATION.GO_TO_HOMEPAGE")}
      </Button>
    </Box>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  wrapper: {
    display: "flex",
    margin: "0 auto",
    maxWidth: "600px",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",
  },
  successIcon: {
    color: alohi.green,
  },
  errorIcon: {
    color: alohi.gold,
  },
  loadingBox: {
    fontSize: "16px",
    marginTop: "40px",
    minHeight: "100px",
    alignItems: "center",
  },
  successBox: {
    fontSize: "16px",
    alignItems: "center",
  },
  errorBox: {
    fontSize: "16px",
    marginBottom: "40px",
    alignItems: "center",
    paddingBottom: "40px",
  },
  successTitle: {
    fontSize: "20px",
    fontWeight: "bolder",
    marginBottom: "40px",
  },
  errorTitle: {
    fontSize: "20px",
    fontWeight: "bolder",
    marginBottom: "80px",
  },
  button: {
    width: "240px",
    margin: "10px 0 20px 0",
  },
  divider: {
    height: "1px",
    width: "100%",
    marginBottom: "20px",
  },
  text: {
    marginTop: "12px",
    color: alohi.blue,
    cursor: "pointer",
    fontWeight: "bolder",
  },
}));

export default WithStripeHOC(AuthenticatePayment);
