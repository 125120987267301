// import { alertConstants } from "stores/constants";
import { createSlice } from "@reduxjs/toolkit";

const alert = createSlice({
  name: "ALERT",
  initialState: {},
  reducers: {
    SUCCESS: (state, action) => {
      return {
        type: "alert-success",
        message: action.message,
      };
    },
    ERROR: (state, action) => {
      return {
        type: "alert-danger",
        message: action.message,
      };
    },
    CLEAR: () => {
      return {};
    },
  },
});

const { actions, reducer } = alert;

export const { SUCCESS, ERROR, CLEAR } = actions;

export default reducer;
