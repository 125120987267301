import { useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import queryString from "query-string";

import i18n from "i18n";
import { useAppSelector } from "stores/store";
import { encodeQueryParams } from "helpers/string";
import { selectIsEmailVerificationRequired } from "selectors/ability.selector";
import { selectAccountIsInactive, selectEmailIsUnverified } from "selectors/account.selector";
import { localStorageKeys } from "helpers/localStorage";
import { useLocation } from "react-router";

export enum SuccessRedirectToApp {
  Fax = "fax",
  Sign = "sign",
}

export interface AdminRedirectionParams {
  adminUrl: string;
  adminParams?: Record<string, string | number | boolean> & {
    ["success-redirect-to-app"]?: SuccessRedirectToApp;
  };
  fallbackAction?: () => void;
}

function useAdminRedirections() {
  const isAdminFlagEnabled = useFlag("alohi-admin-fax");
  const { search } = useLocation();
  const { _gl } = queryString.parse(search);

  const emailIsUnverified = useAppSelector(selectEmailIsUnverified);
  const accountIsInactive = useAppSelector(selectAccountIsInactive);
  const isEmailVerificationRequired = useAppSelector(selectIsEmailVerificationRequired);

  const isAdminRedirectionDisabled =
    localStorage.getItem(localStorageKeys.noAdminRedirection) === "true";
  const isAdminRedirectionForced =
    localStorage.getItem(localStorageKeys.forceAdminRedirection) === "true";

  const canBeRedirectedToAdmin =
    isAdminRedirectionForced ||
    (!(emailIsUnverified && isEmailVerificationRequired) &&
      !accountIsInactive &&
      isAdminFlagEnabled &&
      !isAdminRedirectionDisabled);

  const handleRedirection = useCallback(
    (params: AdminRedirectionParams) => {
      if (canBeRedirectedToAdmin) {
        const encodedParams = encodeQueryParams({
          back: window.location.href,
          lng: i18n.language ?? "en",
          ...params.adminParams,
          ...(_gl ? { _gl } : undefined),
        });

        let sign = "?";
        if (params.adminUrl.includes("?")) {
          sign = "&";
        }
        window.location.href = `${params.adminUrl}${sign}${encodedParams}`;
      } else {
        params.fallbackAction?.();
      }
    },
    [_gl, canBeRedirectedToAdmin],
  );

  return { handleRedirection, canBeRedirectedToAdmin };
}

export default useAdminRedirections;
