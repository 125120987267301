import { useSelector } from "react-redux";
import { useEffect } from "react";
import PropTypes from "prop-types";

import useBool from "hooks/useBool";
import {
  selectIsPrivateLayoutReady,
  selectShouldPrivateLayoutBeReady,
  selectUserHasNoPlan,
} from "selectors/common.selector";
import { selectIsCurrentPlanResponse, selectIsCurrentPlanStatus } from "selectors/plan.selector";
import { sentryCaptureMessage } from "helpers/sentry";

function LoadingHandler({ children }) {
  const isPrivateLayoutReady = useSelector(selectIsPrivateLayoutReady);
  const appShouldBeReady = useSelector(selectShouldPrivateLayoutBeReady);
  const userHasNoPlan = useSelector(selectUserHasNoPlan);
  const getPlansStatus = useSelector(selectIsCurrentPlanStatus);
  const getPlansResponse = useSelector(selectIsCurrentPlanResponse);

  const [isAppReady, isAppReadyBool] = useBool(false);

  useEffect(() => {
    const logSentry = (details) => {
      sentryCaptureMessage({
        message: `Something went wrong when loading LoadingHandler (${details})`,
        level: "error",
      });
    };

    if (appShouldBeReady && !isAppReady) {
      isAppReadyBool.setTrue();
      if (userHasNoPlan) {
        logSentry("User has no plan", { status: getPlansStatus, response: getPlansResponse });
      } else if (!isPrivateLayoutReady) {
        logSentry("Unknown reason");
      }
    }
  }, [
    isAppReady,
    isAppReadyBool,
    appShouldBeReady,
    userHasNoPlan,
    isPrivateLayoutReady,
    getPlansStatus,
    getPlansResponse,
  ]);

  useEffect(() => {
    window.__loadingScreen?.show?.();

    return () => window.__loadingScreen?.hide?.();
  }, []);

  useEffect(() => {
    if (isPrivateLayoutReady) {
      window.__loadingScreen?.hide?.();
    }
  }, [isPrivateLayoutReady]);

  if (!isPrivateLayoutReady) {
    return null;
  }

  return <>{children}</>;
}

LoadingHandler.propTypes = {
  children: PropTypes.element,
};

export default LoadingHandler;
