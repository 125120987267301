import { CustomerMarketingDataFaxSignCrossSellBannerStatus } from "api/gql/generated/graphql";
import { useCustomer } from "hooks/gql/useCustomer/useCustomer";

function useTrialIncentive() {
  const {
    customer: { marketingData },
  } = useCustomer();

  const faxSignCrossSellBanner = marketingData?.faxSignCrossSellBanner;

  return {
    hasPromo: Boolean(faxSignCrossSellBanner),
    hasFaxPlan: faxSignCrossSellBanner?.hasFaxPlan ?? false,
    promoStatus:
      faxSignCrossSellBanner?.promoStatus ??
      CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable,
    trialInProgressDaysLeft: faxSignCrossSellBanner?.trialInProgressDaysLeft ?? 0,
    discountAmount: faxSignCrossSellBanner?.discountAmount ?? 0,
    discountCode: faxSignCrossSellBanner?.discountCode ?? "",
    showBanner: faxSignCrossSellBanner?.showBanner ?? false,
  };
}

export default useTrialIncentive;
