import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const post = (data) => {
  const requestOptions = {
    method: "POST",
    body: data,
  };
  const path = `${API_ESHOP}/carts`;
  return fetchWithRefresh(path, requestOptions);
};

const getCartStatus = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/current_cart`;
  return fetchWithRefresh(path, requestOptions);
};

export const cartsApi = {
  post,
  getCartStatus,
};
