import { MenuItem } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Currency from "components/Core/Currency/Currency";
import FormRowSelectInput from "components/Forms/FormRowSelectInput";
import { selectPossibleAmounts, selectSelectedPaymentAmount } from "selectors/payment.selector";
import {
  clearDiscountAmount,
  setPossiblePaymentAmount,
  getAvailableCreditAmounts,
} from "stores/reducers/payment.amounts.reducer";

function AvailableCreditAmounts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const possibleAmounts = useSelector(selectPossibleAmounts);
  const selectedPaymentAmount = useSelector(selectSelectedPaymentAmount);

  const handleChangeAmount = useCallback(
    (event) => {
      const selectedValue = event.target.value;
      const selectedElement = possibleAmounts.find((element) => element.amount === selectedValue);
      dispatch(setPossiblePaymentAmount(selectedElement));
      dispatch(clearDiscountAmount());
    },
    [possibleAmounts, dispatch],
  );

  useEffect(() => {
    // @TODO only fetch if discount, or custom amount is not available.
    dispatch(getAvailableCreditAmounts());
  }, [dispatch]);

  useEffect(() => {
    if (possibleAmounts && possibleAmounts.length) {
      const [, defaultSelection] = possibleAmounts;
      dispatch(setPossiblePaymentAmount(defaultSelection));
    }
  }, [possibleAmounts, dispatch]);

  if (!selectedPaymentAmount || !possibleAmounts) {
    return (
      <FormRowSelectInput
        value=""
        labelGridWidth={4}
        label={t("ADD_CREDIT.ADD_AMOUNT")}
        helperText={t("ADD_CREDIT.SELECT_AMOUNT")}
        placeholder={t("ADD_CREDIT.SELECT_AMOUNT")}
      />
    );
  }

  return (
    <FormRowSelectInput
      labelGridWidth={4}
      onChange={handleChangeAmount}
      id="available-credit-amounts"
      label={t("ADD_CREDIT.ADD_AMOUNT")}
      value={selectedPaymentAmount.amount}
      helperText={t("ADD_CREDIT.SELECT_AMOUNT")}
    >
      {possibleAmounts.map((option, index) => (
        <MenuItem key={index} value={option.amount}>
          <Currency value={option.amount} currency={option.currency} />
        </MenuItem>
      ))}
    </FormRowSelectInput>
  );
}

export default AvailableCreditAmounts;
