import { useTranslation } from "react-i18next";

import { makeStyles, Typography } from "ui";
import { SharedBy } from "../contexts/store";

interface SharedByTextProps {
  sharedBy: SharedBy;
  ownerName: string;
}

function SharedByText({ sharedBy, ownerName }: SharedByTextProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  let node = null;

  switch (sharedBy) {
    case SharedBy.me:
      node = (
        <Typography className={classes.row} stopPropagation>
          {t("SENT_FAX.ME")}
        </Typography>
      );
      break;
    case SharedBy.name:
      node = (
        <Typography className={classes.row} stopPropagation>
          {ownerName}
        </Typography>
      );
      break;
    case SharedBy.deleted:
      node = (
        <Typography className={classes.row} stopPropagation>
          {t("SENT_FAX.DELETED")}
        </Typography>
      );
      break;
    default:
      node = null;
  }

  return node;
}

const useStyles = makeStyles(() => ({
  row: {
    cursor: "text",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default SharedByText;
