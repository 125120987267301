import { useTranslation } from "react-i18next";

import { Typography } from "ui";
import { ZendeskArticle } from "api/types/zendesk";
import Article from "./components/Article";

interface ArticlesListProps {
  articles: ZendeskArticle[];
}

function ArticlesList({ articles }: ArticlesListProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body">{t("CONTACT_US.FOUND_ARTICLES")}</Typography>

      {articles.map((article) => (
        <Article key={article.id} article={article} />
      ))}
    </>
  );
}

export default ArticlesList;
