import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { entries } from "helpers/types";

type InjectorType = "INIT" | "LOADED" | "ERROR";

type TagData = Record<string, string>;

interface InjectorState<T = HTMLElement> {
  tagMap: Record<string, T>;
  injectorMap: Record<string, InjectorType>;
  queue: Record<string, ((error: boolean) => void)[]>;
}

interface Output {
  isError: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  setTag: Dispatch<SetStateAction<TagData | null>>;
}

type TagType = "script" | "link";

function useInjectTag(tagType: TagType): Output {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tag, setTag] = useState<null | TagData>(null);

  const injectorState = useRef<InjectorState>({
    queue: {},
    tagMap: {},
    injectorMap: {},
  });

  useEffect(() => {
    const injectorRef = injectorState.current;

    if (!tag?.src) {
      return;
    }

    if (!injectorRef.injectorMap?.[tag.src]) {
      injectorRef.injectorMap[tag.src] = "INIT";
    }

    // Check if the tag is already cached
    if (injectorRef.injectorMap[tag.src] === "LOADED") {
      setIsError(false);
      setIsLoaded(true);

      return;
    }

    // Check if the tag already errored
    if (injectorRef.injectorMap[tag.src] === "ERROR") {
      setIsError(true);
      setIsLoaded(true);

      return;
    }

    const onTagEvent = (error: boolean) => {
      // Get all error or load functions and call them
      injectorRef.queue?.[tag.src]?.forEach((job) => job(error));

      if (error && injectorRef.tagMap[tag.src]) {
        injectorRef.tagMap?.[tag.src]?.remove();
        injectorRef.injectorMap[tag.src] = "ERROR";
      } else {
        injectorRef.injectorMap[tag.src] = "LOADED";
      }

      delete injectorRef.tagMap[tag.src];

      setIsLoading(false);
    };

    const stateUpdate = (error: boolean) => {
      setIsError(error);
      setIsLoaded(true);
    };

    const onError = () => onTagEvent(true);
    const onLoad = () => onTagEvent(false);

    if (!injectorRef.tagMap?.[tag.src]) {
      injectorRef.tagMap[tag.src] = document.createElement(tagType);

      if (tag) {
        entries(tag).forEach(([key, value]) => {
          injectorRef.tagMap[tag.src].setAttribute(key, value);
        });
      }

      if (injectorRef.tagMap[tag.src]) {
        document.body.append(injectorRef.tagMap[tag.src]);

        injectorRef.tagMap[tag.src].addEventListener("load", onLoad);
        injectorRef.tagMap[tag.src].addEventListener("error", onError);

        setIsLoading(true);
      }
    }

    if (!injectorRef.queue?.[tag.src]) {
      injectorRef.queue[tag.src] = [stateUpdate];
    } else {
      injectorRef.queue?.[tag.src]?.push(stateUpdate);
    }

    return () => {
      if (!injectorRef.tagMap[tag.src]) {
        return;
      }

      injectorRef.tagMap[tag.src]?.removeEventListener("load", onLoad);
      injectorRef.tagMap[tag.src]?.removeEventListener("error", onError);
    };
  }, [tag, tagType]);

  const removeTag = useCallback(() => {
    const injectorRef = injectorState.current;

    if (!tag) {
      return;
    }

    Array.from(document.body.getElementsByTagName(tagType)).forEach((element) => {
      const src = element.getAttribute("src");

      if (src === tag.src) {
        element.remove();
        delete injectorRef.tagMap[tag.src];
      }
    });
  }, [tag, tagType]);

  useEffect(() => {
    return removeTag;
  }, [removeTag]);

  const output = useMemo(
    () => ({
      setTag,
      isError,
      isLoaded,
      isLoading,
    }),
    [isError, isLoaded, isLoading],
  );

  return output;
}

export default useInjectTag;
