import { FaButton } from "@alohi/kit";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { faChartNetwork } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { useAppSelector } from "stores/store";
import { contactsRoutes } from "enums/contacts";
import { selectCanIEditSharedContacts } from "selectors/ability.selector";
import ShareConfirmation from "./ShareConfirmation";

function ShareButton() {
  const { t } = useTranslation();

  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();

  const canIEditSharedContacts = useAppSelector(selectCanIEditSharedContacts);

  const [isShareModalOpen, isShareModalOpenBool] = useBool(false);

  if (!canIEditSharedContacts) {
    return null;
  }

  if (contactsTab === contactsRoutes.whitelist || contactsTab === contactsRoutes.groups) {
    return (
      <>
        <FaButton
          variant="light"
          icon={faChartNetwork}
          tooltip={t("COMMON.SHARE")}
          onClick={isShareModalOpenBool.setTrue}
        />

        {isShareModalOpen ? (
          <ShareConfirmation handleClosure={isShareModalOpenBool.setFalse} />
        ) : null}
      </>
    );
  }

  return null;
}

export default ShareButton;
