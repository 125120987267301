import { Box, ButtonProps, Icon, makeStyles } from "@alohi/kit";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";

interface CustomScrollButtonProps extends Omit<ButtonProps, "onClick"> {
  direction: "left" | "right";
  disabled: boolean;
  onClick: (direction: "left" | "right") => void;
}

export const CustomScrollButton: React.FC<CustomScrollButtonProps> = ({
  direction,
  disabled,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.base, disabled ? classes.disabled : undefined)}>
      <Icon
        className={classes.icon}
        icon={direction === "left" ? faChevronLeft : faChevronRight}
        onClick={() => !disabled && onClick(direction)}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  base: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    width: "60px",
  },
  icon: {
    color: "black",
    fontSize: 20,
  },
  disabled: {
    opacity: 0.4,
  },
}));
