import { useTranslation } from "react-i18next";
import { Divider, Button, Box } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";

import { websiteUrls } from "routes/urls";
import { Typography, useTheme } from "ui";

function InvalidFaxConfirmation() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box width="50%" mx="auto" mt={10}>
      <Box mt={5} mb={5} display="flex" flexDirection="column" alignItems="center">
        <FontAwesomeIcon icon={faTimesCircle} size="5x" color={theme.alohi.red} />
        <Typography variant="h5" style={{ marginTop: "30px" }}>
          {t("CONFIRMATION.SCANNED_DOCUMENT_IS_INVALID")}
        </Typography>
      </Box>
      <Divider />
      <Box width="50%" mx="auto" my={5}>
        <Button fullWidth href={websiteUrls.homepage}>
          {t("CONFIRMATION.GO_TO_HOMEPAGE")}
        </Button>
      </Box>
    </Box>
  );
}

export default InvalidFaxConfirmation;
