import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import FormRowTextInput from "components/Forms/FormRowTextInput";
import { numberWithCommas } from "helpers/string";

interface MoreOptionProps {
  onCustomPages: (requestedPages: number) => void;
}

const MIN = 500_000;

function MoreOption({ onCustomPages }: MoreOptionProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState("1_000_000");
  const [isError, setError] = useState(false);
  const [isOutOfRange, setIsOutOfRange] = useState(false);

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any non-numeric characters from input
    const numericValue = getNumericValue(target.value);
    const isOutOfRange = Number(numericValue) < MIN;
    setIsOutOfRange(isOutOfRange);

    setError(isOutOfRange);
    setValue(numericValue.toString());
  };

  const getNumericValue = (stringNumber: string): number => {
    return Number(stringNumber.replace(/[^0-9]/g, ""));
  };

  useEffect(() => {
    onCustomPages(isOutOfRange ? 0 : Number(value));
  }, [isOutOfRange, onCustomPages, value]);

  return (
    <FormRowTextInput
      error={isError}
      onChange={onChange}
      id="customPagesInput"
      placeholder={numberWithCommas(MIN)}
      label={t("PAGE_OPTIONS.REQUIRED_PAGES")}
      value={getNumericValue(value) ? numberWithCommas(getNumericValue(value)) : ""}
      helperText={isError ? t("PAGE_OPTIONS.REQUIRED_PAGES_ERROR") + numberWithCommas(MIN) : ""}
    />
  );
}

export default MoreOption;
