import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { Typography } from "ui";
import RenewPlanModal from "views/RenewPlan/RenewPlanModal";
import { selectAccountIsCorporateMember } from "selectors/account.selector";
import { selectIsPlanInactive, selectInactivePlanDescription } from "selectors/plan.selector";

function PlanInactive() {
  const classes = useStyles();
  const { t } = useTranslation();

  const isPlanInactive = useSelector(selectIsPlanInactive);
  const planDescription = useSelector(selectInactivePlanDescription);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const [openRenewPlanModal, setOpenRenewPlanModal] = useState(false);

  if (isCorporateMember) {
    return null;
  }

  return (
    <>
      {isPlanInactive ? (
        <Box onClick={() => setOpenRenewPlanModal(true)} className={classes.root}>
          <Typography variant="subtitle3">
            <FontAwesomeIcon size="1x" className={classes.icon} icon={faExclamationTriangle} />
            {t("CHANGE_PLAN.PLAN_INACTIVE")}
          </Typography>
          <Typography>{planDescription}</Typography>
        </Box>
      ) : null}

      {openRenewPlanModal ? (
        <RenewPlanModal handleClosure={() => setOpenRenewPlanModal(false)} />
      ) : null}
    </>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape }) => ({
  root: {
    textAlign: "center",
    padding: spacing(1, 3),
    backgroundColor: alohi.lightestBlue,
    borderRadius: shape.borderRadius,
    marginTop: spacing(2),
    cursor: "pointer",
  },
  icon: {
    marginRight: spacing(1),
  },
}));

export default PlanInactive;
