import createSelector from "selectorator";

import { isValidCompanyName } from "helpers/inputValidation";
import {
  selectAccount,
  selectAccountIsCorporateMember,
  selectAccountFulfilled,
  selectCustomerInfo,
  selectIsCustomerInfoFulfilled,
} from "./account.selector";
import {
  selectVerifyNumber,
  selectVerifyCustomNumber,
  selectSelectedNumberCountry,
  selectIsGeoLocationRunning,
  selectBulkNumbers,
  selectIsGeoLocationFulfilled,
} from "./verifications.selector";
import {
  selectRefundId,
  selectCurrentCart,
  selectIsChargeAmountError,
  selectCorporateUpgradeState,
} from "./payment.selector";
import {
  selectNextPlan,
  selectCurrentPlan,
  selectCountryForPlan,
  selectIsPlanIndividual,
  selectIsAddressRequired,
  selectIsBundleRequired,
  selectIsCurrentPlanFulfilled,
} from "./plan.selector";
import {
  selectAddresses,
  selectApprovedDocuments,
  selectPendingDocuments,
} from "./address.selector";
import {
  selectAllNumbers,
  selectAssignedNumbers,
  selectAssignedNumbersFulfilled,
} from "./numbers.selector";

/* A corporate upgrade is considered
 * an upgrade from individual plan to corporate plan
 * and the selected plan is corporate
 * if this is true, we require two phone numbers to be chosen
 */
export const selectIsCorporateUpgrade = createSelector(
  [selectIsPlanIndividual, selectNextPlan],
  (isIndividualPlan, nextPlan) => {
    return isIndividualPlan && nextPlan?.plan_target && nextPlan.plan_target === "corporate";
  },
);

export const selectCountryHasPlanRequirements = createSelector(
  [selectCountryForPlan],
  (selectedCountry) => {
    if (selectedCountry?.requirements && selectedCountry.requirements.length) {
      const requirements = selectedCountry.requirements.filter(
        (element) => element.type === "plan_restriction",
      );
      if (requirements.length) {
        return true;
      }
    }
    return false;
  },
);

export const selectIsAddressVerificationFulfilled = createSelector(
  [selectIsAddressRequired, selectAddresses, selectSelectedNumberCountry],
  (isAddressRequired, addresses, numberCountry) => {
    if (isAddressRequired && addresses) {
      return Boolean(addresses.find(({ iso_country }) => iso_country === numberCountry));
    }
    return !isAddressRequired;
  },
);

export const selectIsChangePlanDisabled = createSelector(
  [
    selectIsCorporateUpgrade,
    selectCorporateUpgradeState,
    selectVerifyNumber,
    selectVerifyCustomNumber,
    selectCurrentCart,
    selectIsChargeAmountError,
    selectIsAddressVerificationFulfilled,
    selectPendingDocuments,
    selectApprovedDocuments,
    selectIsBundleRequired,
  ],
  (
    isCorporateUpgrade,
    corporateUpgrade,
    selectedNumber,
    selectedCustomNumber,
    cart,
    isChargeError,
    isAddressVerificationFulfilled,
    pendingDocuments,
    approvedDocuments,
    isBundleRequired,
  ) => {
    let numberValidation = true;
    let bundleVerification = true;

    if (isCorporateUpgrade) {
      numberValidation = corporateUpgrade.firstNumber && corporateUpgrade.secondNumber;
      if (corporateUpgrade.companyName) {
        numberValidation =
          numberValidation && isValidCompanyName(corporateUpgrade.companyName || "");
      }
    } else {
      numberValidation = selectedNumber || selectedCustomNumber;
      if (isBundleRequired) {
        bundleVerification = approvedDocuments?.length >= 1 || !(pendingDocuments?.length >= 1);
      }
    }
    return !(
      cart &&
      !isChargeError &&
      numberValidation &&
      isAddressVerificationFulfilled &&
      bundleVerification
    );
  },
);

export const selectIsUpgradePlanDisabled = createSelector(
  [
    selectIsCorporateUpgrade,
    selectCorporateUpgradeState,
    selectCurrentCart,
    selectRefundId,
    selectIsChargeAmountError,
    selectIsAddressVerificationFulfilled,
  ],
  (
    isCorporateUpgrade,
    corporateUpgrade,
    cart,
    refundId,
    isChargeError,
    isAddressVerificationFulfilled,
  ) => {
    let numberValidation = true;
    if (isCorporateUpgrade) {
      numberValidation = corporateUpgrade.secondNumber;
      if (corporateUpgrade.companyName) {
        numberValidation =
          numberValidation && isValidCompanyName(corporateUpgrade.companyName || "");
      }
    }

    return !(
      cart &&
      refundId &&
      !isChargeError &&
      numberValidation &&
      isAddressVerificationFulfilled
    );
  },
);

export const selectIsAddCorporateNumberDisabled = createSelector(
  [
    selectVerifyNumber,
    selectVerifyCustomNumber,
    selectCurrentCart,
    selectIsChargeAmountError,
    selectIsAddressVerificationFulfilled,
    selectBulkNumbers,
  ],
  (
    selectedNumber,
    selectedCustomNumber,
    cart,
    isChargeError,
    isAddressVerificationFulfilled,
    bulkNumbers,
  ) => {
    const numberValidation = selectedNumber || selectedCustomNumber || bulkNumbers;
    return !(cart && !isChargeError && numberValidation && isAddressVerificationFulfilled);
  },
);

export const selectIsChangeNumberDisabled = createSelector(
  [
    selectVerifyNumber,
    selectVerifyCustomNumber,
    selectCurrentCart,
    selectIsChargeAmountError,
    selectIsAddressVerificationFulfilled,
  ],
  (selectedNumber, selectedCustomNumber, cart, isChargeError, isAddressVerificationFulfilled) => {
    const numberValidation = selectedNumber || selectedCustomNumber;
    return !(cart && !isChargeError && numberValidation && isAddressVerificationFulfilled);
  },
);

export const selectAllNumbersAndPlanInfo = createSelector(
  [selectCurrentPlan, selectAllNumbers],
  (plan, numbers) => {
    if (!plan || !numbers) {
      return [];
    }
    const planItem = plan?.numbers_data || {};
    return numbers.reduce((accumulator, currentValue) => {
      const numberObject = {
        ...currentValue,
      };
      if (planItem?.[currentValue?.number]) {
        Object.assign(numberObject, planItem[currentValue.number], {
          statusAndExpiration: {
            status: currentValue.status,
            expiration: planItem[currentValue.number]?.expiration_date ?? "",
          },
        });
        accumulator.push(numberObject);
      }
      return accumulator;
    }, []);
  },
);

export const selectNumberFriendlyName = (number) =>
  createSelector([selectAllNumbersAndPlanInfo], (numbers) => {
    if (!numbers?.length) return null;
    const { friendly_name } = numbers.find((numberObject) => numberObject.number === number);
    return friendly_name;
  });

export const selectShouldPrivateLayoutBeReady = createSelector(
  [
    selectAccountFulfilled,
    selectAssignedNumbersFulfilled,
    selectIsCustomerInfoFulfilled,
    selectIsGeoLocationFulfilled,
    selectIsCurrentPlanFulfilled,
  ],
  (accountFulfilled, numbersFulfilled, customerFulfilled, geolocationFulfilled, planFulfilled) =>
    accountFulfilled &&
    numbersFulfilled &&
    customerFulfilled &&
    geolocationFulfilled &&
    planFulfilled,
);

export const selectUserHasNoPlan = createSelector(
  [selectShouldPrivateLayoutBeReady, selectAccountIsCorporateMember, selectCurrentPlan],
  (shouldBeReady, isCorporateMember, currentPlan) => {
    if (!shouldBeReady) return false;

    return !isCorporateMember && !currentPlan;
  },
);

export const selectIsPrivateLayoutReady = createSelector(
  [
    selectAccountIsCorporateMember,
    selectCurrentPlan,
    selectAccount,
    selectAssignedNumbers,
    selectIsGeoLocationRunning,
    selectCustomerInfo,
  ],
  (
    isCorporateMember,
    currentPlan,
    accountIsReady,
    numbersIsReady,
    isGeoLocationRunning,
    customerInfo,
  ) =>
    (isCorporateMember || currentPlan) &&
    accountIsReady &&
    numbersIsReady &&
    !isGeoLocationRunning &&
    customerInfo,
);
