import { useCallback } from "react";
import { Box, useBool } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import { Typography, makeStyles } from "ui";
import { alohiAdminUrls } from "routes/urls";
import ChangePlan from "views/ChangePlan/ChangePlan";
import { selectIsPlanFree } from "selectors/plan.selector";
import useAdminRedirections from "hooks/useAdminRedirections";

function UpgradeToReceiveFaxes() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const isPlanFree = useSelector(selectIsPlanFree);
  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: isChangePlanOpenBool.setTrue,
    });
  }, [redirectToAdmin, isChangePlanOpenBool]);

  return (
    <>
      {isPlanFree ? (
        <Box onClick={handleOpenChangePlan} className={classes.root}>
          <Typography variant="subtitle3">
            <FontAwesomeIcon className={classes.icon} icon={faExclamationTriangle} />
            {t("FAXES.UPGRADE_TITLE")}
          </Typography>
          <Typography>{t("FAXES.UPGRADE_CONTENT")}</Typography>
        </Box>
      ) : null}

      {isChangePlanOpen ? <ChangePlan handleClosure={isChangePlanOpenBool.setFalse} /> : null}
    </>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape }) => ({
  root: {
    textAlign: "center",
    padding: spacing(1, 3),
    backgroundColor: alohi.lightestBlue,
    borderRadius: shape.borderRadius,
    marginTop: spacing(2),
    cursor: "pointer",
  },
  icon: {
    marginRight: spacing(1),
  },
}));

export default UpgradeToReceiveFaxes;
