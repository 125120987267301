import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addressApi } from "../../api";
import RS from "../../enums/requestStatus";
import { bundleStatuses } from "../../enums/bundle";
import { logout } from "./authentication.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  bundles: initialRequestState,
  powerset: initialRequestState,
  newBundle: initialRequestState,
  addresses: initialRequestState,
  newAddress: initialRequestState,
  requirements: initialRequestState,
};

export const clearPostNewBundle = createAction("ADDRESS/CLEAR_POST_NEW_BUNDLE");
export const clearPostNewAddress = createAction("ADDRESS/CLEAR_POST_NEW_ADDRESS");
export const clearAddressRequirements = createAction("ADDRESS/CLEAR_ADDRESS_REQUIREMENTS");
export const clearAddressPowerset = createAction("ADDRESS/CLEAR_POWERSET_OF_ADDRESS_REQUIREMENTS");

export const postNewBundle = createAsyncThunk(
  "ADDRESS/POST_NEW_BUNDLE",
  async (
    { country, userType, userData, numberType, customFields, userDocuments, documentDictionary },
    { rejectWithValue },
  ) => {
    try {
      const filesArray = Object.values(userDocuments).reduce((accumulator, currentValue) => {
        Object.entries(currentValue).forEach((value) => {
          accumulator.push(value);
        });

        return accumulator;
      }, []);

      // Upload each file, and remove storage string from the returned path
      const uploadedFiles = await Promise.all(
        Array.prototype.map.call(filesArray, async ([key, value], index) => {
          const { path } = await addressApi.documentUpload(value, index).catch((err) => {
            err.index = index;
            throw err;
          });
          return [key, path.replace("/storage/", "")];
        }),
      );
      const uploadedFilesObject = uploadedFiles.reduce((accumulator, [key, path]) => {
        accumulator[key] = path;
        return accumulator;
      }, {});

      const response = await addressApi.postNewBundle({
        country,
        userType,
        userData,
        numberType,
        customFields,
        userDocuments,
        documentDictionary,
        uploadedFiles: uploadedFilesObject,
      });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const getAddresses = createAsyncThunk(
  "ADDRESS/GET_ADDRESSES",
  async (_, { rejectWithValue }) => {
    try {
      const response = await addressApi.getAddresses();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (extraArguments, { getState }) => {
      return getState().address.addresses.response === null || extraArguments?.force;
    },
  },
);

export const getAddressRequirements = createAsyncThunk(
  "ADDRESS/GET_ADDRESS_REQUIREMENTS",
  async ({ country, numberType, userType }, { rejectWithValue }) => {
    try {
      const response = await addressApi.getRequirements({ country, numberType, userType });
      return { response, userType };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const getPowerSetOfAddressRequirements = createAsyncThunk(
  "ADDRESS/GET_POWERSET_OF_ADDRESS_REQUIREMENTS",
  async ({ country, numberType, userType, uploadedDocuments }, { rejectWithValue }) => {
    try {
      const response = await addressApi.getRequirements({
        country,
        userType,
        numberType,
        uploadedDocuments,
      });
      return { response, userType };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const postNewAddress = createAsyncThunk(
  "ADDRESS/POST_NEW_ADDRESS",
  async ({ isoCountry, city, street, region, zipCode, customerName }, { rejectWithValue }) => {
    try {
      const response = await addressApi.postNewAddress({
        city,
        street,
        region,
        zipCode,
        isoCountry,
        customerName,
      });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const getBundles = createAsyncThunk(
  "ADDRESS/GET_BUNDLES",
  async (_, { rejectWithValue }) => {
    try {
      const response = await addressApi.getBundles();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const { reducer } = createSlice({
  name: "ADDRESS",
  initialState,
  reducers: {},
  extraReducers: {
    [clearPostNewBundle]: (state) => {
      state.newBundle = initialRequestState;
    },
    [postNewBundle.pending]: (state) => {
      state.newBundle.response = null;
      state.newBundle.status = RS.RUNNING;
      state.newBundle.error = null;
    },
    [postNewBundle.fulfilled]: (state, { payload }) => {
      state.newBundle.response = payload.response;
      state.newBundle.status = RS.IDLE;
      state.newBundle.error = null;
    },
    [postNewBundle.rejected]: (state, action) => {
      state.newBundle.response = null;
      state.newBundle.status = RS.ERROR;
      state.newBundle.error = action.payload;
    },
    [getAddresses.pending]: (state) => {
      state.addresses.status = RS.RUNNING;
      state.addresses.error = null;
    },
    [getAddresses.fulfilled]: (state, { payload }) => {
      state.addresses.response = payload.response;
      state.addresses.status = RS.IDLE;
      state.addresses.error = null;
    },
    [getAddresses.rejected]: (state, action) => {
      state.addresses.status = RS.ERROR;
      state.addresses.error = action.payload;
    },
    [clearPostNewAddress]: (state) => {
      state.newAddress = initialRequestState;
    },
    [postNewAddress.pending]: (state) => {
      state.newAddress.response = null;
      state.newAddress.status = RS.RUNNING;
      state.newAddress.error = null;
    },
    [postNewAddress.fulfilled]: (state, { payload }) => {
      state.newAddress.response = payload.response;
      state.newAddress.status = RS.IDLE;
      state.newAddress.error = null;
    },
    [postNewAddress.rejected]: (state, action) => {
      state.newAddress.response = null;
      state.newAddress.status = RS.ERROR;
      state.newAddress.error = action.payload;
    },
    [clearAddressPowerset]: (state) => {
      state.powerset = initialRequestState;
    },
    [getPowerSetOfAddressRequirements.pending]: (state) => {
      state.powerset.status = RS.RUNNING;
      state.powerset.error = null;
    },
    [getPowerSetOfAddressRequirements.fulfilled]: (state, { payload }) => {
      if (!state.powerset.response) {
        state.powerset.response = {};
      }
      state.powerset.response[payload.userType] = payload.response;
      state.powerset.status = RS.IDLE;
      state.powerset.error = null;
    },
    [getPowerSetOfAddressRequirements.rejected]: (state, action) => {
      state.powerset.status = RS.ERROR;
      state.powerset.error = action.payload;
    },
    [clearAddressRequirements]: (state) => {
      state.requirements = initialRequestState;
    },
    [getAddressRequirements.pending]: (state) => {
      state.requirements.status = RS.RUNNING;
      state.requirements.error = null;
    },
    [getAddressRequirements.fulfilled]: (state, { payload }) => {
      if (!state.requirements.response) {
        state.requirements.response = {};
      }
      state.requirements.response[payload.userType] = payload.response;
      state.requirements.status = RS.IDLE;
      state.requirements.error = null;
    },
    [getAddressRequirements.rejected]: (state, action) => {
      state.requirements.status = RS.ERROR;
      state.requirements.error = action.payload;
    },
    [getBundles.pending]: (state) => {
      state.bundles.status = RS.RUNNING;
      state.bundles.error = null;
    },
    [getBundles.fulfilled]: (state, { payload }) => {
      const response = payload.response?.bundles;
      const approved = [];
      const pending = [];
      const rejected = [];
      for (let i = 0; i < response.length; i++) {
        switch (response[i].status) {
          case bundleStatuses.approved:
            approved.push(response[i]);
            break;
          case bundleStatuses.pendingReview:
            pending.push(response[i]);
            break;
          case bundleStatuses.twilioRejected:
            rejected.push(response[i]);
            break;
          default:
        }
      }
      state.bundles.response = {
        approved,
        pending,
        rejected,
      };
      state.bundles.status = RS.IDLE;
      state.bundles.error = null;
    },
    [getBundles.rejected]: (state, action) => {
      state.bundles.status = RS.ERROR;
      state.bundles.error = action.payload;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
