import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import RS from "../../enums/requestStatus";
import { accountsApi } from "../../api/accounts.api";
import { selectIsPlanEnterprise } from "../../selectors/plan.selector";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialDataResidencyState = {
  locations: initialRequestState,
  userSettings: initialRequestState,
  migrationStatus: initialRequestState,
  updateDataLocation: initialRequestState,
};

export const clearUpdateDataLocation = createAction("ACCOUNT/CLEAR_UPDATE_DATA_LOCATION");
export const setUserDataSettings = createAction("ACCOUNT/SET_USER_DATA_SETTINGS");

const getDataResidencyLocations = createAsyncThunk(
  "ACCOUNT/GET_DATA_RESIDENCY_LOCATIONS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountsApi.fetchDataRegions();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().account.locations.status !== RS.RUNNING;
    },
  },
);

const getUserDataResidencySettings = createAsyncThunk(
  "ACCOUNT/GET_USER_DATA_RESIDENCY_SETTINGS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountsApi.fetchUserDataResidencySettings();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().account.userSettings.status !== RS.RUNNING;
    },
  },
);

const updateDataLocation = createAsyncThunk(
  "ACCOUNT/UPDATE_DATA_LOCATION",
  async ({ liveData, backupData }, { rejectWithValue }) => {
    try {
      const response = await accountsApi.updateDataLocation({ liveData, backupData });
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const getMigrationStatus = createAsyncThunk(
  "ACCOUNT/GET_MIGRATION_STATUS",
  async (_, { rejectWithValue, getState }) => {
    const isEnterprise = selectIsPlanEnterprise(getState());
    if (!isEnterprise) {
      return rejectWithValue("not enterprise");
    }
    try {
      const response = await accountsApi.getMigrationStatus();
      return { response };
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().account.migrationStatus.status !== RS.RUNNING;
    },
  },
);

const dataResidencySlice = (builder) => {
  builder.addCase(getDataResidencyLocations.pending, (state) => {
    state.locations.status = RS.RUNNING;
    state.locations.error = null;
  });
  builder.addCase(getDataResidencyLocations.fulfilled, (state, { payload }) => {
    state.locations.response = payload.response;
    state.locations.status = RS.IDLE;
    state.locations.error = null;
  });
  builder.addCase(getDataResidencyLocations.rejected, (state, action) => {
    state.locations.status = RS.ERROR;
    state.locations.error = action.payload;
  });
  builder.addCase(setUserDataSettings, (state, { payload }) => {
    state.userSettings.response = payload;
    state.userSettings.status = RS.IDLE;
    state.userSettings.error = null;
  });
  builder.addCase(getUserDataResidencySettings.pending, (state) => {
    state.userSettings.status = RS.RUNNING;
    state.userSettings.error = null;
  });
  builder.addCase(getUserDataResidencySettings.fulfilled, (state, { payload }) => {
    state.userSettings.response = payload.response;
    state.userSettings.status = RS.IDLE;
    state.userSettings.error = null;
  });
  builder.addCase(getUserDataResidencySettings.rejected, (state, action) => {
    state.userSettings.status = RS.ERROR;
    state.userSettings.error = action.payload;
  });
  builder.addCase(clearUpdateDataLocation, (state) => {
    state.updateDataLocation.response = null;
    state.updateDataLocation.status = RS.IDLE;
    state.updateDataLocation.error = null;
  });
  builder.addCase(updateDataLocation.pending, (state) => {
    state.updateDataLocation.response = null;
    state.updateDataLocation.status = RS.RUNNING;
    state.updateDataLocation.error = null;
  });
  builder.addCase(updateDataLocation.fulfilled, (state, { payload }) => {
    state.updateDataLocation.response = payload.response;
    state.updateDataLocation.status = RS.IDLE;
    state.updateDataLocation.error = null;
  });
  builder.addCase(updateDataLocation.rejected, (state, action) => {
    state.updateDataLocation.response = null;
    state.updateDataLocation.status = RS.ERROR;
    state.updateDataLocation.error = action.payload;
  });
  builder.addCase(getMigrationStatus.pending, (state) => {
    state.migrationStatus.response = null;
    state.migrationStatus.status = RS.RUNNING;
    state.migrationStatus.error = null;
  });
  builder.addCase(getMigrationStatus.fulfilled, (state, { payload }) => {
    state.migrationStatus.response = payload.response;
    state.migrationStatus.status = RS.IDLE;
    state.migrationStatus.error = null;
  });
  builder.addCase(getMigrationStatus.rejected, (state, action) => {
    state.migrationStatus.response = null;
    state.migrationStatus.status = RS.ERROR;
    state.migrationStatus.error = action.payload;
  });
};

export {
  updateDataLocation,
  dataResidencySlice,
  getMigrationStatus,
  initialDataResidencyState,
  getDataResidencyLocations,
  getUserDataResidencySettings,
};
