export const faxAllowedFileTypes = [
  ".pdf",
  ".PDF",
  ".tif",
  ".TIF",
  ".tiff",
  ".TIFF",
  ".jpg",
  ".JPG",
  ".jpeg",
  ".JPEG",
  ".png",
  ".PNG",
  ".doc",
  ".DOC",
  ".docx",
  ".DOCX",
  ".xls",
  ".XLS",
  ".xlsx",
  ".XLSX",
  ".txt",
  ".TXT",
  ".rtf",
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const faxAllowedMimeTypes = [
  // .txt
  "text/plain",
  // .pdf
  "application/pdf",
  // .tiff
  "image/tiff",
  // .png
  "image/png",
  "image/x-png",
  // .jpeg
  "image/jpeg",
  // .jpg
  "image/jpg",
  // .doc
  "application/msword",
  // .docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // .xls
  "application/vnd.ms-excel",
  // .xlsx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // .rtf
  "application/rtf",
  "text/rtf",
];

// List of allowed signatures used to double-check if the file is valid
// https://en.wikipedia.org/wiki/List_of_file_signatures
export const faxAllowedMagicBytes = [
  "25504446", // PDF
  "49492A00", // TIFF/TIF
  "4D4D002A", // TIFF/TIF
  "FFD8FFDB", // JPG/JPEG
  "FFD8FFE0", // JPG/JPEG
  "FFD8FFEE", // JPG/JPEG
  "FFD8FFE1", // JPG/JPEG
  "89504E47", // PNG
  "D0CF11E0", // DOC/XLS
  "504B0304", // DOCX/XLSX (also accepts zip)
  "504B0506", // DOCX/XLSX (also accepts zip)
  "504B0708", // DOCX/XLSX (also accepts zip)
  "7B5C7274", // RTF
];

export const contactsAllowedFileTypes = [
  ".xls",
  ".XLS",
  ".xlsx",
  ".XLSX",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const bundleAllowedFileTypes = [".jpg", ".jpeg", ".png", ".JPEG", ".PNG", ".pdf", ".PDF"];

export const bundleMimeTypes = "image/png, application/pdf, image/jpeg";

export const fileMimeTypeMap = {
  png: "image/png",
  jpg: "image/jpg",
  txt: "text/plain",
  jpeg: "image/jpeg",
  tiff: "image/tiff",
  pdf: "application/pdf",
  doc: "application/msword",
  xls: "application/vnd.ms-excel",
  ppt: "application/vnd.ms-powerpoint",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  rtf: "application/rtf",
};
