import { Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Typography } from "ui";
import useTitle from "../hooks/useTitle";
import notFoundImg from "../assets/images/404.svg";

function NotFound() {
  const { t } = useTranslation();
  useTitle(t("TITLES.RETRY"));

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <img src={notFoundImg} height={200} width="auto" alt="Not found" />
      <Box mt={5}>
        <Typography variant="body" style={{ fontSize: 30 }}>
          {t("COMMON.SERVER_ERROR")}
        </Typography>
      </Box>
    </Box>
  );
}

export default NotFound;
