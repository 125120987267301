import {
  alohi,
  BaseButton,
  BorderAnimation,
  Box,
  DefaultTheme,
  Icon,
  makeStyles,
  useBool,
} from "@alohi/kit";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { MouseEvent, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Typography } from "ui";

import { borderAnimationSettings } from "./helpers/animations";
import useTrialIncentive from "./hooks/useTrialIncentive";
import { CustomerMarketingDataFaxSignCrossSellBannerStatus } from "api/gql/generated/graphql";

interface IncentiveButtonProps {
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

function IncentiveButton({ className, onClick }: IncentiveButtonProps) {
  const { t } = useTranslation();

  const { hasPromo, promoStatus, trialInProgressDaysLeft } = useTrialIncentive();

  const [isHovering, isHoveringBool] = useBool(false);
  const [isClicking, isClickingBool] = useBool(false);

  const message = useMemo(() => {
    switch (promoStatus) {
      case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialAvailable:
        return t("INCENTIVE_SIGN.START_TRIAL");
      case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialInProgress:
        return (
          <Trans
            i18nKey="INCENTIVE_SIGN.TRIAL_REMAINING"
            components={{
              strong: <strong />,
            }}
            values={{
              count: trialInProgressDaysLeft,
              value_1: trialInProgressDaysLeft,
            }}
          />
        );

      case CustomerMarketingDataFaxSignCrossSellBannerStatus.TrialExpired:
        return t("INCENTIVE_SIGN.CLAIM_50_PERCENT", { value_1: 50 });
    }
  }, [promoStatus, t, trialInProgressDaysLeft]);

  const classes = useStyles({ isClicking });

  const idleAnimation = useMemo(() => borderAnimationSettings, []);

  const hoverAnimation = useMemo(
    () => ({
      ...borderAnimationSettings,
      width: 1.2,
      speed: 2,
    }),
    [],
  );

  if (!hasPromo) return null;
  return (
    <>
      <BaseButton
        draggable={false}
        className={clsx(classes.base, className)}
        onMouseEnter={isHoveringBool.setTrue}
        onMouseLeave={isHoveringBool.setFalse}
        onMouseDown={isClickingBool.setTrue}
        onMouseUp={isClickingBool.setFalse}
        onMouseOut={isClickingBool.setFalse}
        onClick={onClick}
      >
        <Box className={classes.incentiveButtonContent}>
          <Typography className={classes.incentiveButtonText}>{message}</Typography>
          <Icon className={classes.incentiveButtonIcon} icon={faLongArrowRight} />
        </Box>
        <BorderAnimation
          isHovering={isHovering}
          isActive={isClicking}
          borderRadius={17}
          idleAnimation={idleAnimation}
          hoverAnimation={hoverAnimation}
        />
      </BaseButton>
    </>
  );
}

const useStyles = makeStyles<DefaultTheme, { isClicking: boolean }>(({ spacing }) => ({
  base: ({ isClicking }) => ({
    padding: spacing(1, 2, 1, 2),
    background: isClicking ? alohi.neutral100 : alohi.neutral0,
  }),
  incentiveButtonContent: {
    display: "flex",
    alignItems: "center",
  },
  incentiveButtonText: {
    color: "black",
  },
  incentiveButtonIcon: {
    marginLeft: spacing(1),
    color: "black",
  },
}));

export default IncentiveButton;
