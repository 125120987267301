import PropTypes from "prop-types";
import { Modal } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback, useEffect, useMemo } from "react";

import useBool from "hooks/useBool";
import { clearAddressRequirements } from "stores/reducers/address.reducer";
import CreateAddressBundleModal from "views/CreateAddressBundle/CreateAddressBundleModal";
import AddNumberComponent from "components/Payment/AddNumber/AddNumberComponent";
import { clearAreaForCountry, clearCountryForPlan } from "stores/reducers/plans.reducer";
import {
  clearVerifyNumber,
  clearVerifyCustomNumber,
  clearAssignBundleToNumber,
} from "stores/reducers/verifications.reducer";
import { selectIsBundleRequiredToBeCreated } from "selectors/plan.selector";
import { selectSelectedNumberObject } from "selectors/verifications.selector";
import {
  clearFirstCorporateNumber,
  clearSecondCorporateNumber,
} from "stores/reducers/payment.corporate.reducer";
import { selectApprovedDocuments, selectPendingDocuments } from "selectors/address.selector";

function AddNumberModal({ numberType, handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const numberVariant = useSelector(selectSelectedNumberObject);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);
  const pendingDocuments = useSelector(selectPendingDocuments);
  const approvedDocuments = useSelector(selectApprovedDocuments);

  const [openNewBundle, openNewBundleHandler] = useBool(false);

  const clearAddNumber = useCallback(() => {
    dispatch(clearVerifyNumber());
    dispatch(clearCountryForPlan());
    dispatch(clearAreaForCountry());
    dispatch(clearVerifyCustomNumber());
    dispatch(clearAssignBundleToNumber());
    dispatch(clearAddressRequirements());
  }, [dispatch]);

  useEffect(() => {
    if (numberType === "firstNumber") {
      dispatch(clearFirstCorporateNumber());
    }
    if (numberType === "secondNumber") {
      dispatch(clearSecondCorporateNumber());
    }
  }, [dispatch, numberType]);

  useEffect(() => {
    clearAddNumber();
    return () => clearAddNumber();
  }, [clearAddNumber, dispatch]);

  const confirmHandle = useMemo(() => {
    return {
      onConfirm: isBundleRequiredToBeCreated
        ? openNewBundleHandler.setTrue
        : numberVariant
        ? () => handleClosure(numberVariant)
        : undefined,
    };
  }, [handleClosure, isBundleRequiredToBeCreated, numberVariant, openNewBundleHandler.setTrue]);

  const hasOnlyPendingDocuments = approvedDocuments?.length === 0 && pendingDocuments?.length >= 1;

  const isConfirmDisabled = hasOnlyPendingDocuments;

  return (
    <>
      <Modal
        {...confirmHandle}
        hasCloseIcon={false}
        data-cy={"AddNumberModal"}
        cancelTitle={t("COMMON.BACK")}
        onClose={() => handleClosure(null)}
        onCancel={() => handleClosure(null)}
        isConfirmDisabled={isConfirmDisabled}
        title={t("CHANGE_PLAN.CHOOSE_NUMBER")}
        confirmTitle={
          isBundleRequiredToBeCreated ? t("CHANGE_PLAN.PROVIDE_ID") : t("ADD_NUMBER.PROCEED")
        }
      >
        <AddNumberComponent actionType="number" />
      </Modal>
      {openNewBundle ? (
        <CreateAddressBundleModal handleClosure={openNewBundleHandler.setFalse} />
      ) : null}
    </>
  );
}

AddNumberModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  numberType: PropTypes.oneOf(["firstNumber", "secondNumber"]).isRequired,
};

export default memo(AddNumberModal);
