import { useRef, useState } from "react";
import { Box, useOnMount } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { Typography } from "ui";
import database from "helpers/database";
import { fileToFaxFile } from "helpers/file";
import Loading from "components/Loading/Loading";
import { FaxFile } from "views/SendFax/contexts/store";
import { IFRAME_UPLOAD_FILES } from "views/InlineFrames/UploaderView/Uploader";
import InitSendFaxProviders from "./InitSendFaxProviders";

function InlineFrameToFax() {
  const { t } = useTranslation();
  const dbRef = useRef(database);

  const [files, setFiles] = useState<FaxFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useOnMount(async () => {
    try {
      setIsLoading(true);
      const database = dbRef.current;

      const filesStore = await database.getFile<FileList, typeof IFRAME_UPLOAD_FILES>(
        IFRAME_UPLOAD_FILES,
      );
      const files = filesStore.value;
      const sendFaxFiles: FaxFile[] = [];

      for await (const file of Array.from(files)) {
        const sendFaxFile = await fileToFaxFile(file);
        sendFaxFiles.push(sendFaxFile);
      }

      setFiles(sendFaxFiles);
    } catch {
      // Nothing to do with the error
    } finally {
      setIsLoading(false);
    }
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body">{t("FAXES.DOWNLOADING_FILES")}</Typography>
        <Loading />
      </Box>
    );
  }

  return <InitSendFaxProviders files={files} initiatedFrom={{ from: "upload-iframe" }} />;
}

export default InlineFrameToFax;
