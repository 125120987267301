import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Box, useSnackbar } from "@alohi/kit";
import { useDispatch, useSelector } from "react-redux";
import { useState, useCallback, useEffect, useMemo } from "react";

import useBool from "hooks/useBool";
import useCart from "hooks/useCart";
import usePrevious from "hooks/usePrevious";
import ChargeError from "views/Transactions/ChargeError";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import { getCurrentPlan } from "stores/reducers/plans.reducer";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import { getAllNumbers } from "stores/reducers/numbers.reducer";
import PurchasedNumbersSuccess from "views/Transactions/PurchasedNumbersSuccess";
import { selectApprovedDocuments, selectPendingDocuments } from "selectors/address.selector";
import { corporateAsyncActions } from "stores/reducers/corporate.reducer";
import NewBundleRedirect from "components/AddressBundle/NewBundleRedirect";
import {
  selectIsBuyingBulkNumbers,
  selectSelectedNumberCountryRequirePhoneType,
  selectSelectedNumberItemType,
} from "selectors/verifications.selector";
import { selectIsAddCorporateNumberDisabled } from "selectors/common.selector";
import CreateAddressBundleModal from "views/CreateAddressBundle/CreateAddressBundleModal";
import AddNumberComponent from "components/Payment/AddNumber/AddNumberComponent";
import { clearCart, getCartStatus } from "stores/reducers/payment.cart.reducer";
import { setCustomPaymentAmount } from "stores/reducers/payment.amounts.reducer";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import { getCustomerInfo, getAccountDetails } from "stores/reducers/account.reducer";
import ChangeOrAddNumberFooterInfo from "components/Payment/ChangeNumber/ChangeOrAddNumberFooterInfo";
import {
  selectBasePriceForCorporateNumber,
  selectIsBundleRequiredToBeCreated,
} from "selectors/plan.selector";
import {
  selectCartError,
  selectChargeAmount,
  selectCurrentCart,
  selectIsChargeAmountError,
  selectIsChargeAmountLoading,
  selectPaymentDepositError,
} from "selectors/payment.selector";
import useForceRemount from "hooks/useForceRemount";
import useOnMountCondition from "hooks/useOnMountCondition";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

import { getAssignedNumbers } from "../../stores/reducers/numbers.reducer";

function AddCorporateNumberModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { sendCustomEvent } = useGoogleTagManager();

  const [key, forceRemount] = useForceRemount();

  const cartError = useSelector(selectCartError);
  const isChargeSuccess = useSelector(selectChargeAmount);
  const isChargeError = useSelector(selectIsChargeAmountError);
  const isPaymentDepositError = useSelector(selectPaymentDepositError);
  const currentCart = useSelector(selectCurrentCart);
  const approvedDocuments = useSelector(selectApprovedDocuments);
  const pendingDocuments = useSelector(selectPendingDocuments);
  const isProceedLoading = useSelector(selectIsChargeAmountLoading);
  const isBuyingBulkNumbers = useSelector(selectIsBuyingBulkNumbers);
  const basePriceForCorporateUsers = useSelector(selectBasePriceForCorporateNumber);
  const isBundleRequiredToBeCreated = useSelector(selectIsBundleRequiredToBeCreated);
  const isAddCorporateNumberDisabled = useSelector(selectIsAddCorporateNumberDisabled);
  const isPhoneTypeRequired = useSelector(selectSelectedNumberCountryRequirePhoneType);
  const selectedNumberType = useSelector(selectSelectedNumberItemType);

  const prevCartError = usePrevious(cartError);
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [openChargeError, setOpenChargeError] = useState(false);
  const [openChargeSuccess, setOpenChargeSuccess] = useState(false);

  const cleanReducers = useCleanPlansReducers();
  const [openNewBundle, openNewBundleHandler] = useBool(false);
  const [{ creditNeeded, canChargeCart }, { chargeCart }] = useCart(true);

  const handleOnConfirm = useCallback(() => {
    if (isBundleRequiredToBeCreated) {
      openNewBundleHandler.setTrue();
      return;
    }
    if (canChargeCart) {
      chargeCart(isBuyingBulkNumbers);
    } else {
      dispatch(setCustomPaymentAmount(creditNeeded));
      setShowAddCredit(true);
    }
  }, [
    dispatch,
    chargeCart,
    creditNeeded,
    canChargeCart,
    isBuyingBulkNumbers,
    openNewBundleHandler,
    isBundleRequiredToBeCreated,
  ]);

  const handleSuccess = useCallback(() => {
    dispatch(getCurrentPlan());
    dispatch(getCurrentCredit());
    dispatch(getAllNumbers());
    dispatch(getAssignedNumbers());
    dispatch(getAccountDetails());
    dispatch(getCustomerInfo());
    dispatch(corporateAsyncActions.getHierarchy());
    dispatch(getCreditDetails());
    dispatch(getCartStatus());
    cleanReducers();
    handleClosure();
  }, [dispatch, cleanReducers, handleClosure]);

  const handleOnClose = useCallback(() => {
    cleanReducers();
    handleClosure(null);
  }, [cleanReducers, handleClosure]);

  const handleAddCredit = useCallback(
    (isSuccess) => {
      if (isSuccess) {
        chargeCart(isBuyingBulkNumbers);
      }
      setShowAddCredit(false);
    },
    [chargeCart, isBuyingBulkNumbers],
  );

  useEffect(() => {
    if (cartError) {
      enqueueSnackbar(cartError, { variant: "error" });
      dispatch(clearCart());
    }
  }, [cartError, dispatch, t, enqueueSnackbar]);

  useEffect(() => {
    if (isChargeSuccess) {
      setOpenChargeSuccess(true);
    } else if (isChargeError) {
      setOpenChargeError(true);
    }
  }, [isChargeError, isChargeSuccess]);

  useOnMountCondition(() => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartInitalization,
      page: "add_numbers",
    });
  }, true);

  useOnMountCondition(() => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.cartPurchaseSuccess,
      page: "add_numbers",
      cart: {
        faxExtraNumbers: {
          totalCount: currentCart.items.random.length + currentCart.items.custom.length,
          customCount: currentCart.items.custom.length,
        },
        totalPrice: currentCart.total_amount,
      },
    });
  }, Boolean(isChargeSuccess));

  useOnMountCondition(
    () => {
      if (!currentCart) {
        return;
      }
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.cartPurchaseFailed,
        page: "add_numbers",
        cart: {
          faxExtraNumbers: {
            totalCount: currentCart.items.random.length + currentCart.items.custom.length,
            customCount: currentCart.items.custom.length,
          },
          totalPrice: currentCart.total_amount,
        },
      });
    },
    Boolean(isChargeError) || Boolean(isPaymentDepositError),
  );

  const hasNoValidBundleAvailable = useMemo(() => {
    // If the number does not require a specific type: no invalidity
    if (!isPhoneTypeRequired) return false;
    // If no approved bundle yet: no invalidity. The user will create one by proceeding
    if (approvedDocuments?.length === 0) return false;
    // Check if an approved bundle matches the required number type
    const hasValidBundleApproved = Boolean(
      approvedDocuments?.find((document) => document.number_type === selectedNumberType),
    );
    return !hasValidBundleApproved;
  }, [approvedDocuments, isPhoneTypeRequired, selectedNumberType]);

  if (openChargeSuccess) {
    return (
      <PurchasedNumbersSuccess
        handleClosure={handleSuccess}
        title={
          isBuyingBulkNumbers
            ? t("ADD_NUMBER.ADD_CORPORATE_NUMBER_TITLE")
            : basePriceForCorporateUsers
        }
        description={
          isBuyingBulkNumbers
            ? t("ADD_NUMBER.CHARGE_IN_BACKGROUND_STARTED")
            : t("ADD_NUMBER.YOUR_NUMBER_SUCCESSFULLY_ADDED")
        }
      />
    );
  }

  const hasOnlyPendingDocuments = approvedDocuments?.length === 0 && pendingDocuments?.length >= 1;
  const isConfirmDisabled =
    isAddCorporateNumberDisabled ||
    !!cartError ||
    !!prevCartError ||
    hasOnlyPendingDocuments ||
    hasNoValidBundleAvailable;

  return (
    <Box key={key}>
      <Modal
        onCancel={handleOnClose}
        onConfirm={handleOnConfirm}
        title={basePriceForCorporateUsers}
        isModalDisabled={isProceedLoading}
        isConfirmLoading={isProceedLoading}
        isConfirmDisabled={isConfirmDisabled}
        confirmTitle={
          isBundleRequiredToBeCreated ? t("CHANGE_PLAN.PROVIDE_ID") : t("ADD_NUMBER.PROCEED")
        }
      >
        <AddNumberComponent actionType="corporate_number" />
        <ChangeOrAddNumberFooterInfo />
      </Modal>

      {showAddCredit ? (
        <AddCreditModal
          withSuccessModal={false}
          reason="corporate_number"
          handleClosure={handleAddCredit}
        />
      ) : null}

      {openChargeError ? (
        <ChargeError
          handleClosure={(shouldCloseModal) => {
            if (shouldCloseModal) {
              forceRemount();
              setOpenChargeError(false);
            } else {
              handleOnClose();
            }
          }}
        />
      ) : null}

      {openNewBundle ? (
        <CreateAddressBundleModal handleClosure={openNewBundleHandler.setFalse} />
      ) : null}

      <NewBundleRedirect handleClosure={handleClosure} />
    </Box>
  );
}

AddCorporateNumberModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default AddCorporateNumberModal;
