import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { FormLabel, FormHelperText, useSnackbar } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { Grid, FormControl, useTheme, makeStyles } from "ui";

function FormRowMultiSelectTextInput({
  id,
  label,
  helperText,
  error,
  unique,
  uniqueInsensitive,
  validator,
  maxCount,
  initialValues,
  maxCountErrorMessage,
  uniqueErrorMessage,
  validationErrorMessage,
  onChange,
  onBlur,
  placeholder,
  labelGridWidth = 4,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, input] = useInput("", validator);
  const [values, setValues] = useState(initialValues || []);

  const add = (inputValue) => {
    input.touch();
    if (validator && !validator(inputValue)) {
      validationErrorMessage && enqueueSnackbar(validationErrorMessage, { variant: "error" });
      return;
    }
    if (unique && values.some(({ value }) => value === inputValue)) {
      uniqueErrorMessage && enqueueSnackbar(uniqueErrorMessage, { variant: "error" });
      return;
    }
    if (
      uniqueInsensitive &&
      values.some(({ value }) => value.toLowerCase() === inputValue.toLowerCase())
    ) {
      uniqueErrorMessage && enqueueSnackbar(uniqueErrorMessage, { variant: "error" });
      return;
    }
    if (!!maxCount && !!values.length && maxCount <= values.length) {
      maxCountErrorMessage && enqueueSnackbar(maxCountErrorMessage, { variant: "error" });
      return;
    }
    input.setValue("");
    input.unTouch();
    const newValues = [...values, createOption(inputValue)];
    setValues(newValues);
    onChange(newValues);
  };

  const handleKeyDown = (event) => {
    if (!input.hasValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        add(inputValue);
        event.preventDefault();
        break;
      default:
    }
  };

  const handleBlur = (event) => {
    event.preventDefault();
    setIsFocused(false);
    onBlur && onBlur(event);
    if (input.hasValue) {
      add(inputValue);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleInputChange = (inputValue) => {
    input.setValue(inputValue);
    !inputValue?.length && input.unTouch();
  };

  const handleChange = (value) => {
    setValues(value || []);
    onChange(value || []);
  };

  const customStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        borderColor: error ? theme.alohi.red : theme.alohi.lightGray,

        ...(state.isFocused && {
          borderColor: error ? theme.alohi.red : theme.alohi.blue,
          boxShadow: `0 0 0 1px ${error ? theme.alohi.red : theme.alohi.blue}`,
        }),

        "&:hover": {
          borderColor: error ? theme.alohi.red : theme.alohi.blue,
          boxShadow: `0 0 0 1px ${error ? theme.alohi.red : theme.alohi.blue}`,
        },
      }),
      input: (provided) => ({
        ...provided,
        ...(input.showsError && { color: theme.alohi.red }),
      }),
    }),
    [error, input.showsError, theme.alohi],
  );

  return (
    <FormControl fullWidth>
      <Grid container alignItems="center">
        {!!label && (
          <Grid
            item
            {...(labelGridWidth && { xs: labelGridWidth })}
            className={classes.labelContainer}
          >
            <FormLabel htmlFor={id} error={error} className={classes.label} focused={isFocused}>
              {label}
            </FormLabel>
          </Grid>
        )}
        <Grid item xs={!label ? 12 : 12 - labelGridWidth || 12}>
          <div className={classes.inputContainer}>
            <CreatableSelect
              inputId={id}
              isMulti
              isClearable
              value={values}
              menuIsOpen={false}
              onBlur={handleBlur}
              inputValue={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onInputChange={handleInputChange}
              components={{ DropdownIndicator: null }}
              styles={customStyles}
              aria-describedby={`${id}-helper-text`}
              placeholder={placeholder}
              onFocus={handleFocus}
            />
            <FormHelperText id={`${id}-helper-text`} error={error}>
              {helperText || " "}
            </FormHelperText>
          </div>
        </Grid>
      </Grid>
    </FormControl>
  );
}

FormRowMultiSelectTextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  unique: PropTypes.bool,
  uniqueInsensitive: PropTypes.bool,
  maxCount: PropTypes.number,
  initialValues: PropTypes.array,
  validator: PropTypes.func,
  maxCountErrorMessage: PropTypes.string,
  uniqueErrorMessage: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  labelGridWidth: PropTypes.number,
};

const createOption = (label) => ({
  label,
  value: label,
});

const useStyles = makeStyles(({ alohi, spacing }) => ({
  labelContainer: {
    textAlign: "right",
  },
  label: {
    color: alohi.gray,
    marginRight: spacing(2),
    fontWeight: "bold",
    fontSize: 14,
  },
  inputContainer: {
    marginTop: 19,
  },
}));

export default FormRowMultiSelectTextInput;
