import {
  Box,
  Button,
  Container,
  makeStyles,
  Panel,
  TextButton,
  Tooltip,
  Typography,
  useSnackbar,
} from "@alohi/kit";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";

import LinearInfiniteProgress from "components/Core/LinearInfiniteProgress/LinearInfiniteProgress";
import Loading from "components/Loading/Loading";
import { formatDefaultDate } from "helpers/date";
import useInterval from "hooks/useInterval";
import useTitle from "hooks/useTitle";
import { filterQueryParams, websiteUrls } from "routes/urls";
import {
  selectExportContactsInfo,
  selectExportFaxesInfo,
  selectIsExportDataForbidden,
  selectIsExportPageLoading,
  selectIsStartContactsExportSuccess,
  selectIsStartFaxesExportSuccess,
  selectStartContactsExportError,
  selectStartFaxesExportError,
} from "selectors/dataExport.selector";
import {
  clearStartContactsExport,
  clearStartFaxesExport,
  downloadExport,
  getExportStatus,
  startContactsExport,
  startFaxesExport,
} from "stores/reducers/dataExport.reducer";

function DataExportRequest() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const reroutedQueryParams = filterQueryParams({
    filter: ["_gl"],
    currentParams: queryParams,
  }).toString();

  useTitle(t("DATA_EXPORT.TITLE"));

  const isPageLoading = useSelector(selectIsExportPageLoading);
  const isExportDataForbidden = useSelector(selectIsExportDataForbidden);

  const isStartExportFaxesSuccess = useSelector(selectIsStartFaxesExportSuccess);
  const isStartExportContactsSuccess = useSelector(selectIsStartContactsExportSuccess);
  const startFaxesExportError = useSelector(selectStartFaxesExportError);
  const startContactsExportError = useSelector(selectStartContactsExportError);

  const exportFaxesInfo = useSelector(selectExportFaxesInfo);
  const exportContactsInfo = useSelector(selectExportContactsInfo);

  const [isExportFaxesButtonLoading, setIsExportFaxesButtonLoading] = useState(false);
  const [isExportContactsButtonLoading, setIsExportContactsButtonLoading] = useState(false);

  const refreshExportStatus = useCallback(() => {
    if (exportFaxesInfo.status === "IN_PROGRESS" || exportContactsInfo.status === "IN_PROGRESS") {
      dispatch(getExportStatus());
    }
  }, [dispatch, exportContactsInfo.status, exportFaxesInfo.status]);

  useEffect(() => {
    dispatch(getExportStatus());
  }, [dispatch]);

  useInterval(refreshExportStatus, 10000);

  useEffect(() => {
    if (isStartExportFaxesSuccess) {
      (async () => {
        await dispatch(getExportStatus());
        dispatch(clearStartFaxesExport());
        setIsExportFaxesButtonLoading(false);
      })();
    }
    if (isStartExportContactsSuccess) {
      (async () => {
        await dispatch(getExportStatus());
        dispatch(clearStartContactsExport());
        setIsExportContactsButtonLoading(false);
      })();
    }
  }, [dispatch, isStartExportContactsSuccess, isStartExportFaxesSuccess]);

  useEffect(() => {
    if (startFaxesExportError) {
      enqueueSnackbar(startFaxesExportError, { variant: "error" });
      dispatch(clearStartFaxesExport());
    }
    if (startContactsExportError) {
      enqueueSnackbar(startContactsExportError, { variant: "error" });
      dispatch(clearStartContactsExport());
    }
  }, [dispatch, enqueueSnackbar, startContactsExportError, startFaxesExportError]);

  if (isPageLoading) {
    return (
      <div className={classes.loader}>
        <Loading />
      </div>
    );
  }

  if (isExportDataForbidden) {
    return <Redirect to={{ pathname: `/faxes`, search: reroutedQueryParams }} />;
  }

  return (
    <Container maxWidth="sm">
      <Box mb={4}>
        <Typography>
          <Trans
            i18nKey={"DATA_EXPORT.PRIVACY_STATEMENT"}
            components={{
              anchor: (
                <a href={websiteUrls.privacyPolicy} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              ),
            }}
          />
        </Typography>
      </Box>

      <Panel id="data-export" headerTitle={t("DATA_EXPORT.ACCESS_YOUR_INFORMATION")}>
        <Panel.Front>
          <Panel.Row>
            <Panel.Cell variant="title" style={{ flexBasis: "25%" }}>
              {t("DATA_EXPORT.EXPORT_FAXES")}
            </Panel.Cell>
            <Panel.Cell variant="data" className={classes.dataCell}>
              {exportFaxesInfo.status === "IN_PROGRESS" && (
                <Tooltip title={t("DATA_EXPORT.THIS_CAN_TAKE_TIME")}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography>{t("DATA_EXPORT.IN_PROGRESS")}</Typography>
                    <Box ml={1}>
                      <LinearInfiniteProgress
                        dotCount={3}
                        diameter={4}
                        wrapperStyle={{ margin: 0 }}
                      />
                    </Box>
                  </Box>
                </Tooltip>
              )}
              {exportFaxesInfo.status === "READY" && (
                <Box sx={{ flexFlow: "wrap" }}>
                  {exportFaxesInfo?.files?.map((fileName, index) => {
                    const downloadName =
                      t("COMMON.FAXES") +
                      ((exportFaxesInfo?.files?.length ?? 0) > 1
                        ? ` - ${t("DATA_EXPORT.PART")} ${index + 1}`
                        : "");

                    return (
                      <Box key={fileName}>
                        <TextButton
                          sx={{ mr: 1 }}
                          onClick={() => dispatch(downloadExport({ fileName, downloadName }))}
                        >
                          {`${t("COMMON.DOWNLOAD")} ${downloadName}`}
                        </TextButton>
                      </Box>
                    );
                  })}
                </Box>
              )}

              {exportFaxesInfo.status === "READY" && (
                <>
                  ({t("DATA_EXPORT.CREATED")}: {formatDefaultDate(exportFaxesInfo.date)})
                </>
              )}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              {exportFaxesInfo.status === "EMPTY" && (
                <Button
                  variant="white"
                  onClick={() => {
                    setIsExportFaxesButtonLoading(true);
                    dispatch(startFaxesExport());
                  }}
                  isLoading={isExportFaxesButtonLoading}
                >
                  {t("DATA_EXPORT.REQUEST_EXPORT")}
                </Button>
              )}
              {exportFaxesInfo.status === "READY" && (
                <Button
                  variant="white"
                  onClick={() => {
                    setIsExportFaxesButtonLoading(true);
                    dispatch(startFaxesExport());
                  }}
                  isLoading={isExportFaxesButtonLoading}
                >
                  {t("DATA_EXPORT.REFRESH_EXPORT")}
                </Button>
              )}
            </Panel.Cell>
          </Panel.Row>
          <Panel.Row>
            <Panel.Cell variant="title" style={{ flexBasis: "25%" }}>
              {t("DATA_EXPORT.EXPORT_CONTACTS")}
            </Panel.Cell>
            <Panel.Cell variant="data" className={classes.dataCell}>
              {exportContactsInfo.status === "IN_PROGRESS" && (
                <Tooltip title={t("DATA_EXPORT.THIS_CAN_TAKE_TIME")}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography>{t("DATA_EXPORT.IN_PROGRESS")}</Typography>
                    <Box ml={1}>
                      <LinearInfiniteProgress
                        dotCount={3}
                        diameter={4}
                        wrapperStyle={{ margin: 0 }}
                      />
                    </Box>
                  </Box>
                </Tooltip>
              )}
              {exportContactsInfo.status === "READY" &&
                exportContactsInfo?.files?.map((fileName, index) => {
                  const downloadName =
                    t("CONTACTS.CONTACTS") +
                    ((exportContactsInfo?.files?.length ?? 0) > 1
                      ? ` - ${t("DATA_EXPORT.PART")} ${index + 1}`
                      : "");

                  return (
                    <Box key={fileName}>
                      <TextButton
                        sx={{ mr: 1 }}
                        onClick={() => dispatch(downloadExport({ fileName, downloadName }))}
                      >
                        {`${t("COMMON.DOWNLOAD")} ${downloadName}`}
                      </TextButton>
                    </Box>
                  );
                })}
              {exportContactsInfo.status === "READY" && (
                <>
                  ({t("DATA_EXPORT.CREATED")}: {formatDefaultDate(exportContactsInfo.date)})
                </>
              )}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              {exportContactsInfo.status === "EMPTY" && (
                <Button
                  variant="white"
                  onClick={() => {
                    setIsExportContactsButtonLoading(true);
                    dispatch(startContactsExport());
                  }}
                  isLoading={isExportContactsButtonLoading}
                >
                  {t("DATA_EXPORT.REQUEST_EXPORT")}
                </Button>
              )}
              {exportContactsInfo.status === "READY" && (
                <Button
                  variant="white"
                  onClick={() => {
                    setIsExportContactsButtonLoading(true);
                    dispatch(startContactsExport());
                  }}
                  isLoading={isExportContactsButtonLoading}
                >
                  {t("DATA_EXPORT.REFRESH_EXPORT")}
                </Button>
              )}
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>
      </Panel>
    </Container>
  );
}

const useStyles = makeStyles(() => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  dataCell: {
    height: "unset",
  },
}));

export default DataExportRequest;
