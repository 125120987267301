import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Avatar, Typography, Grid, useTheme, Button, Modal, Box, useSnackbar } from "@alohi/kit";

import { fetchWithRefresh } from "helpers/fetch";
import DragAndDropOverlay from "components/DragAndDrop/DragAndDropOverlay";
import { clearUploadProfileImage, uploadProfileImage } from "stores/reducers/storage.reducer";
import {
  selectUploadedProfileImage,
  selectIsUploadedProfileError,
  selectIsUploadedProfileRunning,
} from "selectors/storage.selector";
import "react-image-crop/dist/ReactCrop.css";

// This modal is only uploading data to storage
// The parent component is then handling the new image (i.e. saving as Profile picture, company picture...)
// Warning: initialImage can be a URL or base64
function UploadProfileImageModal({ initialImage, handleClosure }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isUploadError = useSelector(selectIsUploadedProfileError);
  const isUploadRunning = useSelector(selectIsUploadedProfileRunning);
  const uploadedImageUrl = useSelector(selectUploadedProfileImage);

  const [sourceImage, setSourceImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [newImageFile, setNewImageFile] = useState(null);
  const [htmlImageElement, setHtmlImageElement] = useState(null);
  const [mime, setMime] = useState(null);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    aspect: 1,
    width: 100,
    height: 100,
  });

  const revertChange = useCallback(() => {
    handleClosure({ url: "", base64: sourceImage });
  }, [handleClosure, sourceImage]);

  const handleConfirm = useCallback(() => {
    dispatch(uploadProfileImage(newImageFile));
  }, [dispatch, newImageFile]);

  const getCroppedImage = useCallback(
    (htmlImageElement, crop) => {
      const canvas = document.createElement("canvas");
      const scaleX = htmlImageElement.naturalWidth / htmlImageElement.width;
      const scaleY = htmlImageElement.naturalHeight / htmlImageElement.height;
      canvas.width = 200; // crop.width;
      canvas.height = 200; // crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        htmlImageElement,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        200, //crop.width,
        200, //crop.height
      );
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          blob.name = "file";
          resolve({ file: blob, base64: canvas.toDataURL(mime, 1.0) });
        }, mime);
      });
    },
    [mime],
  );

  const onComplete = useCallback(
    async (crop) => {
      if (!htmlImageElement || !crop.width || !crop.height) return;
      const { file, base64 } = await getCroppedImage(htmlImageElement, crop);
      setNewImageFile(file);
      setNewImage(base64);
    },
    [getCroppedImage, htmlImageElement],
  );

  const onImageLoaded = useCallback(
    async (image) => {
      setHtmlImageElement(image);
      const { file, base64 } = await getCroppedImage(image, crop);
      setNewImageFile(file);
      setNewImage(base64);
    },
    [crop, getCroppedImage],
  );

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSourceImage(reader.result);
      });
      setMime(acceptedFiles[0].type);
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  useEffect(() => {
    if (uploadedImageUrl) {
      handleClosure({ url: uploadedImageUrl, base64: newImage });
      dispatch(clearUploadProfileImage());
    }
  }, [handleClosure, newImage, dispatch, uploadedImageUrl]);

  useEffect(() => {
    if (isUploadError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearUploadProfileImage());
      revertChange();
    }
  }, [dispatch, isUploadError, revertChange, enqueueSnackbar, t]);

  useEffect(() => {
    // Fetch if the initialImage prop is a URL (if it's base64, it "fetches" locally)
    (async () => {
      if (initialImage && sourceImage === null) {
        const blob = await fetchWithRefresh(initialImage, {
          cache: "force-cache",
        });
        const localUrl = URL.createObjectURL(blob);
        setSourceImage(localUrl);
        return () => {
          URL.revokeObjectURL(localUrl);
        };
      }
    })();
  }, [initialImage, sourceImage]);

  return (
    <Modal
      onConfirm={handleConfirm}
      onPressEscape={revertChange}
      isConfirmDisabled={!newImage}
      title={t("CROP_IMAGE.MOVE_SCALE")}
      isConfirmLoading={isUploadRunning}
      customFooterInfo={
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Button variant="gray" onClick={revertChange}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button variant="blue" onClick={open}>
            {t("COMMON.UPLOAD")}
          </Button>
        </Box>
      }
    >
      <Grid container justifyContent="center" {...getRootProps()}>
        <Grid item xs={6} container justifyContent="center" direction="column">
          <input data-cy="UploadProfileImageModal" {...getInputProps()} accept={"image/*"} />
          <Box pt={2} pl={3}>
            <Typography type="panelCellTitle">{t("CROP_IMAGE.ADJUST_PHOTO")}</Typography>
            <Typography type="body">{t("CROP_IMAGE.DRAG_ELEMENT")}</Typography>
            <Box width="100%" display="flex" justifyContent="center" py={4} pr={3}>
              <Box
                width={200}
                height={200}
                display="flex"
                cursor="pointer"
                alignItems="center"
                position="relative"
                justifyContent="center"
                bgcolor={theme.alohi.lighterGray}
                onClick={!sourceImage ? open : undefined}
              >
                {isDragActive ? <DragAndDropOverlay /> : null}
                {!!sourceImage ? (
                  <ReactCrop
                    crop={crop}
                    circularCrop
                    keepSelection
                    src={sourceImage}
                    onComplete={onComplete}
                    onImageLoaded={onImageLoaded}
                    onChange={(crop) => setCrop(crop)}
                    imageStyle={{ maxHeight: 200, maxWidth: 200 }}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          justifyContent="center"
          style={{ borderLeft: `1px solid ${theme.alohi.lighterGray}` }}
        >
          <Box pt={2} px={3}>
            <Typography type="panelCellTitle">{t("CROP_IMAGE.PREVIEW")}</Typography>
            <Typography type="body">{t("CROP_IMAGE.HOW_APPEAR")}</Typography>
            <Box width="100%" display="flex" justifyContent="center" py={4}>
              <Avatar alt="Crop" src={newImage} style={{ width: "200px", height: "200px" }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
}

UploadProfileImageModal.propTypes = {
  initialImage: PropTypes.string, // Warning: Can be an URL or base64 image
  handleClosure: PropTypes.func.isRequired,
};

export default UploadProfileImageModal;
