import PropTypes from "prop-types";
import { Modal, Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { selectIsVerifyCustomNumberLoading } from "selectors/verifications.selector";
import AddCustomNumberComponent from "components/Payment/AddNumber/AddCustomNumberComponent";

function AddCustomNumberModal({ handleClosure, actionType }) {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsVerifyCustomNumberLoading);

  return (
    <Modal
      scroll="paper"
      hasCloseIcon={false}
      isModalDisabled={isLoading}
      cancelTitle={t("COMMON.BACK")}
      onCancel={() => handleClosure(null)}
      title={t("CHANGE_PLAN.CUSTOM_FAX_NUMBER_TITLE")}
    >
      <Box py={3}>
        <AddCustomNumberComponent handleClosure={handleClosure} actionType={actionType} />
      </Box>
    </Modal>
  );
}

AddCustomNumberModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  actionType: PropTypes.oneOf(["number", "number_replacement", "corporate_number"]).isRequired,
};

export default AddCustomNumberModal;
