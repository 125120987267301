import { useTranslation } from "react-i18next";
import { Dispatch, lazy, SetStateAction, Suspense, useCallback, useMemo } from "react";

import { useAppSelector } from "stores/store";
import { RichText } from "views/SendFax/contexts/store";
import { useSendFaxHelpers } from "views/SendFax/contexts/helpers";
import SharedCoverSheetModal from "views/SendFax/modals/SharedCoverSheetModal";
import GalleryCoverSheetModal from "views/SendFax/modals/GalleryCoverSheetModal";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import RequireUpgradeToPremiumOrHigher from "views/RequireUpgrade/RequireUpgradeToPremiumOrHigher";
import DefaultCoverSheetModal from "views/SendFax/modals/DefaultCoverSheetModal/DefaultCoverSheetModal";
import {
  selectCanIUseGalleryCoverSheet,
  selectCanIUseSharedCoverSheet,
} from "selectors/ability.selector";

const LazyAddTextModal = lazy(() => import("views/AddText/AddTextModal"));

interface PageOptionsModalsProps {
  selection: string;
  setSelection: Dispatch<SetStateAction<string | null>>;
}

function PageOptionsModals({ setSelection, selection }: PageOptionsModalsProps) {
  const { t } = useTranslation();
  const { addFiles } = useSendFaxHelpers();

  const canIUseSharedCoverSheet = useAppSelector(selectCanIUseSharedCoverSheet);
  const canIUseGalleryCoverSheet = useAppSelector(selectCanIUseGalleryCoverSheet);

  const handleCloseModal = useCallback(() => setSelection(null), [setSelection]);

  const handleNewText = useCallback(
    (newFile: RichText | null) => {
      handleCloseModal();

      if (newFile) {
        addFiles([newFile]);
      }
    },
    [addFiles, handleCloseModal],
  );

  const modals = useMemo(() => {
    switch (selection) {
      case t("SENT_FAX.RICH_TEXT_EDITOR"):
        return (
          <Suspense fallback={""}>
            <LazyAddTextModal handleClosure={handleNewText} />
          </Suspense>
        );
      case t("SENT_FAX.DEFAULT_COVER_SHEET"):
        return <DefaultCoverSheetModal handleClosure={handleCloseModal} />;
      case t("COVER_SHEET.GALLERY"):
        if (canIUseGalleryCoverSheet) {
          return <GalleryCoverSheetModal handleClosure={handleCloseModal} />;
        }

        return (
          <RequireUpgradeToPremiumOrHigher
            title={t("COVER_SHEET.GALLERY")}
            featureName={t("COVER_SHEET.GALLERY")}
            handleClosure={handleCloseModal}
          />
        );

      case t("SENT_FAX.SHARED_COVER_SHEET"):
        if (canIUseSharedCoverSheet) {
          return <SharedCoverSheetModal handleClosure={handleCloseModal} />;
        }

        return (
          <RequireUpgradeToEnterprise
            handleClosure={handleCloseModal}
            title={t("SENT_FAX.SHARED_COVER_SHEET")}
            featureName={t("SENT_FAX.SHARED_COVER_SHEET")}
          />
        );
      default:
        return null;
    }
  }, [
    t,
    selection,
    handleNewText,
    handleCloseModal,
    canIUseSharedCoverSheet,
    canIUseGalleryCoverSheet,
  ]);

  return modals;
}

export default PageOptionsModals;
