import { FAX_FILE, RICH_TEXT } from "views/SendFax/contexts/store";
import CsvIcon from "assets/images/file-icons/csv.svg?react";
import DocIcon from "assets/images/file-icons/doc.svg?react";
import ZipIcon from "assets/images/file-icons/zip.svg?react";
import PptIcon from "assets/images/file-icons/ppt.svg?react";
import TextIcon from "assets/images/file-icons/text.svg?react";
import CodeIcon from "assets/images/file-icons/code.svg?react";
import ImageIcon from "assets/images/file-icons/image.svg?react";
import AcrobatIcon from "assets/images/file-icons/acrobat.svg?react";
import SpreadsheetIcon from "assets/images/file-icons/spreadsheet.svg?react";

interface DragAndDropIconsProps {
  mime: string;
  thumbnail: string | null;
  type: typeof FAX_FILE | typeof RICH_TEXT;
}

function DragAndDropIcons({ type, mime, thumbnail }: DragAndDropIconsProps) {
  let content = null;

  if (type === "richText") {
    content = <TextIcon width={32} height={32} />;
  }

  switch (true) {
    case mime.search("text/plain") !== -1:
    case mime.search("application/rtf") !== -1:
    case mime.search("text/rtf") !== -1:
      content = <TextIcon width={32} height={32} />;
      break;
    case mime.search("text/html") !== -1:
      content = <CodeIcon width={32} height={32} />;
      break;
    case mime.search("text/csv") !== -1:
      content = <CsvIcon width={32} height={32} />;
      break;
    case mime.search("pdf") !== -1:
    case mime.search("tiff") !== -1:
      content = <AcrobatIcon width={32} height={32} />;
      break;
    case mime.search("word") !== -1:
      content = <DocIcon width={32} height={32} />;
      break;
    case mime.search("image") !== -1:
      if (thumbnail) {
        content = (
          <div
            style={{
              width: "32px",
              height: "32px",
              backgroundSize: "cover",
              backgroundImage: `url(${thumbnail})`,
            }}
          />
        );
        break;
      }
      content = <ImageIcon width={32} height={32} />;
      break;
    case mime.search("zip") !== -1:
      content = <ZipIcon width={32} height={32} />;
      break;
    case mime.search("presentation") !== -1:
      content = <PptIcon width={32} height={32} />;
      break;
    case mime.search("excel") !== -1:
    case mime.search("spreadsheetml") !== -1:
      content = <SpreadsheetIcon width={32} height={32} />;
      break;
    default:
  }

  return content;
}

export default DragAndDropIcons;
