import { useEffect } from "react";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useOnMount, useSnackbar } from "@alohi/kit";
import { useHistory, useLocation } from "react-router";

import { urls } from "routes/urls";
import { useAppDispatch, useAppSelector } from "stores/store";
import { clearVerifyEmail, verifyEmail } from "stores/reducers/verifications.reducer";
import { selectVerifyEmail, selectVerifyEmailError } from "selectors/verifications.selector";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

function VerifyEmail() {
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { sendCustomEvent } = useGoogleTagManager();

  const { token } = queryString.parse(search);

  const isVerifyEmail = useAppSelector(selectVerifyEmail);
  const verifyEmailError = useAppSelector(selectVerifyEmailError);

  useEffect(() => {
    if (isVerifyEmail) {
      enqueueSnackbar(t("VERIFY.ACCOUNT_VERIFY"), { variant: "success" });
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.emailVerification,
      });
      dispatch(clearVerifyEmail());
      history.push(urls.faxes.root);
    }
  }, [dispatch, enqueueSnackbar, history, isVerifyEmail, sendCustomEvent, t]);

  useEffect(() => {
    if (verifyEmailError) {
      enqueueSnackbar(verifyEmailError, { variant: "error" });

      dispatch(clearVerifyEmail());
      history.push(urls.faxes.root);
    }
  }, [dispatch, enqueueSnackbar, verifyEmailError, history]);

  useOnMount(async () => {
    if (token && typeof token === "string") {
      type DispatchInput = Record<string, unknown>;
      type DispatchReturn = AsyncThunkAction<DispatchInput, void, DispatchInput>;

      const verifyEmailTyped = verifyEmail as (input: Record<string, unknown>) => DispatchReturn;

      dispatch(verifyEmailTyped({ token }));
    } else {
      history.push(urls.login);
    }
  });

  return null;
}

export default VerifyEmail;
