import { t } from "i18next";
import { generateRandomFloat } from "helpers/numbers";
import { planDurations } from "../enums/plans";
import { entries } from "./types";

export const capitalizeString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const durationMap = (str: string | undefined, unit: boolean) => {
  if (unit) {
    return str === planDurations.month ? t("COMMON.MONTH") : t("COMMON.YEAR");
  }

  return str === planDurations.month ? `(${t("CHANGE_PLAN.MONTH")})` : `(${t("CHANGE_PLAN.YEAR")})`;
};

export const randomPassword = (length: number, chars: string) => {
  let mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()+-={}[]:\";'<>?,./|\\";
  let result = "";
  for (let i = length; i > 0; --i) result += mask[Math.floor(generateRandomFloat() * mask.length)];
  return result;
};

export const base64 = {
  encode: (string: string) => btoa(unescape(encodeURIComponent(string))),
  decode: (string: string) => atob(escape(decodeURIComponent(string))),
};

export const numberWithCommas = (number: number) => number.toLocaleString("en-US");

export const trimString = (str: string, num: number) =>
  `${str.substr(0, num)}` + (str.length > num ? "..." : "");

export function makeId(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(generateRandomFloat() * charactersLength));
  }

  return result;
}

export function shortenStringWithEllipsis(params: { text: string; maxLength: number }): string {
  if (params.text.length <= params.maxLength) {
    return params.text;
  }

  return params.text.slice(0, params.maxLength) + "...";
}

export function decodeHtmlEntities(input: string): string {
  return input
    .replace(/&amp;/g, "&") // Ampersand
    .replace(/&lt;/g, "<") // Less Than
    .replace(/&gt;/g, ">") // Greater Than
    .replace(/&quot;/g, '"') // Double Quote
    .replace(/&apos;/g, "'") // Single Quote
    .replace(/&copy;/g, "©") // Copyright Symbol
    .replace(/&reg;/g, "®") // Registered Trademark Symbol
    .replace(/&trade;/g, "™") // Trademark Symbol
    .replace(/&euro;/g, "€") // Euro Symbol
    .replace(/&pound;/g, "£") // Pound Sterling
    .replace(/&yen;/g, "¥") // Yen
    .replace(/&cent;/g, "¢") // Cent
    .replace(/&deg;/g, "°") // Degree Symbol
    .replace(/&micro;/g, "µ") // Micro Symbol
    .replace(/&nbsp;/g, " ") // Non-Breaking Space
    .replace(/&hellip;/g, "…") // Horizontal Ellipsis
    .replace(/&mdash;/g, "—") // Em Dash
    .replace(/&ndash;/g, "–") // En Dash
    .replace(/&times;/g, "×") // Multiplication Sign
    .replace(/&divide;/g, "÷") // Division Sign
    .replace(/&plusmn;/g, "±") // Plus-Minus Sign
    .replace(/&para;/g, "¶") // Pilcrow, Paragraph Symbol
    .replace(/&sect;/g, "§") // Section Symbol
    .replace(/&ldquo;/g, "“") // Left Double Quotation Mark
    .replace(/&rdquo;/g, "”") // Right Double Quotation Mark
    .replace(/&lsquo;/g, "‘") // Left Single Quotation Mark
    .replace(/&rsquo;/g, "’") // Right Single Quotation Mark
    .replace(/&laquo;/g, "«") // Left Double Angle Quote
    .replace(/&raquo;/g, "»"); // Right Double Angle Quote
}

export const encodeQueryParams = (object: Record<string, unknown>) => {
  return entries(object)
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(String(value));
      return `${encodedKey}=${encodedValue}`;
    })
    .join("&");
};
