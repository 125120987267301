import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentPlan } from "stores/reducers/plans.reducer";

import { selectIsPlanInactive } from "selectors/plan.selector";
import ManagePaymentMethodModal from "../ManagePaymentMethod/ManagePaymentMethodModal";

function RenewPlanModal({ handleClosure }) {
  const dispatch = useDispatch();

  const isPlanInactive = useSelector(selectIsPlanInactive);

  useEffect(() => {
    dispatch(getCurrentPlan());
  }, [dispatch]);

  useEffect(() => {
    if (!isPlanInactive) {
      // Close this modal if the plan is active
      handleClosure(false);
    }
  }, [handleClosure, isPlanInactive]);

  return <ManagePaymentMethodModal withAutomaticRecharge handleClosure={handleClosure} />;
}

RenewPlanModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default RenewPlanModal;
