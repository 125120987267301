import { API_ESHOP } from "config";
import { fetchWithRefresh } from "helpers";

const getCurrentCredit = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_ESHOP}/current_credit?include_details=true`;
  return fetchWithRefresh(path, requestOptions);
};

const updateCreditCurrency = (currency) => {
  const requestOptions = {
    method: "PATCH",
    body: {
      op: "change_currency",
      currency,
    },
  };
  const path = `${API_ESHOP}/current_credit`;
  return fetchWithRefresh(path, requestOptions);
};

export const currentCreditApi = {
  getCurrentCredit,
  updateCreditCurrency,
};
