import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";

import { DROPBOX_PICKER_OAUTH } from "config";
import useInjectTag from "hooks/useInjectTag";
import useOnMountCondition from "hooks/useOnMountCondition";
import { MAX_UPLOAD_FILE_COUNT, OneMB } from "views/SendFax/contexts/helpers";

interface UseDropboxPickerProps {
  allowedFileTypes: string[];
  onLoading?: (isLoading: boolean) => void;
  onAddDocuments: (documents: FileList | File[]) => void;
}

interface DropboxFile {
  link: string;
  name: string;
}

interface DropboxOptions {
  success: (files: DropboxFile[]) => void;
  cancel?: () => undefined;
  multiselect?: boolean;
  extensions?: string[];
  sizeLimit?: number;
  linkType?: string;
  folderselect?: boolean;
}

interface ExtendedWindow extends Window {
  Dropbox: {
    choose: (options: DropboxOptions) => undefined;
  };
}

declare const window: ExtendedWindow;

const SCRIPT_ID = "dropboxjs";
const DROPBOX_SDK_URL = "https://www.dropbox.com/static/api/2/dropins.js";

function useDropboxPicker({ onAddDocuments, onLoading, allowedFileTypes }: UseDropboxPickerProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { setTag, isError, isLoaded, isLoading } = useInjectTag("script");

  const handleTransformFile = useCallback(async (dropboxFile: DropboxFile) => {
    try {
      const response = await fetch(dropboxFile.link);

      const blob = await response.blob();
      const file = new File([blob], dropboxFile.name, { type: blob.type });

      return file;
    } catch {
      throw new Error("Can't download file");
    }
  }, []);

  const handlePickedFiles = useCallback(
    async (dropboxFiles: DropboxFile[]) => {
      try {
        onLoading?.(true);

        const promises = dropboxFiles.map(handleTransformFile);
        const response = await Promise.allSettled(promises);

        const files = response.reduce<File[]>((accumulator, currentValue) => {
          if (currentValue.status === "fulfilled") {
            accumulator.push(currentValue.value);
          }
          return accumulator;
        }, []);

        onAddDocuments(files);
      } catch {
        throw new Error("Can't download files");
      } finally {
        onLoading?.(false);
      }
    },
    [handleTransformFile, onAddDocuments, onLoading],
  );

  const handleOpenPicker = useCallback(() => {
    window.Dropbox.choose({
      multiselect: true,
      linkType: "direct",
      folderselect: false,
      success: handlePickedFiles,
      extensions: allowedFileTypes,
      sizeLimit: OneMB * MAX_UPLOAD_FILE_COUNT,
    });
  }, [allowedFileTypes, handlePickedFiles]);

  const handleLoadScripts = useCallback(() => {
    if (isLoaded) {
      handleOpenPicker();
    }
  }, [handleOpenPicker, isLoaded]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("SENT_FAX.ERROR_DROPBOX"), { variant: "error" });
    }
  }, [enqueueSnackbar, isError, t]);

  useOnMountCondition(() => {
    setTag({
      async: "true",
      id: SCRIPT_ID,
      src: DROPBOX_SDK_URL,
      "data-app-key": DROPBOX_PICKER_OAUTH,
    });
  }, true);

  return { loadDropboxPicker: handleLoadScripts, isDropboxPickerLoading: isLoading };
}

export default useDropboxPicker;
