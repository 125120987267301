import { useRef, useEffect } from "react";

function useOnMountCondition(callback: () => void, condition: boolean) {
  const wasCalled = useRef(false);

  useEffect(() => {
    if (!wasCalled.current && condition) {
      callback();
      wasCalled.current = true;
    }
  }, [callback, condition]);
}

export default useOnMountCondition;
