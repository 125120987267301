import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Button, Box } from "@alohi/kit";

function DocumentUploadButton({ disabled, fileName = "", handleClosure }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      disabled={disabled}
      className={classes.container}
      classes={{ label: classes.labelOverwrite }}
    >
      <Box className={classes.button} onClick={handleClosure}>
        {fileName || t("ADDRESS.BUNDLE_UPLOAD_BUTTON")}
      </Box>
    </Button>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  labelOverwrite: {
    display: "block",
  },
  container: {
    textAlign: "left",
    maxWidth: "247.5px",
    marginRight: spacing(1),
  },
  button: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

DocumentUploadButton.propTypes = {
  fileName: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  handleClosure: PropTypes.func.isRequired,
};

export default DocumentUploadButton;
