import { Box } from "@alohi/kit";
import { useState, memo, MouseEvent } from "react";

import { Chip, makeStyles } from "ui";
import { trimString } from "helpers/string";
import { Contact } from "stores/reducers/contacts.helpers";
import ManageGroupsModal from "views/Contacts/modals/ManageGroupsModal";

interface ModalState {
  isOpen: boolean;
  contactId: null | string;
}

interface GroupsRowProps {
  row: Contact;
  hasActions: boolean;
  isSharedContact: boolean;
}

function GroupsRow({ isSharedContact, row, hasActions }: GroupsRowProps) {
  const classes = useStyles();

  const [manageGroupsModal, setManageGroupsModal] = useState<ModalState>({
    isOpen: false,
    contactId: null,
  });

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setManageGroupsModal({ isOpen: true, contactId: row.id });
  };

  return (
    <Box className={classes.chips} onClick={handleClick}>
      {row.groups.map((label, index) => {
        if (index > 0) {
          return index === 1 ? (
            <Chip size="small" label=". . ." key={label + index} className={classes.chip} />
          ) : null;
        }

        return (
          <Chip
            size="small"
            key={label + index}
            className={classes.chip}
            label={trimString(label, 20)}
          />
        );
      })}

      {manageGroupsModal.isOpen && manageGroupsModal.contactId ? (
        <ManageGroupsModal
          groupsDiffer={false}
          hasActions={hasActions}
          isShared={isSharedContact}
          checkedGroupNames={row.groups}
          contactsToEdit={[manageGroupsModal.contactId]}
          handleClosure={() => setManageGroupsModal({ isOpen: false, contactId: null })}
        />
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer",
  },
  chip: {
    margin: 2,
    cursor: "pointer",
    backgroundColor: alohi.lightestBlue,
  },
}));

export default memo(GroupsRow);
