import PropTypes from "prop-types";
import { makeStyles } from "ui";
import { useOnPressEscape } from "../../hooks/useOnPressEscape";
import Portal from "../Modal/Portal";
import CloseIcon from "./closeIcon.svg?react";

function ImageViewer({ imageUrl, handleClosure, title = "" }) {
  const classes = useStyles();

  useOnPressEscape(handleClosure);

  return (
    <Portal>
      <div className={classes.imageViewer} onClick={() => handleClosure(null)}>
        <div className={classes.toolbar}>
          <p className={classes.title}>{title}</p>
          <button className={classes.closeButton} onClick={() => handleClosure(null)}>
            <CloseIcon className={classes.closeIcon} />
          </button>
        </div>
        <img className={classes.image} src={imageUrl} alt="preview_image" />
      </div>
    </Portal>
  );
}

const useStyles = makeStyles(() => ({
  imageViewer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 10000,
    display: "flex",
    padding: "0 0 0 0",
    flexDirection: "column",
    background: "rgba(58, 63, 81, 0.8)",
  },
  image: {
    display: "flex",
    height: "calc(100% - 33px)",
    width: "100vw",
    margin: "0 auto",
    objectFit: "contain",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  toolbar: {
    height: "32px",
    backgroundColor: "rgb(56, 56, 61)",
    marginBottom: 1,
    boxShadow: "0 1px 0 rgb(12, 12, 13)",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    width: 28,
    height: 28,
    padding: "2px 6px 0",
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    "&:hover": {
      backgroundColor: "rgba(102, 102, 103, 1)",
    },
  },
  closeIcon: {
    transform: "rotate(45deg)",
  },
  title: {
    color: "white",
  },
}));

ImageViewer.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  handleClosure: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default ImageViewer;
