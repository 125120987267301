import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { FaButton, Modal, Typography, Box } from "@alohi/kit";
import { faFileExport } from "@fortawesome/pro-light-svg-icons";

import { historyApi } from "api";
import dayjs from "helpers/date";
import useBool from "hooks/useBool";
import { boxNames } from "enums/faxes";
import {
  selectBoxCurrentFetchOptions,
  selectSelectedMemberUid,
  selectSelectedNumber,
} from "selectors/faxes.selector";
import ChangePlanToCustom from "../Payment/ChangePlanToCustom";

function ExportToCSV() {
  const { t } = useTranslation();
  const {
    params: { boxName = boxNames.inbox },
  } = useRouteMatch<{ boxName: string }>();

  const selectedNumber = useSelector(selectSelectedNumber);
  const selectedMemberUid = useSelector(selectSelectedMemberUid);
  const currentFetchOptions = useSelector(selectBoxCurrentFetchOptions(boxName));

  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);
  const [isExportToCSVUnavailableModalOpen, isExportToCSVUnavailableModalOpenBool] = useBool(false);

  const handleExportToCSV = async () => {
    try {
      const { afterFilter, beforeFilter } = currentFetchOptions;

      const response = await historyApi.exportToCsv({
        number: selectedNumber,
        memberUid: selectedMemberUid,
        direction: boxName === boxNames.sentbox ? "outgoing" : "incoming",
        trashOnly: boxName === boxNames.trashbox,
        searchPhrase: currentFetchOptions?.searchPhrase,
        status: currentFetchOptions?.status,
        excludeStatus: currentFetchOptions?.excludeStatus,
        after: afterFilter ? dayjs.utc(afterFilter).format("YYYY-MM-DD HH:mm:ss") : "",
        before: beforeFilter ? dayjs.utc(beforeFilter).format("YYYY-MM-DD HH:mm:ss") : "",
      });

      const localUrl = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = localUrl;
      link.setAttribute("download", "FAX-ArchiveReport.csv");
      link.click();
    } catch {
      isExportToCSVUnavailableModalOpenBool.setTrue();
    }
  };

  return (
    <>
      <FaButton
        variant="light"
        icon={faFileExport}
        onClick={handleExportToCSV}
        tooltip={t("FAXES.EXPORT_CSV")}
      />

      {isExportToCSVUnavailableModalOpen ? (
        <Modal
          maxWidth="xs"
          title={t("CHANGE_PLAN.UPGRADE_PLAN")}
          onConfirm={isChangePlanOpenBool.setTrue}
          confirmTitle={t("CHANGE_PLAN.UPGRADE_PLAN")}
          onCancel={isExportToCSVUnavailableModalOpenBool.setFalse}
        >
          <Box p={3}>
            <Typography type="body">
              {t("CHANGE_PLAN.REQUIRE_UPGRADE_TO_PREMIUM_OR_HIGHER", {
                featureName: t("FAXES.EXPORT_CSV"),
              })}
            </Typography>
            <Typography type="body">{t("CHANGE_PLAN.UPGRADE_PLAN_FOR_USE_FEATURE")}</Typography>
          </Box>
        </Modal>
      ) : null}

      {isChangePlanOpen ? (
        <ChangePlanToCustom
          type="premium"
          handleClosure={(isSuccess) => {
            isChangePlanOpenBool.setFalse();

            if (isSuccess) {
              isExportToCSVUnavailableModalOpenBool.setFalse();
            }
          }}
        />
      ) : null}
    </>
  );
}

export default ExportToCSV;
