import { useState } from "react";
import PropTypes from "prop-types";

import ActivateTwoFaEnterCodeStepModal from "components/TwoFa/ActivateTwoFaEnterCodeStepModal";
import TwoFaActivationRecoveryCodesModal from "components/TwoFa/ActivateTwoFaRecoveryCodesStepModal";

const STEPS = {
  ENTER_CODE: "enterCode",
  RECOVERY_CODES: "recoveryCodes",
};

function ActivateTwoFaModal({ handleClosure }) {
  const [currentView, setCurrentView] = useState(STEPS.ENTER_CODE);

  return {
    [STEPS.ENTER_CODE]: (
      <ActivateTwoFaEnterCodeStepModal
        handleClosure={handleClosure}
        onSuccess={() => setCurrentView(STEPS.RECOVERY_CODES)}
      />
    ),
    [STEPS.RECOVERY_CODES]: <TwoFaActivationRecoveryCodesModal handleClosure={handleClosure} />,
  }[currentView];
}

ActivateTwoFaModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default ActivateTwoFaModal;
