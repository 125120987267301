import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Box, Helper, Tabs, Typography, useSnackbar } from "@alohi/kit";
import { faCircleXmark, faHexagonCheck, faHourglassClock } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import { bundleStatuses } from "enums/bundle";
import CreateAddressBundleModal from "views/CreateAddressBundle/CreateAddressBundleModal";
import RegulatoryDocumentsList from "components/Profile/RegulatoryDocuments/RegulatoryDocumentsList";
import DocumentsTooltip from "components/Profile/General/RegulatoryCompliancePanel/components/DocumentsTooltip";
import {
  selectRejectedDocuments,
  selectAllPendingDocuments,
  selectAllApprovedDocuments,
  selectIsNewBundleSuccessful,
} from "selectors/address.selector";
import {
  getBundles,
  clearPostNewBundle,
  clearAddressPowerset,
} from "stores/reducers/address.reducer";

const VIEWS = {
  PENDING: bundleStatuses.pendingReview,
  APPROVED: bundleStatuses.twilioApproved,
  REJECTED: bundleStatuses.twilioRejected,
};

interface RegulatoryDocumentsModalProps {
  handleClosure: () => void;
  defaultView?: string;
}

function RegulatoryDocumentsModal({
  handleClosure,
  defaultView = VIEWS.APPROVED,
}: RegulatoryDocumentsModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(defaultView);

  const [newBundleOpen, newBundleOpenBool] = useBool(false);

  const onChangeTab = useCallback((_, newTab) => {
    setActiveTab(newTab);
  }, []);

  const pendingDocuments = useSelector(selectAllPendingDocuments);
  const approvedDocuments = useSelector(selectAllApprovedDocuments);
  const rejectedDocuments = useSelector(selectRejectedDocuments);
  const isBundleSuccessful = useSelector(selectIsNewBundleSuccessful);

  useEffect(() => {
    if (isBundleSuccessful) {
      enqueueSnackbar(t("ADDRESS.BUNDLE_SUCCESS"), { variant: "success" });
      dispatch(clearPostNewBundle());
      dispatch(clearAddressPowerset());
      dispatch(getBundles());
      newBundleOpenBool.setFalse();
    }
  }, [dispatch, enqueueSnackbar, isBundleSuccessful, newBundleOpenBool, t]);

  return (
    <>
      <Modal
        scroll="paper"
        minHeight="30vh"
        onCancel={handleClosure}
        onConfirm={newBundleOpenBool.setTrue}
        confirmTitle={t("PROFILE.REGULATORY_COMPLIANCE_ADD_REGULATORY_DOCUMENTS")}
        title={
          <Box>
            <Typography type="modalTitle">
              {t("PROFILE.REGULATORY_COMPLIANCE_REGULATORY_DOCUMENTS_TITLE")}
            </Typography>
            <Box ml={1} component="a">
              <Helper
                size="lg"
                variant="light"
                sx={{ cursor: "pointer" }}
                placement="right"
                tooltip={<DocumentsTooltip />}
              />
            </Box>
          </Box>
        }
      >
        <Tabs value={activeTab} onChange={onChangeTab} orientation="horizontal">
          <Tabs.Tab
            value={VIEWS.APPROVED}
            label={t("PROFILE.REGULATORY_COMPLIANCE_TABS_APPROVED")}
            icon={<FontAwesomeIcon icon={faHexagonCheck} size="lg" />}
          />
          <Tabs.Tab
            value={VIEWS.PENDING}
            label={t("PROFILE.REGULATORY_COMPLIANCE_TABS_PENDING")}
            icon={<FontAwesomeIcon icon={faHourglassClock} size="lg" />}
          />
          <Tabs.Tab
            value={VIEWS.REJECTED}
            label={t("PROFILE.REGULATORY_COMPLIANCE_TABS_REJECTED")}
            icon={<FontAwesomeIcon icon={faCircleXmark} size="lg" />}
          />
        </Tabs>
        {
          {
            [VIEWS.APPROVED]: (
              <RegulatoryDocumentsList
                documentData={approvedDocuments}
                documentStatus={bundleStatuses.twilioApproved}
              />
            ),
            [VIEWS.PENDING]: (
              <RegulatoryDocumentsList
                documentData={pendingDocuments}
                documentStatus={bundleStatuses.pendingReview}
              />
            ),
            [VIEWS.REJECTED]: (
              <RegulatoryDocumentsList
                documentData={rejectedDocuments}
                documentStatus={bundleStatuses.twilioRejected}
              />
            ),
          }[activeTab]
        }
      </Modal>

      {newBundleOpen ? (
        <CreateAddressBundleModal handleClosure={newBundleOpenBool.setFalse} withCountrySelector />
      ) : null}
    </>
  );
}

export default RegulatoryDocumentsModal;
