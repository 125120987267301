import { memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, Typography } from "@alohi/kit";
import { useTranslation } from "react-i18next";

import { boxNames } from "../../enums/faxes";
import { selectCdrStatusMessage, selectOutboxCdrIsSending } from "../../selectors/faxes.selector";
import LinearInfiniteProgress from "../Core/LinearInfiniteProgress/LinearInfiniteProgress";

function FaxesColumnOutboxStatus({ dataIndex }) {
  const { t } = useTranslation();
  const isSending = useSelector(selectOutboxCdrIsSending(dataIndex));
  const statusMessage = useSelector(selectCdrStatusMessage(boxNames.outbox, dataIndex));

  return isSending ? (
    <Box display="flex" alignItems="center" justifyContent="start">
      <Typography type="body">{t("FAXES.SENDING")}</Typography>
      <Box ml={1}>
        <LinearInfiniteProgress dotCount={3} diameter={4} wrapperStyle={{ margin: 0 }} />
      </Box>
    </Box>
  ) : statusMessage ? (
    <Typography type="body">{statusMessage}</Typography>
  ) : null;
}

FaxesColumnOutboxStatus.propTypes = {
  dataIndex: PropTypes.number,
};

export default memo(FaxesColumnOutboxStatus);
