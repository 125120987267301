import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { accountsApi } from "api";
import { RootState } from "stores/store";
import RS, { FetchError, InitialRequestState } from "enums/requestStatus";
import { logout } from "./authentication.reducer";
import {
  Contact,
  transformContacts,
  GetContactsResponse,
  UpdateContactResponse,
  CreateContactResponse,
  DefaultRequestOptions,
  revertTransformContact,
} from "./contacts.helpers";

const defaultRequestOptions: DefaultRequestOptions = {
  limit: 50,
  offset: 0,
  shared: true,
  search: "",
  sortBy: "",
  type: "json",
  direction: "",
};

const initialState = {
  getSharedContacts: InitialRequestState(),
  createSharedContact: InitialRequestState(),
  updateSharedContact: InitialRequestState(),
  deleteSharedContacts: InitialRequestState(),
  getMoreSharedContacts: InitialRequestState(),
  setSharedContactsGroups: InitialRequestState(),

  hasMore: false,
  selection: [] as string[],
  contacts: null as null | Contact[],
  lastRequestOptions: defaultRequestOptions,
};

const getSharedContacts = createAsyncThunk<
  GetContactsResponse,
  Partial<DefaultRequestOptions>,
  { state: RootState; rejectValue: FetchError }
>(
  "SHARED_CONTACTS/GET",
  async (requestOptionsOverrides, { getState, rejectWithValue }) => {
    try {
      const lastRequestOptions = getState().sharedContacts.lastRequestOptions;
      const requestOptions = {
        ...lastRequestOptions,
        ...requestOptionsOverrides,
      };

      const response = await accountsApi.getContacts(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().sharedContacts.getSharedContacts.status !== RS.RUNNING;
    },
  },
);

const getMoreSharedContacts = createAsyncThunk<
  GetContactsResponse,
  undefined,
  { state: RootState; rejectValue: FetchError }
>(
  "SHARED_CONTACTS/GET_MORE",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { contacts, lastRequestOptions } = getState().sharedContacts;

      const requestOptions = {
        ...lastRequestOptions,
        offset: contacts?.length ?? 0,
      };

      const response = await accountsApi.getContacts(requestOptions);

      return { response, requestOptions };
    } catch (error) {
      return rejectWithValue(error as FetchError);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().sharedContacts.getMoreSharedContacts.status !== RS.RUNNING;
    },
  },
);

const createSharedContact = createAsyncThunk<
  CreateContactResponse,
  { newContact: Partial<Contact> },
  { state: RootState; rejectValue: FetchError }
>("SHARED_CONTACTS/CREATE", async ({ newContact }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.createContact(revertTransformContact(newContact), true);
    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const updateSharedContact = createAsyncThunk<
  UpdateContactResponse,
  { contactDiffs: Partial<Contact> },
  { state: RootState; rejecteValue: FetchError }
>("SHARED_CONTACTS/UPDATE", async ({ contactDiffs }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.updateContact(revertTransformContact(contactDiffs));
    return { response, contactDiffs };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const deleteSharedContacts = createAsyncThunk<
  { idsToDelete: string[] },
  { idsToDelete: string[] },
  { state: RootState; rejectValue: FetchError }
>("SHARED_CONTACTS/DELETE", async ({ idsToDelete }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.deleteContacts(idsToDelete);
    return { response, idsToDelete };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

export const clearSharedContacts = createAction("SHARED_CONTACTS/CLEAR");
export const clearSetSharedContactsGroups = createAction("SHARED_CONTACTS/CLEAR_SET_GROUPS");
export const updateSharedContactsSelection = createAction<string[]>(
  "SHARED_CONTACTS/UPDATE_SELECTION",
);

const setSharedContactsGroups = createAsyncThunk<
  { response: Record<string, string> },
  { contactIds: string[]; groups: string[]; isShared: boolean },
  { state: RootState; rejectValue: FetchError }
>("SHARED_CONTACTS/SET_GROUPS", async ({ contactIds, isShared, groups }, { rejectWithValue }) => {
  try {
    const response = await accountsApi.setContactsGroups({
      groups: groups as never[],
      isShared,
      contactIds: contactIds as never[],
    });

    return { response };
  } catch (error) {
    return rejectWithValue(error as FetchError);
  }
});

const { reducer } = createSlice({
  name: "SHARED_CONTACTS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSharedContactsSelection, (state, { payload }) => {
      state.selection = payload;
    });
    builder.addCase(getSharedContacts.pending, (state) => {
      state.getSharedContacts.status = RS.RUNNING;
      state.getSharedContacts.error = null;
    });
    builder.addCase(getSharedContacts.fulfilled, (state, { payload }) => {
      state.getSharedContacts.status = RS.IDLE;
      state.getSharedContacts.response = payload.response;

      state.contacts = transformContacts(payload.response.contacts.result);
      state.hasMore = payload.response.contacts.has_more;
      state.lastRequestOptions = {
        ...state.lastRequestOptions,
        ...payload.requestOptions,
      };
    });
    builder.addCase(getSharedContacts.rejected, (state, { payload }) => {
      state.getSharedContacts.status = RS.ERROR;
      state.getSharedContacts.error = payload;
    });

    builder.addCase(getMoreSharedContacts.pending, (state) => {
      state.getMoreSharedContacts.status = RS.RUNNING;
      state.getMoreSharedContacts.error = null;
    });
    builder.addCase(getMoreSharedContacts.fulfilled, (state, { payload }) => {
      state.getMoreSharedContacts.status = RS.IDLE;
      state.getMoreSharedContacts.response = payload.response;

      if (state.contacts) {
        state.contacts.push(...transformContacts(payload.response.contacts.result));
        // Reset the indexes
        state.contacts = state.contacts.map((rest, index) => ({
          ...rest,
          index,
        }));
      } else {
        state.contacts = transformContacts(payload.response.contacts.result);
      }

      state.hasMore = payload.response.contacts.has_more;
      state.lastRequestOptions = {
        ...state.lastRequestOptions,
        ...payload.requestOptions,
      };
    });
    builder.addCase(getMoreSharedContacts.rejected, (state, { payload }) => {
      state.getMoreSharedContacts.status = RS.ERROR;
      state.getMoreSharedContacts.error = payload;
    });

    builder.addCase(createSharedContact.pending, (state) => {
      state.createSharedContact.status = RS.RUNNING;
      state.createSharedContact.error = null;
    });
    builder.addCase(createSharedContact.fulfilled, (state, { payload }) => {
      state.createSharedContact.status = RS.IDLE;
      state.createSharedContact.response = payload.response;
      state.contacts = null; // Refresh the list
      state.hasMore = false;
    });

    builder.addCase(updateSharedContact.pending, (state) => {
      state.updateSharedContact.status = RS.RUNNING;
      state.updateSharedContact.error = null;
    });
    builder.addCase(updateSharedContact.fulfilled, (state, { payload }) => {
      state.updateSharedContact.status = RS.IDLE;

      const indexToEdit = state.contacts?.findIndex(
        (contact) => contact.id === payload.contactDiffs.id,
      );

      if (indexToEdit !== undefined && indexToEdit !== -1 && state.contacts) {
        const newContact = {
          ...state.contacts[indexToEdit],
          ...payload.contactDiffs,
          index: indexToEdit,
        } as Contact;

        state.contacts[indexToEdit] = newContact;
      }
    });

    builder.addCase(deleteSharedContacts.pending, (state) => {
      state.deleteSharedContacts.status = RS.RUNNING;
      state.deleteSharedContacts.error = null;
    });
    builder.addCase(deleteSharedContacts.fulfilled, (state, { payload }) => {
      state.deleteSharedContacts.status = RS.IDLE;

      if (state.contacts) {
        state.contacts = state.contacts.filter(
          (contact) => !payload.idsToDelete.includes(contact.id),
        );
        // Reset the indexes
        state.contacts = state.contacts.map((rest, index) => ({
          ...rest,
          index,
        }));
      }
    });

    builder.addCase(clearSetSharedContactsGroups, (state) => {
      state.setSharedContactsGroups.status = RS.IDLE;
      state.setSharedContactsGroups.error = null;
      state.setSharedContactsGroups.response = null;
    });
    builder.addCase(setSharedContactsGroups.pending, (state) => {
      state.setSharedContactsGroups.status = RS.RUNNING;
      state.setSharedContactsGroups.error = null;
    });
    builder.addCase(setSharedContactsGroups.fulfilled, (state, { payload }) => {
      state.setSharedContactsGroups.status = RS.IDLE;
      state.setSharedContactsGroups.response = payload.response;
      state.contacts = null; // Refresh the list
    });
    builder.addCase(setSharedContactsGroups.rejected, (state, { payload }) => {
      state.setSharedContactsGroups.status = RS.ERROR;
      state.setSharedContactsGroups.error = payload;
    });

    builder.addCase(clearSharedContacts, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

export {
  getSharedContacts,
  createSharedContact,
  updateSharedContact,
  deleteSharedContacts,
  getMoreSharedContacts,
  setSharedContactsGroups,
};

export default reducer;
