import PropTypes from "prop-types";
import { Tabs, Box } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";

import { Typography, useTheme } from "ui";
import useForceRemount from "hooks/useForceRemount";
import useBool from "hooks/useBool";
import CreditCard from "../../views/CreditCard/CreditCard";
import { paymentTypes, paymentProviders } from "../../enums/payment";
import CreditCardError from "../../views/Transactions/CreditCardError";
import PaypalButton from "../../components/Core/PaypalButton/PaypalButton";
import { selectNewCreditCard, selectNewCreditCardError } from "../../selectors/payment.selector";
import {
  getPaymentMethods,
  setNewPaymentProvider,
  getDefaultPaymentMethod,
} from "../../stores/reducers/payment.methods.reducer";
import {
  newCreditCard,
  clearCreditCardInfo,
  clearNewCreditCard,
} from "../../stores/reducers/payment.creditCard.reducer";

const VIEWS = {
  PAYPAL: "paypal",
  CREDIT_CARD: "creditCard",
};

function AddPaymentMethodComponent({ handleClosure, initialTab }) {
  const { alohi } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const newCreditCardResponse = useSelector(selectNewCreditCard);
  const newCreditCardError = useSelector(selectNewCreditCardError);

  const [currentTab, setCurrentTab] = useState(
    initialTab === "paypal" ? VIEWS.PAYPAL : VIEWS.CREDIT_CARD,
  );
  const [openNewCreditCardError, openNewCreditCardErrorBool] = useBool(false);

  const [key, forceRemount] = useForceRemount();

  const handlePayPalData = useCallback(
    (nonce) => {
      if (nonce) {
        dispatch(
          newCreditCard({
            token: nonce,
            type: paymentTypes.PAYPAL,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleOpenPaymentMethod = (type) => {
    openNewCreditCardErrorBool.setFalse();
    setCurrentTab(type === "paypal" ? VIEWS.PAYPAL : VIEWS.CREDIT_CARD);
    if (type === "list") {
      handleClosure();
    }
  };

  const handleOnCancel = useCallback(() => {
    openNewCreditCardErrorBool.setFalse();
    forceRemount();
  }, [forceRemount, openNewCreditCardErrorBool]);

  const handleOnClose = useCallback(() => {
    openNewCreditCardErrorBool.setFalse();
    handleClosure();
  }, [handleClosure, openNewCreditCardErrorBool]);

  useEffect(() => {
    if (newCreditCardResponse) {
      dispatch(getPaymentMethods());
      dispatch(getDefaultPaymentMethod());

      dispatch(clearCreditCardInfo());
      dispatch(clearNewCreditCard());
      // Inform that a new payment method has been created.
      handleClosure(newCreditCardResponse);
    }
  }, [dispatch, newCreditCardResponse, handleClosure]);

  useEffect(() => {
    if (currentTab === VIEWS.CREDIT_CARD) {
      dispatch(setNewPaymentProvider(paymentProviders.STRIPE));
    } else if (currentTab === VIEWS.PAYPAL) {
      dispatch(setNewPaymentProvider(paymentProviders.PAYPAL));
    }
  }, [currentTab, dispatch]);

  useEffect(() => {
    if (newCreditCardError) {
      openNewCreditCardErrorBool.setTrue();
    }
  }, [newCreditCardError, openNewCreditCardErrorBool]);

  return (
    <div>
      <Tabs value={currentTab} onChange={(_, tab) => setCurrentTab(tab)}>
        <Tabs.Tab
          value={VIEWS.CREDIT_CARD}
          label={t("ADD_CREDIT.CARD")}
          icon={<FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>}
        />
        <Tabs.Tab
          value={VIEWS.PAYPAL}
          label={t("ADD_CREDIT.PAYPAL")}
          icon={<FontAwesomeIcon icon={faPaypal} size="lg" />}
        />
      </Tabs>
      {
        {
          [VIEWS.CREDIT_CARD]: (
            <CreditCard key={key} onOpenPaymentMethod={handleOpenPaymentMethod} />
          ),
          [VIEWS.PAYPAL]: (
            <Box key={key} p={4}>
              <Typography variant="body">{t("ADD_CREDIT.PAYPAL_DESCRIPTION")}</Typography>
              <PaypalButton handleClosure={handlePayPalData} />
            </Box>
          ),
        }[currentTab]
      }
      <Box mt={0} mx="60px" mb={0} pb="10px">
        <Typography variant="caption">
          <Box display="flex" alignItems="center" justifyContent="flex-start" m={2}>
            <Typography variant="body" style={{ fontSize: 14 }}>
              <FontAwesomeIcon
                size="sm"
                icon={faLock}
                color={alohi.gray}
                style={{ marginRight: 4, marginBottom: 2 }}
              />
              {t("ADD_CREDIT.DESCRIBE")}
            </Typography>
          </Box>
        </Typography>
      </Box>

      {openNewCreditCardError ? (
        <CreditCardError
          type={currentTab === VIEWS.PAYPAL ? "paypal" : "credit_card"}
          onCancel={handleOnCancel}
          handleClosure={handleOnClose}
          onOpenPaymentMethod={handleOpenPaymentMethod}
        />
      ) : null}
    </div>
  );
}

AddPaymentMethodComponent.propTypes = {
  handleClosure: PropTypes.func,
  initialTab: PropTypes.string,
};

export default AddPaymentMethodComponent;
