import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { FadeLoader } from "react-spinners";

function Loading({ size = 22, scale = 1 }) {
  return (
    <Box display="flex" justifyContent="center" width={size} height={size}>
      <FadeLoader
        radius={4}
        height={15}
        size={size}
        css={{
          top: 0,
          left: 0,
          width: 0,
          height: size,
          transform: `scale(${scale * 0.3}, ${scale * -0.3})`,
        }}
      />
    </Box>
  );
}

Loading.propTypes = {
  size: PropTypes.number,
  scale: PropTypes.number,
};

export default Loading;
