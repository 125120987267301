import { Box, Divider, Menu, MenuItem, useBool } from "@alohi/kit";
import { faArrowDownToLine, faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { faGrid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FaxPlusLogo from "assets/svg/fax_plus_logo.svg?react";
import SignPlusLogo from "assets/svg/sign_plus_logo.svg?react";
import ScanPlusLogo from "assets/svg/scan_plus_logo.svg?react";
import IncentiveButton from "components/Incentive/IncentiveButton";
import IncentiveModal from "components/Incentive/modals/IncentiveModal";
import { SIGN_APP_DOMAIN } from "config";
import dataCy from "enums/dataCy";
import { helpCenterUrls, urls, websiteUrls } from "routes/urls";
import { ListSubheader, Typography, makeStyles, useTheme } from "ui";
import { useHistory } from "react-router";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

function AppSwitcher() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const { sendCustomEvent } = useGoogleTagManager();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isIncentiveModalOpen, isIncentiveModalOpenBool] = useBool(false);

  const handleOpenIncentiveModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    isIncentiveModalOpenBool.setTrue();
  };

  const handleCloseIncentiveModal = () => {
    isIncentiveModalOpenBool.setFalse();
  };

  const handleClickSignPlus = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarAppSwitcher,
      page: location.pathname,
      extraData: {
        label: "sign_plus",
        to: SIGN_APP_DOMAIN,
      },
    });
    window.open(SIGN_APP_DOMAIN, "_blank", "noopener,noreferrer");
  };

  const handleClickFaxPlus = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarAppSwitcher,
      page: location.pathname,
      extraData: {
        label: "fax_plus",
        to: urls.faxes.inbox,
      },
    });
    history.push(urls.faxes.inbox);
  };

  const handleClickScanPlus = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarAppSwitcher,
      page: location.pathname,
      extraData: {
        label: "scan_plus",
        to: urls.scanPlus,
      },
    });
    window.open(urls.scanPlus, "_blank", "noopener,noreferrer");
  };

  const handleClickGetMobileApp = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarAppSwitcher,
      page: location.pathname,
      extraData: {
        label: "get_mobile",
        to: helpCenterUrls.mobile,
      },
    });
    window.open(helpCenterUrls.mobile, "_blank", "noopener,noreferrer");
  };

  const handleClickAboutAlohi = () => {
    sendCustomEvent({
      event: GoogleAnalyticsCustomEvents.navbarAppSwitcher,
      page: location.pathname,
      extraData: {
        label: "about_alohi",
        to: websiteUrls.alohi,
      },
    });
    window.open(websiteUrls.alohi, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (anchorEl) {
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.navbarAppSwitcher,
        page: location.pathname,
        extraData: {
          label: "open",
        },
      });
    }
  }, [anchorEl, sendCustomEvent]);

  return (
    <>
      <Box
        id="app-switcher"
        aria-haspopup="true"
        className={classes.root}
        data-cy={dataCy.appSwitcher}
        aria-controls="app-switcher"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <FontAwesomeIcon icon={faGrid} style={{ color: theme.alohi.almostGray }} fontSize={20} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "app-switcher",
          subheader: <ListSubheader>{t("COMMON.YOUR_APPS")}</ListSubheader>,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MenuItem onClick={handleClickSignPlus}>
          <Box className={classes.centerFlex}>
            <Box
              sx={{ mr: 2 }}
              className={clsx({
                [classes.productIcon]: true,
                [classes.centerFlex]: true,
              })}
            >
              <SignPlusLogo width={25} height={25} />
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>Sign.Plus</Typography>
              <Typography variant="subtitle" className={classes.subtitle}>
                {t("COMMON.ELECTRONIC_SIGNATURE")}
              </Typography>
            </Box>
            <IncentiveButton
              className={classes.incentiveButton}
              onClick={handleOpenIncentiveModal}
            />
          </Box>
        </MenuItem>

        <MenuItem onClick={handleClickFaxPlus}>
          <Box className={classes.centerFlex}>
            <Box
              sx={{ mr: 2 }}
              className={clsx({
                [classes.productIcon]: true,
                [classes.centerFlex]: true,
              })}
            >
              <FaxPlusLogo width={28} height={28} />
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>Fax.Plus</Typography>
              <Typography variant="subtitle" className={classes.subtitle}>
                {t("COMMON.ONLINE_FAXING")}
              </Typography>
            </Box>
          </Box>
        </MenuItem>

        <MenuItem onClick={handleClickScanPlus}>
          <Box className={classes.centerFlex}>
            <Box
              sx={{ mr: 2 }}
              className={clsx({
                [classes.productIcon]: true,
                [classes.centerFlex]: true,
              })}
            >
              <ScanPlusLogo width={28} height={28} />
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>Scan.Plus</Typography>
              <Typography variant="subtitle" className={classes.subtitle}>
                {t("COMMON.MOBILE_SCANNER")}
              </Typography>
            </Box>
          </Box>
        </MenuItem>

        <Divider sx={{ my: 1 }} />
        <MenuItem className={classes.menuItem} onClick={handleClickGetMobileApp}>
          <FontAwesomeIcon icon={faArrowDownToLine} className={classes.icon} />
          <Typography>{t("COMMON.GET_THE_MOBILE_APP")}</Typography>
        </MenuItem>

        <Divider sx={{ my: 1 }} />
        <MenuItem className={classes.menuItem} onClick={handleClickAboutAlohi}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.icon} />
          <Typography>{t("COMMON.ABOUT_ALOHI")}</Typography>
        </MenuItem>
      </Menu>
      {isIncentiveModalOpen ? <IncentiveModal onClose={handleCloseIncentiveModal} /> : null}
    </>
  );
}

const useStyles = makeStyles(({ spacing, alohi, shape }) => ({
  root: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    padding: spacing(2, 0),
  },
  menuItem: {
    minWidth: 250,
  },
  icon: {
    fontSize: 18,
    marginRight: spacing(1.5),
    color: alohi.almostGray,
  },
  centerFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subtitle: {
    fontSize: "0.8125rem",
  },
  productIcon: {
    width: 44,
    height: 44,
    backgroundColor: alohi.white,
    borderRadius: shape.borderRadius,
    border: `1px solid ${alohi.lightestGray}`,
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
  },
  incentiveButton: {
    borderRadius: 999,
    marginLeft: spacing(4),
  },
}));

export default AppSwitcher;
