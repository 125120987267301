import PropTypes from "prop-types";
import { SortableTreeWithoutDndContext as SortableTree } from "react-sortable-tree";
import { makeStyles } from "ui";
import { accountTypes } from "../../../enums/account";

import UsersHierarchyTreeNodeRenderer from "./UsersHierarchyTreeNodeRenderer";
import "react-sortable-tree/style.css";

function UsersHierarchyTree({ treeData, onChange, isEditMode }) {
  const classes = useStyles({ hasOneNode: treeData.length === 1 });

  return (
    <SortableTree
      treeData={treeData}
      onChange={onChange}
      maxDepth={3}
      rowHeight={120}
      nodeContentRenderer={UsersHierarchyTreeNodeRenderer}
      canDrop={({ nextTreeIndex }) => nextTreeIndex !== 0}
      canDrag={({ node }) => isEditMode && node.accountType !== accountTypes.corporateOwner}
      canNodeHaveChildren={(node) => node.accountType !== accountTypes.corporateOwner}
      isVirtualized={false}
      className={classes.tree}
    />
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  tree: ({ hasOneNode }) => ({
    width: "100%",
    [`& .rst__lineHalfHorizontalRight::before,
      .rst__lineFullVertical::after,
      .rst__lineHalfVerticalTop::after,
      .rst__lineHalfVerticalBottom::after,
      .rst__lineChildren::after`]: {
      background: hasOneNode ? "transparent" : alohi.lightGray,
    },
    "& .rst__node": {
      display: "flex",
    },
    "& .rst__nodeContent": {
      flex: "1 0 auto",
      position: "sticky",
    },
  }),
}));

UsersHierarchyTree.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  isEditMode: PropTypes.bool,
};

export default UsersHierarchyTree;
