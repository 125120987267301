import PropTypes from "prop-types";
import { useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { FaButton, Box, useSnackbar } from "@alohi/kit";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import { websiteUrls } from "routes/urls";
import HelpTooltip from "components/Core/Tooltip/HelpTooltip";
import DragAndDropOverlay from "components/DragAndDrop/DragAndDropOverlay";
import { bundleMimeTypes, bundleAllowedFileTypes } from "enums/allowedFileTypes";
import { Radio, Typography, MessageBox, makeStyles, FormControlLabel, Link, Chip } from "ui";
import DocumentUploadButton from "views/CreateAddressBundle/components/Documents/components/DocumentContainer/components/DocumentUploadButton";
import DocumentCustomFields from "views/CreateAddressBundle/components/Documents/components/DocumentContainer/components/DocumentCustomFields";

function DocumentContainer({
  name,
  inputId,
  description,
  onDeleteFile,
  userDocuments,
  selectedDocuments,
  acceptedDocuments,
  isContainerDisabled,
  onUpdateUserDocuments,
  numberOfRequiredDocuments,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const lastValuePressed = useRef(null);

  const { open, getRootProps, isDragActive, getInputProps } = useDropzone({
    accept: bundleMimeTypes,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    onDrop: ([file]) => {
      if (!file || isContainerDisabled) {
        return;
      }
      // 5MB limit
      if (file.size > 5242880) {
        enqueueSnackbar(t("FORMS.LARGE_FILE", { size: 5 }), {
          variant: "warning",
        });
        return;
      }

      onUpdateUserDocuments(inputId, file, userDocuments)(lastValuePressed.current.target.value);
    },
  });

  const handleDeleteFile = useCallback(
    (documentType) => {
      onDeleteFile(inputId, documentType, userDocuments);
    },
    [inputId, onDeleteFile, userDocuments],
  );

  const documents = Object.values(selectedDocuments ?? {});
  const isRadioGroupDisabled =
    documents.length === numberOfRequiredDocuments && numberOfRequiredDocuments !== 0;

  return (
    <Box key={inputId} {...getRootProps()} className={classes.container}>
      {isDragActive && <DragAndDropOverlay />}

      <input {...getInputProps()} accept={bundleAllowedFileTypes} />

      {isContainerDisabled ? (
        <Box className={classes.overlay}>
          <Typography variant="body">{t("ADDRESS.BUNDLE_DOCUMENT_NOT_NEEDED")}</Typography>
        </Box>
      ) : null}

      <Box className={classes.containerTop}>
        <Box className={classes.title}>
          <Typography variant="body" style={{ fontWeight: "bold", marginRight: "8px" }}>
            {t("ADDRESS.BUNDLE_SELECT_PROOF", { name })}
          </Typography>

          {description ? <HelpTooltip size={"lg"} title={description} /> : null}
        </Box>

        {numberOfRequiredDocuments > 1 ? (
          <Chip
            variant="filled"
            className={classes.chip}
            label={`${documents.length}/${numberOfRequiredDocuments}`}
          />
        ) : null}
      </Box>

      {acceptedDocuments.map(({ documentName, documentType }, index) => {
        const isChecked = Boolean(selectedDocuments?.[documentType]);

        return (
          <FormControlLabel
            checked={isChecked}
            label={documentName}
            value={documentType}
            key={documentType + index}
            disabled={isRadioGroupDisabled && !isChecked}
            onChange={(event) => {
              // Save the last SyntheticEvent, to pass the value selected by the user to handleUpdateUserDocuments callback
              lastValuePressed.current = event;
            }}
            control={
              <Radio
                size="small"
                disableRipple
                color="primary"
                disabled={false}
                classes={{ root: classes.radio }}
                onClick={() => {
                  if (!isChecked) {
                    open();
                  }
                }}
              />
            }
          />
        );
      })}

      {acceptedDocuments.map(({ documentName, documentType }, index) => {
        const isChecked = Boolean(selectedDocuments?.[documentType]);

        if (!isChecked) {
          return null;
        }
        const fileName = selectedDocuments[documentType].name;

        return (
          <Box display="flex" mt="12px" key={documentName + index}>
            <DocumentUploadButton
              fileName={fileName}
              handleClosure={open}
              disabled={isContainerDisabled}
            />
            <FaButton icon={faTrashAlt} onClick={() => handleDeleteFile(documentType)} />
          </Box>
        );
      })}

      {name === "Japan Regulatory Bundle Application" ? (
        <MessageBox variant="info">
          <Typography variant="info">
            {t(`ADDRESS.JAPAN_BUNDLE_MESSAGE`)}
            <br />
            <Trans
              i18nKey={"ADDRESS.JAPAN_BUNDLE_LINK"}
              components={{
                bundleLink: <Link target="_blank" rel="noreferrer" href={websiteUrls.jpBundle} />,
              }}
            />
          </Typography>
        </MessageBox>
      ) : null}

      <DocumentCustomFields inputId={inputId} />
    </Box>
  );
}

const useStyles = makeStyles(({ shape, spacing, alohi }) => ({
  radio: {
    padding: spacing(1),
  },
  container: {
    display: "flex",
    padding: spacing(2),
    position: "relative",
    marginTop: spacing(1),
    flexDirection: "column",
    borderRadius: shape.borderRadius,
    border: `1px solid ${alohi.lightGray}`,
  },
  overlay: {
    top: 0,
    left: 0,
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    background: alohi.white,
    justifyContent: "center",
    borderRadius: shape.borderRadius,
  },
  containerTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  chip: {
    backgroundColor: alohi.lightestBlue,
  },
}));

DocumentContainer.propTypes = {
  name: PropTypes.string,
  inputId: PropTypes.string,
  onDeleteFile: PropTypes.func,
  description: PropTypes.string,
  userDocuments: PropTypes.object,
  acceptedDocuments: PropTypes.array,
  isContainerDisabled: PropTypes.bool,
  selectedDocuments: PropTypes.object,
  onUpdateUserDocuments: PropTypes.func,
  numberOfRequiredDocuments: PropTypes.number,
};

export default DocumentContainer;
