import { useSnackbar } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "ui";

import { plans } from "../../enums/plans";
import { selectIsCorporateUpgrade } from "../../selectors/common.selector";
import { clearCart } from "../../stores/reducers/payment.cart.reducer";
import {
  clearVerifyNumber,
  clearVerifyCustomNumber,
  clearVerifyBulkNumbersResponse,
  clearAssignBundleToNumber,
} from "../../stores/reducers/verifications.reducer";
import {
  setNextPlan,
  getAvailableAreas,
  setAreaForCountry,
  clearAreaForCountry,
} from "../../stores/reducers/plans.reducer";
import {
  selectNextPlan,
  selectAvailablePlans,
  selectAreaForCountry,
  selectAvailableAreas,
  selectCountryForPlan,
  selectIsNextPlanBasic,
  selectIsCurrentPlanBasic,
} from "../../selectors/plan.selector";
import { clearAddressRequirements } from "../../stores/reducers/address.reducer";
import FormRowReactSelectInput from "../Forms/FormRowReactSelectInput";
import { OptionArea } from "./CustomizedSelect";

function AvailableAreas() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const nextPlan = useSelector(selectNextPlan);
  const selectedArea = useSelector(selectAreaForCountry);
  const countryForPlan = useSelector(selectCountryForPlan);
  const availableAreas = useSelector(selectAvailableAreas);
  const availablePlans = useSelector(selectAvailablePlans);
  const isNextPlanBasic = useSelector(selectIsNextPlanBasic);
  const isCurrentPlanBasic = useSelector(selectIsCurrentPlanBasic);
  const isCorporateUpgrade = useSelector(selectIsCorporateUpgrade);

  useEffect(() => {
    if (countryForPlan?.iso) {
      dispatch(getAvailableAreas(countryForPlan.iso));
    }
  }, [countryForPlan, dispatch]);

  useEffect(() => {
    if (selectedArea?.isTollFree) {
      if (isCurrentPlanBasic && !isCorporateUpgrade) {
        // User is basic, not upgrading to a corporate plan and he tried to change his number to a premium one.
        enqueueSnackbar(
          t("CHANGE_PLAN.PLAN_RESTRICTION_NEED_FOR_THE_AREA_CODE", {
            country: countryForPlan.name,
          }),
          {
            variant: "info",
          },
        );
        dispatch(clearAreaForCountry());
      } else if (isNextPlanBasic) {
        // User is free and he tried to change his number to a premium one.
        enqueueSnackbar(
          t("CHANGE_PLAN.CHANGE_PLAN_RESTRICTION_NEED_FOR_THE_AREA_CODE", {
            country: countryForPlan.name,
          }),
          {
            variant: "info",
          },
        );
        // Premium monthly will be auto-selected by us for the user
        dispatch(setNextPlan(availablePlans, true, plans.premium));
      }
    }
  }, [
    t,
    nextPlan,
    dispatch,
    selectedArea,
    availablePlans,
    countryForPlan,
    isNextPlanBasic,
    enqueueSnackbar,
    isCorporateUpgrade,
    isCurrentPlanBasic,
  ]);

  const handleAreaChange = useCallback(
    (area) => {
      dispatch(clearVerifyNumber());
      dispatch(clearVerifyCustomNumber());
      dispatch(clearAssignBundleToNumber());
      dispatch(clearVerifyBulkNumbersResponse());
      dispatch(clearAddressRequirements());
      // Clear the cart to refresh the price
      dispatch(clearCart());
      // Update the area code
      dispatch(setAreaForCountry(area));
    },
    [dispatch],
  );

  if (!countryForPlan) {
    return null;
  }

  const selectStyles = {
    groupHeading: (provided) => ({
      ...provided,
      marginBottom: 0,
      fontSize: "0.9em",
      color: theme.alohi.gray,
      background: theme.alohi.darkGray,
    }),
    group: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.alohi.gray,
    }),
  };

  return (
    <FormRowReactSelectInput
      id="select-city"
      label={t("COMMON.CITY")}
      labelGridWidth={5}
      ReactSelectProps={{
        isSearchable: true,
        maxMenuHeight: 250,
        value: selectedArea,
        styles: selectStyles,
        options: availableAreas,
        onChange: handleAreaChange,
        placeholder: t("COMMON.SELECT_CITY"),
        components: { Option: OptionArea },
      }}
    />
  );
}

export default AvailableAreas;
