import { Box, Modal } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback, MouseEvent as ReactMouseEvent } from "react";

import FormRow from "components/Forms/FormRow";
import useOnMountCondition from "hooks/useOnMountCondition";
import { useAppDispatch, useAppSelector } from "stores/store";
import { getGroups } from "stores/reducers/contacts.groups.reducer";
import { getSharedGroups } from "stores/reducers/contacts.sharedGroups.reducer";
import {
  selectSetWhitelistGroupsResponse,
  selectSetWhitelistGroupsIsRunning,
  selectSetSharedContactsGroupsResponse,
  selectSetSharedContactsGroupsIsRunning,
} from "selectors/contacts.selector";
import {
  setWhitelistGroups,
  clearSetWhitelistGroups,
} from "stores/reducers/contacts.whitelist.reducer";
import {
  setSharedContactsGroups,
  clearSetSharedContactsGroups,
} from "stores/reducers/contacts.sharedContacts.reducer";
import GroupAutoComplete from "../components/GroupAutoComplete";

interface ManageGroupsModalProps {
  isShared: boolean;
  hasActions: boolean;
  groupsDiffer: boolean;
  contactsToEdit: string[];
  handleClosure: () => void;
  checkedGroupNames: string[];
}

function ManageGroupsModal({
  isShared,
  hasActions,
  groupsDiffer,
  handleClosure,
  contactsToEdit,
  checkedGroupNames,
}: ManageGroupsModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const whitelistSetGroupsResponse = useAppSelector<boolean>(selectSetWhitelistGroupsResponse);
  const whitelistSetGroupsIsRunning = useAppSelector<boolean>(selectSetWhitelistGroupsIsRunning);
  const sharedContactsSetGroupsResponse = useAppSelector<boolean>(
    selectSetSharedContactsGroupsResponse,
  );
  const sharedContactsSetGroupsIsRunning = useAppSelector<boolean>(
    selectSetSharedContactsGroupsIsRunning,
  );

  const response = isShared ? sharedContactsSetGroupsResponse : whitelistSetGroupsResponse;
  const isRunning = isShared ? sharedContactsSetGroupsIsRunning : whitelistSetGroupsIsRunning;

  const [groupsState, setGroupsState] = useState<string[]>(groupsDiffer ? [] : checkedGroupNames);

  const fetchGroups = useCallback(() => {
    isShared
      ? dispatch(getSharedGroups({ offset: 0, limit: 100 }))
      : dispatch(getGroups({ offset: 0, limit: 100 }));
  }, [dispatch, isShared]);

  function handleConfirm() {
    if (!hasActions) {
      handleClosure();
      return;
    }

    if (isShared) {
      dispatch(
        setSharedContactsGroups({
          isShared,
          groups: groupsState,
          contactIds: contactsToEdit,
        }),
      );
    } else {
      dispatch(
        setWhitelistGroups({
          isShared,
          groups: groupsState,
          contactIds: contactsToEdit,
        }),
      );
    }
  }

  function handleClose(event?: ReactMouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent) {
    event?.stopPropagation();

    handleClosure();
  }

  useOnMountCondition(fetchGroups, true);

  useEffect(() => {
    if (response) {
      if (isShared) {
        dispatch(clearSetSharedContactsGroups());
        handleClosure();
      } else {
        dispatch(clearSetWhitelistGroups());
        handleClosure();
      }
    }
  }, [dispatch, fetchGroups, handleClosure, isShared, response]);

  return (
    <Modal
      maxWidth="xs"
      scroll="paper"
      onCancel={handleClose}
      onConfirm={handleConfirm}
      onPressEscape={handleClose}
      isModalDisabled={isRunning}
      isConfirmLoading={isRunning}
      isConfirmDisabled={isRunning}
      title={t("CONTACTS.MANAGE_GROUPS")}
    >
      <Box py={3} pr="15%">
        <FormRow
          labelGridWidth={4}
          label={t("CONTACTS.GROUPS")}
          helperText={groupsDiffer && hasActions ? t("CONTACTS.ALL_PREVIOUS_OVERWRITTEN") : ""}
        >
          <GroupAutoComplete
            isShared={isShared}
            hasActions={hasActions}
            groupsState={groupsState}
            setGroupsState={setGroupsState}
          />
        </FormRow>
      </Box>
    </Modal>
  );
}

export default ManageGroupsModal;
