import { useEffect } from "react";

import useBool from "hooks/useBool";
import SendFax from "views/SendFax/SendFax";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { useSendFaxHelpers } from "views/SendFax/contexts/helpers";
import { Destination, SendFaxFiles, SendFaxInitiatedFrom } from "views/SendFax/contexts/store";

interface InitSendFaxProps {
  files?: SendFaxFiles;
  destination?: Destination[];
  initiatedFrom?: SendFaxInitiatedFrom;
}

function InitSendFax({ initiatedFrom, destination, files }: InitSendFaxProps) {
  const { sendFaxDispatch } = useSendFaxContext();
  const { addFiles, addDestinations } = useSendFaxHelpers();

  const [areFilesInitialized, areFilesInitializedBool] = useBool(false);
  const [isDestinationInitialized, isDestinationInitializedBool] = useBool(false);
  const [isInitiatedFromInitialized, isInitiatedFromInitializedBool] = useBool(false);

  useEffect(() => {
    if (files?.length && !areFilesInitialized) {
      addFiles(files);
      areFilesInitializedBool.setTrue();
    }
  }, [addFiles, areFilesInitialized, areFilesInitializedBool, files]);

  useEffect(() => {
    if (destination && !isDestinationInitialized) {
      addDestinations(destination);
      isDestinationInitializedBool.setTrue();
    }
  }, [addDestinations, destination, isDestinationInitialized, isDestinationInitializedBool]);

  useEffect(() => {
    if (initiatedFrom && !isInitiatedFromInitialized) {
      sendFaxDispatch({
        type: "SET_INITIATED_FROM",
        payload: initiatedFrom,
      });
      isInitiatedFromInitializedBool.setTrue();
    }
  }, [initiatedFrom, isInitiatedFromInitialized, isInitiatedFromInitializedBool, sendFaxDispatch]);

  return <SendFax />;
}

export default InitSendFax;
