import {
  ADMIN_DOMAIN,
  MARKETING_WEBSITE_URL,
  SIGN_APP_DOMAIN,
  SIGN_PLUS_DOCUMENT_REDIRECT_URL,
} from "config";
import { boxNames } from "enums/faxes";

const FAX_PLUS = MARKETING_WEBSITE_URL;

export const urls = {
  homePage: MARKETING_WEBSITE_URL ?? "https://www.fax.plus",
  scanPlus: "https://www.scan.plus",
  appSignPlus: "https://app.sign.plus",
  login: "/login",
  signup: {
    root: "/signup",
    corporateMember: "/signup/corporate-member",
  },
  invite: "/invite/:token",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  verifyEmail: "/verify-email",
  dashboard: {
    root: "/dashboard",
    users: "/dashboard/users",
    company: "/dashboard/company",
  },
  users: "/users",
  numbers: "/numbers",
  faxes: {
    root: "/faxes",
    inbox: `/faxes/${boxNames.inbox}`,
    sent: `/faxes/${boxNames.sentbox}`,
    outbox: `/faxes/${boxNames.outbox}`,
    trash: `/faxes/${boxNames.trashbox}`,
  },
  documentFax: "/document-fax",
  contacts: {
    root: "/contacts",
    blacklist: "/contacts/blacklist",
    recent: "/contacts/recent",
    groups: "/contacts/groups",
    sharedGroups: "/contacts/shared-groups",
    sharedContacts: "/contacts/shared-contacts",
  },
  sendFax: "/send-fax",
  resendFax: "/resend-fax",
  forwardFax: "/forward-fax",
  replyFax: "/reply-fax",
  inlineFrameToFax: "/iframe-upload",
  sendFaxGoogleDrive: "/send-fax-google-drive",
  profile: {
    root: "/profile",
    general: "/profile/general",
    planAndBilling: "/profile/plan_billing",
    security: "/profile/security",
    compliance: "/profile/compliance",
    integrations: "/profile/integrations",
  },
  dataDeletionRequest: "/data-deletion",
  dataExportRequest: "/export",
  confirmation: "/confirmation/:token",
  unsubscribe: "/unsubscribe",
  resubscribe: "/resubscribe",
  authenticatePayment: {
    root: "/authenticate_payment",
    secret: "/authenticate_payment/:client_secret",
  },
  oneDrive: "/one-drive",
  iframe: {
    upload: "/iframe/upload",
  },
} as const;

export const websiteUrls = {
  alohi: "https://www.alohi.com",
  legal: "https://www.alohi.com/legal/",
  terms: "https://www.alohi.com/terms-service/",
  contactUs: "https://www.alohi.com/contact-us/",
  compliance: "https://www.alohi.com/trust/compliance/",
  security: "https://www.alohi.com/trust/service-security/",
  iOSAppStore: "https://apps.apple.com/app/fax-plus-receive-send-fax/id1170782544",
  androidAppStore: "https://play.google.com/store/apps/details?id=plus.fax.android&hl=en_US",
  systemStatus: "https://status.fax.plus/",
  homepage: FAX_PLUS,
  pricing: `${FAX_PLUS}/pricing`,
  hipaa: `${FAX_PLUS}/hipaa-fax-help`,
  advancedSecurity: `${FAX_PLUS}/advanced-security`,
  privacyPolicy: `${FAX_PLUS}/privacy-policy/`,
  cdrRedirection: (token: string) => `${SIGN_PLUS_DOCUMENT_REDIRECT_URL}${token}`,
  supportEmail: "support@alohi.com",
  jpBundle: "https://www.twilio.com/en-us/guidelines/jp/regulatory",
};

export const helpCenterUrls = {
  homepage: "https://help.fax.plus",
  teamSize: `${FAX_PLUS}/team-size-help`,
  sso: `${FAX_PLUS}/sso-help`,
  emailRecipient: `${FAX_PLUS}/email-recipient`,
  optimize: `${FAX_PLUS}/optimizehelp`,
  setupTwoFa: `${FAX_PLUS}/setup-2fa-help`,
  addressVerification: `${FAX_PLUS}/address-verification-help`,
  slack: `${FAX_PLUS}/slack-help`,
  dataResidency: `${FAX_PLUS}/dataresidency-help`,
  documentRetention: `${FAX_PLUS}/document-retention-help`,
  defaultCallerId: `${FAX_PLUS}/default_caller_id_help`,
  api: `${FAX_PLUS}/api-help`,
  advancedSecurity: `${FAX_PLUS}/advanced-security`,
  defaultCoverSheet: `${FAX_PLUS}/cover-sheet-help`,
  faxReception: `${FAX_PLUS}/fax-reception-help`,
  regulatoryCompliance: `${FAX_PLUS}/regulatory-documents-help`,
  addNoteFax: `${FAX_PLUS}/help/add-note-to-fax`,
  shareContacts: `${FAX_PLUS}/help/how-to-share-contacts`,
  mobile: `${FAX_PLUS}/mobile`,
  contactSales: `${FAX_PLUS}/contact-sales`,
  slackChannelId: `${FAX_PLUS}/slack-notification-help`,
  massFaxing: `${FAX_PLUS}/high-volume-fax-option`,
  coverSheetHelp: `${FAX_PLUS}/cover-sheet-help`,
  coverSheetEnforcement: `${FAX_PLUS}/cover-sheet-enforcement-help`,
  blockedAccount: `${FAX_PLUS}/account-blocked-help`,
  ssoAlohi: `${FAX_PLUS}/alohi-sso-help`,
  sendFaxForFree: `https://help.alohi.com/hc/en-us/articles/11178108602012-How-can-I-send-a-fax-for-free`,
};

export const developerUrls = {
  apiDocs: "https://apidoc.fax.plus/",
  github: "https://github.com/alohihq",
  scimTenantUrl: "https://restapi.fax.plus/scim/v2",
  scimTenantUrlAlohi: "https://sso.alohi.com/scim/v2",
};

export const allowedRoutesForSuggestingMobile = [
  urls.login,
  urls.signup.root,
  urls.signup.corporateMember,
  urls.dashboard.root,
  urls.dashboard.company,
  urls.dashboard.users,
  urls.users,
  urls.numbers,
  urls.faxes.root,
  urls.faxes.inbox,
  urls.faxes.outbox,
  urls.faxes.sent,
  urls.faxes.trash,
  urls.sendFax,
  urls.contacts.root,
  urls.contacts.blacklist,
  urls.contacts.recent,
  urls.contacts.groups,
  urls.contacts.sharedContacts,
  urls.contacts.sharedGroups,
  urls.profile.root,
  urls.profile.general,
  urls.profile.compliance,
  urls.profile.security,
  urls.profile.integrations,
  urls.profile.planAndBilling,
];

export const signPlusUrls = {
  home: `${SIGN_APP_DOMAIN}/`,
  profile: {
    general: `${SIGN_APP_DOMAIN}/profile/general`,
    plans: `${SIGN_APP_DOMAIN}/profile/plans`,
    security: `${SIGN_APP_DOMAIN}/profile/security`,
    compliance: `${SIGN_APP_DOMAIN}/profile/compliance`,
    integrations: `${SIGN_APP_DOMAIN}/profile/integrations`,
  },
};

export const alohiAdminUrls = {
  home: `${ADMIN_DOMAIN}/`,
  fax: {
    upgrade: `${ADMIN_DOMAIN}/fax/upgrade`,
    addNumber: `${ADMIN_DOMAIN}/fax/add-number`,
    changeNumber: `${ADMIN_DOMAIN}/fax/change-number`,
    highVolume: `${ADMIN_DOMAIN}/fax/upgrade?high-volume=true`,
  },
  sign: {
    upgrade: `${ADMIN_DOMAIN}/sign/upgrade`,
  },
};

export const filterQueryParams = (params: { filter: string[]; currentParams: URLSearchParams }) => {
  const newParams = new URLSearchParams();
  params.filter.forEach((key) => {
    const value = params.currentParams.get(key);
    if (value) {
      newParams.set(key, value);
    }
  });
  return newParams;
};

export function stripQueryParams(url: string): string {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.origin + parsedUrl.pathname;
  } catch {
    return url;
  }
}
