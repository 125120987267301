import { useTranslation } from "react-i18next";
import { makeStyles, IconButton, Box } from "@alohi/kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import useBool from "hooks/useBool";
import TextIcon from "assets/images/file-icons/text.svg?react";
import { List, ListItem, ListItemText, ListItemAvatar } from "ui";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import ConfirmDeleteFileItemModal from "../modals/ConfirmDeleteFileItemModal";

interface DefaultCoverSheetListItemProps {
  openCreateCoverSheet: () => void;
}

function DefaultCoverSheetListItem({ openCreateCoverSheet }: DefaultCoverSheetListItemProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { sendFaxStore, sendFaxDispatch } = useSendFaxContext();

  const [isConfirmDeleteModalOpen, isConfirmDeleteModalOpenHandlers] = useBool(false);

  const handleConfirmDeleteModal = (isConfirmed: boolean) => {
    isConfirmDeleteModalOpenHandlers.setFalse();

    if (isConfirmed) {
      sendFaxDispatch({ type: "CLEAR_DEFAULT_COVER_SHEET" });
    }
  };

  return (
    <List className={classes.listStyle}>
      <ListItem dense className={classes.listItem} onClick={openCreateCoverSheet}>
        <ListItemAvatar>
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <FontAwesomeIcon icon={faSort} size="1x" color="transparent" />
            <TextIcon width={32} height={32} />
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={t("SENT_FAX.DEFAULT_COVER_SHEET")}
          secondary={t("SENT_FAX.COVER_SHEET_FROM", {
            from: sendFaxStore.defaultCoverSheet?.from,
          })}
          classes={{
            primary: classes.listItemPrimaryText,
            secondary: classes.listItemSecondaryText,
          }}
        />
        <IconButton
          size="small"
          color="secondary"
          icon={faTrashAlt}
          aria-label="Delete"
          onClick={(event) => {
            if (event) {
              event.stopPropagation();
            }
            isConfirmDeleteModalOpenHandlers.setTrue();
          }}
        />
      </ListItem>

      {isConfirmDeleteModalOpen ? (
        <ConfirmDeleteFileItemModal
          handleClosure={handleConfirmDeleteModal}
          description={t("DELETE_ITEM.ARE_YOU_SURE_DELETE_COVER_SHEET")}
        />
      ) : null}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  listStyle: {
    width: "100%",
    paddingBottom: 0,
    cursor: "pointer",
    background: "transparent",
    paddingTop: theme.spacing(1),
  },
  listItem: {
    background: theme.alohi.lightestBlue,
    borderRadius: theme.shape.borderRadius,
  },
  listItemPrimaryText: {
    display: "block",
    overflow: "hidden",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    color: theme.alohi.gray,
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  listItemSecondaryText: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
    color: theme.alohi.lightGray,
  },
}));

export default DefaultCoverSheetListItem;
