import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, makeStyles, Typography, Box, useSnackbar } from "@alohi/kit";

import Avatar from "components/Core/Avatar/Avatar";
import { API_STORAGE } from "config";
import useTitle from "hooks/useTitle";
import LinearInfiniteProgress from "components/Core/LinearInfiniteProgress/LinearInfiniteProgress";
import { selectAccountInfo } from "selectors/account.selector";
import {
  selectAuthNToken,
  selectClientPublicInfo,
  selectIsAuthorizeAppError,
  selectAppAuthorizationRedirection,
  selectIsCancelAppAuthorizationError,
  selectIsAuthorizeAppErrorInvalidAuthorization,
} from "selectors/integrations.selector";
import {
  authorizeApp,
  getAuthNToken,
  getClientPublicInfo,
  cancelAppAuthorization,
} from "stores/reducers/integrations.apps.reducer";

function Oauth() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const authNToken = useSelector(selectAuthNToken);
  const clientPublicInfo = useSelector(selectClientPublicInfo);

  const isAllowError = useSelector(selectIsAuthorizeAppError);
  const allowErrorInvalid = useSelector(selectIsAuthorizeAppErrorInvalidAuthorization);
  const isCancelError = useSelector(selectIsCancelAppAuthorizationError);
  const redirectTo = useSelector(selectAppAuthorizationRedirection);
  const accountInfo = useSelector(selectAccountInfo);

  const profileImage = accountInfo?.profileImage ? `${API_STORAGE}${accountInfo.profileImage}` : "";
  const { search } = useLocation();
  const { authz_token: authZToken, client_id: clientId, state } = queryString.parse(search);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useTitle(t("WEB_SERVICE.TITLE_PAGE"));

  useEffect(() => {
    dispatch(getClientPublicInfo({ clientId }));
    dispatch(getAuthNToken());
  }, [clientId, dispatch]);

  useEffect(() => {
    if (redirectTo) window.location.href = redirectTo;
  }, [redirectTo]);

  const handleAllow = () => {
    setIsButtonLoading(true);
    dispatch(
      authorizeApp({
        authZToken,
        authNToken,
        state,
      }),
    );
  };

  const handleCancel = () => {
    dispatch(
      cancelAppAuthorization({
        authZToken,
        authNToken,
        state,
      }),
    );
  };

  useEffect(() => {
    if (isAllowError || isCancelError) {
      setIsButtonLoading(false);
      if (allowErrorInvalid) {
        enqueueSnackbar(t("FORMS.INVALID_AUTHORIZATION"), { variant: "error" });
      } else {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      }
    }
  }, [isAllowError, allowErrorInvalid, isCancelError, enqueueSnackbar, t]);

  if (!clientPublicInfo) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.avatars}>
          <Avatar alt="profile-picture" src={profileImage} className={classes.image} />
          <LinearInfiniteProgress dotCount={8} wrapperStyle={{ padding: 0 }} />
          <Avatar
            alt="app-picture"
            src={`${API_STORAGE}/storage/api_logo?client_id=${clientId}`}
            className={classes.image}
          />
        </Box>

        <Typography className={classes.mainText}>
          <Trans
            i18nKey="OAUTH.MANAGEMENT"
            components={{ strong: <strong /> }}
            values={{ value_1: clientPublicInfo?.fullname ?? "" }}
          />
        </Typography>

        <Typography className={classes.secondText}>
          <Trans
            i18nKey="OAUTH.MORE_INFO_DESCRIPTION"
            components={{ strong: <strong /> }}
            values={{ value_1: clientPublicInfo?.fullname ?? "" }}
          />
        </Typography>

        <Box className={classes.buttons}>
          <Button variant="gray" onClick={handleCancel}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button onClick={handleAllow} isLoading={isButtonLoading} isDisabled={isButtonLoading}>
            {t("COMMON.ALLOW")}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    maxWidth: 480,
    display: "flex",
    padding: spacing(5, 7),
    alignItems: "center",
    justifySelf: "center",
    height: "fit-content",
    flexDirection: "column",
    justifyContent: "center",
    margin: `${spacing(4)} auto 0 auto`,
  },
  image: {
    width: 80,
    height: 80,
  },
  buttons: {
    width: "350px",
    display: "flex",
    marginTop: spacing(5),
    justifyContent: "space-around",
  },
  avatars: {
    display: "flex",
    gap: spacing(2),
    alignItems: "center",
    justifyContent: "center",
    marginBottom: spacing(5),
  },
  errorContainer: {
    whiteSpace: "pre",
    fontSize: "0.875rem",
    marginTop: spacing(4),
  },
  mainText: {
    textAlign: "center",
  },
  secondText: {
    textAlign: "center",
    marginTop: spacing(5),
  },
}));

export default Oauth;
