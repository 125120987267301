import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { Divider, Menu, MenuItem, FaButton, Typography, Box } from "@alohi/kit";

import { boxNames } from "enums/faxes";
import { selectCdrId } from "selectors/faxes.selector";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";
import ConfirmDeleteFaxModal from "views/Faxes/ConfirmDeleteFaxModal";

function TrashboxActions({ dataIndex }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialCdrId = useSelector(selectCdrId(boxNames.trashbox, dataIndex), () => true);
  // Keep the ID of the original CDR, even when indexes change
  // Make sure that the menu is still tied to the CDR it was opened from
  const [cdrId, setCdrId] = useState(initialCdrId);

  const [actions, setActions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [confirmDeleteFaxDialog, setConfirmDeleteFaxModal] = useState({
    isOpen: false,
    cdrIds: null,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCdrId(initialCdrId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!anchorEl) {
      let newActions = [];
      newActions.push({
        label: t("COMMON.DELETE"),
        callback: () => {
          setConfirmDeleteFaxModal({
            isOpen: true,
            cdrIds: [cdrId],
          });
        },
      });
      newActions.push({
        label: t("COMMON.RESTORE"),
        callback: () => {
          dispatch(
            faxesAsyncActions.restore({
              boxName: boxNames.trashbox,
              cdrIds: [cdrId],
            }),
          );
        },
      });
      setActions(newActions);
    }
  }, [anchorEl, dispatch, cdrId, t, actions.length]);

  // Close modals when cdr is deleted and this component unmounts
  useEffect(() => {
    return () => {
      setConfirmDeleteFaxModal({
        isOpen: false,
        cdrIds: null,
      });
    };
  }, []);

  return (
    <>
      <Box textAlign="end">
        <FaButton
          aria-label="More"
          aria-haspopup="true"
          onClick={handleClick}
          aria-controls={`actions-menu-${dataIndex}`}
          icon={faEllipsisV}
        />
        <Menu
          id={`actions-menu-${dataIndex}`}
          anchorEl={anchorEl}
          open={!!anchorEl && !!actions}
          onClose={handleClose}
          elevation={2}
        >
          {actions.map((action, key) => {
            if (action.label === "Divider") {
              return (
                <Box py={1} key={key}>
                  <Divider />
                </Box>
              );
            } else {
              return (
                <MenuItem
                  key={action.label}
                  disabled={action.disabled}
                  onClick={() => {
                    action.callback();
                    handleClose();
                  }}
                >
                  <Typography style={{ fontSize: "0.875rem" }}>{action.label}</Typography>
                </MenuItem>
              );
            }
          })}
        </Menu>
      </Box>
      {confirmDeleteFaxDialog.isOpen && (
        <ConfirmDeleteFaxModal
          boxName={boxNames.trashbox}
          cdrIds={confirmDeleteFaxDialog.cdrIds}
          handleClosure={() =>
            setConfirmDeleteFaxModal({
              isOpen: false,
              cdrIds: null,
            })
          }
        />
      )}
    </>
  );
}

TrashboxActions.propTypes = {
  dataIndex: PropTypes.number.isRequired,
};

export default memo(TrashboxActions);
