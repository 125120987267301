import { Panel, Button } from "@alohi/kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ActivateTwoFaModal from "views/TwoFa/ActivateTwoFaModal";
import DeactivateTwoFaModal from "views/TwoFa/DeactivateTwoFaModal";
import { selectIsTwoFaActive } from "selectors/authentication.selector";
import { getTwoFaSettings } from "stores/reducers/twoFa.reducer";

function TwoFaRow() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isTwoFaActive = useSelector(selectIsTwoFaActive);

  const [isTwoFaActivationModalOpen, setIsTwoFaActivationModalOpen] = useState(false);
  const [isTwoFaDeactivationModalOpen, setIsTwoFaDeactivationModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getTwoFaSettings());
  }, [dispatch]);

  const handleOpenModal = () =>
    isTwoFaActive ? setIsTwoFaDeactivationModalOpen(true) : setIsTwoFaActivationModalOpen(true);

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">{t("PROFILE.2FA_TITLE")}</Panel.Cell>
        {isTwoFaActive !== null && (
          <Panel.Cell variant="data">
            {isTwoFaActive ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
          </Panel.Cell>
        )}
        {isTwoFaActive !== null && (
          <Panel.Cell variant="actions">
            <Button variant="white" onClick={handleOpenModal}>
              {isTwoFaActive ? t("PROFILE.DEACTIVATE") : t("PROFILE.ACTIVATE")}
            </Button>
          </Panel.Cell>
        )}
      </Panel.Row>

      {isTwoFaActivationModalOpen && (
        <ActivateTwoFaModal handleClosure={() => setIsTwoFaActivationModalOpen(false)} />
      )}

      {isTwoFaDeactivationModalOpen ? (
        <DeactivateTwoFaModal handleClosure={() => setIsTwoFaDeactivationModalOpen(false)} />
      ) : null}
    </>
  );
}

export default TwoFaRow;
