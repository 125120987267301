import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "stores/store";
import { extractPhoneNumbers, parseNumber } from "helpers/numbers";
import { verifyMimeMagicNumbers } from "helpers/file";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import { faxAllowedMimeTypes } from "enums/allowedFileTypes";
import { selectDefaultCountry } from "selectors/verifications.selector";
import {
  Destination,
  SendFaxFiles,
  Destinations,
  NewEntryDestination,
  GroupDestination,
  ContactDestination,
  RecentDestination,
} from "views/SendFax/contexts/store";
import { Contact, Group } from "stores/reducers/contacts.helpers";
import { RecentContact } from "stores/reducers/contacts.types";
import { shortenStringWithEllipsis } from "helpers/string";

export const MAX_UPLOAD_FILE_COUNT = 10;
export const MAX_UPLOAD_FILE_SIZE = 30; // 30 MB
// OneMB = 1024 x 1024 = 1048576 but we hard code 1000000 because of ngnix
export const OneMB = 1000000;

function useSendFaxHelpers() {
  const { t } = useTranslation();
  const { sendFaxStore, sendFaxDispatch } = useSendFaxContext();
  const { handleNumberValidation, handleNewEntryDestination } = useSendFaxDestinationHelpers();
  const { defaultCountry } = useAppSelector(selectDefaultCountry);

  const addFiles = useCallback(
    (newFiles: SendFaxFiles) => {
      const newFilesResult = sendFaxStore.files.slice();
      let totalFilesSize = sendFaxStore.files.reduce((acc, curr) => curr.size + acc, 0);

      const mimeVerificationPromises: Promise<{ file: SendFaxFiles[number] | null }>[] = [];

      for (let iteration = 0; iteration < newFiles.length; iteration++) {
        const currentFile = newFiles[iteration];

        if (!currentFile) {
          continue;
        }

        totalFilesSize += currentFile.size;

        const isAllowedFileType = faxAllowedMimeTypes.some(
          (element) => currentFile.mime.indexOf(element) !== -1,
        );

        if (!isAllowedFileType) {
          sendFaxDispatch({ type: "SET_ERROR", payload: t("FORMS.FILE_TYPE_NOT_ALLOWED") });
          continue;
        }

        if (currentFile.size > MAX_UPLOAD_FILE_SIZE * OneMB) {
          sendFaxDispatch({
            type: "SET_ERROR",
            payload: t("FORMS.LARGE_FILE", {
              replace: { size: MAX_UPLOAD_FILE_SIZE },
            }),
          });
          continue;
        }
        if (totalFilesSize > MAX_UPLOAD_FILE_SIZE * OneMB) {
          sendFaxDispatch({
            type: "SET_ERROR",
            payload: t("FORMS.MAX_TOTAL_FILE_SIZE", {
              replace: { size: MAX_UPLOAD_FILE_SIZE },
            }),
          });
          break;
        }

        const amountOfCoverSheets =
          (Boolean(sendFaxStore.defaultCoverSheet) ? 1 : 0) +
          (Boolean(sendFaxStore.sharedCoverSheet) ? 1 : 0) +
          (Boolean(sendFaxStore.galleryCoverSheet) ? 1 : 0);

        if (newFilesResult.length + newFiles.length + amountOfCoverSheets > MAX_UPLOAD_FILE_COUNT) {
          sendFaxDispatch({
            type: "SET_ERROR",
            payload: t("FORMS.MAX_UPLOAD_FILE", {
              replace: { count: MAX_UPLOAD_FILE_COUNT },
            }),
          });
          break;
        }

        if (currentFile.type === "faxFile") {
          if (["text/plain"].includes(currentFile.mime)) {
            // It's impossible to check the magic number of a text file (html, svg, .txt etc)
            // Consider it valid by returning a true value
            mimeVerificationPromises.push(new Promise((resolve) => resolve({ file: currentFile })));
          } else {
            mimeVerificationPromises.push(
              verifyMimeMagicNumbers(currentFile.file).then(
                (isValid) =>
                  new Promise((resolve) => resolve({ file: isValid ? currentFile : null })),
              ),
            );
          }
        } else {
          mimeVerificationPromises.push(new Promise((resolve) => resolve({ file: currentFile })));
        }
      }

      Promise.allSettled(mimeVerificationPromises).then((values) => {
        values.forEach((result) => {
          if (result.status === "rejected") {
            sendFaxDispatch({
              type: "SET_ERROR",
              payload: t("ADD_NUMBER.UPLOADED_DOCUMENT_IS_INVALID"),
            });
          } else if (result.status === "fulfilled") {
            const { file } = result.value;

            if (file) {
              newFilesResult.push(file);

              sendFaxDispatch({
                type: "REPLACE_FILES",
                payload: newFilesResult,
              });
            } else {
              sendFaxDispatch({
                type: "SET_ERROR",
                payload: t("ADD_NUMBER.UPLOADED_DOCUMENT_IS_INVALID"),
              });
            }
          }
        });
      });
    },
    [
      t,
      sendFaxDispatch,
      sendFaxStore.files,
      sendFaxStore.sharedCoverSheet,
      sendFaxStore.galleryCoverSheet,
      sendFaxStore.defaultCoverSheet,
    ],
  );

  const addDestinations = useCallback(
    (destinations: Destination[]) => {
      const contacts = destinations.filter(
        (destination) =>
          destination.icon === "newEntry" ||
          destination.icon === "contact" ||
          destination.icon === "recent" ||
          destination.icon === "sharedContact",
      );
      const groups = destinations.filter(
        (destination) => destination.icon === "group" || destination.icon === "sharedGroup",
      );
      const validatedContacts = contacts.filter(
        (destination) =>
          handleNumberValidation(destination.value, sendFaxStore.destinations).isValid,
      );
      const deduplicatedContacts = [
        ...new Map(validatedContacts.map((item) => [item["value"], item])).values(),
      ];

      if (deduplicatedContacts.length || groups) {
        sendFaxDispatch({
          type: "SET_DESTINATION",
          payload: [...deduplicatedContacts, ...groups],
        });
      } else {
        sendFaxDispatch({
          type: "SET_ERROR",
          payload: t("SENT_FAX.NUMBER_NOT_VALID", {
            replace: { number: destinations.join(" ") },
          }),
        });
      }
    },
    [handleNumberValidation, sendFaxDispatch, sendFaxStore.destinations, t],
  );

  const extractDestinations = useCallback(
    (params: {
      text: string;
      onDestinations: (destinations: NewEntryDestination[]) => void;
      onSuccess: (text: string) => void;
      onErrors: (error: string) => void;
    }) => {
      if (!params.text) return;

      const MAX_DISPLAYABLE_ERROR_NUMBERS = 2;
      const { numbers, isExtraText } = extractPhoneNumbers(params.text, defaultCountry);

      if (!numbers.length) {
        params.onErrors(
          t("SENT_FAX.NUMBER_NOT_VALID", {
            number: shortenStringWithEllipsis({ text: params.text, maxLength: 30 }),
          }),
        );
      }

      const newNumbersOptions: NewEntryDestination[] = [];
      const duplicatedNumbers: string[] = [];
      numbers.forEach((number) => {
        const { isValid, isDuplicate } = handleNumberValidation(number, sendFaxStore.destinations);
        if (isValid) {
          newNumbersOptions.push(handleNewEntryDestination(number));
        } else if (isDuplicate) {
          duplicatedNumbers.push(number);
        }
      });

      if (newNumbersOptions.length) {
        if (isExtraText) {
          params.onSuccess(
            newNumbersOptions.length === 1
              ? t("SENT_FAX.EXTRACTED_PHONE_NUMBER", {
                  number: newNumbersOptions.length,
                })
              : t("SENT_FAX.EXTRACTED_PHONE_NUMBER_plural", {
                  number: newNumbersOptions.length,
                }),
          );
        }
        params.onDestinations(newNumbersOptions);
      } else if (duplicatedNumbers.length) {
        if (duplicatedNumbers.length === 1) {
          params.onErrors(
            t("SENT_FAX.DUPLICATE_NUMBER", {
              number: duplicatedNumbers[0],
            }),
          );
        } else {
          params.onErrors(
            t("SENT_FAX.DUPLICATE_NUMBER_plural", {
              list: duplicatedNumbers
                .slice(0, Math.min(MAX_DISPLAYABLE_ERROR_NUMBERS, duplicatedNumbers.length))
                .toString()
                .replaceAll(",", ", ")
                .concat(duplicatedNumbers.length > MAX_DISPLAYABLE_ERROR_NUMBERS ? ", ..." : ""),
            }),
          );
        }
      }
    },
    [
      defaultCountry,
      handleNewEntryDestination,
      handleNumberValidation,
      sendFaxStore.destinations,
      t,
    ],
  );

  return {
    addFiles,
    addDestinations,
    extractDestinations,
  };
}

function useSendFaxDestinationHelpers() {
  const { defaultCountry } = useAppSelector(selectDefaultCountry);

  const parseNumberWithGeoLocation = useCallback(
    (input) => {
      const parsedInput = input.replace(/ /g, "");
      const { number, extension } = parseNumber(parsedInput, defaultCountry);
      return number + extension;
    },
    [defaultCountry],
  );

  const handleNumberValidation = useCallback(
    (inputValue: string, destinations: Destinations) => {
      const parsedNumber = parseNumberWithGeoLocation(inputValue);
      const isDuplicate = !!destinations.find((element) => element.value === parsedNumber);

      return { isValid: !!parsedNumber && !isDuplicate, isDuplicate: isDuplicate };
    },
    [parseNumberWithGeoLocation],
  );

  const handleNewEntryDestination = useCallback(
    (inputValue: string): NewEntryDestination => {
      const parsedNumber = parseNumberWithGeoLocation(inputValue);

      return {
        isNew: true,
        target: "to",
        icon: "newEntry",
        value: parsedNumber,
        caption: parsedNumber,
        label: {
          number: parsedNumber,
          geoLocation: defaultCountry,
        },
      };
    },
    [defaultCountry, parseNumberWithGeoLocation],
  );

  const handleGroupDestination = useCallback(
    (group: Group, icon: "group" | "sharedGroup"): GroupDestination => {
      return {
        icon,
        value: group.id,
        target: "tag_ids",
        label: group.name || "Group",
        membersCount: group.membersCount,
        caption: group.membersCount + " member" + (group.membersCount !== 1 ? "s" : ""),
      };
    },
    [],
  );

  const handleContactDestination = useCallback(
    (contact: Contact, icon: "contact" | "sharedContact"): ContactDestination => {
      return {
        icon,
        target: "to",
        value: contact.faxNumber,
        label: contact.fullName || contact.faxNumber,
        caption: contact.fullName ? contact.faxNumber : "",
      };
    },
    [],
  );

  const handleRecentDestination = useCallback((recent: RecentContact): RecentDestination => {
    return {
      target: "to",
      icon: "recent",
      value: recent.faxNumber,
      label: recent.faxNumber,
      caption: recent.faxNumber,
    };
  }, []);

  return {
    handleGroupDestination,
    handleNumberValidation,
    handleRecentDestination,
    handleContactDestination,
    handleNewEntryDestination,
    parseNumberWithGeoLocation,
  };
}

export { useSendFaxHelpers, useSendFaxDestinationHelpers };
