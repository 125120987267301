import clsx from "clsx";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@alohi/kit";

interface DotProps extends BoxProps {
  size?: number;
  variant: "red" | "green" | "yellow";
}

interface StyleProps {
  size: DotProps["size"];
  variant: DotProps["variant"];
}

function JustDot({ size = 8, variant = "green", className, ...rest }: DotProps) {
  const classes = useStyles({ variant, size });

  return <Box className={clsx(classes.root, className)} {...rest} />;
}

const useStyles = makeStyles((theme) => ({
  root: (props: StyleProps) => ({
    width: props.size,
    height: props.size,
    borderRadius: "50%",
    backgroundColor: {
      green: theme.alohi.green,
      red: theme.alohi.red,
      yellow: theme.alohi.yellow,
    }[props.variant],
  }),
}));

export default JustDot;
