import { useCallback } from "react";
import { useSnackbar } from "@alohi/kit";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import dataCy from "enums/dataCy";

type UseLocalFilesPickerProps = {
  allowedMimes: string[];
  onAddDocuments: (documents: FileList | File[]) => void;
};

function useLocalFilesPicker({ allowedMimes, onAddDocuments }: UseLocalFilesPickerProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (rejectedFiles.length > 0) {
        enqueueSnackbar(t("FORMS.FILE_TYPE_NOT_ALLOWED"), { variant: "error" });
      }

      if (acceptedFiles.length > 0) {
        onAddDocuments(acceptedFiles);
      }
    },
    [enqueueSnackbar, onAddDocuments, t],
  );

  const { open, getInputProps, isDragActive, getRootProps } = useDropzone({
    onDrop,
    noClick: true,
    noDragEventsBubbling: true,
    accept: allowedMimes.join(", "),
  });

  return {
    open,
    isDragActive,
    getRootProps,
    loadLocalFiles: open,
    Input: (
      <input {...getInputProps()} data-cy={dataCy.filePicker} accept={allowedMimes.join(", ")} />
    ),
  };
}

export default useLocalFilesPicker;
