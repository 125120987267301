import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, makeStyles } from "@alohi/kit";

export enum SUBJECTS {
  SUBJECT = "subject",
  TECHNICAL = "technical",
  SALES = "sales",
  PUBLIC_RELATIONS = "publicRelations",
  OTHER = "other",
}

interface SelectSubjectProps {
  blocked?: boolean;
  className?: string;
  selectedSubject: SUBJECTS;
  onSelectedSubject: (subject: SUBJECTS) => void;
}

function SelectSubject({
  className,
  blocked,
  selectedSubject,
  onSelectedSubject,
}: SelectSubjectProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const getProductName = (product: string) => {
    switch (product) {
      case SUBJECTS.SUBJECT:
        return t("CONTACT_US.SUBJECT_SUBJECT");
      case SUBJECTS.TECHNICAL:
        return t("CONTACT_US.SUBJECT_TECHNICAL");
      case SUBJECTS.SALES:
        return t("CONTACT_US.SUBJECT_SALES");
      case SUBJECTS.PUBLIC_RELATIONS:
        return t("CONTACT_US.SUBJECT_PUBLIC_RELATIONS");
      case SUBJECTS.OTHER:
        return t("CONTACT_US.SUBJECT_OTHER");
      default:
        return "";
    }
  };

  return (
    <Select
      className={clsx(classes.select, className)}
      fullWidth
      value={blocked ? t("PROFILE.ACCOUNT_BLOCKED") : selectedSubject}
      onChange={({ target }) => onSelectedSubject(target.value as SUBJECTS)}
    >
      {blocked ? (
        <MenuItem value={t("PROFILE.ACCOUNT_BLOCKED")}>{t("PROFILE.ACCOUNT_BLOCKED")}</MenuItem>
      ) : (
        Object.values(SUBJECTS).map((product) => (
          <MenuItem
            sx={{ display: product === SUBJECTS.SUBJECT ? "none" : undefined }}
            disabled={product === SUBJECTS.SUBJECT}
            key={product}
            value={product}
          >
            {getProductName(product)}
          </MenuItem>
        ))
      )}
    </Select>
  );
}

const useStyles = makeStyles(() => ({
  select: {},
}));

export default SelectSubject;
