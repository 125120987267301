import { Box } from "@alohi/kit";
import { Trans } from "react-i18next";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "ui";

function PleaseContactAdmin() {
  return (
    <Box style={{ display: "inline-block" }}>
      <FontAwesomeIcon style={{ marginRight: 8 }} icon={faLock} />
      <Typography variant="caption">
        <Trans i18nKey="PROFILE.PLEASE_CONTACT_YOUR_ADMIN" />
      </Typography>
    </Box>
  );
}

export default PleaseContactAdmin;
