import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Box, useSnackbar, Button, Modal, Typography } from "@alohi/kit";

import useBool from "hooks/useBool";
import { plans } from "enums/plans";
import { getCustomerInfo } from "stores/reducers/account.reducer";
import { selectIsPlanEnterprise, selectPlanType } from "selectors/plan.selector";
import ContactAdminToUseFeature from "views/ContactAdmin/ContactAdminToUseFeature";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import {
  selectAccountIsCorporateMember,
  selectIsAdvancedSecurityError,
  selectIsAdvancedSecurityRunning,
  selectIsAdvancedSecuritySuccess,
  selectIsEnabledAdvancedSecurity,
} from "selectors/account.selector";
import {
  activateAdvancedSecurity,
  clearActivateAdvancedSecurity,
} from "stores/reducers/account.hipaa.reducer";
import ActivationModalTitle from "./ActivationModalTitle";

const VIEWS = {
  NONE: "none",
  UPGRADE_PLAN: "upgradePlan",
  UNACTIVATE: "unactivate",
  CONFIRM_ACTIVATE: "confirmActivate",
  CORPORATE_MEMBER_VIEW: "corporateMemberView",
};

function ActivateAdvancedSecurityButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentPlan = useSelector(selectPlanType);
  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);
  const isAscEnabled = useSelector(selectIsEnabledAdvancedSecurity);

  const isAdvancedSecurityError = useSelector(selectIsAdvancedSecurityError);
  const isAdvancedSecurityRunning = useSelector(selectIsAdvancedSecurityRunning);
  const isAdvancedSecuritySuccess = useSelector(selectIsAdvancedSecuritySuccess);

  const [canActivate, canActivateHandler] = useBool(false);
  const [currentView, setCurrentView] = useState(VIEWS.NONE);

  const handleAction = useCallback(() => {
    if (isAscEnabled) {
      setCurrentView(VIEWS.UNACTIVATE);
    } else if (isCorporateMember) {
      setCurrentView(VIEWS.CORPORATE_MEMBER_VIEW);
    } else if (!isPlanEnterprise) {
      setCurrentView(VIEWS.UPGRADE_PLAN);
    } else {
      setCurrentView(VIEWS.CONFIRM_ACTIVATE);
    }
  }, [isPlanEnterprise, isAscEnabled, isCorporateMember]);

  const handleUpgradePlan = useCallback(
    (isSuccess) => {
      if (isSuccess) {
        canActivateHandler.setTrue();
      }
      setCurrentView(VIEWS.NONE);
    },
    [canActivateHandler],
  );

  const handleConfirmation = useCallback(() => {
    dispatch(activateAdvancedSecurity());
    dispatch(clearActivateAdvancedSecurity());
    setCurrentView(VIEWS.NONE);
  }, [dispatch]);

  useEffect(() => {
    if (canActivate && currentPlan === plans.enterprise) {
      setCurrentView(VIEWS.CONFIRM_ACTIVATE);
      canActivateHandler.toggle();
    }
  }, [canActivate, canActivateHandler, currentPlan]);

  useEffect(() => {
    if (isAdvancedSecurityError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearActivateAdvancedSecurity());
    }
    if (isAdvancedSecuritySuccess) {
      enqueueSnackbar(t("PROFILE.HIPAA_ADVANCED_SECURITY_SUCCESS"), {
        variant: "success",
      });
      dispatch(getCustomerInfo());
      dispatch(clearActivateAdvancedSecurity());
    }
  }, [enqueueSnackbar, t, isAdvancedSecuritySuccess, isAdvancedSecurityError, dispatch]);

  return (
    <>
      {!isAscEnabled ? (
        <Button variant="white" onClick={handleAction}>
          {t("PROFILE.HIPAA_ACTIVATE")}
        </Button>
      ) : (
        <Button variant="white" onClick={handleAction}>
          {t("PROFILE.HIPAA_DISABLE")}
        </Button>
      )}
      {
        {
          [VIEWS.NONE]: null,
          [VIEWS.CONFIRM_ACTIVATE]: (
            <Modal
              maxWidth="xs"
              onConfirm={handleConfirmation}
              title={<ActivationModalTitle />}
              onCancel={() => setCurrentView(VIEWS.NONE)}
              isModalDisabled={isAdvancedSecurityRunning}
              isConfirmLoading={isAdvancedSecurityRunning}
              confirmTitle={t("PROFILE.HIPAA_ACTIVATE")}
            >
              <Box p={3}>
                <Typography type="body">
                  <Trans i18nKey={"PROFILE.HIPAA_ACTIVATE_ADVANCED_QUESTION"} />
                </Typography>
              </Box>
            </Modal>
          ),
          [VIEWS.UNACTIVATE]: (
            <Modal
              maxWidth="xs"
              confirmTitle={t("COMMON.CLOSE")}
              onConfirm={() => setCurrentView(VIEWS.NONE)}
              title={t("PROFILE.HIPAA_DISABLE_ADVANCED_TITLE")}
            >
              <Box p={3}>
                <Typography type="body">
                  <Trans i18nKey={"PROFILE.HIPAA_DISABLE_ADVANCED_INFO"} />
                </Typography>
              </Box>
            </Modal>
          ),
          [VIEWS.UPGRADE_PLAN]: (
            <RequireUpgradeToEnterprise
              handleClosure={handleUpgradePlan}
              title={t("PROFILE.HIPAA_ADVANCED_SECURITY")}
              featureName={t("PROFILE.HIPAA_ADVANCED_SECURITY")}
            />
          ),
          [VIEWS.CORPORATE_MEMBER_VIEW]: (
            <ContactAdminToUseFeature
              handleClosure={() => setCurrentView(VIEWS.NONE)}
              title={t("PROFILE.HIPAA_ADVANCED_SECURITY")}
              description={t("PROFILE.HIPAA_UPGRADE_FEATURE")}
            />
          ),
        }[currentView]
      }
    </>
  );
}

export default ActivateAdvancedSecurityButton;
