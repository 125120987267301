import { createAction, createSlice } from "@reduxjs/toolkit";

import { boxNames } from "../../enums/faxes";
import { orderBySendTimeDate } from "../../helpers/date";
import { faxesAsyncActions, setAllCdrCheckbox, toggleCdrCheckbox } from "./faxes.reducer";
import { logout } from "./authentication.reducer";

const defaultFetchOptions = {
  url: "numbers/all/applications/applicationId/outbox",
  direction: "",
  limit: "",
  type: "",
};

const initialState = {
  records: null,
  currentFetchOptions: defaultFetchOptions,
  thumbnails: null,
  cdrIdOfLoading: null,
};

export const clearOutbox = createAction("OUTBOX/CLEAR");

const { reducer } = createSlice({
  name: "OUTBOX",
  initialState,
  extraReducers: {
    [clearOutbox]: () => initialState,
    [toggleCdrCheckbox]: (state, { payload: { cdrId, boxName } }) => {
      if (boxName !== boxNames.outbox) return;
      for (let i = 0; i < state.records.length; i++) {
        if (state.records[i].id === cdrId) {
          state.records[i].isChecked = !state.records[i].isChecked;
          break;
        }
      }
    },
    [setAllCdrCheckbox]: (state, { payload: { value, boxName } }) => {
      if (boxName !== boxNames.outbox) return;
      state.records = state.records?.map((record) => ({ ...record, isChecked: value }));
    },
    [faxesAsyncActions.fetchBox.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.outbox) {
        return;
      }
      state.currentFetchOptions = payload.fetchOptions;
      state.records = (payload.response.records || []).slice().sort(orderBySendTimeDate);
    },
    [faxesAsyncActions.fetchBox.rejected]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.records = [];
      state.currentFetchOptions = action.meta.arg.fetchOptions;
    },
    [faxesAsyncActions.fetchThumbnails.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.outbox) {
        return;
      }
      if (payload.response) {
        state.thumbnails = { ...state.thumbnails, ...payload.response };
      }
    },
    [faxesAsyncActions.addNoteOnFax.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.outbox) {
        return;
      }
      for (let i = 0; i < state.records.length; i++) {
        if (state.records[i].id === payload.cdrId) {
          state.records[i].comment.text = payload.note;
          break;
        }
      }
    },
    [faxesAsyncActions.deleteFax.fulfilled]: (state, { payload }) => {
      if (payload.boxName !== boxNames.outbox) {
        return;
      }
      const set = new Set(payload.cdrIds);
      state.records = state.records?.reduce((acc, curr) => {
        if (!set.has(curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);
    },
    [faxesAsyncActions.previewFile.pending]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.cdrIdOfLoading = action.meta.arg.cdrId;
    },
    [faxesAsyncActions.previewFile.fulfilled]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.cdrIdOfLoading = null;
    },
    [faxesAsyncActions.previewFile.rejected]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.cdrIdOfLoading = null;
    },
    [faxesAsyncActions.download.pending]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.cdrIdOfLoading = action.meta.arg.cdrId;
    },
    [faxesAsyncActions.download.fulfilled]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.cdrIdOfLoading = null;
    },
    [faxesAsyncActions.download.rejected]: (state, action) => {
      if (action.meta.arg.boxName !== boxNames.outbox) return;
      state.cdrIdOfLoading = null;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
