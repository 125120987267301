import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Typography } from "ui";
import { formatDefaultDate } from "helpers/date";
import { NO_EXPIRATION_DATE } from "../../../modals/CreateTokenModal";

interface InfosProps {
  token_str: string;
  expires_at: number;
}

const Infos = ({ token_str, expires_at }: InfosProps) => {
  const { t } = useTranslation();

  const expiresDate = dayjs.unix(expires_at);
  const isExpired = expiresDate.unix() < dayjs().unix();
  return (
    <>
      <Typography variant="warning">{token_str.replace("***", "\u2217\u2217\u2217")}</Typography>
      <Typography variant={isExpired ? "voided" : "signatureConsent"}>
        {expires_at === NO_EXPIRATION_DATE.unix()
          ? t("API_TOKEN.EXPIRES_ON", {
              date: t("COMMON.NEVER"),
            })
          : isExpired
          ? t("API_TOKEN.EXPIRED_ON", {
              date: formatDefaultDate(expiresDate.toString()),
            })
          : t("API_TOKEN.EXPIRES_ON", {
              date: formatDefaultDate(expiresDate.toString()),
            })}
      </Typography>
    </>
  );
};

export default Infos;
