import { ActionReducerMapBuilder, createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { RequestStatus, initialRequestState, FetchError } from "enums/requestStatus";
import { accountsApi } from "../../api/accounts.api";
import { accountTypes } from "../../enums/account";
import { selectAccountCid } from "../../selectors/account.selector";
import { RootState } from "../store";

export const initialPermissionsState = {
  setAsAdmin: initialRequestState,
  removeAsAdmin: initialRequestState,
  transferOwnership: initialRequestState,
};

const setAsAdmin = createAsyncThunk<
  { response: unknown },
  { userUid: string },
  { rejectValue: FetchError["origin"] }
>("ACCOUNT/SET_AS_ADMIN", async ({ userUid }, { rejectWithValue, getState }) => {
  const cid = selectAccountCid(getState());
  try {
    const response = await accountsApi.setPermissions({
      uid: userUid,
      cid,
      permissionSet: accountTypes.corporateAdmin,
    });

    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const removeAsAdmin = createAsyncThunk<
  { response: unknown },
  { userUid: string },
  { rejectValue: FetchError["origin"] }
>("ACCOUNT/REMOVE_AS_ADMIN", async ({ userUid }, { rejectWithValue, getState }) => {
  const cid = selectAccountCid(getState());
  try {
    const response = await accountsApi.setPermissions({
      uid: userUid,
      cid,
      permissionSet: accountTypes.corporateMember,
    });

    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const transferOwnership = createAsyncThunk<
  { response: unknown },
  { newOwnerUid: string },
  { rejectValue: FetchError["origin"] }
>("ACCOUNT/TRANSFER_OWNERSHIP", async ({ newOwnerUid }, { rejectWithValue, getState }) => {
  const cid = selectAccountCid(getState());
  try {
    const response = await accountsApi.transferOwnership({
      newOwnerUid,
      cid,
    });
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const clearSetAsAdmin = createAction("ACCOUNT/CLEAR_SET_AS_ADMIN");
export const clearRemoveAsAdmin = createAction("ACCOUNT/CLEAR_REMOVE_AS_ADMIN");
export const clearTransferOwnership = createAction("ACCOUNT/CLEAR_TRANSFER_OWNERSHIP");

export const permissionsSlice = (builder: ActionReducerMapBuilder<RootState["account"]>) => {
  builder.addCase(setAsAdmin.pending, (state) => {
    state.setAsAdmin.status = RequestStatus.RUNNING;
    state.setAsAdmin.error = null;
  });
  builder.addCase(setAsAdmin.fulfilled, (state, { payload }) => {
    state.setAsAdmin.status = RequestStatus.IDLE;
    state.setAsAdmin.response = payload.response;
  });
  builder.addCase(setAsAdmin.rejected, (state, { payload }) => {
    state.setAsAdmin.status = RequestStatus.ERROR;
    state.setAsAdmin.error = payload;
  });
  builder.addCase(clearSetAsAdmin, (state) => {
    state.setAsAdmin = initialRequestState;
  });
  builder.addCase(removeAsAdmin.pending, (state) => {
    state.removeAsAdmin.status = RequestStatus.RUNNING;
    state.removeAsAdmin.error = null;
  });
  builder.addCase(removeAsAdmin.fulfilled, (state, { payload }) => {
    state.removeAsAdmin.status = RequestStatus.IDLE;
    state.removeAsAdmin.response = payload.response;
  });
  builder.addCase(removeAsAdmin.rejected, (state, { payload }) => {
    state.removeAsAdmin.status = RequestStatus.ERROR;
    state.removeAsAdmin.error = payload;
  });
  builder.addCase(clearRemoveAsAdmin, (state) => {
    state.removeAsAdmin = initialRequestState;
  });
  builder.addCase(transferOwnership.pending, (state) => {
    state.transferOwnership.status = RequestStatus.RUNNING;
    state.transferOwnership.error = null;
  });
  builder.addCase(transferOwnership.fulfilled, (state, { payload }) => {
    state.transferOwnership.status = RequestStatus.IDLE;
    state.transferOwnership.response = payload.response;
  });
  builder.addCase(transferOwnership.rejected, (state, { payload }) => {
    state.transferOwnership.status = RequestStatus.ERROR;
    state.transferOwnership.error = payload;
  });
  builder.addCase(clearTransferOwnership, (state) => {
    state.transferOwnership = initialRequestState;
  });
};

export { setAsAdmin, removeAsAdmin, transferOwnership };
