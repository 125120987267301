import { Autocomplete } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TextField, Box, useSnackbar } from "@alohi/kit";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material";
import { memo, Dispatch, SetStateAction, SyntheticEvent, useCallback } from "react";

import { makeStyles } from "ui";
import { UseInput } from "hooks/useInput";
import { parseEmailsString } from "helpers/inputValidation";

interface StyleProps {
  isError: boolean;
}

interface InviteUsersInputProps {
  currentEmail: string;
  emailsToInvite: string[];
  emailInput: UseInput<string>[1];
  setEmailsToInvite: Dispatch<SetStateAction<string[]>>;
}

function InviteUsersInput({
  emailInput,
  currentEmail,
  emailsToInvite,
  setEmailsToInvite,
}: InviteUsersInputProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: string, reason: string) => {
      if (event && event.type === "blur") {
        emailInput.setValue("");
      } else if (reason === "reset") {
        const { uniqueEmails, someEmailsAreInvalid } = parseEmailsString(emailInput.ref.current);

        if (uniqueEmails?.length) {
          emailInput.setValue("");
          emailInput.unTouch();

          if (someEmailsAreInvalid) {
            enqueueSnackbar(t("FORMS.SOME_EMAILS_ARE_INVALID"), {
              variant: "warning",
            });
          }
        } else {
          emailInput.setShowsError(true);
          enqueueSnackbar(t("FORMS.INVALID_EMAIL"), { variant: "warning" });
        }
      } else if (reason === "clear") {
        emailInput.setValue("");
        setEmailsToInvite([]);
        emailInput.setShowsError(false);
      } else {
        emailInput.setValue(value);
      }
    },
    [emailInput, enqueueSnackbar, setEmailsToInvite, t],
  );

  const handleOnChange = useCallback(
    (
      _,
      __,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<string> | undefined,
    ) => {
      const { uniqueEmails } = parseEmailsString(details?.option ?? "");

      switch (reason) {
        case "removeOption":
          setEmailsToInvite((previousEmails) => {
            const set = new Set(previousEmails);
            uniqueEmails?.forEach((email) => set.delete(email));
            return Array.from(set);
          });
          break;
        case "createOption":
          setEmailsToInvite((previousEmails) => {
            const set = new Set(previousEmails);
            uniqueEmails?.forEach((email) => set.add(email));
            return Array.from(set);
          });
          break;
        case "blur":
        case "clear":
        case "selectOption":
          break;
      }
    },
    [setEmailsToInvite],
  );

  const isError = Boolean(emailInput.showsError);
  const classes = useStyles({ isError });

  return (
    <Box px={3} pt={3}>
      <Autocomplete
        freeSolo
        multiple
        options={[]}
        disableListWrap
        value={emailsToInvite}
        className={classes.root}
        inputValue={currentEmail}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        renderInput={(props) => (
          <TextField
            {...props}
            error={isError}
            onBlur={emailInput.onBlur}
            onFocus={emailInput.onFocus}
            placeholder={t("FORMS.ADD_EMAIL")}
          />
        )}
      />
    </Box>
  );
}

const useStyles = makeStyles(({ alohi }) => ({
  root: ({ isError }: StyleProps) => ({
    "&& .MuiOutlinedInput-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: isError ? alohi.red : alohi.gray,
    },
  }),
}));

export default memo(InviteUsersInput);
