import PropTypes from "prop-types";
import { useEffect } from "react";
import { PDFObject } from "react-pdfobject";
import { makeStyles } from "ui";
import { useOnPressEscape } from "../../hooks/useOnPressEscape";

function PdfViewer({ handleClosure, pdfUrl, handleDownload }) {
  const classes = useStyles();

  useOnPressEscape(() => handleClosure());

  useEffect(() => {
    window.pdfViewer__handleClosure = handleClosure;
    return () => {
      delete window.pdfViewer__handleClosure;
    };
  }, [handleClosure]);

  useEffect(() => {
    window.pdfViewer__download = handleDownload;
    return () => {
      delete window.pdfViewer__download;
    };
  }, [handleClosure, handleDownload]);

  if (!pdfUrl) return null;

  return (
    <div className={classes.pdfViewer}>
      <PDFObject
        url={pdfUrl}
        containerProps={{ className: classes.pdfContainer }}
        PDFJS_URL={"/static/pdf-viewer/v7/web/viewer.html"}
        forcePDFJS
      />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  pdfViewer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 10000,
  },
  pdfContainer: {
    width: "100%",
    height: "100%",
  },
}));

PdfViewer.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  handleClosure: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
};

export default PdfViewer;
