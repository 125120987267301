import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { corporateApi, supportApi } from "api";
import { accountTypes } from "enums/account";
import RS, { FetchError } from "enums/requestStatus";
import { selectAccountCid } from "selectors/account.selector";
import { selectDashbordSelectedPeriod, selectUsagePeriods } from "selectors/corporate.selector";
import { logout } from "./authentication.reducer";

interface RequestUpgradePayload {
  name: string;
  email: string;
  destinations: string;
  requestedPages: number;
}

interface InitialRequestState {
  error: null | unknown;
  status: typeof RS.IDLE | typeof RS.ERROR | typeof RS.RUNNING;
  response: null | Record<string, Record<string, Record<string, unknown>>>;
}

const initialRequestState: InitialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

const initialState = {
  getUsersRequest: initialRequestState,
  inviteByEmailsRequest: initialRequestState,
  resendInvitationByEmailRequest: initialRequestState,
  getAdminInfoRequest: initialRequestState,
  getLimitsRequest: initialRequestState,
  updateUserRoleRequest: initialRequestState,
  updateUserQuotaRequest: initialRequestState,
  getUserQuotaRequest: initialRequestState,
  assignAndRevokeUserNumbersRequest: initialRequestState,
  enableUserRequest: initialRequestState,
  disableUserRequest: initialRequestState,
  deleteUserRequest: initialRequestState,
  getHierarchy: initialRequestState,
  updateHierarchyRequest: initialRequestState,
  updateCompanyInfo: initialRequestState,
  updateDefaultNumber: initialRequestState,
  usagePeriods: initialRequestState,
  listCorporateMembers: initialRequestState,
  pageConsumption: {
    ...initialRequestState,
    selectedPeriod: 0,
  },
};

export const clearEnableUser = createAction("CORPORATE/CLEAR_ENABLE_USER");
export const clearUpdateCompany = createAction("CORPORATE/CLEAR_UPDATE_COMPANY");
export const updateDashboardSelectedPeriod = createAction(
  "CORPORATE/UPDATE_DASHBOARD_SELECTED_PERIOD",
  function prepare(period) {
    return { payload: period };
  },
);

const getUsagePeriods = createAsyncThunk<{ response: unknown }, { period: number }>(
  "CORPORATE/GET_USAGE_PERIODS",
  async ({ period }, { getState, rejectWithValue }) => {
    try {
      const cid = selectAccountCid(getState());
      const response = await corporateApi.getUsagePeriods({ period, cid });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getPageConsumption = createAsyncThunk<{ response: unknown }, { type: "users" | "company" }>(
  "CORPORATE/GET_PAGE_CONSUMPTION",
  async ({ type }, { rejectWithValue, getState }) => {
    try {
      const cid = selectAccountCid(getState());
      const usagePeriods = selectUsagePeriods(getState());
      const period = selectDashbordSelectedPeriod(getState());

      const selectedRangePeriod = usagePeriods[period];

      if (selectedRangePeriod) {
        const { from_date: fromDate, to_date: toDate } = selectedRangePeriod;
        const response = await corporateApi.getPageConsumption({
          cid,
          fromDate,
          toDate,
        });
        return { response, type, period };
      }

      return rejectWithValue("Wrong period provided");
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getUsers = createAsyncThunk("CORPORATE/GET_USERS", async (_, { rejectWithValue }) => {
  try {
    const response = await corporateApi.getUsers();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const listCorporateMembers = createAsyncThunk<
  { response: unknown },
  { type: "SIGN_PLUS" | "FAX_PLUS" }
>("CORPORATE/LIST_CORPORATE_MEMBERS", async ({ type }, { rejectWithValue }) => {
  try {
    const response = await corporateApi.listCorporateMembers({ type });
    return { response, type };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const inviteByEmails = createAsyncThunk<{ response: unknown }, { emails: unknown }>(
  "CORPORATE/INVITE",
  async ({ emails }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.inviteByEmails(emails);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const resendInvitationByEmail = createAsyncThunk<{ response: unknown }, { email: unknown }>(
  "CORPORATE/RESEND_INVITATION",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.resendInviteByEmail(email);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getAdminInfo = createAsyncThunk(
  "CORPORATE/GET_ADMIN_INFO",
  async (_, { rejectWithValue }) => {
    try {
      const response = await corporateApi.getAdminInfo();
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getLimits = createAsyncThunk("CORPORATE/GET_LIMITS", async (_, { rejectWithValue }) => {
  try {
    const response = await corporateApi.getLimits();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const updateUserRole = createAsyncThunk<{ response: unknown }, { userUid: unknown; role: unknown }>(
  "CORPORATE/UPDATE_USER_ROLE",
  async ({ userUid, role }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.updateUserRole({ userUid, role });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const updateUserQuota = createAsyncThunk<
  { response: unknown },
  { userUid: unknown; quota: unknown }
>("CORPORATE/UPDATE_USER_QUOTA", async ({ userUid, quota }, { rejectWithValue }) => {
  try {
    const response = await corporateApi.updateUserQuota({ userUid, quota });
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

const getUserQuota = createAsyncThunk<{ response: unknown }, { userUid: unknown }>(
  "CORPORATE/GET_USER_QUOTA",
  async ({ userUid }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.getQuotaByUid(userUid);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const assignAndRevokeUserNumbers = createAsyncThunk<
  { response: unknown },
  { userUid: unknown; numbersToRevoke: never; numbersToAssign: never }
>(
  "CORPORATE/ASSIGN_NUMBERS",
  async ({ userUid, numbersToRevoke, numbersToAssign }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.assignAndRevokeUserNumbers({
        userUid,
        numbersToRevoke,
        numbersToAssign,
      });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const enableUser = createAsyncThunk<{ response: unknown }, { userUid: unknown }>(
  "CORPORATE/ENABLE_USER",
  async ({ userUid }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.enableUser(userUid);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const disableUser = createAsyncThunk<{ response: unknown }, { userUid: unknown }>(
  "CORPORATE/DISABLE_USER",
  async ({ userUid }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.disableUser(userUid);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const deleteUser = createAsyncThunk<{ response: unknown }, { userUid: unknown }>(
  "CORPORATE/DELETE_USER",
  async ({ userUid }, { rejectWithValue }) => {
    try {
      const response = await corporateApi.deleteUser(userUid);
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const getHierarchy = createAsyncThunk("CORPORATE/GET_HIERARCHY", async (_, { rejectWithValue }) => {
  try {
    const response = await corporateApi.getHierarchy();
    return { response };
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

interface TreeData {
  uid: string;
  accountType: string;
  children: TreeData[];
}

const updateHierarchy = createAsyncThunk<{ response: unknown }, { hierarchyTreeData: TreeData[] }>(
  "CORPORATE/UPDATE_HIERARCHY",
  async ({ hierarchyTreeData }, { rejectWithValue }) => {
    const shapeUsersArrayToObject = (usersArray: TreeData[]) => {
      const userObject: { [key: string]: unknown } = {};
      usersArray.forEach((user) => {
        if (user.accountType !== accountTypes.corporateOwner) {
          userObject[user.uid] = {
            uid: user.uid,
            ...(!!user.children.length && {
              members: shapeUsersArrayToObject(user.children),
            }),
          };
        }
      });
      return userObject;
    };

    try {
      const response = await corporateApi.updateUsers(shapeUsersArrayToObject(hierarchyTreeData));
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

export const updateCompanyInfo = createAsyncThunk<
  { response: unknown },
  { companyLogo?: string | null; companyName: string }
>("CORPORATE/UPDATE_COMPANY_INFO", async (companyInfo, { getState, rejectWithValue }) => {
  try {
    const response = await corporateApi.updateCompanyInfo(companyInfo, getState);
    return response;
  } catch (error) {
    return rejectWithValue((error as FetchError).origin);
  }
});

export const updateDefaultCompanyNumber = createAsyncThunk<{ response: unknown }, string>(
  "CORPORATE/UPDATE_DEFAULT_COMPANY_NUMBER",
  async (number, { rejectWithValue }) => {
    try {
      const response = await corporateApi.updateDefaultCompanyNumber(number);
      return response;
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const requestUpgrade = createAsyncThunk<{ response: unknown }, RequestUpgradePayload>(
  "CORPORATE/REQUEST_UPGRADE",
  async ({ name, email, requestedPages, destinations }, { rejectWithValue }) => {
    try {
      const response = await supportApi.support({
        name,
        email,
        request: {
          category: "HIGH_VOLUME",
          destinations,
          requested_pages: requestedPages,
        },
      });
      return { response };
    } catch (error) {
      return rejectWithValue((error as FetchError).origin);
    }
  },
);

const corporate = createSlice({
  name: "CORPORATE",
  initialState,
  reducers: {
    CLEAR_INVITE_BY_EMAILS_REQUEST: (state) => {
      state.inviteByEmailsRequest = initialRequestState;
    },
    CLEAR_RESEND_INVITATION_BY_EMAIL_REQUEST: (state) => {
      state.resendInvitationByEmailRequest = initialRequestState;
    },
    CLEAR_GET_USER_QUOTA_REQUEST: (state) => {
      state.getUserQuotaRequest = initialRequestState;
    },
    CLEAR_UPDATE_USER_ROLE_REQUEST: (state) => {
      state.updateUserRoleRequest = initialRequestState;
    },
    CLEAR_UPDATE_USER_QUOTA_REQUEST: (state) => {
      state.updateUserQuotaRequest = initialRequestState;
    },
    CLEAR_DISABLE_USER_REQUEST: (state) => {
      state.disableUserRequest = initialRequestState;
    },
    CLEAR_DELETE_USER_REQUEST: (state) => {
      state.deleteUserRequest = initialRequestState;
    },
    CLEAR_UPDATE_HIERARCHY_REQUEST: (state) => {
      state.updateHierarchyRequest = initialRequestState;
    },
    CLEAR_GET_LIMITS_REQUEST: (state) => {
      state.getLimitsRequest = initialRequestState;
    },
    CLEAR_ASSIGN_AND_REVOKE_USER_NUMBERS_REQUEST: (state) => {
      state.assignAndRevokeUserNumbersRequest = initialRequestState;
    },
    CLEAR: () => initialState,
  },
  extraReducers: {
    [updateDashboardSelectedPeriod as never]: (state, { payload }) => {
      state.pageConsumption.selectedPeriod = payload;
    },
    [getPageConsumption.pending as never]: (state) => {
      state.pageConsumption.error = null;
      state.pageConsumption.status = RS.RUNNING;
    },
    [getPageConsumption.fulfilled as never]: (state, { payload }) => {
      state.pageConsumption.error = null;
      state.pageConsumption.status = RS.IDLE;
      if (!state.pageConsumption.response) {
        state.pageConsumption.response = {};
      }
      if (!state.pageConsumption.response?.[payload.type]) {
        state.pageConsumption.response[payload.type] = {};
      }
      if (!state.pageConsumption.response?.[payload.type]?.[payload.period]) {
        state.pageConsumption.response[payload.type][payload.period] = payload.response;
      }
    },
    [getPageConsumption.rejected as never]: (state, { payload }) => {
      state.pageConsumption.status = RS.ERROR;
      state.pageConsumption.error = payload;
      state.pageConsumption.response = null;
    },
    [getUsagePeriods.pending as never]: (state) => {
      state.usagePeriods.error = null;
      state.usagePeriods.response = null;
      state.usagePeriods.status = RS.RUNNING;
    },
    [getUsagePeriods.fulfilled as never]: (state, { payload }) => {
      state.usagePeriods.error = null;
      state.usagePeriods.status = RS.IDLE;
      state.usagePeriods.response = payload.response;
    },
    [getUsagePeriods.rejected as never]: (state, { payload }) => {
      state.usagePeriods.status = RS.ERROR;
      state.usagePeriods.error = payload;
      state.usagePeriods.response = null;
    },
    [getUsers.pending as never]: (state) => {
      state.getUsersRequest.status = RS.RUNNING;
      state.getUsersRequest.error = null;
    },
    [getUsers.fulfilled as never]: (state, { payload }) => {
      state.getUsersRequest.status = RS.IDLE;
      state.getUsersRequest.response = payload.response;
    },
    [getUsers.rejected as never]: (state, { payload }) => {
      state.getUsersRequest.status = RS.ERROR;
      state.getUsersRequest.error = payload;
    },
    [getHierarchy.pending as never]: (state) => {
      state.getHierarchy.status = RS.RUNNING;
      state.getHierarchy.error = null;
    },
    [getHierarchy.fulfilled as never]: (state, { payload }) => {
      state.getHierarchy.status = RS.IDLE;
      state.getHierarchy.response = payload.response;
    },
    [getHierarchy.rejected as never]: (state, { payload }) => {
      state.getHierarchy.status = RS.ERROR;
      state.getHierarchy.error = payload;
    },
    [inviteByEmails.pending as never]: (state) => {
      state.inviteByEmailsRequest.status = RS.RUNNING;
      state.inviteByEmailsRequest.error = null;
    },
    [inviteByEmails.fulfilled as never]: (state, { payload }) => {
      state.inviteByEmailsRequest.status = RS.IDLE;
      state.inviteByEmailsRequest.response = payload.response;
    },
    [inviteByEmails.rejected as never]: (state, { payload }) => {
      state.inviteByEmailsRequest.status = RS.ERROR;
      state.inviteByEmailsRequest.error = payload;
    },
    [resendInvitationByEmail.pending as never]: (state) => {
      state.resendInvitationByEmailRequest.status = RS.RUNNING;
      state.resendInvitationByEmailRequest.error = null;
    },
    [resendInvitationByEmail.fulfilled as never]: (state, { payload }) => {
      state.resendInvitationByEmailRequest.status = RS.IDLE;
      state.resendInvitationByEmailRequest.response = payload.response;
    },
    [resendInvitationByEmail.rejected as never]: (state, { payload }) => {
      state.resendInvitationByEmailRequest.status = RS.ERROR;
      state.resendInvitationByEmailRequest.error = payload;
    },
    [getAdminInfo.pending as never]: (state) => {
      state.getAdminInfoRequest.status = RS.RUNNING;
      state.getAdminInfoRequest.error = null;
    },
    [getAdminInfo.fulfilled as never]: (state, { payload }) => {
      state.getAdminInfoRequest.status = RS.IDLE;
      state.getAdminInfoRequest.response = payload.response;
    },
    [getAdminInfo.rejected as never]: (state, { payload }) => {
      state.getAdminInfoRequest.status = RS.ERROR;
      state.getAdminInfoRequest.error = payload;
    },
    [getLimits.pending as never]: (state) => {
      state.getLimitsRequest.status = RS.RUNNING;
      state.getLimitsRequest.error = null;
    },
    [getLimits.fulfilled as never]: (state, { payload }) => {
      state.getLimitsRequest.status = RS.IDLE;
      state.getLimitsRequest.response = payload.response;
    },
    [getLimits.rejected as never]: (state, { payload }) => {
      state.getLimitsRequest.status = RS.ERROR;
      state.getLimitsRequest.error = payload;
    },
    [updateUserRole.pending as never]: (state) => {
      state.updateUserRoleRequest.status = RS.RUNNING;
      state.updateUserRoleRequest.error = null;
    },
    [updateUserRole.fulfilled as never]: (state, { payload }) => {
      state.updateUserRoleRequest.status = RS.IDLE;
      state.updateUserRoleRequest.response = payload.response;
    },
    [updateUserRole.rejected as never]: (state, { payload }) => {
      state.updateUserRoleRequest.status = RS.ERROR;
      state.updateUserRoleRequest.error = payload;
    },
    [updateUserQuota.pending as never]: (state) => {
      state.updateUserQuotaRequest.status = RS.RUNNING;
      state.updateUserQuotaRequest.error = null;
    },
    [updateUserQuota.fulfilled as never]: (state, { payload }) => {
      state.updateUserQuotaRequest.status = RS.IDLE;
      state.updateUserQuotaRequest.response = payload.response;
      state.getUserQuotaRequest = initialRequestState;
    },
    [updateUserQuota.rejected as never]: (state, { payload }) => {
      state.updateUserQuotaRequest.status = RS.ERROR;
      state.updateUserQuotaRequest.error = payload;
    },
    [getUserQuota.pending as never]: (state) => {
      state.getUserQuotaRequest.status = RS.RUNNING;
      state.getUserQuotaRequest.error = null;
    },
    [getUserQuota.fulfilled as never]: (state, { payload }) => {
      state.getUserQuotaRequest.status = RS.IDLE;
      state.getUserQuotaRequest.response = payload.response;
    },
    [getUserQuota.rejected as never]: (state, { payload }) => {
      state.getUserQuotaRequest.status = RS.ERROR;
      state.getUserQuotaRequest.error = payload;
    },
    [assignAndRevokeUserNumbers.pending as never]: (state) => {
      state.assignAndRevokeUserNumbersRequest.status = RS.RUNNING;
      state.assignAndRevokeUserNumbersRequest.error = null;
    },
    [assignAndRevokeUserNumbers.fulfilled as never]: (state, { payload }) => {
      state.assignAndRevokeUserNumbersRequest.status = RS.IDLE;
      state.assignAndRevokeUserNumbersRequest.response = payload.response;
    },
    [assignAndRevokeUserNumbers.rejected as never]: (state, { payload }) => {
      state.assignAndRevokeUserNumbersRequest.status = RS.ERROR;
      state.assignAndRevokeUserNumbersRequest.error = payload;
    },
    [enableUser.pending as never]: (state) => {
      state.enableUserRequest.status = RS.RUNNING;
      state.enableUserRequest.error = null;
    },
    [enableUser.fulfilled as never]: (state, { payload }) => {
      state.enableUserRequest.status = RS.IDLE;
      state.enableUserRequest.response = payload.response;
    },
    [enableUser.rejected as never]: (state, { payload }) => {
      state.enableUserRequest.status = RS.ERROR;
      state.enableUserRequest.error = payload;
    },
    [clearEnableUser as never]: (state) => {
      state.enableUserRequest = initialRequestState;
    },
    [disableUser.pending as never]: (state) => {
      state.disableUserRequest.status = RS.RUNNING;
      state.disableUserRequest.error = null;
    },
    [disableUser.fulfilled as never]: (state, { payload }) => {
      state.disableUserRequest.status = RS.IDLE;
      state.disableUserRequest.response = payload.response;
    },
    [disableUser.rejected as never]: (state, { payload }) => {
      state.disableUserRequest.status = RS.ERROR;
      state.disableUserRequest.error = payload;
    },
    [deleteUser.pending as never]: (state) => {
      state.deleteUserRequest.status = RS.RUNNING;
      state.deleteUserRequest.error = null;
    },
    [deleteUser.fulfilled as never]: (state, { payload }) => {
      state.deleteUserRequest.status = RS.IDLE;
      state.deleteUserRequest.response = payload.response;
    },
    [deleteUser.rejected as never]: (state, { payload }) => {
      state.deleteUserRequest.status = RS.ERROR;
      state.deleteUserRequest.error = payload;
    },
    [updateHierarchy.pending as never]: (state) => {
      state.updateHierarchyRequest.status = RS.RUNNING;
      state.updateHierarchyRequest.error = null;
    },
    [updateHierarchy.fulfilled as never]: (state, { payload }) => {
      state.updateHierarchyRequest.status = RS.IDLE;
      state.updateHierarchyRequest.response = payload.response;
    },
    [updateHierarchy.rejected as never]: (state, { payload }) => {
      state.updateHierarchyRequest.status = RS.ERROR;
      state.updateHierarchyRequest.error = payload;
    },
    [clearUpdateCompany as never]: (state) => {
      state.updateCompanyInfo.status = RS.IDLE;
      state.updateCompanyInfo.error = null;
      state.updateCompanyInfo.response = null;

      state.updateDefaultNumber.status = RS.IDLE;
      state.updateDefaultNumber.error = null;
      state.updateDefaultNumber.response = null;
    },
    [updateCompanyInfo.pending as never]: (state) => {
      state.updateCompanyInfo.status = RS.RUNNING;
      state.updateCompanyInfo.error = null;
      state.updateCompanyInfo.response = null;
    },
    [updateCompanyInfo.fulfilled as never]: (state, { payload }) => {
      state.updateCompanyInfo.status = RS.IDLE;
      state.updateCompanyInfo.error = null;
      state.updateCompanyInfo.response = payload;
    },
    [updateCompanyInfo.rejected as never]: (state, { payload }) => {
      state.updateCompanyInfo.status = RS.ERROR;
      state.updateCompanyInfo.error = payload;
      state.updateCompanyInfo.response = null;
    },
    [updateDefaultCompanyNumber.pending as never]: (state) => {
      state.updateDefaultNumber.status = RS.RUNNING;
      state.updateDefaultNumber.error = null;
      state.updateDefaultNumber.response = null;
    },
    [updateDefaultCompanyNumber.fulfilled as never]: (state, { payload }) => {
      state.updateDefaultNumber.status = RS.IDLE;
      state.updateDefaultNumber.error = null;
      state.updateDefaultNumber.response = payload;
    },
    [updateDefaultCompanyNumber.rejected as never]: (state, { payload }) => {
      state.updateDefaultNumber.status = RS.ERROR;
      state.updateDefaultNumber.error = payload;
      state.updateDefaultNumber.response = null;
    },
    CLEAR_LIST_CORPORATE_MEMBERS: (state) => {
      state.listCorporateMembers = initialRequestState;
    },
    [listCorporateMembers.pending as never]: (state) => {
      state.listCorporateMembers.status = RS.RUNNING;
      state.listCorporateMembers.error = null;
    },
    [listCorporateMembers.fulfilled as never]: (state, { payload }) => {
      state.listCorporateMembers.status = RS.IDLE;

      if (!state.listCorporateMembers.response) {
        state.listCorporateMembers.response = {};
      }

      state.listCorporateMembers.response[payload.type] = payload.response;
    },
    [listCorporateMembers.rejected as never]: (state, { payload }) => {
      state.listCorporateMembers.status = RS.ERROR;
      state.listCorporateMembers.error = payload;
    },
    [logout.fulfilled as never]: () => initialState,
  },
});

const { actions, reducer } = corporate;

const {
  CLEAR_INVITE_BY_EMAILS_REQUEST,
  CLEAR_RESEND_INVITATION_BY_EMAIL_REQUEST,
  CLEAR_GET_USER_QUOTA_REQUEST,
  CLEAR_DISABLE_USER_REQUEST,
  CLEAR_DELETE_USER_REQUEST,
  CLEAR_UPDATE_USER_QUOTA_REQUEST,
  CLEAR_UPDATE_USER_ROLE_REQUEST,
  CLEAR_GET_LIMITS_REQUEST,
  CLEAR_UPDATE_HIERARCHY_REQUEST,
  CLEAR_ASSIGN_AND_REVOKE_USER_NUMBERS_REQUEST,
  CLEAR,
} = actions;

export {
  CLEAR_INVITE_BY_EMAILS_REQUEST,
  CLEAR_RESEND_INVITATION_BY_EMAIL_REQUEST,
  CLEAR_GET_USER_QUOTA_REQUEST,
  CLEAR_DISABLE_USER_REQUEST,
  CLEAR_DELETE_USER_REQUEST,
  CLEAR_UPDATE_USER_QUOTA_REQUEST,
  CLEAR_UPDATE_USER_ROLE_REQUEST,
  CLEAR_GET_LIMITS_REQUEST,
  CLEAR_UPDATE_HIERARCHY_REQUEST,
  CLEAR_ASSIGN_AND_REVOKE_USER_NUMBERS_REQUEST,
  CLEAR,
};

export default reducer;

export const corporateAsyncActions = {
  getUsagePeriods,
  getPageConsumption,
  getUsers,
  getHierarchy,
  inviteByEmails,
  resendInvitationByEmail,
  getAdminInfo,
  getLimits,
  updateUserRole,
  updateUserQuota,
  getUserQuota,
  assignAndRevokeUserNumbers,
  enableUser,
  disableUser,
  deleteUser,
  updateHierarchy,
  listCorporateMembers,
  requestUpgrade,
};
