import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { Divider, Menu, MenuItem, Typography, FaButton, Box } from "@alohi/kit";

import { boxNames } from "enums/faxes";
import { faxesAsyncActions } from "stores/reducers/faxes.reducer";
import AddNoteOnFaxModal from "views/Faxes/modals/AddNoteOnFaxModal";
import ConfirmDeleteFaxModal from "views/Faxes/ConfirmDeleteFaxModal";
import { selectAccountDefaultFaxFileType } from "selectors/account.selector";
import {
  selectCdrId,
  selectCdrById,
  selectCdrContactName,
  selectCdrFromToNumber,
  selectCdrFullNoteById,
  selectIsCdrReadyForPreview,
} from "selectors/faxes.selector";

function OutboxActions({ dataIndex }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialCdrId = useSelector(selectCdrId(boxNames.outbox, dataIndex));
  const initialToNumber = useSelector(selectCdrFromToNumber(boxNames.outbox, dataIndex));
  const initialContactName = useSelector(selectCdrContactName(boxNames.outbox, dataIndex));
  const isReadyToDownload = useSelector(selectIsCdrReadyForPreview(boxNames.outbox, dataIndex));

  // Freeze ID of the original CDR, even when indexes change
  // Make sure that the menu is still tied to the CDR it was opened from
  const [cdrId, setCdrId] = useState(initialCdrId);
  const [toNumber, setToNumber] = useState(initialToNumber);
  const [contactName, setContactName] = useState(initialContactName);
  const cdr = useSelector(selectCdrById(boxNames.outbox, cdrId));
  const downloadFileType = useSelector(selectAccountDefaultFaxFileType);
  const note = useSelector(selectCdrFullNoteById(boxNames.outbox, cdrId));

  const [actions, setActions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addNoteOnFaxModal, setAddNoteOnFaxModal] = useState({
    isOpen: false,
    cdrId: null,
    currentNote: null,
  });
  const [confirmDeleteFaxDialog, setConfirmDeleteFaxModal] = useState({
    isOpen: false,
    cdrIds: null,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCdrId(initialCdrId);
    setToNumber(initialToNumber);
    setContactName(initialContactName);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Close menu if CDR disappears from the box
  useEffect(() => {
    if (!cdr && !!anchorEl) {
      handleClose();
    }
  }, [anchorEl, cdr]);

  useEffect(() => {
    if (!!anchorEl) {
      let newActions = [];

      newActions.push({
        label: t("COMMON.DELETE"),
        callback: () => {
          setConfirmDeleteFaxModal({
            isOpen: true,
            cdrIds: [cdrId],
          });
        },
      });

      newActions.push({ label: "Divider" });

      if (isReadyToDownload) {
        newActions.push({
          label: t("COMMON.DOWNLOAD"),
          callback: () => {
            dispatch(
              faxesAsyncActions.download({
                boxName: boxNames.outbox,
                downloadFileType,
                cdrId,
              }),
            );
          },
        });
      }

      newActions.push({
        label: !!note ? t("FAXES.MODIFY_NOTE") : t("FAXES.ADD_NOTE"),
        callback: () => {
          setAddNoteOnFaxModal({
            isOpen: true,
            cdrId,
            currentNote: note,
          });
        },
      });
      setActions(newActions);
    }
  }, [
    anchorEl,
    dispatch,
    toNumber,
    cdrId,
    note,
    t,
    contactName,
    isReadyToDownload,
    downloadFileType,
    actions.length,
  ]);

  // Close modals when cdr is deleted and this component unmounts
  useEffect(() => {
    return () => {
      setAddNoteOnFaxModal({
        isOpen: false,
        cdrId: null,
        currentNote: null,
      });
      setConfirmDeleteFaxModal({ isOpen: false, cdrIds: null });
    };
  }, []);

  return (
    <>
      <Box textAlign="end">
        <FaButton
          aria-label="More"
          aria-haspopup="true"
          onClick={handleClick}
          aria-controls={`actions-menu-${dataIndex}`}
          icon={faEllipsisV}
        />
        <Menu
          id={`actions-menu-${dataIndex}`}
          anchorEl={anchorEl}
          open={!!anchorEl && !!actions}
          onClose={handleClose}
          elevation={2}
        >
          {actions.map((action, key) => {
            if (action.label === "Divider") {
              return (
                <Box py={1} key={key}>
                  <Divider />
                </Box>
              );
            } else {
              return (
                <MenuItem
                  key={action.label}
                  disabled={action.disabled}
                  onClick={() => {
                    action.callback();
                    handleClose();
                  }}
                >
                  <Typography style={{ fontSize: "0.875rem" }}>{action.label}</Typography>
                </MenuItem>
              );
            }
          })}
        </Menu>
      </Box>

      {addNoteOnFaxModal.isOpen ? (
        <AddNoteOnFaxModal
          dataIndex={dataIndex}
          boxName={boxNames.outbox}
          cdrId={addNoteOnFaxModal.cdrId}
          currentNote={addNoteOnFaxModal.currentNote}
          handleClosure={() =>
            setAddNoteOnFaxModal({
              isOpen: false,
              cdrId: null,
              currentNote: null,
            })
          }
        />
      ) : null}

      {confirmDeleteFaxDialog.isOpen ? (
        <ConfirmDeleteFaxModal
          handleClosure={() => setConfirmDeleteFaxModal({ isOpen: false, cdrIds: null })}
          boxName={boxNames.outbox}
          cdrIds={confirmDeleteFaxDialog.cdrIds}
        />
      ) : null}
    </>
  );
}

OutboxActions.propTypes = {
  dataIndex: PropTypes.number.isRequired,
};

export default memo(OutboxActions);
