import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Panel, Button, Typography, Box } from "@alohi/kit";

import { Helper } from "ui";
import SsoModal from "views/Sso/SsoModal";
import { helpCenterUrls } from "routes/urls";
import SsoDeactivationModal from "views/Sso/SsoDeactivationModal";
import { selectIsSsoActive } from "selectors/account.sso.selector";
import { getSsoSettings } from "stores/reducers/account.sso.reducer";

function SsoRow() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSsoActive = useSelector(selectIsSsoActive);

  const [isSsoModalOpen, setIsSsoModalOpen] = useState(false);
  const [isSsoDeactivationModalOpen, setIsSsoDeactivationModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getSsoSettings());
  }, [dispatch]);

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">
          <Typography type="panelCellTitle">
            {t("SSO.TITLE")}
            <Box component="a" ml={1} href={helpCenterUrls.sso} target="_blank" rel="noreferrer">
              <Helper variant="dark" size="lg" />
            </Box>
          </Typography>
        </Panel.Cell>
        {isSsoActive !== null && (
          <>
            <Panel.Cell variant="data">
              {isSsoActive ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
            </Panel.Cell>
            <Panel.Cell variant="actions">
              {isSsoActive ? (
                <>
                  <Button variant="white" sx={{ mr: 2 }} onClick={() => setIsSsoModalOpen(true)}>
                    {t("SSO.CONFIGURE")}
                  </Button>
                  <Button variant="white" onClick={() => setIsSsoDeactivationModalOpen(true)}>
                    {t("PROFILE.DEACTIVATE")}
                  </Button>
                </>
              ) : (
                <Button variant="white" onClick={() => setIsSsoModalOpen(true)}>
                  {t("PROFILE.ACTIVATE")}
                </Button>
              )}
            </Panel.Cell>
          </>
        )}
      </Panel.Row>

      {isSsoModalOpen && <SsoModal handleClosure={() => setIsSsoModalOpen(false)} />}

      {isSsoDeactivationModalOpen && (
        <SsoDeactivationModal handleClosure={() => setIsSsoDeactivationModalOpen(false)} />
      )}
    </>
  );
}

export default SsoRow;
