import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, useSnackbar, Typography } from "@alohi/kit";

import { selectIsPlanEnterprise } from "selectors/plan.selector";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import {
  selectIsDeactivateSsoError,
  selectIsDeactivateSsoSuccess,
} from "selectors/account.sso.selector";
import {
  deactivateSso,
  getSsoSettings,
  clearDeactivateSso,
} from "stores/reducers/account.sso.reducer";

function SsoDeactivationModal({ handleClosure }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const isSuccess = useSelector(selectIsDeactivateSsoSuccess);
  const isError = useSelector(selectIsDeactivateSsoError);
  const isPlanEnterprise = useSelector(selectIsPlanEnterprise);

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deactivateSso());
  };

  useEffect(() => {
    (async () => {
      if (isSuccess) {
        dispatch(clearDeactivateSso());
        await dispatch(getSsoSettings());
        enqueueSnackbar(t("SSO.DEACTIVATION_SUCCESS"), { variant: "success" });
        setIsLoading(false);
        handleClosure();
      }
    })();
  }, [dispatch, enqueueSnackbar, handleClosure, isSuccess, t]);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [enqueueSnackbar, isError, t]);

  if (!isPlanEnterprise)
    return (
      <RequireUpgradeToEnterprise
        handleClosure={handleClosure}
        title={t("SSO.ACTIVATION")}
        featureName={t("SSO.TITLE")}
      />
    );

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      isModalDisabled={isLoading}
      isConfirmLoading={isLoading}
      title={t("SSO.DEACTIVATION")}
    >
      <Box p={3}>
        <Typography>{t("SSO.CONFIRM_DEACTIVATION")}</Typography>
      </Box>
    </Modal>
  );
}

SsoDeactivationModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default SsoDeactivationModal;
