import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Typography } from "ui";
import { formatDefaultDate } from "helpers/date";
import { capitalizeString } from "helpers/string";

interface NameProps {
  name: string;
  last_used: number;
}

const Name = ({ name, last_used }: NameProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        fontWeight={"bold"}
        overflow={"hidden"}
        whiteSpace={"nowrap"}
        textOverflow={"ellipsis"}
        pr={1}
      >
        {capitalizeString(name)}
      </Typography>
      <Typography variant="signatureConsent">
        {last_used
          ? t("API_TOKEN.LAST_USED", {
              date: formatDefaultDate(dayjs.unix(last_used).toString()),
            })
          : t("API_TOKEN.NEVER_USED")}
      </Typography>
    </>
  );
};

export default Name;
