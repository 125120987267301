import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectListCorporateMembersIsRunning } from "selectors/corporate.selector";
import { Table, TableColumnsProp, makeStyles } from "ui";
import NoMembersFound from "./NoMembersFound";

interface InviteUsersTableProps {
  emailsToInvite: string[];
  setEmailsToInvite: Dispatch<SetStateAction<string[]>>;
  corporateMembers: Member[];
}

export interface Member {
  cid: string;
  name: string;
  email: string;
  lastname: string;
}

function InviteFromSignPlusTable({
  corporateMembers,
  emailsToInvite,
  setEmailsToInvite,
}: InviteUsersTableProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isLoading = useSelector(selectListCorporateMembersIsRunning);

  const rows = useMemo(
    () =>
      (corporateMembers ?? []).map((member) => ({
        id: member.email, // use the email as an id, since it's unique
        name: `${member.name} ${member.lastname}`,
        email: member.email,
      })),
    [corporateMembers],
  );

  const columns = useMemo(() => {
    const columns: TableColumnsProp = [];

    columns.push({
      flex: 0.5,
      field: "name",
      sortable: false,
      headerName: t("COMMON.NAME"),
    });

    columns.push({
      flex: 1,
      field: "email",
      sortable: false,
      headerName: t("COMMON.EMAIL"),
    });

    return columns;
  }, [t]);

  return (
    <Table
      noBorder
      rows={rows}
      columns={columns}
      checkboxSelection
      isLoading={isLoading}
      className={classes.root}
      selectionModel={emailsToInvite}
      components={{ NoRowsOverlay: NoMembersFound }}
      onSelectionModelChange={(selection) => setEmailsToInvite(selection as string[])}
    />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    "&& .MuiDataGrid-columnHeader:first-child": {
      paddingLeft: 0,
    },
  },
}));

export default InviteFromSignPlusTable;
