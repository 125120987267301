import { memo } from "react";

import { generateId } from "helpers/common";

interface ThumbnailPlaceholderProps {
  width?: number;
  height?: number;
}

function ThumbnailPlaceholder({ width = 47, height = 60 }: ThumbnailPlaceholderProps) {
  const id = generateId();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 376 516"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(2.000000, 2.000000)">
          <path
            fill={`url(#grad-${id})`}
            d="M371.4,140.2 L371.4,479 C371.4,497.2 356.6,512 338.4,512 L33.6,512 C15.4,512 0.6,497.2 0.6,479 L0.6,33 C0.6,14.8 15.4,0 33.6,0 L231.2,0 L371.4,140.2 Z"
            id="Path"
            stroke="#BFBFBF"
            strokeWidth="4"
          />
          <g transform="translate(91.000000, 206.000000)" fill="#e2e2e2">
            <rect x="0.2" y="141.6" width="189.6" height="21.1" />
            <rect x="0.2" y="94.6" width="189.6" height="21.1" />
            <rect x="0.2" y="47.5" width="189.6" height="21.1" />
            <rect x="0.2" y="0.5" width="134.9" height="21.1" />
          </g>
          <path
            fill="#fff"
            d="M369.4,141.2 L262.964622,141.2 C244.894437,141.2 230.2,126.505563 230.2,108.435378 L230.2,2"
            stroke="#BFBFBF"
            strokeWidth="4"
          />
          <defs>
            <linearGradient id={`grad-${id}`}>
              <stop offset="0%" stopColor="rgb(250, 250, 250)" />
              <stop offset="20%" stopColor="rgb(243, 243, 243)" />
              <stop offset="33%" stopColor="rgb(243, 243, 243)" />
              <stop offset="50%" stopColor="rgb(243, 243, 243)" />
              <stop offset="55%" stopColor="rgb(243, 243, 243)" />
              <stop offset="67%" stopColor="rgb(250, 250, 250)" />
              <stop offset="100%" stopColor="rgb(250, 250, 250)" />
              <animateTransform
                attributeName="gradientTransform"
                type="rotate"
                from="45"
                to="45"
                dur="1.5s"
                fill="freeze"
                repeatCount="10"
                additive="sum"
              />
              <animateTransform
                attributeName="gradientTransform"
                type="translate"
                from="-1 0"
                to="2 0"
                dur="1.5s"
                fill="freeze"
                repeatCount="10"
                additive="sum"
              />
            </linearGradient>
          </defs>
        </g>
      </g>
    </svg>
  );
}

export default memo(ThumbnailPlaceholder);
