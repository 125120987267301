import { ReactNode, useRef } from "react";
import { FlagProvider } from "@unleash/proxy-client-react";

import { UNLEASH_CLIENT_KEY, UNLEASH_URL } from "config";

interface FeatureFlagsProps {
  children: ReactNode;
}

function FeatureFlags({ children }: FeatureFlagsProps) {
  const config = useRef({
    url: UNLEASH_URL,
    refreshInterval: 0,
    appName: "Fax.Plus",
    clientKey: UNLEASH_CLIENT_KEY,
  });

  return <FlagProvider config={config.current}>{children}</FlagProvider>;
}

export default FeatureFlags;
