import { Panel, Button } from "@alohi/kit";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "ui";
import RedeemModal from "views/Redeem/RedeemModal";
import Currency from "components/Core/Currency/Currency";
import AddCreditModal from "views/AddCredit/AddCreditModal";
import FreeCreditModal from "views/FreeCredit/FreeCreditModal";
import { selectIsPlanCorporate } from "selectors/plan.selector";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import {
  selectCurrentCredit,
  selectCurrentCreditCurrency,
  selectCurrentCreditFreeCredit,
} from "selectors/credit.selector";

function MyCredit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentCredit = useSelector(selectCurrentCredit);
  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const freeCredit = useSelector(selectCurrentCreditFreeCredit);
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);

  const updateCurrentCredit = useCallback(() => {
    dispatch(getCreditDetails());
    dispatch(getCurrentCredit());
  }, [dispatch]);

  const [openRedeem, setOpenRedeem] = useState(false);
  const [openAddCredit, setOpenAddCredit] = useState(false);
  const [openFreeCreditModal, setOpenFreeCreditModal] = useState(false);

  return (
    <Panel
      data-cy={isPlanCorporate ? "CompanyCredit" : "MyCredit"}
      id={isPlanCorporate ? "company-credit" : "my-credit"}
      headerTitle={isPlanCorporate ? t("PROFILE.COMPANY_CREDIT") : t("PROFILE.MY_CREDIT")}
    >
      <Panel.Front>
        <Panel.Row>
          <Panel.Cell variant="title">{t("ADD_CREDIT.CURRENT_CREDIT")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Typography variant="currentCredit" onClick={() => setOpenAddCredit(true)}>
              <Currency
                value={currentCredit}
                currency={currentCreditCurrency}
                testId={"CreditText"}
              />
            </Typography>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button variant="white" sx={{ mr: 2 }} onClick={() => setOpenRedeem(true)}>
              {t("COMMON.REDEEM")}
            </Button>
            <Button variant="white" onClick={() => setOpenAddCredit(true)}>
              {t("COMMON.ADD_CREDIT")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.FREE_CREDIT")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Typography variant="panelCellData">
              {freeCredit >= 0 ? (
                <Trans
                  i18nKey="PROFILE.EARNED_SO_FAR"
                  components={{
                    credit: <Currency value={freeCredit} currency={currentCreditCurrency} />,
                  }}
                />
              ) : (
                ""
              )}
            </Typography>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button
              variant="white"
              onClick={() => setOpenFreeCreditModal(true)}
              sx={{ maxWidth: 110 }}
            >
              {t("REFERRAL.GET_FREE_CREDIT")}
            </Button>
            {openFreeCreditModal ? (
              <FreeCreditModal handleClosure={() => setOpenFreeCreditModal(false)} />
            ) : null}
          </Panel.Cell>
        </Panel.Row>
      </Panel.Front>

      {openRedeem ? <RedeemModal handleClosure={() => setOpenRedeem(false)} /> : null}

      {openAddCredit ? (
        <AddCreditModal
          withSuccessModal
          reason="add_credit"
          handleClosure={() => {
            updateCurrentCredit();
            setOpenAddCredit(false);
          }}
        />
      ) : null}
    </Panel>
  );
}

export default MyCredit;
