import { Box, Typography, alohi, makeStyles } from "@alohi/kit";
import { useTranslation } from "react-i18next";

function AddressesTooltip() {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {t("PROFILE.REGULATORY_COMPLIANCE_ADDRESSES_TITLE")}
      </Typography>
      <Typography className={classes.text}>
        {t("PROFILE.REGULATORY_COMPLIANCE_ADRESSES_TOOLTIP")}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1),
  },
  title: {
    color: alohi.white,
  },
  text: {
    color: alohi.white,
    fontSize: 12,
    marginTop: spacing(1),
  },
}));

export default AddressesTooltip;
