import PropTypes from "prop-types";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Typography, Box, useBool } from "@alohi/kit";
import { useSelector } from "react-redux";

import { Link } from "ui";
import { alohiAdminUrls, websiteUrls } from "routes/urls";
import ChangePlanToCustom from "components/Payment/ChangePlanToCustom";
import useAdminRedirections from "hooks/useAdminRedirections";
import { selectPlanPeriod } from "selectors/plan.selector";

function RequireUpgradeToCorporate({ handleClosure, title, featureName }) {
  const { t } = useTranslation();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const period = useSelector(selectPlanPeriod);

  const [isChangePlanOpen, isChangePlanOpenBool] = useBool(false);

  const handleOpenChangePlan = useCallback(() => {
    redirectToAdmin({
      adminUrl: alohiAdminUrls.fax.upgrade,
      fallbackAction: isChangePlanOpenBool.setTrue,
      adminParams: {
        "plan-type": "BUSINESS",
        period: period ?? "",
      },
    });
  }, [redirectToAdmin, isChangePlanOpenBool.setTrue, period]);

  return (
    <>
      <Modal
        title={title}
        maxWidth="xs"
        onCancel={handleClosure}
        onConfirm={handleOpenChangePlan}
        confirmTitle={t("COMMON.CHANGE_PLAN")}
      >
        <Box p={3}>
          <Typography>
            <Trans i18nKey={"CHANGE_PLAN.REQUIRE_UPGRADE_TO_CORPORATE"} values={{ featureName }} />
          </Typography>
          <Box mt={1} />
          <Typography>
            <Trans
              i18nKey={"CHANGE_PLAN.PLEASE_UPGRADE_TO_CORPORATE"}
              components={{
                pricingLink: <Link target="_blank" rel="noopener" href={websiteUrls.pricing} />,
              }}
            />
          </Typography>
        </Box>
      </Modal>

      {isChangePlanOpen ? (
        <ChangePlanToCustom type="business" handleClosure={handleClosure} />
      ) : null}
    </>
  );
}

RequireUpgradeToCorporate.propTypes = {
  handleClosure: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  featureName: PropTypes.string.isRequired,
};

export default RequireUpgradeToCorporate;
