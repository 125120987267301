import { Box } from "@alohi/kit";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useInput } from "hooks/useInput";
import { getI18nCountry } from "helpers/numbers";
import CountrySelect from "components/Core/CountrySelect/CountrySelect";
import { isValidName, isValidPostalCode, isValidStreetName } from "helpers/inputValidation";
import FormRow from "../Forms/FormRow";
import FormRowTextInput from "../Forms/FormRowTextInput";

function CreateNewAddressComponent({ country, isCountryDisabled, handleClosure }) {
  const { t } = useTranslation();

  const [countryValue, countryInput] = useInput(country);
  const [cityValue, cityInput] = useInput("", isValidStreetName);
  const [streetValue, streetInput] = useInput("", isValidStreetName);
  const [regionValue, regionInput] = useInput("", isValidStreetName);
  const [customerNameValue, customerNameInput] = useInput("", isValidName);
  const [postalCodeValue, postalCodeInput] = useInput("", isValidPostalCode);

  useEffect(() => {
    handleClosure({
      city: cityInput.isValid ? cityValue : "",
      street: streetInput.isValid ? streetValue : "",
      region: regionInput.isValid ? regionValue : "",
      country: countryInput.isValid ? countryValue : "",
      zipCode: postalCodeInput.isValid ? postalCodeValue : "",
      customerName: customerNameInput.isValid ? customerNameValue : "",
    });
  }, [
    cityValue,
    streetValue,
    regionValue,
    countryValue,
    handleClosure,
    postalCodeValue,
    customerNameValue,
    cityInput.isValid,
    streetInput.isValid,
    regionInput.isValid,
    countryInput.isValid,
    postalCodeInput.isValid,
    customerNameInput.isValid,
  ]);

  const countryValueSelect = {
    value: countryValue,
    label: getI18nCountry(countryValue)?.label ?? "",
  };

  return (
    <Box component="form" pr="10%">
      <FormRowTextInput
        required
        type="text"
        inputProps={{
          maxLength: 50,
        }}
        id="customer-name-input"
        value={customerNameValue}
        onBlur={customerNameInput.onBlur}
        onFocus={customerNameInput.onFocus}
        error={customerNameInput.showsError}
        onChange={customerNameInput.onChange}
        label={`${t("ADDRESS.NEW_ADDRESS_CUSTOMER_NAME")} *`}
      />
      <FormRow label={`${t("ADDRESS.NEW_ADDRESS_COUNTRY")} *`}>
        <CountrySelect
          value={countryValueSelect}
          isDisabled={isCountryDisabled}
          onChange={({ value }) => countryInput.setValue(value)}
        />
      </FormRow>
      <FormRowTextInput
        required
        type="text"
        inputProps={{
          maxLength: 50,
        }}
        id="region-input"
        value={regionValue}
        onBlur={regionInput.onBlur}
        onFocus={regionInput.onFocus}
        error={regionInput.showsError}
        onChange={regionInput.onChange}
        label={`${t("ADDRESS.NEW_ADDRESS_REGION")} *`}
      />
      <FormRowTextInput
        id="city"
        required
        type="text"
        inputProps={{
          maxLength: 50,
        }}
        value={cityValue}
        onBlur={cityInput.onBlur}
        onFocus={cityInput.onFocus}
        error={cityInput.showsError}
        onChange={cityInput.onChange}
        label={`${t("ADDRESS.NEW_ADDRESS_CITY")} *`}
      />
      <FormRowTextInput
        required
        multiline
        type="text"
        inputProps={{
          maxLength: 50,
        }}
        id="address-input"
        value={streetValue}
        onBlur={streetInput.onBlur}
        onFocus={streetInput.onFocus}
        error={streetInput.showsError}
        onChange={streetInput.onChange}
        label={`${t("ADDRESS.NEW_ADDRESS_STREET")} *`}
      />
      <FormRowTextInput
        required
        type="text"
        inputProps={{
          maxLength: 12,
        }}
        id="zip_code-input"
        value={postalCodeValue}
        onBlur={postalCodeInput.onBlur}
        onFocus={postalCodeInput.onFocus}
        error={postalCodeInput.showsError}
        onChange={postalCodeInput.onChange}
        label={`${t("ADDRESS.NEW_ADDRESS_ZIP_CODE")} *`}
      />
    </Box>
  );
}

CreateNewAddressComponent.propTypes = {
  country: PropTypes.string.isRequired,
  handleClosure: PropTypes.func.isRequired,
  isCountryDisabled: PropTypes.bool.isRequired,
};

export default memo(CreateNewAddressComponent);
