import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { TextField, Switch, Panel, TextButton, Box } from "@alohi/kit";

import dataCy from "enums/dataCy";
import {
  selectIndividualAssignedNumber,
  selectIsUpdateFaxReceptionSuccess,
  selectIsIndividualPlanFaxReceptionEnabled,
} from "selectors/numbers.selector";
import { Radio, makeStyles, RadioGroup, Typography, FormControlLabel } from "ui";
import {
  selectCanISeeMyFaxRow,
  selectCanIManageStoreFaxesInFaxPlus,
} from "selectors/ability.selector";
import {
  selectSaveHistory,
  selectCallerIdName,
  selectIsEnabledAdvancedSecurity,
  selectAccountDefaultFaxFileType,
  selectIsUpdateAccountDetailsSuccessful,
} from "selectors/account.selector";
import {
  getCustomerInfo,
  updateAccountDetails,
  clearUpdateAccountDetails,
} from "stores/reducers/account.reducer";
import {
  getAllNumbers,
  getAssignedNumbers,
  updateFaxReception,
  clearUpdateFaxReception,
} from "stores/reducers/numbers.reducer";
import FaxNumberBackRow from "./FaxNumberBackRow";
import FaxNumberFrontRow from "./FaxNumberFrontRow";
import PleaseContactAdmin from "./PleaseContactAdmin";
import FaxReceptionBackRow from "./FaxReceptionBackRow";
import FaxReceptionFrontRow from "./FaxReceptionFrontRow";
import DisabledForCompliance from "./DisabledForCompliance";

function MyFaxPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const saveHistory = useSelector(selectSaveHistory);
  const calledIdName = useSelector(selectCallerIdName);
  const canISeeMyFaxRow = useSelector(selectCanISeeMyFaxRow);
  const assignedNumber = useSelector(selectIndividualAssignedNumber);
  const defaultFaxType = useSelector(selectAccountDefaultFaxFileType);
  const isAccountUpdated = useSelector(selectIsUpdateAccountDetailsSuccessful);
  const isFaxReceptionUpdated = useSelector(selectIsUpdateFaxReceptionSuccess);
  const isEnabledAdvancedSecurity = useSelector(selectIsEnabledAdvancedSecurity);
  const isFaxReceptionEnabled = useSelector(selectIsIndividualPlanFaxReceptionEnabled);
  const canIManageStoreFaxesInFaxPlus = useSelector(selectCanIManageStoreFaxesInFaxPlus);

  const notifyWhenDisabledInitially = Boolean(assignedNumber?.notify_when_disabled);

  const [editMode, setEditMode] = useState(false);
  const [fileType, setFileType] = useState(defaultFaxType);
  const [faxHeader, setFaxHeader] = useState<string>(calledIdName);
  const [storeFaxIn, setStoreFaxIn] = useState<boolean>(saveHistory);
  const [faxReception, setFaxReception] = useState(isFaxReceptionEnabled);
  const [notifyWhenDisabled, setNotifyWhenDisabled] = useState(notifyWhenDisabledInitially);

  const onCancel = useCallback(() => {
    setFaxHeader(calledIdName);
    setStoreFaxIn(saveHistory);
    setFileType(defaultFaxType);
    setFaxReception(isFaxReceptionEnabled);
    setEditMode(false);
  }, [calledIdName, defaultFaxType, isFaxReceptionEnabled, saveHistory]);

  const handleOnSave = useCallback(() => {
    if (
      (faxReception !== isFaxReceptionEnabled ||
        notifyWhenDisabled !== notifyWhenDisabledInitially) &&
      assignedNumber?.number
    ) {
      dispatch(
        updateFaxReception({
          toEnable: faxReception,
          numberToUpdate: assignedNumber.number,
          notifyWhenDisabled: notifyWhenDisabled,
        }),
      );
    }

    dispatch(
      updateAccountDetails({
        updateType: "myFax",
        accountData: {
          callerIdName: faxHeader,
          saveHistory: storeFaxIn,
          defaultFileType: fileType,
        },
      }),
    );
  }, [
    dispatch,
    fileType,
    faxHeader,
    storeFaxIn,
    faxReception,
    notifyWhenDisabled,
    isFaxReceptionEnabled,
    assignedNumber?.number,
    notifyWhenDisabledInitially,
  ]);

  useEffect(() => {
    if (isAccountUpdated) {
      dispatch(clearUpdateAccountDetails());
      dispatch(getCustomerInfo());
      setEditMode(false);
    }
  }, [dispatch, isAccountUpdated]);

  useEffect(() => {
    if (faxReception !== isFaxReceptionEnabled && isFaxReceptionUpdated) {
      dispatch(getAllNumbers());
      dispatch(getAssignedNumbers());
      dispatch(clearUpdateFaxReception());
    }
  }, [dispatch, faxReception, isFaxReceptionEnabled, isFaxReceptionUpdated]);

  useEffect(() => {
    // If the assignedNumber is changed and the local faxReception value is out of sync, update it
    setFaxReception(isFaxReceptionEnabled);
  }, [isFaxReceptionEnabled]);

  return (
    <Panel
      id="my-fax"
      sx={{ mb: 7 }}
      isFlipped={editMode}
      data-cy={dataCy.myFaxPanel}
      headerTitle={t("PROFILE.MY_FAX")}
    >
      <Panel.Front
        headerAction={
          <TextButton onClick={() => setEditMode(true)}>
            <Typography variant="panelFrontHeader">{t("COMMON.EDIT")}</Typography>
            <Box sx={{ ml: 1, color: "white" }}>
              <FontAwesomeIcon icon={faPenToSquare} color="white" />
            </Box>
          </TextButton>
        }
      >
        {canISeeMyFaxRow && <FaxNumberFrontRow />}
        <FaxReceptionFrontRow faxReception={faxReception} />
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.FAX_HEADER")}</Panel.Cell>
          <Panel.Cell variant="data">{faxHeader || "--"}</Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.FILE_TYPE")}</Panel.Cell>
          <Panel.Cell variant="data">{fileType.toUpperCase()}</Panel.Cell>
        </Panel.Row>

        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.STORE_FAX")}</Panel.Cell>
          <Panel.Cell variant="data">
            {storeFaxIn ? t("COMMON.ACTIVE") : t("COMMON.INACTIVE")}
          </Panel.Cell>
        </Panel.Row>
      </Panel.Front>

      <Panel.Back onCancel={onCancel} onConfirm={handleOnSave}>
        {canISeeMyFaxRow && <FaxNumberBackRow />}
        <FaxReceptionBackRow
          faxReception={faxReception}
          setFaxReception={setFaxReception}
          notifyWhenDisabled={notifyWhenDisabled}
          setNotifyWhenDisabled={setNotifyWhenDisabled}
        />
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.FAX_HEADER")}</Panel.Cell>
          <Panel.Cell variant="data">
            <TextField
              margin="none"
              type="text"
              value={faxHeader}
              placeholder={t("PROFILE.FAX_HEADER")}
              onChange={(e) => {
                setFaxHeader(e.target.value);
              }}
              inputProps={{
                maxLength: 20,
              }}
              style={{ maxWidth: "50%" }}
            />
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.FILE_TYPE")}</Panel.Cell>
          <Panel.Cell variant="data">
            {fileType && (
              <RadioGroup
                name={fileType}
                value={fileType}
                aria-label="file type"
                className={classes.fileTypeRadioGroup}
                onChange={({ target }) => setFileType(target.value)}
              >
                <FormControlLabel value="pdf" control={<Radio color="primary" />} label="PDF" />
                <FormControlLabel value="tiff" control={<Radio color="primary" />} label="TIFF" />
              </RadioGroup>
            )}
          </Panel.Cell>
        </Panel.Row>

        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.STORE_FAX")}</Panel.Cell>
          <Panel.Cell variant="data">
            <Box className={classes.saveHistory}>
              <div>
                <Switch
                  checked={storeFaxIn}
                  value={storeFaxIn || ""}
                  onChange={() => setStoreFaxIn((prev) => !prev)}
                  disabled={isEnabledAdvancedSecurity || !canIManageStoreFaxesInFaxPlus}
                />
                {storeFaxIn ? t("COMMON.ACTIVE") : t("COMMON.INACTIVE")}
              </div>
            </Box>
          </Panel.Cell>
          <Panel.Cell variant="actions">
            {isEnabledAdvancedSecurity ? (
              <DisabledForCompliance />
            ) : !canIManageStoreFaxesInFaxPlus ? (
              <PleaseContactAdmin />
            ) : null}
          </Panel.Cell>
        </Panel.Row>
      </Panel.Back>
    </Panel>
  );
}

const useStyles = makeStyles(() => ({
  fileTypeRadioGroup: {
    flexDirection: "row",
  },
  saveHistory: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default MyFaxPanel;
