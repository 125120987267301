import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import RS from "../../enums/requestStatus";
import { boxNames } from "../../enums/faxes";
import { historyApi, syncHistoryApi } from "../../api";
import syncHistoryEvents from "../../enums/syncHistory";
import { logout } from "./authentication.reducer";
import {
  syncInboxToAdd,
  syncInboxToRead,
  syncInboxToTrash,
  syncInboxToUnread,
  syncInboxToDelete,
  syncInboxToModify,
  syncInboxToRestore,
} from "./faxes.inbox.reducer";
import {
  syncSentboxToAdd,
  syncSentboxToTrash,
  syncSentboxToDelete,
  syncSentboxToModify,
  syncSentboxToRestore,
} from "./faxes.sentbox.reducer";
import {
  syncTrashboxToRead,
  syncTrashboxToTrash,
  syncTrashboxToUnread,
  syncTrashboxToDelete,
  syncTrashboxToModify,
  syncTrashboxToRestore,
} from "./faxes.trashbox.reducer";

const { inbox, trashbox, sentbox } = boxNames;
const { ADD, RESTORE, TRASH, UNREAD, MODIFY, DELETE, READ } = syncHistoryEvents;

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

export const clearSyncHistoryEvents = createAction("SYNC_HISTORY/CLEAR_SYNC_HISTORY_EVENTS");

const getSyncHistory = createAsyncThunk(
  "SYNC_HISTORY/GET_SYNC_HISTORY",
  async (params = {}, { getState, rejectWithValue }) => {
    const lastUpdate = getState().syncHistory.lastUpdate;
    if (lastUpdate) {
      params.since = params.since ?? lastUpdate;
    }
    try {
      let response = await syncHistoryApi.getSyncHistory(params);
      return { response };
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
  {
    condition: (_, { getState }) => {
      return getState().syncHistory.getSyncHistoryRequest.status !== RS.RUNNING;
    },
  },
);

export const syncCdrs = createAsyncThunk(
  "FAXES/SYNC_CDRS",
  async ({ boxData, downloadCdrs, memberUid, number }, { rejectWithValue, dispatch }) => {
    try {
      let response;
      if (downloadCdrs.length) {
        response = await historyApi.getByIds({ cdrIds: downloadCdrs, number, memberUid });
      }
      const inboxEvents = Object.keys(boxData[inbox].events);
      const sentboxEvents = Object.keys(boxData[sentbox].events);
      const trashboxEvents = Object.keys(boxData[trashbox].events);

      if (inboxEvents.length && boxData[inbox].isSyncAllowed) {
        for (const event of inboxEvents) {
          switch (event) {
            case ADD:
              dispatch(syncInboxToAdd({ response, boxData }));
              break;
            case RESTORE:
              dispatch(syncInboxToRestore({ response, boxData }));
              break;
            case DELETE:
              dispatch(syncInboxToDelete(boxData[inbox]["events"][DELETE]));
              break;
            case TRASH:
              dispatch(syncInboxToTrash(boxData[inbox]["events"][TRASH]));
              break;
            case READ:
              dispatch(syncInboxToRead(boxData[inbox]["events"][READ]));
              break;
            case UNREAD:
              dispatch(syncInboxToUnread(boxData[inbox]["events"][UNREAD]));
              break;
            case MODIFY:
              dispatch(
                syncInboxToModify({
                  response,
                  cdrIds: boxData[inbox]["events"][MODIFY],
                }),
              );
              break;
            default:
          }
        }
      }
      if (sentboxEvents.length && boxData[sentbox].isSyncAllowed) {
        for (const event of sentboxEvents) {
          switch (event) {
            case ADD:
              dispatch(syncSentboxToAdd({ response, boxData }));
              break;
            case RESTORE:
              dispatch(syncSentboxToRestore({ response, boxData }));
              break;
            case DELETE:
              dispatch(syncSentboxToDelete(boxData[sentbox]["events"][DELETE]));
              break;
            case TRASH:
              dispatch(syncSentboxToTrash(boxData[sentbox]["events"][TRASH]));
              break;
            case MODIFY:
              dispatch(
                syncSentboxToModify({
                  response,
                  cdrIds: boxData[sentbox]["events"][MODIFY],
                }),
              );
              break;
            default:
          }
        }
      }
      if (trashboxEvents.length && boxData[trashbox].isSyncAllowed) {
        for (const event of trashboxEvents) {
          switch (event) {
            case DELETE:
              dispatch(syncTrashboxToDelete(boxData[trashbox]["events"][DELETE]));
              break;
            case TRASH:
              dispatch(syncTrashboxToTrash({ response, boxData }));
              break;
            case RESTORE:
              dispatch(syncTrashboxToRestore(boxData[trashbox]["events"][RESTORE]));
              break;
            case MODIFY:
              dispatch(
                syncTrashboxToModify({
                  response,
                  cdrIds: boxData[trashbox]["events"][MODIFY],
                }),
              );
              break;
            case READ:
              dispatch(syncTrashboxToRead(boxData[trashbox]["events"][READ]));
              break;
            case UNREAD:
              dispatch(syncTrashboxToUnread(boxData[trashbox]["events"][UNREAD]));
              break;
            default:
          }
        }
      }
    } catch (error) {
      return rejectWithValue(error?.origin);
    }
  },
);

const initialState = {
  getSyncHistoryRequest: initialRequestState,

  events: null,
  lastUpdate: null,
};

const { reducer } = createSlice({
  name: "SYNC_HISTORY",
  initialState,
  extraReducers: {
    [clearSyncHistoryEvents]: (state) => {
      state.events = [];
    },
    [getSyncHistory.pending]: (state) => {
      state.getSyncHistoryRequest.status = RS.RUNNING;
      state.getSyncHistoryRequest.error = null;
    },
    [getSyncHistory.fulfilled]: (state, { payload }) => {
      state.getSyncHistoryRequest.status = RS.IDLE;
      state.getSyncHistoryRequest.response = payload.response;
      if (!state.events) {
        state.events = [];
      }
      if (payload.response?.events.length) {
        state.events?.push(...payload.response.events);
      }
      state.lastUpdate =
        payload.response.now ||
        payload.response.events?.[payload.response.events?.length - 1]?.date;
    },
    [getSyncHistory.rejected]: (state, { payload }) => {
      state.getSyncHistoryRequest.status = RS.ERROR;
      state.getSyncHistoryRequest.error = payload;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export { getSyncHistory };

export default reducer;
