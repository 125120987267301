import { useTranslation } from "react-i18next";
import { Box, Link, Typography, alohi, makeStyles } from "@alohi/kit";

import { helpCenterUrls } from "routes/urls";

function Tooltip() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t("COMMON.SEND_FAX")}</Typography>
      <Typography className={classes.text}>{t("SENT_FAX.TOOLTIP_PROMOTION")}</Typography>
      <Typography className={classes.text}>{t("SENT_FAX.TOOLTIP_COMPARE_PLANS")}</Typography>
      <Typography className={classes.textHelp}>{t("SENT_FAX.TOOLTIP_HELP_CENTER")}</Typography>

      <Link
        href={helpCenterUrls.sendFaxForFree}
        withIcon
        className={classes.link}
        color={alohi.lightBlue}
        fontSize={12}
      >
        <li>{t("SENT_FAX.TOOLTIP_SEND_FAX_FREE")}</li>
      </Link>
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(1),
  },
  title: {
    color: alohi.white,
  },
  text: {
    fontSize: 12,
    color: alohi.white,
    marginTop: spacing(1),
  },
  textHelp: {
    fontSize: 12,
    color: alohi.white,
    marginTop: spacing(2),
  },
  link: {
    display: "flex",
    alignItems: "center",
    marginLeft: spacing(1),
    paddingTop: spacing(0.5),
    justifyContent: "center",
    "&& .svg-inline--fa": {
      marginLeft: spacing(1),
    },
    "&& a": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

export default Tooltip;
