import { Trans, useTranslation } from "react-i18next";
import { TextButton, Typography, makeStyles, useBool, Box } from "@alohi/kit";

import { urls } from "routes/urls";
import { Link, MessageBox } from "ui";
import { useAppSelector } from "stores/store";
import ContactUsModal from "views/ContactUs/ContactUsModal";
import { selectCountryForPlan } from "selectors/plan.selector";
import { SUBJECTS } from "views/ContactUs/components/SelectSubject/SelectSubject";
import { selectApprovedDocuments } from "selectors/address.selector";

function CountryInfo() {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedCountry = useAppSelector(selectCountryForPlan);
  const approvedBundles = useAppSelector(selectApprovedDocuments);

  const [isContactUsModalOpen, isContactUsModalOpenBool] = useBool(false);

  const hasApprovedBundleForSelectedCountry = approvedBundles?.length > 0;

  if (selectedCountry?.iso == "FR") {
    return (
      <>
        <Box className={classes.wrapper}>
          <MessageBox variant="info">
            <Box className={classes.content}>
              <Typography>{t("ADDRESS.FRENCH_NUMBERS")}</Typography>
              {hasApprovedBundleForSelectedCountry ? (
                <Typography className={classes.text}>{t("ADDRESS.CONTACT_TO_CONTINUE")}</Typography>
              ) : (
                <>
                  <Typography className={classes.text}>
                    <Trans
                      i18nKey="ADDRESS.SUBMIT_REGULATORY_DOCUMENTS"
                      components={{
                        documents: (
                          <Link target="_blank" rel="noopener" href={urls.profile.compliance} />
                        ),
                      }}
                    />
                  </Typography>
                  <Typography className={classes.text}>
                    {t("ADDRESS.DOCUMENT_CONFIRMATION")}
                  </Typography>
                </>
              )}
              <TextButton className={classes.button} onClick={isContactUsModalOpenBool.setTrue}>
                {t("CONTACT_US.TITLE")}
              </TextButton>
            </Box>
          </MessageBox>
        </Box>

        {isContactUsModalOpen ? (
          <ContactUsModal
            isHelpBypass
            blocked={false}
            preSelectedSubject={SUBJECTS.SALES}
            handleClosure={isContactUsModalOpenBool.setFalse}
          />
        ) : null}
      </>
    );
  }

  return null;
}

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    width: "100%",
    alignSelf: "center",
    padding: spacing(0, 6, 3, 6),
  },
  content: {
    display: "flex",
    padding: spacing(1, 0),
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  text: {
    marginTop: spacing(1),
  },
  button: {
    marginTop: spacing(2),
    alignSelf: "flex-start",
  },
}));

export default CountryInfo;
