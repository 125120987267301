import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@alohi/kit";

import { deleteGroup } from "stores/reducers/contacts.groups.reducer";
import SingleGroupMessage from "components/Contacts/SingleGroupMessage";
import { deleteSharedGroup } from "stores/reducers/contacts.sharedGroups.reducer";

interface DeleteGroupModalProps {
  groupIds: string[];
  isSharedGroup: boolean;
  handleClosure: () => void;
}

function DeleteGroupModal({ groupIds, isSharedGroup, handleClosure }: DeleteGroupModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const renderMessage = () => {
    if (groupIds.length === 1) {
      return <SingleGroupMessage isSharedGroup={isSharedGroup} groupId={groupIds[0]} />;
    }

    return t("CONTACTS.QUESTION_REMOVE_SELECTED_CONTACTS", {
      listName: t("CONTACTS.CONTACTS").toLowerCase(),
    });
  };

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      title={t("CONTACTS.DELETE_GROUP")}
      onConfirm={() => {
        if (isSharedGroup) {
          dispatch(deleteSharedGroup({ idsToDelete: groupIds }));
        } else {
          dispatch(deleteGroup({ idsToDelete: groupIds }));
        }

        handleClosure();
      }}
    >
      <Box p={3}>
        <Typography type="body">{renderMessage()}</Typography>
      </Box>
    </Modal>
  );
}

export default DeleteGroupModal;
