import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Panel, Button, Typography, Box, useSnackbar } from "@alohi/kit";

import { Helper } from "ui";
import { helpCenterUrls } from "routes/urls";
import { SLACK_CLIENT_ID, SLACK_OAUTH_URI } from "config";
import { selectIsPlanCorporate } from "selectors/plan.selector";
import SlackNotificationsModal from "views/Notifications/SlackNotificationsModal";
import RequireUpgradeToCorporate from "views/RequireUpgrade/RequireUpgradeToCorporate";
import {
  selectIsSlackActive,
  selectIsPostSlackTokenError,
  selectIsPostSlackTokenSuccess,
} from "selectors/integrations.selector";
import {
  getSlackToken,
  postSlackToken,
  getSlackChannels,
  clearPostSlackToken,
} from "stores/reducers/integrations.slack.reducer";
import ConfirmDeactivateSlackModal from "./ConfirmDeactivateSlackModal";

function Slack() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const redirectUri = `${window.location.origin}${window.location.pathname}`;
  const slackUri = `${SLACK_OAUTH_URI}?client_id=${SLACK_CLIENT_ID}&scope=channels:join,channels:read,chat:write,files:write,im:history,users:read,groups:read&redirect_uri=${redirectUri}`;
  const queryParams = queryString.parse(location.search);

  const isSlackActive = useSelector(selectIsSlackActive);
  const isPlanCorporate = useSelector(selectIsPlanCorporate);
  const isActivationError = useSelector(selectIsPostSlackTokenError);
  const isActivationSuccess = useSelector(selectIsPostSlackTokenSuccess);

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSlackNotificationsModalOpen, setIsSlackNotificationsModalOpen] = useState(false);
  const [
    isSlackNotificationsRequiresUpgradeModalOpen,
    setIsSlackNotificationsRequiresUpgradeModalOpen,
  ] = useState(false);
  const [isConfirmDeactivateSlackModalOpen, setIsConfirmDeactivateSlackModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getSlackToken());
  }, [dispatch]);

  // After Slack redirect
  useEffect(() => {
    if (queryParams.error) {
      history.replace(window.location.pathname);
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    } else if (queryParams.code) {
      setIsButtonLoading(true);
      history.replace(window.location.pathname);

      dispatch(
        postSlackToken({
          redirectUri,
          code: queryParams.code,
        }),
      );
    }
  }, [
    t,
    history,
    location,
    dispatch,
    redirectUri,
    queryParams,
    location.href,
    enqueueSnackbar,
    queryParams.code,
    queryParams.error,
  ]);

  useEffect(() => {
    (async () => {
      if (isActivationSuccess) {
        await Promise.all([dispatch(getSlackToken()), dispatch(getSlackChannels())]);

        setIsSlackNotificationsModalOpen(true);
        setIsButtonLoading(false);
        dispatch(clearPostSlackToken());
      } else if (isActivationError) {
        enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
        setIsButtonLoading(false);
        dispatch(clearPostSlackToken());
      }
    })();
  }, [dispatch, enqueueSnackbar, isActivationError, isActivationSuccess, t]);

  useEffect(() => {
    return () => {
      dispatch(clearPostSlackToken());
    };
  }, [dispatch]);

  const handleClick = () => {
    if (!isPlanCorporate) {
      setIsSlackNotificationsRequiresUpgradeModalOpen(true);
    } else if (isSlackActive) {
      setIsConfirmDeactivateSlackModalOpen(true);
    } else {
      setIsButtonLoading(true);
      window.location.href = slackUri;
    }
  };

  return (
    <>
      <Panel
        id="slack"
        sx={{ mb: 7 }}
        headerTitle={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faSlack} />
            <Typography type="panelFrontHeader" sx={{ px: 1 }}>
              {t("COMMON.SLACK")}
            </Typography>
            <a href={helpCenterUrls.slack} target="_blank" rel="noreferrer">
              <Helper size="lg" variant="light" />
            </a>
          </Box>
        }
      >
        <Panel.Front>
          <Panel.Row>
            <Panel.Cell variant="title">{t("COMMON.STATUS")}</Panel.Cell>
            <Panel.Cell variant="data">
              {isSlackActive !== null &&
                (isSlackActive ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE"))}
            </Panel.Cell>
            <Panel.Cell variant={"actions"}>
              {isSlackActive !== null && (
                <Button variant="white" onClick={handleClick} isLoading={isButtonLoading}>
                  {isSlackActive ? t("PROFILE.DEACTIVATE") : t("PROFILE.ACTIVATE")}
                </Button>
              )}
            </Panel.Cell>
          </Panel.Row>
        </Panel.Front>
      </Panel>

      {isConfirmDeactivateSlackModalOpen ? (
        <ConfirmDeactivateSlackModal
          handleClosure={() => setIsConfirmDeactivateSlackModalOpen(false)}
        />
      ) : null}

      {isSlackNotificationsRequiresUpgradeModalOpen && (
        <RequireUpgradeToCorporate
          title={t("SLACK.ACTIVATION")}
          featureName={t("SLACK.INTEGRATION")}
          handleClosure={() => setIsSlackNotificationsRequiresUpgradeModalOpen(false)}
        />
      )}

      {isSlackNotificationsModalOpen && (
        <SlackNotificationsModal handleClosure={() => setIsSlackNotificationsModalOpen(false)} />
      )}
    </>
  );
}

export default Slack;
