import { lazy, Suspense } from "react";

const Uploader = lazy(() => import("./Uploader"));

function UploaderView() {
  return (
    <Suspense fallback={<></>}>
      <Uploader />
    </Suspense>
  );
}

export default UploaderView;
