import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/pro-light-svg-icons";
import { ButtonBase, MenuItem, Switch, Box, Modal, useSnackbar } from "@alohi/kit";

import FormRow from "components/Forms/FormRow";
import { InputAdornment, useTheme } from "ui";
import { isValidEmail } from "helpers/inputValidation";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import FormRowSelectInput from "components/Forms/FormRowSelectInput";
import {
  selectEmailNotificationOptions,
  selectIsUpdateNotificationsError,
  selectEmailNotificationAddresses,
  selectIsUpdateNotificationsRunning,
  selectIsUpdateNotificationsSuccessful,
  selectEmailNotificationAvailableOptions,
} from "selectors/account.notifications.selector";
import {
  updateNotifications,
  clearUpdateNotifications,
} from "stores/reducers/account.notifications.reducer";

function EmailNotificationsModal({ handleClosure }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isError = useSelector(selectIsUpdateNotificationsError);
  const isRunning = useSelector(selectIsUpdateNotificationsRunning);
  const isSuccessful = useSelector(selectIsUpdateNotificationsSuccessful);

  const emails = useSelector(selectEmailNotificationAddresses);
  const availableOptions = useSelector(selectEmailNotificationAvailableOptions);
  const [{ receiveFax: initialReceiveFax }, { sendFax: initialSendFax }] = useSelector(
    selectEmailNotificationOptions,
  );

  const [sendFax, setSendFax] = useState(initialSendFax.id);
  const [selectedEmails, setSelectedEmails] = useState(emails);
  const [receiveFax, setReceiveFax] = useState(initialReceiveFax.id);
  const [hasMultipleEmails, setHasMultipleEmails] = useState(emails.length > 1);
  const [errorEmails, setErrorEmails] = useState(emails.map((email) => isValidEmail(email)));

  const handleAddOrRemoveEmails = useCallback(
    (remove, index) => () => {
      if (remove) {
        setSelectedEmails((prev) => {
          const copy = prev.slice();
          copy.splice(index, 1);
          return copy;
        });
        setErrorEmails((prev) => {
          prev.splice(index, 1);
          return prev;
        });
      } else {
        setSelectedEmails((prev) => [...prev, ""]);
        setErrorEmails((prev) => [...prev, true]);
      }
    },
    [],
  );

  const handleChangeEmail = useCallback((string, index) => {
    setSelectedEmails((prev) => {
      const copy = prev.slice();
      copy.splice(index, 1, string);
      setErrorEmails((prev) => {
        prev.splice(index, 1, string === "" || isValidEmail(string));
        return prev;
      });
      return copy;
    });
  }, []);

  const handleOnConfirm = useCallback(() => {
    const set = new Set([...selectedEmails.map((element) => element.toLowerCase())]);
    if (set.size !== selectedEmails.length) {
      enqueueSnackbar(t("FORMS.DUPLICATE_EMAIL_NOTIFY"), { variant: "error" });
    } else {
      dispatch(
        updateNotifications({
          updateType: "email",
          accountData: {
            sendFax,
            receiveFax,
            hasMultipleEmails,
            emails: selectedEmails,
          },
        }),
      );
    }
  }, [dispatch, selectedEmails, hasMultipleEmails, sendFax, receiveFax, enqueueSnackbar, t]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      handleClosure();
    } else if (isSuccessful) {
      handleClosure();
    }
  }, [isSuccessful, isError, handleClosure, enqueueSnackbar, t, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearUpdateNotifications());
    };
  }, [dispatch]);

  const canConfirm = selectedEmails.length === 1 || errorEmails.every((element) => element);

  return (
    <Modal
      onCancel={handleClosure}
      onConfirm={handleOnConfirm}
      isModalDisabled={isRunning}
      isConfirmLoading={isRunning}
      isConfirmDisabled={!canConfirm}
      title={t("COMMON.MODIFY_NOTIFICATION")}
    >
      <Box component="form" p={3} pr={"15%"}>
        <FormRowSelectInput
          label={t("PROFILE.RECEIVE_FAX")}
          id="receive-fax-notifications-input"
          value={receiveFax}
          onChange={({ target }) => setReceiveFax(target.value)}
          labelGridWidth={5}
        >
          {availableOptions.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </FormRowSelectInput>

        <FormRowSelectInput
          label={t("PROFILE.SEND_FAX")}
          id="send-fax-notifications-input"
          value={sendFax}
          onChange={({ target }) => setSendFax(target.value)}
          labelGridWidth={5}
        >
          {availableOptions.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </FormRowSelectInput>

        <FormRow labelGridWidth={5} label={t("PROFILE.SEND_MULTIPLE_EMAIL")}>
          <Box ml={-1}>
            <Switch
              value={hasMultipleEmails}
              checked={hasMultipleEmails}
              onChange={() => setHasMultipleEmails((prev) => !prev)}
            />
          </Box>
        </FormRow>

        <Box>
          {hasMultipleEmails ? (
            <>
              {selectedEmails.map((email, index) => (
                <Box key={`email-${index}`}>
                  <FormRowTextInput
                    labelGridWidth={5}
                    type="email"
                    value={email}
                    id={`email-${index}`}
                    disabled={index === 0}
                    label={`Email ${index + 1}`}
                    placeholder={`Email ${index + 1}`}
                    error={index !== 0 && !errorEmails[index] && email !== ""}
                    onChange={({ target }) => handleChangeEmail(target.value, index)}
                    endAdornment={
                      <InputAdornment position="end">
                        <ButtonBase
                          aria-label="remove email"
                          onClick={handleAddOrRemoveEmails(true, index)}
                        >
                          {index !== 0 && (
                            <FontAwesomeIcon
                              size={"lg"}
                              icon={faTimes}
                              color={theme.palette.secondary.main}
                            />
                          )}
                        </ButtonBase>
                      </InputAdornment>
                    }
                  />
                </Box>
              ))}
              {selectedEmails.length < 5 ? (
                <FormRow labelGridWidth={5}>
                  <ButtonBase onClick={handleAddOrRemoveEmails(false)}>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faPlusCircle}
                      color={theme.palette.primary.main}
                    />
                  </ButtonBase>
                </FormRow>
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}

EmailNotificationsModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default EmailNotificationsModal;
