import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Panel, Button, useSnackbar } from "@alohi/kit";

import { selectIsPlanEnterprise } from "selectors/plan.selector";
import { selectIsCorporateTwoFaActive } from "selectors/account.selector";
import { selectCanIManageCorporateTwoFa } from "selectors/ability.selector";
import RequireUpgradeToEnterprise from "views/RequireUpgrade/RequireUpgradeToEnterprise";
import ActivateCorporateTwoFaModal from "views/TwoFa/ActivateCorporateTwoFaModal";
import DeactivateCorporateTwoFaModal from "views/TwoFa/DeactivateCorporateTwoFaModal";

function CorporateTwoFaRow() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const planIsEnterprise = useSelector(selectIsPlanEnterprise);
  const isCorporateTwoFaActive = useSelector(selectIsCorporateTwoFaActive);
  const canIManageCorporateTwoFa = useSelector(selectCanIManageCorporateTwoFa);

  const [isRequireUpgradeModalOpen, setIsRequireUpgradeModalOpen] = useState(false);
  const [isActivateCorporateTwoFaModalOpen, setIsActivateCorporateTwoFaModalOpen] = useState(false);
  const [isDeactivateCorporateTwoFaModalOpen, setIsDeactivateCorporateTwoFaModalOpen] =
    useState(false);

  // Showed to individuals, owners and admins
  const handleOnClick = useCallback(() => {
    if (!planIsEnterprise) {
      setIsRequireUpgradeModalOpen(true);
    } else if (!canIManageCorporateTwoFa) {
      enqueueSnackbar(t("PROFILE.2FA_ASK_OWNER"), { variant: "info" });
    } else if (isCorporateTwoFaActive) {
      setIsDeactivateCorporateTwoFaModalOpen(true);
    } else {
      setIsActivateCorporateTwoFaModalOpen(true);
    }
  }, [canIManageCorporateTwoFa, enqueueSnackbar, isCorporateTwoFaActive, planIsEnterprise, t]);

  return (
    <>
      <Panel.Row>
        <Panel.Cell variant="title">{t("PROFILE.ENFORCE2FA_TITLE")}</Panel.Cell>
        <Panel.Cell variant="data">
          {isCorporateTwoFaActive ? t("PROFILE.ACTIVE") : t("PROFILE.INACTIVE")}
        </Panel.Cell>
        <Panel.Cell variant="actions">
          <Button variant="white" onClick={handleOnClick}>
            {isCorporateTwoFaActive ? t("PROFILE.DEACTIVATE") : t("PROFILE.ACTIVATE")}
          </Button>
        </Panel.Cell>
      </Panel.Row>

      {isRequireUpgradeModalOpen && (
        <RequireUpgradeToEnterprise
          featureName={t("PROFILE.ENFORCE2FA")}
          title={t("PROFILE.ENFORCE2FA_TITLE")}
          handleClosure={() => setIsRequireUpgradeModalOpen(false)}
        />
      )}
      {isDeactivateCorporateTwoFaModalOpen && (
        <DeactivateCorporateTwoFaModal
          handleClosure={() => setIsDeactivateCorporateTwoFaModalOpen(false)}
        />
      )}
      {isActivateCorporateTwoFaModalOpen && (
        <ActivateCorporateTwoFaModal
          handleClosure={() => setIsActivateCorporateTwoFaModalOpen(false)}
        />
      )}
    </>
  );
}

export default CorporateTwoFaRow;
