import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BigButton, Box, Divider, makeStyles } from "@alohi/kit";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";

import { Typography } from "ui";
import { CountryDetails, RequirementAddressVerification } from "api/types/country";
import business from "assets/images/business.png";
import individual from "assets/images/individual.png";
import FormRow from "components/Forms/FormRow";
import { documentUserTypes } from "enums/bundle";
import useBool from "hooks/useBool";
import { selectUserAddressRequirements } from "selectors/address.selector";
import { selectAvailableCountries } from "selectors/plan.selector";
import { clearAddressRequirements, getAddressRequirements } from "stores/reducers/address.reducer";
import { CreateAddressBundleContext } from "../../context/createAddressBundle.context";
import RegulatoryRequirements from "./components/RegulatoryRequirements";
import SelectCountry from "./components/SelectCountry";

interface BundleTypeProps {
  withCountrySelector?: boolean;
  preselectedCountry?: string;
  preselectedNumberType?: string;
}

function BundleType({
  withCountrySelector,
  preselectedCountry,
  preselectedNumberType,
}: BundleTypeProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [{ numberType, country, userType }, localDispatch] = useContext(CreateAddressBundleContext);

  const userFields = useSelector(selectUserAddressRequirements(userType));

  const isIndividualSelected = userType === documentUserTypes.individual;
  const isBusinessSelected = userType === documentUserTypes.business;

  const availableCountries: CountryDetails[] = useSelector(selectAvailableCountries);
  const selectedAvailableCountry = availableCountries?.find(
    (availableCountry) => availableCountry.iso === country,
  );

  const isIndividualBundleAvailable = useMemo(() => {
    const test = selectedAvailableCountry?.requirements.find(
      ({ type }) => type === "address_verification_v2",
    ) as RequirementAddressVerification;

    return test?.end_user_type?.includes(documentUserTypes.individual.toLowerCase()) ?? false;
  }, [selectedAvailableCountry?.requirements]);

  const isBusinessBundleAvailable = useMemo(() => {
    const test = selectedAvailableCountry?.requirements.find(
      ({ type }) => type === "address_verification_v2",
    ) as RequirementAddressVerification;

    return test?.end_user_type?.includes(documentUserTypes.business.toLowerCase()) ?? false;
  }, [selectedAvailableCountry?.requirements]);

  const [canMoveForward, canMoveForwardHandler] = useBool(false);

  const updateType = useCallback(
    (type) => () => {
      dispatch(clearAddressRequirements());
      dispatch(
        /* eslint-disable-next-line */
        // @ts-ignore
        getAddressRequirements({
          country,
          numberType,
          userType: type,
        }),
      );
      localDispatch({ type: "UPDATE_USER_TYPE", payload: type });
      // The request was made, if there is no error and a response is returned we can move forward
      canMoveForwardHandler.setTrue();
    },
    [localDispatch, numberType, country, dispatch, canMoveForwardHandler],
  );

  useEffect(() => {
    // Change to next step once we have the data from the backend
    if (canMoveForward && userFields) {
      localDispatch({ type: "STEP_FORWARD" });
      canMoveForwardHandler.toggle();
    }
  }, [canMoveForward, canMoveForwardHandler, localDispatch, userFields, userType]);

  return (
    <>
      {withCountrySelector ? (
        <>
          <SelectCountry
            preselectedCountry={preselectedCountry}
            preselectedNumberType={preselectedNumberType}
          />
          {country ? (
            <Box px={20}>
              <Divider />
            </Box>
          ) : null}
        </>
      ) : null}
      {country ? (
        <Box px={5} py={3} display="flex" flexDirection="column" justifyContent="center">
          <RegulatoryRequirements withCountrySelector={withCountrySelector} />
          <FormRow
            label={
              <Box display="flex" flexDirection="column" m={2}>
                <Typography variant="body" style={{ fontWeight: 700 }}>
                  {t("ADDRESS.BUNDLE_USER_TYPE")}
                </Typography>
                <Typography variant="subLabel">{t("ADDRESS.BUNDLE_PURCHASE_AS")}</Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent={"space-around"}>
              <BigButton
                width="120px"
                height="120px"
                className={!isIndividualBundleAvailable ? classes.disabled : undefined}
                onClick={updateType(documentUserTypes.individual)}
                isSelected={isIndividualSelected}
                isDisabled={!isIndividualBundleAvailable}
              >
                <img src={individual} alt="individual" width="90px" height="auto" />
                <Typography variant="subtitle3">{t("ADDRESS.BUNDLE_INDIVIDUAL")}</Typography>
              </BigButton>
              <BigButton
                width="120px"
                height="120px"
                className={!isBusinessBundleAvailable ? classes.disabled : undefined}
                onClick={updateType(documentUserTypes.business)}
                isSelected={isBusinessSelected}
                isDisabled={!isBusinessBundleAvailable}
              >
                <img src={business} alt="business" width="90px" height="auto" />
                <Typography variant="subtitle3">{t("ADDRESS.BUNDLE_BUSINESS")}</Typography>
              </BigButton>
            </Box>
          </FormRow>
        </Box>
      ) : null}
    </>
  );
}

const useStyles = makeStyles({
  disabled: {
    cursor: "not-allowed",
    pointerEvents: "auto",
  },
});

export default memo(BundleType);
