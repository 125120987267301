import { makeStyles } from "ui";

export default makeStyles(() => ({
  "@keyframes blink": {
    from: { opacity: 0.4 },
    to: { opacity: 1 },
  },

  circleContainer: {
    width: "auto",
    borderRadius: "50px",
    padding: "62px 10px",
    display: "table",
    margin: "0 auto",
    position: "relative",
    "& > span": {
      float: "left",
      margin: "0 2px",
      backgroundColor: "#59abe3",
      display: "block",
      borderRadius: "50%",
      opacity: "0.4",
    },
    // "@-webkit-keyframes blink": {
    //     "50%": {
    //       opacity: 1
    //     }
    //   },

    //   "@keyframes blink" :{
    //     "50%": {
    //       opacity: 1
    //     }
    //   },
    "& > span:nth-of-type(1)": {
      // "-webkit-animation": "1s blink infinite 0.09091s",
      // animation: "1s blink infinite 0.09091s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.09091s",
    },
    "& > span:nth-of-type(2)": {
      // "-webkit-animation": "1s blink infinite 0.18182s",
      // animation: "1s blink infinite 0.18182s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.18182s",
    },
    "& > span:nth-of-type(3)": {
      // "-webkit-animation": "1s blink infinite 0.27273s",
      // animation: "1s blink infinite 0.27273s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.27273s",
    },
    "& > span:nth-of-type(4)": {
      // "-webkit-animation": "1s blink infinite 0.36364s",
      // animation: "1s blink infinite 0.36364s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.36364s",
    },
    "& > span:nth-of-type(5)": {
      // "-webkit-animation": "1s blink infinite 0.45455s",
      // animation: "1s blink infinite 0.45455s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.45455s",
    },
    "& > span:nth-of-type(6)": {
      // "-webkit-animation": "1s blink infinite 0.54545s",
      // animation: "1s blink infinite 0.54545s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.54545s",
    },
    "& > span:nth-of-type(7)": {
      // "-webkit-animation": "1s blink infinite 0.63636s",
      // animation: "1s blink infinite 0.63636s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.63636s",
    },
    "& > span:nth-of-type(8)": {
      // "-webkit-animation": "1s blink infinite 0.72727s",
      // animation: "1s blink infinite 0.72727s",
      animationName: "$blink",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDelay: "0.72727s",
    },
    //   span:nth-of-type(9) {
    //     -webkit-animation: 1s blink infinite 0.81818s,
    //     animation: 1s blink infinite 0.81818s,
    //   },
    //   span:nth-of-type(10) {
    //     -webkit-animation: 1s blink infinite 0.90909s,
    //     animation: 1s blink infinite 0.90909s,
    //   },
    //   span:nth-of-type(11) {
    //     -webkit-animation: 1s blink infinite 1s,
    //     animation: 1s blink infinite 1s,
    //   },
  },
}));
