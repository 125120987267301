import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar, Box, Typography, makeStyles } from "@alohi/kit";

import { PhoneNumberFilter } from "components/Core/Filters";
import { selectAreaForCountry, selectCountryForPlan } from "selectors/plan.selector";
import {
  selectBulkNumbersAmount,
  selectIsBuyingBulkNumbers,
  selectIsVerifyNumberError,
  selectSelectedNumberSource,
  selectIsVerifyNumberNoContent,
} from "selectors/verifications.selector";

function PreviewNumber() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isError = useSelector(selectIsVerifyNumberError);
  const areaForCountry = useSelector(selectAreaForCountry);
  const selectedCountry = useSelector(selectCountryForPlan);
  const numberSource = useSelector(selectSelectedNumberSource);
  const bulkNumberAmount = useSelector(selectBulkNumbersAmount);
  const isNoContent = useSelector(selectIsVerifyNumberNoContent);
  const isBuyingBulkNumbers = useSelector(selectIsBuyingBulkNumbers);

  const bulkNumberText = useMemo(() => {
    if (areaForCountry?.value) {
      switch (areaForCountry.value) {
        case "random-area-code":
          return t("ADD_NUMBER.ADD_RANDOM_BULK_NUMBERS", {
            amount: bulkNumberAmount,
          });
        case "toll-free":
          return t("ADD_NUMBER.ADD_TOLL_FREE_BULK_NUMBERS", {
            amount: bulkNumberAmount,
          });
        default:
          return (
            <>
              <Typography type="body">
                {t("ADD_NUMBER.ADD_AREA_CODE_BULK_NUMBERS", {
                  amount: bulkNumberAmount,
                })}
              </Typography>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize" color="alohi.gray">
                {`+1 ${areaForCountry.value} — —`}
              </Box>
            </>
          );
      }
    }
  }, [t, areaForCountry?.value, bulkNumberAmount]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
    }
  }, [isError, t, enqueueSnackbar]);

  useEffect(() => {
    if (isNoContent) {
      enqueueSnackbar(t("ADD_NUMBER.NO_CONTENT_CUSTOM"), { variant: "info" });
    }
  }, [isNoContent, t, enqueueSnackbar]);

  const content = isBuyingBulkNumbers ? (
    bulkNumberText
  ) : (
    <>
      <Typography type="body">{`${t("ADD_NUMBER.YOUR_NUMBER_WILL")}${t(
        "COMMON.COLON",
      )} `}</Typography>
      <Box fontWeight="fontWeightBold" fontSize="h6.fontSize" color="alohi.gray">
        {numberSource ? (
          <PhoneNumberFilter number={numberSource} />
        ) : selectedCountry ? (
          `+ ${selectedCountry.country_code} -- --`
        ) : (
          "+ --- -- --"
        )}
      </Box>
    </>
  );

  return <Box className={classes.wrapper}>{content}</Box>;
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  wrapper: {
    height: "90px",
    display: "flex",
    borderRadius: "8px",
    textAlign: "center",
    alignItems: "center",
    margin: spacing(0, 3),
    flexDirection: "column",
    justifyContent: "center",
    background: alohi.blue200,
  },
}));

export default PreviewNumber;
