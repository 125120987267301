import { useCallback } from "react";
import { FaButton } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";

import { urls } from "routes/urls";
import { contactsRoutes } from "enums/contacts";
import { useAppDispatch, useAppSelector } from "stores/store";
import { Contact, Group } from "stores/reducers/contacts.helpers";
import { SendFaxRouteState } from "views/SendFax/components/InitSendFaxProviders";
import { useSendFaxDestinationHelpers } from "views/SendFax/contexts/helpers";
import { updateGroupSelection } from "stores/reducers/contacts.groups.reducer";
import { ContactDestination, GroupDestination } from "views/SendFax/contexts/store";
import { updateSharedGroupSelection } from "stores/reducers/contacts.sharedGroups.reducer";
import { updateWhitelistContactsSelection } from "stores/reducers/contacts.whitelist.reducer";
import { updateSharedContactsSelection } from "stores/reducers/contacts.sharedContacts.reducer";
import {
  selectGroups,
  selectSharedGroups,
  selectSharedContacts,
  selectWhitelistContacts,
  selectIdsOfCheckedContacts,
} from "selectors/contacts.selector";

function SendFaxButton() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    params: { contactsTab = contactsRoutes.whitelist },
  } = useRouteMatch<{ contactsTab: string }>();
  const { handleGroupDestination, handleContactDestination } = useSendFaxDestinationHelpers();

  const groupList = useAppSelector<null | Group[]>(selectGroups);
  const contacts = useAppSelector<null | Contact[]>(selectWhitelistContacts);
  const sharedGroupsList = useAppSelector<null | Group[]>(selectSharedGroups);
  const sharedContactsList = useAppSelector<null | Contact[]>(selectSharedContacts);
  const idsOfCheckedContacts = useAppSelector(selectIdsOfCheckedContacts(contactsTab));

  const handleSendFax = useCallback(() => {
    switch (contactsTab) {
      case contactsRoutes.whitelist:
        const numbers = contacts?.filter((contact) => idsOfCheckedContacts.includes(contact.id));

        if (numbers?.length) {
          const destination = numbers.map<ContactDestination>((contact) =>
            handleContactDestination(contact, "contact"),
          );

          history.push(urls.sendFax, {
            destination,
          } satisfies SendFaxRouteState);
          dispatch(updateWhitelistContactsSelection([]));
        }

        break;
      case contactsRoutes.groups:
        const groups = groupList?.filter((group) => idsOfCheckedContacts.includes(group.id));

        if (groups?.length) {
          const destination = groups.map<GroupDestination>((group) =>
            handleGroupDestination(group, "group"),
          );

          history.push(urls.sendFax, {
            destination,
          } satisfies SendFaxRouteState);
          dispatch(updateGroupSelection([]));
        }

        break;
      case contactsRoutes["shared-contacts"]: {
        const numbers = sharedContactsList?.filter((contact) =>
          idsOfCheckedContacts.includes(contact.id),
        );

        if (numbers?.length) {
          const destination = numbers.map<ContactDestination>((contact) =>
            handleContactDestination(contact, "sharedContact"),
          );

          history.push(urls.sendFax, {
            destination,
          } satisfies SendFaxRouteState);
          dispatch(updateSharedContactsSelection([]));
        }

        break;
      }
      case contactsRoutes["shared-groups"]: {
        const groups = sharedGroupsList?.filter((group) => idsOfCheckedContacts.includes(group.id));

        if (groups?.length) {
          const destination = groups.map<GroupDestination>((group) =>
            handleGroupDestination(group, "sharedGroup"),
          );

          history.push(urls.sendFax, {
            destination,
          } satisfies SendFaxRouteState);
          dispatch(updateSharedGroupSelection([]));
        }

        break;
      }
    }
  }, [
    history,
    contacts,
    dispatch,
    groupList,
    contactsTab,
    sharedGroupsList,
    sharedContactsList,
    idsOfCheckedContacts,
    handleGroupDestination,
    handleContactDestination,
  ]);

  if (
    ![
      contactsRoutes.groups,
      contactsRoutes.whitelist,
      contactsRoutes["shared-groups"],
      contactsRoutes["shared-contacts"],
    ].includes(contactsTab)
  ) {
    return null;
  }

  return (
    <FaButton
      variant="light"
      icon={faPaperPlane}
      onClick={handleSendFax}
      tooltip={t("COMMON.SEND_FAX")}
    />
  );
}

export default SendFaxButton;
