import { Trans, useTranslation } from "react-i18next";

import { useAppSelector } from "stores/store";
import { Group } from "stores/reducers/contacts.helpers";
import { selectGroupById, selectSharedGroupById } from "selectors/contacts.selector";

interface SingleGroupMessageProps {
  groupId: string;
  isSharedGroup: boolean;
}

function SingleGroupMessage({ isSharedGroup, groupId }: SingleGroupMessageProps) {
  const { t } = useTranslation();

  const normalGroup = useAppSelector<Group | null>(selectGroupById(groupId));
  const sharedGroup = useAppSelector<Group | null>(selectSharedGroupById(groupId));
  const group = isSharedGroup ? sharedGroup : normalGroup;

  if (!group) {
    return null;
  }

  return (
    <Trans i18nKey="CONTACTS.QUESTION_REMOVE_CONTACT">
      Are you sure you want to remove
      <strong>{{ name: group.name }}</strong>from your
      {{ listName: t("CONTACTS.GROUPS").toLowerCase() }}?
    </Trans>
  );
}

export default SingleGroupMessage;
