import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Typography, Divider } from "@alohi/kit";

function ContactsImportFooter({ numOfSelectedRows, totalNumOfRows, totalNumOfImported }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body">{t("CONTACTS.COUNT_LOAD_AVAILABLE")}</Typography>
        <Typography variant="body">
          {t("CONTACTS.NUM_IMPORTED", {
            selected: totalNumOfImported,
            total: totalNumOfRows,
          })}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box display="flex" justifyContent="space-between">
        <Typography variant="body">{t("CONTACTS.COUNT_SELECTED_IMPORT")}</Typography>
        <Typography variant="body">{numOfSelectedRows}</Typography>
      </Box>
    </Box>
  );
}

ContactsImportFooter.propTypes = {
  totalNumOfRows: PropTypes.number.isRequired,
  numOfSelectedRows: PropTypes.number.isRequired,
  totalNumOfImported: PropTypes.number.isRequired,
};

const useStyles = makeStyles(({ alohi, spacing }) => ({
  root: {
    flex: 1,
    display: "flex",
    borderRadius: "8px",
    padding: spacing(3),
    flexDirection: "column",
    backgroundColor: alohi.neutral100,
    margin: spacing(3, 3, 0, 3),
    border: `1px solid ${alohi.neutral200}`,
  },
  divider: {
    margin: spacing(1.5, 0),
  },
}));

export default ContactsImportFooter;
