import {
  useRef,
  useState,
  Dispatch,
  useEffect,
  useCallback,
  SetStateAction,
  MutableRefObject,
} from "react";

function useInput<T = string>(
  initialValue: T,
  validator?: (value: T) => boolean,
  isInputTouched = false,
) {
  const ref = useRef<T>(initialValue);
  const [value, setValue] = useState<T>(initialValue);

  const [isValid, setIsValid] = useState(validator?.(initialValue) ?? true);
  const [isBlurred, setIsBlurred] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isTouched, setIsTouched] = useState(isInputTouched);

  const [showsError, setShowsError] = useState(false);

  useEffect(() => {
    setIsDirty((current) => current || value !== initialValue);
    const isValid = validator?.(value) ?? true;
    setIsValid(isValid);
    setShowsError(isTouched && !isValid);
    ref.current = value;
  }, [initialValue, isTouched, validator, value]);

  const onBlur = useCallback(() => {
    setIsTouched(true);
    setIsBlurred(true);
    setIsFocused(false);
  }, []);

  const onFocus = useCallback(() => {
    setIsBlurred(false);
    setIsFocused(true);
  }, []);

  const onChange = useCallback(({ target }) => {
    ref.current = target.value;
    setValue(target.value);
  }, []);

  const touch = useCallback(() => setIsTouched(true), []);

  const unTouch = useCallback(() => setIsTouched(false), []);

  const hasValue =
    value !== undefined &&
    value !== null &&
    (typeof value === "string" ? Boolean(value.trim && value.trim() !== "") : true);

  return [
    value,
    {
      ref,
      setValue,

      setIsValid,
      isValid, // Validator function returns true

      isBlurred, // False by default
      isFocused, // False by default
      isDirty, // Content has been changed
      isTouched, // Field has been visited

      showsError, // Touched and invalid, or manually overwritten
      setShowsError, // Manually overwrite error (for example if API error)

      onBlur,
      onFocus,
      onChange,

      touch,
      unTouch,

      hasValue,
    },
  ] as const;
}

export type UseInput<T = string> = [
  T,
  {
    ref: MutableRefObject<T>;
    setValue: Dispatch<SetStateAction<T>>;

    setIsValid: Dispatch<SetStateAction<boolean>>;
    isValid: boolean;

    isBlurred: boolean;
    isFocused: boolean;
    isDirty: boolean;
    isTouched: boolean;

    showsError: boolean;
    setShowsError: Dispatch<SetStateAction<boolean>>;

    onBlur: () => void;
    onFocus: () => void;
    onChange: ({ target }: { target: { value: T } }) => void;

    unTouch: () => void;
    touch: () => void;
  },
];

export { useInput };
