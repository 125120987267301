import { default as parsePhoneNumber } from "libphonenumber-js";
import createSelector from "selectorator";
import { documentUserTypes } from "../enums/bundle";
import { currencySymbols } from "../enums/currency";
import { planDurations } from "../enums/plans";
import RS from "../enums/requestStatus";
import { orderByLabel, orderByName } from "../helpers/common";
import dayjs from "../helpers/date";

import i18n from "../i18n/index";
import { selectApprovedDocuments, selectPendingDocuments } from "../selectors/address.selector";
import { selectCurrentCreditCurrency } from "../selectors/credit.selector";
import {
  selectDefaultCountry,
  selectSelectedNumberObject,
} from "../selectors/verifications.selector";
import { sentryCaptureMessage } from "helpers/sentry";

export const selectIsCurrentPlanStatus = createSelector(
  ["plans.currentPlan.status"],
  (status) => status,
);

export const selectIsCurrentPlanResponse = createSelector(
  ["plans.currentPlan.response"],
  (response) => response,
);

export const selectIsCurrentPlanLoading = createSelector(
  ["plans.currentPlan.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsCurrentPlanFulfilled = createSelector(
  ["plans.currentPlan.status", "plans.currentPlan.response"],
  (status, response) => (status === RS.IDLE && Boolean(response)) || status === RS.ERROR,
);

export const selectCurrentPlan = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan;
  }
});

export const selectIsCurrentPlanRunning = createSelector(
  ["plans.currentPlan.status"],
  (status) => status === RS.RUNNING,
);

export const selectPlanTotalPages = createSelector([selectCurrentPlan], (plan) => {
  if (!plan) {
    return false;
  }
  return plan.total_pages;
});

export const selectIsCurrentPlanBasic = createSelector([selectCurrentPlan], (plan) => {
  if (!plan) {
    return false;
  }
  return plan.plan_type === "basic";
});

export const selectIsCurrentPlanPremium = createSelector([selectCurrentPlan], (plan) => {
  if (!plan) {
    return false;
  }
  return plan.plan_type === "premium";
});

export const selectIsCurrentPlanBusiness = createSelector([selectCurrentPlan], (plan) => {
  if (!plan) {
    return false;
  }
  return plan.plan_type === "business";
});

export const selectCurrentPlanNumber = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    const parsedNumber = parsePhoneNumber(firstPlan.numbers[0]);
    return {
      number: firstPlan.numbers[0],
      iso: parsedNumber?.country ?? "",
    };
  }
});

export const selectPlanId = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.id;
  }
});

export const selectPlanType = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.plan_type;
  }
});

export const selectIsPlanFree = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.plan_type === "free";
  }
});

export const selectIsPlanIndividual = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.plan_target === "individual";
  }
});

export const selectIsPlanCorporate = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.plan_target === "corporate";
  }
});

export const selectIsPlanEnterprise = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.plan_type === "enterprise";
  }
});

export const selectIsPlanCancelled = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.is_canceled;
  }
});

export const selectIsPlanActive = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.status === "active";
  }
});

export const selectIsPlanInactive = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.status === "inactive";
  }
});

export const selectPlanIsWaitingApproval = createSelector(
  ["plans.currentPlan.response"],
  (plan) => {
    if (plan && plan.length) {
      const [firstPlan] = plan;
      return firstPlan.status === "waiting_approval";
    }
  },
);

export const selectPlanPageOptionValue = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan?.options?.page?.value;
  }
});

export const selectPlanHasNumbers = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return !!firstPlan.numbers.length;
  }
});

export const selectPlanExpirationDate = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.expiration_date;
  }
});

export const selectPlanPageResetDate = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.page_reset_date;
  }
});

export const selectPlanPurchaseId = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.purchase_id;
  }
});

export const selectPlanPurchasePrice = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.purchase_price;
  }
});

export const selectIsAvailablePlansLoading = createSelector(
  ["plans.availablePlans.status"],
  (status) => status === RS.RUNNING,
);

export const selectAvailablePlans = createSelector(
  ["plans.availablePlans.response"],
  (response) => {
    if (response) {
      const map = new Map();
      const transformedResponse = [];
      for (let i = 0; i < response.length; i++) {
        const planType = response[i].plan_type;
        const planDuration = response[i].duration_unit;
        if (!map.get(planType)) {
          map.set(planType, []);
        }
        const option = {
          ...response[i],
          value: response[i].id,
          label:
            planDuration === planDurations.month
              ? i18n.t("CHANGE_PLAN.MONTH")
              : `${i18n.t("CHANGE_PLAN.YEAR")} (${i18n.t("PAGE_OPTIONS.SAVE")} ${
                  currencySymbols[response[i].currency] +
                  response[i].marketing_price_annual_difference
                })`,
        };
        map.get(planType).push(option);
      }
      for (const [key, value] of map) {
        transformedResponse.push({
          label: key,
          options: value,
        });
      }
      return transformedResponse;
    }
  },
);

export const selectNextPlan = createSelector(
  ["plans.availablePlans.selected"],
  (nextPlan) => nextPlan,
);

export const selectIsNextPlanBasic = createSelector([selectNextPlan], (plan) => {
  if (!plan) {
    return false;
  }
  return plan.plan_type === "basic";
});

export const selectAvailableCountriesResponse = createSelector(
  ["plans.availableCountries.response", selectCurrentCreditCurrency],
  (response, currency) => {
    return response?.[currency];
  },
);

export const selectShowPremiumChip = createSelector(
  [selectCurrentPlan, selectNextPlan, selectIsPlanIndividual],
  (currentPlan, nextPlan, isPlanIndividual) => {
    if (
      (currentPlan &&
        (currentPlan.plan_type === "business" || currentPlan.plan_type === "enterprise")) ||
      (isPlanIndividual && nextPlan?.plan_target === "corporate")
    ) {
      return false;
    }
    return true;
  },
);

export const selectCountryForPlan = createSelector(
  ["plans.availableCountries.selected"],
  (selectedCountry) => selectedCountry,
);

export const selectIsAllowedToChooseAreaCode = createSelector(
  ["plans.availableCountries.selected"],
  (selectedCountry) => selectedCountry?.iso !== "FR",
);

export const selectCountryRequiresVerification = createSelector(
  [selectCountryForPlan],
  (country) => {
    return country?.is_default_provider ?? false;
  },
);

export const selectIsIndividualBundleAvailableForCountry = createSelector(
  [selectCountryForPlan],
  (selectedCountry) => {
    return (
      selectedCountry?.requirements
        .find(({ type }) => type === "address_verification_v2")
        ?.end_user_type?.includes(documentUserTypes.individual.toLowerCase()) ?? false
    );
  },
);

export const selectIsBusinessBundleAvailableForCountry = createSelector(
  [selectCountryForPlan],
  (selectedCountry) => {
    return (
      selectedCountry?.requirements
        .find(({ type }) => type === "address_verification_v2")
        ?.end_user_type?.includes(documentUserTypes.business.toLowerCase()) ?? false
    );
  },
);

export const selectAvailableCountries = createSelector(
  [selectAvailableCountriesResponse, selectDefaultCountry, selectShowPremiumChip],
  (response, defaultCountrySelector, isPremiumChipVisible) => {
    if (response?.countries) {
      const { geoLocation, defaultCountry } = defaultCountrySelector;
      const { countries } = response;
      const newCountries = countries.slice();
      newCountries.sort(orderByName);

      let defaultCountryMatch = null;
      let geoLocationMatch = null;
      let usCountryMatch = null;

      let countryList = newCountries.reduce((accumulator, currentValue) => {
        const newObject = { ...currentValue };
        let isPlanRestriction = false;
        let isAddressVerification = false;
        if (currentValue.requirements.find((element) => element.type === "plan_restriction")) {
          isPlanRestriction = true;
        }
        if (
          currentValue.is_default_provider &&
          currentValue.requirements.find(
            ({ type, value }) => type === "address_verification_v2" && value === "bundle",
          )
        ) {
          isAddressVerification = true;
        }
        Object.assign(newObject, {
          value: currentValue.iso,
          label: i18n.t(`COUNTRY.ISO_${currentValue.iso}`),
          isAddressVerification,
          isPremiumChipVisible: isPremiumChipVisible && isPlanRestriction,
        });
        if (currentValue.iso === defaultCountry) {
          defaultCountryMatch = newObject;
          // Don't push if true, it will be unshifted at line 235 in front of vector
          return accumulator;
        }
        accumulator.push(newObject);
        return accumulator;
      }, []);

      if (defaultCountryMatch) {
        countryList.unshift(defaultCountryMatch);
        return countryList;
      }

      // Couldn't find the country by using the defaultCountry selector logic (based on priority)
      // Search by geoLocation
      countryList = newCountries.reduce((accumulator, currentValue) => {
        const newObject = { ...currentValue };
        let isPlanRestriction = false;
        let isAddressVerification = false;
        if (currentValue.requirements.find((element) => element.type === "plan_restriction")) {
          isPlanRestriction = true;
        }
        if (
          currentValue.is_default_provider &&
          currentValue.requirements.find(
            ({ type, value }) => type === "address_verification_v2" && value === "bundle",
          )
        ) {
          isAddressVerification = true;
        }
        Object.assign(newObject, {
          value: currentValue.iso,
          label: currentValue.name,
          isAddressVerification,
          isPremiumChipVisible: isPremiumChipVisible && isPlanRestriction,
        });
        if (currentValue.iso === geoLocation) {
          geoLocationMatch = newObject;
          // Don't push if true, it will be unshifted at line 257 in front of vector
          return accumulator;
        }
        accumulator.push(newObject);
        return accumulator;
      }, []);

      if (geoLocationMatch) {
        countryList.unshift(geoLocationMatch);
        return countryList;
      }

      // Couldn't find the country by using the geoLocation selector logic (based on priority)
      // Search by default country which is "US"
      countryList = newCountries.reduce((accumulator, currentValue) => {
        const newObject = { ...currentValue };
        let isPlanRestriction = false;
        let isAddressVerification = false;
        if (currentValue.requirements.find((element) => element.type === "plan_restriction")) {
          isPlanRestriction = true;
        }
        if (
          currentValue.is_default_provider &&
          currentValue.requirements.find(
            ({ type, value }) => type === "address_verification_v2" && value === "bundle",
          )
        ) {
          isAddressVerification = true;
        }
        Object.assign(newObject, {
          value: currentValue.iso,
          label: currentValue.name,
          isAddressVerification,
          isPremiumChipVisible: isPremiumChipVisible && isPlanRestriction,
        });
        if (currentValue.iso === "US") {
          usCountryMatch = newObject;
          // Don't push if true, it will be unshifted at line 279 in front of vector
          return accumulator;
        }
        accumulator.push(newObject);
        return accumulator;
      }, []);

      if (usCountryMatch) {
        countryList.unshift(usCountryMatch);
        return countryList;
      }

      // Should never reach this.
      // US will always be present in the list, otherwise something is really off
      return [];
    }
  },
);

export const selectCurrencyBasePrices = createSelector(
  [selectAvailableCountriesResponse],
  (response) => response?.base_prices?.currency ?? "",
);

export const selectBasePricesForNumber = createSelector(
  [selectAvailableCountriesResponse],
  (response) => response?.base_prices?.number ?? {},
);

export const selectBasePricesForChangeNumber = createSelector(
  [selectAvailableCountriesResponse],
  (response) => response?.base_prices?.change_number ?? {},
);

export const selectPricesForChangeNumber = createSelector([selectCountryForPlan], (country) => {
  if (!country?.change_number_types) {
    return null;
  }
  return Object.values(country.change_number_types).reduce((acc, curr) => {
    acc[curr.type] = curr.min_price;
    return acc;
  }, {});
});

export const selectAvailableAreas = createSelector(
  ["plans.availableAreas.response", "plans.availableCountries.selected", selectShowPremiumChip],
  (response, country, isPremiumChipVisible) => {
    if (response?.areas) {
      const areas = response.areas;
      const map = new Map();
      const transformedResponse = [];

      for (let i = 0; i < areas.length; i++) {
        const [firstLetter] = areas[i].city;
        if (!map.get(firstLetter)) {
          map.set(firstLetter, []);
        }
        const option = {
          ...areas[i],
          label: areas[i].city,
          value: areas[i].calling_code, // Used for list
          areaCode: areas[i].calling_code, // Used to send to backend
          countryCode: country?.country_code,
        };
        map.get(firstLetter).push(option);
      }

      for (const [key, value] of map) {
        transformedResponse.push({
          label: key,
          options: value,
        });
      }

      transformedResponse.sort(orderByLabel);
      const firstOptions = {
        label: "",
        options: [],
      };
      const countryIncludesTollFree =
        country?.available_types && country.available_types.includes("tollfree");

      firstOptions.options.push({
        value: "random-area-code",
        areaCode: "",
        isTollFree: false,
        city: "Random Area Code",
        label: i18n.t("ADD_NUMBER.ANY_CITY"),
      });

      if (countryIncludesTollFree) {
        firstOptions.options.push({
          value: "toll-free",
          areaCode: "",
          isTollFree: true,
          city: "Toll-Free",
          label: i18n.t("ADD_NUMBER.TOLL_FREE"),
          isPremiumChipVisible,
        });
      }
      if (areas.length) {
        firstOptions.options.push({
          value: "available-area-codes",
          areaCode: "",
          isTollFree: false,
          isDisabled: true,
          city: "Available Area Codes",
          label: i18n.t("ADD_NUMBER.AVAILABLE_AREA_CODES"),
        });
      }

      transformedResponse.unshift(firstOptions);
      return transformedResponse;
    }
  },
);

export const selectAreaForCountry = createSelector(
  ["plans.availableAreas.selected"],
  (area) => area,
);

export const selectIsRechargePlanSuccessful = createSelector(
  ["plans.rechargePlan.response"],
  (response) => {
    if (Boolean(response) && !response.next_action) return true;
    return null;
  },
);

// 3DS
export const selectRechargePlanRequiresAuthentication = createSelector(
  ["plans.rechargePlan.response"],
  (response) => {
    if (response?.next_action?.action === "requires_authentication") {
      return response.next_action;
    }
    return null;
  },
);

export const selectIsRechargePlanRunning = createSelector(
  ["plans.rechargePlan.status"],
  (status) => status === RS.RUNNING,
);

export const selectRechargePlanError = createSelector(["plans.rechargePlan.error"], (error) => {
  if (!error) {
    return null;
  }

  // In case we have a message from Stripe, display that
  if (error?.isStripeError && error.message) {
    return error.message;
  }

  switch (error?.reason) {
    case "credit_card_charge_failed":
      return i18n.t("PAYMENT.PAYMENT_DECLINED");
    case "3ds_recharge_error":
      return i18n.t("PAYMENT.3DS_FAILED");
    // From here down we have the same errors as deposit/confirm deposit
    case "GENERIC_PAYMENT_DECLINED":
      return i18n.t("ERRORS.GENERIC_PAYMENT_DECLINED");
    case "CARD_PAYMENT_DECLINED":
      return i18n.t("ERRORS.CARD_PAYMENT_DECLINED");
    case "ERROR_PAYPAL":
    case "PAYPAL_PAYMENT_DECLINED":
      return i18n.t("ERRORS.PAYPAL_PAYMENT_DECLINED");
    case "INVALID_PAYMENT_METHOD":
      return i18n.t("ERRORS.INVALID_PAYMENT_METHOD");
    case "INCORRECT_CARD_CVC_CODE":
      return i18n.t("ERRORS.INCORRECT_CARD_CVC_CODE");
    case "INCORRECT_CARD_NUMBER":
      return i18n.t("ERRORS.INCORRECT_CARD_NUMBER");
    case "PAYMENT_METHOD_EXPIRED":
      return i18n.t("ERRORS.PAYMENT_METHOD_EXPIRED");
    case "PAYMENT_METHOD_EXCEEDED_LIMIT":
      return i18n.t("ERRORS.PAYMENT_METHOD_EXCEEDED_LIMIT");
    case "PAYMENT_FRAUD_PREVENTION":
      return i18n.t("ERRORS.PAYMENT_FRAUD_PREVENTION");
    case "PAYMENT_3DS_ERROR":
      return i18n.t("ERRORS.PAYMENT_3DS_ERROR");
    case "PAYMENT_TECHNICAL_ERROR":
      return i18n.t("ERRORS.PAYMENT_TECHNICAL_ERROR");
    case "CARD_NOT_SUPPORTED":
      return i18n.t("ERRORS.CARD_NOT_SUPPORTED");
    case "ignored":
    case "invalid_cart":
    case "card_declined":
    case "Internal Error.":
    case "insufficient_credit":
    case "internal_server_error":
    case "credit_card_requires_action":
    case "purchase_error:general-('Connection aborted.', RemoteDisconnected('Remote end closed connection without response'))":
    default:
      sentryCaptureMessage({
        message: `Something went wrong during recharge`,
        level: "error",
        breadcumbs: {
          category: "fetch",
          level: "warning",
          message: JSON.stringify(error),
        },
      });
      return i18n.t("COMMON.PAYMENT_ERROR");
  }
});

export const selectIsCancelPlanSuccessful = createSelector(
  ["plans.cancelPlan.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsCancelPlanRunning = createSelector(
  ["plans.cancelPlan.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsCancelPlanError = createSelector(
  ["plans.cancelPlan.status"],
  (status) => status === RS.ERROR,
);

export const selectIsResumePlanSuccessful = createSelector(
  ["plans.resumePlan.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return !!response;
  },
);

export const selectIsResumePlanRunning = createSelector(
  ["plans.resumePlan.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsResumePlanError = createSelector(
  ["plans.resumePlan.status"],
  (status) => status === RS.ERROR,
);

export const selectInactivePlanDescription = createSelector(
  [selectCurrentPlan, selectIsPlanCorporate, selectIsPlanCancelled],
  (plan, isPlanCorporate, isPlanCancelled) => {
    if (!plan) {
      return "";
    }
    if (!plan.deletion_date) {
      return "";
    }
    const diff = dayjs(plan?.deletion_date).diff(dayjs(dayjs.utc()), "days");
    const dayLabel = diff === 0 ? "today" : diff > 1 ? `in ${diff} days` : `in ${diff} day`;
    const numberLabel = isPlanCorporate ? "numbers are" : "number is";
    // @TODO i18n
    if (isPlanCancelled) {
      return `Your plan was cancelled and has now expired. Your ${numberLabel} inactive and will be revoked ${dayLabel}. Please renew your plan.`;
    }
    return `Your plan has expired. Your ${numberLabel} inactive and will be revoked ${dayLabel}. Please renew your plan.`;
  },
);

/* @TODO To refactor the selectors */

export const getPlanCurrency = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    return firstPlan.purchase_id.split("-")[3];
  }
});

export const selectPlanDuration = createSelector(["plans.currentPlan.response"], (plan) => {
  if (plan && plan.length) {
    const [firstPlan] = plan;
    const isMonthly = firstPlan.purchase_id.search("month");
    return isMonthly !== -1 ? planDurations.month : planDurations.annual;
  }
});

export const selectPlanPeriod = createSelector([selectPlanDuration], (duration) => {
  if (duration === "month") {
    return "MONTHLY";
  }
  if (duration === "annual") {
    return "ANNUAL";
  }
});

export const selectBasePriceForCorporateNumber = createSelector(
  [selectCurrencyBasePrices, selectBasePricesForNumber],
  (currency, amounts) => {
    return currency && amounts?.corporate
      ? i18n.t("ADD_NUMBER.ADD_CORPORATE_NUMBER_TITLE_AMOUNT", {
          amount: `${currencySymbols[currency.toUpperCase()]}${amounts.corporate}`,
          unit: i18n.t("COMMON.MONTH"),
        })
      : i18n.t("ADD_NUMBER.ADD_CORPORATE_NUMBER_TITLE");
  },
);

export const selectIsAddressRequired = createSelector(
  [selectCountryRequiresVerification, selectCountryForPlan, selectSelectedNumberObject],
  // numberSource means that a country, area, and a numberType was selected
  (requiresVerification, selectedCountry, numberSource) => {
    if (requiresVerification && selectedCountry && numberSource) {
      return Boolean(
        selectedCountry?.requirements.find(
          ({ type, value }) => type === "address_verification_v2" && value === "address",
        ),
      );
    }
    return false;
  },
);

export const selectIsBundleRequired = createSelector(
  [selectCountryRequiresVerification, selectCountryForPlan, selectSelectedNumberObject],
  (requiresVerification, selectedCountry, numberSource) => {
    // numberSource means that a country, area, and a numberType was selected
    if (requiresVerification && selectedCountry && numberSource) {
      return Boolean(
        selectedCountry?.requirements.find(
          ({ type, value }) => type === "address_verification_v2" && value === "bundle",
        ),
      );
    }
    return false;
  },
);

export const selectIsBundleRequiredToBeCreated = createSelector(
  [
    selectIsBundleRequired,
    selectSelectedNumberObject,
    selectPendingDocuments,
    selectApprovedDocuments,
  ],
  (isBundleRequired, numberSource, pendingDocuments, approvedDocuments) => {
    if (
      isBundleRequired &&
      numberSource && // numberSource means that a country, area, and a numberType was selected
      pendingDocuments?.length === 0 &&
      approvedDocuments?.length === 0
    ) {
      return true;
    }
    return false;
  },
);

export const selectIsChangePlanButtonDisplayed = createSelector(
  [selectIsPlanCancelled, selectIsPlanActive, selectPlanIsWaitingApproval],
  (isPlanCancelled, isPlanActive, isWaitingApproval) => {
    return !(isPlanCancelled && isPlanActive) && !isWaitingApproval;
  },
);

export const selectIsAllowedToBuyBulkNumbers = createSelector(
  [selectAreaForCountry, selectCountryForPlan],
  (area, country) => {
    if (!area || !country) {
      return false;
    }

    return country?.iso === "CA" || country?.iso === "US" || country?.iso === "JP";
  },
);

export const selectListPageOptions = createSelector(
  ["plans.listPageOptions.response"],
  (response) => {
    if (!response) {
      return null;
    }

    return response;
  },
);

export const selectIsListPageOptionsRunning = createSelector(
  ["plans.listPageOptions.status"],
  (status) => status === RS.RUNNING,
);

export const selectPageOptionsId = createSelector(["plans.pageOptionsId.response"], (response) => {
  if (!response) {
    return null;
  }

  return response;
});

export const selectIsPageOptionsIdRunning = createSelector(
  ["plans.pageOptionsId.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsPlanCanBeUpgraded = createSelector(
  [selectIsPlanEnterprise, selectPlanPageOptionValue, selectPlanDuration],
  (isPlanEnterprise, isPlanOption, planDuration) => {
    return (
      isPlanEnterprise &&
      ((isPlanOption && planDuration === planDurations.month) ||
        planDuration === planDurations.annual)
    );
  },
);
