import PropTypes from "prop-types";
import { useMemo, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { useSnackbar, Modal, Box, Typography, FaButton, TextField } from "@alohi/kit";

import gift from "assets/images/gift.svg";
import useClipboard from "hooks/useClipboard";
import FormRow from "components/Forms/FormRow";
import Currency from "components/Core/Currency/Currency";
import { selectCurrentCreditCurrency } from "selectors/credit.selector";
import { getReferralLink } from "stores/reducers/verifications.reducer";
import {
  selectReferralLink,
  selectReferralInviteCode,
  selectReferralInviteCurrency,
  selectReferralInviteRefereeAmount,
  selectReferralInviteReferrerAmount,
} from "selectors/verifications.selector";

function FreeCreditModal({ handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { copy, isCopied } = useClipboard();

  const referralLinkData = useSelector(selectReferralLink);
  const inviteCode = useSelector(selectReferralInviteCode);
  const inviteCurrency = useSelector(selectReferralInviteCurrency);
  const refereeAmount = useSelector(selectReferralInviteRefereeAmount);
  const referrerAmount = useSelector(selectReferralInviteReferrerAmount);
  const currentCreditCurrency = useSelector(selectCurrentCreditCurrency);

  useEffect(() => {
    if (currentCreditCurrency) {
      dispatch(getReferralLink(currentCreditCurrency));
    }
  }, [dispatch, currentCreditCurrency]);

  const linkToBeCopied = useMemo(
    () => (inviteCode ? `${window.location.origin}/invite/${inviteCode}` : ""),
    [inviteCode],
  );

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar(t("REFERRAL.COPIED_TO_CLIPBOARD"), { variant: "success" });
    }
  }, [enqueueSnackbar, isCopied, t]);

  if (!referralLinkData) {
    return null;
  }

  return (
    <Modal
      title={t("REFERRAL.FREE_CREDIT")}
      confirmTitle={t("COMMON.DONE")}
      onConfirm={() => handleClosure(true)}
    >
      <Box display="flex" flexDirection="column" alignItems="center" p={3}>
        <Typography variant="body" align="center">
          <Trans
            i18nKey="REFERRAL.GIVE_YOUR_FRIENDS_FREE_CREDIT_DETAILS"
            components={[
              <Currency
                key={0}
                value={referrerAmount}
                currency={inviteCurrency}
                style={{ display: "contents" }}
              />,
              <Currency
                key={1}
                value={refereeAmount}
                currency={inviteCurrency}
                style={{ display: "contents" }}
              />,
            ]}
          />
        </Typography>
        <Box my={4}>
          <img src={gift} alt="gift" width="100" />
        </Box>
        <FormRow label={t("REFERRAL.YOUR_INVITE_LINK")} id="invite-link" labelGridWidth={3}>
          <TextField
            fullWidth
            isDisabled
            id="invite-link-input"
            value={linkToBeCopied}
            InputProps={{
              endAdornment: (
                <FaButton
                  tooltip={t("COMMON.COPY")}
                  icon={faCopy}
                  onClick={() => copy(linkToBeCopied)}
                />
              ),
            }}
            aria-describedby="invite-link-helper-text"
          />
        </FormRow>
      </Box>
    </Modal>
  );
}

FreeCreditModal.propTypes = {
  handleClosure: PropTypes.func,
};

export default FreeCreditModal;
