import { Button } from "@alohi/kit";
import { useSelector } from "react-redux";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import useBool from "hooks/useBool";
import { alohiAdminUrls } from "routes/urls";
import ChangePlan from "views/ChangePlan/ChangePlan";
import useAdminRedirections from "hooks/useAdminRedirections";
import useCleanPlansReducers from "hooks/useCleanPlansReducers";
import { selectIsPlanCanBeUpgraded } from "selectors/plan.selector";
import UpgradeEnterpriseModal from "views/UpgradeEnterprise/UpgradeEnterpriseModal";

function ChangePlanButton() {
  const { t } = useTranslation();

  const cleanReducers = useCleanPlansReducers();
  const { handleRedirection: redirectToAdmin } = useAdminRedirections();

  const [isChangePlanModalOpen, isChangePlanModalOpenBool] = useBool(false);
  const [isUpgradeEnterpriseModalOpen, isUpgradeEnterpriseModalOpenBool] = useBool(false);

  const isUpgradeButton = useSelector(selectIsPlanCanBeUpgraded);

  const handleOpenChangePlan = useCallback(() => {
    if (isUpgradeButton) {
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.highVolume,
        fallbackAction: isUpgradeEnterpriseModalOpenBool.setTrue,
      });
    } else {
      redirectToAdmin({
        adminUrl: alohiAdminUrls.fax.upgrade,
        fallbackAction: isChangePlanModalOpenBool.setTrue,
      });
    }
  }, [
    redirectToAdmin,
    isUpgradeButton,
    isChangePlanModalOpenBool,
    isUpgradeEnterpriseModalOpenBool,
  ]);

  const handleClosure = useCallback(() => {
    cleanReducers();
    isChangePlanModalOpenBool.setFalse();
  }, [cleanReducers, isChangePlanModalOpenBool]);

  return (
    <>
      <Button
        variant="white"
        onClick={handleOpenChangePlan}
        isLoading={isUpgradeEnterpriseModalOpen}
      >
        {t("COMMON.UPGRADE")}
      </Button>

      {isChangePlanModalOpen ? <ChangePlan handleClosure={handleClosure} /> : null}

      {isUpgradeEnterpriseModalOpen ? (
        <UpgradeEnterpriseModal handleClosure={isUpgradeEnterpriseModalOpenBool.setFalse} />
      ) : null}
    </>
  );
}

export default memo(ChangePlanButton);
