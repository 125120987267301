import PropTypes from "prop-types";
import { Modal, Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import RenewPlanModal from "views/RenewPlan/RenewPlanModal";
import { selectInactivePlanDescription } from "selectors/plan.selector";

function InactivePlanModal({ handleClosure }) {
  const { t } = useTranslation();

  const planDescription = useSelector(selectInactivePlanDescription);
  const [openRenewPlanModal, setOpenRenewPlanModal] = useState(false);

  const handleRenewPlan = useCallback(
    (isSuccessful) => {
      if (isSuccessful) {
        handleClosure(true);
      } else {
        handleClosure(false);
      }

      setOpenRenewPlanModal(false);
    },
    [handleClosure],
  );

  return (
    <Modal
      maxWidth="xs"
      onCancel={handleClosure}
      cancelTitle={t("COMMON.CLOSE")}
      title={t("CHANGE_PLAN.PLAN_INACTIVE")}
      confirmTitle={t("CHANGE_PLAN.RENEW_PLAN")}
      onConfirm={() => setOpenRenewPlanModal(true)}
    >
      <Box p={3}>{planDescription}</Box>

      {openRenewPlanModal ? <RenewPlanModal handleClosure={handleRenewPlan} /> : null}
    </Modal>
  );
}

InactivePlanModal.propTypes = {
  handleClosure: PropTypes.func.isRequired,
};

export default InactivePlanModal;
