import { Box, alohi } from "@alohi/kit";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "ui";
import { getI18nCountry } from "helpers/numbers";
import CountryFlag from "components/Core/CountryFlag/CountryFlag";
import { CreateAddressBundleContext } from "views/CreateAddressBundle/context/createAddressBundle.context";

interface RegulatoryRequirementsProps {
  withCountrySelector?: boolean;
}

function RegulatoryRequirements({ withCountrySelector }: RegulatoryRequirementsProps) {
  const { t } = useTranslation();
  const [{ country }] = useContext(CreateAddressBundleContext);

  return (
    <Box mb={2}>
      {withCountrySelector ? null : (
        <Box display={"flex"} justifyContent={"center"} mb={2}>
          <CountryFlag
            style={{
              width: "fit-content",
              height: "50px",
              boxShadow: alohi.bannerShadow,
            }}
            code={country}
          />
        </Box>
      )}
      <Box mb={2} sx={{ textAlign: "center" }}>
        <Typography variant="h5">{t("ADDRESS.BUNDLE_LOCAL_REGULATORY")}</Typography>
      </Box>
      <Typography variant="body">
        {t("ADDRESS.BUNDLE_CONDITION", {
          country: getI18nCountry(country)?.label ?? "",
        })}
      </Typography>
    </Box>
  );
}

export default memo(RegulatoryRequirements);
