import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { currentCreditApi, plansApi } from "api";
import RS from "enums/requestStatus";
import { selectPlanId } from "selectors/plan.selector";
import { logout } from "./authentication.reducer";

const initialRequestState = {
  status: RS.IDLE,
  response: null,
  error: null,
};

export const clearUpdateCurrency = createAction("CREDIT/CLEAR_UPDATE_CURRENCY");

export const getCurrentCredit = createAsyncThunk(
  "CREDIT/GET_CURRENT_CREDIT",
  async (_, { rejectWithValue }) => {
    try {
      const response = await currentCreditApi.getCurrentCredit();
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const getCreditDetails = createAsyncThunk(
  "CREDIT/GET_CREDIT_DETAILS",
  async (_, { rejectWithValue, getState }) => {
    try {
      const planId = selectPlanId(getState());
      if (planId) {
        const response = await plansApi.getCreditDetails(planId);
        return response;
      }
      return rejectWithValue("plan id not found");
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

export const updateCurrency = createAsyncThunk(
  "CREDIT/UPDATE_CREDIT_CURRENCY",
  async (currency, { rejectWithValue }) => {
    try {
      const response = await currentCreditApi.updateCreditCurrency(currency);
      return response;
    } catch (error) {
      return rejectWithValue(error.origin);
    }
  },
);

const initialState = {
  currentCredit: initialRequestState,
  creditDetails: initialRequestState,
  updateCurrency: initialRequestState,
};

const { reducer } = createSlice({
  name: "CREDIT",
  initialState,
  reducers: {},
  extraReducers: {
    [getCurrentCredit.pending]: (state) => {
      state.currentCredit.status = RS.RUNNING;
      state.currentCredit.error = null;
    },
    [getCurrentCredit.fulfilled]: (state, action) => {
      state.currentCredit.response = action.payload;
      state.currentCredit.status = RS.IDLE;
      state.currentCredit.error = null;
    },
    [getCurrentCredit.rejected]: (state, action) => {
      state.currentCredit.response = null;
      state.currentCredit.status = RS.ERROR;
      state.currentCredit.error = action.payload;
    },
    [getCreditDetails.pending]: (state) => {
      state.creditDetails.status = RS.RUNNING;
      state.creditDetails.error = null;
    },
    [getCreditDetails.fulfilled]: (state, action) => {
      state.creditDetails.response = action.payload;
      state.creditDetails.status = RS.IDLE;
      state.creditDetails.error = null;
    },
    [getCreditDetails.rejected]: (state, action) => {
      state.creditDetails.response = null;
      state.creditDetails.status = RS.ERROR;
      state.creditDetails.error = action.payload;
    },
    [clearUpdateCurrency]: (state) => {
      state.updateCurrency.response = null;
      state.updateCurrency.status = RS.IDLE;
      state.updateCurrency.error = null;
    },
    [updateCurrency.pending]: (state) => {
      state.updateCurrency.response = null;
      state.updateCurrency.status = RS.RUNNING;
      state.updateCurrency.error = null;
    },
    [updateCurrency.fulfilled]: (state, action) => {
      state.updateCurrency.response = action.payload;
      state.updateCurrency.status = RS.IDLE;
      state.updateCurrency.error = null;
    },
    [updateCurrency.rejected]: (state, action) => {
      state.updateCurrency.response = null;
      state.updateCurrency.status = RS.ERROR;
      state.updateCurrency.error = action.payload;
    },
    [logout.fulfilled]: () => initialState,
  },
});

export default reducer;
