import { memo, useState } from "react";
import { alohi, Box, makeStyles, Typography } from "@alohi/kit";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { durationMap, capitalizeString } from "helpers/string";
import {
  selectPlanType,
  selectIsPlanFree,
  selectPlanDuration,
  selectPlanPageOptionValue,
} from "selectors/plan.selector";
import { useTranslation } from "react-i18next";
import InactivePlanModal from "views/InactivePlan/InactivePlanModal";
import dataCy from "enums/dataCy";

interface PreviewPlanTypeProps {
  className?: string;
  isPlanInactive: boolean;
}

function PreviewPlanType({ className, isPlanInactive }: PreviewPlanTypeProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const planType = useSelector(selectPlanType);
  const isPlanFree = useSelector(selectIsPlanFree);
  const planDuration = useSelector(selectPlanDuration);
  const pageOptionValue = useSelector(selectPlanPageOptionValue);

  const [openInactivePlan, setOpenInactivePlan] = useState(false);

  const duration = `${durationMap(planDuration, false)}`.replace(
    "(",
    pageOptionValue ? `(${pageOptionValue / 1000}k - ` : "(",
  );

  const label = isPlanFree
    ? `${capitalizeString(planType)}`
    : `${capitalizeString(planType)} ${duration}`;

  return (
    <Box className={clsx(classes.base, className)} data-cy={dataCy.planDetailsPanelPlanType}>
      <Typography isBold>{t("PROFILE.SUBSCRIPTION_TYPE")}</Typography>
      <Typography className={classes.label}>{label}</Typography>
      {isPlanInactive && (
        <Typography className={classes.inactive} onClick={() => setOpenInactivePlan(true)}>
          {t("PROFILE.INACTIVE")}
        </Typography>
      )}
      {openInactivePlan && <InactivePlanModal handleClosure={() => setOpenInactivePlan(false)} />}
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    [breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  row: {
    display: "flex",
    alignItems: "flex-end",
  },
  label: {
    marginTop: spacing(0.5),
    [breakpoints.down("md")]: {
      marginTop: 0,
      marginLeft: spacing(0.5),
    },
  },
  inactiveSeparator: {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
  },
  inactive: {
    color: alohi.blue400,
    cursor: "pointer",
  },
}));

export default memo(PreviewPlanType);
