import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import {
  MenuItem,
  Panel,
  Button,
  TextField,
  Typography,
  Box,
  TextButton,
  useSnackbar,
} from "@alohi/kit";

import { Helper } from "ui";
import dataCy from "enums/dataCy";
import { useInput } from "hooks/useInput";
import { urlImage } from "helpers/common";
import CoreAvatar from "components/Core/Avatar/Avatar";
import { PhoneNumberFilter } from "components/Core/Filters";
import { helpCenterUrls as websiteUrls } from "routes/urls";
import { isValidCompanyName } from "helpers/inputValidation";
import { useAppDispatch, useAppSelector } from "stores/store";
import { getAllNumbers } from "stores/reducers/numbers.reducer";
import { getCustomerInfo } from "stores/reducers/account.reducer";
import defaultCompanyAvatar from "assets/images/avatar-company.png";
import UploadProfileImageModal from "views/Image/UploadProfileImageModal";
import { actions, selectors, thunks } from "stores/reducers/coverSheet.reducer";
import { selectCompanyLogo, selectCompanyName } from "selectors/account.selector";
import { selectAllActiveNumbers, selectDefaultNumber } from "selectors/numbers.selector";
import BackPanelRow from "views/Profile/components/CoverSheetEnforcement/components/BackPanelRow";
import FrontPanelRow from "views/Profile/components/CoverSheetEnforcement/components/FrontPanelRow";
import {
  updateCompanyInfo,
  clearUpdateCompany,
  updateDefaultCompanyNumber,
} from "stores/reducers/corporate.reducer";
import {
  selectIsUpdateCompanyRunning,
  selectIsUpdateCompanyInfoError,
  selectIsUpdateCompanyInfoSuccessful,
  selectIsUpdateCompanyDefaultNumberError,
  selectIsUpdateCompanyDefaultNumberSuccessful,
} from "selectors/corporate.selector";

function CompanyInfoPanel() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const activeNumbers = useSelector(selectAllActiveNumbers);

  const initialCompanyLogo = useAppSelector(selectCompanyLogo);
  const initialCompanyName = useAppSelector(selectCompanyName);
  const enforcedCoverSheet = useAppSelector(selectors.enforced);
  const initialDefaultNumber = useAppSelector(selectDefaultNumber);

  const enforceType = useAppSelector(selectors.enforceType);
  const isRunning = useSelector(selectIsUpdateCompanyRunning);
  const isUpdateInfoError = useSelector(selectIsUpdateCompanyInfoError);
  const isUpdateInfoSuccessful = useSelector(selectIsUpdateCompanyInfoSuccessful);
  const isUpdateDefaultNumberError = useSelector(selectIsUpdateCompanyDefaultNumberError);
  const isUpdateDefaultNumberSuccessful = useSelector(selectIsUpdateCompanyDefaultNumberSuccessful);

  const [defaultNumber, defaultNumberInput] = useInput(initialDefaultNumber);
  const [companyName, companyNameInput] = useInput(initialCompanyName, isValidCompanyName);

  const [image, setImage] = useState<string | null>("");
  const [imageURL, setImageURL] = useState<string | null>(initialCompanyLogo);

  const [editMode, setEditMode] = useState(false);
  const [openProfilePhoto, setOpenProfilePhoto] = useState(false);
  const [newEnforcedCoverSheet, setNewEnforcedCoverSheet] = useState<null | {
    id: string;
    name: string;
  }>(null);

  const onCancel = useCallback(() => {
    setImageURL("");
    setImage(urlImage(initialCompanyLogo));
    defaultNumberInput.setValue(initialDefaultNumber);
    companyNameInput.setValue(initialCompanyName);

    setNewEnforcedCoverSheet(
      enforcedCoverSheet ? { id: enforcedCoverSheet.id, name: enforcedCoverSheet.name } : null,
    );
    setEditMode(false);
  }, [
    companyNameInput,
    enforcedCoverSheet,
    initialCompanyLogo,
    initialCompanyName,
    defaultNumberInput,
    initialDefaultNumber,
  ]);

  const handleRemoveImage = useCallback(() => {
    setImage(null);
    setImageURL(null);
  }, []);

  const handleSave = () => {
    companyNameInput.touch();

    if (newEnforcedCoverSheet === null) {
      // We had an enforced cover sheet before, and the new one has been removed
      if (enforcedCoverSheet) {
        dispatch(
          thunks.enforce({
            enforceType: "disable",
            id: enforcedCoverSheet.id,
          }),
        );
      }
    } else if (newEnforcedCoverSheet) {
      if (enforcedCoverSheet?.id !== newEnforcedCoverSheet.id) {
        dispatch(
          thunks.enforce({
            enforceType: "enforce",
            id: newEnforcedCoverSheet.id,
          }),
        );
      }
    }

    if (companyName === "") {
      enqueueSnackbar(t("FORMS.COMPANY_NAME_REQUIRED"), { variant: "error" });
      companyNameInput.setShowsError(true);
      return;
    }

    if (imageURL !== initialCompanyLogo || companyName !== initialCompanyName) {
      dispatch(
        updateCompanyInfo({
          companyLogo: imageURL,
          companyName: companyName,
        }),
      );
    } else if (defaultNumber !== initialDefaultNumber) {
      dispatch(updateDefaultCompanyNumber(defaultNumber));
    } else {
      setEditMode(false);
    }
  };

  const handleImageUpload = useCallback(({ url, base64 }) => {
    setImage(base64);
    setImageURL(url);
    setOpenProfilePhoto(false);
  }, []);

  useEffect(() => {
    setImage(urlImage(initialCompanyLogo));
  }, [initialCompanyLogo]);

  useEffect(() => {
    dispatch(thunks.enforced());
  }, [dispatch]);

  useEffect(() => {
    if (enforcedCoverSheet) {
      setNewEnforcedCoverSheet({
        id: enforcedCoverSheet.id,
        name: enforcedCoverSheet.name,
      });
    }
  }, [enforcedCoverSheet]);

  useEffect(() => {
    if (enforceType === "disable") {
      enqueueSnackbar("Cover Sheet enforcement was disabled", {
        variant: "success",
      });

      dispatch(actions.clearEnforce());
    }

    if (enforceType === "enforce") {
      enqueueSnackbar("Cover Sheet was enforced successfully", {
        variant: "success",
      });

      dispatch(actions.clearEnforce());
    }
  }, [dispatch, enforceType, enqueueSnackbar]);

  useEffect(() => {
    if (isUpdateInfoSuccessful) {
      dispatch(getCustomerInfo());
      dispatch(clearUpdateCompany());
      setEditMode(false);
    } else if (isUpdateDefaultNumberSuccessful) {
      dispatch(getAllNumbers());
      dispatch(clearUpdateCompany());
      setEditMode(false);
    } else if (isUpdateDefaultNumberError || isUpdateInfoError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      dispatch(clearUpdateCompany());
      setEditMode(false);
    }
  }, [
    t,
    dispatch,
    enqueueSnackbar,
    isUpdateInfoError,
    isUpdateInfoSuccessful,
    isUpdateDefaultNumberError,
    isUpdateDefaultNumberSuccessful,
  ]);

  return (
    <Panel
      sx={{ mb: 7 }}
      id="company-info"
      isFlipped={editMode}
      data-cy={dataCy.companyInfoPanel}
      headerTitle={t("PROFILE.COMPANY_INFO")}
    >
      <Panel.Front
        headerAction={
          <TextButton onClick={() => setEditMode(true)}>
            <Typography type="panelFrontHeader">{t("COMMON.EDIT")}</Typography>
            <Box sx={{ ml: 1, color: "white" }}>
              <FontAwesomeIcon icon={faPenToSquare} color="white" />
            </Box>
          </TextButton>
        }
      >
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.COMPANY_NAME")}</Panel.Cell>
          <Panel.Cell variant="data">{companyName}</Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.COMPANY_LOGO")}</Panel.Cell>
          <Panel.Cell variant="data">
            <CoreAvatar src={image ?? defaultCompanyAvatar} alt={companyName} />
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Typography type="panelCellTitle">{t("PROFILE.DEFAULT_NUMBER_COMPANY")}</Typography>
              </Box>
              <a href={websiteUrls.defaultCallerId} target="_blank" rel="noreferrer">
                <Helper size="lg" variant="dark" />
              </a>
            </Box>
          </Panel.Cell>
          <Panel.Cell variant="data">
            {defaultNumber ? <PhoneNumberFilter number={defaultNumber} /> : null}
          </Panel.Cell>
        </Panel.Row>

        <FrontPanelRow enforcedCoverSheet={newEnforcedCoverSheet} />
      </Panel.Front>

      <Panel.Back
        onConfirm={handleSave}
        onCancel={onCancel}
        isConfirmLoading={isRunning}
        isPanelDisabled={isRunning}
        isConfirmDisabled={companyNameInput.showsError}
      >
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.COMPANY_NAME")}</Panel.Cell>
          <Panel.Cell variant="data">
            <TextField
              id="company-name-input"
              value={companyName}
              sx={{ minWidth: 200 }}
              onBlur={companyNameInput.onBlur}
              error={!companyNameInput.isValid}
              onFocus={companyNameInput.onFocus}
              onChange={companyNameInput.onChange}
            />
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.COMPANY_LOGO")}</Panel.Cell>
          <Panel.Cell variant="data">
            <CoreAvatar src={image ?? defaultCompanyAvatar} alt={companyName} />
          </Panel.Cell>
          <Panel.Cell variant="actions">
            <Button variant="white" onClick={() => setOpenProfilePhoto(true)} sx={{ mr: 2 }}>
              {t("PROFILE.UPLOAD")}
            </Button>
            <Button variant="white" onClick={handleRemoveImage}>
              {t("PROFILE.REMOVE")}
            </Button>
          </Panel.Cell>
        </Panel.Row>
        <Panel.Row>
          <Panel.Cell variant="title">{t("PROFILE.DEFAULT_NUMBER_COMPANY")}</Panel.Cell>
          <Panel.Cell variant="data">
            {activeNumbers.length > 0 ? (
              <TextField
                select
                label=""
                value={defaultNumber}
                sx={{ minWidth: 200 }}
                onChange={defaultNumberInput.onChange}
              >
                {activeNumbers.map((option: { number: string }) => (
                  <MenuItem key={option.number} value={option.number}>
                    <PhoneNumberFilter number={option.number} />
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </Panel.Cell>
        </Panel.Row>

        <BackPanelRow
          enforcedCoverSheet={newEnforcedCoverSheet}
          setNewEnforcedCoverSheet={setNewEnforcedCoverSheet}
        />

        {openProfilePhoto ? (
          <UploadProfileImageModal initialImage={image} handleClosure={handleImageUpload} />
        ) : null}
      </Panel.Back>
    </Panel>
  );
}

export default CompanyInfoPanel;
