import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Switch, Panel, Box, FormControlLabel, Checkbox, makeStyles } from "@alohi/kit";
import { Dispatch, SetStateAction } from "react";

import dataCy from "enums/dataCy";
import useBool from "hooks/useBool";
import { Helper, Typography } from "ui";
import { helpCenterUrls } from "routes/urls";
import { selectIsCurrentPlanBasic, selectIsCurrentPlanPremium } from "selectors/plan.selector";
import RequireUpgradeToPremiumOrHigher from "views/RequireUpgrade/RequireUpgradeToPremiumOrHigher";

interface FaxReceptionBackRowProps {
  faxReception: boolean;
  notifyWhenDisabled: boolean;
  setFaxReception: Dispatch<SetStateAction<boolean>>;
  setNotifyWhenDisabled: Dispatch<SetStateAction<boolean>>;
}

function FaxReceptionBackRow({
  faxReception,
  setFaxReception,
  notifyWhenDisabled,
  setNotifyWhenDisabled,
}: FaxReceptionBackRowProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isCurrentPlanBasic = useSelector(selectIsCurrentPlanBasic);
  const isCurrentPlanPremium = useSelector(selectIsCurrentPlanPremium);

  const [isRequireUpgradeModalOpen, isRequireUpgradeModalOpenBool] = useBool(false);

  if (isCurrentPlanPremium || isCurrentPlanBasic) {
    return (
      <>
        <Panel.Row>
          <Panel.Cell variant="title">
            <Typography variant="panelCellTitle">{t("PROFILE.FAX_RECEPTION")}</Typography>
            <Box
              ml={1}
              component="a"
              target="_blank"
              rel="noreferrer"
              href={helpCenterUrls.faxReception}
            >
              <Helper size="lg" variant="dark" />
            </Box>
          </Panel.Cell>
          <Panel.Cell variant="data">
            <Switch
              checked={faxReception}
              data-cy={dataCy.faxReceptionSwitchToggle}
              onChange={() => {
                if (isCurrentPlanPremium) {
                  setFaxReception((prev) => !prev);
                } else {
                  isRequireUpgradeModalOpenBool.setTrue();
                }
              }}
            />

            {faxReception ? t("COMMON.ACTIVE") : t("COMMON.INACTIVE")}

            {!faxReception ? (
              <>
                <FormControlLabel
                  className={classes.faxReceptionCheckbox}
                  control={
                    <Checkbox
                      value={notifyWhenDisabled}
                      checked={notifyWhenDisabled}
                      onChange={() => setNotifyWhenDisabled((previous) => !previous)}
                    />
                  }
                  label={
                    <Box className={classes.receiveAlerts}>
                      <Typography component="span">
                        {t("NUMBERS.DISABLE_FAX_RECEPTION_ALERTS")}
                      </Typography>
                      <Helper tooltip={t("NUMBERS.DISABLE_FAX_RECEPTION_ALERTS_TOOLTIP")} />
                    </Box>
                  }
                />
              </>
            ) : null}
          </Panel.Cell>
        </Panel.Row>

        {isRequireUpgradeModalOpen ? (
          <RequireUpgradeToPremiumOrHigher
            title={t("PROFILE.FAX_RECEPTION")}
            featureName={t("NUMBERS.SWITCHING_OFF_FAX_RECEPTION")}
            handleClosure={isRequireUpgradeModalOpenBool.setFalse}
          />
        ) : null}
      </>
    );
  }

  return null;
}

const useStyles = makeStyles(({ spacing }) => ({
  faxReceptionCheckbox: {
    marginLeft: "auto",
  },
  receiveAlerts: {
    display: "flex",
    gap: spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default FaxReceptionBackRow;
