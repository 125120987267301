import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { memo, useEffect, useCallback, useMemo } from "react";
import { Box } from "@alohi/kit";

import { boxNames } from "../../enums/faxes";
import {
  selectBoxHasMore,
  selectBoxRecords,
  selectSelectedNumber,
  selectSelectedMemberUid,
  selectAreFiltersApplied,
  selectIsFetchBoxRunning,
  selectSelectedNumberOfFetched,
  selectSelectedMemberUidOfFetched,
} from "../../selectors/faxes.selector";
import { faxesAsyncActions } from "../../stores/reducers/faxes.reducer";
import FaxesColumnFrom from "../Columns/FaxesColumnFromTo";
import FaxesColumnIsFailedOrUnread from "../Columns/FaxesColumnIsFailedOrUnread";
import FaxesColumnDate from "../Columns/FaxesColumnDate";
import FaxesColumnPage from "../Columns/FaxesColumnPage";
import FaxesColumnNote from "../Columns/FaxesColumnNote";
import InfiniTable from "../InfiniTable/InfiniTable";
import EmptyFaxes from "../InfiniTable/EmptyFaxes";
import FaxCheckbox from "./FaxCheckbox";
import InboxActions from "./InboxActions";
import { sentryCaptureMessage } from "helpers/sentry";

function Inbox() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inboxRecords = useSelector(selectBoxRecords(boxNames.inbox));
  const hasMore = useSelector(selectBoxHasMore(boxNames.inbox));
  const prevSelectedNumber = useSelector(selectSelectedNumberOfFetched(boxNames.inbox));
  const prevSelectedMemberUid = useSelector(selectSelectedMemberUidOfFetched(boxNames.inbox));
  const selectedNumber = useSelector(selectSelectedNumber);
  const selectedMemberUid = useSelector(selectSelectedMemberUid);
  const isFiltering = useSelector(selectAreFiltersApplied(boxNames.inbox));
  const isFetchBoxRunning = useSelector(selectIsFetchBoxRunning);

  const fetchInbox = useCallback(
    (fetchOptions = {}) => {
      dispatch(faxesAsyncActions.fetchBox({ boxName: boxNames.inbox, fetchOptions }));
    },
    [dispatch],
  );

  // Initial Fetching
  useEffect(() => {
    !inboxRecords &&
      !isFetchBoxRunning &&
      (selectedNumber || selectedMemberUid) &&
      fetchInbox({ number: selectedNumber, memberUid: selectedMemberUid });
  }, [fetchInbox, inboxRecords, selectedNumber, selectedMemberUid, isFetchBoxRunning]);

  // Fetch again if every item has been deleted and there's more
  useEffect(() => {
    !!inboxRecords &&
      inboxRecords.length === 0 &&
      hasMore &&
      (selectedNumber || selectedMemberUid) &&
      dispatch(
        faxesAsyncActions.fetchBoxMore({
          boxName: boxNames.inbox,
        }),
      );
  }, [inboxRecords, selectedNumber, selectedMemberUid, dispatch, hasMore]);

  // Refresh if number/member has changed
  useEffect(() => {
    inboxRecords &&
      (selectedNumber !== prevSelectedNumber || selectedMemberUid !== prevSelectedMemberUid) &&
      fetchInbox({
        number: selectedNumber,
        memberUid: selectedMemberUid,
        offset: 0,
        before: null,
      });
  }, [
    inboxRecords,
    selectedNumber,
    selectedMemberUid,
    fetchInbox,
    prevSelectedNumber,
    prevSelectedMemberUid,
  ]);

  // Fetch thumbnails
  useEffect(() => {
    inboxRecords &&
      dispatch(
        faxesAsyncActions.fetchThumbnails({
          boxName: boxNames.inbox,
          fetchOptions: { uid: selectedMemberUid },
        }),
      );
  }, [inboxRecords, dispatch, selectedMemberUid]);

  const inboxComponents = useMemo(
    () => ({
      Checkbox: function thumbnailCheckboxRender({
        "data-index": dataIndex,
        "data-description": dataDescription,
        ...rest
      }) {
        const isCdrRow = dataDescription === "row-select";

        if (isCdrRow) {
          return (
            <Box sx={{ position: "relative" }}>
              <FaxesColumnIsFailedOrUnread boxName={boxNames.inbox} dataIndex={dataIndex ?? 0} />
              <FaxCheckbox
                boxName={boxNames.inbox}
                {...rest}
                dataIndex={dataIndex}
                dataDescription={dataDescription}
              />
            </Box>
          );
        }

        return (
          <FaxCheckbox
            boxName={boxNames.inbox}
            {...rest}
            dataIndex={dataIndex}
            dataDescription={dataDescription}
          />
        );
      },
    }),
    [],
  );

  const inboxColumns = useMemo(
    () => [
      {
        name: "from",
        label: t("FAXES.FROM"),
        options: {
          filter: false,
          customBodyRenderLite: function columnFromRender(dataIndex) {
            return <FaxesColumnFrom boxName={boxNames.inbox} dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "pages",
        label: t("COMMON.PAGES"),
        options: {
          filter: false,
          customBodyRenderLite: function columnPageRender(dataIndex) {
            return <FaxesColumnPage boxName={boxNames.inbox} dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "date",
        label: t("COMMON.DATE"),
        options: {
          customBodyRenderLite: function columnDateRender(dataIndex) {
            return <FaxesColumnDate boxName={boxNames.inbox} dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "comment",
        label: t("COMMON.NOTE"),
        options: {
          filter: false,
          customBodyRenderLite: function columnNoteRender(dataIndex) {
            return <FaxesColumnNote boxName={boxNames.inbox} dataIndex={dataIndex} />;
          },
        },
      },
      {
        name: "faxStatus",
        label: t("FAXES.FAX_STATUS"),
        options: {
          display: false,
        },
      },
      {
        name: "actions",
        label: " ",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => {
            return {
              style: {
                display: "table-cell",
                width: 50,
              },
            };
          },
          customBodyRenderLite: function columnActionsRender(dataIndex) {
            return <InboxActions dataIndex={dataIndex} />;
          },
        },
      },
    ],
    [t],
  );

  const inboxOptions = useMemo(
    () => ({
      onTableChange: (action, tableState) => {
        if (["sort"].includes(action)) {
          fetchInbox({
            sortBy: tableState.sortOrder.name,
            ascending: !(tableState.sortOrder.direction === "asc"),
            offset: 0,
            before: null,
          });
        }
      },
      responsive: "simple",
      textLabels: {
        body: {
          noMatch: (
            <EmptyFaxes text={isFiltering ? t("COMMON.NO_RESULT") : t("FAXES.NO_FAXES_HERE_YET")} />
          ),
        },
      },
    }),
    [fetchInbox, t, isFiltering],
  );

  return (
    <>
      <InfiniTable
        rowHeight={76}
        columns={inboxColumns}
        data={inboxRecords}
        options={inboxOptions}
        components={inboxComponents}
        onWaypoint={() => {
          dispatch(
            faxesAsyncActions.fetchBoxMore({
              boxName: boxNames.inbox,
            }),
          );
        }}
        hasWaypoint={hasMore}
        isLoading={!inboxRecords || hasMore}
      />
    </>
  );
}

export default memo(Inbox);
