import { alohi, BorderAnimation, Box, makeStyles, Typography } from "@alohi/kit";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { useMemo } from "react";
import { borderAnimationSettings } from "components/Incentive/helpers/animations";
import Perks from "./Perks";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";

interface ContentPaidTrialInProgressCorpMemberProps {
  className?: string;
}

function ContentPaidTrialInProgressCorpMember({
  className,
}: ContentPaidTrialInProgressCorpMemberProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { trialInProgressDaysLeft } = useTrialIncentive();

  const animation = useMemo(() => borderAnimationSettings, []);

  const adminLabel = "";

  return (
    <Box className={clsx(classes.base, className)}>
      <Box className={classes.content}>
        <Typography className={classes.title} type="flow-h1">
          <Trans
            i18nKey="INCENTIVE_SIGN.YOU_HAVE_X_DAYS_LEFT_ON_TRIAL"
            components={{
              strong: <strong />,
            }}
            values={{
              count: trialInProgressDaysLeft,
              value_1: trialInProgressDaysLeft,
            }}
          />
        </Typography>
        <Typography className={classes.description} type="flow-body">
          {t("INCENTIVE_SIGN.MAKE_THE_MOST_OF_YOUR_TRIAL")}
        </Typography>
        <Perks className={classes.perks} />
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.animatedContainer}>
          <BorderAnimation
            isHovering={false}
            isActive={false}
            borderRadius={8}
            idleAnimation={animation}
            hoverAnimation={animation}
          />
          <Box className={classes.buttonsContainerTextContainer}>
            <Typography type="flow-body">
              {t("INCENTIVE_SIGN.AS_FAX_USER_CLAIM_DISCOUNT_BEFORE_END_OF_TRIAL", {
                value_1: 50,
              })}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.contactAdminContainer}>
          <Typography type="flow-body">
            {t("INCENTIVE_SIGN.ASK_YOUR_ADMIN", {
              value_1: adminLabel,
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  base: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "scroll",
    [breakpoints.down("sm")]: {
      padding: spacing("90px", 2, 2, 2),
    },
  },
  title: {
    fontWeight: "normal",
  },
  description: {
    marginTop: spacing(2),
  },
  perks: {
    marginTop: spacing(4),
  },
  footer: {
    height: "fit-content",
    [breakpoints.down("sm")]: {
      padding: spacing(2),
      borderWidth: 0,
      borderTopWidth: 1,
      borderStyle: "solid",
      borderColor: alohi.neutral200,
    },
  },
  buttonsContainer: {
    marginTop: spacing(4),
    display: "flex",
    alignItems: "center",
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  animatedContainer: {
    marginTop: spacing(4),
    padding: spacing(2),
    borderRadius: "8px",
    position: "relative",
    [breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  buttonsContainerTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  contactAdminContainer: {
    marginTop: spacing(2),
    padding: spacing(2),
    background: alohi.orange150,
    borderColor: alohi.orange500,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
  },
}));

export default ContentPaidTrialInProgressCorpMember;
