import { Reducer, Dispatch, ReactNode, useContext, useReducer, createContext } from "react";

import { SendFaxAction, sendFaxReducer } from "views/SendFax/contexts/reducer";
import { initialSendFaxStore, SendFaxStore } from "views/SendFax/contexts/store";

export type SendFaxContextProps = {
  sendFaxStore: SendFaxStore;
  sendFaxDispatch: Dispatch<SendFaxAction>;
};

export const SendFaxContext = createContext<SendFaxContextProps>({
  sendFaxStore: initialSendFaxStore,
  sendFaxDispatch: () => undefined,
});

export function useSendFaxContext() {
  const context = useContext(SendFaxContext);

  if (context === undefined) {
    throw new Error("useSendFaxContext must be within SendFaxContextProvider!");
  }

  return context;
}

export function SendFaxContextProvider({ children }: { children: ReactNode }) {
  const [sendFaxStore, sendFaxDispatch] = useReducer<Reducer<SendFaxStore, SendFaxAction>>(
    sendFaxReducer,
    initialSendFaxStore,
  );

  return (
    <SendFaxContext.Provider value={{ sendFaxStore, sendFaxDispatch }}>
      {children}
    </SendFaxContext.Provider>
  );
}
