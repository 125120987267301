import { TextField } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback } from "react";

import { useInput } from "hooks/useInput";
import { capitalizeString } from "helpers/string";

interface TextFieldMessageProps {
  className?: string;
  message: string;
  onMessage: (message: string) => void;
}

function TextFieldMessage({ className, message, onMessage }: TextFieldMessageProps) {
  const { t } = useTranslation();
  const [inputMessage, inputMessageHandler] = useInput(message);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      inputMessageHandler.onChange(event);
      onMessage(event.target.value);
    },
    [inputMessageHandler, onMessage],
  );

  return (
    <TextField
      multiline
      fullWidth
      type="text"
      minRows={5}
      value={inputMessage}
      className={className}
      id="contact-us-message"
      onChange={handleChange}
      onBlur={inputMessageHandler.onBlur}
      onFocus={inputMessageHandler.onFocus}
      error={inputMessageHandler.showsError}
      inputProps={{ maxLength: 2000 }}
      placeholder={capitalizeString(t("COMMON.MESSAGE"))}
    />
  );
}

export default TextFieldMessage;
