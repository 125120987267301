import createSelector from "selectorator";

import i18n from "i18n/index";
import dayjs from "helpers/date";
import RS from "enums/requestStatus";
import { accountTypes } from "enums/account";

export function transformDialogue(value) {
  let answer = "";
  switch (value) {
    case false:
      answer = "noad";
      break;
    case true:
      answer = "withad";
      break;
    default:
      answer = "withad";
      break;
  }
  return answer;
}

export const selectAccountInfo = createSelector(
  [
    "account.accountDetails.response.email",
    "account.accountDetails.response.phone",
    "account.accountDetails.response.name",
    "account.accountDetails.response.lastname",
    "account.accountDetails.response.profile_image",
  ],
  (email, phone, name, lastName, profileImage) => ({
    name,
    email,
    phone,
    lastName,
    profileImage,
  }),
);

export const selectAccountId = createSelector(
  ["account.accountDetails.response"],
  (account) => account?.id,
);

export const selectAccountCid = (state) => state.account.accountDetails.response?.cid;

export const selectAccountDuration = createSelector(
  ["account.accountDetails.response"],
  (account) => {
    try {
      return dayjs
        .duration(dayjs().diff(dayjs(account?.creation_date)))
        .format("Y [years,] M [months,] D [days]");
    } catch {
      return "";
    }
  },
);

export const selectAccountFulfilled = createSelector(
  ["account.accountDetails.status", "account.accountDetails.response"],
  (status, response) => (status === RS.IDLE && Boolean(response)) || status === RS.ERROR,
);

export const selectAccount = createSelector(
  ["account.accountDetails.response"],
  (account) => account,
);

export const selectAccountEmail = createSelector(
  ["account.accountDetails.response"],
  (account) => account?.email,
);

export const selectAccountUid = createSelector(
  ["account.accountDetails.response"],
  (account) => account?.id,
);

export const selectAccountPhone = createSelector(
  ["account.accountDetails.response"],
  (account) => account?.phone,
);

export const selectSaveHistory = (state) =>
  state.account.getCustomerInfo.response.customer_data?.save_history ?? null;

export const selectAccountDefaultFaxFileType = (state) =>
  state.account.accountDetails.response.user_data?.default_file_type ?? null;

export const selectCallerIdName = (state) =>
  state.account.accountDetails.response.settings?.caller_id_name ?? null;

export const selectIsCustomerInfoStatus = (state) => state.account.getCustomerInfo.status;

export const selectIsCustomerInfoFulfilled = (state) =>
  (state.account.getCustomerInfo.status === RS.IDLE &&
    Boolean(state.account.getCustomerInfo.response)) ||
  state.account.getCustomerInfo.status === RS.ERROR;

export const selectIsCustomerInfoRunning = (state) =>
  state.account.getCustomerInfo.status === RS.RUNNING;

export const selectCustomerInfo = (state) => state.account.getCustomerInfo.response ?? null;

export const selectCompanyName = createSelector(
  [selectCustomerInfo],
  (customerInfo) => customerInfo?.customer_data?.company_name ?? "",
);

export const selectAccountSettings = createSelector(
  ["account.accountDetails.response"],
  (account) => account?.settings,
);

export const selectAccountFaxSettings = createSelector(
  ["account.accountDetails.response"],
  (account) => account?.settings?.send_fax,
);

export const selectAccountFaxToHuman = createSelector([selectAccountFaxSettings], (settings) => ({
  hasFaxToHuman: !!settings?.options?.announcement,
  faxToHuman: {
    gender: settings?.options?.announcement?.data?.gender ?? "male",
    language: settings?.options?.announcement?.data?.language ?? "en",
    dialogue: transformDialogue(settings?.options?.announcement?.data?.from_fax_plus),
  },
}));

export const selectAccountRetryFax = createSelector([selectAccountFaxSettings], (settings) => {
  const hasFaxRetry = !!settings?.retry?.count;
  return {
    hasFaxRetry: hasFaxRetry,
    faxRetry: hasFaxRetry ? settings?.retry : { count: 1, delay: 1 },
  };
});

export const selectAccountShouldEnhance = createSelector(
  [selectAccountFaxSettings],
  (settings) => settings?.should_enhance,
);

export const selectAccountNotifyReceiver = createSelector(
  [selectAccountFaxSettings],
  (settings) => settings?.notify_receiver,
);

export const selectIsLoadingAccountDetails = createSelector(
  ["account.accountDetails.status"],
  (status) => status === RS.RUNNING,
);

export const selectAccountType = createSelector(["account.accountDetails.response"], (response) => {
  if (!response) {
    return null;
  }
  return response.account_type;
});

export const selectAccountIsCorporateMember = createSelector(
  [selectAccountType],
  (accountType) => accountType === accountTypes.corporateMember,
);

export const selectAccountIsBusinessCorporateMember = createSelector(
  [selectAccountType, "plans.currentPlan.response"],
  (accountType, plan) =>
    accountType === accountTypes.corporateMember && plan?.[0]?.plan_type === "business",
);

export const selectAccountIsCorporateAdmin = createSelector(
  [selectAccountType],
  (accountType) => accountType === accountTypes.corporateAdmin,
);

export const selectAccountIsCorporateOwner = createSelector(
  [selectAccountType],
  (accountType) => accountType === accountTypes.corporateOwner,
);

export const selectPhoneIsUnverified = createSelector(
  ["account.accountDetails.response"],
  (response) => {
    return response?.verification_status?.phone === "unverified";
  },
);

export const selectEmailIsUnverified = createSelector(
  ["account.accountDetails.response"],
  (response) => {
    const emailVerified = response?.verification_status?.email === "verified";
    const phoneVerified = response?.verification_status?.phone === "verified";
    const skipEmailVerification = response?.verification_status?.skip_email_verification;

    return !(emailVerified || (phoneVerified && skipEmailVerification));
  },
);

export const selectAccountIsInactive = createSelector(
  ["account.accountDetails.response"],
  (response) => response?.account_status === "inactive",
);

export const selectCompanyLogo = createSelector([selectCustomerInfo], (customerInfo) => {
  const companyLogo = customerInfo.customer_data?.company_logo ?? null;
  if (companyLogo) {
    // The key helps browser caching know when the image has changed and needs to be fetched again
    return `/storage/corporate_logo?key=${encodeURIComponent(
      customerInfo.customer_data.company_logo,
    )}`;
  } else {
    return null;
  }
});

export const selectIsUpdateAccountDetailsSuccessful = createSelector(
  ["account.updateAccountDetails.response"],
  (response) => {
    if (!response) {
      return false;
    }
    return !!response;
  },
);

export const selectIsUpdateAccountDetailsRunning = createSelector(
  ["account.updateAccountDetails.status"],
  (status) => status === RS.RUNNING,
);

export const selectUpdateAccountDetailsErrorMessage = createSelector(
  ["account.updateAccountDetails.error"],
  (error) => {
    if (!error) return null;

    switch (error.reason) {
      case "already_registered":
        return i18n.t("FORMS.SELECT_ANOTHER_EMAIL");
      case "cannot_change_email_address_with_sso":
        return i18n.t("SSO.CANNOT_CHANGE_EMAIL_WITH_SSO");
      case "corporate_member_cannot_change_email":
        return i18n.t("FORMS.CORPORATE_MEMBER_CANT_CHANGE_EMAIL");
      case "similar_email_already_used":
        return i18n.t("FORMS.SIMILAR_EMAIL_ALREADY_USED");
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsImportContactsSuccessful = createSelector(
  ["account.importContacts.response"],
  (response) => response,
);

export const selectIsImportContactsError = createSelector(
  ["account.importContacts.status"],
  (status) => status === RS.ERROR,
);

export const selectIsImportContactsRunning = createSelector(
  ["account.importContacts.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsSearchContactsRunning = createSelector(
  ["account.contacts.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsChangePasswordSuccessful = createSelector(
  ["account.changePassword.response"],
  (response) => response,
);

export const selectIsChangePasswordError = createSelector(
  ["account.changePassword.status"],
  (status) => status === RS.ERROR,
);

export const selectChangePasswordErrorMessage = createSelector(
  ["account.changePassword.error"],
  (error) => {
    if (!error) return null;

    if (error.reason === "cannot_change_password_with_sso") {
      return i18n.t("SSO.CANNOT_CHANGE_PASSWORD_WITH_SSO");
    } else if (error.reason === "unsecure_password") {
      return i18n.t("FORMS.PASSWORD_MUST_BE_MORE_SECURE");
    } else if (error.reason === "invalid_password") {
      return i18n.t("PROFILE.CHANGE_PASSWORD_INVALID");
    } else {
      return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);

export const selectIsChangePasswordRunning = createSelector(
  ["account.changePassword.status"],
  (status) => status === RS.RUNNING,
);

export const selectLastPasswordDate = createSelector(
  ["account.accountDetails.response"],
  (response) => response?.last_password_modification_date,
);

export const selectForgotUsernameSuccess = createSelector(
  ["account.forgotUsername.response"],
  (response) => {
    if (!response) {
      return null;
    }
    return response.sent === true;
  },
);

export const selectForgotUsernameError = createSelector(
  ["account.forgotUsername.response", "account.forgotUsername.error"],
  (response, error) => {
    if (!!error) {
      return true;
    }
    if (response?.sent === false) {
      return true;
    }
    return null;
  },
);

export const selectIsEnabledAdvancedSecurity = (state) =>
  state.account.getCustomerInfo.response?.settings?.security?.advanced_security?.enabled ?? null;

export const selectMemberOutboundQuota = (state) =>
  state.account.accountDetails.response.quota?.quota ?? 0;

export const selectMemberQuota = createSelector(["account.accountDetails.response"], (account) =>
  account.quota?.quota > account.quota?.used ? account.quota?.quota - account.quota?.used : 0,
);

export const selectIsBaaSigned = (state) =>
  state.account.getCustomerInfo.response?.settings?.security?.advanced_security?.baa_signed ?? null;

export const selectIsCorporateTwoFaActive = (state) =>
  state.account.getCustomerInfo.response?.settings?.security?.enforce_corporate_2fa;

export const selectIncomingRetentionDays = (state) =>
  state.account.getCustomerInfo.response?.settings?.security?.incoming_retention_days;

export const selectOutgoingRetentionDays = (state) =>
  state.account.getCustomerInfo.response?.settings?.security?.outgoing_retention_days;

export const selectIsAdvancedSecurityError = createSelector(
  ["account.advancedSecurity.status"],
  (status) => status === RS.ERROR,
);

export const selectIsAdvancedSecuritySuccess = createSelector(
  ["account.advancedSecurity.status", "account.advancedSecurity.response"],
  (status, response) => status !== RS.ERROR && Boolean(response),
);

export const selectIsAdvancedSecurityRunning = createSelector(
  ["account.advancedSecurity.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsDocumentRetentionError = createSelector(
  ["account.documentRetention.status"],
  (status) => status === RS.ERROR,
);

export const selectIsDocumentRetentionSuccess = createSelector(
  ["account.documentRetention.status", "account.documentRetention.response"],
  (status, response) => status !== RS.ERROR && Boolean(response),
);

export const selectIsDocumentRetentionRunning = createSelector(
  ["account.documentRetention.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsRequestBaaError = createSelector(
  ["account.requestBaa.status"],
  (status) => status === RS.ERROR,
);

export const selectIsRequestBaaSuccess = createSelector(
  ["account.requestBaa.status", "account.requestBaa.response"],
  (status, response) => status !== RS.ERROR && Boolean(response),
);

export const selectIsRequestBaaRunning = createSelector(
  ["account.requestBaa.status"],
  (status) => status === RS.RUNNING,
);

export const selectUserBackupDataId = createSelector(
  ["account.userSettings.response"],
  (settings) => {
    if (!settings) {
      return null;
    }
    return settings.backup_object_storage.cdr;
  },
);

export const selectUserLiveDataId = createSelector(
  ["account.userSettings.response"],
  (settings) => {
    if (!settings) {
      return null;
    }
    return settings.object_storage.cdr;
  },
);

export const selectIsUserAllowedToMigrate = createSelector(
  ["account.userSettings.response"],
  (settings) => {
    if (!settings) {
      return null;
    }
    return settings.migration_is_allowed;
  },
);

export const selectNextMigrationTime = createSelector(
  ["account.userSettings.response"],
  (settings) => {
    if (!settings) {
      return null;
    }
    return settings.next_migration_time;
  },
);

export const selectUserDataLocations = createSelector(
  ["account.locations.response"],
  (response) => {
    return response;
  },
);

export const selectLiveDataLocations = createSelector([selectUserDataLocations], (response) => {
  if (!response?.storage) {
    return null;
  }
  const locations = Object.keys(response.storage).sort((a, b) => b.localeCompare(a));

  return {
    ids: locations,
    nameMap: locations.reduce((acc, location, index) => {
      const newLocationObject = {
        ...response.storage[location],
        location: response.storage[location].description.split(",")[0],
      };
      acc[locations[index]] = newLocationObject;
      return acc;
    }, {}),
  };
});

export const selectBackupDataLocations = createSelector([selectUserDataLocations], (response) => {
  if (!response?.backup) {
    return null;
  }
  const locations = Object.keys(response.backup).sort((a, b) => b.localeCompare(a));

  return {
    ids: locations,
    nameMap: locations.reduce((acc, location, index) => {
      const newLocationObject = {
        ...response.backup[location],
        location: response.backup[location].description.split(",")[0],
      };
      acc[locations[index]] = newLocationObject;
      return acc;
    }, {}),
  };
});

export const selectUserUpdatedData = createSelector(
  ["account.updateDataLocation.response"],
  (response) => {
    if (!response) {
      return false;
    }
    return response;
  },
);

export const selectIsUserUpdateLocationError = createSelector(
  ["account.updateDataLocation.status"],
  (status) => status === RS.ERROR,
);

export const selectIsUserUpdateLocationRunning = createSelector(
  ["account.updateDataLocation.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsMigrationInProgress = createSelector(
  ["account.migrationStatus.status", "account.migrationStatus.response"],
  (status, response) => {
    if (status === RS.ERROR) {
      return false;
    }
    return !!response;
  },
);

export const selectIsAbilityToSendFaxViaEmailEnabled = createSelector(
  ["account.accountDetails.response"],
  (account) => {
    if (!account) {
      return null;
    }

    return account?.settings?.email_to_fax?.enabled;
  },
);

export const selectIsIgnoreEmailContentEnabled = createSelector(
  ["account.accountDetails.response"],
  (account) => {
    if (!account) {
      return null;
    }

    return account?.settings?.email_to_fax?.ignore_email_content;
  },
);

export const selectIsAwardedFreePages = createSelector(
  ["account.accountDetails.response"],
  (account) => {
    if (!account) {
      return null;
    }

    return account?.oneshot_actions?.awarded_free_pages;
  },
);

export const selectIsIncludeEmailContentEnabled = createSelector(
  [selectIsAbilityToSendFaxViaEmailEnabled, selectIsIgnoreEmailContentEnabled],
  (isAbilityToSendFaxViaEmailEnabled, isIgnoreEmailContentEnabled) => {
    if (!isAbilityToSendFaxViaEmailEnabled) return false;
    return !isIgnoreEmailContentEnabled;
  },
);

export const selectIsAcceptTosSuccess = (state) => !!state.account.acceptTos.response;

export const selectAcceptTosError = createSelector(["account.acceptTos.error"], (error) => {
  if (!error) return null;

  switch (error.reason) {
    default:
      return i18n.t("COMMON.SERVER_ERROR");
  }
});

export const selectIsTosAccepted = (state) =>
  !!state.account.accountDetails.response?.verification_status?.tos_approval_date;

export const selectAllowAnonymous = createSelector(
  [selectCustomerInfo],
  (customerInfo) => customerInfo?.settings?.fax_reception?.allow_anonymous ?? false,
);

export const selectIsAllowAnonymousRunning = createSelector(
  ["account.changeAnonymous.status"],
  (status) => status === RS.RUNNING,
);

export const selectIsAllowAnonymousSuccess = createSelector(
  ["account.changeAnonymous.status", "account.changeAnonymous.response"],
  (status, response) => status !== RS.ERROR && Boolean(response),
);

export const selectIsAllowAnonymousError = createSelector(
  ["account.changeAnonymous.error"],
  (error) => {
    if (!error) return null;

    switch (error.reason) {
      default:
        return i18n.t("COMMON.SERVER_ERROR");
    }
  },
);
