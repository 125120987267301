import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Box, useSnackbar } from "@alohi/kit";

import { urlImage } from "helpers/common";
import { FormControlLabel, Grid } from "ui";
import FormRow from "components/Forms/FormRow";
import useBool, { UseBoolType } from "hooks/useBool";
import CoreAvatar from "components/Core/Avatar/Avatar";
import { useAppDispatch, useAppSelector } from "stores/store";
import { getCustomerInfo } from "stores/reducers/account.reducer";
import defaultCompanyAvatar from "assets/images/avatar-company.png";
import UploadProfileImageModal from "views/Image/UploadProfileImageModal";
import { selectCanIUseCoverSheetBranding } from "selectors/ability.selector";
import { selectIsUpdateCompanyInfoSuccessful } from "selectors/corporate.selector";
import { clearUpdateCompany, updateCompanyInfo } from "stores/reducers/corporate.reducer";
import {
  selectCompanyLogo,
  selectCompanyName,
  selectAccountIsCorporateMember,
} from "selectors/account.selector";

interface CompanyLogoProps {
  isUsingCompanyLogo: UseBoolType[0];
  isUsingCompanyLogoBool: UseBoolType[1];
}

function CompanyLogo({ isUsingCompanyLogo, isUsingCompanyLogoBool }: CompanyLogoProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const companyLogo = useSelector(selectCompanyLogo);
  const companyName = useSelector(selectCompanyName);
  const isCorporateMember = useSelector(selectAccountIsCorporateMember);

  const isUpdateInfoSuccessful = useAppSelector(selectIsUpdateCompanyInfoSuccessful);
  const canIUseCoverSheetBranding = useAppSelector(selectCanIUseCoverSheetBranding);

  const isCompanyLogoPresent = Boolean(companyLogo);
  const [image, setImage] = useState<string | null>("");
  const [isUpdatedCompanyLogoOpen, isUpdatedCompanyLogoOpenBool] = useBool(false);

  const handleImageUpload = ({ url, base64 }: { url: string; base64: string }) => {
    if (url && base64) {
      setImage(base64);

      dispatch(
        updateCompanyInfo({
          companyName,
          companyLogo: url,
        }),
      );

      isUsingCompanyLogoBool.setTrue();
    }

    isUpdatedCompanyLogoOpenBool.setFalse();
  };

  const handleCheckForCompanyLogo = () => {
    if (isCorporateMember) {
      if (!isCompanyLogoPresent) {
        enqueueSnackbar(t("CONTACTS.CONTACT_YOUR_ADMIN"), { variant: "info" });
      } else {
        isUsingCompanyLogoBool.toggle();
      }
    } else if (isCompanyLogoPresent) {
      isUsingCompanyLogoBool.toggle();
    } else {
      isUpdatedCompanyLogoOpenBool.setTrue();
    }
  };

  const handleOpenProfilePicture = () => {
    if (isCorporateMember) {
      if (!isCompanyLogoPresent) {
        enqueueSnackbar(t("CONTACTS.CONTACT_YOUR_ADMIN"), { variant: "info" });
      } else {
        handleCheckForCompanyLogo();
      }
    } else if (isCompanyLogoPresent || image) {
      isUpdatedCompanyLogoOpenBool.setTrue();
    } else {
      handleCheckForCompanyLogo();
    }
  };

  useEffect(() => {
    setImage(urlImage(companyLogo));
  }, [companyLogo]);

  useEffect(() => {
    if (isUpdateInfoSuccessful) {
      dispatch(getCustomerInfo());
      dispatch(clearUpdateCompany());
    }
  }, [dispatch, isUpdateInfoSuccessful]);

  if (!canIUseCoverSheetBranding) {
    return null;
  }

  const source = image ?? defaultCompanyAvatar;

  return (
    <>
      <FormRow label={<Box onClick={handleCheckForCompanyLogo}>Use Company Logo</Box>}>
        <Grid container>
          <Grid item xs>
            <FormControlLabel
              control={
                <Checkbox
                  name="useCompanyLogo"
                  id="use-company-logo"
                  checked={isUsingCompanyLogo}
                  onChange={handleCheckForCompanyLogo}
                  inputProps={{ "aria-label": "use-company-logo" }}
                />
              }
              label={
                <CoreAvatar src={source} alt="company-logo" onClick={handleOpenProfilePicture} />
              }
            />
          </Grid>
        </Grid>
      </FormRow>

      {isUpdatedCompanyLogoOpen ? (
        <UploadProfileImageModal initialImage={image} handleClosure={handleImageUpload} />
      ) : null}
    </>
  );
}

export default CompanyLogo;
