import { useTranslation } from "react-i18next";

import { Typography } from "ui";
import { helpCenterUrls } from "routes/urls";

function ModalInfo() {
  const { t } = useTranslation();

  return (
    <Typography>
      {t("PAGE_OPTIONS.INFO")}{" "}
      <Typography
        variant="anchor"
        component="span"
        sx={{ "&&": { fontSize: "0.875rem" } }}
        onClick={() => window.open(helpCenterUrls.contactSales, "_blank")}
      >
        {t("PAGE_OPTIONS.CONTACT")}
      </Typography>
    </Typography>
  );
}

export default ModalInfo;
