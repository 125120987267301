import { Panel, Box } from "@alohi/kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Typography, Helper } from "ui";
import { helpCenterUrls } from "routes/urls";
import { selectIsCurrentPlanBasic, selectIsCurrentPlanPremium } from "selectors/plan.selector";

interface FaxReceptionFrontRowProps {
  faxReception: boolean;
}

function FaxReceptionFrontRow({ faxReception }: FaxReceptionFrontRowProps) {
  const { t } = useTranslation();

  const isCurrentPlanBasic = useSelector(selectIsCurrentPlanBasic);
  const isCurrentPlanPremium = useSelector(selectIsCurrentPlanPremium);

  if (isCurrentPlanPremium || isCurrentPlanBasic) {
    return (
      <Panel.Row>
        <Panel.Cell variant="title">
          <Typography variant="panelCellTitle">{t("PROFILE.FAX_RECEPTION")}</Typography>
          <Box
            ml={1}
            component="a"
            target="_blank"
            rel="noreferrer"
            href={helpCenterUrls.faxReception}
          >
            <Helper size="lg" variant="dark" />
          </Box>
        </Panel.Cell>
        <Panel.Cell variant="data">
          {faxReception ? t("COMMON.ACTIVE") : t("COMMON.INACTIVE")}
        </Panel.Cell>
      </Panel.Row>
    );
  }

  return null;
}

export default FaxReceptionFrontRow;
