import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faComment } from "@fortawesome/pro-solid-svg-icons";
import { useSnackbar, Box, Button, makeStyles, Modal, Typography } from "@alohi/kit";

import useBool from "hooks/useBool";
import { formatDefaultDate } from "helpers/date";
import ContactUsModal from "views/ContactUs/ContactUsModal";
import { capitalizeString, durationMap } from "helpers/string";
import { cancelCurrentPlan, clearCancelPlan } from "stores/reducers/plans.reducer";
import {
  selectPlanId,
  selectPlanType,
  selectPlanDuration,
  selectIsPlanCorporate,
  selectIsCancelPlanError,
  selectPlanExpirationDate,
  selectIsCancelPlanRunning,
  selectIsCancelPlanSuccessful,
} from "selectors/plan.selector";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

interface CancelPlanModalProps {
  handleClosure: () => void;
}

function CancelPlanModal({ handleClosure }: CancelPlanModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const planId = useSelector(selectPlanId);
  const planType = useSelector(selectPlanType);
  const planDuration = useSelector(selectPlanDuration);
  const planExpirationDate = useSelector(selectPlanExpirationDate);
  const isPlanCorporate = useSelector(selectIsPlanCorporate);

  const isError = useSelector(selectIsCancelPlanError);
  const isRunning = useSelector(selectIsCancelPlanRunning);
  const isSuccessful = useSelector(selectIsCancelPlanSuccessful);

  const { sendCustomEvent } = useGoogleTagManager();

  const [isContactUsModalOpen, isContactUsModalOpenHandlers] = useBool(false);

  const handleOnConfirm = useCallback(() => {
    dispatch(cancelCurrentPlan(planId));
  }, [dispatch, planId]);

  useEffect(() => {
    if (isSuccessful) {
      sendCustomEvent({
        event: GoogleAnalyticsCustomEvents.planCancellation,
      });
      handleClosure();
    }
    if (isError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });
      handleClosure();
    }
  }, [handleClosure, t, enqueueSnackbar, isSuccessful, isError, sendCustomEvent]);

  useEffect(() => {
    return () => {
      dispatch(clearCancelPlan());
    };
  }, [dispatch]);

  return (
    <>
      <Modal
        maxWidth="sm"
        onConfirm={handleClosure}
        isModalDisabled={isRunning}
        title={t("CHANGE_PLAN.CANCEL_PLAN")}
        confirmTitle={t("CHANGE_PLAN.KEEP_MY_SUBSCRIPTION")}
        customFooterInfo={
          <Box ml={1}>
            <Typography component="a" type="anchor" onClick={handleOnConfirm}>
              {t("CHANGE_PLAN.CONFIRM_CANCELLATION")}
            </Typography>
          </Box>
        }
      >
        <Box p={3}>
          <Typography type="body" className={classes.bold}>
            {t("CHANGE_PLAN.QUESTION_CANCEL_PLAN", {
              planType: capitalizeString(planType),
              duration: durationMap(planDuration, false),
            })}
          </Typography>
          <Typography type="body">
            {t("CHANGE_PLAN.DESCRIPTION_CANCEL_PLAN", {
              date: formatDefaultDate(planExpirationDate),
            })}
          </Typography>
          <Box mt={2} />
          <Typography type="body">{t("CHANGE_PLAN.YOU_WILL_LOSE")}</Typography>
          {isPlanCorporate ? (
            <>
              <ul className={classes.listOfFeatures}>
                <Typography component="li" type="body">
                  {t("CHANGE_PLAN.YOUR_ASSIGNED_FAX_NUMBERS_AND_ABILITY_TO_RECEIVE_FAXES")}
                </Typography>
                <Typography component="li" type="body">
                  {t("CHANGE_PLAN.YOUR_HIERARCHY_STRUCTURE_AND_MEMBERS")}
                </Typography>
                <Typography component="li" type="body">
                  {t("CHANGE_PLAN.YOUR_MEMBERS_WILL_NOT_BE_ABLE_TO_ACCESS_FAX_DOCUMENTS")}
                </Typography>
              </ul>
            </>
          ) : (
            <ul className={classes.listOfFeatures}>
              <Typography component="li" type="body">
                {t("CHANGE_PLAN.YOUR_ASSIGNED_FAX_NUMBER")}
              </Typography>
              <Typography component="li" type="body">
                {t("CHANGE_PLAN.ABILITY_TO_RECEIVE_FAXES")}
              </Typography>
              <Typography component="li" type="body">
                {t("CHANGE_PLAN.MONTHLY_ALLOTMENT_OF_FAX_PAGES")}
              </Typography>
            </ul>
          )}
          <div className={classes.helpContainer}>
            <div>
              <Typography type="body" className={classes.bold}>
                {t("CHANGE_PLAN.CAN_WE_HELP")}
              </Typography>
              <Typography type="body">{t("CHANGE_PLAN.WE_ARE_HERE_TO_HELP")}</Typography>
            </div>
            <div>
              <Button
                variant="white"
                startIcon={faComment}
                onClick={isContactUsModalOpenHandlers.setTrue}
              >
                {t("CHANGE_PLAN.GET_SUPPORT")}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {isContactUsModalOpen && (
        <ContactUsModal
          blocked={false}
          handleClosure={() => {
            isContactUsModalOpenHandlers.setFalse();
            handleClosure();
          }}
        />
      )}
    </>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  bold: {
    fontWeight: "bold",
  },
  helpContainer: {
    display: "flex",
    alignItems: "self-end",
    margin: spacing(3, 0, 2, 0),
    justifyContent: "space-between",
  },
  listOfFeatures: {
    margin: spacing(1, 2, 1, 2),
    listStyle: "none",
    "& li": {
      marginTop: spacing(1),
      "&::before": {
        content: '"\\2022"',
        color: alohi.gray,
        display: "inline-block",
        width: "1em",
      },
    },
  },
}));

export default CancelPlanModal;
