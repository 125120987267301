import { memo } from "react";
import { Box } from "@alohi/kit";
import PropTypes from "prop-types";

import { Typography, makeStyles } from "ui";

function PlanCard({ title, img, primary, secondary }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center" p={1}>
        <Typography component="div" variant="body">
          {title}
        </Typography>
        <Box mt={1}>{img}</Box>
        <Box whiteSpace="nowrap" mt={1}>
          <Typography sx={{ fontWeight: "bold" }}>{primary}</Typography>
        </Box>
        <Box whiteSpace="nowrap">
          <Typography component="div" variant="body">
            {secondary}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

PlanCard.propTypes = {
  img: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const useStyles = makeStyles(({ alohi }) => ({
  root: {
    width: "150px",
    boxShadow: "none",
    alignItems: "center",
  },
  primary: {
    fontWeight: 600,
    color: alohi.gray,
  },
}));

export default memo(PlanCard);
