import PropTypes from "prop-types";

import Visa from "assets/images/payment-methods/Visa-dark.svg";
import Discover from "assets/images/payment-methods/Discover-dark.svg";
import MasterCard from "assets/images/payment-methods/MasterCard-dark.svg";
import AmericanExpress from "assets/images/payment-methods/AmericanExpress-dark.svg";
import JCB from "assets/images/payment-methods/JCB-dark.svg";
import Paypal from "assets/images/payment-methods/Paypal-dark.svg";
import DinersClub from "assets/images/payment-methods/DinersClub-dark.svg";

const getSrcByBrand = (brand) => {
  const src = { value: "" };
  switch (brand?.toLowerCase()) {
    case "visa":
      src.value = Visa;
      break;
    case "paypal":
      src.value = Paypal;
      break;
    case "discover":
      src.value = Discover;
      break;
    case "mastercard":
      src.value = MasterCard;
      break;
    case "amex":
    case "american express":
      src.value = AmericanExpress;
      break;
    case "jcb":
      src.value = JCB;
      break;
    case "diners club":
      src.value = DinersClub;
      break;

    default:
      src.value = MasterCard;
      break;
  }
  return src.value;
};

function PaymentMethodImg({ ...props }) {
  const { brand, ...rest } = props;

  return <img src={getSrcByBrand(brand)} width="50" alt={brand} {...rest} />;
}

PaymentMethodImg.propTypes = {
  brand: PropTypes.string,
};

export default PaymentMethodImg;
