import PropTypes from "prop-types";
import { useState } from "react";
import Select from "react-select";
import { FormLabel, FormHelperText } from "@alohi/kit";
import { FormControl, Grid, makeStyles, useTheme } from "ui";

function FormRowReactSelectInput({
  id,
  label,
  helperText,
  children,
  labelGridWidth = 4,
  error,
  ReactSelectProps,
}) {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();

  const handleBlur = (e) => {
    setIsFocused(false);
    ReactSelectProps?.onBlur && ReactSelectProps.onBlur(e);
  };

  const handleFocus = (e) => {
    setIsFocused(true);
    ReactSelectProps?.onFocus && ReactSelectProps.onFocus(e);
  };

  return (
    <FormControl fullWidth>
      <Grid container alignItems="center">
        {label ? (
          <Grid item xs={labelGridWidth} className={classes.labelContainer}>
            <FormLabel
              id={`${id}-label`}
              htmlFor={id}
              error={error}
              className={classes.label}
              focused={isFocused}
            >
              {label}
            </FormLabel>
          </Grid>
        ) : null}
        <Grid item xs={12 - labelGridWidth}>
          <div className={classes.inputContainer}>
            <Select
              id={id}
              aria-labelledby={`${id}-label`}
              menuPortalTarget={document.body}
              {...ReactSelectProps}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                groupHeading: (provided) => ({
                  ...provided,
                  marginBottom: 0,
                  fontSize: "0.9em",
                  color: theme.alohi.gray,
                  background: theme.alohi.darkGray,
                }),
                group: (provided) => ({
                  ...provided,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: 13,
                  color: theme.alohi.gray,
                  background: state.isSelected ? theme.alohi.lightestBlue : theme.alohi.white,
                  ":hover": {
                    background: theme.alohi.lighterGray,
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: theme.alohi.gray,
                }),
                control: (provided) => ({
                  ...provided,
                  minHeight: 40,
                }),
                ...ReactSelectProps.styles,
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              theme={(provided) => ({
                ...provided,
                colors: {
                  ...provided.colors,
                  primary: theme.alohi.blue,
                },
              })}
            >
              {children}
            </Select>
            <FormHelperText id={`${id}-helper-text`} error={error}>
              {helperText || " "}
            </FormHelperText>
          </div>
        </Grid>
      </Grid>
    </FormControl>
  );
}

const useStyles = makeStyles(({ alohi, spacing }) => ({
  labelContainer: {
    textAlign: "right",
  },
  label: {
    color: alohi.gray,
    marginRight: spacing(2),
    fontWeight: "bold",
    fontSize: 14,
  },
  inputContainer: {
    marginTop: 19,
  },
}));

FormRowReactSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.node,
  labelGridWidth: PropTypes.number,
  ReactSelectProps: PropTypes.object,
};

export default FormRowReactSelectInput;
