import { configureStore } from "@reduxjs/toolkit";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import twoFa from "stores/reducers/twoFa.reducer";
import { NODE_ENV } from "config";
import authentication from "./reducers/authentication.reducer";
import plans from "./reducers/plans.reducer";
import storage from "./reducers/storage.reducer";
import address from "./reducers/address.reducer";
import verifications from "./reducers/verifications.reducer";
import alert from "./reducers/alert.reducer";
import account from "./reducers/account.reducer";
import credit from "./reducers/credit.reducer";
import payment from "./reducers/payment.reducer";
import numbers from "./reducers/numbers.reducer";
import whitelist from "./reducers/contacts.whitelist.reducer";
import blacklist from "./reducers/contacts.blacklist.reducer";
import recent from "./reducers/contacts.recent.reducer";
import groups from "./reducers/contacts.groups.reducer";
import sharedContacts from "./reducers/contacts.sharedContacts.reducer";
import sharedGroups from "./reducers/contacts.sharedGroups.reducer";
import corporate from "./reducers/corporate.reducer";
import syncHistory from "./reducers/syncHistory.reducer";
import faxes from "./reducers/faxes.reducer";
import inbox from "./reducers/faxes.inbox.reducer";
import sent from "./reducers/faxes.sentbox.reducer";
import outbox from "./reducers/faxes.outbox.reducer";
import trash from "./reducers/faxes.trashbox.reducer";
import integrations from "./reducers/integrations.reducer";
import slack from "./reducers/integrations.slack.reducer";
import api from "./reducers/integrations.api.reducer";
import apps from "./reducers/integrations.apps.reducer";
import layout from "./reducers/layout.reducer";
import support from "./reducers/support.reducer";
import dataExport from "./reducers/dataExport.reducer";
import coverSheet from "./reducers/coverSheet.reducer";

const reducer = {
  authentication,
  twoFa,

  alert,
  account,
  address,
  plans,
  credit,
  payment,
  storage,
  numbers,
  verifications,

  whitelist,
  blacklist,
  recent,
  groups,
  sharedContacts,
  sharedGroups,

  corporate,

  syncHistory,

  integrations,
  slack,
  api,
  apps,

  faxes,
  inbox,
  sent,
  outbox,
  trash,

  layout,
  support,
  dataExport,

  coverSheet,
};

export const store = configureStore({
  reducer,
  devTools: NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Export Typed hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
