import { useTranslation } from "react-i18next";
import { Divider, SplitButton, Button, Modal, Box, useSnackbar } from "@alohi/kit";
import {
  lazy,
  useState,
  Suspense,
  useEffect,
  useCallback,
  MouseEvent as ReactMouseEvent,
} from "react";

import useBool from "hooks/useBool";
import { useInput } from "hooks/useInput";
import { isValidName } from "helpers/inputValidation";
import { useAppDispatch, useAppSelector } from "stores/store";
import { Loader, MessageBox, Typography, makeStyles } from "ui";
import FormRowTextInput from "components/Forms/FormRowTextInput";
import useLocalFilesPicker from "views/Common/hooks/useLocalFilesPicker";
import PageOptionsModals from "views/SendFax/components/PageOptionsModals";
import { thunks, selectors, actions } from "stores/reducers/coverSheet.reducer";
import FileUploadOptions, {
  FileUploadOptionsProps,
} from "views/Common/components/FileUploadOptions";
import useFilesManager, { allowedMimes } from "./helpers/filesManager";

const ViewPdf = lazy(() => import("views/SendFax/components/ViewPdf/ViewPdf"));

interface AddCoverSheetModalProps {
  title: string;
  isShared: boolean;
  handleClosure: (isSuccess: boolean) => void;
  disableAnimation?: boolean;
}

interface StyleProps {
  isDragActive: boolean;
}

function AddCoverSheetModal({
  title,
  isShared,
  handleClosure,
  disableAnimation,
}: AddCoverSheetModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [name, nameInput] = useInput("", isValidName);
  const [firstPageFile, setFirstPageFile] = useState<null | File>(null);
  const [isPageWarningDisplayed, isPageWarningDisplayedBool] = useBool(false);
  const [isLoadingFromThirdParty, isLoadingFromThirdPartyBool] = useBool(false);

  const { file, checkFile, removeFile } = useFilesManager();

  const isCreated = useAppSelector(selectors.createResponse);
  const isCreateError = useAppSelector(selectors.isCreateError);
  const uploadFilename = useAppSelector(selectors.uploadFilename);
  const isCreateRunning = useAppSelector(selectors.isCreateRunning);
  const isUploadedFileError = useAppSelector(selectors.isUploadError);
  const isUploadedFileRunning = useAppSelector(selectors.isUploadRunning);
  const isMimeTypeNotSupported = useAppSelector(selectors.isMimeTypeNotSupported);

  const { isDragActive, getRootProps, Input, open } = useLocalFilesPicker({
    onAddDocuments: checkFile,
    allowedMimes: allowedMimes,
  });

  const handleTitleClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    open();
  };

  const handleIsThirdPartyLoading = useCallback(
    (isLoading: boolean) => {
      isLoading ? isLoadingFromThirdPartyBool.setTrue() : isLoadingFromThirdPartyBool.setFalse();
    },
    [isLoadingFromThirdPartyBool],
  );

  const MenuItems = useCallback(
    (props: FileUploadOptionsProps) => (
      <FileUploadOptions
        {...props}
        onAddDocuments={checkFile}
        allowedMimes={allowedMimes}
        isBoxIntegrationEnabled={false}
        allowedFileTypes={[".pdf", ".png"]}
        onLoading={handleIsThirdPartyLoading}
      />
    ),
    [checkFile, handleIsThirdPartyLoading],
  );

  const handleParentClick = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const handleOnSelect = () => {
    nameInput.touch();

    if (nameInput.isValid && file) {
      dispatch(thunks.upload({ file: firstPageFile || file }));
    }
  };

  const handleOnClear = useCallback(() => {
    removeFile();
    setFirstPageFile(null);

    isPageWarningDisplayedBool.setFalse();
  }, [isPageWarningDisplayedBool, removeFile]);

  const handleFirstPageBlob = useCallback(
    (blob: Blob | null, numOfPages: number) => {
      if (blob && numOfPages > 1) {
        isPageWarningDisplayedBool.setTrue();

        const newFile = new File([blob], "output.png");
        setFirstPageFile(newFile);
      }
    },
    [isPageWarningDisplayedBool],
  );

  useEffect(() => {
    if (uploadFilename) {
      dispatch(
        thunks.create({
          name,
          isShared,
          filename: uploadFilename,
        }),
      );
    }
  }, [dispatch, isShared, name, uploadFilename]);

  useEffect(() => {
    if (isCreated) {
      handleClosure(true);
    }
  }, [handleClosure, isCreated]);

  useEffect(() => {
    if (isMimeTypeNotSupported) {
      enqueueSnackbar(t("FAXES.UN_SUPPORT_FILE_TYPE"), { variant: "error" });

      handleOnClear();
      dispatch(actions.clearUpload());
    } else if (isUploadedFileError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });

      dispatch(actions.clearUpload());
    }
  }, [dispatch, enqueueSnackbar, isMimeTypeNotSupported, isUploadedFileError, handleOnClear, t]);

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("COMMON.SERVER_ERROR"), { variant: "error" });

      dispatch(actions.clearCreate());
    }
  }, [dispatch, enqueueSnackbar, isCreateError, t]);

  useEffect(() => {
    return () => {
      dispatch(actions.clearCreate());
      dispatch(actions.clearUpload());
    };
  }, [dispatch]);

  const isFileMissing = file === null;
  const classes = useStyles({ isDragActive });
  const isLoading = isUploadedFileRunning || isCreateRunning || isLoadingFromThirdParty;
  const isConfirmDisabled = isFileMissing || nameInput.showsError || isLoading;

  return (
    <Modal
      maxWidth="sm"
      title={title}
      scroll="paper"
      onConfirm={handleOnSelect}
      isConfirmLoading={isLoading}
      confirmTitle={t("COMMON.ADD")}
      disableAnimation={disableAnimation}
      isConfirmDisabled={isConfirmDisabled}
      confirmTooltip={nameInput.showsError ? t("COVER_SHEET.PLEASE_ADD_NAME") : ""}
      customFooterInfo={
        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          <Button variant="gray" onClick={() => handleClosure(false)}>
            {t("COMMON.CANCEL")}
          </Button>
          <Button onClick={handleOnClear} isDisabled={isFileMissing}>
            {t("COMMON.CLEAR")}
          </Button>
        </Box>
      }
    >
      <Box>
        <Box px={3}>
          <FormRowTextInput
            required
            type="text"
            value={name}
            helperText=" "
            id="name-input"
            labelGridWidth={4}
            onBlur={nameInput.onBlur}
            onFocus={nameInput.onFocus}
            error={nameInput.showsError}
            onChange={nameInput.onChange}
            label={t("COVER_SHEET.NAME")}
          />
        </Box>

        {isPageWarningDisplayed ? (
          <MessageBox variant="info" mb={2} mt={1} mx={3}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="info">
                {t("COVER_SHEET.ONLY_FIRST_PAGE_WILL_BE_UPLOADED")}
              </Typography>
            </Box>
          </MessageBox>
        ) : null}

        <Divider />
      </Box>

      <Box className={classes.rootContainer}>
        {!isFileMissing ? (
          file.type === "image/png" ? (
            <img
              width="600px"
              alt="cover-sheet-page"
              className={classes.image}
              src={URL.createObjectURL(file)}
            />
          ) : (
            <Suspense
              fallback={
                <Box className={classes.loader}>
                  <Loader scale={1.2} />
                </Box>
              }
            >
              <ViewPdf file={file} getFirstPageBlob={handleFirstPageBlob} />
            </Suspense>
          )
        ) : (
          <Box className={classes.dropDocumentsContainer} {...getRootProps()} onClick={open}>
            {isDragActive ? <Box className={classes.dropOverlay} /> : null}

            {Input}

            <Typography
              component="div"
              variant="dropZone"
              className={classes.text}
              onClick={handleParentClick}
            >
              <SplitButton
                MenuItems={MenuItems}
                data-cy="AddFileButton"
                onTitleClick={handleTitleClick}
                MenuItemsModals={PageOptionsModals}
                title={t("COMMON.CLICK_TO_UPLOAD")}
              />
              <Box component="span" sx={{ ml: 1 }} onClick={open}>
                {t("COMMON.OR_DRAG_AND_DROP")}
              </Box>
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ alohi, spacing, shape }) => ({
  rootContainer: {
    width: "100%",
    display: "flex",
    minHeight: "200px",
    maxHeight: "60vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    objectFit: "scale-down",
  },
  dropDocumentsContainer: ({ isDragActive }: StyleProps) => ({
    width: "100%",
    display: "flex",
    cursor: "pointer",
    userSelect: "none",
    position: "relative",
    alignItems: "center",
    border: "2px dashed",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: shape.borderRadius,
    borderColor: isDragActive ? alohi.lightBlue : alohi.lightGray,
    margin: spacing(5, 3, 5, 3),
  }),
  dropOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: shape.borderRadius,
    backgroundColor: alohi.blue + "2e", // alohi.blue with transparency
  },
  text: {
    opacity: 0.7,
    display: "flex",
    color: alohi.gray,
    alignItems: "center",
    margin: spacing(0, 3),
    justifyContent: "center",
  },
  loader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default AddCoverSheetModal;
