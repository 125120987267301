import PropTypes from "prop-types";
import { Modal } from "@alohi/kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import { paymentProviders } from "enums/payment";
import { capitalizeString } from "helpers/string";
import Currency from "components/Core/Currency/Currency";
import WithStripeHOC from "components/Payment/WithStripeHOC";
import AddCreditError from "views/Transactions/AddCreditError";
import useStripe3dsOnDeposit from "hooks/useStripe3dsOnDeposit";
import { selectLastPaymentDate } from "selectors/credit.selector";
import TransactionSuccess from "views/Transactions/TransactionSuccess";
import { selectIsPlanFree, selectNextPlan } from "selectors/plan.selector";
import AddCreditCardOrCredit from "components/Payment/AddCreditCardOrCredit";
import DiscountCodeInvoice from "components/AddDiscountCode/DiscountCodeInvoice";
import { getCreditDetails, getCurrentCredit } from "stores/reducers/credit.reducer";
import {
  selectNewCreditCard,
  selectPaymentDeposit,
  selectPaymentMethods,
  selectPaymentTokenId,
  selectNewPaymentProvider,
  selectPaymentDepositError,
  selectSelectedPaymentAmount,
  selectSelectedPaymentMethod,
  selectIsCreditCardCompleted,
  selectIsPaymentProcessLoading,
  selectPaymentDepositRequiresAction,
} from "selectors/payment.selector";
import {
  clearCustomPaymentAmount,
  clearDiscountAmount,
} from "stores/reducers/payment.amounts.reducer";
import {
  newPaymentDeposit,
  clearNewPaymentDeposit,
  clearConfirmPaymentDeposit,
} from "stores/reducers/payment.deposit.reducer";
import {
  getPaymentMethods,
  clearNewPaymentProvider,
  getDefaultPaymentMethod,
  clearSelectedPaymentMethod,
} from "stores/reducers/payment.methods.reducer";
import {
  clearNewCreditCard,
  clearCreditCardInfo,
  triggerStripePayment,
} from "stores/reducers/payment.creditCard.reducer";
import { GoogleAnalyticsCustomEvents, useGoogleTagManager } from "hooks/useGoogleTagManager";

function AddCreditModal({ withSuccessModal = true, reason = "add_credit", handleClosure }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sendCustomEvent } = useGoogleTagManager();

  const nextPlan = useSelector(selectNextPlan);
  const isPlanFree = useSelector(selectIsPlanFree);
  const newCreditCard = useSelector(selectNewCreditCard);
  const paymentToken = useSelector(selectPaymentTokenId);
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentDeposit = useSelector(selectPaymentDeposit);
  const newPaymentProvider = useSelector(selectNewPaymentProvider);
  const allowToAddPayment = useSelector(selectIsCreditCardCompleted);
  const paymentDepositError = useSelector(selectPaymentDepositError);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentMethod);
  const selectedPaymentAmount = useSelector(selectSelectedPaymentAmount);
  const isPaymentProcessLoading = useSelector(selectIsPaymentProcessLoading);
  const paymentDepositRequiresAction = useSelector(selectPaymentDepositRequiresAction);
  const lastPaymentDate = useSelector(selectLastPaymentDate);

  const [openDepositError, setOpenDepositError] = useState(false);
  const [openDepositSuccess, setOpenDepositSuccess] = useState(false);

  const authenticateCard = useStripe3dsOnDeposit();

  const hasPaymentMethods = paymentMethods && paymentMethods.length > 0;

  const addCreditModalTitle = useMemo(() => {
    if (isPlanFree && reason === "change_plan") {
      return t("ADD_CREDIT.SUBSCRIBE_TO_PLAN", {
        plan: capitalizeString(nextPlan.plan_type),
      });
    }

    return t("COMMON.ADD_CREDIT");
  }, [isPlanFree, t, nextPlan, reason]);

  const handleAddCredit = useCallback(() => {
    if (hasPaymentMethods && selectedPaymentMethod?.id && selectedPaymentAmount?.token) {
      dispatch(
        newPaymentDeposit({
          depositReason: reason,
          data: {
            payment_token: paymentToken,
            via: selectedPaymentMethod.method,
            credit_amount_token: selectedPaymentAmount.token,
          },
        }),
      );
    } else {
      // If the user is free and adds a new credit card, it will trigger a stripe API call
      // Afterwards a new paymentId will be created (line 115)
      dispatch(triggerStripePayment());
    }
  }, [
    reason,
    dispatch,
    paymentToken,
    hasPaymentMethods,
    selectedPaymentMethod,
    selectedPaymentAmount,
  ]);

  const handleModalClosure = useCallback(
    (isSuccess) => {
      setOpenDepositError(false);
      setOpenDepositSuccess(false);

      if (isSuccess) {
        dispatch(getCreditDetails());
        dispatch(getCurrentCredit());
        dispatch(getPaymentMethods());
        dispatch(getDefaultPaymentMethod());
      }

      dispatch(clearCreditCardInfo());
      dispatch(clearNewCreditCard());
      dispatch(clearNewPaymentDeposit());
      dispatch(clearNewPaymentProvider());
      dispatch(clearConfirmPaymentDeposit());
      dispatch(clearCustomPaymentAmount());

      handleClosure(isSuccess);
    },
    [dispatch, handleClosure],
  );

  useEffect(() => {
    dispatch(clearDiscountAmount());
    dispatch(clearSelectedPaymentMethod());
    dispatch(getPaymentMethods());
  }, [dispatch]);

  useEffect(() => {
    if (newCreditCard && !hasPaymentMethods && selectedPaymentAmount?.token) {
      dispatch(
        newPaymentDeposit({
          depositReason: reason,
          data: {
            via: newPaymentProvider,
            payment_token: paymentToken,
            credit_amount_token: selectedPaymentAmount.token,
          },
        }),
      );
    }
  }, [
    reason,
    dispatch,
    newCreditCard,
    paymentToken,
    hasPaymentMethods,
    newPaymentProvider,
    selectedPaymentAmount,
  ]);

  useEffect(() => {
    if (paymentDeposit) {
      if (lastPaymentDate === null) {
        sendCustomEvent({
          event: GoogleAnalyticsCustomEvents.firstTransaction,
          extraData: {
            value: paymentDeposit.amount,
            currency: paymentDeposit.currency,
          },
        });
      }

      if (withSuccessModal) {
        setOpenDepositSuccess(true);
      } else {
        handleModalClosure(true);
      }
    } else if (paymentDepositError) {
      setOpenDepositError(true);
    } else if (paymentDepositRequiresAction) {
      authenticateCard();
    }
  }, [
    paymentDeposit,
    lastPaymentDate,
    authenticateCard,
    withSuccessModal,
    handleModalClosure,
    paymentDepositError,
    paymentDepositRequiresAction,
    sendCustomEvent,
  ]);

  return (
    <>
      {!openDepositSuccess ? (
        <Modal
          data-cy={"AddCreditModal"}
          title={addCreditModalTitle}
          isModalDisabled={isPaymentProcessLoading}
          onCancel={() => handleModalClosure(false)}
          isConfirmLoading={isPaymentProcessLoading}
          isConfirmDisabled={!(allowToAddPayment || hasPaymentMethods)}
          onConfirm={
            newPaymentProvider === paymentProviders.STRIPE || hasPaymentMethods
              ? handleAddCredit
              : undefined
          }
        >
          <AddCreditCardOrCredit />
          <DiscountCodeInvoice />
        </Modal>
      ) : null}

      {openDepositSuccess ? (
        <TransactionSuccess
          title={t("COMMON.ADD_CREDIT")}
          handleClosure={() => handleModalClosure(true)}
          description={
            <>
              {t("ADD_CREDIT.ADD_CREDIT_SUCCESS")}
              <Currency value={paymentDeposit.amount} currency={paymentDeposit.currency} />
            </>
          }
        />
      ) : null}
      {openDepositError ? (
        <AddCreditError
          handleClosure={(shouldCloseModal) => {
            if (shouldCloseModal) {
              setOpenDepositError(false);
            } else {
              handleModalClosure(false);
            }
          }}
        />
      ) : null}
    </>
  );
}

AddCreditModal.propTypes = {
  reason: PropTypes.oneOf([
    "add_credit",
    "change_plan",
    "page_options",
    "change_number",
    "recharge_plan",
    "activate_plan",
    "corporate_number",
  ]),
  withSuccessModal: PropTypes.bool,
  handleClosure: PropTypes.func.isRequired,
};

export default WithStripeHOC(AddCreditModal);
