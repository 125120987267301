import clsx from "clsx";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TextField, DatePicker, Box } from "@alohi/kit";
import { useEffect, memo, useCallback, useContext, useMemo } from "react";

import dayjs from "helpers/date";
import { Typography, makeStyles } from "ui";
import { selectDocumentTypeDictionary } from "selectors/address.selector";
import { CreateAddressBundleContext } from "views/CreateAddressBundle/context/createAddressBundle.context";
import AddressesSelect from "views/CreateAddressBundle/components/BundlePersonalInformation/components/AddressesSelect";

function DocumentCustomFields({ inputId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [{ country, customFields, userDocuments, userData, userType }, localDispatch] = useContext(
    CreateAddressBundleContext,
  );
  const documentDictionary = useSelector(selectDocumentTypeDictionary(userType));

  const handleUpdateCustomField = useCallback(
    (fieldType) =>
      ({ target }) => {
        localDispatch({
          type: "UPDATE_CUSTOM_FIELDS",
          payload: {
            inputId,
            fieldType,
            value: target.value,
          },
        });
      },
    [inputId, localDispatch],
  );

  const handleUpdateAddressField = useCallback(
    (fieldType) => (address) => {
      if (address) {
        localDispatch({
          type: "UPDATE_CUSTOM_FIELDS",
          payload: {
            inputId,
            fieldType,
            value: address,
          },
        });
      }
    },
    [inputId, localDispatch],
  );

  const alreadyProvidedFields = useMemo(
    () => Object.keys(userData?.[userType] ?? []),
    [userData, userType],
  );

  const customFieldsObject = useMemo(() => {
    return Object.entries(userDocuments?.[userType] ?? []).reduce(
      (accumulator, [inputId, value]) => {
        const documentTypes = Object.keys(value);

        documentTypes.forEach((value) => {
          accumulator[inputId] =
            documentDictionary
              ?.find(({ document_type }) => document_type === value)
              ?.attributes?.filter(({ machine_name }) => {
                if (machine_name === "address_sids") {
                  return true;
                }

                return alreadyProvidedFields.indexOf(machine_name) === -1;
              }) ?? null;
        });

        return accumulator;
      },
      {},
    );
  }, [alreadyProvidedFields, documentDictionary, userDocuments, userType]);

  useEffect(() => {
    // Init custom fields if any (ex: address_sids, document_number etc)
    if (customFieldsObject?.[inputId] && !Boolean(customFields?.[userType]?.[inputId])) {
      const fields = customFieldsObject?.[inputId]?.reduce((accumulator, { machine_name }) => {
        accumulator[machine_name] = "";
        return accumulator;
      }, {});

      localDispatch({
        type: "INIT_CUSTOM_FIELDS",
        payload: {
          [inputId]: fields,
        },
      });
    }
  }, [customFields, userType, customFieldsObject, inputId, localDispatch]);

  const render = useMemo(() => {
    const content = [];
    const fieldsCopy = customFieldsObject?.[inputId];
    if (!Boolean(fieldsCopy?.length)) {
      return null;
    }
    const requiredAddress = fieldsCopy.find(({ machine_name }) => machine_name === "address_sids");

    fieldsCopy.forEach(({ machine_name, friendly_name }) => {
      // It will be unshifted at the end.
      if (machine_name === "address_sids") {
        return;
      }
      if (["expiry_date", "birth_date"].includes(machine_name)) {
        content.push(
          <Box className={clsx(classes.input)} key={machine_name + inputId}>
            <DatePicker
              format="YYYY-MM-DD"
              placeholder={friendly_name}
              value={dayjs(customFields?.[userType]?.[inputId]?.[machine_name]) || null}
              onChange={(date) =>
                handleUpdateCustomField(machine_name)({
                  target: { value: dayjs(date).format("YYYY-MM-DD") },
                })
              }
            />
          </Box>,
        );
      } else {
        content.push(
          <TextField
            variant="outlined"
            placeholder={friendly_name}
            key={machine_name + inputId}
            classes={{ root: classes.input }}
            id={`${machine_name}-input-${inputId}`}
            onChange={handleUpdateCustomField(machine_name)}
            value={customFields?.[userType]?.[inputId]?.[machine_name] ?? ""}
          />,
        );
      }
    });

    if (requiredAddress) {
      content.unshift(
        <AddressesSelect
          label="" // to better fit the the design of other inputs
          country={country}
          key="address_sids"
          labelGridWidth={6}
          handleClosure={handleUpdateAddressField("address_sids")}
          value={customFields?.[userType]?.[inputId]?.["address_sids"] ?? ""}
        />,
      );
    }

    content.unshift(
      <Box mt={2} key="requiredInfoText">
        <Typography variant="body" style={{ fontWeight: "bold" }}>
          {t("ADDRESS.BUNDLE_ADDITIONAL_INFO")}
        </Typography>
      </Box>,
    );

    return content;
  }, [
    t,
    inputId,
    country,
    userType,
    customFields,
    classes,
    customFieldsObject,
    handleUpdateCustomField,
    handleUpdateAddressField,
  ]);

  return render;
}

const useStyles = makeStyles(({ spacing }) => ({
  input: {
    maxWidth: "247.5px",
    marginTop: spacing(1),
  },
  inputEmpty: {
    // Matching opacity of placeholders
    "& .MuiInputBase-input": {
      opacity: 0.42,
    },
  },
}));

export default memo(DocumentCustomFields);
