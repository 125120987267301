import { useState, useEffect } from "react";
import { AvatarProps as MuiAvatarProps } from "@mui/material";

import { Avatar as MuiAvatar } from "ui";
import { fetchWithRefresh } from "helpers/fetch";
import defaultAvatar from "assets/images/avatar-small.png";

function Avatar({ alt, src, className, ...otherProps }: MuiAvatarProps) {
  const [localSrc, setLocalSrc] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (!!src) {
        let localUrl = "";
        try {
          const blob = await fetchWithRefresh(src, { cache: "force-cache" });
          localUrl = URL.createObjectURL(blob);
          setLocalSrc(localUrl);
        } catch {
          setLocalSrc(null);
        }
        return () => {
          URL.revokeObjectURL(localUrl);
        };
      } else {
        setLocalSrc(null);
      }
    })();
  }, [src]);

  return (
    <MuiAvatar alt={alt} className={className} src={localSrc ?? defaultAvatar} {...otherProps} />
  );
}

export default Avatar;
