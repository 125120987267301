import { useCallback } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

import { profileRoutes } from "enums/profile";
import ContactUsModal from "views/ContactUs/ContactUsModal";
import { SUBJECTS } from "views/ContactUs/components/SelectSubject/SelectSubject";
import { useFlag } from "@unleash/proxy-client-react";
import IncentiveModal from "components/Incentive/modals/IncentiveModal";
import useTrialIncentive from "components/Incentive/hooks/useTrialIncentive";

function PrivateLayoutHandleQueryString() {
  const history = useHistory();
  const { search } = useLocation();
  const { direct_open_modal } = queryString.parse(search);

  const hasFaxSignCrossSellingBanner = useFlag("fax-sign-cross-selling-banner");
  const { hasPromo } = useTrialIncentive();

  const closeCurrentModal = useCallback(() => {
    history.replace(`/profile/${profileRoutes.general}`);
  }, [history]);

  switch (direct_open_modal) {
    case "contact_us_for_sales":
      return (
        <ContactUsModal
          isHelpBypass
          blocked={false}
          handleClosure={closeCurrentModal}
          preSelectedSubject={SUBJECTS.SALES}
        />
      );
    case "sign_cross_selling":
      if (hasPromo && hasFaxSignCrossSellingBanner) {
        return <IncentiveModal onClose={closeCurrentModal} />;
      }
      return null;
    default:
      return null;
  }
}

export default PrivateLayoutHandleQueryString;
