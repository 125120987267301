import { API_AFFOGATO } from "config";
import { fetchWithRefresh } from "../helpers/fetch";

const sendAPIVerification = ({ imageUrl, fullName, redirectionUris } = {}) => {
  const requestOptions = {
    method: "POST",
    body: {
      files: [imageUrl.slice(0, 9) === "/storage/" ? imageUrl.slice(9) : imageUrl],
      data: {
        client_avatar: imageUrl.slice(0, 9) === "/storage/" ? imageUrl.slice(9) : imageUrl,
        fullname: fullName,
        redirection_uris: redirectionUris,
        user_type: "regular",
      },
    },
  };
  const path = `${API_AFFOGATO}/api_verification`;
  return fetchWithRefresh(path, requestOptions);
};

const getAPIVerification = () => {
  const requestOptions = {
    method: "GET",
  };
  const path = `${API_AFFOGATO}/api_verification`;
  return fetchWithRefresh(path, requestOptions);
};

export const apiVerificationApi = { sendAPIVerification, getAPIVerification };
