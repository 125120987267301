import { Box, Modal } from "@alohi/kit";
import { useCallback, useEffect } from "react";

import useInjectTag from "hooks/useInjectTag";

interface BoxModalProps {
  onOpen: () => void;
}

function BoxModal({ onOpen }: BoxModalProps) {
  const { isLoaded, setTag } = useInjectTag("link");

  const boxCallback = useCallback(
    (domElement: HTMLDivElement | null) => {
      if (domElement !== null) {
        onOpen();
      }
    },
    [onOpen],
  );

  useEffect(() => {
    if (!isLoaded) {
      setTag({
        rel: "stylesheet",
        src: "https://cdn01.boxcdn.net/platform/elements/17.1.0/en-US/picker.css",
        href: "https://cdn01.boxcdn.net/platform/elements/17.1.0/en-US/picker.css",
      });
    }
  }, [isLoaded, setTag]);

  return (
    <Modal maxWidth="md" minHeight="80vh" isFooterHidden>
      {isLoaded ? <Box className="box-container" ref={boxCallback} height="100%" /> : null}
    </Modal>
  );
}

export default BoxModal;
