import { useTranslation } from "react-i18next";
import { Modal, Box, Typography, Helper } from "@alohi/kit";

import { useInput } from "hooks/useInput";
import { helpCenterUrls } from "routes/urls";
import { isValidNote } from "helpers/inputValidation";
import { useSendFaxContext } from "views/SendFax/contexts/context";
import FormRowTextInput from "components/Forms/FormRowTextInput";

interface AddNoteModalProps {
  handleClosure: () => void;
}

function AddNoteModal({ handleClosure }: AddNoteModalProps) {
  const { t } = useTranslation();

  const {
    sendFaxDispatch,
    sendFaxStore: { comment },
  } = useSendFaxContext();

  const [note, noteInput] = useInput(comment, isValidNote);

  const handleConfirm = async () => {
    noteInput.touch();

    if (noteInput.isValid) {
      sendFaxDispatch({
        type: "SET_COMMENT",
        payload: note,
      });

      handleClosure();
    }
  };

  return (
    <Modal
      maxWidth="xs"
      disableRestoreFocus
      onCancel={handleClosure}
      onConfirm={handleConfirm}
      onPressEnter={handleConfirm}
      onPressEscape={handleClosure}
      isConfirmDisabled={noteInput.showsError}
      title={
        <Box display="flex" alignItems="center">
          <Typography type="modalTitle">{t("FAXES.ADD_NOTE")}</Typography>
          <Box
            ml={1}
            component="a"
            target="_blank"
            rel="noreferrer"
            href={helpCenterUrls.addNoteFax}
          >
            <Helper size="lg" variant="dark" />
          </Box>
        </Box>
      }
    >
      <Box py={2} px={4}>
        <FormRowTextInput
          autoFocus
          value={note}
          id="add-note"
          labelGridWidth={12}
          onBlur={noteInput.onBlur}
          onFocus={noteInput.onFocus}
          error={noteInput.showsError}
          onChange={noteInput.onChange}
          inputProps={{ maxLength: 100 }}
        />
      </Box>
    </Modal>
  );
}

export default AddNoteModal;
