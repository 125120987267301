import { useCallback } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { selectRechargePlanRequiresAuthentication } from "../selectors/plan.selector";

import { setPlanRechargeError, setPlanRechargeSuccess } from "../stores/reducers/plans.reducer";
import { confirmPurchaseDeposit } from "stores/reducers/payment.deposit.reducer";
import { useAppDispatch } from "stores/store";
import { sentryCaptureMessage } from "helpers/sentry";

function useStripe3dsOnRecharge() {
  const stripe = useStripe();
  const dispatch = useAppDispatch();

  const rechargePlanRequiresAuthentication = useSelector(selectRechargePlanRequiresAuthentication);

  const confirmCard = useCallback(async () => {
    const response = await stripe.handleCardAction(
      rechargePlanRequiresAuthentication.client_secret,
    );

    if (response?.paymentIntent) {
      await dispatch(confirmPurchaseDeposit());
      dispatch(setPlanRechargeSuccess(true));
    } else if (response?.error) {
      sentryCaptureMessage({
        message: "Something went wrong during 3DS payment confirmation for recharge",
        level: "error",
        breadcumbs: {
          category: "fetch",
          level: "warning",
          message: JSON.stringify(response?.error),
        },
      });
      dispatch(
        setPlanRechargeError({
          isStripeError: true,
          reason: "3ds_recharge_error",
          message: response?.error?.message,
        }),
      );
    }
  }, [dispatch, rechargePlanRequiresAuthentication, stripe]);

  return confirmCard;
}

export default useStripe3dsOnRecharge;
