import { Box, Typography } from "@alohi/kit";
import { useTranslation } from "react-i18next";

interface LinkExpiredProps {
  onCompleted: () => void;
}

function LinkExpired({ onCompleted }: LinkExpiredProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Typography component="span">{t("FORGOT.SECURITY_REASON")}</Typography>
      <Typography
        type="anchor"
        component="span"
        onClick={onCompleted}
        sx={{ "&&": { fontSize: 14 } }}
      >
        {t("FORGOT.REQUEST_NEW_MAIL")}
      </Typography>
    </Box>
  );
}

export default LinkExpired;
