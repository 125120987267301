import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { Modal, makeStyles, useOnMount, Box, Typography } from "@alohi/kit";

import useBool from "hooks/useBool";
import {
  selectNewCreditCardError,
  selectNewCreditCardIsNotHonored,
} from "selectors/payment.selector";
import {
  clearCreditCardInfo,
  clearNewCreditCard,
} from "stores/reducers/payment.creditCard.reducer";
import SwitchPaymentFooter from "./SwitchPaymentFooter";

interface CreditCardErrorProps {
  type: "paypal" | "credit_card";
  onCancel: () => void;
  handleClosure: () => void;
  onOpenPaymentMethod: (type: "list" | "paypal" | "credit_card") => void;
}
function CreditCardError({
  type,
  onCancel,
  handleClosure,
  onOpenPaymentMethod,
}: CreditCardErrorProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isNotHonoredPayment = useSelector(selectNewCreditCardIsNotHonored);
  const newCreditCardError = useSelector(selectNewCreditCardError);

  const [firstErrorText, setFirstErrorText] = useState("");
  const [secondErrorText, setSecondErrorText] = useState("");
  const [hasSwitchPaymentFooter, hasSwitchPaymentFooterBool] = useBool(false);
  const [confirmTitle, setConfirmTitle] = useState(t("FORMS.DONE"));
  const [title, setTitle] = useState(t("ADD_CREDIT.INVALID_PAYMENT"));
  const [handleConfirm, setHandleConfirm] = useState<() => void>(() => {
    return () => undefined;
  });

  useOnMount(() => {
    if (isNotHonoredPayment) {
      if (type === "paypal") {
        setFirstErrorText(t("ERRORS.PAYPAL_PAYMENT_DECLINED"));
      } else {
        setFirstErrorText(t("ERRORS.DO_NOT_HONOR_1"));
        setSecondErrorText(t("ERRORS.DO_NOT_HONOR_2"));
      }
      setTitle(t("ADD_CREDIT.PAYMENT_DECLINED"));
      setConfirmTitle(t("TITLES.RETRY"));
      setHandleConfirm(() => {
        return () => onCancel();
      });
      hasSwitchPaymentFooterBool.setTrue();
    } else {
      setTitle(t("ADD_CREDIT.INVALID_PAYMENT"));
      setConfirmTitle(t("FORMS.DONE"));
      setFirstErrorText(newCreditCardError);
      setHandleConfirm(() => {
        return () => handleClosure();
      });
    }
    dispatch(clearCreditCardInfo());
    dispatch(clearNewCreditCard());
  });

  return (
    <Modal
      title={title}
      onConfirm={handleConfirm}
      confirmTitle={confirmTitle}
      customFooterInfo={
        hasSwitchPaymentFooter ? (
          <SwitchPaymentFooter type={type} onOpenPaymentMethod={onOpenPaymentMethod} />
        ) : undefined
      }
    >
      <Box className={classes.wrapper}>
        <FontAwesomeIcon className={classes.icon} size="6x" icon={faExclamationCircle} />
        <Typography type="body">{firstErrorText || t("ADD_CREDIT.CARD_DECLINED")}</Typography>
        {secondErrorText ? (
          <Typography className={classes.secondText} type="body">
            {secondErrorText || t("ADD_CREDIT.CARD_DECLINED")}
          </Typography>
        ) : null}
      </Box>
    </Modal>
  );
}

const useStyles = makeStyles(({ spacing, alohi }) => ({
  wrapper: {
    display: "flex",
    margin: spacing(2),
    alignItems: "center",
    alignContent: "start",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    fontSize: "6rem",
    color: alohi.gold,
    marginBottom: spacing(2),
  },
  secondText: {
    margin: spacing(2, 0, 1, 0),
  },
}));

export default CreditCardError;
