import { useEffect } from "react";
import { Box, Container, theme } from "@alohi/kit";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useTitle from "hooks/useTitle";
import { profileRoutes } from "enums/profile";
import ProfileTabs from "components/Profile/ProfileTabs";
import { getTwoFaSettings } from "stores/reducers/twoFa.reducer";
import { getSsoSettings } from "stores/reducers/account.sso.reducer";
import { selectCanISeeIntegrationsTab } from "selectors/ability.selector";
import {
  getMigrationStatus,
  getDataResidencyLocations,
  getUserDataResidencySettings,
} from "stores/reducers/account.dataResidency.reducer";
import General from "./General";
import Security from "./Security";
import Compliance from "./Compliance";
import PlanBilling from "./PlanBilling";
import Integrations from "./Integrations";
import { useMediaQuery } from "@alohi/kit";

function Profile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useTitle(t("PROFILE.TITLE"));

  const isMobileLayout = useMediaQuery(theme.breakpoints.down("md"));

  const canISeeIntegrationsTab = useSelector(selectCanISeeIntegrationsTab);

  const {
    params: { profileTab = profileRoutes.general },
  } = useRouteMatch<{ profileTab: string }>();

  useEffect(() => {
    dispatch(getSsoSettings());
    dispatch(getTwoFaSettings());
    dispatch(getDataResidencyLocations());
    dispatch(getUserDataResidencySettings());
    dispatch(getMigrationStatus());
  }, [dispatch]);

  return (
    <Container maxWidth="sm">
      {isMobileLayout ? null : <ProfileTabs />}
      <Box mt={isMobileLayout ? 0 : 4}>
        {
          {
            [profileRoutes.general]: <General />,
            [profileRoutes.planBilling]: <PlanBilling />,
            [profileRoutes.security]: <Security />,
            [profileRoutes.compliance]: <Compliance />,
            [profileRoutes.integrations]: canISeeIntegrationsTab ? <Integrations /> : null,
          }[profileTab]
        }
      </Box>
    </Container>
  );
}

export default Profile;
